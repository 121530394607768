import React from 'react';
import { StyledChatAvatar } from './ChatAvatar.styled';
import { AccountApiType } from '../../User/User.types';
import { useAccountsContext } from '../../Account';
import { OnlineStatusCircle } from '../../Account/components/OnlineStatus/OnlineStatusCircle';
import { AvatarPlaceholder } from './AvatarPlaceholder';
import { AvatarImage } from './AvatarImage';
import { MessageType } from '../Chat.types';
import { StyledAvatarImage } from './AvatarImage/AvatarImage.styled';
import desktopLogo from '../../../webmanifest/images/icons/apple-touch-logo-192x192.png';
import { SkeletonBox } from '../../../shared/components/SkeletonBox';

interface ChatAvatarProps {
  account: AccountApiType | MessageType.System;
  onClick?: () => void;
  avatarSize?: string;
  showOnlineStatus?: boolean;
  onlineStatusSize?: string;
  onlineStatusBorderColor?: string;
  className?: string;
  placeholderColor?: string;
  isLoading?: boolean;
}

export const AVATAR_PLACEHOLDER_WIDTH = 2.25;

export const ChatAvatar = React.forwardRef<HTMLDivElement, ChatAvatarProps>(
  (
    {
      account,
      onClick,
      avatarSize = `${AVATAR_PLACEHOLDER_WIDTH}rem`,
      showOnlineStatus = true,
      onlineStatusSize = '0.625rem',
      onlineStatusBorderColor,
      className,
      placeholderColor,
      isLoading = false,
    },
    ref,
  ) => {
    const { accountsWithAvailability } = useAccountsContext();

    const accountWithAvailability =
      account !== MessageType.System
        ? accountsWithAvailability[account.id] || account
        : undefined;

    const onlineStatus = accountWithAvailability?.onlineStatus;

    return (
      <StyledChatAvatar
        ref={ref}
        className={className}
        avatarSize={avatarSize}
        onClick={onClick}
        isLoading={isLoading}
        data-testid="chat-avatar">
        {isLoading ? (
          <SkeletonBox borderRadius={0.25} />
        ) : account === MessageType.System ? (
          <StyledAvatarImage src={desktopLogo} />
        ) : account.image?.contentUrl ? (
          <AvatarImage account={account} />
        ) : (
          <AvatarPlaceholder bgColor={placeholderColor} account={account} />
        )}
        {showOnlineStatus && onlineStatus && (
          <OnlineStatusCircle
            onlineStatus={onlineStatus}
            circleSize={onlineStatusSize}
            borderColor={onlineStatusBorderColor}
            data-testid="online-status"
          />
        )}
      </StyledChatAvatar>
    );
  },
);
