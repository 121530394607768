import React, { FC } from 'react';
import { ConferenceApiType } from '../Conference.types';
import { ConferenceAvatarDesktop } from './ConferenceAvatarDesktop';
import { ConferenceAvatarUser } from './ConferenceAvatarUser';
import { useConferenceParticipants } from '../Conference.hooks';
import { ConferenceAvatarGroup } from './ConferenceAvatarGroup';
import { ConferenceAvatarEmpty } from './ConferenceAvatarEmpty';

export interface ConferenceAvatarProps {
  conference: ConferenceApiType;
  onClick?: () => void;
  avatarSize?: string;
  showOnlineStatus?: boolean;
  onlineStatusSize?: string;
  onlineStatusBorderColor?: string;
  className?: string;
}

export const ConferenceAvatar: FC<ConferenceAvatarProps> = ({
  conference,
  avatarSize = '2rem',
  onClick = () => {},
  ...props
}) => {
  const participants = useConferenceParticipants(conference, true);

  if (conference.desktop) {
    return (
      <ConferenceAvatarDesktop
        desktop={conference.desktop}
        avatarSize={avatarSize}
        onClick={onClick}
      />
    );
  }

  if (participants.length > 1) {
    return (
      <ConferenceAvatarGroup
        participants={participants}
        avatarSize={avatarSize}
        onClick={onClick}
      />
    );
  }

  if (!participants?.length) {
    return <ConferenceAvatarEmpty avatarSize={avatarSize} />;
  }

  return (
    <ConferenceAvatarUser
      conference={conference}
      avatarSize={avatarSize}
      onClick={onClick}
      {...props}
    />
  );
};
