import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import {
  ImportantMessageContainer,
  StyledShareVaultModal,
} from './ShareVaultModal.styled';
import { ShareVault } from '../ShareVault';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';

interface ShareVaultModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose'> {
  vaultId: string;
  login: string | null;
}

export const ShareVaultModal: FC<ShareVaultModalProps> = ({
  vaultId,
  login = '*****',
  ...modalProps
}) => {
  return (
    <Modal visible={true} width={486} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage
          id={EncryptionTranslation.sharePasswordsModalHeader}
          values={{ login }}
        />
      </ModalHeader>
      <StyledShareVaultModal>
        <ShareVault vaultId={vaultId} />
        <ImportantMessageContainer>
          <ImportantMessage
            type={ImportantMessageType.INFO}
            data-testid="important-msg">
            <FormattedMessage
              id={EncryptionTranslation.passwordSharePasswordInfo}
            />
          </ImportantMessage>
        </ImportantMessageContainer>
      </StyledShareVaultModal>
    </Modal>
  );
};
