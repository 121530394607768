import React, { FC, useCallback } from 'react';
import { useCurrentTeam } from '../../Team.hooks';
import { TeamForm } from '../TeamForm';
import { StyledEditTeamForm } from './EditTeamForm.styled';
import { useTeamsActions } from '../../data/Team/Team.actions';
import type { TeamFormValues } from '../../data/Team/types/Team.types';

interface EditTeamFormProps {
  onDone: () => void;
}

export const EditTeamForm: FC<EditTeamFormProps> = ({ onDone }) => {
  const currentTeam = useCurrentTeam();

  const { editTeam } = useTeamsActions();

  const handleSubmit = useCallback(
    (formValues: TeamFormValues) => {
      editTeam(currentTeam.id, formValues.name, formValues.description).then(
        onDone,
      );
    },
    [currentTeam.id, editTeam, onDone],
  );

  return (
    <StyledEditTeamForm>
      <TeamForm team={currentTeam} onSubmit={handleSubmit} onCancel={onDone} />
    </StyledEditTeamForm>
  );
};
