import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { Input } from '../../shared/components/Input';

export const StyledGlobalSearch = styled.div<{ isFocused: boolean }>`
  display: flex;
  max-width: 512px;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  border-radius: 4px;
  width: 100%;
  height: 46px;
  padding: 0.25rem 0.5rem;
  margin: 0 auto;
  background-color: ${p => p.theme.colors.OnNavbarSubdued};
  ${({ theme: { breakpoints } }) => breakpoints.md`
      height: 28px;
  `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${p => p.theme.colors.Surface};
      border-width: 1px;
      border-style: solid;
      border-radius: 0.5rem 0.5rem 0 0;
      border-color: ${p => p.theme.colors.SurfaceBorder};
      max-width: 100%;
      padding: 0.25rem 0.75rem;

      button {
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
        padding: 0.625rem;
        height: 2rem;
        display: flex;
        align-self: center;

        &:hover {
          background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
        }

        &:focus,
        &:active {
          outline: none;
          background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
        }

        &:focus-visible {
          border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
        }
      }

      input::placeholder {
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
        opacity: 0.4;
      }

      input:focus {
        border: 2px solid transparent;
      }

      ${({ theme: { breakpoints } }) => breakpoints.md`
        height: 3rem;
      `}
    `}
`;

export const GlobalSearchOuter = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.md`
  `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      ${({ theme: { breakpoints } }) => breakpoints.md`
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: flex-start;

        position: fixed;
        top: 0.5rem;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      `}
    `}
`;

export const GlobalSearchInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    max-width: 80vw;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.lg`
    max-width: 816px;
  `}
`;

export const OptionsButton = styled(ButtonWithIcon)`
  color: ${p => p.theme.colors.OnNavbarMediumEmphasis};
  user-select: none;
  pointer-events: none;
`;

export const SearchInput = styled(Input)`
  padding: 0 0.375rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  box-shadow: none;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 2px;

  &:disabled {
    background: transparent;
    opacity: 0.5;
  }

  &::placeholder {
    color: ${p => p.theme.colors.OnNavbarMediumEmphasis};
  }

  &:focus {
    padding: 0.25rem 0.5rem;
  }
`;

export const ClearButton = styled(ButtonWithIcon)<{
  visible: boolean;
}>`
  color: ${p => p.theme.colors.OnNavbarMediumEmphasis};
  ${p =>
    !p.visible &&
    css`
      opacity: 0;
      user-select: none;
    `};
`;
