import React, { FC, useMemo } from 'react';
import {
  ChatDocumentIcon,
  DocumentGoogleDocumentIcon,
  DocumentGoogleDriveIcon,
  DocumentGooglePresentationIcon,
  DocumentIcon,
  DocumentOfficePptxIcon,
  DocumentOfficeWordIcon,
  DocumentOfficeXlsIcon,
  DocumentPdfIcon,
  GoogleDocsIcon,
} from '../../../../shared/icons';
import { DocumentType, getEntityMetadataType } from '..';
import { LinkApiType } from '../../../Link/Link.types';
import { ChatMessageAssetApiType } from '../../../Chat/Chat.types';
import { LinkImageContainer } from '../../../Link/LinksView/Link/LinkImage/LinkImage.styled';
import { StyledDocumentDynamicIcon } from './DocumentDynamicIcon.styled';
import { DocumentGoogleSpreadsheetsIcon } from '../../../../shared/icons/DocumentGoogleSpreadsheets.icon';

type DocumentDynamicIconProps = {
  link?: LinkApiType;
  messageAsset?: ChatMessageAssetApiType;
  iconWidth?: number;
  iconHeight?: number;
  withBackground?: boolean;
  isAsset?: boolean;
  fullSize?: boolean;
};

export const DocumentDynamicIcon: FC<DocumentDynamicIconProps> = ({
  link,
  messageAsset,
  iconWidth = 46,
  iconHeight = 46,
  withBackground,
  isAsset,
  fullSize,
}) => {
  const documentMetaType: DocumentType = useMemo(
    () => getEntityMetadataType(link || messageAsset),
    [link, messageAsset],
  );

  const ICON_SIZE = useMemo(
    () => ({
      width: iconWidth,
      height: iconHeight,
    }),
    [iconHeight, iconWidth],
  );

  const typeIcon = useMemo(() => {
    switch (documentMetaType) {
      case DocumentType.PDF:
        return <DocumentPdfIcon {...ICON_SIZE} />;
      case DocumentType.MS_EXCEL:
        return <DocumentOfficeXlsIcon {...ICON_SIZE} />;
      case DocumentType.MS_WORD:
        return <DocumentOfficeWordIcon {...ICON_SIZE} />;
      case DocumentType.MS_POWERPOINT:
        return <DocumentOfficePptxIcon {...ICON_SIZE} />;
      case DocumentType.GOOGLE_DOCUMENT:
        return <DocumentGoogleDocumentIcon {...ICON_SIZE} />;
      case DocumentType.GOOGLE_SPREADSHEETS:
        return <DocumentGoogleSpreadsheetsIcon {...ICON_SIZE} />;
      case DocumentType.GOOGLE_PRESENTATION:
        return <DocumentGooglePresentationIcon {...ICON_SIZE} />;
      case DocumentType.GOOGLE_DRIVE:
      case DocumentType.GOOGLE_DRIVE_FOLDER:
        return <DocumentGoogleDriveIcon {...ICON_SIZE} />;
      case DocumentType.GOOGLE_COMMON:
        return <GoogleDocsIcon {...ICON_SIZE} />;
      case DocumentType.TEXT:
        return <ChatDocumentIcon {...ICON_SIZE} />;
      case DocumentType.COMMON:
      default:
        return <DocumentIcon {...ICON_SIZE} />;
    }
  }, [ICON_SIZE, documentMetaType]);

  if (withBackground) {
    return (
      <LinkImageContainer
        isAsset={isAsset}
        fullSize={fullSize}
        data-testid="doc-dynamic-icon-container">
        <StyledDocumentDynamicIcon
          type={documentMetaType}
          data-testid="doc-dynamic-icon">
          {typeIcon}
        </StyledDocumentDynamicIcon>
      </LinkImageContainer>
    );
  }

  return typeIcon;
};
