import styled from 'styled-components';

export const StyledRemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 1.375rem;
  height: 1.375rem;
  background-color: ${p => p.theme.colors.Surface};
  border-radius: 50%;
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  appearance: none;
  border: none;

  ::before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.720072 0.720154C0.860698 0.579704 1.05132 0.500814 1.25007 0.500814C1.44882 0.500814 1.63945 0.579704 1.78007 0.720154L5.00007 3.94015L8.22007 0.720154C8.28873 0.646467 8.37153 0.587365 8.46353 0.546373C8.55553 0.505381 8.65485 0.483339 8.75555 0.481562C8.85625 0.479786 8.95628 0.49831 9.04967 0.536031C9.14306 0.573752 9.22789 0.629897 9.29911 0.701116C9.37033 0.772334 9.42647 0.857168 9.46419 0.950557C9.50192 1.04394 9.52044 1.14397 9.51866 1.24467C9.51689 1.34538 9.49484 1.44469 9.45385 1.53669C9.41286 1.62869 9.35376 1.71149 9.28007 1.78015L6.06007 5.00015L9.28007 8.22015C9.35376 8.28881 9.41286 8.37161 9.45385 8.46361C9.49484 8.55561 9.51689 8.65493 9.51866 8.75563C9.52044 8.85633 9.50192 8.95636 9.46419 9.04975C9.42647 9.14314 9.37033 9.22797 9.29911 9.29919C9.22789 9.37041 9.14306 9.42655 9.04967 9.46427C8.95628 9.50199 8.85625 9.52052 8.75555 9.51874C8.65485 9.51697 8.55553 9.49492 8.46353 9.45393C8.37153 9.41294 8.28873 9.35384 8.22007 9.28015L5.00007 6.06015L1.78007 9.28015C1.6379 9.41263 1.44985 9.48475 1.25555 9.48133C1.06125 9.4779 0.875864 9.39919 0.738451 9.26177C0.601038 9.12436 0.522326 8.93897 0.518897 8.74467C0.515469 8.55037 0.587592 8.36233 0.720072 8.22015L3.94007 5.00015L0.720072 1.78015C0.579622 1.63953 0.500732 1.4489 0.500732 1.25015C0.500732 1.0514 0.579622 0.860779 0.720072 0.720154Z' fill='white'/%3E%3C/svg%3E%0A");
    background-color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  :hover {
    ::before {
      background-color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }
`;
