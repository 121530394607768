import { WebPushSubscriptionApiType } from './WebPush.types';
import { arrayBufferToBase64 } from '../../shared/utils/encoding.utils';

export const getWebPushSubscriptionForAPI = (
  subscription: PushSubscription,
): WebPushSubscriptionApiType | null => {
  if (
    subscription.endpoint &&
    subscription.getKey('p256dh') &&
    subscription.getKey('auth')
  ) {
    return {
      endpoint: subscription.endpoint,
      p256dh: arrayBufferToBase64(subscription.getKey('p256dh')!),
      auth: arrayBufferToBase64(subscription.getKey('auth')!),
    };
  }
  return null;
};
