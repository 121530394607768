import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { useApolloClient } from '@apollo/client';
import { getChatMercureUrl } from './ChatMercure.utils';
import {
  ChatEvent,
  ChatEventRequests,
  ChatEventTypes,
} from './ChatMercure.types';
import {
  handleNewChatMessage,
  handleRemoveChatMessage,
  handleUpdateChatMessage,
} from './handlers/chatMessage';
import {
  handleNewChatConversation,
  handleRemoveChatConversation,
  handleUpdateChatConversation,
} from './handlers/chatConversation';
import {
  handleNewChatConference,
  handleUpdateChatConference,
} from './handlers/chatConference';
import {
  handleNewScheduledChatConference,
  handleRemoveScheduledConferenceInCache,
  handleUpdateScheduledConferenceInCache,
} from './handlers/scheduledChatConversation';
import {
  handleNewRepeatingScheduledChatConferencesInCache,
  handleRemoveRepeatingScheduledChatConferencesInCache,
} from './handlers/scheduledRepeatingChatConferences';
import {
  ChatMercureContext,
  ChatMercureContextType,
} from './ChatMercure.context';
import { CallToast } from '../../Conference/CallToast';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useWorkspacesQuery,
} from '../../Workspace/Workspace.hooks';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { useQueryParams } from '../../../shared/hooks';
import { format, fromUnixTime } from 'date-fns';
import { useWorkerQueue } from '../WorkerQueue/WorkerQueue.hooks';
import { CHAT_MERCURE_WORKER_QUEUE_TAG } from './ChatMercure.constants';
import { playMentionSound } from '../../Chat/Chat.utils';
import { FetchPolicyContext } from '../../Api/FetchPolicy/FetchPolicy.context';
import { isEventFresh } from '../General/GeneralMercure.utils';
import { MessageType } from '../../Chat/Chat.types';
import { updateLinkDataInCache } from '../../Chat/cache/Chat.cache';
import { extractNodes } from '../../../shared/api/api.utils';
import { useChatMessagesDelta } from './ChatMercure.hooks';
import { MessagesNotificationsType } from '../../GeneralSettings/NotificationsSettingsAdmin/CustomNotificationsSettingsForm/CustomNotifications.types';
import { useChatMessageRepository } from '../../Chat/Data/Repository/ChatMessage/ChatMessageApiRepository';
import { MercureClientType, MercureStatus } from '../Mercure.types';
import { ChatMercureClient } from './ChatMercureClient';
import { useMercureClient } from '../Mercure.hooks';
import { useIsOnline } from '../../Offline';
import { useAccountApiRepository } from '../../Account/data/CurrentAccount/CurrentAccount.repositories';

export const ChatMercureProvider: FC<PropsWithChildren> = ({ children }) => {
  const { chatMercureClient, setChatMercureClient } = useMercureClient();
  const { account: currentAccount, updateAccount } = useCurrentAccount();
  const { account: workspaceAccount } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const useAccountApiRepositoryRef = useRef(useAccountApiRepository());

  const { currentConversationInstance, getChatLink } =
    useChatMessageRepository();

  const { thread: sidebarThreadMessageId } = queryParams;
  const closeThreadSideBar = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        thread: undefined,
        conversation: undefined,
        highlight: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const logChatMercureEvent = useCallback(
    (message: string, event?: ChatEvent) => {
      if (window.DESKTOPCOM_LOG_CHAT_MERCURE_EVENTS) {
        const text = `[${format(new Date(), 'hh:mm:ss.SSS')}] ${message}`;
        if (event) {
          console.log(
            `${text}: `,
            event['@type'] + '.' + event['@request'],
            event,
          );
        } else {
          console.log(text);
        }
      }
    },
    [],
  );

  const { data: workspacesData } = useWorkspacesQuery({
    fetchPolicy: 'cache-only',
    variables: {
      id: currentAccount!.identityId,
    },
  });

  const workspaceIds = useMemo(
    () =>
      extractNodes(workspacesData?.accountIdentity.myAccounts)
        .map(account => extractNodes(account.workspaces))
        .flat()
        .map(identityWorkspace => identityWorkspace.workspace.id),
    [workspacesData],
  );

  const workspaceIdsRef = useRef<string[]>([]);
  workspaceIdsRef.current = workspaceIds;

  const fetchChatMessagesDelta = useChatMessagesDelta();

  const apolloClient = useApolloClient();
  const { addToQueue, onMessage } = useWorkerQueue();

  const workspaceAccountRef = useRef(workspaceAccount);
  workspaceAccountRef.current = workspaceAccount;

  const currentAccountRef = useRef(currentAccount);
  currentAccountRef.current = currentAccount;

  const { resetState } = useContext(FetchPolicyContext);

  const listenersRef = useRef<Array<(e: ChatEvent) => void>>([]);

  const contextValue: ChatMercureContextType = useMemo(
    () => ({
      addListener: listener => {
        listenersRef.current = [...listenersRef.current, listener];
      },
      removeListener: listener => {
        listenersRef.current = listenersRef.current.filter(l => l !== listener);
      },
    }),
    [],
  );

  const forceRefetchConferences = useCallback(() => {
    if (window.location.pathname.split('/').includes('calls')) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          refetchScheduledConference: true,
        }),
      });
    }
  }, [navigate, queryParams]);

  // useRef here to avoid queryParams and history dependency in handleMercureEvent
  // its allow avoiding Mercure reconnect issue
  const forceRefetchConferencesRef = useRef<() => void>();
  forceRefetchConferencesRef.current = forceRefetchConferences;

  const soundDisabled =
    currentAccount &&
    (!currentAccount.identity?.accountIdentitySetting?.soundsEnable ||
      currentAccount.identity?.accountIdentitySetting?.messagesNotifications ===
        MessagesNotificationsType.disable);

  const handleMercureEvent = useCallback(
    (event: ChatEvent) => {
      if (!chatMercureClient) {
        logChatMercureEvent('Chat Mercure Client dissconnected', event);
        return;
      }

      logChatMercureEvent('Chat Mercure event processed', event);
      const eventTimestamp = fromUnixTime(event['@timestamp']).toISOString();

      chatMercureClient.lastEventReceivedAt = eventTimestamp;

      switch (event['@type']) {
        // This is a temporary solution, do not take it as a precedent.
        // It was made due to a lack of data in entities.
        // Do not repeat this implementation.
        case ChatEventRequests.PlaySound:
          if (!event.onCall && isEventFresh(event)) {
            if (soundDisabled) {
              return;
            }

            playMentionSound();
          }
          break;
        case ChatEventTypes.ChatMessage:
          if (event.type === MessageType.Draft) {
            // if (event['@request'] === ChatEventRequests.New) {
            //   handleNewDraftChatMessage(event, apolloClient);
            // } else if (event['@request'] === ChatEventRequests.Remove) {
            //   handleRemoveDraftChatMessage(event, apolloClient);
            // }
            break;
          }

          switch (event['@request']) {
            case ChatEventRequests.New:
              handleNewChatMessage(
                event,
                currentAccountRef.current,
                updateAccount,
                currentConversationInstance(event.conversationIri),
                useAccountApiRepositoryRef.current,
              );
              break;
            case ChatEventRequests.Update:
              handleUpdateChatMessage(
                event,
                workspaceAccountRef.current,
                getChatLink,
              );
              break;
            case ChatEventRequests.Remove:
              handleRemoveChatMessage(
                event,
                closeThreadSideBar,
                sidebarThreadMessageId as string | null,
              );
              break;
            default:
              break;
          }
          break;
        case ChatEventTypes.ChatLink:
          switch (event['@request']) {
            case ChatEventRequests.Update:
              updateLinkDataInCache(apolloClient, event.data.id, () => ({
                ...event.data,
              }));
              break;
          }
          break;
        case ChatEventTypes.ChatConversation:
          if (!workspace.id || !workspaceAccountRef.current.id) {
            return;
          }
          switch (event['@request']) {
            case ChatEventRequests.New:
              handleNewChatConversation(event, {
                workspaceId: event.workspace.id,
                accountId: workspaceAccountRef.current.id,
              });
              break;
            case ChatEventRequests.Update:
              handleUpdateChatConversation(event, {
                workspaceId: event.workspace.id,
                accountId: workspaceAccountRef.current.id,
              });
              break;
            case ChatEventRequests.Remove:
              handleRemoveChatConversation(event);
              break;
            default:
              break;
          }
          break;
        case ChatEventTypes.ChatConference:
          switch (event['@request']) {
            case ChatEventRequests.New:
              handleNewChatConference(event, apolloClient);
              break;
            case ChatEventRequests.Update:
              handleUpdateChatConference(
                event,
                apolloClient,
                workspaceAccountRef.current,
                updateAccount,
                useAccountApiRepositoryRef.current,
              );
              break;
            default:
              break;
          }
          break;
        case ChatEventTypes.ScheduledChatConference:
        case ChatEventTypes.CustomScheduledChatConferenceRemove:
          switch (event['@request']) {
            case ChatEventRequests.New:
              handleNewScheduledChatConference(event, apolloClient);
              break;
            case ChatEventRequests.Update:
              handleUpdateScheduledConferenceInCache(event, apolloClient);
              break;
            case ChatEventRequests.Remove:
              handleRemoveScheduledConferenceInCache(event, apolloClient);
              break;
            default:
              break;
          }
          break;
        case ChatEventTypes.RepeatingScheduleChatConference:
        case ChatEventTypes.CustomScheduledRepeatingChatConferenceRemove:
          switch (event['@request']) {
            case ChatEventRequests.New:
              handleNewRepeatingScheduledChatConferencesInCache(
                event,
                apolloClient,
              );
              break;
            case ChatEventRequests.Update:
              forceRefetchConferencesRef.current?.();
              break;
            case ChatEventRequests.Remove:
              handleRemoveRepeatingScheduledChatConferencesInCache(
                event,
                apolloClient,
              );
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      listenersRef.current.forEach(listener => {
        listener(event);
      });
    },
    [
      chatMercureClient,
      logChatMercureEvent,
      workspace.id,
      soundDisabled,
      updateAccount,
      currentConversationInstance,
      getChatLink,
      closeThreadSideBar,
      sidebarThreadMessageId,
      apolloClient,
    ],
  );

  useEffect(() => {
    onMessage(CHAT_MERCURE_WORKER_QUEUE_TAG, handleMercureEvent);
  }, [handleMercureEvent, onMessage]);

  const mercureUrl = useMemo(
    () => getChatMercureUrl(currentAccount?.mercureInfo?.chat),
    [currentAccount],
  );
  const mercureToken = currentAccount?.mercureInfo?.chat?.authorization;

  const isOnline = useIsOnline();

  const mercureClientArgs = useMemo(() => {
    if (!currentAccount || !mercureUrl || !mercureToken) {
      return null;
    }

    return {
      currentAccount,
      mercureClientType: MercureClientType.chat,
      mercureUrl,
      initialAuthToken: mercureToken,
      workspaceIds,
      fetchChatMessagesDelta,
      resetState,
      updateAccount,
      addToQueue,
      isOnline,
    };
  }, [
    addToQueue,
    currentAccount,
    fetchChatMessagesDelta,
    mercureToken,
    mercureUrl,
    resetState,
    updateAccount,
    workspaceIds,
    isOnline,
  ]);

  useEffect(() => {
    if (chatMercureClient || !mercureClientArgs) {
      return;
    }

    console.log('[Chat Mercure] settings client');
    setChatMercureClient(
      new ChatMercureClient({
        ...mercureClientArgs,
      }),
    );
  }, [chatMercureClient, mercureClientArgs, setChatMercureClient]);

  useEffect(() => {
    if (chatMercureClient && mercureClientArgs) {
      chatMercureClient.updateVariables(mercureClientArgs);
    }
  }, [mercureClientArgs, chatMercureClient]);

  useEffect(() => {
    if (
      !isOnline &&
      chatMercureClient?.mercureStatus === MercureStatus.connected
    ) {
      console.log('[Chat Mercure] disconnecting due to offline');
      chatMercureClient.disconnectMercureClient();
    }
  }, [chatMercureClient, isOnline]);

  useEffect(() => {
    if (
      isOnline &&
      chatMercureClient?.mercureStatus === MercureStatus.disconnected
    ) {
      console.log('[Chat Mercure] reconnecting after returning back to online');
      chatMercureClient.reconnect();
    }
  }, [chatMercureClient, isOnline]);

  return (
    <ChatMercureContext.Provider value={contextValue}>
      {children}

      <CallToast />
    </ChatMercureContext.Provider>
  );
};
