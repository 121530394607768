import styled from 'styled-components';

export const StyledCreateVaultItem = styled.div`
  padding-top: 1rem;

  > * ~ * {
    margin-top: 1rem;
  }
`;

export const DecryptionSuccessMessage = styled.div`
  padding: 1rem;
`;
