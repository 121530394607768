import styled, { css } from 'styled-components';
import { StyledInitials } from '../../User/UserAvatar/Avatar.styled';

export const StyledWorkspaceIcon = styled.div<{
  size: number;
  rounded: boolean;
  color?: string;
  backgroundColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${p => p.size}px;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.SurfaceBorder};
  background-color: ${p =>
    p.backgroundColor || p.theme.colors.WorkspaceIconDefault};
  position: relative;
  overflow: hidden;

  svg,
  img {
    pointer-events: none;
  }

  ${({ color }) =>
    color &&
    css`
      svg,
      ${StyledInitials} {
        color: ${color};
      }
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
    `}
`;
