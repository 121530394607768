import styled from 'styled-components';

export const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 15px;
  }
`;
