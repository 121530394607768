import styled from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { Breakpoints } from '../../Theme';

export const NoTimelineEventsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding-bottom: 2rem;
`;

export const TimelineEventsList = styled.div`
  overflow-y: auto;
  flex-basis: 100%;
  padding: 12px 16px;
`;

export const FilterContainer = styled.div`
  padding: 13px 16px;
`;

export const SelectedDateRangeFilter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 10px 4px 16px;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  border-radius: 4px;
`;

export const SelectedDate = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.Primary};
  font-size: 0.875rem;

  svg {
    margin-right: 12px;
  }
`;

export const FilterCloseButton = styled(ButtonWithIcon)`
  svg {
    width: 9px;
    height: 9px;
    color: ${p => p.theme.colors.Primary};
  }

  @media (max-width: ${Breakpoints.lg - 1}px) {
    padding: 12px;
    transform: translateX(10px);
  }
`;
