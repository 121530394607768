import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/esm/locale';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMobile } from '../../../../../shared/hooks';
import { useAccountsContext, useDeletedAccount } from '../../../../Account';
import { TimelineTranslation } from '../../../../Timeline/i18n';

export const useMessageDate = (inputDate: Date): string => {
  const intl = useIntl();
  const isMobile = useMobile();

  return useMemo(() => {
    const formatRelativeLocale: any = {
      lastWeek: isMobile ? 'p' : 'dd/MM/yyyy',
      yesterday: isMobile
        ? 'p'
        : `'${intl.formatMessage({
            id: TimelineTranslation.dayDateYesterday,
          })} at' p`,
      today: isMobile
        ? 'p'
        : `'${intl.formatMessage({
            id: TimelineTranslation.dayDateToday,
          })} at' p`,
      other: isMobile ? 'p' : 'dd/MM/yyyy',
    };
    try {
      return formatRelative(inputDate, new Date(), {
        locale: {
          ...enUS,
          formatRelative: token => {
            if (['lastWeek', 'yesterday', 'today', 'other'].includes(token)) {
              return formatRelativeLocale[token];
            } else {
              return '';
            }
          },
        },
      });
    } catch (e) {
      return '';
    }
  }, [inputDate, intl, isMobile]);
};

export const useMessageAuthor = (authorId: string | null) => {
  const { accountsWithAvailability } = useAccountsContext();
  const deletedAccount = useDeletedAccount();

  return authorId
    ? accountsWithAvailability[authorId] ?? deletedAccount
    : undefined;
};
