import {
  createAccessRestApi,
  deleteAccessByIdRestApi,
  updateAccessByIdRestApi,
} from './operations/AccessRestApi.operations';
import type { CreateAccessArgs } from './types/Access.types';
import type { DesktopAccessType } from '../../Desktop/data/Desktop/types/Desktop.types';
import {
  addAccessToIDDB,
  removeAccessByIdIDDB,
  updateAccessInIDDB,
} from './operations/AccessIDDB.operation';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';

export const useAccessActions = () => {
  const { workspace } = useCurrentWorkspace();

  const createAccess = async (desktopId: string, args: CreateAccessArgs) => {
    try {
      const response = await createAccessRestApi(desktopId, args);
      if (response?.data) {
        addAccessToIDDB(response.data, workspace.id);
      }
      return Promise.resolve();
    } catch (error) {
      console.error(error);
    }
  };

  const updateAccess = async (
    desktopId: string,
    accessId: string,
    newAccessType: DesktopAccessType,
  ) => {
    try {
      updateAccessInIDDB(accessId, newAccessType);
      updateAccessByIdRestApi(desktopId, accessId, newAccessType);
    } catch (error) {
      console.error(error);
    }
  };

  const removeAccessById = async (accessId: string, desktopId: string) => {
    try {
      removeAccessByIdIDDB(accessId);
      deleteAccessByIdRestApi(desktopId, accessId);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createAccess,
    updateAccess,
    removeAccessById,
  };
};
