import styled, { css } from 'styled-components';

const APP_SIZE = 116;
const APP_SIZE_MOBILE = 113;
const APP_SIZE_SMALL_MOBILE = 100;

export const StyledAppsLayoutGrid = styled.div`
  flex: 1;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 8px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, ${APP_SIZE_MOBILE}px);
  margin: 0 auto;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    grid-template-columns: repeat(auto-fill, ${APP_SIZE}px);
    margin: unset;
  `} @media(max-width: 376 px) {
    grid-template-columns: repeat(auto-fill, ${APP_SIZE_SMALL_MOBILE}px);
  }
`;

export const StyledAppsLayoutGridInner = styled.div<{ processHover: boolean }>`
  border-radius: 8px;
  padding: 0.5rem;
  width: ${APP_SIZE_MOBILE}px;
  height: ${APP_SIZE_MOBILE}px;
  background-color: ${p => p.theme.colors.Surface};
  display: flex;
  border: solid 1px ${p => p.theme.colors.BorderDefault};

  ${({ theme, processHover }) =>
    processHover &&
    css`
      &:hover {
        background-color: ${theme.colors.OnSurfaceHover};
      }
    `}

  ${({ theme: { breakpoints } }) => breakpoints.md`
    width: ${APP_SIZE}px;
    height: ${APP_SIZE}px;
  `} @media(max-width: 376 px) {
    width: ${APP_SIZE_SMALL_MOBILE}px;
    height: ${APP_SIZE_SMALL_MOBILE}px;
  }
`;
