import { useCallback, useContext } from 'react';
import { DesktopItemMultiselectContext } from './DesktopItemMultiselect.context';
import { useCurrentDesktop } from '../Desktop.hooks';

export const useSelectedLinksMap = () => {
  const {
    selectedLinkMap,
    toggleSelectedLink,
    selectMultipleDesktopLinks,
    unselectAllDesktopLinks,
  } = useContext(DesktopItemMultiselectContext);
  return {
    selectedLinkMap,
    toggleSelectedLink,
    selectMultipleDesktopLinks,
    unselectAllDesktopLinks,
  };
};

export const useCurrentDesktopSelectedLinksMap = () => {
  const {
    selectedLinkMap,
    toggleSelectedLink,
    selectMultipleDesktopLinks,
    unselectAllDesktopLinks,
  } = useContext(DesktopItemMultiselectContext);
  const desktop = useCurrentDesktop();
  const desktopId = desktop?.id;

  const currentDesktopSelectedLinksMap =
    (desktopId && selectedLinkMap[desktopId]) || {};

  const toggleCurrentDesktopSelectedLink = useCallback(
    (linkId: string) => {
      return desktopId && toggleSelectedLink(desktopId, linkId);
    },
    [desktopId, toggleSelectedLink],
  );

  const selectMultipleCurrentDesktopLinks = useCallback(
    (linkIds: string[]) => {
      return desktopId && selectMultipleDesktopLinks(desktopId, linkIds);
    },
    [desktopId, selectMultipleDesktopLinks],
  );

  const unselectAllCurrentDesktopLinks = useCallback(() => {
    return desktopId && unselectAllDesktopLinks(desktopId);
  }, [desktopId, unselectAllDesktopLinks]);

  return {
    currentDesktopSelectedLinksMap,
    toggleCurrentDesktopSelectedLink,
    selectMultipleCurrentDesktopLinks,
    unselectAllCurrentDesktopLinks,
  };
};
