import styled from 'styled-components';
import {
  CLASS_NAME_APPS_LINKS_COLUMN,
  CLASS_NAME_APPS_SECTION,
  CLASS_NAME_CHAT_SECTION,
  CLASS_NAME_LINKS_SECTION,
} from './LayoutConsolidated.constants';

export const StyledLayoutConsolidated = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;

  .${CLASS_NAME_APPS_LINKS_COLUMN} {
    flex: 3;
  }

  .${CLASS_NAME_CHAT_SECTION} {
    flex: 2;
    position: relative;
  }

  .${CLASS_NAME_APPS_LINKS_COLUMN} + .${CLASS_NAME_CHAT_SECTION} {
    margin-left: 1.5rem;
    max-width: 24rem;
  }
`;

export const AppsLinksColumn = styled.div<{ hasLinks?: boolean }>`
  display: flex;
  flex-direction: column;

  .${CLASS_NAME_APPS_SECTION} {
    flex: 1;
    max-height: ${({ hasLinks }) => (hasLinks ? '17.8rem' : '100%')};
  }

  .${CLASS_NAME_LINKS_SECTION} {
    flex: 1;
  }

  .${CLASS_NAME_APPS_SECTION} + .${CLASS_NAME_LINKS_SECTION} {
    margin-top: 2.5rem;
  }
`;
