import React, { FC } from 'react';
import {
  ConferenceClosedBannerControls,
  ConferenceClosedBannerMessage,
  StyledConferenceClosedBanner,
} from './ConferenceClosedBanner.styled';
import { ConferenceTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';

export const ConferenceClosedBanner: FC = () => {
  const handleClose = () => {
    window.close();
    setTimeout(() => {
      window.location.href = '/';
    }, 250);
  };

  return (
    <StyledConferenceClosedBanner>
      <FormattedMessage
        tagName={ConferenceClosedBannerMessage}
        id={ConferenceTranslation.conferenceClosedBannerMessage}
      />
      <ConferenceClosedBannerControls>
        <Button
          size={ButtonSize.md}
          mode={ButtonMode.secondary}
          onClick={handleClose}>
          <FormattedMessage
            id={ConferenceTranslation.conferenceClosedBannerCloseButton}
          />
        </Button>
        <Button
          size={ButtonSize.md}
          mode={ButtonMode.primary}
          onClick={() => window.location.reload()}>
          <FormattedMessage
            id={ConferenceTranslation.conferenceClosedBannerRejoinButton}
          />
        </Button>
      </ConferenceClosedBannerControls>
    </StyledConferenceClosedBanner>
  );
};
