import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Translation } from '../../../Intl/i18n/i18n.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { UserTranslation } from '../../i18n';
import { InviteUsersFormFields } from '../../User.types';
import { useSomeEmailIsEmpty } from './InviteUsers.hook';
import { FormControls } from './InviteUsers.styled';

interface InviteUsersFormControlsProps {
  users: InviteUsersFormFields[];
  isSubmitting: boolean;
  inviteUsersButtonText?: Translation;
  onSkip?: () => void;
}

export const InviteUsersFormControls: FC<InviteUsersFormControlsProps> = ({
  users,
  isSubmitting,
  onSkip,
  inviteUsersButtonText,
}) => {
  const isSomeEmailEmpty = useSomeEmailIsEmpty(users);

  return (
    <FormControls>
      <Button
        type="submit"
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        disabled={isSomeEmailEmpty || isSubmitting}
        data-testid="submit-button">
        <FormattedMessage
          id={inviteUsersButtonText || UserTranslation.inviteUsersSubmitButton}
        />
      </Button>
      {onSkip && (
        <Button
          type="button"
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          fullWidth
          disabled={isSubmitting}
          onClick={onSkip}
          data-testid="skip-button">
          <FormattedMessage id={UserTranslation.inviteUsersSkipButton} />
        </Button>
      )}
    </FormControls>
  );
};
