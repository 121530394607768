import { gql } from '@apollo/client';

export const CREATE_WORKSPACE_DOMAIN = gql`
  mutation CreateWorkspaceDomain($input: createWorkspaceDomainInput!) {
    createWorkspaceDomain(input: $input) {
      clientMutationId
    }
  }
`;

export interface CreateWorkspaceDomainVariables {
  input: {
    domain: string;
    workspace: string;
    enabled: boolean;
  };
}

export interface CreateWorkspaceDomainResponse {
  clientMutationId: string;
}

export const UPDATE_WORKSPACE_DOMAIN = gql`
  mutation UpdateWorkspaceDomain($input: updateWorkspaceDomainInput!) {
    updateWorkspaceDomain(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateWorkspaceDomainVariables {
  input: {
    id: string;
    domain: string;
    enabled: boolean;
  };
}

export interface UpdateWorkspaceDomainResponse {
  clientMutationId: string;
}

export const DELETE_WORKSPACE_DOMAIN = gql`
  mutation DeleteWorkspaceDomain($input: deleteWorkspaceDomainInput!) {
    deleteWorkspaceDomain(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteWorkspaceDomainVariables {
  input: {
    id: string;
  };
}

export interface DeleteWorkspaceDomainResponse {
  clientMutationId: string;
}
