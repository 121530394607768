import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useCallback, useMemo } from 'react';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import {
  InvitationRepositoryProps,
  InvitationRepositoryResponse,
  InvitationsRepositoryProps,
  InvitationsRepositoryResponse,
} from './types/InvitationRepository.types';
import {
  getInvitationGraphQl,
  getInvitationsGraphQl,
} from './dataSources/InvitationGraphQl.dataSources';
import {
  getInvitationFromIDDB,
  getInvitationsFromIDDB,
} from './dataSources/InvitationIDDB.dataSources';
import { getRedundantCacheInvitations } from './utils/InvitationsRepository.utils';
import {
  bulkDeleteInvitationsFromIDDB,
  bulkPutInvitationsToIDDB,
} from './operations/InvitationIDDB.operations';
import { AccountInvitationApiType } from './types/Invitation.types';
import { useSingleEntityRepository } from '../../../../shared/hooks/repository/singleEntityRepository.hooks';
import { getLongId } from '../../../../shared/utils/id';

export const useInvitationsRepository = ({
  fetchPolicy = 'cache-and-network',
  variables = {},
}: InvitationsRepositoryProps): InvitationsRepositoryResponse => {
  const { workspace } = useCurrentWorkspace();
  const { desktopId } = variables;

  const fetchInvitations = useCallback(async () => {
    try {
      const invitationsFromAPI = await getInvitationsGraphQl(
        workspace.id,
        desktopId,
      );
      const cachedInvitationRows = await getInvitationsFromIDDB(
        workspace.id,
        desktopId,
      );

      const redundantCacheInvitations = getRedundantCacheInvitations(
        cachedInvitationRows,
        invitationsFromAPI,
      );

      await bulkPutInvitationsToIDDB(
        invitationsFromAPI,
        workspace.id,
        desktopId,
      );
      await bulkDeleteInvitationsFromIDDB(
        redundantCacheInvitations.map(invitation => invitation.id),
      );

      return invitationsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [desktopId, workspace.id]);

  const { entities: invitations, loading } =
    useMultipleEntityRepository<AccountInvitationApiType>({
      fetchFunction: fetchInvitations,
      iddbQuerier: () => getInvitationsFromIDDB(workspace.id, desktopId),
      dependencies: [workspace.id, desktopId],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      invitations,
      loading,
      fetchInvitations,
    }),
    [invitations, loading, fetchInvitations],
  );
};

export const useInvitationRepository = ({
  fetchPolicy = 'cache-and-network',
  variables,
}: InvitationRepositoryProps): InvitationRepositoryResponse => {
  const { workspace } = useCurrentWorkspace();
  const { id } = variables;

  const fetchInvitation = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const invitationFromAPI = await getInvitationGraphQl(
        getLongId('account-invitations', id),
      );

      if (invitationFromAPI) {
        await bulkPutInvitationsToIDDB(
          [invitationFromAPI],
          invitationFromAPI.referenceId,
          invitationFromAPI.desktopId,
        );
      }

      return invitationFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const { entity: invitation, loading } =
    useSingleEntityRepository<AccountInvitationApiType>({
      fetchFunction: fetchInvitation,
      iddbQuerier: () => getInvitationFromIDDB(id),
      dependencies: [id],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      invitation,
      loading,
      fetchInvitation,
    }),
    [invitation, loading, fetchInvitation],
  );
};
