import React, { FC, useCallback } from 'react';
import { EditUserProfileFormValues } from '../../User.types';
import {
  useRevokeEmailChangeMutation,
  useUpdateAccountMutation,
} from '../../User.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { UserTranslation } from '../../i18n';
import { EditUserProfileForm } from './EditUserProfile.form';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import { useConfirm } from '../../../../shared/components/Modal/ConfirmModal';
import { useIntl } from 'react-intl';

export const EditUserProfile: FC<{
  onRequestClose: () => void;
}> = ({ onRequestClose }) => {
  const { formatMessage } = useIntl();
  const { askConfirmation } = useConfirm();
  const { account } = useCurrentWorkspaceAccount();
  const { refetchWorkspaces } = useCurrentWorkspace();
  const [updateAccountMutation] = useUpdateAccountMutation();
  const [revokeEmailChangeMutation] = useRevokeEmailChangeMutation();

  const onDone = useCallback(() => {
    if (refetchWorkspaces) {
      refetchWorkspaces();
    }
    if (onRequestClose) {
      onRequestClose();
    }
    showToastSuccessMessage(UserTranslation.formSuccessMessage);
  }, [onRequestClose, refetchWorkspaces]);

  const handleSubmit = useCallback(
    (formValues: EditUserProfileFormValues) => {
      const {
        image,
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        companyUrl,
        timezone,
      } = formValues;

      return updateAccountMutation({
        variables: {
          input: {
            id: account!.id,
            firstName,
            lastName,
            email,
            phoneNumber: phoneNumber || '',
            image: image || null,
            companyName,
            companyUrl,
            timezone,
          },
        },
      })
        .then(onDone)
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [updateAccountMutation, account, onDone],
  );

  const handleRevokeEmailChange = useCallback(() => {
    askConfirmation(
      formatMessage({
        id: UserTranslation.revokeEmailChangeConfirm,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      revokeEmailChangeMutation({
        variables: {
          input: {
            id: account.id,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(UserTranslation.revokeEmailChangeSuccess);
          if (refetchWorkspaces) {
            refetchWorkspaces();
          }
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    });
  }, [
    askConfirmation,
    formatMessage,
    revokeEmailChangeMutation,
    account,
    refetchWorkspaces,
  ]);

  return (
    <EditUserProfileForm
      account={account}
      onSubmit={handleSubmit}
      onRevokeEmailChange={handleRevokeEmailChange}
      onCancel={onRequestClose}
    />
  );
};
