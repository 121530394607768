import styled from 'styled-components';
import {
  ModalContent,
  ModalHeaderTitle,
} from '../../../../shared/components/Modal';

export const ImportToDesktopModalTitle = styled(ModalHeaderTitle)`
  padding: 2rem 2rem 1rem;
  font-size: 1.5rem;
  text-align: center;
`;

export const ImportToDesktopModalContent = styled(ModalContent)`
  text-align: center;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 2.5rem 4.625rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
