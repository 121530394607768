import React, { FC, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../../i18n';
import { StyledCreateVaultItemModal } from './CreateVaultItemModal.styled';
import { TabPanel } from 'react-tabs';
import {
  StyledTab,
  StyledTabList,
  StyledTabs,
  TabsType,
} from '../../../../../shared/components/Tabs';
import { CreateVaultItem } from '../CreateVaultItem';
import { ImportPasswords } from '../../../ImportPasswords';

interface CreateVaultItemModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose' | 'width'> {
  appId?: string;
  allowManualPasswordCreation?: boolean;
  selectForCustomAppIdOnCreate?: string;
}

export const CreateVaultItemModal: FC<CreateVaultItemModalProps> = ({
  appId,
  allowManualPasswordCreation,
  selectForCustomAppIdOnCreate,
  ...modalProps
}) => {
  const [selectedTab, selectTab] = useState(0);
  return (
    <Modal visible={true} width={486} topAligned={true} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage id={EncryptionTranslation.createVaultModalHeader} />
      </ModalHeader>
      <StyledCreateVaultItemModal>
        <StyledTabs
          selectedIndex={selectedTab}
          onSelect={index => selectTab(index)}>
          {allowManualPasswordCreation && (
            <StyledTabList type={TabsType.tabs} data-testid="tabs">
              <StyledTab data-testid="tab-manual">
                <FormattedMessage
                  id={EncryptionTranslation.createVaultModalTabManual}
                />
              </StyledTab>
              <StyledTab data-testid="tab-group">
                <FormattedMessage
                  id={EncryptionTranslation.createVaultModalTabGroup}
                />
              </StyledTab>
              <StyledTab data-testid="tab-import">
                <FormattedMessage
                  id={EncryptionTranslation.createVaultModalTabImport}
                />
              </StyledTab>
            </StyledTabList>
          )}
          <TabPanel>
            <CreateVaultItem
              appId={appId}
              displayImageField={allowManualPasswordCreation}
              displayUrlField={allowManualPasswordCreation}
              onDone={modalProps.onRequestClose}
            />
          </TabPanel>
          <TabPanel>
            <CreateVaultItem
              displayGroupSelect
              onDone={modalProps.onRequestClose}
            />
          </TabPanel>
          <TabPanel>
            <ImportPasswords onDone={modalProps.onRequestClose} />
          </TabPanel>
        </StyledTabs>
      </StyledCreateVaultItemModal>
    </Modal>
  );
};
