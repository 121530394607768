import React from 'react';
import { DesktopTab } from '../Desktop.constants';

export interface CopyPasteSectionDataContextType {
  copyLinkSectionData?: string | null;
  setCopyLinkSectionData?: (copyLinkSectionData: string) => void;
  clearCopyLinkSectionData: () => void;
  showPasteLinkSectionButton?: () => void;
  showPasteAppSectionButton?: () => void;
  handlePasteClipboardDesktopSectionData: (
    section: DesktopTab.links | DesktopTab.apps,
  ) => void;
  handleCopyLinksDesktopSection?: (linkIds?: string[] | null) => void;
  handleCopyAppsDesktopSection?: () => void;
}

export const CopyPasteSectionDataContext =
  React.createContext<CopyPasteSectionDataContextType>({
    copyLinkSectionData: null,
    setCopyLinkSectionData: () => {},
    clearCopyLinkSectionData: () => {},
    showPasteLinkSectionButton: () => {},
    showPasteAppSectionButton: () => {},
    handlePasteClipboardDesktopSectionData: () => {},
    handleCopyLinksDesktopSection: () => {},
    handleCopyAppsDesktopSection: () => {},
  },
);
