import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { INITIAL_SIDEBAR_OPEN_KEY } from '../Desktop.constants';
import { DesktopSidebarContext } from './DesktopSidebar.context';
import { DesktopSidebarIdentifier } from './DesktopSidebar.types';

export const DesktopSidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sidebarItem, setSidebarItem] =
    useState<DesktopSidebarIdentifier | null>(null);

  const automaticMembersRef = useRef(true);

  const toggleIdentifier = useCallback(
    (identifier: DesktopSidebarIdentifier | null) => {
      automaticMembersRef.current = false;
      setSidebarItem(prevIdentifier => {
        if (prevIdentifier === identifier || !identifier) {
          localStorage.removeItem(INITIAL_SIDEBAR_OPEN_KEY);
        } else {
          localStorage.setItem(INITIAL_SIDEBAR_OPEN_KEY, identifier);
        }

        return prevIdentifier === identifier ? null : identifier;
      });
    },
    [],
  );

  const desktopSidebarValue = useMemo(
    () => ({
      identifier: sidebarItem,
      setIdentifier: toggleIdentifier,
    }),
    [sidebarItem, toggleIdentifier],
  );

  /**
   * set initial state of sidebar
   */
  useEffect(() => {
    const sidebarState = localStorage.getItem(INITIAL_SIDEBAR_OPEN_KEY);
    if (sidebarState) {
      setSidebarItem(sidebarState as DesktopSidebarIdentifier);
    }
  }, []);

  return (
    <DesktopSidebarContext.Provider value={desktopSidebarValue}>
      {children}
    </DesktopSidebarContext.Provider>
  );
};
