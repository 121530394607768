import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  LearnMoreLink,
  ProUpgrade,
  ProUpgradeDescription,
  ProUpgradeTitle,
  UpgradeConfirmationControls,
} from './UpgradeProAccount.styled';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { BillingTranslation } from '../i18n';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useUpgradeWorkspaceMutation,
} from '../../Workspace/Workspace.hooks';
import { PaymentPlan } from '../Billing.types';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { SpinnerFullscreen } from '../../../shared/components/SpinnerFullscreen';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { useGetRepeatedPaymentCustomerByWorkspaceQuery } from '../Billing.hooks';
import { useConfirm } from '../../../shared/components/Modal/ConfirmModal';
import { PAYMENT_PLAN_COST } from '../Billing.constants';
import { appEnv } from '../../../appEnv';

export const UpgradeProAccount: FC = () => {
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { account } = useCurrentAccount();
  const [upgradeWorkspaceMutation] = useUpgradeWorkspaceMutation();
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const { askConfirmation } = useConfirm();
  const intl = useIntl();

  const {
    data,
    loading: paymentCustomerLoading,
    refetch: refetchPaymentCustomer,
  } = useGetRepeatedPaymentCustomerByWorkspaceQuery({
    variables: {
      id: workspace.id,
    },
    fetchPolicy: 'no-cache',
  });

  const paymentCustomer = data?.workspace?.paymentCustomer;

  const askUpgradeConfirmation = useCallback(
    () =>
      askConfirmation(
        intl.formatMessage({
          id: BillingTranslation.upgradeConfirmation,
        }),
        intl.formatMessage({
          id: BillingTranslation.upgradeConfirmationTitle,
        }),
        {
          width: 360,
          customControls: (onConfirm, onCancel) => (
            <UpgradeConfirmationControls>
              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}
                data-testid="enter-new-details">
                <FormattedMessage
                  id={BillingTranslation.upgradeEnterNewDetailsButton}
                />
              </Button>
              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={onConfirm}
                data-testid="continue-upgrade">
                <FormattedMessage
                  id={BillingTranslation.upgradeContinueButton}
                />
              </Button>
            </UpgradeConfirmationControls>
          ),
        },
      ),
    [askConfirmation, intl],
  );

  const handleCtaClick = useCallback(() => {
    const upgradeToPro = (hasPaymentCustomer: boolean) => {
      setIsUpgrading(true);
      return upgradeWorkspaceMutation({
        variables: {
          input: {
            id: workspace.id,
            newType: PaymentPlan.PRO,
            reuseOldBilling: hasPaymentCustomer,
          },
        },
      })
        .then(res => {
          setIsUpgrading(false);
          if (!res.data) {
            return;
          }
          if (hasPaymentCustomer) {
            showToastSuccessMessage(BillingTranslation.upgradeAccountSuccess);
          }
          refetchWorkspaces({
            id: account!.identityId,
          });
          refetchPermissions();
          refetchPaymentCustomer();
        })
        .catch(e => {
          setIsUpgrading(false);
          showToastGraphQLErrors(e.graphQLErrors);
        });
    };

    if (paymentCustomer) {
      askUpgradeConfirmation().then(confirm => {
        if (confirm === null) return;
        upgradeToPro(confirm);
      });
    } else {
      upgradeToPro(!!paymentCustomer);
    }
  }, [
    paymentCustomer,
    upgradeWorkspaceMutation,
    workspace.id,
    refetchWorkspaces,
    account,
    refetchPermissions,
    refetchPaymentCustomer,
    askUpgradeConfirmation,
  ]);

  return (
    <ProUpgrade data-testid="pro-upgrade-panel">
      <ProUpgradeTitle data-testid="title">
        <FormattedHTMLMessage
          id={BillingTranslation.upgradeToProTitle}
          values={{ cost: PAYMENT_PLAN_COST[PaymentPlan.PRO] }}
        />
      </ProUpgradeTitle>
      <ProUpgradeDescription data-testid="description">
        <FormattedMessage id={BillingTranslation.upgradeToProDescription} />
      </ProUpgradeDescription>

      <Button
        disabled={paymentCustomerLoading}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        onClick={handleCtaClick}
        data-testid="upgrade-button">
        <FormattedMessage id={BillingTranslation.upgradeToProButtonLabel} />
      </Button>

      <LearnMoreLink href={appEnv.PRICING_URL}>
        <FormattedMessage id={BillingTranslation.seeAllFeaturesLinkText} />
      </LearnMoreLink>

      {isUpgrading && <SpinnerFullscreen />}
    </ProUpgrade>
  );
};
