import styled from 'styled-components';
import { SegmentNavigator } from '../../../Segment/SegmentNavigator/SegmentNavigator';

export const PasswordsSegmentNavigator = styled(SegmentNavigator)`
  .segment-navigator-container {
    padding: ${({ theme }) => theme.spacing.xs};
    padding-right: unset;
  }
`;

export const MasterPasswordLockScreenContaner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
`;
