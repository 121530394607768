import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { sortByAccountSort } from '../../../../../shared/utils/list.utils';
import { cloneObject, SortableArea } from '../../../../Drag';
import { AccountWithWorkspacesApiType } from '../../../../User/User.types';
import { useUpdateWorkspaceSortMutation } from '../../../Workspace.hooks';
import { WorkspaceApiType } from '../../../Workspace.types';
import { WorkspacesListItem } from './WorkspacesListItem';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { DRAG_ACTIVATION_DISTANCE } from '../../../../Drag/Drag.constants';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { WorkspaceAccountGroupIdentity } from '../../../../User/User.constants';
import { getLongId, getShortId } from '../../../../../shared/utils/id';

type WorkspacesListProps = {
  identityAccounts: AccountWithWorkspacesApiType[];
};

export const WorkspacesList: FC<WorkspacesListProps> = ({
  identityAccounts,
}) => {
  const [workspacesWithSort, setWorkspacesWithSort] = useState<
    WorkspaceApiType[]
  >([]);

  const identityWorkspaces = useMemo(
    () =>
      identityAccounts.map(account => extractNodes(account.workspaces)).flat(),
    [identityAccounts],
  );

  const workspaces = useMemo(
    () => identityWorkspaces.map(({ workspace }) => workspace),
    [identityWorkspaces],
  );

  // TODO: rework after transitioning workspaces to IndexedDb approach
  const { account: authenticatedAccount } = useCurrentAccount();
  const workspaceRoleMap = useMemo(() => {
    return (
      authenticatedAccount?.workspaceInfo?.reduce(
        (acc, curr) => ({
          ...acc,
          [getLongId('workspaces', curr.id)]: curr.role,
        }),
        {} as Record<string, WorkspaceAccountGroupIdentity>,
      ) || {}
    );
  }, [authenticatedAccount]);

  useEffect(() => {
    setWorkspacesWithSort(workspaces.map(cloneObject));
  }, [workspaces]);

  const sortedWorkspaces = useMemo(
    () => workspacesWithSort.sort(sortByAccountSort),
    [workspacesWithSort],
  );

  const dndSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: DRAG_ACTIVATION_DISTANCE,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [updateWorkspaceSortMutation] = useUpdateWorkspaceSortMutation();
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over?.id && active.id !== over.id) {
        // update new position in UI
        const oldIndex = workspacesWithSort.findIndex(
          workspace => getShortId(workspace.id) === active.id,
        );
        const newIndex = workspacesWithSort.findIndex(
          workspace => getShortId(workspace.id) === over.id,
        );

        const workspacesCurrentOrder = workspacesWithSort.map(workspace =>
          getShortId(workspace.id),
        );

        const newSortOrderById = arrayMove(
          workspacesCurrentOrder,
          oldIndex,
          newIndex,
        );

        const workspacesNewSorted = workspacesWithSort.map(workspace => ({
          ...workspace,
          accountSortPosition: newSortOrderById.findIndex(
            id => getShortId(workspace.id) === id,
          ),
        }));

        setWorkspacesWithSort(workspacesNewSorted);

        // Update new positon on server
        updateWorkspaceSortMutation({
          variables: {
            input: {
              workspaces: workspacesNewSorted.map(workspace => ({
                id: workspace.id,
                position: workspace.accountSortPosition,
              })),
            },
          },
        }).catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
          setWorkspacesWithSort(workspaces.map(cloneObject));
        });
      }
    },
    [updateWorkspaceSortMutation, workspaces, workspacesWithSort],
  );

  return (
    <DndContext
      sensors={dndSensors}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={handleDragEnd}>
      <SortableArea>
        <SortableContext
          items={sortedWorkspaces.map(workspace => getShortId(workspace.id))}
          strategy={verticalListSortingStrategy}>
          {sortedWorkspaces.map(workspace => (
            <WorkspacesListItem
              key={getShortId(workspace.id)}
              workspace={workspace}
              role={workspaceRoleMap[workspace.id]}
            />
          ))}
        </SortableContext>
      </SortableArea>
    </DndContext>
  );
};
