import React, { FC } from 'react';

interface PaymentMethodAccountProps {
  digits: number | string;
}

// TODO: This should render differntly based on credit card vs paypal
export const PaymentMethodAccount: FC<PaymentMethodAccountProps> = ({
  digits,
}) => {
  return <>{`XXXX XXXX XXXX ${digits}`}</>;
};
