import { useCallback, useMemo, useState } from 'react';
import { extractNodes } from '../../../../shared/api/api.utils';
import { useQueryParams } from '../../../../shared/hooks';
import { ContentLayout, DESKTOP_APPS_LAYOUT_KEY } from '../../../ContentLayout';
import {
  useCurrentDesktop,
  useFilterDesktopAppsQuery,
} from '../../Desktop.hooks';
import { useCurrentTypeOfPage } from '../../Desktop.utils';
import { database } from '../../../Database';
import { useLiveQuery } from 'dexie-react-hooks';

export const AppsContainerViewModel = () => {
  // hooks
  const desktop = useCurrentDesktop();
  const queryParams = useQueryParams();

  // states
  const [layout, setLayout] = useState<ContentLayout>(() => {
    return (
      (localStorage.getItem(DESKTOP_APPS_LAYOUT_KEY) as ContentLayout) ||
      ContentLayout.GRID
    );
  });

  const { desktopFilter } = queryParams;

  const {
    isNormalDesktop,
    isFavoritesDesktop,
    isSearching,
    isSearchingDesktop,
    isCompanyDesktop,
    isAdminPage,
  } = useCurrentTypeOfPage();

  const updateLayout = useCallback(
    (newLayout: ContentLayout): void => {
      setLayout(newLayout);
      localStorage.setItem(DESKTOP_APPS_LAYOUT_KEY, newLayout.toString());
    },
    [setLayout],
  );
  const appsData = useLiveQuery(
    () => {
      return database.desktopApps
        .where('desktopId')
        .equals(desktop?.id ?? '')
        .sortBy('createdAt');
    },
    [desktop?.id],
    [],
  );

  // TODO: @bqk - use data source
  const { data: filteredAppsData, loading: filterAppsLoading } =
    useFilterDesktopAppsQuery({
      skip: !isSearching || !isSearchingDesktop,
      variables: {
        desktop: desktop?.id as string,
        filterFreeTextSearch: desktopFilter as string,
      },
      fetchPolicy: 'no-cache',
    });

  const loading = filterAppsLoading;

  const apps = useMemo(() => {
    return isNormalDesktop ||
      isCompanyDesktop ||
      (isFavoritesDesktop && !isSearching)
      ? filteredAppsData
        ? extractNodes(filteredAppsData.desktopApps)
        : appsData.map(app => app.sourceData)
      : [];
  }, [
    isNormalDesktop,
    isCompanyDesktop,
    isFavoritesDesktop,
    isSearching,
    filteredAppsData,
    appsData,
  ]);

  const desktopName = desktop?.name || '';

  return {
    isAdminPage,
    isNormalDesktop,
    desktopName,
    desktopFilter,
    loading,
    apps,
    layout,
    updateLayout,
  };
};
