import styled from 'styled-components';

export const TeamField = styled.div`
  & ~ & {
    margin-top: 16px;
  }
`;

export const TeamFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 6px;
  }
`;
