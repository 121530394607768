import React, {
  FC,
  PropsWithChildren,
  Reducer,
  useCallback,
  useReducer,
} from 'react';
import { SelectedLinksMap } from './DesktopItemMultiselect.types';
import { DesktopItemMultiselectContext } from './DesktopItemMultiselect.context';

export const DesktopItemMultiselectProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [selectedLinkMap, updateSelectedLinkMap] = useReducer<
    Reducer<
      SelectedLinksMap,
      {
        type: 'toggle' | 'selectMultiple' | 'unselectAll';
        payload: {
          desktopId: string;
          linkId?: string | string[];
        };
      }
    >
  >((state, action) => {
    const { type, payload } = action;
    const { desktopId, linkId } = payload;

    if (type === 'toggle' && linkId) {
      const {
        [linkId as string]: isCurrentLinkSelected,
        ...currentDesktopOtherLinksMap
      } = state[desktopId] || {};
      return {
        ...state,
        [desktopId]: {
          ...currentDesktopOtherLinksMap,
          ...(isCurrentLinkSelected ? {} : { [linkId as string]: true }),
        },
      };
    }

    if (type === 'selectMultiple') {
      return {
        ...state,
        [desktopId]: {
          ...(state[desktopId] || {}),
          ...(linkId as string[]).reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: true,
            }),
            {},
          ),
        },
      };
    }

    if (type === 'unselectAll') {
      return {
        ...state,
        [desktopId]: {},
      };
    }

    return state;
  }, {});

  const toggleSelectedLink = useCallback(
    (desktopId: string, linkId: string) => {
      updateSelectedLinkMap({
        type: 'toggle',
        payload: {
          desktopId,
          linkId,
        },
      });
    },
    [],
  );

  const selectMultipleDesktopLinks = useCallback(
    (desktopId: string, linkIds: string[]) => {
      updateSelectedLinkMap({
        type: 'selectMultiple',
        payload: {
          desktopId,
          linkId: linkIds,
        },
      });
    },
    [],
  );

  const unselectAllDesktopLinks = useCallback((desktopId: string) => {
    updateSelectedLinkMap({
      type: 'unselectAll',
      payload: {
        desktopId,
      },
    });
  }, []);

  return (
    <DesktopItemMultiselectContext.Provider
      value={{
        selectedLinkMap,
        toggleSelectedLink,
        selectMultipleDesktopLinks,
        unselectAllDesktopLinks,
      }}>
      {children}
    </DesktopItemMultiselectContext.Provider>
  );
};
