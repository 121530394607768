import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../shared/components/Modal';
import { useCurrentConversation } from '../Chat.hooks';
import { ChatTranslation } from '../i18n';
import { ManageUsersModalBody } from './ManageUsersModal.styled';
import { ConversationUsers } from '../CreateConversation/ConversationUsers';

export const ManageUsersModal: FC<ModalProps> = ({
  onRequestClose,
  ...props
}) => {
  const { conversation } = useCurrentConversation();

  useEffect(() => {
    return () => {
      onRequestClose();
    };
  }, [onRequestClose]);

  if (!conversation) {
    return null;
  }

  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={ChatTranslation.manageUsersModalTitle} />
      </ModalHeader>
      <ManageUsersModalBody>
        <ConversationUsers
          chatConversation={conversation}
          onCancel={onRequestClose}
          onDone={onRequestClose}
        />
      </ManageUsersModalBody>
    </Modal>
  );
};
