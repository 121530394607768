import React, { FC } from 'react';
import Select, { MenuPlacement } from 'react-select';
import { useTheme } from 'styled-components';
import { FieldProps } from 'formik';
import { timezoneStringToReactSelectValue } from '../../../domains/TimeDate/TimeDate.utils';
import { TimezoneType } from '../../../domains/TimeDate/TimeDate.types';

export enum ReactSelectMenuPlacement {
  AUTO = 'auto',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const ReactSelect: FC<
  FieldProps & {
    id?: string;
    onChange: (value: string) => void;
    options: TimezoneType[];
    onBlur?: () => void;
    menuPlacement?: MenuPlacement;
    placeholder?: string;
  }
> = props => {
  const {
    onChange,
    options,
    onBlur,
    field: { name, value },
    menuPlacement = ReactSelectMenuPlacement.AUTO,
    placeholder = '',
    id = undefined,
  } = props;
  const theme = useTheme();

  const val =
    typeof value === 'string' ? timezoneStringToReactSelectValue(value) : value;

  return (
    <Select
      aria-labelledby={id}
      name={name}
      onBlur={onBlur || props.field.onBlur}
      value={val}
      onChange={value => onChange(value)}
      options={options}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      styles={{
        singleValue: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceHighEmphasis,
          fontSize: '0.875rem',
        }),
        menuList: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceMediumEmphasis,
          fontSize: '0.875rem',
          backgroundColor: theme.colors.Surface,
          borderRadius: '4px',
          border: '1px solid',
          borderColor: theme.colors.BorderDefault,
        }),
        indicatorSeparator: styles => ({
          ...styles,
          display: 'none',
        }),
        dropdownIndicator: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceLightEmphasis,
          ':hover': {
            color: theme.colors.OnSurfaceLightEmphasis,
          },
        }),
        control: styles => ({
          ...styles,
          height: '41px',
          backgroundColor: theme.colors.Surface,
          borderColor: theme.colors.BorderDefault,
          ':hover': {
            borderColor: theme.colors.BorderDefault,
          },
        }),
        // TODO: fix problem with types
        // @ts-ignore
        option: (styles, { isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected
              ? theme.colors.OnSurfaceSelected
              : isFocused
              ? theme.colors.OnSurfacePressed
              : null,
            color: isSelected
              ? theme.colors.OnSurfaceHighEmphasis
              : theme.colors.OnSurfaceHighEmphasis,

            ':active': {
              ...styles[':active'],
              backgroundColor: theme.colors.OnSurfaceLightEmphasis,
            },
          };
        },
      }}
    />
  );
};
