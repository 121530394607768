import React, { FC, useCallback, useMemo, useState } from 'react';
import IframePlayer from './VideoPlayer.iframe';
import { FormattedMessage, useIntl } from 'react-intl';
import { PreviewPlayIcon } from '../../shared/icons/';
import { VideoPlayerTranslation } from './i18n';
import {
  StyledPlayButton,
  StyledPlayerWrapperImage,
  StyledPlayWrapper,
  StyledProviderContent,
  StyledProviderDesctiprion,
  StyledProviderTitle,
  StyledProviderType,
  StyledVideoPreviewInner,
} from './VideoPlayer.styled';
import { StyledVideoPreviewOuter } from '../Link/LinksView/Link/Link.styled';
import { useVideoProviderIconUrl, VideoProvider } from './VideoPlayer.hooks';
import { LinkApiType } from '../Link/Link.types';
import {
  getLinkMetaType,
  linkHasMetaEmbedLink,
} from '../Preview/DocumentPreview';
import { getLinkTitle } from '../Link/Link.utils';

interface VideoPlayerExternalProps {
  link: LinkApiType;
}

export const VideoPlayerExternal: FC<VideoPlayerExternalProps> = ({ link }) => {
  const [renderPreview, setRenderPreview] = useState(false);
  const intl = useIntl();

  const externalType = useMemo(() => getLinkMetaType(link), [link]);
  const linkTitle = useMemo(() => getLinkTitle(link), [link]);

  const providerIconSrc = useVideoProviderIconUrl(
    externalType as VideoProvider,
  );

  const previewImageSrc = link.data.image?.contentUrl ?? '';

  const videoSource = useMemo(() => linkHasMetaEmbedLink(link), [link]);

  const handleClick = useCallback(() => {
    setRenderPreview(!renderPreview);
  }, [renderPreview]);

  const translationID = useMemo(() => {
    switch (externalType) {
      case VideoProvider.vimeo:
        return VideoPlayerTranslation.vimeo;
      case VideoProvider.youtube:
        return VideoPlayerTranslation.youtube;
      default:
        return VideoPlayerTranslation.externalAlt;
    }
  }, [externalType]);

  if (!link || !videoSource) {
    return null;
  }

  return (
    <StyledVideoPreviewOuter data-testid="external-video-preview-contaier">
      <StyledProviderContent>
        <StyledProviderType>
          <img
            src={providerIconSrc}
            alt={intl.formatMessage({
              id: translationID,
            })}
          />
          <FormattedMessage id={translationID} />
        </StyledProviderType>
        <StyledProviderTitle>{linkTitle}</StyledProviderTitle>
        <StyledProviderDesctiprion>
          {link.data.description}
        </StyledProviderDesctiprion>
      </StyledProviderContent>
      <StyledVideoPreviewInner>
        {!renderPreview && (
          <StyledPlayWrapper>
            <StyledPlayerWrapperImage
              src={previewImageSrc}
              alt={linkTitle}
              onClick={handleClick}
              withPadding={true}
            />

            <StyledPlayButton
              icon={PreviewPlayIcon}
              aria-label={intl.formatMessage({
                id: VideoPlayerTranslation.playerPlayButton,
              })}
              onClick={handleClick}
              data-testid="preview-play-external-button"
            />
          </StyledPlayWrapper>
        )}
        {renderPreview && <IframePlayer source={videoSource} autoPlay={true} />}
      </StyledVideoPreviewInner>
    </StyledVideoPreviewOuter>
  );
};
