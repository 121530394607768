import { getLongId } from '../../../../../shared/utils/id';

export const addTeamToDesktopRelatedTeams = (
  relatedTeamIds: string[] = [],
  newTeamId: string,
) => {
  return Array.from(
    new Set([...relatedTeamIds, getLongId('workspace-teams', newTeamId)]),
  );
};
