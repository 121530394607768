import React, { FC, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppSsoGroupApiType,
  SsoConfigurationInputType,
} from '../../../../AppStore.types';
import {
  BreadcrumbsSeparator,
  HeaderTitle,
  HeaderTitleContainer,
} from '../../../AppStore/components/Header';
import { AppStoreTranslation } from '../../../../i18n';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { extractNodes } from '../../../../../../shared/api/api.utils';
import {
  useCurrentWorkspace,
  useWorkspaceLastOpenedDesktop,
} from '../../../../../Workspace/Workspace.hooks';
import {
  getImageSrcSet,
  getQueryParamsFrom,
} from '../../../../../../shared/utils/url.utils';
import { useMobile, useQueryParams } from '../../../../../../shared/hooks';
import {
  ConfigurationBody,
  Header,
  ToggleButton,
} from './ConfigurationPage.styled';
import {
  ConfigureSsoForm,
  ConfigureSsoFormSubmitHandler,
  ConfigureSsoFormValues,
} from './ConfigureSsoForm';
import {
  useCreateSsoConfiguration,
  useDeleteSsoConfiguration,
  useUpdateSsoConfiguration,
} from '../../../../AppStore.hooks';
import { GET_ALL_DESKTOPS_APPS } from '../../../../../Desktop/Desktop.queries';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import {
  GET_APP_SSO_GROUPS,
  GetAppSsoGroupsResponse,
  GetAppSsoGroupsVariables,
} from '../../../../AppStore.queries';
import { ConfigurationTutorial } from './ConfigurationTutorial';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderSeparator,
  AdminHeaderTitle,
  DangerZoneButton,
  DangerZoneButtonMode,
  DrawerHeader,
} from '../../../../../Admin';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { checkIfSsoCanBeToggled } from './Configuration.utils';
import { getWorkspaceAdminCloseLink } from '../../../../../Workspace/Workspace.utils';
import { getFilterParam } from '../../SsoAppsGrid.utils';
import {
  AppImage,
  AppImageWrap,
  AppName,
} from '../../../../../App/Views/AppLink/AppLink.styled';

const convertFormValues = (
  formValues: ConfigureSsoFormValues,
): SsoConfigurationInputType[] =>
  Object.keys(formValues).map(field => ({
    field,
    value: formValues[field],
  }));

interface ConfigurationPageProps {
  ssoAppGroup: AppSsoGroupApiType;
  adminView?: boolean;
}

export const ConfigurationPage: FC<ConfigurationPageProps> = ({
  ssoAppGroup,
  adminView = false,
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();

  const filter = getFilterParam(queryParams);

  const { lastOpenedDesktop } = useWorkspaceLastOpenedDesktop();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const { askConfirmation } = useConfirm();
  const intl = useIntl();

  const workspaceConfig = useMemo(
    () =>
      extractNodes(ssoAppGroup.workspaceConfigs).find(
        ({ workspace }) => workspace.id === currentWorkspace.id,
      ),
    [ssoAppGroup, currentWorkspace.id],
  );

  const compatibleApps = ssoAppGroup.compatibleApps;

  const [createSsoConfigurationMutation] = useCreateSsoConfiguration();
  const [updateSsoConfigurationMutation] = useUpdateSsoConfiguration();
  const saveSsoConfig: ConfigureSsoFormSubmitHandler = useCallback(
    (formValues, actions) => {
      workspaceConfig
        ? updateSsoConfigurationMutation({
            variables: {
              input: {
                id: workspaceConfig.id,
                fieldValues: convertFormValues(formValues),
              },
            },
            refetchQueries: [
              {
                query: GET_ALL_DESKTOPS_APPS,
                variables: {
                  workspaceId: currentWorkspace.id,
                },
              },
            ],
          })
            .then(() => {
              showToastSuccessMessage(
                AppStoreTranslation.ssoAppMessagesConfigurationUpdated,
              );
              actions.setSubmitting(false);
            })
            .catch(e => {
              actions.setSubmitting(false);
              showToastGraphQLErrors(e.graphQLErrors);
            })
        : createSsoConfigurationMutation({
            variables: {
              input: {
                workspace: currentWorkspace.id,
                ssoGroup: ssoAppGroup.id,
                fieldValues: convertFormValues(formValues),
              },
            },
            update: (proxy, { data }) => {
              if (!data) {
                return;
              }

              try {
                const ssoAppsCache = proxy.readQuery<
                  GetAppSsoGroupsResponse,
                  GetAppSsoGroupsVariables
                >({
                  query: GET_APP_SSO_GROUPS,
                  variables: {
                    workspace: currentWorkspace.id,
                    name: filter,
                  },
                });
                if (!ssoAppsCache) {
                  return;
                }

                const item = {
                  __typename: 'AppSsoGroupEdge',
                  node: {
                    ...ssoAppGroup,
                    workspaceConfigs: {
                      ...ssoAppGroup.workspaceConfigs,
                      edges: [
                        ...ssoAppGroup.workspaceConfigs.edges,
                        {
                          __typename: 'WorkspaceAppConfigEdge',
                          node: data.createWorkspaceAppConfig
                            .workspaceAppConfig,
                        },
                      ],
                    },
                  },
                };

                proxy.writeQuery<
                  GetAppSsoGroupsResponse,
                  GetAppSsoGroupsVariables
                >({
                  query: GET_APP_SSO_GROUPS,
                  variables: {
                    workspace: currentWorkspace.id,
                    name: filter,
                  },
                  data: {
                    ...ssoAppsCache,
                    ssoGroupsConfigured: {
                      ...ssoAppsCache.ssoGroupsConfigured,
                      edges: item.node.custom
                        ? ssoAppsCache.ssoGroupsConfigured.edges.map(edge =>
                            edge.node.id === item.node.id ? item : edge,
                          )
                        : [...ssoAppsCache.ssoGroupsConfigured.edges, item],
                    },
                    ssoGroupsCompatible: {
                      ...ssoAppsCache.ssoGroupsCompatible,
                      edges: ssoAppsCache.ssoGroupsCompatible.edges.filter(
                        edge => edge.node.id !== item.node.id,
                      ),
                    },
                  },
                });
              } catch (e) {}
            },
          })
            .then(() => {
              showToastSuccessMessage(
                AppStoreTranslation.ssoAppMessagesConfigurationCreated,
              );
              actions.setSubmitting(false);
            })
            .catch(e => {
              actions.setSubmitting(false);
              showToastGraphQLErrors(e.graphQLErrors);
            });
    },
    [
      workspaceConfig,
      updateSsoConfigurationMutation,
      currentWorkspace.id,
      createSsoConfigurationMutation,
      ssoAppGroup,
      filter,
    ],
  );

  const [processing, setProcessing] = useState(false);

  const toggleSsoConfiguration = useCallback(
    (id: string, enabled: boolean) => {
      setProcessing(true);
      return updateSsoConfigurationMutation({
        variables: {
          input: {
            id,
            enabled,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_DESKTOPS_APPS,
            variables: {
              workspaceId: currentWorkspace.id,
            },
          },
        ],
      })
        .then(() => {
          setProcessing(false);
          showToastSuccessMessage(
            enabled
              ? AppStoreTranslation.ssoAppMessagesConfigurationEnabled
              : AppStoreTranslation.ssoAppMessagesConfigurationDisabled,
          );
        })
        .catch(e => {
          setProcessing(false);
          return showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [currentWorkspace.id, updateSsoConfigurationMutation],
  );

  const enableSso = useCallback(() => {
    if (workspaceConfig) {
      toggleSsoConfiguration(workspaceConfig.id, true);
    }
  }, [toggleSsoConfiguration, workspaceConfig]);

  const disableSso = useCallback(() => {
    if (workspaceConfig) {
      toggleSsoConfiguration(workspaceConfig.id, false);
    }
  }, [toggleSsoConfiguration, workspaceConfig]);

  const ssoEnabled = workspaceConfig?.enabled || false;

  const [deleteSsoConfigurationMutation] = useDeleteSsoConfiguration();
  const deleteSsoConfig = useCallback(() => {
    if (!workspaceConfig) {
      return;
    }

    askConfirmation(
      intl.formatMessage({
        id: AppStoreTranslation.ssoAppConfirmationConfigurationDelete,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      return deleteSsoConfigurationMutation({
        variables: {
          input: {
            id: workspaceConfig.id,
          },
        },
        update: (proxy, { data }) => {
          if (!data) {
            return;
          }

          try {
            const ssoAppsCache = proxy.readQuery<
              GetAppSsoGroupsResponse,
              GetAppSsoGroupsVariables
            >({
              query: GET_APP_SSO_GROUPS,
              variables: {
                workspace: currentWorkspace.id,
                name: filter,
              },
            });
            if (!ssoAppsCache) {
              return;
            }

            const item = {
              __typename: 'AppSsoGroupEdge',
              node: {
                ...ssoAppGroup,
                workspaceConfigs: {
                  ...ssoAppGroup.workspaceConfigs,
                  edges: ssoAppGroup.workspaceConfigs.edges.filter(
                    ({ node }) =>
                      node.id !==
                      data.deleteWorkspaceAppConfig.workspaceAppConfig.id,
                  ),
                },
              },
            };

            proxy.writeQuery<GetAppSsoGroupsResponse, GetAppSsoGroupsVariables>(
              {
                query: GET_APP_SSO_GROUPS,
                variables: {
                  workspace: currentWorkspace.id,
                  name: filter,
                },
                data: {
                  ...ssoAppsCache,
                  ssoGroupsConfigured: {
                    ...ssoAppsCache.ssoGroupsConfigured,
                    edges: item.node.custom
                      ? ssoAppsCache.ssoGroupsConfigured.edges.map(edge =>
                          edge.node.id === item.node.id ? item : edge,
                        )
                      : ssoAppsCache.ssoGroupsConfigured.edges.filter(
                          edge => edge.node.id !== item.node.id,
                        ),
                  },
                  ssoGroupsCompatible: {
                    ...ssoAppsCache.ssoGroupsCompatible,
                    edges: item.node.custom
                      ? ssoAppsCache.ssoGroupsCompatible.edges
                      : [...ssoAppsCache.ssoGroupsCompatible.edges, item],
                  },
                },
              },
            );
          } catch (e) {}
        },
        refetchQueries: [
          {
            query: GET_ALL_DESKTOPS_APPS,
            variables: {
              workspaceId: currentWorkspace.id,
            },
          },
        ],
      })
        .then(() => {
          showToastSuccessMessage(
            AppStoreTranslation.ssoAppMessagesConfigurationDeleted,
          );
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    });
  }, [
    workspaceConfig,
    askConfirmation,
    intl,
    deleteSsoConfigurationMutation,
    currentWorkspace.id,
    filter,
    ssoAppGroup,
  ]);

  const ssoCanBeToggled = useMemo(
    () =>
      checkIfSsoCanBeToggled(
        ssoAppGroup.ssoConfigurationFields,
        workspaceConfig,
      ),
    [ssoAppGroup.ssoConfigurationFields, workspaceConfig],
  );

  return (
    <>
      {adminView ? (
        <>
          {isMobile && (
            <DrawerHeader
              data-testid="heading"
              onRequestClose={() =>
                navigate(
                  getWorkspaceAdminCloseLink(
                    currentWorkspace,
                    lastOpenedDesktop,
                  ),
                )
              }
              onBackButtonClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    ssoGroupId: undefined,
                  }),
                })
              }
              backButtonText={intl.formatMessage({
                id: AppStoreTranslation.ssoAppTabName,
              })}>
              {ssoAppGroup.name}
            </DrawerHeader>
          )}
          <AdminHeader data-testid="header">
            {!isMobile && (
              <AdminHeaderTitle>
                <Link
                  to={{
                    search: getQueryParamsFrom({
                      ...queryParams,
                      ssoGroupId: undefined,
                    }),
                  }}>
                  <FormattedMessage id={AppStoreTranslation.ssoAppGridTitle} />
                </Link>{' '}
                <AdminHeaderSeparator /> {ssoAppGroup.name}
              </AdminHeaderTitle>
            )}
            <AdminHeaderControls data-testid="controls">
              <ToggleButton
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={!ssoCanBeToggled || processing}
                danger={ssoEnabled}
                onClick={ssoEnabled ? disableSso : enableSso}>
                {ssoEnabled ? (
                  <FormattedMessage
                    id={AppStoreTranslation.ssoAppCardLabelsDisable}
                  />
                ) : (
                  <FormattedMessage
                    id={AppStoreTranslation.ssoAppCardLabelsEnable}
                  />
                )}
              </ToggleButton>
            </AdminHeaderControls>
          </AdminHeader>
        </>
      ) : (
        <Header>
          <HeaderTitleContainer>
            <Link
              to={{
                search: getQueryParamsFrom({
                  ...queryParams,
                  ssoGroupId: undefined,
                }),
              }}>
              <HeaderTitle>
                <FormattedMessage id={AppStoreTranslation.ssoAppGridTitle} />
              </HeaderTitle>
            </Link>
            <BreadcrumbsSeparator />
            <HeaderTitle>{ssoAppGroup.name}</HeaderTitle>
          </HeaderTitleContainer>
          <ToggleButton
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            disabled={!ssoCanBeToggled || processing}
            danger={ssoEnabled}
            onClick={ssoEnabled ? disableSso : enableSso}>
            {ssoEnabled ? (
              <FormattedMessage
                id={AppStoreTranslation.ssoAppCardLabelsDisable}
              />
            ) : (
              <FormattedMessage
                id={AppStoreTranslation.ssoAppCardLabelsEnable}
              />
            )}
          </ToggleButton>
        </Header>
      )}
      <ConfigurationBody>
        <div className="label">
          <FormattedMessage
            id={AppStoreTranslation.ssoAppConfigureCompatibleApps}
          />
        </div>
        <div className="apps">
          {compatibleApps.map(app => (
            <div className="app" key={app.id}>
              <AppImageWrap>
                {app.logo?.contentUrl && (
                  <AppImage
                    src={app.logo.contentUrl}
                    srcSet={getImageSrcSet(app.logo.contentUrl, 76, 48)}
                    alt={app.name}
                  />
                )}
              </AppImageWrap>
              <AppName>{app.name}</AppName>
            </div>
          ))}
        </div>
        <ConfigureSsoForm
          key={
            workspaceConfig
              ? `${workspaceConfig.id}-${workspaceConfig.enabled}`
              : 'empty-sso-form'
          }
          ssoConfigurationFields={ssoAppGroup.ssoConfigurationFields}
          customConfigurationMap={
            ssoAppGroup.custom ? ssoAppGroup.customConfigurationMap : undefined
          }
          workspaceConfig={workspaceConfig}
          onSubmit={saveSsoConfig}
        />
        {workspaceConfig ? (
          <>
            <ConfigurationTutorial tutorial={workspaceConfig.tutorial} />
            <div className="danger-zone">
              <div className="label label--danger">
                <FormattedMessage
                  id={AppStoreTranslation.ssoAppDangerZoneTitle}
                />
              </div>

              <div className="danger-zone__body">
                <DangerZoneButton
                  size={ButtonSize.sm}
                  mode={ButtonMode.secondary}
                  dangerMode={DangerZoneButtonMode.critical}
                  onClick={deleteSsoConfig}>
                  <FormattedMessage
                    id={AppStoreTranslation.ssoAppDangerZoneDeleteButton}
                  />
                </DangerZoneButton>
                <FormattedMessage
                  id={AppStoreTranslation.ssoAppDangerZoneDeleteDescription}>
                  {description => (
                    <p className="danger-zone__description">{description}</p>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </>
        ) : null}
      </ConfigurationBody>
    </>
  );
};
