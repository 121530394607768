import styled from 'styled-components';

// TODO: add proper color from theme
export const StyledAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0);
`;
