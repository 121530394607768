import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Collapse,
  DesktopListToggleButton,
  StyledDesktopList,
  StyledDesktopListContainer,
  StyledDesktopListHeader,
  StyledDesktopListTitle,
} from './DesktopList.styled';
import { CornerBottomIcon } from '../../../../../shared/icons';
import { DesktopTranslation } from '../../../i18n';
import { useUpdateDesktopSortMutation } from '../../../Desktop.hooks';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { cloneObject, DragType, SortableArea } from '../../../../Drag';
import { sortByAccountSort } from '../../../../../shared/utils/list.utils';
import { DesktopType } from '../../../Desktop.constants';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DesktopListItem } from './DesktopListItem';
import { useMobile } from '../../../../../shared/hooks';
import { getShortId } from '../../../../../shared/utils/id';
import { DesktopListItemSkeleton } from './DesktopListItemSkeleton';
import { useWorkspaceDndContext } from '../../../../Workspace/WorkspaceDndProvider/WorkspaceDnd.hooks';
import type { DesktopApiType } from '../../../data/Desktop/types/Desktop.types';

interface DesktopListProps {
  loading?: boolean;
  items: DesktopApiType[];
  allDesktops: DesktopApiType[];
  expanded: boolean;
  onExpandToggle: () => void;
  title: DesktopTranslation;
  dragType: DragType;
  emptyState?: ReactNode;
  desktopListType: DesktopType;
}

export const DesktopList: FC<DesktopListProps> = ({
  loading = false,
  items,
  allDesktops,
  expanded,
  onExpandToggle,
  title,
  emptyState,
  desktopListType,
}) => {
  const { formatMessage, formatHTMLMessage } = useIntl();
  const [desktopsWithSort, setDesktopsWithSort] = useState<DesktopApiType[]>(
    [],
  );
  const isMobile = useMobile();

  useEffect(() => {
    setDesktopsWithSort(items.map(cloneObject));
  }, [items]);

  const sortedDesktops = useMemo(
    () => desktopsWithSort.sort(sortByAccountSort),
    [desktopsWithSort],
  );

  const desktopTooltips = useMemo(() => {
    switch (desktopListType) {
      case DesktopType.INTERNAL:
        return {
          'data-html': true,
          'data-tooltip-id': 'global-tooltip',
          'data-tooltip-html': formatHTMLMessage({
            id: DesktopTranslation.tooltipInternalDesktopList,
          }) as string,
          'data-tooltip-place': TooltipPlace.right,
        };
      case DesktopType.SHARED:
        return {
          'data-tooltip-id': 'global-tooltip',
          'data-tooltip-content': formatMessage({
            id: DesktopTranslation.tooltipSharedlDesktopList,
          }),
          'data-tooltip-place': TooltipPlace.right,
        };
      case DesktopType.PRIVATE:
        return {
          'data-html': true,
          'data-tooltip-id': 'global-tooltip',
          'data-tooltip-html': formatHTMLMessage({
            id: DesktopTranslation.tooltipPrivateDesktopList,
          }) as string,
          'data-tooltip-place': TooltipPlace.right,
        };

      default:
        return null;
    }
  }, [desktopListType, formatHTMLMessage, formatMessage]);

  // dnd
  const [updateDesktopSortMutation] = useUpdateDesktopSortMutation();
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over?.id && active.id !== over.id) {
        // update new position in UI
        const oldIndex = desktopsWithSort.findIndex(
          desktop => getShortId(desktop.id) === active.id,
        );
        const newIndex = desktopsWithSort.findIndex(
          desktop => getShortId(desktop.id) === over.id,
        );

        const desktopsCurrentOrder = desktopsWithSort.map(desktop =>
          getShortId(desktop.id),
        );

        const newSortOrderById = arrayMove(
          desktopsCurrentOrder,
          oldIndex,
          newIndex,
        );

        const desktopsNewSorted = desktopsWithSort.map(desktop => ({
          ...desktop,
          accountSortPosition: newSortOrderById.findIndex(
            id => getShortId(desktop.id) === id,
          ),
        }));

        setDesktopsWithSort(desktopsNewSorted);

        // Update new position on server
        const desktops = [
          ...desktopsNewSorted.map(desktop => ({
            id: desktop.id,
            position: desktop.accountSortPosition,
          })),
          ...allDesktops
            .filter(
              desktop => !newSortOrderById.includes(getShortId(desktop.id)),
            )
            .map((desktop, i) => ({
              id: desktop.id,
              position: desktop.accountSortPosition,
            })),
        ];

        updateDesktopSortMutation({
          variables: {
            input: {
              desktops,
            },
          },
        }).catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
          setDesktopsWithSort(items.map(cloneObject));
        });
      }
    },
    [allDesktops, desktopsWithSort, items, updateDesktopSortMutation],
  );

  const { setDesktopSortedHandler } = useWorkspaceDndContext();
  useEffect(() => {
    setDesktopSortedHandler({ id: desktopListType, handler: handleDragEnd });
  }, [handleDragEnd, desktopListType]); // eslint-disable-line
  // dnd end

  return (
    <>
      <StyledDesktopListContainer>
        <StyledDesktopListHeader>
          <StyledDesktopListTitle isOpened={expanded} {...desktopTooltips}>
            <DesktopListToggleButton onClick={onExpandToggle}>
              <CornerBottomIcon />
              <FormattedMessage id={title} />
            </DesktopListToggleButton>
          </StyledDesktopListTitle>
        </StyledDesktopListHeader>

        {loading ? (
          <DesktopListItemSkeleton count={6} />
        ) : (
          <Collapse isOpened={expanded}>
            {sortedDesktops.length > 0 &&
              (isMobile ? (
                <StyledDesktopList>
                  {sortedDesktops.map(desktop => (
                    <DesktopListItem key={desktop.id} desktop={desktop} />
                  ))}
                </StyledDesktopList>
              ) : (
                <StyledDesktopList>
                  <SortableArea>
                    <SortableContext
                      id={desktopListType}
                      items={sortedDesktops.map(desktop =>
                        getShortId(desktop.id),
                      )}
                      strategy={verticalListSortingStrategy}>
                      {sortedDesktops.map(desktop => (
                        <DesktopListItem key={desktop.id} desktop={desktop} />
                      ))}
                    </SortableContext>
                  </SortableArea>
                </StyledDesktopList>
              ))}

            {!items.length && emptyState}
          </Collapse>
        )}
      </StyledDesktopListContainer>
    </>
  );
};
