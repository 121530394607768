import React, { FC, useCallback, useMemo, useRef } from 'react';
import CreatableSelect from 'react-select';
import { useTheme } from 'styled-components';
import { AccountApiType } from '../../../User/User.types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  OptionChatAvatar,
  OptionInner,
  StyledCustomOptionLabel,
} from './Downgrade.styled';
import { BillingTranslation } from '../../i18n';
import { FieldProps } from 'formik';
import _get from 'lodash/get';
import { ErrorMessage } from '../../../../shared/components/Input';
import { GlobalTranslation } from '../../../Intl/i18n';
import { ReactSelectOptionsType } from '../../../../shared/components/ReactSelect/ReactSelect.types';

interface DowngradeMembersSelectProps extends ReactSelectOptionsType {
  accounts: Map<string, AccountApiType>;
}

export const DowngradeMembersSelect: FC<
  FieldProps & DowngradeMembersSelectProps
> = ({ options, accounts, field, form }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const inputRef = useRef<any>(null);
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  const CustomOption = (props: any) => {
    const { innerProps, isDisabled, label, value } = props;
    const currentAccount = accounts.get(value);
    return !isDisabled ? (
      <OptionInner {...innerProps}>
        {currentAccount && (
          <OptionChatAvatar
            account={currentAccount}
            className="avatar"
            avatarSize="1.5rem"
            onlineStatusSize="0.6rem"
          />
        )}
        <StyledCustomOptionLabel>{label}</StyledCustomOptionLabel>
      </OptionInner>
    ) : null;
  };

  const handleKeyDown = useCallback((event: any) => {
    const { key } = event;

    switch (key) {
      case 'Enter':
        event.preventDefault();
        break;
      case 'Tab':
        event.preventDefault();
        break;
    }
  }, []);

  return (
    <div data-testid="react-select">
      <CreatableSelect
        ref={inputRef}
        components={{ Option: CustomOption }}
        onKeyDown={handleKeyDown}
        name={field.name}
        value={field.value ? field.value : ''}
        options={options}
        placeholder={formatMessage({
          id: BillingTranslation.downgradeModalInputPlaceholder,
        })}
        onChange={option => form.setFieldValue(field.name, option ?? [])}
        isMulti
        styles={{
          input: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          multiValue: styles => ({
            ...styles,
            backgroundColor: theme.colors.Background,
          }),
          multiValueLabel: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            fontWeight: 500,
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
        }}
      />
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </div>
  );
};
