import React, { FC } from 'react';
import { useCurrentConference } from '../../Conference.hooks';
import {
  ScheduledConferenceViewSection,
  ScheduledConferenceViewWrapper,
} from './ScheduledConferenceView.styled';
import { ScheduledConferenceHeader } from './ScheduledConferenceHeader';
import { ScheduledConferenceLink } from './ScheduledConferenceLink';
import { ScheduledConferenceInvitees } from './ScheduledConferenceInvitees';
import { ScheduledConferenceOrganizer } from './ScheduledConferenceOrganizer';
import { ScheduledConferenceTitleControls } from './ScheduledConferenceTitleControls';
import {
  SegmentPageTitleBar,
  TitleBarTitle,
  TitleBarControls,
} from '../../../Segment/SegmentPageTitleBar';
import { SegmentEmpty } from '../../../Segment';
import { Spinner } from '../../../../shared/components/Spinner';
import { StyledConferencesPage } from '../ConferencesPage.styled';
import { useMobile } from '../../../../shared/hooks';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';

type ScheduledConferenceViewProps = {
  currentConferenceId?: string;
};

export const ScheduledConferenceView: FC<ScheduledConferenceViewProps> = ({
  currentConferenceId,
}) => {
  const {
    scheduledConference,
    scheduledConferenceLoading,
  } = useCurrentConference();
  const isMobile = useMobile();
  const [ConferencePageRef] = useHideScrollbarWhenIdle();

  if (!scheduledConference) {
    return null;
  }

  if (!scheduledConference && scheduledConferenceLoading) {
    return (
      <SegmentEmpty>
        <Spinner />
      </SegmentEmpty>
    );
  }

  return (
    <>
      <SegmentPageTitleBar>
        <TitleBarTitle>{scheduledConference.title}</TitleBarTitle>
        <TitleBarControls>
          <ScheduledConferenceTitleControls />
        </TitleBarControls>
      </SegmentPageTitleBar>

      <StyledConferencesPage
        ref={ConferencePageRef}
        isMobile={isMobile}
        isConferenceVisible={!!currentConferenceId}>
        <ScheduledConferenceViewWrapper>
          <ScheduledConferenceViewSection>
            <ScheduledConferenceHeader />
          </ScheduledConferenceViewSection>

          <ScheduledConferenceViewSection>
            <ScheduledConferenceLink />
          </ScheduledConferenceViewSection>

          <ScheduledConferenceViewSection>
            <ScheduledConferenceInvitees />
          </ScheduledConferenceViewSection>

          <ScheduledConferenceViewSection>
            <ScheduledConferenceOrganizer />
          </ScheduledConferenceViewSection>
        </ScheduledConferenceViewWrapper>
      </StyledConferencesPage>
    </>
  );
};
