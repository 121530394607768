import { captureException } from '@sentry/react';
import { useLiveQuery } from 'dexie-react-hooks';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../../../../../shared/hooks';
import { getShortId } from '../../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { database } from '../../../../../Database';
import { CHATS_CONVERSATION_PATHNAME } from '../../../../../Desktop/Desktop.constants';
import { LinkDetailsTab } from '../../../../../Link/Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { useSearchMessageData } from '../../../../Chat.hooks';
import {
  getChatConversationIri,
  getChatMessageIri,
} from '../../../../Chat.utils';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { ChatMessageThreadSidebarContext } from './ChatMessageThreadSidebar.context';

export const ChatMessageThreadSidebarProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { linkDetailsTab } = queryParams;
  const isMobile = useMobile();

  const { thread, conversation: urlConversationId, highlight } = queryParams;
  const { workspace } = useCurrentWorkspace();
  const { setSearchMessage } = useSearchMessageData();
  const { getChatMessageById, getMessageWithAllThreads } =
    useChatMessageRepository();
  const [loading, setLoading] = useState<boolean>(true);
  const threadsApiLoading = useRef(false);
  const [disableLastCommentAt, setDisableLastCommentAt] = useState(false);
  const currentConversationWidthRef = useRef<number>(0);

  const setCurrentConversationWidth = (width: number) => {
    currentConversationWidthRef.current = width;
  };

  const chatMessageId = thread?.length
    ? getChatMessageIri(thread as string)
    : null;

  const scrollToMessageId = highlight?.length
    ? getChatMessageIri(highlight as string)
    : null;

  const conversationId = urlConversationId?.length
    ? getChatConversationIri(urlConversationId as string)
    : null;

  const closeThreadSideBar = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        thread: undefined,
        conversation: undefined,
        highlight: undefined,
      }),
    });
    setLoading(true);
  }, [navigate, queryParams]);

  const removeHightlight = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        highlight: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const conversation = useLiveQuery(
    () =>
      conversationId
        ? database.chatConversations
            .where('id')
            .equals(conversationId)
            .toArray()
            .then(response => (response.length ? response[0] : undefined))
        : undefined,
    [workspace.id, conversationId],
    undefined,
  );

  const parentMessage = useLiveQuery(
    () =>
      chatMessageId && conversationId
        ? database.chatMessages
            .where('id')
            .equals(chatMessageId)
            .toArray()
            .then(async resp => {
              if (!resp.length) {
                await getChatMessageById(
                  workspace.id,
                  conversationId,
                  chatMessageId,
                );
                return null;
              }

              return resp[0];
            })
        : null,
    [chatMessageId, conversationId],
    null,
  );

  const threadMessages = useLiveQuery(
    () =>
      conversationId && parentMessage?.id
        ? database.chatMessages
            .where('parentId')
            .equals(parentMessage.id)
            .sortBy('createdAt')
            .then(async resp => {
              if (!parentMessage?.id) {
                return null;
              }

              if (
                parentMessage.data.threadMessagesCount !== 0 &&
                resp.length < parentMessage.data.threadMessagesCount &&
                !threadsApiLoading.current
              ) {
                threadsApiLoading.current = true;

                try {
                  await getMessageWithAllThreads(
                    conversationId,
                    parentMessage.id,
                  );
                  return null;
                } catch (err) {
                  captureException(err);
                  return null;
                } finally {
                  threadsApiLoading.current = false;
                }
              }

              return [parentMessage, ...resp];
            })
        : null,
    [parentMessage],
    null,
  );

  const threadSidebarIsOpen = !!thread;

  const showMessageInConversation = () => {
    if (!parentMessage || linkDetailsTab === LinkDetailsTab.COMMENTS) {
      return;
    }

    navigate(
      {
        pathname: generatePath(CHATS_CONVERSATION_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          conversationId: getShortId(parentMessage.data.conversationId),
        }),
        search: getQueryParamsFrom(queryParams),
      },
      { replace: true },
    );

    setSearchMessage({
      id: parentMessage.id,
      createdAt: parentMessage.createdAt.toString(),
    });
  };

  useEffect(() => {
    if (chatMessageId) {
      setLoading(true);
    }
  }, [chatMessageId]);

  useEffect(() => {
    if (
      threadMessages &&
      threadMessages.length > 0 &&
      parentMessage?.id &&
      !threadsApiLoading.current
    ) {
      setLoading(false);
    }
  }, [parentMessage?.id, threadMessages]);

  useEffect(() => {
    if (
      threadMessages &&
      threadMessages.length === 0 &&
      threadSidebarIsOpen &&
      !loading
    ) {
      closeThreadSideBar();
    }
  }, [
    closeThreadSideBar,
    loading,
    parentMessage,
    threadMessages,
    threadSidebarIsOpen,
  ]);

  const shouldCloseSidebar =
    (currentConversationWidthRef.current < 800 || isMobile) &&
    threadSidebarIsOpen;

  return (
    <ChatMessageThreadSidebarContext.Provider
      value={{
        threadMessages: threadMessages ? threadMessages : [],
        threadSidebarIsOpen,
        closeThreadSideBar,
        conversation,
        showMessageInConversation,
        chatMessageId,
        loading,
        scrollToMessage: scrollToMessageId,
        disableLastCommentAt,
        setDisableLastCommentAt,
        setCurrentConversationWidth,
        shouldCloseSidebar,
        removeHightlight,
      }}>
      {children}
    </ChatMessageThreadSidebarContext.Provider>
  );
};
