import React, { FC, PropsWithChildren } from 'react';
import { NotificationModal } from '../NotificationContainer.styled';
import { useModalControls } from '../../../../../shared/components/Modal/Modal.hooks';
import { LinkPreviewModal } from '../../../NotificationsModals/LinkPreviewModal/LinkPreviewModal';
import { NotificationMessageDesktopLinkFields } from '../../../Notifications.types';

export interface NotificationLinkModalProps {
  fields: NotificationMessageDesktopLinkFields;
}

export const NotificationLinkModal: FC<
  PropsWithChildren<NotificationLinkModalProps>
> = ({ children, fields }) => {
  const notificationModalControls = useModalControls();

  return (
    <>
      <NotificationModal onClick={notificationModalControls.open}>
        {children}
      </NotificationModal>
      {notificationModalControls.visible && (
        <LinkPreviewModal
          visible={notificationModalControls.visible}
          onRequestClose={notificationModalControls.close}
          fields={fields}
        />
      )}
    </>
  );
};
