import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import { StyledSelectVaultModal } from './ChooseVaultForDesktopModal.styled';
import { ChooseVaultForDesktop } from '../ChooseVaultForDesktop';

interface ChooseVaultForDesktopModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose'> {
  appId: string;
  groupAppId?: string;
  desktopIdOverride?: string;
}

export const ChooseVaultForDesktopModal: FC<ChooseVaultForDesktopModalProps> = ({
  appId,
  groupAppId,
  desktopIdOverride,
  ...modalProps
}) => {
  return (
    <Modal visible={true} width={486} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage
          id={EncryptionTranslation.selectVaultForDesktopModalHeader}
        />
      </ModalHeader>
      <StyledSelectVaultModal>
        <ChooseVaultForDesktop
          appId={appId}
          groupAppId={groupAppId}
          desktopIdOverride={desktopIdOverride}
          onDone={modalProps.onRequestClose}
        />
      </StyledSelectVaultModal>
    </Modal>
  );
};
