import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ChatReplyIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="chat-reply-icon"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78033 0.219668C5.07322 0.512558 5.07322 0.987437 4.78033 1.28033L2.81066 3.25H9.25C11.8733 3.25 14 5.37663 14 8V9.5C14 9.9142 13.6642 10.25 13.25 10.25C12.8358 10.25 12.5 9.9142 12.5 9.5V8C12.5 6.20507 11.0449 4.75 9.25 4.75H2.81066L4.78033 6.7197C5.07322 7.0126 5.07322 7.4874 4.78033 7.7803C4.48744 8.0732 4.01256 8.0732 3.71967 7.7803L0.469668 4.53033C0.176777 4.23744 0.176777 3.76256 0.469668 3.46967L3.71967 0.219668C4.01256 -0.0732225 4.48744 -0.0732225 4.78033 0.219668Z"
        fill="currentColor"
      />
    </svg>
  );
});
