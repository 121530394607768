import React, { FC, PropsWithChildren } from 'react';
import { InformationIcon, WarningIcon } from '../../icons';
import { StyledImportantMessage } from './ImportantMessage.styled';

export enum ImportantMessageType {
  DEFAULT = 'default',
  WARNING = 'warning',
  CRITICAL = 'critical',
  INFO = 'info',
}

interface ImportantMessageProps {
  type: ImportantMessageType;
  withIcon?: boolean;
  circleIcon?: boolean;
  className?: string;
}

export const ImportantMessage: FC<PropsWithChildren<ImportantMessageProps>> = ({
  type,
  withIcon,
  circleIcon,
  children,
  className,
  ...props
}) => {
  if (!children) {
    return null;
  }

  return (
    <StyledImportantMessage
      data-testid="important-message"
      {...props}
      className={`important-message${className ? ` ${className}` : ''}`}
      type={type}
      withIcon={withIcon}>
      {withIcon && (circleIcon ? <InformationIcon /> : <WarningIcon />)}
      <div>{children}</div>
    </StyledImportantMessage>
  );
};
