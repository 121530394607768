import React, { FC, useCallback } from 'react';
import {
  CustomIntegrationItem,
  RemoveButton,
  StyledCustomIntegrations,
  StyledCustomIntegrationsWrapper,
  StyledIntegrationBotImage,
  StyledIntegrationButtonsBlock,
  StyledIntegrationDescription,
  StyledIntegrationTitle,
} from './CustomIntegrations.styled';
import { AppIntegrationsTranslation } from '../../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import {
  useDeleteIntegrationClientMutation,
  useGetWorkspaceIntegrationClients,
} from '../../AppIntegrations.hooks';
import {
  getImageSrcSet,
  getQueryParamsFrom,
} from '../../../../shared/utils/url.utils';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { CUSTOM_INTEGRATION_PATHNAME } from '../../../Desktop/Desktop.constants';
import { generatePath, useNavigate } from 'react-router-dom';
import { GraphQLError } from 'graphql';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { getShortId } from '../../../../shared/utils/id';
import { useConfirm } from '../../../../shared/components/Modal';
import { useQueryParams } from '../../../../shared/hooks';
import { CreateIntegrationModal } from './CustomIntegrationView/CreateIntegrationModal';
import { AdminHeaderSeparator } from '../../../Admin';
import { extractNodes } from '../../../../shared/api/api.utils';

export const CustomIntegrations: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const queryParams = useQueryParams();
  const { newIntegrationModal } = queryParams;
  const { askConfirmation } = useConfirm();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [deleteIntegrationClientMutation] =
    useDeleteIntegrationClientMutation();

  const { data, refetch } = useGetWorkspaceIntegrationClients({
    variables: {
      workspace: currentWorkspace.id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const customIntegrations = extractNodes(data?.integrationClients);
  const deleteIntegration = useCallback(
    (id: string) => {
      deleteIntegrationClientMutation({
        variables: {
          input: {
            id: id,
          },
        },
      })
        .then(() => {
          refetch();
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [deleteIntegrationClientMutation, refetch],
  );

  const handleDeleteIntegrationClick = useCallback(
    (id: string) => {
      askConfirmation(
        intl.formatMessage({
          id: AppIntegrationsTranslation.deleteCustomIntegrationConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteIntegration(id);
      });
    },
    [askConfirmation, deleteIntegration, intl],
  );

  const closeNewIntegrationModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        newIntegrationModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <StyledCustomIntegrationsWrapper>
      <div className="header">
        <FormattedHTMLMessage
          id={AppIntegrationsTranslation.appIntegrationCustomTitle}
        />
      </div>
      <StyledCustomIntegrations>
        {customIntegrations.map(item => (
          <CustomIntegrationItem key={item.id}>
            {item.bot.image && (
              <StyledIntegrationBotImage
                src={item.bot.image.contentUrl}
                srcSet={getImageSrcSet(item.bot?.image?.contentUrl, 48, 48)}
                alt={item.bot?.firstName || ''}
              />
            )}
            <div>
              <StyledIntegrationTitle>
                {item.bot?.firstName}
              </StyledIntegrationTitle>
              <StyledIntegrationDescription>
                {item.name}
              </StyledIntegrationDescription>
            </div>
            <StyledIntegrationButtonsBlock>
              <RemoveButton
                className="delete-button"
                mode={ButtonMode.tertiary}
                size={ButtonSize.sm}
                type="button"
                onClick={() => handleDeleteIntegrationClick(item.id)}>
                <FormattedMessage
                  id={AppIntegrationsTranslation.appIntegrationDeleteButton}
                />
              </RemoveButton>
              <Button
                mode={ButtonMode.tertiary}
                size={ButtonSize.sm}
                onClick={() => {
                  navigate(
                    generatePath(CUSTOM_INTEGRATION_PATHNAME, {
                      workspaceId: getShortId(currentWorkspace.id),
                      integrationId: getShortId(item.id),
                    }),
                  );
                }}>
                <FormattedMessage
                  id={AppIntegrationsTranslation.appIntegrationConfigureApp}
                />
                <AdminHeaderSeparator />
              </Button>
            </StyledIntegrationButtonsBlock>
          </CustomIntegrationItem>
        ))}
      </StyledCustomIntegrations>
      <Button
        className="add-custom-integration"
        mode={ButtonMode.primary}
        size={ButtonSize.sm}
        onClick={() => {
          navigate({
            search: getQueryParamsFrom({
              ...queryParams,
              newIntegrationModal: true,
            }),
          });
        }}>
        <FormattedHTMLMessage
          id={AppIntegrationsTranslation.appIntegrationCreateNew}
        />
      </Button>
      <CreateIntegrationModal
        visible={Boolean(newIntegrationModal)}
        onRequestClose={closeNewIntegrationModal}
      />
    </StyledCustomIntegrationsWrapper>
  );
};
