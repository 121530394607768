import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../../../i18n';
import { HeaderTitle, StyledHeader } from './Header.styled';
import { useQueryParams } from '../../../../../../shared/hooks';
import { TabId } from '../../../../AppStore.constants';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { AppsFilter } from '../AppsFilter';

export const Header: FC = () => {
  const { tabId } = useQueryParams();
  const { workspace } = useCurrentWorkspace();
  const title = useMemo(() => {
    switch (tabId) {
      case TabId.personal:
        return <FormattedMessage id={AppStoreTranslation.personalTabFilter} />;
      case TabId.customApps:
        return (
          <FormattedMessage id={AppStoreTranslation.customAppsTabFilter} />
        );
      case TabId.company:
        return (
          <FormattedMessage
            id={AppStoreTranslation.companyTabFilter}
            values={{
              workspaceName: workspace.name,
            }}
          />
        );
      case TabId.passwords:
        return (
          <FormattedMessage id={AppStoreTranslation.myPasswordsTabFilter} />
        );
      default:
        return <FormattedMessage id={AppStoreTranslation.appsSectionTitle} />;
    }
  }, [tabId, workspace]);
  return (
    <StyledHeader>
      <HeaderTitle>{title}</HeaderTitle>
      <AppsFilter />
    </StyledHeader>
  );
};
