import React, { FC, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkTranslation } from '../i18n';
import { TagFormValues } from '../Link.types';
import { TagSelect } from './TagSelect';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { ReactSelectOptionType } from '../../../shared/components/ReactSelect/ReactSelect.types';
import { Spinner } from '../../../shared/components/Spinner';
import { useQueryParams } from '../../../shared/hooks';
import { useNavigate } from 'react-router';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';

interface TagFormProps {
  createTag: (value: string) => Promise<unknown>;
  editTags: (value: string[]) => void;
  onCancel: () => void;
  tagsOptions?: ReactSelectOptionType[];
  selectedTags?: ReactSelectOptionType[];
  disabledControls?: boolean;
  onCreateTags?: (value: string[]) => void;
  isLinkDataLoading: boolean;
  disabled?: boolean;
}

const FormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button ~ button {
    margin-left: 12px;
  }
`;

export const TagForm: FC<TagFormProps> = ({
  createTag,
  onCancel,
  tagsOptions,
  selectedTags,
  editTags,
  disabledControls,
  onCreateTags,
  isLinkDataLoading,
  disabled = false,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  useEffect(() => {
    return () => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          manageTag: undefined,
          linkId: undefined,
        }),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: TagFormValues = {
    tags: selectedTags || [],
  };
  if (isLinkDataLoading) {
    return <Spinner />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        onCreateTags
          ? onCreateTags(values.tags.map(tag => tag.value))
          : editTags(values.tags.map(tag => tag.value));
        actions.setSubmitting(false);
      }}>
      {({ isSubmitting }) => (
        <Form data-testid="tag-form">
          <div>
            <Field
              name="tags"
              label={intl.formatMessage({
                id: LinkTranslation.tagFormNameLabel,
              })}
              placeholder={intl.formatMessage({
                id: LinkTranslation.tagFormNamePlaceholder,
              })}
              options={tagsOptions}
              createTag={createTag}
              selectedTags={selectedTags}
              disabled={disabled}
              component={TagSelect}
            />

            <FormControls>
              <Button
                data-testid="cancel-button"
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}>
                <FormattedMessage id={LinkTranslation.tagFormCancelButton} />
              </Button>
              <Button
                data-testid="submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={disabled || isSubmitting || disabledControls}>
                <FormattedMessage id={LinkTranslation.formSubmitButton} />
              </Button>
            </FormControls>
          </div>
        </Form>
      )}
    </Formik>
  );
};
