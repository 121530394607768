import { Quill, RangeStatic } from 'quill';
import Delta from 'quill-delta';
import Op from 'quill-delta/dist/Op';

import {
  EditorEventType,
  EditorOpInsertType,
  MentionData,
  WordBeforeArgs,
  WordPosition,
  WordPositionDirection,
} from './Editor.types';
import { SpecialMentionType } from '../../../domains/Chat/Chat.types';

import {
  EMOTICONS_REPLACE_MAP,
  EMOTICONS_REPLACE_REGEX,
} from './Editor.constants';

export const replaceEmoticons = (text: string) =>
  text.replace(
    EMOTICONS_REPLACE_REGEX,
    (_, beforeEmoticon, emoticon, afterEmoticon) => {
      return `${beforeEmoticon}${EMOTICONS_REPLACE_MAP[emoticon]}${afterEmoticon}`;
    },
  );

export const getWordPosition = (
  sentece: string,
  position: number,
  direction?: WordPositionDirection,
): WordPosition => {
  const isSpace = (char: string) => /\s/.exec(char);
  let start =
    direction === WordPositionDirection.after ? position + 1 : position - 1;
  let end = position;

  while (start >= 0 && !isSpace(sentece[start])) {
    start -= 1;
  }
  start = Math.max(0, start + 1);

  while (end < sentece.length && !isSpace(sentece[end])) {
    end += 1;
  }
  end = Math.max(start, end);

  return {
    start,
    end,
  };
};

export const getWordFromPosition = (
  sentence: string,
  pos: WordPosition,
): string => {
  return sentence.substring(pos.start, pos.end);
};

export const setEditorValue = (editor: Quill, value: string): void => {
  const beforeWord = value?.substr(0, value.indexOf('@')) ?? null;

  if (!editor) {
    return;
  }

  if (value && !beforeWord) {
    const mentionModule = editor.getModule('mention');
    const mentionWordPos = getWordPosition(
      value,
      value.indexOf('@'),
      WordPositionDirection.after,
    );

    const mentionWord =
      getWordFromPosition(value, mentionWordPos).replace('@', '') ?? null;

    mentionModule.insertItem(
      {
        denotationChar: '@',
        id: mentionWord,
        value: mentionWord,
      },
      true,
    );
  } else {
    editor.insertText(0, value);
  }
};

export const selectionChangeHandler = (
  range: RangeStatic,
  oldRange: RangeStatic,
): EditorEventType | null => {
  if (range === null && oldRange !== null) {
    return EditorEventType.blur;
  }
  if (range !== null && oldRange === null) {
    return EditorEventType.focus;
  }

  return null;
};

export const hasWhiteSpace = (s: string): boolean => {
  const whitespaceChars = [' ', '\n'];
  return whitespaceChars.some(char => s.includes(char));
};

export const mentionListRenderer = (
  searchTerm: string,
  renderList: (data: MentionData[], term: string) => void,
  mentionChar: string,
  mentionData: MentionData[],
  editor: Quill | null,
) => {
  if (mentionChar !== '@' || !editor) {
    return;
  }

  const mention = editor.getModule('mention');

  if (!mention) {
    return;
  }

  const cursorPosition = editor.getSelection()?.index;
  const editorContent = editor.getContents();

  if (!cursorPosition) {
    return;
  }

  const content = editorContent.ops[editorContent.ops.length - 1];
  const contentText: string | undefined = content.insert as string;

  if (!contentText) {
    return;
  }

  const indexOfMentionChar = contentText.indexOf('@') - 1;

  const canTriggerMention = editorContent.ops.find((op: Op) => {
    if (typeof op.insert !== 'string') {
      return false;
    }

    if (indexOfMentionChar === -1) {
      return true;
    }

    if (op.insert.charAt(contentText.length - 2) === '@') {
      return hasWhiteSpace(op.insert.charAt(contentText.length - 3));
    }

    if (op.insert.indexOf('@') >= 0) {
      return hasWhiteSpace(op.insert.charAt(op.insert.indexOf('@') - 1));
    }

    return false;
  });

  if (!canTriggerMention) {
    return;
  }

  if (searchTerm.length === 0) {
    renderList(mentionData, searchTerm);
  } else {
    const matches = [];
    for (let i = 0; i < mentionData.length; i++)
      if (
        (mentionData[i] as MentionData).value
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        matches.push(mentionData[i]);
      }

    renderList(matches, searchTerm);
  }
};

export const senteceFromOps = (editor: Quill, content?: Delta): string => {
  const contents: Delta = content || editor.getContents();
  let sentence: string = '';

  contents.ops.forEach((op: Op) => {
    const insert: string | EditorOpInsertType = op.insert as
      | string
      | EditorOpInsertType;

    if (typeof insert === 'object' && 'mention' in insert && insert.mention) {
      return (sentence += `${insert.mention.denotationChar}${insert.mention.value}`);
    }

    sentence += insert;
  });

  return sentence;
};

export const convertToApiFormat = (
  str: string,
  mentionData: MentionData[],
): string => {
  let message: string = str;

  mentionData.forEach((mention: MentionData) => {
    if (mention.type) {
      message = message.replace(
        new RegExp(`@${mention.value}`, 'g'),
        `[@${mention.value}]`,
      );
      return;
    }
    message = message.replace(
      new RegExp(`@${mention.value}`, 'g'),
      `[@${mention.id}]`,
    );
  });

  return message;
};

const prepareMentionInDraft = (
  str: string,
  mentionData: MentionData[],
): string => {
  if (!str.length) {
    return '';
  }

  let draft: string = str;

  mentionData.forEach((mention: MentionData) => {
    if (
      mention.id === SpecialMentionType.Here ||
      mention.id === SpecialMentionType.Channel
    ) {
      return;
    }

    draft = draft.replace(
      new RegExp(`@${mention.value}`, 'g'),
      `[@${mention.id}]`,
    );
  });

  return draft;
};

export const prepareEditorInserts = (
  value: string,
  mentionData: MentionData[],
): Delta => {
  const preparedMessage = prepareMentionInDraft(value, mentionData);

  const splitString = preparedMessage.split(' ');

  const contents: Delta = new Delta();

  splitString.forEach((word: string, i) => {
    const wordIncludesMention = word.includes('[@');

    let splitedWord: string[] = [];

    if (wordIncludesMention) {
      splitedWord = [...word.split('[@'), word.slice(word.indexOf(']') + 1)];
    } else {
      splitedWord = word.split(' ');
    }

    splitedWord.forEach(wordWithMention => {
      const dataMention = mentionData.find(mention =>
        wordWithMention.includes(mention.id),
      );

      if (dataMention && wordIncludesMention) {
        const mention = dataMention ? dataMention.value : wordWithMention;

        contents.push({
          insert: {
            mention: {
              denotationChar: '@',
              id: mention,
              index: mention,
              value: mention + ' ',
            },
          },
        });
        return;
      }

      if (i + 1 !== splitString.length) {
        // programmatically inserting space after each word except the last
        const wordWithSpace = `${replaceEmoticons(wordWithMention)} `;

        // wordWithSpace.length > 1 = extra space limits
        if (wordWithSpace.length > 1) {
          contents.push({
            insert: wordWithSpace,
          });
        }
        return;
      }

      contents.push({
        insert: `${replaceEmoticons(wordWithMention)}`,
      });
    });
  });

  return contents;
};

export const setCursorPosition = (editor: Quill, position?: number) => {
  const selection = editor.getSelection();
  if (!selection) {
    return;
  }

  const cursorPosition = position || selection?.index;

  setTimeout(() => {
    editor.setSelection(cursorPosition, 0);
  }, 0);
};

const focusOnLastChar = (editor: Quill, draftValue: string) => {
  if (!editor || !draftValue) {
    return;
  }

  const editorSelection = editor.getSelection() || { index: 0 };

  setTimeout(
    () => editor.setSelection(editorSelection.index + draftValue.length + 1, 0),
    150,
  );
};

export const insertDraftMessage = (
  editor: Quill,
  draft: string,
  mentionData: MentionData[],
): void => {
  const sentence = prepareEditorInserts(draft, mentionData);

  editor.setContents(sentence, 'api');

  setCursorPosition(editor);
  focusOnLastChar(editor, draft);
};

export const autoReplaceEmoticons = (editor: Quill, content: Delta): void => {
  setCursorPosition(editor);

  const contents: Delta = new Delta();

  content.forEach((word: Op) => {
    if (typeof word.insert === 'string') {
      contents.push({
        insert: replaceEmoticons(word.insert),
      });

      return;
    }

    contents.push({
      insert: word.insert,
    });
  });

  editor.setContents(contents, 'api');
};

export const getWordBefore = (
  editor: Quill,
  sentence: string,
): WordBeforeArgs => {
  const range = editor.getSelection();

  if (!range) {
    return {
      position: {
        start: 0,
        end: 0,
      },
      word: '',
    };
  }

  const start = range.index - 1;
  const position = getWordPosition(sentence, start);
  const word = getWordFromPosition(sentence, position) ?? '';

  return {
    position,
    word,
  };
};

export const insertText = (
  editor: Quill,
  text: string,
  range?: RangeStatic,
) => {
  if (range) {
    editor.insertText(range.index, text);
  } else {
    editor.insertText(0, text);
  }
};

export const convertDataUriToFile = (
  dataUri: string,
  filename: string,
): Promise<File> => {
  const type = dataUri.substring(
    dataUri.indexOf(':') + 1,
    dataUri.indexOf(';'),
  );
  return fetch(dataUri)
    .then(r => r.blob())
    .then(
      blob =>
        new File([blob], filename, {
          type,
        }),
    );
};

export const dangerouslyInsertLineBreak = (
  editor: Quill,
  range: RangeStatic,
) => {
  editor.clipboard.dangerouslyPasteHTML(range.index, '<p><br/><br/></p>');
};

export const pasteImages = (
  fileList: FileList,
  onImagePasted: (files: File[]) => void,
) => {
  onImagePasted(Array.from(fileList));
};

export const prepareTextToPaste = (delta: Delta) => {
  const textOps: Delta['ops'] = [];

  delta.ops.forEach(op => {
    if (!op.insert) {
      return;
    }

    if (op.attributes?.link) {
      textOps.push({
        insert: op.attributes.link,
      });

      return;
    }

    if (typeof op.insert === 'object' && 'mention' in op.insert) {
      textOps.push({
        insert: op.insert,
      });

      return;
    }

    if (typeof op.insert === 'string') {
      textOps.push({
        insert: op.insert,
      });

      return;
    }
  });
  delta.ops = textOps;

  return delta;
};

export const editorInputIsEmpty = (delta: Delta) =>
  delta.ops.length === 1 && delta.ops[0].insert === '\n';
