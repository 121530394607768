import { useCallback } from 'react';
import { CreateFavoriteItemType } from '../deprecatedData/Operations/Mutations/CreateFavorite.mutation';
import { createFavoriteRepository } from '../deprecatedData/Repository/FavoritesRepository';

export const useCaseCreateFavorite = () => {
  const createFavoriteUseCase = useCallback(
    async (workspaceId: string, item: CreateFavoriteItemType) => {
      return await createFavoriteRepository(workspaceId, item);
    },
    [],
  );

  return {
    createFavoriteUseCase,
  };
};
