import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  EmptyHeaderWrapper,
  EmptyStateTitle,
  StyledColumn,
  StyledDivider,
  StyledEmptyStateDesktopVersion,
  StyledEmptyStateHelpText,
  StyledMessage,
  StyledMessageDesktopVersion,
  StyledMessageInstructions,
  StyledPlusIconWrapper,
} from './EmptyState.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../../Desktop.hooks';
import { DesktopTranslation } from '../../../i18n';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { useCurrentTypeOfPage } from '../../../Desktop.utils';
import { AppHoverDemo } from './AppHoverDemo/AppHoverDemo';
import { ImportStepTypes } from '../../../../ChromeExtension/ChromeExtension.types';
import { extensionSupported } from '../../../../../shared/utils/extension.utils';
import { AddIcon } from '../../../../../shared/icons';
import ImportIllustration from '../../../../../shared/assets/images/import-illustration.png';
import { appEnv } from '../../../../../appEnv';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface EmptyStateProps {
  adminView?: boolean;
}

export const EmptyStateDesktopVersion: FC<EmptyStateProps> = ({
  adminView,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentDesktop = useCurrentDesktop();
  const { canAddApp } = useCurrentDesktopPermissions();
  const queryParams = useQueryParams();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  const handleOnboardingClick = () => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        openOnboardingQuickTour: true,
      }),
    });
  };

  const getImportFromBrowserColumn = () => {
    return (
      <StyledColumn>
        <img src={ImportIllustration} alt="" />
        <StyledMessageDesktopVersion data-testid="empty-msg-desktop-version">
          <FormattedMessage id={DesktopTranslation.importYourApps} />
        </StyledMessageDesktopVersion>
        {extensionSupported && !adminView && currentDesktop && canAddApp && (
          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            data-testid="import-app-desktop-version"
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  extensionInfoModal: true,
                  nextStep: canAddApp
                    ? ImportStepTypes.selectApps
                    : ImportStepTypes.selectLinks,
                }),
              })
            }>
            <FormattedMessage id={DesktopTranslation.importFromBrowserButton} />
          </Button>
        )}
      </StyledColumn>
    );
  };

  const getAddNewColumn = () => {
    return (
      <StyledColumn>
        <StyledPlusIconWrapper>
          <AddIcon
            height={24}
            width={24}
            color={theme.colors.OnSurfaceLightEmphasis}
          />
        </StyledPlusIconWrapper>
        <StyledMessageDesktopVersion data-testid="empty-msg-desktop-version">
          <FormattedMessage id={DesktopTranslation.addNewApp} />
        </StyledMessageDesktopVersion>
        {currentDesktop && canAddApp && (
          <Button
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  appStoreModal: true,
                }),
              })
            }
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            data-testid="add-app-desktop-version">
            <FormattedMessage id={DesktopTranslation.emptyAddAppsButton} />
          </Button>
        )}
      </StyledColumn>
    );
  };
  return (
    <EmptyHeaderWrapper>
      {isFavoritesDesktop ? (
        <>
          <AppHoverDemo />
          <StyledMessage data-testid="no-favorites-desktop-msg-desktop-version">
            <FormattedMessage
              id={DesktopTranslation.emptyAppsMessageFavorites}
            />
            <StyledMessageInstructions>
              <FormattedMessage
                id={DesktopTranslation.emptyAppsMessageFavoritesInstructions}
              />
            </StyledMessageInstructions>
          </StyledMessage>
        </>
      ) : (
        <>
          <EmptyStateTitle>
            <FormattedMessage id={DesktopTranslation.emptyHeader} />
          </EmptyStateTitle>
          <StyledEmptyStateDesktopVersion data-testid="empty-state-desktop-version">
            {getImportFromBrowserColumn()}
            <StyledDivider />
            {getAddNewColumn()}
          </StyledEmptyStateDesktopVersion>
          <StyledEmptyStateHelpText>
            <FormattedMessage id={DesktopTranslation.emptyAppsStateHelpText} />
            <span
              onClick={handleOnboardingClick}
              className="empty-state-onboarding">
              <FormattedMessage
                id={DesktopTranslation.emptyAppsStateOnboardingTour}
              />
            </span>
            <FormattedHTMLMessage
              id={DesktopTranslation.emptyAppsStateFaqLink}
              values={{
                faqUrl: appEnv.SUPPORT_FAQ_URL,
              }}
            />
          </StyledEmptyStateHelpText>
        </>
      )}
    </EmptyHeaderWrapper>
  );
};
