import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledFolderCrumbs = styled.nav`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 32px;
`;

export const CrumbWrapper = styled.div.attrs({
  'data-testid': 'crumb-wrapper',
})<{ isFirstCrumb?: boolean; isButton?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 auto;
  flex-shrink: 1000;
  padding: ${({ theme }) => `${theme.spacing.xs} 0`};
  display: inline-block;

  ${({ theme, isFirstCrumb }) =>
    !isFirstCrumb &&
    css`
      &::before {
        content: '/';
        margin: ${`0 ${theme.spacing.xxs}`};
        color: ${theme.colors.OnSurfaceLightEmphasis};
        font-size: 0.875rem;
      }
    `}

  ${({ isButton }) =>
    isButton &&
    css`
      display: contents;
      flex: 0 0 auto;
      flex-shrink: 0.5;
    `}
`;

export const CrumbLink = styled(Link).attrs({
  'data-testid': 'crumb-link',
})`
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.xxs}`};
  border-radius: 4px;
  background: transparent;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  ${({ theme }) => theme.fontTemplates.regularSemibold};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.OnSurfaceSelected};
  }
`;

export const CrumbButton = styled.button.attrs({
  'data-testid': 'crumb-button',
})`
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border: 0;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.xxs}`};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  background: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.OnSurfaceSelected};
  }
`;

export const CrumbName = styled.span.attrs({
  'data-testid': 'folder-name',
})`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  ${({ theme }) => theme.fontTemplates.regularSemibold};
`;
