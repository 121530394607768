import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Expand } from '../../../../../shared/components/Expand';
import { InputSize } from '../../../../../shared/components/Input';
import { Search } from '../../../../../shared/components/Search';
import { CornerBottomIcon } from '../../../../../shared/icons';
import { StyledConversationExpand } from '../../../../Chat/ChatSegment/ChatNavigator/ConversationList/ConversationList.styled';
import {
  VAULTS_LIST_SEARCH_BAR,
  VAULTS_LIST_FILTER_QUERY_PARAM,
} from '../../../Encryption.constants';
import { EncryptionTranslation } from '../../../i18n';
import { VaultsCollapsedSectionsProps } from '../PasswordsSwitcher.hooks';
import {
  StyledPasswordFilter,
  StyledPasswordsGroups,
} from '../PasswordsSwitcher.styled';
import { ListVaultItem } from './VaultItem';
import { ExpandedVaultsType, VaultsListSections } from './VaultsList.types';

interface VaultListProps {
  item: ExpandedVaultsType;
  collapsedSections: VaultsCollapsedSectionsProps;
  isScrolling: boolean;
  handleToggle: (type: VaultsListSections) => void;
}

export const VaultList: FC<VaultListProps> = memo(
  ({ item, collapsedSections, isScrolling, handleToggle }) => {
    const handleExpand = (type: VaultsListSections, toggle: () => void) => {
      handleToggle(type);
      toggle();
    };

    const appExpand = (
      <StyledPasswordsGroups>
        <Expand
          expandedByDefault={!collapsedSections.app}
          dataTestId="vaults-app-expand"
          renderTrigger={toggle => (
            <StyledConversationExpand
              iconFirst
              icon={CornerBottomIcon}
              expanded={!collapsedSections.app}
              onClick={() => handleExpand(VaultsListSections.app, toggle)}>
              <FormattedMessage
                id={EncryptionTranslation.passwordsAppPasswords}
              />
            </StyledConversationExpand>
          )}
        />
      </StyledPasswordsGroups>
    );

    const groupExpand = (
      <StyledPasswordsGroups>
        <Expand
          expandedByDefault={!collapsedSections.group}
          dataTestId="vaults-group-expand"
          renderTrigger={toggle => (
            <StyledConversationExpand
              iconFirst
              icon={CornerBottomIcon}
              expanded={!collapsedSections.group}
              onClick={() => handleExpand(VaultsListSections.group, toggle)}>
              <FormattedMessage
                id={EncryptionTranslation.passwordsGroupPasswords}
              />
            </StyledConversationExpand>
          )}
        />
      </StyledPasswordsGroups>
    );

    const otherExpand = (
      <StyledPasswordsGroups>
        <Expand
          expandedByDefault={!collapsedSections.other}
          dataTestId="vaults-other-expand"
          renderTrigger={toggle => (
            <StyledConversationExpand
              iconFirst
              icon={CornerBottomIcon}
              expanded={!collapsedSections.other}
              onClick={() => handleExpand(VaultsListSections.other, toggle)}>
              <FormattedMessage
                id={EncryptionTranslation.passwordsOtherPasswords}
              />
            </StyledConversationExpand>
          )}
        />
      </StyledPasswordsGroups>
    );

    const expandRenderer = () => {
      const groupName = item as VaultsListSections;

      switch (groupName) {
        case VaultsListSections.app:
          return appExpand;
        case VaultsListSections.group:
          return groupExpand;
        case VaultsListSections.other:
          return otherExpand;
      }
    };

    if (item === VAULTS_LIST_SEARCH_BAR) {
      return (
        <StyledPasswordFilter data-testid="password-filter">
          <Search
            queryParamName={VAULTS_LIST_FILTER_QUERY_PARAM}
            size={InputSize.sm}
            data-testid="password-search"
          />
        </StyledPasswordFilter>
      );
    }

    if (typeof item === 'string') {
      return expandRenderer();
    }

    return (
      <ListVaultItem
        data-testid={`vault-itemId-${item.id}`}
        vault={item}
        renderItemButtons={isScrolling}
      />
    );
  },
);
