import styled, { css } from 'styled-components';

const FIELDS_BUFFER = 1.5;

const sharedFieldSetCss = css`
  border: 0;
  margin: 0;
  padding: 0;
`;

export const FieldSet = styled.fieldset`
  ${sharedFieldSetCss}
  display: contents;

  & > div ~ div {
    margin-top: ${FIELDS_BUFFER}rem;
  }
`;

export const FieldSetControls = styled.fieldset`
  ${sharedFieldSetCss}
  margin-top: ${FIELDS_BUFFER}rem;
  text-align: right;

  & > button ~ button {
    margin-left: 1rem;
  }
`;
