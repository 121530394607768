import { getVaultIri } from '../Encryption.utils';
import {
  StreamEventRequest,
  StreamVaultItemConfigDesktopRequestEvent,
} from '../../Mercure/General';
import { Dispatch, SetStateAction } from 'react';
import { VaultApiType } from '../Encryption.types';
import {
  addVaultItemConfigDesktop,
  makeVaultItemConfigDesktop,
  removeVaultItemConfigDesktop,
} from '../utils/VaultItemConfigDesktop.utils';

export const handleVaultItemConfigDesktopMercureEvent = (
  event: StreamVaultItemConfigDesktopRequestEvent,
  setWorkspaceVaultsCache: Dispatch<
    SetStateAction<Record<string, VaultApiType[]>>
  >,
) => {
  const vaultItemAppConfigDesktopId = event['@iri'];
  const vaultId = getVaultIri(event.appVaultItemConfig.vaultItem.vault._id);
  const workspaceId = event.appVaultItemConfig.vaultItem.vault.workspace.id;

  if (event['@request'] === StreamEventRequest.NEW) {
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspaceId]: addVaultItemConfigDesktop(
        cache[workspaceId],
        vaultId,
        makeVaultItemConfigDesktop(event),
      ),
    }));
  }
  if (event['@request'] === StreamEventRequest.REMOVE) {
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspaceId]: removeVaultItemConfigDesktop(
        cache[workspaceId],
        vaultItemAppConfigDesktopId,
      ),
    }));
  }
};
