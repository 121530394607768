import React, { FC, useCallback } from 'react';
import {
  StyledItem,
  StyledItemBody,
  StyledItemType,
} from './SearchItems.styled';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopTab,
} from '../../../../Desktop/Desktop.constants';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { getShortId } from '../../../../../shared/utils/id';
import {
  GlobalSearchResultChatConversationHit,
  GlobalSearchResultLinkCommentHit,
} from '../../../GlobalSearch.queries';
import { MessageIcon } from '../../../../Chat/icons';
import { LinkDetailsTab } from '../../../../Link/Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useQueryParams } from '../../../../../shared/hooks';
import { useCurrentDesktopContent } from '../../../../Desktop/Desktop.hooks';

export const LinkCommentItem: FC<
  GlobalSearchResultLinkCommentHit &
    Pick<GlobalSearchResultChatConversationHit, 'desktopIri' | 'linkIri'>
> = ({ desktopIri, linkIri, message }) => {
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();
  const { setDesktopLayout } = useCurrentDesktopContent();
  const navigate = useNavigate();

  const handleLinkCommentClick = useCallback(() => {
    if (!desktopIri || !linkIri) {
      return null;
    }

    setDesktopLayout(DesktopLayout.TABBED);
    navigate({
      pathname: generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: getShortId(desktopIri),
        tab: DesktopTab.links,
      }),
      search: getQueryParamsFrom({
        ...queryParams,
        linkDetails: getShortId(linkIri),
        linkDetailsTab: LinkDetailsTab.COMMENTS,
        conversation: undefined,
        thread: undefined,
      }),
    });
  }, [
    desktopIri,
    linkIri,
    setDesktopLayout,
    navigate,
    workspace.id,
    queryParams,
  ]);

  return (
    <StyledItem onClick={handleLinkCommentClick}>
      <StyledItemBody>
        <MessageIcon />
        <span>{message}</span>
      </StyledItemBody>
      <StyledItemType>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchLinkCommentType}
        />
      </StyledItemType>
    </StyledItem>
  );
};
