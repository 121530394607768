import React, { FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { BillingTranslation } from '../../../../i18n';
import { WorkspaceApiType } from '../../../../../Workspace/Workspace.types';
import { StyledNextRenewDate } from './NextRenewalDate.styled';
import { format } from 'date-fns';

interface NextRenewDateProps {
  workspace: WorkspaceApiType;
}

export const NextRenewDate: FC<NextRenewDateProps> = ({ workspace }) => {
  if (workspace.nextRenewAt) {
    return (
      <FormattedHTMLMessage
        tagName={StyledNextRenewDate}
        id={BillingTranslation.adminBillingNextRenewalDate}
        values={{
          date: format(new Date(workspace.nextRenewAt), 'dd.MM.yyyy'),
        }}
      />
    );
  }

  if (workspace.canceledAt) {
    return (
      <FormattedHTMLMessage
        tagName={StyledNextRenewDate}
        id={BillingTranslation.adminBillingSubscriptionEnds}
        values={{
          date: format(new Date(workspace.canceledAt), 'dd.MM.yyyy'),
        }}
      />
    );
  }

  return null;
};
