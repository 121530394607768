/**
 * Backend adds some hash coed into the end of the filename, which doesn't look good in UI.
 * This function cuts it off.
 *
 * @param filename string
 * @returns string
 */
export const prettyFilename = (filename: string) => {
  const match = filename.match(/(-\w+)\.\w+$/);

  if (!match) {
    return filename;
  }

  return filename.replace(match[1], '');
};
