import React from 'react';
import { VaultApiType } from '../../Encryption.types';

interface SelectVaultContextType {
  selectedVaults: { [vaultId: string]: boolean };
  selectVault: (vaultId: string) => void;
  selectVaults: (vaults: VaultApiType[]) => void;
  unselectVault: (vaultId: string) => void;
  unselectAll: () => void;
}

export const SelectVaultContext = React.createContext<SelectVaultContextType>({
  selectedVaults: {},
  selectVault: () => {},
  selectVaults: () => {},
  unselectVault: () => {},
  unselectAll: () => {},
});
