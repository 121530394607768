import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { TeamTranslation } from '../i18n';
import { TeamField, TeamFormControls } from './TeamForm.styled';
import { FormTextarea } from '../../../shared/components/Textarea';
import type {
  TeamApiType,
  TeamFormValues,
} from '../data/Team/types/Team.types';

const TeamFormSchema: Schema<TeamFormValues> = Yup.object().shape({
  name: Yup.string().required(TeamTranslation.formNameRequiredError),
  description: Yup.string(),
});

interface TeamFormProps {
  team?: TeamApiType;
  onSubmit: (values: TeamFormValues) => void;
  onCancel: () => void;
}

export const TeamForm: FC<TeamFormProps> = ({ team, onSubmit, onCancel }) => {
  const intl = useIntl();
  const initialValues: TeamFormValues = {
    name: team?.name ?? '',
    description: team?.description ?? '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TeamFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="team-form">
          <div>
            <TeamField>
              <Field
                name="name"
                type="text"
                autoFocus
                data-testid="team-form-name-input"
                placeholder={intl.formatMessage({
                  id: TeamTranslation.formNamePlaceholder,
                })}
                label={intl.formatMessage({
                  id: TeamTranslation.formNameLabel,
                })}
                component={FormInput}
              />
            </TeamField>

            <TeamField>
              <Field
                name="description"
                data-testid="team-form-description-input"
                placeholder={intl.formatMessage({
                  id: TeamTranslation.formDescriptionPlaceholder,
                })}
                label={intl.formatMessage({
                  id: TeamTranslation.formDescriptionLabel,
                })}
                component={FormTextarea}
              />
            </TeamField>

            <TeamFormControls>
              {team ? (
                <Button
                  data-testid="team-form-submit-button"
                  type="submit"
                  mode={ButtonMode.primary}
                  size={ButtonSize.md}
                  disabled={isSubmitting}>
                  <FormattedMessage id={TeamTranslation.editSubmitButton} />
                </Button>
              ) : (
                <>
                  <Button
                    data-testid="team-form-cancel-button"
                    type="button"
                    mode={ButtonMode.secondary}
                    size={ButtonSize.md}
                    onClick={onCancel}>
                    <FormattedMessage id={TeamTranslation.createCancelButton} />
                  </Button>
                  <Button
                    data-testid="team-form-submit-button"
                    type="submit"
                    mode={ButtonMode.primary}
                    size={ButtonSize.md}
                    disabled={isSubmitting}>
                    <FormattedMessage id={TeamTranslation.createSubmitButton} />
                  </Button>
                </>
              )}
            </TeamFormControls>
          </div>
        </Form>
      )}
    </Formik>
  );
};
