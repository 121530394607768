import styled from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';

export const StyledWorkspaceFormImageContainer = styled.div`
  max-width: 9.625rem;
  margin: 0 auto 1rem;
`;

export const StyledWorkspaceFormImage = styled.img`
  width: 9.625rem;
  height: 9.625rem;
  border-radius: 0.5rem;
  object-fit: cover;
`;

export const StyledWorkspaceFormControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ImageRemoveButton = styled(Button)`
  position: relative;
  z-index: 1;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.CriticalDark};
`;

export const FormFields = styled.div`
  & > div ~ div {
    margin-top: 1.625rem;
  }

  textarea {
    min-height: 100px;
  }
`;

export const IdentifierFormInputBlock = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

export const FormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  button ~ button {
    margin-left: 1rem;
  }
`;

export const StyledIdentifierContainer = styled.div`
  display: flex;

  .main-domain {
    display: flex;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    margin-left: 0.5rem;
    padding: 0.625rem 0;
  }
`;
