import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserTranslation } from '../../../../i18n';
import { Input } from '../../../../../../shared/components/Input';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../../Admin';
import { Spinner } from '../../../../../../shared/components/Spinner';
import {
  useResendInvitationMutation,
  useUserCacheUpdate,
} from '../../../../User.hooks';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccess,
} from '../../../../../../shared/components/Toast';
import { AccountInvitationApiType } from '../../../../../Invitation/data/Invitation/types/Invitation.types';

interface UrlProps {
  invitation: AccountInvitationApiType;
}

export const UrlSection: FC<UrlProps> = ({ invitation }) => {
  const [resending, setResending] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const {
    workspace: { id: workspaceId },
  } = useCurrentWorkspace();

  const { updateAccountInvitationCache } = useUserCacheUpdate();
  const [resendInvitationMutation] = useResendInvitationMutation();
  const resendInvitation = useCallback(() => {
    setResending(true);
    resendInvitationMutation({
      variables: {
        input: {
          id: invitation.id,
          workspace: workspaceId,
        },
      },
      update: (cache, { data }) => {
        if (!data) {
          return;
        }
        updateAccountInvitationCache(
          {
            id: invitation.id,
          },
          data.resendWorkspaceAccountInvitation.accountInvitation,
        );
      },
    })
      .then(() => {
        setResending(false);
        const message = formatMessage(
          {
            id: UserTranslation.adminAccountInvitationResendSuccess,
          },
          {
            email: invitation.recipients[0].email,
          },
        );
        showToastSuccess(message);
      })
      .catch(err => {
        setResending(false);
        showToastGraphQLErrors(err.graphQLErrors);
      });
  }, [
    formatMessage,
    invitation.id,
    invitation.recipients,
    resendInvitationMutation,
    workspaceId,
    updateAccountInvitationCache,
  ]);

  if (!invitation.invitationUri) {
    return null;
  }

  return (
    <Section data-testid="urls-section">
      <SectionHeader data-testid="header">
        <SectionHeaderTitle data-testid="title">
          <FormattedMessage
            id={UserTranslation.adminAccountInvitationUrlHeader}
          />
        </SectionHeaderTitle>
        {resending ? (
          <span>
            <Spinner size={12} />
          </span>
        ) : (
          <SectionHeaderButton
            onClick={resendInvitation}
            data-testid="resend-invitation-button">
            {formatMessage(
              {
                id: UserTranslation.adminAccountInvitationResend,
              },
              {
                email: invitation.recipients[0].email,
              },
            )}
          </SectionHeaderButton>
        )}
      </SectionHeader>
      <SectionBody data-testid="section-body">
        <Input
          defaultValue={invitation.invitationUri}
          readOnly
          data-testid="invitation-url"
        />
      </SectionBody>
    </Section>
  );
};
