import React, { memo } from 'react';
import { useMobile } from '../../../../shared/hooks';
import { LinkApiType } from '../../Link.types';
import { LinkComments } from './LinkComments/LinkComments';
import { LinkDetails } from './LinkDetails/LinkDetails';
import { LinkDetailsTab } from './LinkDetailsModal.constants';
import { LinkDetailsContent } from './LinkDetailsModal.styled';
import { LinkPreview } from './LinkPreview/LinkPreview';

interface LinkDetailsContentContainerProps {
  data: LinkApiType;
  linkDetailsTab?: string;
}

export const LinkDetailsContentContainer =
  memo<LinkDetailsContentContainerProps>(({ data, linkDetailsTab }) => {
    const isMobile = useMobile();

    return (
      <LinkDetailsContent data-testid="link-details-content">
        <LinkPreview link={data} isHidden={isMobile && !!linkDetailsTab} />
        {linkDetailsTab === LinkDetailsTab.COMMENTS && (
          <LinkComments link={data} />
        )}
        {linkDetailsTab === LinkDetailsTab.DETAILS && (
          <LinkDetails link={data} />
        )}
      </LinkDetailsContent>
    );
  });
