import styled, { css, keyframes } from 'styled-components';
import { ButtonWithIcon } from '../../shared/components/ButtonWithIcon/ButtonWithIcon';

interface StyledNotificationsProps {
  open: boolean;
  hasUnread: boolean;
}

export const StyledNotifications = styled.div`
  width: 100%;
  height: 100%;
`;

const bellRingAnimation = keyframes`
  0% { transform: rotateZ(0deg); }
  20% { transform: rotateZ(20deg); }
  40% { transform: rotateZ(-16deg); }
  60% { transform: rotateZ(10deg); }
  80% { transform: rotateZ(-6deg); }
  90% { transform: rotateZ(3deg); }
  95% { transform: rotateZ(-1deg); }
  100% { transform: rotateZ(0deg); }
`;

export const BadgeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Badge = styled(ButtonWithIcon)<StyledNotificationsProps>`
  ${p =>
    p.hasUnread &&
    css`
      animation: ${bellRingAnimation} 0.5s ease-out;
    `};
`;
