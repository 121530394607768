import { useMemo } from 'react';

export const useLongShadow = (inputWidth: number, shadowColor: string) => {
  return useMemo(() => {
    let shadowSize = 2;
    let shadow = `${shadowSize}px 0 0 -4px ${shadowColor}`;

    for (; shadowSize < inputWidth; shadowSize++) {
      shadow = `${shadow}, ${shadowSize}px 0 0 -4px ${shadowColor}`;
    }

    return shadow;
  }, [inputWidth, shadowColor]);
};
