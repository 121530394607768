export enum ScrollDirection {
  top = 'top',
  bottom = 'bottom',
  unkonwn = 'unknown',
}

export interface LoadingState {
  listLoading: boolean;
  loadingBefore: boolean;
  loadingAfter: boolean;
}
