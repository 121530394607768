import styled, { css } from 'styled-components';

export const StyledMemberItemWrapper = styled.div<{ active?: boolean }>`
  width: 100%;
  ${p =>
    typeof p.active === 'undefined'
      ? css`
          :hover {
            background-color: ${p => p.theme.colors.OnSurfaceHover};
          }
        `
      : p.active &&
        css`
          background-color: ${p => p.theme.colors.OnSurfaceSelected};
        `}
`;

export const StyledMemberItem = styled.button<{ active?: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  cursor: pointer;

  & + & {
    margin-top: 0.25rem;
  }

  > div {
    margin-left: 0.375rem;
    display: flex;
    flex-direction: column;
  }

  .member-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`;

export const MemberTitle = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
`;

export const MemberName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  line-height: 1.3;
`;

export const MemberType = styled.span`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.125rem;
`;

export const MemberBadge = styled.div`
  display: flex;
  align-items: center;
  margin-top: -0.25rem;
  margin-left: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
