import { useMemo } from 'react';
import {
  isAppleTouchDevice,
  isChromium,
  isFirefox,
  isMacOS,
  isSafari,
} from '../../shared/utils/user-agent';

export const useBrowserSupportCheck = () => {
  const supported = useMemo(() => {
    // iOS/iPadOS with non-Safari not fully supported
    if (isAppleTouchDevice() && !isSafari()) {
      return false;
    }
    // MacOS device with non-Chromium not fully supported
    if (isMacOS() && !isChromium()) {
      return false;
    }
    // any device with Firefox not fully supported
    if (isFirefox()) {
      return false;
    }
    return true;
  }, []);
  return { supported };
};
