import styled from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { CopyIcon, VideoCallIcon } from '../../../../shared/icons';
import { Input } from '../../../../shared/components/Input';
import { InputWithIcon } from '../../../../shared/components/InputWithIcon/InputWithIcon';

export const ConferencePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: ${({ theme }) => theme.colors.Surface};
`;
export const StyledConferencePreview = styled.div`
  padding: 1rem;
  max-width: 480px;
  width: 100%;
`;

export const ConferencePreviewLink = styled.div``;
styled(Input)`
  margin-top: 1rem;
  cursor: default !important;
`;

export const ConferencePreviewControls = styled.div`
  margin-top: 0.5rem;

  ${p => p.theme.breakpoints.md`
    display: flex;
    button ~ button {
      margin-top: 0;
      margin-left: 1rem;
    }
  `}
`;
export const ConferencePreviewJoinButton = styled(ButtonWithIcon).attrs({
  className: 'join-now-button',
  mode: ButtonMode.primary,
  size: ButtonSize.md,
  icon: VideoCallIcon,
  iconFirst: true,
  fullWidth: true,
})``;

styled(ButtonWithIcon).attrs({
  mode: ButtonMode.secondary,
  size: ButtonSize.md,
  icon: CopyIcon,
  iconFirst: true,
  fullWidth: true,
})``;

export const ConferencePreviewInputCopyLinkButton = styled(
  ButtonWithIcon,
).attrs({
  icon: CopyIcon,
  iconFirst: true,
  fullWidth: true,
})`
  width: auto;
  right: 1px;
  padding: 0.5rem;
  left: unset !important;
  background-color: ${({ theme }) =>
    theme.colors.ConferencePreviewCopyButtonBackground} !important;
`;

export const ConferencePreviewNewMeetingTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.regularSemibold}
  color: ${({ theme }) => theme.colors.OnSurface};
  margin-bottom: 0.3125rem;
`;

export const ConferencePreviewCloseButton = styled(Button).attrs({
  mode: ButtonMode.secondary,
  size: ButtonSize.md,
  fullWidth: true,
})``;

export const PreviewInputWithIcon = styled(InputWithIcon)`
  background-color: ${({ theme }) =>
    theme.colors.ConferencePreviewInputBackground} !important;
`;
