export const WorkspaceConfigFields = `
id
enabled
values
workspace {
  id
}
ssoGroup {
  id
}
tutorial
`;

export const AppSsoGroupFields = `
id
_id
name
logo {
  id
  contentUrl
}
ssoConfigurationFields
custom
customConfigurationMap
compatibleApps
`;

export const WorkspaceConfigsConnection = `
workspaceConfigs(workspace: $workspace) {
  edges {
    node {
      ${WorkspaceConfigFields}
    }
  }
}
`;
