import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocalStorage } from 'react-use';
import { useConfirm } from '../../../shared/components/Modal';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import {
  DESKTOP_SECTION_COPY_APPS_KEY,
  DESKTOP_SECTION_COPY_LINKS_KEY,
  DesktopTab,
} from '../Desktop.constants';
import {
  useCopyAppsToDesktopMutation,
  useCopyLinksToDesktopMutation,
  useCurrentDesktop,
  useDesktopPermissions,
} from '../Desktop.hooks';
import { DesktopTranslation } from '../i18n';
import { CopyPasteSectionDataContext } from './CopyPasteSectionData.context';
import { getFolderIri } from '../Desktop.utils';
import { useCasePasteLink } from '../../Link/UseCase/editLink';
import { useQueryParams } from '../../../shared/hooks';
import { isMacOS } from '../../../shared/utils/user-agent';
import { sendDesktopTrackingEvent } from '../tracking/DesktopTracking.utils';

export const CopyPasteSectionDataProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [copyLinksToDesktopMutation] = useCopyLinksToDesktopMutation();
  const [copyAppsToDesktopMutation] = useCopyAppsToDesktopMutation();
  const desktop = useCurrentDesktop();
  const { canPasteDesktopAppsSection, canPasteDesktopLinksSection } =
    useDesktopPermissions(desktop?.id);
  const { askConfirmation } = useConfirm();
  const { formatMessage } = useIntl();
  const { pasteLinks } = useCasePasteLink();
  const queryParams = useQueryParams();

  /**
   * LocalStorage destructuring
   */

  const [
    copyLinkSectionData,
    setCopyLinkSectionData,
    clearCopyLinkSectionData,
  ] = useLocalStorage<string | null>(DESKTOP_SECTION_COPY_LINKS_KEY, null);

  const [copyAppSectionData, setCopyAppSectionData, clearCopyAppSectionData] =
    useLocalStorage<string | null>(DESKTOP_SECTION_COPY_APPS_KEY, null);

  /**
   * parsedCopyContent functions
   */

  const parsedCopyLinkSectionData = useMemo(
    () => (!copyLinkSectionData ? null : JSON.parse(copyLinkSectionData)),
    [copyLinkSectionData],
  );

  const parsedCopyAppSectionData = useMemo(
    () => (!copyAppSectionData ? null : JSON.parse(copyAppSectionData)),
    [copyAppSectionData],
  );

  /**
   * handleCopyContent / handlePasteContent functions
   */

  const handleCopyLinksDesktopSection = useCallback(
    (linkIds: string[] | null = null) => {
      const linksData = {
        section: DesktopTab.links,
        fromDesktop: desktop!.id,
        ...(linkIds && { linkIds }),
      };

      setCopyLinkSectionData(JSON.stringify(linksData));
      sendDesktopTrackingEvent('copy_desktop_links_section');
    },
    [desktop, setCopyLinkSectionData],
  );

  const handleCopyAppsDesktopSection = useCallback(() => {
    const appsData = {
      section: DesktopTab.apps,
      fromDesktop: desktop!.id,
    };

    setCopyAppSectionData(JSON.stringify(appsData));
    sendDesktopTrackingEvent('copy_desktop_apps_section');
  }, [desktop, setCopyAppSectionData]);

  const handlePasteClipboardDesktopSectionData = useCallback(
    (section: DesktopTab.links | DesktopTab.apps) => {
      if (
        (section === DesktopTab.links &&
          parsedCopyLinkSectionData.section !== DesktopTab.links) ||
        (section === DesktopTab.apps &&
          parsedCopyAppSectionData.section !== DesktopTab.apps)
      ) {
        return;
      }

      askConfirmation(
        formatMessage(
          {
            id:
              section === DesktopTab.apps
                ? DesktopTranslation.pasteClipboardAppsConfirmationDescription
                : DesktopTranslation.pasteClipboardLinksConfirmationDescription,
          },
          {
            desktopName: desktop?.name,
          },
        ),
        formatMessage({
          id:
            section === DesktopTab.apps
              ? DesktopTranslation.pasteClipboardAppsConfirmationTitle
              : DesktopTranslation.pasteClipboardLinksConfirmationTitle,
        }),
        {
          width: 360,
          confirmButtonText: formatMessage({
            id:
              section === DesktopTab.apps
                ? DesktopTranslation.pasteClipboardAppsConfirmationButtonLabel
                : DesktopTranslation.pasteClipboardLinksConfirmationButtonLabel,
          }),
        },
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        const onPasteSuccess = () => {
          if (section === DesktopTab.links) {
            clearCopyLinkSectionData();
            sendDesktopTrackingEvent('paste_desktop_links_section');
          } else {
            clearCopyAppSectionData();
            sendDesktopTrackingEvent('paste_desktop_apps_section');
          }

          showToastSuccessMessage(
            section === DesktopTab.apps
              ? DesktopTranslation.copyDesktopAppsSuccess
              : DesktopTranslation.copyDesktopLinksSuccess,
          );
        };

        if (section === DesktopTab.apps) {
          return copyAppsToDesktopMutation({
            variables: {
              input: {
                id: desktop!.id,
                fromDesktop: parsedCopyAppSectionData.fromDesktop,
              },
            },
          })
            .then(() => {
              onPasteSuccess();
            })
            .catch(error => showToastGraphQLErrors(error.graphQLErrors));
        }

        if (section === DesktopTab.links) {
          if (parsedCopyLinkSectionData.linkIds) {
            pasteLinks(
              parsedCopyLinkSectionData.linkIds,
              desktop?.id,
              queryParams.folderId
                ? getFolderIri(queryParams.folderId as string)
                : undefined,
            );
            onPasteSuccess();
            return;
          }

          return copyLinksToDesktopMutation({
            variables: {
              input: {
                id: desktop!.id,
                fromDesktop: parsedCopyLinkSectionData.fromDesktop,
              },
            },
          })
            .then(() => {
              onPasteSuccess();
            })
            .catch(error => showToastGraphQLErrors(error.graphQLErrors));
        }
      });
    },
    [
      parsedCopyLinkSectionData,
      parsedCopyAppSectionData,
      askConfirmation,
      formatMessage,
      desktop,
      clearCopyLinkSectionData,
      clearCopyAppSectionData,
      copyAppsToDesktopMutation,
      copyLinksToDesktopMutation,
      pasteLinks,
      queryParams,
    ],
  );

  /**
   * showPasteContent functions
   */

  const showPasteLinkSectionButton = useMemo(
    () =>
      parsedCopyLinkSectionData &&
      parsedCopyLinkSectionData.section === DesktopTab.links &&
      canPasteDesktopLinksSection &&
      desktop?.id !== parsedCopyLinkSectionData.fromDesktop,
    [canPasteDesktopLinksSection, desktop?.id, parsedCopyLinkSectionData],
  );

  const showPasteAppSectionButton = useMemo(
    () =>
      parsedCopyAppSectionData &&
      parsedCopyAppSectionData.section === DesktopTab.apps &&
      canPasteDesktopAppsSection &&
      desktop?.id !== parsedCopyAppSectionData.fromDesktop,
    [canPasteDesktopAppsSection, desktop?.id, parsedCopyAppSectionData],
  );

  const pasteLinksOnCtrlKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (
        ((isMacOS() && event.metaKey) || (!isMacOS() && event.ctrlKey)) &&
        event.code === 'KeyV' &&
        parsedCopyLinkSectionData
      ) {
        handlePasteClipboardDesktopSectionData(DesktopTab.links);
      }
    },
    [handlePasteClipboardDesktopSectionData, parsedCopyLinkSectionData],
  );

  useEffect(() => {
    document.addEventListener('keydown', pasteLinksOnCtrlKeydown, false);
    return () => {
      document.removeEventListener('keydown', pasteLinksOnCtrlKeydown, false);
    };
  }, [pasteLinksOnCtrlKeydown]);

  return (
    <CopyPasteSectionDataContext.Provider
      value={{
        copyLinkSectionData,
        setCopyLinkSectionData,
        clearCopyLinkSectionData,
        showPasteLinkSectionButton,
        showPasteAppSectionButton,
        handlePasteClipboardDesktopSectionData,
        handleCopyLinksDesktopSection,
        handleCopyAppsDesktopSection,
      }}>
      {children}
    </CopyPasteSectionDataContext.Provider>
  );
};
