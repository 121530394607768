import styled from 'styled-components';
import { Input } from '../../../../shared/components/Input';

export const StyledSelectVaultListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

export const SelectVaultListItemTitle = styled.label`
  padding-left: 0.5rem;
  cursor: pointer;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
`;

export const SelectVaultListItemRadioInput = styled(Input).attrs({
  type: 'radio',
})`
  margin-right: 0.5rem;
  cursor: pointer;
`;
