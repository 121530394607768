import React, { FC } from 'react';
import {
  OnboardingStep,
  OnboardingStepButton,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../../OnboardingPage.styled';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';

interface EmailVerifiedProps {
  onNextClick: () => void;
  canGoNext?: boolean;
}

export const EmailVerified: FC<EmailVerifiedProps> = ({
  onNextClick,
  canGoNext,
}) => {
  return (
    <OnboardingStep data-testid="email-verified-step">
      <OnboardingStepHeader data-testid="header">
        <FormattedMessage
          id={OnboardingTranslation.onboardingActivationCompletedTitle}
        />
      </OnboardingStepHeader>
      <OnboardingStepText data-testid="text" centered>
        <FormattedMessage
          id={OnboardingTranslation.onboardingActivationCompletedText}
        />
      </OnboardingStepText>

      <OnboardingStepButton
        disabled={!canGoNext}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        onClick={onNextClick}>
        <FormattedMessage
          id={OnboardingTranslation.onboardingActivationCompletedButton}
        />
      </OnboardingStepButton>
    </OnboardingStep>
  );
};
