import { gql } from '@apollo/client';
import { IntegrationFields } from './AppIntegrations.fragments';
import { IntegrationClientApiType } from './AppIntegrations.types';

export const CREATE_INTEGRATION_CLIENT = gql`
  mutation CreateIntegrationClient($input: createIntegrationClientInput!) {
    createIntegrationClient(input: $input) {
      integrationClient {
        ${IntegrationFields}
      }
    }
  }
`;

export interface CreateIntegrationClientVariables {
  input: {
    name: string;
    workspace: string;
    botName: string;
    botImage: string | null;
  };
}

export interface CreateIntegrationClientResponse {
  createIntegrationClient: {
    integrationClient: IntegrationClientApiType;
  };
}

export const EDIT_INTEGRATION_CLIENT = gql`
  mutation EditIntegrationClient($input: editIntegrationClientInput!) {
    __typename
    editIntegrationClient(input: $input) {
      integrationClient {
        ${IntegrationFields}
      }
    }
  }
`;

export interface EditIntegrationClientVariables {
  input: {
    id: string;
    name: string;
    botName: string;
    botImage: string | null;
  };
}

export interface EditIntegrationClientResponse {
  editIntegrationClient: {
    integrationClient: IntegrationClientApiType;
  };
}

export const DELETE_INTEGRATION_CLIENT = gql`
  mutation DeleteIntegrationClient($input: deleteIntegrationClientInput!) {
    deleteIntegrationClient(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteIntegrationClientVariables {
  input: {
    id: string;
  };
}

export interface DeleteIntegrationClientResponse {
  deleteIntegrationClient: {
    clientMutationId: string;
  };
}

export const CREATE_INTEGRATION_CLIENT_HOOK = gql`
  mutation CreateIntegrationClientHook(
    $input: createIntegrationClientHookInput!
  ) {
    createIntegrationClientHook(input: $input) {
      integrationClientHook {
        id
        secret
        desktop {
          id
          name
        }
      }
    }
  }
`;

export interface CreateIntegrationClientHookVariables {
  input: {
    integrationClient: string;
    desktop: string;
  };
}

export interface CreateIntegrationClientHookResponse {
  createIntegrationClientHook: {
    integrationClientHook: {
      id: string;
      secret: string;
      desktop: {
        id: string;
        name: string;
      };
    };
  };
}

export const DELETE_INTEGRATION_CLIENT_HOOK = gql`
  mutation DeleteIntegrationClientHook(
    $input: deleteIntegrationClientHookInput!
  ) {
    deleteIntegrationClientHook(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteIntegrationClientHookVariables {
  input: {
    id: string;
  };
}

export interface DeleteIntegrationClientHookResponse {
  deleteIntegrationClient: {
    clientMutationId: string;
  };
}

export interface InstallMarketplaceItemVariables {
  input: {
    workspace: string;
    appIntegrationMarketplaceItem: string;
  };
}

export interface InstallMarketplaceItemResponse {
  appIntegration: {
    id: string;
  };
}

export const INSTALL_MARKETPLACE_ITEM = gql`
  mutation CreateAppIntegration($input: createAppIntegrationInput!) {
    createAppIntegration(input: $input) {
      appIntegration {
        id
      }
    }
  }
`;

export interface CreateAppIntegrationCredentialVariables {
  input: {
    appIntegrationMarketplaceItem: string;
    workspace: string;
    credentials: CreateAppIntegrationCredential;
  };
}

export interface CreateAppIntegrationCredential {
  username: string;
  password: string;
  domain: string;
}

export interface CreateAppIntegrationCredentialResponse {
  appIntegrationCredential: {
    id: string;
  };
}

export const CREATE_APP_INTEGRATION_CREDENTIAL = gql`
  mutation CreateAppIntegrationCredential(
    $input: createAppIntegrationCredentialInput!
  ) {
    createAppIntegrationCredential(input: $input) {
      appIntegrationCredential {
        id
      }
    }
  }
`;

export interface SwitchAppIntegrationVariables {
  input: {
    appIntegrationIri: string;
    workspace: string;
  };
}

export interface SwitchAppIntegrationResponse {
  appIntegration: {
    id: string;
  };
}

export const SWITCH_APP_INTEGRATION = gql`
  mutation SwitchAppIntegration($input: switchAppIntegrationInput!) {
    switchAppIntegration(input: $input) {
      appIntegration {
        id
      }
    }
  }
`;

export interface RemoveAppIntegrationVariables {
  input: {
    appIntegrationIri: string;
    workspace: string;
  };
}

export interface RemoveAppIntegrationResponse {
  removeAppIntegration: {
    clientMutationId: string;
  };
}

export const REMOVE_APP_INTEGRATION = gql`
  mutation RemoveAppIntegration($input: removeAppIntegrationInput!) {
    removeAppIntegration(input: $input) {
      clientMutationId
    }
  }
`;
