import React from 'react';
import { toast } from 'react-toastify';
import {
  CloseButton,
  DEFAULT_POSITION,
} from '../../../../shared/components/Toast';
import InstallPlugin from '../../../../shared/assets/images/install-plugin.svg';
import styled from 'styled-components';
import { ArrowRightIcon } from '../../../../shared/icons';
import { appEnv } from '../../../../appEnv';
import { Link } from '../../../../shared/components/Link';
import { FormattedMessage } from 'react-intl';
import { ChromeExtensionTranslation } from '../../i18n';
import { checkExtensionInstalled } from '../../../../shared/utils/extension.utils';
import { IS_EXTENSION_TOAST_SHOWN } from '../../../AppRoot/AppRoot.constants';

const INSTALL_PLUGIN_TOAST_ID = 'install-plugin-toast-id';
const PERMITTED_PATHS = [
  '/favorites',
  '/chats',
  '/calls',
  '/desktop',
  '/admin/settings',
];

const StyledInstallPluginToast = styled(Link).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: appEnv.IMPORT_EXTENSION_LINK,
})`
  display: flex;
  font-size: 0.875rem;
  align-items: flex-start;

  p {
    margin-bottom: 0.125rem;
    line-height: 1.25;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    &:first-child {
      font-family: ${({ theme }) => theme.fonts.Semibold};
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }

  button {
    margin-top: 0.375rem;
    font-size: 0.75rem;
    color: ${p => p.theme.colors.Primary};
    font-family: ${({ theme }) => theme.fonts.Semibold};
    &:hover svg {
      margin-left: 0.625rem;
    }
    span {
      flex-shrink: 0;
    }
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
`;

const InstallPluginToastLabel = styled.span`
  display: flex;
  margin-top: 0.375rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  color: ${p => p.theme.colors.Primary};
  svg {
    margin-left: 0.375rem;
    transition: all 0.3s ease;
  }
`;

export const displayInstallPluginToast = () => {
  //TODO Try use better way, for example, router middleware
  if (!PERMITTED_PATHS.some(path => window.location.href.includes(path))) {
    return null;
  }

  if (!!sessionStorage.getItem(IS_EXTENSION_TOAST_SHOWN)) {
    return null;
  }

  checkExtensionInstalled()
    .then(installed => {
      if (!installed) {
        toast(
          <StyledInstallPluginToast>
            <img src={InstallPlugin} alt="" />
            <div>
              <p>
                <FormattedMessage
                  id={ChromeExtensionTranslation.installPluginToastTitle}
                />
              </p>
              <p>
                <FormattedMessage
                  id={ChromeExtensionTranslation.installPluginToastDescription}
                />
              </p>
              <InstallPluginToastLabel>
                <FormattedMessage
                  id={ChromeExtensionTranslation.installPluginToastLinkLabel}
                />
                <ArrowRightIcon width={12} height={12} />
              </InstallPluginToastLabel>
            </div>
          </StyledInstallPluginToast>,
          {
            hideProgressBar: true,
            position: DEFAULT_POSITION,
            closeButton: <CloseButton />,
            autoClose: false,
            toastId: INSTALL_PLUGIN_TOAST_ID,
            draggable: false,
            onClose: () =>
              sessionStorage.setItem(IS_EXTENSION_TOAST_SHOWN, 'true'),
          },
        );
      }
    })
    .catch(() => {});
};
