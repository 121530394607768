import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  AccountApiType,
  AvailabilityStatusType,
} from '../../../../domains/User/User.types';
import { MentionData } from '..';
import { AvatarPlaceholder } from '../../../../domains/Chat/ChatAvatar/AvatarPlaceholder';
import { StyledAvatarImage } from '../../../../domains/Chat/ChatAvatar/AvatarImage/AvatarImage.styled';
import { getGlideImageSrcSet } from '../../../utils/url.utils';
import { getAccountName } from '../../../../domains/User/User.utils';

const IMAGE_SIZE = 64;

const avatarRenderer = (account: AccountApiType) =>
  account.image ? (
    <StyledAvatarImage
      src={account.image.contentUrl}
      srcSet={getGlideImageSrcSet(account.image.contentUrl, {
        w: IMAGE_SIZE,
        h: IMAGE_SIZE,
        fit: 'crop-center',
      })}
      alt={getAccountName(account)}
      data-testid="avatar-image"
    />
  ) : (
    <AvatarPlaceholder account={account} />
  );

const staticOnlineStatusRenderer = (account: AccountApiType) => {
  switch (account.onlineStatus) {
    case AvailabilityStatusType.Online:
      return <div className="static-online-status online"></div>;
    case AvailabilityStatusType.Away:
      return <div className="static-online-status away"></div>;
    case AvailabilityStatusType.Busy:
      return <div className="static-online-status busy"></div>;

    default:
      return <div className="static-online-status offline"></div>;
  }
};

const renderedAvatar = (account: AccountApiType) =>
  ReactDOMServer.renderToStaticMarkup(avatarRenderer(account));

const renderStaticOnlineStatus = (account: AccountApiType) =>
  ReactDOMServer.renderToStaticMarkup(staticOnlineStatusRenderer(account));

export const renderUserMention = (item: MentionData) => `
    <div class="userMention">
      <div class="avat-with-online-status">
        <div class="avatar">
          ${renderedAvatar(item)}
        </div>
        ${renderStaticOnlineStatus(item)}
        </div>
        <div class="icon" />${item.value}
    </div>
`;
