import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useCreateChatConferenceMutation } from '../Conference.hooks';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import type {
  ConferenceApiType,
  ConferenceCallType,
} from '../Conference.types';
import { ConferenceCreateBy } from '../Conference.types';
import type { ConferenceCreateOptions } from '../Conference.utils';
import { getConferencePageLink } from '../Conference.utils';
import { useCreateChatConversationMutation } from '../../Chat/Chat.hooks';
import { CreateChatConversationResponse } from '../../Chat/Chat.mutations';
import { ExecutionResult } from 'graphql';
import { CreateChatConferenceResponse } from '../Conference.mutations';
import { Spinner } from '../../../shared/components/Spinner';
import { StyledCreateConferencePage } from './CreateConferencePage.styled';
import { useNavigate } from 'react-router-dom';
import { getLongId, getShortId } from '../../../shared/utils/id';
import { ConferencePreview } from './ConferencePreview';
import { shouldLogCallCreationToSentry } from '../../../shared/utils/logging.utils';
import {
  useCreateCallInNativeWrapper,
  useNativeWrapper,
} from '../../NativeWrapper';
import { getAccountName } from '../../User/User.utils';
import { ConversationType } from '../../Chat/Chat.types';
import { captureException, captureMessage } from '../../ErrorInterceptor';
import { useConferenceData } from './CreateConferencePage.hooks';
import { database } from '../../Database';
import { CurrentAccountTableTypeToAccountApiType } from '../../Account/data/CurrentAccount/utils/CurrentAccountIDDB.utils';

const MAX_RETRIES = 3;
const RETRY_TIMEOUT = 500;

interface CreateConferencePageProps {
  createConferenceData?: ConferenceCreateOptions;
}

export const CreateConferencePage: FC<CreateConferencePageProps> = ({
  createConferenceData,
}) => {
  const navigate = useNavigate();
  const { currentWorkspaceId, currentAccountId, callType, createBy, id } =
    useConferenceData(createConferenceData);
  const [createChatConferenceMutation] = useCreateChatConferenceMutation();
  const [createChatConversationMutation] = useCreateChatConversationMutation();
  const conferenceCreationRetries = useRef(0);

  const [conferenceForPreview, setConferenceForPreview] =
    useState<ConferenceApiType | null>(null);

  const createConversationIfNeeded =
    useCallback((): Promise<ExecutionResult<CreateChatConversationResponse> | null> => {
      if (createBy === ConferenceCreateBy.accountId && id) {
        return createChatConversationMutation({
          variables: {
            input: {
              workspace: '/workspaces/' + currentWorkspaceId,
              users: ['/accounts/' + id, '/accounts/' + currentAccountId],
            },
          },
        });
      } else if (createBy === ConferenceCreateBy.empty) {
        return createChatConversationMutation({
          variables: {
            input: {
              workspace: '/workspaces/' + currentWorkspaceId,
              users: ['/accounts/' + currentAccountId],
            },
          },
        });
      }
      return Promise.resolve(null);
    }, [
      currentWorkspaceId,
      currentAccountId,
      id,
      createBy,
      createChatConversationMutation,
    ]);

  const { isNativeWrapperAvailable } = useNativeWrapper();
  const createCallInNativeWrapper = useCreateCallInNativeWrapper();

  const apolloClient = useApolloClient();

  const getRecipientData = useCallback(
    async (
      chatConversationIri: string,
      createdById: string,
    ): Promise<{
      recipientName: string | null;
      recipientAvatarUrl: string | null;
    }> => {
      let recipientName: string | null = null;
      let recipientAvatarUrl: string | null = null;
      const cachedConversation = await database.chatConversations.get({
        id: chatConversationIri,
      });
      if (cachedConversation) {
        if (cachedConversation.type !== ConversationType.private) {
          recipientName = cachedConversation.title;
        } else {
          const userIds = cachedConversation.userIds.filter(
            userId => userId !== createdById,
          );
          if (userIds.length === 1 && currentWorkspaceId) {
            const cachedRecipient = await database.accounts
              .get({
                id: userIds[0],
                workspaceId: getLongId(
                  'workspaces',
                  currentWorkspaceId as string,
                ),
              })
              .then(
                accountRow =>
                  accountRow &&
                  CurrentAccountTableTypeToAccountApiType(accountRow),
              );

            if (cachedRecipient) {
              recipientName = getAccountName(cachedRecipient) ?? null;
              recipientAvatarUrl = cachedRecipient.image?.contentUrl
                ? `https:${cachedRecipient.image.contentUrl}`
                : null;
            }
          }
        }
      }
      return {
        recipientName,
        recipientAvatarUrl,
      };
    },
    [currentWorkspaceId],
  );

  // TODO: remove retry workaround when concurrency issue will be solved on backend
  const createConference = useCallback(
    (
      chatConversation: string,
    ): Promise<void | ExecutionResult<CreateChatConferenceResponse> | null> => {
      return createChatConferenceMutation({
        variables: {
          input: {
            callType: callType as ConferenceCallType,
            chatConversation:
              '/chat-conversations/' + getShortId(chatConversation),
          },
        },
      })
        .then(async res => {
          const chatConference = res.data?.createChatConference.chatConference;
          if (!chatConference) {
            return;
          }

          if (createBy === ConferenceCreateBy.empty) {
            setConferenceForPreview(chatConference);
            return;
          }

          if (isNativeWrapperAvailable) {
            const { recipientName, recipientAvatarUrl } =
              await getRecipientData(
                chatConference.chatConversationIri,
                chatConference.createdBy.id,
              ).catch(() => ({
                recipientName: null,
                recipientAvatarUrl: null,
              }));
            createCallInNativeWrapper({
              roomName: chatConference._id,
              token: chatConference.jwtToken,
              callType: chatConference.callType,
              recipientName,
              recipientAvatarUrl,
            });
            return;
          }

          navigate(getConferencePageLink(chatConference.id), {
            state: {
              jwtToken: chatConference.jwtToken,
              callType: chatConference.callType,
            },
            replace: true,
          });
        })
        .catch(e => {
          captureException(e);
          if (conferenceCreationRetries.current < MAX_RETRIES) {
            if (shouldLogCallCreationToSentry()) {
              captureMessage(
                'call createConference() from CreateConferencePage (retry)',
              );
            }
            conferenceCreationRetries.current += 1;
            setTimeout(() => {
              createConference(chatConversation);
            }, RETRY_TIMEOUT);
          } else {
            conferenceCreationRetries.current = 0;
            showToastGraphQLErrors(e.graphQLErrors);
          }
        });
    },
    [
      createChatConferenceMutation,
      callType,
      createBy,
      isNativeWrapperAvailable,
      navigate,
      getRecipientData,
      createCallInNativeWrapper,
    ],
  );

  useEffect(() => {
    if (shouldLogCallCreationToSentry()) {
      captureMessage(
        'call createConversationIfNeeded() from CreateConferencePage',
      );
    }
    createConversationIfNeeded().then(res => {
      const newConversationId =
        res?.data?.createChatConversation?.chatConversation?.id;
      if (newConversationId || id) {
        if (shouldLogCallCreationToSentry()) {
          captureMessage('call createConference() from CreateConferencePage');
        }
        createConference(newConversationId || (id as string));
      }
    });
  }, [createConversationIfNeeded, createConference, id]);

  if (conferenceForPreview) {
    return <ConferencePreview conference={conferenceForPreview} />;
  }

  return (
    <StyledCreateConferencePage>
      <Spinner size={30} />
    </StyledCreateConferencePage>
  );
};
