import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { openUrl } from '../../../../../shared/utils/url.utils';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../../../Timeline/Timeline.constants';
import { useTimelineEventTracker } from '../../../../Timeline/Timeline.hooks';
import { LinkTranslation } from '../../../i18n';
import { LinkApiType } from '../../../Link.types';
import { getLinkTitle } from '../../../Link.utils';
import {
  LinkDetailsTitleButton,
  LinkDetailsTitleContainer,
  LinkDetailsTitleHeading,
  LinkDetailsTitleSubtitle,
  StyledLinkDetailsTitle,
} from './LinkDetailsTitle.styled';
import { CopyIcon, ExternalLinkIcon } from '../../../../../shared/icons';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { showToastSuccessMessage } from '../../../../../shared/components/Toast';
import { useMobile } from '../../../../../shared/hooks';

type LinkDetailsTitleProps = {
  link: LinkApiType;
  disableTrackTimelineEvent: boolean;
};

export const LinkDetailsTitle: FC<LinkDetailsTitleProps> = ({
  link,
  disableTrackTimelineEvent = false,
}) => {
  const trackTimelineEvent = useTimelineEventTracker();
  const { account } = useCurrentAccount();
  const { formatMessage } = useIntl();
  const isMobile = useMobile();

  const linkTitle = useMemo(
    () =>
      link
        ? getLinkTitle(link)
        : formatMessage({ id: LinkTranslation.linkDetailsModalHeader }),
    [formatMessage, link],
  );

  const openLink = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!disableTrackTimelineEvent) {
        trackTimelineEvent(
          TimelineEventScope.links,
          link.id,
          TimelineEventAction.open,
        );
      }

      const url = link.data.url;
      openUrl(account!.identity.openLink, url, linkTitle, event);
    },
    [disableTrackTimelineEvent, link, account, linkTitle, trackTimelineEvent],
  );

  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(link.data.realUrl || link.data.url)
      .then(() => {
        showToastSuccessMessage(LinkTranslation.linkCopiedMessage);
      });
  }, [link.data.realUrl, link.data.url]);

  return (
    <LinkDetailsTitleContainer>
      <StyledLinkDetailsTitle>
        <LinkDetailsTitleHeading>
          {isMobile
            ? formatMessage({ id: LinkTranslation.linkDetailsModalHeader })
            : linkTitle}
        </LinkDetailsTitleHeading>
        <LinkDetailsTitleButton
          onClick={openLink}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: LinkTranslation.linkDetailsTitleButtonTooltipOpenLink,
          })}
          data-tooltip-place={TooltipPlace.bottom}
          aria-label={formatMessage({
            id: LinkTranslation.linkDetailsTitleButtonTooltipOpenLink,
          })}>
          <ExternalLinkIcon width={16} height={16} />
        </LinkDetailsTitleButton>
        <LinkDetailsTitleButton
          className={!isMobile ? 'hide-unless-hover-parent' : undefined}
          onClick={handleCopyLink}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: LinkTranslation.linkDetailsTitleButtonTooltipCopyLink,
          })}
          data-tooltip-place={TooltipPlace.bottom}
          aria-label={formatMessage({
            id: LinkTranslation.linkDetailsTitleButtonTooltipCopyLink,
          })}>
          <CopyIcon width={18} height={18} />
        </LinkDetailsTitleButton>
      </StyledLinkDetailsTitle>
      {isMobile && (
        <LinkDetailsTitleSubtitle>{linkTitle}</LinkDetailsTitleSubtitle>
      )}
    </LinkDetailsTitleContainer>
  );
};
