import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { ChatAvatar } from '../../../Chat/ChatAvatar';

export const OptionChatAvatar = styled(ChatAvatar)`
  margin-right: 0.625rem;
`;

export const StyledAddOption = styled(Button)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const OptionInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const StyledLabel = styled.div`
  text-align: left;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  margin: 0.75rem 0 0.5rem 0;
`;

export const StyledSlackIcon = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
`;

export const StyledCustomOptionLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
