import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Schema, TestContext } from 'yup';
import {
  ConferenceCallType,
  ScheduleChatMembersApiType,
  ScheduleConferenceFormValues,
  ScheduledChatConferenceApiType,
  ScheduledConferenceRepeatingValues,
} from '../../Conference.types';
import { ConferenceTranslation } from '../../i18n';
import { GlobalTranslation } from '../../../Intl/i18n';
import {
  add,
  addMinutes,
  getHours,
  getMinutes,
  isBefore,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  getAddedAndDeletedMembers,
  getFormattedDate,
  getRoundedNextHour,
} from '../../Conference.utils';
import { getInitialTimeZoneOption } from '../../../TimeDate/TimeDate.utils';
import { getAccountName } from '../../../User/User.utils';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { getShortId } from '../../../../shared/utils/id';
import { useCreateChatConversationMutation } from '../../../Chat/Chat.hooks';
import {
  useCreateRepeatingScheduleChatConference,
  useCreateScheduleChatConferenceMutation,
  useCurrentConference,
  useDeleteScheduledChatConferenceMutation,
  useUpdateRepeatingScheduledChatConferenceMutation,
  useUpdateScheduledChatConferenceMutation,
} from '../../Conference.hooks';
import {
  MAX_CONFERENCE_PARTICIPANTS_FREE_WORKSPACE,
  MAX_CONFERENCE_PARTICIPANTS_PRO_WORKSPACE,
  REFETCH_REPEATING_MEETINGS_TRIGGER_VALUE,
  TIME_OUT_TO_REFETCH_REPEATING_MEETINGS,
} from '../../Conference.constants';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { PaymentPlan } from '../../../Billing/Billing.types';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import {
  CreateRepeatingScheduledChatConferenceInputType,
  CreateScheduledChatConferenceInputType,
} from '../../Conference.mutations';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';

export const useScheduleFormValidation =
  (): Schema<ScheduleConferenceFormValues> => {
    const { workspace } = useCurrentWorkspace();

    return useMemo(
      () =>
        Yup.object().shape({
          title: Yup.string().required(
            ConferenceTranslation.scheduleConferenceTopicIsRequired,
          ),
          description: Yup.string(),
          selectedDay: Yup.date().required(),
          selectedEndDay: Yup.date().required(),
          startAt: Yup.date()
            .typeError(GlobalTranslation.invalidDateFormat)
            .test(
              'validateDateInPast',
              ConferenceTranslation.scheduleConferenceStartAtInPast,
              function (this: TestContext) {
                const { selectedDay, startAt, timezone } = this.parent;
                return isBefore(
                  zonedTimeToUtc(
                    new Date(),
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                  ),
                  zonedTimeToUtc(
                    add(selectedDay, {
                      hours: getHours(startAt),
                      minutes: getMinutes(startAt),
                    }),
                    timezone.value,
                  ),
                );
              },
            ),
          endAt: Yup.date()
            .typeError(GlobalTranslation.invalidDateFormat)
            .test(
              'validateIsEarlierThenStartAt',
              ConferenceTranslation.scheduleConferenceEarlierThenStartAt,
              function (this: TestContext) {
                const { endAt, startAt, selectedDay, selectedEndDay } =
                  this.parent;
                return isBefore(
                  add(selectedDay, {
                    hours: getHours(startAt),
                    minutes: getMinutes(startAt),
                  }),
                  add(selectedEndDay, {
                    hours: getHours(endAt),
                    minutes: getMinutes(endAt),
                  }),
                );
              },
            ),
          timezone: Yup.object().shape({
            value: Yup.string().required(
              ConferenceTranslation.scheduleConferenceTimezoneIsRequired,
            ),
            label: Yup.string(),
          }),
          members: Yup.array()
            .max(
              workspace.type === PaymentPlan.FREE
                ? MAX_CONFERENCE_PARTICIPANTS_FREE_WORKSPACE
                : MAX_CONFERENCE_PARTICIPANTS_PRO_WORKSPACE,
              ConferenceTranslation.scheduleConferenceMaxMembersForFreeWorkspace,
            )
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              }),
            ),
          repeating: Yup.object().shape({
            label: Yup.string(),
            value: Yup.number(),
          }),
        }),
      [workspace.type],
    );
  };

export interface initialValuesScheduleFormProps {
  newScheduleConference?: string | null | string[];
  scheduledConference?: ScheduledChatConferenceApiType;
  repeatSelectOptions: {
    label: string;
    value: ScheduledConferenceRepeatingValues;
  }[];
  initialMembers: ScheduleChatMembersApiType[];
}

export const useInitialValuesScheduleForm = (
  props: initialValuesScheduleFormProps,
) => {
  const {
    newScheduleConference,
    scheduledConference,
    repeatSelectOptions,
    initialMembers,
  } = props;

  return {
    title:
      scheduledConference && !newScheduleConference
        ? scheduledConference.title
        : '',
    description:
      scheduledConference && !newScheduleConference
        ? scheduledConference.description || ''
        : '',
    selectedDay: startOfDay(
      !newScheduleConference && scheduledConference
        ? new Date(scheduledConference.startAt)
        : new Date(),
    ),
    selectedEndDay: startOfDay(
      !newScheduleConference && scheduledConference
        ? new Date(scheduledConference.endAt)
        : new Date(),
    ),
    startAt:
      scheduledConference && !newScheduleConference
        ? utcToZonedTime(
            scheduledConference.startAt,
            scheduledConference.timezone,
          )
        : getRoundedNextHour(),
    endAt:
      scheduledConference && !newScheduleConference
        ? utcToZonedTime(
            scheduledConference.endAt,
            scheduledConference.timezone,
          )
        : addMinutes(getRoundedNextHour(), 60),
    timezone:
      scheduledConference && !newScheduleConference
        ? {
            value: scheduledConference.timezone,
            label: scheduledConference.timezone,
          }
        : getInitialTimeZoneOption(),
    members:
      !newScheduleConference && initialMembers
        ? initialMembers.map(member => ({
            value: (member.account ? member.account.id : member.email) || '',
            label:
              (member.account
                ? getAccountName(member.account)
                : member.email) || '',
          }))
        : [],
    repeating:
      scheduledConference?.repeatingScheduleChatConference?.repeatPattern &&
      !newScheduleConference
        ? repeatSelectOptions[
            ScheduledConferenceRepeatingValues.SELECTED_OPTION
          ]
        : repeatSelectOptions[
            ScheduledConferenceRepeatingValues.DOES_NOT_REPEAT
          ],
  };
};

export interface UpdateScheduledConferenceProps {
  scheduledConference?: ScheduledChatConferenceApiType;
  initialMembers: ScheduleChatMembersApiType[];
  onClose: () => void;
}

export const useUpdateScheduledConference = (
  props: UpdateScheduledConferenceProps,
) => {
  const [
    updateScheduleChatConferenceMutation,
    { loading: updateScheduleChatConferenceLoading },
  ] = useUpdateScheduledChatConferenceMutation();
  const [
    updateRepeatingScheduleChatConferenceMutation,
    { loading: updateRepeatingScheduleChatConferenceLoading },
  ] = useUpdateRepeatingScheduledChatConferenceMutation();

  const { scheduledConference, initialMembers, onClose } = props;

  return {
    updateScheduledConference: useCallback(
      (
        values: ScheduleConferenceFormValues,
        updateRepeatingThisAndFollowing = false,
        updateAllRepeatingConferences = false,
      ) => {
        if (scheduledConference && initialMembers) {
          const { addedMembers, deletedMembers } = getAddedAndDeletedMembers(
            values.members,
            initialMembers,
          );

          const targetToUpdateScheduledConference = {
            _id: scheduledConference._id,
            id: scheduledConference.id,
            startAt: getFormattedDate(
              values.startAt,
              values.selectedDay,
              values.timezone.value,
            ),
            endAt: getFormattedDate(
              values.endAt,
              values.selectedEndDay,
              values.timezone.value,
            ),
            title: values.title,
            description: values.description,
            members: {
              added: addedMembers,
              deleted: deletedMembers,
            },
            timezone: values.timezone.value,
          };

          const repeatPattern = values.customRepeating
            ? values.customRepeating
            : scheduledConference.repeatingScheduleChatConference
                ?.repeatPattern;
          const repeatingConferenceId =
            scheduledConference?.repeatingScheduleChatConference?.id;

          if (
            updateRepeatingThisAndFollowing &&
            repeatPattern &&
            repeatingConferenceId
          ) {
            updateRepeatingScheduleChatConferenceMutation({
              variables: {
                input: {
                  ...targetToUpdateScheduledConference,
                  id: repeatingConferenceId,
                  repeatPattern,
                  ...{
                    ...(!updateAllRepeatingConferences && {
                      updateOnlyAfter: scheduledConference.id,
                    }),
                  },
                },
              },
            })
              .then(() => onClose())
              .catch(e => showToastGraphQLErrors(e.graphQLErrors));
          } else {
            updateScheduleChatConferenceMutation({
              variables: {
                input: {
                  ...targetToUpdateScheduledConference,
                },
              },
            })
              .then(() => onClose())
              .catch(e => showToastGraphQLErrors(e.graphQLErrors));
          }
        }
      },
      [
        initialMembers,
        onClose,
        scheduledConference,
        updateRepeatingScheduleChatConferenceMutation,
        updateScheduleChatConferenceMutation,
      ],
    ),
    isScheduleConferenceUpdating:
      updateScheduleChatConferenceLoading ||
      updateRepeatingScheduleChatConferenceLoading,
  };
};

export interface CreateScheduledConferenceProps {
  onClose: () => void;
  workspaceId: string;
  accountId: string;
}

export const useCreateScheduledConference = (
  props: CreateScheduledConferenceProps,
) => {
  const { onClose, workspaceId, accountId } = props;
  const [
    createScheduleChatConferenceMutation,
    { loading: createScheduleChatConferenceLoading },
  ] = useCreateScheduleChatConferenceMutation();
  const [
    createChatConversationMutation,
    { loading: createChatConversationLoading },
  ] = useCreateChatConversationMutation();

  return {
    createScheduleConference: useCallback(
      (scheduleConference: CreateScheduledChatConferenceInputType) => {
        createChatConversationMutation({
          variables: {
            input: {
              workspace: workspaceId,
              users: [accountId],
            },
          },
        })
          .then(res => {
            const chatConversationId =
              res?.data?.createChatConversation?.chatConversation?.id;

            if (chatConversationId) {
              createScheduleChatConferenceMutation({
                variables: {
                  input: {
                    ...scheduleConference,
                    chatConversation: chatConversationId,
                  },
                },
              })
                .then(() => onClose())
                .catch(e => showToastGraphQLErrors(e.graphQLErrors));
            }
          })
          .catch(e => showToastGraphQLErrors(e.graphQLErrors));
      },
      [
        accountId,
        createChatConversationMutation,
        createScheduleChatConferenceMutation,
        onClose,
        workspaceId,
      ],
    ),
    isScheduleConferenceCreating:
      createScheduleChatConferenceLoading || createChatConversationLoading,
  };
};

export const useCreateRepeatScheduledConference = (props: {
  toggleRepeatingConferenceInfo: () => void;
}) => {
  const { toggleRepeatingConferenceInfo } = props;
  const { refetchConferenceFuture } = useCurrentConference();
  const [
    createRepeatingScheduleChatConferenceMutation,
    { loading: createRepeatingScheduleChatConferenceLoading },
  ] = useCreateRepeatingScheduleChatConference();

  return {
    createRepeatScheduledConference: useCallback(
      (
        scheduleConference: CreateRepeatingScheduledChatConferenceInputType,
        values: ScheduleConferenceFormValues,
      ) => {
        return createRepeatingScheduleChatConferenceMutation({
          variables: {
            input: {
              ...scheduleConference,
              askToJoin: false,
              repeatPattern: values.customRepeating!,
            },
          },
        }).then(() => {
          if (
            refetchConferenceFuture &&
            values.customRepeating!.COUNT! >
              REFETCH_REPEATING_MEETINGS_TRIGGER_VALUE
          ) {
            setTimeout(() => {
              refetchConferenceFuture();
            }, TIME_OUT_TO_REFETCH_REPEATING_MEETINGS);
          }
          toggleRepeatingConferenceInfo();
        });
      },
      [
        createRepeatingScheduleChatConferenceMutation,
        refetchConferenceFuture,
        toggleRepeatingConferenceInfo,
      ],
    ),
    isRepeatingScheduleConferenceCreating:
      createRepeatingScheduleChatConferenceLoading,
  };
};

export interface onSubmitProps {
  toggleRepeatingConferenceInfo: () => void;
  newScheduleConference?: string | null | string[];
  scheduledConference?: ScheduledChatConferenceApiType;
  initialMembers: ScheduleChatMembersApiType[];
  workspaceId: string;
  accountId: string;
  onClose: () => void;
}

export const useOnSubmit = (props: onSubmitProps) => {
  const {
    toggleRepeatingConferenceInfo,
    newScheduleConference,
    scheduledConference,
    initialMembers,
    workspaceId,
    accountId,
    onClose,
  } = props;
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [deleteScheduledConference] =
    useDeleteScheduledChatConferenceMutation();

  const {
    createRepeatScheduledConference,
    isRepeatingScheduleConferenceCreating,
  } = useCreateRepeatScheduledConference({ toggleRepeatingConferenceInfo });

  const { createScheduleConference, isScheduleConferenceCreating } =
    useCreateScheduledConference({
      onClose,
      workspaceId,
      accountId,
    });

  const { updateScheduledConference, isScheduleConferenceUpdating } =
    useUpdateScheduledConference({
      scheduledConference,
      initialMembers,
      onClose,
    });

  const isRepeatingChatConference = Boolean(
    !newScheduleConference &&
      scheduledConference?.repeatingScheduleChatConference,
  );

  return {
    onSubmit: (values: ScheduleConferenceFormValues) => {
      if (isRepeatingChatConference) {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            scheduleRepeatingConfirmationOpened: true,
          }),
        });
        return;
      }

      const scheduleConference = {
        workspace: workspaceId,
        callType: ConferenceCallType.video,
        startAt: getFormattedDate(
          values.startAt,
          values.selectedDay,
          values.timezone.value,
        ),
        endAt: getFormattedDate(
          values.endAt,
          values.selectedEndDay,
          values.timezone.value,
        ),
        title: values.title,
        description: values.description,
        members: values.members.map(member =>
          member?.email
            ? { email: member.email }
            : { accountId: getShortId(member.value) },
        ),
        timezone: values.timezone.value,
      };

      if (!newScheduleConference) {
        if (values.customRepeating && scheduledConference) {
          createRepeatScheduledConference(
            scheduleConference as CreateRepeatingScheduledChatConferenceInputType,
            values,
          ).then(() => {
            deleteScheduledConference({
              variables: {
                input: {
                  id: scheduledConference.id,
                  _id: '',
                  workspace: workspaceId,
                },
              },
            });
          });
          return;
        } else {
          updateScheduledConference(values);
          return;
        }
      }

      if (values.customRepeating) {
        createRepeatScheduledConference(
          scheduleConference as CreateRepeatingScheduledChatConferenceInputType,
          values,
        );
        return;
      }

      createScheduleConference(scheduleConference);
    },
    isSubmitting:
      isScheduleConferenceCreating ||
      isScheduleConferenceUpdating ||
      isRepeatingScheduleConferenceCreating,
  };
};
