import styled, { css } from 'styled-components';
import { SegmentPage } from '../../../Segment/Segment.styled';

export const StyledDesktopContent = styled(SegmentPage)<{
  isMobile: boolean;
}>`
  background: ${({ theme }) => theme.colors.Surface};
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
`;

export const LoadingState = styled(SegmentPage)<{
  isMobile: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.Surface};

  ${({ isMobile, theme }) =>
    isMobile &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${theme.colors.Surface};
    `}
`;
