import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { OnboardingTranslation } from '../../i18n';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep } from '../OnboardingPage.styled';
import { AboutTeamForm } from './AboutTeam.form';
import { AboutTeamStepHeader } from './AboutTeamPage.styled';
import { AboutTeamFormValues, SignUpStep } from '../../Onboarding.types';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useChangeTeamSizeForAccountMutation } from '../../Onboarding.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { generatePath, Navigate } from 'react-router-dom';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const AboutTeamPage: FC = () => {
  const { account, refetchAccountData } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const [changeAboutTeamForAccount] = useChangeTeamSizeForAccountMutation();
  const onSubmit = useCallback(
    (values: AboutTeamFormValues) => {
      if (!account?.id) {
        return;
      }
      return changeAboutTeamForAccount({
        variables: {
          input: {
            id: account.id,
            teamSize: values.teamSize,
          },
        },
      })
        .then(() => refetchAccountData?.())
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [account?.id, changeAboutTeamForAccount, refetchAccountData],
  );

  if (account?.currentStep !== SignUpStep.about_team) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <OnboardingPage horizontallyCentered>
      <OnboardingStep data-testid="registration-step">
        <OnboardingStepsCounter currentStep={account?.currentStep} />
        <AboutTeamStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingTeamSizePageTitle}
          />
        </AboutTeamStepHeader>

        <AboutTeamForm onSubmit={onSubmit} />
      </OnboardingStep>
    </OnboardingPage>
  );
};
