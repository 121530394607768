import React, { FC, useEffect, useState } from 'react';
import { StyledEncryptedState } from './EncryptedState.styled';
import { MasterPasswordLockScreen } from '../../MasterPasswordLockScreen';
import { useCurrentWorkspaceAccountPrivateKey } from '../../Encryption.hooks';
import { useMobile } from '../../../../shared/hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { isCurrentAccountHasPassword } from '../../../User/User.utils';
import { SetPasswordView } from './SetPasswordView';
import { useMobileNavigationSidebar } from '../../../Workspace/Workspace.hooks';

export const EncryptedState: FC = () => {
  const {
    decryptCurrentAccountPrivateKey,
  } = useCurrentWorkspaceAccountPrivateKey();
  const isMobile = useMobile();
  const { account: currentAccount } = useCurrentAccount();
  const accountHasPassword = isCurrentAccountHasPassword(currentAccount);
  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();
  const [didInit, setDidInit] = useState(false);

  useEffect(() => {
    if (!didInit) {
      mobileNavigationSidebarClose();
      setDidInit(true);
    }
  }, [didInit, mobileNavigationSidebarClose]);

  return (
    <StyledEncryptedState isMobile={isMobile}>
      {accountHasPassword ? (
        <MasterPasswordLockScreen
          decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
        />
      ) : (
        <SetPasswordView />
      )}
    </StyledEncryptedState>
  );
};
