import React, { FC, useCallback, useEffect } from 'react';
import { useAuthService, useCurrentAccount } from '../Auth.hooks';
import { getAuth0ReturnUriHost } from '../Auth.utils';
import { AppShellSkeleton } from '../../AppRoot/AppShellSkeleton';
import { LOGIN_PATHNAME } from '../Auth.constants';
import { shouldLogAuthToSentry } from '../../../shared/utils/logging.utils';
import { AvailabilityStatusType } from '../../User/User.types';
import { usePublishPing } from '../../Mercure/Ping/PingMercure.hooks';
import { captureMessage } from '../../ErrorInterceptor';
import { useBroadcastChannelContext } from '../../BroadcastChannel/BroadcastChannel.hooks';
import { BroadcastChannelMessageType } from '../../BroadcastChannel/BroadcastChannel.types';
import { useQueryParams } from '../../../shared/hooks';
import { delay } from '../../../shared/utils/retry.utils';
import { useIntl } from 'react-intl';
import { AuthTranslation } from '../i18n';

const DISPLAY_MFA_SESSION_TIMEOUT_MESSAGE_DELAY = 5000;

export const LogoutPage: FC = () => {
  const authService = useAuthService();
  const { account } = useCurrentAccount();
  const publishPing = usePublishPing();
  const { postMessage } = useBroadcastChannelContext();
  const { displayMfaSessionTimeoutMessage, sendLogoutMessageToOtherTabs } =
    useQueryParams();
  const intl = useIntl();

  const logout = useCallback(async () => {
    if (account) {
      publishPing(AvailabilityStatusType.Offline);
    }

    if (sendLogoutMessageToOtherTabs) {
      postMessage(BroadcastChannelMessageType.LOGOUT);
    }

    if (shouldLogAuthToSentry()) {
      captureMessage('[LogoutPage]  call authService.logout()');
    }

    if (displayMfaSessionTimeoutMessage) {
      await delay(DISPLAY_MFA_SESSION_TIMEOUT_MESSAGE_DELAY);
    }

    await authService.logout({
      returnTo: getAuth0ReturnUriHost() + LOGIN_PATHNAME,
    });
  }, [
    account,
    authService,
    publishPing,
    postMessage,
    displayMfaSessionTimeoutMessage,
    sendLogoutMessageToOtherTabs,
  ]);

  useEffect(() => {
    if (shouldLogAuthToSentry()) {
      captureMessage('[LogoutPage] call logout() from useEffect');
    }

    logout();
  }, [logout]);

  return (
    <AppShellSkeleton
      message={
        displayMfaSessionTimeoutMessage
          ? intl.formatMessage({
              id: AuthTranslation.logoutAfterMfaSessionTimeoutMessage,
            })
          : undefined
      }
    />
  );
};
