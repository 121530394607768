import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ExternalServiceSignInWithGoogle,
  useIsGoogle,
  useServiceName,
} from '.';
import {
  GoogleAuthStatusTypes,
  useBrowserGoogleAuthStatusContext,
} from '../BrowserGoogleAuthStatus';
import { ImageApiType } from '../../shared/api/api.types';
import { useQueryParams } from '../../shared/hooks';
import { getQueryParamsFrom, openUrl } from '../../shared/utils/url.utils';
import { LinkDocumentMetaData } from '../Link/Link.types';
import { ExternalServiceAuthTranslation } from './ExternalServiceAuthContent/i18n';
import { useNativeWrapper } from '../NativeWrapper';
import { ExternalServiceAuthNotSupported } from './ExternalServiceAuthContent/ExternalServiceAuth.notSupported';
import { OpenType } from '../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';

interface ExternalServiceAuthContentProps {
  linkMetaData: LinkDocumentMetaData;
  icon?: ImageApiType;
  linkUrl: string;
}

export const ExternalServiceAuth: FC<ExternalServiceAuthContentProps> = ({
  linkMetaData,
  icon,
  linkUrl,
}) => {
  const isGoogle = useIsGoogle(linkMetaData.type);
  const signInWindow = useRef<Window | null>(null);
  const serviceName = useServiceName(linkMetaData.type);
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { browserGoogleAuthUrl, browserGoogleAuthorizationStatus } =
    useBrowserGoogleAuthStatusContext();

  const needGoogleAuthInBrowser =
    browserGoogleAuthorizationStatus === GoogleAuthStatusTypes.unauthorized;

  const intl = useIntl();

  const closeLinkDetailsModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        linkDetails: undefined,
        linkDetailsTab: undefined,
        chatLink: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const openSignInWindow = useCallback(() => {
    if (needGoogleAuthInBrowser) {
      closeLinkDetailsModal();
    }

    signInWindow.current = window.open(
      needGoogleAuthInBrowser
        ? browserGoogleAuthUrl
        : linkMetaData.authorization_url,
      intl.formatMessage(
        {
          id: ExternalServiceAuthTranslation.signInWindowTitle,
        },
        {
          serviceName,
        },
      ),
      'height=600, width=450',
    );

    if (signInWindow.current && signInWindow.current.focus) {
      signInWindow.current.focus();
    }
  }, [
    closeLinkDetailsModal,
    browserGoogleAuthUrl,
    intl,
    linkMetaData.authorization_url,
    needGoogleAuthInBrowser,
    serviceName,
  ]);

  useEffect(
    () => () => {
      if (
        !needGoogleAuthInBrowser &&
        signInWindow.current &&
        signInWindow.current.close
      ) {
        signInWindow.current.close();
      }
    },
    [needGoogleAuthInBrowser],
  );

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const openNatively = () => {
    openUrl(OpenType.newTab, linkUrl, '');
  };

  if (isNativeWrapperAvailable) {
    return <ExternalServiceAuthNotSupported onClick={openNatively} />;
  }

  if (isGoogle) {
    return (
      <ExternalServiceSignInWithGoogle signIn={openSignInWindow} icon={icon} />
    );
  }
  return null;
};
