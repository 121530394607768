import styled from 'styled-components';

export const StyledTimelineDay = styled.div`
  & ~ & {
    margin-top: 22px;
  }
`;

export const TimelineDayTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.625rem;
  line-height: 1.5;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;
