import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import { useNavigate } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { Modal, ModalHeaderColor } from '../../../../shared/components/Modal';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { ScheduleConferenceForm } from '../ScheduleConferenceForm';
import {
  ButtonControls,
  StyledScheduleConferenceModal,
  StyledScheduleRepeatingInfo,
} from './ScheduleConferenceModal.styled';
import { useTheme } from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { GlobalTranslation } from '../../../Intl/i18n';
import { useCurrentConference } from '../../Conference.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import { ConferencesTabsValues } from '../../Conference.types';

export const ScheduleConferenceModal: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const isMobile = useMobile();
  const { scheduledConference, scheduledConferenceLoading } =
    useCurrentConference();
  const modalBackgroundColor = theme.colors.Surface;
  const modalTextColor = theme.colors.OnSurfaceHighEmphasis;
  const [isRepeatingInfoVisible, setRepeatingInfoVisible] = useState(false);
  const { newScheduleConference } = queryParams;

  const onClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
        scheduleConferenceOpened: undefined,
        newScheduleConference: undefined,
        scheduleRepeatingConfirmationOpened: undefined,
        createScheduleConferenceOpened: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const toggleRepeatingInfo = () => {
    setRepeatingInfoVisible(prevState => !prevState);
  };
  const showSpinner =
    scheduledConferenceLoading &&
    !newScheduleConference &&
    !scheduledConference;

  return (
    <Modal
      visible
      onRequestClose={onClose}
      width={700}
      fullScreen={isMobile}
      disableOutsideModalClose>
      <StyledScheduleConferenceModal>
        <ModalHeaderColor
          onRequestClose={onClose}
          backgroundColor={modalBackgroundColor}
          textColor={modalTextColor}>
          <FormattedMessage
            id={ConferenceTranslation.scheduleConferenceMeeting}
          />
        </ModalHeaderColor>
        <div className="divider" />
        {isRepeatingInfoVisible ? (
          <StyledScheduleRepeatingInfo>
            <FormattedMessage
              id={ConferenceTranslation.scheduleConferenceRepeatingInfo}
            />
            <ButtonControls>
              <Button
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onClose}>
                <FormattedMessage id={GlobalTranslation.ok} />
              </Button>
            </ButtonControls>
          </StyledScheduleRepeatingInfo>
        ) : showSpinner ? (
          <Spinner containerHeight={550} />
        ) : (
          <ScheduleConferenceForm
            onClose={onClose}
            toggleRepeatingConferenceInfo={toggleRepeatingInfo}
          />
        )}
      </StyledScheduleConferenceModal>
    </Modal>
  );
};
