import React from 'react';
import { NotificationsControls } from './Notifications.types';

export const NotificationsContext = React.createContext<NotificationsControls>({
  toggleNotifications: () => {},
  closeNotifications: () => {},
  keepNotificationsOpen: (isOpen: boolean) => {},
  isOpen: false,
  keepOpen: false,
});
