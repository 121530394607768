import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../../shared/hooks';
import { getShortId } from '../../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { useSearchMessageData } from '../../../../Chat.hooks';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { getIDDBChatMessage } from '../../../../Data/Repository/ChatMessage/ChatMessageIDDBRepository';
import { CHAT_ID_PATHNAME } from '../../../../Chat.constants';

const OPEN_THREAD_DELAY = 200;

export const useOpenThreadSidebar = () => {
  const navigate = useNavigate();
  const { getChatMessageById } = useChatMessageRepository();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const { setSearchMessage } = useSearchMessageData();

  const { linkDetails, linkDetailsTab } = queryParams;

  return {
    openThreadSidebar: async (
      messageId: string,
      conversationId: string,
      message_id?: string,
      navigateWithExplicitChatPathname = false,
    ) => {
      const cachedMessage = await getIDDBChatMessage(messageId);

      let message = cachedMessage;

      if (!message) {
        message =
          (await getChatMessageById(workspace.id, conversationId, messageId)) ||
          cachedMessage;
      }

      setTimeout(() => {
        if (message?.id) {
          navigate({
            ...(navigateWithExplicitChatPathname
              ? {
                  pathname: generatePath(CHAT_ID_PATHNAME, {
                    workspaceId: getShortId(workspace.id),
                    chatId: getShortId(message.conversationId),
                  }),
                }
              : {}),
            search: getQueryParamsFrom({
              thread: getShortId(message.id),
              conversation: getShortId(message.conversationId),
              ...(message_id && {
                highlight: getShortId(message_id),
              }),
              ...(linkDetails && { linkDetails }),
              ...(linkDetailsTab && {
                linkDetailsTab,
              }),
            }),
          });

          setSearchMessage({
            id: message.id,
            createdAt: message.createdAt.toString(),
          });
        }
      }, OPEN_THREAD_DELAY);
    },
  };
};
