import React, { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { SearchThinIcon } from '../../../../shared/icons';
import { InputSize } from '../../../../shared/components/Input';
import { useIntl } from 'react-intl';
import {
  FilterInput,
  StyledMembersFilters,
  StyledMembersFiltersWrapper,
} from './MemberFilter.styled';
import { ChatTranslation } from '../../i18n';
import { useDebouncedCallback } from 'use-debounce';

const SEARCH_DEBOUNCE_TIME = 500;

interface MemberFilterProps {
  onFilterValueChange: (filterValue: string) => void;
  membersSidebarRef?: RefObject<HTMLDivElement>;
}

export const MembersFilter: FC<MemberFilterProps> = ({
  onFilterValueChange,
  membersSidebarRef,
}) => {
  const intl = useIntl();
  const [filterValue, setFilterValue] = useState('');

  const debouncedCallback = useDebouncedCallback(value => {
    if (onFilterValueChange) {
      onFilterValueChange(value);
    }
  }, SEARCH_DEBOUNCE_TIME || 0);

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      setFilterValue(value);
      debouncedCallback(value);
    },
    [debouncedCallback],
  );

  const clearFilterValue = useCallback(
    (event: Event) => {
      if (
        event &&
        !membersSidebarRef?.current?.contains(event.target as Node)
      ) {
        setFilterValue('');
        debouncedCallback('');
        document.removeEventListener('mousedown', clearFilterValue);
      }
    },
    [membersSidebarRef, debouncedCallback],
  );

  const handleOnFocus = useCallback(() => {
    document.addEventListener('mousedown', clearFilterValue);
  }, [clearFilterValue]);

  useEffect(
    () => () => {
      setFilterValue('');
      debouncedCallback('');
      document.removeEventListener('mousedown', clearFilterValue);
    },
    [clearFilterValue, setFilterValue, debouncedCallback],
  );

  return (
    <StyledMembersFiltersWrapper>
      <StyledMembersFilters>
        <SearchThinIcon width={12} height={12} />
        <FilterInput
          data-testid="filter-members"
          onFocus={handleOnFocus}
          value={filterValue}
          size={InputSize.sm}
          placeholder={intl.formatMessage({
            id: ChatTranslation.filterMembersPlaceholder,
          })}
          onChange={handleFilterChange}
        />
      </StyledMembersFilters>
    </StyledMembersFiltersWrapper>
  );
};
