import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SuccessIcon from '../../../../shared/assets/images/success-light.svg';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { ModalHeaderTitle } from '../../../../shared/components/Modal';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { ImportStepTypes } from '../../ChromeExtension.types';
import { ChromeExtensionTranslation } from '../../i18n';

export const ImportSuccessContent = styled.div`
  text-align: center;
  max-width: 590px;
  margin: 0 auto;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const ImportSuccessTitle = styled(ModalHeaderTitle)`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 134px;
`;

export const SuccessImage = styled.img`
  margin-bottom: 1.5rem;
`;

export const ImportSuccessSubtitle = styled.div`
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const ImportSuccessDescription = styled.div`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin-bottom: 2.25rem;
`;

export const ImportSuccessButtonGroup = styled.div`
  button {
    margin-bottom: 1.5rem;
  }
`;

export const ImportSuccess: FC<{}> = () => {
  const currentDesktop = useCurrentDesktop();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { importStep } = queryParams;

  switch (importStep) {
    case ImportStepTypes.importAppsSuccess:
    case ImportStepTypes.importLinksSuccess:
      break;
    default:
      return null;
  }

  return (
    <>
      <ImportSuccessTitle>
        {importStep === ImportStepTypes.importAppsSuccess ? (
          <FormattedMessage id={ChromeExtensionTranslation.importAppsTitle} />
        ) : (
          <FormattedMessage id={ChromeExtensionTranslation.importLinksTitle} />
        )}
      </ImportSuccessTitle>

      <ImportSuccessContent>
        <SuccessImage src={SuccessIcon} />
        <ImportSuccessSubtitle>
          {importStep !== ImportStepTypes.importAppsSuccess && (
            <FormattedMessage
              id={ChromeExtensionTranslation.importLinksAddedSubtitle}
            />
          )}
        </ImportSuccessSubtitle>
        <ImportSuccessDescription>
          {importStep === ImportStepTypes.importAppsSuccess ? (
            <FormattedMessage id={ChromeExtensionTranslation.importAppsAdded} />
          ) : (
            <FormattedMessage
              id={ChromeExtensionTranslation.importLinksAddedDescription}
            />
          )}
        </ImportSuccessDescription>

        <ImportSuccessButtonGroup>
          {importStep === ImportStepTypes.importAppsSuccess && (
            <Button
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              type="button"
              fullWidth
              onClick={() => {
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    importStep: ImportStepTypes.selectLinks,
                  }),
                });
              }}>
              <FormattedMessage
                id={ChromeExtensionTranslation.importContinueWithLinks}
              />
            </Button>
          )}

          <Button
            mode={
              importStep === ImportStepTypes.importAppsSuccess
                ? ButtonMode.secondary
                : ButtonMode.primary
            }
            size={ButtonSize.md}
            type="button"
            fullWidth
            onClick={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  importModal: undefined,
                  importStep: undefined,
                }),
              });
            }}>
            {importStep === ImportStepTypes.importAppsSuccess ? (
              <FormattedHTMLMessage
                id={ChromeExtensionTranslation.importGoToDesktop}
                values={{ desktop: currentDesktop?.name }}
              />
            ) : (
              <FormattedHTMLMessage
                id={ChromeExtensionTranslation.importGoToAccount}
              />
            )}
          </Button>
        </ImportSuccessButtonGroup>
      </ImportSuccessContent>
    </>
  );
};
