import React, { FC, useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep } from '../OnboardingPage.styled';
import {
  DEFAULT_FLOW,
  INVITATION_PATHNAME,
  REGISTRATION_PATHNAME,
} from '../../Onboarding.constants';
import { useQueryParams } from '../../../../shared/hooks';
import { useAuthService, useCurrentAccount } from '../../../Auth/Auth.hooks';
import { useAcceptAccountInvitation } from '../../../User/User.hooks';
import { GET_WORKSPACES_BY_IDENTITY } from '../../../Workspace/Workspace.queries';
import { Spinner } from '../../../../shared/components/Spinner';
import { InvitationActions } from './InvitationActions';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { getAuth0ReturnUriHost } from '../../../Auth/Auth.utils';
import { LOGIN_PATHNAME } from '../../../Auth/Auth.constants';
import { useGetForRegistrationAccountInvitation } from '../../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

// TODO: Quick page to show the flow, proper styling has to be added
export const InvitationPage: FC = () => {
  const navigate = useNavigate();
  const { id: inviteId } = useQueryParams();
  const authService = useAuthService();
  const { account } = useCurrentAccount();
  const [processing, setProcessing] = useState(false);
  const [acceptAccountInvitation] = useAcceptAccountInvitation();

  const { data: invitationData, loading: invitationLoading } =
    useGetForRegistrationAccountInvitation({
      skip: !inviteId,
      variables: {
        id: `/account-invitations/${inviteId}`,
      },
    });
  const invitation = invitationData?.getForRegistrationAccountInvitation;

  const acceptWithCurrentAccount = useCallback(() => {
    setProcessing(true);
    acceptAccountInvitation({
      variables: {
        input: {
          id: `/account-invitations/${inviteId as string}`,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_WORKSPACES_BY_IDENTITY,
          variables: {
            id: account!.identityId,
          },
        },
      ],
    })
      .then(({ data }) => {
        setProcessing(false);
        if (!data) {
          return;
        }

        if (!data.acceptAccountInvitation.accountInvitation) {
          navigate('/');
          return;
        }

        const { referenceId, desktopId } =
          data.acceptAccountInvitation.accountInvitation;
        navigate(
          `/workspace/${referenceId}${
            desktopId ? `/desktop/${desktopId}` : '/chats'
          }`,
        );
      })
      .catch(e => {
        setProcessing(false);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [acceptAccountInvitation, inviteId, account, navigate]);

  const loginWithAnotherAccount = useCallback(() => {
    if (!authService) {
      return;
    }
    authService.logout({
      returnTo: `${getAuth0ReturnUriHost()}${LOGIN_PATHNAME}?returnTo=${
        window.location.origin
      }${INVITATION_PATHNAME}?id=${inviteId}`,
    });
  }, [authService, inviteId]);

  const registerNewAccount = useCallback(() => {
    if (!authService) {
      return;
    }
    authService.logout({
      returnTo: `${getAuth0ReturnUriHost()}${REGISTRATION_PATHNAME}/${DEFAULT_FLOW}?id=${inviteId}`,
    });
  }, [authService, inviteId]);

  if (account === null) {
    return (
      <Navigate
        to={`${REGISTRATION_PATHNAME}/${DEFAULT_FLOW}?id=${inviteId}`}
      />
    );
  }

  if (!invitation && !invitationLoading) {
    return <Navigate to="/" />;
  }

  return (
    <OnboardingPage>
      <OnboardingStep>
        {invitationLoading || processing ? (
          <Spinner containerHeight={280} />
        ) : (
          <InvitationActions
            invitation={invitation!}
            onAcceptWithCurrentAccount={acceptWithCurrentAccount}
            onLoginWithAnotherAccount={loginWithAnotherAccount}
            onRegisterNewAccount={registerNewAccount}
          />
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
