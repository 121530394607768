import { gql } from '@apollo/client';
import { ImageApiType } from '../../shared/api/api.types';

export const CREATE_IMAGE_ASSET = gql`
  mutation createImageAsset($file: Upload!) {
    createImageAsset(input: { file: $file }) {
      imageAsset {
        id
        contentUrl
      }
    }
  }
`;

export interface createImageAssetVariables {
  file: File;
}

export interface createImageAssetResponse {
  createImageAsset: {
    imageAsset: ImageApiType;
  };
}
