import { AttachmentAction, AttachmentState } from './Attachment.types';
import {
  ACTION_TYPE_REMOVE_ATTACHMENT,
  ACTION_TYPE_RESET_ATTACHMENTS,
  ACTION_TYPE_UPLOAD_ATTACHMENT_FAILED,
  ACTION_TYPE_UPLOAD_ATTACHMENT_STARTED,
  ACTION_TYPE_UPLOAD_ATTACHMENT_SUCCESS,
} from './Attachment.constants';
import type { FileAssetApiType, OriginalVideoMetaData } from '../Asset.types';
import { FILE_ASSET_ID_PREFIX } from '../Asset.constants';

export const attachmentReducer = (
  state: AttachmentState,
  action: AttachmentAction,
): AttachmentState => {
  switch (action.type) {
    case ACTION_TYPE_UPLOAD_ATTACHMENT_STARTED: {
      const attachment = {
        id: action.id,
        file: action.file,
      };
      return {
        ...state,
        attachments: [...state.attachments, attachment],
      };
    }
    case ACTION_TYPE_UPLOAD_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachments: state.attachments.map(attachment =>
          attachment.id === action.id
            ? {
                ...attachment,
                asset: action.asset,
              }
            : attachment,
        ),
      };
    }
    case ACTION_TYPE_REMOVE_ATTACHMENT:
    case ACTION_TYPE_UPLOAD_ATTACHMENT_FAILED: {
      const originalAssetId = action.id.replace(FILE_ASSET_ID_PREFIX, '');

      return {
        ...state,
        attachments: state.attachments
          .filter(
            attachment =>
              !(
                (
                  (attachment.asset as FileAssetApiType)
                    ?.metadata as OriginalVideoMetaData
                )?.preview_asset_id === originalAssetId
              ),
          )
          .filter(attachment => attachment.id !== action.id),
      };
    }
    case ACTION_TYPE_RESET_ATTACHMENTS: {
      return {
        ...state,
        attachments: action.attachments,
      };
    }
    default:
      return state;
  }
};
