import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AttachmentDetails,
  IconContainer,
  ImageWrapper,
  StyledUploadAttachment,
} from './UploadAttachment.styled';
import { RemoveButton } from './RemoveButton';
import { Attachment } from '../../../../../../Asset/Attachment/Attachment.types';
import { isImageMimeType } from '../../../../../../Asset';
import { ChatDocumentIcon } from '../../../../../../../shared/icons';
import { ChatTranslation } from '../../../../../i18n';

interface UploadAttachmentProps {
  attachment: Attachment;
  onRemove: (id: string) => void;
}

export const UploadAttachment: FC<UploadAttachmentProps> = ({
  attachment,
  onRemove,
}) => {
  const intl = useIntl();

  const [imgSrc, setImgSrc] = useState(attachment.asset?.contentUrl);
  const isImage = isImageMimeType(
    attachment.file?.type || attachment.asset?.mimeType,
  );

  useEffect(() => {
    if (!isImage || !attachment.file) {
      return;
    }

    const objectUrl = URL.createObjectURL(attachment.file);
    setImgSrc(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [attachment.file, isImage]);

  const uploading = Boolean(attachment.file && !attachment.asset);

  const fileName = attachment.file?.name || attachment.asset?.filename;

  return (
    <StyledUploadAttachment
      processing={uploading}
      isImage={isImage}
      data-testid="upload-attachment">
      <RemoveButton
        onClick={() => {
          onRemove(attachment.id);
        }}
        aria-label={intl.formatMessage({
          id: ChatTranslation.attachmentRemoveButtonLabel,
        })}
        data-testid="remove-button"
      />
      {isImage ? (
        <ImageWrapper>
          <img
            src={imgSrc}
            alt={intl.formatMessage({
              id: ChatTranslation.attachmentImagePreviewAlt,
            })}
            data-testid="image"
          />
        </ImageWrapper>
      ) : (
        <>
          <IconContainer>
            <ChatDocumentIcon />
          </IconContainer>
          <AttachmentDetails data-testid="attachment-details">
            {fileName}
          </AttachmentDetails>
        </>
      )}
    </StyledUploadAttachment>
  );
};
