import { useCallback, useContext, useEffect, useState } from 'react';
import { NotificationsContext } from '../../../domains/Notifications/Notifications.context';

export const useModalControls = () => {
  const { keepNotificationsOpen } = useContext(NotificationsContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      keepNotificationsOpen(true);
    } else {
      keepNotificationsOpen(false);
    }
  }, [keepNotificationsOpen, visible]);

  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);
  return { visible, open, close };
};
