import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const NewCustomIntegrationHooksBodyWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xl};
  overflow: hidden;
`;

export const IntegrationHooksWrapper = styled.div`
  max-width: 26.25rem;
`;

export const IntegrationHookWrapper = styled.div`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`;

export const IntegrationHook = styled.div`
  ${({ theme }) => theme.fontTemplates.headerMain};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.xs}
    ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.m};
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const IntegrationHookAddButton = styled(ButtonWithIcon)`
  ${({ theme }) => theme.fontTemplates.regularSemibold};
  width: 100%;
  line-height: 1.43;
  color: ${p => p.theme.colors.Primary};
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing.l};
  padding: 0 ${({ theme }) => theme.spacing.l};
`;

export const DeleteButton = styled(ButtonWithIcon)`
  color: ${p => p.theme.colors.CriticalLight};
  padding: ${({ theme }) => theme.spacing.xs};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }
`;
