import styled from 'styled-components';

export const StyledEditUserForm = styled.div`
  padding: 12px 24px 24px;
`;

export const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 6px;
  }
`;
