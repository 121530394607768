import styled from 'styled-components';
// TODO: Make table mobile friendly

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
`;

export const TableHead = styled.thead`
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
  height: 2.5rem;
`;

export const TableHeader = styled.th`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
  font-family: ${p => p.theme.fonts.Regular};
  text-align: left;
  line-height: 40px;
  padding-left: 1rem;

  &:last-of-type {
    padding-right: 1rem;
  }
`;

export const TableBody = styled.tbody`
  &::before {
    content: '';
    line-height: 1em;
    display: block;
  }

  tr:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${p => p.theme.colors.Background};
  }
`;

export const TableCell = styled.td`
  line-height: 40px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  position: relative;
  padding-left: 1rem;

  &:first-of-type {
    &::before {
      content: ' ';
      background: ${p => p.theme.colors.Surface};
      width: 9px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &:last-of-type {
    padding-right: 1rem;

    &::after {
      content: ' ';
      background: ${p => p.theme.colors.Surface};
      width: 9px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
`;
