import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { NativeWrapperContext } from './NativeWrapper.context';
import { MessageFromNativeWrapper } from './NativeWrapper.types';
import {
  isNativeWrapperAvailable,
  sendMessageToNativeWrapper,
} from './NativeWrapper.utils';
import { usePersistedStorage } from '../PersistedStorage';
import { INITIAL_ACCESS_TOKEN_STORAGE_KEY } from './NativeWrapper.constants';
import { AppShellSkeleton } from '../AppRoot/AppShellSkeleton';
import { captureException } from '../ErrorInterceptor';

export const NativeWrapperProvider: FC<PropsWithChildren> = ({ children }) => {
  const { storage } = usePersistedStorage();
  const [isAvailable] = useState(() => isNativeWrapperAvailable());
  const [initialAccessToken, setInitialAccessToken] = useState<string | null>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAvailable) {
      setInitialAccessToken('');
      return;
    }

    if (initialAccessToken) {
      return;
    }

    const accessToken = window.location.hash.replace('#accessToken=', '');
    if (accessToken) {
      navigate(
        {
          hash: '',
        },
        { replace: true },
      );
      setInitialAccessToken(accessToken);
      storage.setItem(INITIAL_ACCESS_TOKEN_STORAGE_KEY, accessToken);
    } else {
      storage
        .getItem(INITIAL_ACCESS_TOKEN_STORAGE_KEY)
        .then(value => {
          if (typeof value === 'string') {
            setInitialAccessToken(value);
          } else {
            throw new Error('Unknown initial access token type');
          }
        })
        .catch(e => {
          setInitialAccessToken(null);
          console.error(e);
        });
    }
  }, [navigate, initialAccessToken, isAvailable, storage]);

  const promiseResolversMapRef = useRef<{
    SET_ACCESS_TOKEN?: (token: string) => void;
    MFA_CANCELLED?: () => void;
  }>({});

  const subscribersRef = useRef<
    Array<(message: MessageFromNativeWrapper) => void>
  >([]);

  const handleNativeMessage = useCallback(
    (data: string) => {
      try {
        const message: MessageFromNativeWrapper = JSON.parse(data);

        if (window.DESKTOPCOM_LOG_NATIVE_WRAPPER) {
          console.log('[NativeWrapper] Message FROM native wrapper:', message);
        }

        switch (message.type) {
          case 'SET_ACCESS_TOKEN': {
            storage.setItem(
              INITIAL_ACCESS_TOKEN_STORAGE_KEY,
              message.accessToken,
            );
            promiseResolversMapRef.current.SET_ACCESS_TOKEN?.(
              message.accessToken,
            );
            break;
          }
          case 'MFA_CANCELLED': {
            promiseResolversMapRef.current.MFA_CANCELLED?.();
            break;
          }
          default:
            break;
        }
        subscribersRef.current.forEach(subscriber => {
          subscriber(message);
        });
      } catch (e) {
        captureException(
          e as Error,
          'Failed to process a message from Native Wrapper',
        );
        console.error(e);
      }
    },
    [storage],
  );

  const subscribeToMessages = useCallback(
    (subscriber: (message: MessageFromNativeWrapper) => void) => {
      subscribersRef.current.push(subscriber);

      const isFirstSubscriber = subscribersRef.current.length === 1;
      const initialMessages = window._nwInitialNativeMessages;
      if (
        isFirstSubscriber &&
        initialMessages &&
        Array.isArray(initialMessages)
      ) {
        initialMessages.forEach(initialMessage => {
          handleNativeMessage(initialMessage);
        });
        window._nwInitialNativeMessages = undefined;
      }
      return {
        remove: () => {
          subscribersRef.current = subscribersRef.current.filter(
            item => item !== subscriber,
          );
        },
      };
    },
    [handleNativeMessage],
  );

  useEffect(() => {
    window._nwHandleNativeMessage = handleNativeMessage;
  }, [handleNativeMessage]);

  const callRefreshToken = useCallback((): Promise<string> => {
    return new Promise((resolve, reject) => {
      const messageSent = sendMessageToNativeWrapper({
        type: 'REFRESH_TOKEN',
      });
      if (messageSent) {
        promiseResolversMapRef.current.SET_ACCESS_TOKEN = resolve;
      } else {
        reject();
      }
    });
  }, []);

  const callTriggerMfa = useCallback((): Promise<string> => {
    return new Promise((resolve, reject) => {
      const messageSent = sendMessageToNativeWrapper({
        type: 'TRIGGER_MFA',
      });
      if (messageSent) {
        promiseResolversMapRef.current.SET_ACCESS_TOKEN = resolve;
        promiseResolversMapRef.current.MFA_CANCELLED = reject;
      } else {
        reject();
      }
    });
  }, []);

  if (typeof initialAccessToken === 'undefined') {
    return <AppShellSkeleton />;
  }

  return (
    <NativeWrapperContext.Provider
      value={{
        isNativeWrapperAvailable: isAvailable,
        sendMessageToNativeWrapper,
        callRefreshToken,
        callTriggerMfa,
        initialAccessToken,
        subscribeToMessages,
      }}>
      {children}
    </NativeWrapperContext.Provider>
  );
};
