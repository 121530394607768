import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { CreateIntegrationForm } from '../CreateIntegrationForm';
import { CreateIntegrationFormValues } from '../../../../AppIntegrations.types';
import { GraphQLError } from 'graphql';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import { useCreateIntegrationClientMutation } from '../../../../AppIntegrations.hooks';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import { CUSTOM_INTEGRATION_PATHNAME } from '../../../../../Desktop/Desktop.constants';
import { getShortId } from '../../../../../../shared/utils/id';
import { AppIntegrationsTranslation } from '../../../../i18n';

export const CreateIntegrationModal: FC<ModalProps> = ({
  visible,
  onRequestClose,
}) => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const [createIntegrationClientMutation] =
    useCreateIntegrationClientMutation();

  const handleSubmit = useCallback(
    (formValues: CreateIntegrationFormValues) => {
      const { integrationName, botName, botImage } = formValues;

      return createIntegrationClientMutation({
        variables: {
          input: {
            workspace: workspace.id,
            name: integrationName,
            botName,
            botImage,
          },
        },
      })
        .then(response => {
          const integrationId =
            response?.data?.createIntegrationClient.integrationClient.id;
          if (integrationId) {
            navigate(
              generatePath(CUSTOM_INTEGRATION_PATHNAME, {
                workspaceId: getShortId(workspace.id),
                integrationId: getShortId(integrationId),
              }),
            );
          }
          onRequestClose();
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [createIntegrationClientMutation, onRequestClose, workspace, navigate],
  );

  return (
    <Modal width={400} visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <ModalHeaderTitle>
          <FormattedMessage
            id={AppIntegrationsTranslation.appCustomIntegrationAddNewLabel}
          />
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent>
        <CreateIntegrationForm
          onSubmit={handleSubmit}
          onCancel={onRequestClose}
        />
      </ModalContent>
    </Modal>
  );
};
