import React, { FC, useCallback, useState } from 'react';
import { EditUserForm } from './EditUser.form';
import { StyledEditUserForm } from './EditUser.styled';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { AccountApiType } from '../../User.types';
import { useChangeWorkspaceGroupForAccount } from '../../User.hooks';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { Spinner } from '../../../../shared/components/Spinner';
import { useAccountsContext } from '../../../Account';
import { WorkspaceAccountGroupIdentity } from '../../User.constants';
import { useWorkspaceInfoRepository } from '../../../WorkspaceInfo/data/WorkspaceInfo/WorkspaceInfo.repositories';

interface EditUserProps {
  onDone: () => void;
  account: AccountApiType;
  groupIdentifier: WorkspaceAccountGroupIdentity;
}

export const EditUser: FC<EditUserProps> = ({
  onDone,
  account,
  groupIdentifier,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [changeWorkspaceGroupForAccount] = useChangeWorkspaceGroupForAccount();
  const { refetchAccounts } = useAccountsContext();
  const { fetchWorkspaceInfo } = useWorkspaceInfoRepository({
    accountId: account.id,
  });

  const handleSubmit = useCallback(
    ({ groupIdentifier }: { groupIdentifier: string | null }) => {
      setLoading(true);
      changeWorkspaceGroupForAccount({
        variables: {
          input: {
            id: account.id,
            workspace: currentWorkspace.id,
            groupIdentifier: groupIdentifier || '',
          },
        },
      })
        .then(() => {
          setLoading(false);
          refetchAccounts();
          fetchWorkspaceInfo();
          onDone();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
          setLoading(false);
        });
    },
    [
      account.id,
      changeWorkspaceGroupForAccount,
      currentWorkspace.id,
      onDone,
      refetchAccounts,
      fetchWorkspaceInfo,
    ],
  );

  return (
    <StyledEditUserForm>
      {!loading ? (
        <EditUserForm
          account={account}
          groupIdentifier={groupIdentifier}
          onSubmit={handleSubmit}
          onCancel={onDone}
        />
      ) : (
        <Spinner />
      )}
    </StyledEditUserForm>
  );
};
