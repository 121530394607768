import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormInput } from '../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { DesktopForImportField } from './CreateDesktopForImport.styled';
import { DesktopTranslation } from '../../i18n';
import { DesktopForImportFormValues } from '../../data/Desktop/types/Desktop.types';

const DesktopFormSchema: Schema<DesktopForImportFormValues> =
  Yup.object().shape({
    name: Yup.string().required(DesktopTranslation.formNameRequiredError),
  });

interface CreateDesktopForImportFormProps {
  onSubmit: (values: DesktopForImportFormValues) => void;
}

export const CreateDesktopForImportForm: FC<
  CreateDesktopForImportFormProps
> = ({ onSubmit }) => {
  const intl = useIntl();
  const initialValues: DesktopForImportFormValues = {
    name: intl.formatMessage({
      id: DesktopTranslation.formDefaultDesktopName,
    }),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DesktopFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="desktop-form">
          <div>
            <DesktopForImportField>
              <Field
                name="name"
                type="text"
                data-testid="desktop-form-name-input"
                placeholder={intl.formatMessage({
                  id: DesktopTranslation.formNamePlaceholder,
                })}
                label={intl.formatMessage({
                  id: DesktopTranslation.formAddImportDesktopLabel,
                })}
                component={FormInput}
              />
            </DesktopForImportField>

            <Button
              data-testid="desktop-form-submit-button"
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={isSubmitting}
              fullWidth>
              <FormattedMessage id={DesktopTranslation.formContinueButton} />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
