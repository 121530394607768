import { gql } from '@apollo/client';

export const CREATE_FAVORITE = gql`
  mutation CreateFavorite($input: createFavoriteInput!) {
    __typename
    createFavorite(input: $input) {
      favorite {
        link {
          id
          accountFavorite
        }
        folder {
          id
          accountFavorite
        }
        desktopApp {
          id
          accountFavorite
        }
      }
    }
  }
`;

export type CreateFavoriteItemType =
  | { link: string }
  | { folder: string }
  | { desktopApp: string };

export interface CreateFavoriteVariables {
  input: { workspace: string } & CreateFavoriteItemType;
}

export interface CreateFavoriteResponse {
  createFavorite: {
    favorite: {
      link?: {
        id: string;
        accountFavorite: boolean;
      };
      folder?: {
        id: string;
        accountFavorite: boolean;
      };
      desktopApp?: {
        id: string;
        accountFavorite: boolean;
      };
    };
  };
}
