import styled from 'styled-components';

export const StyledPaymentCoupon = styled.span.attrs({
  'data-testid': 'payment-coupon',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 12px;

  .couponName {
    font-size: 0.875rem;
    padding: 2px 4px;
    border-radius: 4px;
    line-height: 1.2;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    background-color: ${p => p.theme.colors.Primary};
    color: ${p => p.theme.colors.PrimaryTextLight};
  }

  .couponDescription {
    font-size: 0.675rem;
    padding: 4px 0;
    line-height: 1.2;
    font-family: ${p => p.theme.fonts.Regular};
  }
`;
