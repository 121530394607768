import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  APP_INTEGRATIONS_MARKETPLACE_CATEGORIES,
  APP_INTEGRATIONS_MARKETPLACE_ITEMS,
  SEARCH_APP_INTEGRATIONS,
  GET_INTEGRATION_CLIENT,
  GET_WORKSPACE_INTEGRATION_CLIENTS,
  GetAppIntegrationsMarketplaceCategoriesResponse,
  GetAppIntegrationsMarketplaceCategoriesVariables,
  GetAppIntegrationsMarketplaceItemsResponse,
  GetAppIntegrationsMarketplaceItemsVariables,
  SearchAppIntegrationsResponse,
  SearchAppIntegrationsVariables,
  IntegrationClientResponse,
  IntegrationClientVariables,
  IntegrationClientsResponse,
  IntegrationClientsVariables,
  GetIntegrationAuthUrlVariables,
  GetIntegrationAuthUrlResponse,
  GET_GOOGLE_AUTH_URL,
  GET_GITHUB_AUTH_URL,
  GET_TRELLO_AUTH_URL,
  GET_DROPBOX_AUTH_URL,
  GET_MAILCHIMP_AUTH_URL,
  AuthUrlApiType,
  GET_NOTION_AUTH_URL,
  GET_SALESFORCE_AUTH_URL,
  GET_ASANA_AUTH_URL,
  GET_HUBSPOT_AUTH_URL,
  GET_MICROSOFT_AUTH_URL,
} from './AppIntegrations.queries';
import {
  CREATE_INTEGRATION_CLIENT_HOOK,
  DELETE_INTEGRATION_CLIENT,
  DELETE_INTEGRATION_CLIENT_HOOK,
  EDIT_INTEGRATION_CLIENT,
  CREATE_INTEGRATION_CLIENT,
  CreateIntegrationClientResponse,
  CreateIntegrationClientVariables,
  CreateIntegrationClientHookResponse,
  CreateIntegrationClientHookVariables,
  DeleteIntegrationClientHookResponse,
  DeleteIntegrationClientHookVariables,
  DeleteIntegrationClientResponse,
  DeleteIntegrationClientVariables,
  EditIntegrationClientResponse,
  EditIntegrationClientVariables,
  INSTALL_MARKETPLACE_ITEM,
  InstallMarketplaceItemVariables,
  InstallMarketplaceItemResponse,
  SwitchAppIntegrationResponse,
  SwitchAppIntegrationVariables,
  SWITCH_APP_INTEGRATION,
  REMOVE_APP_INTEGRATION,
  RemoveAppIntegrationResponse,
  RemoveAppIntegrationVariables,
  CREATE_APP_INTEGRATION_CREDENTIAL,
  CreateAppIntegrationCredentialVariables,
  CreateAppIntegrationCredentialResponse,
} from './AppIntegrations.mutations';
import { useRefreshableQuery } from '../Api/Api.hooks';
import { APP_INTEGRATIONS_DRIVERS_MAP } from './AppIntegrations.types';

export const useAppIntegrationsMarketplaceCategories = (
  options?: QueryHookOptions<
    GetAppIntegrationsMarketplaceCategoriesResponse,
    GetAppIntegrationsMarketplaceCategoriesVariables
  >,
) =>
  useQuery<
    GetAppIntegrationsMarketplaceCategoriesResponse,
    GetAppIntegrationsMarketplaceCategoriesVariables
  >(APP_INTEGRATIONS_MARKETPLACE_CATEGORIES, options);

export const useAppIntegrationsMarketplaceItems = (
  options?: QueryHookOptions<
    GetAppIntegrationsMarketplaceItemsResponse,
    GetAppIntegrationsMarketplaceItemsVariables
  >,
) =>
  useQuery<
    GetAppIntegrationsMarketplaceItemsResponse,
    GetAppIntegrationsMarketplaceItemsVariables
  >(APP_INTEGRATIONS_MARKETPLACE_ITEMS, options);

export const useSearchAppIntegrations = (
  options?: QueryHookOptions<
    SearchAppIntegrationsResponse,
    SearchAppIntegrationsVariables
  >,
) =>
  useQuery<SearchAppIntegrationsResponse, SearchAppIntegrationsVariables>(
    SEARCH_APP_INTEGRATIONS,
    options,
  );

export const useGetWorkspaceIntegrationClients = (
  options?: QueryHookOptions<
    IntegrationClientsResponse,
    IntegrationClientsVariables
  >,
) =>
  useQuery<IntegrationClientsResponse, IntegrationClientsVariables>(
    GET_WORKSPACE_INTEGRATION_CLIENTS,
    options,
  );

export const useGetIntegrationClient = (
  options?: QueryHookOptions<
    IntegrationClientResponse,
    IntegrationClientVariables
  >,
) =>
  useRefreshableQuery<IntegrationClientResponse, IntegrationClientVariables>(
    GET_INTEGRATION_CLIENT,
    options,
  );

const useGetGoogleAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_GOOGLE_AUTH_URL,
    options,
  );

const useGetGithubAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_GITHUB_AUTH_URL,
    options,
  );

const useGetTrelloAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_TRELLO_AUTH_URL,
    options,
  );

const useGetDropboxAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_DROPBOX_AUTH_URL,
    options,
  );

const useGetMailchimpAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_MAILCHIMP_AUTH_URL,
    options,
  );

const useGetNotionAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_NOTION_AUTH_URL,
    options,
  );

const useGetSalesforceAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_SALESFORCE_AUTH_URL,
    options,
  );

const useGetAsanaAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_ASANA_AUTH_URL,
    options,
  );

const useGetHubspotAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_HUBSPOT_AUTH_URL,
    options,
  );

const useGetMicrosoftAuthUrl = (
  options?: QueryHookOptions<
    GetIntegrationAuthUrlResponse,
    GetIntegrationAuthUrlVariables
  >,
) =>
  useQuery<GetIntegrationAuthUrlResponse, GetIntegrationAuthUrlVariables>(
    GET_MICROSOFT_AUTH_URL,
    options,
  );

export const useGetAppIntegrationsAuthUrl = (
  options: QueryHookOptions<GetIntegrationAuthUrlVariables>,
): Record<string, AuthUrlApiType | undefined> => {
  const scopeType = options?.variables?.connectorConfiguration.scopeType;
  const driver = options?.variables?.connectorConfiguration.driver;
  const optionsForQuery = {
    variables: {
      scope: scopeType,
      workspaceId: options?.variables?.workspaceId,
    },
  };

  return {
    [APP_INTEGRATIONS_DRIVERS_MAP.google]: useGetGoogleAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.google,
    }).data?.googleAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.github]: useGetGithubAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.github,
    }).data?.githubAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.trello]: useGetTrelloAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.trello,
    }).data?.trelloAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.dropbox]: useGetDropboxAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.dropbox,
    }).data?.dropboxAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.mailchimp]: useGetMailchimpAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.mailchimp,
    }).data?.mailchimpAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.notion]: useGetNotionAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.notion,
    }).data?.notionAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.salesforce]: useGetSalesforceAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.salesforce,
    }).data?.salesforceAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.asana]: useGetAsanaAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.asana,
    }).data?.asanaAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.hubspot]: useGetHubspotAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.hubspot,
    }).data?.hubspotAuthUrl,
    [APP_INTEGRATIONS_DRIVERS_MAP.microsoft]: useGetMicrosoftAuthUrl({
      ...optionsForQuery,
      skip: driver !== APP_INTEGRATIONS_DRIVERS_MAP.microsoft,
    }).data?.microsoftAuthUrl,
  };
};

export const useDeleteIntegrationClientMutation = () =>
  useMutation<
    DeleteIntegrationClientResponse,
    DeleteIntegrationClientVariables
  >(DELETE_INTEGRATION_CLIENT);

export const useCreateIntegrationClientHookMutation = () =>
  useMutation<
    CreateIntegrationClientHookResponse,
    CreateIntegrationClientHookVariables
  >(CREATE_INTEGRATION_CLIENT_HOOK);

export const useDeleteIntegrationClientHookMutation = () =>
  useMutation<
    DeleteIntegrationClientHookResponse,
    DeleteIntegrationClientHookVariables
  >(DELETE_INTEGRATION_CLIENT_HOOK);

export const useEditIntegrationClientMutation = () =>
  useMutation<EditIntegrationClientResponse, EditIntegrationClientVariables>(
    EDIT_INTEGRATION_CLIENT,
  );

export const useCreateIntegrationClientMutation = () =>
  useMutation<
    CreateIntegrationClientResponse,
    CreateIntegrationClientVariables
  >(CREATE_INTEGRATION_CLIENT);

export const useInstallMarketplaceItemMutation = () =>
  useMutation<InstallMarketplaceItemResponse, InstallMarketplaceItemVariables>(
    INSTALL_MARKETPLACE_ITEM,
  );

export const useCreateAppIntegrationCredentialMutation = () =>
  useMutation<
    CreateAppIntegrationCredentialResponse,
    CreateAppIntegrationCredentialVariables
  >(CREATE_APP_INTEGRATION_CREDENTIAL);

export const useSwitchAppIntegrationMutation = () =>
  useMutation<SwitchAppIntegrationResponse, SwitchAppIntegrationVariables>(
    SWITCH_APP_INTEGRATION,
  );

export const useRemoveAppIntegrationMutation = () =>
  useMutation<RemoveAppIntegrationResponse, RemoveAppIntegrationVariables>(
    REMOVE_APP_INTEGRATION,
  );
