import styled from 'styled-components';

export const StyledAnnouncement = styled.div`
  padding: 1.5rem 1.5rem 1rem;
`;

export const AnnouncementCover = styled.img`
  width: 100%;
  border-radius: 6.28px;
  object-fit: contain;
`;

export const FeaturesContainer = styled.div`
  margin-top: 1.5rem;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const FeaturesTitle = styled.span`
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;

  ${({ theme }) => theme.breakpoints.md`
    font-size: 1.25rem;
  `}
`;

export const FeaturesList = styled.ul`
  margin-top: 1rem;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${({ theme }) => theme.breakpoints.md`
    font-size: 1rem;
    line-height: 1.5rem;
  `}
  li:nth-child(4) {
    list-style-type: none;
  }

  li:nth-child(4)::before {
    content: '💡';
    position: absolute;
    left: 1.875rem;
  }
`;

export const FeaturesItem = styled.li`
  margin-bottom: 0.5rem;
  list-style-type: disc;

  &:last-child {
    margin-bottom: 0;
  }

  > b {
    letter-spacing: -1%;
  }

  > p {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  ul {
    margin-left: 1rem;
  }

  ul li::before {
    content: '-';
    position: relative;
    left: -0.5rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`;
