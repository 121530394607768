import styled from 'styled-components';
import { TabPanel, Tabs } from 'react-tabs';
import { StyledTab } from '../../../../../shared/components/Tabs';

export const StyledCustomIntegrationViewWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};

  .header {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const StyledAppsIntegrationsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};

  .header {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;
`;

export const StyledIntegrationTab = styled(StyledTab)`
  width: auto !important;
  padding: 0 ${({ theme }) => theme.spacing.m};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledTabPanel = styled(TabPanel)`
  overflow: auto;

  &.react-tabs__tab-panel--selected {
    height: 100%;
    width: 100%;
  }
`;

export const CustomIntegrationBodyWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xxl};
  overflow: hidden;

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
      padding: ${spacing.xxl} ${spacing.xl} 0 ${spacing.xs};
  `}
`;

export const CustomIntegrationFormBody = styled.div`
  max-width: 26.25rem;
`;

export const CustomIntegrationField = styled.div`
  & ~ & {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const CustomIntegrationFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  button ~ button {
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  .cancel-button {
    background-color: ${p => p.theme.colors.Surface};
  }
`;

export const CustomIntegrationHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  a {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;
