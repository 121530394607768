import React, { FC, useCallback } from 'react';
import { useAuthService } from '../../../../Auth/Auth.hooks';
import { Auth0Connection } from '../../../../Auth/Auth.service';
import {
  ExternalProviderButton,
  FacebookConfirmationBody,
  StyledExternalProvidersList,
} from './ExternalProvidersList.styled';
import {
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  MicrosoftIcon,
} from '../../../../../shared/icons';
import { OnboardingTranslation } from '../../../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useConfirm } from '../../../../../shared/components/Modal';
import { appEnv } from '../../../../../appEnv';
import { SignUpFlow } from '../../../Onboarding.types';
import { NativePlatform } from '../../../../NativeWrapper/NativeWrapper.types';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

interface ExternalProvidersListProps {
  flow: SignUpFlow;
  invitation: AccountInvitationApiType | undefined;
  linkRequestId: string | undefined;
  platform?: NativePlatform;
}

export const ExternalProvidersList: FC<ExternalProvidersListProps> = ({
  flow,
  invitation,
  linkRequestId,
  platform,
}) => {
  const authService = useAuthService();
  const { askConfirmation } = useConfirm();
  const { formatMessage } = useIntl();

  const handleClick = useCallback(
    async (connection: Auth0Connection) => {
      if (connection === Auth0Connection.facebook) {
        const canContinue = await askConfirmation(
          <FacebookConfirmationBody>
            <FormattedHTMLMessage
              tagName="div"
              id={
                OnboardingTranslation.onboardingRegistrationFacebookWarningText
              }
              values={{
                signUpWithFacebookLink:
                  appEnv.SUPPORT_SIGN_UP_WITH_FACEBOOK_URL,
              }}
            />
          </FacebookConfirmationBody>,
          undefined,
          {
            width: 432,
            confirmButtonText: formatMessage({
              id: OnboardingTranslation.onboardingRegistrationFacebookWarningConfirmLabel,
            }),
            cancelButtonText: formatMessage({
              id: OnboardingTranslation.onboardingRegistrationFacebookWarningCancelLabel,
            }),
          },
        );
        if (!canContinue) {
          return;
        }
      }
      const appState =
        '/register-social?' +
        getQueryParamsFrom({
          flow,
          ...(invitation
            ? {
                inviteId: invitation?._id,
              }
            : {}),
          ...(linkRequestId
            ? {
                linkRequestId: linkRequestId,
              }
            : {}),
          ...(platform
            ? {
                platform,
                connection,
              }
            : {}),
        });
      authService?.login({
        connection,
        appState,
      });
    },
    [
      askConfirmation,
      authService,
      flow,
      formatMessage,
      invitation,
      linkRequestId,
      platform,
    ],
  );

  return (
    <StyledExternalProvidersList data-testid="external-providers">
      <ExternalProviderButton
        externalProvider={Auth0Connection.google}
        icon={GoogleIcon}
        onClick={() => handleClick(Auth0Connection.google)}
        data-testid="external-provider-google">
        <FormattedMessage
          id={
            OnboardingTranslation.onboardingRegistrationExternalProviderGoogle
          }
        />
      </ExternalProviderButton>
      <ExternalProviderButton
        externalProvider={Auth0Connection.facebook}
        icon={FacebookIcon}
        onClick={() => handleClick(Auth0Connection.facebook)}
        data-testid="external-provider-facebook">
        <FormattedMessage
          id={
            OnboardingTranslation.onboardingRegistrationExternalProviderFacebook
          }
        />
      </ExternalProviderButton>
      <ExternalProviderButton
        externalProvider={Auth0Connection.apple}
        icon={AppleIcon}
        onClick={() => handleClick(Auth0Connection.apple)}
        data-testid="external-provider-apple">
        <FormattedMessage
          id={OnboardingTranslation.onboardingRegistrationExternalProviderApple}
        />
      </ExternalProviderButton>
      <ExternalProviderButton
        externalProvider={Auth0Connection.microsoft}
        icon={MicrosoftIcon}
        onClick={() => handleClick(Auth0Connection.microsoft)}
        data-testid="external-provider-microsoft">
        <FormattedMessage
          id={
            OnboardingTranslation.onboardingRegistrationExternalProviderMicrosoft
          }
        />
      </ExternalProviderButton>
    </StyledExternalProvidersList>
  );
};
