import styled from 'styled-components';

export const RequestResetPasswordField = styled.div`
  display: flex;

  > * {
    flex-basis: 100%;
  }
  > * ~ * {
    margin-left: 16px;
  }

  & ~ & {
    margin-top: 16px;
  }
`;

export const RequestResetPasswordControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
