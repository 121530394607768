import React, { FC, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DragNativeFileItemType, DragNativeUrlItemType } from '../Drag.types';
import { StyledExternalUrlDropZone } from './ExternalUrlDropZone.styled';
import { useCurrentDesktopPermissions } from '../../Desktop/Desktop.hooks';
import { useDroppedLinkHandler } from './ExternalUrlDropZone.hooks';
import { validateExternallyDroppedFile } from './ExternalUrlDropZone.utils';
import {
  DROPZONE_ACTIVE_CLASSNAME,
  DROPZONE_ENABLED_CLASSNAME,
} from './ExternalUrlDropZone.constants';
import {
  ExternalFileCategory,
  ExternalFileCategoryUnsupportedType,
  ExternalFileCategoryUrlType,
  ExternalFileSupportedExtension,
} from './ExternalUrlDropZone.types';
import { showToastErrorMessage } from '../../../shared/components/Toast';
import { LinkTranslation } from '../../Link/i18n';

export const ExternalUrlDropZone: FC<{ className?: string }> = ({
  className,
}) => {
  const { canAddLink } = useCurrentDesktopPermissions();

  const handleDroppedLink = useDroppedLinkHandler();

  const [{ isDraggingOver }, dropRef] = useDrop<
    DragNativeUrlItemType | DragNativeFileItemType,
    unknown,
    { isDraggingOver: boolean }
  >({
    accept: [NativeTypes.URL, NativeTypes.FILE],
    canDrop: () => canAddLink,
    drop: async item => {
      if ('urls' in item && item.urls.length) {
        handleDroppedLink(item.urls[0]);
      } else if ('files' in item && item.files.length) {
        const fileDropResult = await validateExternallyDroppedFile(
          item.files[0],
          [
            ExternalFileSupportedExtension.URL,
            ExternalFileSupportedExtension.WEBLOC,
          ],
        );

        if (fileDropResult.type === ExternalFileCategory.URL) {
          handleDroppedLink(
            (fileDropResult as ExternalFileCategoryUrlType).url,
          );
        } else if (fileDropResult.type === ExternalFileCategory.UNSUPPORTED) {
          showToastErrorMessage(LinkTranslation.unsupportedDroppedUrlFile, {
            fileName: (fileDropResult as ExternalFileCategoryUnsupportedType)
              .fileName,
          });
        }
      }
    },
    collect: monitor => ({
      isDraggingOver: monitor.isOver(),
    }),
  });

  const classNames = useMemo(() => {
    const result: string[] = [];
    if (className) {
      result.push(className);
    }
    if (canAddLink) {
      result.push(DROPZONE_ENABLED_CLASSNAME);
    }
    if (isDraggingOver) {
      result.push(DROPZONE_ACTIVE_CLASSNAME);
    }
    return result.join(' ');
  }, [canAddLink, className, isDraggingOver]);

  return <StyledExternalUrlDropZone ref={dropRef} className={classNames} />;
};
