import React, { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../../shared/hooks';
import { AddIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { PREVIEW_SEGMENT_URL_CHANGE_DELAY } from '../../../../PreviewSegment/PreviewSegment.constants';
import { PreviewSegmentContext } from '../../../../PreviewSegment/PreviewSegment.context';
import { SegmentType } from '../../../../Segment';
import { NavigatorActionButton } from '../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';
import { CreateDesktopModal } from '../../../Modals/CreateDesktopModal';
import { StandardDesktopViewModel } from '../../../Views/StandardDesktop/ViewModel';
import { DesktopTranslation } from '../../../i18n';

interface DesktopNavigatorTitleActionsProps {
  buttonText?: string;
}

export const DesktopNavigatorTitleActions: FC<
  DesktopNavigatorTitleActionsProps
> = ({ buttonText }) => {
  const { formatMessage } = useIntl();
  const {
    permissions: { canCreateDesktop },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { createDesktopModal } = queryParams;
  const { isPreviewMode, navigateToSegment } = useContext(
    PreviewSegmentContext,
  );
  const { desktopIsLoading } = StandardDesktopViewModel();

  const handleOnClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.DESKTOPS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            createDesktopModal: true,
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  const handleClose = () => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createDesktopModal: undefined,
      }),
    });
  };

  return canCreateDesktop ? (
    <>
      <NavigatorActionButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: DesktopTranslation.tooltipCreateDesktop,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={AddIcon}
        iconSize={14}
        onClick={handleOnClick}
        data-testid="create-desktop-btn"
        iconFirst={true}
        secondaryButton
        disabled={desktopIsLoading}
        buttonWithText={!!buttonText}>
        {buttonText && <FormattedMessage id={buttonText} />}
      </NavigatorActionButton>

      {createDesktopModal && (
        <CreateDesktopModal visible={true} onRequestClose={handleClose} />
      )}
    </>
  ) : null;
};
