import React from 'react';

export interface ChatMessageContextProps {
  messageId: string;
  replyFormIsVisible?: boolean;
  isEditing?: boolean;
}

interface ChatMessageContextType {
  toggleReplyForm: (messageId: string, state: boolean) => void;
  replyFormVisible: (messageId: string) => boolean;
  setMessageIsEditing: (messageId: string, state: boolean) => void;
  messageIsEditing: (messageId: string) => boolean;
}

export const ChatMessageContext = React.createContext<ChatMessageContextType>({
  toggleReplyForm: () => undefined,
  replyFormVisible: () => false,
  setMessageIsEditing: () => undefined,
  messageIsEditing: () => false,
});
