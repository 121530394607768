import React, { FC, useCallback, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import {
  OnboardingStep,
  OnboardingStepHeader,
  StyledResetPasswordButton,
  StyledResetPasswordHelpText,
} from '../OnboardingPage.styled';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { ResetPasswordDescription } from './ResetPasswordPage.styled';
import { useQueryParams } from '../../../../shared/hooks';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { Navigate, useNavigate } from 'react-router-dom';
import { REQUEST_RESET_PASSWORD_PATHNAME } from '../../Onboarding.constants';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
} from '../../../../shared/components/Toast';
import {
  hashAuthPassword,
  hashMasterPassword,
} from '../../../../shared/utils/hash.utils';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { appEnv } from '../../../../appEnv';
import { useResetPasswordMutation } from '../../Onboarding.hooks';
import { LOGIN_PATHNAME } from '../../../Auth/Auth.constants';
import { ResetPasswordFormValues } from '../../Onboarding.types';
import {
  encryptPrivateKey,
  exportPublicKey,
  generateRsaKeys,
} from '../../../Encryption/Encryption.crypto.utils';
import { GraphQLError } from 'graphql';

export const ResetPasswordPage: FC = () => {
  const { id, hash, email } = useQueryParams();
  const [passwordReset, setPasswordReset] = useState(false);
  const navigate = useNavigate();
  const { account } = useCurrentAccount();

  const [resetPasswordMutation] = useResetPasswordMutation();
  const resetPassword = useCallback(
    (values: ResetPasswordFormValues) => {
      return Promise.all([
        generateRsaKeys(),
        hashMasterPassword(values.newPassword, email as string),
      ])
        .then(([{ privateKey, publicKey }, newMasterPasswordHash]) => {
          return Promise.all([
            exportPublicKey(publicKey),
            encryptPrivateKey(
              privateKey,
              newMasterPasswordHash,
              `/accounts/${id}`,
            ),
            hashAuthPassword(values.newPassword, email as string),
          ]);
        })
        .then(([pubKey, privKeyEncrypted, newAuthPasswordHash]) => {
          return resetPasswordMutation({
            variables: {
              input: {
                email: email as string, // we need it here to verify that user creates password hash with the right email as salt
                newPassword: newAuthPasswordHash,
                repeatPassword: newAuthPasswordHash,
                id: `/accounts/${id}`,
                passwordHash: hash as string,
                pubKey,
                privKeyEncrypted,
              },
            },
          });
        })
        .then(res => {
          if (res.data?.resetPasswordAccount) {
            setPasswordReset(true);
          } else if (res.errors?.length) {
            showToastGraphQLErrors(res.errors as GraphQLError[]);
          }
        });
    },
    [resetPasswordMutation, id, hash, email],
  );

  if (!id || !hash || !email) {
    showToastErrorMessage(
      OnboardingTranslation.onboardingResetPasswordInvalidLink,
    );
    return <Navigate to={REQUEST_RESET_PASSWORD_PATHNAME} />;
  }

  if (account) {
    return <Navigate to="/" />;
  }

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="reset-password-step">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingResetPasswordTitle}
          />
        </OnboardingStepHeader>
        {passwordReset ? (
          <>
            <ResetPasswordDescription data-testid="reset-password-success-description">
              <FormattedMessage
                id={OnboardingTranslation.onboardingResetPasswordSuccessMessage}
              />
            </ResetPasswordDescription>

            <StyledResetPasswordHelpText>
              <ImportantMessage type={ImportantMessageType.WARNING}>
                <FormattedHTMLMessage
                  id={OnboardingTranslation.onboardingResetPasswordHelpText}
                  values={{
                    learnMoreLink: appEnv.SUPPORT_PASSWORD_MANAGER_URL,
                  }}
                />
              </ImportantMessage>
            </StyledResetPasswordHelpText>
            <StyledResetPasswordButton>
              <Button
                onClick={() => navigate(LOGIN_PATHNAME)}
                size={ButtonSize.sm}
                mode={ButtonMode.primary}
                data-testid="login-with-new-password-button">
                <FormattedMessage
                  id={
                    OnboardingTranslation.onboardingResetPasswordSuccessButton
                  }
                />
              </Button>
            </StyledResetPasswordButton>
          </>
        ) : (
          <>
            <ResetPasswordDescription data-testid="reset-password-description">
              <FormattedMessage
                id={OnboardingTranslation.onboardingResetPasswordDescription}
              />
            </ResetPasswordDescription>
            <ResetPasswordForm onSubmit={resetPassword} />
          </>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
