import React, { FC } from 'react';
import { Range as ReactRange } from 'react-range';
import styled from 'styled-components';

const StyledThumb = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: solid 1px ${p => p.theme.colors.OnSurfaceLightEmphasis};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRange = styled.div`
  min-width: 12.5rem;
  height: 6px;
  border-radius: 1.25rem;
  border: solid 1px ${p => p.theme.colors.BorderDefault};
  background-color: ${p => p.theme.colors.SurfaceSubdued};
`;

interface RangeProps {
  step?: number;
  min?: number;
  max?: number;
  value?: number;
  onChange: (values: number) => void;
}
// TODO Add FormRange component
export const Range: FC<RangeProps> = ({
  step = 1,
  min = 0,
  max = 100,
  value = 8,
  onChange,
}) => {
  const handleOnChange = (value: number[]) => {
    onChange(Number(value[0].toFixed(1)));
  };

  return (
    <ReactRange
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={value => handleOnChange(value)}
      renderTrack={({ props, children }) => (
        <StyledRange {...props}>{children}</StyledRange>
      )}
      renderThumb={({ props }) => <StyledThumb {...props}></StyledThumb>}
    />
  );
};
