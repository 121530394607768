import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditWorkspaceSettings } from '../EditWorkspaceSettings';
import { WorkspaceTranslation } from '../../i18n';

export const EditWorkspaceSettingsModal: FC<ModalProps> = ({
  visible,
  onRequestClose,
}) => {
  return (
    <Modal width={384} visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <ModalHeaderTitle>
          <FormattedMessage
            id={WorkspaceTranslation.workspaceSettingsEditModalHeader}
          />
        </ModalHeaderTitle>
      </ModalHeader>
      <EditWorkspaceSettings onRequestClose={onRequestClose} />
    </Modal>
  );
};
