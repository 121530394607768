import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Quill, { RangeStatic, Sources } from 'quill';
import Delta from 'quill-delta';
import { StyledEditor, StyledEditorContainer } from './Editor.styled';
import 'quill/dist/quill.core.css';
import {
  BindigKeyType,
  EditorSource,
  MentionData,
  WordBeforeArgs,
  WordPosition,
} from './Editor.types';
import { BaseEmoji, emojiIndex } from 'emoji-mart';
import {
  autoReplaceEmoticons,
  dangerouslyInsertLineBreak,
  editorInputIsEmpty,
  getWordBefore,
  insertDraftMessage,
  insertText,
  mentionListRenderer,
  pasteImages,
  prepareTextToPaste,
  senteceFromOps,
  setCursorPosition,
} from './Editor.utils';

import { AccountWithCountsApiType } from '../../../domains/User/User.types';
import { EmojiAutocompletePopover } from '../../../domains/Chat/EmojiPickerPopover/EmojiAutocompletePopover';

import { useEffectOnce } from 'react-use';
import {
  EditorKeyboardBindings,
  EMOJI_LENGTH_IN_EDITOR,
  EMOTICONS_REPLACE_MAP,
  EMOTICONS_REPLACE_REGEX,
  MESSAGE_INPUT_EMOJI_REGEX,
} from '.';
import { useMobile } from '../../hooks';
import { renderSpecialMention } from './MentionItem/SpecialMention';
import { renderUserMention } from './MentionItem/UserMention';
import 'quill-mention';
import { MentionBlot } from './Blot/MentionBlot';

Quill.register(MentionBlot);

export type EditorProps = {
  onValueChange: (value: string) => void;
  onSubmit: () => Promise<void>;
  readOnly: boolean;
  mentionData: MentionData[];
  onInputReset?: () => void;
  children?: ReactNode;
  onImagePasted: (files: File[]) => void;
};

export interface EditorControls {
  resetValue: () => void;
  insertText: (text: string, useCurrentSelection?: boolean) => void;
  insertDraftValue: (text: string) => void;
  insertMentionTrigger: () => void;
  insertFromClipboard: (text: string) => void;
  isFocused: () => boolean | undefined;
  focus: (charIndex?: number) => void;
  blur: () => void;
  isEmpty: () => boolean;
}

export const Editor = React.forwardRef<EditorControls, EditorProps>(
  (
    {
      onValueChange,
      onSubmit,
      readOnly,
      mentionData,
      onInputReset,
      children,
      onImagePasted,
    },
    ref,
  ) => {
    const editorContainerRef: MutableRefObject<HTMLDivElement | null> =
      useRef(null);
    const editorInstance: MutableRefObject<Quill | null> = useRef(null);

    const [emojiTarget, setEmojiTarget] = useState<
      (WordPosition & WordBeforeArgs) | undefined
    >();

    const [search, setSearch] = useState('');
    const [index, setIndex] = useState(0);

    const [range, setRange] = useState<RangeStatic | undefined>();

    const emojitTargetRangeRef = useRef<RangeStatic>();

    const oldEditorValue = useRef<Delta | null>(null);

    const isMobile = useMobile();

    const resetValue = useCallback(() => {
      const editor = editorInstance.current;
      onInputReset?.();

      const contents: Delta = new Delta();

      if (editor) {
        editor.setContents(contents);
      }
    }, [onInputReset]);

    useImperativeHandle(
      ref,
      () => ({
        resetValue: () => resetValue(),
        insertDraftValue: (text: string) => {
          const editor = editorInstance.current;
          if (!editor) {
            return;
          }

          insertDraftMessage(editor, text, mentionData);

          editor.focus();
        },
        insertText: (text, useCurrentSelection = false) => {
          const editor = editorInstance.current;
          if (!editor) {
            return;
          }

          if (useCurrentSelection) {
            // TODO: probably can be a default behaviour
            // this way we don't break old logic
            const currentSelection = editor.getSelection(true);
            editor.insertText(currentSelection.index, text, currentSelection);
            return;
          }

          insertText(editor, text, range);

          editor.focus();
        },
        insertMentionTrigger: () => {
          const editor = editorInstance.current;
          const mentionModule = editor?.getModule('mention');
          if (!editor || !mentionModule) {
            return;
          }

          mentionModule.openMenu('@');

          if (editor.hasFocus()) {
            return;
          }

          editor.focus();
        },
        insertFromClipboard: (text: string) => {
          const editor = editorInstance.current;
          if (!editor) {
            return;
          }

          insertText(editor, text, range);

          if (editor.hasFocus()) {
            return;
          }

          editor.focus();
        },
        isFocused: () => {
          return editorInstance.current?.hasFocus();
        },
        focus: (charIndex?: number) => {
          const editor = editorInstance.current;
          if (!editor) {
            return;
          }

          const editorSelection = editor.getSelection();

          if (charIndex && editorSelection) {
            requestAnimationFrame(() => {
              setTimeout(
                () => editor.setSelection(editorSelection.index + charIndex, 0),
                0,
              );
            });
          } else {
            requestAnimationFrame(() => {
              editor.focus();
            });
          }
        },
        isEmpty: () => {
          const editor = editorInstance.current;
          if (!editor) {
            return true;
          }

          const delta: Delta = editor.getContents();

          return editorInputIsEmpty(delta);
        },
        blur: () => {
          const editor = editorInstance.current;
          if (!editor) {
            return;
          }

          editor.blur();
        },
      }),
      [mentionData, range, resetValue],
    );

    const handleChange = useCallback(
      (delta: Delta, oldContents: Delta, source: Sources) => {
        const editor = editorInstance.current;

        oldEditorValue.current = oldContents;

        if (!editor) {
          return;
        }

        if ('delete' in delta.ops[0] && source === EditorSource.API) {
          resetValue();
          return;
        }

        const sentence = senteceFromOps(editor);

        onValueChange(sentence);

        if (source === EditorSource.API) {
          return;
        }

        const range = editor.getSelection();

        hideEmojiPopover();

        if (range) {
          const wordBefore = getWordBefore(editor, sentence);
          const wordBeforePos = wordBefore.position;

          const beforeText = wordBefore.word;

          const beforeEmojiMatch =
            beforeText &&
            !Object.keys(EMOTICONS_REPLACE_MAP).some(key =>
              key.startsWith(beforeText),
            ) &&
            beforeText.match(MESSAGE_INPUT_EMOJI_REGEX);

          if (beforeEmojiMatch) {
            emojitTargetRangeRef.current = range;
            setEmojiTarget({
              ...wordBeforePos,
              ...wordBefore,
            });

            setSearch(beforeText);
            setIndex(0);
          }
        }
      },
      [onValueChange, resetValue],
    );

    const handleFocus = useCallback(
      (range: RangeStatic, oldRange: RangeStatic) => {
        // TODO - For future, we can get editors blure or focus state.
        // const eventType: EditorEventType | null = selectionChangeHandler(
        //   range,
        //   oldRange,
        // );

        const editor = editorInstance.current;

        if (!editor) {
          return;
        }

        setRange(range);
      },
      [],
    );

    const keyboardSpaceKeyHandle = () => {
      const editor = editorInstance.current;

      if (!editor) {
        return;
      }

      const text = editor.getText();

      if (text.match(EMOTICONS_REPLACE_REGEX)) {
        autoReplaceEmoticons(editor, editor.getContents());

        return;
      }

      return true;
    };

    const emojis = useMemo(
      () =>
        search
          ? (emojiIndex.search(search.substr(1)) ?? [])
              .filter((emoji): emoji is BaseEmoji => 'native' in emoji)
              .slice(0, 10)
          : [],
      [search],
    );

    const handleSelectedEmoji = (
      emoji: BaseEmoji,
      emojiPosition: (WordPosition & WordBeforeArgs) | undefined = emojiTarget,
    ) => {
      const editor = editorInstance.current;

      if (!emojiPosition || !editor) {
        return;
      }

      editor.deleteText(emojiPosition.start, emojiPosition.word.length);

      editor.insertText(emojiPosition.start, `${emoji.native} `);

      hideEmojiPopover();

      setTimeout(() => {
        editorContainerRef.current?.focus();
        editor?.focus();
      }, 800);
    };

    const emojiTargetRef = useRef(emojiTarget);
    emojiTargetRef.current = emojiTarget;
    const emojisRef = useRef(emojis);
    emojisRef.current = emojis;
    const emojiIndexRef = useRef(index);
    emojiIndexRef.current = index;

    const keyboardArrowKeyHandle = (range: RangeStatic, context: any) => {
      const emojiTarget = emojiTargetRef.current;
      const editor = editorInstance.current;

      if (emojiTarget && editor) {
        const itemsLength = emojisRef.current.length;

        switch (context.key) {
          case BindigKeyType.arrowDown:
            if (emojitTargetRangeRef.current?.index) {
              setCursorPosition(editor, emojitTargetRangeRef.current.index);
            }

            const prevIndex =
              emojiIndexRef.current >= itemsLength - 1
                ? 0
                : emojiIndexRef.current + 1;
            setIndex(prevIndex);
            break;
          case BindigKeyType.arrowUp:
            if (emojitTargetRangeRef.current?.index) {
              setCursorPosition(editor, emojitTargetRangeRef.current.index);
            }

            const nextIndex =
              emojiIndexRef.current <= 0
                ? itemsLength - 1
                : emojiIndexRef.current - 1;
            setIndex(nextIndex);
            break;
          case BindigKeyType.tab:
          case BindigKeyType.enter:
            const emojiTarget = emojiTargetRef.current;
            if (emojiTarget) {
              const index = emojiIndexRef.current;
              const emoji = emojisRef.current[index];

              if (emoji) {
                const sentence = senteceFromOps(editor);
                const wordBefore = getWordBefore(editor, sentence);
                const wordBeforePos = wordBefore.position;

                handleSelectedEmoji(emoji, {
                  ...wordBeforePos,
                  ...wordBefore,
                });
              }
              hideEmojiPopover();
            }
            break;
          case BindigKeyType.escape:
            hideEmojiPopover();
            break;
        }
      }
    };

    const onSubmitRef = useRef(onSubmit);
    onSubmitRef.current = onSubmit;

    const keyboardCtrlEnterKeyHandle = (range: RangeStatic, context: any) => {
      const editor = editorInstance.current;

      if (!editor) {
        return;
      }

      dangerouslyInsertLineBreak(editor, range);
      editor.setSelection(range.index + 1, 0);
    };

    const keyboardEnterKeyHandle = (range: RangeStatic, context: any) => {
      const editor = editorInstance.current;

      if (isMobile && editor) {
        dangerouslyInsertLineBreak(editor, range);
        return;
      }

      if (!emojiTargetRef.current) {
        onSubmitRef.current();
      }
    };

    const keyboardDeleteKeyHandle = (range: RangeStatic, context: any) => {
      const editor = editorInstance.current;
      const extendedPictographicRegExp = /\p{Extended_Pictographic}/u;

      if (!editor) {
        return;
      }

      const withEmoji = extendedPictographicRegExp.test(
        editor.getText(range.index),
      );

      if (withEmoji && range.length === EMOJI_LENGTH_IN_EDITOR) {
        editor.deleteText(range.index, EMOJI_LENGTH_IN_EDITOR);
        return;
      }

      if (!range.length) {
        editor.deleteText(range.index, 1);
      }

      editor.deleteText(range.index, range.length);
    };

    const initListeners = () => {
      const editor = editorInstance.current;

      if (!editor) {
        return;
      }

      editor.on('text-change', handleChange);
      editor.on('selection-change', handleFocus);
    };

    const atTypes = ['channel', 'here'];

    const renderListMentionItem = (item: MentionData) =>
      `
        ${
          item.type && atTypes.includes(item.type)
            ? renderSpecialMention(item)
            : renderUserMention({
                ...item,
                avatar: item.avatar,
              })
        }
      `;

    const onSelectMention = useCallback(
      (
        item: { value: string },
        insertItem: (args: { value: string }) => void,
      ) => {
        const mention = item.value;

        insertItem({
          ...item,
          value: mention, // here we can override item.value text to tag like <div>${item.value}</div>
        });
      },
      [],
    );

    useEffect(() => {
      getMentionDataRef.current = mentionData;
    }, [mentionData]);

    const getMentionDataRef = useRef(mentionData);

    const initEditor = () => {
      if (editorContainerRef.current && !editorInstance.current) {
        editorInstance.current = new Quill(editorContainerRef.current, {
          modules: {
            keyboard: {
              bindings: EditorKeyboardBindings({
                keyboardSpaceKeyHandle,
                keyboardArrowKeyHandle,
                keyboardEnterKeyHandle,
                keyboardCtrlEnterKeyHandle,
                keyboardDeleteKeyHandle,
              }),
            },
            mention: {
              positioningStrategy: 'absolute',
              mentionDenotationChars: ['@'],
              mentionContainerClass: 'mentionContainer',
              mentionListClass: 'mentionList',
              listItemClass: 'mentionItem',
              defaultMenuOrientation: 'top',
              renderItem: renderListMentionItem,
              onSelect: onSelectMention,
              source: (
                searchTerm: string,
                renderList: (
                  data: (AccountWithCountsApiType | MentionData)[],
                  term: string,
                ) => void,
                mentionChar: string,
              ) =>
                mentionListRenderer(
                  searchTerm,
                  renderList,
                  mentionChar,
                  getMentionDataRef.current,
                  editorInstance.current || null,
                ),
            },
          },
          formats: ['mention'],
        });

        editorInstance.current!.clipboard.addMatcher(
          Node.ELEMENT_NODE,
          (node, delta) => {
            return prepareTextToPaste(delta);
          },
        );

        initListeners();
      }
    };

    useEffectOnce(() => {
      initEditor();
    });

    const hideEmojiPopover = () => {
      setEmojiTarget(undefined);
      emojitTargetRangeRef.current = undefined;
    };

    const [triggerRect, setTriggerRect] = useState<DOMRect>();

    useEffect(() => {
      const target = emojiTarget && emojis.length ? emojiTarget : null;
      const editor = editorInstance.current;

      if (!target || !editor) {
        return;
      }

      const domRange = editor.root.getBoundingClientRect();
      setTriggerRect(domRange);
    }, [emojiTarget, emojis.length]);

    useEffect(() => {
      const editor = editorInstance.current;

      if (!editor) {
        return;
      }

      editor.enable(!readOnly);
    }, [readOnly]);

    return (
      <StyledEditorContainer>
        <StyledEditor
          ref={editorContainerRef}
          data-testid="editor"
          onPaste={e => pasteImages(e.clipboardData.files, onImagePasted)}
        />
        {children}

        {emojiTarget && emojis.length > 0 && (
          <EmojiAutocompletePopover
            emojis={emojis}
            selectedIndex={index}
            onRequestClose={hideEmojiPopover}
            triggerRect={triggerRect}
            onSelect={handleSelectedEmoji}
            onIndexChange={setIndex}
          />
        )}
      </StyledEditorContainer>
    );
  },
);
