import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  EmptyHeader,
  EmptyImage,
  ImportWarning,
  StyledEmptyState,
  StyledMessage,
} from './EmptyState.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../../Desktop.hooks';
import { DesktopTranslation } from '../../../i18n';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { useCurrentTypeOfPage } from '../../../Desktop.utils';
import StartContent from '../../../../../shared/assets/images/start-content.svg';
import { appEnv } from '../../../../../appEnv';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import { extensionSupported } from '../../../../../shared/utils/extension.utils';
import { useNavigate } from 'react-router-dom';

interface EmptyStateProps {
  adminView?: boolean;
}

export const EmptyStateMobileVersion: FC<EmptyStateProps> = ({ adminView }) => {
  const navigate = useNavigate();
  const currentDesktop = useCurrentDesktop();
  const { canAddApp } = useCurrentDesktopPermissions();
  const queryParams = useQueryParams();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  const renderMessage = () => {
    if (isFavoritesDesktop) {
      return (
        <StyledMessage data-testid="no-favorites-mobile-msg">
          <FormattedMessage id={DesktopTranslation.emptyAppsMessageFavorites} />
        </StyledMessage>
      );
    }

    return (
      <>
        <EmptyImage src={StartContent} />
        <EmptyHeader data-testid="empty-header">
          <FormattedMessage id={DesktopTranslation.emptyHeader} />
        </EmptyHeader>
        <StyledMessage data-testid="empty-msg">
          <FormattedMessage id={DesktopTranslation.emptyMessage} />
        </StyledMessage>
      </>
    );
  };

  return (
    <StyledEmptyState data-testid="empty-state">
      {renderMessage()}

      {currentDesktop && canAddApp && (
        <>
          <div>
            <Button
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    appStoreModal: true,
                  }),
                })
              }
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              data-testid="add-app">
              <FormattedMessage id={DesktopTranslation.emptyAddAppsButton} />
            </Button>
          </div>

          {!extensionSupported && !adminView && (
            <ImportWarning>
              <ImportantMessage
                type={ImportantMessageType.DEFAULT}
                withIcon
                circleIcon>
                <FormattedHTMLMessage
                  id={DesktopTranslation.importLoginComputerBrowserWarning}
                  values={{ importFaqUrl: appEnv.SUPPORT_IMPORT_BOOKMARKS_URL }}
                />
              </ImportantMessage>
            </ImportWarning>
          )}
        </>
      )}
    </StyledEmptyState>
  );
};
