import React, { FC } from 'react';
import { CreateCustomAppModalBody } from '../../AppStore/Modals/CreateCustomAppModal/CreateCustomAppModal.styled';
import { Modal, ModalHeader } from '../../../shared/components/Modal';
import DesktopLogo from '../../../shared/assets/images/logo-min.svg';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  ButtonWrapper,
  NotificationLogoWrapper,
  NotificationWrapper,
} from './NotificationPermissionModal.styled';
import { WebPushTranslation } from '../i18n';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

interface NotificationPermissionModalProps {
  onSubmit: () => void;
  onClose: () => void;
  onBackdropClick: () => void;
}

export const NotificationPermissionModal: FC<
  NotificationPermissionModalProps
> = ({ onSubmit, onClose, onBackdropClick }) => {
  const intl = useIntl();

  return (
    <Modal visible={true} width={486} onRequestClose={onBackdropClick}>
      <>
        <ModalHeader onRequestClose={onClose}>
          <FormattedHTMLMessage id={WebPushTranslation.permissionModalTitle} />
        </ModalHeader>

        <CreateCustomAppModalBody>
          <NotificationLogoWrapper>
            <img
              src={DesktopLogo}
              alt={intl.formatMessage({
                id: WebPushTranslation.permissionModalImageAlt,
              })}
            />
          </NotificationLogoWrapper>

          <NotificationWrapper>
            <FormattedHTMLMessage
              id={WebPushTranslation.permissionModalDescription}
            />
          </NotificationWrapper>

          <ButtonWrapper>
            <Button
              mode={ButtonMode.secondary}
              fullWidth
              type="button"
              size={ButtonSize.md}
              onClick={onClose}>
              <FormattedHTMLMessage
                id={WebPushTranslation.permissionModalCancelButton}
              />
            </Button>

            <Button
              mode={ButtonMode.primary}
              fullWidth
              type="button"
              size={ButtonSize.md}
              onClick={onSubmit}>
              <FormattedHTMLMessage
                id={WebPushTranslation.permissionModalSubmitButton}
              />
            </Button>
          </ButtonWrapper>
        </CreateCustomAppModalBody>
      </>
    </Modal>
  );
};
