import React, { FC, useCallback, useMemo } from 'react';
import {
  AppIntegrationsCategories,
  MarketplaceCategoryType,
} from '../../../AppIntegrations.types';
import {
  StyledMarketplaceCategoryWrapper,
  StyledMarketplaceItem,
  StyledMarketplaceItems,
  StyledRestItemsCountIndicator,
} from './MarketplaceCategory.styled';
import { generatePath, useNavigate } from 'react-router-dom';
import { MARKETPLACE_CATEGORY_PATH } from '../../../AppIntegrations.constants';
import { getShortId } from '../../../../../shared/utils/id';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { AdminHeaderSeparator } from '../../../../Admin';

interface IntegrationAppProps {
  marketplaceCategory: MarketplaceCategoryType;
  integrationCategory: AppIntegrationsCategories;
}

export const MarketplaceCategory: FC<IntegrationAppProps> = ({
  marketplaceCategory,
  integrationCategory,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();

  const handleViewCategoryItemsClick = useCallback(() => {
    navigate({
      pathname: generatePath(MARKETPLACE_CATEGORY_PATH, {
        workspaceId: getShortId(workspace.id),
        marketplaceCategoryId: getShortId(marketplaceCategory.id),
      }),
      search: getQueryParamsFrom({
        integrationsCategory: integrationCategory,
        ...queryParams,
      }),
    });
  }, [
    navigate,
    workspace.id,
    marketplaceCategory.id,
    integrationCategory,
    queryParams,
  ]);

  const firstFourAppsInCategory = useMemo(
    () => marketplaceCategory.marketplaceItems.slice(0, 4),
    [marketplaceCategory],
  );

  return (
    <StyledMarketplaceCategoryWrapper onClick={handleViewCategoryItemsClick}>
      <img
        className="logo"
        src={marketplaceCategory.image.contentUrl}
        alt={marketplaceCategory.name}
      />
      <div className="body">
        <span className="name">{marketplaceCategory.name}</span>
      </div>
      <div className="controls">
        <StyledMarketplaceItems>
          {firstFourAppsInCategory.map(item => (
            <StyledMarketplaceItem
              isActive={item?.accountAppIntegration?.active || false}
              key={item.id}>
              <img src={item.app.logo.contentUrl} alt={item.app.name} />
              <div className="is-active" />
            </StyledMarketplaceItem>
          ))}
        </StyledMarketplaceItems>
        {marketplaceCategory.marketplaceItems.length > 4 && (
          <StyledRestItemsCountIndicator>
            +{marketplaceCategory.marketplaceItems.length - 4}
          </StyledRestItemsCountIndicator>
        )}
        <AdminHeaderSeparator />
      </div>
    </StyledMarketplaceCategoryWrapper>
  );
};
