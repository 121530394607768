import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyledBodyRowTitle,
  StyledDivider,
  StyledPasswordBodyRow,
  StyledPasswordCopyButton,
  StyledPasswordStrength,
  StyledPasswordViewBody,
  StyledPasswordViewTitle,
  StyledPasswordViewWrapper,
  StyledPasswordWithCopyButtonRow,
} from './PasswordView.styled';
import {
  getVaultAccess,
  getVaultApp,
  getVaultIri,
  getVaultItem,
  logPwmInfo,
  passwordStrengthChecker,
} from '../../Encryption.utils';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultLoginsMap,
  useWorkspaceVaultById,
} from '../../Encryption.hooks';
import { PasswordStrength, VaultItemApiType } from '../../Encryption.types';
import { extractNodes } from '../../../../shared/api/api.utils';
import { EncryptionTranslation } from '../../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useTheme } from 'styled-components';
import { matchPath, useLocation } from 'react-router-dom';
import { PASSWORDS_VAULT_PATHNAME } from '../../../Desktop/Desktop.constants';
import { PasswordSharedWith } from './PasswordSharedWith/PasswordSharedWith';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import {
  decryptVaultAccessKey,
  decryptVaultItem,
} from '../../Encryption.crypto.utils';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { appEnv } from '../../../../appEnv';
import { PasswordsImage } from '../PasswordsImage/PasswordsImage';
import { getHostname } from '../../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.utils';

export const PasswordView = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { pathname } = useLocation();

  const currentVaultId = useMemo(() => {
    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { vaultId } = match.params;
    return vaultId ? getVaultIri(vaultId) : null;
  }, [pathname]);

  const { vault } = useWorkspaceVaultById(currentVaultId as string);
  const vaultApp = useMemo(() => getVaultApp(vault), [vault]);
  const vaultItemDomain = useMemo(() => {
    const vaultItemUrl = (vault && getVaultItem(vault).url) || '';
    return getHostname(vaultItemUrl);
  }, [vault]);

  const { keyset } = useCurrentAccountKeyset();
  const [decryptedVaultItem, setDecryptedVaultItem] = useState<
    VaultItemApiType | undefined
  >();
  const passwordStrength = useRef<{ color: string; tooltip: string }>();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { loginsMap } = useCurrentWorkspaceVaultLoginsMap();
  const keysetId = keyset?.id || '';
  const vaultItem = useMemo(() => {
    return extractNodes(vault?.vaultItems)[0];
  }, [vault]);
  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keysetId);
  }, [vault, keyset, keysetId]);
  const vaultAccessKey = vaultAccess?.vaultKeyEncrypted;

  const name = vaultApp?.fullName || vaultItemDomain;
  const imageSrc = vaultApp?.logo.contentUrl || vaultItem?.image?.contentUrl;
  const url = vaultApp?.uri || vaultItem?.url;

  const generatePasswordStrength = useCallback(
    (decryptedPassword: string) => {
      switch (passwordStrengthChecker(decryptedPassword)) {
        case PasswordStrength.STRONG:
          return {
            color: theme.colors.SuccessDark,
            tooltip: intl.formatMessage({
              id: EncryptionTranslation.passwordStrengthVeryStrong,
            }),
          };
        case PasswordStrength.MEDIUM:
          return {
            color: theme.colors.PrimaryDark,
            tooltip: intl.formatMessage({
              id: EncryptionTranslation.passwordStrengthMedium,
            }),
          };
        case PasswordStrength.WEAK:
          return {
            color: theme.colors.CriticalDark,
            tooltip: intl.formatMessage({
              id: EncryptionTranslation.passwordStrengthWeak,
            }),
          };
      }
    },
    [intl, theme],
  );

  /*
   * Logs
   * */
  useEffect(() => {
    logPwmInfo('PasswordView -> current user keyset Id', keysetId);
    logPwmInfo('PasswordView -> password vaultAccess Id', vaultAccess?.id);
    logPwmInfo(
      'PasswordView -> password vaultAccess keyset id',
      vaultAccess?.keyset?.id,
    );
    logPwmInfo('PasswordView -> password vaultItem Id', vaultItem?.id);
  }, [keysetId, vaultAccess, vaultItem]);

  useEffect(() => {
    if (
      privateKey &&
      vaultItem &&
      vaultAccessKey &&
      loginsMap &&
      currentVaultId
    ) {
      decryptVaultAccessKey(privateKey, vaultAccessKey)
        .then(decryptedVaultAccessKey => {
          logPwmInfo(
            'PasswordView -> decryptVaultAccessKey -> vaultAccessKey decrypted',
            true,
          );
          return Promise.all([
            loginsMap?.[currentVaultId],
            decryptVaultItem(decryptedVaultAccessKey, vaultItem.password),
          ]);
        })
        .then(([decryptedLogin, decryptedPassword]) => {
          logPwmInfo(
            'PasswordView -> decryptVaultAccessKey -> decryptedLogin',
            !!decryptedLogin,
          );
          logPwmInfo(
            'PasswordView -> decryptVaultAccessKey -> decryptedPassword',
            !!decryptedPassword,
          );
          passwordStrength.current =
            generatePasswordStrength(decryptedPassword);
          setDecryptedVaultItem({
            ...vaultItem,
            login: decryptedLogin,
            password: decryptedPassword,
          });
        });
    }
  }, [
    currentVaultId,
    loginsMap,
    privateKey,
    vaultItem,
    vaultAccessKey,
    vaultApp,
    generatePasswordStrength,
  ]);

  const handleCopyLogin = useCallback(() => {
    if (decryptedVaultItem?.login) {
      navigator.clipboard.writeText(decryptedVaultItem.login).then(() => {
        showToastSuccessMessage(
          EncryptionTranslation.passwordCopyLoginToastText,
        );
      });
    }
  }, [decryptedVaultItem]);

  const handleCopyPassword = useCallback(() => {
    if (decryptedVaultItem?.password) {
      navigator.clipboard.writeText(decryptedVaultItem.password).then(() => {
        showToastSuccessMessage(
          EncryptionTranslation.passwordCopyPasswordToastText,
        );
      });
    }
  }, [decryptedVaultItem]);

  if (!vaultItem) {
    return null;
  }

  return (
    <>
      <StyledPasswordViewWrapper data-testid="password-view">
        <StyledPasswordViewTitle data-testid="password-view-title">
          {imageSrc && (
            <PasswordsImage
              size={72}
              url={imageSrc}
              alt={name!}
              borderRadius={8}
              defaultMargin={false}
            />
          )}
          <span data-testid="name">{name}</span>
        </StyledPasswordViewTitle>
        <StyledDivider />
        <StyledPasswordViewBody data-testid="password-view-body">
          {vaultAccessKey ? (
            <>
              <StyledPasswordWithCopyButtonRow data-testid="login-row">
                <div>
                  <StyledBodyRowTitle data-testid="login-label">
                    <FormattedMessage
                      id={EncryptionTranslation.passwordViewLoginRow}
                    />
                  </StyledBodyRowTitle>
                  <span data-testid="login-value">
                    {decryptedVaultItem?.login}
                  </span>
                </div>
                {decryptedVaultItem?.login && (
                  <StyledPasswordCopyButton
                    onClick={handleCopyLogin}
                    data-testid="login-copy-button"
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={intl.formatMessage({
                      id: EncryptionTranslation.passwordViewLoginCopyButton,
                    })}
                    data-tooltip-place={TooltipPlace.left}
                    aria-label={intl.formatMessage({
                      id: EncryptionTranslation.passwordViewLoginCopyButton,
                    })}
                  />
                )}
              </StyledPasswordWithCopyButtonRow>

              <StyledPasswordWithCopyButtonRow data-testid="password-row">
                <div>
                  <StyledBodyRowTitle data-testid="password-label">
                    <FormattedMessage
                      id={EncryptionTranslation.passwordViewPasswordRow}
                    />
                  </StyledBodyRowTitle>
                  <span data-testid="password-value">
                    {'•'.repeat(decryptedVaultItem?.password?.length || 0)}
                  </span>
                </div>
                {decryptedVaultItem?.password && (
                  <StyledPasswordCopyButton
                    onClick={handleCopyPassword}
                    data-testid="password-copy-button"
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={intl.formatMessage({
                      id: EncryptionTranslation.passwordViewPasswordCopyButton,
                    })}
                    data-tooltip-place={TooltipPlace.left}
                    aria-label={intl.formatMessage({
                      id: EncryptionTranslation.passwordViewPasswordCopyButton,
                    })}
                  />
                )}
              </StyledPasswordWithCopyButtonRow>
              <StyledPasswordBodyRow data-testid="password-strength-row">
                <StyledBodyRowTitle data-testid="password-strength-label">
                  <FormattedMessage
                    id={EncryptionTranslation.passwordViewStrengthRow}
                  />
                </StyledBodyRowTitle>
                <StyledPasswordStrength
                  color={passwordStrength?.current?.color}
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={
                    passwordStrength?.current?.tooltip || ''
                  }
                  data-tooltip-place={TooltipPlace.bottom}
                  data-testid="password-strength-color"
                />
              </StyledPasswordBodyRow>
            </>
          ) : (
            <div>
              <ImportantMessage
                type={ImportantMessageType.CRITICAL}
                data-testid="unable-to-decrypt-msg">
                <FormattedHTMLMessage
                  id={EncryptionTranslation.passwordUnableToDecryptMessage}
                  values={{ learnMoreUrl: appEnv.SUPPORT_PASSWORD_MANAGER_URL }}
                />
              </ImportantMessage>
            </div>
          )}
          <StyledPasswordBodyRow data-testid="password-website-row">
            <StyledBodyRowTitle data-testid="password-website-label">
              <FormattedMessage
                id={EncryptionTranslation.passwordViewWebsiteRow}
              />
            </StyledBodyRowTitle>
            <span data-testid="password-website-value">{url}</span>
          </StyledPasswordBodyRow>
          <PasswordSharedWith vault={vault} />
        </StyledPasswordViewBody>
      </StyledPasswordViewWrapper>
    </>
  );
};
