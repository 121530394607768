import { gql } from '@apollo/client';
import { TagApiType } from './Link.types';
import { ChatConversationFields } from '../Chat/Chat.fragments';
import { ChatConversationApiType } from '../Chat/Chat.types';

export const CREATE_TAG = gql`
  mutation CreateTag($input: createTagInput!) {
    __typename
    createTag(input: $input) {
      tag {
        id
        _id
        name
      }
    }
  }
`;

export interface CreateTagVariables {
  input: {
    name: string;
    workspace: string;
  };
}

export interface CreateTagResponse {
  createTag: {
    tag: TagApiType;
  };
}

export const CREATE_LINK_CHAT_CONVERSATION = gql`
  mutation CreateLinkChatConversation(
    $input: createLinkChatConversationInput!
  ) {
    __typename
    createLinkChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface CreateLinkChatConversationResponse {
  createLinkChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface CreateLinkChatConversationVariables {
  input: {
    link: string;
  };
}
