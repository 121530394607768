import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useQueryParams } from '../../../../../shared/hooks';
import { EyeClosedIcon, EyeIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { DesktopTranslation } from '../../../i18n';

export const ToolbarToggleOptions = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { showIosOptions } = queryParams;

  const handleOptionsToggle = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        showIosOptions: showIosOptions ? undefined : 'true',
      }),
    });
  }, [navigate, queryParams, showIosOptions]);

  return (
    <ButtonWithIcon
      icon={showIosOptions ? EyeClosedIcon : EyeIcon}
      iconFirst={true}
      mode={ButtonMode.tertiary}
      size={ButtonSize.xs}
      onClick={handleOptionsToggle}
      data-tourid="ios-toggle-options">
      <FormattedMessage
        id={
          showIosOptions
            ? DesktopTranslation.hideOptionsButtonLabel
            : DesktopTranslation.showOptionsButtonLabel
        }
      />
    </ButtonWithIcon>
  );
};
