import { gql } from '@apollo/client';
import {
  ConferenceApiType,
  ConferenceCallType,
  ConferenceInputType,
  ScheduleRepeatingRepeatFreqValues,
} from './Conference.types';
import { ChatConferenceFields } from './Conference.fragments';

export const CREATE_CHAT_CONFERENCE = gql`
  mutation CreateChatConference($input: createChatConferenceInput!) {
    createChatConference(input: $input) {
      chatConference {
        ${ChatConferenceFields}
        createdBy {
          id
        }
      }
    }
  }
`;

export interface CreateChatConferenceResponse {
  createChatConference: {
    chatConference: ConferenceApiType & {
      createdBy?: { id: string };
    };
  };
}

export interface CreateChatConferenceVariables {
  input: ConferenceInputType;
}

export const ACCEPT_INBOUND_CALL = gql`
  mutation acceptInboundCallChatConference(
    $input: acceptInboundCallChatConferenceInput!
  ) {
    acceptInboundCallChatConference(input: $input) {
      clientMutationId
    }
  }
`;

export interface AcceptInboundCallResponse {
  acceptInboundCallChatConference: {
    clientMutationId: string;
  };
}

export interface AcceptInboundCallVariables {
  input: {
    id: string;
  };
}

export const REJECT_INBOUND_CALL = gql`
  mutation rejectInboundCallChatConference(
    $input: rejectInboundCallChatConferenceInput!
  ) {
    rejectInboundCallChatConference(input: $input) {
      clientMutationId
    }
  }
`;

export interface RejectInboundCallResponse {
  rejectInboundCallChatConference: {
    clientMutationId: string;
  };
}

export interface RejectInboundCallVariables {
  input: {
    id: string;
  };
}

export const JOIN_CHAT_CONFERENCE = gql`
    mutation JoinFromAnotherWorkspaceChatConference($input: joinFromAnotherWorkspaceChatConferenceInput!) {
      joinFromAnotherWorkspaceChatConference(input: $input) {
        chatConference {
          ${ChatConferenceFields}
        }
      }
    }
`;

export interface JoinChatConferenceResponse {
  joinFromAnotherWorkspaceChatConference: {
    chatConference: ConferenceApiType;
  };
}

export interface JoinChatConferenceVariables {
  input: {
    id: string;
  };
}

export const JOIN_ANONYMOUS_CHAT_CONFERENCE = gql`
  mutation JoinAnonymousChatConference(
    $input: joinAnonymousChatConferenceInput!
  ) {
    joinAnonymousChatConference(input: $input) {
      chatConference {
        id
        jwtToken
        callType
        limit
      }
    }
  }
`;

export interface JoinAnonymousChatConferenceResponse {
  joinAnonymousChatConference: {
    chatConference: {
      id: string;
      jwtToken: string;
      callType: ConferenceCallType;
      limit: number;
      chatConversationIri: string;
      workspace: { id: string };
    };
  };
}

export interface JoinAnonymousChatConferenceVariables {
  input: {
    id: string;
  };
}

export const SEEN_CHAT_CONFERENCE = gql`
  mutation seenChatConference($input: seenChatConferenceInput!) {
    seenChatConference(input: $input) {
      chatConference {
        ${ChatConferenceFields}
      }
    }
  }
`;

export interface UpdateSeenChatConferenceVariables {
  input: {
    id: string;
  };
}

export interface UpdateSeenChatConferenceResponse {
  seenChatConference: {
    chatConference: ConferenceApiType;
  };
}

export interface CreateScheduledChatConferenceMembers {
  accountId?: string;
  email?: string;
}

export interface CreateScheduledChatConferenceInputType {
  workspace: string;
  chatConversation?: string;
  callType: ConferenceCallType;
  startAt: Date;
  endAt: Date;
  timezone: string;
  title: string;
  members: CreateScheduledChatConferenceMembers[];
  description: string;
}

export interface RepeatingScheduledChatConferenceRepeatPattern {
  COUNT?: number;
  REPEAT_EVERY: number;
  REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues | string;
  MONTH_DAY?: number;
  UNTIL?: string;
  WEEK_NO?: number;
  WEEK_DAY?: string;
  WEEK_DAYS?: string[];
}

export interface CreateRepeatingScheduledChatConferenceInputType
  extends CreateScheduledChatConferenceInputType {
  askToJoin: boolean;
  repeatPattern: RepeatingScheduledChatConferenceRepeatPattern;
}

export interface CreateScheduledChatConferenceVariables {
  input: CreateScheduledChatConferenceInputType;
}

export interface CreateScheduledChatConferenceResponse {
  scheduleChatConference: {
    id: string;
  };
}

export const CREATE_SCHEDULED_CHAT_CONFERENCE = gql`
  mutation createScheduleChatConference(
    $input: createScheduleChatConferenceInput!
  ) {
    createScheduleChatConference(input: $input) {
      scheduleChatConference {
        id
      }
    }
  }
`;

export interface UpdateScheduledChatConferenceMembers {
  added: {
    accountId?: string;
    email?: string;
  }[];
  deleted: string[];
}

export interface UpdateScheduledChatConferenceInputType {
  _id: string;
  id: string;
  startAt: Date;
  endAt: Date;
  timezone: string;
  title: string;
  members: UpdateScheduledChatConferenceMembers;
  description: string;
}

export interface UpdateScheduledChatConferenceResponse
  extends CreateScheduledChatConferenceResponse {}

export interface UpdateScheduledChatConferenceVariables {
  input: UpdateScheduledChatConferenceInputType;
}

export const UPDATE_SCHEDULED_CHAT_CONFERENCE = gql`
  mutation updateScheduleChatConference(
    $input: updateScheduleChatConferenceInput!
  ) {
    updateScheduleChatConference(input: $input) {
      scheduleChatConference {
        id
      }
    }
  }
`;

export interface UpdateRepeatingScheduledChatConferenceInputType
  extends UpdateScheduledChatConferenceInputType {
  repeatPattern: RepeatingScheduledChatConferenceRepeatPattern;
  updateOnlyAfter?: string;
}

export interface UpdateRepeatingScheduledChatConferenceResponse
  extends UpdateScheduledChatConferenceResponse {}

export interface UpdateRepeatingScheduledChatConferenceVariables {
  input: UpdateRepeatingScheduledChatConferenceInputType;
}

export const UPDATE_REPEATING_SCHEDULED_CHAT_CONFERENCE = gql`
  mutation updateRepeatingScheduleChatConference(
    $input: updateRepeatingScheduleChatConferenceInput!
  ) {
    updateRepeatingScheduleChatConference(input: $input) {
      repeatingScheduleChatConference {
        id
      }
    }
  }
`;

export const DELETE_SCHEDULED_CHAT_CONFERENCE = gql`
  mutation deleteCustomScheduleChatConference(
    $input: deleteCustomScheduleChatConferenceInput!
  ) {
    deleteCustomScheduleChatConference(input: $input) {
      scheduleChatConference {
        id
      }
      clientMutationId
    }
  }
`;

export interface DeleteScheduledChatConferenceVariables {
  input: {
    id: string;
    _id: string;
    workspace: string;
  };
}

export interface DeleteScheduledChatConferenceResponse {
  deleteCustomScheduleChatConference: {
    scheduleChatConference: {
      id: string;
    };
  };
}

export const DELETE_REPEATING_SCHEDULED_CHAT_CONFERENCES = gql`
  mutation deleteCustomRepeatingScheduleChatConference(
    $input: deleteCustomRepeatingScheduleChatConferenceInput!
  ) {
    deleteCustomRepeatingScheduleChatConference(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteRepeatingScheduledChatConferenceVariables {
  input: {
    id: string;
    _id: string;
    workspace: string;
    deleteOnlyAfter?: string;
  };
}

export interface DeleteRepeatingScheduledChatConferenceResponse {
  deleteRepeatingScheduleChatConference: {
    repeatingScheduleChatConference: {
      id: string;
    };
  };
}

export const CREATE_REPEATING_SCHEDULE_CHAT_CONFERENCE = gql`
  mutation createRepeatingScheduleChatConference(
    $input: createRepeatingScheduleChatConferenceInput!
  ) {
    createRepeatingScheduleChatConference(input: $input) {
      repeatingScheduleChatConference {
        id
      }
    }
  }
`;

export interface CreateRepeatingScheduledChatConferenceResponse {
  input: {
    id: string;
  };
}

export interface CreateRepeatingScheduledChatConferenceVariables {
  input: CreateRepeatingScheduledChatConferenceInputType;
}

export const DECLINE_SCHEDULE_CHAT_CONFERENCE = gql`
  mutation declineScheduleChatConference(
    $input: declineScheduleChatConferenceInput!
  ) {
    declineScheduleChatConference(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeclineScheduleChatConferenceResponse {
  input: {
    clientMutationId: string;
  };
}

export interface DeclineScheduleChatConferenceVariables {
  input: {
    id: string;
    _id: string;
    workspace: string;
  };
}
export const DECLINE_REPEATING_SCHEDULE_CHAT_CONFERENCE = gql`
  mutation declineRepeatingScheduleChatConference(
    $input: declineRepeatingScheduleChatConferenceInput!
  ) {
    declineRepeatingScheduleChatConference(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeclineRepeatingScheduleChatConferenceResponse {
  input: {
    clientMutationId: string;
  };
}

export interface DeclineRepeatingScheduleChatConferenceVariables {
  input: {
    id: string;
    _id: string;
    workspace: string;
  };
}
