import { format } from 'date-fns';
import type { UserContextProps } from '../ChatMessage/ChatMessage.types';
import { convertGraphQLApiChatMessageToInternal } from '../../../Chat.utils';
import type {
  ChatMessageAssetApiType,
  ChatMessageInternalType,
  ChatTextMessageInternalType,
} from '../../../Chat.types';
import { MessageType } from '../../../Chat.types';
import type { ChatMessagesTableType } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { generateRandomId, getLongId } from '../../../../../shared/utils/id';

export const optimisticChatMessage = (
  message: string,
  parentChatMessageId: string | null = null,
  assets: ChatMessageAssetApiType[] = [],
  accountId: string,
  userContext: UserContextProps,
) => {
  const date = new Date();
  const nowDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
  const optimisticMessageId = getLongId('chat-messages', generateRandomId());

  return convertGraphQLApiChatMessageToInternal(
    {
      accountIri: accountId,
      createdAt: nowDate,
      id: optimisticMessageId,
      message: message,
      modified: false,
      parentChatMessageIri: parentChatMessageId || '',
      reactions: [],
      seenBy: [accountId],
      threadMessagesCount: 0,
      type: parentChatMessageId
        ? MessageType.ThreadMessage
        : MessageType.Message,
      context: {
        assets,
      },
      isFirstUnread: false,
      isOptimistic: true,
      threadMessagesAccounts: !parentChatMessageId ? [accountId] : [],
      lastMessageInThreadAt: null,
    },
    userContext,
  ) as ChatTextMessageInternalType;
};

export const optimisticEditChatMessage = (
  editableMessage: ChatMessagesTableType,
  message: string,
  assets: ChatMessageAssetApiType[] = [],
): ChatMessageInternalType => ({
  ...editableMessage.data,
  message: message,
  isModified: true,
  type:
    editableMessage.data.type === MessageType.Message
      ? MessageType.Message
      : MessageType.ThreadMessage,
  context: {
    assets,
  },
});
