import React, { FC } from 'react';
import { Spinner } from '../../../shared/components/Spinner';
import { RelatedTeamsForm } from './RelatedTeamsForm/RelatedTeamsForm';
import styled from 'styled-components';
import type { TeamApiType } from '../data/Team/types/Team.types';
import { useTeamsRepository } from '../data/Team/Team.repositories';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';

const StyledRelatedTeams = styled.div`
  padding: 16px 25px 23px;
`;

interface ManageRelatedTeamProps {
  relatedTeams: TeamApiType[];
  disableSystemTeams?: boolean;
  onSubmit: (teams: TeamApiType[]) => void;
}

export const ManageRelatedTeams: FC<ManageRelatedTeamProps> = ({
  relatedTeams,
  disableSystemTeams,
  onSubmit,
}) => {
  const { workspace } = useCurrentWorkspace();

  const { teams, loading } = useTeamsRepository({
    workspaceId: workspace.id,
  });

  return (
    <StyledRelatedTeams>
      {loading ? (
        <Spinner />
      ) : (
        <RelatedTeamsForm
          teams={teams}
          relatedTeams={relatedTeams}
          disableSystemTeams={disableSystemTeams}
          onSubmit={onSubmit}
        />
      )}
    </StyledRelatedTeams>
  );
};
