import { ChatMessageAssetApiType } from '../../../Chat.types';
import {
  FileAssetApiType,
  ImageAssetApiType,
} from '../../../../Asset/Asset.types';
import { isImageAsset } from '../../../../Asset';
import {
  GRAPHQL_TYPENAME_FILE_ASSET,
  GRAPHQL_TYPENAME_IMAGE_ASSET,
} from '../../../../Asset/Asset.constants';
import { getShortId } from '../../../../../shared/utils/id';

export const makeAsset = (
  chatAsset: ChatMessageAssetApiType,
): FileAssetApiType | ImageAssetApiType => {
  const result = {
    _id: getShortId(chatAsset.assetIri),
    contentUrl: chatAsset.url,
    mimeType: chatAsset.mimeType,
    fileSize: chatAsset.size,
    metadata: chatAsset.metadata,
    filename: chatAsset.filename,
  };
  if (isImageAsset(chatAsset.assetIri)) {
    return {
      ...result,
      id: chatAsset.assetIri,
      __typename: GRAPHQL_TYPENAME_IMAGE_ASSET,
    };
  }
  return {
    ...result,
    id: chatAsset.assetIri,
    __typename: GRAPHQL_TYPENAME_FILE_ASSET,
  };
};
