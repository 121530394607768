import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ScheduledDowntimeContext } from './ScheduledDowntime.context';
import { showScheduledDowntimeMessage } from '../../shared/components/Toast';
import { GlobalTranslation } from '../Intl/i18n';
import {
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  isBefore,
  isSameDay,
} from 'date-fns';
import { FormattedHTMLMessage } from 'react-intl';
import { appEnv } from '../../appEnv';
import { ScheduledDowntimeCounter } from './ScheduledDowntimeCounter/ScheduledDowntimeCounter';
import { SCHEDULED_DOWNTIME_TOAST_ID } from './ScheduledDowntime.constants';

export const ScheduledDowntimeProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [scheduledDowntime, setScheduledDowntime] = useState('');

  const initScheduledDowntimeMessage = useCallback(() => {
    const currentTime = new Date();
    const scheduledDowntimeTime = new Date(scheduledDowntime);

    if (isBefore(scheduledDowntimeTime, currentTime)) {
      return;
    }

    const remainingTime = differenceInMilliseconds(
      scheduledDowntimeTime,
      currentTime,
    );
    const remainingTimeInMinutes = differenceInMinutes(
      scheduledDowntimeTime,
      currentTime,
    );
    showScheduledDowntimeMessage(
      isSameDay(new Date(scheduledDowntime), new Date()) &&
        remainingTimeInMinutes <= 60 ? (
        <ScheduledDowntimeCounter scheduledDowntime={scheduledDowntime} />
      ) : (
        <FormattedHTMLMessage
          id={GlobalTranslation.scheduledDowntimeDateMessage}
          values={{
            expectedValue: format(
              new Date(scheduledDowntime),
              'EEE, MMM dd, ' + appEnv.TIME_FORMAT,
            ),
          }}
        />
      ),
      {
        toastId: SCHEDULED_DOWNTIME_TOAST_ID,
      },
    );
  }, [scheduledDowntime]);

  useEffect(() => {
    if (scheduledDowntime) {
      initScheduledDowntimeMessage();
    }
  }, [initScheduledDowntimeMessage, scheduledDowntime]);

  return (
    <ScheduledDowntimeContext.Provider
      value={{ scheduledDowntime, setScheduledDowntime }}>
      {children}
    </ScheduledDowntimeContext.Provider>
  );
};
