const ROUNDED_VALUE = 4;

export const setTopLeftRadius = (topMost: boolean, leftMost: boolean) => {
  return topMost && leftMost ? ROUNDED_VALUE : 0;
};

export const setTopRightRadius = (topMost: boolean, rightMost: boolean) => {
  return topMost && rightMost ? ROUNDED_VALUE : 0;
};

export const setBottomLeftRadius = (bottomMost: boolean, leftMost: boolean) => {
  return bottomMost && leftMost ? ROUNDED_VALUE : 0;
};

export const setBottomRightRadius = (
  bottomMost: boolean,
  rightMost: boolean,
) => {
  return bottomMost && rightMost ? ROUNDED_VALUE : 0;
};
