import React, { Ref, useCallback, useMemo, useRef, useState } from 'react';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useMobile, useQueryParams } from '../../../../../../shared/hooks';
import {
  CallIcon,
  DeleteIcon,
  EditIcon,
  KebabIcon,
  LogoutIcon,
  TeamIcon,
  TimeIcon,
  VideoCallIcon,
} from '../../../../../../shared/icons';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../../../../Workspace/Workspace.hooks';
import { MobileMenuButton } from './Controls.styled';
import {
  CreateDesktopForImportModal,
  ExtensionInfoModal,
} from '../../../../../ChromeExtension';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../../i18n';
import {
  useCurrentDesktop,
  useDesktopPermissions,
  useLeaveDesktopMutation,
} from '../../../../Desktop.hooks';
import { extensionSupported } from '../../../../../../shared/utils/extension.utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { ImportModal } from '../../../../../ChromeExtension/Steps/ImportModal';
import { useConfirm } from '../../../../../../shared/components/Modal';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../../../../shared/components/Menu';
import { useModalControls } from '../../../../../../shared/components/Modal/Modal.hooks';
import { EditDesktopModal } from '../../../../DesktopsAdmin/DesktopView/EditDesktopModal';
import {
  ConferenceCallType,
  ConferenceCreateBy,
} from '../../../../../Conference/Conference.types';
import { openConferenceCreateWindow } from '../../../../../Conference/Conference.utils';
import { AddContentButton } from './AddContentButton/AddContentButton';
import { useCurrentTypeOfPage } from '../../../../Desktop.utils';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { useShareDesktopPopoverControls } from '../../../../ShareDesktopPopover';
import {
  CONFERENCES_ROOT_PATHNAME,
  DESKTOP_ROOT_PATHNAME,
} from '../../../../Desktop.constants';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { CallAllMembersList } from '../../../../ShareDesktopPopover/CallAllMembersList';
import { useCurrentConversation } from '../../../../../Chat/Chat.hooks';
import { useCurrentConference } from '../../../../../Conference/Conference.hooks';
import { useAccountsContext } from '../../../../../Account';
import { AccountApiType } from '../../../../../User/User.types';
import { sortMembersByAvailabilityAndName } from '../../../../../Chat/MembersList/MembersList.utils';
import { useApolloClient } from '@apollo/client';
import { removeFromChatConversationListCache } from '../../../../../Chat/cache/Chat.cache';
import {
  SegmentPageTitleBar,
  TitleBarControls,
  TitleBarTitle,
  TitleBarTitleButtonWrapper,
  TitleBarTitleContainer,
} from '../../../../../Segment/SegmentPageTitleBar';
import { NavigatorActionButton } from '../../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { useTimelineDesktopPopoverControls } from '../../../../../Timeline/Timeline.hooks';
import { useDesktopsRepository } from '../../../../data/Desktop/Desktop.repositories';
import { useDesktopsActions } from '../../../../data/Desktop/Desktop.actions';
import { PermissionContext } from '../../../../data/Desktop/types/Desktop.types';
import { sendDesktopTrackingEvent } from '../../../../tracking/DesktopTracking.utils';
import { getShortId } from '../../../../../../shared/utils/id';

export const DesktopControls = () => {
  const desktopTitleRef: Ref<HTMLButtonElement> = useRef(null);
  const timelineButtonRef: Ref<HTMLButtonElement> = useRef(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const intl = useIntl();
  const isMobile = useMobile();
  const currentDesktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const { askConfirmation } = useConfirm();
  const editDesktopModal = useModalControls();
  const [leaveDesktopMutation] = useLeaveDesktopMutation();
  const {
    permissions: { canCreateGroupCall, canCallAllDesktopMembers },
  } = useCurrentWorkspacePermissions();
  const queryParams = useQueryParams();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const MobileMenuRef: Ref<HTMLButtonElement> = useRef(null);
  const apolloClient = useApolloClient();
  const { removeDesktopById } = useDesktopsActions();

  const removeChatConversation = useCallback(() => {
    const chatIri = currentDesktop!.chatConversationIri;

    if (chatIri) {
      removeFromChatConversationListCache(apolloClient, chatIri, {
        workspaceId: getShortId(workspace.id),
      });
    }
  }, [apolloClient, currentDesktop, workspace.id]);

  const deleteDesktop = useCallback(async () => {
    if (!currentDesktop) {
      return;
    }

    removeChatConversation();
    await removeDesktopById(currentDesktop.id);
    navigate(
      generatePath(DESKTOP_ROOT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      }),
    );
  }, [
    currentDesktop,
    removeChatConversation,
    removeDesktopById,
    navigate,
    workspace.id,
  ]);

  const { desktops } = useDesktopsRepository({
    variables: {
      shareable: false,
    },
    fetchPolicy: 'cache-only',
  });

  const leaveDesktop = useCallback(() => {
    if (!currentDesktop) {
      return;
    }

    leaveDesktopMutation({
      variables: {
        input: {
          id: currentDesktop.id,
        },
      },
      update: () => removeDesktopById(currentDesktop.id),
    })
      .catch(() => {})
      .then(() => {
        navigate(
          generatePath(
            desktops.length === 1
              ? CONFERENCES_ROOT_PATHNAME
              : DESKTOP_ROOT_PATHNAME,
            {
              workspaceId: getShortId(workspace.id),
            },
          ),
        );
      });
  }, [
    leaveDesktopMutation,
    currentDesktop,
    removeDesktopById,
    workspace,
    navigate,
    desktops.length,
  ]);

  const { conversation } = useCurrentConversation();
  const { conference } = useCurrentConference();
  const { accountsWithAvailability } = useAccountsContext();

  const desktopAccountIds = useMemo(
    () => (currentDesktop ? currentDesktop.accountIds : []),
    [currentDesktop],
  );

  const participants = useMemo(() => {
    let participantsData: string[] = [];
    if (conversation) {
      participantsData = conversation.userIds;
    } else if (conference) {
      participantsData = conference.seenBy;
    } else if (currentDesktop) {
      participantsData = desktopAccountIds;
    }

    return participantsData
      .map(accountId => accountsWithAvailability[accountId])
      .filter(Boolean)
      .sort((a: AccountApiType, b: AccountApiType) => {
        return sortMembersByAvailabilityAndName(a, b, accountsWithAvailability);
      });
  }, [
    accountsWithAvailability,
    conference,
    conversation,
    currentDesktop,
    desktopAccountIds,
  ]);

  const { extensionInfoModal, createDesktopForImportModal, importModal } =
    queryParams;

  /**
   * @todo: API should be re-designed since desktops are now handled in a single context
   */
  const userPermissions = useDesktopPermissions(currentDesktop?.id);
  const adminUserPermissions = useDesktopPermissions(
    currentDesktop?.id,
    PermissionContext.admin,
  );
  const { account: currentAccount } = useCurrentWorkspaceAccount();

  const { canShare, canEdit, canLeave, canRemove } = userPermissions;
  const {
    canShare: canShareInternal,
    canEdit: canEditInternal,
    canRemove: canRemoveInternal,
  } = adminUserPermissions;

  const handleLeaveDesktopClick = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: DesktopTranslation.leaveConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) return;
      leaveDesktop();
    });
  }, [intl, askConfirmation, leaveDesktop]);

  const handleDeleteDesktopClick = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: DesktopTranslation.deleteConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) return;
      deleteDesktop();
    });
  }, [intl, askConfirmation, deleteDesktop]);

  const handleOpenConferenceCreateWindow = useCallback(
    (type: ConferenceCallType) => {
      openConferenceCreateWindow({
        currentAccountId: currentAccount.id,
        currentWorkspaceId: workspace.id,
        callType: type,
        createBy: ConferenceCreateBy.conversationId,
        id: currentDesktop?.chatConversationIri,
      });
    },
    [currentAccount.id, currentDesktop?.chatConversationIri, workspace.id],
  );

  const confirmOpenConferenceCreateWindow = useCallback(
    (type: ConferenceCallType) => {
      const accounts = participants.filter(acc => acc.id !== currentAccount.id);

      askConfirmation(
        intl.formatMessage(
          {
            id: DesktopTranslation.createConferenceConfirmationDescription,
          },
          {
            desktopName: currentDesktop?.name,
            countOfUsersForCall: accounts.length,
          },
        ),
        intl.formatMessage(
          {
            id: DesktopTranslation.createConferenceConfirmationTitle,
          },
          {
            desktopName: currentDesktop?.name,
          },
        ),
        {
          disabled: accounts.length === 0,
          confirmButtonText: intl.formatMessage({
            id: DesktopTranslation.callAllMembersButton,
          }),
          width: 360,
          userList: <CallAllMembersList accounts={accounts} />,
        },
      ).then(confirm => {
        if (!confirm) return;
        handleOpenConferenceCreateWindow(type);
      });
    },
    [
      askConfirmation,
      currentAccount.id,
      currentDesktop?.name,
      handleOpenConferenceCreateWindow,
      intl,
      participants,
    ],
  );

  const shareButtonRef = useRef<HTMLButtonElement>(null);
  const { showShareDesktopPopover } = useShareDesktopPopoverControls();
  const { showTimelineDesktopPopover } = useTimelineDesktopPopoverControls();

  const shareDesktopClickHandler = useCallback(
    (triggeredWithMenu?: boolean) => {
      if (!currentDesktop) {
        return;
      }
      showShareDesktopPopover(
        currentDesktop,
        isMobile ? MobileMenuRef : shareButtonRef,
      );
      triggeredWithMenu
        ? sendDesktopTrackingEvent('open_manage_users_from_menu')
        : sendDesktopTrackingEvent('open_manage_users_from_share_button');
    },
    [currentDesktop, isMobile, showShareDesktopPopover],
  );

  const desktopActivityClickHandler = useCallback(() => {
    if (!currentDesktop) {
      return;
    }
    showTimelineDesktopPopover(
      currentDesktop,
      isMobile ? MobileMenuRef : timelineButtonRef,
    );
  }, [currentDesktop, isMobile, showTimelineDesktopPopover]);

  if (!currentDesktop && !isFavoritesDesktop) {
    return null;
  }

  return (
    <SegmentPageTitleBar>
      {extensionInfoModal && extensionSupported && (
        <ExtensionInfoModal
          visible={true}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                extensionInfoModal: undefined,
              }),
            });
          }}
        />
      )}
      {createDesktopForImportModal && extensionSupported && (
        <CreateDesktopForImportModal
          visible={true}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                createDesktopForImportModal: undefined,
              }),
            });
          }}
        />
      )}
      {importModal && extensionSupported && (
        <ImportModal
          visible={true}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                importModal: undefined,
                importStep: undefined,
              }),
            });
          }}
        />
      )}

      <TitleBarTitleContainer>
        {canShare ||
        canShareInternal ||
        canEdit ||
        canEditInternal ||
        canLeave ? (
          <>
            <TitleBarTitleButtonWrapper
              data-tooltip-place={TooltipPlace.bottom}
              data-tooltip-id="global-tooltip"
              data-tooltip-content={intl.formatMessage({
                id: DesktopTranslation.tooltipManageDesktop,
              })}
              ref={desktopTitleRef}
              data-testid="current-desktop-name-button">
              <TitleBarTitle withChevron={true}>
                {currentDesktop!.name}
              </TitleBarTitle>
            </TitleBarTitleButtonWrapper>

            <Menu
              trigger={desktopTitleRef}
              width="auto"
              vAlign={VerticalAlignment.BOTTOM}
              hAlign={HorizontalAlignment.LEFT}
              data-testid="manage-desktop-menu">
              {(canEdit || canEditInternal) && (
                <MenuItem
                  onClick={editDesktopModal.open}
                  icon={() => <EditIcon height={16} width={16} />}
                  data-testid="edit-desktop-item">
                  <FormattedMessage id={DesktopTranslation.menuEditDesktop} />
                </MenuItem>
              )}

              {(canShare || canShareInternal) && (
                <MenuItem
                  onClick={() => shareDesktopClickHandler(true)}
                  icon={() => <TeamIcon width={16} height={16} />}
                  data-testid="share-desktop-item">
                  <FormattedMessage
                    id={DesktopTranslation.menuManageUsersLink}
                  />
                </MenuItem>
              )}

              {canLeave && (
                <MenuItem
                  onClick={handleLeaveDesktopClick}
                  icon={LogoutIcon}
                  data-testid="leave-desktop-item">
                  <FormattedMessage
                    id={DesktopTranslation.menuLeaveDesktop}
                    values={{
                      desktopName: currentDesktop!.name,
                    }}
                  />
                </MenuItem>
              )}

              {(canRemove || canRemoveInternal) && (
                <>
                  <MenuSeparator />
                  <MenuItem
                    highlightRed
                    onClick={handleDeleteDesktopClick}
                    icon={DeleteIcon}
                    data-testid="remove-desktop-item">
                    <FormattedMessage
                      id={DesktopTranslation.menuRemoveDesktop}
                    />
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        ) : (
          <TitleBarTitle withChevron={false}>
            {isFavoritesDesktop
              ? intl.formatMessage({ id: DesktopTranslation.listFavorites })
              : currentDesktop!.name}
          </TitleBarTitle>
        )}
      </TitleBarTitleContainer>
      {!isFavoritesDesktop && (
        <TitleBarControls>
          {currentDesktop && !isMobile && (
            <>
              <ButtonWithIcon
                ref={timelineButtonRef}
                data-testid="timeline-button"
                data-tourid="timeline-button"
                data-tooltip-id="global-tooltip"
                data-tooltip-content={intl.formatMessage({
                  id: DesktopTranslation.tooltipActivityButton,
                })}
                data-tooltip-place={TooltipPlace.bottom}
                icon={TimeIcon}
                mode={ButtonMode.secondary}
                size={ButtonSize.sm}
                onClick={() => {
                  showTimelineDesktopPopover(currentDesktop, timelineButtonRef);
                }}
                iconFirst>
                <FormattedMessage id={DesktopTranslation.menuTimeline} />
              </ButtonWithIcon>
            </>
          )}
          {(canShare || canShareInternal) && currentDesktop && !isMobile && (
            <>
              <ButtonWithIcon
                ref={shareButtonRef}
                data-testid="share-desktop-button"
                data-tourid="share-desktop-button"
                data-tooltip-id="global-tooltip"
                data-tooltip-content={intl.formatMessage({
                  id: DesktopTranslation.tooltipShareDesktopButton,
                })}
                data-tooltip-place={TooltipPlace.bottom}
                icon={TeamIcon}
                mode={ButtonMode.secondary}
                size={ButtonSize.sm}
                onClick={() => {
                  showShareDesktopPopover(currentDesktop, shareButtonRef);
                }}
                iconFirst>
                <FormattedMessage id={DesktopTranslation.shareButtonLabel} />
              </ButtonWithIcon>
            </>
          )}
          {!isMobile && <AddContentButton />}

          {canCreateGroupCall &&
            canCallAllDesktopMembers &&
            currentDesktop?.chatEnabled && (
              <>
                <NavigatorActionButton
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={intl.formatMessage(
                    { id: DesktopTranslation.tooltipAudioCallButton },
                    { desktopName: currentDesktop?.name },
                  )}
                  data-tooltip-place={TooltipPlace.bottom}
                  data-multiline={true}
                  icon={CallIcon}
                  iconSize={20}
                  onClick={() =>
                    confirmOpenConferenceCreateWindow(ConferenceCallType.audio)
                  }
                  data-testid="desktop-audiocall-button"
                />

                <NavigatorActionButton
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={intl.formatMessage(
                    {
                      id: DesktopTranslation.tooltipVideoCallButton,
                    },
                    {
                      desktopName: currentDesktop?.name,
                    },
                  )}
                  data-tooltip-place={TooltipPlace.bottom}
                  data-multiline={true}
                  icon={VideoCallIcon}
                  iconSize={20}
                  onClick={() =>
                    confirmOpenConferenceCreateWindow(ConferenceCallType.video)
                  }
                  data-testid="desktop-video-button"
                />
              </>
            )}

          {isMobile && (
            <>
              <AddContentButton />
              <MobileMenuButton
                isActive={isMobileMenuOpen}
                icon={KebabIcon}
                ref={MobileMenuRef}
              />
              <Menu
                trigger={MobileMenuRef}
                onOpen={() => {
                  setMobileMenuOpen(true);
                }}
                onClose={() => {
                  setMobileMenuOpen(false);
                }}
                width={120}
                vAlign={VerticalAlignment.BOTTOM}
                hAlign={HorizontalAlignment.RIGHT}>
                <MenuItem
                  icon={TeamIcon}
                  onClick={() => shareDesktopClickHandler()}>
                  <FormattedMessage id={DesktopTranslation.shareButtonLabel} />
                </MenuItem>
                <MenuItem icon={TimeIcon} onClick={desktopActivityClickHandler}>
                  <FormattedMessage id={DesktopTranslation.menuTimeline} />
                </MenuItem>
              </Menu>
            </>
          )}
        </TitleBarControls>
      )}

      {(canEdit || canEditInternal) && editDesktopModal.visible && (
        <EditDesktopModal onRequestClose={editDesktopModal.close} />
      )}
    </SegmentPageTitleBar>
  );
};
