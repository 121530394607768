import { appEnv } from '../../../../appEnv';
import { generateRandomId } from '../../../../shared/utils/id';

export const validatePendingEmailHashApi = (
  accountId: string,
  hash: string,
) => {
  const transactionId = generateRandomId();

  return fetch(
    `${appEnv.API_REST_URL}/validate_pending_email_hash/${accountId}/${hash}`,
    {
      method: 'GET',
      headers: {
        'X-Request-ID': transactionId,
      },
    },
  );
};
