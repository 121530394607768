import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MessageListContext } from './MessageList.context';
import { useCurrentConversation } from '../../../../Chat.hooks';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { VirtuosoHandle } from 'react-virtuoso';
import type { ChatMessageInternalType } from '../../../../Chat.types';

const MARK_UNREAD_TIME_OUT = 2000;
const MARK_MAX_PORTION = 50;

export const MessageListDataProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [unreadMessages, setUnreadMessages] = useState<
    Array<ChatMessageInternalType>
  >([]);
  const [listReady, setListReady] = useState<boolean>(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const virtualListRef = useRef<VirtuosoHandle | null>(null);
  const markApiTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const prevConversationId = useRef<string | null>(null);

  const { conversation } = useCurrentConversation();
  const { markChatMessagesAsSeen, markIDDBChatMessagesAsSeen } =
    useChatMessageRepository();

  const readyToMark = unreadMessages.length > 0 && listReady;

  const clearUnreadMessages = useCallback(() => {
    if (unreadMessages.length > 0) {
      setUnreadMessages([]);
    }
  }, [unreadMessages]);

  const markMessagesAsSeen = useCallback(async () => {
    if (readyToMark && unreadMessages[0]?.conversationId) {
      const unreadMessagesToMark = unreadMessages;
      clearUnreadMessages();

      await markIDDBChatMessagesAsSeen(unreadMessagesToMark);

      await markChatMessagesAsSeen(
        unreadMessagesToMark.map(({ id }) => id),
        unreadMessagesToMark[0].conversationId,
      );
    }
  }, [
    clearUnreadMessages,
    markChatMessagesAsSeen,
    markIDDBChatMessagesAsSeen,
    readyToMark,
    unreadMessages,
  ]);

  const addUnreadMessage = useCallback(
    async (message: ChatMessageInternalType) => {
      const messageExist = unreadMessages.find(({ id }) => id === message.id);

      if (!messageExist) {
        setUnreadMessages(prevState => [...prevState, { ...message }]);
      }
    },
    [unreadMessages],
  );

  const setVirtualListRef = (ref: VirtuosoHandle) => {
    virtualListRef.current = ref;
  };

  useEffect(() => {
    if (unreadMessages.length === MARK_MAX_PORTION) {
      markMessagesAsSeen();
      clearMarkApiTimeout();
    }
  }, [markMessagesAsSeen, unreadMessages]);

  useEffect(() => {
    if (unreadMessages.length > 0) {
      clearMarkApiTimeout();

      markApiTimeout.current = setTimeout(
        () => markMessagesAsSeen(),
        MARK_UNREAD_TIME_OUT,
      );
    }
  }, [clearUnreadMessages, markMessagesAsSeen, unreadMessages.length]);

  const clearMarkApiTimeout = () => {
    if (markApiTimeout.current) {
      clearTimeout(markApiTimeout.current);
    }
  };

  useEffect(() => {
    if (conversation && conversation.id !== prevConversationId.current) {
      prevConversationId.current = conversation.id;
      markMessagesAsSeen();
      clearUnreadMessages();

      clearMarkApiTimeout();
    }
  }, [clearUnreadMessages, conversation, markMessagesAsSeen]);

  return (
    <MessageListContext.Provider
      value={{
        addUnreadMessage,
        listReady,
        setListReady,
        unreadMessages,
        setIsAtBottom,
        isAtBottom,
        setVirtualListRef,
        virtualListRef: virtualListRef.current,
      }}>
      {children}
    </MessageListContext.Provider>
  );
};
