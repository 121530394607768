import React, { FC, useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { AppIntegrationsTranslation } from '../../../../i18n';
import { FormInput } from '../../../../../../shared/components/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateAppIntegrationCredentialMutation } from '../../../../AppIntegrations.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { useParams } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import {
  CreateAppIntegrationCredentialFormControls,
  CreateAppIntegrationCredentialFormWrapper,
} from './CreateAppIntegrationCredentialForm.styled';
import { useQueryParams } from '../../../../../../shared/hooks';
import { CreateAppIntegrationCredential } from '../../../../AppIntegrations.mutations';

interface CreateAppIntegrationCredentialFormValues {
  username: string;
  password: string;
  domain: string;
}

const CreateAppIntegrationCredentialFormSchema: Schema<CreateAppIntegrationCredentialFormValues> =
  Yup.object().shape({
    username: Yup.string().required(
      AppIntegrationsTranslation.createAppIntegrationCredentialUsernameRequired,
    ),
    password: Yup.string().required(
      AppIntegrationsTranslation.createAppIntegrationCredentialPasswordRequired,
    ),
    domain: Yup.string()
      .url(
        AppIntegrationsTranslation.createAppIntegrationCredentialUrlIsNotValid,
      )
      .required(
        AppIntegrationsTranslation.createAppIntegrationCredentialUrlRequired,
      ),
  });

export const CreateAppIntegrationCredentialForm: FC<{
  onClose: () => void;
  onDone: () => void;
}> = ({ onClose, onDone }) => {
  const [createAppIntegrationCredentialMutation] =
    useCreateAppIntegrationCredentialMutation();
  const queryParams = useQueryParams();
  const { createAppIntegrationCredentialMarketplaceItemId } = queryParams;
  const { workspaceId } = useParams<{
    workspaceId: string;
  }>();

  const onSubmit = useCallback(
    (values: CreateAppIntegrationCredential) => {
      createAppIntegrationCredentialMutation({
        variables: {
          input: {
            workspace: '/workspaces/' + workspaceId,
            appIntegrationMarketplaceItem:
              createAppIntegrationCredentialMarketplaceItemId as string,
            credentials: {
              ...values,
              domain: new URL(values.domain).hostname,
            },
          },
        },
      })
        .then(() => onDone())
        .catch(err => showToastGraphQLErrors(err.graphQLErrors));
    },
    [
      createAppIntegrationCredentialMutation,
      createAppIntegrationCredentialMarketplaceItemId,
      onDone,
      workspaceId,
    ],
  );

  const initialValues: CreateAppIntegrationCredentialFormValues = {
    username: '',
    password: '',
    domain: '',
  };
  const intl = useIntl();

  return (
    <CreateAppIntegrationCredentialFormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={CreateAppIntegrationCredentialFormSchema}>
        {({ isSubmitting }) => (
          <Form data-testid="create-app-integration-credential">
            <Field
              name="username"
              type="text"
              data-testid="integration-username-input"
              placeholder={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialEmailPlaceholder,
              })}
              label={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialEmail,
              })}
              component={FormInput}
            />
            <Field
              name="password"
              type="password"
              data-testid="integration-password-input"
              placeholder={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialPasswordPlaceholder,
              })}
              label={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialPassword,
              })}
              component={FormInput}
            />
            <Field
              name="domain"
              type="text"
              data-testid="integration-domain-input"
              placeholder={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialDomainPlaceholder,
              })}
              label={intl.formatMessage({
                id: AppIntegrationsTranslation.appIntegrationCreateCredentialDomain,
              })}
              component={FormInput}
            />
            <CreateAppIntegrationCredentialFormControls>
              <Button
                data-testid="cancel-button"
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onClose}>
                <FormattedMessage
                  id={
                    AppIntegrationsTranslation.createAppIntegrationCredentialCancel
                  }
                />
              </Button>
              <Button
                data-testid="submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={isSubmitting}>
                <FormattedMessage
                  id={
                    AppIntegrationsTranslation.createAppIntegrationCredentialSave
                  }
                />
              </Button>
            </CreateAppIntegrationCredentialFormControls>
          </Form>
        )}
      </Formik>
    </CreateAppIntegrationCredentialFormWrapper>
  );
};
