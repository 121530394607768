import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ClipboardContext } from './Clipboard.context';
import {
  useCreateClipboardMutation,
  useCurrentDesktop,
  useDesktopPermissions,
  useGetClipboardQuery,
} from '../Desktop/Desktop.hooks';
import {
  isClipboardSupported,
  readClipboard,
} from '../../shared/utils/clipboard.util';
import { ClipboardContextTypes } from './Clipboard.types';
import { getCookie } from '../../shared/utils/storage';
import { DesktopTab } from '../Desktop/Desktop.constants';
import { SESSION_INSTANCE_ID } from '../Auth/Auth.constants';

const clipboardSessionId = getCookie(SESSION_INSTANCE_ID);

export const ClipboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentDesktop = useCurrentDesktop();
  const { canPasteDesktopAppsSection, canPasteDesktopLinksSection } =
    useDesktopPermissions(currentDesktop?.id);
  const [clipboardData, setClipboardData] = useState<any>(null);

  const { refetch: refetchClipboard, loading } = useGetClipboardQuery({
    skip: !clipboardSessionId || !isClipboardSupported,
    variables: {
      uuid: clipboardSessionId as string,
    },
    fetchPolicy: 'network-only',
  });

  const [createClipboardMutation] = useCreateClipboardMutation();

  const parseClipboardData = (data: string) => {
    const clipboardData = JSON.parse(data);

    if (
      clipboardData.section === DesktopTab.apps ||
      clipboardData.section === DesktopTab.links
    ) {
      setClipboardData(clipboardData);
    } else {
      setClipboardData(null);
    }
  };

  const createClipboardData = useCallback(
    (data: string, onCreate?: () => void) => {
      if (isClipboardSupported) {
        navigator.clipboard.writeText(data).then(() => {
          setClipboardData(null);
          if (onCreate) {
            onCreate();
          }
        });
      } else {
        if (!clipboardSessionId) {
          return;
        }
        createClipboardMutation({
          variables: {
            input: {
              uuid: clipboardSessionId,
              data,
            },
          },
        })
          .then(() => {
            setClipboardData(null);
            if (onCreate) {
              onCreate();
            }
          })
          .catch(() => {});
      }
    },
    [createClipboardMutation],
  );

  const readClipboardData = useCallback(() => {
    if (!clipboardData) {
      if (isClipboardSupported) {
        readClipboard()
          .then(data => {
            if (data) {
              parseClipboardData(data);
            }
          })
          .catch(() => {});
      } else {
        refetchClipboard()
          .then(({ data }) => {
            if (data) {
              parseClipboardData(data.getClipboard.data);
            }
          })
          .catch(() => {});
      }
    }
  }, [clipboardData, refetchClipboard]);

  const clearClipboardData = useCallback(() => {
    if (isClipboardSupported) {
      navigator.clipboard.writeText('').finally(() => setClipboardData(null));
    } else {
      if (!clipboardSessionId) {
        return;
      }
      createClipboardMutation({
        variables: {
          input: {
            uuid: clipboardSessionId,
            data: '',
          },
        },
      })
        .then(() => {
          setClipboardData(null);
          refetchClipboard();
        })
        .catch(() => {});
    }
  }, [createClipboardMutation, refetchClipboard]);

  const canPaste = useMemo(() => {
    return (
      !loading &&
      !!clipboardData &&
      (canPasteDesktopAppsSection || canPasteDesktopLinksSection) &&
      currentDesktop!.id !== clipboardData.desktopFrom
    );
  }, [
    canPasteDesktopAppsSection,
    canPasteDesktopLinksSection,
    clipboardData,
    currentDesktop,
    loading,
  ]);

  const clipboardContextData: ClipboardContextTypes = {
    canPaste,
    clipboardData: clipboardData || null,
    clearClipboardData,
    readClipboardData,
    createClipboardData: (data, onCreate) =>
      createClipboardData(data, onCreate),
  };

  return (
    <ClipboardContext.Provider value={clipboardContextData}>
      {children}
    </ClipboardContext.Provider>
  );
};
