import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  DESKTOP_ID_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopTab,
  FAVORITES_ROOT_PATHNAME,
  FAVORITES_TAB_PATHNAME,
} from '../../../Desktop.constants';
import { TabBar } from './TabBar/TabBar';
import { StyledLayoutTabbed } from './LayoutTabbed.styled';
import { AppsSection, ChatSection } from '../Section';
import {
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../shared/hooks';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../Workspace/Workspace.hooks';
import {
  useCurrentDesktop,
  useCurrentDesktopContent,
  useGetIsChatHidden,
} from '../../../Desktop.hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { MembersUniversal } from '../../../../Chat/MembersUniversal';
import { useCurrentTypeOfPage } from '../../../Desktop.utils';
import { useCurrentConversation } from '../../../../Chat/Chat.hooks';
import { SectionLinks } from '../../../../Link/LinksView/SectionLinks/SectionLinks';
import { getShortId } from '../../../../../shared/utils/id';

export const LayoutTabbed: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const desktop = useCurrentDesktop();
  const { desktopLayout, desktopApps, desktopLinks, desktopFolders, hasChat } =
    useCurrentDesktopContent();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const queryParams = useQueryParams();
  const [redirectConsolidated, setRedirectConsolidated] = useState(false);
  const [redirectToTab, setRedirectToTab] = useState(false);
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const { conversation } = useCurrentConversation();
  const isChatHidden = useGetIsChatHidden();
  const {
    permissions: { canViewDesktopChatSection },
  } = useCurrentWorkspacePermissions();
  const [currentTab, setCurrentTab] = useState<DesktopTab | undefined>(
    undefined,
  );

  const hasApps = !!desktopApps.length;
  const hasLinks = !!desktopLinks.length;
  const hasFolders = !!desktopFolders.length;

  const { tab } = useParams<{ tab?: DesktopTab }>();

  /**
   * Set initial tab if no active tab
   */
  useLayoutEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  /**
   * Verify valid tabs
   */
  const didRedirectDesktopId = useRef<undefined | null | string>();

  useEffect(() => {
    let redirectToConsolidated: boolean = false;
    if (
      currentTab !== DesktopTab.apps &&
      currentTab !== DesktopTab.links &&
      currentTab !== DesktopTab.chat
    ) {
      setRedirectToTab(true);
    } else if (currentTab === DesktopTab.apps && !hasApps) {
      redirectToConsolidated = true;
    } else if (currentTab === DesktopTab.links && !hasLinks && !hasFolders) {
      redirectToConsolidated = true;
    } else if (currentTab === DesktopTab.chat && !hasChat) {
      redirectToConsolidated = true;
    } else if (!isTablet && desktopLayout !== DesktopLayout.TABBED) {
      redirectToConsolidated = true;
    }

    if (redirectToConsolidated) {
      setRedirectConsolidated(true);
    }
  }, [
    currentTab,
    desktopLayout,
    hasApps,
    hasChat,
    hasLinks,
    hasFolders,
    isTablet,
  ]);

  let tabPath: string | null = null;

  let shouldRedirectToTabPath =
    redirectToTab &&
    !tab &&
    (didRedirectDesktopId.current !== (desktop && getShortId(desktop.id)) ||
      isFavoritesDesktop);

  let shouldRedirectToOtherTab =
    (currentTab === DesktopTab.apps && !hasApps) ||
    (currentTab === DesktopTab.links && !hasLinks && !hasFolders);

  if (!tab) {
    shouldRedirectToTabPath = true;
  }

  if (shouldRedirectToTabPath || shouldRedirectToOtherTab) {
    if (hasApps) {
      tabPath = isFavoritesDesktop
        ? generatePath(FAVORITES_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            tab: DesktopTab.apps,
          })
        : generatePath(DESKTOP_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            desktopId: desktop && getShortId(desktop.id),
            tab: DesktopTab.apps,
          });
    } else if (hasLinks || hasFolders) {
      tabPath = isFavoritesDesktop
        ? generatePath(FAVORITES_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            tab: DesktopTab.links,
          })
        : generatePath(DESKTOP_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            desktopId: desktop && getShortId(desktop.id),
            tab: DesktopTab.links,
          });
    } else if (hasChat) {
      tabPath = isFavoritesDesktop
        ? generatePath(FAVORITES_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            tab: DesktopTab.chat,
          })
        : generatePath(DESKTOP_TAB_PATHNAME, {
            workspaceId: workspace && getShortId(workspace.id),
            desktopId: desktop && getShortId(desktop.id),
            tab: DesktopTab.chat,
          });
    } else {
      !(isTablet || isMobile) &&
        !redirectConsolidated &&
        setRedirectConsolidated(true);
    }

    if (tabPath) {
      didRedirectDesktopId.current = desktop && getShortId(desktop.id);
      return (
        <Navigate
          to={{
            pathname: tabPath,
            search: getQueryParamsFrom(queryParams),
          }}
        />
      );
    }
  }

  if (
    redirectConsolidated &&
    didRedirectDesktopId.current !== (desktop && getShortId(desktop.id))
  ) {
    didRedirectDesktopId.current = desktop && getShortId(desktop.id);
    const consolidatedPath = isFavoritesDesktop
      ? generatePath(FAVORITES_ROOT_PATHNAME, {
          workspaceId: workspace && getShortId(workspace.id),
        })
      : generatePath(DESKTOP_ID_PATHNAME, {
          workspaceId: workspace && getShortId(workspace.id),
          desktopId: desktop && getShortId(desktop.id),
        });

    return (
      <Navigate
        to={{
          pathname: consolidatedPath,
          search: getQueryParamsFrom(queryParams),
        }}
      />
    );
  }

  if (!currentTab) {
    return null;
  }

  return (
    <>
      <TabBar />
      <StyledLayoutTabbed
        isMobile={isMobile}
        data-testid="desktop-layout-tabbed">
        {currentTab === DesktopTab.apps && <AppsSection />}
        {currentTab === DesktopTab.links && (
          <SectionLinks desktopLayout={DesktopLayout.TABBED} />
        )}
        {currentTab === DesktopTab.chat &&
          !isChatHidden &&
          conversation &&
          canViewDesktopChatSection && <ChatSection />}
        <MembersUniversal />
      </StyledLayoutTabbed>
    </>
  );
};
