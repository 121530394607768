import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '../../../shared/components/Menu';
import { GridIcon } from '../../../shared/icons';
import { ContentLayout } from '../ContentLayout.constants';
import { DesktopTranslation } from '../../Desktop/i18n';

interface GridLayoutOptionProps {
  setLayout: (newLayout: ContentLayout) => void;
  currentLayout: ContentLayout;
}

export const GridLayoutOption: FC<GridLayoutOptionProps> = ({
  setLayout,
  currentLayout,
}) => {
  return (
    <MenuItem
      icon={GridIcon}
      displayCheckMark={currentLayout === ContentLayout.GRID}
      onClick={() => {
        setLayout(ContentLayout.GRID);
      }}>
      <FormattedMessage id={DesktopTranslation.appsLayoutGrid} />
    </MenuItem>
  );
};
