import { NewChatConferenceEvent } from '../ChatMercure.types';
import { ConferenceApiType } from '../../../Conference/Conference.types';
import { GRAPHQL_TYPENAME_CHAT_CONFERENCE } from '../../../Conference/Conference.constants';

export const makeChatConference = (
  chatConferenceEvent: NewChatConferenceEvent,
): ConferenceApiType => ({
  __typename: GRAPHQL_TYPENAME_CHAT_CONFERENCE,
  id: chatConferenceEvent['@iri'],
  _id: chatConferenceEvent._id,
  createdAt: chatConferenceEvent.createdAt,
  lastStartedAt: chatConferenceEvent.lastStartedAt || null,
  statistics: chatConferenceEvent.statistics,
  status: chatConferenceEvent.status,
  desktop: chatConferenceEvent.desktop,
  limit: chatConferenceEvent.limit,
  callType: chatConferenceEvent.callType,
  chatConversationIri: chatConferenceEvent.chatConversationIri,
  jwtToken: chatConferenceEvent.jwtToken || '',
  seenBy: chatConferenceEvent.seenBy,
  createdBy: chatConferenceEvent.createdBy,
});
