import React, { FC, useCallback } from 'react';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { WorkspaceTranslation } from '../../i18n';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useUpdateWorkspaceMutation,
} from '../../Workspace.hooks';
import { WorkspaceFormValues } from '../../Workspace.types';
import { GET_WORKSPACES_BY_IDENTITY } from '../../Workspace.queries';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { WorkspaceForm } from '../../WorkspaceForm/WorkspaceForm';
import { StyledEditWorkspaceSettingsForm } from './EditWorkspaceSettings.styled';

export const EditWorkspaceSettings: FC<{
  onRequestClose: () => void;
}> = ({ onRequestClose }) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const {
    permissions: { canEditWorkspace, canChangeIdentifier },
    refetchPermissions,
  } = useCurrentWorkspacePermissions();

  const [updateWorkspaceMutation] = useUpdateWorkspaceMutation();
  const handleSubmit = useCallback(
    (formValues: WorkspaceFormValues) => {
      const { identifier, image, ...valuesToUpdate } = formValues;
      return updateWorkspaceMutation({
        variables: {
          input: {
            ...valuesToUpdate,
            ...(image ? { image } : {}),
            ...(canChangeIdentifier && identifier
              ? { identifier: identifier }
              : {}),
            id: workspace.id,
          },
        },
        refetchQueries: [
          {
            query: GET_WORKSPACES_BY_IDENTITY,
            variables: {
              id: account!.identityId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(res => {
          if (onRequestClose) {
            onRequestClose();
          }
          showToastSuccessMessage(WorkspaceTranslation.formSuccessMessage);
          refetchPermissions();

          return res;
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      updateWorkspaceMutation,
      canChangeIdentifier,
      workspace.id,
      account,
      onRequestClose,
      refetchPermissions,
    ],
  );

  return (
    <StyledEditWorkspaceSettingsForm>
      <WorkspaceForm
        workspace={workspace}
        disabled={!canEditWorkspace}
        onSubmit={handleSubmit}
        onCancel={onRequestClose}
      />
    </StyledEditWorkspaceSettingsForm>
  );
};
