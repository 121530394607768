import React, { FC } from 'react';
import {
  GlobalTranslation,
  Translation,
} from '../../../domains/Intl/i18n/i18n.types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

interface HeadTitleProps {
  translationId?: Translation;
}

export const HeadTitle: FC<HeadTitleProps> = ({ translationId }) => {
  const intl = useIntl();
  return (
    <Helmet>
      <title>
        {translationId
          ? intl.formatMessage({
              id: translationId,
            }) + ' | '
          : ''}
        {intl.formatMessage({
          id: GlobalTranslation.headTitle,
        })}
      </title>
    </Helmet>
  );
};
