import styled from 'styled-components';
import { KeyboardArrowRightIcon } from '../../../../../../shared/icons';

export const StyledHeader = styled.div`
  background-color: ${p => p.theme.colors.Surface};
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
  margin-bottom: 20px;
  padding: 24px 16px 24px 20px;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 24px 20px;
    min-height: 4.25rem;
  `}
`;

export const HeaderTitle = styled.h2`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1.25rem;
  line-height: 1.22;
  margin-bottom: 12px;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    margin-bottom: 0;
  `}
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: inherit;
  }

  h2 {
    margin-bottom: 0;
  }
`;

export const BreadcrumbsSeparator = styled(KeyboardArrowRightIcon)`
  margin: 0 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;
