import React from 'react';
import type {
  ChatTextMessageInternalType,
  DraftChatMessageApiType,
} from '../../Chat.types';

interface ConversationDraftContextType {
  loading: boolean;
  getDraftMessage: (id?: string) => DraftChatMessageApiType | null | undefined;
  setDraftMessage: (
    id: string,
    chatMessage: Partial<ChatTextMessageInternalType>,
    conversationId: string,
  ) => void;
  resetDraftMessage: (id: string) => void;
}

export const ConversationDraftContext =
  React.createContext<ConversationDraftContextType>({
    loading: true,
    getDraftMessage: () => null,
    setDraftMessage: () => {},
    resetDraftMessage: () => {},
  });
