import React, { FC, useCallback, useMemo } from 'react';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { InviteUsersFormFields } from '../../../User.types';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { SignUpStep } from '../../../../Onboarding/Onboarding.types';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { GoogleInviteUsers } from './GoogleInviteUsers';
import { MicrosoftInviteUsers } from './MicrosoftInviteUsers';
import { ExternalInviteUsersContainer } from './ExternalInviteUsers.styled';
import { useAccountsRepository } from '../../../../Account/data/Account/Account.repositories';
import { useGetAllInvitedAccounts } from '../../../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';
import { getShortId } from '../../../../../shared/utils/id';

export interface ExternalInviteUsersProps {
  users: InviteUsersFormFields[];
  setFormData: (field: string, value: any) => void;
  removeContact: (index: number) => void;
}

export const ExternalInviteUsers: FC<ExternalInviteUsersProps> = ({
  users,
  setFormData,
  removeContact,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();

  const { accounts, loading: loadingAccounts } = useAccountsRepository({
    variables: {
      workspaceId: workspace.id,
    },
  });

  const { data: invited, loading: loadingInvites } = useGetAllInvitedAccounts({
    skip: account?.currentStep === SignUpStep.invite_users,
    variables: {
      type: 'workspace',
      referenceId: getShortId(workspace.id),
    },
    fetchPolicy: 'no-cache',
  });

  const invitedUsers = useMemo(
    () => extractNodes(invited?.workspaceAccountInvitations),
    [invited],
  );

  const handleShowAuthPopup = useCallback(
    (
      e: React.SyntheticEvent,
      contacts: InviteUsersFormFields[],
      visibleHandler: (state: boolean) => void,
      authUrl: string,
    ) => {
      e.preventDefault();

      if (contacts.length > 0) {
        visibleHandler(true);
        return;
      }
      if (!authUrl) {
        return;
      }

      const popupWidth = 500;
      const popupHeight = 650;

      const xPosition = window.screen.width / 2 - popupWidth / 2;
      const yPosition = window.screen.height / 2 - popupHeight / 2;

      window.open(
        authUrl,
        'Authorization',
        `popup, height=${popupHeight}, width=${popupWidth}, left=${xPosition}, top=${yPosition}`,
      );
    },
    [],
  );

  if (loadingInvites || loadingAccounts) {
    return <Spinner />;
  }

  return (
    <ExternalInviteUsersContainer>
      <MicrosoftInviteUsers
        users={users}
        setFormData={setFormData}
        removeContact={removeContact}
        handleShowAuthPopup={handleShowAuthPopup}
        existingAccounts={accounts}
        invitedUsers={invitedUsers}
      />
      <GoogleInviteUsers
        users={users}
        setFormData={setFormData}
        removeContact={removeContact}
        handleShowAuthPopup={handleShowAuthPopup}
        existingAccounts={accounts}
        invitedUsers={invitedUsers}
      />
    </ExternalInviteUsersContainer>
  );
};
