import React, { FC, memo, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonSize } from '../../../../../../shared/components/Button/Button';
import { StyledCloseButton } from '../../../../../../shared/components/Toast/Toast.styled';
import { useMobile } from '../../../../../../shared/hooks';
import { CloseIcon } from '../../../../../../shared/icons';
import { ChatTranslation } from '../../../../i18n';
import {
  ChatMessageThreadSidebarHeaderStyled,
  ChatMessageThreadSidebarHeaderTitleStyled,
} from '../ChatMessageThread.styled';
import { ChatMessageThreadSidebarTitle } from './ChatMessageThreadSibebarTitle';
import { ChatMessageThreadSidebarContext } from './ChatMessageThreadSidebar.context';

export const ChatMessageThreadSidebarHeader: FC = memo(() => {
  const { formatMessage } = useIntl();
  const { closeThreadSideBar, showMessageInConversation } = useContext(
    ChatMessageThreadSidebarContext,
  );
  const isMobile = useMobile();

  return (
    <ChatMessageThreadSidebarHeaderStyled isMobile={isMobile}>
      <ChatMessageThreadSidebarHeaderTitleStyled
        onClick={() => showMessageInConversation()}>
        <FormattedMessage
          id={ChatTranslation.chatMessageSidebarTitle}
          tagName="div"
        />

        <ChatMessageThreadSidebarTitle />
      </ChatMessageThreadSidebarHeaderTitleStyled>
      <StyledCloseButton
        size={ButtonSize.sm}
        icon={CloseIcon}
        onClick={closeThreadSideBar}
        aria-label={formatMessage({
          id: ChatTranslation.chatMessageSidebarLabelClose,
        })}
      />
    </ChatMessageThreadSidebarHeaderStyled>
  );
});
