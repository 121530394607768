import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import CreatableSelect, { InputActionMeta } from 'react-select';
import { useTheme } from 'styled-components';
import _get from 'lodash/get';
import { GlobalTranslation } from '../../../Intl/i18n';
import { ErrorMessage } from '../../../../shared/components/Input';
import { AccountApiType, AccountOrigin } from '../../../User/User.types';
import { ConferenceTranslation } from '../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmail } from '../../../../shared/utils/email.utils';
import SlackIcon from '../../../../shared/assets/images/slack.svg';
import {
  OptionChatAvatar,
  OptionInner,
  StyledAddOption,
  StyledCustomOptionLabel,
  StyledLabel,
  StyledSlackIcon,
} from './SelectMembers.styled';
import { useMobile } from '../../../../shared/hooks';
import { ReactSelectOptionsType } from '../../../../shared/components/ReactSelect/ReactSelect.types';

const SEMICOLON_KEY_CODE = 186;
const COMMA_KEY_CODE = 188;
const HOME_KEY_CODE = 36;
const END_KEY_CODE = 35;
const SPACE_KEY_CODE = 32;
const ENTER_KEY_CODE = 13;
const TAB_KEY_CODE = 9;

interface CreateConversationSelectProps extends ReactSelectOptionsType {
  accounts: Map<string, AccountApiType>;
  label?: string;
  disabled: boolean;
}

const shouldShowOption = (
  option: { emailForSearch: string; label: string },
  rawInput: string,
) => {
  return [option.label, option.emailForSearch].some(opt =>
    opt.toLowerCase().includes(rawInput.toLowerCase()),
  );
};

export const SelectMembers: FC<FieldProps & CreateConversationSelectProps> = ({
  label,
  options,
  field,
  form,
  accounts,
  disabled,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isMobile = useMobile();
  const inputRef = useRef<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [isMenuOpened, setMenuOpened] = useState(false);
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  const CustomOption = (props: any) => {
    const { innerProps, isDisabled, label, value, data } = props;
    const currentAccount = accounts.get(value);
    return !isDisabled ? (
      <OptionInner {...innerProps}>
        {currentAccount && (
          <OptionChatAvatar
            account={currentAccount}
            className="avatar"
            avatarSize="1.5rem"
            onlineStatusSize="0.6rem"
          />
        )}

        <StyledCustomOptionLabel>{label}</StyledCustomOptionLabel>

        {data.origin === AccountOrigin.slack && (
          <StyledSlackIcon src={SlackIcon} alt="" />
        )}
      </OptionInner>
    ) : null;
  };

  const saveValue = useCallback(
    (value: string) => {
      form.setFieldValue(field.name, [
        ...field.value,
        { label: value, value: value, email: value },
      ]);
      setInputValue('');
    },
    [field, form],
  );

  const openMenu = useCallback(() => {
    setMenuOpened(true);
  }, []);

  const handleKeyDown = useCallback(
    (event: any) => {
      const {
        keyCode,
        target: { value },
      } = event;

      switch (keyCode) {
        case ENTER_KEY_CODE:
        case COMMA_KEY_CODE:
        case SEMICOLON_KEY_CODE:
        case TAB_KEY_CODE:
        case SPACE_KEY_CODE:
          event.preventDefault();

          if (isEmail(value)) {
            saveValue(value);
          }
          break;
        case HOME_KEY_CODE:
          event.preventDefault();
          inputRef.current.select.inputRef.selectionStart = 0;
          break;
        case END_KEY_CODE:
          event.preventDefault();
          inputRef.current.select.inputRef.selectionStart = value.length;
          break;
      }
    },
    [saveValue],
  );

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (isMobile) {
      if (actionMeta.action === 'menu-close') {
        return null;
      }
    }

    if (actionMeta.action === 'input-blur') {
      setMenuOpened(false);
    }

    setInputValue(value);
  };

  const handleAddMember = (value: string) => {
    saveValue(value);
  };

  return (
    <div data-testid="react-select">
      <StyledLabel>{label}</StyledLabel>
      <CreatableSelect
        ref={inputRef}
        menuIsOpen={isMenuOpened}
        components={{ Option: CustomOption }}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        onMenuOpen={openMenu}
        isDisabled={disabled}
        options={options}
        name={field.name}
        placeholder={formatMessage({
          id: ConferenceTranslation.scheduleConferenceFormNameLabel,
        })}
        value={field.value ? field.value : ''}
        onChange={option => form.setFieldValue(field.name, option ?? [])}
        filterOption={(option, rawInput) =>
          shouldShowOption(option.data, rawInput)
        }
        //@ts-ignore
        noOptionsMessage={data => {
          const { inputValue } = data;
          if (inputValue && isEmail(inputValue)) {
            return (
              <StyledAddOption
                onClick={() =>
                  handleAddMember(inputValue)
                }>{`${intl.formatMessage({
                id: ConferenceTranslation.scheduledConferenceAdd,
              })} ${inputValue}`}</StyledAddOption>
            );
          } else {
            return intl.formatMessage({
              id: ConferenceTranslation.scheduledUpcomingConferenceMembersNoOptionsSelected,
            });
          }
        }}
        onBlur={field.onBlur}
        isMulti
        styles={{
          input: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          multiValue: styles => ({
            ...styles,
            backgroundColor: theme.colors.Background,
          }),
          multiValueLabel: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            fontWeight: 500,
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
        }}
      />
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </div>
  );
};
