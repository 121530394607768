import styled from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';

export const StyledCustomAppPreview = styled.div`
  padding: 0.75rem 0.25rem 0.25rem;
  text-align: center;
`;

export const CustomAppPreviewHeader = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: ${p => p.theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const CustomAppPreviewDescription = styled.p`
  max-width: 250px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  margin: 0 auto 1rem;
`;

export const CustomAppPreviewBox = styled.div`
  max-width: 150px;
  margin: 0 auto 2.375rem;
  padding: 1.25rem 0.625rem;
  background-color: ${p => p.theme.colors.Background};
  border-radius: 8px;
`;

export const CustomAppPreviewName = styled.p`
  margin-top: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.3;
  font-family: ${p => p.theme.fonts.Semibold};
`;

export const CustomAppPreviewButton = styled(Button)`
  padding: 0.625rem 1.5px;
  margin-top: 1rem;
  &:first-of-type {
    margin: 0;
  }
`;

export const CustomAppPreviewIcon = styled.img`
  width: 74px;
  height: 74px;
  margin-bottom: 0.25rem;
  border-radius: 3px;
  object-fit: cover;
`;
