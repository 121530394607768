import { useMutation } from '@apollo/client';
import {
  CREATE_FILE_ASSET,
  CREATE_IMAGE_ASSET,
  CreateFileAssetResponse,
  CreateFileAssetVariables,
  CreateImageAssetResponse,
  CreateImageAssetVariables,
} from './Asset.mutations';

export const useCreateFileAssetMutation = () =>
  useMutation<CreateFileAssetResponse, CreateFileAssetVariables>(
    CREATE_FILE_ASSET,
  );

export const useCreateImageAssetMutation = () =>
  useMutation<CreateImageAssetResponse, CreateImageAssetVariables>(
    CREATE_IMAGE_ASSET,
  );
