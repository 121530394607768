import React, {
  FC,
  Ref,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Section,
  SectionContent,
  SectionLoading,
  SectionProps,
  SectionTitleBar,
} from '../Section';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  DESKTOP_ROOT_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopTab,
  FAVORITES_TAB_PATHNAME,
} from '../../../../Desktop.constants';
import {
  useCurrentDesktop,
  useCurrentDesktopContent,
  useCurrentDesktopPermissions,
  useDeleteSectionMutation,
  useFilterDesktopAppsQuery,
} from '../../../../Desktop.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../../Workspace/Workspace.hooks';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../../../../shared/components/Menu';
import {
  ClipboardIcon,
  CopyIcon,
  DeleteIcon,
  EyeClosedIcon,
  EyeIcon,
} from '../../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { DesktopTranslation } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { extractNodes } from '../../../../../../shared/api/api.utils';
import { DesktopFilterEmptyState } from '../../../DesktopFilter/DesktopFilterEmptyState';
import {
  ContentLayout,
  ContentLayoutSelector,
  DESKTOP_APPS_LAYOUT_KEY,
  FluidLayoutOption,
  GridLayoutOption,
} from '../../../../../ContentLayout';
import { useElementHasScroll } from '../../../../../../shared/hooks/element.hooks';
import { useCurrentTypeOfPage } from '../../../../Desktop.utils';
import { StyledDeleteItem } from '../Section.styled';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { isCurrentAccountGuest } from '../../../../../User/User.utils';
import { useDebouncedCallback } from 'use-debounce';
import { CopyPasteSectionDataContext } from '../../../../CopyPasteSectionDataProvider';
import { AppsLayout } from '../../../../Views/AppsLayout/AppsLayout';
import { sendAppTrackingEvent } from '../../../../tracking/DesktopTracking.utils';
import { getShortId } from '../../../../../../shared/utils/id';

interface AppSectionProps extends SectionProps {
  isOnlySection?: boolean;
}

const FILTER_APPS_DEBOUNCE_DELAY = 30;

export const AppsSection: FC<AppSectionProps> = ({
  isOnlySection,
  ...props
}) => {
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const desktop = useCurrentDesktop();
  const { desktopLayout, desktopApps } = useCurrentDesktopContent();
  const desktopShortId = getShortId(desktop?.id || '');
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const workspaceShortId = useMemo(() => {
    return getShortId(workspace.id);
  }, [workspace.id]);
  const MenuRef: Ref<HTMLButtonElement> = useRef(null);
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { canAddApp, canRemoveApp, canCopyDesktopAppsSection } =
    useCurrentDesktopPermissions();
  const { formatMessage } = useIntl();
  const [filterString, setFilterString] = useState<string>('');
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const [deleteSectionMutation] = useDeleteSectionMutation();

  const {
    showPasteAppSectionButton,
    handlePasteClipboardDesktopSectionData,
    handleCopyAppsDesktopSection,
  } = useContext(CopyPasteSectionDataContext);

  const tabbedViewUrl = useMemo(() => {
    if (isFavoritesDesktop) {
      return generatePath(FAVORITES_TAB_PATHNAME, {
        workspaceId: workspaceShortId,
        tab: DesktopTab.apps,
      });
    } else if (desktopShortId) {
      return generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: workspaceShortId,
        desktopId: desktopShortId,
        tab: DesktopTab.apps,
      });
    } else {
      return generatePath(DESKTOP_ROOT_PATHNAME, {
        workspaceId: workspaceShortId,
      });
    }
  }, [desktopShortId, workspaceShortId, isFavoritesDesktop]);

  const addAppHandler = useCallback(() => {
    sendAppTrackingEvent('open_app_store_from_section');
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        appStoreModal: true,
      }),
    });
  }, [navigate, queryParams]);

  /**
   * Filter apps
   */

  const { data: filteredAppsData, loading: filterAppsLoading } =
    useFilterDesktopAppsQuery({
      skip: !filterString,
      variables: {
        desktop: desktop?.id as string,
        filterFreeTextSearch: filterString,
      },
      fetchPolicy: 'no-cache',
    });

  const filterAppsHandler = useDebouncedCallback((value: string) => {
    setFilterString(() => value);
  }, FILTER_APPS_DEBOUNCE_DELAY);

  const filteredApps = useMemo(
    () => extractNodes(filteredAppsData?.desktopApps),
    [filteredAppsData?.desktopApps],
  );

  const apps = filterString.length ? filteredApps : desktopApps;

  const filterEmptyResults =
    !filterAppsLoading && !!filterString.length && !filteredApps.length;

  // todo: insert skeleton

  /**
   * Content layout selector
   */

  const [contentLayout, setContentLayout] = useState<ContentLayout>(() => {
    return (
      (localStorage.getItem(DESKTOP_APPS_LAYOUT_KEY) as ContentLayout) ||
      ContentLayout.GRID
    );
  });

  const updateLayout = useCallback((newLayout: ContentLayout): void => {
    setContentLayout(newLayout);
    localStorage.setItem(DESKTOP_APPS_LAYOUT_KEY, newLayout.toString());
  }, []);

  const handleDeleteSection = useCallback(() => {
    if (!desktop?.id) {
      return null;
    }
    askConfirmation(
      intl.formatMessage(
        {
          id: DesktopTranslation.sectionDeleteAppsConfirmationText,
        },
        {
          deleteCount: desktopApps.length,
        },
      ),
      undefined,
      {
        dangerConfirm: true,
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      deleteSectionMutation({
        variables: {
          input: {
            id: desktop.id,
            sections: [DesktopTab.apps],
          },
        },
      });
    });
  }, [
    askConfirmation,
    deleteSectionMutation,
    desktop?.id,
    desktopApps.length,
    intl,
  ]);

  const LayoutSelector = useMemo(
    () => (
      <ContentLayoutSelector currentLayout={contentLayout} iconSize={16}>
        <GridLayoutOption
          setLayout={updateLayout}
          currentLayout={contentLayout}
        />
        <FluidLayoutOption
          setLayout={updateLayout}
          currentLayout={contentLayout}
        />
      </ContentLayoutSelector>
    ),
    [contentLayout, updateLayout],
  );

  /**
   * Show app menu
   */

  const { showIosOptions } = queryParams;
  const handleAppMenuToggle = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        showIosOptions: showIosOptions ? undefined : 'true',
      }),
    });
  }, [navigate, queryParams, showIosOptions]);

  /**
   * Detect if section has scrollbars
   */

  const AppsSectionRef = useRef(null);
  const { hasScroll: sectionHasScroll } = useElementHasScroll(AppsSectionRef);

  const canDeleteShareableDesktop = desktop?.shareable
    ? desktop.createdBy?.id === account.id
    : true;

  return (
    <Section {...props}>
      <SectionTitleBar
        identifier="apps"
        title={formatMessage({ id: DesktopTranslation.headerApps })}
        itemsCount={desktopApps.length ? desktopApps.length : undefined}
        tabPath={tabbedViewUrl}
        menuRef={MenuRef}
        addItemHandler={canAddApp ? addAppHandler : null}
        addItemTooltipText={formatMessage({
          id: DesktopTranslation.headerButtonAddApp,
        })}
        searchInputOnChange={
          !isFavoritesDesktop ? filterAppsHandler : undefined
        }
        searchInputPlaceholderText={formatMessage({
          id: DesktopTranslation.sectionTitlebarFilterAppsPlaceHolder,
        })}
        searchInputTooltipText={formatMessage({
          id: DesktopTranslation.sectionTitlebarFilterAppsTooltip,
        })}
        displayMaximizeButton={!isOnlySection}
        contentLayoutSelectorComponent={LayoutSelector}
      />
      <Menu
        trigger={MenuRef}
        width={230}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.RIGHT}
        data-testid="menu">
        <MenuItem
          icon={showIosOptions ? EyeClosedIcon : EyeIcon}
          onClick={handleAppMenuToggle}>
          <FormattedMessage
            id={
              showIosOptions
                ? DesktopTranslation.hideOptionsButtonLabel
                : DesktopTranslation.showOptionsButtonLabel
            }
          />
        </MenuItem>

        {showPasteAppSectionButton && (
          <>
            <MenuItem
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: DesktopTranslation.pasteDesktopAppsSectionTooltip,
              })}
              data-tooltip-place={TooltipPlace.left}
              icon={ClipboardIcon}
              onClick={() =>
                handlePasteClipboardDesktopSectionData(DesktopTab.apps)
              }>
              <FormattedMessage
                id={DesktopTranslation.pasteDesktopAppsDataMenuItem}
              />
            </MenuItem>
          </>
        )}

        {canCopyDesktopAppsSection && (
          <>
            <MenuItem
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: DesktopTranslation.copyDesktopAppsSectionTooltip,
              })}
              data-tooltip-place={TooltipPlace.left}
              icon={CopyIcon}
              onClick={handleCopyAppsDesktopSection}>
              <FormattedMessage
                id={DesktopTranslation.copyDesktopAppsSectionMenuItem}
              />
            </MenuItem>
          </>
        )}

        {!isCurrentAccountGuest(account, getShortId(workspace.id)) &&
          canDeleteShareableDesktop &&
          canRemoveApp && (
            <>
              <MenuSeparator />
              <StyledDeleteItem icon={DeleteIcon} onClick={handleDeleteSection}>
                <FormattedMessage
                  id={DesktopTranslation.sectionDeleteMenuItem}
                />
              </StyledDeleteItem>
            </>
          )}
      </Menu>
      <SectionContent sectionHasScroll={sectionHasScroll} ref={AppsSectionRef}>
        {!filterAppsLoading && !!apps.length && (
          <AppsLayout
            apps={apps}
            layout={
              desktopLayout === DesktopLayout.TABBED
                ? contentLayout
                : ContentLayout.GRID
            }
          />
        )}

        {filterEmptyResults && (
          <DesktopFilterEmptyState
            showIllustration={desktopLayout === DesktopLayout.TABBED}
          />
        )}

        {filterAppsLoading && <SectionLoading />}
      </SectionContent>
    </Section>
  );
};
