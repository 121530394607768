import { gql } from '@apollo/client';
import { WebPushSubscriptionInputType } from './WebPush.types';

export const UPDATE_WEB_PUSH_ACCOUNT_IDENTITY = gql`
  mutation UpdateWebPushAccountIdentity(
    $input: updateWebPushAccountIdentityInput!
  ) {
    updateWebPushAccountIdentity(input: $input) {
      clientMutationId
    }
  }
`;

export interface UpdateWebPushAccountIdentityVariables {
  input: WebPushSubscriptionInputType;
}

export interface UpdateWebPushAccountIdentityResponse {
  clientMutationId: string;
}
