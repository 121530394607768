import styled from 'styled-components';
import { DocumentType } from '..';

export const StyledDocumentDynamicIcon = styled.div<{
  type: DocumentType | null;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};

  ${({ theme, type }) => {
    switch (type) {
      case DocumentType.PDF:
        return `background-color: ${theme.colors.CriticalLighter};`;
      case DocumentType.MS_EXCEL:
      case DocumentType.GOOGLE_SPREADSHEETS:
        return `background-color: ${theme.colors.SuccessLighter};`;
      case DocumentType.MS_WORD:
      case DocumentType.GOOGLE_DOCUMENT:
        return `background-color: ${theme.colors.PrimaryLightest};`;
      case DocumentType.MS_POWERPOINT:
      case DocumentType.GOOGLE_PRESENTATION:
        return `background-color: ${theme.colors.WarningLight};`;
      case DocumentType.GOOGLE_DRIVE:
      case DocumentType.COMMON:
      default:
        return `background-color: ${theme.colors.Background};`;
    }
  }}
`;
