import styled from 'styled-components';
import { SEGMENT_NAVIGATOR_WIDTH } from '../Segment/SegmentNavigator/SegmentNavigator.styled';
import { SEGMENT_SWITCHER_WIDTH } from '../Segment/SegmentSwitcher/SegmentSwitcher.styled';

const TOP_POSITION = 35;

export const PreviewSegmentContainer = styled.div`
  border-radius: 16px;
  position: fixed;
  top: ${TOP_POSITION}px;
  padding-top: 0.3125rem;
  left: ${SEGMENT_SWITCHER_WIDTH - 1}px;
  width: ${SEGMENT_NAVIGATOR_WIDTH}px;
  min-width: ${SEGMENT_NAVIGATOR_WIDTH}px;
  height: 95%;
  z-index: ${({ theme }) => theme.zIndexLevels.Confirm};
  background: ${({ theme }) => theme.colors.Surface};
  display: flex;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.15);

  border: 1px solid ${({ theme }) => theme.colors.PreviewSegmentBorderColor};

  .join-now-button,
  .unlock-password-manager {
    background-color: ${({ theme }) => theme.colors.Primary} !important;
    color: ${({ theme }) => theme.colors.PrimaryTextLight} !important;

    &:hover {
      background-color: ${({ theme }) => theme.colors.PrimaryDark} !important;
    }
  }

  .segment-navigator,
  .conference-list {
    background-color: transparent !important;
  }
`;

export const PreviewSegmentArrowContainer = styled.div<{
  top: number;
}>`
  position: absolute;
  top: ${p => `${p.top}px`};
  left: -11px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.PreviewSegmentArrowColor};
    }
  }
`;
