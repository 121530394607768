import React, { FC, useCallback, useMemo } from 'react';
import {
  ScheduledConferenceIcalFile,
  ScheduledConferenceLinkHeader,
  ScheduledConferenceLinkWrapper,
  ScheduledConferenceMeetingLink,
} from './ScheduledConferenceLink.styled';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import {
  useCurrentConference,
  useScheduledConferenceMembers,
} from '../../../Conference.hooks';
import { getConferencePageLink } from '../../../Conference.utils';
import {
  ConferenceViewHeaderSection,
  ScheduledConferenceContentInner,
} from '../../ConferenceView/ConferenceView.styled';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { CopyIcon } from '../../../../../shared/icons';
import { showToastSuccessMessage } from '../../../../../shared/components/Toast';
import { useMobile } from '../../../../../shared/hooks';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';

export const ScheduledConferenceLink: FC = () => {
  const { scheduledConference } = useCurrentConference();
  const { account } = useCurrentWorkspaceAccount();
  const members = useScheduledConferenceMembers(true);
  const isMobile = useMobile();
  const icalendarFile = useMemo(
    () =>
      members.find(member => member.account?.id === account?.id)
        ?.icalendarUrl || '',
    [account, members],
  );

  const conferenceLink = useMemo(
    () =>
      window.location.origin +
      getConferencePageLink(scheduledConference?.chatConference?.id || ''),
    [scheduledConference],
  );

  const handleCopyLinkClick = useCallback(() => {
    navigator.clipboard.writeText(conferenceLink).then(() => {
      showToastSuccessMessage(
        ConferenceTranslation.scheduledUpcomingConferenceCopyLinkToastText,
      );
    });
  }, [conferenceLink]);

  return (
    <ScheduledConferenceLinkWrapper>
      <ScheduledConferenceContentInner>
        <ConferenceViewHeaderSection>
          <ScheduledConferenceLinkHeader>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferenceMeetingLink}
            />
          </ScheduledConferenceLinkHeader>
        </ConferenceViewHeaderSection>

        <div className="conference-link-body">
          <ScheduledConferenceMeetingLink isMobile={isMobile}>
            <a href={conferenceLink} rel="noopener noreferrer">
              {conferenceLink}
            </a>
            <ButtonWithIcon
              icon={() => <CopyIcon height={20} />}
              onClick={handleCopyLinkClick}
            />
          </ScheduledConferenceMeetingLink>
          {scheduledConference && icalendarFile && (
            <ScheduledConferenceIcalFile
              href={icalendarFile}
              download={scheduledConference.title}
              rel="noopener noreferrer">
              <FormattedMessage
                id={ConferenceTranslation.scheduledUpcomingConferenceIcalFile}
              />
            </ScheduledConferenceIcalFile>
          )}
        </div>
      </ScheduledConferenceContentInner>
    </ScheduledConferenceLinkWrapper>
  );
};
