import React, { FC, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageForm } from '../MessageForm';
import type { ChatTextMessageInternalType } from '../../../Chat.types';
import { StyledEditMessageForm } from './EditMessageForm.styled';
import { MessageFormType } from '../MessageForm/MessageForm.types';
import { ChatTranslation } from '../../../i18n';
import { ChatMessageAssetApiType } from '../../../Chat.types';
import { ConversationViewContext } from '../ConversationView.context';
import { useEffectOnce } from 'react-use';
import { ConversationDraftContext } from '../../ConversationDraft/ConversationDraft.context';
import { useCurrentConversation } from '../../../Chat.hooks';

const SCROLL_INTO_VIEW_INTERVAL = 100;

export interface EditMessageFormProps {
  onSave: (value: string, assets: ChatMessageAssetApiType[]) => Promise<void>;
  onCancel: () => void;
  chatMessage: ChatTextMessageInternalType;
  className?: string;
}

export const EditMessageForm: FC<EditMessageFormProps> = ({
  onSave,
  onCancel,
  chatMessage,
  className,
}) => {
  const { setDraftMessage } = useContext(ConversationDraftContext);
  const { messages } = useContext(ConversationViewContext);
  const { conversation } = useCurrentConversation();

  const isLastMessage = useMemo(
    () => messages.at(-1)?.id === chatMessage.id,
    [chatMessage.id, messages],
  );

  const editoFormRef = React.useRef<HTMLInputElement>(null);

  useEffectOnce(() => {
    if (isLastMessage) {
      setTimeout(() => {
        editoFormRef.current?.scrollIntoView();
      }, SCROLL_INTO_VIEW_INTERVAL);
    }

    setDraftMessage(chatMessage.id, chatMessage, chatMessage.conversationId);
  });

  const handleSubmit = (
    value: string,
    parentChatMessageId: string | null = null,
    assets: ChatMessageAssetApiType[] = [],
  ) => {
    return onSave(value, assets);
  };

  if (!conversation) {
    return null;
  }

  return (
    <StyledEditMessageForm className={className} ref={editoFormRef}>
      <MessageForm
        key={chatMessage.id}
        formId={chatMessage.id}
        conversation={conversation}
        onSubmit={handleSubmit}
        canSaveDraft={false}
        draftLoaded={true}
        onCancel={onCancel}
        formType={MessageFormType.EditMessage}
        buttonsLables={{
          cancelLabel: (
            <FormattedMessage id={ChatTranslation.newMessageFormCancelButton} />
          ),
          submitLabel: (
            <FormattedMessage id={ChatTranslation.newMessageFormSaveButton} />
          ),
        }}
      />
    </StyledEditMessageForm>
  );
};
