import { useLongShadow } from './VideoPlayer.controls.hooks';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const VideoControlsStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.875rem 0.75rem 0.25rem 0.75rem;
  border-radius: 0px 0px 4px 4px;
  z-index: 2;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.13, rgba(2, 17, 33, 0.67)),
    color-stop(1, rgba(2, 17, 33, 0.87))
  );
`;

export const LeftSideControllersStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSideControllersStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const VideoControlsInnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlayPauseStyled = styled(ButtonWithIcon)`
  &::before {
    content: attr(data-title);
    position: absolute;
    display: none;
    left: 0;
    top: -25px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-weight: 400;
    padding: 4px 6px;
    word-break: keep-all;
    white-space: pre;
    font-size: 0.625rem;
  }

  &:hover::before {
    display: inline-block;
  }
`;

export const MuteButtonStyled = styled(ButtonWithIcon)`
  margin-right: 1.5rem;
`;

export const FullScreenStyled = styled(ButtonWithIcon)``;

export const SeekBarContainerStyled = styled.div<{ width: number }>`
  max-width: ${p => `${p.width}px`}
  display: flex;
  width: 100%;
  margin-bottom: 0.625rem;
  padding: 0 0.375rem;
  position: relative;
  height: 0.25rem;
`;

const height = '0.25rem';
const thumbHeight = 12;
const trackHeight = '0.25rem';
const upperColor = 'rgb(0 0 0 / 0.6)';
const lowerColor = '#fff';
const thumbColor = '#fff';
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const thumbHoverColor = '#ccc';

export const SeekBarStyled = styled.input<{ width: number }>`
  display: none;
  appearance: none;
  max-width: ${p => `${p.width}px`};
  width: 100%;
  margin: 0;
  height: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: auto;
    height: 0.75rem;
    width: 0.75rem;
    background: #fff;
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${p => useLongShadow(p.width, p.theme.colors.BorderDefault)};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;

export const VolumeSeekBarContainerStyled = styled.div`
  height: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: 4rem;
`;

export const SeekTooltipStyled = styled.div`
  display: none;
  position: absolute;
  top: -50px;
  margin-left: -20px;
  font-size: 0.75rem;
  padding: 3px;
  content: attr(data-title);
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PlayTimerContainerStyled = styled.div`
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${p => p.theme.colors.PrimaryTextLight};
  line-height: 1rem;
  margin-left: 1.625rem;
`;

export const PlayTimerSeparatorStyled = styled.span`
  margin: 0 0.25rem;
`;

export const TimeBlockStyled = styled.span`
  min-width: 1.588rem;
  text-align: center;
`;
