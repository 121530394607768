import styled from 'styled-components';

export const StyledEmbedPreviewContainer = styled.div<{
  isLink?: boolean;
}>`
  display: flex;
  width: 100%;
  height: ${p => (p.isLink ? '100%' : '50vh')};
  position: relative;
`;

export const StyledEmbedPreviewError = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledEmbedPreview = styled.embed`
  border: 0;
`;

export const StyledEmbedPreviewErrorInfo = styled.div`
  ${({ theme }) => theme.fontTemplates.regular};
  margin-bottom: 1.25rem;
`;

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.Surface};
`;
