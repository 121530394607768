import React, { FC, useCallback, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useAllWorkspaceAccountsSorted } from '../../../Chat/Chat.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getListMap } from '../../../../shared/utils/list.utils';
import { DowngradeMembersSelect } from './DowngradeMembersSelect';
import { getAccountName, isAccountGuest } from '../../../User/User.utils';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { BillingTranslation } from '../../i18n';
import { useDowngradeWorkspaceMutation } from '../../Billing.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { FieldSetControls } from '../../../../shared/components/FieldSet';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { PaymentPlan } from '../../Billing.types';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { AccountOrigin } from '../../../User/User.types';
import { StyledListInfo, StyledListInfoWrapper } from './Downgrade.styled';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { appEnv } from '../../../../appEnv';

interface DowngradeMembersFormProps {
  onClose: () => void;
}

interface DowngradeFormValues {
  value: string;
  label: string;
}

const LIMIT_USERS_FOR_DOWNGRADE = 1;

export const DowngradeForm: FC<DowngradeMembersFormProps> = ({ onClose }) => {
  const [downgradeWorkspace] = useDowngradeWorkspaceMutation();
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const [isDowngrading, setIsDowngrading] = useState<boolean>(false);
  const accounts = useAllWorkspaceAccountsSorted();
  const initialValues: { members: DowngradeFormValues[] } = { members: [] };
  const downgradeFormSchema = Yup.object().shape({
    members: Yup.array().max(
      LIMIT_USERS_FOR_DOWNGRADE,
      BillingTranslation.downgradeModalErrorText,
    ),
  });

  const accountsMap = useMemo(() => {
    return getListMap(accounts);
  }, [accounts]);

  const selectMembersOptions = useMemo(
    () =>
      accounts
        .filter(account => account.origin !== AccountOrigin.bot)
        .map(account => ({
          value: account.id,
          label: getAccountName(account),
          active: !isAccountGuest(account),
        }))
        .filter(account => account?.active),
    [accounts],
  );

  const onSubmit = useCallback(
    (values: { members: DowngradeFormValues[] }) => {
      setIsDowngrading(true);
      downgradeWorkspace({
        variables: {
          input: {
            id: workspace.id,
            newType: PaymentPlan.FREE,
            accounts: values.members.map(
              (member: { value: string; label: string }) => member.value,
            ),
          },
        },
      })
        .then(() => {
          refetchWorkspaces({
            id: account!.identityId,
          });
          showToastSuccessMessage(
            BillingTranslation.adminBillingCancelSubscriptionSuccessMessage,
          );
          onClose();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        })
        .finally(() => setIsDowngrading(false));
    },
    [account, downgradeWorkspace, onClose, refetchWorkspaces, workspace],
  );

  return (
    <>
      <Formik
        validationSchema={downgradeFormSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}>
        <Form>
          <Field
            name="members"
            component={DowngradeMembersSelect}
            options={selectMembersOptions}
            accounts={accountsMap}
          />
          <StyledListInfoWrapper>
            <FormattedMessage
              id={BillingTranslation.downgradeModalTextBullet3}
            />
            <StyledListInfo>
              <li>
                <FormattedMessage
                  id={BillingTranslation.downgradeModalTextBullet4}
                />
              </li>
              <li>
                <FormattedMessage
                  id={BillingTranslation.downgradeModalTextBullet5}
                />
              </li>
              <li>
                <FormattedMessage
                  id={BillingTranslation.downgradeModalTextBullet6}
                />
              </li>
              <li>
                <FormattedMessage
                  id={BillingTranslation.downgradeModalTextBullet7}
                />
              </li>
              <li>
                <FormattedHTMLMessage
                  id={BillingTranslation.downgradeModalTextBullet8}
                  values={{
                    learMoreLink: appEnv.PRICING_LEARN_MORE_URL,
                  }}
                />
              </li>
            </StyledListInfo>
          </StyledListInfoWrapper>
          <ImportantMessage
            data-testid="important-msg"
            type={ImportantMessageType.WARNING}
            withIcon>
            <FormattedHTMLMessage
              id={BillingTranslation.downgradeModalWarningText}
              values={{
                date: workspace.nextRenewAt
                  ? format(new Date(workspace.nextRenewAt), 'dd.MM.yyyy')
                  : '',
              }}
            />
          </ImportantMessage>
          <FieldSetControls>
            <Button
              type="button"
              mode={ButtonMode.secondary}
              onClick={onClose}
              size={ButtonSize.md}
              data-testid="cancel">
              <FormattedMessage id={BillingTranslation.formEditCancelButton} />
            </Button>
            <Button
              type="submit"
              mode={ButtonMode.danger}
              size={ButtonSize.md}
              data-testid="submit">
              <FormattedMessage id={BillingTranslation.downgradeModalSubmit} />
            </Button>
          </FieldSetControls>
        </Form>
      </Formik>
      {isDowngrading && <SpinnerFullscreen />}
    </>
  );
};
