import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledControls } from './VerifyPasswordForm.styled';
import { FormInput } from '../../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../../i18n';
import { VerifyEmailChangeFormValues } from '../../../Onboarding.types';

const ChangeUserPasswordFormSchema: Yup.Schema<VerifyEmailChangeFormValues> = Yup.object().shape(
  {
    currentPassword: Yup.string().required(
      OnboardingTranslation.verifyEmailChangePasswordInputRequired,
    ),
  },
);

export interface VerifyPasswordFormProps {
  onSubmit: (formValues: VerifyEmailChangeFormValues) => void;
}

export const VerifyPasswordForm: FC<VerifyPasswordFormProps> = ({
  onSubmit,
}) => {
  const intl = useIntl();

  const initialValues: VerifyEmailChangeFormValues = {
    currentPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeUserPasswordFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Field
            className="fieldBox"
            data-testid="current-password"
            name="currentPassword"
            type="password"
            label={intl.formatMessage({
              id: OnboardingTranslation.verifyEmailChangePasswordInputLabel,
            })}
            component={FormInput}
          />

          <StyledControls>
            <Button
              disabled={isSubmitting}
              mode={ButtonMode.primary}
              data-testid="submit-button"
              size={ButtonSize.md}
              type="submit"
              fullWidth>
              <FormattedMessage
                id={OnboardingTranslation.verifyEmailChangeVerifyButton}
              />
            </Button>
          </StyledControls>
        </Form>
      )}
    </Formik>
  );
};
