import { addSeconds, format } from 'date-fns';
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  SeekBarContainerStyled,
  SeekBarStyled,
  SeekTooltipStyled,
} from './VideoPlayer.controls.styled';

type VideoPlayerProgressProps = {
  player: HTMLVideoElement;
  playerDurationRef: RefObject<HTMLDivElement>;
};

export interface PlayerProgressProps {
  updateProgress: () => void;
}

export const VideoPlayerProgress = forwardRef<
  PlayerProgressProps,
  VideoPlayerProgressProps
>(({ player, playerDurationRef }, ref) => {
  const seekInputRef = useRef<HTMLInputElement>(null);
  const seekTooltipRef = useRef<HTMLDivElement>(null);
  const seekBarContainerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    updateProgress,
  }));

  const formatTime = useCallback((timeInSeconds: number) => {
    const result = addSeconds(0, timeInSeconds);

    return format(result, 'm:ss');
  }, []);

  const initializeProgress = useCallback(() => {
    if (!playerDurationRef.current) {
      return;
    }

    const videoDuration = Math.round(player.duration);
    const time = formatTime(videoDuration);

    seekInputRef.current?.setAttribute('max', `${videoDuration}`);

    playerDurationRef.current.innerText = time;
    playerDurationRef.current.setAttribute('datetime', time);
  }, [formatTime, player.duration, playerDurationRef]);

  const updateProgress = useCallback(() => {
    const currenTime = player.currentTime;

    if (!seekInputRef.current) {
      return;
    }

    seekInputRef.current.value = `${Math.round(currenTime)}`;
  }, [player.currentTime]);

  const updateSeekTooltip = useCallback(
    (event: MouseEvent) => {
      const seekInput = seekInputRef.current;
      const seekTooltip = seekTooltipRef.current;

      if (!seekInput || !seekTooltip) {
        return;
      }

      const skipTo = Math.round(
        //@ts-ignore
        //TODO remove ts-ignore
        (event.offsetX / event.target.clientWidth) *
          //@ts-ignore
          //TODO remove ts-ignore
          parseInt(event.target!.getAttribute('max'), 10),
      );

      seekInput.setAttribute('data-seek', `${skipTo}`);
      const time = formatTime(skipTo);
      seekTooltip.textContent = time;
      const rect = player.getBoundingClientRect();

      seekTooltip.style.display = 'block';
      seekTooltip.style.left = `${event.pageX - rect.left}px`;
    },
    [formatTime, player],
  );

  const hideSeekTooltip = useCallback(() => {
    const seekTooltip = seekTooltipRef.current;

    if (!seekTooltip) {
      return;
    }

    seekTooltip.style.display = 'none';
  }, []);

  const initListeners = useCallback(() => {
    seekInputRef.current?.addEventListener('mousemove', updateSeekTooltip);
    seekInputRef.current?.addEventListener('mouseleave', hideSeekTooltip);
  }, [hideSeekTooltip, updateSeekTooltip]);

  const removeListeners = useCallback(() => {
    seekInputRef.current?.addEventListener('mousemove', updateSeekTooltip);
    seekInputRef.current?.removeEventListener('mouseleave', hideSeekTooltip);
  }, [hideSeekTooltip, updateSeekTooltip]);

  const skipAhead = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const seekInput = seekInputRef.current;
      const target = event.target as EventTarget & {
        dataset: any;
        value: any;
      };
      if (!seekInput) {
        return;
      }

      const skipTo = target.dataset.seek ? target.dataset.seek : target.value;

      player.currentTime = skipTo;
      seekInput.value = skipTo;
    },
    [player],
  );

  useEffect(() => {
    initListeners();
    initializeProgress();

    return () => {
      removeListeners();
    };
  }, [initListeners, initializeProgress, removeListeners]);

  return (
    <SeekBarContainerStyled
      width={player.offsetWidth}
      ref={seekBarContainerRef}>
      <SeekBarStyled
        width={player.offsetWidth}
        ref={seekInputRef}
        onClick={skipAhead}
        defaultValue="0"
        type="range"
      />
      <SeekTooltipStyled className="seek-tooltip" ref={seekTooltipRef}>
        00:00
      </SeekTooltipStyled>
    </SeekBarContainerStyled>
  );
});
