import React, { FC, memo } from 'react';
import { StyledCountBadge } from './CountBadge.styled';

export enum PositionType {
  relative = 'relative',
  absolute = 'absolute',
}

interface CountBadgeProps {
  offsetTop?: number;
  offsetRight?: number;
  count: number;
  limitCount?: boolean;
  color?: string;
  positionMode?: PositionType;
  className?: string;
  borderColor?: string;
}

const STOP_COUNT_VALUE = 9;

export const CountBadge: FC<CountBadgeProps> = memo(
  ({
    offsetRight = 0,
    offsetTop = 0,
    count = 0,
    limitCount = true,
    color,
    positionMode = PositionType.absolute,
    className,
    borderColor,
  }) => {
    if (count < 1) {
      return null;
    }

    return (
      <StyledCountBadge
        className={className}
        top={offsetTop}
        right={offsetRight}
        color={color}
        borderColor={borderColor}
        positionMode={positionMode}
        data-testid="count-badge">
        {limitCount && count > STOP_COUNT_VALUE
          ? `${STOP_COUNT_VALUE}+`
          : count}
      </StyledCountBadge>
    );
  },
);
