import {
  GetAppStoreAppsResponse,
  GetCustomAppStoreAppsResponse,
} from './AppStore.queries';
import { TabId } from './AppStore.constants';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { getEmailDomain, isEmail } from '../../shared/utils/email.utils';
import {
  GoogleAppOpenType,
  GOOGLE_APPS_OPEN_TYPES,
} from '../App/App.constants';

export const deleteAppStoreAppFromCache = (
  appStoreAppsCache: GetAppStoreAppsResponse,
  appId: string,
) => ({
  ...appStoreAppsCache,
  apps: {
    ...appStoreAppsCache.apps,
    edges: appStoreAppsCache.apps.edges.filter(({ node }) => node.id !== appId),
  },
});

export const deleteCustomAppStoreAppFromCache = (
  appStoreAppsCache: GetCustomAppStoreAppsResponse,
  appId: string,
) => ({
  ...appStoreAppsCache,
  listCustomWorkspaceApps: {
    ...appStoreAppsCache.listCustomWorkspaceApps,
    edges: appStoreAppsCache.listCustomWorkspaceApps.edges.filter(
      ({ node }) => node.id !== appId,
    ),
  },
});

export const getAppStoreQueryCustomVariableValue = (
  tabId: TabId | undefined,
): boolean | null => {
  switch (tabId) {
    case TabId.customApps:
      return true;
    case TabId.personal:
    case TabId.company:
    case TabId.passwords:
      return null;
    default:
      return false;
  }
};

export const getAppStoreQueryWorkspaceVariableValue = (
  tabId: TabId | undefined,
  currentWorkspace: WorkspaceApiType,
) => {
  return (
    ((tabId === TabId.company ||
      tabId === TabId.personal ||
      tabId === TabId.customApps) &&
      currentWorkspace.id) ||
    null
  );
};

export const getAppIri = (shortId: string) => {
  return '/apps/' + shortId;
};

const GOOGLE_EMAIL_DOMAIN = 'gmail.com';
export const getGoogleMultipleAccountsOpenUri = (
  openUri: string,
  login: string | null,
): string | null => {
  if (login && isEmail(login)) {
    const loginEmailDomain = getEmailDomain(login);

    const openType: GoogleAppOpenType =
      loginEmailDomain === GOOGLE_EMAIL_DOMAIN
        ? GoogleAppOpenType.AuthUser
        : GOOGLE_APPS_OPEN_TYPES[openUri] ?? GoogleAppOpenType.None;

    if (openType === GoogleAppOpenType.AuthUser) {
      return `${openUri}?authuser=${login}`;
    } else if (openType === GoogleAppOpenType.Domain) {
      return `${openUri}a/${loginEmailDomain}`;
    }
  }
  return null;
};
