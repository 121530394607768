import React, { FC } from 'react';
import { StyledSectionTitle } from './SectionTitle.styled';

interface SectionTitleProps {
  title: string;
  subtitle?: string;
}

export const SectionTitle: FC<SectionTitleProps> = ({ title, subtitle }) => {
  return (
    <StyledSectionTitle>
      <span className="title">{title}</span>
      {subtitle && <span className="subtitle">{subtitle}</span>}
    </StyledSectionTitle>
  );
};
