import React, { FC, useMemo } from 'react';
import {
  DrawerSettingsMenuItem,
  DrawerSettingsMenuLinkText,
  DrawerSettingsMenuList,
  DrawerSettingsMenuNavLink,
  DrawerSettingsSeparator,
  StyledDrawerSettingsMenu,
  WorkspaceSelectContainer,
} from './WorkspaceAdminMenu.styled';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace.hooks';
import { WorkspaceTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  BellIcon,
  CompanyIcon,
  CreditcardIcon,
  CustomAppsIcon,
  DesktopIcon,
  KeyboardArrowRightIcon,
  KeyIcon,
  LogoutIcon,
  PreferencesIcon,
  TeamIcon,
  UserIcon,
} from '../../../../shared/icons';
import { GlobalTranslation } from '../../../Intl/i18n';
import { AppStoreTranslation } from '../../../AppStore/i18n';
import { WorkspaceSelect } from '../../WorkspaceAdmin/WorkspaceSelect';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { WebIcon } from '../../../../shared/icons/Web.icon';
import { VerifyFeatureAccess } from '../../../Billing/VerifyFeatureAccess';
import { BillingTranslation } from '../../../Billing/i18n';
import { AppIntegrationIcon } from '../../../../shared/icons/AppIntegration.icon';
import { useNativeWrapper } from '../../../NativeWrapper';
import { getShortId } from '../../../../shared/utils/id';
import { LOGOUT_PATHNAME } from '../../../Auth/Auth.constants';

const ICON_SIZE = 24;

export const WorkspaceAdminMenu: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { isNativeWrapperAvailable } = useNativeWrapper();
  const {
    permissions: {
      canMenuBillingPage,
      canViewBillingPage,
      canViewProfilePage,
      canMenuUsersPage,
      canViewUsersPage,
      canMenuGroupsPage,
      canViewGroupsPage,
      canMenuDesktopsPage,
      canViewDesktopsPage,
      canMenuCustomAppsPage,
      canViewCustomAppsPage,
      canMenuSsoPage,
      canViewSsoPage,
      canMenuDomainPage,
      canViewDomainPage,
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
      canMenuAppIntegrationsPage,
    },
  } = useCurrentWorkspacePermissions();
  const workspaceShortId = useMemo(() => {
    return getShortId(workspace.id);
  }, [workspace.id]);

  return (
    <StyledDrawerSettingsMenu>
      <DrawerSettingsMenuList>
        <DrawerSettingsSeparator />

        <DrawerSettingsMenuItem>
          <DrawerSettingsMenuNavLink
            to={`/workspace/${workspaceShortId}/admin/settings`}>
            <PreferencesIcon width={ICON_SIZE} height={ICON_SIZE} />
            <DrawerSettingsMenuLinkText>
              <FormattedMessage
                id={WorkspaceTranslation.adminNavUserSettingsLink}
              />
            </DrawerSettingsMenuLinkText>
            <KeyboardArrowRightIcon />
          </DrawerSettingsMenuNavLink>
        </DrawerSettingsMenuItem>
        <DrawerSettingsMenuItem>
          <DrawerSettingsMenuNavLink
            to={`/workspace/${workspaceShortId}/admin/notifications`}>
            <BellIcon width={ICON_SIZE} height={ICON_SIZE} />
            <DrawerSettingsMenuLinkText>
              <FormattedMessage
                id={WorkspaceTranslation.adminNavNotificationSettingsLink}
              />
            </DrawerSettingsMenuLinkText>
            <KeyboardArrowRightIcon />
          </DrawerSettingsMenuNavLink>
        </DrawerSettingsMenuItem>

        <DrawerSettingsSeparator />

        <WorkspaceSelectContainer>
          <WorkspaceSelect
            onSelect={workspace =>
              navigate({
                pathname: `/workspace/${workspaceShortId}`,
                search: getQueryParamsFrom({
                  ...queryParams,
                  settingsModal: true,
                }),
              })
            }
          />
        </WorkspaceSelectContainer>

        <DrawerSettingsSeparator />

        {/* PROFILE */}
        <DrawerSettingsMenuItem>
          <DrawerSettingsMenuNavLink
            to={`/workspace/${workspaceShortId}/admin/profile`}>
            <UserIcon width={ICON_SIZE} height={ICON_SIZE} />
            <DrawerSettingsMenuLinkText>
              <FormattedMessage
                id={WorkspaceTranslation.adminNavUserProfileLink}
              />
            </DrawerSettingsMenuLinkText>
            <KeyboardArrowRightIcon />
          </DrawerSettingsMenuNavLink>
        </DrawerSettingsMenuItem>

        {/* NOTIFICATIONS */}
        <DrawerSettingsMenuItem>
          <DrawerSettingsMenuNavLink
            to={`/workspace/${workspaceShortId}/admin/workspace-notifications`}>
            <BellIcon width={ICON_SIZE} height={ICON_SIZE} />
            <DrawerSettingsMenuLinkText>
              <FormattedMessage
                id={WorkspaceTranslation.adminNavUserNotificationsLink}
              />
            </DrawerSettingsMenuLinkText>
            <KeyboardArrowRightIcon />
          </DrawerSettingsMenuNavLink>
        </DrawerSettingsMenuItem>

        {/* DOMAIN */}
        <VerifyFeatureAccess
          canDisplay={canMenuDomainPage}
          canAccess={canViewDomainPage}
          restrictionMessage={BillingTranslation.generalUpgradeMessage}>
          <DrawerSettingsMenuItem>
            <DrawerSettingsMenuNavLink
              className="verify-trigger"
              to={`/workspace/${workspaceShortId}/admin/domains`}>
              <WebIcon width={ICON_SIZE} height={ICON_SIZE} />
              <DrawerSettingsMenuLinkText>
                <FormattedMessage
                  id={WorkspaceTranslation.adminNavDomainsLink}
                />
              </DrawerSettingsMenuLinkText>
              <KeyboardArrowRightIcon />
            </DrawerSettingsMenuNavLink>
          </DrawerSettingsMenuItem>
        </VerifyFeatureAccess>

        {canViewProfilePage && (
          <DrawerSettingsMenuItem>
            <DrawerSettingsMenuNavLink
              to={`/workspace/${workspaceShortId}/admin/company`}>
              <CompanyIcon width={ICON_SIZE} height={ICON_SIZE} />
              <DrawerSettingsMenuLinkText>
                <FormattedMessage
                  id={WorkspaceTranslation.adminNavCompanyProfileLink}
                />
              </DrawerSettingsMenuLinkText>
              <KeyboardArrowRightIcon />
            </DrawerSettingsMenuNavLink>
          </DrawerSettingsMenuItem>
        )}

        {/* USERS */}
        <VerifyFeatureAccess
          canDisplay={canMenuUsersPage}
          canAccess={canViewUsersPage}
          restrictionMessage={BillingTranslation.generalUpgradeMessage}>
          <DrawerSettingsMenuItem>
            <DrawerSettingsMenuNavLink
              className="verify-trigger"
              to={`/workspace/${workspaceShortId}/admin/users`}>
              <UserIcon width={ICON_SIZE} height={ICON_SIZE} />
              <DrawerSettingsMenuLinkText>
                <FormattedMessage id={WorkspaceTranslation.adminNavUsersLink} />
              </DrawerSettingsMenuLinkText>
              <KeyboardArrowRightIcon />
            </DrawerSettingsMenuNavLink>
          </DrawerSettingsMenuItem>
        </VerifyFeatureAccess>

        {/* GROUPS / TEAMS */}
        <VerifyFeatureAccess
          canDisplay={canMenuGroupsPage}
          canAccess={canViewGroupsPage}
          restrictionMessage={BillingTranslation.generalUpgradeMessage}>
          <DrawerSettingsMenuItem>
            <DrawerSettingsMenuNavLink
              className="verify-trigger"
              to={`/workspace/${workspaceShortId}/admin/groups`}>
              <TeamIcon width={ICON_SIZE} height={ICON_SIZE} />
              <DrawerSettingsMenuLinkText>
                <FormattedMessage id={WorkspaceTranslation.adminNavTeamsLink} />
              </DrawerSettingsMenuLinkText>
              <KeyboardArrowRightIcon />
            </DrawerSettingsMenuNavLink>
          </DrawerSettingsMenuItem>
        </VerifyFeatureAccess>

        {/* DESKTOPS */}
        {(canMenuDesktopsPage || canViewDesktopsPage) && (
          <VerifyFeatureAccess
            canDisplay={canMenuDesktopsPage}
            canAccess={canViewDesktopsPage}
            restrictionMessage={BillingTranslation.generalUpgradeMessage}>
            <DrawerSettingsMenuItem>
              <DrawerSettingsMenuNavLink
                className="verify-trigger"
                to={`/workspace/${workspaceShortId}/admin/desktops`}>
                <DesktopIcon width={ICON_SIZE} height={ICON_SIZE} />
                <DrawerSettingsMenuLinkText>
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavDesktopsLink}
                  />
                </DrawerSettingsMenuLinkText>
                <KeyboardArrowRightIcon />
              </DrawerSettingsMenuNavLink>
            </DrawerSettingsMenuItem>
          </VerifyFeatureAccess>
        )}

        {/* BILLING */}
        {!isNativeWrapperAvailable && (
          <VerifyFeatureAccess
            canDisplay={canMenuBillingPage}
            canAccess={canViewBillingPage}
            restrictionMessage={BillingTranslation.generalUpgradeMessage}>
            <DrawerSettingsMenuItem>
              <DrawerSettingsMenuNavLink
                className="verify-trigger"
                to={`/workspace/${workspaceShortId}/admin/billing`}>
                <CreditcardIcon width={ICON_SIZE} height={ICON_SIZE} />
                <DrawerSettingsMenuLinkText>
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavBillingLink}
                  />
                </DrawerSettingsMenuLinkText>
                <KeyboardArrowRightIcon />
              </DrawerSettingsMenuNavLink>
            </DrawerSettingsMenuItem>
          </VerifyFeatureAccess>
        )}

        {/* SSO */}
        {(canMenuSsoPage || canViewSsoPage) && (
          <VerifyFeatureAccess
            canDisplay={canMenuSsoPage}
            canAccess={canViewSsoPage}
            restrictionMessage={BillingTranslation.generalUpgradeMessage}>
            <DrawerSettingsMenuItem>
              <DrawerSettingsMenuNavLink
                className="verify-trigger"
                to={`/workspace/${workspaceShortId}/admin/sso`}>
                <KeyIcon width={ICON_SIZE} height={ICON_SIZE} />
                <DrawerSettingsMenuLinkText>
                  <FormattedMessage id={AppStoreTranslation.ssoAppTabName} />
                </DrawerSettingsMenuLinkText>
                <KeyboardArrowRightIcon />
              </DrawerSettingsMenuNavLink>
            </DrawerSettingsMenuItem>
          </VerifyFeatureAccess>
        )}

        {/* APPS INTEGRATIONS */}
        {(canMenuAppIntegrationsPage ||
          canViewWorkspaceAppIntegrations ||
          canViewPersonalAppIntegrations) && (
          <VerifyFeatureAccess
            canDisplay={canMenuAppIntegrationsPage}
            canAccess={
              canViewWorkspaceAppIntegrations || canViewPersonalAppIntegrations
            }
            restrictionMessage={BillingTranslation.generalUpgradeMessage}>
            <DrawerSettingsMenuItem>
              <DrawerSettingsMenuNavLink
                className="verify-trigger"
                to={`/workspace/${workspaceShortId}/admin/apps-integrations`}>
                <AppIntegrationIcon width={ICON_SIZE} height={ICON_SIZE} />
                <DrawerSettingsMenuLinkText>
                  <FormattedMessage
                    id={AppStoreTranslation.customAppsIntegrationsTabFilter}
                  />
                </DrawerSettingsMenuLinkText>
                <KeyboardArrowRightIcon />
              </DrawerSettingsMenuNavLink>
            </DrawerSettingsMenuItem>
          </VerifyFeatureAccess>
        )}

        {/* CUSTOM APPS */}
        {(canMenuCustomAppsPage || canViewCustomAppsPage) && (
          <VerifyFeatureAccess
            canDisplay={canMenuCustomAppsPage}
            canAccess={canViewCustomAppsPage}
            restrictionMessage={BillingTranslation.generalUpgradeMessage}>
            <DrawerSettingsMenuItem>
              <DrawerSettingsMenuNavLink
                className="verify-trigger"
                to={`/workspace/${workspaceShortId}/admin/custom-apps`}>
                <CustomAppsIcon width={ICON_SIZE} height={ICON_SIZE} />
                <DrawerSettingsMenuLinkText>
                  <FormattedMessage
                    id={AppStoreTranslation.customAppsTabFilter}
                  />
                </DrawerSettingsMenuLinkText>
                <KeyboardArrowRightIcon />
              </DrawerSettingsMenuNavLink>
            </DrawerSettingsMenuItem>
          </VerifyFeatureAccess>
        )}

        <DrawerSettingsSeparator />
        <DrawerSettingsSeparator />

        <DrawerSettingsMenuItem>
          <DrawerSettingsMenuNavLink
            to={{
              pathname: LOGOUT_PATHNAME,
              search: getQueryParamsFrom({
                sendLogoutMessageToOtherTabs: true,
              }),
            }}>
            <LogoutIcon width={ICON_SIZE} height={ICON_SIZE} />
            <DrawerSettingsMenuLinkText>
              <FormattedMessage id={GlobalTranslation.logOut} />
            </DrawerSettingsMenuLinkText>
          </DrawerSettingsMenuNavLink>
        </DrawerSettingsMenuItem>

        <DrawerSettingsSeparator />
      </DrawerSettingsMenuList>
    </StyledDrawerSettingsMenu>
  );
};
