import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

const truncate = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledAppCard = styled.div<{ width?: number }>`
  width: ${p => p.width}px;
  background-color: ${p => p.theme.colors.Surface};
  padding: 16px;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CardData = styled.div`
  flex: 1 1 100%;
  padding-right: 0.25rem;
`;

export const CardTitle = styled.h3`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 20px;
  word-break: break-all;
  ${truncate};
`;

export const CardCategory = styled.p`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 14px;
  ${truncate};
`;

export const AppLogo = styled.img<{ size: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  flex: 0 0 32px;
  margin-right: 16px;
  user-select: none;
  object-fit: contain;
  overflow: hidden;
`;

export const MenuButton = styled(ButtonWithIcon)`
  margin-left: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs};
`;
