import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../shared/components/Modal';
import { ChatTranslation } from '../i18n';
import { RenameChatForm } from './RenameChatForm';
import { useCurrentConversation } from '../Chat.hooks';
import { RenameChatModalBody } from './RenameChatModal.styled';

export const RenameChatModal: FC<ModalProps> = ({
  onRequestClose,
  ...props
}) => {
  const { conversation } = useCurrentConversation();

  if (!conversation) {
    return null;
  }

  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={ChatTranslation.renameChatModalTitle} />
      </ModalHeader>
      <RenameChatModalBody>
        <RenameChatForm
          chatConversation={conversation}
          onDone={onRequestClose}
        />
      </RenameChatModalBody>
    </Modal>
  );
};
