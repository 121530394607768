import React, { FC } from 'react';
import { StyledLinkPreview } from './LinkPreview.styled';
import { LinkApiType } from '../../../Link.types';
import { Preview } from '../../../../Preview/';

type LinkPreviewProps = {
  link: LinkApiType;
  isHidden: boolean;
};

export const LinkPreview: FC<LinkPreviewProps> = ({ link, isHidden }) => {
  return (
    <StyledLinkPreview isHidden={isHidden}>
      <Preview link={link} />
    </StyledLinkPreview>
  );
};
