import React, { FC, useCallback, useMemo, useState } from 'react';
import CreatableSelect, { Props } from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';
import { NewOption } from './DesktopSelect.types';
import { shouldShowOption } from './DesktopSelect.utils';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { useGroupedSortedAccounts } from '../../../../Chat/Chat.hooks';
import { getAccountName, isAccountGuest } from '../../../../User/User.utils';
import { CustomOption } from './CustomOption';
import { GlobalTranslation } from '../../../../Intl/i18n';
import { DesktopTranslation } from '../../../i18n';
import { useTheme } from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { isEmail } from '../../../../../shared/utils/email.utils';
import isString from 'lodash/isString';
import type { TeamApiType } from '../../../../Team/data/Team/types/Team.types';
import type {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../../User/User.types';
import { useTeamsRepository } from '../../../../Team/data/Team/Team.repositories';

export interface DesktopSelectProps
  extends Pick<
    Props<AccountWithCountsApiType | TeamApiType, false>,
    'isDisabled'
  > {
  onSelect: (
    item: AccountWithCountsApiType | TeamApiType | string,
  ) => Promise<unknown>;
  allowTeams?: boolean;
  allowGuests?: boolean;
  currentTeamIds: string[];
  currentAccountIds: string[];
  currentInviteEmails: string[];
  placeholder: string;
}

export const DesktopSelect: FC<DesktopSelectProps> = ({
  onSelect,
  allowTeams = false,
  allowGuests = false,
  currentTeamIds,
  currentAccountIds,
  currentInviteEmails,
  placeholder,
  ...props
}) => {
  const intl = useIntl();
  const theme = useTheme();

  const { workspace } = useCurrentWorkspace();
  const [value, setValue] = useState<
    AccountWithCountsApiType | TeamApiType | null
  >(null);

  const groupedAccounts = useGroupedSortedAccounts();

  const { teams } = useTeamsRepository({
    workspaceId: workspace.id,
  });

  const allowedTeams = useMemo(
    () =>
      allowTeams
        ? teams
            .filter(team => !currentTeamIds.includes(team.id))
            .sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [teams, currentTeamIds, allowTeams],
  );

  const options = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: DesktopTranslation.usersSelectGroupTypeGroups,
        }),
        options: allowedTeams,
      },
      ...groupedAccounts,
    ],
    [intl, allowedTeams, groupedAccounts],
  );

  const handleChange = useCallback(
    async (
      newValue:
        | AccountWithCountsApiType
        | TeamApiType
        | NewOption
        | string
        | null,
    ) => {
      if (!newValue) {
        return;
      }
      await onSelect(
        isString(newValue) || 'id' in newValue ? newValue : newValue.value,
      );
      setValue(null);
    },
    [onSelect],
  );

  const handleKeyDown = (event: any) => {
    const {
      key,
      target: { value },
    } = event;

    const inputValue = value.replace(/\s/g, '');
    const newValue = { value: inputValue, label: inputValue, __isNew__: true };

    setValue('id' in newValue ? newValue : newValue.value);

    if (!isEmail('id' in newValue ? newValue : newValue.value)) {
      return null;
    }

    switch (key) {
      case 'Enter':
        handleChange(newValue);
        break;
      case 'Tab':
        handleChange(newValue);
        break;
    }
  };

  return (
    <CreatableSelect<AccountWithCountsApiType | TeamApiType>
      components={{ Option: CustomOption }}
      options={options}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      {...props}
      getOptionLabel={(
        option: AccountWithCountsApiType | TeamApiType | NewOption,
      ) => {
        if ('label' in option) {
          return option.label;
        }
        if ('name' in option) {
          return option.name;
        }
        return getAccountName(option) || '';
      }}
      getOptionValue={(
        option: AccountWithCountsApiType | TeamApiType | NewOption,
      ) => {
        if ('label' in option) {
          return option.value;
        }
        return option.id;
      }}
      filterOption={(option, rawInput) => {
        const data: AccountWithCountsApiType | TeamApiType | NewOption =
          option.data as AccountWithCountsApiType | TeamApiType | NewOption;
        if ('label' in data && data.__isNew__) {
          return true;
        }

        if ('id' in data && currentAccountIds.includes(data.id)) {
          return false;
        }

        if ('workspaceCache' in data && isAccountGuest(data) && !allowGuests) {
          return false;
        }

        return shouldShowOption(
          data as AccountWithCountsApiType | TeamApiType,
          rawInput,
        );
      }}
      //TODO remove ts error and ts ignore
      // @ts-ignore
      isValidNewOption={(
        rawInput: string,
        value: string,
        options: (AccountWithCountsApiType | TeamApiType | NewOption)[],
      ) => {
        if (!allowGuests || currentInviteEmails.includes(rawInput)) {
          return false;
        }
        const noExistingOptions = options.every(option => {
          if ('options' in option) {
            return true;
          }
          return !shouldShowOption(
            option as AccountApiType | TeamApiType,
            rawInput,
          );
        });
        return noExistingOptions && rawInput.includes('@');
      }}
      formatCreateLabel={(rawInput: string) =>
        intl.formatMessage(
          {
            id: DesktopTranslation.accessGuest,
          },
          {
            email: rawInput,
          },
        )
      }
      noOptionsMessage={(obj: { inputValue: string }) => {
        return isEmail(obj.inputValue) ? (
          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={() => handleChange(obj.inputValue)}>
            <FormattedMessage id={GlobalTranslation.labelSendinvite} />
          </Button>
        ) : (
          intl.formatMessage({
            id: GlobalTranslation.labelNothingFound,
          })
        );
      }}
      styles={{
        valueContainer: styles => ({
          ...styles,
          padding: theme.spacing.xs,
        }),
        placeholder: styles => ({
          ...styles,
          zIndex: 1,
        }),
        input: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceHighEmphasis,
          width: '100% !important',
          display: 'flex',
          margin: 0,

          div: {
            zIndex: 2,
            width: '100% !important',
          },
          input: {
            zIndex: 3,
            width: '100% !important',
          },
        }),
        singleValue: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceHighEmphasis,
          fontSize: '0.875rem',
        }),
        menuList: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceHighEmphasis,
          fontSize: '0.875rem',
          backgroundColor: theme.colors.Surface,
          borderRadius: '4px',
          border: '1px solid',
          borderColor: theme.colors.BorderDefault,
        }),
        indicatorSeparator: styles => ({
          ...styles,
          display: 'none',
        }),
        dropdownIndicator: styles => ({
          ...styles,
          color: theme.colors.OnSurfaceLightEmphasis,
          ':hover': {
            color: theme.colors.OnSurfaceLightEmphasis,
          },
        }),
        control: styles => ({
          ...styles,
          fontSize: '1rem',
          fontWeight: 500,
          backgroundColor: theme.colors.Surface,
          color: theme.colors.OnSurfaceMediumEmphasis,
          borderColor: theme.colors.BorderDefault,
          ':hover': {
            borderColor: theme.colors.BorderDefault,
          },
        }),
      }}
    />
  );
};
