export const TIME_ZONES: string[] = [
  'Pacific/Midway',
  'America/Adak',
  'America/Anchorage',
  'Pacific/Gambier',
  'America/Dawson_Creek',
  'America/Ensenada',
  'America/Los_Angeles',
  'America/Chihuahua',
  'America/Denver',
  'America/Belize',
  'America/Cancun',
  'America/Chicago',
  'America/Bogota',
  'America/Havana',
  'America/New_York',
  'America/Caracas',
  'America/Campo_Grande',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Santiago',
  'America/La_Paz',
  'America/Argentina/Buenos_Aires',
  'America/Montevideo',
  'America/Araguaina',
  'America/Godthab',
  'America/Miquelon',
  'America/Sao_Paulo',
  'America/St_Johns',
  'America/Noronha',
  'America/Danmarkshavn',
  'Atlantic/Cape_Verde',
  'Europe/Belfast',
  'Africa/Abidjan',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Europe/London',
  'Europe/Oslo',
  'Europe/Paris',
  'Africa/Algiers',
  'Africa/Windhoek',
  'Atlantic/Azores',
  'Atlantic/Stanley',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Brussels',
  'Africa/Cairo',
  'Africa/Blantyre',
  'Asia/Beirut',
  'Asia/Damascus',
  'Asia/Gaza',
  'Asia/Jerusalem',
  'Africa/Addis_Ababa',
  'Europe/Minsk',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Yerevan',
  'Europe/Moscow',
  'Asia/Kabul',
  'Asia/Tashkent',
  'Asia/Kolkata',
  'Asia/Katmandu',
  'Asia/Dhaka',
  'Asia/Yekaterinburg',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Novosibirsk',
  'Etc/GMT+8',
  'Asia/Hong_Kong',
  'Asia/Krasnoyarsk',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Irkutsk',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Pacific/Marquesas',
  'Etc/GMT+10',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Asia/Yakutsk',
  'Australia/Lord_Howe',
  'Asia/Vladivostok',
  'Pacific/Norfolk',
  'Etc/GMT+12',
  'Asia/Anadyr',
  'Asia/Magadan',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Tongatapu',
  'Pacific/Kiritimati',
];
