import React, { FC, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { AccountApiType, EditUserProfileFormValues } from '../../User.types';
import { FormInput } from '../../../../shared/components/Input';
import { FormPhoneInput } from '../../../../shared/components/PhoneInput';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { UserTranslation } from '../../i18n';
import { ImageUpload } from '../../../Image/ImageUpload';
import UploadImagePlaceholder from '../../../../shared/assets/images/image-upload.svg';
import { getGlideImageSrcSet } from '../../../../shared/utils/url.utils';
import {
  EmailChangeRevokeButton,
  ImageButtonsContainer,
  ImageRemoveButton,
  ImageUploadButton,
  StyledAvatar,
  StyledAvatarBox,
  StyledControls,
  StyledEditUserProfileForm,
} from './EditUserProfile.form.styled';
import { SubLabel } from '../../../../shared/components/SubLabel';
import { FormReactSelect } from '../../../../shared/components/ReactSelect';
import { useTimeZoneOptions } from '../../../TimeDate/TimeDate.hooks';
import { TimezoneType } from '../../../TimeDate/TimeDate.types';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { GlobalTranslation } from '../../../Intl/i18n';

const EditUserProfileFormSchema: Yup.Schema<EditUserProfileFormValues> =
  Yup.object().shape({
    image: Yup.string(),
    phoneNumber: Yup.string().max(
      20,
      UserTranslation.profileFormPhoneLengthWarning,
    ),
    firstName: Yup.string().required(
      UserTranslation.profileFormFirstNameRequired,
    ),
    lastName: Yup.string().required(
      UserTranslation.profileFormLastNameRequired,
    ),
    email: Yup.string().required(UserTranslation.profileFormEmailRequired),
    timezone: Yup.string(),
    companyName: Yup.string().max(
      255,
      UserTranslation.profileFormCompanyNameLengthWarning,
    ),
    companyUrl: Yup.string().url(
      UserTranslation.profileFormCompanyUrlIsNotValid,
    ),
  });

export interface EditUserProfileFormProps {
  account: AccountApiType;
  onSubmit: (formValues: EditUserProfileFormValues) => void;
  onRevokeEmailChange: () => void;
  onCancel?: () => void;
}

export const EditUserProfileForm: FC<EditUserProfileFormProps> = ({
  account,
  onSubmit,
  onRevokeEmailChange,
  onCancel,
}) => {
  const intl = useIntl();
  const timeZoneOptions = useTimeZoneOptions();

  const initialValues: EditUserProfileFormValues = {
    image: account?.image?.id || '',
    phoneNumber: account?.phoneNumber || '',
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    email: account?.email || '',
    timezone: account?.timezone || '',
    companyName: account?.companyName || '',
    companyUrl: account?.companyUrl || '',
  };

  const [imageUrl, setImageUrl] = useState<string>(
    account?.image?.contentUrl || '',
  );

  const PROFILE_IMAGE_SIZE = 132;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditUserProfileFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, errors, touched, values }) => {
        if (errors.companyUrl && touched.companyUrl) {
          const protocolRegex = /^(http|https):/;
          const protocol = 'https://';
          if (values.companyUrl && !values.companyUrl.match(protocolRegex)) {
            setFieldValue('companyUrl', `${protocol}${values.companyUrl}`);
          }
        }
        return (
          <Form>
            <StyledEditUserProfileForm>
              <StyledAvatarBox size={PROFILE_IMAGE_SIZE}>
                <ImageUpload
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('image', id);
                    setImageUrl(contentUrl);
                  }}>
                  <StyledAvatar
                    src={imageUrl || UploadImagePlaceholder}
                    srcSet={getGlideImageSrcSet(imageUrl, {
                      w: PROFILE_IMAGE_SIZE,
                      h: PROFILE_IMAGE_SIZE,
                      fit: 'crop-center',
                    })}
                    size={PROFILE_IMAGE_SIZE}
                  />
                </ImageUpload>
              </StyledAvatarBox>
              <ImageButtonsContainer>
                <ImageUpload
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('image', id);
                    setImageUrl(contentUrl);
                  }}>
                  <ImageUploadButton
                    size={ButtonSize.sm}
                    mode={ButtonMode.secondary}
                    fullWidth>
                    <FormattedMessage
                      id={
                        imageUrl
                          ? UserTranslation.profileImageChangeButton
                          : UserTranslation.profileImageUploadButton
                      }
                    />
                  </ImageUploadButton>
                </ImageUpload>
                {imageUrl && (
                  <ImageRemoveButton
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      setFieldValue('image', '');
                      setImageUrl('');
                    }}
                    size={ButtonSize.sm}
                    mode={ButtonMode.secondary}
                    fullWidth>
                    <FormattedMessage
                      id={UserTranslation.profileImageRemoveButton}
                    />
                  </ImageRemoveButton>
                )}
              </ImageButtonsContainer>
              <div className="fields">
                <Field
                  className="fieldBox"
                  data-testid="first-name"
                  name="firstName"
                  type="text"
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormFirstNameLabel,
                  })}
                  component={FormInput}
                />
                <Field
                  className="fieldBox"
                  data-testid="last-name"
                  name="lastName"
                  type="text"
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormLastNameLabel,
                  })}
                  component={FormInput}
                />

                <Field name="email" type="text">
                  {(props: FieldProps) => {
                    return (
                      <div className="fieldBox">
                        <FormInput
                          {...props}
                          disabled={!!account.pendingEmail}
                          data-testid="email"
                          label={intl.formatMessage({
                            id: UserTranslation.profileFormEmailLabel,
                          })}
                        />
                        {account.pendingEmail && (
                          <SubLabel>
                            <FormattedHTMLMessage
                              id={UserTranslation.profileConfirmChangeEmail}
                              values={{ pendingEmail: account.pendingEmail }}
                            />
                            <EmailChangeRevokeButton
                              data-testid="email-change-revoke-button"
                              onClick={onRevokeEmailChange}>
                              <FormattedHTMLMessage
                                id={UserTranslation.revokeEmailChangeButton}
                              />
                            </EmailChangeRevokeButton>
                          </SubLabel>
                        )}
                      </div>
                    );
                  }}
                </Field>

                <Field
                  className="fieldBox"
                  data-testid="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormPhoneLabel,
                  })}
                  component={FormPhoneInput}
                />

                <Field
                  className="fieldBox"
                  data-testid="company-name"
                  name="companyName"
                  type="text"
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormCompanyNameLabel,
                  })}
                  component={FormInput}
                />

                <Field
                  className="fieldBox"
                  data-testid="company-url"
                  name="companyUrl"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: UserTranslation.profileFormCompanyUrlPlaceholder,
                  })}
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormCompanyUrlLabel,
                  })}
                  component={FormInput}
                />

                <Field
                  name="timezone"
                  className="fieldBox"
                  data-testid="timezone"
                  component={FormReactSelect}
                  options={timeZoneOptions}
                  onChange={(timezone: TimezoneType) =>
                    setFieldValue('timezone', timezone.value)
                  }
                  label={intl.formatMessage({
                    id: UserTranslation.profileFormTimezoneLabel,
                  })}
                  placeholder={intl.formatMessage({
                    id: UserTranslation.profileFormTimezonePlaceholder,
                  })}
                />

                <ImportantMessage type={ImportantMessageType.INFO}>
                  <FormattedHTMLMessage
                    id={GlobalTranslation.checkEmailMessage}
                  />
                </ImportantMessage>

                <StyledControls>
                  <Button
                    data-testid="user-form-cancel-button"
                    type="button"
                    mode={ButtonMode.secondary}
                    size={ButtonSize.md}
                    onClick={onCancel}>
                    <FormattedMessage
                      id={UserTranslation.adminUserFormCancelButton}
                    />
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    mode={ButtonMode.primary}
                    data-testid="submit-button"
                    size={ButtonSize.md}
                    type="submit">
                    <FormattedMessage
                      id={UserTranslation.adminUserFormSubmitButton}
                    />
                  </Button>
                </StyledControls>
              </div>
            </StyledEditUserProfileForm>
          </Form>
        );
      }}
    </Formik>
  );
};
