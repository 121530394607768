import styled, { css } from 'styled-components';
import {
  setBottomLeftRadius,
  setBottomRightRadius,
  setTopLeftRadius,
  setTopRightRadius,
} from './FieldCluster.utils';

interface ClusterRowProps {
  inputCount: 1 | 2;
  firstRow: boolean;
  lastRow: boolean;
}

export const FieldClusterContainer = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 1.5rem 0 0 0;
`;

export const FieldClusterTitle = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const FieldClusterRow = styled.div<ClusterRowProps>`
  ${({ inputCount, firstRow, lastRow }) =>
    inputCount === 1 &&
    css`
      input,
      select {
        border-top-left-radius: ${setTopLeftRadius(firstRow, true)}px;
        border-top-right-radius: ${setTopRightRadius(firstRow, true)}px;
        border-bottom-left-radius: ${setBottomLeftRadius(lastRow, true)}px;
        border-bottom-right-radius: ${setBottomRightRadius(lastRow, true)}px;
        ${!firstRow && `border-top: 0;`}
      }
    `};

  ${({ inputCount, firstRow, lastRow }) =>
    inputCount === 2 &&
    css`
      display: flex;

      > div {
        flex: 1;
      }

      input,
      select {
        ${!firstRow && `border-top: 0;`}
      }

      div:first-child input,
      div:first-child select {
        border-top-left-radius: ${setTopLeftRadius(firstRow, true)}px;
        border-top-right-radius: ${setTopRightRadius(firstRow, false)}px;
        border-bottom-left-radius: ${setBottomLeftRadius(lastRow, true)}px;
        border-bottom-right-radius: ${setBottomRightRadius(lastRow, false)}px;
        border-right: 0;
      }

      div:last-child input,
      div:last-child select {
        border-top-left-radius: ${setTopLeftRadius(firstRow, false)}px;
        border-top-right-radius: ${setTopRightRadius(firstRow, true)}px;
        border-bottom-left-radius: ${setBottomLeftRadius(lastRow, false)}px;
        border-bottom-right-radius: ${setBottomRightRadius(lastRow, true)}px;
      }
    `};
`;
