import React, { FC } from 'react';
import {
  Section,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../Admin';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../../i18n';
import { sendAppTrackingEvent } from '../../../tracking/DesktopTracking.utils';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { AppsContainer } from '../../../Views/AppsContainer/AppsContainer';
import { useQueryParams } from '../../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { AppsSectionBody } from './ManageRelatedApps.styled';
import { ChooseVaultForDesktopModal } from '../../../../Encryption/ChooseVaultForDesktop/ChooseVaultForDesktopModal';
import {
  EDIT_APP_MODAL_Z_INDEX,
  SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX,
} from '../../../../AppStore/AppStore.constants';
import { getAppIri } from '../../../../AppStore/AppStore.utils';
import { getDesktopIri } from '../../../Desktop.utils';
import { EditAppModal } from '../../../../AppStore/Modals/EditCustomAppModal';
import { AppStoreModal } from '../../../../AppStore/Modals/AppStoreModal';

export const ManageRelatedApps: FC<{}> = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  return (
    <>
      <Section data-testid="apps-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="header-title">
            <FormattedMessage id={DesktopTranslation.adminSingleDesktopApps} />
          </SectionHeaderTitle>
          <SectionHeaderButton
            onClick={() => {
              sendAppTrackingEvent('open_app_store_from_admin');
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  appStoreModal: true,
                }),
              });
            }}
            data-testid="open-app-store-btn">
            <FormattedMessage
              id={DesktopTranslation.adminSingleDesktopAppStoreLink}
            />
          </SectionHeaderButton>
        </SectionHeader>

        <AppsSectionBody>
          <AppsContainer adminView hideLayoutPicker={true} />
        </AppsSectionBody>
      </Section>

      {queryParams.selectVaultForAppId && (
        <ChooseVaultForDesktopModal
          containerZIndex={SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX}
          appId={getAppIri(queryParams.selectVaultForAppId as string)}
          groupAppId={
            queryParams.selectVaultForGroupAppId
              ? getAppIri(queryParams.selectVaultForGroupAppId as string)
              : undefined
          }
          desktopIdOverride={
            (queryParams.selectVaultForDesktopIdOverride &&
              getDesktopIri(
                queryParams.selectVaultForDesktopIdOverride as string,
              )) ||
            undefined
          }
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                selectVaultForAppId: undefined,
                selectVaultForGroupAppId: undefined,
                selectVaultForDesktopIdOverride: undefined,
              }),
            })
          }
        />
      )}

      {queryParams.editAppId && (
        <EditAppModal containerZIndex={EDIT_APP_MODAL_Z_INDEX} />
      )}
      {queryParams.appStoreModal && <AppStoreModal />}
    </>
  );
};
