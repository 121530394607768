import React, { FC, useContext, useMemo } from 'react';
import {
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultsList,
  useWorkspaceVaultById,
} from '../../Encryption.hooks';
import { SelectVaultProvider } from '../PasswordsSwitcher/SelectVault.provider';
import { MasterPasswordLockScreenContaner } from './PasswordsSegment.styled';
import { PasswordsSwitcher } from '../PasswordsSwitcher/PasswordsSwitcher';
import { MasterPasswordLockScreen } from '../../MasterPasswordLockScreen';
import { PasswordsSegmentTitleBar } from '../PasswordsSegmentTitleBar/PasswordsSegmentTitleBar';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  PASSWORDS_ROOT_PATHNAME,
  PASSWORDS_VAULT_PATHNAME,
} from '../../../Desktop/Desktop.constants';
import { getVaultIri } from '../../Encryption.utils';
import { CreateVaultItemModal } from '../../VaultItemForm/CreateVaultItem/CreateVaultItemModal';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../shared/hooks';
import { PasswordsPageModal } from '../PasswordsPageModal';
import { EditVaultItemModal } from '../../VaultItemForm';
import { MANAGE_APP_PASSWORDS_MODAL_Z_INDEX } from '../../../AppStore/AppStore.constants';
import { DeleteVaultModal } from '../../DeleteVault/DeleteVaultModal';
import { UnshareVaultModal } from '../../UnshareVault/UnshareVaultModal';
import { DeleteMultipleVaultsModal } from '../../DeleteMultipleVaults/DeleteMultipleVaultsModal';
import { SelectVaultContext } from '../PasswordsSwitcher/SelectVault.context';
import { getShortId } from '../../../../shared/utils/id';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { MembersUniversal } from '../../../Chat/MembersUniversal';

export const PasswordsSegmentSwitcher: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { selectedVaults, unselectAll } = useContext(SelectVaultContext);
  const selectedVaultIds: string[] = useMemo(() => {
    return Object.keys(selectedVaults);
  }, [selectedVaults]);
  const { vaults } = useCurrentWorkspaceVaultsList();

  const currentVaultId = useMemo(() => {
    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { vaultId } = match.params;
    return vaultId ? getVaultIri(vaultId) : null;
  }, [pathname]);
  const { vault } = useWorkspaceVaultById(currentVaultId as string);

  const onPasswordDelete = (deletedVaultIds: string[]) => {
    if (queryParams.deleteSelectedVaults) {
      unselectAll();
    }
    const currentVaultIsDeleted =
      currentVaultId && deletedVaultIds.includes(currentVaultId);
    const vaultForRedirect =
      currentVaultIsDeleted &&
      vaults.find(vault => !deletedVaultIds.includes(vault.id));

    navigate({
      ...(currentVaultIsDeleted
        ? {
            pathname: generatePath(PASSWORDS_ROOT_PATHNAME, {
              workspaceId: getShortId(workspaceId),
              ...(vaultForRedirect
                ? { vaultId: getShortId(vaultForRedirect.id) }
                : {}),
            }),
          }
        : {}),
      search: getQueryParamsFrom({
        ...queryParams,
        deleteSelectedVaults: undefined,
        deleteVaultId: undefined,
        deleteCurrentVault: undefined,
        unshareCurrentVault: undefined,
      }),
    });
  };

  return (
    <SelectVaultProvider>
      <PasswordsSegmentTitleBar />
      {privateKey && <PasswordsSwitcher />}

      {!privateKey && (
        <MasterPasswordLockScreenContaner>
          <MasterPasswordLockScreen
            decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
          />
        </MasterPasswordLockScreenContaner>
      )}

      <MembersUniversal />

      {queryParams.isCreatePasswordModalOpened && (
        <CreateVaultItemModal
          allowManualPasswordCreation
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                isCreatePasswordModalOpened: undefined,
              }),
            })
          }
        />
      )}

      {vault && queryParams.passwordPageModal && (
        <PasswordsPageModal
          visible={true}
          vault={vault}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                passwordPageModal: undefined,
              }),
            });
          }}
        />
      )}

      {privateKey &&
        (queryParams.editVaultId ||
          (currentVaultId && queryParams.editCurrentVault)) && (
          <EditVaultItemModal
            vaultId={
              queryParams.editVaultId
                ? getVaultIri(queryParams.editVaultId as string)
                : currentVaultId!
            }
            containerZIndex={MANAGE_APP_PASSWORDS_MODAL_Z_INDEX}
            onRequestClose={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  editCurrentVault: undefined,
                  editVaultId: undefined,
                }),
              });
            }}
            onDone={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  editCurrentVault: undefined,
                  editVaultId: undefined,
                }),
              });
            }}
          />
        )}

      {privateKey &&
        (queryParams.deleteCurrentVault || queryParams.deleteVaultId) && (
          <DeleteVaultModal
            vaultId={
              queryParams.deleteVaultId
                ? getVaultIri(queryParams.deleteVaultId as string)
                : (currentVaultId as string)
            }
            onDeleted={onPasswordDelete}
            onRequestClose={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  deleteCurrentVault: undefined,
                  deleteVaultId: undefined,
                }),
              });
            }}
          />
        )}

      {privateKey && queryParams.unshareCurrentVault && (
        <UnshareVaultModal
          vaultId={currentVaultId as string}
          onUnshared={onPasswordDelete}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                unshareCurrentVault: undefined,
              }),
            });
          }}
        />
      )}

      {privateKey && queryParams.deleteSelectedVaults && (
        <DeleteMultipleVaultsModal
          vaultIds={selectedVaultIds}
          onDeleted={onPasswordDelete}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                deleteSelectedVaults: undefined,
              }),
            });
          }}
        />
      )}
    </SelectVaultProvider>
  );
};
