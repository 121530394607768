import { useCallback, useMemo } from 'react';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import { useCurrentRoutePath } from '../../../../shared/hooks/router.hooks';
import { isWorkspaceSettingsView } from '../../../../shared/utils/url.utils';
import {
  TeamDesktopsRepositoryProps,
  TeamDesktopsRepositoryResp,
} from './types/DesktopRepository.types';
import { useTeamDesktopsActions } from './TeamDesktops.actions';
import { DesktopApiType } from '../Desktop/types/Desktop.types';
import { getTeamDesktopsRestApi } from './dataSources/TeamDesktopRestApi.dataSources';
import { DesktopApiTypeToDesktopTableType } from '../../../Database/DesktopTable/DesktopsTable.utils';
import { getTeamDesktopsByIdFromIDDB } from './dataSources/TeamDesktopIDDB.dataSources';
import { createDesktopRowsMap } from '../Desktop/utils/DesktopIDDB.utils';
import { addTeamToDesktopRelatedTeams } from './utils/TeamDesktopIDDB.utils';
import { getRedundantCacheDesktops } from '../Desktop/utils/DesktopRepository.utils';
import { removeRelatedDesktopsFromTeamsIDDB } from '../../../Team/data/DesktopTeam/operations/DesktopTeam.operations';
import { removeRelatedTeamsFromDesktopsIDDB } from './operations/TeamDesktop.operations';
import { getLongId } from '../../../../shared/utils/id';

export const useTeamDesktopsRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: TeamDesktopsRepositoryProps): TeamDesktopsRepositoryResp => {
  const { teamId, workspaceId } = args;

  const { bulkPutTeamDesktopsToIDDB } = useTeamDesktopsActions();
  const { account } = useCurrentWorkspaceAccount();
  const path = useCurrentRoutePath();

  const isWorkspaceSettings = isWorkspaceSettingsView(path);

  const fetchDesktops = useCallback(async () => {
    try {
      const { data: desktopsFromAPI, ok } = await getTeamDesktopsRestApi(
        teamId,
      );
      if (ok) {
        const cachedDesktopRows = await getTeamDesktopsByIdFromIDDB(teamId);
        const cachedDesktopRowsMap = createDesktopRowsMap(cachedDesktopRows);
        const redundantCacheDesktops = getRedundantCacheDesktops(
          cachedDesktopRows,
          desktopsFromAPI,
        );

        const desktopRows = desktopsFromAPI.map(desktopFromAPI => {
          const desktopFromApiTableType = DesktopApiTypeToDesktopTableType(
            desktopFromAPI,
            workspaceId,
            desktopFromAPI.accountIds.includes(account.id),
          );
          return {
            ...desktopFromApiTableType,
            relatedAccounts:
              cachedDesktopRowsMap?.[desktopFromAPI.id]?.relatedAccounts,
            relatedTeams: addTeamToDesktopRelatedTeams(
              cachedDesktopRowsMap?.[desktopFromAPI.id]?.relatedTeams,
              getLongId('workspace-teams', teamId),
            ),
          };
        });

        bulkPutTeamDesktopsToIDDB(desktopRows);

        removeRelatedDesktopsFromTeamsIDDB(
          [getLongId('workspace-teams', teamId)],
          redundantCacheDesktops.map(desktop => desktop.id),
        );
        removeRelatedTeamsFromDesktopsIDDB(
          redundantCacheDesktops.map(desktop => desktop.id),
          [getLongId('workspace-teams', teamId)],
        );
      }

      return desktopsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [bulkPutTeamDesktopsToIDDB, teamId, workspaceId, account.id]);

  const { entities: desktopsEntities, loading } =
    useMultipleEntityRepository<DesktopApiType>({
      fetchFunction: fetchDesktops,
      iddbQuerier: () => getTeamDesktopsByIdFromIDDB(teamId),
      dependencies: [teamId, workspaceId],
      fetchPolicy,
    });

  const desktops = useMemo(() => {
    if (isWorkspaceSettings) {
      return desktopsEntities.filter(desktop => !desktop.shareable);
    }

    return desktopsEntities.filter(desktop =>
      desktop.accountIds.includes(account.id),
    );
  }, [account.id, desktopsEntities, isWorkspaceSettings]);

  return useMemo(
    () => ({
      desktops,
      loading,
      refetchDesktops: fetchDesktops,
    }),
    [desktops, loading, fetchDesktops],
  );
};
