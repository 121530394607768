import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { DrawerHeader } from '../Admin';
import { getWorkspaceAdminCloseLink } from '../Workspace/Workspace.utils';
import {
  generatePath,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useMobile } from '../../shared/hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspaceLastOpenedDesktop,
} from '../Workspace/Workspace.hooks';
import { AppIntegrationsView } from './AppIntegrationsView/AppIntegrationsView';
import { CustomIntegrationView } from './Integrations/CustomIntegrations/CustomIntegrationView';
import {
  isIOSNativeWrapperAvailable,
  openDeepLinkInNativeWrapper,
  openInDeviceBrowser,
  useNativePlatformFromUrl,
  useNativeWrapper,
} from '../NativeWrapper';
import { StyledAppIntegrationsNotAvailable } from './AppIntegrations.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../shared/components/Button/Button';
import { NativePlatform } from '../NativeWrapper/NativeWrapper.types';
import { Route } from '../ErrorInterceptor/router.injectors';
import { AppIntegrationsTranslation } from './i18n';
import { MarketplaceItems } from './Integrations/Marketplace/MarketplaceItems';
import { WORKSPACE_ADMIN_PATHNAME } from '../Workspace/Workspace.constants';
import { getShortId } from '../../shared/utils/id';

export const AppIntegrations: FC = () => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { lastOpenedDesktop } = useWorkspaceLastOpenedDesktop();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: {
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
    },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const openInBrowser = () => {
    const nativePlatform = isIOSNativeWrapperAvailable()
      ? NativePlatform.iOS
      : NativePlatform.Android;
    openInDeviceBrowser(
      `${window.location.origin}${pathname}?platform=${nativePlatform}`,
    );
  };

  const nativePlatform = useNativePlatformFromUrl();

  if (
    permissionsLoaded &&
    !canViewWorkspaceAppIntegrations &&
    !canViewPersonalAppIntegrations
  ) {
    navigate(
      generatePath(WORKSPACE_ADMIN_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      }),
    );
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <>
      {isMobile && (
        <DrawerHeader
          data-testid="heading"
          onRequestClose={() => {
            if (nativePlatform && isMobile) {
              openDeepLinkInNativeWrapper(nativePlatform, 'open-app');
              return;
            }
            navigate(
              getWorkspaceAdminCloseLink(currentWorkspace, lastOpenedDesktop),
            );
          }}>
          <FormattedMessage
            id={AppIntegrationsTranslation.appIntegrationCustomAppsTabFilter}
          />
        </DrawerHeader>
      )}
      {isNativeWrapperAvailable ? (
        <StyledAppIntegrationsNotAvailable>
          <FormattedMessage
            id={AppIntegrationsTranslation.appIntegrationsNotAvailable}
          />
          <Button
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={openInBrowser}>
            <FormattedMessage
              id={AppIntegrationsTranslation.appIntegrationsOpenInBrowser}
            />
          </Button>
        </StyledAppIntegrationsNotAvailable>
      ) : (
        <Routes>
          <Route
            path={`custom-integration/:integrationId`}
            element={<CustomIntegrationView />}
          />
          <Route path={':marketplaceCategoryId'} element={<MarketplaceItems />}>
            <Route
              path={':marketplaceCategoryItemId'}
              element={<MarketplaceItems />}>
              <Route path={':appId'} element={<MarketplaceItems />} />
            </Route>
          </Route>
          <Route index element={<AppIntegrationsView />} />
        </Routes>
      )}
    </>
  );
};
