import React, { FC } from 'react';
import { useAppleTouchDevice } from '../../../../shared/hooks';
import { AppLink } from '../../../App/Views/AppLink/AppLink';
import { ContentLayout } from '../../../ContentLayout';
import { useCurrentDesktopPermissions } from '../../Desktop.hooks';
import {
  StyledAppsLayoutFluid,
  StyledCategoryApp,
  StyledCategoryLabel,
} from './AppsLayoutFluid.styled';
import { AppsLayoutFluidViewModel } from './ViewModel';
import type { DesktopAppEdgeApiType } from '../../data/Desktop/types/Desktop.types';

interface LayoutProps {
  apps: DesktopAppEdgeApiType[];
}

export const AppsLayoutFluid: FC<LayoutProps> = ({ apps }) => {
  const isAppleTouchDevice = useAppleTouchDevice();
  const { canRemoveApp } = useCurrentDesktopPermissions();

  const { categories } = AppsLayoutFluidViewModel(apps);

  const renderApps = (category: Array<DesktopAppEdgeApiType>) => {
    return category.map((appEdge, index) => (
      <StyledCategoryApp
        className={
          index === category.length - 1
            ? 'category-end'
            : index === 0
            ? 'category-start'
            : 'category-middle'
        }
        data-testid="app-category"
        key={appEdge.id}
        isFirst={index === 0}
        isLast={index === category.length - 1}
        processHover={!isAppleTouchDevice}>
        <AppLink
          app={appEdge}
          appsLayout={ContentLayout.FLUID}
          dragEnabled={canRemoveApp}
          processHover={!isAppleTouchDevice}
          heartIconOnlyOnFavorited={isAppleTouchDevice}
        />
        {index === 0 && appEdge.app?.category?.name && (
          <StyledCategoryLabel
            className="app-category-name"
            data-testid="app-category-name"
            title={appEdge.app.category.name}>
            <span>{appEdge.app.category.name}</span>
          </StyledCategoryLabel>
        )}
      </StyledCategoryApp>
    ));
  };

  return (
    <StyledAppsLayoutFluid>
      {Object.keys(categories).map((category: string) =>
        renderApps(categories[category]),
      )}
    </StyledAppsLayoutFluid>
  );
};
