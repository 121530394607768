import { useMutation } from '@apollo/client';
import {
  UpdateAccountWorkspaceSettingVariables,
  UpdateAccountWorkspaceSettingResponse,
  UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS,
  UPDATE_INTEGRATIONS_ACCOUNT_WORKSPACE_SETTING,
  UpdateAccountWorkspaceIntegrationsVariables,
} from '.';

export const useUpdateWorkspaceNotificationsSettingMutation = () =>
  useMutation<
    UpdateAccountWorkspaceSettingResponse,
    UpdateAccountWorkspaceSettingVariables
  >(UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS);

export const useUpdateWorkspaceNotificationsIntegrationsMutation = () =>
  useMutation<
    UpdateAccountWorkspaceSettingResponse,
    UpdateAccountWorkspaceIntegrationsVariables
  >(UPDATE_INTEGRATIONS_ACCOUNT_WORKSPACE_SETTING);
