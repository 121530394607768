import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ExternalServiceAuthButton,
  ExternalServiceAuthDescriptionStyled,
} from '..';
import { ExternalServiceAuthTranslation } from './i18n';

interface ExternalServiceAuthNotSupportedProps {
  onClick: () => void;
}
export const ExternalServiceAuthNotSupported: FC<ExternalServiceAuthNotSupportedProps> = ({
  onClick,
}) => {
  return (
    <div>
      <ExternalServiceAuthDescriptionStyled>
        <FormattedMessage
          id={ExternalServiceAuthTranslation.externalAuthNotSupportedTitle}
        />
      </ExternalServiceAuthDescriptionStyled>
      <ExternalServiceAuthButton onClick={onClick}>
        <FormattedMessage
          id={ExternalServiceAuthTranslation.externalAuthNotSupportedButton}
        />
      </ExternalServiceAuthButton>
    </div>
  );
};
