import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalAnimation,
  ModalContent,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { useMobile } from '../../../../shared/hooks';
import {
  SubHeaderContent,
  SubHeaderPrice,
  SubHeaderText,
} from './UpgradeFreeModal.styled';
import { BillingTranslation } from '../../i18n';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { AddPaymentMethod } from './AddPaymentMethod/AddPaymentMethod';
import { getPaymentPlanByWorkspace } from '../../Billing.utils';
import { useGetPaymentPlansQuery } from '../../Billing.hooks';
import {
  useCancelUpgradeWorkspaceMutation,
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import Dinero from 'dinero.js';
import noop from 'lodash/noop';

import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { StripeElementsProvider } from '../../StripeElements';
import { useBillingTrackingEventOnInit } from '../../tracking/BillingTracking.hooks';
import { sendBillingTrackingEvent } from '../../tracking/BillingTracking.utils';

interface UpgradeFreeModalProps
  extends Omit<ModalProps, 'visible' | 'onRequestClose'> {}

export const UpgradeFreeModal: FC<UpgradeFreeModalProps> = () => {
  const isMobile = useMobile();

  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { account } = useCurrentAccount();
  const { data: paymentPlans } = useGetPaymentPlansQuery({
    variables: {
      workspace: workspace.id,
    },
  });
  const [cancelUpgradeWorkspaceMutation] = useCancelUpgradeWorkspaceMutation();
  const [isDowngrading, setIsDowngrading] = useState<boolean>(false);
  const workspacePaymentPlan = useMemo(() => {
    return getPaymentPlanByWorkspace(paymentPlans, workspace);
  }, [paymentPlans, workspace]);

  useBillingTrackingEventOnInit('initiate_upgrade');

  useEffect(() => {
    return () => {
      refetchPermissions();
    };
  }, [refetchPermissions]);

  const handleCancelUpgrade = useCallback(() => {
    setIsDowngrading(true);
    return cancelUpgradeWorkspaceMutation({
      variables: {
        input: {
          id: workspace.id,
        },
      },
    })
      .then(res => {
        setIsDowngrading(false);
        sendBillingTrackingEvent('cancel_upgrade');
        if (!res.data) {
          return;
        }
        refetchWorkspaces({
          id: account!.identityId,
        });
      })
      .catch(e => {
        setIsDowngrading(false);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    account,
    cancelUpgradeWorkspaceMutation,
    workspace.id,
    refetchWorkspaces,
  ]);

  return (
    <>
      <Modal
        visible={true}
        isColoredModal={true}
        width={500}
        fullScreen={isMobile}
        animation={isMobile ? ModalAnimation.slideUp : ModalAnimation.fadeIn}
        onRequestClose={noop}>
        <ModalHeader onRequestClose={handleCancelUpgrade}>
          <FormattedMessage id={BillingTranslation.upgradeFreeModalTitle} />
        </ModalHeader>
        <ModalContent>
          <SubHeaderContent>
            <SubHeaderText>
              <FormattedHTMLMessage
                id={BillingTranslation.upgradeFreeModalSubheaderText}
              />
            </SubHeaderText>
            <SubHeaderPrice>
              {workspacePaymentPlan?.amount && (
                <FormattedMessage
                  id={BillingTranslation.upgradeFreeModalSubheaderPrice}
                  values={{
                    amount: Dinero({
                      amount: workspacePaymentPlan.amount,
                    }).toFormat('0,0.00'),
                  }}
                />
              )}
            </SubHeaderPrice>
          </SubHeaderContent>
          <StripeElementsProvider>
            <AddPaymentMethod />
          </StripeElementsProvider>
        </ModalContent>
      </Modal>
      {isDowngrading && <SpinnerFullscreen />}
    </>
  );
};
