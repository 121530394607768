import React, { FC, useMemo } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspaceState,
} from '../../../Workspace/Workspace.hooks';
import { DesktopList } from './DesktopList/DesktopList';
import { splitDesktopsIntoLists } from './DesktopNavigator.utils';
import { DesktopTranslation } from '../../i18n';
import { DragType } from '../../../Drag';
import {
  DesktopListEmptyState,
  StyledDesktopList,
  StyledDesktopListItem,
} from './DesktopList/DesktopList.styled';
import { appEnv } from '../../../../appEnv';
import { DesktopType } from '../../Desktop.constants';
import { FavoritesLink } from './FavoritesLink/FavoritesLink';
import { VaultLink } from './VaultLink/VaultLink';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  getAccountEntityKeysets,
  isCurrentAccountGuest,
} from '../../../User/User.utils';
import { useDesktopsRepository } from '../../data/Desktop/Desktop.repositories';
import { getShortId } from '../../../../shared/utils/id';

export const DesktopNavigator: FC = () => {
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canViewWorkspaceUserDesktops },
  } = useCurrentWorkspacePermissions();
  const shortWorkspaceId = useMemo(() => {
    return getShortId(workspace.id);
  }, [workspace.id]);

  const accountKeysets = useMemo(
    () => getAccountEntityKeysets(account),
    [account],
  );

  const isWorkspaceGuest: boolean | undefined = useMemo(
    () => isCurrentAccountGuest(account, getShortId(workspace.id)),
    [account, workspace.id],
  );

  const disabledVaultLink =
    (!accountKeysets.length && account?.hasSetPassword) || isWorkspaceGuest;

  const { desktops: desktopsData, loading: desktopsLoading } =
    useDesktopsRepository({
      variables: { filterOnlyAccessible: true },
    });

  const desktops = useMemo(
    () =>
      desktopsData.sort((a, b) =>
        a.accountSortPosition > b.accountSortPosition ? 1 : -1,
      ),
    [desktopsData],
  );

  const { internalDesktops, sharedDesktops, privateDesktops } = useMemo(
    () => splitDesktopsIntoLists(desktops),
    [desktops],
  );

  const {
    privateDesktopsOpen,
    togglePrivateDesktops,
    internalDesktopsOpen,
    toggleInternalDesktops,
    sharedDesktopsOpen,
    toggleSharedDesktops,
    canBeDisplayedInNav,
  } = useWorkspaceState();

  const isFirstTimeLoading = desktopsLoading;

  return (
    <>
      <StyledDesktopList>
        <StyledDesktopListItem>
          <FavoritesLink
            workspaceId={shortWorkspaceId}
            data-testid="favorites-link"
          />
        </StyledDesktopListItem>
        <StyledDesktopListItem>
          <VaultLink
            workspaceId={shortWorkspaceId}
            data-testid="favorites-link"
            disabled={disabledVaultLink}
          />
        </StyledDesktopListItem>
      </StyledDesktopList>

      {canViewWorkspaceUserDesktops && (
        <DesktopList
          desktopListType={DesktopType.INTERNAL}
          loading={isFirstTimeLoading}
          title={DesktopTranslation.listInternalDesktops}
          items={internalDesktops}
          allDesktops={desktops}
          expanded={internalDesktopsOpen}
          onExpandToggle={toggleInternalDesktops}
          dragType={DragType.INTERNAL_DESKTOPS_LIST}
          emptyState={
            canBeDisplayedInNav() && (
              <DesktopListEmptyState>
                <FormattedHTMLMessage
                  id={DesktopTranslation.listInternalDesktopsEmptyText}
                  values={{ desktopTypesUrl: appEnv.SUPPORT_DESKTOP_TYPES_URL }}
                />
              </DesktopListEmptyState>
            )
          }
        />
      )}
      <DesktopList
        desktopListType={DesktopType.SHARED}
        loading={isFirstTimeLoading}
        title={DesktopTranslation.listSharedDesktops}
        items={sharedDesktops}
        allDesktops={desktops}
        expanded={sharedDesktopsOpen}
        onExpandToggle={toggleSharedDesktops}
        dragType={DragType.SHARED_DESKTOPS_LIST}
        emptyState={
          canBeDisplayedInNav() && (
            <DesktopListEmptyState>
              <FormattedHTMLMessage
                id={DesktopTranslation.listSharedDesktopsEmptyText}
                values={{ desktopTypesUrl: appEnv.SUPPORT_DESKTOP_TYPES_URL }}
              />
            </DesktopListEmptyState>
          )
        }
      />
      <DesktopList
        desktopListType={DesktopType.PRIVATE}
        loading={isFirstTimeLoading}
        title={DesktopTranslation.listPrivateDesktops}
        items={privateDesktops}
        allDesktops={desktops}
        expanded={privateDesktopsOpen}
        onExpandToggle={togglePrivateDesktops}
        dragType={DragType.PRIVATE_DESKTOPS_LIST}
        emptyState={
          canBeDisplayedInNav() && (
            <DesktopListEmptyState>
              <FormattedHTMLMessage
                id={DesktopTranslation.listPrivateDesktopsEmptyText}
                values={{ desktopTypesUrl: appEnv.SUPPORT_DESKTOP_TYPES_URL }}
              />
            </DesktopListEmptyState>
          )
        }
      />
    </>
  );
};
