import React, { FC } from 'react';
import { SetPasswordForm } from './SetPasswordForm';
import {
  encryptPrivateKey,
  exportPublicKey,
  generateRsaKeys,
} from '../../Encryption/Encryption.crypto.utils';
import {
  hashAuthPassword,
  hashMasterPassword,
} from '../../../shared/utils/hash.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { SetPasswordFormValues } from '../Password.types';
import { useChangePasswordAccountMutation } from '../../User/User.hooks';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { captureException } from '../../ErrorInterceptor';

interface SetPasswordProps {
  onDone?: () => void;
  showWarningMessage?: boolean;
  submitButtonMessageId: string;
}

export const SetPassword: FC<SetPasswordProps> = ({
  onDone,
  showWarningMessage = true,
  submitButtonMessageId,
}) => {
  const { refetchAccountData } = useCurrentAccount();
  const { refetchWorkspaces } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const accountId = account?.id;
  const accountEmail = account?.email;

  const [changePasswordAccountMutation] = useChangePasswordAccountMutation();
  const sendPassword = (
    password: string,
    pubKey: string,
    privKeyEncrypted: string,
  ) => {
    return hashAuthPassword(password, accountEmail).then(newPassword => {
      return changePasswordAccountMutation({
        variables: {
          input: {
            id: accountId,
            currentPassword: null,
            newPassword,
            repeatPassword: newPassword,
            pubKey,
            privKeyEncrypted,
          },
        },
      });
    });
  };

  const handleSubmit = async (values: SetPasswordFormValues) => {
    try {
      const { password } = values;
      const { privateKey, publicKey } = await generateRsaKeys();
      const masterPasswordHash = await hashMasterPassword(
        password,
        accountEmail,
      );
      const pubKey = await exportPublicKey(publicKey);
      const privKeyEncrypted = await encryptPrivateKey(
        privateKey,
        masterPasswordHash,
        accountId,
      );
      await sendPassword(password, pubKey, privKeyEncrypted);
      await refetchAccountData?.();
      await refetchWorkspaces?.();
      onDone?.();
    } catch (e) {
      captureException(e as Error, 'Failed to set password for social user');
      console.error(e);
    }
  };

  return (
    <SetPasswordForm
      onSubmit={handleSubmit}
      showWarningMessage={showWarningMessage}
      submitButtonMessageId={submitButtonMessageId}
    />
  );
};
