import styled, { css } from 'styled-components';

export const StyledLinkPreview = styled.div<{ isHidden: boolean }>`
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;
