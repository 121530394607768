import styled from 'styled-components';

export const StyledUnsubscribePage = styled.div`
  max-width: 32rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  background-color: ${p => p.theme.colors.Background};
  border-radius: 8px;
  padding: 2.5rem;
  margin: 3.75rem auto;

  button {
    display: block;
    margin: 1rem auto 0;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 2rem;
  font-family: ${p => p.theme.fonts.Semibold};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const StyledDescription = styled.div`
  a {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    text-decoration: underline;
  }
`;
