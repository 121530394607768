import React from 'react';
import {
  DesktopEmptyViewBody,
  DesktopEmptyViewFooter,
  DesktopEmptyViewOnboardingLink,
  DesktopEmptyViewWrapper,
  EmptyFavoritesHeader,
  EmptyFavoritesMessage,
} from './DesktopEmptyView.styled';
import { useCurrentDesktop } from '../../Desktop.hooks';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../i18n';
import { appEnv } from '../../../../appEnv';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { DesktopEmptyStateIcon } from '../../../../shared/icons/DesktopEmptyState.icon';

export const DesktopEmptyView = () => {
  const currentDesktop = useCurrentDesktop();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const handleOnboardingClick = () => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        openOnboardingQuickTour: true,
      }),
    });
  };

  return (
    <DesktopEmptyViewWrapper>
      {currentDesktop ? (
        <DesktopEmptyViewBody>
          <DesktopEmptyStateIcon />
          <div className="emptyViewTittle">
            <FormattedHTMLMessage
              id={DesktopTranslation.desktopEmptyViewTitle}
              values={{
                desktopName: currentDesktop?.name,
              }}
            />
          </div>
          <div className="emptyViewSubTitle">
            <FormattedMessage
              id={DesktopTranslation.desktopEmptyViewSubTitle}
            />
          </div>
        </DesktopEmptyViewBody>
      ) : (
        <DesktopEmptyViewBody>
          <EmptyFavoritesHeader data-testid="empty-header">
            <FormattedMessage
              id={DesktopTranslation.desktopEmptyFavoritesHeader}
            />
          </EmptyFavoritesHeader>
          <EmptyFavoritesMessage data-testid="no-links-msg">
            <FormattedMessage
              id={DesktopTranslation.desktopEmptyFavoritesMessage}
            />
          </EmptyFavoritesMessage>
        </DesktopEmptyViewBody>
      )}
      <DesktopEmptyViewFooter>
        <FormattedMessage id={DesktopTranslation.desktopEmptyViewFooter} />
        <div>
          <FormattedMessage
            id={DesktopTranslation.desktopEmptyViewFooterStartAn}
          />
          <DesktopEmptyViewOnboardingLink onClick={handleOnboardingClick}>
            <FormattedMessage
              id={DesktopTranslation.desktopEmptyViewFooterOnboardingTour}
            />
          </DesktopEmptyViewOnboardingLink>
          <FormattedHTMLMessage
            id={DesktopTranslation.desktopEmptyViewFooterFaq}
            values={{
              faqUrl: appEnv.SUPPORT_FAQ_URL,
            }}
          />
        </div>
      </DesktopEmptyViewFooter>
    </DesktopEmptyViewWrapper>
  );
};
