import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CalendarButton,
  FilterInput,
  FilterTypeButton,
  MenuTitle,
  SearchMenuItem,
  StyledTimelineFilters,
} from './TimelineFilters.styled';
import { CalendarIcon, SearchWithArrow } from '../../../../shared/icons';
import { Menu } from '../../../../shared/components/Menu';
import { ButtonSize } from '../../../../shared/components/Button/Button';
import { SearchOptions } from '../../Timeline.constants';
import { InputSize } from '../../../../shared/components/Input';
import { TimelineDateRange } from '../TimelineDateRange';
import { TimelineTranslation } from '../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../Theme';

const SEARCH_DEBOUNCE_TIME = 500;

interface TimelineFiltersProps {
  onFilterValueChange: (filterValue: string) => void;
  onFilterTypeChange: (eventFilterType: string) => void;
  onDateRangeChange: (date: { startDate: Date; endDate: Date }) => void;
  shouldCloseTimelineOnMenuSelect?: MutableRefObject<boolean>;
  isTimeLineDateRangeOpened?: MutableRefObject<boolean>;
}

export const TimelineFilters: FC<TimelineFiltersProps> = ({
  onFilterValueChange,
  onFilterTypeChange,
  onDateRangeChange,
  shouldCloseTimelineOnMenuSelect,
  isTimeLineDateRangeOpened,
}) => {
  const dateRangePickerRef = useRef(null);
  const filterInputRef = useRef(null);
  const [filterEventType, setFilterEventType] = useState('');
  const intl = useIntl();
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.md,
  });

  useEffect(() => {
    if (shouldCloseTimelineOnMenuSelect) {
      shouldCloseTimelineOnMenuSelect.current = false;
    }
  }, [shouldCloseTimelineOnMenuSelect]);

  const handleDateRangeChange = (value: { startDate: Date; endDate: Date }) => {
    if (shouldCloseTimelineOnMenuSelect) {
      shouldCloseTimelineOnMenuSelect.current = false;
    }
    onDateRangeChange(value);
  };

  const handleSelectMenu = (selectedMenuItem: string) => {
    if (shouldCloseTimelineOnMenuSelect) {
      shouldCloseTimelineOnMenuSelect.current = false;
    }
    onFilterTypeChange(selectedMenuItem);
    setFilterEventType(selectedMenuItem);
  };

  return (
    <StyledTimelineFilters>
      <FilterTypeButton ref={filterInputRef} icon={SearchWithArrow} />

      <FilterInput
        size={InputSize.sm}
        placeholder={intl.formatMessage({
          id: TimelineTranslation.eventsFilterInputPlaceholder,
        })}
        onChange={e => onFilterValueChange(e.target.value)}
        debounce={SEARCH_DEBOUNCE_TIME}
      />

      <CalendarButton
        icon={CalendarIcon}
        size={ButtonSize.sm}
        ref={dateRangePickerRef}
      />

      <Menu trigger={filterInputRef} width={200} maxHeight={186}>
        <MenuTitle>
          <FormattedMessage
            id={TimelineTranslation.eventsFilterOptionsHeading}
          />
        </MenuTitle>
        <SearchMenuItem
          displayCheckMark={filterEventType === SearchOptions.all}
          onClick={() => handleSelectMenu(SearchOptions.all)}>
          <FormattedMessage id={TimelineTranslation.eventsFilterOptionsAll} />
        </SearchMenuItem>
        <SearchMenuItem
          displayCheckMark={filterEventType === SearchOptions.apps}
          onClick={() => handleSelectMenu(SearchOptions.apps)}>
          <FormattedMessage id={TimelineTranslation.eventsFilterOptionsApps} />
        </SearchMenuItem>
        <SearchMenuItem
          displayCheckMark={filterEventType === SearchOptions.links}
          onClick={() => handleSelectMenu(SearchOptions.links)}>
          <FormattedMessage id={TimelineTranslation.eventsFilterOptionsLinks} />
        </SearchMenuItem>
        <SearchMenuItem
          displayCheckMark={filterEventType === SearchOptions.events}
          onClick={() => handleSelectMenu(SearchOptions.events)}>
          <FormattedMessage
            id={TimelineTranslation.eventsFilterOptionsEvents}
          />
        </SearchMenuItem>
      </Menu>

      <Menu
        portalContainerId="timeline-desktop-popover"
        trigger={dateRangePickerRef}
        width={isMobile ? null : 570}
        maxHeight={null}>
        <TimelineDateRange
          onDateRangeChange={handleDateRangeChange}
          isTimeLineDateRangeOpened={isTimeLineDateRangeOpened}
        />
      </Menu>
    </StyledTimelineFilters>
  );
};
