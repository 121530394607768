import styled from 'styled-components';
import { ConferenceViewHeaderSection } from '../../ConferenceView/ConferenceView.styled';

export const ScheduledConferenceInviteesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScheduledConferenceMember = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-top: 0.25rem;

  img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const ScheduledConferenceMemberEmail = styled.div`
  display: flex;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  padding: 0.25rem 0.5rem 0 0.5rem;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${p => p.theme.colors.SurfaceSubdued};
  }

  span {
    display: flex;
    align-items: center;
    margin: 0 0.625rem 0 0.5rem;
  }

  button {
    padding: 0.25rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
    }
  }
`;

export const ConferenceInviteesHeaderSection = styled(
  ConferenceViewHeaderSection,
)`
  margin-right: 0.5rem;
  ${({ theme: { breakpoints } }) => breakpoints.md`
    padding-top: 0.625rem;
  `}
`;

export const StyledConferenceInvitees = styled.div`
  button {
    margin-top: 0.25rem;
    font-family: ${p => p.theme.fonts.Regular};
    > div:first-child {
      margin-left: 0;
    }
    ${({ theme: { breakpoints } }) => breakpoints.md`
      margin: 0;
    `}
  }
`;
