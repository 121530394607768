import styled from 'styled-components';

export const StyledPdfLink = styled.a`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  float: left;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }

  svg {
    float: left;
  }
`;
