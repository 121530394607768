import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDesktopSidebar } from '../../Desktop/DesktopSidebar';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../shared/components/Modal';
import { MembersList } from '../MembersList';
import { ChatTranslation } from '../i18n';

export const MembersModal: FC<
  Omit<ModalProps, 'visible' | 'onRequestClose'>
> = props => {
  const { setIdentifier } = useDesktopSidebar();
  const handleCloseRequest = () => setIdentifier(null);

  return (
    <Modal visible fullScreen onRequestClose={handleCloseRequest} {...props}>
      <ModalHeader onRequestClose={handleCloseRequest}>
        <FormattedMessage id={ChatTranslation.sidebarTitle} />
      </ModalHeader>
      <MembersList />
    </Modal>
  );
};
