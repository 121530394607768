import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { useQueryParams } from '../../../../shared/hooks';

import { BillingTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { appEnv } from '../../../../appEnv';
import {
  SuccessUpgradeModalBody,
  SuccessUpgradeModalIllustration,
  SuccessUpgradeModalIntro,
} from './SuccessUpgradeModal.styled';
import UpgradeStarIllustration from '../../../../shared/assets/images/upgrade-star.svg';
import { useBillingTrackingEventOnInit } from '../../tracking/BillingTracking.hooks';
import { getShortId } from '../../../../shared/utils/id';

interface SuccessUpgradeModalProps
  extends Omit<ModalProps, 'visible' | 'onRequestClose'> {}

export const SuccessUpgradeModal: FC<SuccessUpgradeModalProps> = () => {
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canChangeIdentifier },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const onClose = () =>
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        upgradeSuccess: undefined,
      }),
    });

  useBillingTrackingEventOnInit('complete_upgrade');

  if (!canChangeIdentifier) return null;

  return (
    <>
      <Modal visible={true} width={384} onRequestClose={onClose}>
        <ModalHeader onRequestClose={onClose} border={false} />
        <SuccessUpgradeModalBody data-testid="upgrade-success-modal-body">
          <SuccessUpgradeModalIllustration>
            <img src={UpgradeStarIllustration} role="presentation" alt="" />
          </SuccessUpgradeModalIllustration>
          <SuccessUpgradeModalIntro data-testid="upgrade-success-message">
            <FormattedMessage
              id={BillingTranslation.upgradeToProSuccessMessage}
              values={{
                workspaceDomain: appEnv.WORKSPACE_DOMAIN,
              }}
            />
          </SuccessUpgradeModalIntro>
          <Button
            fullWidth={true}
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            data-testid="continue-button"
            onClick={() =>
              navigate(`/workspace/${getShortId(workspace.id)}/admin/company`)
            }>
            <FormattedMessage
              id={BillingTranslation.upgradeToProSuccessContinueButton}
            />
          </Button>
        </SuccessUpgradeModalBody>
      </Modal>
    </>
  );
};
