import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { TooltipPlace } from '../../../../../../../shared/components/Tooltip';
import { IconProps } from '../../../../../../../shared/icons/icon.types';

interface TabBarButtonProps {
  icon: FC<IconProps>;
  identifier: string;
  tooltipText?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const StyledTabBarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  appearance: none;
  border: 0;
  background: transparent;
  padding: unset;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  svg {
    pointer-events: none;
  }
`;

export const TabBarButton = React.forwardRef<
  HTMLButtonElement,
  TabBarButtonProps
>(({ icon: Icon, identifier, tooltipText, children, ...buttonProps }, ref) => {
  return (
    <>
      <StyledTabBarButton
        ref={ref}
        {...buttonProps}
        data-tooltip-place={TooltipPlace.bottom}
        data-tooltip-id="global-tooltip"
        data-tooltip-content={!!tooltipText ? tooltipText : ''}>
        {Icon && <Icon width={16} height={16} />}
      </StyledTabBarButton>
    </>
  );
});
