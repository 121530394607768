import React, { FC, useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import {
  ErrorMessage,
  FormInputLabel,
  FormInputProps,
  StyledFormInput,
} from '../Input';
import { Input as AppInput } from '../Input/Input';
import Input from 'react-phone-number-input/input';

export const FormPhoneInput: FC<FieldProps & FormInputProps> = ({
  field,
  form,
  label,
  className,
  inlineError = false,
  displayErrors = true,
  ...props
}) => {
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  const onValueChange = useCallback(
    (value: string) => {
      form.setFieldValue(field.name, value);
    },
    [field.name, form],
  );

  return (
    <StyledFormInput
      className={className}
      invalid={displayErrors && !!errorIdToDisplay}
      inlineError={inlineError}>
      {label && <FormInputLabel htmlFor={field.name}>{label}</FormInputLabel>}
      <Input
        value={field.value}
        {...props}
        inputComponent={AppInput}
        onChange={onValueChange}
        maxLength="255"
      />
      {displayErrors && errorIdToDisplay && (
        <ErrorMessage inlineError={inlineError}>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormInput>
  );
};
