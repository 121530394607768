import styled, { css } from 'styled-components';

const truncate = css`
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const StyledLinkAccountPreview = styled.div`
  display: flex;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
`;

export const LinkAccountPreviewData = styled.div`
  margin-left: 13px;
`;

export const LinkAccountPreviewName = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 1.5;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  ${truncate};
`;

export const LinkAccountPreviewEmail = styled.div`
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.14px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  ${truncate};
`;
