import React, { useCallback, useRef, useState } from 'react';
import {
  HorizontalAlignment,
  Menu,
  MenuHeading,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../shared/components/Menu';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { StyledUserProfile } from './UserProfile.styled';
import {
  CogIcon,
  InfoQuestionIcon,
  LogoutIcon,
  UserIcon,
} from '../../../shared/icons';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import {
  ADMIN_PREFERENCES_PATHNAME,
  ADMIN_PROFILE_PATHNAME,
} from '../User.constants';
import { UserTranslation } from '../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { AvailabilityStatusType } from '../User.types';
import { AccountTranslation } from '../../Account/i18n';
import { ChatAvatar } from '../../Chat/ChatAvatar';
import { useTheme } from 'styled-components';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { OnlineStatusCircle } from '../../Account/components/OnlineStatus/OnlineStatusCircle';
import { ENTER_KEY } from '../../Keyboard/Keyboard.constants';
import { usePublishPing } from '../../Mercure/Ping/PingMercure.hooks';
import {
  clearStatusValidTimestamps,
  setStatusValidTimestamp,
} from '../../Account/Account.utils';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { DesktopTranslation } from '../../Desktop/i18n';
import { appEnv } from '../../../appEnv';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { WorkspaceTranslation } from '../../Workspace/i18n';
import { sendAccountStatusTrackingEvent } from '../tracking/AccountTracking.utils';
import { useAccountsContext } from '../../Account';
import { getShortId } from '../../../shared/utils/id';
import { LOGOUT_PATHNAME } from '../../Auth/Auth.constants';

const STATUSES: Array<{
  status: AvailabilityStatusType;
  translationKey: AccountTranslation;
}> = [
  {
    status: AvailabilityStatusType.Online,
    translationKey: AccountTranslation.statusOnline,
  },
  {
    status: AvailabilityStatusType.Busy,
    translationKey: AccountTranslation.statusBusy,
  },
  {
    status: AvailabilityStatusType.Invisible,
    translationKey: AccountTranslation.statusInvisible,
  },
];

export const UserProfile = () => {
  const theme = useTheme();
  const [isMenuOpened, setMenuState] = useState(false);
  const userProfileRef = useRef(null);
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();
  const currentWorkspaceAccount = accountsWithAvailability[account.id];

  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const publishPing = usePublishPing();
  const { formatMessage } = useIntl();
  const queryParams = useQueryParams();

  const updateAccountAvailability = useCallback(
    (newStatus: AvailabilityStatusType) => {
      sendAccountStatusTrackingEvent(`set_${newStatus}`);
      switch (newStatus) {
        case AvailabilityStatusType.Busy:
          clearStatusValidTimestamps();
          setStatusValidTimestamp(AvailabilityStatusType.Busy);
          break;
        case AvailabilityStatusType.Invisible:
          clearStatusValidTimestamps();
          setStatusValidTimestamp(AvailabilityStatusType.Invisible);
          break;
        default:
          clearStatusValidTimestamps();
          break;
      }

      publishPing(newStatus);
    },
    [publishPing],
  );

  const { pathname } = useLocation();

  if (!currentWorkspaceAccount) return null;

  const handleMenuOpen = () => {
    setMenuState(true);
  };
  const handleMenuClose = () => {
    setMenuState(false);
  };

  return (
    <StyledUserProfile
      ref={userProfileRef}
      tabIndex={0}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={
        !isMenuOpened
          ? formatMessage({ id: DesktopTranslation.tooltipProfileAndSettings })
          : ''
      }
      data-tooltip-place={TooltipPlace.bottom}
      data-testid="user-profile-button"
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === ENTER_KEY) {
          const targetElement = e.target as HTMLDivElement;
          targetElement.click();
        }
      }}>
      <ChatAvatar
        account={currentWorkspaceAccount}
        avatarSize="1.75rem"
        onlineStatusSize="0.75rem"
        onlineStatusBorderColor={theme.colors.OnlineStatusOfflineBorder}
      />

      <Menu
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
        trigger={userProfileRef}
        width={200}
        maxHeight={360}
        vAlign={isMobile ? VerticalAlignment.TOP : VerticalAlignment.BOTTOM}
        hAlign={
          isMobile ? HorizontalAlignment.RIGHT : HorizontalAlignment.RIGHT
        }
        data-testid="user-profile-menu">
        <MenuHeading>
          <FormattedMessage id={UserTranslation.profileMenuStatusHeading} />
        </MenuHeading>

        {STATUSES.map(({ status, translationKey }) => (
          <MenuItem
            key={status}
            bigIcon={false}
            displayCheckMark={status === currentWorkspaceAccount.onlineStatus}
            isDisabled={status === currentWorkspaceAccount.onlineStatus}
            icon={() => (
              <OnlineStatusCircle onlineStatus={status} circleSize="0.5rem" />
            )}
            onClick={() => updateAccountAvailability(status)}
            data-testid={`user-profile-menu-status-${status}`}>
            <FormattedMessage id={translationKey} />
          </MenuItem>
        ))}

        <MenuSeparator margin="0.25rem 0" />

        <MenuItem
          bigIcon={false}
          icon={() => <UserIcon width={16} height={16} />}
          onClick={() => {
            const profilePath = generatePath(ADMIN_PROFILE_PATHNAME, {
              workspaceId: getShortId(workspace.id),
            });

            navigate(
              {
                pathname: profilePath,
              },
              {
                state: {
                  returnTo: pathname,
                },
              },
            );
          }}
          data-testid="user-profile-menu-profile">
          <FormattedMessage id={UserTranslation.profileMenuMyProfile} />
        </MenuItem>
        <MenuItem
          bigIcon={false}
          icon={() => <CogIcon width={16} height={16} />}
          onClick={() => {
            const preferencesPath = generatePath(ADMIN_PREFERENCES_PATHNAME, {
              workspaceId: getShortId(workspace.id),
            });

            if (isMobile) {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  settingsModal: true,
                }),
              });
            } else {
              navigate(
                {
                  pathname: preferencesPath,
                },
                {
                  state: {
                    returnTo: pathname,
                  },
                },
              );
            }
          }}
          data-testid="user-profile-menu-preferences">
          <FormattedMessage id={UserTranslation.profileMenuPreferences} />
        </MenuItem>

        {appEnv.SUPPORT_URL && (
          <MenuItem
            bigIcon={false}
            icon={() => <InfoQuestionIcon width={16} height={16} />}
            onClick={() => window.open(appEnv.SUPPORT_URL)}
            data-tesitd="user-profile-menu-help-center">
            <FormattedMessage id={UserTranslation.profileMenuHelpCenter} />
          </MenuItem>
        )}

        {!workspace.communicate && (
          <>
            <MenuItem
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    openOnboardingQuickTour: true,
                  }),
                })
              }
              data-testid="quick-start-guide-item">
              <FormattedMessage
                id={WorkspaceTranslation.onboardingQuickStartGuid}
              />
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    forceLatestAnnouncementModal: true,
                  }),
                })
              }
              data-testid="latest-update-item">
              <FormattedMessage
                id={WorkspaceTranslation.onboardingLatestUpdate}
              />
            </MenuItem>
          </>
        )}

        <MenuSeparator margin="0.25rem 0" />

        <MenuItem
          bigIcon={false}
          icon={() => <LogoutIcon width={13} height={14} />}
          onClick={() => {
            navigate({
              pathname: LOGOUT_PATHNAME,
              search: getQueryParamsFrom({
                sendLogoutMessageToOtherTabs: true,
              }),
            });
          }}
          data-testid="user-profile-menu-logout">
          <FormattedMessage id={UserTranslation.profileMenuLogout} />
        </MenuItem>
      </Menu>
    </StyledUserProfile>
  );
};
