import { gql } from '@apollo/client';
import { AssetFragmentFields } from './Asset.fragments';
import { FileAssetApiType, ImageAssetApiType } from './Asset.types';

export const CREATE_FILE_ASSET = gql`
  mutation CreateFileAsset($input: createFileAssetInput!) {
    createFileAsset(input: $input) {
      fileAsset {
        ${AssetFragmentFields}
      }
    }
  }
`;

export interface CreateFileAssetVariables {
  input: {
    file: File;
    workspace?: string;
    desktop?: string | null;
  };
}

export interface CreateFileAssetResponse {
  createFileAsset: {
    fileAsset: FileAssetApiType;
  };
}

export const CREATE_IMAGE_ASSET = gql`
  mutation CreateImageAsset($input: createImageAssetInput!) {
    createImageAsset(input: $input) {
      imageAsset {
        ${AssetFragmentFields}
      }
    }
  }
`;

export interface CreateImageAssetVariables {
  input: {
    file: File;
    workspace?: string;
    desktop?: string | null;
  };
}

export interface CreateImageAssetResponse {
  createImageAsset: {
    imageAsset: ImageAssetApiType;
  };
}
