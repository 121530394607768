import React, { FC, useEffect, useRef } from 'react';
import {
  CarouselImage,
  StyledCarousel,
  StyledCarouselContainer,
  StyledCarouselControl,
  StyledVideoPlayerPreview,
} from '.';
import { ChatMessageAssetApiType } from '../../../domains/Chat/Chat.types';
import Slider from 'react-slick';
import { ArrowLeftIcon } from '../../icons';
import { isVideoMimeType } from '../../../domains/Asset/Asset.utils';
import {
  VideoPlayer,
  VideoPlayerControlsProps,
} from '../../../domains/VideoPlayer';
import { useMobile } from '../../hooks';

interface CarouselProps {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  assets: ChatMessageAssetApiType[];
  initialSlide?: number;
  touchMove?: boolean;
  arrows?: boolean;
  slideChange?: (index: number) => void;
}

export const Carousel: FC<CarouselProps> = ({
  dots = false,
  infinite = false,
  speed = 500,
  slidesToShow = 1,
  slidesToScroll = 1,
  assets,
  initialSlide = 0,
  touchMove = false,
  arrows = true,
  slideChange,
}) => {
  const videoPlayerRef = useRef<VideoPlayerControlsProps>(null);
  const sliderRef = useRef<Slider>(null);
  const isMobile = useMobile();

  const handlePlayer = (index: number) => {
    const isVideo = isVideoMimeType(assets[index].mimeType);

    if (!isVideo) {
      return;
    }

    const player = videoPlayerRef.current;

    if (player) {
      player.load();
      player.enableControls(true);
    }

    if (player && !player.paused()) {
      player.enableControls(false);
      player.unload();
    }
  };

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    handlePlayer(newIndex);

    slideChange?.(newIndex);
  };

  const settings = {
    dots,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    touchMove,
    arrows,
    initialSlide,
    nextArrow: (
      <StyledCarouselControl isMobile={isMobile} icon={ArrowLeftIcon} />
    ),
    prevArrow: (
      <StyledCarouselControl isMobile={isMobile} icon={ArrowLeftIcon} />
    ),
    beforeChange: handleBeforeChange,
  };

  const itemRenderer = (asset: ChatMessageAssetApiType, index: number) => {
    const isVideo = isVideoMimeType(asset.mimeType);

    if (isVideo) {
      return (
        <StyledVideoPlayerPreview key={asset.assetIri} isMobile={isMobile}>
          <VideoPlayer
            ref={videoPlayerRef}
            source={asset.url}
            autoPlay={false}
            controls={true}
            withCustomControls={false}
          />
        </StyledVideoPlayerPreview>
      );
    }

    return (
      <CarouselImage
        key={asset.assetIri}
        url={asset.url}
        filename={asset.filename}
      />
    );
  };

  useEffect(() => {
    if (sliderRef.current && initialSlide > 0) {
      sliderRef.current.slickGoTo(initialSlide, true);
    }
  }, [initialSlide]);

  return (
    <StyledCarousel data-testid="carousel">
      <StyledCarouselContainer>
        <Slider ref={sliderRef} {...settings} lazyLoad="progressive">
          {assets.map((asset, index) => itemRenderer(asset, index))}
        </Slider>
      </StyledCarouselContainer>
    </StyledCarousel>
  );
};
