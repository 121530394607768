import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkspaceTranslation } from '../../i18n';
import {
  SignUpFlow,
  SignUpFlowPath,
} from '../../../Onboarding/Onboarding.types';
import { AccountApiType } from '../../../User/User.types';
import {
  LinkAccountOption,
  LinkAccountOptionButton,
  LinkAccountOptionHeading,
  LinkAccountOptions,
} from '../WorkspacesList/LinkAccountModal/LinkAccountModal.styled';
import { LinkAccountPlanSelect } from '../WorkspacesList/LinkAccountModal/LinkAccountPlanSelect';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useWorkspacesQuery } from '../../Workspace.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { useWorkspaceApiRepository } from '../../Data/Repository/WorkspaceApiRepository';
import { useAccountApiRepository } from '../../../Account/data/CurrentAccount/CurrentAccount.repositories';

export const EmptyWorkspaceCreation: FC = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { createLinkedAccountWorkspace } = useWorkspaceApiRepository();
  const { account, updateAccount } = useCurrentAccount();
  const [linkNewAccountFlow, setLinkNewAccountFlow] = useState(
    SignUpFlowPath.PRO,
  );
  const { getAccountWorkspaceInfo } = useAccountApiRepository();

  const { refetch } = useWorkspacesQuery({
    skip: !account,
    variables: {
      id: account!.identityId,
    },
  });

  const handleNewWorkspaceClick = useCallback(() => {
    setButtonDisabled(true);
    const signUpFlowType =
      linkNewAccountFlow === SignUpFlowPath.PRO
        ? SignUpFlow.PRO
        : SignUpFlow.FREE;

    createLinkedAccountWorkspace(signUpFlowType)
      .then((account: AccountApiType | null) => {
        if (account) {
          getAccountWorkspaceInfo().then(workspaceInfo => {
            updateAccount(() => ({
              ...account,
              workspaceInfo,
            }));

            refetch();
          });
        }
      })
      .catch(err => {
        showToastGraphQLErrors(err);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  }, [
    createLinkedAccountWorkspace,
    getAccountWorkspaceInfo,
    linkNewAccountFlow,
    refetch,
    updateAccount,
  ]);

  return (
    <LinkAccountOptions>
      <LinkAccountOption>
        <LinkAccountOptionHeading>
          <FormattedMessage
            id={WorkspaceTranslation.createLinkedUserWorkspaceAccountPlan}
            tagName="div"
          />
        </LinkAccountOptionHeading>

        <LinkAccountPlanSelect
          checked={linkNewAccountFlow}
          onChange={setLinkNewAccountFlow}
        />

        <LinkAccountOptionButton
          mode={ButtonMode.primary}
          size={ButtonSize.md}
          onClick={handleNewWorkspaceClick}
          disabled={buttonDisabled}
          data-testid="linked-account-create-new-workspace-button">
          <FormattedMessage
            id={WorkspaceTranslation.linkAccountNewButton}
            tagName="span"
          />
        </LinkAccountOptionButton>
      </LinkAccountOption>
    </LinkAccountOptions>
  );
};
