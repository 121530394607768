import React, { FC, useState } from 'react';
import { CustomIntegrationField } from '../../CustomIntegrationView.styled';
import {
  ImageUploadButton,
  ImageUploadLabel,
  NewCustomIntegrationFormImage,
} from '../CustomIntegrationBot.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { FormInput } from '../../../../../../../shared/components/Input';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../../shared/components/Button/Button';
import { IntegrationClientFullApiType } from '../../../../../AppIntegrations.types';
import { ImageUpload, ImageUploadBody } from '../../../../../../Image';
import UploadImagePlaceholder from '../../../../../../../shared/assets/images/image-upload.svg';
import { AppIntegrationsTranslation } from '../../../../../i18n';

interface IntegrationBotFieldsProps {
  integrationClient?: IntegrationClientFullApiType;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

export const CustomIntegrationBotFields: FC<IntegrationBotFieldsProps> = ({
  integrationClient,
  setFieldValue,
  errors,
  touched,
}) => {
  const intl = useIntl();
  const [imageUrl, setImageUrl] = useState<string>(
    integrationClient?.bot.image?.contentUrl || '',
  );

  return (
    <>
      <CustomIntegrationField>
        <Field
          name="botName"
          type="text"
          data-testid="bot-name-input"
          placeholder={intl.formatMessage({
            id:
              AppIntegrationsTranslation.appCustomIntegrationBotNamePlaceholder,
          })}
          label={intl.formatMessage({
            id: AppIntegrationsTranslation.appCustomIntegrationBotNameLabel,
          })}
          component={FormInput}
        />
      </CustomIntegrationField>

      <CustomIntegrationField>
        <ImageUploadLabel htmlFor="integration-bot-image-upload">
          <FormattedMessage
            id={AppIntegrationsTranslation.appCustomIntegrationBotImageLabel}
          />
        </ImageUploadLabel>

        <ImageUpload
          id="integration-bot-image-upload"
          onUpload={({ id, contentUrl }) => {
            setFieldValue('botImage', id);
            setImageUrl(contentUrl);
          }}>
          <ImageUploadBody>
            <NewCustomIntegrationFormImage
              src={imageUrl || UploadImagePlaceholder}
              invalid={!!errors.botImage && !!touched.botImage}
              data-testid="integration-bot-image"
            />
            <ImageUploadButton size={ButtonSize.sm} mode={ButtonMode.secondary}>
              <FormattedMessage
                id={
                  AppIntegrationsTranslation.appCustomIntegrationBotImageButton
                }
              />
            </ImageUploadButton>
          </ImageUploadBody>
        </ImageUpload>
      </CustomIntegrationField>
    </>
  );
};
