import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useState,
} from 'react';
import { TimelineDesktopPopoverContext } from '../../Timeline.context';
import { TimelineDesktopPopover } from '.';
import type { DesktopApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

export const TimelineDesktopPopoverProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [popoverData, setPopoverData] = useState<{
    desktop: DesktopApiType;
    domRef: RefObject<HTMLElement>;
  }>();

  const showTimelineDesktopPopover = useCallback(
    (desktop: DesktopApiType, domRef: RefObject<HTMLElement>) => {
      setPopoverData({
        desktop,
        domRef,
      });
    },
    [],
  );

  const hideTimelineDesktopPopover = useCallback(() => {
    setPopoverData(undefined);
  }, []);

  return (
    <TimelineDesktopPopoverContext.Provider
      value={{ showTimelineDesktopPopover, hideTimelineDesktopPopover }}>
      {children}

      {popoverData && (
        <TimelineDesktopPopover
          triggerRef={popoverData.domRef}
          onRequestClose={hideTimelineDesktopPopover}
          desktop={popoverData.desktop}
        />
      )}
    </TimelineDesktopPopoverContext.Provider>
  );
};
