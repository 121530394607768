import styled, { css } from 'styled-components';
import { PositionType } from './CountBadge';

interface StyledCountBadgeProps {
  top: number;
  right: number;
  color?: string;
  borderColor?: string;
  positionMode?: PositionType;
}

export const StyledCountBadge = styled.div<StyledCountBadgeProps>`
  position: ${({ positionMode }) => positionMode};
  background-color: ${p => p.theme.colors.CountBadgeBackground};
  border-radius: 50%;
  min-width: 1rem;
  height: 1rem;
  color: ${p => p.theme.colors.CountBadge};
  font-size: 0.625rem;
  line-height: 1.25rem; // twice the font-size
  font-family: ${({ theme }) => theme.fonts.Semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
    `}
`;
