import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../../../../../../shared/components/Spinner';

const StyledSectionLoading = styled.div`
  padding: 2rem 0;
`;

export const SectionLoading = () => {
  return (
    <StyledSectionLoading>
      <Spinner />
    </StyledSectionLoading>
  );
};
