import React from 'react';
import {
  BroadcastChannelMessage,
  BroadcastChannelMessageType,
} from './BroadcastChannel.types';

export interface BroadcastChannelContextType {
  postMessage: (type: BroadcastChannelMessageType) => void;
  subscribe: (
    type: BroadcastChannelMessageType,
    callback: (data?: BroadcastChannelMessage) => void,
  ) => void;
}

export const BroadcastChannelContext = React.createContext<BroadcastChannelContextType>(
  {
    postMessage: () => {},
    subscribe: () => {},
  },
);
