import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useDeleteVaultItemMutation,
  useUpdateVaultItemMutation,
  useVaultsListUpdate,
  useWorkspaceVaultById,
} from '../../Encryption.hooks';
import {
  getAccountKeyset,
  getVaultAccess,
  getVaultApp,
  getVaultItem,
} from '../../Encryption.utils';
import { addProtocol } from '../../../../shared/utils/url.utils';
import { MasterPasswordLockScreen } from '../../MasterPasswordLockScreen';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useWorkspaceOwnerAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  VaultApiType,
  VaultItemApiType,
  VaultItemFormValues,
  VaultItemType,
} from '../../Encryption.types';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { EncryptionTranslation } from '../../i18n';
import { AccountApiType } from '../../../User/User.types';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { FormattedMessage } from 'react-intl';
import { displayInstallPluginToast } from '../../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { VaultItemForm } from '../VaultItemForm';
import {
  decryptVaultAccessKey,
  decryptVaultItem,
  encryptVaultItem,
} from '../../Encryption.crypto.utils';
import { captureException } from '../../../ErrorInterceptor';

interface EditVaultItemProps {
  vaultId: string;
  onDone?: () => void;
  appId?: string;
}

export const EditVaultItem: FC<EditVaultItemProps> = ({
  vaultId,
  onDone,
  appId,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { keyset } = useCurrentAccountKeyset();
  const { vault } = useWorkspaceVaultById(vaultId);
  const vaultItem = useMemo(() => getVaultItem(vault as VaultApiType), [vault]);
  const vaultApp = useMemo(() => getVaultApp(vault as VaultApiType), [vault]);
  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keyset.id);
  }, [vault, keyset]);
  const vaultAccessKey = vaultAccess?.vaultKeyEncrypted;
  const vaultAccessShared = vaultAccess?.shared;

  const [decryptedVaultItem, setDecryptedVaultItem] = useState<
    VaultItemApiType | undefined
  >();

  const { workspaceOwnerAccount, workspaceOwnerAccountLoading } =
    useWorkspaceOwnerAccount(workspace?.id);
  const workspaceOwnerKeyset = useMemo(() => {
    return getAccountKeyset(workspaceOwnerAccount as AccountApiType);
  }, [workspaceOwnerAccount]);
  const displayOwnerNoSharingWarning =
    workspaceOwnerAccount?.id === account?.id;

  useEffect(() => {
    if (privateKey && vaultItem && vaultAccessKey && !decryptedVaultItem) {
      decryptVaultAccessKey(privateKey, vaultAccessKey)
        .then(decryptedVaultAccessKey => {
          return Promise.all([
            decryptVaultItem(decryptedVaultAccessKey, vaultItem.login),
            decryptVaultItem(decryptedVaultAccessKey, vaultItem.password),
          ]);
        })
        .then(([decryptedLogin, decryptedPassword]) => {
          setDecryptedVaultItem({
            ...vaultItem,
            login: decryptedLogin,
            password: decryptedPassword,
          });
        });
    }
  }, [decryptedVaultItem, privateKey, vaultItem, vaultAccessKey]);

  const [updateVaultItemMutation] = useUpdateVaultItemMutation();
  const [deleteVaultItemMutation] = useDeleteVaultItemMutation();
  const updateVaultItem = useCallback(
    (
      id: string,
      encryptedLogin: string,
      encryptedPassword: string,
      image?: string,
      url?: string,
    ) => {
      return updateVaultItemMutation({
        variables: {
          input: {
            id,
            login: encryptedLogin,
            password: encryptedPassword,
            ...(image ? { image } : {}),
            ...(url ? { url } : {}),
          },
        },
      }).then(
        res => {
          if (res?.data) {
            if (onDone) {
              onDone();
            }
            showToastSuccessMessage(
              EncryptionTranslation.editVaultFormSuccessMessage,
            );
            displayInstallPluginToast();
          }
        },
        e => {
          showToastGraphQLErrors(e.graphQLErrors);
        },
      );
    },
    [updateVaultItemMutation, onDone],
  );
  const { removeVault } = useVaultsListUpdate();
  const deleteVaultItem = useCallback(
    (id: string) => {
      return deleteVaultItemMutation({
        variables: {
          input: {
            id,
          },
        },
      }).then(
        res => {
          if (res?.data) {
            if (vault?.id) {
              removeVault(vault.id);
            }
            if (onDone) {
              onDone();
            }
            showToastSuccessMessage(
              EncryptionTranslation.editVaultFormSuccessMessage,
            );
            displayInstallPluginToast();
          }
        },
        e => {
          showToastGraphQLErrors(e.graphQLErrors);
        },
      );
    },
    [deleteVaultItemMutation, onDone, removeVault, vault],
  );

  // TODO: split into smaller function, optimize dependencies
  const handleSubmit = useCallback(
    ({ login, password, image, url }: VaultItemFormValues) => {
      if (!vaultAccessKey) {
        return null;
      }
      if (!login && !password) {
        return deleteVaultItem(vaultItem.id);
      }
      return decryptVaultAccessKey(privateKey, vaultAccessKey)
        .then(decryptedVaultAccessKey => {
          return Promise.all([
            encryptVaultItem(decryptedVaultAccessKey, login),
            encryptVaultItem(decryptedVaultAccessKey, password),
          ]);
        })
        .then(
          ([encryptedLogin, encryptedPassword]) => {
            return updateVaultItem(
              vaultItem.id,
              encryptedLogin,
              encryptedPassword,
              image,
              addProtocol(url),
            );
          },
          e => {
            captureException(e);
            showToastErrorMessage(
              EncryptionTranslation.editVaultFormEncryptionErrorMessage,
            );
          },
        );
    },
    [privateKey, updateVaultItem, deleteVaultItem, vaultAccessKey, vaultItem],
  );

  if (workspaceOwnerAccountLoading) {
    return <Spinner containerHeight={120} />;
  }

  if (!vault && !workspaceOwnerKeyset) {
    return (
      <ImportantMessage type={ImportantMessageType.WARNING}>
        <FormattedMessage
          id={EncryptionTranslation.editVaultOwnerHasNoKeysetErrorMessage}
        />
      </ImportantMessage>
    );
  }

  if (!privateKey) {
    return (
      <MasterPasswordLockScreen
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  if (vaultItem && vaultAccessKey && !decryptedVaultItem) {
    return <Spinner containerHeight={200} />;
  }

  // Still loading or decrypting vaults
  if (vaultId && !vaultItem) {
    return <Spinner containerHeight={200} />;
  }

  return (
    <VaultItemForm
      decryptedVaultItem={decryptedVaultItem}
      displayOwnerNoSharingWarning={displayOwnerNoSharingWarning}
      displayUrlField={vaultItem?.type === VaultItemType.manual}
      displayImageField={!vaultApp}
      disabled={vaultAccessShared}
      onSubmit={handleSubmit}
      appId={appId}
    />
  );
};
