import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalAnimation,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { AppStore } from '../../Views/AppStore';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';

export const AppStoreModal: FC<
  Omit<ModalProps, 'visible' | 'onRequestClose'>
> = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const desktop = useCurrentDesktop();

  const onRequestClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        appStoreModal: undefined,
        editAppId: undefined,
        tabId: undefined,
        createCustomAppModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <Modal
      visible={true}
      width={1024}
      fullScreen={isMobile}
      animation={isMobile ? ModalAnimation.slideUp : ModalAnimation.fadeIn}
      onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage
          id={DesktopTranslation.appStoreModalHeaderTitle}
          values={{ desktopName: desktop?.name ?? '' }}
        />
      </ModalHeader>
      <AppStore />
    </Modal>
  );
};
