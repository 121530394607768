import React, { FC, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../shared/components/Table';
import { Cell, CellProps, Column, useTable } from 'react-table';
import { PdfLink } from './PdfLink';
import { useIntl } from 'react-intl';
import { BillingTranslation } from '../../i18n';
import { InvoiceTableDataType } from '../../Billing.types';

interface InvoicesTableProps {
  data: Array<InvoiceTableDataType>;
}

export const InvoicesTable: FC<InvoicesTableProps> = ({ data }) => {
  const { formatMessage } = useIntl();
  const columns: Array<Column<any>> = useMemo(
    () => [
      {
        Header: formatMessage({ id: BillingTranslation.adminDateTableHeader }),
        accessor: 'date',
      },
      {
        Header: formatMessage({
          id: BillingTranslation.adminPaymentMethodTableHeader,
        }),
        accessor: 'paymentmethod',
      },
      {
        Header: formatMessage({
          id: BillingTranslation.adminAmountTableHeader,
        }),
        accessor: 'amount',
      },
      {
        Header: formatMessage({ id: BillingTranslation.adminPDFTableHeader }),
        accessor: 'pdf',
        Cell: (props: CellProps<Cell>) => <PdfLink {...props} />,
      },
    ],
    [formatMessage],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeader {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
