import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../../../../Billing/i18n';
import {
  LinkAccountPlanSelectLabel,
  LinkAccountPlanSelectLabelPrice,
  LinkAccountPlanSelectLabelPriceCaption,
  LinkAccountPlanSelectLabelText,
  StyledLinkAccountPlanSelect,
} from './LinkAccountPlanSelect.styled';
import { PAYMENT_PLAN_COST } from '../../../../../Billing/Billing.constants';
import { PaymentPlan } from '../../../../../Billing/Billing.types';
import { WorkspaceTranslation } from '../../../../i18n';
import { SignUpFlowPath } from '../../../../../Onboarding/Onboarding.types';

interface LinkAccountPlanSelectProps {
  checked: SignUpFlowPath;
  onChange: (flow: SignUpFlowPath) => void;
}

export const LinkAccountPlanSelect: FC<LinkAccountPlanSelectProps> = ({
  checked,
  onChange,
}) => {
  return (
    <StyledLinkAccountPlanSelect>
      <LinkAccountPlanSelectLabel
        htmlFor="link-account-free"
        data-testid="link-account-free-label"
        highlighted={checked === SignUpFlowPath.FREE}>
        <input
          type="radio"
          id="link-account-free"
          name="link-account-flow"
          checked={checked === SignUpFlowPath.FREE}
          value={SignUpFlowPath.FREE}
          onChange={e => onChange(e.target.value as SignUpFlowPath)}
        />
        <LinkAccountPlanSelectLabelText>
          <FormattedMessage
            id={BillingTranslation.planFreeTitle}
            tagName="span"
          />
        </LinkAccountPlanSelectLabelText>
      </LinkAccountPlanSelectLabel>

      <LinkAccountPlanSelectLabel
        htmlFor="link-account-pro"
        data-testid="link-account-pro-label"
        highlighted={checked === SignUpFlowPath.PRO}>
        <input
          type="radio"
          id="link-account-pro"
          name="link-account-flow"
          checked={checked === SignUpFlowPath.PRO}
          value={SignUpFlowPath.PRO}
          onChange={e => onChange(e.target.value as SignUpFlowPath)}
        />
        <LinkAccountPlanSelectLabelText>
          <FormattedMessage
            id={BillingTranslation.planProTitle}
            tagName="span"
          />
          <LinkAccountPlanSelectLabelPrice>
            <FormattedHTMLMessage
              id={WorkspaceTranslation.linkAccountExistingPlanCost}
              values={{ cost: PAYMENT_PLAN_COST[PaymentPlan.PRO] }}
            />
            <LinkAccountPlanSelectLabelPriceCaption>
              <FormattedHTMLMessage
                id={WorkspaceTranslation.linkAccountExistingPlanPerUserCaption}
              />
            </LinkAccountPlanSelectLabelPriceCaption>
          </LinkAccountPlanSelectLabelPrice>
        </LinkAccountPlanSelectLabelText>
      </LinkAccountPlanSelectLabel>

      {/* <LinkAccountPlanSelectLabel
        htmlFor="link-account-enterprise"
        data-testid="link-account-enterprise-label"
        highlighted={checked === SignUpFlowPath.ENTERPRISE}>
        <input
          type="radio"
          id="link-account-enterprise"
          name="link-account-flow"
          checked={checked === SignUpFlowPath.ENTERPRISE}
          value={SignUpFlowPath.ENTERPRISE}
          onChange={e => onChange(e.target.value as SignUpFlowPath)}
        />
        <LinkAccountPlanSelectLabelText>
          <FormattedMessage
            id={BillingTranslation.planEnterpriseTitle}
            tagName="span"
          />
          <LinkAccountPlanSelectLabelPrice>
            <FormattedHTMLMessage
              id={WorkspaceTranslation.linkAccountExistingPlanCost}
              values={{ cost: PAYMENT_PLAN_COST[PaymentPlan.ENTERPRISE] }}
            />
          </LinkAccountPlanSelectLabelPrice>
        </LinkAccountPlanSelectLabelText>
      </LinkAccountPlanSelectLabel> */}
    </StyledLinkAccountPlanSelect>
  );
};
