import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import {
  ChatConversationInternalType,
  ConversationsSortedByGroups,
  ConversationType,
} from '../Chat.types';
import { matchPath, useLocation } from 'react-router-dom';
import { CHATS_CONVERSATION_PATHNAME } from '../../Desktop/Desktop.constants';

export const useSortedConversationsLists = (
  chatConversations: ChatConversationInternalType[],
  searchValueExist: boolean,
): ConversationsSortedByGroups => {
  const pinnedConversations = useMemo(
    () =>
      _orderBy(
        chatConversations?.filter(chat => chat.pinnedAt && !chat.isHidden),
        ['pinnedAt'],
        ['asc'],
      ).reverse(),
    [chatConversations],
  );

  const regularConversations = useMemo(
    () =>
      _orderBy(
        chatConversations
          .filter(chat => {
            if (searchValueExist) {
              return !chat.pinnedAt;
            }

            return !chat.pinnedAt && !chat.isHidden;
          })
          // Reason for this is that lastMessageAt can be undefined and sorting works not correct
          .map(chat => {
            return {
              ...chat,
              sortDate: chat.lastMessageAt || chat.createdAt,
            };
          }),
        ['sortDate'],
        ['desc'],
      ),
    [chatConversations, searchValueExist],
  );

  const privateConversations = useMemo(
    () =>
      _orderBy(
        regularConversations
          .filter(chat =>
            [
              ConversationType.private,
              ConversationType.group,
              ConversationType.pending,
            ].includes(chat.type),
          )
          // Reason for this is that lastMessageAt can be undefined and sorting works not correct
          .map(chat => {
            return {
              ...chat,
              sortDate: chat.lastMessageAt || chat.createdAt,
            };
          }),
        ['sortDate'],
        ['desc'],
      ),
    [regularConversations],
  );

  const desktopConversations = useMemo(
    () =>
      _orderBy(
        regularConversations
          .filter(chat => chat.type === ConversationType.desktop)
          // Reason for this is that lastMessageAt can be undefined and sorting works not correct
          .map(chat => {
            return {
              ...chat,
              sortDate: chat.lastMessageAt || chat.createdAt,
            };
          }),
        ['sortDate'],
        ['desc'],
      ),
    [regularConversations],
  );

  const appIntegrationConversations = useMemo(
    () =>
      _orderBy(
        regularConversations
          .filter(chat => chat.type === ConversationType.appIntegration)
          // Reason for this is that lastMessageAt can be undefined and sorting works not correct
          .map(chat => {
            return {
              ...chat,
              sortDate: chat.lastMessageAt || chat.createdAt,
            };
          }),
        ['sortDate'],
        ['desc'],
      ),
    [regularConversations],
  );

  return useMemo(
    () => ({
      pinned: pinnedConversations,
      private: privateConversations,
      desktop: desktopConversations,
      appIntegrations: appIntegrationConversations,
    }),
    [
      appIntegrationConversations,
      desktopConversations,
      pinnedConversations,
      privateConversations,
    ],
  );
};

export const useCurrentConversationIdFromUrl = (): undefined | string => {
  const { pathname } = useLocation();

  return useMemo(() => {
    const match = matchPath(CHATS_CONVERSATION_PATHNAME, pathname);

    if (!match) {
      return undefined;
    }

    const { conversationId } = match.params;
    return conversationId;
  }, [pathname]);
};
