import styled from 'styled-components';

export const StyledSectionTitle = styled.div`
  margin-top: 2.25rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;

  .title {
    font-size: 1rem;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    font-family: ${p => p.theme.fonts.Semibold};
  }

  .subtitle {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  }
`;
