import React from 'react';
import { SelectedLinksMap } from './DesktopItemMultiselect.types';

interface DesktopItemMultiselectContextType {
  selectedLinkMap: SelectedLinksMap;
  toggleSelectedLink: (desktopId: string, linkId: string) => void;
  selectMultipleDesktopLinks: (desktopId: string, linkIds: string[]) => void;
  unselectAllDesktopLinks: (desktopId: string) => void;
}

export const DesktopItemMultiselectContext =
  React.createContext<DesktopItemMultiselectContextType>({
    selectedLinkMap: {},
    toggleSelectedLink: () => {},
    selectMultipleDesktopLinks: () => {},
    unselectAllDesktopLinks: () => {},
  });
