import {
  ImageOrientationSize,
  ImageOrientationType,
  LANDSCAPE_WIDTH,
  MULTIPLE_IMAGE_HEIGHT,
  MULTIPLE_LANDSCAPE_WIDTH,
  MULTIPLE_PORTRAIT_WIDTH,
  PORTRAIT_HEIGHT,
} from '.';
import { getImageSrcSet } from '../../../../../../../shared/utils/url.utils';

export const imageDimension = (dimensions: string): ImageOrientationSize => {
  return {
    width: Number(dimensions?.split(',')[0]),
    height: Number(dimensions?.split(',')[1]),
  };
};

export const imageOrientation = (dimensions: string): ImageOrientationType => {
  const imageSize = imageDimension(dimensions);

  if (imageSize.width > imageSize.height) {
    return ImageOrientationType.Landscape;
  }

  if (imageSize.width < imageSize.height) {
    return ImageOrientationType.Portrait;
  }

  if (imageSize.width === imageSize.height) {
    return ImageOrientationType.Square;
  }

  return ImageOrientationType.Unidentified;
};

export const imageWidthByOrientation = (
  orientation: ImageOrientationType,
  multiple: boolean,
  dimensions: string,
) => {
  if (orientation === ImageOrientationType.Square) {
    const imageSize = imageDimension(dimensions);
    return imageSize.width;
  }

  return multiple ? MULTIPLE_LANDSCAPE_WIDTH : LANDSCAPE_WIDTH;
};

export const imageWidthCalc = (
  imageSize: ImageOrientationSize,
  orientation: ImageOrientationType,
) => {
  if (orientation !== ImageOrientationType.Square) {
    return Math.round(imageSize.width / (imageSize.height / PORTRAIT_HEIGHT));
  }

  return imageSize.width;
};

export const imageHeighthCalc = (
  imageSize: ImageOrientationSize,
  orientation: ImageOrientationType,
  multiple: boolean,
  dimensions: string,
) => {
  const width = imageWidthByOrientation(orientation, multiple, dimensions);

  return Math.round(imageSize.height / (imageSize.width / width));
};

const protraitSize = (
  multiple: boolean,
  dimensions: string,
  orientation: ImageOrientationType,
): ImageOrientationSize => {
  const imageSize = imageDimension(dimensions);

  const width = imageWidthCalc(imageSize, orientation);

  return {
    width: multiple ? MULTIPLE_PORTRAIT_WIDTH : width,
    height: multiple ? MULTIPLE_IMAGE_HEIGHT : PORTRAIT_HEIGHT,
  };
};

export const imageSrcSet = (
  orientation: ImageOrientationType,
  multiple: boolean,
  assetUrl: string,
  dimensions: string,
  mobile: boolean = false,
) => {
  if (orientation === ImageOrientationType.Portrait) {
    const portraitSize = protraitSize(multiple, dimensions, orientation);
    return getImageSrcSet(assetUrl, portraitSize.width, portraitSize.height);
  }

  return getImageSrcSet(
    assetUrl,
    imageWidthByOrientation(orientation, multiple, dimensions),
  );
};
