import React, { FC, useCallback, useMemo } from 'react';
import { ListItemContent } from '../../../../shared/components/List';
import { DeleteIcon } from '../../../../shared/icons';
import {
  AdminList,
  AdminListEmptyMessage,
  AdminListItem,
  RelatedItemDeleteButton,
} from '../../../Admin';
import type { DesktopAccessType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import type { TeamApiType } from '../../data/Team/types/Team.types';
import { AccessApiType } from '../../../Access/data/types/Access.types';
import { createTeamAccessMap } from '../../../Access/data/utils/Access.utils';
import { ListItemMenu } from '../../../Desktop/ShareDesktopPopover/MembersList/ListItemMenu';

interface RelatedTeamsListProps {
  emptyMessage: string;
  teams: TeamApiType[];
  desktopAccesses?: AccessApiType[];
  hideSystemTeamsRemoveButtons?: boolean;
  onEditTeamAccess?: (accessId: string, accessType: DesktopAccessType) => void;
  onRemove: (team: TeamApiType) => void;
}

export const RelatedTeamsList: FC<RelatedTeamsListProps> = ({
  emptyMessage,
  teams,
  desktopAccesses = [],
  hideSystemTeamsRemoveButtons,
  onEditTeamAccess,
  onRemove,
}) => {
  const teamAccessesMap = useMemo(() => {
    return createTeamAccessMap(desktopAccesses);
  }, [desktopAccesses]);

  const handleRemove = useCallback(
    (team: TeamApiType) => {
      if (onRemove && team) {
        onRemove(team);
      }
    },
    [onRemove],
  );

  if (!teams.length) {
    return <AdminListEmptyMessage>{emptyMessage}</AdminListEmptyMessage>;
  }

  return (
    <AdminList data-testid="admin-list">
      {teams.map(team => (
        <AdminListItem key={team.id}>
          <ListItemContent title={team.name} subtitle={team.description}>
            {(!hideSystemTeamsRemoveButtons ||
              (hideSystemTeamsRemoveButtons && !team.system)) &&
              !onEditTeamAccess && (
                <RelatedItemDeleteButton
                  icon={DeleteIcon}
                  data-testid="delete-button"
                  onClick={() => handleRemove(team)}
                />
              )}
            {teamAccessesMap[team.id]?.[0] && onEditTeamAccess && (
              <ListItemMenu
                accessType={teamAccessesMap[team.id]?.[0].accessType}
                onEdit={value =>
                  onEditTeamAccess(teamAccessesMap[team.id]?.[0].id, value)
                }
                onDelete={() => handleRemove(team)}
              />
            )}
          </ListItemContent>
        </AdminListItem>
      ))}
    </AdminList>
  );
};
