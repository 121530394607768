import styled from 'styled-components';
import { Breakpoints } from '../../Theme';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const LinkField = styled.div`
  & ~ & {
    margin-top: 16px;
  }
`;

export const FormControls = styled.div`
  display: flex;
  margin-top: 20px;

  button ~ button {
    margin-left: 12px;
  }
`;

export const FlexSeparator = styled.div`
  flex: 1 1 100%;
`;

export const AvatarBoxWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.sm`
    margin-right: 25px;
  `}
`;

export const StyledAvatarBox = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    flex-direction: column;

    button {
      margin-left: 0;
      margin-top: 15px;
    }
  `}

  @media (max-width: ${Breakpoints.sm - 1}px) {
    margin-bottom: 20px;
  }
`;

export const FormFieldsWrapper = styled.div`
  padding-top: 20px;

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    display: flex;
  `}
`;

export const LinkFieldsGroup = styled.div`
  width: 100%;
`;

export const TagButton = styled(ButtonWithIcon)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }
`;
