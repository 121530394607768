import { TimelineDayType, TimelineEventApiType } from './Timeline.types';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import { formatISO, startOfDay } from 'date-fns';

export const sortDaysByDate = (a: TimelineDayType, b: TimelineDayType) => {
  if (a.date === b.date) {
    return 0;
  }
  return a.date < b.date ? 1 : -1;
};

export const groupEventsByDay = (
  events: TimelineEventApiType[] | undefined,
): TimelineDayType[] => {
  if (!events || !events?.length) {
    return [];
  }

  const eventsMap: {
    [key: string]: TimelineEventApiType[];
  } = _groupBy(events, item => {
    const date = startOfDay(new Date(item.occurredAt));
    return formatISO(date);
  });
  const timelineDaysList: TimelineDayType[] = _map(
    eventsMap,
    (events, date) => ({
      events,
      date,
    }),
  );

  return timelineDaysList.sort(sortDaysByDate);
};
