import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { EditCustomApp } from '../../Forms/CustomAppForm/EditCustomApp';
import { AppStoreTranslation } from '../../i18n';
import { CreateCustomAppModalBody } from './EditCustomAppModal.styled';

export const EditAppModal: FC<Pick<ModalProps, 'containerZIndex'>> = ({
  ...modalProps
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const onRequestClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editAppId: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <Modal
      visible={true}
      width={486}
      {...modalProps}
      onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={AppStoreTranslation.editCustomAppModalHeader} />
      </ModalHeader>
      <CreateCustomAppModalBody>
        <EditCustomApp onDone={onRequestClose} />
      </CreateCustomAppModalBody>
    </Modal>
  );
};
