import { LinkVideoMetaData } from './../../Link/Link.types';
import { OriginalVideoMetaData, VideoMetaData } from '../../Asset/Asset.types';
import { ChatMessageAssetApiType } from '../../Chat/Chat.types';
import { LinkApiType, LinkDocumentMetaData } from '../../Link/Link.types';
import { DocumentType } from './DocumentPreview.types';

export const getLinkMetaType = (link?: LinkApiType) =>
  link?.data?.metadata && 'type' in link.data.metadata
    ? link.data.metadata.type
    : null;

export const linkHasMetaPreviewUrl = (link?: LinkApiType) =>
  link?.data?.metadata &&
  (link.data.metadata as LinkDocumentMetaData).preview_url;

export const linkHasAuthUrl = (link?: LinkApiType) =>
  link?.data?.metadata &&
  (link.data.metadata as LinkDocumentMetaData).authorization_url;

export const linkHasMetaEmbedLink = (link?: LinkApiType) =>
  link?.data?.metadata && (link.data.metadata as LinkVideoMetaData).embed_link;

export const getEntityMetadataType = (
  entity?: LinkApiType | ChatMessageAssetApiType,
): DocumentType => {
  if (!entity) return DocumentType.COMMON;

  const metaData =
    (entity as LinkApiType)?.data?.metadata ||
    (entity as ChatMessageAssetApiType)?.metadata;

  if (!metaData) return DocumentType.COMMON;

  return (metaData as LinkDocumentMetaData)?.type;
};

export const getEntityMetadata = (
  entity?: LinkApiType | ChatMessageAssetApiType | null,
):
  | LinkDocumentMetaData
  | LinkVideoMetaData
  | VideoMetaData
  | OriginalVideoMetaData
  | null => {
  if (!entity) return null;

  const metaData =
    (entity as LinkApiType)?.data?.metadata ||
    (entity as ChatMessageAssetApiType)?.metadata;

  if (!metaData) return null;

  return metaData;
};

export const isDocumentMimeType = (mimeType: string) => {
  return Object.values(DocumentType).some((docType: string) => {
    return docType.includes(mimeType);
  });
};
