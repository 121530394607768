import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalControls } from '../../../../../../shared/components/Modal/Modal.hooks';
import { useConfirm } from '../../../../../../shared/components/Modal';
import {
  RelatedDesktopsList,
  Section,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../../Admin';
import { getListChanges } from '../../../../../../shared/utils/list.utils';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import { getAccountName } from '../../../../User.utils';
import { UserTranslation } from '../../../../i18n';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import type { DesktopApiType } from '../../../../../Desktop/data/Desktop/types/Desktop.types';
import { DesktopAccessType } from '../../../../../Desktop/data/Desktop/types/Desktop.types';
import { ManageRelatedDesktopsModal } from '../../../../UserModals/ManageRelatedDesktopsModal';
import { AccountApiType } from '../../../../User.types';
import {
  addRelatedDesktopsToAccountsIDDB,
  removeRelatedDesktopsFromAccountsIDDB,
} from '../../../../../Account/data/DesktopAccounts/operations/DesktopAccountIDDB.operations';
import {
  addRelatedAccountsToDesktopsIDDB,
  removeRelatedAccountsFromDesktopsIDDB,
} from '../../../../../Desktop/data/AccountDesktop/operations/AccountDesktop.operations';
import { AccessApiType } from '../../../../../Access/data/types/Access.types';
import { useAccessActions } from '../../../../../Access/data/Access.actions';

interface DesktopsProps {
  relatedDesktops: DesktopApiType[];
  desktopAccessesMap: Record<string, AccessApiType[]>;
  deletableRelatedDesktopsMap: { [key: string]: boolean };
  account: AccountApiType;
  defaultAccessType: DesktopAccessType;
  isOnlyFullAccessAllowed: boolean;
}

export const DesktopsSection: FC<DesktopsProps> = ({
  account,
  relatedDesktops,
  desktopAccessesMap,
  deletableRelatedDesktopsMap,
  defaultAccessType,
  isOnlyFullAccessAllowed,
}) => {
  const intl = useIntl();
  const relatedDesktopModal = useModalControls();
  const { askConfirmation } = useConfirm();

  const { createAccess, removeAccessById } = useAccessActions();

  const handleManageDesktopsSubmit = useCallback(
    (selectedDesktops: DesktopApiType[]) => {
      const { added, removed } = getListChanges(
        relatedDesktops,
        selectedDesktops,
      );

      addRelatedAccountsToDesktopsIDDB(
        added.map(desktop => desktop.id),
        [account.id],
      );
      addRelatedDesktopsToAccountsIDDB(
        [account.id],
        added.map(desktop => desktop.id),
      );

      removeRelatedAccountsFromDesktopsIDDB(
        removed.map(desktop => desktop.id),
        [account.id],
      );
      removeRelatedDesktopsFromAccountsIDDB(
        [account.id],
        removed.map(desktop => desktop.id),
      );

      Promise.all([
        ...added.map((desktop: DesktopApiType) => {
          return createAccess(desktop.id, {
            account: account.id,
            accessType: defaultAccessType,
          });
        }),
        ...removed.map((desktop: DesktopApiType) => {
          const accessId = desktopAccessesMap?.[desktop.id]?.filter(
            access => access.account?.id === account.id,
          )[0]?.id;
          return accessId
            ? removeAccessById(accessId, desktop.id)
            : Promise.resolve();
        }),
      ]).catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });

      relatedDesktopModal.close();
    },
    [
      relatedDesktops,
      createAccess,
      removeAccessById,
      account,
      defaultAccessType,
      desktopAccessesMap,
      relatedDesktopModal,
    ],
  );

  const handleDeleteRelatedDesktop = useCallback(
    (desktop: Pick<DesktopApiType, 'id'>) => {
      askConfirmation(
        intl.formatMessage({
          id: UserTranslation.adminUserDesktopsRemoveConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        removeRelatedAccountsFromDesktopsIDDB([desktop.id], [account.id]);
        removeRelatedDesktopsFromAccountsIDDB([account.id], [desktop.id]);

        const accessId = desktopAccessesMap?.[desktop.id]?.filter(
          access => access.account?.id === account.id,
        )[0]?.id;
        return accessId
          ? removeAccessById(accessId, desktop.id)
          : Promise.resolve();
      });
    },
    [askConfirmation, intl, removeAccessById, desktopAccessesMap, account],
  );

  const { updateAccess } = useAccessActions();
  const handleDesktopAccessChange = (
    desktop: DesktopApiType,
    newAccessType: DesktopAccessType,
  ) => {
    const desktopAccess = desktopAccessesMap?.[desktop.id]?.find(
      access => access.account?.id === account.id,
    );
    if (desktopAccess) {
      updateAccess(desktop.id, desktopAccess.id, newAccessType);
    }
  };

  return (
    <>
      <Section data-testid="desktops">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={UserTranslation.adminDesktopsHeader} />
          </SectionHeaderTitle>
          <SectionHeaderButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: UserTranslation.tooltipManageUserDesktopAccess,
            })}
            data-tooltip-place={TooltipPlace.top}
            onClick={relatedDesktopModal.open}
            data-testid="manage-button">
            <FormattedMessage id={UserTranslation.adminManageDesktopsButton} />
          </SectionHeaderButton>
        </SectionHeader>
        <RelatedDesktopsList
          desktops={relatedDesktops}
          desktopAccessesMap={desktopAccessesMap}
          accountId={account.id}
          deletableItemsMap={deletableRelatedDesktopsMap}
          isOnlyFullAccessAllowed={isOnlyFullAccessAllowed}
          emptyMessage={intl.formatMessage({
            id: UserTranslation.adminDesktopsEmptyMessage,
          })}
          onChangeAccess={handleDesktopAccessChange}
          onRemove={handleDeleteRelatedDesktop}
        />
      </Section>

      <ManageRelatedDesktopsModal
        subtitle={getAccountName(account)}
        relatedDesktops={relatedDesktops}
        deletableRelatedDesktopsMap={deletableRelatedDesktopsMap}
        onSubmit={handleManageDesktopsSubmit}
        visible={relatedDesktopModal.visible}
        onRequestClose={relatedDesktopModal.close}
      />
    </>
  );
};
