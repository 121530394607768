import React, { FC } from 'react';
import { Spinner } from '../Spinner';
import { Button, ButtonProps } from '../Button/Button';
import { LoadMoreButtonContainer } from './LoadMore.styled';

interface LoadMoreButtonProps extends ButtonProps {
  loading?: boolean;
  spinnerSize?: number;
  onLoadMore: () => void;
}

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  loading,
  onLoadMore,
  spinnerSize = 18,
  children,
  ...props
}) => {
  return (
    <LoadMoreButtonContainer>
      <Button
        disabled={loading}
        onClick={onLoadMore}
        data-testid="load-more-button"
        {...props}>
        {loading ? <Spinner size={spinnerSize} /> : children}
      </Button>
    </LoadMoreButtonContainer>
  );
};
