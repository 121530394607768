import { useMemo, useRef } from 'react';
import type {
  ImageOrientationSize,
  ImageOrientationType,
} from './AssetView.types';
import {
  imageDimension,
  imageHeighthCalc,
  imageWidthCalc,
} from './AssetView.utils';

export const useImageSize = (
  dimensions: string,
  orientation: ImageOrientationType,
  multipleImage: boolean,
): ImageOrientationSize => {
  const imageWidthRef = useRef<number>();
  const imageHeightRef = useRef<number>();
  const imageDimensionSize = imageDimension(dimensions);

  return useMemo(() => {
    const imageWidth = imageWidthCalc(imageDimensionSize, orientation);
    const imageHeight = imageHeighthCalc(
      imageDimensionSize,
      orientation,
      multipleImage,
      dimensions,
    );

    if (!imageWidthRef.current) {
      imageWidthRef.current = imageWidth;
    }

    if (!imageHeightRef.current) {
      imageHeightRef.current = imageHeight;
    }

    return {
      width: imageWidthRef.current,
      height: imageHeightRef.current,
    };
  }, [dimensions, imageDimensionSize, multipleImage, orientation]);
};
