import styled from 'styled-components';
import { Input } from '../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';

export const MasterPasswordLockForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
`;

export const LockScreenPasswordInputWrapper = styled.div`
  width: 100%;
  text-align: left;

  & > span {
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

export const LockScreenPasswordInput = styled(Input).attrs({
  type: 'password',
})``;

export const LockScreenUnlockButton = styled(Button).attrs({
  className: 'unlock-password-manager',
  fullWidth: true,
  mode: ButtonMode.primary,
  size: ButtonSize.md,
})`
  margin-top: 1rem;
`;

export const LockScreenErrorMessage = styled.p`
  color: ${p => p.theme.colors.CriticalLight};
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

export const PasswordInputHint = styled.span`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    font-size: 0.875rem;
  `}
`;
