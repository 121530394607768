import { VaultAccessApiType, VaultApiType } from '../Encryption.types';
import {
  GRAPHQL_TYPENAME_KEYSET,
  GRAPHQL_TYPENAME_VAULT,
  GRAPHQL_TYPENAME_VAULT_ACCESS,
  GRAPHQL_TYPENAME_VAULT_ACCESS_EDGE,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_EDGE,
  GRAPHQL_TYPENAME_VAULT_ITEM_EDGE,
} from '../Encryption.constants';
import { StreamVaultAccessEvent } from '../../Mercure/General';
import {
  getAppConfigDesktopIri,
  getAppConfigIri,
  getKeysetIri,
  getVaultItemIri,
} from '../Encryption.utils';
import { getAppIri } from '../../AppStore/AppStore.utils';
import { getDesktopIri } from '../../Desktop/Desktop.utils';

export const addVaultAccess = (
  vaults: VaultApiType[] = [],
  newVault: VaultApiType,
  newVaultAccess: VaultAccessApiType,
  newParentVaultAccess?: VaultAccessApiType,
): VaultApiType[] => {
  const vaultAccessAlreadyExists = vaults.some(vault =>
    vault.vaultAccesses.edges.some(
      vaultAccessNode => vaultAccessNode.node.id === newVaultAccess.id,
    ),
  );
  if (vaultAccessAlreadyExists) {
    return vaults;
  }

  const vaultDoesNotExist = !vaults.some(vault => vault.id === newVault.id);

  const isParentAbsent =
    newParentVaultAccess &&
    !vaults.some(vault =>
      vault.vaultAccesses.edges.some(
        vaultAccessNode => vaultAccessNode.node.id === newParentVaultAccess.id,
      ),
    );

  return vaultDoesNotExist
    ? [
        ...vaults,
        {
          ...newVault,
          vaultAccesses: {
            edges: [
              {
                node: newVaultAccess,
                __typename: GRAPHQL_TYPENAME_VAULT_ACCESS_EDGE,
              },
              ...(newParentVaultAccess
                ? [
                    {
                      node: newParentVaultAccess,
                      __typename: GRAPHQL_TYPENAME_VAULT_ACCESS_EDGE,
                    },
                  ]
                : []),
            ],
          },
        },
      ]
    : vaults.map(vault =>
        vault.id !== newVault.id
          ? vault
          : {
              ...vault,
              vaultAccesses: {
                ...vault.vaultAccesses,
                edges: [
                  ...vault.vaultAccesses.edges,
                  {
                    node: newVaultAccess,
                    __typename: GRAPHQL_TYPENAME_VAULT_ACCESS,
                  },
                  ...(isParentAbsent
                    ? [
                        {
                          node: newParentVaultAccess,
                          __typename: GRAPHQL_TYPENAME_VAULT_ACCESS,
                        },
                      ]
                    : []),
                ],
              },
            },
      );
};

export const removeVaultAccess = (
  vaults: VaultApiType[] = [],
  vaultAccessId: string,
): VaultApiType[] => {
  return vaults.map(vault => {
    return {
      ...vault,
      vaultAccesses: {
        edges: vault.vaultAccesses.edges.filter(
          accessEdge => accessEdge.node.id !== vaultAccessId,
        ),
        __typename: vault.vaultAccesses.__typename,
      },
    };
  });
};

export const makeVaultAccess = (
  event: StreamVaultAccessEvent,
): VaultAccessApiType => {
  return {
    __typename: GRAPHQL_TYPENAME_VAULT_ACCESS,
    id: event.id,
    keyset: {
      id: getKeysetIri(event.keyset._id),
      _id: event.keyset._id,
      __typename: GRAPHQL_TYPENAME_KEYSET,
    },
    vaultKeyEncrypted: event.vaultKeyEncrypted,
    isImplicitlyShared: event.isImplicitlyShared,
    shared: event.shared,
    createdAt: event.createdAt,
    parent: event.parent && {
      id: event.parent.id,
    },
  };
};

export const makeVaultAccessParent = (
  event: StreamVaultAccessEvent,
): VaultAccessApiType | undefined => {
  return (
    (event.parent && {
      __typename: GRAPHQL_TYPENAME_VAULT_ACCESS,
      id: event.parent.id,
      keyset: {
        id: getKeysetIri(event.parent.keyset._id),
        _id: event.parent.keyset._id,
        __typename: GRAPHQL_TYPENAME_KEYSET,
      },
      vaultKeyEncrypted: event.parent.vaultKeyEncrypted,
      isImplicitlyShared: event.parent.isImplicitlyShared,
      shared: event.parent.shared,
      createdAt: event.parent.createdAt,
      parent: null,
    }) ||
    undefined
  );
};

export const makeVaultFromVaultAccessEvent = (
  event: StreamVaultAccessEvent,
): VaultApiType => {
  const vaultItem = event.vault.vaultItems[0];
  return {
    __typename: GRAPHQL_TYPENAME_VAULT,
    id: event.vault.id,
    createdAt: event.vault.createdAt,
    modifiedAt: event.vault.modifiedAt,
    vaultAccesses: {
      edges: [],
    },
    vaultItems: {
      edges: [
        {
          node: {
            id: getVaultItemIri(vaultItem.id),
            login: vaultItem.login,
            password: vaultItem.password,
            url: vaultItem.url,
            description: vaultItem.description,
            image: vaultItem.image,
            type: vaultItem.type,
            appVaultItemConfigs: {
              edges: vaultItem.appVaultItemConfigs.map(appConfig => ({
                node: {
                  __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG,
                  id: getAppConfigIri(appConfig.id),
                  app: {
                    ...appConfig.app,
                    id: getAppIri(appConfig.app.id),
                  },
                  appVaultItemConfigDesktops: {
                    edges: appConfig.appVaultItemConfigDesktops.map(
                      appConfigDesktop => ({
                        node: {
                          __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP,
                          id: getAppConfigDesktopIri(appConfigDesktop.id),
                          desktop: {
                            id: getDesktopIri(appConfigDesktop.desktop.id),
                          },
                        },
                        __typename:
                          GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
                      }),
                    ),
                  },
                },
                __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG_EDGE,
              })),
            },
          },
          __typename: GRAPHQL_TYPENAME_VAULT_ITEM_EDGE,
        },
      ],
    },
  };
};
