import styled from 'styled-components';

export const ConferenceDetailsTitle = styled.h3`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.3;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
`;

export const ConferenceDetailsList = styled.ul``;

export const ConferenceDetailsListItem = styled.li`
  display: flex;
  font-size: 0.875rem;
  line-height: 1.3;

  .label {
    flex: 0 0 96px;
  }

  ${({ theme }) => theme.breakpoints.md`
    .label {
      flex: 0 0 84px;
    }
  `}

  .value {
    flex: 1 1 100%;
    margin-left: 0.625rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    user-select: text;
  }

  & ~ & {
    margin-top: 0.5rem;
  }
`;
