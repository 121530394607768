import {
  GET_GOOGLE_AUTH_LINK,
  GetGoogleAuthLinkResponse,
  GetGoogleAuthLinkVariables,
} from './BrowserGoogleAuthStatus.queries';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { BrowserGoogleAuthStatusContext } from './BrowserGoogleAuthStatus.context';

export const useBrowserGoogleAuthStatusContext = () =>
  useContext(BrowserGoogleAuthStatusContext);

export const useGetGoogleAuthUrlQuery = (
  options?: QueryHookOptions<
    GetGoogleAuthLinkResponse,
    GetGoogleAuthLinkVariables
  >,
) =>
  useQuery<GetGoogleAuthLinkResponse, GetGoogleAuthLinkVariables>(
    GET_GOOGLE_AUTH_LINK,
    options,
  );
