import { useCallback, useMemo, useState } from 'react';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  useAppStoreApps,
  useAppStoreCategories,
  useCustomAppStoreApps,
  useDeleteCustomAppMutation,
} from '../../AppStore.hooks';
import { AppStoreAppApiType } from '../../AppStore.types';
import {
  GET_CUSTOM_APPSTORE_APPS,
  GetAppStoreAppsResponse,
  GetAppStoreAppsVariables,
  GetCustomAppStoreAppsResponse,
  GetCustomAppStoreAppsVariables,
} from '../../AppStore.queries';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import { useQueryParams } from '../../../../shared/hooks';
import { TabId } from '../../AppStore.constants';
import { useIntl } from 'react-intl';
import { AppStoreTranslation } from '../../i18n';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../../shared/components/Modal';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { GRAPHQL_TYPENAME_DESKTOP } from '../../../Desktop/Desktop.constants';
import {
  deleteCustomAppStoreAppFromCache,
  getAppStoreQueryCustomVariableValue,
  getAppStoreQueryWorkspaceVariableValue,
} from '../../AppStore.utils';
import { mergeWithArray } from '../../../../shared/utils/list.utils';
import { GraphQLError } from 'graphql';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useLiveQuery } from 'dexie-react-hooks';
import { database } from '../../../Database';
import type { DesktopApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

const DEFAULT_PAGE_SIZE = 30;
const LOAD_MORE_RETRY_TIMEOUT = 5000;

export const AppStoreViewModel = () => {
  const currentDesktop = useCurrentDesktop() as DesktopApiType;
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const {
    permissions: { canAddCustomApp },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const [loadingMoreApps, setLoadingMoreApps] = useState(false);

  const queryParams = useQueryParams();
  const { categoryId, tabId, filter, createCustomAppModal, editAppId } =
    queryParams;

  const appStoreAppsVariables: GetAppStoreAppsVariables = useMemo(() => {
    return {
      filterUsedByMe: tabId === TabId.personal,
      category:
        tabId === TabId.customApps
          ? null
          : (categoryId && `categories/${categoryId}`) || null,
      custom: getAppStoreQueryCustomVariableValue(tabId as TabId),
      filterExistVaultItemsByWorkspace:
        tabId === TabId.customApps
          ? null
          : tabId === TabId.passwords
          ? currentWorkspace.id
          : null,
      workspace: getAppStoreQueryWorkspaceVariableValue(
        tabId as TabId,
        currentWorkspace,
      ),
      first: DEFAULT_PAGE_SIZE,
      filterFreeTextSearch: (filter as string) || null,
      presentInAppStore: true,
    };
  }, [tabId, categoryId, filter, currentWorkspace]);

  const {
    data: defaultAppsData,
    loading: loadingAllApps,
    fetchMore: fetchMoreApps,
  } = useAppStoreApps({
    skip: tabId === TabId.sso || tabId === TabId.customApps,
    variables: appStoreAppsVariables,
    fetchPolicy: tabId === TabId.personal ? 'network-only' : 'cache-first',
  });

  const {
    data: customAppsData,
    refetch: refetchCustomApps,
    loading: loadingCustomApps,
  } = useCustomAppStoreApps({
    skip: tabId !== TabId.customApps,
    variables: appStoreAppsVariables,
    fetchPolicy: 'cache-and-network',
  });

  const loadingApps =
    tabId === TabId.customApps ? loadingCustomApps : loadingAllApps;

  const { data: categoriesData, loading: loadingCategories } =
    useAppStoreCategories({
      variables: {
        presentInAppStore: true,
      },
    });

  const apps = useMemo(
    () =>
      extractNodes(
        tabId === TabId.customApps
          ? customAppsData?.listCustomWorkspaceApps
          : defaultAppsData?.apps,
      ),
    [defaultAppsData, customAppsData, tabId],
  );

  const categories = useMemo(
    () => extractNodes(categoriesData?.categories),
    [categoriesData],
  );

  const currentDesktopAppsData = useLiveQuery(
    () => {
      return currentDesktop
        ? database.desktopApps
            .where('desktopId')
            .equals(currentDesktop.id)
            .sortBy('createdAt')
        : [];
    },
    [currentDesktop?.id],
    [],
  );

  const currentDesktopAppIds = useMemo(
    () => currentDesktopAppsData.map(app => app.sourceData.app.id),
    [currentDesktopAppsData],
  );

  const [deleteCustomAppMutation] = useDeleteCustomAppMutation();

  const appsAddedToCurrentDesktop = useMemo(
    () =>
      new Set(
        apps
          .filter(app => currentDesktopAppIds.includes(app.id))
          .map(app => app.id),
      ),
    [apps, currentDesktopAppIds],
  );

  const deleteCustomApp = useCallback(
    (app: AppStoreAppApiType) => {
      deleteCustomAppMutation({
        variables: { input: { id: app.id } },
        update: (proxy, { data }) => {
          if (!data) {
            return;
          }

          try {
            const appStoreAppsCache = proxy.readQuery<
              GetCustomAppStoreAppsResponse,
              GetCustomAppStoreAppsVariables
            >({
              query: GET_CUSTOM_APPSTORE_APPS,
              variables: appStoreAppsVariables,
            });

            if (appStoreAppsCache) {
              proxy.writeQuery<
                GetCustomAppStoreAppsResponse,
                GetCustomAppStoreAppsVariables
              >({
                query: GET_CUSTOM_APPSTORE_APPS,
                variables: appStoreAppsVariables,
                data: deleteCustomAppStoreAppFromCache(
                  appStoreAppsCache,
                  data.deleteApp.app.id,
                ),
              });
            }
          } catch (e) {}

          try {
            // @ts-ignore
            const { ROOT_QUERY } = proxy.data.data;
            // @ts-ignore
            proxy.data.data.ROOT_QUERY = Object.keys(ROOT_QUERY).reduce(
              (acc, key) => {
                if (ROOT_QUERY[key].typename === GRAPHQL_TYPENAME_DESKTOP) {
                  return acc;
                }
                return { ...acc, [key]: ROOT_QUERY[key] };
              },
              {},
            );
          } catch (e) {}
        },
      })
        .then(() => {
          showToastSuccessMessage(
            AppStoreTranslation.deleteCustomAppNotification,
            { appName: app.fullName },
          );
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [deleteCustomAppMutation, appStoreAppsVariables],
  );

  const handleDeleteCustomAppClick = useCallback(
    (app: AppStoreAppApiType) => {
      askConfirmation(
        intl.formatMessage({
          id: AppStoreTranslation.deleteCustomAppConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteCustomApp(app);
      });
    },
    [askConfirmation, deleteCustomApp, intl],
  );

  const defaultAppsDataEndCursor = defaultAppsData?.apps.pageInfo?.endCursor;

  const handleLoadMore = useCallback(() => {
    if (tabId === TabId.customApps) {
      return;
    }

    setLoadingMoreApps(true);
    fetchMoreApps({
      variables: {
        after: defaultAppsDataEndCursor,
      },
      updateQuery: (prev: GetAppStoreAppsResponse, { fetchMoreResult }) => {
        return mergeWithArray(
          // TODO: Get rid of this fallback after Apollo issue will be resolved - https://github.com/apollographql/apollo-client/issues/5169
          prev || apps,
          fetchMoreResult || {},
        ) as GetAppStoreAppsResponse;
      },
    })
      .then(() => setLoadingMoreApps(false))
      .catch((e: { graphQLErrors: GraphQLError[] }): any => {
        setTimeout(() => setLoadingMoreApps(false), LOAD_MORE_RETRY_TIMEOUT);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [defaultAppsDataEndCursor, apps, fetchMoreApps, tabId]);

  const handleCreateCustomAppBannerClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createCustomAppModal: true,
        tabId: TabId.customApps,
      }),
    });
  }, [navigate, queryParams]);

  return {
    filter,
    categories,
    categoryId,
    loadingCategories,
    tabId,
    canAddCustomApp,
    loadingApps,
    loadingMoreApps,
    appsAddedToCurrentDesktop,
    defaultAppsData,
    apps,
    refetchCustomApps,
    createCustomAppModal,
    editAppId,
    handleCreateCustomAppBannerClick,
    handleDeleteCustomAppClick,
    handleLoadMore,
  };
};
