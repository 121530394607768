import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Favicon,
  Hostname,
  HostnameContainer,
  LinkRequireAccess,
  LinkWarning,
  StyledLinkData,
  Title,
} from './LinkData.styled';
import { LinkApiType } from '../../../Link.types';
import { getLinkHostname, getLinkTitle } from '../../../Link.utils';
import { getImageSrcSet } from '../../../../../shared/utils/url.utils';
import { useDocumentIsSupportedPreviewType } from '../../../../Preview/DocumentPreview';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import attentionIcon from '../../../../../shared/assets/images/attentionYellowIcon.svg';
import { useIsGoogleUrl } from './LinkData.hooks';
import GoogleIcon from '../../../../../shared/assets/images/google.svg';

type LinkDataProps = {
  link: LinkApiType;
};

export const LinkData: FC<LinkDataProps> = ({ link }) => {
  const canPreviewDocument = useDocumentIsSupportedPreviewType(link);
  const isGoogleIcon = useIsGoogleUrl(link.data.realUrl);

  const hostname = useMemo(
    () => getLinkHostname(link, canPreviewDocument),
    [canPreviewDocument, link],
  );
  const linkTitle = useMemo(() => getLinkTitle(link), [link]);
  const [isRequiresAccess, setRequiresAccess] = useState(false);
  const intl = useIntl();

  // NOTE: The following hard coded logic is temporary
  // until google api is implemented in backend
  const overrideTitles = useMemo(
    () => [
      'Google Sheets: Sign-in',
      'Google Docs: Sign-in',
      'Google Slides: Sign-in',
      'Google Drive: Sign-in',
    ],
    [],
  );

  useEffect(() => {
    if (overrideTitles.includes(link.data.title!)) {
      setRequiresAccess(true);
    }
  }, [link.data.title, overrideTitles]);

  const requireAccess = link.data.metadata && 'no_access' in link.data.metadata;

  const linkIcon = isGoogleIcon ? GoogleIcon : link.data.icon?.contentUrl;

  return (
    <StyledLinkData data-testid="link-data">
      <Title data-testid="link-title" oneLiner={isRequiresAccess}>
        {linkTitle}
      </Title>

      {requireAccess && (
        <LinkRequireAccess>
          <Favicon
            src={attentionIcon}
            srcSet={getImageSrcSet(attentionIcon, 15)}
            data-testid="atention-icon"
            alt={intl.formatMessage({
              id: LinkTranslation.linksViewRequiresDirectAccess,
            })}
          />
          <FormattedMessage
            id={LinkTranslation.linksViewRequiresDirectAccess}
          />
        </LinkRequireAccess>
      )}

      <HostnameContainer>
        <Hostname data-testid="link-hostname">
          {linkIcon && (
            <Favicon
              src={linkIcon}
              srcSet={getImageSrcSet(linkIcon, 15)}
              data-testid="link-favicon"
              alt={hostname}
            />
          )}
          <span>{hostname}</span>
        </Hostname>
        {link.data.abuseChecked && !link.data.safe && (
          <LinkWarning>
            <FormattedMessage id={LinkTranslation.linksViewLinkWarningLabel} />
          </LinkWarning>
        )}
      </HostnameContainer>
    </StyledLinkData>
  );
};
