import styled from 'styled-components';
import { OpenDesktopIcon } from '../../../shared/icons';

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  &.chat-switcher-header {
    background-color: ${p => p.theme.colors.Background};
  }

  button ~ button {
    margin-left: 0.5rem;
  }

  svg {
    height: 24px;
    width: 24px;
    color: ${p => p.theme.colors.Primary};

    + div {
      margin-left: 0.5rem;
    }
  }

  ${({ theme: { breakpoints } }) => breakpoints.sm`
     svg {
      width: unset;
      height: unset;
     }
  `}

  button[disabled] {
    background-color: ${p => p.theme.colors.Surface};

    svg {
      color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    }
  }
`;

export const ChatHeaderTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.headerMain}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 1;
  width: 100%;
  padding-right: 0.625rem;
  align-items: center;

  .burgerButton {
    margin-right: 0.75rem;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledRedirectToDesktopButton = styled(OpenDesktopIcon)`
  cursor: pointer;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis}!important;
`;

export const ChatHeaderSpacer = styled.div`
  width: 1px;
  background: ${p => p.theme.colors.BackgroundLight};
  height: 1.25rem;
  margin: 0 1rem;
`;

export const ChatHeaderDivider = styled.div`
  height: 1.25rem;
  width: 1px;
  background-color: ${p => p.theme.colors.SurfaceBorder};
  margin: 0 1rem;
`;
