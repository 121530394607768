import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const StyledShareVaultForm = styled.div`
  display: flex;

  > div {
    flex: 1 1 auto;
  }

  ${Button} {
    margin-left: 1rem;
    height: fit-content;
  }
`;
