import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  getDesktopIri,
  getDesktopLayoutStorage,
  setDesktopLayoutStorage,
} from '../../Desktop.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams, useTablet } from '../../../../shared/hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getFolderByFolderId } from '../../../Folder/Folder.utils';
import { useFilterFoldersQuery } from '../../../Folder/Folder.hooks';
import { CurrentDesktopContentContextType } from '../../Desktop.context';
import { useEffectOnce } from 'react-use';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { DesktopLayout } from '../../Desktop.constants';
import { database } from '../../../Database';
import { useCaseGetWorkspaceFavorites } from '../../../Workspace/UseCase/getWorkspaceFavorites';
import { getDesktopLinksByFolderId } from '../../deprecatedData/Repository/DesktopRepository';

const VARIANT_NAME = 'favorites';

export const FavoritesDesktopViewModel = () => {
  const navigate = useNavigate();
  const isTablet = useTablet();
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();

  const [folderLinksTotalCount, setFolderLinksTotalCount] = useState(0);
  const [linksLoading, setLinksLoading] = useState(false);
  const [fetchedDesktopFolders, setFetchedDesktopFolders] = useState<string[]>(
    [],
  );
  const [desktopLayout, setDesktopLayout] = useState<DesktopLayout>(
    getDesktopLayoutStorage(VARIANT_NAME),
  );

  const { getWorkspaceFavoritesUseCase, favoritesLoading } =
    useCaseGetWorkspaceFavorites();

  useEffect(() => {
    if (workspace?.id) {
      getWorkspaceFavoritesUseCase(workspace.id);
    }
  }, [getWorkspaceFavoritesUseCase, workspace?.id]);

  const favoritesApps = useLiveQuery(
    () => {
      return database.desktopApps
        .where('workspaceId')
        .equals(workspace.id ?? '')
        .and(app => app.accountFavorite)
        .toArray()
        .then(appsResp => {
          return (
            appsResp?.map(app => ({
              ...app.sourceData,
              desktop: {
                id: getDesktopIri(app.desktopId),
              },
            })) ?? []
          );
        });
    },
    [workspace.id],
    [],
  );

  const favoritesFolders = useLiveQuery(
    () => {
      return database.desktopFolders
        .where('workspaceId')
        .equals(workspace.id ?? '')
        .and(app => app.accountFavorite)
        .toArray()
        .then(foldersResp => {
          return foldersResp?.map(folder => folder.sourceData) ?? [];
        });
    },
    [workspace.id],
    [],
  );

  const favoritesLinks = useLiveQuery(
    () => {
      return database.desktopLinks
        .where('workspaceId')
        .equals(workspace.id ?? '')
        .and(link => link.accountFavorite)
        .toArray()
        .then(linksResp => {
          return linksResp?.map(link => link.sourceData) ?? [];
        });
    },
    [workspace.id],
    [],
  );

  const folderLinks = useLiveQuery(
    () => {
      return queryParams.folderId
        ? database.desktopLinks
            .where('workspaceId')
            .equals(workspace.id ?? '')
            .and(link => link.folder === `/folders/${queryParams.folderId}`)
            .toArray()
            .then(folderLinksResp => {
              return (
                folderLinksResp?.map(folderLink => folderLink.sourceData) || []
              );
            })
        : [];
    },
    [queryParams.folderId],
    [],
  );

  useEffect(() => {
    if (!queryParams.folderId) {
      return;
    }
    setLinksLoading(() => true);

    getDesktopLinksByFolderId(workspace.id, queryParams.folderId as string)
      .then(resonse => {
        setFolderLinksTotalCount(() => resonse?.linksTotalCount ?? 0);
      })
      .finally(() => {
        setLinksLoading(() => false);
      });
  }, [queryParams.folderId, workspace.id]);

  const currentFolder = useMemo(
    () => getFolderByFolderId(favoritesFolders, queryParams.folderId as string),
    [favoritesFolders, queryParams.folderId],
  );

  useFilterFoldersQuery({
    skip:
      !currentFolder ||
      fetchedDesktopFolders.includes(currentFolder?.desktop.id),
    variables: {
      desktop: currentFolder?.desktop.id,
      desktop_workspace: workspace.id,
      filterOnlyAccessible: true,
      filterFreeTextSearch: '',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      setFetchedDesktopFolders([
        ...fetchedDesktopFolders,
        currentFolder!.desktop.id,
      ]);
    },
  });

  /**
   * Initial one time actions when mounting component
   */

  useEffectOnce(() => {
    const storedLayout = getDesktopLayoutStorage(VARIANT_NAME);
    setDesktopLayout(storedLayout);
  });

  /**
   * Desktop layout logic
   */

  const updateDesktopLayout = useCallback((layout: DesktopLayout) => {
    setDesktopLayout(layout);
    setDesktopLayoutStorage(VARIANT_NAME, layout);
  }, []);

  // change when tablet state changes
  // do not store this, just update the soft state
  useEffect(() => {
    const storedLayout = getDesktopLayoutStorage(VARIANT_NAME);

    if (isTablet && storedLayout === DesktopLayout.CONSOLIDATED) {
      setDesktopLayout(DesktopLayout.TABBED);
    } else if (!isTablet && storedLayout === DesktopLayout.CONSOLIDATED) {
      setDesktopLayout(DesktopLayout.CONSOLIDATED);
    }
  }, [desktopLayout, isTablet]);

  /**
   * Contexts
   */

  const hasApps = !!favoritesApps.length;
  const hasLinks = !!favoritesLinks.length;
  const hasFolders = !!favoritesFolders.length;
  const hasChat = false;

  const favoritesIsLoading = favoritesLoading;

  const favoritesHasContent = hasApps || hasLinks || hasFolders || hasChat;

  const favoritesIsEmpty =
    !favoritesIsLoading && !hasApps && !hasLinks && !hasFolders && !hasChat;

  const desktopLinksLoading =
    !folderLinks && linksLoading && !favoritesLinks.length;

  const desktopLinksTotal = folderLinks
    ? folderLinksTotalCount
    : favoritesLinks.length;

  const mergedFavoritesLinks = useMemo(() => {
    if (queryParams.folderId) {
      return folderLinks;
    } else {
      return favoritesLinks;
    }
  }, [favoritesLinks, folderLinks, queryParams.folderId]);

  const currentDesktopContentContextValue: CurrentDesktopContentContextType =
    useMemo(() => {
      return {
        desktopApps: favoritesApps,
        desktopAppsLoading: false,
        desktopFolders: favoritesFolders,
        desktopLinks: mergedFavoritesLinks,
        desktopLinksLoading,
        desktopLinksTotal,
        hasChat: false,
        desktopAccessForCurrentAccount: null,
        setDesktopLayout: updateDesktopLayout,
        desktopLayout,
        fetchMoreDesktopLinksLoading: false,
        fetchMoreDesktopLinks: () => {},
        refetchDesktopContent: () => {},
        setMovingLinkToFolder: () => {},
      };
    }, [
      favoritesApps,
      favoritesFolders,
      mergedFavoritesLinks,
      desktopLinksLoading,
      desktopLinksTotal,
      updateDesktopLayout,
      desktopLayout,
    ]);

  const chooseVaultForDesktopModalCloseHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        selectVaultForAppId: undefined,
        selectVaultForGroupAppId: undefined,
        selectVaultForDesktopIdOverride: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const {
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
  } = queryParams;

  return {
    currentDesktopContentContextValue,
    hasChat: false,
    desktopLayout,
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
    chooseVaultForDesktopModalCloseHandler,
    favoritesIsLoading,
    favoritesHasContent,
    favoritesIsEmpty,
    linksLoading,
  };
};
