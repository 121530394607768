import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AppIntegrationMarketplaceItemType } from '../../../../AppIntegrations.queries';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { AppIntegrationsTranslation } from '../../../../i18n';
import {
  StyledMarketplaceApp,
  StyledMarketplaceAppInfo,
  StyledMarketplaceAppWrapper,
} from './MarketplaceApp.styled';
import { Toggle } from '../../../../../../shared/components/Toggle';
import {
  useRemoveAppIntegrationMutation,
  useSwitchAppIntegrationMutation,
} from '../../../../AppIntegrations.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import { getShortId } from '../../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../../../Workspace/Workspace.constants';

export const MarketplaceApp: FC<{
  marketplaceItem: AppIntegrationMarketplaceItemType;
}> = ({ marketplaceItem }) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { workspaceId } = useParams<{
    workspaceId: string;
  }>();

  const [isActive, setIsActive] = useState(
    marketplaceItem.accountAppIntegration?.active,
  );
  const [switchAppIntegrationMutation] = useSwitchAppIntegrationMutation();
  const [deleteAppIntegrationMutation] = useRemoveAppIntegrationMutation();

  const handleRedirectToCategorys = useCallback(() => {
    navigate({
      pathname: generatePath(APPS_INTEGRATIONS_PATHNAME, {
        workspaceId: getShortId(workspaceId as string),
      }),
      search: getQueryParamsFrom({
        ...queryParams,
        integrationsCategory: undefined,
      }),
    });
  }, [navigate, workspaceId, queryParams]);

  const handleDeleteIntegration = useCallback(() => {
    deleteAppIntegrationMutation({
      variables: {
        input: {
          appIntegrationIri: marketplaceItem.accountAppIntegration?.id ?? '',
          workspace: '/workspaces/' + workspaceId,
        },
      },
    })
      .then(res => {
        if (res) {
          showToastSuccessMessage(
            AppIntegrationsTranslation.successDeletedAppIntegration,
          );
          handleRedirectToCategorys();
        }
      })
      .catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
      });
  }, [
    deleteAppIntegrationMutation,
    handleRedirectToCategorys,
    marketplaceItem,
    workspaceId,
  ]);

  const handleMarketplaceItemStateChange = useCallback(
    (event: ChangeEvent<any> | undefined) => {
      const { checked } = event!.target;
      setIsActive(checked);
      switchAppIntegrationMutation({
        variables: {
          input: {
            appIntegrationIri: marketplaceItem.accountAppIntegration?.id ?? '',
            workspace: '/workspaces/' + workspaceId,
          },
        },
      }).catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
        setIsActive(!checked);
      });
    },
    [marketplaceItem, switchAppIntegrationMutation, workspaceId],
  );

  return (
    <StyledMarketplaceAppWrapper>
      <StyledMarketplaceAppInfo>
        <img
          className="logo"
          src={marketplaceItem.app.logo.contentUrl}
          alt=""
        />
        <div className="body">
          <span className="title">{marketplaceItem.name}</span>
        </div>
      </StyledMarketplaceAppInfo>
      <div className="configuration-title">
        <FormattedMessage
          id={AppIntegrationsTranslation.appIntegrationConfiguration}
        />
      </div>
      <StyledMarketplaceApp>
        <div className="body">
          <span className="name">{marketplaceItem.name}</span>
          <span className="description">{marketplaceItem.description}</span>
        </div>
        <div className="controls">
          <Toggle
            id={marketplaceItem.id}
            value={isActive}
            onChange={handleMarketplaceItemStateChange}
          />
        </div>
      </StyledMarketplaceApp>
      <Button
        mode={ButtonMode.danger}
        size={ButtonSize.md}
        onClick={handleDeleteIntegration}>
        <FormattedMessage
          id={AppIntegrationsTranslation.appIntegrationDeleteIntegration}
        />
      </Button>
    </StyledMarketplaceAppWrapper>
  );
};
