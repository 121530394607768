import { useCallback, useContext } from 'react';
import { PersistedStorageContext } from './PersistedStorage.context';
import { PredefinedData } from './PersistedStorage.types';
import { AuthConfig } from '../Auth/Auth.types';
import { AccountApiType } from '../User/User.types';

export const usePersistedStorage = () => {
  const context = useContext(PersistedStorageContext);
  if (!context) {
    throw new Error('Persisted Storage should not be used before initialized');
  }
  return context;
};

export const usePersistedAuthConfig = (): [
  AuthConfig | null,
  (config: AuthConfig | null) => void,
] => {
  const {
    data: { authConfig },
    setItem,
  } = usePersistedStorage();
  const setAuthConfig = useCallback(
    (value: PredefinedData['authConfig']) => {
      setItem('authConfig', value);
    },
    [setItem],
  );
  return [authConfig, setAuthConfig];
};

export const usePersistedAuthenticatedAccount = (): [
  AccountApiType | null,
  (account: AccountApiType | null) => void,
] => {
  const {
    data: { authenticatedAccount },
    setItem,
  } = usePersistedStorage();
  const setAuthenticatedAccount = useCallback(
    (value: PredefinedData['authenticatedAccount']) => {
      setItem('authenticatedAccount', value);
    },
    [setItem],
  );
  return [authenticatedAccount, setAuthenticatedAccount];
};

export const useIsPersistedStorageAvailable = () => {
  return usePersistedStorage().available;
};
