import styled from 'styled-components';

export const StyledMarketplaceCategoryWrapper = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: 0.5rem;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Surface};
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    border-color: ${p => p.theme.colors.SurfaceBorder};
  }

  .name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    color: ${p => p.theme.colors.OnSurface};
  }

  .body {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .logo {
    width: 2.25rem !important;
    height: 2.25rem !important;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .description {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  .controls {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    font-weight: 400;

    button {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledMarketplaceItems = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMarketplaceItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.375rem;
  }

  .is-active {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background-color: ${p =>
      p.isActive
        ? p.theme.colors.OnlineStatusActive
        : p.theme.colors.SurfaceSubdued};
  }
`;

export const StyledRestItemsCountIndicator = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${p => p.theme.colors.Background};
  font-weight: 600;
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05rem;
`;
