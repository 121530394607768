import styled from 'styled-components';

export const DesktopEmptyViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const DesktopEmptyViewBody = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  svg {
    padding-right: 0.625rem;
  }

  .emptyViewTittle {
    font-size: 1.25rem;
    font-family: ${p => p.theme.fonts.Medium};
    line-height: 1.2;
    padding: 1.5rem 0 0.5rem 0;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .emptyViewSubTitle {
    max-width: 380px;
    font-size: 1rem;
  }
`;

export const EmptyFavoritesHeader = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  ${({ theme: { breakpoints, fonts } }) => breakpoints.md`
    font-size: 1.5rem;
    font-family: ${fonts.Regular};
  `}
`;

export const EmptyFavoritesMessage = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 1.125rem;
  text-align: center;
`;

export const DesktopEmptyViewFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.43;
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.875rem;
  padding-bottom: 2rem;
  a {
    cursor: pointer;
    color: ${p => p.theme.colors.Primary};
  }
`;

export const DesktopEmptyViewOnboardingLink = styled.span`
  cursor: pointer;
  color: ${p => p.theme.colors.Primary};
`;
