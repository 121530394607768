import React, { FC } from 'react';
import { UserTranslation } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditUserModal } from '../../../../UserModals/EditUserModal';
import { useModalControls } from '../../../../../../shared/components/Modal/Modal.hooks';
import {
  GeneralInfoBadges,
  GeneralInfoContent,
  GeneralInfoGroupTag,
  GeneralInfoImage,
  GeneralInfoMeta,
  GeneralInfoSubtitle,
  GeneralInfoTitle,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../../Admin';
import { GroupTag } from '../../../../UserGroupTag';
import { AccountApiType, AccountOrigin } from '../../../../User.types';
import { Avatar } from '../../../../UserAvatar/Avatar';
import { getAccountName } from '../../../../User.utils';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../../User.constants';
import { useCurrentWorkspacePermissions } from '../../../../../Workspace/Workspace.hooks';
import { IntegrationBadge } from '../../../../../Chat/ChatAvatar/IntegrationBadge';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';

interface GeneralInformationProps {
  account: AccountApiType;
  groupIdentifier: GroupIdentity;
  active: boolean;
}

export const GeneralInformationSection: FC<GeneralInformationProps> = ({
  account,
  groupIdentifier,
  active,
}) => {
  const { formatMessage } = useIntl();
  const editUserModal = useModalControls();
  const {
    permissions: { canEditUsersPage },
  } = useCurrentWorkspacePermissions();

  return (
    <>
      <Section data-testid="general-info">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={UserTranslation.adminGeneralInfo} />
          </SectionHeaderTitle>
          {groupIdentifier !== GroupIdentity.GUEST && canEditUsersPage && (
            <>
              <SectionHeaderButton
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: UserTranslation.tooltipEditUserRole,
                })}
                data-tooltip-place={TooltipPlace.top}
                onClick={editUserModal.open}
                data-testid="edit-button">
                <FormattedMessage id={UserTranslation.adminGeneralInfoEdit} />
              </SectionHeaderButton>
            </>
          )}
        </SectionHeader>
        <SectionBody data-testid="section">
          <GeneralInfoContent>
            <GeneralInfoImage data-testid="image">
              <Avatar size={108} account={account} />
            </GeneralInfoImage>
            <GeneralInfoMeta>
              <GeneralInfoTitle data-testid="title">
                {getAccountName(account)}
                <GeneralInfoBadges>
                  {account?.origin !== AccountOrigin.webtopcom && (
                    <IntegrationBadge
                      origin={account.origin as AccountOrigin}
                      badgeSize={22}
                    />
                  )}
                </GeneralInfoBadges>
              </GeneralInfoTitle>
              {account.origin !== AccountOrigin.slack && (
                <GeneralInfoSubtitle data-testid="subtitle">
                  {account.email}
                </GeneralInfoSubtitle>
              )}
              <GeneralInfoGroupTag>
                {groupIdentifier && (
                  <GroupTag
                    groupIdentifier={
                      active ? groupIdentifier : GroupIdentity.SUSPENDED
                    }
                    data-testid="group">
                    {formatMessage({
                      id: UserTranslation[
                        active
                          ? (groupIdentifier as GroupIdentity)
                          : (GroupIdentity.SUSPENDED as GroupIdentity)
                      ],
                    })}
                  </GroupTag>
                )}
              </GeneralInfoGroupTag>
            </GeneralInfoMeta>
          </GeneralInfoContent>
        </SectionBody>
      </Section>

      <EditUserModal
        visible={editUserModal.visible}
        onRequestClose={editUserModal.close}
        account={account}
        groupIdentifier={groupIdentifier}
      />
    </>
  );
};
