import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useConfirm } from '../../../shared/components/Modal';
import { getLongId } from '../../../shared/utils/id';
import { database } from '../../Database';
import { deleteLinksRepository } from '../Data/Repository/LinkRepository';
import { LinkTranslation } from '../i18n';
import { LinkApiType } from '../Link.types';

export const useCaseDeleteLinks = () => {
  const { askConfirmation } = useConfirm();
  const { formatMessage } = useIntl();

  const deleteLinks = useCallback(
    async (links: LinkApiType[]) => {
      return await askConfirmation(
        formatMessage(
          {
            id:
              links.length > 1
                ? LinkTranslation.linksViewDeleteMultipleLinksConfirmation
                : LinkTranslation.linksViewDeleteLinkConfirmation,
          },
          { count: links.length },
        ),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        const response = deleteLinksRepository(
          links.map(link => getLongId('links', link.id)),
        );

        // Subtract the link from linkCount for folder link was deleted from
        const folderId = links[0].folder?.id;
        if (folderId) {
          database.desktopFolders.get(folderId).then(folder => {
            if (!folder) {
              return;
            }
            const currentLinksCount = folder.sourceData.linksCount;
            database.desktopFolders.update(folder.id, {
              'sourceData.linksCount': Math.max(
                0,
                currentLinksCount - links.length,
              ),
            });
          });
        }

        return response;
      });
    },
    [askConfirmation, formatMessage],
  );

  return {
    deleteLinks,
  };
};
