import { TeamsTableType } from '../../../../Database/WorkspaceTeams/WorkspaceTeamsTable';
import { TeamApiType } from '../types/Team.types';

export const getRedundantCacheTeams = (
  cacheTeams: TeamsTableType[] = [],
  apiTeams: TeamApiType[] = [],
) => {
  const apiTeamsMap = apiTeams.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, TeamApiType>,
  );

  return cacheTeams.filter(team => !apiTeamsMap[team.id]);
};
