import React, { FC, memo, useCallback } from 'react';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { openUrl } from '../../../../../../shared/utils/url.utils';
import { useCurrentAccount } from '../../../../../Auth/Auth.hooks';
import { OpenType } from '../../../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { AccountOrigin } from '../../../../../User/User.types';
import { getHostname } from './RichMessage.utils';

interface RichMessageUrlProps {
  url: string;
}

const RichMessageUrl: FC<RichMessageUrlProps> = ({ url }) => {
  const { account } = useCurrentAccount();
  const openType = account?.identity.openLink || OpenType.newTab;

  const messageUrl =
    account?.origin === AccountOrigin.bot ? `${url}\n\n` : getHostname(url);

  const openLink = useCallback(
    (event: React.MouseEvent, linkUrl: string) => {
      event.preventDefault();

      const removeableTailChars = [
        '.',
        ',',
        ':',
        ';',
        '!',
        '@',
        '$',
        '&',
        '*',
        '(',
        ')',
      ];

      if (removeableTailChars.includes(linkUrl[linkUrl.length - 1])) {
        linkUrl = linkUrl.substring(0, linkUrl.length - 1);
      }

      openUrl(openType, linkUrl, linkUrl, event);
    },
    [openType],
  );

  return (
    <>
      <a
        onClick={e => openLink(e, url)}
        href={url}
        rel="noopener noreferrer"
        data-testid="link"
        data-tooltip-id="global-tooltip"
        data-tooltip-content={url}
        data-tooltip-place={TooltipPlace.top}>
        {messageUrl}
      </a>
    </>
  );
};

export default memo(RichMessageUrl);
