import {
  Auth0Client,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import delay from 'delay';
import {
  AUTH0_REDIRECT_URI,
  MFA_SESSION_STARTED_STORAGE_KEY,
} from './Auth.constants';
import {
  AuthSessionHandler,
  GetTokenOptions,
  LoginOptions,
  TriggerMfaOptions,
} from './Auth.types';
import { REQUIRE_MFA_SCOPE } from '../Onboarding/Onboarding.constants';
import { captureMessage } from '../ErrorInterceptor';
import { isTokenAboutToExpire } from '../../shared/utils/jwt.utils';
import { createPersistedLog } from '../PersistedLog/PersistedLog.utils';
import { setCookie } from '../../shared/utils/storage';

export enum Auth0Connection {
  google = 'google-oauth2',
  facebook = 'facebook',
  apple = 'apple',
  microsoft = 'windowslive',
}

const GET_TOKEN_EXPIRED_TOKEN_RETRY_TIME = 3000;
const GET_TOKEN_ON_ERROR_RETRY_TIME = 5000;

export class AuthService implements AuthSessionHandler {
  private scope: string | undefined;
  private onLogout: () => Promise<void>;

  constructor(private readonly auth0Client: Auth0Client) {
    this.onLogout = () => Promise.resolve();
  }

  public login(
    options: LoginOptions = {},
    auth0Options?: RedirectLoginOptions,
  ) {
    const { loginHint: login_hint, ...otherOptions } = options;

    createPersistedLog(
      `[AuthService] call login, will redirect to ${AUTH0_REDIRECT_URI}`,
      'auth',
    );

    this.auth0Client.loginWithRedirect({
      redirect_uri: AUTH0_REDIRECT_URI,
      login_hint,
      ...otherOptions,
      ...auth0Options,
    });
  }

  public handleLogin() {
    createPersistedLog(`[AuthService] call handleLogin`, 'auth');
    return this.auth0Client.handleRedirectCallback();
  }

  setOnLogoutCallback(callback: AuthService['onLogout']) {
    this.onLogout = callback;
  }

  public async logout(options?: LogoutOptions) {
    await this.onLogout();
    createPersistedLog('[AuthService] call AuthService.logout', 'auth');
    this.auth0Client.logout(options);
  }

  tryMfaSilently() {
    createPersistedLog('[AuthService] tryMfaSilently', 'auth');

    return this.getToken(
      {},
      {
        scope: REQUIRE_MFA_SCOPE,
      },
    )
      .then(token => {
        createPersistedLog(
          `[AuthService] tryMfaSilently getToken: ${!!token}`,
          'auth',
        );
        if (token) {
          this.scope = REQUIRE_MFA_SCOPE;
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  triggerMfa(options: TriggerMfaOptions) {
    createPersistedLog('[AuthService] triggerMfa');
    setCookie(MFA_SESSION_STARTED_STORAGE_KEY, new Date().toISOString());
    this.login(options, {
      scope: REQUIRE_MFA_SCOPE,
    });
    return Promise.resolve(false);
  }

  public getToken(
    options: GetTokenOptions = {},
    auth0Options: GetTokenSilentlyOptions = {},
    fetchAuthConfig: () => Promise<void> = () => Promise.resolve(),
  ): Promise<string | null> {
    const getTokenOption = { scope: this.scope, ...options, ...auth0Options };
    let expiredTokenRetried = false;
    let authConfigRefreshedOnError = false;

    const getTokenSilently = (): Promise<string | null> => {
      return this.auth0Client
        .getTokenSilently(getTokenOption)
        .then(token => {
          /*
           * This logic is a workaround covering an issue of getTokenSilently returning expired token
           * https://github.com/auth0/auth0-spa-js/issues/343
           *  */
          if (token && isTokenAboutToExpire(token, 0) && !expiredTokenRetried) {
            captureMessage(
              '[Auth.service] getTokenSilently returned expired token, retying',
            );
            createPersistedLog(
              '[Auth.service] getTokenSilently returned expired token, retying',
              'auth',
            );
            expiredTokenRetried = true;
            return delay(GET_TOKEN_EXPIRED_TOKEN_RETRY_TIME).then(() =>
              getTokenSilently(),
            );
          }
          return token;
        })
        .catch(async e => {
          createPersistedLog(
            `[AuthService] Error on getTokenSilently: ${e.error}`,
            'auth',
          );
          if (!authConfigRefreshedOnError) {
            authConfigRefreshedOnError = true;
            await fetchAuthConfig();
          }
          /*
           * We return null if user is unauthorised and retry with delay
           * for all other cases (Failed to fetch, 500, 502, etc.)
           * */
          if (e.error !== 'login_required') {
            return delay(GET_TOKEN_ON_ERROR_RETRY_TIME).then(() =>
              getTokenSilently(),
            );
          }
          return null;
        });
    };

    return getTokenSilently();
  }
}
