import React, { FC } from 'react';
import {
  ConferenceDetailsList,
  ConferenceDetailsListItem,
  ConferenceDetailsTitle,
} from './ConferenceDetails.styled';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import { useCurrentConference } from '../../../Conference.hooks';
import { addSeconds, format, formatDistance } from 'date-fns';
import { ConferenceDurationCounter } from './ConferenceDurationCounter';
import { appEnv } from '../../../../../appEnv';

export const ConferenceDetails: FC = () => {
  const { conference } = useCurrentConference();

  const createdAt =
    (!!conference?.lastStartedAt || !!conference?.createdAt) &&
    new Date(conference.lastStartedAt || conference.createdAt);

  const totalDuration = conference?.statistics.total_duration || null;

  const callParticipants =
    (conference && conference.statistics.participants) || null;

  const startedAt =
    callParticipants &&
    callParticipants.length > 1 &&
    callParticipants[1].joined;

  const endedAt =
    totalDuration && createdAt && addSeconds(createdAt, totalDuration);

  return (
    <div>
      <ConferenceDetailsTitle>
        <FormattedMessage
          id={ConferenceTranslation.conferenceViewDetailsTitle}
        />
      </ConferenceDetailsTitle>
      <ConferenceDetailsList>
        {createdAt && (
          <ConferenceDetailsListItem>
            <div className="label">
              <FormattedMessage
                id={ConferenceTranslation.conferenceViewDetailsItemStart}
              />
            </div>
            <div className="value">
              {format(createdAt, 'dd/MM/yyyy ' + appEnv.TIME_FORMAT)}
            </div>
          </ConferenceDetailsListItem>
        )}

        {startedAt && (
          <ConferenceDetailsListItem>
            <div className="label">
              <FormattedMessage
                id={ConferenceTranslation.conferenceViewDetailsItemDuration}
              />
            </div>
            <div className="value">
              {endedAt ? (
                formatDistance(new Date(startedAt), endedAt)
              ) : (
                <ConferenceDurationCounter startedAt={startedAt} />
              )}
            </div>
          </ConferenceDetailsListItem>
        )}
      </ConferenceDetailsList>
    </div>
  );
};
