import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile } from '../../shared/hooks';
import { VideoPlayerTranslation } from './i18n';
import {
  VideoPlayerClickableLayerStyled,
  VideoPlayerContainerStyled,
  VideoPlayerErrorStyled,
  VideoPlayerInnerContainerStyled,
  VideoPlayerStyled,
} from './VideoPlayer.styled';
import {
  PlayerControlsProps,
  VideoPlayerControls,
} from './controls/VideoPlayer.controls';

interface VideoPlayerProps {
  width?: number;
  height?: number;
  controls: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  source: string;
  className?: string;
  handlePause?: () => void;
  withCustomControls: boolean;
}

export interface VideoPlayerControlsProps {
  play: () => void;
  pause: () => void;
  paused: () => boolean;
  load: () => void;
  unload: () => void;
  enableControls: (state: boolean) => void;
}

export const VideoPlayer = React.forwardRef<
  VideoPlayerControlsProps,
  VideoPlayerProps
>(
  (
    {
      width = '100%',
      height = '100%',
      source,
      controls,
      loop = false,
      muted = false,
      autoPlay,
      className = '',
      withCustomControls,
      handlePause,
    },
    ref,
  ) => {
    const playerRef: LegacyRef<HTMLVideoElement> = useRef(null);
    const [error, setError] = useState(false);
    const [playerReadyState, setPlayerReadyState] = useState(false);
    const conrolsRef = useRef<PlayerControlsProps>(null);
    const isMobile = useMobile();

    useImperativeHandle(
      ref,
      () => ({
        play: () => {
          playerRef.current?.play();
        },
        pause: () => {
          playerRef.current?.pause();
        },
        paused: () => playerRef.current?.paused ?? true,
        load: () => {
          if (!playerRef.current) {
            return;
          }

          playerRef.current.setAttribute('src', source);
          playerRef.current.load();
        },
        unload: () => {
          if (!playerRef.current) {
            return;
          }
          playerRef.current.setAttribute('src', '');
          playerRef.current.load();
        },
        enableControls: (state: boolean) => {
          if (!playerRef.current) {
            return;
          }

          playerRef.current.controls = state;
        },
      }),
      [source],
    );

    const playerLoaded = useCallback(() => {
      setPlayerReadyState(true);
    }, []);

    const initListeners = useCallback(
      (player: HTMLVideoElement) => {
        if (!player) {
          return;
        }

        if (handlePause) {
          player.addEventListener('pause', handlePause);
        }

        player.addEventListener('loadeddata', playerLoaded);
      },
      [handlePause, playerLoaded],
    );

    const initPlayer = useCallback(() => {
      const player = playerRef.current;
      if (!player) {
        return;
      }

      if (autoPlay) {
        player.play();
      }

      if (loop) {
        player.loop = loop;
      }

      if (muted) {
        player.muted = muted;
      }

      initListeners(player);
    }, [autoPlay, initListeners, loop, muted]);

    const destroy = useCallback(() => {
      if (playerRef.current) {
        playerRef.current.removeAttribute('src');
      }
      setError(false);
    }, []);

    useEffect(() => {
      const player = playerRef.current;
      if (!player) {
        return;
      }

      initPlayer();

      return () => {
        destroy();
      };
    }, [destroy, initPlayer, playerRef]);

    const customControls = useMemo(() => {
      return playerReadyState && playerRef.current ? (
        <VideoPlayerControls ref={conrolsRef} player={playerRef.current} />
      ) : null;
    }, [conrolsRef, playerReadyState]);

    const handleClickOnPlayer = useCallback(() => {
      if (conrolsRef.current) {
        conrolsRef.current.togglePlay();
      }
    }, []);

    return (
      <VideoPlayerContainerStyled data-testid="video-player">
        {!error ? (
          <VideoPlayerInnerContainerStyled>
            {withCustomControls && (
              <VideoPlayerClickableLayerStyled onClick={handleClickOnPlayer} />
            )}
            <VideoPlayerStyled
              isMobile={isMobile}
              ref={playerRef}
              width={width}
              height={height}
              controls={controls}
              className={`videoPlayer ${className}`}
              disablePictureInPicture={true}
              controlsList="nodownload"
              preload="none">
              <source src={source} />
              <p>
                <FormattedMessage
                  id={VideoPlayerTranslation.playerErrorMessage}
                />
              </p>
            </VideoPlayerStyled>
          </VideoPlayerInnerContainerStyled>
        ) : (
          <VideoPlayerErrorStyled>
            <FormattedMessage id={VideoPlayerTranslation.playerErrorMessage} />
          </VideoPlayerErrorStyled>
        )}

        {withCustomControls && customControls}
      </VideoPlayerContainerStyled>
    );
  },
);
