export interface ChromeExtensionDesktopFormValues {
  name: string;
}

export enum ImportStepTypes {
  createImportDesktop = 'createImportDesktop',
  selectApps = 'selectApps',
  importAppsSuccess = 'importAppsSuccess',
  selectLinks = 'selectLinks',
  importLinksSuccess = 'importLinksSuccess',
}

export interface BookmarkType {
  dateAdded: number;
  id: string;
  title: string;
  url?: string;
  children?: BookmarkType[];
}

export interface TreeType {
  label: string;
  value: string;
  children?: TreeType[];
}

export interface ImportFolderType {
  folderName: string | null;
  links: ImportLinkType[];
}

export interface ImportLinkType {
  title: string;
  url: string;
}
