import React, { FC, useCallback } from 'react';
import { ChangeUserPasswordForm } from './ChangeUserPassword.form';
import {
  ChangeUserPasswordFormValues,
  ChangeUserPasswordInput,
} from '../../User.types';
import { useChangePasswordAccountMutation } from '../../User.hooks';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { UserTranslation } from '../../i18n';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';
import { useAuthService } from '../../../Auth/Auth.hooks';
import { useCurrentAccountKeyset } from '../../../Encryption/Encryption.hooks';
import {
  hashAuthPassword,
  hashMasterPassword,
} from '../../../../shared/utils/hash.utils';
import {
  encryptPrivateKey,
  exportPublicKey,
  generateRsaKeys,
  reencryptPrivateKey,
} from '../../../Encryption/Encryption.crypto.utils';
import { getAuth0ReturnUriHost } from '../../../Auth/Auth.utils';
import { LOGIN_PATHNAME } from '../../../Auth/Auth.constants';

export const ChangeUserPassword: FC<{
  onRequestClose: () => void;
}> = ({ onRequestClose }) => {
  const { account } = useCurrentWorkspaceAccount();
  const accountId = account?.id;
  const accountEmail = account?.email;
  const [changePasswordAccountMutation] = useChangePasswordAccountMutation();
  const authService = useAuthService();
  const { keyset } = useCurrentAccountKeyset();

  const onDone = useCallback(() => {
    if (onRequestClose) {
      onRequestClose();
    }
    showToastSuccessMessage(UserTranslation.formSuccessMessage);
    authService?.logout({
      returnTo: getAuth0ReturnUriHost() + LOGIN_PATHNAME,
    });
  }, [onRequestClose, authService]);

  const createNewKeyset = useCallback(
    (currentPassword: string, newPassword: string) => {
      if (keyset) {
        const { privKeyEncrypted } = keyset;
        return Promise.all([
          hashMasterPassword(currentPassword, accountEmail),
          hashMasterPassword(newPassword, accountEmail),
        ])
          .then(([currentPasswordHash, newPasswordHash]) => {
            return reencryptPrivateKey(
              privKeyEncrypted,
              currentPasswordHash,
              newPasswordHash,
              accountId,
            );
          })
          .then(reencryptedPrivateKey => [keyset.pubKey, reencryptedPrivateKey])
          .catch(() => {
            throw new Error(
              'Cannot create new Password manager keys. Probably your old password is incorrect.',
            );
          });
      }
      return Promise.all([
        hashMasterPassword(newPassword, accountEmail),
        generateRsaKeys(),
      ]).then(([newPasswordHash, { privateKey, publicKey }]) => {
        return Promise.all([
          exportPublicKey(publicKey),
          encryptPrivateKey(privateKey, newPasswordHash, accountId),
        ]);
      });
    },
    [accountId, accountEmail, keyset],
  );

  const changePassword = useCallback(
    (formValues: ChangeUserPasswordFormValues) => {
      return Promise.all([
        hashAuthPassword(formValues.currentPassword, accountEmail),
        hashAuthPassword(formValues.newPassword, accountEmail),
        createNewKeyset(
          formValues.currentPassword,
          formValues.newPassword,
        ).catch(() => {
          showToastErrorMessage(
            UserTranslation.profileFormPasswordErrorReencryptingKeys,
          );
          throw new Error('Error while creating new Password manager keys.');
        }),
      ])
        .then(
          ([
            currentPasswordHash,
            newPasswordHash,
            [pubKey, privKeyEncrypted],
          ]) => {
            const input: ChangeUserPasswordInput = {
              id: accountId,
              currentPassword: currentPasswordHash,
              newPassword: newPasswordHash,
              repeatPassword: newPasswordHash,
              pubKey,
              privKeyEncrypted,
            };
            return changePasswordAccountMutation({
              variables: {
                input,
              },
            }).catch(() => {
              // retry with plain text current password
              return changePasswordAccountMutation({
                variables: {
                  input: {
                    ...input,
                    currentPassword: formValues.currentPassword,
                  },
                },
              });
            });
          },
        )
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
          throw new Error('Error while preparing data for password change');
        });
    },
    [changePasswordAccountMutation, accountId, accountEmail, createNewKeyset],
  );

  const handleSubmit = useCallback(
    (formValues: ChangeUserPasswordFormValues) => {
      return changePassword(formValues)
        .then(onDone)
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [changePassword, onDone],
  );

  return (
    <ChangeUserPasswordForm onSubmit={handleSubmit} onCancel={onRequestClose} />
  );
};
