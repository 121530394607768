import { useCallback } from 'react';
import { useCurrentWorkspaceAccount } from '../../../../../Workspace/Workspace.hooks';
import { hasAccountReaction } from './MessageReactions.utils';
import type { ChatMessageInternalType } from '../../../../Chat.types';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';

export const useMessageReactionControls = (
  chatMessage: ChatMessageInternalType,
) => {
  const { account } = useCurrentWorkspaceAccount();
  const { addChatMessageReaction, removeChatMessageReaction } =
    useChatMessageRepository();

  const accountId: string | undefined = account?.id;

  const chatMessageId = chatMessage.id;

  const addReactionToChatMessage = useCallback(
    (emoji: string) => {
      if (!accountId) {
        return;
      }

      addChatMessageReaction(chatMessage.id, accountId, emoji);
    },
    [accountId, addChatMessageReaction, chatMessage.id],
  );

  const removeReactionFromChatMessage = useCallback(
    (emoji: string) => {
      if (!accountId) {
        return;
      }

      removeChatMessageReaction(chatMessageId, accountId, emoji);
    },
    [accountId, chatMessageId, removeChatMessageReaction],
  );

  const toggleChatMessageReaction = useCallback(
    (emoji: string) => {
      if (!accountId) {
        return;
      }

      if (hasAccountReaction(chatMessage.reactions, emoji, accountId)) {
        removeReactionFromChatMessage(emoji);
      } else {
        addReactionToChatMessage(emoji);
      }
    },
    [
      accountId,
      addReactionToChatMessage,
      chatMessage.reactions,
      removeReactionFromChatMessage,
    ],
  );

  return {
    addReactionToChatMessage,
    removeReactionFromChatMessage,
    toggleChatMessageReaction,
  };
};
