import styled from 'styled-components';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';

export const StyledSearch = styled.div`
  position: relative;

  svg + input {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
    border: none;
    padding: 0.25rem 0.625rem 0.25rem 1.75rem;
    line-height: 1.43;

    &:focus {
      padding: 0.125rem 0.5rem 0.125rem 1.625rem;
    }
  }

  svg {
    left: 0.5rem;
  }
`;

export const ClearSearchFieldButton = styled(ButtonWithIcon)`
  position: absolute;
  top: 0.125rem;
  right: 0;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
