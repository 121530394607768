import styled from 'styled-components';

export const StyledConferenceView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xxl} 0;
`;

export const ConferenceViewTopBar = styled.h2`
  display: flex;
  padding: 0.25rem 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 3.5rem;
  border-bottom: 1px solid ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Surface};
  font-size: 1rem;

  button ~ button {
    margin-left: 1rem;
  }

  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    + div {
      margin-left: 0.5rem;
    }
  }
`;

export const ConferenceViewSection = styled.div`
  max-width: 380px;
  width: 100%;
  margin-top: 2rem;
  padding: 0 1rem;
`;

export const ConferenceViewHeaderSection = styled.div`
  min-width: 5.5rem;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    text-align: right;
    margin-right: 1rem;
  `}
`;

export const ScheduledConferenceContentInner = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex-direction: initial;
  `}
`;
