import { gql } from '@apollo/client';

export const UPDATE_MFA = gql`
  mutation UpdateGeneralSettings(
    $input: updateGlobalSettingsAccountIdentityInput!
  ) {
    updateGlobalSettingsAccountIdentity(input: $input) {
      accountIdentity {
        mfaRequired
      }
    }
  }
`;

export const RESET_MFA = gql`
  mutation ResetMfaRequestWorkspace($input: resetMfaRequestWorkspaceInput!) {
    resetMfaRequestWorkspace(input: $input) {
      workspace {
        id
      }
    }
  }
`;

export const RESET_MFA_ACCOUNT = gql`
  mutation ResetMfaAccount($input: resetMfaAccountInput!) {
    resetMfaAccount(input: $input) {
      account {
        id
      }
    }
  }
`;
