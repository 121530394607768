import { FieldProps } from 'formik';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { InputWithIcon } from '../../../../shared/components/InputWithIcon/InputWithIcon';
import {
  showToastErrorMessage,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { CopyIcon, EyeClosedIcon, EyeIcon } from '../../../../shared/icons';
import { EncryptionTranslation } from '../../i18n';
import { TooltipPlace } from '../../../../shared/components/Tooltip';

enum PasswordFieldType {
  text = 'text',
  password = 'password',
}

export const PasswordFieldInner = styled.div`
  .input-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    height: 2rem;
    width: 2rem;

    &:first-child {
      right: 40px;
      left: auto;
    }

    &:nth-child(2) {
      right: 8px;
      left: auto;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
    }
  }
`;

export const StyledPasswordInputField = styled.div`
  input,
  input:focus {
    padding-right: 4.625rem;
  }
`;

export const PasswordInputFieldWithIcons: FC<FieldProps> = ({
  form,
  field,
  ...props
}) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const copyPassword = useCallback(() => {
    if (!passwordInputRef.current) {
      return;
    }

    navigator.clipboard
      .writeText(passwordInputRef.current.value)
      .then(() => {
        showToastSuccessMessage(
          EncryptionTranslation.editPasswordCopiedMessage,
        );
      })
      .catch(errorMessage => {
        showToastErrorMessage(errorMessage);
      });
  }, []);

  const handleCopyPasswordClick = useCallback(() => {
    setShowPassword(value => !value);
  }, []);

  return (
    <StyledPasswordInputField>
      <InputWithIcon
        ref={passwordInputRef}
        {...field}
        data-testid="vault-password-input"
        label={intl.formatMessage({
          id: EncryptionTranslation.editPasswordInputLabel,
        })}
        type={
          showPassword ? PasswordFieldType.text : PasswordFieldType.password
        }
        {...props}>
        <PasswordFieldInner>
          <ButtonWithIcon
            className="input-button"
            icon={CopyIcon}
            type="button"
            onClick={copyPassword}
            data-tooltip-place={TooltipPlace.bottom}
            data-tooltip-content={intl.formatMessage({
              id: EncryptionTranslation.editPasswordCopyPassword,
            })}
            data-testid="copy-password-button"
          />
          <ButtonWithIcon
            className="input-button"
            icon={!showPassword ? EyeIcon : EyeClosedIcon}
            type="button"
            onClick={handleCopyPasswordClick}
            data-tooltip-content={intl.formatMessage({
              id: !showPassword
                ? EncryptionTranslation.editPasswordShowPassword
                : EncryptionTranslation.editPasswordHidePassword,
            })}
            data-testid="show-password-button"
            data-tooltip-place={TooltipPlace.bottom}
          />
        </PasswordFieldInner>
      </InputWithIcon>
    </StyledPasswordInputField>
  );
};
