import {
  AuthSessionHandler,
  GetTokenOptions,
  LoginOptions,
} from '../../Auth/Auth.types';
import { NativeWrapperContextType } from '../NativeWrapper.types';
import { isTokenAboutToExpire } from '../../../shared/utils/jwt.utils';
import { TOKEN_EXPIRATION_THRESHOLD } from './NativeWrapperAuth.constants';

export class NativeWrapperAuthService implements AuthSessionHandler {
  private onLogout: () => Promise<void>;
  private getTokenPromise: Promise<string> | undefined;

  constructor(
    private readonly sendMessage: NativeWrapperContextType['sendMessageToNativeWrapper'],
    private readonly callRefreshToken: NativeWrapperContextType['callRefreshToken'],
    private readonly callTriggerMfa: NativeWrapperContextType['callTriggerMfa'],
    private accessToken: string | null,
  ) {
    this.onLogout = () => Promise.resolve();
  }

  login({ connection, appState, loginHint }: LoginOptions = {}) {
    this.sendMessage({
      type: 'LOGIN',
      connection: connection ?? null,
      appState: appState ?? null,
      loginHint: loginHint ?? null,
    });
  }

  handleLogin() {
    return Promise.reject('Handle login not implemented');
  }

  setOnLogoutCallback(callback: () => Promise<void>) {
    this.onLogout = callback;
  }

  async logout() {
    await this.onLogout();
    this.sendMessage({
      type: 'LOGOUT',
    });
  }

  tryMfaSilently() {
    return Promise.resolve(false);
  }

  async triggerMfa() {
    if (this.getTokenPromise) {
      await this.getTokenPromise;
    }
    return this.callTriggerMfa().then(newToken => {
      this.accessToken = newToken;
      return true;
    });
  }

  async getToken(options: GetTokenOptions = {}) {
    if (this.getTokenPromise) {
      return this.getTokenPromise;
    }
    if (this.accessToken === null) {
      return null;
    }
    if (
      isTokenAboutToExpire(this.accessToken, TOKEN_EXPIRATION_THRESHOLD) ||
      options.ignoreCache
    ) {
      this.getTokenPromise = this.callRefreshToken()
        .then(token => {
          this.accessToken = token;
          return token;
        })
        .catch(e => {
          return this.accessToken as string;
        })
        .finally(() => {
          this.getTokenPromise = undefined;
        });
      return this.getTokenPromise;
    }
    return this.accessToken;
  }
}
