import styled, { css } from 'styled-components';

export const StyledTabBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  min-height: 48px;
`;

export const TabsContainer = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100%;
      flex: 1;
      justify-content: center;
    `};
`;

export const TabBarButtons = styled.div``;
