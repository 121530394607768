import { useEffect, useMemo, useState } from 'react';
import type { ChatMessagesTableType } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { ChatMessageToChatMessageTableRow } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { ChatMessageThreadVisibility } from './ChatMessageThread.types';
import { getLastMessage } from './ChatMessageThread.utils';
import { useAccountsContext } from '../../../../Account';
import type { AccountWithCountsApiType } from '../../../../User/User.types';

export const useVisibleThreadMessages = (
  messages: ChatMessagesTableType[] | null,
) => {
  const [threadVisibilityMode, setThreadVisibiltyMode] =
    useState<ChatMessageThreadVisibility>(ChatMessageThreadVisibility.ShowLast);

  const [visibleThreadMessages, setVisibleThreadMessages] = useState<
    ChatMessagesTableType[]
  >([]);

  const [allThreadMessages, setAllThreadMessages] = useState<
    ChatMessagesTableType[]
  >([]);

  useEffect(() => {
    switch (threadVisibilityMode) {
      case ChatMessageThreadVisibility.ShowAll:
        setVisibleThreadMessages(allThreadMessages);

        if (messages?.length === 0) {
          setVisibleThreadMessages([]);
        }
        break;

      case ChatMessageThreadVisibility.ShowLast:
        if (messages?.length === 0) {
          setVisibleThreadMessages([]);
          break;
        }

        const lastMessage = getLastMessage(messages);

        if (lastMessage) {
          setVisibleThreadMessages([
            ChatMessageToChatMessageTableRow(lastMessage.data),
          ]);
        }

        break;

      case ChatMessageThreadVisibility.ShowNone:
        setVisibleThreadMessages([]);
        break;

      default:
        setVisibleThreadMessages([]);
        break;
    }
  }, [
    allThreadMessages,
    messages,
    threadVisibilityMode,
    visibleThreadMessages.length,
  ]);

  return {
    allThreadMessages,
    visibleThreadMessages,
    threadVisibilityMode,
    setAllThreadMessages,
    setThreadVisibiltyMode,
  };
};

export const useThreadMessageAuthors = (
  authorsIds: string[],
): AccountWithCountsApiType[] => {
  const { accountsWithAvailability } = useAccountsContext();
  const accounts = Object.values(accountsWithAvailability);

  return useMemo(
    () =>
      accounts.filter(account =>
        authorsIds.some(authorId => authorId === account.id),
      ),
    [accounts, authorsIds],
  );
};
