import React, { FC, useCallback, useRef, useState } from 'react';
import { LinkApiType, LinkFormValues } from '../../Link.types';
import { LinkForm } from '../LinkForm';
import { useQueryParams } from '../../../../shared/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { Spinner } from '../../../../shared/components/Spinner';
import { StyledEditLink } from './EditLink.styles';
import { showToastError } from '../../../../shared/components/Toast';
import { useCaseEditLink } from '../../UseCase/editLink';
import { useCaseGetLink } from '../../UseCase/getLink';
import { useEffectOnce } from 'react-use';
import { getLongId } from '../../../../shared/utils/id';

interface EditLinkProps {
  onDone: () => void;
}

export const EditLink: FC<EditLinkProps> = ({ onDone }) => {
  const { pathname } = useLocation();
  const queryParams = useQueryParams();
  const { editLink: linkId } = queryParams;

  const firstRun = useRef(true);
  const [linkData, setLinkData] = useState<LinkApiType>();
  const { getLinkUseCase, linkLoading } = useCaseGetLink();
  const getLinkData = async () => {
    const response = await getLinkUseCase(getLongId('links', linkId as string));
    firstRun.current = false;
    if (response?.data?.link) {
      setLinkData(response.data?.link);
    }
  };

  useEffectOnce(() => {
    getLinkData();
  });

  const { editLinkUseCase } = useCaseEditLink();

  const editLink = useCallback(
    ({ name, url, customImage }: LinkFormValues) => {
      return editLinkUseCase(linkData!, {
        name,
        url,
        ...(customImage ? { customImage } : {}),
      })
        .then(() => onDone())
        .catch(e => showToastError(e.graphQLErrors));
    },
    [linkData, editLinkUseCase, onDone],
  );

  if (linkLoading || !linkData) {
    return <Spinner containerHeight={160} />;
  }

  if (!linkLoading && !linkData && !firstRun.current) {
    return (
      <Navigate
        to={{
          pathname,
          search: getQueryParamsFrom({
            ...queryParams,
            editLink: undefined,
          }),
        }}
      />
    );
  }

  return (
    <StyledEditLink>
      <LinkForm link={linkData} onSubmit={editLink} onCancel={onDone} />
    </StyledEditLink>
  );
};
