import React, { FC, memo } from 'react';
import { useTheme } from 'styled-components';
import { SkeletonBox } from '../../../../../shared/components/SkeletonBox';
import { DesktopListItemSkeletonContainer } from './DesktopList.styled';

interface DesktopListItemSkeletonProps {
  count?: number;
}

export const DesktopListItemSkeleton: FC<DesktopListItemSkeletonProps> = memo(
  ({ count = 1 }) => {
    const { colors } = useTheme();

    return (
      <>
        {Array.from({ length: count }).map(() => {
          return (
            <DesktopListItemSkeletonContainer>
              <SkeletonBox
                height={1.5}
                color={colors.ChatViewSkeleton}
                borderRadius={0.3}
              />
            </DesktopListItemSkeletonContainer>
          );
        })}
      </>
    );
  },
);
