import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { PasswordTranslation } from '../i18n';
import { SetPassword } from '../SetPassword';
import { SetPasswordModalContent } from './SetPasswordModal.styled';

export const SetPasswordModal: FC<ModalProps> = ({
  onRequestClose,
  ...props
}) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={PasswordTranslation.setPasswordModalHeader} />
      </ModalHeader>

      <SetPasswordModalContent>
        <SetPassword
          onDone={onRequestClose}
          submitButtonMessageId={
            PasswordTranslation.setPasswordFormSubmitButton
          }
        />
      </SetPasswordModalContent>
    </Modal>
  );
};
