import styled from 'styled-components';

export const StyledLimitReachedBanner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LimitReachedBannerControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button ~ button {
    margin-left: 0.5rem;
  }
`;

export const LimitReachedBannerMessage = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  padding: 0 40px;
  max-width: 720px;
`;
