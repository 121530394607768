import React, { FC, useCallback } from 'react';
import {
  StyledItem,
  StyledItemBody,
  StyledItemType,
} from './SearchItems.styled';
import { GlobalSearchResultFolderHit } from '../../../GlobalSearch.queries';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { FolderIcon } from '../../../../../shared/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { FOLDER_PATHNAME } from '../../../../Folder/Folder.constants';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../../shared/utils/id';
import { useCurrentDesktopContent } from '../../../../Desktop/Desktop.hooks';
import { DesktopLayout } from '../../../../Desktop/Desktop.constants';

export const FolderItem: FC<
  GlobalSearchResultFolderHit & {
    onBlur: () => void;
  }
> = ({ id, name, onBlur, desktop }) => {
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const { setDesktopLayout } = useCurrentDesktopContent();

  const handleFolderClick = useCallback(() => {
    onBlur();
    setDesktopLayout(DesktopLayout.TABBED);
    navigate({
      pathname: generatePath(FOLDER_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: getShortId(desktop._id),
      }),
      search: getQueryParamsFrom({
        folderId: getShortId(id),
      }),
    });
  }, [onBlur, setDesktopLayout, navigate, workspace.id, desktop._id, id]);

  return (
    <StyledItem onClick={handleFolderClick}>
      <StyledItemBody>
        <FolderIcon />
        <span>{name}</span>
      </StyledItemBody>
      <StyledItemType>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchFolderType}
        />
      </StyledItemType>
    </StyledItem>
  );
};
