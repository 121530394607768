import {
  GET_CHAT_LINK,
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_SUBSET,
  GET_DRAFT_CHAT_MESSAGES,
  GetChatLinkResponse,
  GetChatLinkVariables,
  GetChatMessagesResponse,
  GetChatMessagesSubsetResponse,
  GetChatMessagesSubsetVariables,
  GetChatMessagesVariables,
  GetDraftChatMessagesResponse,
  GetDraftChatMessagesVariables,
} from '../../../Chat.queries';
import type {
  AddReactionToChatMessageResponse,
  AddReactionToChatMessageVariables,
  CreateChatMessageResponse,
  CreateChatMessageVariables,
  CreateDraftChatMessageResponse,
  CreateDraftChatMessageVariables,
  EditChatMessageResponse,
  EditChatMessageVariables,
  markAllMessagesAsReadInChatConversationResponse,
  markAllMessagesAsReadInChatConversationVariables,
  MarkMultipleMessagesAsReadResponse,
  MarkMultipleMessagesAsReadVariables,
  RemoveChatMessageResponse,
  RemoveChatMessageVariables,
  RemoveReactionFromChatMessageResponse,
  RemoveReactionFromChatMessageVariables,
} from '../../../Chat.mutations';
import {
  ADD_REACTION_TO_CHAT_MESSAGE,
  CREATE_CHAT_MESSAGE,
  CREATE_DRAFT_CHAT_MESSAGE,
  EDIT_CHAT_MESSAGE,
  MARK_ALL_MESSAGES_AS_READ_IN_CHAT_CONVERSATION,
  MARK_MULTIPLE_CHAT_MESSAGE_AS_READ,
  REMOVE_CHAT_MESSAGE,
  REMOVE_REACTION_FROM_CHAT_MESSAGE,
} from '../../../Chat.mutations';
import { getApolloClient, getRestApiClient } from '../../../../Api/Api.utils';

import { generateRandomId, getShortId } from '../../../../../shared/utils/id';
import { appEnv } from '../../../../../appEnv';
import { encodeMessageVariablesToQueryString } from '../DataSource.utils';
import type {
  GetAllChatMessageThreadsVariables,
  GetChatMessageByIdVariables,
  GetChatMessagesRestResponse,
} from './ChatMessages.types';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { MESSAGE_LIST_PAGE_SIZE } from '../../../ChatView/ConversationView/MessagesList/MessageList.constants';
import type { ChatMessageApiType } from '../../../Chat.types';
import { FetchResult } from '@apollo/client';
import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';

export const createChatMessageApi = async (
  message: string,
  optimisticId: string,
  parentChatMessageId: string | null = null,
  assets: string[] = [],
  conversationId: string,
  createdAt: string,
) => {
  if (!conversationId) {
    return Promise.reject();
  }

  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    CreateChatMessageResponse,
    CreateChatMessageVariables
  >({
    mutation: CREATE_CHAT_MESSAGE,
    variables: {
      input: {
        optimisticId,
        chatConversation: conversationId,
        message,
        assets,
        parentChatMessage: parentChatMessageId,
        createdAt,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const editChatMessageApi = (
  chatMessageId: string,
  message: string,
  assets: string[],
) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    EditChatMessageResponse,
    EditChatMessageVariables
  >({
    mutation: EDIT_CHAT_MESSAGE,
    variables: {
      input: {
        id: chatMessageId,
        message,
        assets,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const removeChatMessageApi = (chatMessageId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    RemoveChatMessageResponse,
    RemoveChatMessageVariables
  >({
    mutation: REMOVE_CHAT_MESSAGE,
    variables: {
      input: {
        id: chatMessageId,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const getChatMessagesApi = (
  variables: GetChatMessagesVariables,
): Promise<RestApiClientResponse<GetChatMessagesRestResponse>> => {
  const transactionId = generateRandomId();
  const { workspaceId, conversation } = variables;

  const urlParams = encodeMessageVariablesToQueryString(variables);
  const apiClient = getRestApiClient();

  if (!apiClient) {
    throw Error('No Api client');
  }

  return apiClient.fetch(
    `/workspace/${getShortId(workspaceId!)}/chat/${getShortId(
      conversation,
    )}/messages${urlParams ? '?&' + urlParams : ''}`,
  );
};

export const markMultipleChatMessagesAsSeenApi = (
  chatMessageIds: string[],
  conversationId: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    MarkMultipleMessagesAsReadResponse,
    MarkMultipleMessagesAsReadVariables
  >({
    mutation: MARK_MULTIPLE_CHAT_MESSAGE_AS_READ,
    variables: {
      input: {
        id: conversationId,
        chatMessages: chatMessageIds,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
};

export const markAllChatMessagesAsSeenApi = (conversationId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    markAllMessagesAsReadInChatConversationResponse,
    markAllMessagesAsReadInChatConversationVariables
  >({
    mutation: MARK_ALL_MESSAGES_AS_READ_IN_CHAT_CONVERSATION,
    variables: {
      input: {
        id: conversationId,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
};

export const getChatMessagesSubsetAround = (
  date: Date,
  conversationId: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .query<GetChatMessagesSubsetResponse, GetChatMessagesSubsetVariables>({
      query: GET_CHAT_MESSAGES_SUBSET,
      variables: {
        conversation: conversationId,
        date: date.toISOString(),
        amount: MESSAGE_LIST_PAGE_SIZE,
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => [
      ...extractNodes(response.data.beforeMessages).reverse(),
      ...extractNodes(response.data.afterMessages),
    ]);
};

export const createDraftChatMessageApi = (
  conversationId: string,
  message: string = '',
  assets: string[] = [],
) => {
  if (!conversationId) {
    return Promise.reject();
  }

  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    CreateDraftChatMessageResponse,
    CreateDraftChatMessageVariables
  >({
    mutation: CREATE_DRAFT_CHAT_MESSAGE,
    variables: {
      input: {
        chatConversation: conversationId,
        message,
        assets,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const addReactionToChatMessageApi = (
  chatMessageId: string,
  emoji: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    AddReactionToChatMessageResponse,
    AddReactionToChatMessageVariables
  >({
    mutation: ADD_REACTION_TO_CHAT_MESSAGE,
    variables: {
      input: {
        id: chatMessageId,
        emoji,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const removeReactionToChatMessageApi = (
  chatMessageId: string,
  emoji: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    RemoveReactionFromChatMessageResponse,
    RemoveReactionFromChatMessageVariables
  >({
    mutation: REMOVE_REACTION_FROM_CHAT_MESSAGE,
    variables: {
      input: {
        id: chatMessageId,
        emoji,
      },
    },
    fetchPolicy: 'no-cache',
  });
};

export const getChatMessagesGraphQLApi = (
  variables: GetChatMessagesVariables,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .query<GetChatMessagesResponse, GetChatMessagesVariables>({
      query: GET_CHAT_MESSAGES,
      variables,
      fetchPolicy: 'no-cache',
    })
    .then(response => {
      return {
        messages: extractNodes(response.data.listChatMessages).reverse(),
        pageInfo: response.data.listChatMessages.pageInfo,
      };
    });
};

export const getDraftChatMessagesApi = (
  chatMessageId: string,
): Promise<
  FetchResult<
    GetDraftChatMessagesResponse,
    Record<string, any>,
    Record<string, any>
  >
> => {
  const apolloClient = getApolloClient();

  return apolloClient!.mutate<
    GetDraftChatMessagesResponse,
    GetDraftChatMessagesVariables
  >({
    mutation: GET_DRAFT_CHAT_MESSAGES,
    variables: {
      workspace: chatMessageId,
    },
    fetchPolicy: 'no-cache',
  });
};

export const getChatMessageByIdApi = (
  variables: GetChatMessageByIdVariables,
) => {
  const transactionId = generateRandomId();
  const { workspaceId, conversationId, chatMessageId, token } = variables;

  return fetch(
    `${appEnv.API_REST_URL}/workspace/${workspaceId}/chat/${conversationId}/messages/${chatMessageId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Request-ID': transactionId,
      },
    },
  )
    .then(response => response.json())
    .then((chatMessage: ChatMessageApiType) => chatMessage);
};

export const getAllChatMessageThreadsApi = (
  variables: GetAllChatMessageThreadsVariables,
) => {
  const transactionId = generateRandomId();
  const { workspaceId, conversationId, chatMessageId, token } = variables;

  return fetch(
    `${appEnv.API_REST_URL}/workspace/${workspaceId}/chat/${conversationId}/messages/${chatMessageId}/threads`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Request-ID': transactionId,
      },
    },
  )
    .then(response => response.json())
    .then((chatMessages: ChatMessageApiType[]) => chatMessages);
};

export const getChatLinkApi = (linkId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!.query<GetChatLinkResponse, GetChatLinkVariables>({
    query: GET_CHAT_LINK,
    variables: {
      id: linkId,
    },
    fetchPolicy: 'no-cache',
  });
};
