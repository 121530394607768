import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const FullScreenIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 10.5C10.6119 10.5 10.5 10.6119 10.5 10.75V13.25C10.5 13.6642 10.1642 14 9.75 14C9.33579 14 9 13.6642 9 13.25V10.75C9 9.7835 9.7835 9 10.75 9H13.25C13.6642 9 14 9.33579 14 9.75C14 10.1642 13.6642 10.5 13.25 10.5H10.75ZM18 9.75C18 9.33579 18.3358 9 18.75 9H21.25C22.2165 9 23 9.7835 23 10.75V13.25C23 13.6642 22.6642 14 22.25 14C21.8358 14 21.5 13.6642 21.5 13.25V10.75C21.5 10.6119 21.3881 10.5 21.25 10.5H18.75C18.3358 10.5 18 10.1642 18 9.75ZM9.75 18C10.1642 18 10.5 18.3358 10.5 18.75V21.25C10.5 21.3881 10.6119 21.5 10.75 21.5H13.25C13.6642 21.5 14 21.8358 14 22.25C14 22.6642 13.6642 23 13.25 23H10.75C9.7835 23 9 22.2165 9 21.25V18.75C9 18.3358 9.33579 18 9.75 18ZM22.25 18C22.6642 18 23 18.3358 23 18.75V21.25C23 22.2165 22.2165 23 21.25 23H18.75C18.3358 23 18 22.6642 18 22.25C18 21.8358 18.3358 21.5 18.75 21.5H21.25C21.3881 21.5 21.5 21.3881 21.5 21.25V18.75C21.5 18.3358 21.8358 18 22.25 18Z"
        fill="currentColor"
      />
    </svg>
  );
});
