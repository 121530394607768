import React, { FC, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Link as LinkComponent } from '../../../Link/LinksView/Link';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { NotificationTranslation } from '../../i18n';
import { NotificationMessageDesktopLinkFields } from '../../Notifications.types';
import { DESKTOP_ID_PATHNAME } from '../../../Desktop/Desktop.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import styled, { useTheme } from 'styled-components';
import { Spinner } from '../../../../shared/components/Spinner';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { LinkApiType } from '../../../Link/Link.types';
import { useCaseGetLink } from '../../../Link/UseCase/getLink';
import { useEffectOnce } from 'react-use';
import { getShortId } from '../../../../shared/utils/id';

interface LinkPreviewModalProps {
  fields: NotificationMessageDesktopLinkFields;
  visible: boolean;
  onRequestClose: () => void;
}

const LinkPreviewModalContent = styled.div`
  padding: 1rem 1rem 1.5rem 1rem;
`;

const LinkPreviewModalDesktop = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  word-break: break-all;
  word-break: break-word;
`;

export const LinkPreviewModal: FC<LinkPreviewModalProps> = ({
  fields,
  visible,
  onRequestClose,
}) => {
  const { workspace } = useCurrentWorkspace();
  const desktopUrl = generatePath(DESKTOP_ID_PATHNAME, {
    workspaceId: getShortId(workspace.id),
    desktopId: fields.desktop_id,
  });
  const theme = useTheme();

  const [linkData, setLinkData] = useState<LinkApiType>();
  const { getLinkUseCase, linkLoading } = useCaseGetLink();

  const getLinkData = async () => {
    const response = await getLinkUseCase(fields.link_iri);
    if (response?.data?.link) {
      setLinkData(response.data.link);
    }
  };

  useEffectOnce(() => {
    getLinkData();
  });

  return (
    <Modal
      width={384}
      onRequestClose={onRequestClose}
      visible={visible}
      containerZIndex={theme.zIndexLevels.NotificationList + 1}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={NotificationTranslation.linkPreviewModalTitle}
            />
          </ModalHeaderTitle>
        </div>
      </ModalHeader>
      <LinkPreviewModalContent>
        {linkLoading || !linkData ? (
          <Spinner />
        ) : (
          <>
            {linkData && (
              //TODO Remove ts-ignore
              //@ts-ignore
              <DndProvider backend={HTML5Backend}>
                <LinkComponent link={linkData} dragEnabled={false} />
              </DndProvider>
            )}
          </>
        )}
        <LinkPreviewModalDesktop>
          <FormattedMessage
            tagName="span"
            id={NotificationTranslation.linkPreviewModalDesktopLink}
          />
          <Link to={desktopUrl}>{fields.desktop_name}</Link>
        </LinkPreviewModalDesktop>
      </LinkPreviewModalContent>
    </Modal>
  );
};
