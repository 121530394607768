import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { LocalForageWrapper } from 'apollo3-cache-persist';
import { useAuthContext, useAuthService } from '../Auth/Auth.hooks';
import { createApolloClient, createRestApiClient } from './Api.utils';
import {
  useIsPersistedStorageAvailable,
  usePersistedStorage,
} from '../PersistedStorage';
import { AppShellSkeleton } from '../AppRoot/AppShellSkeleton';
import { FetchPolicyProvider } from './FetchPolicy';
import { clearBrowserStoredData } from '../Auth/Auth.utils';
import { clearCEPH } from '../Encryption/Encryption.utils';
import { RestApiClient } from './RestApiClient/RestApiClient';
import { RestApiClientProvider } from './RestApiClient/RestApiClient.provider';
import { clearIndexedDB } from '../Database/Database.utils';

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const authService = useAuthService();
  const { storage } = usePersistedStorage();
  const apiClientCreationRequestedRef = useRef(false);
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(
    null,
  );
  const [restApiClient, setRestApiClient] = useState<RestApiClient | null>(
    null,
  );
  const { fetchAuthConfig } = useAuthContext();

  const isPersistedStorageAvailable = useIsPersistedStorageAvailable();

  useEffect(() => {
    if (apiClientCreationRequestedRef.current) {
      return;
    }

    apiClientCreationRequestedRef.current = true;

    createApolloClient(
      authService,
      isPersistedStorageAvailable ? new LocalForageWrapper(storage) : undefined,
      fetchAuthConfig,
    ).then(setApolloClient);

    createRestApiClient(authService).then(setRestApiClient);
  }, [authService, isPersistedStorageAvailable, storage, fetchAuthConfig]);

  useEffect(() => {
    if (apolloClient) {
      authService.setOnLogoutCallback(async () => {
        await apolloClient.clearStore();
        await clearBrowserStoredData();
        await clearCEPH().catch(() => {});
        await clearIndexedDB();
      });
    }
  }, [apolloClient, authService]);

  if (!restApiClient || !apolloClient) {
    return <AppShellSkeleton />;
  }

  return (
    <RestApiClientProvider client={restApiClient}>
      <ApolloProvider client={apolloClient}>
        <FetchPolicyProvider>{children}</FetchPolicyProvider>
      </ApolloProvider>
    </RestApiClientProvider>
  );
};
