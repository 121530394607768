import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  ChangeEvent,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../../../../../../Intl/i18n/i18n.types';
import { ButtonWithIcon } from '../../../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useMobile } from '../../../../../../../../shared/hooks';
import { ClearIcon, SearchIcon } from '../../../../../../../../shared/icons';
import { CancelButton, Input, StyledSearchInput } from './SearchInput.styled';
import { useDebounce } from 'use-debounce';
import {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

type SearchInputProps = {
  placeholder?: string;
  onChange: (e: string) => void;
  onBlur: () => void;
  clearSearch?: () => void;
  styles?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
};

export interface SearchInputControls {
  forceBlur: () => void;
  anchorElement?: HTMLDivElement | null;
}

const ESCAPE_KEY = 'Escape';
const SEARCH_DEBOUNCE_TIME = 500;

export const SearchInput = forwardRef<SearchInputControls, SearchInputProps>(
  ({ placeholder, onChange, onBlur, clearSearch, styles }, componentRef) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputElement = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [debouncedSearchValue] = useDebounce(
      searchValue,
      SEARCH_DEBOUNCE_TIME,
    );
    const isMobile = useMobile();

    const clearInputHandler = useCallback(() => {
      inputElement.current!.value = '';
      onChange('');
      setSearchValue('');
      inputElement.current!.focus();
    }, [onChange]);

    useEffect(() => {
      onChange(debouncedSearchValue);
    }, [onChange, debouncedSearchValue]);

    useEffect(() => {
      containerRef.current!.classList.add('is-active');
      inputElement.current!.focus();
    }, []);

    const clearAndCloseSearch = useCallback(() => {
      onChange('');
      setSearchValue('');
      clearSearch?.();
    }, [clearSearch, onChange]);

    /**
     * Component blur handling
     */
    const searchSectionBlur = useCallback(() => {
      if (!searchValue) {
        clearAndCloseSearch();
      }
    }, [clearAndCloseSearch, searchValue]);

    const outsideClickHandler = useCallback(
      (event: Event) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          searchSectionBlur();
        }
      },
      [searchSectionBlur],
    );

    useImperativeHandle(componentRef, () => ({
      forceBlur() {
        searchSectionBlur();
      },
      anchorElement: containerRef.current || null,
    }));

    useEffect(() => {
      document.addEventListener('mousedown', outsideClickHandler);
      return () => {
        document.removeEventListener('mousedown', outsideClickHandler);
      };
    }, [outsideClickHandler]);

    /**
     * Blur component on ESC
     */

    const closeOnEscapeKeydown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === ESCAPE_KEY) {
          clearAndCloseSearch();
        }
      },
      [clearAndCloseSearch],
    );

    useEffect(() => {
      document.addEventListener('keydown', closeOnEscapeKeydown, false);
      return () =>
        document.removeEventListener('keydown', closeOnEscapeKeydown, false);
    }, [closeOnEscapeKeydown]);

    const handleOnChangeSearchValue = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
      },
      [],
    );

    return (
      <>
        <StyledSearchInput ref={containerRef} styles={styles}>
          <SearchIcon width={12} height={16} />
          <Input
            ref={inputElement}
            placeholder={placeholder}
            onChange={handleOnChangeSearchValue}
          />
          {searchValue && (
            <ButtonWithIcon onClick={clearInputHandler} icon={ClearIcon} />
          )}
        </StyledSearchInput>
        {isMobile && (
          <CancelButton type="button" onClick={clearAndCloseSearch}>
            <FormattedMessage id={GlobalTranslation.cancel} />
          </CancelButton>
        )}
      </>
    );
  },
);
