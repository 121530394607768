import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { LinkApiType } from '../../../Link/Link.types';
import { getLinkTitle } from '../../../Link/Link.utils';
import { getImageSrcSet } from '../../../../shared/utils/url.utils';

type UnreadableFallbackProps = {
  link: LinkApiType;
};

const StyledUnreadableFallback = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xxl};
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Screenshot = styled.img`
  border-radius: 8px;
  width: 100%;
  height: auto;
  border: 1px solid ${({ theme }) => theme.colors.BorderLight};
`;

export const UnreadableFallback: FC<UnreadableFallbackProps> = ({ link }) => {
  const linkTitle = useMemo(() => getLinkTitle(link), [link]);

  return (
    <StyledUnreadableFallback>
      <Title title={linkTitle}>{linkTitle}</Title>
      {link.data.screenshot?.contentUrl && (
        <Screenshot
          srcSet={getImageSrcSet(link.data.screenshot.contentUrl, 600)}
        />
      )}
    </StyledUnreadableFallback>
  );
};
