import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderTitle,
  DrawerHeader,
} from '../../../../Admin';
import { useMobile, useQueryParams } from '../../../../../shared/hooks';
import { getWorkspaceAdminCloseLink } from '../../../../Workspace/Workspace.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspaceLastOpenedDesktop,
} from '../../../../Workspace/Workspace.hooks';
import { AppStoreTranslation } from '../../../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import {
  useCustomAppStoreApps,
  useDeleteCustomAppMutation,
} from '../../../AppStore.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { LoadMoreObserver } from '../../../../../shared/components/LoadMore';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { mergeWithArray } from '../../../../../shared/utils/list.utils';
import {
  GET_CUSTOM_APPSTORE_APPS,
  GetCustomAppStoreAppsResponse,
  GetCustomAppStoreAppsVariables,
} from '../../../AppStore.queries';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { AppStoreAppApiType } from '../../../AppStore.types';
import { deleteCustomAppStoreAppFromCache } from '../../../AppStore.utils';
import { useConfirm } from '../../../../../shared/components/Modal';
import { AppsGrid, NoAppsAvailable } from '../AppStore.styled';
import { AppCard } from '../../AppCard';
import { CreateCustomAppModal } from '../../../Modals/CreateCustomAppModal';
import { EditAppModal } from '../../../Modals/EditCustomAppModal';
import { WorkspaceContext } from '../../../../Workspace/Workspace.context';
import { getShortId } from '../../../../../shared/utils/id';

const DEFAULT_PAGE_SIZE = 30;
const LOAD_MORE_RETRY_TIMEOUT = 5000;

export const CustomAppsAdmin: FC = () => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { lastOpenedDesktop } = useWorkspaceLastOpenedDesktop();
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const {
    permissions: { canViewCustomAppsPage, canAddCustomApp },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  const queryParams = useQueryParams();
  const { createCustomAppModal, editAppId } = queryParams;

  const [loadingMoreApps, setLoadingMoreApps] = useState(false);

  const customAppsQueryVariables = useMemo(
    () => ({
      filterUsedByMe: false,
      category: null,
      custom: true,
      workspace: currentWorkspace.id,
      first: DEFAULT_PAGE_SIZE,
      filterFreeTextSearch: null,
      filterExistVaultItemsByWorkspace: null,
    }),
    [currentWorkspace.id],
  );

  const {
    data: appsData,
    loading: loadingApps,
    refetch: refetchApps,
    fetchMore: fetchMoreApps,
  } = useCustomAppStoreApps({
    variables: customAppsQueryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const apps = useMemo(
    () => extractNodes(appsData?.listCustomWorkspaceApps),
    [appsData],
  );

  const handleLoadMore = useCallback(() => {
    setLoadingMoreApps(true);
    fetchMoreApps({
      variables: {
        after: appsData?.listCustomWorkspaceApps.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return mergeWithArray(
          // TODO: Get rid of this fallback after Apollo issue will be resolved - https://github.com/apollographql/apollo-client/issues/5169
          prev || appsData,
          fetchMoreResult || {},
        ) as GetCustomAppStoreAppsResponse;
      },
    })
      .then(() => setLoadingMoreApps(false))
      .catch(e => {
        setTimeout(() => setLoadingMoreApps(false), LOAD_MORE_RETRY_TIMEOUT);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [appsData, fetchMoreApps]);

  const [deleteCustomAppMutation] = useDeleteCustomAppMutation();

  const deleteCustomApp = useCallback(
    (app: AppStoreAppApiType) => {
      deleteCustomAppMutation({
        variables: { input: { id: app.id } },
        update: (proxy, { data }) => {
          if (!data) {
            return;
          }

          try {
            const appStoreAppsCache = proxy.readQuery<
              GetCustomAppStoreAppsResponse,
              GetCustomAppStoreAppsVariables
            >({
              query: GET_CUSTOM_APPSTORE_APPS,
              variables: customAppsQueryVariables,
            });
            if (appStoreAppsCache) {
              proxy.writeQuery<
                GetCustomAppStoreAppsResponse,
                GetCustomAppStoreAppsVariables
              >({
                query: GET_CUSTOM_APPSTORE_APPS,
                variables: customAppsQueryVariables,
                data: deleteCustomAppStoreAppFromCache(
                  appStoreAppsCache,
                  data.deleteApp.app.id,
                ),
              });
            }
          } catch (e) {}
        },
      })
        .then(() => {
          showToastSuccessMessage(
            AppStoreTranslation.deleteCustomAppNotification,
            { appName: app.fullName },
          );
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [deleteCustomAppMutation, customAppsQueryVariables],
  );

  const handleDeleteCustomAppClick = useCallback(
    (app: AppStoreAppApiType) => {
      askConfirmation(
        intl.formatMessage({
          id: AppStoreTranslation.deleteCustomAppConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteCustomApp(app);
      });
    },
    [askConfirmation, deleteCustomApp, intl],
  );

  if (permissionsLoaded && !canViewCustomAppsPage) {
    navigate(`/workspace/${getShortId(currentWorkspace.id)}/admin`);
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <div>
      {isMobile && (
        <DrawerHeader
          data-testid="heading"
          onRequestClose={() =>
            navigate(
              getWorkspaceAdminCloseLink(currentWorkspace, lastOpenedDesktop),
            )
          }>
          <FormattedMessage id={AppStoreTranslation.customAppsTabFilter} />
        </DrawerHeader>
      )}
      <AdminHeader data-testid="header">
        {!isMobile && (
          <AdminHeaderTitle>
            <FormattedMessage id={AppStoreTranslation.customAppsTabFilter} />
          </AdminHeaderTitle>
        )}
        <AdminHeaderControls data-testid="controls">
          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.sm}
            disabled={!canAddCustomApp}
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  createCustomAppModal: true,
                }),
              })
            }>
            <FormattedMessage id={AppStoreTranslation.customAppBannerButton} />
          </Button>
        </AdminHeaderControls>
      </AdminHeader>
      {loadingApps && !loadingMoreApps ? (
        <Spinner size={64} containerHeight={200} />
      ) : !apps.length ? (
        <NoAppsAvailable>
          <FormattedMessage id={AppStoreTranslation.noAppsAvailable} />
        </NoAppsAvailable>
      ) : (
        <>
          <AppsGrid data-testid="apps-grid">
            {apps.map((app, index) => (
              <AppCard
                key={app.id}
                app={app}
                index={index}
                totalApps={apps.length}
                onDeleteCustomAppClick={handleDeleteCustomAppClick}
              />
            ))}
          </AppsGrid>
          {appsData?.listCustomWorkspaceApps.pageInfo?.hasNextPage && (
            <LoadMoreObserver
              loading={loadingMoreApps}
              onLoadMore={handleLoadMore}
            />
          )}
        </>
      )}
      {createCustomAppModal && (
        <CreateCustomAppModal
          showBannerOnDone={false}
          onDone={() => refetchApps()}
        />
      )}
      {editAppId && <EditAppModal />}
    </div>
  );
};
