import styled, { createGlobalStyle } from 'styled-components';

const LOGO_SECTION_HEIGHT = 60;

export const FailedServiceAuthPageBodyGlobalStyles = createGlobalStyle`
  body {
    background-color: ${p => p.theme.colors.OnboardingPageBackground};
  }
`;

export const AppLogo = styled.div`
  display: flex;
  justify-content: center;
  height: ${LOGO_SECTION_HEIGHT}px;
  padding: 0.5rem;
`;

export const FailedServiceAuthContent = styled.div`
  min-height: calc(100vh - ${LOGO_SECTION_HEIGHT}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FailedServiceAuthInner = styled.div`
  margin: 0 auto;
  max-width: 384px;
  padding: 1.5rem;
  background-color: ${p => p.theme.colors.Surface};
  border-radius: 16px;
  text-align: center;
`;

export const FailedServiceAuthTitle = styled.h1`
  font-size: 1.5rem;
  font-family: ${p => p.theme.fonts.Medium};
  margin-bottom: 1.5rem;
`;

export const FailedServiceAuthDescription = styled.p`
  font-family: ${p => p.theme.fonts.Medium};
  margin-bottom: 2.25rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
