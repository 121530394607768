import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { DesktopCopyPasteContext } from './DesktopCopyPaste.context';
import { LinkApiType } from '../../Link/Link.types';

export const DesktopCopyPasteProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [copiedLinks, setCopiedLinks] = useState<LinkApiType[]>([]);

  const copyLinks = useCallback((links: LinkApiType[]) => {
    setCopiedLinks(links);
  }, []);

  return (
    <DesktopCopyPasteContext.Provider
      value={{
        copyLinks,
        copiedLinks,
      }}>
      {children}
    </DesktopCopyPasteContext.Provider>
  );
};
