import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DangerZone,
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneConfirmationMessage,
  DangerZoneHeaderTitle,
  DangerZoneWarning,
  Section,
  SectionBody,
  SectionHeader,
} from '../../../../../Admin';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { UserTranslation } from '../../../../i18n';
import { useConfirm } from '../../../../../../shared/components/Modal';
import {
  useActivateWorkspaceUserMutation,
  useRemoveUserWorkspaceMutation,
  useSuspendWorkspaceUserMutation,
} from '../../../../User.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../../Workspace/Workspace.hooks';
import { useCurrentAccount } from '../../../../../Auth/Auth.hooks';
import { useNavigate } from 'react-router-dom';
import { useModalControls } from '../../../../../../shared/components/Modal/Modal.hooks';
import { MakeOwnerModal } from './MakeOwner';
import {
  GRAPHQL_TYPENAME_ACCOUNT,
  WorkspaceAccountGroupIdentity,
} from '../../../../User.constants';
import { useResetMfa } from '../../../../../MFA/Mfa.hooks';
import { useAccountsContext } from '../../../../../Account';
import { AccountApiType } from '../../../../User.types';
import { getShortId } from '../../../../../../shared/utils/id';

interface DangerZoneSectionProps {
  userAccount: AccountApiType;
  groupIdentifier: WorkspaceAccountGroupIdentity;
  active: boolean;
  refetch: () => void;
  showResetMfaButton: boolean;
}

export const DangerZoneSection: FC<DangerZoneSectionProps> = ({
  userAccount,
  groupIdentifier,
  active,
  refetch,
  showResetMfaButton = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { askConfirmation } = useConfirm();
  const [resetMfaMutation] = useResetMfa();

  const [removeUserWorkspaceMutation] = useRemoveUserWorkspaceMutation();
  const [suspendWorkspaceUserMutation] = useSuspendWorkspaceUserMutation();
  const [activateWorkspaceUserMutation] = useActivateWorkspaceUserMutation();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const {
    permissions: { canEditWorkspace, canResetMfaRequest },
    refetchPermissions,
  } = useCurrentWorkspacePermissions();
  const { refetchAccountData } = useCurrentAccount();
  const { refetchAccounts } = useAccountsContext();
  const intl = useIntl();
  const navigate = useNavigate();
  const makeOwnerConfirmationModalControls = useModalControls();

  /**
   * Make owner
   */

  const onDidMakeOwner = useCallback(() => {
    refetch();
    refetchAccountData?.();
    refetchAccounts();
    refetchPermissions();
    showToastSuccessMessage(UserTranslation.adminDangerZoneMakeOwnerSuccess);
    makeOwnerConfirmationModalControls.close();
  }, [
    refetch,
    refetchAccountData,
    refetchAccounts,
    refetchPermissions,
    makeOwnerConfirmationModalControls,
  ]);

  /**
   * Remove user
   */
  const removeUser = useCallback(
    () =>
      removeUserWorkspaceMutation({
        variables: {
          input: {
            id: currentWorkspace!.id,
            account: userAccount.id,
          },
        },
        update: (cache, result) => {
          if (!result.data) {
            return;
          }
          cache.evict({
            id: `${GRAPHQL_TYPENAME_ACCOUNT}:${userAccount.id}`,
          });
        },
      })
        .then(() => {
          refetch();
          refetchAccounts();
          showToastSuccessMessage(
            UserTranslation.adminDangerZoneRemoveUserSuccess,
            {
              user: userAccount.firstName,
            },
          );
          navigate(`/workspace/${getShortId(currentWorkspace.id)}/admin/users`);
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [
      removeUserWorkspaceMutation,
      currentWorkspace,
      userAccount.id,
      userAccount.firstName,
      refetch,
      refetchAccounts,
      navigate,
    ],
  );

  const handleRemoveUserClick = useCallback(() => {
    const confirmationMessage = (
      <DangerZoneConfirmationMessage>
        {intl.formatMessage(
          {
            id: UserTranslation.adminDangerZoneRemoveUserModalMessage,
          },
          {
            user: userAccount.firstName,
          },
        )}
      </DangerZoneConfirmationMessage>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.adminDangerZoneRemoveUserModalHeader,
      }),
      {
        width: 360,
        dangerConfirm: true,
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      return removeUser();
    });
  }, [intl, userAccount.firstName, askConfirmation, removeUser]);

  /**
   * Reset MFA
   */

  const handleResetMfaClick = useCallback(() => {
    const confirmationMessage = (
      <DangerZoneConfirmationMessage>
        {intl.formatMessage(
          {
            id: UserTranslation.adminDangerZoneResetMfaModalMessage,
          },
          {
            user: userAccount.firstName,
          },
        )}
      </DangerZoneConfirmationMessage>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.adminDangerZoneResetMfaModalHeader,
      }),
      {
        width: 360,
        dangerConfirm: true,
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      return resetMfaMutation({
        variables: {
          input: {
            id: currentWorkspace!.id,
            account: userAccount.id,
          },
        },
      })
        .then(() => {
          refetch();
          refetchAccounts();
          showToastSuccessMessage(
            UserTranslation.adminDangerZoneResetMfaSuccessMessage,
          );
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    });
  }, [
    intl,
    userAccount.firstName,
    userAccount.id,
    askConfirmation,
    resetMfaMutation,
    currentWorkspace,
    refetch,
    refetchAccounts,
  ]);

  /**
   * Suspend user
   */
  const suspendUser = useCallback(
    () =>
      suspendWorkspaceUserMutation({
        variables: {
          input: {
            id: currentWorkspace!.id,
            account: userAccount.id,
          },
        },
      })
        .then(() => {
          refetch();
          refetchAccountData();
          refetchAccounts();
          setLoading(false);
          showToastSuccessMessage(
            UserTranslation.adminDangerZoneSuspendSuccess,
            {
              name: userAccount.firstName,
            },
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [
      suspendWorkspaceUserMutation,
      currentWorkspace,
      userAccount.id,
      userAccount.firstName,
      refetch,
      refetchAccountData,
      refetchAccounts,
    ],
  );

  const handleSuspendUserClick = useCallback(() => {
    setLoading(true);
    const confirmationMessage = (
      <DangerZoneConfirmationMessage>
        {intl.formatMessage(
          {
            id: UserTranslation.adminDangerZoneSuspendConfirm,
          },
          {
            name: userAccount.firstName,
          },
        )}
      </DangerZoneConfirmationMessage>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.adminDangerZoneSuspendConfirmTitle,
      }),
      {
        width: 360,
        dangerConfirm: true,
      },
    ).then(confirm => {
      if (!confirm) {
        setLoading(false);
        return;
      }
      return suspendUser();
    });
  }, [askConfirmation, intl, userAccount, suspendUser]);

  /**
   * Activate user
   */
  const activateUser = useCallback(
    () =>
      activateWorkspaceUserMutation({
        variables: {
          input: {
            id: currentWorkspace!.id,
            account: userAccount.id,
          },
        },
        // TODO - cache update is disabled until cache update will be implemented on the General information page.
        // update: (cache, result) => {
        //   if (!result.data) {
        //     return;
        //   }

        //   updateUserStatusInCache(true);
        // },
      })
        .then(() => {
          refetch();
          refetchAccountData();
          refetchAccounts();
          setLoading(false);
          showToastSuccessMessage(
            UserTranslation.adminDangerZoneActivateSuccess,
            {
              name: userAccount.firstName,
            },
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [
      activateWorkspaceUserMutation,
      currentWorkspace,
      userAccount.id,
      userAccount.firstName,
      refetch,
      refetchAccountData,
      refetchAccounts,
    ],
  );

  const handleActivateUserClick = useCallback(() => {
    setLoading(true);

    const confirmationMessage = (
      <DangerZoneConfirmationMessage>
        {intl.formatMessage(
          {
            id: UserTranslation.adminDangerZoneActivateConfirm,
          },
          {
            name: userAccount.firstName,
          },
        )}
      </DangerZoneConfirmationMessage>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.adminDangerZoneActivateConfirmTitle,
      }),
      {
        width: 360,
        dangerConfirm: false,
      },
    ).then(confirm => {
      if (!confirm) {
        setLoading(false);
        return;
      }
      return activateUser();
    });
  }, [askConfirmation, intl, userAccount, activateUser]);

  if (groupIdentifier === WorkspaceAccountGroupIdentity.OWNER) {
    return null;
  }

  return (
    <Section data-testid="danger-zone">
      <SectionHeader data-testid="header">
        <DangerZoneHeaderTitle data-testid="title">
          <FormattedMessage id={UserTranslation.adminDangerZone} />
        </DangerZoneHeaderTitle>
      </SectionHeader>

      <SectionBody noPadding={true} data-testid="section-body">
        <DangerZone data-testid="suspend-zone">
          {active ? (
            <DangerZoneButton
              disabled={loading}
              data-testid="suspend-button"
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              onClick={handleSuspendUserClick}>
              <FormattedMessage
                id={UserTranslation.adminDangerZoneSuspendButton}
              />
            </DangerZoneButton>
          ) : (
            <DangerZoneButton
              disabled={loading}
              data-testid="activate-button"
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              onClick={handleActivateUserClick}>
              <FormattedMessage
                id={UserTranslation.adminDangerZoneActivateButton}
              />
            </DangerZoneButton>
          )}

          <DangerZoneWarning data-testid="suspend-warning">
            <FormattedMessage
              id={UserTranslation.adminDangerZoneSuspendWarning}
            />
          </DangerZoneWarning>
        </DangerZone>

        {/* TODO: Replace canEditWorkspace with owner data from Workspace when it will be available in API */}
        {canEditWorkspace &&
          groupIdentifier !== WorkspaceAccountGroupIdentity.GUEST && (
            <DangerZone data-testid="make-owner-zone">
              <DangerZoneButton
                data-testid="make-owner-button"
                size={ButtonSize.sm}
                mode={ButtonMode.secondary}
                onClick={makeOwnerConfirmationModalControls.open}>
                <FormattedMessage
                  id={UserTranslation.adminDangerZoneMakeOwnerButton}
                />
              </DangerZoneButton>
              <DangerZoneWarning data-testid="make-owner-warning">
                <FormattedMessage
                  id={UserTranslation.adminDangerZoneMakeOwnerWarning}
                />
              </DangerZoneWarning>
            </DangerZone>
          )}
        <DangerZone data-testid="delete-zone">
          <DangerZoneButton
            data-testid="delete-button"
            size={ButtonSize.sm}
            mode={ButtonMode.secondary}
            dangerMode={DangerZoneButtonMode.critical}
            onClick={handleRemoveUserClick}>
            <FormattedMessage
              id={UserTranslation.adminDangerZoneRemoveUserButton}
            />
          </DangerZoneButton>
          <DangerZoneWarning data-testid="delete-warning">
            <FormattedMessage
              id={UserTranslation.adminDangerZoneRemoveUserWarning}
            />
          </DangerZoneWarning>
        </DangerZone>

        {showResetMfaButton && (
          <DangerZone data-testid="reset-mfa">
            <DangerZoneButton
              data-testid="reset-mfa-button"
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              disabled={!canResetMfaRequest || !userAccount.mfaEnabled}
              dangerMode={DangerZoneButtonMode.critical}
              onClick={handleResetMfaClick}>
              <FormattedMessage
                id={UserTranslation.adminDangerZoneResetMfaButton}
              />
            </DangerZoneButton>
            <DangerZoneWarning data-testid="reset-mfa-warning">
              <FormattedMessage
                id={UserTranslation.adminDangerZoneResetMfaWarning}
              />
            </DangerZoneWarning>
          </DangerZone>
        )}
      </SectionBody>

      {makeOwnerConfirmationModalControls.visible && (
        <MakeOwnerModal
          width={380}
          userAccount={userAccount}
          onRequestClose={makeOwnerConfirmationModalControls.close}
          onDone={onDidMakeOwner}
        />
      )}
    </Section>
  );
};
