import React, { FC } from 'react';
import { DragSortItemType } from '../../Drag.types';
import { StyledWorkspacePreview } from './WorkspacePreview.styled';
import { WorkspaceMenuItem } from '../../../Workspace/WorkspaceSelector/WorkspaceSelectorMenu/WorkspaceMenuItem/WorkspaceMenuItem';
import { DragHandleIcon } from '../../../../shared/icons';
import { WorkspaceApiType } from '../../../Workspace/Workspace.types';

export const WorkspacePreview: FC<{ item: DragSortItemType }> = ({ item }) => {
  const { workspace } =
    'workspace' in item.payload
      ? item.payload
      : {
          workspace: null,
        };

  if (!workspace) {
    return null;
  }

  return (
    <StyledWorkspacePreview>
      <DragHandleIcon className="drag-icon" />
      <WorkspaceMenuItem
        isPreview={true}
        workspace={workspace as WorkspaceApiType}
      />
    </StyledWorkspacePreview>
  );
};
