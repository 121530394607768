import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  DangerZoneSection,
  DesktopsSection,
  GeneralInformationSection,
  TeamsSection,
} from './Sections';
import {
  AdminEntityNotFound,
  AdminHeader,
  AdminHeaderSeparator,
  AdminHeaderTitle,
} from '../../../Admin';
import { getAccountName } from '../../User.utils';
import { UserTranslation } from '../../i18n';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { useAccountsContext } from '../../../Account';
import { useParams } from 'react-router-dom';
import { useAccountDesktopsRepository } from '../../../Desktop/data/AccountDesktop/AccountDesktops.repositories';
import { getLongId } from '../../../../shared/utils/id';
import { useCurrentWorkspaceDesktopAccessesRepository } from '../../../Access/data/Access.repositories';
import { createAccessMap } from '../../../Access/data/utils/Access.utils';
import { useAccountTeamsRepository } from '../../../Team/data/AccountTeam/AccountTeam.repositories';
import { createTeamsMap } from '../../../Team/data/Team/utils/Team.utils';
import { WorkspaceAccountGroupIdentity } from '../../User.constants';
import { DesktopAccessType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { useWorkspaceInfoRepository } from '../../../WorkspaceInfo/data/WorkspaceInfo/WorkspaceInfo.repositories';

export const UserView: FC = () => {
  const { userId, workspaceId } = useParams<{
    userId: string;
    workspaceId: string;
  }>();
  const {
    permissions: { canViewGroupsPage, canViewDesktopsPage },
  } = useCurrentWorkspacePermissions();
  const { accountsWithAvailability, loading, refetchAccounts } =
    useAccountsContext();
  const account =
    accountsWithAvailability[getLongId('accounts', userId as string)];

  const { workspaceInfo, fetchWorkspaceInfo } = useWorkspaceInfoRepository({
    accountId: userId as string,
  });

  const { desktops: relatedDesktops, refetchDesktops } =
    useAccountDesktopsRepository({
      accountId: getLongId('accounts', userId as string),
      workspaceId: getLongId('workspaces', workspaceId as string),
    });

  const { accesses } = useCurrentWorkspaceDesktopAccessesRepository({});

  const desktopAccessesMap = useMemo(
    () => createAccessMap(accesses),
    [accesses],
  );

  const { teams: relatedTeams } = useAccountTeamsRepository({
    accountId: account?.id,
  });

  const relatedTeamsMap = useMemo(
    () => createTeamsMap(relatedTeams),
    [relatedTeams],
  );

  const deletableRelatedDesktopsMap = useMemo(
    () =>
      relatedDesktops.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: !desktopAccessesMap[curr.id]?.some(
            access =>
              access.workspaceTeam?.id &&
              relatedTeamsMap[access.workspaceTeam?.id],
          ),
        };
      }, {}),
    [relatedDesktops, desktopAccessesMap, relatedTeamsMap],
  );

  if (!account && loading) {
    return <Spinner />;
  }

  if (!account) {
    return (
      <AdminEntityNotFound>
        <FormattedMessage id={UserTranslation.adminUserNotFound} />
      </AdminEntityNotFound>
    );
  }

  if (!workspaceInfo) {
    return null;
  }

  return (
    <>
      <AdminHeader data-testid="header">
        <AdminHeaderTitle data-testid="title">
          <FormattedMessage id={UserTranslation.adminSingleUserHeaderTitle} />{' '}
          <AdminHeaderSeparator /> {getAccountName(account)}
        </AdminHeaderTitle>
      </AdminHeader>
      <GeneralInformationSection
        account={account}
        groupIdentifier={workspaceInfo!.role}
        active={workspaceInfo!.active}
      />
      {workspaceInfo?.role !== WorkspaceAccountGroupIdentity.GUEST &&
        canViewGroupsPage && (
          <TeamsSection
            account={account}
            relatedTeams={relatedTeams}
            onTeamListUpdate={() => refetchDesktops()}
          />
        )}
      {workspaceInfo?.role !== WorkspaceAccountGroupIdentity.GUEST &&
        canViewDesktopsPage &&
        relatedDesktops && (
          <DesktopsSection
            account={account}
            relatedDesktops={relatedDesktops}
            desktopAccessesMap={desktopAccessesMap}
            deletableRelatedDesktopsMap={deletableRelatedDesktopsMap}
            defaultAccessType={
              [
                WorkspaceAccountGroupIdentity.ADMIN,
                WorkspaceAccountGroupIdentity.OWNER,
              ].includes(workspaceInfo?.role)
                ? DesktopAccessType.Manager
                : DesktopAccessType.EditLink
            }
            isOnlyFullAccessAllowed={[
              WorkspaceAccountGroupIdentity.ADMIN,
              WorkspaceAccountGroupIdentity.OWNER,
            ].includes(workspaceInfo?.role)}
          />
        )}
      <DangerZoneSection
        userAccount={account}
        groupIdentifier={workspaceInfo!.role}
        active={workspaceInfo!.active}
        refetch={fetchWorkspaceInfo}
        showResetMfaButton={true}
      />
    </>
  );
};
