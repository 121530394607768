import React, { ComponentPropsWithoutRef, memo } from 'react';
import styled, { css } from 'styled-components';

const DEFAULT_SIZE = 16;

export enum CheckboxShapeType {
  default = 'default',
  circle = 'circle',
}

// TODO: add FOCUS state after design will be clarified

export const StyledCheckboxLabel = styled.label<{
  size: number;
  shape?: CheckboxShapeType;
}>`
  display: inline-flex;
  font-family: ${p => p.theme.fonts.Regular};

  input {
    appearance: none;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    flex: 0 0 ${({ size }) => size}px;
    border: 1px solid ${p => p.theme.colors.BorderDefault};
    margin: 0;
    background-color: ${p => p.theme.colors.Surface};
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: pointer;

    ${({ shape }) =>
      shape === CheckboxShapeType.circle &&
      css`
        border-radius: 50%;
      `}

    :checked {
      background-color: ${p => p.theme.colors.Primary};
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL5SURBVHgB7VfLbtpAFB2DsXmlJAhCUFGDVKREbPkBPqJ8QJUmXbarbtoF38Cin1DxI4glex5Su0QCgniIEorde8wMGGSDE2JaRVzpCvDM3Dlz7r1nDGNHO9rRXpZJxWLRQ59wL/+UdF2X2IFN4gB8k8nkhgDca5r2czwe3+TzefnQoLARmPANBoNbfd3uM5mMSmPyoQAJMEq/37/bAKPP5/NfNBbGOJ/nOhikSbUCA2s0Gp9pPELuLxQKXuaSSdw92WxWsQNTq9W+0JzX5KcAxFxkaGuaYNVq9SuNvyW/IH9FrjKXAIk0bQWjqupVIBBI0bxoKpUKsEVRs+c2p8xckb8hj8XjcRS0j2uTZBVQBMUpvXYTnwpGUZRrgAmHw3G26C4f1lmxI4RJ3hQuPAMwPr4GzvRsJxikye/3XwJMLBY74frj1a30Bw9526kAYg5GYvYBJ8G4xeKd3VSpVL7RHIMZcgMM47qjbxFDQ0lBIwHqbwalzW55EIBaYwZgut3u1jQRM0bNCGaclAImQAtOW63WJ6vgHBRAi9ry5HI5n4OauQQzvIAV2zRZAYpEImdoR4jWFlAKB6ZuSxNqhq0XsAGGOWsSAxCKDCIFscrghDag7mg81Ov1Pu4AYzBDvqwZp2CYoD+ZTAbpexQ5R1A7UKPR6IcdGHOaTAXsKE1WLGFxmJ8MQa+xie7AzDojmBGt/RhmzLa8ndmCZgRNYxM7pszMsEVrL3WGxzG6ch8TIqdyps4ZZ8oOlLmbNkWPPZGZNUOu+WulYMoAhU0307dRM5h3Al1ijyxgJ7a8tdkqfQZTpVLpXb1e/14ul9/Tb1wHaO1ztuqmvZixXQimyIyLlkRNoTsuQN0F1qgBVc90Op2TZj3Isvx7OBxOKU0PzWbzD41rWM5cMjNTIfIoW7B1wVk5SyQSIbbOzF6pkneM46Sa+EEvU9psNvPQy7mn0+lo6XR6HgwGtXa7PWfPxIzT00gWrgvXF/l1LU22hn311U3/T/5xHu2/s7/BBj+iaSOIwgAAAABJRU5ErkJggg==);
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }

    & ~ span {
      margin-left: 6px;
      cursor: pointer;
      user-select: none;
    }

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;

      & ~ span {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
`;

interface CheckboxProps extends ComponentPropsWithoutRef<'input'> {
  size?: number;
  shape?: CheckboxShapeType;
}

export const Checkbox = memo(
  React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
      {
        children,
        size = DEFAULT_SIZE,
        shape = CheckboxShapeType.default,
        ...props
      },
      ref,
    ) => {
      return (
        <StyledCheckboxLabel size={size} shape={shape}>
          <input ref={ref} type="checkbox" {...props} />
          {!!children && <span>{children}</span>}
        </StyledCheckboxLabel>
      );
    },
  ),
);
