import { useCallback, useMemo } from 'react';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import type { TeamApiType } from './types/Team.types';
import type {
  TeamRepositoryProps,
  TeamsRepositoryProps,
  TeamsRepositoryResponse,
} from './types/TeamRepository.types';
import { TeamRepositoryResponse } from './types/TeamRepository.types';
import { useSingleEntityRepository } from '../../../../shared/hooks/repository/singleEntityRepository.hooks';
import {
  getWorkspaceTeamFromIDDB,
  getWorkspaceTeamsFromIDDB,
} from './dataSources/TeamIDDB.dataSoureces';
import { getRedundantCacheTeams } from './utils/TeamRepository.utils';
import {
  bulkDeleteTeamsFromIDDB,
  bulkPutTeamsToIDDB,
  putTeamToIDDB,
} from './operations/TeamIDDB.operations';
import { TeamApiTypeToTeamTableType } from './utils/TeamIDDB.utils';
import { createTeamRowsMap } from '../AccountTeam/utils/AccountTeamIDDB.utils';
import { getTeamApi, getTeamsApi } from './dataSources/TeamRESTApi.dataSources';

export const useTeamsRepository = ({
  fetchPolicy = 'cache-and-network',
  workspaceId,
}: TeamsRepositoryProps): TeamsRepositoryResponse => {
  const fetchTeams = useCallback(async () => {
    try {
      const teamsFromApiResponse = await getTeamsApi(workspaceId);
      const teamsFromApi = teamsFromApiResponse?.data || [];

      if (teamsFromApiResponse?.ok) {
        const cacheTeamRows = await getWorkspaceTeamsFromIDDB(workspaceId);
        const cachedTeamRowsMap = createTeamRowsMap(cacheTeamRows);
        const teamRowsFromAPI = teamsFromApiResponse?.data
          .map(team => TeamApiTypeToTeamTableType(team, workspaceId))
          .map(teamRow => ({
            ...teamRow,
            relatedDesktops:
              cachedTeamRowsMap[teamRow.id]?.relatedDesktops || [],
            relatedAccounts:
              cachedTeamRowsMap[teamRow.id]?.relatedAccounts || [],
          }));
        const redundantTeamRows = getRedundantCacheTeams(
          cacheTeamRows,
          teamsFromApi,
        );

        if (teamRowsFromAPI) {
          bulkPutTeamsToIDDB(teamRowsFromAPI, workspaceId);
        }
        if (redundantTeamRows.length) {
          bulkDeleteTeamsFromIDDB(redundantTeamRows.map(team => team.id));
        }
      }

      return teamsFromApi;
    } catch (error) {
      console.error(error);
    }
  }, [workspaceId]);

  const { entities: teams, loading } = useMultipleEntityRepository<TeamApiType>(
    {
      fetchFunction: fetchTeams,
      iddbQuerier: () => getWorkspaceTeamsFromIDDB(workspaceId),
      dependencies: [workspaceId],
      fetchPolicy,
    },
  );

  return useMemo(
    () => ({
      teams,
      loading,
      fetchTeams,
    }),
    [teams, loading, fetchTeams],
  );
};

export const useTeamRepository = ({
  fetchPolicy = 'cache-and-network',
  teamId,
}: TeamRepositoryProps): TeamRepositoryResponse => {
  const { workspace } = useCurrentWorkspace();

  const fetchTeam = useCallback(async () => {
    try {
      const teamFromAPIResponse = await getTeamApi(teamId);
      const teamFromAPI = teamFromAPIResponse?.data;

      if (teamFromAPI && teamFromAPIResponse?.ok) {
        putTeamToIDDB(teamFromAPI, workspace.id);
      }

      return teamFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [teamId, workspace.id]);

  const { entity: team, loading } =
    useSingleEntityRepository<TeamApiType | null>({
      fetchFunction: fetchTeam,
      iddbQuerier: () => getWorkspaceTeamFromIDDB(teamId),
      fetchPolicy,
      dependencies: [teamId],
    });

  return {
    team: team || null,
    loading,
    fetchTeam,
  };
};
