import React, { FC, useMemo } from 'react';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { Spinner } from '../../../shared/components/Spinner/Spinner';
import { RelatedUsersForm } from './RelatedUsersForm/RelatedUsersForm';
import styled from 'styled-components';
import { AccountApiType } from '../User.types';
import { useAccountsContext } from '../../Account';
import { relatedUsersWithWorkspaceCache } from './ManageRelatedUsers.utils';
import { useAccountsRepository } from '../../Account/data/Account/Account.repositories';

const StyledRelatedUsers = styled.div`
  padding: 16px 25px 23px;
`;

interface ManageRelatedTeamProps {
  relatedUsers: AccountApiType[];
  onSubmit: (users: AccountApiType[]) => Promise<unknown>;
}

export const ManageRelatedUsers: FC<ManageRelatedTeamProps> = ({
  relatedUsers,
  onSubmit,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { accountsWithAvailability } = useAccountsContext();
  const { accounts, loading } = useAccountsRepository({
    variables: {
      workspaceId: workspace.id,
    },
  });
  const users: AccountApiType[] = useMemo(
    () => relatedUsersWithWorkspaceCache(accounts, accountsWithAvailability),
    [accounts, accountsWithAvailability],
  );

  return (
    <StyledRelatedUsers>
      {loading ? (
        <Spinner />
      ) : (
        <RelatedUsersForm
          users={users}
          relatedUsers={relatedUsers}
          onSubmit={onSubmit}
        />
      )}
    </StyledRelatedUsers>
  );
};
