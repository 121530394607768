import { useCallback, useMemo } from 'react';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import {
  AccountTeamsRepositoryProps,
  AccountTeamsRepositoryResp,
} from './types/AccountTeamRepository.types';
import { useAccountTeamActions } from './AccountTeam.actions';
import { getAccountTeamsRestApi } from './dataSources/AccountTeamRestApi.dataSources';
import { getTeamsByAccountIdFromIDDB } from './dataSources/AccountTeamIDDB.dataSources';
import {
  addAccountToTeamRelatedAccounts,
  createTeamRowsMap,
} from './utils/AccountTeamIDDB.utils';
import { getWorkspaceTeamsFromIDDB } from '../Team/dataSources/TeamIDDB.dataSoureces';
import { TeamApiTypeToTeamTableType } from '../Team/utils/TeamIDDB.utils';
import { TeamApiType } from '../Team/types/Team.types';
import { getRedundantCacheTeams } from '../Team/utils/TeamRepository.utils';
import { removeRelatedTeamsFromAccountsIDDB } from '../../../Account/data/TeamAccounts/operations/TeamAccount.operations';
import { removeRelatedAccountsFromTeamsIDDB } from './operations/AccountTeamIDDB.oprations';

export const useAccountTeamsRepository = ({
  fetchPolicy = 'cache-and-network',
  accountId,
}: AccountTeamsRepositoryProps): AccountTeamsRepositoryResp => {
  const { bulkPutAccountTeamsToIDDB } = useAccountTeamActions();
  const { workspace } = useCurrentWorkspace();

  const fetchTeams = useCallback(async () => {
    try {
      if (!accountId) {
        return [];
      }

      const { data: teamsFromAPI, ok } = await getAccountTeamsRestApi(
        workspace.id,
        accountId,
      );

      if (ok) {
        const cachedTeamRows = await getWorkspaceTeamsFromIDDB(workspace.id);
        const cachedTeamRowsMap = createTeamRowsMap(cachedTeamRows);
        const redundantCacheTeams = getRedundantCacheTeams(
          cachedTeamRows,
          teamsFromAPI,
        );

        const teamRows = teamsFromAPI.map(teamFromAPI => {
          const teamFromApiTableType = TeamApiTypeToTeamTableType(
            teamFromAPI,
            workspace.id,
          );
          return {
            ...teamFromApiTableType,
            relatedDesktops:
              cachedTeamRowsMap?.[teamFromAPI.id]?.relatedDesktops,
            relatedAccounts: addAccountToTeamRelatedAccounts(
              cachedTeamRowsMap?.[teamFromAPI.id]?.relatedAccounts,
              accountId,
            ),
          };
        });

        bulkPutAccountTeamsToIDDB(teamRows);

        removeRelatedTeamsFromAccountsIDDB(
          [accountId],
          redundantCacheTeams.map(team => team.id),
        );
        removeRelatedAccountsFromTeamsIDDB(
          redundantCacheTeams.map(team => team.id),
          [accountId],
        );
      }

      return teamsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [bulkPutAccountTeamsToIDDB, accountId, workspace.id]);

  const { entities: teams, loading } = useMultipleEntityRepository<TeamApiType>(
    {
      fetchFunction: fetchTeams,
      iddbQuerier: () => getTeamsByAccountIdFromIDDB(accountId),
      dependencies: [accountId, workspace.id],
      fetchPolicy,
    },
  );

  return useMemo(
    () => ({
      teams,
      loading,
      refetchDesktops: fetchTeams,
    }),
    [teams, loading, fetchTeams],
  );
};
