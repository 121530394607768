import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledUserListItem = styled.div`
  display: grid;
  grid-template-columns: 2.625rem auto 2rem;
  grid-gap: 0.75rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.25rem;
  align-items: center;
`;

export const AvatarPlaceholder = styled.div`
  width: 2.625rem;
  height: 2.625rem;
  text-transform: uppercase;
  background: ${p => p.theme.colors.BackgroundLight};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.PrimaryTextLight};
  font-size: 1.25rem;
  border-radius: 50%;
`;

export const UserName = styled.div`
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserEmail = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
`;

export const PendingLabel = styled.div`
  color: ${p => p.theme.colors.PrimaryTextLight};
  background-color: ${p => p.theme.colors.Pending};
  font-size: 0.75rem;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-left: 0.25rem;
`;

export const MenuContainer = styled.div`
  justify-self: center;
`;

export const MenuButton = styled(ButtonWithIcon)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUserListItemContent = styled.div`
  overflow: hidden;
`;
