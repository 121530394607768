import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChatTranslation } from '../../i18n';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { CreateConversationModalInner } from './CreateConversationModal.styled';
import { ConversationUsers } from '../ConversationUsers';

interface CreateConversationModalProps extends Omit<ModalProps, 'visible'> {
  onConversationCreated: (chatConversationId: string) => void;
}

export const CreateConversationModal: FC<CreateConversationModalProps> = ({
  onRequestClose,
  onConversationCreated,
}) => {
  return (
    <Modal visible={true} width={540} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={ChatTranslation.createConversationModalHeader} />
      </ModalHeader>
      <CreateConversationModalInner>
        <ConversationUsers
          onCancel={onRequestClose}
          onDone={onConversationCreated}
        />
      </CreateConversationModalInner>
    </Modal>
  );
};
