import styled, { css } from 'styled-components';

export const WorkspaceSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70vw;

  ${({ theme }) => theme.breakpoints.sm`
      width: 40vw;
  `}

  ${({ theme }) => theme.breakpoints.md`
      width: 25vw;
  `}
`;

export const StyledWorkspaceSelector = styled.button<{ isMenuOpen: boolean }>`
  appearance: none;
  border: 0;
  border-radius: 4px;
  height: 34px;
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme, isMenuOpen }) =>
    isMenuOpen
      ? css`
          background: ${theme.colors.OnNavbarSelected};
          pointer-events: none;
        `
      : css`
          background: unset;

          &:hover {
            background: ${theme.colors.OnNavbarHover};
          }
        `}
`;

export const WorkspaceIconContainer = styled.div`
  border-radius: 4px;
  margin-right: ${({ theme }) => theme.spacing.xs};
`;

export const Chevron = styled.div<{ lightBackground?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: ${({ lightBackground, theme }) =>
    lightBackground
      ? theme.colors.OnSurfaceMediumEmphasis
      : theme.colors.OnNavbarMediumEmphasis};
`;
