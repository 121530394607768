import React, { FC, PropsWithChildren, useCallback, useReducer } from 'react';
import {
  ConversationStateContext,
  ConversationStateControlsContext,
} from './ConversationState.context';
import { conversationStateReducer } from './ConversationState.reducer';
import { ActionType, ConversationStateState } from './ConversationState.types';
import { CONVERSATION_STATE_UNSET_DELAY } from './ConversationState.constants';

//@TODO Nika_Beridze Refactor
export const ConversationStateProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(conversationStateReducer, {
    scrollToMessageId: null,
    openThreadOfMessageId: null,
    mountedMessageFormsCount: 0,
  });

  const setScrollToMessage = useCallback((messageId: string) => {
    dispatch({
      type: ActionType.SetScrollToMessageId,
      messageId,
    });
  }, []);

  const unsetScrollToMessage = useCallback(() => {
    setTimeout(() => {
      dispatch({
        type: ActionType.UnsetScrollToMessageId,
      });
    }, CONVERSATION_STATE_UNSET_DELAY);
  }, []);

  const setOpenThreadOfMessage = useCallback((messageId: string) => {
    dispatch({
      type: ActionType.SetOpenThreadOfMessageId,
      messageId,
    });
  }, []);

  const unsetOpenThreadOfMessage = useCallback(() => {
    setTimeout(() => {
      dispatch({
        type: ActionType.UnsetOpenThreadOfMessageId,
      });
    }, CONVERSATION_STATE_UNSET_DELAY);
  }, []);

  const setState = useCallback((state: Partial<ConversationStateState>) => {
    dispatch({
      type: ActionType.SetState,
      ...state,
    });
  }, []);

  return (
    <ConversationStateControlsContext.Provider
      value={{
        setScrollToMessage,
        unsetScrollToMessage,
        setOpenThreadOfMessage,
        unsetOpenThreadOfMessage,
        setState,
      }}>
      <ConversationStateContext.Provider value={state}>
        {children}
      </ConversationStateContext.Provider>
    </ConversationStateControlsContext.Provider>
  );
};
