import React, { FC, useCallback, useState } from 'react';
import { useMobile } from '../../../../shared/hooks';
import { useTheme } from 'styled-components';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
  useConfirm,
} from '../../../../shared/components/Modal';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { BillingTranslation } from '../../i18n';
import {
  ButtonControls,
  Illustration,
  Intro,
  RequestUpgradeModalContent,
} from './RequestUpgradeModal.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import UpgradeStarIllustration from '../../../../shared/assets/images/upgrade-star.svg';
import { useGetRepeatedPaymentCustomerByWorkspaceQuery } from '../../Billing.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useUpgradeWorkspaceMutation,
} from '../../../Workspace/Workspace.hooks';
import { PaymentPlan } from '../../Billing.types';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  LearnMoreLink,
  UpgradeConfirmationControls,
} from '../../UpgradeProAccount/UpgradeProAccount.styled';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { PAYMENT_PLAN_COST } from '../../Billing.constants';
import { APP_URL } from '../../../Subscription/Subscription.constans';
import { appEnv } from '../../../../appEnv';

export const RequestUpgradeModal: FC<ModalProps> = ({
  onRequestClose,
  visible,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const [upgradeWorkspaceMutation] = useUpgradeWorkspaceMutation();
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { askConfirmation } = useConfirm();
  const { formatMessage } = useIntl();

  const { data: paymentCustomerData, refetch: refetchPaymentCustomer } =
    useGetRepeatedPaymentCustomerByWorkspaceQuery({
      variables: {
        id: workspace.id,
      },
      fetchPolicy: 'no-cache',
    });

  const paymentCustomer = paymentCustomerData?.workspace?.paymentCustomer;

  const askUpgradeConfirmation = useCallback(
    () =>
      askConfirmation(
        formatMessage({
          id: BillingTranslation.upgradeConfirmation,
        }),
        formatMessage({
          id: BillingTranslation.upgradeConfirmationTitle,
        }),
        {
          width: 360,
          customControls: (onConfirm, onCancel) => (
            <UpgradeConfirmationControls>
              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}
                data-testid="enter-new-details">
                <FormattedMessage
                  id={BillingTranslation.upgradeEnterNewDetailsButton}
                />
              </Button>
              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={onConfirm}
                data-testid="continue-upgrade">
                <FormattedMessage
                  id={BillingTranslation.upgradeContinueButton}
                />
              </Button>
            </UpgradeConfirmationControls>
          ),
        },
      ),
    [askConfirmation, formatMessage],
  );

  const handleUpgrade = useCallback(() => {
    const upgradeToPremium = (hasPaymentCustomer: boolean) => {
      setIsUpgrading(true);
      return upgradeWorkspaceMutation({
        variables: {
          input: {
            id: workspace.id,
            newType: PaymentPlan.PRO,
            reuseOldBilling: hasPaymentCustomer,
          },
        },
      })
        .then(res => {
          setIsUpgrading(false);
          if (!res.data) {
            return;
          }
          if (hasPaymentCustomer) {
            showToastSuccessMessage(BillingTranslation.upgradeAccountSuccess);
          }
          refetchWorkspaces({
            id: account!.identityId,
          });
          refetchPermissions();
          refetchPaymentCustomer();
        })
        .catch(e => {
          setIsUpgrading(false);
          showToastGraphQLErrors(e.graphQLErrors);
        });
    };

    if (paymentCustomer) {
      askUpgradeConfirmation().then(confirm => {
        if (confirm === null) {
          return;
        }

        upgradeToPremium(confirm);
      });
    } else {
      upgradeToPremium(!!paymentCustomer);
    }
    onRequestClose();
  }, [
    paymentCustomer,
    onRequestClose,
    upgradeWorkspaceMutation,
    workspace.id,
    refetchWorkspaces,
    account,
    refetchPermissions,
    refetchPaymentCustomer,
    askUpgradeConfirmation,
  ]);

  return (
    <>
      <Modal
        visible={visible}
        width={512}
        containerZIndex={theme.zIndexLevels.Confirm}
        onRequestClose={onRequestClose}
        {...props}
        data-testid="request-upgrade-modal">
        <ModalHeader onRequestClose={onRequestClose}>
          <ModalHeaderTitle data-testid="title">
            <FormattedHTMLMessage
              id={BillingTranslation.requestUpgradeModalTitle}
              values={{ cost: PAYMENT_PLAN_COST[PaymentPlan.PRO] }}
            />
          </ModalHeaderTitle>
        </ModalHeader>

        <ModalContent>
          <RequestUpgradeModalContent data-testid="upgrade-pitch-modal">
            <Illustration>
              <img src={UpgradeStarIllustration} role="presentation" alt="" />
            </Illustration>
            <Intro>
              <FormattedHTMLMessage
                id={BillingTranslation.requestUpgradeModalIntro}
                values={{
                  appUrl: APP_URL,
                }}
              />
              <LearnMoreLink href={appEnv.PRICING_URL}>
                <FormattedMessage
                  id={BillingTranslation.seeAllFeaturesInProLinkText}
                />
              </LearnMoreLink>
            </Intro>

            <ButtonControls>
              <Button
                type="button"
                className="upgrade-button"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                data-testid="upgrade-now"
                fullWidth={isMobile}
                onClick={handleUpgrade}>
                <FormattedMessage
                  id={BillingTranslation.requestUpgradeModalConfirm}
                />
              </Button>
              <Button
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                data-testid="cancel"
                fullWidth={isMobile}
                onClick={onRequestClose}>
                <FormattedMessage
                  id={BillingTranslation.requestUpgradeModalCancel}
                />
              </Button>
            </ButtonControls>
          </RequestUpgradeModalContent>
        </ModalContent>
      </Modal>
      {isUpgrading && <SpinnerFullscreen />}
    </>
  );
};
