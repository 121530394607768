import { gql } from '@apollo/client';
import {
  ChatConversationApiType,
  ChatMessageApiType,
  DraftChatMessageApiType,
} from './Chat.types';
import { GRAPHQL_TYPENAME_MUTATION } from '../../shared/api/api.constants';
import {
  ChatConversationFields,
  ChatMessageFields,
  DraftChatMessageFields,
  RemoveChatConversationFields,
} from './Chat.fragments';
import {
  GRAPHQL_TYPENAME_ADD_REACTION_CHAT_MESSAGE_PAYLOAD,
  GRAPHQL_TYPENAME_REMOVE_REACTION_CHAT_MESSAGE_PAYLOAD,
} from './Chat.constants';
import { DesktopFragmentFields } from '../Desktop/Desktop.fragments';
import type { DesktopApiType } from '../Desktop/data/Desktop/types/Desktop.types';

export const CREATE_CHAT_MESSAGE = gql`
  mutation CreateChatMessage($input: createChatMessageInput!) {
    __typename
    createChatMessage(input: $input) {
      chatMessage {
        ${ChatMessageFields}
      }
    }
  }
`;

export interface CreateChatMessageResponse {
  createChatMessage: {
    chatMessage: ChatMessageApiType;
  };
}

export interface CreateChatMessageVariables {
  input: {
    optimisticId: string;
    chatConversation: string;
    message: string;
    assets: string[];
    parentChatMessage: string | null;
    createdAt: string;
  };
}

export const CREATE_DRAFT_CHAT_MESSAGE = gql`
  mutation CreateDraftChatMessage($input: draftChatMessageInput!) {
    __typename
    draftChatMessage(input: $input) {
      chatMessage {
        ${DraftChatMessageFields}
      }
    }
  }
`;

export interface CreateDraftChatMessageResponse {
  __typename: 'draftChatMessagePayload';
  draftChatMessage: {
    chatMessage: DraftChatMessageApiType;
  };
}

export interface CreateDraftChatMessageVariables {
  input: {
    chatConversation: string;
    message: string;
    assets: string[];
  };
}

export const CREATE_CHAT_CONVERSATION = gql`
  mutation CreateChatConversation($input: createChatConversationInput!) {
    __typename
    createChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface CreateChatConversationResponse {
  createChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface CreateChatConversationVariables {
  input: {
    workspace: string;
    users: string[];
    title?: string;
  };
}

export const INVITE_GUEST_TO_CHAT_CONVERSATION = gql`
  mutation InviteGuestToChatConversation($input: inviteGuestToChatConversationInput!) {
    __typename
    inviteGuestToChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface InviteGuestToChatConversationResponse {
  inviteGuestToChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface InviteGuestToChatConversationVariables {
  input: {
    id: string;
    email: string;
  };
}

export const CREATE_PENDING_CHAT_CONVERSATION = gql`
  mutation CreatePendingChatConversation($input: createPendingChatConversationInput!) {
    __typename
    createPendingChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface CreatePendingChatConversationResponse {
  createPendingChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface CreatePendingChatConversationVariables {
  input: {
    workspace: string;
    inviteeEmails: string[];
    accounts: string[];
    title?: string;
  };
}

export const MARK_ALL_MESSAGES_AS_READ_IN_CHAT_CONVERSATION = gql`
  mutation MarkAllMessagesAsReadInChatConversation(
    $input: markAllMessagesAsReadInChatConversationInput!
  ) {
    markAllMessagesAsReadInChatConversation(input: $input) {
      clientMutationId
    }
  }
`;

export interface markAllMessagesAsReadInChatConversationResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
}

export interface markAllMessagesAsReadInChatConversationVariables {
  input: {
    id: string;
  };
}

export const EDIT_CHAT_CONVERSATION = gql`
  mutation EditChatConversation($input: editChatConversationInput!) {
    __typename
    editChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface EditChatConversationResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  editChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface EditChatConversationVariables {
  input: {
    id: string;
    title: string;
  };
}

export const LEAVE_FROM_CHAT_CONVERSATION = gql`
  mutation LeaveFromChatConversation($input: leaveFromChatConversationInput!) {
    __typename
    leaveFromChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface LeaveFromChatConversationResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  leaveFromChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface LeaveFromChatConversationVariables {
  input: {
    id: string;
  };
}

export const REMOVE_CHAT_CONVERSATION = gql`
  mutation RemoveChatConversation($input: removeChatConversationInput!) {
    __typename
    removeChatConversation(input: $input) {
      chatConversation {
        ${RemoveChatConversationFields}
      }
    }
  }
`;

export interface RemoveChatConversationResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  removeChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface RemoveChatConversationVariables {
  input: {
    id: string;
  };
}

export const ADD_MEMBERS_TO_CHAT_CONVERSATION = gql`
  mutation AddMembersToChatConversation(
    $input: addMembersToChatConversationInput!
  ) {
    __typename
    addMembersToChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface AddMembersToChatConversationResponse {
  addMembersToChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface AddMembersToChatConversationVariables {
  input: {
    id: string;
    users: string[];
  };
}

export const REMOVE_MEMBERS_FROM_CHAT_CONVERSATION = gql`
  mutation RemoveMembersFromChatConversation(
    $input: removeMembersFromChatConversationInput!
  ) {
    __typename
    removeMembersFromChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
    }
  }
`;

export interface RemoveMembersFromChatConversationResponse {
  removeMembersFromChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface RemoveMembersFromChatConversationVariables {
  input: {
    id: string;
    users: string[];
  };
}

export const ADD_REACTION_TO_CHAT_MESSAGE = gql`
  mutation AddReactionToChatMessage(
    $input: addReactionToChatMessageInput!
  ) {
    __typename
    addReactionToChatMessage(input: $input) {
      chatMessage {
        ${ChatMessageFields}
      }
    }
  }
`;

export interface AddReactionToChatMessageResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  addReactionToChatMessage: {
    __typename: typeof GRAPHQL_TYPENAME_ADD_REACTION_CHAT_MESSAGE_PAYLOAD;
    chatMessage: ChatMessageApiType;
  };
}

export interface AddReactionToChatMessageVariables {
  input: {
    id: string;
    emoji: string;
  };
}

export const REMOVE_REACTION_FROM_CHAT_MESSAGE = gql`
  mutation RemoveReactionFromChatMessage(
    $input: removeReactionFromChatMessageInput!
  ) {
    __typename
    removeReactionFromChatMessage(input: $input) {
      chatMessage {
        ${ChatMessageFields}
      }
  }
}
`;

export interface RemoveReactionFromChatMessageResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  removeReactionFromChatMessage: {
    __typename: typeof GRAPHQL_TYPENAME_REMOVE_REACTION_CHAT_MESSAGE_PAYLOAD;
    chatMessage: ChatMessageApiType;
  };
}

export interface RemoveReactionFromChatMessageVariables {
  input: {
    id: string;
    emoji: string;
  };
}

export const REMOVE_CHAT_MESSAGE = gql`
  mutation RemoveChatMessage($input: removeChatMessageInput!) {
    removeChatMessage(input: $input) {
      chatMessage {
        id
      }
    }
  }
`;

export interface RemoveChatMessageResponse {
  removeChatMessage: {
    chatMessage: {
      id: string;
    } | null;
  };
}

export interface RemoveChatMessageVariables {
  input: {
    id: string;
  };
}

export const EDIT_CHAT_MESSAGE = gql`
  mutation EditChatMessage($input: editChatMessageInput!) {
    __typename
    editChatMessage(input: $input) {
      chatMessage {
        ${ChatMessageFields}
      }
    }
  }
`;

export interface EditChatMessageResponse {
  editChatMessage: {
    chatMessage: ChatMessageApiType;
  };
}

export interface EditChatMessageVariables {
  input: {
    id: string;
    message: string;
    assets: string[];
  };
}

export const ENABLE_CHAT_FOR_DESKTOP = gql`
  mutation EnableChatForDesktop($input: enableChatForDesktopInput!) {
    enableChatForDesktop(input: $input) {
      desktop {
        ${DesktopFragmentFields}
      }
    }
  }
`;

export interface EnableChatForDesktopResponse {
  enableChatForDesktop: {
    desktop: DesktopApiType;
  };
}

export interface EnableChatForDesktopVariables {
  input: {
    id: string;
  };
}

export const PIN_CHAT_CONVERSATION = gql`
  mutation PinChatConversation($input: pinChatConversationInput!) {
    __typename
    pinChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
      clientMutationId
    }
  }
`;

export interface PinChatConversationResponse {
  pinChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface PinChatConversationVariables {
  input: {
    id: string;
    accountIri: string;
  };
}

export const UNPIN_CHAT_CONVERSATION = gql`
  mutation UnpinChatConversation($input: unpinChatConversationInput!) {
    __typename
    unpinChatConversation(input: $input) {
      chatConversation {
        ${ChatConversationFields}
      }
      clientMutationId
    }
  }
`;

export interface UnpinChatConversationResponse {
  unpinChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export interface UnpinChatConversationVariables {
  input: {
    id: string;
    accountIri: string;
  };
}

export const DELETE_CHAT_LINK = gql`
  mutation DeleteChatLink($input: deleteChatLinkInput!) {
    deleteChatLink(input: $input) {
      clientMutationId
    }
  }
`;

export interface DeleteChatLinkVariables {
  input: {
    id: string;
  };
}

export interface DeleteChatLinkResponse {
  deleteChatLink: {
    clientMutationId: string;
  };
}

export const MARK_MULTIPLE_CHAT_MESSAGE_AS_READ = gql`
  mutation MarkMultipleMessagesAsRead(
    $input: markMultipleMessagesAsReadInChatConversationInput!
  ) {
    markMultipleMessagesAsReadInChatConversation(input: $input) {
      clientMutationId
    }
  }
`;

export interface MarkMultipleMessagesAsReadVariables {
  input: {
    id: string;
    chatMessages: string[];
  };
}

export interface MarkMultipleMessagesAsReadResponse {
  clientMutationId: string;
}
