export enum ExternalFileSupportedExtension {
  URL = 'url',
  WEBLOC = 'webloc',
}

export enum ExternalFileCategory {
  UNSUPPORTED = 'unsupported',
  URL = 'url',
}

interface ExternalFileCategoryType {
  type: ExternalFileCategory;
}

export interface ExternalFileCategoryUnsupportedType
  extends ExternalFileCategoryType {
  fileName: string;
  fileExtension: string;
}

export interface ExternalFileCategoryUrlType extends ExternalFileCategoryType {
  url: string;
}

export type ExternalFileCategoryTypes =
  | ExternalFileCategoryUnsupportedType
  | ExternalFileCategoryUrlType;
