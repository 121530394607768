import styled from 'styled-components';

interface StyledProps {
  isUpdating: boolean;
}

export const StyledRemovePaymentMethodButton = styled.button<StyledProps>`
  display: flex;
  appearance: none;
  border: 0;
  background: transparent;
  cursor: ${p => (p.isUpdating ? 'default' : 'pointer')};
  opacity: ${p => (p.isUpdating ? 0.2 : 1)};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  &:hover {
    color: ${p =>
      p.isUpdating
        ? p.theme.colors.OnSurfaceLightEmphasis
        : p.theme.colors.OnSurfaceHighEmphasis};
  }
`;
