import styled, { css } from 'styled-components';
import NavListItemRoundedIcon from '../../../../shared/assets/images/nav-list-icon-round-icon.svg';
import NavListItemFilledRoundedIcon from '../../../../shared/assets/images/nav-list-item-filled-icon-rounded.svg';
import Color from 'color';

export const DropZone = styled.div<{
  isDraggingOver: boolean;
  isActive?: boolean;
}>`
  a {
    ${({ isDraggingOver, isActive, theme }) =>
      isDraggingOver &&
      css`
        border-radius: 0.25rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${isActive
          ? Color(theme.colors.Primary).alpha(0.8).rgb().string()
          : 'transparent'} !important;
      `}
  }
`;

export const StyledNavigatorLinkContent = styled.span<{
  enableHover: boolean;
  isDraggingOver?: boolean;
  isDragging?: boolean;
  isActive?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.Medium};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  border: none;
  border-radius: 0.25rem;
  text-decoration: none;
  word-break: break-word;
  box-shadow: 0 0 0 0 transparent;
  transform: rotateZ(0deg);
  transition: box-shadow 0.2s ease-out, background-color 0.2s linear,
    transform linear 0.2s;
  user-select: none;
  height: 2.1875rem;

  ${p =>
    p.isDraggingOver &&
    css`
      background-color: ${Color(p.theme.colors.Primary)
        .alpha(0.8)
        .rgb()
        .string()};
      opacity: 0.8;
    `}

  ${p =>
    p.isDragging &&
    css`
      background-color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
      color: ${({ theme }) => theme.colors.PrimaryTextLight};
    `}

  ${({ enableHover, isActive }) =>
    !enableHover &&
    !isActive &&
    css`
      &:hover {
        background-color: ${p => p.theme.colors.OnNavbarHover};
        .new-conference-icon,
        .schedule-conference-icon {
          visibility: visible;
        }
      }
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${p => p.theme.colors.PrimaryTextLight};
      background-color: ${p => p.theme.colors.Primary};
      display: flex;
      align-items: center;

      & > .list-rounded-icon {
        background-image: url(${NavListItemFilledRoundedIcon});
      }

      & > * {
        color: ${p => p.theme.colors.PrimaryTextLight};
      }
    `}

  .unreadNotificationsBadge {
    margin-right: 0.15rem;
    top: 24%;
    transform: translateY(-50%);
  }

  .list-rounded-icon {
    background-image: url(${NavListItemRoundedIcon});
    background-position: center;
    width: 0.9375rem;
    height: 0.9375rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;
