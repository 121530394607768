import React, { FC, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useLoginWithMfa } from '../../Workspace.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { WorkspaceApiType } from '../../Workspace.types';
import { WorkspaceTranslation } from '../../i18n';
import { InformationIcon } from '../../../../shared/icons';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { FormToggle } from '../../../../shared/components/Toggle/FormToggle';
import {
  WorkspaceMfaActivateBanner,
  WorkspaceMfaActivateBannerContent,
  WorkspaceMfaActivateField,
  WorkspaceMfaActivateFieldTitle,
} from './WorkspaceMfaForm.styled';
import { useMobile } from '../../../../shared/hooks';

export interface WorkspaceMfaFormValues {
  mfaRequired: boolean;
}

const WorkspaceMfaFormSchema: Schema<WorkspaceMfaFormValues> =
  Yup.object().shape({
    mfaRequired: Yup.boolean(),
  });

interface WorkspaceFormProps {
  workspace: WorkspaceApiType;
  disabled?: boolean;
  onSubmit: (formValues: WorkspaceMfaFormValues) => void;
}

export const FormikAutoSave: FC = () => {
  const { submitForm, values, dirty } = useFormikContext();
  useEffect(() => {
    if (values && dirty) {
      submitForm();
    }
  }, [dirty, submitForm, values]);

  return null;
};

export const WorkspaceMfaForm: FC<WorkspaceFormProps> = ({
  workspace,
  disabled,
  onSubmit,
}) => {
  const { account } = useCurrentAccount();
  const isMobile = useMobile();
  const loginWithMfa = useLoginWithMfa();

  const initialValues: WorkspaceMfaFormValues = {
    mfaRequired: workspace?.mfaRequired || false,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={WorkspaceMfaFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          {!disabled && !account?.isMfaValid && (
            <WorkspaceMfaActivateBanner
              isMobile={isMobile}
              data-testid="workspace-mfa-banner">
              <WorkspaceMfaActivateBannerContent>
                <div className="icon">
                  <InformationIcon />
                </div>
                <FormattedMessage
                  id={WorkspaceTranslation.formMfaConfirmMessage}
                />
              </WorkspaceMfaActivateBannerContent>
              <Button
                type="button"
                mode={ButtonMode.primary}
                size={ButtonSize.sm}
                data-testid="mfa-activate-button"
                onClick={() => loginWithMfa()}>
                <FormattedMessage
                  id={WorkspaceTranslation.formMfaConfirmButton}
                />
              </Button>
            </WorkspaceMfaActivateBanner>
          )}
          <WorkspaceMfaActivateField>
            <WorkspaceMfaActivateFieldTitle
              isActive={!disabled && account?.isMfaValid}>
              <FormattedHTMLMessage
                id={WorkspaceTranslation.formMfaDescription}
              />
            </WorkspaceMfaActivateFieldTitle>
            <Field
              id="mfa-toggle"
              data-testid="mfa-toggle"
              checked={workspace.mfaRequired}
              disabled={isSubmitting || disabled || !account?.isMfaValid}
              name="mfaRequired"
              component={FormToggle}
            />
          </WorkspaceMfaActivateField>
          <FormikAutoSave />
        </Form>
      )}
    </Formik>
  );
};
