import { ImageApiType } from '../api/api.types';
import { getImageSrcSet } from './url.utils';

export enum ImageFormat {
  SQUARE = 'square',
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export const setDefaultImage = (
  target: EventTarget,
  ImageUrl: string,
  size?: number,
) => {
  const img = target as HTMLImageElement;
  img.src = ImageUrl;

  if (size) {
    img.srcset = getImageSrcSet(ImageUrl, size);
  }

  return img;
};

export const dimensionsToKeys = (
  image: ImageApiType,
): { width: number; height: number; format: ImageFormat } => {
  if (!image.dimensions) {
    return {
      width: 0,
      height: 0,
      format: ImageFormat.SQUARE,
    };
  }
  const width = parseInt(image.dimensions[0], 10);
  const height = parseInt(image.dimensions[1], 10);
  let format;

  if (width > height) {
    format = ImageFormat.LANDSCAPE;
  } else if (width < height) {
    format = ImageFormat.PORTRAIT;
  } else {
    format = ImageFormat.SQUARE;
  }

  return {
    width,
    height,
    format,
  };
};
