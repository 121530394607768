import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { extractNodes } from '../../../../shared/api/api.utils';
import { ConversationDraftContext } from './ConversationDraft.context';
import type {
  ChatTextMessageContext,
  ChatTextMessageInternalType,
  DraftChatMessageApiType,
} from '../../Chat.types';
import { MessageType } from '../../Chat.types';
import { useMap } from '../../../../shared/hooks/useMap';
import type { GetDraftChatMessagesResponse } from '../../Chat.queries';
import { useEffectOnce } from 'react-use';
import { useChatMessageRepository } from '../../Data/Repository/ChatMessage/ChatMessageApiRepository';

export const ConversationDraftProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [, actions] = useMap<string, DraftChatMessageApiType>(new Map());
  const [loading, setLoading] = useState(true);
  const { getDraftChatMessages } = useChatMessageRepository();

  const presetDrafts = useCallback(
    (data: GetDraftChatMessagesResponse) => {
      const extractedDrafts = extractNodes(data.listDraftChatMessages);

      if (!extractedDrafts.length) {
        return;
      }

      actions.setAll(
        extractedDrafts.map(object => [object.conversationIri || '', object]),
      );
    },
    [actions],
  );

  useEffectOnce(() => {
    getDraftChatMessages()
      .then(resp => {
        if (resp?.data) {
          presetDrafts(resp.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const getDraftMessage = (id?: string) => (id && actions.get(id)) || null;

  const setDraftMessage = (
    id: string,
    chatMessage: Partial<ChatTextMessageInternalType>,
    conversationId: string,
  ) => {
    actions.set(id, {
      message: chatMessage.message || '',
      context: (chatMessage.context as ChatTextMessageContext) || null,
      conversationIri: conversationId,
      type: MessageType.Draft,
    });
  };

  const resetDraftMessage = (id: string) => {
    actions.remove(id);
  };

  return (
    <ConversationDraftContext.Provider
      value={{
        loading,
        getDraftMessage,
        setDraftMessage,
        resetDraftMessage,
      }}>
      {children}
    </ConversationDraftContext.Provider>
  );
};
