import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import type { SegmentType } from '../Segment';
import { PreviewSegmentContext } from './PreviewSegment.context';
import { PreviewSegmentEventTypes } from './PreviewSegment.types';
import { initListeners, removeListeners } from './PreviewSegment.utils';

interface PreviewSegmentProviderProps {
  children: ReactNode;
  hoverRef: HTMLElement | null;
  navigateToSegment: (segment: SegmentType, isPreviewMode?: boolean) => void;
}

export const PreviewSegmentProvider: FC<PreviewSegmentProviderProps> = ({
  children,
  hoverRef,
  navigateToSegment,
}) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false);
  const [canClose, setCanClose] = useState<boolean>(true);

  const handleHoverPreview = useCallback(
    (event: MouseEvent) => {
      if (event.type === PreviewSegmentEventTypes.Mouseleave) {
        setCanClose(true);
        return setShouldShow(false);
      }
      if (event.type === PreviewSegmentEventTypes.Mouseleave && !shouldShow) {
        return setShouldShow(true);
      }
      if (canClose) {
        setShouldShow(!shouldShow);
      }
    },
    [canClose, shouldShow],
  );

  const handleClickPreview = useCallback((e: MouseEvent) => {
    setCanClose(false);
  }, []);

  const closePreview = () => {
    setCanClose(true);
    setShouldShow(false);
  };

  useEffect(() => {
    initListeners(hoverRef, handleHoverPreview, handleClickPreview);

    return () => {
      removeListeners(hoverRef, handleHoverPreview, handleClickPreview);
    };
  }, [handleClickPreview, handleHoverPreview, hoverRef]);

  return (
    <PreviewSegmentContext.Provider
      value={{
        isPreviewMode: shouldShow,
        navigateToSegment,
        handleHoverPreview,
        handleClickPreview,
        closePreview,
      }}>
      {shouldShow ? children : null}
    </PreviewSegmentContext.Provider>
  );
};
