import { WorkspaceAccountGroupIdentity } from '../../User.constants';
import { AccountWithCountsApiType } from '../../User.types';
import _groupBy from 'lodash/groupBy';

export const sortAccountsAlphabeticalyByRole = (
  users: AccountWithCountsApiType[],
): AccountWithCountsApiType[] => {
  const groupedUsers = _groupBy(
    users,
    user => user.workspaceCache?.[user.id]?.groupIdentifier ?? '',
  );

  return [
    ...(groupedUsers?.[WorkspaceAccountGroupIdentity.OWNER] ?? [])?.sort(
      (a, b) => a.firstName.localeCompare(b.firstName),
    ),
    ...(groupedUsers?.[WorkspaceAccountGroupIdentity.ADMIN] ?? []).sort(
      (a, b) => a.firstName.localeCompare(b.firstName),
    ),
    ...(groupedUsers?.[WorkspaceAccountGroupIdentity.MEMBER] ?? [])?.sort(
      (a, b) => a.firstName.localeCompare(b.firstName),
    ),
    ...(groupedUsers?.[WorkspaceAccountGroupIdentity.GUEST] ?? [])?.sort(
      (a, b) => a.firstName.localeCompare(b.firstName),
    ),
    ...(groupedUsers?.[''] ?? [])?.sort((a, b) =>
      a.firstName.localeCompare(b.firstName),
    ),
  ];
};
