import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { WorkspaceApiType } from '../../../Workspace.types';
import { getCurrentWorkspaceInfo } from '../../../Workspace.utils';
import { WorkspaceIcon } from '../../../WorkspaceIcon';
import { FormattedMessage } from 'react-intl';
import { UserTranslation } from '../../../../User/i18n';
import {
  CountBadge,
  PositionType,
} from '../../../../../shared/components/CountBadge';
import { useUnreadCounterGetter } from '../../../../Counters';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import {
  StyledWorkspaceMenuItem,
  WorkspaceIconWithCountBadge,
  WorkspaceInfo,
  WorkspaceName,
  WorkspaceRole,
} from './WorkspaceMenuItem.styled';
import { WorkspaceAccountGroupIdentity } from '../../../../User/User.constants';
import { getShortId } from '../../../../../shared/utils/id';

export const WorkspaceMenuItem: FC<{
  workspace: WorkspaceApiType;
  role?: WorkspaceAccountGroupIdentity;
  isPreview?: boolean;
}> = ({ workspace, role, isPreview }) => {
  const { account: authenticatedAccount } = useCurrentAccount();
  const theme = useTheme();
  const workspaceInfo = useMemo(
    () =>
      authenticatedAccount
        ? getCurrentWorkspaceInfo(
            authenticatedAccount,
            getShortId(workspace.id),
          )
        : null,
    [authenticatedAccount, workspace.id],
  );

  const getUnreadCounter = useUnreadCounterGetter();

  return (
    <StyledWorkspaceMenuItem isPreview={isPreview}>
      <WorkspaceIconWithCountBadge>
        <WorkspaceIcon workspace={workspace} size={32} />
        <CountBadge
          count={getUnreadCounter(workspace.id)}
          positionMode={PositionType.absolute}
          offsetTop={-4}
          offsetRight={-4}
          data-testid="menu-workspace-notifications-counter"
          borderColor={theme.colors.Surface}
        />
      </WorkspaceIconWithCountBadge>
      <WorkspaceInfo>
        <WorkspaceName>{workspace.name}</WorkspaceName>
        {workspaceInfo && (
          <FormattedMessage
            tagName={WorkspaceRole}
            id={UserTranslation[role || workspaceInfo.role]}
          />
        )}
      </WorkspaceInfo>
    </StyledWorkspaceMenuItem>
  );
};
