import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { WorkspaceInfoApiType } from '../types/WorkspaceInfo.types';
import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';

export const getAccountWorkspaceInfoApi = (
  accountId: string,
  workspaceId: string,
): Promise<RestApiClientResponse<WorkspaceInfoApiType> | undefined> => {
  const shortAccountId = getShortId(accountId);
  const shortWorkspaceId = getShortId(workspaceId);
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient!.fetch(
    `/account/${shortAccountId}/info/workspace/${shortWorkspaceId}`,
  );
};
