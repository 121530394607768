import styled, { css } from 'styled-components';
import { Tab, TabList, TabListProps, Tabs } from 'react-tabs';

export interface TabsProps extends TabListProps {
  type?: TabsType;
  width?: number;
  tabwidth?: number;
}

export enum TabsType {
  tabs = 'tabs',
  switcher = 'switcher',
}

export const StyledTab = styled(Tab)<{ width?: number }>`
  min-width: 7rem;
  z-index: 1;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px !important;
    `}

  &:focus-visible {
    border: none;
  }
  div {
    display: flex;
  }
  svg {
    margin-left: 0.25rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;
`;

export const StyledTabList = styled(TabList)<TabsProps>`
  overflow-x: hidden;
  display: flex;
  position: relative;
  z-index: 0;

  // using prop for boolean value causes a console error in
  // TabList which tries to render the prop on the DOM element.
  // Hence the data approach.
  &[data-isvisiblescroll='true'] {
    overflow-x: auto;
  }

  ${({ type, tabwidth }) =>
    type === TabsType.tabs &&
    css`
      border-bottom: 1px solid ${p => p.theme.colors.SurfaceBorder};

      ${StyledTab} {
        width: ${tabwidth ? tabwidth + 'px' : '50%'};
        height: 3rem;

        &.react-tabs__tab--selected {
          border-bottom: 2px solid ${p => p.theme.colors.Primary};
          color: ${p => p.theme.colors.Primary};
        }
      }
    `}

  ${({ type, width }) =>
    type === TabsType.switcher &&
    css`
      width: ${width}px;
      background-color: ${p => p.theme.colors.SurfaceSubdued};
      border-radius: 8px;
      padding: 0.125rem;

      ${StyledTab} {
        font-family: ${({ theme }) => theme.fonts.Semibold};
        font-size: 0.75rem;
        line-height: 1;
        color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
        flex-grow: 1;
        flex-basis: 0;

        &.react-tabs__tab--selected {
          color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
          background-color: ${p => p.theme.colors.Surface};
          height: 1.25rem;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
            0px 1px 3px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
      }
    `}
`;
