import React, { FC, useCallback, useMemo } from 'react';
import { TeamApiType } from '../../../../Team/data/Team/types/Team.types';
import {
  Section,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../Admin';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../i18n';
import { Spinner } from '../../../../../shared/components/Spinner';
import { RelatedTeamsList } from '../../../../Team/ManageRelatedTeams';
import { ManageRelatedTeamsModal } from './ManageRelatedTeamsModal';
import { useModalControls } from '../../../../../shared/components/Modal/Modal.hooks';
import {
  DesktopAccessType,
  DesktopApiType,
} from '../../../data/Desktop/types/Desktop.types';
import { useConfirm } from '../../../../../shared/components/Modal';
import { getLongId } from '../../../../../shared/utils/id';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';
import { useCurrentWorkspaceDesktopAccessesRepository } from '../../../../Access/data/Access.repositories';
import { createAccessMap } from '../../../../Access/data/utils/Access.utils';
import { useTeamsActions } from '../../../../Team/data/Team/Team.actions';
import { getListChanges } from '../../../../../shared/utils/list.utils';
import { useAccessActions } from '../../../../Access/data/Access.actions';

export const ManageRelatedTeams: FC<{
  desktop: DesktopApiType;
}> = ({ desktop }) => {
  const intl = useIntl();
  const relatedTeamsModal = useModalControls();
  const { askConfirmation } = useConfirm();
  const {
    permissions: { canViewGroupsPage },
  } = useCurrentWorkspacePermissions();

  const { accesses, loading, fetchAccesses } =
    useCurrentWorkspaceDesktopAccessesRepository({});
  const { updateAccess } = useAccessActions();
  const desktopAccessesMap = useMemo(
    () => createAccessMap(accesses),
    [accesses],
  );
  const desktopAccesses = useMemo(() => {
    return (
      desktopAccessesMap?.[getLongId('desktops', desktop!.id as string)] || []
    );
  }, [desktopAccessesMap, desktop]);

  const relatedTeams = useMemo(() => {
    return desktopAccesses
      .filter(access => !access.account?.id && access.workspaceTeam?.id)
      .map(access => access.workspaceTeam) as TeamApiType[];
  }, [desktopAccesses]);

  const editTeamAccess = useCallback(
    (accessId: string, newAccessType: DesktopAccessType) => {
      updateAccess(desktop.id, accessId, newAccessType);
    },
    [desktop, updateAccess],
  );

  const { addDesktopToTeamTeam, deleteDesktopFromTeam } = useTeamsActions();
  const handleManageTeamsSubmit = useCallback(
    async (selectedTeams: TeamApiType[]) => {
      const { added, removed } = getListChanges(relatedTeams, selectedTeams);

      await Promise.allSettled([
        ...added.map(team => addDesktopToTeamTeam(team.id, desktop.id)),
        ...removed.map(team => deleteDesktopFromTeam(team.id, desktop.id)),
      ]);

      relatedTeamsModal.close();
    },
    [
      relatedTeams,
      relatedTeamsModal,
      desktop.id,
      addDesktopToTeamTeam,
      deleteDesktopFromTeam,
    ],
  );

  const handleDeleteRelatedTeamClick = useCallback(
    (team: TeamApiType) => {
      askConfirmation(
        intl.formatMessage({
          id: DesktopTranslation.adminDesktopTeamsRemoveConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }
        deleteDesktopFromTeam(team.id, desktop.id);
      });
    },
    [intl, askConfirmation, desktop, deleteDesktopFromTeam],
  );

  if (!canViewGroupsPage) {
    return null;
  }

  return (
    <>
      <Section data-testid="related-teams-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={DesktopTranslation.adminDesktopTeams} />
          </SectionHeaderTitle>
          <SectionHeaderButton
            onClick={relatedTeamsModal.open}
            data-testid="manage-button">
            <FormattedMessage
              id={DesktopTranslation.adminDesktopTeamsManageButton}
            />
          </SectionHeaderButton>
        </SectionHeader>

        {loading && !relatedTeams ? (
          <Spinner />
        ) : (
          <RelatedTeamsList
            teams={relatedTeams}
            desktopAccesses={desktopAccesses}
            emptyMessage={intl.formatMessage({
              id: DesktopTranslation.adminDesktopTeamsEmptyMessage,
            })}
            onEditTeamAccess={editTeamAccess}
            onRemove={handleDeleteRelatedTeamClick}
          />
        )}
      </Section>

      <ManageRelatedTeamsModal
        subtitle={desktop.name}
        relatedTeams={relatedTeams}
        onSubmit={handleManageTeamsSubmit}
        visible={relatedTeamsModal.visible}
        onRequestClose={relatedTeamsModal.close}
      />
    </>
  );
};
