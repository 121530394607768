import styled from 'styled-components';

export const StyledUpgrade = styled.div<{ unaffectedStyles: boolean }>`
  ${({ unaffectedStyles }) =>
    !unaffectedStyles &&
    `
    opacity: 0.5;
  `}

  > * {
    cursor: pointer;
  }

  .verify-trigger {
    pointer-events: none;
    cursor: default;
  }
`;

export const UpgradeMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export const UpgradeIcon = styled.div`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.Warning};
`;
