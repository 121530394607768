import styled from 'styled-components';

export const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 15px;
  }
`;

export const ChangeUserPasswordFormInner = styled.div`
  padding: 12px 24px 24px;

  .fieldBox {
    margin-bottom: 26px;
  }
`;

export const HiddenInput = styled.input`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  border: 0;
  margin: 0;
  pointer-events: none;
`;
