import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { LoggedOutOverlay } from './LoggedOutOverlay/LoggedOutOverlay';
import { BroadcastChannelMessageType } from '../../BroadcastChannel/BroadcastChannel.types';
import { useBroadcastChannelContext } from '../../BroadcastChannel/BroadcastChannel.hooks';

export const LoggedOutScreenProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const { subscribe } = useBroadcastChannelContext();

  useEffect(() => {
    subscribe(BroadcastChannelMessageType.LOGOUT, () => {
      setLoggedOut(true);
    });
  }, [subscribe]);

  return <>{loggedOut ? <LoggedOutOverlay /> : children}</>;
};
