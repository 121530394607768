import { NativeTypes } from 'react-dnd-html5-backend';
import { LinkApiType } from '../Link/Link.types';
import { XYCoord } from 'react-dnd';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { AccountApiType } from '../User/User.types';
import { FolderApiType } from '../Folder/Folder.types';
import type {
  DesktopAppEdgeApiType,
  DesktopApiType,
} from '../Desktop/data/Desktop/types/Desktop.types';

export enum DragType {
  APP = 'app',
  LINK = 'link',
  FOLDER = 'folder',
  WORKSPACE = 'workspace',
  PRIVATE_DESKTOPS_LIST = 'private-desktops',
  INTERNAL_DESKTOPS_LIST = 'internal-desktops',
  SHARED_DESKTOPS_LIST = 'shared-desktops',
}

interface DragOffsetsType {
  clientOffset?: XYCoord | null;
  sourceOffset?: XYCoord | null;
  initialOffset?: XYCoord | null;
}

export interface DragLinkItemType extends DragOffsetsType {
  type: DragType.LINK;
  link: LinkApiType;
  workspaceId: string;
  currentDesktopSelectedLinksMap?: Record<string, true>;
}

export interface DragFolderItemType extends DragOffsetsType {
  type: DragType.FOLDER;
  folder: FolderApiType;
  workspaceId: string;
}

export interface DragAppItemType extends DragOffsetsType {
  type: DragType.APP;
  app: DesktopAppEdgeApiType;
  workspaceId: string;
}

export interface DragWorkspaceItemType extends DragOffsetsType {
  type: DragType.WORKSPACE;
  workspace: WorkspaceApiType;
  workspaceId: string;
}

export interface DragSortItemType extends DragOffsetsType {
  type: DragType;
  payload:
    | {
        workspace: WorkspaceApiType;
        workspaceAccount: AccountApiType;
      }
    | DesktopApiType;
}

export interface DragNativeUrlItemType {
  type: typeof NativeTypes.URL;
  urls: string[];
}

export interface DragNativeFileItemType {
  type: typeof NativeTypes.FILE;
  files: File[];
}
