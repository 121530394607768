import { ParsedQuery } from 'query-string';
import { ApolloCache } from '@apollo/client';
import {
  GET_APP_SSO_GROUPS,
  GET_SSO_COMPATIBLE_APPS,
  GetAppSsoGroupsResponse,
  GetAppSsoGroupsVariables,
  GetSsoCompatibleAppsResponse,
  GetSsoCompatibleAppsVariables,
} from '../../AppStore.queries';
import { AppSsoGroupApiType } from '../../AppStore.types';

export const getFilterParam = (queryParams: ParsedQuery) => {
  if (typeof queryParams.filter !== 'string') {
    return undefined;
  }

  const filterParam = queryParams.filter.trim();
  return filterParam ? filterParam : undefined;
};

export const addAppSsoGroupToCache = (
  cache: ApolloCache<any>,
  variables: GetAppSsoGroupsVariables,
  appSsoGroup: AppSsoGroupApiType,
) => {
  try {
    const ssoAppsCache = cache.readQuery<
      GetAppSsoGroupsResponse,
      GetAppSsoGroupsVariables
    >({
      query: GET_APP_SSO_GROUPS,
      variables,
    });
    if (!ssoAppsCache) {
      return;
    }

    cache.writeQuery<GetAppSsoGroupsResponse, GetAppSsoGroupsVariables>({
      query: GET_APP_SSO_GROUPS,
      variables,
      data: {
        ...ssoAppsCache,
        ssoGroupsConfigured: {
          ...ssoAppsCache.ssoGroupsConfigured,
          edges: [
            ...ssoAppsCache.ssoGroupsConfigured.edges,
            {
              __typename: 'AppSsoGroupEdge',
              node: {
                ...appSsoGroup,
                workspaceConfigs: {
                  __typename: 'WorkspaceAppConfigConnection',
                  edges: [],
                },
              },
            },
          ],
        },
      },
    });
  } catch (e) {}
};

export const removeAppFromSsoCompatibleAppsCache = (
  cache: ApolloCache<any>,
  variables: GetSsoCompatibleAppsVariables,
  appId: string,
) => {
  try {
    const ssoAppsCache = cache.readQuery<
      GetSsoCompatibleAppsResponse,
      GetSsoCompatibleAppsVariables
    >({
      query: GET_SSO_COMPATIBLE_APPS,
      variables,
    });
    if (!ssoAppsCache) {
      return;
    }

    cache.writeQuery<
      GetSsoCompatibleAppsResponse,
      GetSsoCompatibleAppsVariables
    >({
      query: GET_SSO_COMPATIBLE_APPS,
      variables,
      data: {
        ...ssoAppsCache,
        apps: {
          ...ssoAppsCache.apps,
          ...(ssoAppsCache.apps.totalCount
            ? { totalCount: ssoAppsCache.apps.totalCount - 1 }
            : null),
          ...(ssoAppsCache.apps.pageInfo
            ? {
                pageInfo: {
                  ...ssoAppsCache.apps.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(ssoAppsCache.apps.pageInfo.endCursor),
                        10,
                      ) - 1
                    ).toString(),
                  ),
                },
              }
            : null),
          edges: ssoAppsCache.apps.edges.filter(edge => edge.node.id !== appId),
        },
      },
    });
  } catch (e) {}
};
