import React, { FC, useCallback, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../../shared/components/Input';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import {
  HiddenInput,
  ReCAPTCHAContainer,
  RegistrationField,
  RegistrationFormControls,
  StyledImportantMessage,
  TermsOfUseLink,
} from './RegistrationForm.styled';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import {
  PASSWORD_CHARACTER_REGEX,
  PASSWORD_NUMBER_REGEX,
} from '../../../Onboarding.constants';
import { FormCheckbox } from '../../../../../shared/components/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';
import { appEnv } from '../../../../../appEnv';
import { RegistrationFormValues } from '../../../Onboarding.types';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

interface RegistrationFormProps {
  invitation?: AccountInvitationApiType;
  onSubmit: (account: RegistrationFormValues) => Promise<unknown>;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
  invitation,
  onSubmit,
}) => {
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const registerFormSchema: Schema<RegistrationFormValues> = Yup.object().shape(
    {
      firstName: Yup.string().required(
        OnboardingTranslation.onboardingRegistrationFormNameRequired,
      ),
      lastName: Yup.string().required(
        OnboardingTranslation.onboardingRegistrationFormNameRequired,
      ),
      email: Yup.string()
        .email()
        .required(
          OnboardingTranslation.onboardingRegistrationFormEmailRequired,
        ),
      password: Yup.string()
        .required(OnboardingTranslation.passwordRequired)
        .min(8, OnboardingTranslation.passwordMinimum)
        .matches(
          PASSWORD_CHARACTER_REGEX,
          OnboardingTranslation.passwordContainLetter,
        )
        .matches(
          PASSWORD_NUMBER_REGEX,
          OnboardingTranslation.passwordContainNumber,
        ),
      repeatPassword: Yup.string()
        .required(OnboardingTranslation.repeatPasswordRequired)
        .min(8, OnboardingTranslation.repeatPasswordMinimum)
        .matches(
          PASSWORD_CHARACTER_REGEX,
          OnboardingTranslation.repeatPasswordContainLetter,
        )
        .matches(
          PASSWORD_NUMBER_REGEX,
          OnboardingTranslation.repeatPasswordContainNumber,
        )
        .oneOf(
          [Yup.ref('password'), null],
          OnboardingTranslation.repeatPasswordMatch,
        ),
      acceptTerms: Yup.boolean().oneOf(
        [true],
        OnboardingTranslation.onboardingRegistrationTermsOfUseCheckboxRequired,
      ),
      newsSubscription: Yup.boolean(),
      ...(appEnv.RECAPTCHA_API_KEY
        ? {
            captchaToken: Yup.string().required(
              OnboardingTranslation.onboardingRegistrationCaptchaRequired,
            ),
          }
        : {}),
    },
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    email: invitation?.recipients[0]?.email || '',
    password: '',
    repeatPassword: '',
    newsSubscription: false,
    acceptTerms: false,
    ...(appEnv.RECAPTCHA_API_KEY
      ? {
          captchaToken: '',
        }
      : {}),
  };

  const handleSubmit = useCallback(
    (account: RegistrationFormValues) => {
      setSubmitting(true);
      onSubmit(account).catch(err => {
        setSubmitting(false);
        showToastGraphQLErrors(err.graphQLErrors);
        captchaRef?.current?.reset();
      });
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerFormSchema}
      onSubmit={handleSubmit}>
      {({ isValid, dirty, setFieldValue }) => (
        <Form data-testid="registration-form">
          <RegistrationField>
            <Field
              name="firstName"
              type="text"
              data-testid="name-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationFormFirstNameLabel,
              })}
              component={FormInput}
            />
            <Field
              name="lastName"
              type="text"
              data-testid="name-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationFormLastNameLabel,
              })}
              component={FormInput}
            />
          </RegistrationField>

          {/*Chrome email autocomplete disabling workaround*/}
          <HiddenInput type="email" tabIndex={-1} />

          <RegistrationField>
            <Field
              name="email"
              type="email"
              data-testid="email-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationFormEmailLabel,
              })}
              component={FormInput}
              disabled={invitation}
            />
          </RegistrationField>

          {/*Chrome password autocomplete disabling workaround*/}
          <HiddenInput type="password" tabIndex={-1} />

          <RegistrationField>
            <Field
              name="password"
              type="password"
              data-testid="password-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationPasswordLabel,
              })}
              component={FormInput}
            />
          </RegistrationField>

          <RegistrationField>
            <Field
              name="repeatPassword"
              type="password"
              data-testid="repeat-password-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.repeatPasswordLabel,
              })}
              component={FormInput}
            />
          </RegistrationField>

          <StyledImportantMessage>
            <ImportantMessage type={ImportantMessageType.WARNING}>
              <FormattedHTMLMessage
                id={OnboardingTranslation.onboardingRegistrationHelpText}
                values={{ learnMoreLink: appEnv.SUPPORT_PASSWORD_MANAGER_URL }}
              />
            </ImportantMessage>
          </StyledImportantMessage>

          {appEnv.RECAPTCHA_API_KEY && (
            <ReCAPTCHAContainer data-testid="recaptcha">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={appEnv.RECAPTCHA_API_KEY}
                onChange={token => setFieldValue('captchaToken', token)}
              />
            </ReCAPTCHAContainer>
          )}

          <RegistrationField>
            <Field
              name="acceptTerms"
              type="checkbox"
              data-testid="accept-terms-checkbox"
              label={intl.formatMessage(
                {
                  id: OnboardingTranslation.onboardingRegistrationTermsOfUseMessage,
                },
                {
                  termsLink: (
                    <TermsOfUseLink
                      href={process.env.TERMS_OF_USE_URL}
                      data-testid="terms-link">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingRegistrationTermsOfUseLink
                        }
                      />
                    </TermsOfUseLink>
                  ),
                  privacyPolicyLink: (
                    <TermsOfUseLink
                      href={process.env.PRIVACY_POLICY_URL}
                      data-testid="privacy-policy-link">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingRegistrationPrivacyPolicyLink
                        }
                      />
                    </TermsOfUseLink>
                  ),
                },
              )}
              component={FormCheckbox}
            />
          </RegistrationField>

          <RegistrationField>
            <Field
              name="newsSubscription"
              type="checkbox"
              data-testid="marketing-consent-checkbox"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationMarketingConsentLabel,
              })}
              component={FormCheckbox}
            />
          </RegistrationField>

          <RegistrationFormControls>
            <Button
              type="submit"
              data-testid="submit-button"
              disabled={submitting || !isValid || !dirty}
              size={ButtonSize.md}
              mode={ButtonMode.primary}
              fullWidth>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingRegistrationFormSubmitButton
                }
              />
            </Button>
          </RegistrationFormControls>
        </Form>
      )}
    </Formik>
  );
};
