import { captureException } from '@sentry/react';
import format from 'date-fns/format';
import {
  getIDDBChatMessage,
  updateIDDBChatMessage,
} from '../ChatMessage/ChatMessageIDDBRepository';

export const optimistiUpdateThreadInParentChatMessage = async (
  accountId: string,
  parentChatMessageId?: string | null,
) => {
  const date = new Date();
  const nowDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");

  if (parentChatMessageId) {
    try {
      const parentChatMessage = await getIDDBChatMessage(parentChatMessageId);

      if (parentChatMessage) {
        const threadMessagesCount =
          parentChatMessage.data.threadMessagesCount + 1;

        return await updateIDDBChatMessage(parentChatMessage.id, {
          ...parentChatMessage.data,
          threadMessagesCount,
          threadMessagesAccounts: [
            ...parentChatMessage.data.threadMessagesAccounts,
            accountId,
          ],
          lastMessageInThreadAt: new Date(nowDate),
        });
      }
    } catch (e) {
      captureException(e);
    }
  }
};

export const optimisticDecreaseThreadMessageCount = async (
  parentChatMessageId?: string | null,
) => {
  if (parentChatMessageId) {
    try {
      const parentChatMessage = await getIDDBChatMessage(parentChatMessageId);

      if (parentChatMessage) {
        const threadMessagesCount =
          parentChatMessage.data.threadMessagesCount - 1;

        return await updateIDDBChatMessage(parentChatMessageId, {
          ...parentChatMessage.data,
          threadMessagesCount,
        });
      }
    } catch (e) {
      captureException(e);
    }
  }
};
