import { gql } from '@apollo/client';

export const DELETE_FAVORITE = gql`
  mutation DeleteItemFavorite($input: deleteItemFavoriteInput!) {
    __typename
    deleteItemFavorite(input: $input) {
      favorite {
        link {
          id
          accountFavorite
        }
        folder {
          id
          accountFavorite
        }
        desktopApp {
          id
          accountFavorite
        }
      }
    }
  }
`;

export interface DeleteFavoriteVariables {
  input: {
    workspace: string;
    item: string;
  };
}

export interface DeleteFavoriteResponse {
  deleteItemFavorite: {
    favorite: {
      link?: {
        id: string;
        accountFavorite: boolean;
      };
      folder?: {
        id: string;
        accountFavorite: boolean;
      };
      desktopApp?: {
        id: string;
        accountFavorite: boolean;
      };
    };
  };
}
