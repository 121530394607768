import styled from 'styled-components';

export const StyledSpecialMention = styled.span`
  color: ${p => p.theme.colors.Primary};
  display: inline-block;
  background: none;
  appearance: none;
  border: none;
  padding: 0;
`;

export const StyledMention = styled(StyledSpecialMention)`
  cursor: pointer;
`;
