import styled from 'styled-components';
import { Form } from 'formik';

export const StyledForm = styled(Form)`
  margin-top: 1rem;
`;

export const FormBody = styled.div`
  ${p => p.theme.breakpoints.md`
    overflow: hidden;
  `}
`;

export const FormFields = styled.div<{ displayAsLeftColumn?: boolean }>`
  ${p =>
    p.displayAsLeftColumn &&
    p.theme.breakpoints.md`
      width: 60%;
      float: left; 
  `}
`;

export const FormControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const FormFieldWrapper = styled.div`
  margin-top: 1.25rem;
`;

export const ImageUploadWrapper = styled.div`
  width: 120px;
  margin: 2rem auto;

  ${p => p.theme.breakpoints.md`
    display: flex;
    justify-content: space-around;
    margin: 1.75rem auto;
  `}

  & .imageUpload {
    width: 100%;
  }
`;

export const DecryptionSuccessMessage = styled.div`
  padding: 1rem;
`;

export const OwnerNoSharingWarning = styled.div`
  .important-message {
    margin: 1rem 0 0;
  }
`;
