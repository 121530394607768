import React, { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { Modal, ModalHeaderColor } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import {
  ButtonControls,
  StyledScheduleRepeatingConferenceModal,
} from './ScheduleRepeatingConferenceModal.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  useCurrentConference,
  useDeclineRepeatingScheduleChatConference,
  useDeclineScheduleChatConference,
} from '../../Conference.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../shared/utils/id';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { CONFERENCES_ROOT_PATHNAME } from '../../../Desktop/Desktop.constants';

export const ScheduleRepeatingDeclineConfirmationModal: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { scheduledConference } = useCurrentConference();
  const { workspace } = useCurrentWorkspace();
  const [declineScheduleChatConference] = useDeclineScheduleChatConference();
  const [declineRepeatingScheduleChatConference] =
    useDeclineRepeatingScheduleChatConference();
  const modalBackgroundColor = theme.colors.Surface;
  const modalTextColor = theme.colors.OnSurfaceHighEmphasis;

  const onClose = useCallback(() => {
    navigate({
      pathname: generatePath(CONFERENCES_ROOT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      }),
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleConferenceDeclineConfirmationModalOpen: undefined,
      }),
    });
  }, [navigate, queryParams, workspace.id]);

  const handleDeclineSingleConference = useCallback(() => {
    if (!scheduledConference) {
      return null;
    }
    declineScheduleChatConference({
      variables: {
        input: {
          id: scheduledConference.id,
          _id: scheduledConference._id,
          workspace: workspace.id,
        },
      },
    })
      .then(() => onClose())
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    declineScheduleChatConference,
    onClose,
    scheduledConference,
    workspace.id,
  ]);

  const handleDeclineAllConferences = useCallback(() => {
    if (!scheduledConference?.repeatingScheduleChatConference?.id) {
      return null;
    }
    declineRepeatingScheduleChatConference({
      variables: {
        input: {
          id: scheduledConference.repeatingScheduleChatConference.id,
          _id: getShortId(
            scheduledConference.repeatingScheduleChatConference.id,
          ),
          workspace: workspace.id,
        },
      },
    });
    onClose();
  }, [
    declineRepeatingScheduleChatConference,
    onClose,
    scheduledConference,
    workspace.id,
  ]);

  return (
    <Modal onRequestClose={onClose} visible width={420} containerZIndex={26}>
      <StyledScheduleRepeatingConferenceModal>
        <ModalHeaderColor
          onRequestClose={onClose}
          backgroundColor={modalBackgroundColor}
          textColor={modalTextColor}>
          <strong>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeclineConfirmationTitle
              }
            />
          </strong>
        </ModalHeaderColor>
        <FormattedMessage
          id={
            ConferenceTranslation.scheduledConferenceDeclineConfirmationMessage
          }
        />
        <ButtonControls>
          <Button
            type="button"
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={handleDeclineSingleConference}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeclineSingleConference
              }
            />
          </Button>
          <Button
            type="button"
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={handleDeclineAllConferences}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeclineAllConferences
              }
            />
          </Button>
        </ButtonControls>
      </StyledScheduleRepeatingConferenceModal>
    </Modal>
  );
};
