import React, { FC, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { IllustrationSections, StepsIllustration } from '../StepsIllustration';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useMobile } from '../../../../shared/hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { generatePath, Navigate } from 'react-router-dom';
import { SignUpStep } from '../../Onboarding.types';
import { useChangeOnboardingStepAccountMutation } from '../../Onboarding.hooks';
import { InstallPlugin } from '../../../ChromeExtension/InstallPlugin';
import {
  ONBOARDING_END_PATHNAME,
  ONBOARDING_TEMPLATES_APPLIED_COOKIE_KEY,
} from '../../Onboarding.constants';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { TemplateAppSet } from '../../../Template/Template.constants';
import { useObjectCookieStorage } from '../../../../shared/utils/storage';
import { Spinner } from '../../../../shared/components/Spinner';
import { getShortId } from '../../../../shared/utils/id';

export const InstallPluginPage: FC = () => {
  const isMobile = useMobile();
  const { workspace } = useCurrentWorkspace();
  const { account, refetchAccountData } = useCurrentAccount();
  const [selectedTemplateAppSets] = useObjectCookieStorage<TemplateAppSet[]>(
    ONBOARDING_TEMPLATES_APPLIED_COOKIE_KEY,
  );
  const [skippingStep, setSkippingStep] = useState(false);

  const [changeOnboardingStepAccountMutation] =
    useChangeOnboardingStepAccountMutation();
  const changeOnboardingStep = () => {
    setSkippingStep(true);
    return changeOnboardingStepAccountMutation({
      variables: {
        input: {
          onboardingStep: SignUpStep.completed,
        },
      },
    })
      .then(() => refetchAccountData?.())
      .finally(() => setSkippingStep(false));
  };

  if (account?.currentStep === SignUpStep.completed) {
    const path = generatePath(ONBOARDING_END_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={path} />;
  }

  if (account?.currentStep !== SignUpStep.install_extension) {
    const path = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={path} />;
  }

  return (
    <>
      <HeadTitle translationId={OnboardingTranslation.installPluginPageTitle} />
      <OnboardingPage
        horizontallyCentered
        after={
          isMobile
            ? null
            : () => (
                <StepsIllustration
                  companyName={workspace?.name}
                  displayDesktopList={true}
                  displayIllustrationSections={[
                    IllustrationSections.apps,
                    IllustrationSections.links,
                    IllustrationSections.chat,
                  ]}
                  displayCloudServicesApps={[
                    ...(selectedTemplateAppSets || []),
                  ]}
                />
              )
        }>
        <OnboardingStep data-testid="install-extension-step">
          {skippingStep ? (
            <Spinner containerHeight={200} />
          ) : (
            <>
              <OnboardingStepHeader data-testid="header">
                <FormattedMessage
                  id={OnboardingTranslation.installPluginPageHeading}
                />
              </OnboardingStepHeader>
              <InstallPlugin
                onInstall={changeOnboardingStep}
                onSkip={changeOnboardingStep}
              />
            </>
          )}
        </OnboardingStep>
      </OnboardingPage>
    </>
  );
};
