import styled from 'styled-components';
import { Button } from '../../shared/components/Button/Button';

export const StyledAppIntegrationsNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem 1rem;

  ${Button} {
    margin-top: 0.75rem;
  }
`;
