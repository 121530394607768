import {
  ModalHeaderActionButton,
  StyledModalHeader,
} from './PasswordPageModal.styled';
import React, { FC, PropsWithChildren } from 'react';
import { ChevronBackIcon, CloseIcon } from '../../../../shared/icons';
import { useMobile, useTablet } from '../../../../shared/hooks';
import { GlobalTranslation } from '../../../Intl/i18n';
import { useIntl } from 'react-intl';

interface ModalHeaderProps {
  onRequestClose: () => void;
}

export const ModalHeader: FC<PropsWithChildren<ModalHeaderProps>> = ({
  children,
  onRequestClose,
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { formatMessage } = useIntl();

  return (
    <StyledModalHeader data-testid="modal-header">
      {isMobile && (
        <ModalHeaderActionButton
          icon={ChevronBackIcon}
          iconSize={22}
          onClick={onRequestClose}
          data-testid="modal-close-icon"
          aria-label={formatMessage({
            id: GlobalTranslation.labelClose,
          })}
        />
      )}
      {children}
      {!isMobile && isTablet && (
        <ModalHeaderActionButton
          icon={CloseIcon}
          iconSize={12}
          onClick={onRequestClose}
          data-testid="modal-close-icon"
          aria-label={formatMessage({
            id: GlobalTranslation.labelClose,
          })}
        />
      )}
    </StyledModalHeader>
  );
};
