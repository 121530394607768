import { gql } from '@apollo/client';
import {
  ConferenceApiType,
  FilterPeriod,
  ScheduledChatConferenceApiType,
} from './Conference.types';
import { ApiConnection, PageInfoApiType } from '../../shared/api/api.types';
import {
  ChatConferenceFields,
  ScheduledConferenceFields,
  ScheduledConferenceMembersFields,
} from './Conference.fragments';

export const GET_CHAT_CONFERENCE = gql`
  query ChatConference($id: ID!) {
    chatConference(id: $id) {
      ${ChatConferenceFields}
    }
  }
`;

export interface GetChatConferenceResponse {
  chatConference: ConferenceApiType;
}

export interface GetChatConferenceVariables {
  id: string;
}

export const GET_SCHEDULED_CHAT_CONFERENCE = gql`
  query ScheduleChatConference($id: ID!) {
    scheduleChatConference(id: $id) {
      ${ScheduledConferenceFields}
      createdBy {
        id
      }
      chatConference {
        id
      }
      scheduleChatConferenceMembers(first: 100) {
        edges {
          node {
           ${ScheduledConferenceMembersFields}
          }
        }
      }
      repeatingScheduleChatConference {
        id
        repeatPattern
        scheduleChatConferenceMembers(first: 100) {
          edges {
            node {
              ${ScheduledConferenceMembersFields}
            }
          }
        }
      }
    }
  }
`;

export interface GetScheduledChatConferenceResponse {
  scheduleChatConference: ScheduledChatConferenceApiType;
}

export interface GetScheduledChatConferenceVariables {
  id: string;
}

export const GET_CHAT_CONFERENCES = gql`
  query ChatConferences(
    $workspace: String!
    $first: Int
    $after: String
  ) {
    chatConferences(
      workspace: $workspace
      isScheduled: false
      order: {createdAt: "desc"}
      filterOnlyAccessible: true
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${ChatConferenceFields}
        }
      }
    }
  }
`;

export interface GetChatConferencesResponse {
  chatConferences: ApiConnection<ConferenceApiType>;
}

export interface GetChatConferencesVariables {
  workspace: string;
  first?: number;
  after?: string;
}

export const GET_SCHEDULED_CHAT_CONFERENCES = gql`
  query scheduleChatConferences(
    $workspace: String!
    $first: Int
    $after: String
    $filterPeriod: String
  ) {
    scheduleChatConferences(
      first: $first
      after: $after
      filterWorkspaceId: $workspace
      filterPeriod: $filterPeriod
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${ScheduledConferenceFields}
        }
      }
      totalCount
    }
  }
`;

export interface GetScheduledChatConferencesResponse {
  scheduleChatConferences: ApiConnection<ScheduledChatConferenceApiType>;
  pageInfo: PageInfoApiType;
}

export interface GetScheduledChatConferencesVariables {
  workspace: string;
  filterPeriod?: FilterPeriod;
  first?: number;
  after?: string;
}
