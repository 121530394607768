import { GRAPHQL_TYPENAME_CHAT_CONVERSATION } from '../Chat/Chat.constants';

export type GoogleAuthStatusInBrowserType = {
  clearGoogleAuthStatusInBrowser: () => void;
  browserGoogleAuthorizationStatus: GoogleAuthStatusTypes;
  browserGoogleAuthUrl: string;
};

export enum GoogleAuthStatusTypes {
  authorized = 'authorized',
  unauthorized = 'unauthorized',
  initialization = 'initialization',
}

export interface GoogleAuthStatusInBrowserApiType {
  authUrl: string;
  __typename?: typeof GRAPHQL_TYPENAME_CHAT_CONVERSATION;
}
