import { useLiveQuery } from 'dexie-react-hooks';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ChatMessagesTableType } from '../../../Database/ChatMessagesTable/ChatMessagesTable';
import { useCurrentConversation, useSearchMessageData } from '../../Chat.hooks';
import { useChatMessageRepository } from '../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import {
  ConversationViewContext,
  ConversationViewContextProsp,
} from './ConversationView.context';
import { LoadingState, ScrollDirection } from './ConversationView.types';
import {
  MESSAGE_LIST_PAGE_SIZE,
  MESSAGE_LIST_START_INDEX,
} from './MessagesList/MessageList.constants';
import { getMessageByConversationId } from '../../Data/DataSource/ChatMessage/ChatMessagesIndexedDBSource';

const LIVE_QUERY_RENDER_DELAY = 1000;

export const ConversationViewProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    getChatMessagesBefore,
    getChatMessagesAfter,
    currentConversationInstance,
    getInitialChatMessages,
    findMessageInList,
  } = useChatMessageRepository();

  const conversationInstance = currentConversationInstance();

  const [loadingStates, setLoadingState] = useState<LoadingState>({
    listLoading: true,
    loadingAfter: false,
    loadingBefore: false,
  });

  const { searchMessage, setSearchMessage } = useSearchMessageData();
  const { conversation: currentConversation } = useCurrentConversation();

  const isFirstRender = useRef(true);

  const [messageContext, setMessageContext] = useState<
    Map<string, ConversationViewContextProsp>
  >(new Map());

  const conversationMessages = useMemo(
    () => messageContext.get(currentConversation?.id as string)?.messages ?? [],
    [currentConversation?.id, messageContext],
  );

  const [firstItemIndex, setFirstItemIndex] = useState<number | undefined>(
    MESSAGE_LIST_START_INDEX - MESSAGE_LIST_PAGE_SIZE,
  );

  const scrollDirectionRef = useRef<ScrollDirection>(ScrollDirection.unkonwn);

  const debouncedSetLoadingState = useDebouncedCallback(() => {
    setLoadingState({ ...loadingStates, listLoading: false });
  }, LIVE_QUERY_RENDER_DELAY);

  const setLoadingListState = () => {
    setLoadingState({
      ...loadingStates,
      listLoading: true,
    });
  };

  useEffect(
    () => () => {
      setLoadingState({
        listLoading: true,
        loadingBefore: false,
        loadingAfter: false,
      });
    },
    [currentConversation?.id],
  );

  const definefirstItemIndex = (newLength: number) => {
    const conversationId = currentConversation?.id;
    if (!conversationId) {
      return;
    }
    const conversationInstance = currentConversationInstance();
    const canLoadAfter = conversationInstance?.canLoadAfter;
    const canLoadBefore = conversationInstance?.canLoadBefore;
    const prevMessages = messageContext.get(conversationId)?.messages ?? [];

    if (newLength === prevMessages.length) {
      return;
    }

    if (scrollDirectionRef.current === ScrollDirection.top && canLoadBefore) {
      const subsetLength = newLength - prevMessages.length;
      const initialPosition =
        MESSAGE_LIST_START_INDEX - prevMessages.length + subsetLength;

      setFirstItemIndex(prev => {
        return prev ? prev - subsetLength : initialPosition;
      });
      scrollDirectionRef.current = ScrollDirection.unkonwn;
    }

    if (
      scrollDirectionRef.current === ScrollDirection.bottom &&
      canLoadAfter &&
      firstItemIndex
    ) {
      setFirstItemIndex(undefined);
      scrollDirectionRef.current = ScrollDirection.unkonwn;
    }
  };

  useLiveQuery(
    () =>
      getMessageByConversationId(currentConversation?.id || '', Infinity, true)
        .then(async response => {
          if (!currentConversation?.id) {
            return;
          }

          setMessages(currentConversation.id, response);
        })
        .catch(() => {
          return [];
        })
        .finally(() => {
          debouncedSetLoadingState();
        }),
    [currentConversation?.id, searchMessage],
    [],
  );

  useEffect(() => {
    if (
      isFirstRender.current &&
      currentConversation?.id &&
      !conversationMessages.length &&
      !searchMessage?.id
    ) {
      getInitialChatMessages(currentConversation?.id);
    }
    // eslint-disable-next-line
  }, [
    conversationMessages.length,
    currentConversation?.id,
    loadingStates.listLoading,
    searchMessage?.id,
  ]);

  useEffect(() => {
    isFirstRender.current = false;

    return () => {
      isFirstRender.current = true;
    };
  }, [currentConversation?.id]);

  useEffect(() => {
    if (!currentConversation?.id || !searchMessage?.id) {
      return;
    }

    findMessageInList(
      {
        id: searchMessage.id,
        isThreadMessage: searchMessage.isThreadMessage,
        createdAt: searchMessage.createdAt,
      },
      currentConversation?.id,
    );
    // eslint-disable-next-line
  }, [currentConversation?.id, searchMessage]);

  const setMessages = (
    conversationId: string,
    messages: ChatMessagesTableType[],
  ) => {
    definefirstItemIndex(messages.length);

    setMessageContext(
      new Map(messageContext.set(conversationId, { messages })),
    );
  };

  const loadMoreFromTop = useCallback(async () => {
    const canLoadBefore = conversationInstance?.canLoadBefore;

    if (!canLoadBefore) {
      return;
    }

    scrollDirectionRef.current = ScrollDirection.top;
    setLoadingState(prev => ({ ...prev, loadingBefore: true }));

    try {
      const firstMessage = conversationMessages[0];

      if (!firstMessage?.data?.microtimeAt) {
        return;
      }

      if (firstMessage) {
        await getChatMessagesBefore(firstMessage.data.microtimeAt);
      }
    } finally {
      setLoadingState(prev => ({ ...prev, loadingBefore: false }));
    }
  }, [
    conversationInstance?.canLoadBefore,
    conversationMessages,
    getChatMessagesBefore,
  ]);

  const loadMoreFromBottom = useCallback(async () => {
    const lastMessageMicrotimeAt =
      conversationMessages.at?.(-1)?.data?.microtimeAt;

    const canLoadAfter = conversationInstance?.canLoadAfter;

    if (
      !canLoadAfter ||
      !lastMessageMicrotimeAt ||
      loadingStates.loadingAfter
    ) {
      return;
    }

    scrollDirectionRef.current = ScrollDirection.bottom;
    setLoadingState(prev => ({ ...prev, loadingAfter: true }));

    try {
      await getChatMessagesAfter(lastMessageMicrotimeAt);
    } finally {
      setLoadingState(prev => ({ ...prev, loadingAfter: false }));
    }
  }, [
    conversationInstance?.canLoadAfter,
    conversationMessages,
    getChatMessagesAfter,
    loadingStates.loadingAfter,
  ]);

  const loadMoreMessages = useCallback(
    (scrollDirection: ScrollDirection) => {
      scrollDirectionRef.current = scrollDirection;

      switch (scrollDirection) {
        case ScrollDirection.top:
          loadMoreFromTop();
          break;
        case ScrollDirection.bottom:
          loadMoreFromBottom();
          break;
        default:
          break;
      }
    },
    [loadMoreFromBottom, loadMoreFromTop],
  );

  return (
    <ConversationViewContext.Provider
      value={{
        setMessages,
        messages: conversationMessages,
        searchMessage,
        setSearchMessage,
        firstItemIndex,
        loadMoreMessages,
        listLoading: loadingStates.listLoading,
        loadingBefore: loadingStates.loadingBefore,
        loadingAfter: loadingStates.loadingAfter,
        setLoadingListState,
      }}>
      {children}
    </ConversationViewContext.Provider>
  );
};
