import styled from 'styled-components';

export const CountryFieldBlock = styled.div`
  .countryIdType {
    & label {
      line-height: 1.375;
      margin-bottom: 0.5rem;
    }
  }
`;

export const FieldsGroup = styled.div`
  display: flex;

  .taxIdType {
    margin-right: 1rem;

    & label {
      line-height: 1.375;
      margin-bottom: 0.5rem;
    }
  }

  .vatIdNumber {
    & label {
      & div {
        line-height: 1.375;
      }
    }
  }
`;

export const VatLearnMore = styled.p`
  margin-top: 8px;
  font-size: 0.875rem;
  line-height: 1.25;
`;

export const VatLearnMoreLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${p => p.theme.colors.Primary};
`;
