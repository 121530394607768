import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { CreateTeamForm } from '../../../TeamForm/CreateTeamForm';
import { TeamTranslation } from '../../../i18n';

interface CreateTeamModalProps extends ModalProps {
  onDone: () => void;
}

export const CreateTeamModal: FC<CreateTeamModalProps> = ({
  onRequestClose,
  onDone,
  ...props
}) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={TeamTranslation.createModalHeader} />
      </ModalHeader>
      <CreateTeamForm onDone={onDone} />
    </Modal>
  );
};
