import React, { FC } from 'react';
import { MenuProps } from '../../../../shared/components/Menu/Menu.types';
import { Menu, MenuItem } from '../../../../shared/components/Menu';
import {
  CopyIcon,
  EditIcon,
  HeartFullIcon,
  HeartIcon,
  TrashIcon,
} from '../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { AppLinkMenuViewModel } from './ViewModel';
import type { DesktopAppEdgeApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface AppLinkMenuProps extends MenuProps {
  appNode: DesktopAppEdgeApiType;
  onFavoriteClick: () => void;
}

export const AppLinkMenu: FC<AppLinkMenuProps> = ({
  appNode,
  onFavoriteClick,
  ...props
}) => {
  const {
    canUnlockCredentials,
    canRemoveApp,
    canAddApp,
    login,
    hasPassword,
    keyset,
    isFavorite,
    disableSelectVaultOption,
    handleSelectVaultClick,
    handleCopyAppLoginClick,
    handleCopyAppPasswordClick,
    handleDeleteAppFromDesktop,
    handleEditAppClick,
  } = AppLinkMenuViewModel(appNode);

  const favoriteItemTextTranslationId = isFavorite
    ? DesktopTranslation.removeItemFromFavorites
    : DesktopTranslation.addItemToFavorites;

  return (
    <Menu data-testid="desktop-app-context-menu" {...props} width="auto">
      <MenuItem
        icon={isFavorite ? HeartFullIcon : HeartIcon}
        onClick={onFavoriteClick}
        data-testid="add-to-favorites">
        <FormattedMessage id={favoriteItemTextTranslationId} />
      </MenuItem>
      {canAddApp && appNode.app.custom && (
        <MenuItem
          icon={EditIcon}
          onClick={handleEditAppClick}
          data-testid="edit-app">
          <FormattedMessage id={DesktopTranslation.appShowDetailsApp} />
        </MenuItem>
      )}
      {keyset && canUnlockCredentials && (
        <MenuItem
          icon={EditIcon}
          onClick={handleSelectVaultClick}
          isDisabled={disableSelectVaultOption}
          data-testid="select-vault">
          <FormattedMessage id={DesktopTranslation.appSelectVaultApp} />
        </MenuItem>
      )}
      {login && (
        <MenuItem
          icon={CopyIcon}
          onClick={handleCopyAppLoginClick}
          data-testid="copy-login">
          <FormattedMessage id={DesktopTranslation.appLinkCopyLoginApp} />
        </MenuItem>
      )}
      {hasPassword && (
        <MenuItem
          icon={CopyIcon}
          onClick={handleCopyAppPasswordClick}
          data-testid="copy-password">
          <FormattedMessage id={DesktopTranslation.appLinkCopyPasswordApp} />
        </MenuItem>
      )}
      {canRemoveApp && (
        <MenuItem
          icon={TrashIcon}
          onClick={handleDeleteAppFromDesktop}
          data-testid="remove-app">
          <FormattedMessage id={DesktopTranslation.appLinkRemoveApp} />
        </MenuItem>
      )}
    </Menu>
  );
};
