import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import {
  TimelineEventScope,
  TimelineEventAction,
} from '../../Timeline.constants';
import { TimelineEventApiType } from '../../Timeline.types';

interface TimelineEventUndoTypeProps {
  event: TimelineEventApiType;
}

export const TimelineEventUndoType: FC<TimelineEventUndoTypeProps> = ({
  event,
}) => {
  const renderTimelineEventUndoType = () => {
    switch (event.scopeType) {
      case TimelineEventScope.desktops:
        switch (event.action) {
          case TimelineEventAction.remove_app_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventTitleTheAppsSection}
              />
            );
          case TimelineEventAction.remove_link_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventTitleTheLinksSection}
              />
            );
        }
        break;
      case TimelineEventScope.apps:
        switch (event.action) {
          case TimelineEventAction.remove_custom_from_desktop:
            return (
              <FormattedMessage id={TimelineTranslation.eventTitleTheApp} />
            );
        }
        break;
      case TimelineEventScope.links:
        switch (event.action) {
          case TimelineEventAction.remove:
            return (
              <FormattedMessage id={TimelineTranslation.eventTitleTheLink} />
            );
        }
        break;

      default:
        return null;
    }
  };

  return <>{renderTimelineEventUndoType()}</>;
};
