import { RangeStatic } from 'quill';
import { BindigKeyCode, BindigKeyType } from './Editor.types';

export interface BindingsHandler {
  keyboardSpaceKeyHandle: (range: RangeStatic, context: any) => void;
  keyboardArrowKeyHandle: (range: RangeStatic, context: any) => void;
  keyboardEnterKeyHandle: (range: RangeStatic, context: any) => void;
  keyboardCtrlEnterKeyHandle: (range: RangeStatic, context: any) => void;
  keyboardDeleteKeyHandle: (range: RangeStatic, context: any) => void;
}

export const EditorKeyboardBindings = ({
  keyboardSpaceKeyHandle,
  keyboardArrowKeyHandle,
  keyboardEnterKeyHandle,
  keyboardCtrlEnterKeyHandle,
  keyboardDeleteKeyHandle,
}: BindingsHandler) => ({
  space: {
    key: BindigKeyCode.space,
    handler: (range: RangeStatic, context: any) =>
      keyboardSpaceKeyHandle(range, {
        ...context,
        key: BindigKeyType.space,
      }),
  },
  spaceWithShift: {
    key: BindigKeyCode.space,
    shiftKey: true,
    handler: (range: RangeStatic, context: any) =>
      keyboardSpaceKeyHandle(range, {
        ...context,
        key: BindigKeyType.space,
      }),
  },
  arrowDown: {
    key: BindigKeyCode.arrowDown,
    handler: (range: RangeStatic, context: any) => {
      keyboardArrowKeyHandle(range, {
        ...context,
        key: BindigKeyType.arrowDown,
      });

      return true;
    },
  },
  arrowUp: {
    key: BindigKeyCode.arrowUp,
    handler: (range: RangeStatic, context: any) => {
      keyboardArrowKeyHandle(range, {
        ...context,
        key: BindigKeyType.arrowUp,
      });

      return true;
    },
  },
  tab: {
    key: BindigKeyCode.tab,
    handler: (range: RangeStatic, context: any) =>
      keyboardArrowKeyHandle(range, {
        ...context,
        key: BindigKeyType.tab,
      }),
  },
  escape: {
    key: BindigKeyCode.escape,
    handler: (range: RangeStatic, context: any) =>
      keyboardArrowKeyHandle(range, {
        ...context,
        key: BindigKeyType.escape,
      }),
  },
  enter: {
    key: BindigKeyCode.enter,
    shiftKey: false,
    ctrlKey: false,
    handler: (range: RangeStatic, context: any) => {
      keyboardEnterKeyHandle(range, {
        ...context,
        key: BindigKeyType.enter,
      });
      keyboardArrowKeyHandle(range, {
        ...context,
        key: BindigKeyType.enter,
      });
    },
  },
  enterWithCtrlKey: {
    key: BindigKeyCode.enter,
    ctrlKey: true,
    handler: (range: RangeStatic, context: any) =>
      keyboardCtrlEnterKeyHandle(range, {
        ...context,
        key: BindigKeyType.enter,
      }),
  },
  deleteKey: {
    key: BindigKeyCode.delete,
    handler: (range: RangeStatic, context: any) =>
      keyboardDeleteKeyHandle(range, {
        ...context,
        key: BindigKeyType.delete,
      }),
  },
});
