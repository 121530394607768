import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { WindowMessageContext } from './WindowMessage.context';
import { WindowMessage, WindowMessageType } from './WindowMessage.types';

export const WindowMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messageCallbackMap, setMessageCallbackMap] =
    useState<Record<WindowMessageType, (data?: WindowMessage) => void>>();

  const setMessageCallback = (
    type: WindowMessageType,
    callback: (data?: WindowMessage) => void,
  ) => {
    setMessageCallbackMap(map => ({ ...(map || {}), [type]: callback }));
  };

  useEffect(() => {
    window.onmessage = message => {
      Object.entries(messageCallbackMap || {}).forEach(
        ([messageType, callback]) => {
          if (message?.data?.type === messageType) {
            callback(message?.data);
          }
        },
      );
    };
  }, [messageCallbackMap]);

  return (
    <WindowMessageContext.Provider
      value={{
        setMessageCallback,
      }}>
      {children}
    </WindowMessageContext.Provider>
  );
};
