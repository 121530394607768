import {
  REGEX_MESSAGE_LINK,
  REGEX_MESSAGE_MENTION,
} from '../../../../Chat.constants';
import { MessageParts, MessagePartTypes } from './RichMessage.types';

const apply =
  (...callbacks: Array<(messageParts: MessageParts[]) => MessageParts[]>) =>
  (value: MessageParts[]) =>
    callbacks.reduce((acc, callback) => callback(acc), value);

const findTypeByRegex =
  (type: Exclude<MessagePartTypes, MessagePartTypes.Text>, regex: RegExp) =>
  (messageParts: MessageParts[]) =>
    messageParts.reduce<MessageParts[]>((acc, messagePart) => {
      if (messagePart.type === MessagePartTypes.Text) {
        const matches = messagePart.value?.match(regex);
        if (matches) {
          const newParts: MessageParts[] = [];
          const lastText = matches.reduce((remainder, match) => {
            const [before] = remainder.split(match, 1);
            if (before) {
              newParts.push({
                type: MessagePartTypes.Text,
                value: before,
              });
            }
            newParts.push({
              type,
              value: match,
            });
            return remainder.substr(before.length + match.length);
          }, messagePart.value);
          if (lastText) {
            newParts.push({
              type: MessagePartTypes.Text,
              value: lastText,
            });
          }
          return [...acc, ...newParts];
        }
      }
      return [...acc, messagePart];
    }, []);

const findLinks = findTypeByRegex(MessagePartTypes.Link, REGEX_MESSAGE_LINK);

const findMentions = findTypeByRegex(
  MessagePartTypes.Mention,
  REGEX_MESSAGE_MENTION,
);

export const convertToMessageParts = (message: string): MessageParts[] =>
  apply(
    findLinks,
    findMentions,
  )([{ type: MessagePartTypes.Text, value: message }]);

export const getHostname = (value: string): string | null => {
  try {
    return new URL(value).hostname;
  } catch {
    return null;
  }
};
