import React, { FC } from 'react';
import { TimelineEventScope } from '../../Timeline.constants';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import styled, { css } from 'styled-components';

const TimelineFolder = styled.span<{ styleAsClickable: boolean }>`
  ${p =>
    p.styleAsClickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${p => p.theme.colors.Primary};
      }
    `}
`;

interface TimelineFolderInfoProps {
  folderName?: string;
  folderId?: string;
  eventScope?: TimelineEventScope;
}

export const TimelineFolderInfo: FC<TimelineFolderInfoProps> = ({
  folderName,
  folderId,
  eventScope,
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const renderFormattedMessage = () => {
    if (eventScope === TimelineEventScope.links && folderName) {
      return (
        <>
          <FormattedMessage id={TimelineTranslation.eventInLabel} />{' '}
        </>
      );
    }
  };

  return (
    <>
      {renderFormattedMessage()}
      <TimelineFolder
        className="highlighted"
        styleAsClickable={!!folderId}
        onClick={() =>
          folderId &&
          navigate({
            search: getQueryParamsFrom({
              ...queryParams,
              folderId: folderId,
            }),
          })
        }>
        {folderName}
      </TimelineFolder>
    </>
  );
};
