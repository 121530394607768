import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const EnterIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53 14.78a.75.75 0 0 0 0-1.06l-2.97-2.97H10A4.75 4.75 0 0 0 14.75 6v-.255A4.75 4.75 0 0 0 10 1.001H8.5a.75.75 0 0 0 0 1.5H10a3.25 3.25 0 0 1 3.25 3.25V6A3.25 3.25 0 0 1 10 9.25H3.56l2.97-2.97a.75.75 0 1 0-1.06-1.06L1.22 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 0 0 1.06 0z"
        fill="currentColor"
        fillOpacity=".37"
      />
    </svg>
  );
});
