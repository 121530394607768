export enum ImageOrientationType {
  Portrait = 'portrait',
  Landscape = 'landscape',
  Square = 'square',
  Unidentified = 'unidentified',
}

export interface ImageOrientationSize {
  width: number;
  height: number;
}
