import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_TEMPLATES,
  GetTemplatesResponse,
  GetTemplatesVariables,
} from './Template.queries';
import {
  APPLY_TEMPLATES_TO_DESKTOP,
  ApplyTemplatesToDesktopResponse,
  ApplyTemplatesToDesktopVariables,
} from './Template.mutations';

export const useGetTemplatesQuery = (
  options?: QueryHookOptions<GetTemplatesResponse, GetTemplatesVariables>,
) =>
  useQuery<GetTemplatesResponse, GetTemplatesVariables>(GET_TEMPLATES, options);

export const useApplyTemplatesToDesktopMutation = () =>
  useMutation<
    ApplyTemplatesToDesktopResponse,
    ApplyTemplatesToDesktopVariables
  >(APPLY_TEMPLATES_TO_DESKTOP);
