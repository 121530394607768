import { useApolloClient } from '@apollo/client';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { extractNodes } from '../../../shared/api/api.utils';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { mergeWithArray } from '../../../shared/utils/list.utils';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { CONFERENCES_CONFERENCE_PATHNAME } from '../../Desktop/Desktop.constants';
import { SegmentTranslation } from '../../Segment/i18n';
import { SegmentContent } from '../../Segment';
import { SegmentNavigator } from '../../Segment/SegmentNavigator/SegmentNavigator';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import {
  useChatConferenceQuery,
  useChatConferencesQuery,
  useDeleteRepeatingScheduledChatConferencesMutation,
  useDeleteScheduledChatConferenceMutation,
  useRedirectMeetings,
  useScheduledChatConferenceQuery,
  useScheduledChatConferencesQuery,
} from '../Conference.hooks';
import {
  GetChatConferencesResponse,
  GetScheduledChatConferencesResponse,
} from '../Conference.queries';
import { ConferencesTabsValues, FilterPeriod } from '../Conference.types';
import { ConferencesPage, DEFAULT_PAGE_SIZE } from '../ConferencesPage';
import { ConferencesNavigator } from './ConferencesNavigator';
import _orderBy from 'lodash/orderBy';
import { LoadMoreObserver } from '../../../shared/components/LoadMore';
import { ConferenceContext } from '../Conference.context';
import { differenceInMilliseconds, isToday } from 'date-fns';
import {
  addScheduledConferenceToListCache,
  removeScheduledConferenceFromListCache,
} from '../cache/ScheduledConference.cache';
import { ConferenceNavigatorTitleActions } from './ConferencesNavigator/ConferenceNavigatorTitleActions/ConferenceNavigatorTitleActions';
import { usePreviewSegment } from '../../PreviewSegment/PreviewSegment.hooks';
import { useSegmentTrackingEventOnInit } from '../../Segment/tracking/SegmentTracking.hooks';
import { getShortId } from '../../../shared/utils/id';

const LOAD_MORE_RETRY_TIMEOUT = 5000;

export const ConferenceSegment: FC = () => {
  const { formatMessage } = useIntl();
  const { workspace } = useCurrentWorkspace();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const lastSelectedConferenceIdRef = useRef('');
  const { meetingsActiveTab, refetchScheduledConference } = queryParams;
  const lastSelectedTabRef = useRef(Number(meetingsActiveTab));
  const [loadingMoreConferences, setLoadingMoreConferences] = useState(false);
  const { isPreviewMode } = usePreviewSegment();

  useSegmentTrackingEventOnInit('open_meetings');

  const { data, loading, fetchMore } = useChatConferencesQuery({
    variables: {
      first: DEFAULT_PAGE_SIZE,
      workspace: workspace.id,
    },
  });

  const conferences = useMemo(() => {
    return extractNodes(data?.chatConferences);
  }, [data]);

  const {
    data: scheduledConferencesFutureData,
    loading: loadingConferencesFuture,
    fetchMore: fetchMoreConferencesFuture,
    refetch: refetchConferenceFuture,
  } = useScheduledChatConferencesQuery({
    variables: {
      first: DEFAULT_PAGE_SIZE,
      workspace: workspace.id,
      filterPeriod: FilterPeriod.future,
    },
    //For some reason cache-and-network return old values from response
    //after refetch this query, this condition allows getting updated values on refetch
    fetchPolicy: refetchScheduledConference
      ? 'cache-first'
      : 'cache-and-network',
  });

  const {
    data: scheduledConferencesPastData,
    loading: loadingConferencesPast,
    fetchMore: fetchMoreConferencesPast,
    refetch: refetchConferencePast,
  } = useScheduledChatConferencesQuery({
    variables: {
      first: DEFAULT_PAGE_SIZE,
      workspace: workspace.id,
      filterPeriod: FilterPeriod.past,
    },
    //For some reason cache-and-network return old values from response
    //after refetch this query, this condition allows getting updated values on refetch
    fetchPolicy: refetchScheduledConference
      ? 'cache-first'
      : 'cache-and-network',
  });

  const scheduledConferencesFuture = useMemo(() => {
    return extractNodes(
      scheduledConferencesFutureData?.scheduleChatConferences,
    );
  }, [scheduledConferencesFutureData]);

  const scheduledConferencesPast = useMemo(() => {
    return extractNodes(scheduledConferencesPastData?.scheduleChatConferences);
  }, [scheduledConferencesPastData]);

  const currentConferenceId = useMemo(() => {
    const match = matchPath(CONFERENCES_CONFERENCE_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { conferenceId } = match.params;
    lastSelectedConferenceIdRef.current = conferenceId as string;
    return conferenceId;
  }, [pathname]);

  const { data: currentConferenceData } = useChatConferenceQuery({
    skip:
      !currentConferenceId ||
      Number(meetingsActiveTab) === ConferencesTabsValues.SCHEDULED,
    variables: {
      id: '/chat-conferences/' + currentConferenceId,
    },
  });

  const {
    data: currentScheduledConferenceData,
    loading: currentScheduledConferenceLoading,
  } = useScheduledChatConferenceQuery({
    skip:
      !currentConferenceId ||
      Number(meetingsActiveTab) === ConferencesTabsValues.CALLS,
    variables: {
      id: '/schedule-chat-conferences/' + currentConferenceId,
    },
  });

  const currentConference = useMemo(() => {
    return currentConferenceData?.chatConference || undefined;
  }, [currentConferenceData]);

  const currentScheduledConference = useMemo(() => {
    return currentScheduledConferenceData?.scheduleChatConference || undefined;
  }, [currentScheduledConferenceData]);

  const [deleteScheduledConference] =
    useDeleteScheduledChatConferenceMutation();
  const [deleteRepeatingScheduledConference] =
    useDeleteRepeatingScheduledChatConferencesMutation();

  const handleDeleteConference = useCallback(
    (deleteAllConferences = false, deleteThisAndFollowing = false) => {
      if (!currentScheduledConference) {
        return;
      }

      if (
        deleteAllConferences &&
        currentScheduledConference?.repeatingScheduleChatConference?.id
      ) {
        deleteRepeatingScheduledConference({
          variables: {
            input: {
              id: currentScheduledConference.repeatingScheduleChatConference.id,
              _id: currentScheduledConference.repeatingScheduleChatConference
                .id,
              workspace: workspace.id,
              ...{
                ...(deleteThisAndFollowing && {
                  deleteOnlyAfter: currentScheduledConference.id,
                }),
              },
            },
          },
        }).then(() =>
          navigate({
            pathname: `/workspace/${getShortId(workspace.id)}/calls`,
            search: getQueryParamsFrom({
              ...queryParams,
              scheduleConferenceDeleteConfirmationModalOpen: undefined,
              scheduleConferenceOpened: undefined,
              meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
            }),
          }),
        );
      } else {
        deleteScheduledConference({
          variables: {
            input: {
              _id: '',
              id: currentScheduledConference.id,
              workspace: workspace.id,
            },
          },
        }).then(() =>
          navigate({
            pathname: `/workspace/${getShortId(workspace.id)}/calls`,
            search: getQueryParamsFrom({
              ...queryParams,
              scheduleConferenceDeleteConfirmationModalOpen: undefined,
              scheduleConferenceOpened: undefined,
              meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
            }),
          }),
        );
      }
    },
    [
      currentScheduledConference,
      deleteRepeatingScheduledConference,
      deleteScheduledConference,
      navigate,
      queryParams,
      workspace.id,
    ],
  );

  useRedirectMeetings(conferences, currentConferenceId);

  const handleLoadMoreScheduled = useCallback(
    (filterPeriod: FilterPeriod) => {
      const scheduledConferencesData =
        filterPeriod === FilterPeriod.future
          ? scheduledConferencesFutureData
          : scheduledConferencesPastData;
      const fetchMore =
        filterPeriod === FilterPeriod.future
          ? fetchMoreConferencesFuture
          : fetchMoreConferencesPast;

      fetchMore({
        variables: {
          after:
            scheduledConferencesData?.scheduleChatConferences.pageInfo
              ?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return mergeWithArray(
            // TODO: Get rid of this fallback after Apollo issue will be resolved - https://github.com/apollographql/apollo-client/issues/5169
            prev || scheduledConferencesData,
            fetchMoreResult || {},
          ) as GetScheduledChatConferencesResponse;
        },
      }).catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
    },
    [
      fetchMoreConferencesFuture,
      fetchMoreConferencesPast,
      scheduledConferencesFutureData,
      scheduledConferencesPastData,
    ],
  );

  const handleLoadMore = useCallback(() => {
    if (!fetchMore) {
      return;
    }

    setLoadingMoreConferences(true);
    fetchMore({
      variables: {
        after: data?.chatConferences.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return mergeWithArray(
          // TODO: Get rid of this fallback after Apollo issue will be resolved - https://github.com/apollographql/apollo-client/issues/5169
          prev || data,
          fetchMoreResult || {},
        ) as GetChatConferencesResponse;
      },
    })
      .then(() => setLoadingMoreConferences(false))
      .catch(e => {
        setTimeout(
          () => setLoadingMoreConferences(false),
          LOAD_MORE_RETRY_TIMEOUT,
        );
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [data, fetchMore]);

  useEffect(() => {
    if (!queryParams.skipConferenceRedirect) {
      return;
    }

    if (!currentConferenceId) {
      if (Number(meetingsActiveTab) === ConferencesTabsValues.SCHEDULED) {
        if (
          (scheduledConferencesFuture.length ||
            scheduledConferencesPast.length) &&
          !isMobile
        ) {
          //TODO Investigate how to sync update list of scheduled conferences from Mercure, with current deletion implementation
          let conference = null;
          if (scheduledConferencesFuture.length) {
            if (scheduledConferencesFuture.length === 1) {
              conference = scheduledConferencesPast.length
                ? scheduledConferencesPast[0]
                : scheduledConferencesFuture[0]._id !==
                  lastSelectedConferenceIdRef.current
                ? scheduledConferencesFuture[0]
                : null;
            } else if (
              scheduledConferencesFuture[1]._id ===
              lastSelectedConferenceIdRef.current
            ) {
              conference = scheduledConferencesFuture[0];
            } else {
              conference = scheduledConferencesFuture[1];
            }
          } else if (scheduledConferencesPast.length) {
            conference =
              scheduledConferencesPast.length === 1
                ? scheduledConferencesPast[0]
                : scheduledConferencesPast[1];
          } else {
            return;
          }
          navigate({
            pathname: `/workspace/${getShortId(workspace.id)}/calls/${
              conference ? getShortId(conference.id) : ''
            }`,
            search: getQueryParamsFrom({
              ...queryParams,
              meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
            }),
          });
        }
      } else if (conferences.length && !currentConferenceId && !isMobile) {
        const { _id: conferenceId } = conferences[0];
        navigate({
          pathname: `/workspace/${getShortId(
            workspace.id,
          )}/calls/${conferenceId}`,
          search: getQueryParamsFrom({
            ...queryParams,
            meetingsActiveTab: ConferencesTabsValues.CALLS,
          }),
        });
      }
    } else if (
      currentConferenceId &&
      lastSelectedTabRef.current !== Number(meetingsActiveTab)
    ) {
      let conferenceId = null;
      if (Number(meetingsActiveTab) === ConferencesTabsValues.SCHEDULED) {
        conferenceId = scheduledConferencesFuture.length
          ? scheduledConferencesFuture[0]._id
          : scheduledConferencesPast.length
          ? scheduledConferencesPast[0]._id
          : null;
      } else if (Number(meetingsActiveTab) === ConferencesTabsValues.CALLS) {
        conferenceId = conferences.length ? conferences[0]._id : null;
      }
      if (`${currentConferenceId}` !== `${conferenceId}`) {
        navigate({
          pathname: `/workspace/${getShortId(
            workspace.id,
          )}/calls/${conferenceId}`,
          search: getQueryParamsFrom({
            ...queryParams,
          }),
        });
      }
      lastSelectedTabRef.current = Number(meetingsActiveTab);
    }
  }, [
    conferences,
    currentConferenceId,
    navigate,
    isMobile,
    scheduledConferencesFuture,
    scheduledConferencesPast,
    meetingsActiveTab,
    workspace.id,
    queryParams,
    currentScheduledConference,
  ]);

  useEffect(() => {
    if (refetchScheduledConference) {
      Promise.all([refetchConferenceFuture(), refetchConferencePast()]).then(
        res => {
          const { _id: conferenceId } = res[0].data.scheduleChatConferences
            .edges.length
            ? res[0].data.scheduleChatConferences.edges[0].node
            : res[1].data.scheduleChatConferences.edges[0].node;
          const scheduledConferenceUrl = `/workspace/${getShortId(
            workspace.id,
          )}/calls/${conferenceId}?meetingsActiveTab=${
            ConferencesTabsValues.SCHEDULED
          }`;
          navigate(scheduledConferenceUrl, { replace: true });
        },
      );
    }
  }, [
    navigate,
    refetchConferenceFuture,
    refetchConferencePast,
    refetchScheduledConference,
    workspace.id,
  ]);

  const scheduleConferenceProps = {
    scheduledConferencesFuture: scheduledConferencesFuture,
    scheduledConferencesPast: scheduledConferencesPast,
    loadingConferencesFuture: loadingConferencesFuture,
    loadingConferencesPast: loadingConferencesPast,
    fetchMoreScheduledConferences: handleLoadMoreScheduled,
    hideLoadMoreConferencesFuture:
      scheduledConferencesFutureData?.scheduleChatConferences.pageInfo
        ?.hasNextPage,
    hideLoadMoreConferencesPast:
      scheduledConferencesPastData?.scheduleChatConferences.pageInfo
        ?.hasNextPage,
  };
  const apolloClient = useApolloClient();
  const timeouts = useRef<ReturnType<typeof setTimeout>[]>();

  useEffect(() => {
    if (scheduledConferencesFuture.length) {
      const todaysMeetings = _orderBy(
        scheduledConferencesFuture.filter(meeting =>
          isToday(new Date(meeting.endAt)),
        ),
        'endAt',
      );

      if (!todaysMeetings.length) {
        return;
      }

      if (timeouts.current?.length) {
        timeouts.current?.forEach(timeout => clearTimeout(timeout));
      }

      timeouts.current = todaysMeetings.map(meeting =>
        setTimeout(() => {
          addScheduledConferenceToListCache(
            apolloClient,
            {
              first: DEFAULT_PAGE_SIZE,
              workspace: workspace.id,
              filterPeriod: FilterPeriod.past,
            },
            meeting,
          );
          removeScheduledConferenceFromListCache(
            apolloClient,
            {
              first: DEFAULT_PAGE_SIZE,
              workspace: workspace.id,
              filterPeriod: FilterPeriod.future,
            },
            meeting.id,
          );
        }, differenceInMilliseconds(new Date(meeting.endAt), new Date())),
      );

      return () => {
        timeouts.current?.forEach(timeout => clearTimeout(timeout));
      };
    }
  }, [apolloClient, scheduledConferencesFuture, workspace.id]);

  return (
    <ConferenceContext.Provider
      value={{
        conference: currentConference,
        scheduledConference: currentScheduledConference,
        scheduledConferenceLoading: currentScheduledConferenceLoading,
        refetchConferenceFuture: refetchConferenceFuture,
      }}>
      <SegmentNavigator
        defaultPadding={false}
        segmentTitle={formatMessage({
          id: SegmentTranslation.segmentMeetings,
        })}
        navigatorTitleActions={<ConferenceNavigatorTitleActions />}>
        <ConferencesNavigator
          className="conference-list"
          conferences={conferences}
          scheduleConferenceProps={scheduleConferenceProps}
          listFooter={() => {
            return data?.chatConferences.pageInfo?.hasNextPage ? (
              <LoadMoreObserver
                loading={loadingMoreConferences}
                onLoadMore={handleLoadMore}
              />
            ) : null;
          }}
        />
      </SegmentNavigator>
      {!isPreviewMode && (
        <SegmentContent>
          <ConferencesPage
            loading={loading}
            currentConferenceId={currentConferenceId}
            currentScheduledConference={currentScheduledConference}
            currentConference={currentConference}
            handleDeleteConference={handleDeleteConference}
          />
        </SegmentContent>
      )}
    </ConferenceContext.Provider>
  );
};
