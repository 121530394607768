import Dexie, { Table } from 'dexie';
import { PERSISTED_LOG_TABLE_COLUMNS } from './PersistedLog.constants';
import { PersistedLog } from './PersistedLog.types';

export const DATABASE_NAME = 'desktop-com-logs';

// https://dexie.org/docs/Tutorial/Design#database-versioning
const DATABASE_VERSION = 1;

class PersistedLogStorage extends Dexie {
  logs!: Table<PersistedLog>;

  constructor() {
    super(DATABASE_NAME);

    this.version(DATABASE_VERSION).stores({
      logs: PERSISTED_LOG_TABLE_COLUMNS,
    });
  }
}

export const persistedLogStorage = new PersistedLogStorage();
