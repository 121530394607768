import { useEffect } from 'react';
import {
  sendAppTrackingEvent,
  sendDesktopTrackingEvent,
} from './DesktopTracking.utils';
import {
  AppTrackingAction,
  DesktopTrackingAction,
} from './DesktopTracking.types';

export const useDesktopTrackingEventOnInit = (
  action: DesktopTrackingAction,
) => {
  useEffect(() => {
    sendDesktopTrackingEvent(action);
  }, []); // eslint-disable-line
};

export const useAppTrackingEventOnInit = (action: AppTrackingAction) => {
  useEffect(() => {
    sendAppTrackingEvent(action);
  }, []); // eslint-disable-line
};
