import React from 'react';
import { FolderApiType } from '../../Folder.types';
import { Menu, MenuItem } from '../../../../shared/components/Menu';
import {
  EditIcon,
  HeartFullIcon,
  HeartIcon,
  TrashIcon,
} from '../../../../shared/icons';
import { useNavigate } from 'react-router-dom';
import {
  HorizontalAlignment,
  MenuProps,
  VerticalAlignment,
} from '../../../../shared/components/Menu/Menu.types';
import { FormattedMessage } from 'react-intl';
import { FolderTranslation } from '../../i18n';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../shared/hooks';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { useCurrentDesktopPermissions } from '../../../Desktop/Desktop.hooks';
import { getShortId } from '../../../../shared/utils/id';
import { PermissionContext } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface FoldersMenuProps extends MenuProps {
  handleRemoveFolder: () => void;
  toggleFavoriteFolder: () => void;
  folder: FolderApiType;
  handleOpenMenu: (isOpened: boolean) => void;
}

export const FoldersMenu = (props: FoldersMenuProps) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { handleRemoveFolder, folder, handleOpenMenu } = props;
  const { canEditFolder, canRemoveFolder } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(folder.desktop.id),
  );
  return (
    <Menu
      width={208}
      maxHeight={280}
      vAlign={VerticalAlignment.BOTTOM}
      hAlign={HorizontalAlignment.LEFT}
      onOpen={() => handleOpenMenu(true)}
      onClose={() => handleOpenMenu(false)}
      data-testid="desktop-folder-context-menu"
      {...props}>
      {canEditFolder && (
        <MenuItem
          icon={EditIcon}
          onClick={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                editFolderId: getShortId(folder.id),
              }),
            })
          }
          data-testid="rename-folder">
          <FormattedMessage id={FolderTranslation.linksViewEditFolder} />
        </MenuItem>
      )}
      <MenuItem
        icon={folder.accountFavorite ? HeartFullIcon : HeartIcon}
        onClick={props.toggleFavoriteFolder}
        data-testid="add-to-favorites">
        <FormattedMessage
          id={
            folder.accountFavorite
              ? DesktopTranslation.removeItemFromFavorites
              : DesktopTranslation.addItemToFavorites
          }
        />
      </MenuItem>
      {canRemoveFolder && (
        <MenuItem
          icon={TrashIcon}
          onClick={handleRemoveFolder}
          data-testid="delete-folder">
          <FormattedMessage id={FolderTranslation.linksViewRemoveFolder} />
        </MenuItem>
      )}
    </Menu>
  );
};
