import styled, { css } from 'styled-components';
import {
  Button,
  ButtonMode,
} from '../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';

export const OnboardingUseCaseStepHeader = styled(OnboardingStepHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const OnboardingUseCaseStepText = styled(OnboardingStepText)`
  margin: 0 0 ${({ theme }) => theme.spacing.xxl} 0;
`;

export const UseCaseNextButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xxl};
`;

export const OnboardingCaseFieldsGroup = styled.div`
  button:last-child {
    margin: 0;
  }
`;

export const OnboardingCaseButton = styled(ButtonWithIcon)<{
  mode?: ButtonMode;
}>`
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.m};
  > span {
    flex-direction: column;
    span:nth-child(2) {
      font-family: ${p => p.theme.fonts.Medium};

      ${({ mode, theme }) =>
        mode !== ButtonMode.primary &&
        css`
          color: ${theme.colors.OnSurfaceMediumEmphasis};
        `}
    }
  }
  span ~ svg {
    flex: initial;
    flex-shrink: 0;
  }
`;
