import React, { ComponentType, useCallback, useEffect, useRef } from 'react';
import { useChatConversationRepository } from '../Chat/Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';

// The wrapper is needed to preload some data (Like conversations)
// since in some cases (as preview functionality) segments are deactivating

export function withSegmentWrapper<T>(WrappedComponent: ComponentType<T>) {
  const ChatMessageThreadWrapper = (props: T) => {
    const { workspace } = useCurrentWorkspace();
    const { getInitialConversations } = useChatConversationRepository();
    const activeWorkspaceId = useRef('');

    const loadChatConversations = useCallback(() => {
      if (workspace.id && activeWorkspaceId.current !== workspace.id) {
        activeWorkspaceId.current = workspace.id;
        getInitialConversations();
      }
    }, [getInitialConversations, workspace.id]);

    useEffect(() => {
      loadChatConversations();
    }, [loadChatConversations]);

    return <WrappedComponent {...props} workspace={workspace} />;
  };

  return ChatMessageThreadWrapper;
}
