import React, { FC, useMemo } from 'react';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  SegmentPageTitleBar,
  TitleBarControls,
  TitleBarTitle,
} from '../../../Segment/SegmentPageTitleBar';
import { VaultApiType } from '../../Encryption.types';
import {
  getVaultAccess,
  getVaultApp,
  getVaultItem,
  isVaultManuallyCreated,
} from '../../Encryption.utils';
import { PasswordsImage } from '../PasswordsImage/PasswordsImage';
import { PasswordViewControls } from '../PasswordView/PasswordViewControls';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
} from '../../Encryption.hooks';
import { useMobile, useTablet } from '../../../../shared/hooks';
import { FormattedMessage } from 'react-intl';
import { SegmentTranslation } from '../../../Segment/i18n';
import { PasswordsNavigatorTitleActions } from '../PasswordsSegment/PasswordsNavigatorTitleActions/PasswordsNavigatorTitleActions';
import { StyledPasswordTitle } from './PasswordSegmentTitleBar.styled';

interface PasswordsSegmentTitleBarProps {
  vault?: VaultApiType;
  hideMenuButton?: boolean;
}

export const PasswordsSegmentTitleBar: FC<PasswordsSegmentTitleBarProps> = ({
  vault,
  hideMenuButton = false,
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const vaultApp = useMemo(() => getVaultApp(vault), [vault]);
  const { keyset } = useCurrentAccountKeyset();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const keysetId = keyset?.id || '';
  const vaultItemDomain = useMemo(() => {
    const vaultItemUrl = (vault && getVaultItem(vault).url) || '';

    // TODO: extract into separate util --> also in PasswordView
    try {
      return new URL(vaultItemUrl).hostname;
    } catch {
      return null;
    }
  }, [vault]);

  const vaultItem = useMemo(() => {
    return extractNodes(vault?.vaultItems)[0];
  }, [vault]);

  const isManual = useMemo(() => {
    return vault && isVaultManuallyCreated(vault);
  }, [vault]);

  const imageSrc = isManual
    ? vaultItem?.image?.contentUrl
    : vaultApp?.logo.contentUrl;

  const name = isManual ? vaultItemDomain : vaultApp?.fullName;
  const {
    permissions: { canExplicitlyShareVault },
  } = useCurrentWorkspacePermissions();

  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keysetId);
  }, [vault, keyset, keysetId]);
  const vaultAccessShared = vaultAccess?.shared;
  const vaultAccessKey = vaultAccess?.vaultKeyEncrypted;

  return (
    <SegmentPageTitleBar hideMenuButton={hideMenuButton}>
      <TitleBarTitle>
        {(isMobile || isTablet) && !vault ? (
          <FormattedMessage id={SegmentTranslation.segmentPasswords} />
        ) : (
          privateKey &&
          name && (
            <StyledPasswordTitle>
              <PasswordsImage size={24} url={imageSrc} />
              {name}
            </StyledPasswordTitle>
          )
        )}
      </TitleBarTitle>
      <TitleBarControls>
        {(isMobile || isTablet) && !vault ? (
          <PasswordsNavigatorTitleActions />
        ) : (
          privateKey &&
          vault && (
            <PasswordViewControls
              displayButtons={
                vaultAccessShared ? ['unshare'] : ['edit', 'share', 'delete']
              }
              disableShareButton={!canExplicitlyShareVault}
              disableControls={!vaultAccessKey}
            />
          )
        )}
      </TitleBarControls>
    </SegmentPageTitleBar>
  );
};
