import React, { FC, memo, useContext } from 'react';
import { PeopleIcon, SharpIcon } from '../../../../../../shared/icons';
import { useChatConversationTitle } from '../../../../Chat.hooks';
import { ConversationType } from '../../../../Chat.types';
import {
  PEOPLE_ICON_SIZE,
  SHARP_ICON_SIZE,
} from './ChatMessageThreadSidebar.constants';
import { ChatMessageThreadSidebarContext } from './ChatMessageThreadSidebar.context';

export const ChatMessageThreadSidebarTitle: FC = memo(() => {
  const { conversation } = useContext(ChatMessageThreadSidebarContext);

  const conversationTitle = useChatConversationTitle(conversation);

  switch (conversation?.type) {
    case ConversationType.desktop:
      return (
        <>
          <SharpIcon width={SHARP_ICON_SIZE} height={SHARP_ICON_SIZE} />
          {conversationTitle}
        </>
      );
    case ConversationType.group:
    case ConversationType.pending:
      return (
        <>
          <PeopleIcon width={PEOPLE_ICON_SIZE} height={PEOPLE_ICON_SIZE} />
          {conversationTitle}
        </>
      );
    default:
      return <span>{conversationTitle}</span>;
  }
});
