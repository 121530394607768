import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { AssetPreviewContext } from './AssetPreview.context';
import { AssetPreviewControls } from './AssetPreview.types';
import type {
  ChatMessageAssetApiType,
  ChatTextMessageInternalType,
} from '../../Chat/Chat.types';
import { FileAssetId, ImageAssetId } from '../Asset.types';
import { AssetPreviewModal } from '../AssetPreviewModal';

export const AssetPreviewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [assetData, setAssetData] = useState<{
    chatMessage: ChatTextMessageInternalType;
    assets: ChatMessageAssetApiType[];
    assetIri: ImageAssetId | FileAssetId;
  }>();

  const assetPreviewControls: AssetPreviewControls = useMemo(
    () => ({
      showAssetPreview: (assets, chatMessage, assetIri) => {
        setAssetData({
          chatMessage,
          assets,
          assetIri,
        });
      },
    }),
    [],
  );

  return (
    <AssetPreviewContext.Provider value={assetPreviewControls}>
      {children}

      {assetData && (
        <AssetPreviewModal
          visible
          assetIri={assetData.assetIri}
          assets={assetData.assets}
          chatMessage={assetData.chatMessage}
          onRequestClose={() => setAssetData(undefined)}
        />
      )}
    </AssetPreviewContext.Provider>
  );
};
