import React, { forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useMobile } from '../../../../../../../shared/hooks';
import { useHideScrollbarWhenIdle } from '../../../../../../../shared/hooks/element.hooks';
import { mergeRefs } from '../../../../../../../shared/utils/refs.utils';
import { DesktopLayout } from '../../../../../Desktop.constants';
import { useCurrentDesktopContent } from '../../../../../Desktop.hooks';
import { SECTION_BORDER_RADIUS } from '../Section';

const StyledSectionContent = styled.div<{
  isMobile: boolean;
  isTabbed: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
  flex: 1;
  position: relative;

  ${({ isMobile, isTabbed }) =>
    () => {
      if (!isMobile && isTabbed) {
        return css`
          border-bottom-left-radius: ${SECTION_BORDER_RADIUS};
          border-bottom-right-radius: ${SECTION_BORDER_RADIUS};
          padding-top: unset;
        `;
      } else if (!isMobile) {
        return css`
          border-radius: ${SECTION_BORDER_RADIUS};
        `;
      }
    }}
`;

const InnerContent = styled.div<{
  sectionHasScroll: boolean;
  innerContentOverflowYAuto?: boolean;
}>`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  ${p => (p.innerContentOverflowYAuto ? '' : 'overflow-y: auto;')}
  overflow-x: hidden;
  border-radius: 0.5rem;
  ${({ sectionHasScroll }) =>
    sectionHasScroll &&
    css`
      padding-right: 6px; ;
    `}
`;

// export const SectionContent: FC<{ sectionHasScroll: boolean }> = ({
export const SectionContent = forwardRef<
  HTMLDivElement,
  {
    sectionHasScroll: boolean;
    innerContentOverflowYAuto?: boolean;
    children: ReactNode;
  }
>(({ children, sectionHasScroll, innerContentOverflowYAuto }, ref) => {
  const isMobile = useMobile();
  const { desktopLayout } = useCurrentDesktopContent();
  const [SectionScrollHideRef] = useHideScrollbarWhenIdle();

  return (
    <StyledSectionContent
      isMobile={isMobile}
      isTabbed={desktopLayout === DesktopLayout.TABBED}
      data-testid="section-content">
      <InnerContent
        innerContentOverflowYAuto={innerContentOverflowYAuto}
        ref={mergeRefs(ref, SectionScrollHideRef)}
        sectionHasScroll={sectionHasScroll}>
        {children}
      </InnerContent>
    </StyledSectionContent>
  );
});
