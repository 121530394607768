import React, { FC, useCallback, useState } from 'react';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../../OnboardingPage.styled';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { Spinner } from '../../../../../shared/components/Spinner';
import {
  EmailVerificationButton,
  EmailVerificationResendBlock,
} from './EmailVerificationNeeded.styled';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import { GlobalTranslation } from '../../../../Intl/i18n';

const RESEND_SUCCESS_MESSAGE_TIMEOUT = 5000;

export const EmailVerificationNeeded: FC<{
  emailResendAvailable: boolean;
  onResendEmail: () => Promise<any>;
}> = ({ emailResendAvailable, onResendEmail }) => {
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendSuccessMessageVisible, setResendSuccessMessageVisible] =
    useState(false);

  const displayResendSuccessMessage = useCallback(() => {
    setResendSuccessMessageVisible(true);
    setTimeout(() => {
      setResendSuccessMessageVisible(false);
    }, RESEND_SUCCESS_MESSAGE_TIMEOUT);
  }, []);

  const handleResendEmail = useCallback(() => {
    setResendingEmail(true);
    onResendEmail()
      .then(() => {
        setResendingEmail(false);
        displayResendSuccessMessage();
      })
      .catch(err => {
        setResendingEmail(false);
        showToastGraphQLErrors(err.graphQLErrors);
      });
  }, [onResendEmail, displayResendSuccessMessage]);

  return (
    <OnboardingStep data-testid="email-verification-step">
      <OnboardingStepHeader data-testid="header">
        <FormattedMessage
          id={OnboardingTranslation.onboardingActivationPageHeading}
        />
      </OnboardingStepHeader>
      <OnboardingStepText data-testid="text">
        <FormattedMessage
          id={OnboardingTranslation.onboardingActivationPageText}
        />
      </OnboardingStepText>
      <ImportantMessage type={ImportantMessageType.INFO}>
        <FormattedHTMLMessage id={GlobalTranslation.checkEmailMessage} />
      </ImportantMessage>
      {emailResendAvailable && (
        <EmailVerificationResendBlock data-testid="email-resend">
          {resendingEmail ? (
            <Spinner size={24} />
          ) : (
            <>
              {resendSuccessMessageVisible ? (
                <FormattedMessage
                  id={
                    OnboardingTranslation.onboardingActivationResendEmailSuccessMessage
                  }
                />
              ) : (
                <>
                  <FormattedMessage
                    id={
                      OnboardingTranslation.onboardingActivationResendEmailText
                    }
                  />{' '}
                  <EmailVerificationButton onClick={handleResendEmail}>
                    <FormattedMessage
                      id={
                        OnboardingTranslation.onboardingActivationResendEmailButton
                      }
                    />
                  </EmailVerificationButton>
                </>
              )}
            </>
          )}
        </EmailVerificationResendBlock>
      )}
    </OnboardingStep>
  );
};
