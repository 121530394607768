import React, { FC, memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile, useQueryParams } from '../../../../../shared/hooks';
import { LinkDetailsTab } from '../../../../Link/Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { ChatTranslation } from '../../../i18n';
import { ChatMessageThreadContext } from './ChatMessageThread.context';
import {
  ArrowRightStyled,
  ChatMessageThreadViewCountStyled,
} from './ChatMessageThread.styled';
import { getTimeDistance } from './ChatMessageThread.utils';
import { ChatMessageThreadSidebarContext } from './ChatMessageThreadSidebar/ChatMessageThreadSidebar.context';

export const ChatMessageThreadViewCount: FC = memo(() => {
  const isMobile = useMobile();
  const { chatMessage } = useContext(ChatMessageThreadContext);
  const { disableLastCommentAt } = useContext(ChatMessageThreadSidebarContext);
  const queryParams = useQueryParams();
  const { linkDetailsTab } = queryParams;

  if (!chatMessage?.id) {
    return null;
  }

  return (
    <ChatMessageThreadViewCountStyled
      data-testid="chat_message_thread_view_counter_container"
      data-entityid={chatMessage.id}>
      <FormattedMessage
        id={ChatTranslation.threadCount}
        values={{
          count: chatMessage.threadMessagesCount,
        }}
        tagName="span"
      />

      {!isMobile &&
        linkDetailsTab !== LinkDetailsTab.COMMENTS &&
        !disableLastCommentAt && (
          <>
            {chatMessage.lastMessageInThreadAt ? (
              <FormattedMessage
                id={ChatTranslation.lastReplyAt}
                values={{
                  at: getTimeDistance(chatMessage.lastMessageInThreadAt),
                }}
                tagName={props => <div {...props} className="last-reply-at" />}
              />
            ) : null}

            <FormattedMessage
              id={ChatTranslation.viewThread}
              tagName={props => <div {...props} className="view-thread" />}
            />

            <ArrowRightStyled width={10} height={10} />
          </>
        )}
    </ChatMessageThreadViewCountStyled>
  );
});
