import styled from 'styled-components';

export const StyledSharedWIthAccountsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
`;

export const AccountInfo = styled.div`
  display: flex;
  align-items: center;

  > * ~ * {
    margin-left: 0.5rem;
  }
`;

export const AccountTitle = styled.div`
  > * ~ * {
    margin-top: 0.125rem;
  }

  div {
    max-width: 15rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const AccountName = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const AccountEmail = styled.div`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;
