import _get from 'lodash/get';
import { FileTypeEnum, ProcessedFileResult } from './ImportPasswords.types';
import { format } from 'date-fns';

export const logImportPasswordsInfo = (message: string, data?: any) => {
  if (window.DESKTOPCOM_LOG_IMPORT_PASSWORDS) {
    const text = `[${format(new Date(), 'hh:mm:ss.SSS')}] ${message}:`;
    if (message) {
      console.log(`${text}: `, data);
    }
  }
};

const creatEntitiesFromCSV = (
  headers: string[],
  rows: string[],
  delimiter: string,
) => {
  return rows.map(row => {
    const values = row.split(delimiter);
    return headers.reduce((obj, header, i) => {
      try {
        obj[JSON.parse(header.toLowerCase())] = JSON.parse(values[i]);
      } catch (e) {
        obj[header.toLowerCase()] = values[i];
      }

      return obj;
    }, {} as { [index: string]: string });
  });
};

const processCSV = (data: string, delimiter: string = ',') => {
  const headers = data.slice(0, data.indexOf('\n')).split(delimiter);
  const rows = data.slice(data.indexOf('\n') + 1).split('\n');

  logImportPasswordsInfo('parsed headers', headers);
  logImportPasswordsInfo('parsed rows ', rows);

  return creatEntitiesFromCSV(headers, rows, delimiter);
};

const process1PIF = (data: string) => {
  const REG_EXP_ID_1PIF = /\*{3}[a-z,0-9]{8}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{12}\*{3}/gm;
  const rows = [
    data.slice(0, data.indexOf('\n')),
    ...data.slice(data.indexOf('\n')).split('\n'),
  ].filter(row => !!row && !row.match(REG_EXP_ID_1PIF));

  try {
    return rows.map(row => JSON.parse(row));
  } catch (e) {}
};

export const removeDoubleQuotes = (
  string: string,
  removeFromQuotesAllString = false,
): string => {
  if (removeFromQuotesAllString) {
    return string.replaceAll('"', '');
  }
  if (string.charAt(0) === '"' && string.charAt(string.length - 1) === '"') {
    return string.substring(1, string.length - 1);
  }

  return string;
};

const getPasswordsAndLoginsFromFile = (
  data?: Record<string, string>[],
  fileType?: FileTypeEnum,
): {
  data: ProcessedFileResult[] | null;
  errorsCount: number;
  isFileValid: boolean;
} => {
  if (!data || !fileType) {
    return { data: null, errorsCount: 0, isFileValid: false };
  }
  let errorsCount = 0;
  const pathsToFields = PATH_TO_FIELDS_BY_FILE_TYPE[fileType];

  logImportPasswordsInfo('getPasswordsAndLoginsFromFile entry data', data);

  const result = data.map(row => {
    // remove whitespaces in properties (added after manual file edit in some editors)
    const cleanRow = Object.keys(row).reduce((acc, currKey) => {
      return {
        ...acc,
        [currKey.trim()]: row[currKey],
      };
    }, {});

    return pathsToFields.reduce((acc: ProcessedFileResult, path) => {
      const pathName = Object.keys(path)[0];

      // find values by array of paths
      for (let i = 0; i < path[pathName].length; i++) {
        const result = _get(cleanRow, path[pathName][i]);

        if (!!result || typeof result === 'string') {
          return {
            ...acc,
            [removeDoubleQuotes(pathName)]: removeDoubleQuotes(
              result.trim(),
              pathName === 'url',
            ),
          };
        } else if (i === path[pathName].length && !result) {
          errorsCount++;
          return { ...acc, [pathName]: 'Cannot find field' };
        }
      }
      errorsCount++;
      return { ...acc, [pathName]: undefined };
    }, {});
  });
  logImportPasswordsInfo('getPasswordsAndLoginsFromFile result data', result);
  logImportPasswordsInfo(
    'getPasswordsAndLoginsFromFile errorsCount',
    errorsCount,
  );

  return { data: result, errorsCount, isFileValid: true };
};

export const getDataFromFile = (
  fileData: string,
  fileType: FileTypeEnum,
): {
  data: ProcessedFileResult[] | null;
  errorsCount: number;
  isFileValid: boolean;
} => {
  switch (fileType) {
    case 'csv':
      return getPasswordsAndLoginsFromFile(processCSV(fileData), fileType);
    case '1pif':
      return getPasswordsAndLoginsFromFile(process1PIF(fileData), fileType);
    default:
      return {
        data: null,
        errorsCount: 0,
        isFileValid: false,
      };
  }
};

export const getFileSize = (file: File) => {
  return typeof file?.size === 'number'
    ? (file.size / 1024).toFixed(2)
    : file.size;
};

export const getFileType = (file: File) => {
  if (file?.name) {
    return file?.name.split('.').pop();
  }
  return '';
};

const PATH_TO_FIELDS_BY_FILE_TYPE: {
  [key: string]: { [key: string]: string[] }[];
} = {
  csv: [
    { url: ['url', 'login_uri'] },
    { login: ['username', 'login_username'] },
    { password: ['password', 'login_password'] },
  ],
  '1pif': [
    { url: ['secureContents.URLs.[0].url'] },
    { login: ['secureContents.fields.[0].value'] },
    { password: ['secureContents.fields.[1].value'] },
  ],
};
