import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserTranslation } from '../../i18n';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../shared/components/Modal';
import { EditUserProfile } from '../../UserForms/EditUserProfileForm';

export const EditUserProfileModal: FC<ModalProps> = ({
  visible,
  onRequestClose,
}) => {
  return (
    <Modal width={400} visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <ModalHeaderTitle>
          <FormattedMessage id={UserTranslation.profileEditTitle} />
        </ModalHeaderTitle>
      </ModalHeader>
      <EditUserProfile onRequestClose={onRequestClose} />
    </Modal>
  );
};
