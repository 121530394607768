import React, { FC } from 'react';
import { WorkspaceApiType } from '../Workspace.types';
import { StyledWorkspaceIcon } from './WorkspaceIcon.styled';
import { getGlideImageSrcSet } from '../../../shared/utils/url.utils';
import { WorkspaceIconPlaceholder } from '../WorkspaceSwitcher/WorkspacesList/WorkspaceIconPlaceholder';

const DEFAULT_ICON_SIZE = 38;

interface WorkspaceIconProps {
  workspace: WorkspaceApiType;
  size?: number;
  rounded?: boolean;
  color?: string;
  backgroundColor?: string;
}

export const WorkspaceIcon: FC<WorkspaceIconProps> = ({
  workspace,
  size = DEFAULT_ICON_SIZE,
  rounded = false,
  color,
  backgroundColor,
}) => {
  return (
    <StyledWorkspaceIcon
      rounded={rounded}
      size={size}
      color={color}
      backgroundColor={backgroundColor}>
      <>
        {workspace.image?.contentUrl ? (
          <img
            src={workspace.image.contentUrl}
            data-testid="workspace-item-icon"
            srcSet={getGlideImageSrcSet(workspace.image?.contentUrl, {
              w: size,
              h: size,
              fit: 'crop-center',
            })}
            alt={workspace.name}
          />
        ) : (
          <WorkspaceIconPlaceholder workspace={workspace} />
        )}
      </>
    </StyledWorkspaceIcon>
  );
};
