import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ChatTranslation } from '../../../../Chat/i18n';
import { MemberItem } from '../../../../Chat/MembersList/MemberItem';
import {
  useConferenceParticipants,
  useCurrentConference,
} from '../../../Conference.hooks';

export const ConferenceParticipantsTitle = styled.h3`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.3;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
`;

export const ConferenceParticipant = styled.div`
  margin-bottom: 0.625rem;
`;

export const ConferenceParticipants: FC = () => {
  const { conference } = useCurrentConference();
  const participants = useConferenceParticipants(conference);

  return (
    <div>
      <ConferenceParticipantsTitle>
        <FormattedMessage id={ChatTranslation.conferenceParticipantsTitle} />
      </ConferenceParticipantsTitle>
      {participants.map(account => (
        <MemberItem key={account.id} account={account} showPopover />
      ))}
    </div>
  );
};
