import { useMemo } from 'react';
import { ChatMessageAssetApiType } from '../../Chat/Chat.types';
import { LinkApiType, LinkDocumentMetaData } from '../../Link/Link.types';
import { DocumentType } from './DocumentPreview.types';
import {
  getEntityMetadata,
  getEntityMetadataType,
} from './DocumentPreview.utils';

/**
 * Verify if a link or message asset has supported preview type
 * @param link or asset entity
 * @returns boolean
 */
export const useDocumentIsSupportedPreviewType = (
  entity?: LinkApiType | ChatMessageAssetApiType,
): boolean => {
  return useMemo(() => {
    const metaType = getEntityMetadataType(entity);

    if (!metaType) {
      return false;
    }

    return Object.values(DocumentType).some((docType: string) => {
      return docType.includes(metaType);
    });
  }, [entity]);
};

export const useGoogleOauth = (
  entity?: LinkApiType | ChatMessageAssetApiType,
): string | undefined => {
  const metaData = getEntityMetadata(entity);

  if (!metaData) {
    return;
  }

  // TODO: remove this check after Jira integration implemented on the frontend side
  if ((metaData as LinkDocumentMetaData).type === DocumentType.JIRA) {
    return;
  }

  return (metaData as LinkDocumentMetaData).authorization_url;
};

export const useCalcDimension = (dimension: string | number | null) => {
  return useMemo(
    () =>
      dimension
        ? typeof dimension === 'number'
          ? `${dimension}px`
          : dimension
        : `100%`,
    [dimension],
  );
};
