import { gql } from '@apollo/client';
import { NotificationApiType } from './Notifications.types';

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllAsReadNotification($input: markAllAsReadNotificationInput!) {
    markAllAsReadNotification(input: $input) {
      clientMutationId
    }
  }
`;

export interface MarkAllAsReadNotificationVariables {
  input: {
    workspace: string;
  };
}

export interface MarkAllAsReadNotificationResponse {
  clientMutationId: string;
}

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAsReadNotification($input: markAsReadNotificationInput!) {
    __typename
    markAsReadNotification(input: $input) {
      clientMutationId
      notification {
        id
        _id
        message {
          id
          fields
          type
        }
        status
        createdAt
      }
    }
  }
`;

export interface MarkAsReadNotificationVariables {
  input: {
    id: string;
    markAsRead: boolean;
  };
}

export interface MarkAsReadNotificationResponse {
  markAsReadNotification: {
    notification: NotificationApiType;
  };
  clientMutationId: string;
}
