import styled from 'styled-components';

export const StyledLockdownMessage = styled.div<{}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem;
`;

export const Message = styled.div<{}>`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 1.125rem;
  margin-bottom: 20px;
`;
