import { ApolloClient } from '@apollo/client';
import { GRAPHQL_TYPENAME_ACCOUNT_INVITATION_ITEM } from '../User.constants';
import { AccountInvitationApiType } from '../../Invitation/data/Invitation/types/Invitation.types';
import {
  GET_FOR_REGISTRATION_ACCOUNT_INVITATION,
  GetForRegistrationAccountInvitationResponse,
  GetForRegistrationAccountInvitationVariables,
} from '../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const updateAccountInvitationCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetForRegistrationAccountInvitationVariables,
  invitation: AccountInvitationApiType,
) => {
  try {
    const accountInvitationCache = apolloClient.readQuery<
      GetForRegistrationAccountInvitationResponse,
      GetForRegistrationAccountInvitationVariables
    >({
      query: GET_FOR_REGISTRATION_ACCOUNT_INVITATION,
      variables: queryVariables,
    });
    if (!accountInvitationCache) {
      return;
    }

    apolloClient.writeQuery({
      query: GET_FOR_REGISTRATION_ACCOUNT_INVITATION,
      variables: queryVariables,
      data: {
        ...accountInvitationCache,
        accountInvitation: {
          ...invitation,
          __typename: GRAPHQL_TYPENAME_ACCOUNT_INVITATION_ITEM,
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};
