import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  IntegrationClientFullApiType,
  IntegrationHookApiType,
} from '../../../../AppIntegrations.types';
import { useNavigate } from 'react-router-dom';
import {
  DeleteButton,
  IntegrationHook,
  IntegrationHookAddButton,
  IntegrationHooksWrapper,
  IntegrationHookWrapper,
  NewCustomIntegrationHooksBodyWrapper,
} from './CustomIntegrationHooks.styled';
import { AddIcon, DeleteIcon } from '../../../../../../shared/icons';
import { useQueryParams } from '../../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { IntegrationHookModal } from '../IntegrationHookModal';
import {
  useDeleteIntegrationClientHookMutation,
  useGetIntegrationClient,
} from '../../../../AppIntegrations.hooks';
import { GraphQLError } from 'graphql';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import { getShortId } from '../../../../../../shared/utils/id';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { CustomIntegrationDocumentationLink } from '../../CustomIntegrationsDocumentationLink';
import { CustomIntegrationHeader } from '../CustomIntegrationView.styled';
import { AppIntegrationsTranslation } from '../../../../i18n';

interface IntegrationHooksProps {
  integrationClient: IntegrationClientFullApiType;
}

export const CustomIntegrationHooks: FC<IntegrationHooksProps> = ({
  integrationClient,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { askConfirmation } = useConfirm();
  const queryParams = useQueryParams();
  const { integrationHookModal } = queryParams;

  const { refetch } = useGetIntegrationClient({
    skip: !integrationClient,
    variables: {
      id: integrationClient.id,
    },
  });

  const [deleteIntegrationClientHookMutation] =
    useDeleteIntegrationClientHookMutation();

  const deleteIntegrationHook = useCallback(
    (id: string) => {
      deleteIntegrationClientHookMutation({
        variables: {
          input: {
            id: id,
          },
        },
      })
        .then(() => {
          refetch();
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [deleteIntegrationClientHookMutation, refetch],
  );

  const handleDeleteIntegrationHookClick = useCallback(
    (id: string) => {
      askConfirmation(
        intl.formatMessage({
          id: AppIntegrationsTranslation.deleteCustomIntegrationHookConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteIntegrationHook(id);
      });
    },
    [askConfirmation, deleteIntegrationHook, intl],
  );

  const closeHookModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        hookId: undefined,
        integrationHookModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const openHookModal = useCallback(
    (hook?: IntegrationHookApiType) => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          hookId: hook ? getShortId(hook.id) : undefined,
          integrationHookModal: true,
        }),
      });
    },
    [navigate, queryParams],
  );

  return (
    <>
      <NewCustomIntegrationHooksBodyWrapper>
        <CustomIntegrationHeader>
          <CustomIntegrationDocumentationLink />
        </CustomIntegrationHeader>
        <IntegrationHooksWrapper>
          {integrationClient?.hooks.edges.map(hook => (
            <IntegrationHookWrapper key={hook.node.id}>
              <IntegrationHook onClick={() => openHookModal(hook.node)}>
                {hook.node.desktop.name}
                <DeleteButton
                  icon={DeleteIcon}
                  data-testid="delete-button"
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteIntegrationHookClick(hook.node.id);
                  }}
                />
              </IntegrationHook>
            </IntegrationHookWrapper>
          ))}
          <IntegrationHookAddButton
            className="add-new-hook-button"
            icon={AddIcon}
            iconFirst={true}
            onClick={() => openHookModal()}>
            <FormattedMessage
              id={
                AppIntegrationsTranslation.appCustomIntegrationAddNewHookLabel
              }
            />
          </IntegrationHookAddButton>
        </IntegrationHooksWrapper>
      </NewCustomIntegrationHooksBodyWrapper>
      <IntegrationHookModal
        visible={Boolean(integrationHookModal)}
        onRequestClose={closeHookModal}
        integrationClient={integrationClient}
      />
    </>
  );
};
