import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StyledNotificationsIntegrations,
  StyledNotificationsIntegrationItem,
  StyledNotificationsIntegrationsName,
  NotificationsIntegrations,
  SectionHeaderWithSubTitle,
  StyledWorkspaceNotificationsTitle,
  NotificationsIntegrationsApiType,
  StyledNotificationsIntegrationListIsEmpty,
} from '.';
import { StyledFormInput } from '../../../shared/components/Input';
import { StyledToggle } from '../../../shared/components/Toggle/Toggle.styled';
import { getImageSrcSet } from '../../../shared/utils/url.utils';
import { AdminHeaderSubtitle, Section, SectionHeaderTitle } from '..';
import {
  NotificationsSectionBody,
  NotificationsSectionBodyItem,
} from '../../GeneralSettings/NotificationsSettingsAdmin/NotificationsSettingsForm/NotificationsSettingsForm.styled';
import { WorkspaceTranslation } from '../../Workspace/i18n';

interface WorkspaceNotificationsIntegrationsProps {
  integrations: NotificationsIntegrations[];
  isSubmitting: boolean;
  onSubmit: (formValues: NotificationsIntegrationsApiType) => void;
}

export const WorkspaceNotificationsIntegrations: FC<WorkspaceNotificationsIntegrationsProps> = ({
  integrations,
  isSubmitting,
  onSubmit,
}) => {
  const handleClick = (item: NotificationsIntegrations) => {
    handleCheckboxChange(item.botAccount);

    onSubmit({
      id: item.id,
      botAccount: item.botAccount,
      value: !item.value,
    });
  };

  const [selections, setSelections] = useState<string[]>(
    integrations.map(int => (int.value ? int.botAccount : '')),
  );

  const handleCheckboxChange = (key: string) => {
    const find = selections.indexOf(key);

    if (find > -1) {
      selections.splice(find, 1);
    } else {
      selections.push(key);
    }

    setSelections(selections);
  };

  return (
    <Section>
      <StyledWorkspaceNotificationsTitle>
        <SectionHeaderWithSubTitle>
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage
              id={WorkspaceTranslation.adminNavUserNotificationsIntegrations}
            />
          </SectionHeaderTitle>
          <AdminHeaderSubtitle data-testid="subtitle">
            <FormattedMessage
              id={
                WorkspaceTranslation.adminNavUserNotificationsIntegrationsSubTitle
              }
            />
          </AdminHeaderSubtitle>
        </SectionHeaderWithSubTitle>
      </StyledWorkspaceNotificationsTitle>

      <NotificationsSectionBody data-testid="integrations-section-body">
        <NotificationsSectionBodyItem>
          <StyledNotificationsIntegrations>
            {!integrations.length ? (
              <StyledNotificationsIntegrationListIsEmpty data-testid="integrations-empty-body">
                <FormattedMessage
                  id={WorkspaceTranslation.adminNavUserNotificationsListIsEmpty}
                />
              </StyledNotificationsIntegrationListIsEmpty>
            ) : (
              integrations.map(item => (
                <StyledNotificationsIntegrationItem key={item.botAccount}>
                  <StyledNotificationsIntegrationsName>
                    <img
                      src={item.image}
                      srcSet={getImageSrcSet(item.image, 42, 42)}
                      alt={item.name}
                    />
                    {item.name}
                  </StyledNotificationsIntegrationsName>
                  <StyledFormInput>
                    <StyledToggle>
                      <input
                        data-testid="toggle-global-setting"
                        name={item.name}
                        disabled={isSubmitting}
                        onChange={() => handleClick(item)}
                        checked={selections.includes(item.botAccount)}
                        type="checkbox"
                        className="toggle-button"
                      />
                    </StyledToggle>
                  </StyledFormInput>
                </StyledNotificationsIntegrationItem>
              ))
            )}
          </StyledNotificationsIntegrations>
        </NotificationsSectionBodyItem>
      </NotificationsSectionBody>
    </Section>
  );
};
