import styled from 'styled-components';

export const StyledScheduledConferenceDeleteConfirmationModal = styled.div`
  padding: 1.5rem;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  div:first-child {
    padding: 0;
    margin: 0;
  }
  svg {
    margin: 0;
  }
`;

export const StyledScheduledConferenceMessage = styled.div`
  text-align: left;
  padding-top: 1rem;
`;

export const ButtonControls = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  & > button {
    margin-right: 0.875rem;
  }
`;
