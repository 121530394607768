import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InboxAvatar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InboxAvatarPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${p => p.theme.colors.AvatarPlaceholder};
  background-color: ${p => p.theme.colors.Surface};
`;

export const InboxAvatarAssets = styled.div`
  position: relative;
`;

export const InboxSubstanceImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 32px;
  max-height: 32px;
`;

export const InboxContent = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  width: 100%;
  overflow: hidden;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .inbox-message {
    display: flex;
    justify-content: space-between;
    padding: 0;
    max-width: 310px;
    & > div:first-child {
      width: 95%;
      max-width: 280px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
    & > div:last-child {
      width: auto;
    }
  }
`;

export const InboxContentStrong = styled.strong`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15.625rem;
`;

export const InboxCreatedAt = styled.time`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;
`;

export const StyledInboxStatus = styled.div`
  display: flex;
  align-items: center;
  &:after {
    content: ' ';
    width: 8px;
    height: 8px;
    background-color: ${p => p.theme.colors.Primary};
    border-radius: 50%;
  }
`;

export const InboxItemContainer = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  padding: 0.625rem;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }
`;
