import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  IndexLocationWithAlign,
  ListItem,
  Virtuoso,
  VirtuosoHandle,
} from 'react-virtuoso';
import { useDebouncedCallback } from 'use-debounce';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import { ScrollBottomIcon } from '../../../../../shared/icons';
import { getShortId } from '../../../../../shared/utils/id';
import { useAccountPopoverControls } from '../../../AccountPopover';
import { SearchMessageType } from '../../../Chat.context';
import { useCurrentConversation } from '../../../Chat.hooks';
import type { ChatItemType } from '../../../Chat.types';
import { getSearchMessageIndex } from '../../../Chat.utils';
import { useChatMessageRepository } from '../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { ChatTranslation } from '../../../i18n';

import {
  getMessageNode,
  highlightChatMessage,
} from '../ChatMessage/ChatMessage.utils';
import { ConversationViewContext } from '../ConversationView.context';
import {
  MessageLoader,
  ScrollToBottomButton,
} from '../ConversationView.styled';
import { ScrollDirection } from '../ConversationView.types';
import {
  DEBOUNCED_AT_BOTTOM_STATE_CHANGE,
  DELAY_FOR_HIGHLIGHTING_MESSAGE,
  DELAY_FOR_SCROLL_TO_LAST_MESSAGE,
  DELAY_FOR_SCROLLING_TO_MESSAGE,
  MESSAGE_LIST_START_INDEX,
  MESSAGE_MIN_HEIGHT,
  OVERSCAN_MAIN,
  OVERSCAN_REVERSE,
} from './MessageList.constants';
import { useMessageListDataManagerContext } from './MessageList.hooks';
import { MessageListContext } from './MessageListProvider/MessageList.context';
import { MessageListRow } from './MessageListRow';
import { NoMessagesContainer, NoMessagesMessage } from './MessagesList.styled';
import { Spinner } from '../../../../../shared/components/Spinner';

type MessagesListProps = {
  chatItems: ChatItemType[];
  clearSearchMessage: () => void;
  searchMessage?: SearchMessageType;
  allowReply?: boolean;
};

export const MessagesList = React.forwardRef<VirtuosoHandle, MessagesListProps>(
  (
    { chatItems = [], clearSearchMessage, searchMessage, allowReply = true },
    ref,
  ) => {
    const { formatMessage } = useIntl();
    const { conversation } = useCurrentConversation();

    const {
      listLoading,
      firstItemIndex,
      loadMoreMessages,
      setLoadingListState,
    } = useContext(ConversationViewContext);

    const { isAtBottom, setIsAtBottom, setVirtualListRef } =
      useContext(MessageListContext);

    const { showAccountPopover } = useAccountPopoverControls();

    const [listHideScrollRef] = useHideScrollbarWhenIdle();

    const { listReady, setListReady } = useMessageListDataManagerContext();

    const {
      markAllChatMessagesAsSeen,
      replaceListByLastSubset,
      currentConversationInstance,
    } = useChatMessageRepository();

    const conversationInstance = currentConversationInstance();

    const firstUnread = chatItems.find(
      item => 'id' in item && item.isFirstUnread,
    );

    const [isScrolling, setIsScrolling] = useState(false);

    const isFirstRender = useRef(true);

    useEffect(() => {
      if (listReady) {
        isFirstRender.current = false;
      }

      return () => {
        isFirstRender.current = true;
      };
    }, [listReady]);

    let firstUnreadMessageIndex = getSearchMessageIndex(
      chatItems,
      firstUnread && 'id' in firstUnread ? firstUnread.id : '',
    );

    const hasSearchMessage = searchMessage?.id && searchMessage.createdAt;

    const searchMessageIndex = getSearchMessageIndex(
      chatItems,
      searchMessage?.id,
    );

    const virtuosoHandle = (ref as RefObject<VirtuosoHandle>)?.current;

    const handleAtBottomStateChange = useDebouncedCallback(
      (atBottom: boolean) => {
        if (hasSearchMessage || firstUnread) {
          return;
        }

        setIsAtBottom(atBottom);
      },
      DEBOUNCED_AT_BOTTOM_STATE_CHANGE,
    );

    const debouncedSetListIsReady = useDebouncedCallback(() => {
      setListReady(true);
    }, DELAY_FOR_SCROLLING_TO_MESSAGE);

    const debouncedClearSeachMessage = useDebouncedCallback(() => {
      clearSearchMessage();
    }, DELAY_FOR_HIGHLIGHTING_MESSAGE);

    const setCurrentScrollPosition = useDebouncedCallback(
      (items: ListItem<ChatItemType>[]) => {
        if (!virtuosoHandle) {
          return;
        }

        setVirtualListRef(virtuosoHandle);

        if (!listReady) {
          debouncedSetListIsReady();
        }

        if (
          searchMessage?.id &&
          searchMessageIndex &&
          searchMessageIndex > 0 &&
          virtuosoHandle
        ) {
          const messageNode = getMessageNode(getShortId(searchMessage.id));

          virtuosoHandle.scrollToIndex({
            index: searchMessageIndex,
            align: 'center',
          });

          if (messageNode) {
            highlightChatMessage(messageNode);
          }

          debouncedClearSeachMessage();
          return;
        }

        if (firstUnreadMessageIndex && firstUnreadMessageIndex > 0) {
          virtuosoHandle.scrollToIndex({
            index: firstUnreadMessageIndex,
            align: 'start',
          });

          firstUnreadMessageIndex = null;
          return;
        }
      },
      50,
    );

    /**
     * Render function for virtual list ITEM
     * @renders ITEM for Virtuoso list
     */
    const chatRowRenderer = useCallback(
      (index: number, chatItem: ChatItemType) => (
        <MessageListRow
          isScrolling={isScrolling}
          chatItem={chatItem}
          onAvatarClick={showAccountPopover}
          allowReply={allowReply}
        />
      ),
      [allowReply, isScrolling, showAccountPopover],
    );

    useEffect(
      () => () => {
        setListReady(false);
      },
      [setListReady],
    );

    const handleScrollerRef = useCallback(
      (ref: HTMLElement | Window | null) => {
        listHideScrollRef(ref as HTMLElement);
      },
      [listHideScrollRef],
    );

    const handleAtTopStateChange = useCallback(
      (atTop: boolean) => {
        if (hasSearchMessage || firstUnread || !atTop || !listReady) {
          return;
        }

        loadMoreMessages(ScrollDirection.top);
      },
      [firstUnread, hasSearchMessage, listReady, loadMoreMessages],
    );

    const initialTopMostIndex: IndexLocationWithAlign = {
      index: firstUnreadMessageIndex || MESSAGE_LIST_START_INDEX,
      align: firstUnreadMessageIndex ? 'start' : 'end',
    };

    const handleFollowOutput = useCallback(
      (isAtBottom: boolean) => (isAtBottom ? 'auto' : false),
      [],
    );

    const scrollToBottomButtonHandler = useCallback(async () => {
      if (!conversation?.id) {
        return;
      }

      await markAllChatMessagesAsSeen(conversation.id);

      if (conversationInstance?.canLoadAfter) {
        setLoadingListState();
        await replaceListByLastSubset();
      }

      setTimeout(
        () =>
          virtuosoHandle?.scrollToIndex({
            index: chatItems.length,
          }),
        DELAY_FOR_SCROLL_TO_LAST_MESSAGE,
      );
    }, [
      chatItems.length,
      conversation?.id,
      conversationInstance?.canLoadAfter,
      markAllChatMessagesAsSeen,
      replaceListByLastSubset,
      setLoadingListState,
      virtuosoHandle,
    ]);

    const handleEndReached = () => {
      if (!searchMessage?.id) {
        loadMoreMessages(ScrollDirection.bottom);
      }
    };

    const listIsLoading = searchMessage?.id || listLoading;

    return (
      <>
        <Virtuoso
          ref={ref}
          {...(firstItemIndex && { firstItemIndex })}
          scrollerRef={handleScrollerRef}
          isScrolling={setIsScrolling}
          initialTopMostItemIndex={initialTopMostIndex}
          style={{
            width: '100%',
          }}
          data={chatItems}
          components={{
            EmptyPlaceholder: () => {
              return listIsLoading ? (
                <MessageLoader>
                  <Spinner />
                </MessageLoader>
              ) : null;
            },
            Footer: () =>
              !listIsLoading && chatItems.length === 0 ? (
                <NoMessagesContainer>
                  <FormattedMessage
                    tagName={NoMessagesMessage}
                    id={ChatTranslation.noMessagesInChat}
                  />
                </NoMessagesContainer>
              ) : null,
          }}
          itemContent={chatRowRenderer}
          alignToBottom={!conversationInstance?.canLoadAfter}
          atBottomStateChange={handleAtBottomStateChange}
          endReached={handleEndReached}
          atTopStateChange={handleAtTopStateChange}
          itemsRendered={
            setCurrentScrollPosition as (
              items: ListItem<ChatItemType>[],
            ) => void
          }
          followOutput={!isScrolling && handleFollowOutput}
          defaultItemHeight={MESSAGE_MIN_HEIGHT}
          increaseViewportBy={{ top: OVERSCAN_MAIN, bottom: OVERSCAN_REVERSE }}
          overscan={{ main: OVERSCAN_MAIN, reverse: OVERSCAN_REVERSE }}
        />

        <ScrollToBottomButton
          visible={!isAtBottom && !listLoading}
          onClick={scrollToBottomButtonHandler}
          data-tooltip-place={TooltipPlace.top}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: ChatTranslation.tooltipGoToLastMessage,
          })}>
          <ScrollBottomIcon />
        </ScrollToBottomButton>
      </>
    );
  },
);
