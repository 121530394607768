import styled from 'styled-components';

export const StyledMarketplaceItemsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};

  .header {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
    flex-basis: 100%;
    font-family: ${p => p.theme.fonts.Semibold};
    font-size: 1.25rem;
    line-height: 30px;
    word-break: break-all;
    word-break: break-word;

    > span:not(:last-child) {
      cursor: pointer;
    }

    .title {
      cursor: pointer;
    }
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;

export const StyledMarketplaceCategory = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3.25rem;

  .logo {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.75rem;
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125rem;
  }

  .sub-title {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
`;

export const StyledMarketplaceItemsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`;

export const StyledListTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const GoogleApiUsageDisclosure = styled.p`
  font-size: 0.75rem;
  padding: 1rem;
  margin-bottom: 1rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  a {
    color: ${p => p.theme.colors.PrimaryLight};
  }
`;
