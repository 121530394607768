import { useCallback } from 'react';
import { useSingleEntityRepository } from '../../../../shared/hooks/repository/singleEntityRepository.hooks';
import { database } from '../../../Database';
import {
  WorkspaceInfoRepositoryProps,
  WorkspaceInfoRepositoryResp,
} from './types/WorkspaceInfoRepositories.types';
import { getAccountWorkspaceInfoApi } from './dataSources/WorkspaceInfoRestApi.dataSources';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { putWorkspaceInfoToIDDB } from './operations/WorkspaceInfoIDDB.operations';
import { WorkspaceInfoApiType } from './types/WorkspaceInfo.types';
import { getLongId } from '../../../../shared/utils/id';

export const useWorkspaceInfoRepository = ({
  fetchPolicy = 'cache-and-network',
  accountId,
}: WorkspaceInfoRepositoryProps): WorkspaceInfoRepositoryResp => {
  const { workspace } = useCurrentWorkspace();
  const fetchWorkspaceInfo = useCallback(async () => {
    try {
      const response = await getAccountWorkspaceInfoApi(
        accountId,
        workspace.id,
      );

      if (response?.data && response.ok) {
        putWorkspaceInfoToIDDB(response.data);
      }

      return response?.data;
    } catch (error) {
      console.error(error);
    }
  }, [accountId, workspace.id]);

  const { entity: workspaceInfo, loading } =
    useSingleEntityRepository<WorkspaceInfoApiType | null>({
      fetchFunction: fetchWorkspaceInfo,
      iddbQuerier: () =>
        database.workspaceInfo.get({
          id: workspace.id,
          accountId: getLongId('accounts', accountId),
        }),
      fetchPolicy,
      dependencies: [accountId, workspace.id],
    });

  return {
    workspaceInfo,
    loading,
    fetchWorkspaceInfo,
  };
};
