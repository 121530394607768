import React, { FC, MouseEvent, useCallback } from 'react';
import {
  ConferenceInviteesHeaderSection,
  ScheduledConferenceInviteesWrapper,
  ScheduledConferenceMember,
  ScheduledConferenceMemberEmail,
  StyledConferenceInvitees,
} from './ScheduledConferenceInvitees.styled';
import { CopyIcon, UserIcon } from '../../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import { ScheduledConferenceLinkHeader } from '../ScheduledConferenceLink/ScheduledConferenceLink.styled';
import { MemberItem } from '../../../../Chat/MembersList/MemberItem';
import { useScheduledConferenceMembers } from '../../../Conference.hooks';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { showToastSuccessMessage } from '../../../../../shared/components/Toast';
import { ScheduledConferenceContentInner } from '../../ConferenceView/ConferenceView.styled';

export const ScheduledConferenceInvitees: FC = () => {
  const members = useScheduledConferenceMembers(true);

  const handleCopyEmailClick = useCallback((event: MouseEvent) => {
    const { value } = event.target as HTMLButtonElement;
    navigator.clipboard.writeText(value).then(() => {
      showToastSuccessMessage(
        ConferenceTranslation.scheduledUpcomingConferenceCopyEmailToastText,
      );
    });
  }, []);

  return (
    <ScheduledConferenceInviteesWrapper>
      <ScheduledConferenceContentInner>
        <ConferenceInviteesHeaderSection>
          <ScheduledConferenceLinkHeader>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferenceInvitees}
            />
          </ScheduledConferenceLinkHeader>
        </ConferenceInviteesHeaderSection>
        <StyledConferenceInvitees>
          {members?.map(member => {
            return member.account ? (
              <ScheduledConferenceMember key={member.id}>
                {member.account && (
                  <MemberItem account={member.account} showPopover />
                )}
              </ScheduledConferenceMember>
            ) : (
              <ScheduledConferenceMemberEmail key={member.id}>
                <div>
                  <UserIcon width={16} height={16} />
                </div>
                <span>{member.email}</span>
                <ButtonWithIcon
                  icon={CopyIcon}
                  value={member.email}
                  onClick={handleCopyEmailClick}
                />
              </ScheduledConferenceMemberEmail>
            );
          })}
        </StyledConferenceInvitees>
      </ScheduledConferenceContentInner>
    </ScheduledConferenceInviteesWrapper>
  );
};
