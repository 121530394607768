import styled from 'styled-components';

export const ScheduledConferenceHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    flex-direction: column;
  }

  button {
    height: 2.375rem;
  }
`;

export const ScheduledConferenceHeaderTitle = styled.div`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  margin-bottom: ${p => p.theme.spacing.xl};

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: ${spacing.xl};
  `}
`;

export const ScheduledConferenceHeaderDate = styled.div`
  font-size: 0.875rem;
`;

export const ScheduledConferenceSubtitle = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
    margin-right: ${spacing.m};
  `}
`;
