import React, { FC } from 'react';
import { StyledCarouselItem } from '.';
import { useMobile } from '../../hooks';

interface CarouselImageProps {
  url: string;
  filename: string;
}

export const CarouselImage: FC<CarouselImageProps> = ({ filename, url }) => {
  const isMobile = useMobile();
  return (
    <StyledCarouselItem isMobile={isMobile}>
      <img src={url} loading="lazy" alt={filename} />
    </StyledCarouselItem>
  );
};
