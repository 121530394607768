import { database } from '../../../Database';
import { DesktopsTableType } from '../../../Database/DesktopTable/DesktopsTable';

export const useTeamDesktopsActions = () => {
  const bulkPutTeamDesktopsToIDDB = async (desktops: DesktopsTableType[]) => {
    try {
      database.desktops.bulkPut(desktops);
    } catch (error) {
      console.error(error);
    }
  };

  return { bulkPutTeamDesktopsToIDDB };
};
