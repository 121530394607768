import styled from 'styled-components';

export const Invitations = styled.div`
  margin-top: 50px;
  padding: 0 20px;
  ul {
    padding: 0;
  }

  ${p => p.theme.breakpoints.md`
    padding: 0;
  `}
`;
