import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledHeaderContainer = styled.div`
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: inset 0px -1px 0px ${p => p.theme.colors.SurfaceSubdued};
`;

export const StyledAdminView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.Background};
  padding-bottom: 40px;
  overflow-y: scroll;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    padding-bottom: 0;
  `}
`;

export const StyledHeader = styled.div`
  min-height: 46px;
  max-width: 988px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
`;

export const StyledHeaderTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 1.25;
`;

export const StyledContent = styled.div`
  max-width: 988px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex-direction: row;
    padding: 2.625rem 1.25rem 2.625rem 0.625rem;
  `}
`;

export const StyledSwitch = styled.div`
  flex-basis: 100%;
  overflow: hidden;
`;

export const Navigation = styled.ul`
  flex: 0 0 170px;
  margin-right: 3.75rem;
  padding-top: 1.375rem;
`;

export const NavigationGroup = styled.div`
  & ~ & {
    margin-top: 1.5rem;
  }
`;

export const NavigationGroupHeader = styled.h3`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 1.2;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  text-transform: uppercase;
  margin: 0.375rem 0;
  padding-left: 0.25rem;
`;

export const NavigationItem = styled.li`
  & ~ & {
    margin-top: 0.5rem;
  }

  &:hover {
    border-radius: 4px;
    background: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const NavigationTrigger = styled.div.attrs({
  className: 'verify-trigger',
})``;

export const NavigationLink = styled(NavLink).attrs({
  className: 'verify-trigger',
})`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &.active {
    background: ${p => p.theme.colors.OnSurfaceSelected};
    color: ${p => p.theme.colors.Primary};
    border-radius: 4px;

    svg {
      color: ${p => p.theme.colors.Primary};
    }
  }

  svg {
    margin-right: 0.75rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;
