import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ShieldIcon } from '../../../../shared/icons';
import { getGlideImageSrcSet } from '../../../../shared/utils/url.utils';

const StyledPasswordsImage = styled.img<{
  size: number;
  defaultMargin: boolean;
  borderRadius: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};

  ${({ defaultMargin }) =>
    defaultMargin &&
    css`
      margin-right: ${({ theme }) => theme.spacing.xxs};
    `}
`;

const FallbackImage = styled.div<{
  size: number;
  defaultMargin: boolean;
  borderRadius: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ defaultMargin }) =>
    defaultMargin &&
    css`
      margin-right: ${({ theme }) => theme.spacing.xxs};
    `};
`;

export const PasswordsImage: FC<{
  size: number;
  url?: string;
  defaultMargin?: boolean;
  alt?: string;
  borderRadius?: number;
}> = ({ size, url, defaultMargin = true, borderRadius = 4, alt }) => {
  if (!url) {
    return (
      <FallbackImage
        size={size}
        defaultMargin={defaultMargin}
        borderRadius={borderRadius}
        data-testid="password-vault-image-fallback">
        <ShieldIcon width={size / 2} height={size / 2} />
      </FallbackImage>
    );
  }

  return (
    <StyledPasswordsImage
      alt={alt}
      size={size}
      defaultMargin={defaultMargin}
      borderRadius={borderRadius}
      src={url}
      srcSet={getGlideImageSrcSet(url, {
        w: size,
        h: size,
        fit: 'crop-center',
      })}
      loading="lazy"
      data-testid="password-vault-image"
    />
  );
};
