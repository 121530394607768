import React, { FC, useMemo } from 'react';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import {
  ErrorMessage,
  FormInputLabel,
  Input,
  StyledFormInput,
} from '../../../../../../../../shared/components/Input';
import { GlobalTranslation } from '../../../../../../../Intl/i18n/i18n.types';
import { FieldDescription, Suffix, SuffixWrapper } from './SsoFormInput.styled';

interface FormInputProps {
  label?: string;
  className?: string;
  suffix?: string;
  id?: string;
  description?: string;
}

export const SsoFormInput: FC<FieldProps & FormInputProps> = ({
  field,
  form,
  label,
  className,
  suffix,
  description,
  ...props
}) => {
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );
  return (
    <StyledFormInput className={className} invalid={!!errorIdToDisplay}>
      {label && (
        <FormInputLabel htmlFor={props.id || field.name}>
          {label}
        </FormInputLabel>
      )}
      <SuffixWrapper hasSuffix={!!suffix}>
        <Input id={field.name} {...field} {...props} />
        {suffix ? <Suffix>{suffix}</Suffix> : null}
      </SuffixWrapper>
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
      {description ? (
        <FieldDescription dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </StyledFormInput>
  );
};
