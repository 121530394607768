import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import { ManageTag } from '../../../TagForm';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';

export const ManageTagModal: FC<
  Omit<ModalProps, 'visible'> & {
    onCreateTags?: (value: string[]) => void;
    isOpen: boolean;
    selectedTags?: string[];
  }
> = ({ onRequestClose, onCreateTags, isOpen, selectedTags, ...props }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const handleOnDone = useCallback(() => {
    //TODO Find other way to update link data after tags were updated "refetchLink"
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        refetchLink: true,
        manageTag: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <Modal
      visible={isOpen}
      width={588}
      onRequestClose={onRequestClose}
      {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={LinkTranslation.manageTagModalHeader} />
      </ModalHeader>
      <ManageTag
        onDone={handleOnDone}
        onCancel={onRequestClose}
        onCreateTags={onCreateTags}
        selectedTags={selectedTags}
      />
    </Modal>
  );
};
