import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  GET_APP_INTEGRATION_LIST,
  GetAppIntegrationsResponse,
  GetAppIntegrationsVariables,
  GLOBAL_SEARCH,
  GlobalSearchResponse,
  GlobalSearchVariables,
} from './GlobalSearch.queries';
import { TABS, TABS_MAP } from './ExpandedSearch/ExpandedSearch.constants';
import { TabsStateType } from './ExpandedSearch';
import { showToastGraphQLErrors } from '../../shared/components/Toast';
import { GraphQLError } from 'graphql';
import {
  DEFAULT_PAGE_SIZE,
  RATE_LIMIT,
  RATE_LIMIT_CALLS,
  SEARCH_CHAR_LIMIT,
} from './GlobalSearch.constants';
import { useContext, useMemo } from 'react';
import { GlobalSearchContext } from './GlobalSearch.context';
import { useCurrentWorkspacePermissions } from '../Workspace/Workspace.hooks';

export const useGlobalSearchContext = () => useContext(GlobalSearchContext);

export const useAppIntegrationList = (
  options?: QueryHookOptions<
    GetAppIntegrationsResponse,
    GetAppIntegrationsVariables
  >,
) =>
  useQuery<GetAppIntegrationsResponse, GetAppIntegrationsVariables>(
    GET_APP_INTEGRATION_LIST,
    options,
  );

export const useGlobalSearch = (
  options?: QueryHookOptions<GlobalSearchResponse, GlobalSearchVariables>,
) =>
  useQuery<GlobalSearchResponse, GlobalSearchVariables>(GLOBAL_SEARCH, options);

export const useGetTabsPermissionsState = (): Record<string, boolean> => {
  const {
    permissions: {
      canViewGlobalSearchApps,
      canViewGlobalSearchLinks,
      canViewGlobalSearchChats,
      canViewGlobalSearchMeetings,
    },
  } = useCurrentWorkspacePermissions();

  return useMemo(
    () => ({
      [TABS.APPS]: canViewGlobalSearchApps,
      [TABS.LINKS]: canViewGlobalSearchLinks,
      [TABS.CHATS]: canViewGlobalSearchChats,
      [TABS.MEETINGS]: canViewGlobalSearchMeetings,
    }),
    [
      canViewGlobalSearchApps,
      canViewGlobalSearchChats,
      canViewGlobalSearchLinks,
      canViewGlobalSearchMeetings,
    ],
  );
};

export const useGlobalSearchHandler = (
  onCompleted: (data: GlobalSearchResponse, tab: TABS) => void,
  tab: TABS,
  searchQuery: string | null | undefined,
  tabsState: TabsStateType,
  workspaceId: string,
  conersationId: string = '',
) => {
  const searchVariables = {
    workspace: workspaceId,
    query: searchQuery as string,
    ...TABS_MAP[tab],
    first: DEFAULT_PAGE_SIZE,
  };

  return useGlobalSearch({
    variables: conersationId
      ? { ...searchVariables, chatConversation: conersationId }
      : searchVariables,
    skip:
      tabsState[tab]?.prevConversationId === conersationId
        ? typeof searchQuery !== 'string' ||
          RATE_LIMIT_CALLS.length >= RATE_LIMIT ||
          searchQuery.length < SEARCH_CHAR_LIMIT ||
          tabsState[tab].prevSearch === searchQuery ||
          !tabsState[tab]
        : typeof searchQuery !== 'string' ||
          RATE_LIMIT_CALLS.length >= RATE_LIMIT ||
          searchQuery.length < SEARCH_CHAR_LIMIT ||
          !tabsState[tab],
    onCompleted: data => onCompleted(data, tab),
    onError: e => showToastGraphQLErrors(e.graphQLErrors as GraphQLError[]),
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
};

export interface GlobalSearchByAllTabsResponseType {
  [key: number]: {
    loading: boolean;
    //TODO add type for fetchMore
    fetchMore: any;
  };
}

export const useGlobalSearchByAllTabs = (
  onCompleted: (data: GlobalSearchResponse, tab: TABS) => void,
  searchQuery: string | null | undefined,
  tabsState: TabsStateType,
  workspaceId: string,
  conersationId: string,
): GlobalSearchByAllTabsResponseType => {
  return {
    [TABS.ALL_CONTENT]: {
      loading: true,
      fetchMore: () => {},
    },
    [TABS.APPS]: useGlobalSearchHandler(
      onCompleted,
      TABS.APPS,
      searchQuery,
      tabsState,
      workspaceId,
    ),
    [TABS.LINKS]: useGlobalSearchHandler(
      onCompleted,
      TABS.LINKS,
      searchQuery,
      tabsState,
      workspaceId,
    ),
    [TABS.CHATS]: useGlobalSearchHandler(
      onCompleted,
      TABS.CHATS,
      searchQuery,
      tabsState,
      workspaceId,
      conersationId,
    ),
    [TABS.MEETINGS]: useGlobalSearchHandler(
      onCompleted,
      TABS.MEETINGS,
      searchQuery,
      tabsState,
      workspaceId,
    ),
  };
};
