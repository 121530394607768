import styled from 'styled-components';
import { ImportantMessage } from '../../../../shared/components/ImportantMessage';

export const Guidance = styled(ImportantMessage)`
  margin: 1rem 0;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  a {
    color: ${p => p.theme.colors.PrimaryDark};
  }
`;
