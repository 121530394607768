import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ArrowDownIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0303 9.2197C12.3232 9.5126 12.3232 9.9874 12.0303 10.2803L6.7803 15.5303C6.4874 15.8232 6.0126 15.8232 5.7197 15.5303L0.469668 10.2803C0.176777 9.9874 0.176777 9.5126 0.469668 9.2197C0.762558 8.9268 1.23744 8.9268 1.53033 9.2197L5.5 13.1893V0.75C5.5 0.33579 5.8358 0 6.25 0C6.6642 0 7 0.33579 7 0.75V13.1893L10.9697 9.2197C11.2626 8.9268 11.7374 8.9268 12.0303 9.2197Z"
        fill="currentColor"
      />
    </svg>
  );
});
