import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledRegistration = styled.div`
  width: 100%;
  max-width: 384px;
`;

export const RegistrationHeader = styled.div`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 1.5rem;
  line-height: 36px;
  margin-bottom: 28px;
`;

export const RegistrationWithGoogleButton = styled(ButtonWithIcon)`
  margin-bottom: 28px;
`;

export const RegistrationSeparator = styled.hr<{ value: string }>`
  margin-bottom: 28px;
  position: relative;
  border: 1px solid ${p => p.theme.colors.BorderLight};

  ${({ value }) =>
    value &&
    css`
      &:after {
        display: block;
        content: '${value}';
        text-transform: uppercase;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 30px;
        text-align: center;
        font-family: ${({ theme }) => theme.fonts.Semibold};
        font-size: 0.875rem;
        line-height: 18px;
        color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
        background-color: ${p => p.theme.colors.Background};
      }
    `}
`;
