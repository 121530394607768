import React from 'react';
import { FC } from 'react';
import { IconProps } from './icon.types';

export const HoverPreviewContentArrowDarkIcon: FC<IconProps> = React.memo(
  props => {
    return (
      <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M-3.78552e-07 9.00002L11 0.339767L11 17.6603L-3.78552e-07 9.00002Z"
          fill="#021121"
        />
        <path
          d="M10.5 16.6303L0.80829 9.00002L10.5 1.36978L10.5 16.6303Z"
          stroke="white"
          strokeOpacity="0.24"
        />
      </svg>
    );
  },
);
