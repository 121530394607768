import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BillingTranslation } from '../../../../i18n';
import { PlanUspItem, PlanUspItemIcon, PlanUspItems } from './PlanUsps.styled';
import { CheckmarkIcon } from '../../../../../../shared/icons';
import { PaymentPlan } from '../../../../Billing.types';

interface PlanUspsProps {
  plan: PaymentPlan;
}

export const PlanUsps: FC<PlanUspsProps> = ({ plan }) => {
  const { formatMessage } = useIntl();
  const [uspItems, setUspItems] = useState<Array<string>>([]);

  useEffect(() => {
    if (plan === PaymentPlan.ENTERPRISE) {
      const items = formatMessage({
        id: BillingTranslation.planEnterpriseUsps,
      }).split('|');
      setUspItems(items);
    }
  }, [formatMessage, plan]);

  return (
    <PlanUspItems data-testid="plan-usps">
      {uspItems.map((item, index) => (
        <PlanUspItem key={index}>
          <PlanUspItemIcon>
            <CheckmarkIcon width={12} />
          </PlanUspItemIcon>
          {item}
        </PlanUspItem>
      ))}
    </PlanUspItems>
  );
};
