import styled, { css } from 'styled-components';
import { AvailabilityStatusType } from '../../../../User/User.types';

export const OnlineStatusCircle = styled.div<{
  onlineStatus: AvailabilityStatusType;
  circleSize: string;
  borderColor?: string;
}>`
  width: ${p => p.circleSize};
  height: ${p => p.circleSize};
  border-radius: 50%;
  border-color: ${p =>
    p.borderColor || p.theme.colors.OnlineStatusDefaultBorder};

  ${({ onlineStatus, theme }) =>
    onlineStatus === AvailabilityStatusType.Online &&
    css`
      background-color: ${theme.colors.OnlineStatusActive};
    `}

  ${({ onlineStatus, theme }) =>
    (onlineStatus === AvailabilityStatusType.Offline ||
      onlineStatus === AvailabilityStatusType.Invisible) &&
    css`
      background-color: ${theme.colors.OnlineStatusOffline};

      ::after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid ${theme.colors.BorderDefault};
      }
    `}

  ${({ onlineStatus, theme }) =>
    onlineStatus === AvailabilityStatusType.Away &&
    css`
      background-color: ${theme.colors.OnlineStatusAway};
    `}

  ${({ onlineStatus, theme }) =>
    onlineStatus === AvailabilityStatusType.Busy &&
    css`
      background-color: ${theme.colors.OnlineStatusBusy};
    `}
`;
