import React, { FC, memo, useCallback } from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { EmptyWorkspacesPageActionsContainer } from './EmptyWorkspacesPage.styled';
import { useConfirm } from '../../../../shared/components/Modal';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import {
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneConfirmationMessage,
} from '../../../Admin';
import { LOGOUT_PATHNAME } from '../../../Auth/Auth.constants';
import { UserTranslation } from '../../../User/i18n';
import { useDeleteAccountMutation } from '../../../User/User.hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GlobalTranslation } from '../../../Intl/i18n';
import { EmptyWorkspaceCreationModal } from './EmptyWorkspacesCreationModal';
import { useModalControls } from '../../../../shared/components/Modal/Modal.hooks';
import { WorkspaceTranslation } from '../../i18n';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';

const LOGOUT_TIMEOUT = 3000;

export const EmptyWorkspacesPageActions: FC = memo(() => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const navigate = useNavigate();
  const { askConfirmation } = useConfirm();
  const [deleteAccountMutation] = useDeleteAccountMutation();
  const { account } = useCurrentAccount();
  const createWorkspaceModalControls = useModalControls();

  const handleRemoveAccountClick = useCallback(() => {
    const confirmationMessage = (
      <>
        <DangerZoneConfirmationMessage highlight>
          <FormattedMessage id={UserTranslation.dangerZoneConfirmWarning} />
        </DangerZoneConfirmationMessage>
        <DangerZoneConfirmationMessage>
          <FormattedMessage id={UserTranslation.dangerZoneConfirmMessage} />
        </DangerZoneConfirmationMessage>
      </>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.dangerZoneConfirmTitle,
      }),
      {
        width: 360,
        dangerConfirm: true,
        confirmationInput: intl.formatMessage({
          id: UserTranslation.confirmDeleteText,
        }),
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      deleteAccountMutation({
        variables: {
          input: {
            id: account!.id,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(UserTranslation.deleteAccountSuccess);
          setTimeout(
            () =>
              navigate({
                pathname: LOGOUT_PATHNAME,
                search: getQueryParamsFrom({
                  sendLogoutMessageToOtherTabs: true,
                }),
              }),
            LOGOUT_TIMEOUT,
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    });
  }, [askConfirmation, deleteAccountMutation, intl, account, navigate]);

  return (
    <EmptyWorkspacesPageActionsContainer>
      <Button
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        data-testid="create-workspace"
        onClick={createWorkspaceModalControls.open}>
        {formatMessage({ id: WorkspaceTranslation.createLinkedUserWorkspace })}
      </Button>

      <Button
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        data-testid="logout-button"
        onClick={() => {
          navigate({
            pathname: LOGOUT_PATHNAME,
            search: getQueryParamsFrom({
              sendLogoutMessageToOtherTabs: true,
            }),
          });
        }}>
        {formatMessage({ id: GlobalTranslation.logOut })}
      </Button>

      <DangerZoneButton
        data-testid="delete-account"
        size={ButtonSize.md}
        mode={ButtonMode.secondary}
        dangerMode={DangerZoneButtonMode.critical}
        type="button"
        onClick={handleRemoveAccountClick}>
        <FormattedMessage id={UserTranslation.dangerZoneDeleteAccountButton} />
      </DangerZoneButton>

      <EmptyWorkspaceCreationModal
        visible={createWorkspaceModalControls.visible}
        onRequestClose={createWorkspaceModalControls.close}
      />
    </EmptyWorkspacesPageActionsContainer>
  );
});
