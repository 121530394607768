import styled from 'styled-components';

export const StyledSearchResultCheckbox = styled.div`
  ${({ theme }) => theme.fontTemplates.regular}
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  label {
    display: flex;
    align-items: center;
  }

  b {
    font-family: ${({ theme }) => theme.fonts.Semibold};
  }
`;
