import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  AdminHeader,
  AdminHeaderTitle,
  AdminPage,
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneConfirmationMessage,
  DangerZoneHeaderTitle,
  DrawerHeader,
  GeneralInfoContent,
  GeneralInfoImage,
  GeneralInfoMeta,
  GeneralInfoSubtitle,
  GeneralInfoTitle,
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionHeaderTitle,
} from '../../Admin';

import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { UserTranslation } from '../i18n';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useWorkspaceAccountMap,
} from '../../Workspace/Workspace.hooks';
import { useConfirm } from '../../../shared/components/Modal';
import { useDeleteAccountMutation } from '../User.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { Avatar } from '../UserAvatar/Avatar';
import { getAccountName, isCurrentAccountHasPassword } from '../User.utils';
import styled from 'styled-components';
import { useModalControls } from '../../../shared/components/Modal/Modal.hooks';
import { ChangeUserPasswordModal } from '../UserModals/ChangeUserPasswordModal/ChangeUserPasswordModal';
import { LinkAccountMFAWarning } from './LinkAccountMFAWarning';
import { hasMultipleAccounts } from '../../Workspace/Workspace.utils';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../shared/components/ImportantMessage';
import { LOGOUT_PATHNAME } from '../../Auth/Auth.constants';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { EditUserProfileModal } from '../UserModals/EditUserProfileModal/EditUserProfileModal';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { SetPasswordModal } from '../../Password/SetPasswordModal';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

const PageContent = styled.div`
  padding: 20px 0;
`;

const EditProfileButton = styled(Button)`
  margin-top: 0.9375rem;
`;

export const UserProfileAdmin = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { editUserProfileModal } = queryParams;
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { workspaceAccountMap } = useWorkspaceAccountMap();
  const intl = useIntl();
  const { askConfirmation } = useConfirm();
  const changePasswordModal = useModalControls();
  const setPasswordModal = useModalControls();
  const { account: currentAccount } = useCurrentAccount();

  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const [deleteAccountMutation] = useDeleteAccountMutation();
  const handleRemoveAccountClick = useCallback(() => {
    const confirmationMessage = (
      <>
        <DangerZoneConfirmationMessage highlight>
          <FormattedMessage id={UserTranslation.dangerZoneConfirmWarning} />
        </DangerZoneConfirmationMessage>
        <DangerZoneConfirmationMessage>
          <FormattedMessage id={UserTranslation.dangerZoneConfirmMessage} />
        </DangerZoneConfirmationMessage>
      </>
    );
    askConfirmation(
      confirmationMessage,
      intl.formatMessage({
        id: UserTranslation.dangerZoneConfirmTitle,
      }),
      {
        width: 360,
        dangerConfirm: true,
        confirmationInput: intl.formatMessage({
          id: UserTranslation.confirmDeleteText,
        }),
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      deleteAccountMutation({
        variables: {
          input: {
            id: account!.id,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(UserTranslation.deleteAccountSuccess);
          navigate({
            pathname: LOGOUT_PATHNAME,
            search: getQueryParamsFrom({
              sendLogoutMessageToOtherTabs: true,
            }),
          });
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    });
  }, [askConfirmation, deleteAccountMutation, intl, account, navigate]);

  const displayLinkAccountMFAWarning = useMemo(() => {
    return (
      account.isMainAccount &&
      account.mfaEnabled &&
      hasMultipleAccounts(workspaceAccountMap)
    );
  }, [account, workspaceAccountMap]);

  const closeEditProfileModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editUserProfileModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const openEditProfileModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editUserProfileModal: true,
      }),
    });
  }, [navigate, queryParams]);

  if (!account) return null;

  return (
    <AdminPage data-testid="user-profile-admin">
      {isMobile ? (
        <DrawerHeader
          onRequestClose={() =>
            navigate(`/workspace/${getShortId(workspace.id)}`)
          }>
          <FormattedHTMLMessage id={UserTranslation.profileEditPageHeader} />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage id={UserTranslation.profileEditPageHeader} />
          </AdminHeaderTitle>
        </AdminHeader>
      )}

      <PageContent>
        <Section data-testid="profile-section">
          {account.pendingEmail && (
            <ImportantMessage
              withIcon
              circleIcon
              type={ImportantMessageType.WARNING}
              data-testid="important-msg">
              <FormattedHTMLMessage
                id={UserTranslation.profileConfirmChangeEmail}
                values={{ pendingEmail: account.pendingEmail }}
              />
            </ImportantMessage>
          )}
          <SectionHeader data-testid="header">
            <SectionHeaderTitle data-testid="title">
              <FormattedHTMLMessage id={UserTranslation.profileSectionTitle} />
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionBody data-testid="section-body">
            <GeneralInfoContent>
              <GeneralInfoImage data-testid="image">
                <Avatar size={108} account={account} />
              </GeneralInfoImage>
              <GeneralInfoMeta>
                <GeneralInfoTitle data-testid="title">
                  {getAccountName(account)}
                </GeneralInfoTitle>
                <GeneralInfoSubtitle data-testid="subtitle">
                  {account.email}
                </GeneralInfoSubtitle>
                <EditProfileButton
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: UserTranslation.tooltipEditUserProfile,
                  })}
                  data-tooltip-place={TooltipPlace.top}
                  data-testid="edit-profile-button"
                  onClick={openEditProfileModal}
                  type="button"
                  mode={ButtonMode.secondary}
                  size={ButtonSize.md}>
                  <FormattedHTMLMessage
                    id={UserTranslation.profileEditButton}
                  />
                </EditProfileButton>
              </GeneralInfoMeta>
            </GeneralInfoContent>
          </SectionBody>
        </Section>

        <Section data-testid="change-password-section">
          <SectionHeader data-testid="header">
            <SectionHeaderTitle data-testid="title">
              <FormattedHTMLMessage
                id={UserTranslation.profilePasswordSectionTitle}
              />
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionBody data-testid="section-body">
            <SectionDescription data-testid="description">
              <FormattedHTMLMessage
                id={UserTranslation.profileChangePasswordDescription}
              />
            </SectionDescription>
            {isCurrentAccountHasPassword(currentAccount) ? (
              <Button
                onClick={changePasswordModal.open}
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                data-testid="change-password-button">
                <FormattedHTMLMessage
                  id={UserTranslation.profileChangePassword}
                />
              </Button>
            ) : (
              <Button
                onClick={setPasswordModal.open}
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                data-testid="set-password-button">
                <FormattedHTMLMessage id={UserTranslation.profileSetPassword} />
              </Button>
            )}
          </SectionBody>
        </Section>

        <Section data-testid="danger-zone">
          <SectionHeader data-testid="header">
            <DangerZoneHeaderTitle data-testid="title">
              <FormattedMessage id={UserTranslation.adminDangerZone} />
            </DangerZoneHeaderTitle>
          </SectionHeader>
          <SectionBody data-testid="section-body">
            <SectionDescription data-testid="delete-warning">
              <FormattedMessage id={UserTranslation.dangerZoneDeleteWarning} />
              {displayLinkAccountMFAWarning && <LinkAccountMFAWarning />}
            </SectionDescription>
            <DangerZoneButton
              data-testid="delete-button"
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              dangerMode={DangerZoneButtonMode.critical}
              type="button"
              onClick={handleRemoveAccountClick}>
              <FormattedMessage
                id={UserTranslation.dangerZoneDeleteAccountButton}
              />
            </DangerZoneButton>
          </SectionBody>
        </Section>
      </PageContent>

      <EditUserProfileModal
        visible={Boolean(editUserProfileModal)}
        onRequestClose={closeEditProfileModal}
      />

      <ChangeUserPasswordModal
        visible={changePasswordModal.visible}
        onRequestClose={changePasswordModal.close}
      />

      <SetPasswordModal
        visible={setPasswordModal.visible}
        onRequestClose={setPasswordModal.close}
      />
    </AdminPage>
  );
};
