export const flatPolyfill = () => {
  if (!Array.prototype.flat) {
    Array.prototype.flat = function (depth = 1) {
      depth = isNaN(depth) ? 0 : Math.floor(depth);
      if (depth < 1) return this.slice();
      return [].concat(
        ...(depth < 2
          ? this
          : this.map(v => (Array.isArray(v) ? v.flat(depth - 1) : v))),
      );
    };
  }
};

export const fromEntriesPolyfill = () => {
  if (!Object.fromEntries) {
    Object.defineProperty(Object, 'fromEntries', {
      value(entries) {
        if (!entries || !entries[Symbol.iterator]) {
          throw new Error(
            'Object.fromEntries() requires a single iterable argument',
          );
        }

        const o = {};

        Object.keys(entries).forEach(key => {
          const [k, v] = entries[key];
          o[k] = v;
        });

        return o;
      },
    });
  }
};
