import { showToastError } from '../../../../shared/components/Toast';
import { database } from '../../../Database';
import { FolderApiTypeToDesktopFolderTableRow } from '../../../Database/DesktopFoldersTable/DesktopFoldersTable.utils';
import {
  createFolderApi,
  deleteFolderApi,
  editFolderApi,
} from '../DataSource/FolderApiDataSource';
import { EditFolderInputVariables } from '../Operations/Mutations/EditFolder.mutation';

export const editFolderRepository = async (
  folderId: string,
  workspaceId: string,
  folderData: Omit<EditFolderInputVariables, 'id'>,
) => {
  const response = await editFolderApi(folderId, folderData);
  // TODO: optimistic response?

  const { data, errors } = response;

  if (data) {
    // Update dexie database with edited folder
    try {
      const folderRow = FolderApiTypeToDesktopFolderTableRow(
        data.editFolder.folder,
        workspaceId,
      );
      database.desktopFolders.put(folderRow, folderRow.id);
    } catch (error) {
      console.error(error);
    }
  }

  if (errors) {
    const message = errors[0].message;
    if (message) {
      showToastError(message);
    }
  }

  return response;
};

export const createFolderRepository = async (
  workspaceId: string,
  name: string,
  desktop: string,
  parent?: string,
) => {
  const response = await createFolderApi(name, desktop, parent);
  // TODO: optimistic response?

  const { data } = response;

  if (data) {
    // Update dexie database with new folder
    try {
      const folderRow = FolderApiTypeToDesktopFolderTableRow(
        data.createFolder.folder,
        workspaceId,
      );

      database.desktopFolders.put(folderRow, folderRow.id);

      // update subfolders object in parent folder
      if (folderRow.parentId) {
        database.desktopFolders
          .get(folderRow.parentId)
          .then(parentFolderResponse => {
            if (!parentFolderResponse) {
              return;
            }
            database.desktopFolders.update(parentFolderResponse.id, {
              'sourceData.subFolderIds': [
                ...(parentFolderResponse.sourceData.subFolderIds || []),
                folderRow.id,
              ],
            });
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const deleteFolderRepository = async (folderId: string) => {
  const response = await deleteFolderApi(folderId);
  // TODO: optimistic response?

  const { data } = response;

  if (data) {
    // delete folder from dexie database
    try {
      database.desktopFolders.delete(folderId);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};
