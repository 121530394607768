import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { useUpdateWorkspaceDomainMutation } from '../../Domain.hooks';
import { DomainFormValues, WorkspaceDomainApiType } from '../../Domain.types';
import { DomainTranslation } from '../../i18n';
import { DomainForm } from '../Domain.form';

const StyledEditEdDomainForm = styled.div`
  padding: 0.75rem 1.5rem 1.5rem;
`;

interface EditDomainFormProps {
  currentDomain: WorkspaceDomainApiType;
  onRequestClose: () => void;
  onDone: () => void;
}

export const EditDomainForm: FC<EditDomainFormProps> = ({
  currentDomain,
  onDone,
  onRequestClose,
}) => {
  const [updateWorkspaceDomainMutation] = useUpdateWorkspaceDomainMutation();

  const handleSubmit = useCallback(
    (formValues: DomainFormValues) =>
      updateWorkspaceDomainMutation({
        variables: {
          input: {
            ...formValues,
            id: currentDomain!.id,
          },
        },
      })
        .then(() => {
          onDone();
          onRequestClose();
          showToastSuccessMessage(
            DomainTranslation.adminListEditDomainToastMessage,
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [updateWorkspaceDomainMutation, onDone, onRequestClose, currentDomain],
  );

  return (
    <StyledEditEdDomainForm>
      <DomainForm
        currentDomain={currentDomain}
        onCancel={onRequestClose}
        onSubmit={handleSubmit}
      />
    </StyledEditEdDomainForm>
  );
};
