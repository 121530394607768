import React, { FC, RefObject, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ListWrapper,
  StyledExpand,
  StyledMembersList,
} from './MembersList.styled';
import { useCurrentConversation } from '../Chat.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { MemberItem } from './MemberItem';
import { ChatTranslation } from '../i18n';
import { useAccountsContext } from '../../Account';
import { useCurrentDesktop } from '../../Desktop/Desktop.hooks';
import { AccountApiType } from '../../User/User.types';
import { MembersFilter } from './MembersFilter';
import { Spinner } from '../../../shared/components/Spinner';
import { NoMembersMessage } from './MembersFilter/MemberFilter.styled';
import { sortMembersByAvailabilityAndName } from './MembersList.utils';
import {
  getAccountName,
  isAccountActive,
  isAccountGuest,
  isBotAccount,
  isCurrentAccountGuest,
} from '../../User/User.utils';
import {
  useCurrentConference,
  useScheduledConferenceMembers,
} from '../../Conference/Conference.hooks';
import { useHideScrollbarWhenIdle } from '../../../shared/hooks/element.hooks';
import { Expand } from '../../../shared/components/Expand';
import { CornerBottomIcon } from '../../../shared/icons';
import { getShortId } from '../../../shared/utils/id';

export interface MembersListProps {
  membersSidebarRef?: RefObject<HTMLDivElement>;
}

export const MembersList: FC<MembersListProps> = ({ membersSidebarRef }) => {
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const currentDesktop = useCurrentDesktop();
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const { account: currentWorkspaceAccount } = useCurrentWorkspaceAccount();
  const { conversation } = useCurrentConversation();
  const { conference, scheduledConference } = useCurrentConference();

  const desktopAccountIds = useMemo(
    () => (currentDesktop ? currentDesktop.accountIds : []),
    [currentDesktop],
  );

  const { accountsWithAvailability, loading: accountsLoading } =
    useAccountsContext();

  const sortMembers = useCallback(
    (a: AccountApiType, b: AccountApiType) => {
      return sortMembersByAvailabilityAndName(a, b, accountsWithAvailability);
    },
    [accountsWithAvailability],
  );

  const searchFilter = useCallback(
    (account: AccountApiType) => {
      const memberFullName = getAccountName(account)!.toLowerCase();
      return memberFullName.includes(searchFilterValue.toLowerCase());
    },
    [searchFilterValue],
  );

  const scheduledConferenceMembersIds = useScheduledConferenceMembers(true)
    .map(member => member?.account?.id)
    .filter(Boolean) as string[];

  const participants = useMemo(() => {
    let participantsData: string[] = [];
    if (conversation) {
      participantsData = conversation.userIds;
    } else if (conference) {
      participantsData = conference.seenBy;
    } else if (scheduledConference) {
      participantsData = scheduledConferenceMembersIds;
    } else if (currentDesktop) {
      participantsData = desktopAccountIds;
    }

    return participantsData
      .map(accountId => accountsWithAvailability[accountId])
      .filter(Boolean)
      .filter(account => !isBotAccount(account))
      .filter(account => isAccountActive(account, workspaceId))
      .filter(searchFilter)
      .sort(sortMembers);
  }, [
    accountsWithAvailability,
    conference,
    conversation,
    currentDesktop,
    desktopAccountIds,
    scheduledConference,
    scheduledConferenceMembersIds,
    searchFilter,
    sortMembers,
    workspaceId,
  ]);

  const participantIds = useMemo(
    () => participants.map(({ id }) => id),
    [participants],
  );

  const workspaceAccounts = useMemo(
    () =>
      accountsWithAvailability
        ? Object.values(accountsWithAvailability)
            .map(({ id }) => accountsWithAvailability[id])
            .filter(Boolean)
            .filter(account => isAccountActive(account, workspaceId))
        : [],
    [accountsWithAvailability, workspaceId],
  );

  const otherAccounts = useMemo(
    () =>
      workspaceAccounts.filter(
        account =>
          !participantIds.includes(account.id) &&
          isAccountActive(account, workspaceId),
      ),
    [participantIds, workspaceAccounts, workspaceId],
  );

  const workspaceMembers = useMemo(
    () =>
      otherAccounts
        .filter(
          account =>
            !isAccountGuest(account) && isAccountActive(account, workspaceId),
        )
        .filter(searchFilter)
        .sort(sortMembers),
    [otherAccounts, searchFilter, sortMembers, workspaceId],
  );

  const workspaceGuests = useMemo(
    () =>
      otherAccounts
        .filter(
          account =>
            isAccountGuest(account) && isAccountActive(account, workspaceId),
        )
        .filter(searchFilter)
        .sort(sortMembers),
    [otherAccounts, searchFilter, sortMembers, workspaceId],
  );

  const getMembersListTitle = useCallback(() => {
    if (!!currentDesktop) {
      return ChatTranslation.sidebarDesktopAccounts;
    }
    if (!!conference || !!scheduledConference) {
      return ChatTranslation.sidebarMeetingParticipants;
    }
    if (!!conversation) {
      return ChatTranslation.sidebarChatParticipants;
    }
  }, [conference, conversation, currentDesktop, scheduledConference]);

  const [membersListRef] = useHideScrollbarWhenIdle();

  const isGuestAccount = useMemo(() => {
    return isCurrentAccountGuest(
      currentWorkspaceAccount,
      getShortId(workspace.id),
    );
  }, [workspace.id, currentWorkspaceAccount]);

  return (
    <StyledMembersList ref={membersListRef}>
      <MembersFilter
        onFilterValueChange={setSearchFilterValue}
        membersSidebarRef={membersSidebarRef}
      />

      {!!participants.length && (
        <ListWrapper data-testid="current-context-members-panel">
          <Expand
            expandedByDefault
            renderTrigger={(toggle, expanded) => (
              <StyledExpand
                iconFirst
                icon={CornerBottomIcon}
                onClick={toggle}
                expanded={expanded}>
                <h1 onClick={toggle}>
                  <FormattedMessage id={getMembersListTitle()} />
                </h1>
              </StyledExpand>
            )}>
            {participants.map(account => (
              <MemberItem
                key={account.id}
                account={account}
                showPopover
                isGuest={isAccountGuest(account)}
              />
            ))}
          </Expand>
        </ListWrapper>
      )}

      {accountsLoading && <Spinner containerHeight={200} />}

      {!!workspaceMembers.length && !isGuestAccount && (
        <ListWrapper data-testid="workspace-members-panel">
          <Expand
            expandedByDefault={!participants.length}
            renderTrigger={(toggle, expanded) => (
              <StyledExpand
                iconFirst
                icon={CornerBottomIcon}
                onClick={toggle}
                expanded={expanded}>
                <h1>
                  <FormattedMessage
                    id={ChatTranslation.sidebarWorkspaceMembers}
                  />
                </h1>
              </StyledExpand>
            )}>
            {workspaceMembers.map(account => (
              <MemberItem key={account.id} account={account} showPopover />
            ))}
          </Expand>
        </ListWrapper>
      )}

      {!!workspaceGuests.length && !isGuestAccount && (
        <ListWrapper data-testid="workspace-guests-panel">
          <Expand
            expandedByDefault={
              !(participants.length || workspaceMembers.length)
            }
            renderTrigger={(toggle, expanded) => (
              <StyledExpand
                iconFirst
                icon={CornerBottomIcon}
                onClick={toggle}
                expanded={expanded}>
                <h1>
                  <FormattedMessage
                    id={ChatTranslation.sidebarWorkspaceGuests}
                  />
                </h1>
              </StyledExpand>
            )}>
            {workspaceGuests.map(account => (
              <MemberItem key={account.id} account={account} showPopover />
            ))}
          </Expand>
        </ListWrapper>
      )}

      {!participants.length &&
        (!workspaceMembers.length || isGuestAccount) &&
        (!workspaceGuests.length || isGuestAccount) &&
        !accountsLoading && (
          <NoMembersMessage>
            <FormattedMessage id={ChatTranslation.noMembersMessage} />
          </NoMembersMessage>
        )}
    </StyledMembersList>
  );
};
