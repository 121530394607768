import styled from 'styled-components';

export const StyledConfigureSsoForm = styled.div`
  margin-top: 1rem;
  display: flex;
  max-width: 32rem;

  label {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  .form {
    flex: 1;

    .field {
      select {
        font-family: ${p => p.theme.fonts.Regular};
      }
    }

    .field + .field {
      margin-top: 1rem;
    }
  }

  .sidebar {
    margin-left: 1.5rem;
    min-width: 6rem;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.125rem;
  }

    button {
      width: 100%;
      text-align: center;
    }

    &.guarded {
      .submit-btn {
        display: none;
      }
    }

    &:not(.guarded) {
      .guard-btn {
        display: none;
      }
    }
  }
`;
