import React, { FC } from 'react';
import { StyledIsTypingDotsContainer } from './IsTypingContainer.styled';
import { LoadingDots } from './loadingDots';

interface IsTypingContainerProps {}

export const IsTypingDotsContainer: FC<IsTypingContainerProps> = () => {
  return (
    <StyledIsTypingDotsContainer>
      <LoadingDots />
    </StyledIsTypingDotsContainer>
  );
};
