import styled from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { Tabs } from 'react-tabs';

const APROXIMATE_EXTENDED_SEARCH_STATIC_HEIGHT = 200;

export const StyledSearchResultWrapper = styled.div`
  max-height: calc(80vh - ${APROXIMATE_EXTENDED_SEARCH_STATIC_HEIGHT}px);
  overflow-y: auto;
  font-size: 0.875rem;
`;

export const StyledExpandedSearch = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xl};
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 6px rgba(0, 0, 0, 0.1);
  border-color: ${p => p.theme.colors.SurfaceBorder};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 1;
`;

export const ExpandedSearchTabs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  font-size: 0.875rem;

  svg {
    margin-right: 0.25rem;
  }

  ul {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
  }
`;

export const SearchTab = styled(ButtonWithIcon)<{}>`
  min-width: 84px;
  margin-right: 1rem;
  padding: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-size: 0.875rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};

  &.active {
    background-color: ${p => p.theme.colors.OnSurfaceSelected};
  }

  &:focus {
    background-color: ${p => p.theme.colors.OnSurfacePressed};
  }

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const SearchEmptyState = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const SearchAppIntegrationSection = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.OnSurface};
  border-radius: 0.25rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

  div {
    display: flex;
    align-items: center;
  }
  .enter-icon {
    display: none;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    .enter-icon {
      display: block;
    }
  }

  &:hover {
    background-color: ${p => (p.disabled ? '' : p.theme.colors.OnSurfaceHover)};
  }

  &:focus,
  &:focus-visible {
    background-color: ${p =>
      p.disabled ? '' : p.theme.colors.OnSurfacePressed};
  }
`;

export const StyledAppIntegrationIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
`;

export const StyledAppIcon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;
  width: 100%;
  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

export const SearchTabInner = styled.div`
  display: flex;
`;

export const SearchResultCounter = styled.span`
  margin-left: 0.25rem;
`;
