import { PreviewSegmentEventTypes } from './PreviewSegment.types';

export const initListeners = (
  hoverRef: HTMLElement | null,
  handleHover: (e: MouseEvent) => void,
  handleClick: (e: MouseEvent) => void,
) => {
  hoverRef?.addEventListener(PreviewSegmentEventTypes.Mouseleave, handleHover);
  hoverRef?.addEventListener(PreviewSegmentEventTypes.MouseEnter, handleHover);
  hoverRef?.addEventListener(PreviewSegmentEventTypes.MouseClick, handleClick);
};

export const removeListeners = (
  hoverRef: HTMLElement | null,
  handleHover: (e: MouseEvent) => void,
  handleClick: (e: MouseEvent) => void,
) => {
  hoverRef?.removeEventListener(
    PreviewSegmentEventTypes.Mouseleave,
    handleHover,
  );
  hoverRef?.removeEventListener(
    PreviewSegmentEventTypes.MouseEnter,
    handleHover,
  );
  hoverRef?.removeEventListener(
    PreviewSegmentEventTypes.MouseClick,
    handleClick,
  );
};
