import styled from 'styled-components';

export const RequestUpgradeModalContent = styled.div`
  display: grid;
  grid-template-areas:
    'illustration'
    'intro'
    'usps'
    'controls';
  grid-template-columns: 1fr;

  ${p => p.theme.breakpoints.md`
    grid-template-areas:
      'intro illustration'
      'usps illustration'
      'controls controls';
    grid-template-columns: 280px 1fr;
  `}
`;

export const Illustration = styled.div`
  grid-area: illustration;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Intro = styled.div`
  grid-area: intro;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-family: ${p => p.theme.fonts.Regular};
  text-align: center;
  margin: 2rem auto 0;
  text-align: center;
  padding: 0 1rem;

  ${p => p.theme.breakpoints.md`
    text-align: left;
  `}
`;

export const SellingPoints = styled.ul`
  grid-area: usps;
  margin-top: 1rem;
  padding: 0 1rem;
  margin-left: 1.5rem;

  ${p => p.theme.breakpoints.md`
    padding: unset;
  `}
`;

export const SellingPointIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: -1.5rem;
  margin-right: 0.5rem;
  color: ${p => p.theme.colors.Primary};
  background-color: ${p => p.theme.colors.PrimaryLightest};
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
`;

export const SellingPointTag = styled.span`
  font-size: 0.75rem;
  margin-left: 0.5rem;
  padding: 3px 6px;
  color: ${p => p.theme.colors.Primary};
  background-color: ${p => p.theme.colors.PrimaryLightest};
  border-radius: 4px;
`;

export const SellingPoint = styled.li`
  margin: 0.3125rem 0;
  display: inline-block;
  width: 100%;
`;

export const ButtonControls = styled.div`
  grid-area: controls;
  margin-top: 2rem;

  button:last-of-type {
    margin-top: 1rem;
  }

  ${p => p.theme.breakpoints.md`
    text-align: right;
    display: flex;
    flex-direction: row-reverse;

    button:last-of-type {
      margin-right: 1rem;
      margin-top: unset;
    }
  `}
`;
