import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const StyledCustomIntegrationsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl}
    ${({ theme }) => theme.spacing.xs} 0;
  overflow: hidden;
  width: 100%;

  .header {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: ${p => p.theme.colors.OnSurface};
  }

  .add-custom-integration {
    margin-top: 1rem;
  }
`;

export const CustomIntegrationItem = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: 0.5rem;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Surface};
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    border-color: ${p => p.theme.colors.SurfaceBorder};
    .delete-button {
      display: block !important;
    }
  }
`;

export const StyledCustomIntegrations = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledIntegrationBotImage = styled.img`
  height: 2.25rem;
  width: 2.25rem;
  object-fit: contain;
  margin-right: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.375rem;

  svg {
    transform: translateY(0);
  }
`;

export const StyledIntegrationTitle = styled.div`
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: ${p => p.theme.colors.OnSurface};
`;

export const StyledIntegrationDescription = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledIntegrationButtonsBlock = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 400;

  button {
    padding: 0;
    display: flex;
    align-items: center;
  }
`;

export const RemoveButton = styled(Button)`
  color: ${p => p.theme.colors.CriticalDark};
  margin-right: 1.5rem;
  display: none !important;
`;
