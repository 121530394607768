import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import {
  ModalAnimation,
  ModalHeader,
  ModalProps,
  ModalThemeOverride,
} from '../../../shared/components/Modal';
import { DownloadIcon } from '../../../shared/icons';
import { getAccountName } from '../../User/User.utils';
import { Avatar } from '../../User/UserAvatar/Avatar';
import {
  AssetAccountName,
  AssetCreatedAt,
  AssetModal,
  AssetPreviewMeta,
  AssetPreviewMetaInner,
  AssetPreviewModalBody,
  DownloadLink,
  MetaWrapper,
  ModalHeaderInner,
} from './AssetPreviewModal.styled';
import { getDownloadUrl } from '../Asset.utils';
import { appEnv } from '../../../appEnv';
import { useMobile } from '../../../shared/hooks';
import { PREVIEW_IMAGE_MAX_WIDTH } from '../../Preview';
import { Carousel } from '../../../shared/components/Carousel';
import { AvatarForm } from '../../User/UserAvatar/Avatar.types';
import { useAssetPreviewControls } from '../AssetPreviewProvider';
import { isDocumentMimeType } from '../../Preview/DocumentPreview';
import { EmbedPreview } from '../../Preview/DocumentPreview/EmbedPreview/EmbedPreview';
import { useAccountByIri } from '../../Account';
import type {
  ChatMessageAssetApiType,
  ChatTextMessageInternalType,
} from '../../Chat/Chat.types';
import type { FileAssetId, ImageAssetId } from '../Asset.types';
import type { LinkDocumentMetaData } from '../../Link/Link.types';

interface AssetPreviewModalProps extends ModalProps {
  assetIri: ImageAssetId | FileAssetId;
  assets: ChatMessageAssetApiType[];
  chatMessage: ChatTextMessageInternalType;
}

export const AssetPreviewModal: FC<AssetPreviewModalProps> = ({
  assetIri,
  assets,
  chatMessage,
  onRequestClose,
  ...props
}) => {
  const { showAssetPreview } = useAssetPreviewControls();

  const asset = useMemo(
    () => assets?.find(a => a.assetIri === assetIri),
    [assetIri, assets],
  );

  const initialSlide = useMemo(
    () => assets?.findIndex(a => a.assetIri === assetIri),
    [assetIri, assets],
  );

  const { accountIri, createdAt } = chatMessage;

  const dateFormatted = useMemo(
    () => format(createdAt, 'EEEE, ' + appEnv.TIME_FORMAT),
    [createdAt],
  );
  const animationType: ModalAnimation = ModalAnimation.fadeIn;

  const isMobile = useMobile();

  const handleSlideChange = (index: number) => {
    if (assets) {
      showAssetPreview(assets, chatMessage, assets[index].assetIri);
    }
  };

  const filteredAssets = useMemo(() => {
    if (assets) {
      return assets.filter(
        asset =>
          (asset.metadata &&
            'type' in asset.metadata &&
            !isDocumentMimeType(asset.metadata.type)) ||
          !isDocumentMimeType(asset.mimeType),
      );
    }

    return [];
  }, [assets]);

  const showFilePreview = useMemo(
    () =>
      asset &&
      asset.metadata &&
      'type' in asset.metadata &&
      isDocumentMimeType(asset.metadata.type),
    [asset],
  );

  const account = useAccountByIri(accountIri!);

  return (
    <AssetModal
      width={PREVIEW_IMAGE_MAX_WIDTH}
      onRequestClose={onRequestClose}
      animation={animationType}
      themeOverride={ModalThemeOverride.DARK}
      {...props}>
      <ModalHeader
        onRequestClose={onRequestClose}
        themeOverride={ModalThemeOverride.DARK}>
        <ModalHeaderInner isMobile={isMobile}>
          <AssetPreviewMeta>
            <Avatar account={account} variant={AvatarForm.ROUNDED} />
            <AssetPreviewMetaInner>
              <AssetAccountName isMobile={isMobile}>
                {getAccountName(account)}
              </AssetAccountName>
              <MetaWrapper>
                <AssetCreatedAt isMobile={isMobile}>
                  {dateFormatted}
                </AssetCreatedAt>
              </MetaWrapper>
            </AssetPreviewMetaInner>
          </AssetPreviewMeta>
          {asset && (
            <DownloadLink
              href={getDownloadUrl(asset.url)}
              download={asset.filename}
              rel="noopener noreferrer">
              <DownloadIcon />
            </DownloadLink>
          )}
        </ModalHeaderInner>
      </ModalHeader>
      {showFilePreview && asset?.metadata ? (
        <EmbedPreview
          url={(asset.metadata as LinkDocumentMetaData).preview_url}
          title={asset.filename}
        />
      ) : (
        <AssetPreviewModalBody isMobile={isMobile}>
          {filteredAssets && (
            <Carousel
              slideChange={handleSlideChange}
              assets={filteredAssets}
              initialSlide={initialSlide}
            />
          )}
        </AssetPreviewModalBody>
      )}
    </AssetModal>
  );
};
