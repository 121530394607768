import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledModalHeader = styled.div`
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    min-height: 4rem;
  `}
`;

export const ModalHeaderActionButton = styled(ButtonWithIcon)<{
  iconSize?: number;
  isActive?: boolean;
}>`
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }

  svg {
    width: ${({ iconSize }) => (iconSize ? iconSize : '16')}px;
    height: ${({ iconSize }) => (iconSize ? iconSize : '16')}px;
  }
`;
