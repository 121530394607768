import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import {
  CallButton,
  StyledIncomingCallToast,
  TextBlock,
} from './IncomingCallToast.styled';
import { AccountApiType } from '../../../User/User.types';
import { ChatAvatar } from '../../../Chat/ChatAvatar';
import { CallIcon, ClearIcon, VideoCallIcon } from '../../../../shared/icons';
import { getAccountName } from '../../../User/User.utils';
import { ConferenceTranslation } from '../../i18n';
import { ConferenceCallType } from '../../Conference.types';
import { useTheme } from 'styled-components';
import { useInboundCallControls } from '../../Conference.hooks';

interface IncomingCallToastProps {
  toastId: string;
  caller: AccountApiType;
  callType: ConferenceCallType;
  conferenceId: string;
  callsMapRef: RefObject<Record<string, boolean>>;
  callSoundUrl: string;
}

const SOUND_INTERVAL = 5000;
const MAX_TIMES_PLAYED = 8;

export const IncomingCallToast: FC<IncomingCallToastProps> = ({
  toastId,
  caller,
  callType,
  conferenceId,
  callsMapRef,
  callSoundUrl,
}) => {
  const theme = useTheme();
  const timesPlayedRef = useRef(0);

  useEffect(() => {
    const audioNode = new Audio(callSoundUrl);
    audioNode.play();
    timesPlayedRef.current += 1;
    const timer = setInterval(() => {
      if (!callsMapRef.current?.[toastId]) {
        clearInterval(timer);
        return;
      }
      audioNode.play();
      timesPlayedRef.current += 1;
      if (timesPlayedRef.current >= MAX_TIMES_PLAYED) {
        clearInterval(timer);
      }
    }, SOUND_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [callSoundUrl, callsMapRef, toastId]);

  const isVideoCall = callType === ConferenceCallType.video;
  const callToastText = isVideoCall
    ? ConferenceTranslation.callToastIncomingVideoCall
    : ConferenceTranslation.callToastIncomingAudioCall;

  const [processing, setProcessing] = useState(false);
  const {
    rejectInboundCall,
    acceptAndOpenInboundCall,
  } = useInboundCallControls();

  const hideToast = useCallback(() => {
    toast.dismiss(toastId);
  }, [toastId]);

  const rejectCall = useCallback(async () => {
    setProcessing(true);
    await rejectInboundCall(conferenceId, hideToast);
  }, [conferenceId, hideToast, rejectInboundCall]);

  const acceptCall = useCallback(async () => {
    setProcessing(true);
    acceptAndOpenInboundCall(conferenceId, hideToast);
  }, [acceptAndOpenInboundCall, conferenceId, hideToast]);

  return (
    <StyledIncomingCallToast>
      <ChatAvatar account={caller} showOnlineStatus={false} avatarSize="2rem" />
      <TextBlock>
        <b>{getAccountName(caller)}</b>
        <span>
          <FormattedMessage id={callToastText} />
        </span>
      </TextBlock>
      <CallButton
        backgroundColor={theme.colors.CallReject}
        rotateIcon={!isVideoCall}
        disabled={processing}
        onClick={rejectCall}>
        {isVideoCall ? <ClearIcon height={14} width={14} /> : <CallIcon />}
      </CallButton>
      <CallButton
        backgroundColor={theme.colors.CallAccept}
        disabled={processing}
        onClick={acceptCall}>
        {isVideoCall ? <VideoCallIcon /> : <CallIcon />}
      </CallButton>
    </StyledIncomingCallToast>
  );
};
