import { database } from '../../../../Database';
import { InvitationsTableType } from '../types/InvitationIDDB.types';
import { getLongId } from '../../../../../shared/utils/id';

export const getInvitationsFromIDDB = (
  workspaceId: string,
  desktopId?: string,
) => {
  return database.invitations
    .where({
      workspaceId,
      ...(desktopId ? { desktopId } : {}),
    })
    .toArray();
};

export const getInvitationFromIDDB = (
  invitationId?: string,
): Promise<InvitationsTableType | undefined> => {
  if (!invitationId) {
    return Promise.resolve(undefined);
  }
  return database.invitations.get({
    id: getLongId('account-invitations', invitationId),
  });
};
