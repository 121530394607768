import styled, { css } from 'styled-components';

export const SuffixWrapper = styled.div<{ hasSuffix: boolean }>`
  position: relative;

  ${p =>
    p.hasSuffix &&
    css`
      input {
        padding-right: 7rem;
      }
    `}
`;

export const Suffix = styled.span`
  position: absolute;
  font-family: ${p => p.theme.fonts.Semibold};
  top: 0.75rem;
  right: 1rem;
  font-size: 1rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const FieldDescription = styled.div`
  margin-top: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 1.2;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;
