import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import {
  NotificationsFormValueType,
  WorkspaceNotificationsApiType,
  WorkspaceNotificationsFormValues,
} from './WorkspaceNotifications.types';
import { Section, SectionHeader, SectionHeaderTitle } from '..';
import { FormToggle } from '../../../shared/components/Toggle/FormToggle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { WorkspaceTranslation } from '../../Workspace/i18n';
import {
  NotificationsSectionBody,
  NotificationsSectionBodyItem,
  NotificationsSectionTitleWrapper,
} from '../../GeneralSettings/NotificationsSettingsAdmin/NotificationsSettingsForm/NotificationsSettingsForm.styled';
import {
  CustomNotificationsFormFields,
  NotificationsSeparatorStyled,
} from '../../GeneralSettings/NotificationsSettingsAdmin/CustomNotificationsSettingsForm';
import { WORKSPACE_NOTIFICATIONS_GLOBAL_SETTINGS } from './WorkspaceNotifications.constants';
import { Spinner } from '../../../shared/components/Spinner';

export interface WorkspaceNotificationsFormProps {
  settings: WorkspaceNotificationsApiType;
  isSubmitting: boolean;
  onSubmit: (formValues: WorkspaceNotificationsFormValues) => void;
}

export const WorkspaceNotificationsForm: FC<
  WorkspaceNotificationsFormProps
> = ({ settings, isSubmitting, onSubmit }) => {
  if (!settings) {
    return <Spinner />;
  }

  const handleFormClick = (name: string, value: NotificationsFormValueType) => {
    const formValue = {
      ...settings,
      [name]: value,
    } as WorkspaceNotificationsFormValues;
    onSubmit({ ...formValue });
  };

  return (
    <Formik
      initialValues={{
        id: settings.id,
        messagesNotifications: settings.messagesNotifications,
        generalBellNotificationsEnable: settings.generalBellNotificationsEnable,
        billingNotificationsEnable: settings.billingNotificationsEnable,
        upcomingMeetingNotificationsEnable:
          settings.upcomingMeetingNotificationsEnable,
        soundsEnable: settings.soundsEnable,
        useGlobalSettings: settings.useGlobalSettings,
      }}
      onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <Section>
            <SectionHeader>
              <SectionHeaderTitle data-testid="title">
                <FormattedMessage
                  id={WorkspaceTranslation.adminNavUserNotificationsSounds}
                />
              </SectionHeaderTitle>
            </SectionHeader>

            <NotificationsSectionBody data-testid="global-notifications-section-body">
              <NotificationsSectionBodyItem>
                <NotificationsSectionTitleWrapper>
                  <FormattedHTMLMessage
                    id={
                      WorkspaceTranslation.adminNavUserNotificationsIntegrationsSounds
                    }
                  />
                </NotificationsSectionTitleWrapper>
                <Field
                  data-testid="use-global-settings"
                  name={WORKSPACE_NOTIFICATIONS_GLOBAL_SETTINGS}
                  disabled={isSubmitting}
                  component={FormToggle}
                  onClick={() =>
                    handleFormClick(
                      WORKSPACE_NOTIFICATIONS_GLOBAL_SETTINGS,
                      !values.useGlobalSettings,
                    )
                  }
                  checked={values.useGlobalSettings}
                />
              </NotificationsSectionBodyItem>

              {!values.useGlobalSettings && (
                <>
                  <NotificationsSeparatorStyled />

                  <CustomNotificationsFormFields
                    values={{
                      billingNotificationsEnable:
                        settings.billingNotificationsEnable,
                      generalBellNotificationsEnable:
                        settings.generalBellNotificationsEnable,
                      messagesNotifications: settings.messagesNotifications,
                      upcomingMeetingNotificationsEnable:
                        settings.upcomingMeetingNotificationsEnable,
                    }}
                    handleSubmit={handleFormClick}
                    isSubmitting={isSubmitting}
                  />
                </>
              )}
            </NotificationsSectionBody>
          </Section>
        </Form>
      )}
    </Formik>
  );
};
