import { useCallback, useMemo } from 'react';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import type {
  TeamAccountsRepositoryProps,
  TeamAccountsRepositoryResp,
} from './types/TeamAccounts.types';
import { getTeamAccountsApi } from './dataSources/TeamAccountsApi.dataSources';
import {
  AccountApiTypeToAccountTableType,
  createAccountRowsMap,
} from '../Account/utils/AccountIDDB.utils';
import { AccountApiType } from '../../../User/User.types';
import { getRedundantCacheAccounts } from '../Account/utils/AccountRepository.utils';
import { removeRelatedAccountsFromTeamsIDDB } from '../../../Team/data/AccountTeam/operations/AccountTeamIDDB.oprations';
import { removeRelatedTeamsFromAccountsIDDB } from './operations/TeamAccount.operations';
import { getAccountsByRelatedTeamIdIDDB } from './dataSources/TeamAccountsIDDB.dataSources';
import { getAccountsFromIDDB } from '../Account/dataSources/AccountIDDB.dataSources';
import { addEntityIdsToIDDBRelatedEntitiesList } from '../../../../shared/utils/iddb.utils';
import { bulkPutAccountsToIDDB } from '../Account/operations/AccountIDDB.operations';

export const useTeamAccountsRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: TeamAccountsRepositoryProps): TeamAccountsRepositoryResp => {
  const { teamId, workspaceId } = args;

  const fetchAccounts = useCallback(async () => {
    try {
      const { data: teamAccountsFromAPI, ok } = await getTeamAccountsApi(
        teamId,
      );

      if (ok) {
        // Remove current team from cache accounts that are absent in API response
        const teamAccountsInCache = await getAccountsByRelatedTeamIdIDDB(
          teamId,
        );
        const redundantTeamAccountsInCache = getRedundantCacheAccounts(
          teamAccountsInCache,
          teamAccountsFromAPI,
        );
        removeRelatedAccountsFromTeamsIDDB(
          [teamId],
          redundantTeamAccountsInCache.map(account => account.id),
        );
        removeRelatedTeamsFromAccountsIDDB(
          redundantTeamAccountsInCache.map(account => account.id),
          [teamId],
        );

        // Update accounts in cache with data from API
        const workspaceAccounts = await getAccountsFromIDDB(workspaceId);
        const workspaceAccountsInCacheMap =
          createAccountRowsMap(workspaceAccounts);
        const teamAccountsRows = teamAccountsFromAPI.map(accountFromAPI => {
          const currentRelatedTeamsInCache =
            workspaceAccountsInCacheMap[accountFromAPI.id]?.relatedTeams || [];
          const currentRelatedDesktopsInCache =
            workspaceAccountsInCacheMap[accountFromAPI.id]?.relatedDesktops ||
            [];

          return AccountApiTypeToAccountTableType(
            {
              ...(workspaceAccountsInCacheMap[accountFromAPI.id]?.sourceData ||
                {}),
              ...accountFromAPI,
            },
            workspaceId,
            addEntityIdsToIDDBRelatedEntitiesList(currentRelatedTeamsInCache, [
              teamId,
            ]),
            currentRelatedDesktopsInCache,
          );
        });
        bulkPutAccountsToIDDB(teamAccountsRows);
      }

      return teamAccountsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [teamId, workspaceId]);

  const { entities: accounts, loading } =
    useMultipleEntityRepository<AccountApiType>({
      fetchFunction: fetchAccounts,
      iddbQuerier: () => getAccountsByRelatedTeamIdIDDB(teamId),
      dependencies: [teamId, workspaceId],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      accounts,
      loading,
      fetchAccounts,
    }),
    [accounts, loading, fetchAccounts],
  );
};
