import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Navigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { OnboardingTranslation } from '../../i18n';
import { useChooseFeatureForWorkspaceMutation } from '../../Onboarding.hooks';
import { ChooseFeatureFormValues, SignUpStep } from '../../Onboarding.types';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepText } from '../OnboardingPage.styled';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { ChooseFeatureForm } from './ChooseFeature.form';
import { ChooseFeatureStepHeader } from './ChooseFeaturePage.styled';
import { getShortId } from '../../../../shared/utils/id';

export const ChooseFeaturePage: FC = () => {
  const { account, refetchAccountData } = useCurrentAccount();
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();

  const [chooseFeatureForWorkspace] = useChooseFeatureForWorkspaceMutation();

  const onSubmit = useCallback(
    (values: ChooseFeatureFormValues) => {
      if (!account?.id) {
        return;
      }
      const { organizeAndSearch, communicate } = values;
      return chooseFeatureForWorkspace({
        variables: {
          input: {
            id: workspace.id,
            organizeAndSearch,
            communicate,
          },
        },
      })
        .then(() => {
          refetchAccountData?.();
          refetchWorkspaces();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      account?.id,
      chooseFeatureForWorkspace,
      refetchAccountData,
      refetchWorkspaces,
      workspace.id,
    ],
  );

  if (account?.currentStep !== SignUpStep.choose_feature) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <OnboardingPage horizontallyCentered>
      <OnboardingStep data-testid="registration-step">
        <OnboardingStepsCounter currentStep={account?.currentStep} />
        <ChooseFeatureStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingChooseFeatureTitle}
          />
        </ChooseFeatureStepHeader>

        <OnboardingStepText centered data-testid="description">
          <FormattedMessage
            id={OnboardingTranslation.onboardingChooseFeatureDescription}
          />
        </OnboardingStepText>

        <ChooseFeatureForm onSubmit={onSubmit} />
      </OnboardingStep>
    </OnboardingPage>
  );
};
