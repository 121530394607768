import {
  SsoConfigurationFieldApiType,
  WorkspaceAppConfigurationApiType,
} from '../../../../AppStore.types';

export const checkIfSsoCanBeToggled = (
  ssoConfigurationFields: SsoConfigurationFieldApiType[],
  workspaceConfig?: WorkspaceAppConfigurationApiType,
): boolean => {
  if (!workspaceConfig) {
    return false;
  }
  if (workspaceConfig.enabled) {
    return true;
  }
  const requiredFields = ssoConfigurationFields
    .filter(field => field.required_enable)
    .map(field => field.identifier);
  if (!requiredFields.length) {
    return true;
  }
  return !requiredFields.find(
    identifier => !workspaceConfig.values[identifier],
  );
};
