import { gql } from '@apollo/client';
import { ApiConnection, PageInfoApiType } from '../../shared/api/api.types';
import {
  ChatConversationApiType,
  ChatMessageApiType,
  DraftChatMessageApiType,
  SearchChatConversationApiType,
} from './Chat.types';
import {
  ChatConversationFields,
  ChatMessageFields,
  DraftChatMessageFields,
} from './Chat.fragments';
import { LinkApiType } from '../Link/Link.types';
import { AccountInvitationApiType } from '../Invitation/data/Invitation/types/Invitation.types';

export const GET_CHAT_CONVERSATION = gql`
  query GetChatConversation($id: ID!) {
    chatConversation(id: $id) {
      ${ChatConversationFields}
    }
  }
`;

export interface GetChatConversationResponse {
  __typename: 'ChatConversationItem';
  chatConversation: ChatConversationApiType;
}

export interface GetChatConversationVariables {
  id: string;
}

export const GET_CHAT_CONVERSATIONS = gql`
  query GetChatConversations($workspaceId: String!) {
    workspaceChats(workspaceId: $workspaceId)
      @rest(path: "/workspace/{args.workspaceId}/chat") {
      edges {
        node {
          chatConversation {
            ${ChatConversationFields}
          }
          chatMessages {
            edges {
              node {
                ${ChatMessageFields}
              }
            }
          }
        }
      }
    }
  }
`;

type WorkspaceChatItem = {
  __typename: 'WorkspaceChatItem';
  chatConversation: ChatConversationApiType;
  chatMessages: ApiConnection<ChatMessageApiType>;
};

export interface GetChatConversationsResponse {
  workspaceChats: ApiConnection<WorkspaceChatItem>;
}

export interface GetChatConversationsVariables {
  workspaceId: string;
}

export const GET_CHAT_MESSAGES = gql`
  query GetChatMessages(
    $conversation: ID!
    $parentChatMessage: ID
    $first: Int
    $after: String
    $createdAtLessThan: String
    $createdAtGreaterThanEquals: String
    $order: Iterable
    $microtimetAtLessThan: String
    $microtimeAtGreaterThanEquals: String
  ) {
    listChatMessages(
      conversation: $conversation
      parentChatMessage: $parentChatMessage
      first: $first
      after: $after
      createdAtLessThan: $createdAtLessThan
      createdAtGreaterThanEquals: $createdAtGreaterThanEquals
      order: $order
      microtimetAtLessThan: $microtimetAtLessThan,
      microtimeAtGreaterThanEquals: $microtimeAtGreaterThanEquals,
    ) @connection(key: "listChatMessages", filter: ["conversation"]) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${ChatMessageFields}
        }
      }
    }
  }
`;

export interface GetChatMessagesResponse {
  listChatMessages: ApiConnection<
    ChatMessageApiType,
    'ChatMessageConnection'
  > & {
    pageInfo: PageInfoApiType & { __typename: 'ChatMessagePageInfo' };
  };
}

export interface GetChatMessagesVariables {
  conversation: string;
  workspaceId?: string;
  parentChatMessage?: string;
  first?: number;
  after?: number | string;
  createdAtLessThan?: string;
  createdAtGreaterThanEquals?: string;
  order?: {
    createdAt: 'asc' | 'desc';
  };
  mergeExistingAndIncomingEdges?: boolean;
  microtimetAtLessThan?: string;
  microtimeAtGreaterThanEquals?: string;
}

export const GET_DRAFT_CHAT_MESSAGES = gql`
  query GetDraftChatMessages(
    $workspace: ID!
  ) {
    listDraftChatMessages(
      workspace: $workspace
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${DraftChatMessageFields}
        }
      }
    }
  }
`;

export interface GetDraftChatMessagesResponse {
  listDraftChatMessages: ApiConnection<
    DraftChatMessageApiType,
    'ChatMessageConnection'
  > & {
    pageInfo: PageInfoApiType & { __typename: 'ChatMessagePageInfo' };
  };
}

export interface GetDraftChatMessagesVariables {
  workspace: string;
}

export const GET_CHAT_MESSAGES_SUBSET = gql`
  query GetChatMessagesSubset($conversation: ID!, $date: String, $amount: Int) {
    beforeMessages: listChatMessages(
      conversation: $conversation
      createdAtLessThan: $date
      first: $amount
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${ChatMessageFields}
        }
      }
    }
    afterMessages: listChatMessages(
      conversation: $conversation
      createdAtGreaterThanEquals: $date
      first: $amount,
      order: {
        createdAt: "asc"
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ${ChatMessageFields}
        }
      }
    }
  }
`;

export interface GetChatMessagesSubsetResponse {
  beforeMessages: ApiConnection<ChatMessageApiType> & {
    pageInfo: PageInfoApiType;
  };
  afterMessages: ApiConnection<ChatMessageApiType> & {
    pageInfo: PageInfoApiType;
  };
}

export interface GetChatMessagesSubsetVariables {
  conversation: string;
  date: string;
  amount: number;
}

export const SEARCH_CHAT_CONVERSATIONS = gql`
  query SearchChatConversations(
    $workspace: ID!
    $query: String
    $page: Int
    $pageSize: Int
    $filterOnlyName: Boolean
  ) {
    searchInChats(
      workspace: $workspace
      query: $query
      page: $page
      pageSize: $pageSize
      filterOnlyName: $filterOnlyName
    ) {
      id
      chatConversation {
        ${ChatConversationFields}
      }
      hitMessage {
        ${ChatMessageFields}
      }
    }
  }
`;

export interface SearchChatConversationsResponse {
  searchInChats: SearchChatConversationApiType[];
}

export interface SearchChatConversationsVariables {
  workspace: string;
  query: string;
  pageSize?: number;
  page?: number;
  filterOnlyName?: boolean;
}

export const GET_CHAT_LINK = gql`
  query GetChatLink($id: ID!) {
    chatLink(id: $id) {
      id
      _id
      createdAt
      createdBy {
        id
        _id
        email
        firstName
        lastName
        image {
          id
          contentUrl
        }
        createdAt
        modifiedAt
      }
      data {
        _id
        id
        url
        realUrl
        title
        description
        icon {
          id
          contentUrl
        }
        image {
          id
          contentUrl
        }
        screenshot {
          id
          contentUrl
          dimensions
        }
        status
        abuseChecked
        safe
        abuseDescription
        metadata
      }
    }
  }
`;

export interface GetChatLinkResponse {
  chatLink: LinkApiType | null;
}

export interface GetChatLinkVariables {
  id: string;
}

export const GET_CHAT_CONVERSATION_INVITATIONS = gql`
  query GetChatConversationInvitations($chatConversationIri: String) {
    workspaceAccountInvitations(chatConversationIri: $chatConversationIri) {
      edges {
        node {
          id
          recipients
          invitationUri
        }
      }
    }
  }
`;

export interface GetChatConversationInvitationsResponse {
  workspaceAccountInvitations: ApiConnection<
    Pick<AccountInvitationApiType, 'id' | 'recipients' | 'invitationUri'>
  >;
}

export interface GetChatConversationInvitationsVariables {
  chatConversationIri?: string;
}

export const GET_CHAT_CONVERSATION_UPDATES = gql`
  query GetChatConversationUpdates($workspaceIri: ID!, $date: String!) {
    deltaCreatedChatMessages(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          conversationIri
          ${ChatMessageFields}
        }
      }
    }
    deltaUpdatedChatMessages(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          conversationIri
          ${ChatMessageFields}
        }
      }
    }
    deltaDeletedChatMessages(workspace: $workspaceIri, date: $date) {
      edges {
        node {
          id
          conversationIri
          parentChatMessageIri
        }
      }
    }
  }
`;

export type CreatedChatMessageApiType = ChatMessageApiType & {
  conversationIri: string;
};

export type UpdatedMessageDeltaType = ChatMessageApiType & {
  conversationIri: string;
};

export type DeletedChatMessageApiType = Pick<
  ChatMessageApiType,
  'id' | 'parentChatMessageIri'
> & {
  conversationIri: string;
};

export interface GetChatConversationUpdatesResponse {
  deltaCreatedChatMessages: ApiConnection<CreatedChatMessageApiType>;
  deltaUpdatedChatMessages: ApiConnection<UpdatedMessageDeltaType>;
  deltaDeletedChatMessages: ApiConnection<DeletedChatMessageApiType>;
}

export interface GetChatConversationUpdatesVariables {
  workspaceIri: string;
  date: string;
}

export interface HideChatConversationVariables {
  input: {
    id: string;
    accountIri: string;
  };
}

export interface HideChatConversationResponse {
  __typename: 'ChatConversationItem';
  hideChatConversation: {
    chatConversation: ChatConversationApiType;
  };
}

export const HIDE_CHAT_CONVERSATION = gql`
  mutation HideChatConversation($input: hideChatConversationInput!) {
    __typename
    hideChatConversation(input: $input) {
      chatConversation {
        id
        accountsThatHidden
      }
      clientMutationId
    }
  }
`;
