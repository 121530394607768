import { useMutation } from '@apollo/client';
import {
  UNSUBSCRIBE_PRODUCT_INFORMATION_EMAILS,
  UnsubscribeProductInformationEmailsResponse,
  UnsubscribeProductInformationEmailsVariables,
} from './Subscription.mutations';

export const useUnsubscribeProductInformationEmailsMutation = () =>
  useMutation<
    UnsubscribeProductInformationEmailsResponse,
    UnsubscribeProductInformationEmailsVariables
  >(UNSUBSCRIBE_PRODUCT_INFORMATION_EMAILS);
