import React, { FC, useMemo } from 'react';
import { LinkApiType } from '../../../Link.types';
import { LinkControlsButton, StyledLinkControls } from './LinkControls.styled';
import { useIntl } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import {
  HeartFullIcon,
  HeartIcon,
  LockIcon,
} from '../../../../../shared/icons';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { DesktopTranslation } from '../../../../Desktop/i18n';
import { LinkCommentsCounter } from '../LinkCommentsCounter/LinkCommentsCounter';
import { useGoogleOauth } from '../../../../Preview/DocumentPreview';
import { LinkCheckbox } from '../LinkCheckbox';
import { useCurrentTypeOfPage } from '../../../../Desktop/Desktop.utils';

interface LinkControlsTypes {
  visible: boolean;
  link: LinkApiType;
  chatLink: boolean;
  toggleFavoriteLink: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  withLinkHover: boolean;
  openGoogleAuthWindow: () => void;
  linkSelected: boolean;
  onLinkCheckboxChange: () => void;
}

export const LinkControls: FC<LinkControlsTypes> = ({
  visible,
  link,
  chatLink,
  withLinkHover,
  toggleFavoriteLink,
  openGoogleAuthWindow,
  linkSelected,
  onLinkCheckboxChange,
}) => {
  const intl = useIntl();
  const googleAuthUrl = useGoogleOauth(link);
  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  const favoriteUiText = useMemo(
    (): string =>
      intl.formatMessage({
        id: link.accountFavorite
          ? DesktopTranslation.removeItemFromFavorites
          : DesktopTranslation.addItemToFavorites,
      }),
    [intl, link.accountFavorite],
  );

  return (
    <StyledLinkControls
      visible={visible}
      withLinkHover={withLinkHover}
      data-testid="link-controls">
      {!chatLink && (
        <>
          {!isFavoritesDesktop && (
            <LinkCheckbox
              checked={linkSelected}
              onChecked={onLinkCheckboxChange}
            />
          )}
          <LinkCommentsCounter link={link} />
          <LinkControlsButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={favoriteUiText}
            data-tooltip-place={TooltipPlace.top}
            data-event-off="click mouseleave"
            data-testid="favorite-icon"
            onClick={toggleFavoriteLink}
            aria-label={favoriteUiText}
            icon={link.accountFavorite ? HeartFullIcon : HeartIcon}
          />
        </>
      )}

      {googleAuthUrl && (
        <>
          <LinkControlsButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: LinkTranslation.linkMenuItemSignInWithGoogle,
            })}
            data-tooltip-place={TooltipPlace.top}
            data-testid="google-auth-icon"
            onClick={openGoogleAuthWindow}
            icon={LockIcon}
          />
        </>
      )}
    </StyledLinkControls>
  );
};
