import styled from 'styled-components';

export const ExternalRegistrationPageTitle = styled.div`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 1.5rem;
  line-height: 36px;
  margin-bottom: 28px;
`;

export const ExternalRegistrationNoAccountMessage = styled.p`
  margin: 20px 0;
`;

export const ExternalRegistrationNoAccountMessageEmail = styled.span`
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;
