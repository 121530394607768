import React, { FC } from 'react';
import { getImageSrcSet } from '../../../../shared/utils/url.utils';
import { LOGO_SIZE } from '../../../App/App.constants';
import {
  AppImage,
  AppImageWrap,
  AppName,
  StyledAppLink,
} from '../../../App/Views/AppLink/AppLink.styled';
import { DragAppItemType } from '../../Drag.types';
import { StyledAppPreview } from './AppPreview.styled';

export const AppPreview: FC<{ item: DragAppItemType }> = ({ item }) => {
  const { logo, name } = item.app.app;

  return (
    <StyledAppPreview
      clientOffset={item.clientOffset!}
      sourceOffset={item.sourceOffset!}>
      <StyledAppLink href="#">
        <AppImageWrap>
          {logo?.contentUrl && (
            <AppImage
              size={LOGO_SIZE}
              src={logo.contentUrl}
              srcSet={getImageSrcSet(logo.contentUrl, LOGO_SIZE)}
              alt={name}
              data-testid="app-icon"
            />
          )}
        </AppImageWrap>
        <AppName data-testid="app-name">{name}</AppName>
      </StyledAppLink>
    </StyledAppPreview>
  );
};
