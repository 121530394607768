import { useMemo } from 'react';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  ACTIVATE_ACCOUNT,
  ActivateAccountResponse,
  ActivateAccountVariables,
} from '../Auth/Auth.mutations';
import {
  ACCOUNT_LINK_REQUEST,
  AccountLinkRequestResponse,
  AccountLinkRequestVariables,
  GET_ACCOUNT,
  GET_INDUSTRY_CATEGORIES,
  GetAccountResponse,
  GetAccountVariables,
  GetIndustryCategoriesResponse,
} from './Onboarding.queries';
import {
  CANCEL_LINK_REQUEST_FOR_ACCOUNT,
  CancelLinkRequestForAccountResponse,
  CancelLinkRequestForAccountVariables,
  CHANGE_ABOUT_YOU_FOR_ACCOUNT,
  CHANGE_ONBOARDING_STEP_ACCOUNT,
  CHANGE_TEAM_SIZE_FOR_ACCOUNT,
  CHANGE_USE_CASE_FOR_ACCOUNT,
  ChangeAboutYouForAccountResponse,
  ChangeAboutYouForAccountVariables,
  ChangeOnboardingStepAccountResponse,
  ChangeOnboardingStepAccountVariables,
  ChangeTeamSizeForAccountResponse,
  ChangeTeamSizeForAccountVariables,
  ChangeUseCaseForAccountResponse,
  ChangeUseCaseForAccountVariables,
  COMPLETE_ACCOUNT_LINK_REQUEST,
  CompleteAccountLinkRequestResponse,
  CompleteAccountLinkRequestVariables,
  CREATE_ACCOUNT_LINK_REQUEST,
  CreateAccountLinkRequestResponse,
  CreateAccountLinkRequestVariables,
  REQUEST_RESET_PASSWORD,
  RequestResetPasswordResponse,
  RequestResetPasswordVariables,
  RESEND_ACTIVATE_ACCOUNT,
  ResendActivateAccountResponse,
  ResendActivateAccountVariables,
  RESET_PASSWORD,
  ResetPasswordResponse,
  ResetPasswordVariables,
  SELECT_PLAN_FOR_ACCOUNT,
  SelectPlanForResponse,
  SelectPlanForVariables,
  VERIFY_EMAIL_CHANGE_ACCOUNT,
  VerifyEmailChangeAccountResponse,
  VerifyEmailChangeAccountVariables,
  ChooseFeatureForWorkspaceResponse,
  ChooseFeatureForWorkspaceVariables,
  CHOOSE_FEATURE_FOR_WORKSPACE,
} from './Onboarding.mutations';
import { useRefreshableQuery } from '../Api/Api.hooks';
import { useQueryParams } from '../../shared/hooks';
import { Auth0Connection } from '../Auth/Auth.service';

export const useChangeOnboardingStepAccountMutation = () =>
  useMutation<
    ChangeOnboardingStepAccountResponse,
    ChangeOnboardingStepAccountVariables
  >(CHANGE_ONBOARDING_STEP_ACCOUNT, { errorPolicy: 'all' });
export const useActivateAccountMutation = () =>
  useMutation<ActivateAccountResponse, ActivateAccountVariables>(
    ACTIVATE_ACCOUNT,
    { errorPolicy: 'all' },
  );
export const useResendActivateAccountMutation = () =>
  useMutation<ResendActivateAccountResponse, ResendActivateAccountVariables>(
    RESEND_ACTIVATE_ACCOUNT,
    { errorPolicy: 'all' },
  );
export const useRequestResetPasswordMutation = () =>
  useMutation<RequestResetPasswordResponse, RequestResetPasswordVariables>(
    REQUEST_RESET_PASSWORD,
    { errorPolicy: 'all' },
  );
export const useResetPasswordMutation = () =>
  useMutation<ResetPasswordResponse, ResetPasswordVariables>(RESET_PASSWORD, {
    errorPolicy: 'all',
  });
export const useSelectPlanForAccountMutation = () =>
  useMutation<SelectPlanForResponse, SelectPlanForVariables>(
    SELECT_PLAN_FOR_ACCOUNT,
    {
      errorPolicy: 'all',
    },
  );
export const useCreateAccountLinkRequestMutation = () =>
  useMutation<
    CreateAccountLinkRequestResponse,
    CreateAccountLinkRequestVariables
  >(CREATE_ACCOUNT_LINK_REQUEST, {
    errorPolicy: 'all',
  });
export const useCompleteAccountLinkRequestMutation = () =>
  useMutation<
    CompleteAccountLinkRequestResponse,
    CompleteAccountLinkRequestVariables
  >(COMPLETE_ACCOUNT_LINK_REQUEST, {
    errorPolicy: 'all',
  });
export const useAccountLinkRequestQuery = (
  options?: QueryHookOptions<
    AccountLinkRequestResponse,
    AccountLinkRequestVariables
  >,
) =>
  useQuery<AccountLinkRequestResponse, AccountLinkRequestVariables>(
    ACCOUNT_LINK_REQUEST,
    options,
  );
export const useCancelLinkRequestForAccountMutation = () =>
  useMutation<
    CancelLinkRequestForAccountResponse,
    CancelLinkRequestForAccountVariables
  >(CANCEL_LINK_REQUEST_FOR_ACCOUNT, {
    errorPolicy: 'all',
  });
export const useVerifyEmailChangeAccountMutation = () =>
  useMutation<
    VerifyEmailChangeAccountResponse,
    VerifyEmailChangeAccountVariables
  >(VERIFY_EMAIL_CHANGE_ACCOUNT, { errorPolicy: 'all' });

export const useGetAccount = (
  options?: QueryHookOptions<GetAccountResponse, GetAccountVariables>,
) => useQuery<GetAccountResponse, GetAccountVariables>(GET_ACCOUNT, options);

export const useChangeUseCaseForAccountMutation = () =>
  useMutation<
    ChangeUseCaseForAccountResponse,
    ChangeUseCaseForAccountVariables
  >(CHANGE_USE_CASE_FOR_ACCOUNT, {
    errorPolicy: 'all',
  });

export const useChangeAboutYouForAccountMutation = () =>
  useMutation<
    ChangeAboutYouForAccountResponse,
    ChangeAboutYouForAccountVariables
  >(CHANGE_ABOUT_YOU_FOR_ACCOUNT, {
    errorPolicy: 'all',
  });

export const useGetIndustryCategories = () =>
  useRefreshableQuery<GetIndustryCategoriesResponse>(GET_INDUSTRY_CATEGORIES);

export const useChangeTeamSizeForAccountMutation = () =>
  useMutation<
    ChangeTeamSizeForAccountResponse,
    ChangeTeamSizeForAccountVariables
  >(CHANGE_TEAM_SIZE_FOR_ACCOUNT, {
    errorPolicy: 'all',
  });

export const useChooseFeatureForWorkspaceMutation = () =>
  useMutation<
    ChooseFeatureForWorkspaceResponse,
    ChooseFeatureForWorkspaceVariables
  >(CHOOSE_FEATURE_FOR_WORKSPACE, {
    errorPolicy: 'all',
  });

export const useConnectionFromUrl = () => {
  const { connection } = useQueryParams();
  return useMemo(() => {
    if (!connection) {
      return undefined;
    }
    if (connection === Auth0Connection.google) {
      return Auth0Connection.google;
    }
    if (connection === Auth0Connection.facebook) {
      return Auth0Connection.facebook;
    }
    if (connection === Auth0Connection.apple) {
      return Auth0Connection.apple;
    }
    if (connection === Auth0Connection.microsoft) {
      return Auth0Connection.microsoft;
    }
    return undefined;
  }, [connection]);
};

export const useVerifyPendingEmailHashMutation = () =>
  useMutation<
    VerifyEmailChangeAccountResponse,
    VerifyEmailChangeAccountVariables
  >(VERIFY_EMAIL_CHANGE_ACCOUNT, { errorPolicy: 'all' });
