import { getLongId } from '../../shared/utils/id';
import { FolderApiType } from './Folder.types';

export const getFolderByFolderId = (
  folders: Array<FolderApiType>,
  folderId?: string,
): FolderApiType | undefined => {
  if (!folderId) {
    return;
  }
  return folders.find(({ id }) => id === getLongId('folders', folderId));
};

export const getSubFoldersByFolderId = (
  folders: Array<FolderApiType>,
  folderId: string,
  isFavoritesDesktop: boolean,
): Array<FolderApiType> => {
  const currentFolder = getFolderByFolderId(folders, folderId);

  if (!currentFolder?.subFolderIds && folderId) {
    return [];
  }

  return !folderId
    ? folders.filter(
        folder =>
          (!isFavoritesDesktop && !folder.parent?.id) ||
          (isFavoritesDesktop && folder.accountFavorite),
      )
    : folders.filter(folder =>
        currentFolder?.subFolderIds?.includes(folder.id),
      );
};
