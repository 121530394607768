import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const FormFields = styled.div`
  margin: ${({ theme }) => theme.spacing.xxl} 0
    ${({ theme }) => theme.spacing.xs} 0;

  .external-user {
    display: none;
  }
`;

export const FormFieldsRow = styled.div`
  display: flex;
  margin-top: 0.5rem;
  div:first-child {
    flex: 1;
  }
  & > div ~ div {
    margin-left: 0.5rem;
  }
`;

export const FormButtonGroup = styled.div`
  margin-bottom: 2rem;
`;

export const FormControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    margin-top: 0.75rem;
  }
`;

const UserButtonStyle = css`
  padding: 0;
  white-space: nowrap;
  &:hover {
    opacity: 0.8;
  }
`;

export const AddUserButton = styled(ButtonWithIcon)`
  ${UserButtonStyle};
  margin-top: 0.625rem;
  color: ${p => p.theme.colors.Primary};
`;
