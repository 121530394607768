import React, { FC, PropsWithChildren } from 'react';
import { Message, StyledLockdownMessage } from './LockdownMessage.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { WorkspaceTranslation } from '../i18n';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_PATHNAME } from '../../Auth/Auth.constants';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';

export const LockdownMessage: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <StyledLockdownMessage data-testid="lockdown-msg">
      <Message data-testid="msg">{children}</Message>
      <Button
        className="logoutButton"
        size={ButtonSize.sm}
        mode={ButtonMode.secondary}
        onClick={() =>
          navigate({
            pathname: LOGOUT_PATHNAME,
            search: getQueryParamsFrom({
              sendLogoutMessageToOtherTabs: true,
            }),
          })
        }
        data-testid="logout-btn">
        <FormattedMessage id={WorkspaceTranslation.menuLogoutLink} />
      </Button>
    </StyledLockdownMessage>
  );
};
