import styled, { css } from 'styled-components';
import { LinkControlsButton } from '../../Link/LinksView/Link/LinkControls/LinkControls.styled';
import {
  DROPZONE_BACKGROUND_COLOR_ACTIVE,
  DROPZONE_BACKGROUND_COLOR_DEFAULT,
  DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME,
} from '../../Drag/ExternalUrlDropZone/ExternalUrlDropZone.constants';

export const FolderWrapper = styled.div<{
  isDragging: boolean;
  hasOpenMenu: boolean;
}>`
  position: relative;
  z-index: ${({ theme, hasOpenMenu }) =>
    hasOpenMenu ? theme.zIndexLevels.FolderLifted : theme.zIndexLevels.Folder};

  .menu.opened ~ .folder-background {
    opacity: 1;
  }

  &:hover {
    .folder-background {
      opacity: ${p => (p.isDragging ? 0.3 : 1)};
    }

    .heart-full-icon {
      display: none;
    }
  }
`;

export const FolderContextArea = styled.div`
  height: 100%;
`;

export const FolderContainer = styled.div<{
  isDraggingOver?: boolean;
  isDragging: boolean;
  isDropEnabled?: boolean;
  hasSubfolders?: boolean;
}>`
  position: relative;
  min-height: 120px;
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  cursor: pointer;
  opacity: ${p => (p.isDragging ? 0.3 : 1)};
  border-color: ${p =>
    p.isDropEnabled && p.isDraggingOver
      ? p.theme.colors.BorderLight
      : 'transparent'};
  border-width: 1px;
  border-style: solid;
  background-color: ${p =>
    p.isDropEnabled && p.isDraggingOver
      ? p.theme.colors.BackgroundLight
      : 'transparent'};
  transition: background-color 0.2s ease, border-color 0.2s ease;
  z-index: 2;

  ${p =>
    p.isDropEnabled &&
    css`
      .${DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME} & {
        background-color: ${p.isDraggingOver
          ? DROPZONE_BACKGROUND_COLOR_ACTIVE
          : DROPZONE_BACKGROUND_COLOR_DEFAULT};
        border-color: ${p => p.theme.colors.Primary};

        ${p.isDraggingOver &&
        css`
          h4 {
            color: ${p => p.theme.colors.PrimaryTextLight};
          }
        `}
      }
    `}

  ${p =>
    p.hasSubfolders &&
    css`
      &:hover {
        .folder-title {
          opacity: 0;
        }
      }
    `}
`;

export const FolderIcon = styled.div`
  height: 4.625rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;

export const FolderImage = styled.img`
  position: absolute;
  top: 1.688rem;
  left: 1.625rem;
  width: 2rem;
  height: 1.875rem;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
  border-radius: 0.25rem;
`;

export const FolderTitle = styled.h4`
  max-width: 100%;
  max-height: 50px;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.2;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  transition: opacity 0.3s ease;
`;

export const FolderBackground = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  transition: opacity 0.2s ease;
`;

export const FavoriteIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 4px;
  color: ${p => p.theme.colors.FavoriteIcon};
  cursor: pointer;
  z-index: 3;

  .make-favorite {
    display: none;
  }
`;

export const StyledFolderControls = styled.div.attrs({
  'data-testid': 'folder-controls',
})<{ visible: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.LinkHoverBackground};
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.colors.LinkHoverIcon};
  display: flex;
  justify-content: right;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.xxs};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${({ theme }) =>
    theme.animationTemplates.opacityOnHoverTransition};
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  padding: 0.313rem 0.625rem 0.5rem 0.5rem;
`;

export const StyledFolderControlsFooter = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFolderCounters = styled.div`
  max-width: 5rem;
  overflow: hidden;
`;

export const StyledFolderCounter = styled.div`
  ${({ theme }) => theme.fontTemplates.regularSemibold};
  max-width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FolderControlsButton = styled(LinkControlsButton)`
  cursor: pointer;
  background-color: unset !important;
  display: flex;
  margin-left: auto;
  z-index: 4;
`;

export const StyledFolderOuter = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;
