import { database } from '../../../Database';
import {
  createFavoriteData,
  deleteFavoriteData,
} from '../DataSource/FavoritesApiDataSource';
import { CreateFavoriteItemType } from '../Operations/Mutations/CreateFavorite.mutation';

export const createFavoriteRepository = async (
  workspaceId: string,
  item: CreateFavoriteItemType,
) => {
  const response = await createFavoriteData(workspaceId, item);

  if (response?.data) {
    const { link, folder, desktopApp } = response.data.createFavorite.favorite;

    if (link) {
      database.desktopLinks.update(link.id, {
        accountFavorite: link.accountFavorite,
        'sourceData.accountFavorite': link.accountFavorite,
      });
    }
    if (folder) {
      database.desktopFolders.update(folder.id, {
        accountFavorite: folder.accountFavorite,
        'sourceData.accountFavorite': folder.accountFavorite,
      });
    }
    if (desktopApp) {
      database.desktopApps.update(desktopApp.id, {
        accountFavorite: desktopApp.accountFavorite,
        'sourceData.accountFavorite': desktopApp.accountFavorite,
      });
    }
  }

  return response;
};

export const deleteFavoriteRepository = async (
  workspaceId: string,
  itemId: string,
) => {
  const response = await deleteFavoriteData(workspaceId, itemId);

  if (response?.data) {
    const { link, folder, desktopApp } =
      response.data.deleteItemFavorite.favorite;

    if (link) {
      database.desktopLinks.update(link.id, {
        accountFavorite: link.accountFavorite,
        'sourceData.accountFavorite': link.accountFavorite,
      });
    }
    if (folder) {
      database.desktopFolders.update(folder.id, {
        accountFavorite: folder.accountFavorite,
        'sourceData.accountFavorite': folder.accountFavorite,
      });
    }
    if (desktopApp) {
      database.desktopApps.update(desktopApp.id, {
        accountFavorite: desktopApp.accountFavorite,
        'sourceData.accountFavorite': desktopApp.accountFavorite,
      });
    }
  }

  return response;
};
