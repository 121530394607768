import { DesktopFragmentFields } from '../Desktop/Desktop.fragments';

export const ChatConferenceFields = `
    id
    _id
    jwtToken
    chatConversationIri
    callType
    status
    limit
    seenBy
    createdAt
    lastStartedAt
    statistics
    desktop {
      ${DesktopFragmentFields}
    }
`;

export const ScheduledConferenceFields = `
  _id
  id
  startAt
  endAt
  title
  description
  sequence
  timezone
  membersOverridden
  repeatingScheduleChatConference {
    id
  }
  chatConference {
    id
  }
  createdBy {
    id
  }
`;

export const ScheduledConferenceMembersFields = `
  id
  email
  icalendarUrl
  account {
    id
    _id
    firstName
    lastName
    email
    phoneNumber
    invitedBy
    onCall
    image {
          id
          contentUrl
        }
  }
`;
