import React, { FC, useCallback } from 'react';
import {
  CustomIntegrationBodyWrapper,
  CustomIntegrationFormBody,
  CustomIntegrationFormControls,
  CustomIntegrationHeader,
} from '../CustomIntegrationView.styled';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import {
  AppIntegrationsCategories,
  CustomIntegrationBotFormValues,
  IntegrationClientFullApiType,
} from '../../../../AppIntegrations.types';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../../../Workspace/Workspace.constants';
import { useEditIntegrationClientMutation } from '../../../../AppIntegrations.hooks';
import { GraphQLError } from 'graphql';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import { CustomIntegrationBotFields } from './CustomIntegrationBotFields';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { CustomIntegrationDocumentationLink } from '../../CustomIntegrationsDocumentationLink';
import { AppIntegrationsTranslation } from '../../../../i18n';
import { getShortId } from '../../../../../../shared/utils/id';

interface CustomIntegrationBotProps {
  integrationClient: IntegrationClientFullApiType;
}

export const CustomIntegrationBot: FC<CustomIntegrationBotProps> = ({
  integrationClient,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [editIntegrationClient] = useEditIntegrationClientMutation();

  const CustomIntegrationBotSchema: Schema<CustomIntegrationBotFormValues> =
    Yup.object().shape({
      botName: Yup.string().required(
        AppIntegrationsTranslation.appCustomIntegrationBotNameRequired,
      ),
      botImage: Yup.string(),
    });

  const initialValues: CustomIntegrationBotFormValues = {
    botName: integrationClient?.bot?.firstName,
    botImage: integrationClient?.bot?.image?.id || '',
  };

  const handleSubmit = useCallback(
    (formValues: CustomIntegrationBotFormValues) => {
      const { botName, botImage } = formValues;

      return editIntegrationClient({
        variables: {
          input: {
            id: integrationClient.id,
            name: integrationClient.name,
            botName,
            botImage,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(
            AppIntegrationsTranslation.appCustomIntegrationBotUpdated,
          );
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [editIntegrationClient, integrationClient],
  );

  return (
    <CustomIntegrationBodyWrapper>
      <CustomIntegrationHeader>
        <CustomIntegrationDocumentationLink />
      </CustomIntegrationHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CustomIntegrationBotSchema}>
        {({ isSubmitting, setFieldValue, dirty, errors, touched }) => (
          <Form data-testid="custom-integration-bot-form">
            <CustomIntegrationFormBody>
              <CustomIntegrationBotFields
                integrationClient={integrationClient}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
              />
            </CustomIntegrationFormBody>

            <CustomIntegrationFormControls>
              <Button
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                className="cancel-button"
                onClick={() =>
                  navigate({
                    pathname: generatePath(APPS_INTEGRATIONS_PATHNAME, {
                      workspaceId: getShortId(currentWorkspace.id),
                    }),
                    search: getQueryParamsFrom({
                      ...queryParams,
                      integrationsCategory: AppIntegrationsCategories.custom,
                    }),
                  })
                }
                data-testid="cancel-button">
                <FormattedMessage
                  id={
                    AppIntegrationsTranslation.appCustomIntegrationCancelButton
                  }
                />
              </Button>
              <Button
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={!dirty || isSubmitting}
                data-testid="submit-button">
                <FormattedMessage
                  id={AppIntegrationsTranslation.appCustomIntegrationSaveButton}
                />
              </Button>
            </CustomIntegrationFormControls>
          </Form>
        )}
      </Formik>
    </CustomIntegrationBodyWrapper>
  );
};
