import styled from 'styled-components';

export const DropboxErrorPageStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.Surface};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5;

  button {
    margin-top: 1rem;
  }
`;
