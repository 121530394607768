import { gql } from '@apollo/client';
import { WorkspaceDomainApiType } from './Domain.types';

export const GET_WORKSPACE_DOMAINS = gql`
  query WorkspaceDomains($workspace: String) {
    workspaceDomains(workspace: $workspace) {
      edges {
        node {
          id
          domain
          enabled
          _id
          createdAt
          modifiedAt
        }
      }
    }
  }
`;

export interface WorkspaceDomainsVariables {
  workspace: string;
}

export interface WorkspaceDomainsResponse {
  workspaceDomains: {
    edges: Array<{
      node: WorkspaceDomainApiType;
    }>;
  };
}
