import { getApolloClient } from '../../../Api/Api.utils';
import {
  EDIT_CUSTOM_APP,
  EditCustomAppResponse,
  EditCustomAppVariables,
} from '../../AppStore.mutations';

export const editCustomAppByIdGraphQLApi = (
  variables: EditCustomAppVariables,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<EditCustomAppResponse, EditCustomAppVariables>({
      mutation: EDIT_CUSTOM_APP,
      variables: {
        input: variables.input,
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data);
};
