import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import _get from 'lodash/get';
import { ErrorMessage, FormInputLabel, Input, StyledFormInput } from '../Input';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { IconProps } from '../../icons/icon.types';

const INPUT_WIDTH_OFFSET = 32;

const InputWithTextContainer = styled.div`
  position: relative;
`;

const FormInputText = styled.div`
  position: absolute;
  right: 16px;
  bottom: 12px;
  font-size: 0.875rem;
  font-family: ${p => p.theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface InputWithTextProps {
  text?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  inlineError?: boolean;
  displayErrors?: boolean;
  maxLength?: number;
  labelIcon?: FC<IconProps> | null;
}

export const FormInputWithText: FC<FieldProps & InputWithTextProps> = ({
  text,
  field,
  form,
  label,
  labelIcon: Icon,
  className,
  inlineError = false,
  displayErrors = true,
  maxLength = 255,
  ...props
}) => {
  const sideTextRef = useRef<any>();
  const [inputTextWidth, setInputTextWidth] = useState(100);

  useEffect(() => {
    if (sideTextRef.current && sideTextRef.current.offsetWidth) {
      setInputTextWidth(sideTextRef.current.offsetWidth);
    }
  }, [sideTextRef]);

  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  return (
    <StyledFormInput
      className={className}
      invalid={displayErrors && !!errorIdToDisplay}
      inlineError={inlineError}>
      {label && (
        <FormInputLabel htmlFor={field.name}>
          <div>{label}</div>
          {Icon && <Icon />}
        </FormInputLabel>
      )}
      <InputWithTextContainer>
        {text && <FormInputText ref={sideTextRef}>{text}</FormInputText>}
        <Input
          id={field.name}
          maxLength={maxLength}
          {...field}
          {...props}
          style={{
            paddingRight: inputTextWidth + INPUT_WIDTH_OFFSET,
          }}
        />
      </InputWithTextContainer>
      {displayErrors && errorIdToDisplay && (
        <ErrorMessage inlineError={inlineError}>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormInput>
  );
};
