import { gql } from '@apollo/client';
import { FolderFragmentFields } from '../../../Folder.fragments';
import { FolderApiType } from '../../../Folder.types';

export const EDIT_FOLDER = gql`
  mutation EditFolder($input: editFolderInput!) {
    __typename
    editFolder(input: $input) {
      folder {
        ${FolderFragmentFields}
      }
    }
  }
`;

export interface EditFolderInputVariables {
  id: string;
  name: string;
  tags?: string[];
  desktop?: string | null;
  parent?: string | null;
}

export interface EditFolderVariables {
  input: EditFolderInputVariables;
}

export interface EditFolderResponse {
  editFolder: {
    folder: FolderApiType;
  };
}
