import React, { FC } from 'react';
import { DragSortItemType } from '../../Drag.types';
import { StyledDesktopPreview } from './DesktopPreview.styled';
import type { DesktopApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

export const DesktopPreview: FC<{ item: DragSortItemType }> = ({ item }) => {
  const desktop =
    'workspace' in item.payload ? null : (item.payload as DesktopApiType);

  if (!desktop) {
    return null;
  }

  return <StyledDesktopPreview>{desktop.name}</StyledDesktopPreview>;
};
