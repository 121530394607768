import React, { FC } from 'react';
import { DrawerModal, ModalProps } from '../../../../shared/components/Modal';
import { ModalHeader } from './ModalHeader';
import { PasswordsPage } from '../PasswordsPage/PasswordsPage';
import { VaultApiType } from '../../Encryption.types';
import { PasswordsSegmentTitleBar } from '../PasswordsSegmentTitleBar/PasswordsSegmentTitleBar';

interface PasswordsPageModalProps extends ModalProps {
  vault: VaultApiType;
}

export const PasswordsPageModal: FC<PasswordsPageModalProps> = ({
  vault,
  visible,
  onRequestClose,
}) => {
  return (
    <DrawerModal visible={visible} onRequestClose={onRequestClose} width={540}>
      <ModalHeader onRequestClose={onRequestClose}>
        <PasswordsSegmentTitleBar vault={vault} hideMenuButton />
      </ModalHeader>
      <PasswordsPage />
    </DrawerModal>
  );
};
