import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router-dom';
import { CHATS_CONVERSATION_PATHNAME } from '../../../Desktop/Desktop.constants';
import { GlobalSearchTranslation } from '../../i18n';
import { Checkbox } from '../../../../shared/components/Checkbox';
import { StyledSearchResultCheckbox } from './SearchResult.styled';
import { makeChatConversationIri } from '../../../Chat/Chat.utils';
import {
  useFindChatConversationById,
  useSearchOnlyByConversationName,
} from './SearchOnlyByConversation.hooks';
import { useGlobalSearchContext } from '../../GlobalSearch.hooks';

interface SearchOnlyByConversationProps {
  setConversationId: (conversation: string) => void;
  checked: boolean;
}

export const SearchOnlyByConversation: FC<SearchOnlyByConversationProps> = memo(
  ({ setConversationId, checked }) => {
    const { pathname } = useLocation();
    const { searchOnlyByConversation, setSearchOnlyByConversation } =
      useGlobalSearchContext();

    const currentConversationId = useMemo(() => {
      const match = matchPath(CHATS_CONVERSATION_PATHNAME, pathname);
      if (!match) {
        return undefined;
      }

      const { conversationId } = match.params;
      return makeChatConversationIri(conversationId as string);
    }, [pathname]);

    const chatConversation = useFindChatConversationById(currentConversationId);

    const conversationTitle = useSearchOnlyByConversationName(
      currentConversationId,
    );

    useEffect(() => {
      if (!searchOnlyByConversation || !chatConversation?.id) return;
      setConversationId(chatConversation.id);
    }, [chatConversation, searchOnlyByConversation, setConversationId]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!chatConversation) {
          return;
        }

        setConversationId(e.target.checked ? chatConversation.id : '');

        if (searchOnlyByConversation) {
          setSearchOnlyByConversation(!searchOnlyByConversation);
        }
      },
      [
        chatConversation,
        searchOnlyByConversation,
        setConversationId,
        setSearchOnlyByConversation,
      ],
    );

    return conversationTitle ? (
      <StyledSearchResultCheckbox>
        <Checkbox
          size={16}
          data-testid="password-generator-uppercase"
          name="uppercase"
          checked={checked}
          onChange={handleChange}>
          <FormattedHTMLMessage
            id={GlobalSearchTranslation.searchOnlyInSpecificChannel}
            values={{
              channel: conversationTitle,
            }}
          />
        </Checkbox>
      </StyledSearchResultCheckbox>
    ) : null;
  },
);
