import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import { TeamApiType } from '../types/Team.types';

export const getTeamsApi = async (
  workspaceId: string,
): Promise<RestApiClientResponse<TeamApiType[]> | undefined> => {
  const shortWorkspaceId = getShortId(workspaceId);
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient!.fetch(`/workspace/${shortWorkspaceId}/workspace-teams`);
};

export const getTeamApi = async (
  teamId: string,
): Promise<RestApiClientResponse<TeamApiType> | undefined> => {
  const shortTeamId = getShortId(teamId);
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient!.fetch(`/workspace-team/${shortTeamId}`);
};
