import React, { FC, PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { StyledAppLinkSortableWrapper } from './AppLinkSortableWrapper.styled';

interface AppLinkSortableWrapperProps extends PropsWithChildren {
  appId: string;
  appName: string;
  appStoreAppId: string;
}

export const AppLinkSortableWrapper: FC<AppLinkSortableWrapperProps> = ({
  children,
  appId,
  appName,
  appStoreAppId,
}) => {
  // dnd
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: appId, data: { appName, appStoreAppId } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <StyledAppLinkSortableWrapper
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      {children}
    </StyledAppLinkSortableWrapper>
  );
};
