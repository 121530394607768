import { LinkApiType } from '../Link/Link.types';

export type ExternalServiceAuthContextType = {
  addLinkWithAuthUrl: (data: LinkApiType) => void;
};

export enum ExternalServiceAuthType {
  google = 'google',
  jira = 'jira',
  external = 'external service',
}

export enum GoogleTypes {
  GOOGLE_DRIVE = 'gdrive',
  GOOGLE_DOCUMENT = 'document',
  GOOGLE_SPREADSHEETS = 'spreadsheets',
  GOOGLE_PRESENTATION = 'presentation',
  GOOGLE_FOLDER = 'gdrive-folder',
}
