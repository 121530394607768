import React, { FC, useCallback, useMemo } from 'react';
import {
  useCreateMultipleVaultAccessMutation,
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useEditPasswordRecoveryRequest,
  useGetPasswordRecoveryRequestQuery,
  useGetVaultAccessesRecoveryRequestQuery,
} from '../Encryption.hooks';
import { MasterPasswordLockScreen } from '../MasterPasswordLockScreen';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { extractNodes } from '../../../shared/api/api.utils';
import { getAccountKeyset } from '../Encryption.utils';
import { EncryptionTranslation } from '../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  ReencryptVaultAccessesControlsSection,
  ReencryptVaultAccountInfo,
} from './ReencryptVaultAccesses.styled';
import { AccountApiType } from '../../User/User.types';
import {
  PasswordRecoveryRequestStatus,
  VaultAccessApiType,
} from '../Encryption.types';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../shared/components/ImportantMessage';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { useConfirm } from '../../../shared/components/Modal';
import { getAccountName } from '../../User/User.utils';
import { Spinner } from '../../../shared/components/Spinner';
import { ReencryptVaultAccessesControls } from './ReencryptVaultAccessesControls';
import {
  importPublicKey,
  reencryptVaultAccessKey,
} from '../Encryption.crypto.utils';

interface ReencryptVaultAccessesProps {
  reencryptVaultAccessesRequestId: string;
  onDone: () => void;
}

export const ReencryptVaultAccesses: FC<ReencryptVaultAccessesProps> = ({
  reencryptVaultAccessesRequestId,
}) => {
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const intl = useIntl();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const { account } = useCurrentWorkspaceAccount();
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id || '';

  const {
    data: passwordRecoveryRequestData,
    loading: loadingRecoveryRequestData,
  } = useGetPasswordRecoveryRequestQuery({
    skip: !privateKey || !reencryptVaultAccessesRequestId,
    variables: {
      id: '/password-recovery-requests/' + reencryptVaultAccessesRequestId,
    },
    fetchPolicy: 'network-only',
  });
  const passwordRecoveryRequest =
    passwordRecoveryRequestData?.passwordRecoveryRequest;
  const passwordRecoveryRequestId = passwordRecoveryRequest?.id;

  const recoveryUserKeysetIdsSet = useMemo(() => {
    const keysets = extractNodes(passwordRecoveryRequest?.requestedBy.keysets);
    const keysetIds = keysets.map(keyset => keyset.id);
    return new Set(keysetIds);
  }, [passwordRecoveryRequest]);

  const { data: vaultAccessesData, loading: loadingVaultAccessesData } =
    useGetVaultAccessesRecoveryRequestQuery({
      skip: !account || !privateKey || !passwordRecoveryRequestId,
      variables: {
        recoveryRequest: passwordRecoveryRequestId!,
      },
      fetchPolicy: 'network-only',
    });

  const vaultAccesses = useMemo(() => {
    return extractNodes(vaultAccessesData?.vaultAccesses);
  }, [vaultAccessesData]);

  const ownersAccessesMap: Record<string, VaultAccessApiType> = useMemo(() => {
    const implicitlySharedAccessesMap = vaultAccesses
      .filter(
        access =>
          access.isImplicitlyShared &&
          access.vault &&
          access.keyset.id === keysetId,
      )
      .reduce((acc, curr) => ({ ...acc, [curr.vault!.id]: curr }), {});

    // covers the cases when owner shared his own credential with the user and now have to restore access
    const ownersOwnParentAccesses = vaultAccesses
      .filter(access => access?.parent?.keyset?.id === keysetId)
      .map(access => ({
        ...access.parent,
        vault: { id: access.vault!.id },
        parent: null,
      }))
      .reduce((acc, curr) => ({ ...acc, [curr.vault!.id]: curr }), {});

    return { ...implicitlySharedAccessesMap, ...ownersOwnParentAccesses };
  }, [keysetId, vaultAccesses]);

  const accessesToReencrypt: VaultAccessApiType[] = useMemo(() => {
    return vaultAccesses.filter(
      access =>
        !access.isImplicitlyShared &&
        access.vault &&
        recoveryUserKeysetIdsSet.has(access.keyset.id) &&
        ownersAccessesMap[access.vault.id],
    );
  }, [vaultAccesses, recoveryUserKeysetIdsSet, ownersAccessesMap]);

  const newUserKeyset = useMemo(() => {
    if (passwordRecoveryRequest?.requestedBy) {
      return getAccountKeyset(
        passwordRecoveryRequest.requestedBy as AccountApiType,
      );
    }
    return null;
  }, [passwordRecoveryRequest]);

  const [createMultipleVaultAccessMutation] =
    useCreateMultipleVaultAccessMutation();
  const [editPasswordRecoveryRequest] = useEditPasswordRecoveryRequest();
  const reencryptAccessKeys = useCallback(() => {
    if (!newUserKeyset || !passwordRecoveryRequestId) {
      return Promise.resolve();
    }
    return importPublicKey(newUserKeyset.pubKey)
      .then(newUserPublicKey => {
        return Promise.all(
          accessesToReencrypt.map(access => {
            const implicitlySharedKey =
              ownersAccessesMap[access.vault!.id].vaultKeyEncrypted;
            return reencryptVaultAccessKey(
              implicitlySharedKey,
              privateKey,
              newUserPublicKey,
            );
          }),
        );
      })
      .then(reencryptedKeys =>
        accessesToReencrypt
          .map((access, index) => ({
            workspace: workspaceId,
            vault: access.vault!.id,
            keyset: newUserKeyset.id,
            parent: access.parent?.id,
            vaultKeyEncrypted: reencryptedKeys[index] as string,
          }))
          .filter(access => !!access.vaultKeyEncrypted),
      )
      .then(newVaultAccesses =>
        createMultipleVaultAccessMutation({
          variables: {
            input: {
              vaultAccesses: newVaultAccesses,
            },
          },
        }),
      )
      .then(() =>
        editPasswordRecoveryRequest({
          variables: {
            input: {
              id: passwordRecoveryRequestId,
              status: PasswordRecoveryRequestStatus.ACCEPTED,
            },
          },
        }),
      )
      .catch(e => showToastGraphQLErrors(e.graphQLErrors));
  }, [
    newUserKeyset,
    passwordRecoveryRequestId,
    accessesToReencrypt,
    ownersAccessesMap,
    privateKey,
    workspaceId,
    createMultipleVaultAccessMutation,
    editPasswordRecoveryRequest,
  ]);

  const { askConfirmation } = useConfirm();
  const handleRecoverAccessButtonClick = useCallback(() => {
    return askConfirmation(
      intl.formatMessage({
        id: EncryptionTranslation.reencryptAccessesAcceptConfirmationMessage,
      }),
    ).then(confirm => {
      if (confirm) {
        return reencryptAccessKeys();
      }
    });
  }, [reencryptAccessKeys, askConfirmation, intl]);

  const declineRequest = useCallback(() => {
    if (!passwordRecoveryRequestId) {
      return Promise.resolve();
    }
    return editPasswordRecoveryRequest({
      variables: {
        input: {
          id: passwordRecoveryRequestId,
          status: PasswordRecoveryRequestStatus.DECLINED,
        },
      },
    }).catch(e => showToastGraphQLErrors(e.graphQLErrors));
  }, [editPasswordRecoveryRequest, passwordRecoveryRequestId]);

  if (!privateKey) {
    return (
      <MasterPasswordLockScreen
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  if (loadingRecoveryRequestData || loadingVaultAccessesData) {
    return <Spinner containerHeight={200} />;
  }

  return (
    <div>
      <span data-testid="recovery-request-description">
        <FormattedHTMLMessage
          id={EncryptionTranslation.reencryptAccessesDescription}
        />
      </span>
      <ReencryptVaultAccountInfo>
        <FormattedHTMLMessage
          id={EncryptionTranslation.reencryptAccessesAccountInfo}
          values={{
            fullName:
              passwordRecoveryRequest &&
              getAccountName(
                passwordRecoveryRequest?.requestedBy as AccountApiType,
              ),
            email: passwordRecoveryRequest?.requestedBy?.email,
          }}
        />
      </ReencryptVaultAccountInfo>

      <ReencryptVaultAccessesControlsSection>
        {passwordRecoveryRequest?.status ===
          PasswordRecoveryRequestStatus.ACCEPTED && (
          <ImportantMessage type={ImportantMessageType.WARNING}>
            <FormattedMessage
              id={EncryptionTranslation.reencryptAccessesRequestAcceptedMessage}
            />
          </ImportantMessage>
        )}

        {passwordRecoveryRequest?.status ===
          PasswordRecoveryRequestStatus.DECLINED && (
          <ImportantMessage type={ImportantMessageType.CRITICAL}>
            <FormattedMessage
              id={EncryptionTranslation.reencryptAccessesRequestDeclinedMessage}
            />
          </ImportantMessage>
        )}

        {passwordRecoveryRequest?.status ===
          PasswordRecoveryRequestStatus.PENDING && (
          <ReencryptVaultAccessesControls
            onAccept={handleRecoverAccessButtonClick}
            onDecline={declineRequest}
          />
        )}
      </ReencryptVaultAccessesControlsSection>
    </div>
  );
};
