import React, { FC, useRef } from 'react';
import { generatePath } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import {
  CrumbButton,
  CrumbLink,
  CrumbName,
  CrumbWrapper,
  StyledFolderCrumbs,
} from './FolderCrumbs.styled';
import { HomeIcon, KebabIcon, SubfolderIcon } from '../../../../shared/icons';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { FolderTranslation } from '../../i18n';
import {
  FOLDER_PATHNAME,
  FolderCrumbsLocationContext,
} from '../../Folder.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../shared/utils/id';
import { useIntl } from 'react-intl';
import { useCurrentTypeOfPage } from '../../../Desktop/Desktop.utils';
import {
  DesktopTab,
  FAVORITES_TAB_PATHNAME,
} from '../../../Desktop/Desktop.constants';
import { FolderCrumbsViewModel } from './ViewModel';
import type { DesktopApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

export type injectedDataType = {
  desktop: DesktopApiType;
  folderId?: string;
};

export type FolderCrumbsProps = {
  locationContext: FolderCrumbsLocationContext;
  injectedData: injectedDataType;
};

export const FolderCrumbs: FC<FolderCrumbsProps> = ({
  locationContext,
  injectedData,
}) => {
  const { formatMessage } = useIntl();
  const { workspace } = useCurrentWorkspace();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  const FolderCrumbsButtonRef = useRef<HTMLButtonElement>(null);
  const folderCrumbsRef = useRef<HTMLDivElement>(null);

  const {
    handleRootClick,
    handleFolderClick,
    alwaysShownFolders,
    foldersInMenu,
    folders,
    hasInjectedData,
    queryParams,
  } = FolderCrumbsViewModel(locationContext, injectedData);

  return (
    <>
      <StyledFolderCrumbs ref={folderCrumbsRef} data-testid="folder-crumbs">
        {folders.length >= 1 && (
          <CrumbWrapper isFirstCrumb={true} isButton={true}>
            {hasInjectedData ? (
              <SubfolderIcon className="injectedIcon" width={14} height={14} />
            ) : (
              <CrumbButton
                data-testid="folder-nav-home-button"
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: FolderTranslation.desktopRootFolderLabel,
                })}
                data-tooltip-place={TooltipPlace.top}
                onClick={handleRootClick}>
                <HomeIcon width={14} height={14} />
              </CrumbButton>
            )}
          </CrumbWrapper>
        )}

        {foldersInMenu.length > 0 && (
          <CrumbWrapper isButton={true}>
            <CrumbButton ref={FolderCrumbsButtonRef}>
              <KebabIcon width={14} height={14} />
            </CrumbButton>
            <Menu
              trigger={FolderCrumbsButtonRef}
              maxHeight={300}
              vAlign={VerticalAlignment.BOTTOM}
              hAlign={HorizontalAlignment.LEFT}
              width="auto">
              {foldersInMenu.map(folder => (
                <MenuItem
                  key={folder.id}
                  icon={() => <SubfolderIcon width={14} height={14} />}
                  onClick={() => {
                    handleFolderClick(folder);
                  }}>
                  {folder!.name}
                </MenuItem>
              ))}
            </Menu>
          </CrumbWrapper>
        )}

        {folders.length > 0 &&
          alwaysShownFolders.length &&
          alwaysShownFolders.map((folder, index) => (
            <CrumbWrapper
              key={folder.id}
              data-tooltip-id="global-tooltip"
              data-tooltip-content={folder.name}
              data-tooltip-place={TooltipPlace.top}>
              {index === alwaysShownFolders.length - 1 ? (
                <>
                  {hasInjectedData ? (
                    <CrumbLink
                      to={{
                        pathname: isFavoritesDesktop
                          ? generatePath(FAVORITES_TAB_PATHNAME, {
                              workspaceId: getShortId(workspace.id),
                              tab: DesktopTab.links,
                            })
                          : generatePath(FOLDER_PATHNAME, {
                              workspaceId: getShortId(workspace.id),
                              desktopId: getShortId(injectedData!.desktop.id),
                            }),
                        search: getQueryParamsFrom({
                          ...queryParams,
                          folderId: getShortId(folder.id),
                          linkDetails: undefined,
                        }),
                      }}>
                      {folder.name}
                    </CrumbLink>
                  ) : (
                    <CrumbName>{folder.name}</CrumbName>
                  )}
                </>
              ) : (
                <CrumbLink
                  to={{
                    pathname: isFavoritesDesktop
                      ? generatePath(FAVORITES_TAB_PATHNAME, {
                          workspaceId: getShortId(workspace.id),
                          tab: DesktopTab.links,
                        })
                      : generatePath(FOLDER_PATHNAME, {
                          workspaceId: getShortId(workspace.id),
                          desktopId: getShortId(injectedData!.desktop.id),
                        }),
                    search: getQueryParamsFrom({
                      ...queryParams,
                      folderId: getShortId(folder.id),
                      linkDetails: undefined,
                    }),
                  }}>
                  {folder.name}
                </CrumbLink>
              )}
            </CrumbWrapper>
          ))}
      </StyledFolderCrumbs>
    </>
  );
};
