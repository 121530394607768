import { useContext, useEffect, useMemo } from 'react';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_CHAT_CONVERSATION_INVITATIONS,
  GET_CHAT_LINK,
  GET_CHAT_MESSAGES,
  GetChatConversationInvitationsResponse,
  GetChatConversationInvitationsVariables,
  GetChatLinkResponse,
  GetChatLinkVariables,
  GetChatMessagesResponse,
  GetChatMessagesVariables,
  SEARCH_CHAT_CONVERSATIONS,
  SearchChatConversationsResponse,
  SearchChatConversationsVariables,
} from './Chat.queries';
import {
  CREATE_CHAT_CONVERSATION,
  CreateChatConversationResponse,
  CreateChatConversationVariables,
  DELETE_CHAT_LINK,
  DeleteChatLinkResponse,
  DeleteChatLinkVariables,
  ENABLE_CHAT_FOR_DESKTOP,
  EnableChatForDesktopResponse,
  EnableChatForDesktopVariables,
} from './Chat.mutations';
import { ChatContext, ChatSearchMessageContext } from './Chat.context';
import { ChatConversationInternalType, ConversationType } from './Chat.types';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../Workspace/Workspace.hooks';
import { useAccountsContext } from '../Account';
import {
  getAccountName,
  isAccountGuest,
  isBotAccount,
} from '../User/User.utils';
import { CHAT_ID_PATHNAME, DEFAULT_GROUP_CHAT_NAME } from './Chat.constants';
import { sortMembersByAvailabilityAndName } from './MembersList/MembersList.utils';
import _orderBy from 'lodash/orderBy';
import { useRefreshableQuery } from '../Api/Api.hooks';
import { useIntl } from 'react-intl';
import { ChatTranslation } from './i18n';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../shared/hooks';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { getShortId } from '../../shared/utils/id';
import { usePreviewSegment } from '../PreviewSegment/PreviewSegment.hooks';
import { DESKTOP_ROOT_PATHNAME } from '../Desktop/Desktop.constants';
import { getConversationIDDB } from './Data/DataSource/ChatConversation/ChatConversationsIndexedDBSource';

export const useChatMessagesQuery = (
  options?: QueryHookOptions<GetChatMessagesResponse, GetChatMessagesVariables>,
) =>
  useRefreshableQuery<GetChatMessagesResponse, GetChatMessagesVariables>(
    GET_CHAT_MESSAGES,
    options,
  );

export const useCurrentConversation = () => {
  return useContext(ChatContext);
};

export const useSearchMessageData = () => {
  return useContext(ChatSearchMessageContext);
};

export const useCreateChatConversationMutation = () =>
  useMutation<CreateChatConversationResponse, CreateChatConversationVariables>(
    CREATE_CHAT_CONVERSATION,
  );

export const useSearchChatConversationsQuery = (
  options?: QueryHookOptions<
    SearchChatConversationsResponse,
    SearchChatConversationsVariables
  >,
) =>
  useQuery<SearchChatConversationsResponse, SearchChatConversationsVariables>(
    SEARCH_CHAT_CONVERSATIONS,
    options,
  );
export const useChatConversationTitle = (
  chatConversation?: ChatConversationInternalType,
) => {
  const { account } = useCurrentWorkspaceAccount();
  const otherParticipants = useMemo(() => {
    if (
      !chatConversation ||
      chatConversation.type === ConversationType.desktop
    ) {
      return [];
    }

    if (chatConversation.userIds.length === 1) {
      return chatConversation.userIds;
    }

    return chatConversation.userIds.filter(id => id !== account.id);
  }, [account.id, chatConversation]);
  const { accountsWithAvailability } = useAccountsContext();
  const otherParticipantNames = useMemo(
    () =>
      otherParticipants
        .map(id => accountsWithAvailability[id])
        .filter(Boolean)
        .map(participant => getAccountName(participant, true))
        .concat(chatConversation ? chatConversation.pendingEmails : [])
        .join(', '),
    [accountsWithAvailability, chatConversation, otherParticipants],
  );

  if (!chatConversation) {
    return null;
  }

  if (
    [ConversationType.group, ConversationType.pending].includes(
      chatConversation.type,
    ) &&
    (!chatConversation.title ||
      chatConversation.title === DEFAULT_GROUP_CHAT_NAME)
  ) {
    return otherParticipantNames;
  }

  return [
    ConversationType.desktop,
    ConversationType.group,
    ConversationType.pending,
    ConversationType.link,
  ].includes(chatConversation.type)
    ? chatConversation.title
    : otherParticipantNames;
};

export const useChatLinkQuery = (
  options?: QueryHookOptions<GetChatLinkResponse, GetChatLinkVariables>,
) =>
  useQuery<GetChatLinkResponse, GetChatLinkVariables>(GET_CHAT_LINK, options);

export const useAllWorkspaceAccountsSorted = () => {
  const { account } = useCurrentWorkspaceAccount();

  const { accountsWithAvailability } = useAccountsContext();

  const workspaceMembers = useMemo(() => {
    return _orderBy(
      Object.values(accountsWithAvailability)
        .filter(account => !isAccountGuest(account))
        .sort((a, b) =>
          sortMembersByAvailabilityAndName(a, b, accountsWithAvailability),
        ),
      [
        item => {
          return item?.firstName?.toLowerCase();
        },
      ],
      ['asc'],
    );
  }, [accountsWithAvailability]);

  const workspaceGuests = useMemo(() => {
    return _orderBy(
      Object.values(accountsWithAvailability)
        .filter(account => isAccountGuest(account))
        .sort((a, b) =>
          sortMembersByAvailabilityAndName(a, b, accountsWithAvailability),
        ),
      [
        item => {
          return item.firstName.toLowerCase();
        },
      ],
      ['asc'],
    );
  }, [accountsWithAvailability]);

  return useMemo(
    () =>
      [...workspaceMembers, ...workspaceGuests].filter(
        item => item.id !== account.id,
      ),
    [account.id, workspaceGuests, workspaceMembers],
  );
};

export const useGroupedSortedAccounts = () => {
  const intl = useIntl();
  const { account: workspaceAccount } = useCurrentWorkspaceAccount();

  const { accountsWithAvailability } = useAccountsContext();

  const accounts = useMemo(
    () =>
      Object.values(accountsWithAvailability)
        .filter(acc => !isBotAccount(acc))
        .filter(acc => acc.id !== workspaceAccount?.id)
        .sort((a, b) =>
          sortMembersByAvailabilityAndName(a, b, accountsWithAvailability),
        ),
    [accountsWithAvailability, workspaceAccount?.id],
  );

  const workspaceMembers = useMemo(() => {
    return _orderBy(
      accounts.filter(
        account =>
          account.workspaceCache?.[account.id]?.active &&
          !isAccountGuest(account),
      ),
      [
        item => {
          return item.firstName.toLowerCase();
        },
      ],
      ['asc'],
    );
  }, [accounts]);

  const workspaceGuests = useMemo(() => {
    return _orderBy(
      accounts.filter(
        account =>
          account.workspaceCache?.[account.id]?.active &&
          isAccountGuest(account),
      ),
      [
        item => {
          return item.firstName.toLowerCase();
        },
      ],
      ['asc'],
    );
  }, [accounts]);

  return useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: ChatTranslation.usersSelectGroupTypeMembers,
        }),
        options: workspaceMembers,
      },
      {
        label: intl.formatMessage({
          id: ChatTranslation.usersSelectGroupTypeGuests,
        }),
        options: workspaceGuests,
      },
    ],
    [intl, workspaceGuests, workspaceMembers],
  );
};

export const useEnableChatForDesktopMutation = () =>
  useMutation<EnableChatForDesktopResponse, EnableChatForDesktopVariables>(
    ENABLE_CHAT_FOR_DESKTOP,
  );

export const useChatConversationInvitations = (
  options?: QueryHookOptions<
    GetChatConversationInvitationsResponse,
    GetChatConversationInvitationsVariables
  >,
) =>
  useQuery<
    GetChatConversationInvitationsResponse,
    GetChatConversationInvitationsVariables
  >(GET_CHAT_CONVERSATION_INVITATIONS, options);

export const useDeleteChatLinkMutation = () =>
  useMutation<DeleteChatLinkResponse, DeleteChatLinkVariables>(
    DELETE_CHAT_LINK,
  );

/**
 * Redirect chats logic
 */
export const useRedirectChats = (
  conversations: ChatConversationInternalType[],
  conversationId?: string,
) => {
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canAccessChatPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { isPreviewMode } = usePreviewSegment();

  useEffect(() => {
    if (isPreviewMode) {
      return;
    }

    if (permissionsLoaded && !canAccessChatPage) {
      navigate(
        generatePath(DESKTOP_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
        { replace: true },
      );
    } else if (!conversationId) {
      const sortedConversations = _orderBy(
        conversations
          .filter(conversation => !conversation.isHiddenAt)
          .map(chat => {
            return {
              ...chat,
              sortDate: chat.lastMessageAt || chat.createdAt,
            };
          }),
        ['sortDate'],
        ['desc'],
      );

      if (sortedConversations.length) {
        getConversationIDDB(sortedConversations[0].id).then(
          cachedConversation => {
            if (!cachedConversation?.isHidden) {
              navigate(
                {
                  pathname: generatePath(CHAT_ID_PATHNAME, {
                    workspaceId: getShortId(workspace.id),
                    chatId: getShortId(sortedConversations[0].id),
                  }),
                  search: getQueryParamsFrom(queryParams),
                },
                {
                  replace: true,
                },
              );
            }
          },
        );
      }
    }
  }, [
    conversationId,
    conversations,
    navigate,
    queryParams,
    isPreviewMode,
    workspace,
    permissionsLoaded,
    canAccessChatPage,
  ]);
};
