import { gql } from '@apollo/client';
import { ApiConnection, PageInfoApiType } from '../../shared/api/api.types';
import { AppIntegrationApiType } from '../AppStore/AppStore.types';
import { LinkDocumentMetaData } from '../Link/Link.types';

export const GET_APP_INTEGRATION_LIST = gql`
  query GetAppIntegrationList(
    $filterOnlyAccessible: Boolean
    $workspace: String!
    $active: Boolean
  ) {
    appIntegrations(
      filterOnlyAccessible: $filterOnlyAccessible
      workspace: $workspace
      active: $active
    ) {
      edges {
        node {
          id
          marketplaceItem {
            app {
              id
              name
              logo {
                contentUrl
              }
            }
            searchable
            appName
          }
        }
      }
    }
  }
`;

export interface GetAppIntegrationsVariables {
  filterOnlyAccessible?: boolean;
  workspace?: string;
  active: boolean;
}

export interface GetAppIntegrationsResponse {
  appIntegrations: ApiConnection<AppIntegrationApiType>;
}

export interface GlobalSearchVariables {
  workspace: string;
  query: string;
  includeDesktopAppHits?: boolean;
  includeChatMessageHits?: boolean;
  includeChatConversationHits?: boolean;
  includeLinkHits?: boolean;
  includeFolderHits?: boolean;
  includeRepeatingScheduleChatConferenceHits?: boolean;
  includeScheduleChatConferenceHits?: boolean;
  first: number;
  cursor?: string;
  chatConversation?: string;
}

export interface GlobalSearchResultAppHit {
  id: string;
  app: {
    id: string;
    groupAppId?: string;
    name: string;
    uri: string;
    loginUriPattern?: string;
    fullName: string;
    logo: {
      id: string;
      contentUrl: string;
    };
  };
  desktop: {
    id: string;
    name: string;
  };
}

export interface GlobalSearchResultLinkHit {
  id: string;
  customTitle?: string;
  desktop: {
    id: string;
  };
  folder: {
    id: string;
  };
  data: {
    metadata: LinkDocumentMetaData;
    id: string;
    name?: string;
    title?: string;
    url: string;
    icon?: {
      id: string;
      contentUrl: string;
    };
  };
}

export interface GlobalSearchResultFolderHit {
  id: string;
  name: string;
  desktop: {
    _id: string;
  };
}

export interface GlobalSearchResultChatConversationHit {
  id: string;
  type: string;
  conversationTitle?: string;
  users: string[];
  usersNames: string[];
  desktopIri?: string;
  linkIri?: string;
}

export interface GlobalSearchResultChatMessageHit {
  id: string;
  message: string;
  createdAt: string;
  conversationIri: string;
  parentChatMessageIri: string | null;
  accountIri?: string;
}

export interface GlobalSearchResultScheduleConference {
  id?: string;
  title?: string;
}

export interface GlobalSearchResultLinkCommentHit {
  id: string;
  message: string;
  createdAt: string;
  conversationIri: string;
}

export interface GlobalSearchResult {
  id: string;
  hitType: string;
  desktopAppHit?: GlobalSearchResultAppHit;
  linkHit?: GlobalSearchResultLinkHit;
  folderHit?: GlobalSearchResultFolderHit;
  chatMessageHit?: GlobalSearchResultChatMessageHit;
  chatConversationHit?: GlobalSearchResultChatConversationHit;
  linkCommentHit?: GlobalSearchResultLinkCommentHit;
  scheduleChatConferenceHit?: GlobalSearchResultScheduleConference;
  repeatingScheduleChatConferenceHit?: GlobalSearchResultScheduleConference;
}

export interface GlobalSearchResponse {
  listGlobalSearches: ApiConnection<GlobalSearchResult>;
  pageInfo: PageInfoApiType;
  totalCount: number;
}

export const GLOBAL_SEARCH = gql`
  query globalSearch(
    $query: String!
    $first: Int
    $cursor: String
    $workspace: ID!
    $includeDesktopAppHits: Boolean
    $includeChatMessageHits: Boolean
    $includeChatConversationHits: Boolean
    $includeLinkHits: Boolean
    $includeLinkCommentHits: Boolean
    $includeFolderHits: Boolean
    $includeRepeatingScheduleChatConferenceHits: Boolean
    $includeScheduleChatConferenceHits: Boolean
    $chatConversation: ID
  ) {
    listGlobalSearches(
      workspace: $workspace
      query: $query
      first: $first
      after: $cursor
      includeDesktopAppHits: $includeDesktopAppHits
      includeChatMessageHits: $includeChatMessageHits
      includeChatConversationHits: $includeChatConversationHits
      includeLinkHits: $includeLinkHits
      includeLinkCommentHits: $includeLinkCommentHits
      includeFolderHits: $includeFolderHits
      includeRepeatingScheduleChatConferenceHits: $includeRepeatingScheduleChatConferenceHits
      includeScheduleChatConferenceHits: $includeScheduleChatConferenceHits
      chatConversation: $chatConversation
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          hitType
          desktopAppHit {
            app {
              id
              name
              uri
              fullName
              logo {
                id
                contentUrl
              }
            }
            desktop {
              id
              name
            }
          }
          linkCommentHit {
            id
            message
            createdAt
            conversationIri
          }
          linkHit {
            id
            customTitle
            folder {
              id
            }
            desktop {
              id
            }
            data {
              id
              title
              url
              metadata
              icon {
                id
                contentUrl
              }
            }
          }
          chatMessageHit {
            id
            message
            createdAt
            conversationIri
            parentChatMessageIri
            accountIri
          }
          chatConversationHit {
            id
            type
            conversationTitle
            users
            usersNames
            lastMessageAt
            linkIri
            desktopIri
          }
          scheduleChatConferenceHit {
            id
            title
          }
          repeatingScheduleChatConferenceHit {
            id
            title
          }
          folderHit {
            id
            name
            desktop {
              _id
            }
          }
        }
      }
    }
  }
`;
