import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useSearchInTimelinesQuery } from '../Timeline.hooks';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { Spinner } from '../../../shared/components/Spinner';
import { TimelineEvent } from './TimelineEvent';
import {
  FilterCloseButton,
  FilterContainer,
  NoTimelineEventsMessage,
  SelectedDate,
  SelectedDateRangeFilter,
  TimelineEventsList,
} from './TimelineView.styled';
import { TimelineTranslation } from '../i18n';
import { mergeWithArray } from '../../../shared/utils/list.utils';
import { LoadMoreObserver } from '../../../shared/components/LoadMore';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { TimelineFilters } from './TimelineFilters';
import { getNextPage } from '../../../shared/utils/pagination.utils';
import { groupEventsByDay } from '../Timeline.utils';
import { SearchInTimelinesResponse } from '../Timeline.queries';
import { TimelineDay } from './TimelineDay';
import { CalendarIcon, CloseIcon } from '../../../shared/icons';
import {
  DATE_RANGE_FILTER_FORMAT,
  DATE_RANGE_FORMAT,
  TIMELINE_EVENTS_PAGE_SIZE,
} from '../Timeline.constants';
import { useCurrentDesktop } from '../../Desktop/Desktop.hooks';
import { extractNodes } from '../../../shared/api/api.utils';
import type { TimelineEventApiType } from '../Timeline.types';
import { useTimelineTrackingEventOnInit } from '../tracking/TimelineTracking.hooks';

const RETRY_LOAD_MORE_TIMEOUT = 5000;

export const TimelineView: FC = () => {
  useTimelineTrackingEventOnInit('open_activity');

  const { workspace } = useCurrentWorkspace();
  const shouldCloseTimelineOnMenuSelect = useRef<boolean>(true);
  const isTimeLineDateRangeOpened = useRef<boolean>(false);

  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [eventFilterType, setEventFilterType] = useState('');
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<any>();

  const desktop = useCurrentDesktop();

  const { data, loading, fetchMore } = useSearchInTimelinesQuery({
    variables: {
      workspace: workspace.id,
      pageSize: TIMELINE_EVENTS_PAGE_SIZE,
      page: 0,
      query: searchFilterValue,
      scope: eventFilterType,
      ...dateRangeFilterValue,
      desktop: desktop?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const timelineEvents: TimelineEventApiType[] = useMemo(
    () => extractNodes(data?.searchInTimelineEvents),
    [data],
  );

  const timelineDays = useMemo(() => {
    return groupEventsByDay(timelineEvents);
  }, [timelineEvents]);

  const [loadingMoreEvents, setLoadingMoreEvents] = useState(false);
  const [hasMoreEventsToLoad, setHasMoreEventsToLoad] = useState(true);

  const handleLoadMoreEvents = useCallback(() => {
    const nextPage = getNextPage(
      timelineEvents?.length,
      TIMELINE_EVENTS_PAGE_SIZE,
    );
    if (!nextPage) {
      setHasMoreEventsToLoad(false);
      return;
    }

    setLoadingMoreEvents(true);
    fetchMore({
      variables: {
        page: nextPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return mergeWithArray(
          prev || data,
          fetchMoreResult || {},
        ) as SearchInTimelinesResponse;
      },
    })
      .then(() => setLoadingMoreEvents(false))
      .catch(e => {
        setTimeout(() => setLoadingMoreEvents(false), RETRY_LOAD_MORE_TIMEOUT);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [timelineEvents?.length, fetchMore, data]);

  const handleFilterValueChange = (filterValue: string) => {
    setSearchFilterValue(filterValue);
  };

  const handleDateRangeChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    return setDateRangeFilterValue({
      minDate: format(new Date(startDate), DATE_RANGE_FORMAT),
      maxDate: format(new Date(endDate), DATE_RANGE_FORMAT),
    });
  };

  const formatedDate = useMemo(() => {
    if (!dateRangeFilterValue) return false;
    const { minDate, maxDate } = dateRangeFilterValue;
    const formatedMinDate = format(new Date(minDate), DATE_RANGE_FILTER_FORMAT);
    const formatedMaxDate = format(new Date(maxDate), DATE_RANGE_FILTER_FORMAT);

    if (minDate === maxDate) {
      return formatedMinDate;
    }

    return `${formatedMinDate} - ${formatedMaxDate}`;
  }, [dateRangeFilterValue]);

  const handleFilterClose = () => setDateRangeFilterValue('');
  const handleFilterTypeChange = (eventFilterType: string) =>
    setEventFilterType(eventFilterType);

  return (
    <>
      <FilterContainer>
        <TimelineFilters
          onFilterValueChange={handleFilterValueChange}
          onFilterTypeChange={handleFilterTypeChange}
          onDateRangeChange={handleDateRangeChange}
          shouldCloseTimelineOnMenuSelect={shouldCloseTimelineOnMenuSelect}
          isTimeLineDateRangeOpened={isTimeLineDateRangeOpened}
        />

        {dateRangeFilterValue && (
          <SelectedDateRangeFilter>
            <SelectedDate>
              <CalendarIcon width={14} />
              {formatedDate}
            </SelectedDate>
            <FilterCloseButton onClick={handleFilterClose} icon={CloseIcon} />
          </SelectedDateRangeFilter>
        )}
      </FilterContainer>

      {loading && !data ? (
        <Spinner containerHeight={200} />
      ) : !timelineDays?.length && !loading ? (
        <NoTimelineEventsMessage>
          <FormattedMessage id={TimelineTranslation.noEventsMessage} />
        </NoTimelineEventsMessage>
      ) : (
        <TimelineEventsList>
          {timelineDays.map(day => (
            <TimelineDay date={day.date} key={day.date}>
              {day.events.map(event => (
                <TimelineEvent event={event} key={event.id} />
              ))}
            </TimelineDay>
          ))}
          {hasMoreEventsToLoad && (
            <LoadMoreObserver
              loading={loadingMoreEvents}
              onLoadMore={handleLoadMoreEvents}
            />
          )}
        </TimelineEventsList>
      )}
    </>
  );
};
