import React, { FC, useMemo, useRef } from 'react';
import { LinkApiType } from '../../../Link.types';
import { LinksMenu } from '../LinksMenu';
import { DotsIcon } from '../../../../../shared/icons';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';
import type { ChatMessageInternalType } from '../../../../Chat/Chat.types';
import { useCurrentDesktop } from '../../../../Desktop/Desktop.hooks';
import { useMobile } from '../../../../../shared/hooks';
import {
  LinkControlsButton,
  LinkControlsContainer,
} from './LinkControls.styled';
import { useGoogleOauth } from '../../../../Preview/DocumentPreview';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { LinkTranslation } from '../../../i18n';
import { HorizontalAlignment } from '../../../../../shared/components/Menu';
import { useCurrentTypeOfPage } from '../../../../Desktop/Desktop.utils';
import { useIntl } from 'react-intl';

interface LinkControlsMenuProps {
  link: LinkApiType;
  chatLink: boolean;
  visible: boolean;
  linkChatMessage?: ChatMessageInternalType;
  index: number | undefined;
  handleDeleteChatLink: () => void;
  handleDeleteLink?: () => void;
  handleEditLink: () => void;
  handleCopyLink: () => void;
  handleMenuOpenClick: (isOpened: boolean) => void;
  toggleFavoriteLink: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isUpdatingFavorite: boolean;
  isDocumentLink: boolean | null;
  openGoogleAuthWindow: () => void;
  displayPreviewOption: boolean;
  displayUnselectOption: boolean;
  displayUnselectAllOption: boolean;
  displaySingleLinkOptions: boolean;
  handleSelectClick?: () => void;
  handleUnselectClick?: () => void;
  handleSelectAllClick?: () => void;
  handleUnselectAllClick?: () => void;
}

export const LinkControlsMenu: FC<LinkControlsMenuProps> = ({
  link,
  chatLink,
  linkChatMessage,
  handleDeleteChatLink,
  handleDeleteLink,
  handleEditLink,
  handleCopyLink,
  handleMenuOpenClick,
  toggleFavoriteLink,
  isUpdatingFavorite,
  isDocumentLink,
  openGoogleAuthWindow,
  visible,
  displayPreviewOption,
  displayUnselectOption,
  displayUnselectAllOption,
  displaySingleLinkOptions,
  handleSelectClick,
  handleUnselectClick,
  handleSelectAllClick,
  handleUnselectAllClick,
}) => {
  const { account: workspaceAccount } = useCurrentWorkspaceAccount();
  const desktop = useCurrentDesktop();
  const isMobile = useMobile();
  const buttonRef = useRef(null);
  const googleAuthUrl = useGoogleOauth(link);
  const { formatMessage } = useIntl();

  const isOwnChatLink = useMemo(() => {
    if (!linkChatMessage || !workspaceAccount) {
      return false;
    }
    return linkChatMessage.accountIri === workspaceAccount.id;
  }, [linkChatMessage, workspaceAccount]);

  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  return visible && (desktop || chatLink || isFavoritesDesktop) ? (
    <LinkControlsContainer
      isMobile={isMobile}
      data-testid="link-controls-menu-wrapper">
      <LinkControlsButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: LinkTranslation.tooltipThreeDotsMore,
        })}
        data-tooltip-place={TooltipPlace.top}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={buttonRef}
        icon={DotsIcon}
        data-testid="link-three-dots"
      />
      <LinksMenu
        trigger={buttonRef}
        isDocumentLink={isDocumentLink}
        hAlign={
          isMobile
            ? HorizontalAlignment.RIGHT
            : HorizontalAlignment.OUTSIDE_RIGHT
        }
        handleDeleteLink={
          chatLink
            ? isOwnChatLink
              ? handleDeleteChatLink
              : undefined
            : handleDeleteLink
        }
        handleEditLink={handleEditLink}
        handleCopyLink={handleCopyLink}
        link={link}
        handleOpenMenu={handleMenuOpenClick}
        chatLinksMenu={chatLink}
        toggleFavoriteLink={toggleFavoriteLink}
        isUpdatingFavorite={isUpdatingFavorite}
        googleAuthUrl={googleAuthUrl}
        displayPreviewOption={displayPreviewOption}
        displayUnselectOption={displayUnselectOption}
        displayUnselectAllOption={displayUnselectAllOption}
        displaySingleLinkOptions={displaySingleLinkOptions}
        openGoogleAuthWindow={openGoogleAuthWindow}
        handleSelectClick={handleSelectClick}
        handleUnselectClick={handleUnselectClick}
        handleSelectAllClick={handleSelectAllClick}
        handleUnselectAllClick={handleUnselectAllClick}
      />
    </LinkControlsContainer>
  ) : null;
};
