import React, { Ref, useCallback, useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../../shared/components/Button/Button';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../../../../../shared/components/Menu';
import { showToastGraphQLErrors } from '../../../../../../../shared/components/Toast';
import {
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../../../shared/hooks';
import {
  AddIcon,
  AppsIcon,
  ChatIcon,
  ClipboardIcon,
  EyeClosedIcon,
  EyeIcon,
  FolderIcon,
  ImportIcon,
  LinkIcon,
} from '../../../../../../../shared/icons';
import { extensionSupported } from '../../../../../../../shared/utils/extension.utils';
import { getQueryParamsFrom } from '../../../../../../../shared/utils/url.utils';
import { useEnableChatForDesktopMutation } from '../../../../../../Chat/Chat.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../../../../../Workspace/Workspace.hooks';
import { DesktopTab } from '../../../../../Desktop.constants';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
  useEditHidingChatMutation,
  useGetIsChatHidden,
} from '../../../../../Desktop.hooks';
import { ImportStepTypes } from '../../../../../../ChromeExtension/ChromeExtension.types';
import { ChromeExtensionTranslation } from '../../../../../../ChromeExtension/i18n';
import { DesktopTranslation } from '../../../../../i18n';
import { DesktopControlButton } from '../Controls.styled';
import { ButtonWithIcon } from '../../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import styled from 'styled-components';
import { TooltipPlace } from '../../../../../../../shared/components/Tooltip';
import { CopyPasteSectionDataContext } from '../../../../../CopyPasteSectionDataProvider';
import { useDesktopsActions } from '../../../../../data/Desktop/Desktop.actions';
import { sendAppTrackingEvent } from '../../../../../tracking/DesktopTracking.utils';

const MobileAddButton = styled(ButtonWithIcon)`
  background-color: ${({ theme }) => theme.colors.Primary};
  color: ${({ theme }) => theme.colors.OnNavbar};
  border-radius: 4px;
  padding: 0.25rem;
  width: 24px;
  height: 24px;
`;

export const AddContentButton = () => {
  const isMobile = useMobile();
  useTablet();
  const { account } = useCurrentWorkspaceAccount();
  const { canAddApp, canAddLink, canCreateFolder, canEnableChat } =
    useCurrentDesktopPermissions();
  const addToDesktopRef: Ref<HTMLButtonElement> = useRef(null);
  const isChatHidden = useGetIsChatHidden();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();

  const [enableChatForDesktopMutation] = useEnableChatForDesktopMutation();

  const [editHidingChatMutation] = useEditHidingChatMutation();

  const { updateDesktopInIDDB } = useDesktopsActions();

  const {
    showPasteLinkSectionButton,
    showPasteAppSectionButton,
    handlePasteClipboardDesktopSectionData,
  } = useContext(CopyPasteSectionDataContext);

  const enableChat = useCallback(() => {
    // TODO @bqk: remove get desktop content from here
    if (!desktop?.id) {
      return;
    }

    return enableChatForDesktopMutation({
      variables: {
        input: {
          id: desktop.id,
        },
      },
    })
      .then(resp => {
        const desktopResp = resp.data?.enableChatForDesktop.desktop;

        if (desktopResp) {
          updateDesktopInIDDB(desktop.id, { ...desktop, ...desktopResp });
        }

        refetchPermissions();
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
        return false;
      });
  }, [
    desktop,
    enableChatForDesktopMutation,
    refetchPermissions,
    updateDesktopInIDDB,
  ]);

  const handleShowHideChatClick = useCallback(
    (value: boolean) => {
      if (!desktop?.id) {
        return;
      }

      const accountsThatChatHidden = !value
        ? desktop.accountsThatChatHidden.filter(
            accountId => accountId !== account.id,
          )
        : [...desktop.accountsThatChatHidden, account.id];

      updateDesktopInIDDB(desktop.id, {
        ...desktop,
        accountsThatChatHidden,
      });

      editHidingChatMutation({
        variables: {
          input: {
            id: desktop.id,
            isChatHidden: value,
          },
        },
      })
        .then(() => {
          refetchPermissions();
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    },
    [
      desktop,
      updateDesktopInIDDB,
      editHidingChatMutation,
      account.id,
      refetchPermissions,
    ],
  );

  if (!canAddApp && !canAddLink) {
    return null;
  }

  if (!canAddApp && !canAddLink) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <MobileAddButton
          icon={AddIcon}
          ref={addToDesktopRef}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: DesktopTranslation.tooltipAddDesktopContentButton,
          })}
          data-tooltip-place={TooltipPlace.bottom}
        />
      ) : (
        <DesktopControlButton
          ref={addToDesktopRef}
          size={ButtonSize.sm}
          mode={ButtonMode.primary}
          data-testid="add-content"
          data-tourid="add-content-button"
          onClick={e => e.preventDefault()}>
          <AddIcon />
          {formatMessage({ id: DesktopTranslation.headerAddButton })}
        </DesktopControlButton>
      )}
      <Menu
        trigger={addToDesktopRef}
        width={isMobile ? (canEnableChat ? 212 : 152) : 232}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.RIGHT}
        data-testid="desktop-add-content-menu">
        {showPasteAppSectionButton && (
          <>
            <MenuItem
              data-tooltip-place={TooltipPlace.left}
              data-testid="add-button-paste-apps"
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage(
                { id: DesktopTranslation.pasteDesktopDataSectionTooltip },
                {
                  pasteDataType: formatMessage({
                    id: DesktopTranslation.pasteClipboardManyApps,
                  }),
                },
              )}
              onClick={() => {
                handlePasteClipboardDesktopSectionData(DesktopTab.apps);
              }}
              icon={ClipboardIcon}>
              <FormattedMessage
                id={DesktopTranslation.pasteDataDesktopControlsMenuItem}
                values={{
                  pasteSection: formatMessage({
                    id: DesktopTranslation.pasteClipboardManyApps,
                  }),
                }}
              />
            </MenuItem>
          </>
        )}
        {showPasteLinkSectionButton && (
          <>
            <MenuItem
              data-testid="add-button-paste-links"
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage(
                { id: DesktopTranslation.pasteDesktopDataSectionTooltip },
                {
                  pasteDataType: formatMessage({
                    id: DesktopTranslation.pasteClipboardManyLinks,
                  }),
                },
              )}
              data-tooltip-place={TooltipPlace.left}
              onClick={() => {
                handlePasteClipboardDesktopSectionData(DesktopTab.links);
              }}
              icon={ClipboardIcon}>
              <FormattedMessage
                id={DesktopTranslation.pasteDataDesktopControlsMenuItem}
                values={{
                  pasteSection: formatMessage({
                    id: DesktopTranslation.pasteClipboardManyLinks,
                  }),
                }}
              />
            </MenuItem>
          </>
        )}
        {canAddApp && (
          <MenuItem
            data-testid="add-app-item"
            onClick={() => {
              sendAppTrackingEvent('open_app_store_from_button');
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  appStoreModal: true,
                }),
              });
            }}
            icon={() => <AppsIcon width={16} height={16} />}>
            <FormattedMessage id={DesktopTranslation.headerAddApp} />
          </MenuItem>
        )}
        {canAddLink && (
          <MenuItem
            data-testid="add-link-item"
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  createLink: true,
                }),
              })
            }
            icon={() => <LinkIcon width={16} height={16} />}>
            <FormattedMessage id={DesktopTranslation.headerAddLink} />
          </MenuItem>
        )}
        {canCreateFolder && (
          <MenuItem
            data-testid="create-folder-item"
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  createFolder: true,
                }),
              })
            }
            icon={() => <FolderIcon width={16} height={16} />}>
            <FormattedMessage id={DesktopTranslation.headerCreateFolder} />
          </MenuItem>
        )}
        {canEnableChat && workspace.communicate && (
          <MenuItem
            isDisabled={desktop?.chatEnabled}
            data-testid="enable-chat-item"
            onClick={() => {
              if (!desktop?.chatEnabled) {
                enableChat();
              }
            }}
            icon={() => <ChatIcon width={16} height={16} />}>
            {desktop?.chatEnabled ? (
              <FormattedMessage id={DesktopTranslation.headerChatIsEnabled} />
            ) : (
              <FormattedMessage id={DesktopTranslation.headerEnableChat} />
            )}
          </MenuItem>
        )}
        {workspace.communicate &&
          desktop?.chatEnabled &&
          (isChatHidden ? (
            <MenuItem
              icon={EyeIcon}
              onClick={() => handleShowHideChatClick(false)}
              data-testid="show-hide-chat-item">
              <FormattedMessage id={DesktopTranslation.showChatButtonLabel} />
            </MenuItem>
          ) : (
            <MenuItem
              icon={EyeClosedIcon}
              onClick={() => handleShowHideChatClick(true)}>
              <FormattedMessage id={DesktopTranslation.hideChatButtonLabel} />
            </MenuItem>
          ))}
        {(canAddApp || canAddLink) && extensionSupported && (
          <>
            <MenuSeparator />
            <MenuItem
              data-testid="import-apps-item"
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    extensionInfoModal: true,
                    nextStep: canAddApp
                      ? ImportStepTypes.selectApps
                      : ImportStepTypes.selectLinks,
                  }),
                })
              }
              icon={() => <ImportIcon width={20} height={20} />}>
              <FormattedMessage id={ChromeExtensionTranslation.importApps} />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
