import { gql } from '@apollo/client';
import { AccountIdentityApiType } from '../User/User.types';

export const UNSUBSCRIBE_PRODUCT_INFORMATION_EMAILS = gql`
  mutation UnsubscribeProductInformationEmailsForAccountIdentity(
    $input: unsubscribeProductInformationEmailsForAccountIdentityInput!
  ) {
    unsubscribeProductInformationEmailsForAccountIdentity(input: $input) {
      accountIdentity {
        id
      }
    }
  }
`;

export interface UnsubscribeProductInformationEmailsVariables {
  input: {
    id: string;
    token: string;
  };
}

export interface UnsubscribeProductInformationEmailsResponse {
  unsubscribeProductInformationEmailsForAccountIdentity: {
    accountIdentity: AccountIdentityApiType;
  };
}
