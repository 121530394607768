import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { ListItemContent } from '../../../shared/components/List';
import { useConfirm } from '../../../shared/components/Modal';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { useQueryParams } from '../../../shared/hooks';
import { DeleteIcon } from '../../../shared/icons';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { AdminListItem } from '../../Admin';
import {
  useDeleteWorkspaceDomainMutation,
  useUpdateWorkspaceDomainMutation,
} from '../Domain.hooks';
import { WorkspaceDomainApiType } from '../Domain.types';
import { DomainTranslation } from '../i18n';
import { DeleteDomainButton } from './Domain.styled';

interface DomainItemProps {
  domain: WorkspaceDomainApiType;
  refetch: () => void;
  handleCurrentDomain: (domain: WorkspaceDomainApiType) => void;
}

export const DomainItem: FC<DomainItemProps> = ({
  domain,
  refetch,
  handleCurrentDomain,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [updateWorkspaceDomainMutation] = useUpdateWorkspaceDomainMutation();
  const handleChangeDomainStatus = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: domain.enabled
          ? DomainTranslation.adminListDisableDomainConfirmation
          : DomainTranslation.adminListEnableDomainConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      setLoading(true);
      updateWorkspaceDomainMutation({
        variables: {
          input: {
            domain: domain.domain,
            enabled: !domain.enabled,
            id: domain.id,
          },
        },
      })
        .then(() => {
          setLoading(false);
          refetch();
          showToastSuccessMessage(
            domain.enabled
              ? DomainTranslation.adminListDisableDomainToastMessage
              : DomainTranslation.adminListEnableDomainToastMessage,
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    });
  }, [updateWorkspaceDomainMutation, refetch, askConfirmation, domain, intl]);

  const [deleteWorkspaceDomain] = useDeleteWorkspaceDomainMutation();
  const handleDeleteDomain = useCallback(
    (id: string) => {
      askConfirmation(
        intl.formatMessage({
          id: DomainTranslation.adminListDeleteDomainConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteWorkspaceDomain({
          variables: {
            input: {
              id,
            },
          },
        })
          .then(() => {
            refetch();
            showToastSuccessMessage(
              DomainTranslation.adminListDeleteDomainToastMessage,
            );
          })
          .catch(e => {
            showToastGraphQLErrors(e.graphQLErrors);
          });
      });
    },

    [deleteWorkspaceDomain, refetch, askConfirmation, intl],
  );
  return (
    <AdminListItem
      key={domain.id}
      data-entityid={domain.id}
      onClick={() => {
        handleCurrentDomain(domain);
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            editDomainModal: true,
          }),
        });
      }}>
      <ListItemContent title={domain.domain}>
        {domain.enabled ? (
          <Button
            mode={ButtonMode.secondary}
            size={ButtonSize.sm}
            disabled={loading}
            data-testid="disable-button"
            onClick={e => {
              e.stopPropagation();
              handleChangeDomainStatus();
            }}>
            <FormattedMessage
              id={DomainTranslation.adminListDisableButtonLabel}
            />
          </Button>
        ) : (
          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.sm}
            disabled={loading}
            data-testid="enable-button"
            onClick={e => {
              e.stopPropagation();
              handleChangeDomainStatus();
            }}>
            <FormattedMessage
              id={DomainTranslation.adminListEnableButtonLabel}
            />
          </Button>
        )}

        <DeleteDomainButton
          icon={DeleteIcon}
          data-testid="delete-button"
          onClick={e => {
            e.stopPropagation();
            handleDeleteDomain(domain.id);
          }}
        />
      </ListItemContent>
    </AdminListItem>
  );
};
