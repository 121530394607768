import React, { FC, useMemo } from 'react';
import { DesktopLayout } from '../../Desktop.constants';
import { DesktopControls } from '../Content/components/Controls/Controls';
import { getDesktopIri, useCurrentTypeOfPage } from '../../Desktop.utils';
import { Spinner } from '../../../../shared/components/Spinner';
import { ChatContext } from '../../../Chat/Chat.context';
import { CreateLinkModal } from '../../../Link/LinksView/Modals';
import { ShareDesktopPopoverProvider } from '../../ShareDesktopPopover';
import { ChooseVaultForDesktopModal } from '../../../Encryption/ChooseVaultForDesktop/ChooseVaultForDesktopModal';
import { getAppIri } from '../../../AppStore/AppStore.utils';
import {
  EDIT_APP_MODAL_Z_INDEX,
  SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX,
} from '../../../AppStore/AppStore.constants';
import { CurrentDesktopContentContext } from '../../Desktop.context';
import { CreateFolderModal } from '../../../Folder/Modals';
import {
  SegmentEmpty,
  SegmentType,
  useSetLastUsedSegment,
} from '../../../Segment';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../i18n';
import { TimelineDesktopPopoverProvider } from '../../../Timeline/TimelineView/TimelineDesktopPopover';
import { LoadingState, StyledDesktopContent } from '../Content/Content.styled';
import { useMobile } from '../../../../shared/hooks';
import { StandardDesktopViewModel } from './ViewModel';
import {
  LayoutConsolidated,
  LayoutTabbed,
} from '../../DesktopView/DesktopLayout';
import { EditAppModal } from '../../../AppStore/Modals/EditCustomAppModal';
import { useCurrentDesktop } from '../../Desktop.hooks';
import { DesktopEmptyView } from '../../DesktopView/DesktopEmptyView';
import { MembersUniversal } from '../../../Chat/MembersUniversal';
import { RenderDelay } from '../../../../shared/hooks/renderDelay.hook';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { isCurrentAccountGuest } from '../../../User/User.utils';
import { ChatProvider } from '../../../Chat/Chat.provider';
import { DesktopCopyPasteProvider } from '../../DesktopCopyPasteProvider';
import { CopyPasteSectionDataProvider } from '../../CopyPasteSectionDataProvider';
import { useEffectOnce } from 'react-use';
import { getLongId, getShortId } from '../../../../shared/utils/id';

export const StandardDesktop: FC = () => {
  const isMobile = useMobile();
  const desktop = useCurrentDesktop();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const {
    currentDesktopContentContextValue,
    initialChatData,
    desktopId,
    desktopIsLoading,
    desktopHasContent,
    desktopIsEmpty,
    desktopConversation,
    desktopLayout,
    createFolderParentName,
    createLink,
    createFolder,
    editAppId,
    desktopName,
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
    createLinkModalCloseHandler,
    chooseVaultForDesktopModalCloseHandler,
  } = StandardDesktopViewModel();

  const setLastSegment = useSetLastUsedSegment();
  useEffectOnce(() => {
    setLastSegment(SegmentType.DESKTOPS);
  });

  const isWorkspaceGuest: boolean | undefined = useMemo(
    () => isCurrentAccountGuest(account, getShortId(workspace.id)),
    [account, workspace.id],
  );

  if (!!desktop && getLongId('desktops', desktopId as string) !== desktop?.id) {
    return (
      <>
        <SegmentEmpty>
          <FormattedMessage id={DesktopTranslation.desktopEmptyState} />
        </SegmentEmpty>
        <MembersUniversal />
      </>
    );
  }

  // Unavailable or non-existing desktop
  if (!desktop && !isFavoritesDesktop && !desktopIsLoading) {
    return (
      <>
        <SegmentEmpty>
          <FormattedMessage id={DesktopTranslation.notAvailableDesktop} />
        </SegmentEmpty>
        <MembersUniversal />
      </>
    );
  }

  return (
    <ShareDesktopPopoverProvider>
      <TimelineDesktopPopoverProvider>
        <DesktopCopyPasteProvider>
          <ChatContext.Provider
            value={{
              conversation: desktopConversation,
              initialData: initialChatData,
            }}>
            <ChatProvider>
              <CurrentDesktopContentContext.Provider
                value={currentDesktopContentContextValue}>
                <CopyPasteSectionDataProvider>
                  <StyledDesktopContent
                    data-testid="desktop-view"
                    isMobile={isMobile}>
                    <DesktopControls />

                    {/* Desktop loading state */}
                    {desktopIsLoading && !desktopHasContent && (
                      <LoadingState isMobile={isMobile}>
                        <Spinner />
                      </LoadingState>
                    )}

                    {/* Desktop has no content state */}
                    {desktopIsEmpty && (
                      <RenderDelay>
                        <>
                          <DesktopEmptyView />
                          <MembersUniversal />
                        </>
                      </RenderDelay>
                    )}

                    {/* Desktop content state */}
                    {!desktopIsEmpty && (
                      <>
                        {desktopLayout === DesktopLayout.CONSOLIDATED ? (
                          <LayoutConsolidated />
                        ) : (
                          <LayoutTabbed />
                        )}
                      </>
                    )}
                  </StyledDesktopContent>
                </CopyPasteSectionDataProvider>
              </CurrentDesktopContentContext.Provider>
            </ChatProvider>
          </ChatContext.Provider>
        </DesktopCopyPasteProvider>

        {createLink && (
          <CreateLinkModal
            desktopName={desktopName}
            onRequestClose={createLinkModalCloseHandler}
          />
        )}
        {createFolder && (
          <CreateFolderModal parentName={createFolderParentName} />
        )}
        {editAppId && <EditAppModal containerZIndex={EDIT_APP_MODAL_Z_INDEX} />}
        {selectVaultForAppId && !isWorkspaceGuest && (
          <ChooseVaultForDesktopModal
            containerZIndex={SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX}
            appId={getAppIri(selectVaultForAppId as string)}
            groupAppId={
              selectVaultForGroupAppId
                ? getAppIri(selectVaultForGroupAppId as string)
                : undefined
            }
            desktopIdOverride={
              (selectVaultForDesktopIdOverride &&
                getDesktopIri(selectVaultForDesktopIdOverride as string)) ||
              undefined
            }
            onRequestClose={chooseVaultForDesktopModalCloseHandler}
          />
        )}
      </TimelineDesktopPopoverProvider>
    </ShareDesktopPopoverProvider>
  );
};
