import React, { useCallback } from 'react';
import {
  WorkspaceFeaturesForm,
  WorkspaceFeaturesFormValues,
} from '../WorkspaceFeaturesForm';
import {
  useChangeFeatureForWorkspaceMutation,
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace.hooks';
import { useConfirm } from '../../../../shared/components/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkspaceTranslation } from '../../i18n';
import { GET_WORKSPACES_BY_IDENTITY } from '../../Workspace.queries';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  showToastGraphQLErrors,
  showToastInfoMessage,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { FormikHelpers } from 'formik';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import {
  WorkspaceFeatureConfirmation,
  WorkspaceFeatureConfirmationControls,
} from './WorkspaceFeatures.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { GlobalTranslation } from '../../../Intl/i18n';

export const WorkspaceFeatures = () => {
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canEditWorkspaceFeatures },
    refetchPermissions,
  } = useCurrentWorkspacePermissions();
  const intl = useIntl();
  const { askConfirmation } = useConfirm();
  const { account } = useCurrentAccount();
  const [changeFeatureForWorkspaceMutation] =
    useChangeFeatureForWorkspaceMutation();
  const { refetchAccountData } = useCurrentAccount();
  const updateWorkspaceFeatures = useCallback(
    (
      formValues: WorkspaceFeaturesFormValues,
      formikHelpers: FormikHelpers<WorkspaceFeaturesFormValues>,
    ) => {
      const { organizeAndSearch, communicate } = formValues;
      return changeFeatureForWorkspaceMutation({
        variables: {
          input: {
            id: workspace.id,
            organizeAndSearch,
            communicate,
          },
        },
        refetchQueries: [
          {
            query: GET_WORKSPACES_BY_IDENTITY,
            variables: {
              id: account!.identityId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(() => {
          showToastSuccessMessage(WorkspaceTranslation.formSuccessMessage);
          refetchPermissions();
          refetchAccountData?.();
        })
        .then(() => {
          formikHelpers.setSubmitting(false);
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      account,
      refetchAccountData,
      changeFeatureForWorkspaceMutation,
      refetchPermissions,
      workspace.id,
    ],
  );

  const handleSubmit = useCallback(
    (
      formValues: WorkspaceFeaturesFormValues,
      formikHelpers: FormikHelpers<WorkspaceFeaturesFormValues>,
    ) => {
      const { setSubmitting, resetForm } = formikHelpers;
      const { organizeAndSearch, communicate } = formValues;

      if (
        (workspace.organizeAndSearch && !organizeAndSearch) ||
        (workspace.communicate && !communicate)
      ) {
        askConfirmation(
          <WorkspaceFeatureConfirmation>
            <FormattedMessage
              id={WorkspaceTranslation.formFeatureDeactivateConfirmationBody}
            />
            <ImportantMessage
              type={ImportantMessageType.INFO}
              withIcon={true}
              circleIcon>
              <FormattedMessage
                id={
                  WorkspaceTranslation.formFeatureDeactivateConfirmationMessage
                }
              />
            </ImportantMessage>
          </WorkspaceFeatureConfirmation>,
          intl.formatMessage(
            {
              id: WorkspaceTranslation.formFeatureDeactivateConfirmationTitle,
            },
            {
              featureName:
                workspace.organizeAndSearch && !organizeAndSearch
                  ? intl.formatMessage({
                      id: WorkspaceTranslation.formFeatureOrganizeLabel,
                    })
                  : intl.formatMessage({
                      id: WorkspaceTranslation.formFeatureCommunicateLabel,
                    }),
            },
          ),
          {
            width: 384,
            customControls: (onConfirm, onCancel) => (
              <WorkspaceFeatureConfirmationControls>
                <Button
                  mode={ButtonMode.secondary}
                  size={ButtonSize.md}
                  onClick={onCancel}
                  data-testid="cancel-button">
                  <FormattedMessage id={GlobalTranslation.cancel} />
                </Button>
                <Button
                  mode={ButtonMode.primary}
                  size={ButtonSize.md}
                  onClick={onConfirm}
                  data-testid="confirm-button">
                  <FormattedMessage id={GlobalTranslation.confirm} />
                </Button>
              </WorkspaceFeatureConfirmationControls>
            ),
          },
        ).then(confirm => {
          if (!confirm) {
            setSubmitting(false);
            resetForm({
              values: {
                organizeAndSearch: workspace.organizeAndSearch,
                communicate: workspace.communicate,
              },
            });
            return;
          }

          if (
            (!workspace.organizeAndSearch && !communicate) ||
            (!workspace.communicate && !organizeAndSearch)
          ) {
            showToastInfoMessage(
              WorkspaceTranslation.formFeatureSelectAtLeastOne,
            );
            setSubmitting(false);
            resetForm({
              values: {
                organizeAndSearch: workspace.organizeAndSearch,
                communicate: workspace.communicate,
              },
            });
          } else {
            updateWorkspaceFeatures(formValues, formikHelpers);
          }
        });
      } else {
        updateWorkspaceFeatures(formValues, formikHelpers);
      }
    },
    [askConfirmation, intl, updateWorkspaceFeatures, workspace],
  );

  return (
    <WorkspaceFeaturesForm
      workspace={workspace}
      disabled={!canEditWorkspaceFeatures}
      onSubmit={handleSubmit}
    />
  );
};
