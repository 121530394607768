import styled from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';

export const SelectVaultList = styled.ul`
  padding: 0.5rem;
`;

export const SelectVaultExpandBody = styled.div`
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  padding: 1rem;
`;

export const SelectVaultControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  > * ~ * {
    margin-left: 1rem;
  }
`;

export const SelectVaultAddNewButton = styled(Button).attrs({
  type: 'button',
  size: ButtonSize.md,
  mode: ButtonMode.secondary,
})``;

export const SelectVaultCloseButton = styled(Button).attrs({
  type: 'button',
  size: ButtonSize.md,
  mode: ButtonMode.primary,
})``;
