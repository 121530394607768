import styled from 'styled-components';

export const StyledMembersList = styled.ul`
  margin-top: 1rem;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    max-height: 18.75rem;
    overflow: auto;
  `}
`;

export const EmptyState = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  text-align: center;
`;
