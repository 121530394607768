import { FocusEvent } from 'react';

export const onComponentBlur = (
  e: FocusEvent<HTMLInputElement>,
  blurHandler: () => void,
): void => {
  const currentTarget = e.currentTarget as HTMLElement;

  setTimeout(() => {
    if (!currentTarget.contains(document.activeElement)) {
      blurHandler();
    }
  }, 0);
};
