import { gql } from '@apollo/client';
import { ApiConnection, CategoryApiType } from '../../shared/api/api.types';
import { AppSsoGroupApiType, AppStoreAppApiType } from './AppStore.types';
import {
  AppSsoGroupFields,
  WorkspaceConfigsConnection,
} from './AppStore.fragments';
import { AppFragmentFields } from '../Desktop/Desktop.fragments';

export const GET_APPSTORE_APPS = gql`
  query GetAppStoreApps(
    $first: Int
    $after: String
    $category: String
    $filterUsedByMe: Boolean
    $workspace: String
    $filterFreeTextSearch: String
    $custom: Boolean
    $filterExistVaultItemsByWorkspace: String
    $filterOnlyGroupApps: Boolean
    $onboardingEnabled: Boolean
    $presentInAppStore: Boolean
  ) {
    apps(
      first: $first
      after: $after
      category: $category
      filterUsedByMe: $filterUsedByMe
      workspace: $workspace
      filterFreeTextSearch: $filterFreeTextSearch
      order: { fullName: "asc" }
      presentInAppStore: $presentInAppStore
      custom: $custom
      filterExistVaultItemsByWorkspace: $filterExistVaultItemsByWorkspace
      filterOnlyGroupApps: $filterOnlyGroupApps
      onboardingEnabled: $onboardingEnabled
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
           ${AppFragmentFields}
        }
      }
    }
  }
`;

export const GET_CUSTOM_APPSTORE_APPS = gql`
  query GetCustomAppStoreApps($workspace: ID!) {
    listCustomWorkspaceApps(workspace: $workspace) {
      edges {
        node {
          id
          name
          id
          _id
          name
          fullName
          custom
          logo {
            id
            contentUrl
            __typename
          }
          uri
          loginUri
          openUri
          category {
            _id
            id
            name
            __typename
          }
          groupApp {
            id
            __typename
          }
          hasSsoOverride
          loginUriPattern
          groupApp {
            id
            __typename
          }
          groupEnabled
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export interface GetAppStoreAppsVariables {
  filterUsedByMe: boolean;
  category: string | null;
  workspace: string | null;
  filterFreeTextSearch: string | null;
  custom: boolean | null;
  filterExistVaultItemsByWorkspace: string | null;
  first?: number;
  after?: string;
  onboardingEnabled?: boolean;
  presentInAppStore?: boolean;
  filterOnlyGroupApps?: boolean;
}

export interface GetCustomAppStoreAppsVariables {
  workspace: string | null;
  filterUsedByMe: boolean;
  category: string | null;
  filterFreeTextSearch: string | null;
  custom: boolean | null;
  filterExistVaultItemsByWorkspace: string | null;
  first?: number;
  after?: string;
}
export interface GetAppStoreAppsResponse {
  apps: ApiConnection<AppStoreAppApiType>;
}

export interface GetCustomAppStoreAppsResponse {
  listCustomWorkspaceApps: ApiConnection<AppStoreAppApiType>;
}

export const GET_CUSTOM_APPSTORE_APP = gql`
  query GetAppStoreApp($id: ID!, $workspace: ID!) {
    workspaceApp(id: $id, workspace: $workspace) {
      ${AppFragmentFields}
    }
  }
`;

export interface GetAppStoreAppVariables {
  id: string;
  workspace: string;
}

export interface GetAppStoreAppResponse {
  workspaceApp: AppStoreAppApiType;
}

export const GET_APPSTORE_CATEGORIES = gql`
  query GetAppStoreCategories($presentInAppStore: Boolean) {
    categories(
      first: 100
      order: { name: "asc" }
      presentInAppStore: $presentInAppStore
    ) {
      edges {
        node {
          id
          _id
          name
        }
      }
    }
  }
`;

export interface GetAppStoreCategoriesResponse {
  categories: ApiConnection<CategoryApiType>;
}

export interface GetAppStoreCategoriesVariables {
  presentInAppStore: boolean;
}

export const GET_APP_SSO_GROUPS = gql`
  query GetSsoGroups($workspace: String!, $name: String) {
    ssoGroupsCompatible: appSsoGroups(filterNotConfigured: $workspace, name: $name) {
      edges {
        node {
          ${AppSsoGroupFields}
          ${WorkspaceConfigsConnection}
        }
      }
    }
    ssoGroupsConfigured: appSsoGroups(filterOnlyConfigured: $workspace, name: $name) {
      edges {
        node {
          ${AppSsoGroupFields}
          ${WorkspaceConfigsConnection}
        }
      }
    }
  }
`;

export interface GetAppSsoGroupsResponse {
  ssoGroupsCompatible: ApiConnection<AppSsoGroupApiType>;
  ssoGroupsConfigured: ApiConnection<AppSsoGroupApiType>;
}

export interface GetAppSsoGroupsVariables {
  workspace: string;
  name?: string;
}

export const GET_SSO_COMPATIBLE_APPS = gql`
  query GetSsoCompatibleApps($workspace: String!, $first: Int, $after: String, $fullName: String) {
    apps(filterNotConfigured: $workspace, first: $first, after: $after, fullName: $fullName, order: { fullName: "asc" }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ${AppFragmentFields}
        }
      }
    }
  }
`;

export interface GetSsoCompatibleAppsResponse {
  apps: ApiConnection<AppStoreAppApiType>;
}

export interface GetSsoCompatibleAppsVariables {
  workspace: string;
  first?: number;
  after?: string;
  fullName?: string;
}
