import React, { FC, useMemo } from 'react';
import { StyledOnboardingCountWrapper } from './OnboardingStepsCounter.styled';
import { SIGN_UP_STEP_COUNTS } from '../../Onboarding.constants';
import { OnboardingTranslation } from '../../i18n';
import { FormattedHTMLMessage } from 'react-intl';

export const OnboardingStepsCounter: FC<{ currentStep?: string }> = ({
  currentStep,
}) => {
  const length = useMemo(() => Object.keys(SIGN_UP_STEP_COUNTS).length, []);

  if (!currentStep) {
    return null;
  }

  return (
    <StyledOnboardingCountWrapper data-testid="sign-up-step-counter">
      <FormattedHTMLMessage
        tagName="div"
        id={OnboardingTranslation.onboardingStepsCount}
        values={{ count: SIGN_UP_STEP_COUNTS[currentStep], length: length }}
      />
    </StyledOnboardingCountWrapper>
  );
};
