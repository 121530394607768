import { getApolloClient } from '../../../Api/Api.utils';
import {
  CreateFavoriteItemType,
  CreateFavoriteResponse,
  CreateFavoriteVariables,
  CREATE_FAVORITE,
} from '../Operations/Mutations/CreateFavorite.mutation';
import {
  DeleteFavoriteResponse,
  DeleteFavoriteVariables,
  DELETE_FAVORITE,
} from '../Operations/Mutations/DeleteFavorite.mutation';

/**
 * Get favorites from API
 * @param workspaceId
 * @param accountId
 * @returns Favorites data
 */
// TODO @bqk -> deprecate if favorites works as expected
// export const getFavoritesData = async (
//   workspaceId: string,
//   accountId: string,
// ): Promise<{
//   errors?: readonly GraphQLError[];
//   error?: any;
//   data?: GetFavoritesResponse;
// }> => {
//   const apolloClient = getApolloClient();

//   try {
//     const { errors, data } = await apolloClient!.query<
//       GetFavoritesResponse,
//       GetFavoritesVariables
//     >({
//       query: GET_FAVORITES,
//       variables: {
//         workspaceId: workspaceId,
//         accountId: accountId,
//       },
//       errorPolicy: 'ignore',
//       fetchPolicy: 'network-only',
//     });
//     return Promise.resolve({ errors, data });
//   } catch (error) {
//     return Promise.resolve({ error });
//   }
// };

/**
 * Create a favorite app, link or folder
 * @param workspaceId
 * @param item
 * @returns mutation
 */
export const createFavoriteData = async (
  workspaceId: string,
  item: CreateFavoriteItemType,
) => {
  const apolloClient = getApolloClient();
  try {
    const mutation = await apolloClient!.mutate<
      CreateFavoriteResponse,
      CreateFavoriteVariables
    >({
      mutation: CREATE_FAVORITE,
      variables: {
        input: {
          workspace: workspaceId,
          ...item,
        },
      },
    });
    return Promise.resolve(mutation);
  } catch (err) {
    return Promise.resolve(undefined);
  }
};

/**
 * Delete a favorite item
 * @param workspaceId
 * @param itemId
 * @returns mutation
 */
export const deleteFavoriteData = async (
  workspaceId: string,
  itemId: string,
) => {
  const apolloClient = getApolloClient();
  try {
    const mutation = await apolloClient!.mutate<
      DeleteFavoriteResponse,
      DeleteFavoriteVariables
    >({
      mutation: DELETE_FAVORITE,
      variables: {
        input: {
          workspace: workspaceId,
          item: itemId,
        },
      },
    });
    return Promise.resolve(mutation);
  } catch (err) {
    return Promise.resolve(undefined);
  }
};
