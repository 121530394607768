import React, { FC, useEffect } from 'react';
import { useAuthService } from '../Auth.hooks';
import { useQueryParams } from '../../../shared/hooks';
import { AppShellSkeleton } from '../../AppRoot/AppShellSkeleton';

export const LoginPage: FC<{}> = () => {
  const authService = useAuthService();
  const { returnTo: appState, loginHint } = useQueryParams();
  useEffect(() => {
    if (authService) {
      authService.login({
        appState: typeof appState === 'string' ? appState : undefined,
        loginHint: typeof loginHint === 'string' ? loginHint : undefined,
      });
    }
  }, [authService, appState, loginHint]);

  return <AppShellSkeleton />;
};
