import styled from 'styled-components';
import {
  ConferenceViewHeaderSection,
  ScheduledConferenceContentInner,
} from '../../ConferenceView/ConferenceView.styled';

export const ConferenceOrganizerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;

  .conference-organizer-body {
    display: flex;
    span {
      padding-top: 0.875rem;
      font-size: 0.875rem;
      font-family: ${p => p.theme.fonts.Medium};
    }
  }
`;

export const StyledConferenceOrganizerTitle = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const ConferenceOrganizerHeaderSection = styled(
  ConferenceViewHeaderSection,
)`
  margin-right: 0.5rem;
`;

export const ConferenceOrganizerInner = styled(ScheduledConferenceContentInner)`
  ${({ theme: { breakpoints } }) => breakpoints.md`
    align-items: center;
  `}
`;

export const StyledConferenceOrganizer = styled.div`
  > button {
    margin-top: 0.25rem;
    font-family: ${p => p.theme.fonts.Regular};
    > div:first-child {
      margin-left: 0;
    }
    ${({ theme: { breakpoints } }) => breakpoints.md`
      margin: 0;
    `}
  }
`;
