import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { DragElementWrapper, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME } from './ExternalUrlDropZone.constants';
import { useQueryParams } from '../../../shared/hooks';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';

interface ExternalUrlDropWrapperProps {
  ref: DragElementWrapper<any>;
  className?: string;
}

export const useExternalUrlDropWrapperProps =
  (): ExternalUrlDropWrapperProps => {
    const [{ isDraggingOver }, ref] = useDrop({
      accept: [NativeTypes.URL, NativeTypes.FILE],
      collect: monitor => ({
        isDraggingOver: monitor.isOver(),
      }),
    });

    return {
      ref,
      className: isDraggingOver
        ? DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME
        : undefined,
    };
  };

export const useDroppedLinkHandler = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  return useCallback(
    (url: string) => {
      navigate(
        {
          search: getQueryParamsFrom({
            ...queryParams,
            createLink: true,
          }),
        },
        {
          state: url,
        },
      );
    },
    [navigate, queryParams],
  );
};
