import React, { FC, useCallback } from 'react';
import { useFolderQuery } from '../../Folder.hooks';
import { FolderFormValues } from '../../Folder.types';
import { StyledEditFolder } from './EditFolder.styles';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { FolderForm } from '../FolderForm';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { Navigate } from 'react-router-dom';
import { Spinner } from '../../../../shared/components/Spinner';
import { useQueryParams } from '../../../../shared/hooks';
import { useCurrentDesktopPermissions } from '../../../Desktop/Desktop.hooks';
import { getLongId, getShortId } from '../../../../shared/utils/id';
import { useCaseEditFolder } from '../../UseCase/editFolder';
import { PermissionContext } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface EditFolderProps {
  onDone: () => void;
}

export const EditFolder: FC<EditFolderProps> = ({ onDone }) => {
  const { workspace } = useCurrentWorkspace();
  const { editFolderId } = useQueryParams();

  const folderId = editFolderId
    ? getLongId('folders', editFolderId as string)
    : undefined;

  // TODO @bqk: make use case
  const { data, loading } = useFolderQuery({
    skip: !folderId,
    variables: {
      id: folderId || '',
    },
  });

  const { canEditLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(data?.folder?.desktop.id || ''),
  );

  const { editFolderUseCase } = useCaseEditFolder();

  const editLink = useCallback(
    (values: FolderFormValues) => {
      return editFolderUseCase(folderId as string, {
        ...values,
      })
        .then(() => {
          onDone();
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [editFolderUseCase, folderId, onDone],
  );

  if (!editFolderId || (!data && !loading)) {
    return <Navigate to={`/workspace/${getShortId(workspace.id)}`} />;
  }

  return (
    <StyledEditFolder>
      {loading ? (
        <Spinner containerHeight={120} />
      ) : (
        <FolderForm
          folder={data!.folder}
          onSubmit={editLink}
          onCancel={onDone}
          disabled={!canEditLink}
        />
      )}
    </StyledEditFolder>
  );
};
