import styled from 'styled-components';

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  line-height: 1.43;
  font-weight: 500;
  letter-spacing: -0.14px;
  form {
    padding-top: 1rem;
  }
`;

export const ButtonControls = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 0.875rem;
  }
`;

export const StyledTitle = styled.div`
  padding-bottom: 0.5rem;
  font-weight: 700;
`;

export const StyledListInfo = styled.ul`
  padding: 1rem;
  list-style-type: disc;
`;
