import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledScheduledConferencesListItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${p => p.theme.colors.OnSurfacePressed};
  }

  &.active {
    background-color: ${p => p.theme.colors.Primary};
    color: ${p => p.theme.colors.PrimaryTextLight};

    svg,
    path {
      fill: ${p => p.theme.colors.OnNavbarMediumEmphasis};

      & ~ span {
        color: ${p => p.theme.colors.OnNavbarMediumEmphasis};
      }
    }
  }

  .wrapper {
    width: 100%;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
  }

  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  button {
    padding: ${({ theme }) => theme.spacing.xs};

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }
  }
`;

export const StyledScheduledConferenceIcon = styled.div`
  min-width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
`;

export const StyledScheduledConferenceIconDay = styled.div`
  font-size: 0.5rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.Surface};
  background-color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-family: ${p => p.theme.fonts.Semibold};
`;

export const StyledScheduledConferenceIconMonth = styled.div`
  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
  letter-spacing: 0.04em;
  background-color: ${({ theme }) => theme.colors.Surface};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  border-color: ${({ theme }) => theme.colors.BorderDefault};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
`;

export const StyledScheduledConferenceTitle = styled.span`
  font-size: 0.875rem;
  line-height: 1.43;
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const StyledScheduledConferenceDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.14;
  font-family: ${p => p.theme.fonts.Medium};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  span {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  svg {
    margin-right: 0.375rem;
  }
`;

export const DeleteMenuItem = styled.div`
  color: ${p => p.theme.colors.CriticalDark};
`;
