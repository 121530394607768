import { database } from '../../../../Database';

export const getAccountDesktopsByIdFromIDDB = async (
  accountId: string,
  workspaceId: string,
) => {
  try {
    return database.desktops
      .where({ relatedAccounts: accountId, workspaceId })
      .toArray();
  } catch (e) {
    console.error(e);
    return [];
  }
};
