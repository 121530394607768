import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetGoogleAuthUrlQuery } from '.';
import { appEnv } from '../../appEnv';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';
import { BrowserGoogleAuthStatusContext } from './BrowserGoogleAuthStatus.context';
import {
  GoogleAuthStatusInBrowserType,
  GoogleAuthStatusTypes,
} from './BrowserGoogleAuthStatus.types';

/* HARDCORE DETECTED: Remove from context when we will have possibility to check if user is authorized in google (in BROWSER)*/
export const BrowserGoogleAuthStatusProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [
    browserGoogleAuthorizationStatus,
    setBrowserGoogleAuthorizationStatus,
  ] = useState<GoogleAuthStatusTypes>(GoogleAuthStatusTypes.initialization);

  const [browserGoogleAuthUrl, setBrowserGoogleAuthUrl] = useState<string>('');

  const { data } = useGetGoogleAuthUrlQuery({
    skip: !!browserGoogleAuthUrl,
    variables: {
      workspace: currentWorkspace?.id,
      scopeType: 'drive',
    },
  });

  useEffect(() => {
    if (data?.googleAuthUrl?.authUrl && !browserGoogleAuthUrl) {
      setBrowserGoogleAuthUrl(data.googleAuthUrl.authUrl);
    }
  }, [data, browserGoogleAuthUrl]);

  const clearGoogleAuthStatusInBrowser = useCallback(() => {
    setBrowserGoogleAuthorizationStatus(GoogleAuthStatusTypes.initialization);
  }, []);

  const contextValue: GoogleAuthStatusInBrowserType = useMemo(
    () => ({
      clearGoogleAuthStatusInBrowser,
      browserGoogleAuthorizationStatus,
      browserGoogleAuthUrl,
    }),
    [
      clearGoogleAuthStatusInBrowser,
      browserGoogleAuthorizationStatus,
      browserGoogleAuthUrl,
    ],
  );

  return (
    <BrowserGoogleAuthStatusContext.Provider value={contextValue}>
      {children}
      {browserGoogleAuthorizationStatus ===
      GoogleAuthStatusTypes.initialization ? (
        <img
          alt=""
          style={{ display: 'none' }}
          onLoad={() =>
            setBrowserGoogleAuthorizationStatus(
              GoogleAuthStatusTypes.authorized,
            )
          }
          onError={() =>
            setBrowserGoogleAuthorizationStatus(
              GoogleAuthStatusTypes.unauthorized,
            )
          }
          src={appEnv.GOOGLE_AUTH_CHECKER_URL}
        />
      ) : null}
    </BrowserGoogleAuthStatusContext.Provider>
  );
};
