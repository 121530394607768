import React, { FC, useMemo } from 'react';
import Select from 'react-select';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import { useAppStoreApps } from '../../../../AppStore/AppStore.hooks';
import { extractNodes } from '../../../../../shared/api/api.utils';
import {
  StyledOptionInner,
  StyledVaultItemGroupSelect,
  StyledVaultItemGroupSelectDescription,
  StyledVaultItemGroupSelectImage,
} from './VaultItemGroupSelect.styled';
import { FormattedHTMLMessage } from 'react-intl';
import { EncryptionTranslation } from '../../../i18n';
import { appEnv } from '../../../../../appEnv';
import { useTheme } from 'styled-components';

interface VaultItemGroupSelectProps {
  onChange: (appId?: string) => void;
}

export const VaultItemGroupSelect: FC<VaultItemGroupSelectProps> = ({
  onChange,
}) => {
  const { data } = useAppStoreApps({
    variables: {
      filterUsedByMe: false,
      category: null,
      workspace: null,
      filterFreeTextSearch: null,
      custom: null,
      filterExistVaultItemsByWorkspace: null,
      filterOnlyGroupApps: true,
    },
  });
  const theme = useTheme();

  const options = useMemo(
    () =>
      extractNodes(data?.apps).map(app => ({
        label: app.name,
        value: app.id,
        logo: app.logo,
      })),
    [data],
  );

  const CustomOption = (props: any) => {
    const { innerProps, isDisabled, label, data } = props;

    return !isDisabled ? (
      <StyledOptionInner {...innerProps}>
        <StyledVaultItemGroupSelectImage src={data.logo.contentUrl} />
        <span>{label}</span>
      </StyledOptionInner>
    ) : null;
  };

  const selectStyles = {
    menu: (provided: any) => ({
      ...provided,
      background: theme.colors.Background,
    }),
    control: (provided: any) => ({
      ...provided,
      background: theme.colors.Background,
    }),
    input: (provided: any) => ({
      ...provided,
      color: theme.colors.OnSurface,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.colors.PlaceholderTextColor,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: theme.colors.OnSurface,
    }),
  };

  return (
    <StyledVaultItemGroupSelect data-testid="group-select-panel">
      <ImportantMessage type={ImportantMessageType.INFO}>
        <StyledVaultItemGroupSelectDescription data-testid="group-select-important-message">
          <FormattedHTMLMessage
            id={EncryptionTranslation.vaultItemGroupSelectDescription}
            values={{
              clickHereLink: appEnv.SUPPORT_PASSWORD_GROUPS_URL,
            }}
          />
        </StyledVaultItemGroupSelectDescription>
        <Select
          styles={selectStyles}
          components={{ Option: CustomOption }}
          options={options}
          onChange={option => onChange(option?.value)}
        />
      </ImportantMessage>
    </StyledVaultItemGroupSelect>
  );
};
