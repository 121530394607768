import React, { memo, useEffect } from 'react';
import { StyledChatView } from './ChatView.styled';
import { ConversationView } from './ConversationView';
import { useCurrentTypeOfPage } from '../../Desktop/Desktop.utils';
import { ConversationHeader } from './ConversationHeader';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { useLocation } from 'react-router-dom';
import { SegmentType, useSetLastUsedSegmentPath } from '../../Segment';
import { SegmentPageTitleBar } from '../../Segment/SegmentPageTitleBar';

interface ChatViewProps {
  withHeader?: boolean;
}

export const ChatView = memo<ChatViewProps>(({ withHeader = true }) => {
  const { isChatsPage } = useCurrentTypeOfPage();
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const { embeddedView } = queryParams;
  const { pathname } = useLocation();
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.CHATS);

  useEffect(() => {
    if (isChatsPage) {
      setLastSegmentPath(pathname);
    }
  }, [isChatsPage, pathname, setLastSegmentPath]);

  return (
    <>
      <StyledChatView
        withHeader={withHeader && !embeddedView}
        isChatsPage={isChatsPage}
        isMobile={isMobile}
        data-testid="chat-view">
        {withHeader && !embeddedView && (
          <SegmentPageTitleBar>
            <ConversationHeader />
          </SegmentPageTitleBar>
        )}
        <ConversationView />
      </StyledChatView>
    </>
  );
});
