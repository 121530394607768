import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import { StyledAppsList } from './VaultItem.styled';
import { AppListItem } from './AppListItem/AppListItem';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultLoginsMap,
} from '../../../Encryption.hooks';
import { VaultApiType } from '../../../Encryption.types';
import { getVaultApp, getVaultItem } from '../../../Encryption.utils';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../../../Workspace/Workspace.hooks';
import { PASSWORDS_VAULT_PATHNAME } from '../../../../Desktop/Desktop.constants';
import { getShortId } from '../../../../../shared/utils/id';
import {
  getQueryParamsFrom,
  openUrl,
} from '../../../../../shared/utils/url.utils';
import {
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../shared/hooks';
import { getDecryptedVaultPassword } from '../../../Encryption.crypto.utils';
import { sendCredentialsToInsert } from '../../../../Desktop/Desktop.utils';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { getGoogleMultipleAccountsOpenUri } from '../../../../AppStore/AppStore.utils';
import { DesktopAppGroupType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

interface VaultItemProps {
  vault: VaultApiType;
  renderItemButtons?: boolean;
  style?: CSSProperties;
}

export const ListVaultItem: FC<VaultItemProps> = ({
  vault,
  renderItemButtons,
  style,
}) => {
  const { pathname } = useLocation();
  const [appsListRef] = useHideScrollbarWhenIdle();
  const navigate = useNavigate();
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const queryParams = useQueryParams();
  const { loginsMap } = useCurrentWorkspaceVaultLoginsMap();
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();

  const handleSelectVault = (vault: VaultApiType) => {
    mobileNavigationSidebarClose();
    navigate({
      pathname: generatePath(PASSWORDS_VAULT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        vaultId: getShortId(vault.id),
      }),
      search: getQueryParamsFrom({
        ...queryParams,
        ...(isMobile || isTablet ? { passwordPageModal: true } : {}),
      }),
    });
  };

  const handleOpenApp = (vault: VaultApiType) => {
    const app = getVaultApp(vault);
    const vaultItem = getVaultItem(vault);
    if (vaultItem?.url) {
      openUrl(account!.identity.openApp, vaultItem.url, vaultItem.url);
      fillInCredentials(vault);
    } else if (app) {
      const supportMultipleAccounts = app.id === DesktopAppGroupType.google;
      const multipleAccountsAppOpenUri = supportMultipleAccounts
        ? getGoogleMultipleAccountsOpenUri(app.openUri, loginsMap[vault.id])
        : null;

      const appOpenUri = multipleAccountsAppOpenUri ?? app.loginUri;
      openUrl(account!.identity.openApp, appOpenUri, app.name);
      fillInCredentials(vault);
    }
  };

  const currentVaultId = useMemo(() => {
    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    return match.params.vaultId;
  }, [pathname]);

  const fillInCredentials = useCallback(
    (vault: VaultApiType) => {
      if (vault && keysetId && privateKey) {
        const app = getVaultApp(vault);
        const vaultItem = getVaultItem(vault);

        const loginUrl = vaultItem?.url || app?.loginUri || '';
        const login = loginsMap?.[vault.id] || '';

        getDecryptedVaultPassword(vault, keysetId, privateKey).then(
          password => {
            if (login || password) {
              sendCredentialsToInsert(
                loginUrl,
                login,
                password || '',
                app?.loginUriPattern,
              );
            }
          },
        );
      }
    },
    [keysetId, privateKey, loginsMap],
  );

  return (
    <StyledAppsList
      ref={appsListRef}
      style={style}
      isMobile={isMobile}
      data-testid="vault-item">
      {privateKey && (
        <AppListItem
          vault={vault}
          key={vault.id}
          onSelect={() => handleSelectVault(vault)}
          onOpenApp={() => handleOpenApp(vault)}
          isActive={getShortId(vault.id) === currentVaultId}
          renderItemButtons={renderItemButtons}
        />
      )}
    </StyledAppsList>
  );
};
