import { TeamApiType } from '../../../../Team/data/Team/types/Team.types';
import { AccountApiType } from '../../../../User/User.types';
import { getAccountName } from '../../../../User/User.utils';

export const shouldShowOption = (
  accountOrTeam: AccountApiType | TeamApiType,
  rawInput: string,
) => {
  const searchTerm = rawInput.toLowerCase();
  if ('name' in accountOrTeam) {
    return accountOrTeam.name.toLowerCase().includes(searchTerm);
  }
  const accountEmail = accountOrTeam?.email;
  if (accountEmail?.includes(searchTerm)) {
    return true;
  }
  const accountName = (getAccountName(accountOrTeam) || '').toLowerCase();
  return accountName.includes(searchTerm);
};
