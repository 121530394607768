import styled from 'styled-components';

export const StyledAvatarPlaceholder = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  background: ${p => p.bgColor || p.theme.colors.Surface};
  border: 1px solid ${p => p.bgColor || p.theme.colors.BorderDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 0.375em;
`;
