import { gql } from '@apollo/client';
import { LinkDataFragmentFields } from '../Link/Link.fragments';

export const GET_LINK_DATA_SYNC = gql`
  query GetLinkDataSync($ids: Iterable!, $workspaceIri: ID!) {
    listLinkDatas(ids: $ids, workspaceIri: $workspaceIri) {
      ${LinkDataFragmentFields}
    }
  }
`;
