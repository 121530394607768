import React, { FC, useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useIntl } from 'react-intl';
import { StyledConfigurationTutorial } from './ConfigurationTutorial.styled';
import { CopyableBlock } from './CopyableBlock';
import { LightTheme } from '../../../../../../Theme';
import { AppStoreTranslation } from '../../../../../i18n';
import { SSO_CONFIG_FIELD_PREFIX } from '../Configuration.constants';
import { createRoot } from 'react-dom/client';

const getAnchorElement = (
  eventTarget: EventTarget | null,
): HTMLAnchorElement | null => {
  if (!eventTarget) {
    return null;
  }
  return (eventTarget as HTMLElement).nodeName === 'A'
    ? (eventTarget as HTMLAnchorElement)
    : null;
};

interface ConfigurationTutorialProps {
  tutorial: string;
}

export const ConfigurationTutorial: FC<ConfigurationTutorialProps> = ({
  tutorial,
}) => {
  const tutorialRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    const preBlocks = tutorialRef.current?.querySelectorAll('pre');
    const renderedRoots = Array.from(preBlocks || []).map(preBlock => {
      const componentRoot = document.createElement('div');
      const root = createRoot(componentRoot!);
      root.render(
        <ThemeProvider theme={LightTheme}>
          <CopyableBlock
            buttonText={intl.formatMessage({
              id: AppStoreTranslation.ssoAppCardLabelsCopy,
            })}>
            {preBlock.innerText}
          </CopyableBlock>
        </ThemeProvider>,
      );
      preBlock.replaceWith(componentRoot);
      return root;
    });
    return () => {
      renderedRoots.forEach(root => {
        root.unmount();
      });
    };
  }, [intl, tutorial]);

  useEffect(() => {
    const tutorialNode = tutorialRef.current;
    const handleAnchorClick = (e: MouseEvent) => {
      const anchorNode = getAnchorElement(e.target);
      if (!anchorNode) {
        return;
      }

      const hrefAttr = anchorNode.getAttribute('href') || '';
      if (!hrefAttr.startsWith(`#${SSO_CONFIG_FIELD_PREFIX}`)) {
        return;
      }

      e.preventDefault();
      const inputNode = document.querySelector<HTMLInputElement>(hrefAttr);
      if (!inputNode) {
        return;
      }

      inputNode.focus();
      inputNode.parentElement?.parentElement?.scrollIntoView();
    };
    tutorialNode?.addEventListener('click', handleAnchorClick);
    return () => {
      tutorialNode?.removeEventListener('click', handleAnchorClick);
    };
  }, []);

  return (
    <StyledConfigurationTutorial>
      <div
        ref={tutorialRef}
        className="tutorial"
        dangerouslySetInnerHTML={{ __html: tutorial }}
      />
    </StyledConfigurationTutorial>
  );
};
