import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalProps,
} from '../../../../../../shared/components/Modal';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CreateAppIntegrationCredentialForm } from './CreateAppIntegrationCredentialForm';
import { AppIntegrationsTranslation } from '../../../../i18n';

export const CreateAppIntegrationCredentialModal: FC<
  Pick<ModalProps, 'onRequestClose' | 'containerZIndex'> & {
    onDone: () => void;
  }
> = ({ ...modalProps }) => {
  return (
    <Modal visible={true} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage
          id={
            AppIntegrationsTranslation.createAppIntegrationCredentialModalTitle
          }
        />
      </ModalHeader>
      <ModalContent>
        <FormattedHTMLMessage
          id={AppIntegrationsTranslation.createAppIntegrationCredentialJiraLink}
          values={{
            jiraLink:
              'https://id.atlassian.com/manage-profile/security/api-tokens',
          }}
        />
        <CreateAppIntegrationCredentialForm
          onClose={modalProps.onRequestClose}
          onDone={modalProps.onDone}
        />
      </ModalContent>
    </Modal>
  );
};
