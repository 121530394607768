import React, { FC, memo } from 'react';
import { usePrepareMessageTextSpaces } from './RichMessage.hooks';

export const RichMessageText: FC<{
  value: string;
}> = memo(({ value }) => {
  const preparedMessage = usePrepareMessageTextSpaces(value);

  return <>{preparedMessage}</>;
});
