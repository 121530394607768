import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { CreateDesktopForm } from '../../../DesktopForm';
import { DesktopTranslation } from '../../../i18n';
import { useDesktopTrackingEventOnInit } from '../../../tracking/DesktopTracking.hooks';
import { DesktopApiType } from '../../../data/Desktop/types/Desktop.types';

interface CreateDesktopModalProps extends ModalProps {
  onDone: (desktop?: DesktopApiType) => void;
}

export const CreateDesktopModal: FC<CreateDesktopModalProps> = ({
  onRequestClose,
  onDone,
  ...props
}) => {
  useDesktopTrackingEventOnInit('open_create_desktop_admin');

  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage
          id={DesktopTranslation.createInternalDesktopModalHeader}
        />
      </ModalHeader>
      <CreateDesktopForm
        onDone={onDone}
        onlyInternalDesktopCreation
        onRequestClose={onRequestClose}
      />
    </Modal>
  );
};
