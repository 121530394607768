import React, { FC, PropsWithChildren } from 'react';
import { AssetPreviewProvider } from '../Asset/AssetPreviewProvider';
import { ConversationDraftProvider } from './ChatView/ConversationDraft/ConversationDraft.provider';
import { ConversationStateProvider } from './ChatView/ConversationState';
import { ChatMessageProvider } from './ChatView/ConversationView/ChatMessage/ChatMessage.provider';
import { ChatMessageThreadSidebarProvider } from './ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.provider';
import { ConversationViewProvider } from './ChatView/ConversationView/ConversationView.provider';
import { MessageListDataProvider } from './ChatView/ConversationView/MessagesList/MessageListProvider/MessageList.provider';
import { EmojiPickerProvider } from './EmojiPickerPopover';

export const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConversationViewProvider>
      <ConversationStateProvider>
        <MessageListDataProvider>
          <AssetPreviewProvider>
            <ChatMessageProvider>
              <ChatMessageThreadSidebarProvider>
                <EmojiPickerProvider>
                  <ConversationDraftProvider>
                    {children}
                  </ConversationDraftProvider>
                </EmojiPickerProvider>
              </ChatMessageThreadSidebarProvider>
            </ChatMessageProvider>
          </AssetPreviewProvider>
        </MessageListDataProvider>
      </ConversationStateProvider>
    </ConversationViewProvider>
  );
};
