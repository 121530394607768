import { appEnv } from '../../../appEnv';

export const isFileSizeWithinLimit = (file: File) => {
  if (!file) {
    return true;
  }

  const uploadFileSize = (file.size / 1024 / 1024).toFixed(3);
  return Number(uploadFileSize) <= (appEnv.MAX_UPLOAD_FILE_SIZE || 250);
};
