import React from 'react';
import type { ChatMessageListContextType } from '../MessageList.types';

export const MessageListContext =
  React.createContext<ChatMessageListContextType>({
    addUnreadMessage: () => {},
    setListReady: () => {},
    unreadMessages: [],
    listReady: false,
    setIsAtBottom: () => {},
    isAtBottom: false,
    setVirtualListRef: () => {},
    virtualListRef: null,
  });
