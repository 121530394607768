import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { FolderTranslation } from '../../i18n';
import { CreateFolder } from '../../FolderForm';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';

interface CreateFolderModalProps
  extends Omit<ModalProps, 'visible' | 'onRequestClose'> {
  parentName: string;
}

const closeModalDelay = 30;

export const CreateFolderModal: FC<CreateFolderModalProps> = ({
  parentName,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const onRequestClose = () => {
    setTimeout(() => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          createFolder: undefined,
        }),
      });
    }, closeModalDelay);
  };

  return (
    <Modal visible={true} width={588} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={FolderTranslation.createFolderModalHeader} />{' '}
        {parentName}
      </ModalHeader>
      <CreateFolder onDone={onRequestClose} />
    </Modal>
  );
};
