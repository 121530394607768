import React, { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { Modal, ModalHeaderColor } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import { StyledScheduleRepeatingConferenceModal } from './ScheduleRepeatingConferenceModal.styled';
import { ScheduleRepeatingConferenceForm } from '../ScheduleRepeatingConferenceForm';
import { ScheduleRepeatingConferenceModalProps } from '../../Conference.types';

export const ScheduleRepeatingConferenceModal: FC<
  ScheduleRepeatingConferenceModalProps
> = ({ data, onSubmit, onClose, selectedDate }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const modalBackgroundColor = theme.colors.Surface;
  const modalTextColor = theme.colors.OnSurfaceHighEmphasis;

  const handleOnClose = useCallback(() => {
    onClose();
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleRepeatingConferenceOpened: undefined,
      }),
    });
  }, [navigate, onClose, queryParams]);

  return (
    <Modal
      onRequestClose={handleOnClose}
      visible
      width={350}
      containerZIndex={26}>
      <StyledScheduleRepeatingConferenceModal>
        <ModalHeaderColor
          onRequestClose={handleOnClose}
          backgroundColor={modalBackgroundColor}
          textColor={modalTextColor}>
          <FormattedMessage
            id={
              ConferenceTranslation.scheduleRepeatingConferenceCustomRecurrence
            }
          />
        </ModalHeaderColor>
        <div className="divider" />
        <ScheduleRepeatingConferenceForm
          data={data}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          selectedDate={selectedDate}
        />
      </StyledScheduleRepeatingConferenceModal>
    </Modal>
  );
};
