import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { isPWAWindow } from '../../../../shared/utils/pwa.utils';
import { PwaForceReinstallScreen } from '../../components/PwaForceReinstallScreen';
import { useInstallAppEvent } from '../../../InstallApp/InstallApp.hooks';
import { captureMessage } from '@sentry/react';

export const PwaDomainCheckProvider: FC<PropsWithChildren> = ({ children }) => {
  const [displayForceReinstallMessage, setDisplayForceReinstallMessage] =
    useState(false);
  const { installEvent } = useInstallAppEvent();

  useEffect(() => {
    // App is opened in the PWA window
    // which was installed from the old domain
    if (installEvent && isPWAWindow()) {
      captureMessage('[PWA] Forced reinstall');
      setDisplayForceReinstallMessage(true);
    }
  }, [installEvent]);

  if (displayForceReinstallMessage) {
    return <PwaForceReinstallScreen />;
  }

  return <>{children}</>;
};
