import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  DeleteMultipleVaultsControls,
  DeleteMultipleVaultsInputWrapper,
} from './DeleteMultipleVaults.styled';
import { EncryptionTranslation } from '../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  useBatchRemoveVaultItemMutation,
  useVaultsListUpdate,
  useWorkspaceVaultsByIds,
} from '../Encryption.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { getVaultItem } from '../Encryption.utils';
import { Input } from '../../../shared/components/Input';
import { DELETE_MULTIPLE_VAULTS_INPUT_VALUE } from '../Encryption.constants';

interface DeleteMultipleVaultsProps {
  vaultIds: string[];
  onDeleted?: (ids: string[]) => void;
  onCancelled?: () => void;
}

export const DeleteMultipleVaults: FC<DeleteMultipleVaultsProps> = ({
  vaultIds,
  onDeleted,
  onCancelled,
}) => {
  const { vaults } = useWorkspaceVaultsByIds(vaultIds);
  const vaultItemIds: string[] = useMemo(() => {
    return vaults?.map(vault => getVaultItem(vault)?.id);
  }, [vaults]);
  const [isDeleting, setDeleting] = useState(false);
  const [deleteVaultsInputValue, setDeleteVaultsInputValue] = useState('');
  const { removeVault } = useVaultsListUpdate();

  useEffect(() => {
    if (!vaultItemIds?.length && onCancelled) {
      onCancelled();
    }
  }, []); // eslint-disable-line

  const [batchRemoveVaultItemMutation] = useBatchRemoveVaultItemMutation();
  const deleteVaultItems = () => {
    setDeleting(true);
    return batchRemoveVaultItemMutation({
      variables: {
        input: {
          vaultItems: vaultItemIds,
        },
      },
    })
      .then(
        res => {
          if (res?.data) {
            showToastSuccessMessage(
              EncryptionTranslation.editVaultFormSuccessMessage,
            );
            vaultItemIds.forEach(id => {
              removeVault(
                vaults.find(vault => getVaultItem(vault)?.id === id)!.id,
              );
            });
            if (onDeleted) {
              onDeleted(vaultItemIds);
            }
          }
        },
        e => {
          showToastGraphQLErrors(e.graphQLErrors);
        },
      )
      .catch(e => showToastGraphQLErrors(e))
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <FormattedMessage
        id={EncryptionTranslation.deleteMultipleVaultsDescription}
        values={{ vaultsCount: vaultIds?.length }}
      />
      <DeleteMultipleVaultsInputWrapper>
        <FormattedHTMLMessage
          id={EncryptionTranslation.deleteMultipleVaultsInputDescription}
        />
        <Input
          value={deleteVaultsInputValue}
          onChange={e => {
            setDeleteVaultsInputValue(e.target.value);
          }}
          data-testid="delete-vaults-input"
        />
      </DeleteMultipleVaultsInputWrapper>
      <DeleteMultipleVaultsControls>
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={isDeleting}
          onClick={onCancelled}
          data-testid="cancel-button">
          <FormattedMessage
            id={EncryptionTranslation.deleteMultipleVaultsCancelButton}
          />
        </Button>
        <Button
          mode={ButtonMode.danger}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={
            isDeleting ||
            deleteVaultsInputValue !== DELETE_MULTIPLE_VAULTS_INPUT_VALUE
          }
          onClick={deleteVaultItems}
          data-testid="delete-button">
          <FormattedMessage
            id={EncryptionTranslation.deleteMultipleVaultsDeleteButton}
            values={{ vaultsCount: vaultIds?.length }}
          />
        </Button>
      </DeleteMultipleVaultsControls>
    </>
  );
};
