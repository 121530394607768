import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { StyledAvatarPlaceholder } from '../../../ChatAvatar/AvatarPlaceholder/AvatarPlaceholder.styled';
import { HIGHLIGHTED_CLASS_NAME } from './ChatMessage.constants';
import { AVATAR_PLACEHOLDER_WIDTH } from '../../../ChatAvatar';
import { MessageType } from '../../../Chat.types';

export const StyledChatMessage = styled.div<{
  secondary: boolean;
  withReaction: boolean;
  isMobile: boolean;
  messageType: MessageType;
}>`
  position: relative;
  padding: ${({ isMobile }) => (isMobile ? '1.5rem 1rem' : '0.5rem 1rem')};

  ${StyledAvatarPlaceholder} {
    font-size: 1rem;
  }

  .avatar {
    cursor: pointer;
  }

  &.${HIGHLIGHTED_CLASS_NAME} {
    background-color: ${p => p.theme.colors.BackgroundLight};
  }

  &:hover {
    background-color: ${p => p.theme.colors.OwnMessageBubble};

    ${p =>
      p.withReaction &&
      !p.isMobile &&
      css`
        .chat-message-actions {
          display: flex;
        }
      `}

    .thread-chat-message {
      background-color: unset;
    }
  }
`;

export const MessageContainer = styled.div<{ secondary: boolean }>`
  display: flex;
  padding-left: ${p => (p.secondary ? `${AVATAR_PLACEHOLDER_WIDTH}rem` : '0')};
`;

export const MessageBody = styled.div`
  padding-left: 0.5rem;
  min-width: 180px;
  width: 100%;
`;

export const StyledMessageOwnerName = styled.div`
  font-size: 1rem;
  font-family: ${p => p.theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  display: flex;
  overflow: hidden;
`;

export const StyledMessageDate = styled.div`
  display: flex;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  white-space: nowrap;
  margin-left: 0.375rem;
`;

export const MessageItemContainer = styled.div<{
  isMobile: boolean;
  visible?: boolean;
  isSystem?: boolean;
  withAsset?: boolean;
}>`
  display: ${p => (p.visible ? 'none' : 'flex')};
  flex-wrap: ${p => (p.withAsset ? 'wrap' : 'nowrap')};

  ${p =>
    p.isSystem &&
    css`
      font-family: ${p => p.theme.fonts.Medium};
    `}

  & + & {
    margin-top: 5px;
  }

  &:not(:hover) .menu-trigger {
    opacity: ${p => (p.isMobile ? 1 : 0)};
    pointer-events: none;
  }

  .menu-trigger {
    padding-left: 0.25rem;
  }
`;

export const MessageBubble = styled.div<{ ownMessage: boolean }>`
  min-height: 1.375rem;
  padding: 0 0.75rem;
  border-radius: 1rem;

  font-size: 1rem;
  white-space: pre-line;
  word-break: break-word;
  line-height: 1.375rem;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const MessageModifiedLabel = styled.span`
  font-size: 0.75rem;
  font-family: ${p => p.theme.fonts.Medium};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const AssetBubbleContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.25rem;
  &:last-child {
    padding-bottom: 0;
  }
`;

export const AssetBubble = styled.div<{
  ownMessage: boolean;
  isSingleImageAsset: boolean;
}>`
  position: relative;
  padding: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${p =>
    p.ownMessage
      ? p.theme.colors.OwnMessageBubble
      : p.theme.colors.SurfaceSubdued};

  ${p =>
    p.isSingleImageAsset
      ? css`
          width: 8rem;
          height: 8rem;
        `
      : css`
          max-width: 26rem;
          width: 100%;
          min-height: 94px;
          max-height: 20rem;
        `}
`;

export const ChatDate = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  height: ${({ isMobile }) => (isMobile ? '1.625rem' : 'unset')};
  line-height: 1.25rem;

  span,
  svg {
    padding-right: 0.5rem;
  }

  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const ChatMessageMenuButton = styled(ButtonWithIcon).attrs({
  className: 'menu-trigger',
})`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.15s ease;
  align-self: center;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &:focus {
    opacity: 1 !important;
  }
`;

export const StyledAccountName = styled.span`
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IntegrationBadgeContainer = styled.div`
  padding-left: 0.25rem;
`;

export const MessageActions = styled.div`
  position: absolute;
  top: 0;
  right: 0.625rem;
  display: flex;
`;

export const StyledChatMessageActions = styled.div<{
  isMobile: boolean;
}>`
  display: ${p => (p.isMobile ? 'flex' : 'none')};
  align-items: center;
  background-color: ${p => p.theme.colors.Surface};
  position: absolute;
  top: -0.85rem;
  right: 1rem;
  border: 1px solid ${p => p.theme.colors.SurfaceBorder};
  border-radius: 0.5rem;
  padding: 0.25rem;
`;

export const SystemChatMessage = styled.span`
  h1,
  h2 {
    line-height: 1;
  }
  b {
    font-family: ${p => p.theme.fonts.Semibold};
  }

  highlight,
  quote {
    padding: 0.2rem 0.3rem 0.1rem 0.3rem;
  }

  highlight {
    background: ${p => p.theme.colors.OnSurfacePressed};
    border: 1px solid;
    display: block;
  }

  quote {
    background: ${p => p.theme.colors.OnSurfacePressed};
  }
`;

export const StyledUnreadBadge = styled.div<{
  show: boolean;
}>`
  position: relative;
  width: 0;
  height: 0;
  top: -1px;
  flex-shrink: 0;
  padding-left: 0.75rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 2s;

  &::after {
    content: '';
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${p => p.theme.colors.Primary};
    transform-origin: center;
    position: absolute;
    transform: translate(-0.25rem, -0.25rem) scale(1);
  }
`;
