import styled, { css, DefaultTheme } from 'styled-components';
import {
  LANDSCAPE_MAX_HEIGHT,
  LANDSCAPE_MAX_HEIGHT_MOBILE,
  LANDSCAPE_WIDTH,
  MOBILE_MAX_ASSET_TO_VIEW,
  MOBILE_MULTIPLE_IMAGE_SIZE,
  MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT,
  MOBILE_PORTRAIT_HEIGHT,
  MULTIPLE_IMAGE_HEIGHT,
  MULTIPLE_LANDSCAPE_WIDTH,
  MULTIPLE_PORTRAIT_WIDTH,
  PORTRAIT_HEIGHT,
  SQUARE_MIN_SIZE,
} from './AssetView.constants';
import { ImageOrientationType } from './AssetView.types';

const AssetViewMultipleImage = `
  max-height: ${LANDSCAPE_MAX_HEIGHT}px;
  height: 100%;
  overflow: hidden;
`;

const AssetViewMultipleLandscape = (isMobile: boolean) => `
  max-width: ${LANDSCAPE_WIDTH}px;
  width: 100%;
  height: ${isMobile ? 'unset' : `${LANDSCAPE_MAX_HEIGHT}px`};
`;

const AssetViewMultiplePortrait = (isMobile: boolean) => `
  max-width: ${MULTIPLE_PORTRAIT_WIDTH}px;
  width: 100%;
  height: ${isMobile ? 'auto' : `${MULTIPLE_IMAGE_HEIGHT}px`};
`;

const AssetViewPortait = (isMobile: boolean) => `
  height: ${
    isMobile ? `${MOBILE_PORTRAIT_HEIGHT}px` : `${PORTRAIT_HEIGHT}px !important`
  };
  width: auto;
`;

const AssetViewLandscape = `
  max-width: ${LANDSCAPE_WIDTH}px;
  width: 100%;
  height: ${LANDSCAPE_MAX_HEIGHT}px;
`;

const AssetViewLandscapeMobile = `
  max-width: ${LANDSCAPE_WIDTH}px;
  width: 100%;
  height: ${LANDSCAPE_MAX_HEIGHT_MOBILE}px;
`;

const AssetViewSquare = `
  height: ${SQUARE_MIN_SIZE}px !important;
  max-width: ${SQUARE_MIN_SIZE}px;
  width: 100%;
`;

const SquareScale = (naturalWidth: number) => {
  if (naturalWidth < SQUARE_MIN_SIZE) {
    return css`
      object-fit: scale-down;
    `;
  }
};

const AssetViewImageContainerMobile = (
  isMobile: boolean,
  imageCount: number,
  multipleImage: boolean,
) => {
  const imageWithCount = imageCount > MOBILE_MAX_ASSET_TO_VIEW;

  return (
    multipleImage &&
    isMobile &&
    css`
      min-width: ${imageWithCount
        ? MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT
        : MOBILE_MULTIPLE_IMAGE_SIZE}px;
      min-height: ${imageWithCount
        ? MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT
        : MOBILE_MULTIPLE_IMAGE_SIZE}px;
      max-width: ${imageWithCount
        ? MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT
        : MOBILE_MULTIPLE_IMAGE_SIZE}px;
      max-height: ${imageWithCount
        ? MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT
        : MOBILE_MULTIPLE_IMAGE_SIZE}px;
    `
  );
};

const AssetViewUnidentified = (isMobile: boolean) => `
  width: ${LANDSCAPE_WIDTH}px;
  object-fit: ${isMobile ? 'cover' : 'contain'};
`;

const AssetViewSize = (
  orientation: ImageOrientationType,
  multipleImage: boolean,
  isMobile: boolean,
) => {
  switch (orientation) {
    case ImageOrientationType.Portrait:
      return multipleImage
        ? css`
            ${AssetViewMultipleImage}
            ${AssetViewMultiplePortrait(isMobile)}
          `
        : css`
            ${AssetViewPortait(isMobile)}
          `;
    case ImageOrientationType.Landscape:
      return multipleImage
        ? css`
            ${AssetViewMultipleImage}
            ${AssetViewMultipleLandscape(isMobile)}
          `
        : isMobile
        ? css`
            ${AssetViewLandscapeMobile}
          `
        : css`
            ${AssetViewLandscape}
          `;
    case ImageOrientationType.Square:
      return multipleImage
        ? css`
            ${AssetViewSquare}
            ${AssetViewMultipleImage}
          `
        : css`
            ${AssetViewSquare}
          `;
    case ImageOrientationType.Unidentified:
    default:
      return css`
        ${AssetViewUnidentified(isMobile)}
        ${AssetViewMultipleImage}
        ${AssetViewMultipleLandscape(isMobile)}
      `;
  }
};

export const AssetViewImageStyled = styled.img<{
  orientation: ImageOrientationType;
  multipleImage: boolean;
  naturalWidth: number;
  naturalHeight: number;
  isMobile: boolean;
  imageLoaded: boolean;
}>`
  border-radius: 0.375rem;
  cursor: zoom-in;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const AssetViewImageContainerBorder = (
  theme: DefaultTheme,
  naturalWidth: number,
  naturalHeight: number,
  multiple: boolean,
  isMobile: boolean,
  orientation: ImageOrientationType,
) => {
  const withBorderByWidth =
    multiple &&
    naturalWidth <
      (MULTIPLE_LANDSCAPE_WIDTH || LANDSCAPE_WIDTH || MULTIPLE_PORTRAIT_WIDTH);

  const withBorderByHeight = multiple && naturalHeight < MULTIPLE_IMAGE_HEIGHT;
  const squareWithBorder = !multiple && naturalWidth < SQUARE_MIN_SIZE;

  if (
    withBorderByWidth ||
    withBorderByHeight ||
    squareWithBorder ||
    isMobile ||
    orientation === ImageOrientationType.Unidentified
  ) {
    return css`
      border: 1px solid ${theme.colors.BorderDefault};
    `;
  }
};

export const AssetViewImageContainerStyled = styled.div<{
  orientation: ImageOrientationType;
  multipleImage: boolean;
  naturalWidth: number;
  naturalHeight: number;
  isMobile: boolean;
  imageCount: number;
  imageLoaded: boolean;
}>`
  position: relative;
  display: flex;
  border-radius: 0.375rem;
  cursor: pointer;
  z-index: 1;

  ${({ naturalWidth }) =>
    !naturalWidth &&
    css`
      height: ${LANDSCAPE_MAX_HEIGHT}px;
    `}

  ${({ orientation, multipleImage, isMobile }) =>
    AssetViewSize(orientation, multipleImage, isMobile)};

  ${({
    theme,
    naturalWidth,
    naturalHeight,
    multipleImage,
    isMobile,
    orientation,
  }) =>
    AssetViewImageContainerBorder(
      theme,
      naturalWidth,
      naturalHeight,
      multipleImage,
      isMobile,
      orientation,
    )}

  ${({ multipleImage }) =>
    multipleImage &&
    css`
      margin-right: 0.25rem;
      margin-top: 0.25rem;
    `}

  ${({ isMobile, imageCount, multipleImage }) =>
    AssetViewImageContainerMobile(isMobile, imageCount, multipleImage)};

  ${({ theme, orientation, multipleImage, isMobile }) =>
    !multipleImage &&
    css`
      background: ${theme.colors.SurfaceBorder};
      ${AssetViewSize(orientation, multipleImage, isMobile)}
    }
    `}

  background: ${p => p.theme.colors.SurfaceBorder};

  max-height: ${p =>
    p.isMobile ? `${MOBILE_PORTRAIT_HEIGHT}px` : `${PORTRAIT_HEIGHT}px`};

  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }
`;

export const AssetViewCounterStyled = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${({ theme }) => theme.colors.PrimaryTextLight};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.LinkHoverBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.188rem;
`;
