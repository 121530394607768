import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useMobile } from '../../../shared/hooks';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import {
  AdminHeader,
  AdminHeaderTitle,
  AdminPage,
  DrawerHeader,
} from '../../Admin';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { NotificationsSettingsForm } from './NotificationsSettingsForm';
import { GeneralSettingsTranslation } from '../i18n';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { GlobalTranslation } from '../../Intl/i18n';
import { WORKSPACE_PATHNAME } from '../../Workspace/Workspace.constants';
import {
  AdminNotificationsSettingsFormValues,
  useUpdateNotificationsSettingsMutation,
} from '.';
import { FormikHelpers } from 'formik';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const NotificationsSettingsAdmin: FC = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { account, refetchAccountData } = useCurrentAccount();
  const [updateNotificationsSettingsMutation] =
    useUpdateNotificationsSettingsMutation();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);
  const notificationSettings = account?.identity.accountIdentitySetting;

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const handleSubmit = useCallback(
    (
      formValues: AdminNotificationsSettingsFormValues,
      formikHelpers: FormikHelpers<AdminNotificationsSettingsFormValues>,
    ) => {
      const { soundsMuted, ...newNotificationSettings } = formValues;
      updateNotificationsSettingsMutation({
        variables: {
          input: {
            ...newNotificationSettings,
            soundsEnable: !soundsMuted,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(GlobalTranslation.toastSaveMessage);
          if (refetchAccountData) {
            refetchAccountData();
          }
          refetchPermissions();
        })
        .then(() => formikHelpers.setSubmitting(false))
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      refetchAccountData,
      refetchPermissions,
      updateNotificationsSettingsMutation,
    ],
  );

  return (
    <AdminPage data-testid="notifications-settings-admin">
      {isMobile ? (
        <DrawerHeader
          onRequestClose={() =>
            navigate(
              generatePath(WORKSPACE_PATHNAME, {
                workspaceId: getShortId(workspace.id),
              }),
            )
          }>
          <FormattedHTMLMessage
            id={GeneralSettingsTranslation.notificationsModalTitle}
          />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage
              id={GeneralSettingsTranslation.notificationsModalTitle}
            />
          </AdminHeaderTitle>
        </AdminHeader>
      )}

      <div>
        {notificationSettings && (
          <NotificationsSettingsForm
            settings={notificationSettings}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </AdminPage>
  );
};
