import React from 'react';
import {
  ConversationStateControls,
  ConversationStateState,
} from './ConversationState.types';

export const ConversationStateContext =
  React.createContext<ConversationStateState>({
    scrollToMessageId: null,
    openThreadOfMessageId: null,
    mountedMessageFormsCount: 0,
  });

export const ConversationStateControlsContext =
  React.createContext<ConversationStateControls>({
    setScrollToMessage: () => {},
    unsetScrollToMessage: () => {},
    setOpenThreadOfMessage: () => {},
    unsetOpenThreadOfMessage: () => {},
    setState: () => {},
  });
