import styled from 'styled-components';

export const StyledConferencesListEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .heading {
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
    margin-top: 1.5rem;
  }

  .subheading {
    font-size: 0.75rem;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
    margin-top: 0.5rem;
  }
`;
