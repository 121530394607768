import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditBillingInformationForm } from '../../BillingForms/EditBillingInformationForm';
import { StyledEditBillingInformationModal } from './EditBillingInformationModal.styled';
import { BillingTranslation } from '../../i18n';
import {
  BillingContactFormValues,
  PaymentCustomerApiType,
} from '../../Billing.types';

interface EditBillingInformationModalProps extends ModalProps {
  subtitle?: string;
  onSubmit: (data: BillingContactFormValues) => void;
  paymentCustomer: PaymentCustomerApiType;
}

export const EditBillingInformationModal: FC<EditBillingInformationModalProps> = ({
  onRequestClose,
  subtitle,
  onSubmit,
  paymentCustomer,
  ...props
}) => {
  return (
    <Modal width={400} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={BillingTranslation.adminBillingInformationModalTitle}
            />
          </ModalHeaderTitle>
        </div>
      </ModalHeader>
      <StyledEditBillingInformationModal>
        <EditBillingInformationForm
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          paymentCustomer={paymentCustomer}
        />
      </StyledEditBillingInformationModal>
    </Modal>
  );
};
