import React, { FC, PropsWithChildren } from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Backends, DndProvider } from 'react-dnd-multi-backend';
import { MouseTransition, TouchTransition } from 'dnd-multi-backend';

const DRAGGING_TOUCH_DELAY_MS = 250;

const HTML5ToTouch: Backends = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {
        delayTouchStart: DRAGGING_TOUCH_DELAY_MS,
        enableMouseEvents: true,
      },
      preview: true,
      transition: TouchTransition,
    },
  ],
} as any;

export const DragAndDropProvider: FC<PropsWithChildren> = ({ children }) => {
  return <DndProvider options={HTML5ToTouch}>{children}</DndProvider>;
};
