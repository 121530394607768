export const combineFirstAndLastNames = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  if (!firstName && !lastName) {
    return '';
  } else if (!firstName || !lastName) {
    return firstName || lastName;
  }

  return `${firstName} ${lastName}`;
};
