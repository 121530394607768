export enum MessagePartTypes {
  Text = 'text',
  Link = 'link',
  Mention = 'mention',
}

type MessagePart<T extends MessagePartTypes> = {
  type: T;
  value: string;
};

export type MessageParts =
  | MessagePart<MessagePartTypes.Text>
  | MessagePart<MessagePartTypes.Link>
  | MessagePart<MessagePartTypes.Mention>;
