import { FC, useEffect } from 'react';

export const SuccessServiceAuth: FC = () => {
  useEffect(() => {
    if (window.close) {
      window.close();
    }
  }, []);

  return null;
};
