import React, { FC } from 'react';
import { Dot, DotWrapper } from './IsTypingContainer.styled';

interface IsTypingContainerProps {}

export const LoadingDots: FC<IsTypingContainerProps> = () => {
  return (
    <DotWrapper data-testid="loading-dots">
      <Dot delay="0s" data-testid="first-dot" />
      <Dot delay=".1s" data-testid="second-dot" />
      <Dot delay=".2s" data-testid="third-dot" />
    </DotWrapper>
  );
};
