import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { SelectVaultContext } from './SelectVault.context';
import { VaultApiType } from '../../Encryption.types';

export const SelectVaultProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedVaults, setSelectedVaults] = useState({});

  const handleSelectPassword = useCallback((key: string) => {
    setSelectedVaults(prevState => ({
      ...prevState,
      [key]: true,
    }));
  }, []);

  const handleUnselectPassword = (key: string) => {
    setSelectedVaults(prevState => {
      const {
        [key]: unselectedKey,
        ...remainingKeys
      }: { [key: string]: true } = prevState;
      return remainingKeys;
    });
  };

  const handleSelectAllPasswords = (vaults: VaultApiType[]) => {
    vaults.forEach(vault => {
      setSelectedVaults(prevState => ({
        ...prevState,
        [vault.id]: true,
      }));
    });
  };

  const handleUnselectAllPasswords = () => {
    setSelectedVaults({});
  };

  return (
    <SelectVaultContext.Provider
      value={{
        selectedVaults: selectedVaults,
        selectVault: handleSelectPassword,
        selectVaults: handleSelectAllPasswords,
        unselectVault: handleUnselectPassword,
        unselectAll: handleUnselectAllPasswords,
      }}>
      {children}
    </SelectVaultContext.Provider>
  );
};
