import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import { EmojiData } from 'emoji-mart';
import {
  EmojiPickerPopover,
  EmojiPickerPopoverProps,
} from './EmojiPickerPopover';
import { EmojiPickerContext } from './EmojiPicker.context';

export const EmojiPickerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [popoverData, setPopoverData] = useState<{
    domRef: RefObject<HTMLElement>;
  }>();

  const promiseHandlersRef = useRef<{
    resolve: (value: EmojiData) => void;
    reject: () => void;
  }>();

  const showEmojiPicker = useCallback((domRef: RefObject<HTMLElement>) => {
    setPopoverData({
      domRef,
    });
    return new Promise<EmojiData>((resolve, reject) => {
      promiseHandlersRef.current = {
        resolve,
        reject,
      };
    });
  }, []);

  const hideEmojiPicker = useCallback(() => {
    setPopoverData(undefined);
    promiseHandlersRef.current?.reject();
  }, []);

  const handleSelectedEmoji: EmojiPickerPopoverProps['onSelect'] = useCallback(
    emoji => {
      promiseHandlersRef.current?.resolve(emoji);
      setPopoverData(undefined);
    },
    [],
  );

  return (
    <EmojiPickerContext.Provider value={{ showEmojiPicker, hideEmojiPicker }}>
      {children}

      {popoverData && (
        <EmojiPickerPopover
          triggerRef={popoverData.domRef}
          onRequestClose={hideEmojiPicker}
          onSelect={handleSelectedEmoji}
        />
      )}
    </EmojiPickerContext.Provider>
  );
};
