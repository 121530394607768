import { CSSProperties } from 'react';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  EDITOR_MIN_HEIGHT,
  MOBILE_EDITOR_MIN_HEIGHT,
} from './MessageForm.constants';

export const StyledMessageFormFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem 0.3rem 0.5rem;
`;

export const StyledMessageForm = styled.div<{
  hasAttachments: boolean;
  isMobile: boolean;
}>`
  border-radius: 0.625rem;
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  cursor: text;
  min-height: ${({ isMobile }) =>
    isMobile ? MOBILE_EDITOR_MIN_HEIGHT : EDITOR_MIN_HEIGHT}px;
  border-radius: ${({ hasAttachments }) =>
    hasAttachments ? '0 0 1.25rem 1.25rem' : '1.25rem'};
`;

export const StyledMessageInputContainer = styled.div`
  padding: 1rem 0.5rem 0rem 1rem;
  display: flex;
  align-items: center;

  input[type='file'] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -999px;
  }
`;

export const SendButton = styled.button`
  margin-left: auto;
  margin-right: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.PrimaryTextLight};
  appearance: none;
  background-color: ${p => p.theme.colors.Primary};
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: flex-end;
  transition: background-color 0.2s ease;
  border-radius: 50%;

  :disabled {
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    background: none;
  }

  :hover:not(:disabled) {
    background-color: ${p => p.theme.colors.PrimaryDark};
  }
`;

export const MessageFormButton = styled(ButtonWithIcon)`
  width: 2rem;
  height: 2rem;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 0.25rem;

  :hover:not(:disabled) {
    color: ${p => p.theme.colors.PrimaryDark};
  }
`;

export const StyledEditButtons = styled.div`
  display: flex;
  margin: 0.5rem 0;
  margin-left: auto;
  margin-right: 1rem;

  button + button {
    margin-left: 0.5rem;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const MESSAGE_INPUT_STYLES: CSSProperties = {
  flex: 1,
  maxHeight: '8rem',
  overflowY: 'auto',
  padding: '1px',
  fontSize: '0.875rem',
  wordBreak: 'break-word',
};

export const InputPlaceholder = styled.div`
  position: absolute;
  white-space: nowrap;
  top: 0;
  padding: ${MESSAGE_INPUT_STYLES.padding} 0.5rem 0
    ${MESSAGE_INPUT_STYLES.padding};
  max-width: 100%;
  pointer-events: none;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MessageFormContainer = styled.div`
  position: relative;
`;
