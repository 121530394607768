import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { ClipboardProvider } from '../../Clipboard';
import { SegmentTranslation } from '../../Segment/i18n';
import { SegmentContent } from '../../Segment';
import { SegmentNavigator } from '../../Segment/SegmentNavigator/SegmentNavigator';
import { useCurrentWorkspacePermissions } from '../../Workspace/Workspace.hooks';
import { DesktopContext } from '../Desktop.context';
import { useDesktopSegmentRedirects } from '../Desktop.hooks';
import { useCurrentTypeOfPage } from '../Desktop.utils';
import { DesktopNavigator } from './DesktopNavigator';
import { DesktopNavigatorTitleActions } from './DesktopNavigator/DesktopNavigatorTitleActions/DesktopNavigatorTitleActions';
import { LinkDetailsModal } from '../../Link/Modals/LinkDetailsModal/LinkDetailsModal';
import { EditLinkModal } from '../../Link/LinksView/Modals/EditLinkModal';
import { useMobile, useQueryParams, useTablet } from '../../../shared/hooks';
import { EditFolderModal } from '../../Folder/Modals';
import { DesktopContent } from '../Views/Content/Content';
import { DesktopTranslation } from '../i18n';
import {
  PASSWORDS_ROOT_PATHNAME,
  PASSWORDS_VAULT_PATHNAME,
} from '../Desktop.constants';
import { PasswordsSegment } from '../../Encryption/Passwords/PasswordsSegment/PasswordsSegment';
import { PasswordsSegmentSwitcher } from '../../Encryption/Passwords/PasswordsSegment/PasswordsSegmentSwitcher';
import { useDesktopsRepository } from '../data/Desktop/Desktop.repositories';
import type { DesktopApiType } from '../data/Desktop/types/Desktop.types';
import { useSegmentTrackingEventOnInit } from '../../Segment/tracking/SegmentTracking.hooks';
import { getLongId } from '../../../shared/utils/id';

type DesktopSegmentProps = {
  hideVaultSegment?: boolean;
};

export const DesktopSegment: FC<DesktopSegmentProps> = ({
  hideVaultSegment = false,
}) => {
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const { pathname } = useLocation();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const {
    permissions: {
      canMenuDesktopsSidebarItem,
      canMenuChatsSidebarItem,
      canMenuMeetingsSidebarItem,
    },
  } = useCurrentWorkspacePermissions();

  const canMenuOnlyDesktopSidebarItem =
    canMenuDesktopsSidebarItem &&
    !canMenuChatsSidebarItem &&
    !canMenuMeetingsSidebarItem;

  const { desktops, loading: desktopsLoading } = useDesktopsRepository({
    variables: {
      filterOnlyAccessible: true,
    },
  });

  const { desktopId } = useParams<{ desktopId?: string }>();

  const desktop: DesktopApiType | undefined = useMemo(
    () =>
      desktops.find(
        ({ id }) => id === getLongId('desktops', desktopId as string),
      ),
    [desktopId, desktops],
  );

  useDesktopSegmentRedirects(desktops, desktopsLoading, desktopId, pathname);

  useSegmentTrackingEventOnInit('open_desktops');

  const matchVaultPath = !!(
    matchPath(PASSWORDS_ROOT_PATHNAME, pathname) ||
    matchPath(PASSWORDS_VAULT_PATHNAME, pathname)
  );

  return (
    <>
      <DesktopContext.Provider value={isFavoritesDesktop ? undefined : desktop}>
        {canMenuOnlyDesktopSidebarItem ? (
          <SegmentNavigator
            segmentBottomButton={
              <DesktopNavigatorTitleActions
                buttonText={DesktopTranslation.listAddNewDesktopButton}
              />
            }>
            <DesktopNavigator />
          </SegmentNavigator>
        ) : (
          <SegmentNavigator
            segmentTitle={formatMessage({
              id: SegmentTranslation.segmentDesktops,
            })}
            navigatorTitleActions={<DesktopNavigatorTitleActions />}>
            <DesktopNavigator />
          </SegmentNavigator>
        )}

        {!hideVaultSegment && matchVaultPath && !isMobile && !isTablet ? (
          <PasswordsSegment />
        ) : (
          <ClipboardProvider>
            <SegmentContent>
              {matchVaultPath && (isMobile || isTablet) ? (
                <PasswordsSegmentSwitcher />
              ) : (
                <DesktopContent />
              )}
            </SegmentContent>
          </ClipboardProvider>
        )}
        {queryParams.linkDetails && <LinkDetailsModal />}
        {queryParams.editLink && <EditLinkModal />}
        {queryParams.editFolderId && <EditFolderModal />}
      </DesktopContext.Provider>
    </>
  );
};
