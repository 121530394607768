import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAppSsoGroups,
  useCreateCustomAppSsoGroupMutation,
  useSsoCompatibleAppsQuery,
} from '../../AppStore.hooks';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspaceLastOpenedDesktop,
} from '../../../Workspace/Workspace.hooks';
import { useQueryParams } from '../../../../shared/hooks';
import { AppSsoGroupApiType } from '../../AppStore.types';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useModalControls } from '../../../../shared/components/Modal/Modal.hooks';
import { GetSsoCompatibleAppsResponse } from '../../AppStore.queries';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { mergeWithArray } from '../../../../shared/utils/list.utils';
import {
  addAppSsoGroupToCache,
  getFilterParam,
  removeAppFromSsoCompatibleAppsCache,
} from './SsoAppsGrid.utils';
import { getWorkspaceAdminCloseLink } from '../../../Workspace/Workspace.utils';
import { CreateCustomAppSsoGroupResponse } from '../../AppStore.mutations';
import type { DesktopAppApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { getShortId } from '../../../../shared/utils/id';

const DEFAULT_PAGE_SIZE = 10;
const LOAD_MORE_RETRY_TIMEOUT = 5000;

export const SsoAppsGridViewModel = () => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const {
    permissions: { canViewSsoPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  const { lastOpenedDesktop } = useWorkspaceLastOpenedDesktop();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { ssoGroupId } = queryParams;
  const filter = getFilterParam(queryParams);

  const filterRef = useRef<typeof filter>();
  filterRef.current = filter;

  const { data, loading } = useAppSsoGroups({
    skip: !canViewSsoPage,
    variables: {
      workspace: currentWorkspace.id,
      name: filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: compatibleAppsData,
    loading: compatibleAppsLoading,
    fetchMore: fetchMoreApps,
  } = useSsoCompatibleAppsQuery({
    skip: !canViewSsoPage,
    variables: {
      workspace: currentWorkspace.id,
      first: DEFAULT_PAGE_SIZE,
      fullName: filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const countAppSsoGroupApps = useCallback(
    (groups: AppSsoGroupApiType[]) =>
      groups.reduce<number>((acc, item) => acc + item.compatibleApps.length, 0),
    [],
  );

  const configuredAppSsoGroups: AppSsoGroupApiType[] = useMemo(
    () => extractNodes(data?.ssoGroupsConfigured),
    [data],
  );

  const configuredAppSsoGroupsApps = useMemo(
    () => countAppSsoGroupApps(configuredAppSsoGroups),
    [configuredAppSsoGroups, countAppSsoGroupApps],
  );

  const compatibleAppSsoGroups = useMemo(
    () => extractNodes(data?.ssoGroupsCompatible),
    [data],
  );

  const compatibleAppSsoGroupsApps = useMemo(
    () => countAppSsoGroupApps(compatibleAppSsoGroups),
    [compatibleAppSsoGroups, countAppSsoGroupApps],
  );

  const ssoAppGroup = useMemo(
    () =>
      ssoGroupId
        ? [...configuredAppSsoGroups, ...compatibleAppSsoGroups].find(
            ssoGroup => ssoGroupId === ssoGroup._id,
          )
        : undefined,
    [compatibleAppSsoGroups, configuredAppSsoGroups, ssoGroupId],
  );

  const ssoCompatibleApps = useMemo(
    () => extractNodes(compatibleAppsData?.apps),
    [compatibleAppsData],
  );

  const [createCustomAppSsoGroupMutation] =
    useCreateCustomAppSsoGroupMutation();
  const createCustomSsoGroup = useCallback(
    (app: Pick<DesktopAppApiType, 'id'>) => {
      const usedFilter = filterRef.current;
      return createCustomAppSsoGroupMutation({
        variables: {
          workspace: currentWorkspace.id,
          app: app.id,
        },
        update: (
          proxy,
          { data }: { data?: CreateCustomAppSsoGroupResponse | null },
        ) => {
          if (!data) {
            return;
          }

          addAppSsoGroupToCache(
            proxy,
            {
              workspace: currentWorkspace.id,
            },
            data.createCustomAppSsoGroup.appSsoGroup,
          );

          if (usedFilter) {
            addAppSsoGroupToCache(
              proxy,
              {
                workspace: currentWorkspace.id,
                name: usedFilter,
              },
              data.createCustomAppSsoGroup.appSsoGroup,
            );
          }

          removeAppFromSsoCompatibleAppsCache(
            proxy,
            {
              workspace: currentWorkspace.id,
              first: DEFAULT_PAGE_SIZE,
            },
            app.id,
          );

          if (usedFilter) {
            removeAppFromSsoCompatibleAppsCache(
              proxy,
              {
                workspace: currentWorkspace.id,
                first: DEFAULT_PAGE_SIZE,
                fullName: usedFilter,
              },
              app.id,
            );
          }
        },
      })
        .then(({ data }) => {
          if (data) {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                ssoGroupId: data.createCustomAppSsoGroup.appSsoGroup._id,
              }),
            });
          }
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      createCustomAppSsoGroupMutation,
      currentWorkspace.id,
      navigate,
      queryParams,
    ],
  );

  const createCustomAppModal = useModalControls();

  const [loadingMoreApps, setLoadingMoreApps] = useState(false);

  const handleLoadMore = useCallback(() => {
    setLoadingMoreApps(true);
    fetchMoreApps({
      variables: {
        after: compatibleAppsData?.apps.pageInfo?.endCursor,
      },
      updateQuery: (
        prev: GetSsoCompatibleAppsResponse,
        { fetchMoreResult }: { fetchMoreResult?: GetSsoCompatibleAppsResponse },
      ) => {
        return mergeWithArray(
          // TODO: Get rid of this fallback after Apollo issue will be resolved - https://github.com/apollographql/apollo-client/issues/5169
          prev || compatibleAppsData,
          fetchMoreResult || {},
        ) as GetSsoCompatibleAppsResponse;
      },
    })
      .then(() => setLoadingMoreApps(false))
      .catch(e => {
        setTimeout(() => setLoadingMoreApps(false), LOAD_MORE_RETRY_TIMEOUT);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [compatibleAppsData, fetchMoreApps]);

  const handleRedirectToAdmin = useCallback(() => {
    navigate(`/workspace/${getShortId(currentWorkspace.id)}/admin`);
  }, [currentWorkspace.id, navigate]);

  const handleCloseDrawer = useCallback(() => {
    navigate(getWorkspaceAdminCloseLink(currentWorkspace, lastOpenedDesktop));
  }, [currentWorkspace, navigate, lastOpenedDesktop]);

  const handleCreateCustomAppDone = useCallback(
    (app?: DesktopAppApiType) => {
      if (app) {
        return createCustomSsoGroup(app);
      }
    },
    [createCustomSsoGroup],
  );

  return {
    permissionsLoaded,
    canViewSsoPage,
    loading,
    compatibleAppsLoading,
    compatibleAppsData,
    configuredAppSsoGroups,
    configuredAppSsoGroupsApps,
    compatibleAppSsoGroups,
    compatibleAppSsoGroupsApps,
    ssoAppGroup,
    ssoCompatibleApps,
    createCustomSsoGroup,
    createCustomAppModal,
    loadingMoreApps,
    handleLoadMore,
    handleRedirectToAdmin,
    handleCloseDrawer,
    handleCreateCustomAppDone,
  };
};
