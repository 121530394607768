import React, { FC, useCallback } from 'react';
import { CustomAppForm } from '../CustomAppForm';
import { useCreateCustomAppMutation } from '../../../AppStore.hooks';
import { AppStoreAppFormValues } from '../../../AppStore.types';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { AppStoreTranslation } from '../../../i18n';
import type { DesktopAppApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

interface CreateCustomAppProps {
  onDone: (app: DesktopAppApiType) => Promise<unknown>;
}

export const CreateCustomApp: FC<CreateCustomAppProps> = ({ onDone }) => {
  const [createCustomAppMutation] = useCreateCustomAppMutation();
  const { workspace } = useCurrentWorkspace();

  const handleSubmit = useCallback(
    (values: AppStoreAppFormValues) => {
      const { logo } = values;
      return createCustomAppMutation({
        variables: {
          input: {
            ...values,
            workspace: workspace.id,
            logo: logo ? logo : null,
          },
        },
      })
        .then(async ({ data }) => {
          showToastSuccessMessage(
            AppStoreTranslation.createCustomAppSuccessMessage,
          );
          await onDone(data!.createCustomApp.app);
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [onDone, workspace, createCustomAppMutation],
  );

  return <CustomAppForm onSubmit={handleSubmit} />;
};
