import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Navigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { OnboardingTranslation } from '../../i18n';
import { useChangeUseCaseForAccountMutation } from '../../Onboarding.hooks';
import { SignUpStep, UseCaseFormValues } from '../../Onboarding.types';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep } from '../OnboardingPage.styled';
import { UseCaseForm } from './UseCase.form';
import {
  OnboardingUseCaseStepHeader,
  OnboardingUseCaseStepText,
} from './UseCasePage.styled';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const UseCasePage: FC = () => {
  const { account, refetchAccountData } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const [changeUseCaseForAccount] = useChangeUseCaseForAccountMutation();
  const onSubmit = useCallback(
    (values: UseCaseFormValues) => {
      if (!account?.id) {
        return;
      }
      return changeUseCaseForAccount({
        variables: {
          input: {
            useCase: values.useCase,
            id: account.id,
          },
        },
      })
        .then(() => refetchAccountData?.())
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [account?.id, changeUseCaseForAccount, refetchAccountData],
  );

  if (account?.currentStep !== SignUpStep.use_case) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <OnboardingPage horizontallyCentered>
      <OnboardingStep data-testid="registration-step">
        <OnboardingStepsCounter currentStep={account?.currentStep} />
        <OnboardingUseCaseStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingUseCasePageTitle}
          />
        </OnboardingUseCaseStepHeader>

        <OnboardingUseCaseStepText centered data-testid="description">
          <FormattedMessage
            id={OnboardingTranslation.onboardingUseCaseDescription}
          />
        </OnboardingUseCaseStepText>

        <UseCaseForm onSubmit={onSubmit} />
      </OnboardingStep>
    </OnboardingPage>
  );
};
