import React, {
  FC,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DateRangePicker } from 'react-date-range';
import styled from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { MenuContext } from '../../../../shared/components/Menu/Menu.context';
import { StyledTimelineDateRange } from './TimelineDateRange.styled';
import { getCurrentTheme } from '../../../Theme';

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.625rem 1rem 0 1rem;
  button:last-child {
    margin-left: 15px;
    color: ${p => p.theme.colors.PrimaryTextLight};
  }
`;

interface TimelineDateRangeProps {
  onDateRangeChange: (dateRangeSelection: {
    startDate: Date;
    endDate: Date;
  }) => void;
  isTimeLineDateRangeOpened?: MutableRefObject<boolean>;
}

export const TimelineDateRange: FC<TimelineDateRangeProps> = ({
  onDateRangeChange,
  isTimeLineDateRangeOpened,
}) => {
  const [dateRangeSelection, setDateRangeSelection] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    if (isTimeLineDateRangeOpened) {
      isTimeLineDateRangeOpened.current = true;
    }
    return () => {
      if (isTimeLineDateRangeOpened) {
        isTimeLineDateRangeOpened.current = false;
      }
    };
  }, [isTimeLineDateRangeOpened]);

  const { closeMenu } = useContext(MenuContext);

  const handleApplyDateRange = () => {
    onDateRangeChange(dateRangeSelection);
    setTimeout(closeMenu, 0);
  };

  return (
    <StyledTimelineDateRange>
      <DateRangePicker
        ranges={[dateRangeSelection]}
        color={getCurrentTheme().colors.Primary}
        // @ts-ignore
        maxDate={new Date()}
        onChange={range => {
          // @ts-ignore
          setDateRangeSelection(range.selection);
        }}
      />
      <ButtonsGroup>
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.sm}
          onClick={closeMenu}>
          Cancel
        </Button>
        <Button
          mode={ButtonMode.primary}
          size={ButtonSize.sm}
          onClick={handleApplyDateRange}>
          Apply
        </Button>
      </ButtonsGroup>
    </StyledTimelineDateRange>
  );
};
