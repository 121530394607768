/**
 * This file contains any network related functionality for chat messages (and conversation in time?)
 * Any functionality that somehow queries or mutates conversation data belongs here.
 * For readability this file should probably be split into multiple categories.
 */

import { useAuthService } from '../../../../Auth/Auth.hooks';
import {
  addConversationParticipantApi,
  createConversationApi,
  createPendingConversationApi,
  getConversationByIdApi,
  getConversationsApi,
  hideConversationApi,
  inviteConversationGuestApi,
  leaveConversationApi,
  pinConversationApi,
  removeConversationApi,
  removeConversationParticipantApi,
  renameConversationApi,
  unpinConversationApi,
} from '../../DataSource/ChatConversation/ChatConversationsApiSource';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../Workspace/Workspace.hooks';
import {
  createConversationIDDB,
  createOrUpdateMultipleConversationsIDDB,
  deleteConversationIDDB,
  deleteConversationsFromIDDB,
  getConversationIDDB,
  getConversationsFromIDDB,
  updateConversationIDDB,
} from '../../DataSource/ChatConversation/ChatConversationsIndexedDBSource';
import { captureException } from '@sentry/react';
import { ChatConversationApiToChatConversationsTableRow } from '../../../../Database/ChatConversationsTable/ChatConversationsTable.utils';
import format from 'date-fns/format';
import { ChatConversationUserContext } from './ChatConversation.types';

export const useChatConversationRepository = () => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const authService = useAuthService();

  const userContext: ChatConversationUserContext = {
    workspaceId: workspace.id,
    accountId: account?.id || '',
  };

  /**
   * Get initial conversations
   */
  const getInitialConversations = async () => {
    const authToken = await authService.getToken();

    if (!authToken) {
      return;
    }

    try {
      const { data, ok } = await getConversationsApi(userContext, authToken);
      if (ok) {
        const conversations = data?.map?.(({ chatConversation }) =>
          ChatConversationApiToChatConversationsTableRow(
            chatConversation,
            userContext,
          ),
        );
        const conversationIdsInIDDB = await getConversationsFromIDDB(
          userContext.workspaceId,
        ).then(conversationRows => conversationRows?.map(row => row.id) || []);
        const conversationIdsFromResponseSet = new Set(
          conversations?.map(conversation => conversation.id) || [],
        );
        const deletedConversationIds = conversationIdsInIDDB.filter(
          iddbId => !conversationIdsFromResponseSet.has(iddbId),
        );
        deleteConversationsFromIDDB(deletedConversationIds);

        createOrUpdateMultipleConversationsIDDB(conversations);
      }
    } catch (err) {
      captureException(err);
    }
  };

  /** Create new conersation */
  const createConversation = async (userIds: string[], title?: string) => {
    //!TODO @Nika_Beridze add optimistic response
    try {
      const conversation = await createConversationApi(
        userIds,
        workspace.id,
        title,
      );

      if (conversation) {
        const conversationTableType =
          ChatConversationApiToChatConversationsTableRow(
            conversation,
            userContext,
          );

        await createConversationIDDB(conversationTableType);
      }

      return conversation?.id;
    } catch (err) {
      captureException(err);
    }
  };

  const createPendingConversation = async (
    userIds: string[],
    emails: string[],
    title?: string,
  ) => {
    //!TODO @Nika_Beridze add optimistic response
    try {
      const pendingConversation = await createPendingConversationApi(
        userIds,
        emails,
        workspace.id,
        title,
      );

      if (pendingConversation) {
        const conversationTableType =
          ChatConversationApiToChatConversationsTableRow(
            pendingConversation,
            userContext,
          );

        createConversationIDDB(conversationTableType);
      }

      return pendingConversation?.id;
    } catch (err) {
      captureException(err);
    }
  };

  /** Rename conersation */
  const renameConversation = async (id: string, title: string) => {
    const iddbConversation = await getConversationIDDB(id);

    if (!iddbConversation) {
      return;
    }

    try {
      updateConversationIDDB({
        ...iddbConversation,
        title,
      });

      renameConversationApi(id, title, userContext);
    } catch (err) {
      updateConversationIDDB(iddbConversation);
      captureException(err);
    }
  };

  const inviteConversationGuest = (id: string, email: string) => {
    try {
      inviteConversationGuestApi(id, email).then(conversation => {
        if (conversation) {
          const conversationTableType =
            ChatConversationApiToChatConversationsTableRow(
              conversation,
              userContext,
            );
          updateConversationIDDB(conversationTableType);
        }
      });
    } catch (err) {
      captureException(err);
    }
  };

  const addConversationParticipant = (id: string, userId: string) => {
    try {
      addConversationParticipantApi(id, userId).then(conversation => {
        if (conversation) {
          const conversationTableType =
            ChatConversationApiToChatConversationsTableRow(
              conversation,
              userContext,
            );
          updateConversationIDDB(conversationTableType);
        }
      });
    } catch (err) {
      captureException(err);
    }
  };

  const removeConversationParticipant = (id: string, userId: string) => {
    try {
      removeConversationParticipantApi(id, userId).then(conversation => {
        if (conversation) {
          const conversationTableType =
            ChatConversationApiToChatConversationsTableRow(
              conversation,
              userContext,
            );
          updateConversationIDDB(conversationTableType);
        }
      });
    } catch (err) {
      captureException(err);
    }
  };

  const pinConversation = async (id: string) => {
    //!TODO @Nika_Beridze add optimistic response
    const iddbConversation = await getConversationIDDB(id);
    const date = new Date();
    const nowDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");

    if (iddbConversation) {
      updateConversationIDDB({
        ...iddbConversation,
        pinnedAt: nowDate,
      });
    }
    pinConversationApi(id, userContext.accountId).then(conversation => {
      if (conversation) {
        const conversationTableType =
          ChatConversationApiToChatConversationsTableRow(
            conversation,
            userContext,
          );
        updateConversationIDDB({ ...conversationTableType, pinnedAt: nowDate });
      }
    });
  };

  const unpinConversation = async (id: string) => {
    const iddbConversation = await getConversationIDDB(id);

    if (iddbConversation) {
      updateConversationIDDB({
        ...iddbConversation,
        pinnedAt: null,
      });
    }

    unpinConversationApi(id, userContext.accountId).then(conversation => {
      if (conversation) {
        const conversationTableType =
          ChatConversationApiToChatConversationsTableRow(
            conversation,
            userContext,
          );
        updateConversationIDDB(conversationTableType);
      }
    });
  };

  const leaveConversation = async (id: string) => {
    deleteConversationIDDB(id);
    leaveConversationApi(id);
  };

  const removeConversation = async (id: string) => {
    deleteConversationIDDB(id);
    removeConversationApi(id);
  };

  const getConversationById = async (conversationId: string) => {
    const iddbConversation = await getConversationIDDB(conversationId);

    if (iddbConversation) {
      return iddbConversation;
    }

    getConversationByIdApi(conversationId).then(async chatConversationApi => {
      if (!chatConversationApi) {
        return null;
      }

      const conversationTableType =
        ChatConversationApiToChatConversationsTableRow(
          chatConversationApi,
          userContext,
        );

      await createConversationIDDB(conversationTableType);

      return conversationTableType;
    });
  };

  const hideConversationIDDB = async (conversationId: string) => {
    const iddbConversation = await getConversationIDDB(conversationId);

    if (!iddbConversation) {
      return;
    }

    await updateConversationIDDB({
      ...iddbConversation,
      isHidden: true,
    });

    return iddbConversation;
  };

  const hideConversation = async (conversationId: string) => {
    const chachedConversation = await hideConversationIDDB(conversationId);

    if (!chachedConversation) {
      return;
    }

    hideConversationApi(userContext.accountId, conversationId).catch(() => {
      updateConversationIDDB({
        ...chachedConversation,
        isHidden: false,
      });
    });
  };

  const unHideConversation = async (
    conversationId: string,
    withHiddenAt: boolean = true,
  ) => {
    const iddbConversation = await getConversationIDDB(conversationId);

    if (!iddbConversation) {
      return;
    }

    await updateConversationIDDB({
      ...iddbConversation,
      isHidden: false,
      isHiddenAt: withHiddenAt ? iddbConversation.isHiddenAt : null,
    });
  };

  return {
    getInitialConversations,
    createConversation,
    createPendingConversation,
    renameConversation,
    inviteConversationGuest,
    addConversationParticipant,
    removeConversationParticipant,
    pinConversation,
    unpinConversation,
    leaveConversation,
    removeConversation,
    getConversationById,
    hideConversation,
    hideConversationIDDB,
    unHideConversation,
  };
};
