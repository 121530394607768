import { extractNodes } from '../../../../shared/api/api.utils';
import { database } from '../../../Database';
import { DesktopAppApiTypeToDesktopAppsTableRow } from '../../../Database/DesktopAppsTable/DesktopAppsTable.utils';
import { FolderApiTypeToDesktopFolderTableRow } from '../../../Database/DesktopFoldersTable/DesktopFoldersTable.utils';
import { LinksApiTypeToDesktopLinksTableRow } from '../../../Database/DesktopLinksTable/DesktopLinksTable.utils';
import { GRAPHQL_TYPENAME_DESKTOP_APP } from '../../../Desktop/Desktop.constants';
import { GRAPHQL_TYPENAME_FOLDER } from '../../../Folder/Folder.constants';
import { GRAPHQL_TYPENAME_LINK } from '../../../Link/Link.constants';
import { getWorkspaceFavoritesRest } from '../DataSource/WorkspaceFavoritesApiDataSource';
import type { DesktopAppEdgeApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import type { FolderApiType } from '../../../Folder/Folder.types';
import type { LinkApiType } from '../../../Link/Link.types';

export const getWorkspaceFavoritesRepository = async (workspaceId: string) => {
  const response = await getWorkspaceFavoritesRest(workspaceId);

  if (
    Array.isArray(response.data?.workspaceFavorites.edges) &&
    response.data?.workspaceFavorites.edges.length
  ) {
    const favorites = extractNodes(response.data?.workspaceFavorites);
    try {
      const favoriteDesktopApps = (favorites as DesktopAppEdgeApiType[]).filter(
        favorite => favorite.__typename === GRAPHQL_TYPENAME_DESKTOP_APP,
      );
      const favoriteLinks = (favorites as LinkApiType[]).filter(
        favorite => favorite.__typename === GRAPHQL_TYPENAME_LINK,
      );
      const favoriteFolders = (favorites as FolderApiType[]).filter(
        favorite => favorite.__typename === GRAPHQL_TYPENAME_FOLDER,
      );

      const desktopAppRowsArray = favoriteDesktopApps.map(app =>
        DesktopAppApiTypeToDesktopAppsTableRow(app, app.desktopId, workspaceId),
      );
      const linksRowsArray = favoriteLinks.map(link =>
        LinksApiTypeToDesktopLinksTableRow(link, workspaceId),
      );

      const foldersRowsArray = favoriteFolders.map(folder =>
        FolderApiTypeToDesktopFolderTableRow(folder, workspaceId),
      );

      database.desktopApps.bulkPut(desktopAppRowsArray);
      database.desktopLinks.bulkPut(linksRowsArray);
      database.desktopFolders.bulkPut(foldersRowsArray);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};
