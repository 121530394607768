import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { MenuItem } from '../../../../shared/components/Menu';
import { Input } from '../../../../shared/components/Input';

export const StyledTimelineFilters = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const FilterTypeButton = styled(ButtonWithIcon)`
  position: absolute;
  left: 15px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const CalendarButton = styled(ButtonWithIcon)`
  margin-left: 10px;
  padding: 8px 4px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const SearchMenuItem = styled(MenuItem)`
  button {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    &:hover {
      background-color: ${p => p.theme.colors.Surface};
      color: ${p => p.theme.colors.Primary};
    }
  }
`;

export const MenuTitle = styled.li`
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.08em;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const FilterInput = styled(Input)`
  padding-left: 42px;
  &:focus {
    padding-left: 41px;
  }
`;
