import { ApolloClient } from '@apollo/client';
import { existsInNestedArray } from '../../../shared/utils/list.utils';
import {
  GET_CHAT_CONFERENCES,
  GetChatConferencesResponse,
  GetChatConferencesVariables,
} from '../Conference.queries';
import { ConferenceApiType } from '../Conference.types';
import { GRAPHQL_TYPENAME_CHAT_CONFERENCE_EDGE } from '../Conference.constants';

export const addConferenceToListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetChatConferencesVariables,
  conference: ConferenceApiType,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetChatConferencesResponse,
      GetChatConferencesVariables
    >({
      query: GET_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      conferencesListCache,
      'chatConferences.edges',
      { node: { id: conference.id } },
    );

    if (alreadyExists) {
      return;
    }

    proxy.writeQuery<GetChatConferencesResponse, GetChatConferencesVariables>({
      query: GET_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        chatConferences: {
          ...conferencesListCache.chatConferences,
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_CHAT_CONFERENCE_EDGE,
              node: conference,
            },
            ...conferencesListCache.chatConferences.edges,
          ],
          ...(conferencesListCache.chatConferences.pageInfo
            ? {
                pageInfo: {
                  ...conferencesListCache.chatConferences.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(
                          conferencesListCache.chatConferences.pageInfo
                            .endCursor,
                        ),
                        10,
                      ) + 1
                    ).toString(),
                  ),
                },
              }
            : null),
        },
      },
    });
  } catch (e) {}
};

export const updateConferenceInListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetChatConferencesVariables,
  conference: ConferenceApiType,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetChatConferencesResponse,
      GetChatConferencesVariables
    >({
      query: GET_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache) {
      return;
    }

    proxy.writeQuery<GetChatConferencesResponse, GetChatConferencesVariables>({
      query: GET_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        chatConferences: {
          ...conferencesListCache.chatConferences,
          edges: [
            ...conferencesListCache.chatConferences.edges.map(node => {
              return node.node.id === conference.id
                ? { ...node, node: { ...node.node, ...conference } }
                : node;
            }),
          ],
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};
