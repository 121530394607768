import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { OnboardingTranslation } from '../../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { REGISTRATION_PATHNAME } from '../../Onboarding.constants';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { SignUpFlowPath } from '../../Onboarding.types';
import { appEnv } from '../../../../appEnv';

// TODO: This page must be styled properly. Design is missing as the current design
// shows the pricing plans on the website – but I think we should also have a "light version"
// so we can quickly switch plans (go back from RegistrationPage) without swithching
// between the website and application.
export const PlanPage: FC<{}> = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="registration-step">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingPlanPageTitle}
          />
        </OnboardingStepHeader>
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          fullWidth
          onClick={() => {
            navigate({
              pathname: `${REGISTRATION_PATHNAME}/${SignUpFlowPath.FREE}`,
              search: getQueryParamsFrom(queryParams),
            });
          }}>
          <FormattedMessage id={OnboardingTranslation.onboardingPlanPageFree} />
        </Button>
        <br />
        <br />
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          fullWidth
          onClick={() => {
            navigate({
              pathname: `${REGISTRATION_PATHNAME}/${SignUpFlowPath.PRO}`,
              search: getQueryParamsFrom(queryParams),
            });
          }}>
          <FormattedMessage id={OnboardingTranslation.onboardingPlanPagePro} />
        </Button>
        <br />
        <br />
        {appEnv.ALLOW_ENTERPRISE_REGISTRATION === 'true' && (
          <Button
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            fullWidth
            onClick={() => {
              navigate({
                pathname: `${REGISTRATION_PATHNAME}/${SignUpFlowPath.ENTERPRISE}`,
                search: getQueryParamsFrom(queryParams),
              });
            }}>
            <FormattedMessage
              id={OnboardingTranslation.onboardingPlanPageEnterprise}
            />
          </Button>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
