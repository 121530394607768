import React, { FC } from 'react';
import {
  StyledItemBody,
  StyledItemType,
  StyledNavLink,
} from './SearchItems.styled';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { CalendarIcon } from '../../../../../shared/icons';
import { getShortId } from '../../../../../shared/utils/id';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { ConferencesTabsValues } from '../../../../Conference/Conference.types';
import { GlobalSearchResultScheduleConference } from '../../../GlobalSearch.queries';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { generatePath } from 'react-router-dom';
import { CONFERENCE_PATHNAME } from '../../ExpandedSearch.constants';

export const MeetingItem: FC<
  GlobalSearchResultScheduleConference & {
    scheduleChatConferenceId?: string;
    onBlur: () => void;
  }
> = ({ title, id, scheduleChatConferenceId, onBlur }) => {
  const { workspace } = useCurrentWorkspace();

  return (
    <StyledNavLink
      to={{
        pathname: generatePath(CONFERENCE_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          conferenceId: getShortId(
            scheduleChatConferenceId
              ? scheduleChatConferenceId
              : (id as string),
          ),
        }),
        search: getQueryParamsFrom({
          meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
          skipConferenceRedirect: true,
        }),
      }}
      onClick={onBlur}>
      <StyledItemBody>
        <CalendarIcon />
        <span>{title}</span>
      </StyledItemBody>
      <StyledItemType>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchMeetingType}
        />
      </StyledItemType>
    </StyledNavLink>
  );
};
