import styled from 'styled-components';
import { HeaderTitle } from '../../SegmentNavigator/SegmentNavigator.styled';

export const StyledTitleBarTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(HeaderTitle)<{
  isSafari?: boolean;
}>`
  text-align: left;
   overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}
  max-height: ${({ isSafari }) => (isSafari ? '25px' : 'initial')};
`;

export const TitleBarTitleButtonWrapper = styled.button`
  appearance: none;
  border: 0;
  background: unset;
  padding: 0;
`;

export const StyledKeyboardArrow = styled.div`
  display: flex;
`;
