import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserTranslation } from '../../../i18n';
import { AccountOrigin, AccountWithCountsApiType } from '../../../User.types';
import { Avatar } from '../../../UserAvatar/Avatar';

import {
  StyledBadges,
  StyledDesktops,
  StyledDetails,
  StyledEmail,
  StyledGroup,
  StyledInformation,
  StyledItemControls,
  StyledName,
  StyledTeams,
  StyledTitle,
  StyledUserListItem,
} from './UserListItem.styled';

import { GroupTag } from '../../../UserGroupTag';
import { getAccountName } from '../../../User.utils';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../User.constants';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';
import { useMobile } from '../../../../../shared/hooks';
import { IntegrationBadge } from '../../../../Chat/ChatAvatar/IntegrationBadge';

interface ListItemProps {
  account: AccountWithCountsApiType;
}

export const UserListItem: FC<PropsWithChildren<ListItemProps>> = ({
  account,
  children,
}) => {
  const { formatMessage } = useIntl();
  const {
    permissions: { canViewDesktopsPage, canViewGroupsPage },
  } = useCurrentWorkspacePermissions();
  const { email, origin } = account;

  const {
    groupIdentifier,
    teams: teamsCount = 0,
    desktops: desktopsCount = 0,
    active,
  } = (account.workspaceCache && account.workspaceCache?.[account.id]) || {};

  const isMobile = useMobile();

  return (
    <StyledUserListItem>
      <Avatar size={40} account={account} />
      <StyledInformation>
        <StyledDetails>
          <StyledTitle>
            <StyledName data-testid="name">
              {getAccountName(account)}
            </StyledName>
            <StyledBadges data-testid="badges">
              {origin !== AccountOrigin.webtopcom && (
                <IntegrationBadge origin={origin as AccountOrigin} />
              )}
            </StyledBadges>
          </StyledTitle>
          {origin !== AccountOrigin.slack && (
            <StyledEmail data-testid="email">{email}</StyledEmail>
          )}
        </StyledDetails>
        <StyledGroup>
          {!!groupIdentifier && (
            <GroupTag
              groupIdentifier={
                active ? groupIdentifier : GroupIdentity.SUSPENDED
              }
              data-testid="group">
              {formatMessage({
                id: UserTranslation[
                  active
                    ? (groupIdentifier as GroupIdentity)
                    : (GroupIdentity.SUSPENDED as GroupIdentity)
                ],
              })}
            </GroupTag>
          )}
        </StyledGroup>
        {!isMobile && groupIdentifier !== GroupIdentity.GUEST && (
          <>
            {canViewGroupsPage && (
              <StyledTeams data-testid="teams-count">
                {teamsCount}{' '}
                <FormattedMessage
                  id={
                    teamsCount > 1
                      ? UserTranslation.adminListTeams
                      : UserTranslation.adminListTeam
                  }
                />
              </StyledTeams>
            )}

            {canViewDesktopsPage && (
              <StyledDesktops data-testid="desktops-count">
                {desktopsCount}{' '}
                <FormattedMessage
                  id={
                    desktopsCount > 1
                      ? UserTranslation.adminListDesktops
                      : UserTranslation.adminListDesktop
                  }
                />
              </StyledDesktops>
            )}
          </>
        )}
      </StyledInformation>
      <StyledItemControls>{children}</StyledItemControls>
    </StyledUserListItem>
  );
};
