import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage, NativeTypes } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { FolderApiType, FolderWithLinksApiType } from '../Folder.types';
import {
  FavoriteIcon,
  FolderBackground,
  FolderContainer,
  FolderContextArea,
  FolderIcon,
  FolderImage,
  FolderTitle,
  FolderWrapper,
  StyledFolderOuter,
} from './Folder.styled';
import FolderIconBackground from '../../../shared/assets/images/folder-illustration.png';
import { HeartFullIcon } from '../../../shared/icons';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../Desktop/Desktop.hooks';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import {
  DragFolderItemType,
  DragLinkItemType,
  DragNativeFileItemType,
  DragNativeUrlItemType,
  DragType,
} from '../../Drag';
import { useAsyncTaskQueue } from '../../../shared/utils/AsyncTaskQueue';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { validateExternallyDroppedFile } from '../../Drag/ExternalUrlDropZone';

import { ENTER_KEY } from '../../Keyboard/Keyboard.constants';
import {
  ExternalFileCategory,
  ExternalFileCategoryUnsupportedType,
  ExternalFileCategoryUrlType,
  ExternalFileSupportedExtension,
} from '../../Drag/ExternalUrlDropZone/ExternalUrlDropZone.types';
import { showToastErrorMessage } from '../../../shared/components/Toast';
import { LinkTranslation } from '../../Link/i18n';
import { FolderControls } from './FolderControls';
import { FoldersMenu } from './Menu';
import { useCurrentTypeOfPage } from '../../Desktop/Desktop.utils';
import { getShortId } from '../../../shared/utils/id';
import { useCaseCreateFavorite } from '../../Desktop/UseCase/createFavorite';
import { useCaseDeleteFavorite } from '../../Desktop/UseCase/deleteFavorite';
import { useCaseEditFolder } from '../UseCase/editFolder';
import { useCaseDeleteFolder } from '../UseCase/deleteFolder';
import { useQueryParams } from '../../../shared/hooks';
import { useCaseMoveLink } from '../../Link/UseCase/editLink';
import { PermissionContext } from '../../Desktop/data/Desktop/types/Desktop.types';

interface FolderProps {
  folder: FolderApiType | FolderWithLinksApiType;
  dragEnabled?: boolean;
  searchString?: string;
}

export const Folder: FC<FolderProps> = ({
  folder,
  dragEnabled = false,
  searchString,
}) => {
  const folderRef = useRef(null);
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { createFavoriteUseCase } = useCaseCreateFavorite();
  const { deleteFavoriteUseCase } = useCaseDeleteFavorite();

  const { canAddLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(folder.desktop.id),
  );

  const [isUpdatingFavorite, setUpdatingFavorite] = useState(false);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { isFavoritesDesktop } = useCurrentTypeOfPage();

  const [isMenuOpened, setMenuOpened] = useState(false);
  const handleMenuOpenClick = (isOpened: boolean) => setMenuOpened(isOpened);

  const toggleFavoriteFolder = useCallback(() => {
    if (isUpdatingFavorite) {
      return;
    }

    setUpdatingFavorite(true);
    if (folder.accountFavorite) {
      deleteFavoriteUseCase(workspace.id, folder.id).then(() => {
        setUpdatingFavorite(false);
      });
    } else {
      createFavoriteUseCase(workspace.id, { folder: folder.id }).then(() => {
        setUpdatingFavorite(false);
      });
    }
  }, [
    createFavoriteUseCase,
    deleteFavoriteUseCase,
    folder.accountFavorite,
    folder.id,
    isUpdatingFavorite,
    workspace.id,
  ]);

  const { deleteFolderUseCase } = useCaseDeleteFolder();

  const handleDeleteFolder = useCallback(() => {
    deleteFolderUseCase(folder, searchString);
  }, [deleteFolderUseCase, folder, searchString]);

  const { editFolderUseCase } = useCaseEditFolder();

  const { moveLinksToFolder } = useCaseMoveLink();

  const asyncTaskQueue = useAsyncTaskQueue();
  const handleDroppedLink = useCallback(
    (url: string) => {
      asyncTaskQueue.push(() => {
        navigate({
          search: getQueryParamsFrom({
            folderId: folder.id,
          }),
        });
      });
      asyncTaskQueue.push(() => {
        navigate(
          {
            search: getQueryParamsFrom({
              folderId: folder.id,
              createLink: true,
            }),
          },
          {
            state: url,
          },
        );
      });
    },
    [asyncTaskQueue, folder.id, navigate],
  );

  const [{ isDraggingOver }, folderDropRef] = useDrop<
    | DragLinkItemType
    | DragFolderItemType
    | DragNativeUrlItemType
    | DragNativeFileItemType,
    unknown,
    { isDraggingOver: boolean }
  >({
    accept: [DragType.LINK, DragType.FOLDER, NativeTypes.URL, NativeTypes.FILE],
    canDrop: () => canAddLink,
    drop: async item => {
      if (item.type === DragType.LINK) {
        const linkIsPresentInSelectedLinks =
          item.currentDesktopSelectedLinksMap?.[item.link.id];
        const linkIdsToMove = linkIsPresentInSelectedLinks
          ? Object.keys(item.currentDesktopSelectedLinksMap || {})
          : [item.link.id];
        moveLinksToFolder(linkIdsToMove, folder.id);
      } else if (
        item.type === DragType.FOLDER &&
        item.folder.id !== folder.id
      ) {
        editFolderUseCase(item.folder.id, {
          name: item.folder.name,
          parent: folder.id,
          desktop: desktop!.id,
        });
      } else if ('urls' in item && item.urls.length) {
        handleDroppedLink(item.urls[0]);
      } else if ('files' in item && item.files.length) {
        const fileDropResult = await validateExternallyDroppedFile(
          item.files[0],
          [
            ExternalFileSupportedExtension.URL,
            ExternalFileSupportedExtension.WEBLOC,
          ],
        );

        if (fileDropResult.type === ExternalFileCategory.URL) {
          handleDroppedLink(
            (fileDropResult as ExternalFileCategoryUrlType).url,
          );
        } else if (fileDropResult.type === ExternalFileCategory.UNSUPPORTED) {
          showToastErrorMessage(LinkTranslation.unsupportedDroppedUrlFile, {
            fileName: (fileDropResult as ExternalFileCategoryUnsupportedType)
              .fileName,
          });
        }
      }
    },
    collect: monitor => ({
      isDraggingOver: monitor.isOver(),
    }),
  });

  const dragFolderItemData: DragFolderItemType = {
    type: DragType.FOLDER,
    workspaceId: workspace.id,
    folder,
  };

  const [{ isDragging }, folderDragRef, preview] = useDrag<
    DragFolderItemType,
    unknown,
    { isDragging: boolean }
  >({
    item: dragFolderItemData,
    canDrag: dragEnabled && !isMenuOpened,
    begin: monitor => {
      return {
        ...dragFolderItemData,
        clientOffset: monitor.getInitialClientOffset(),
        sourceOffset: monitor.getInitialSourceClientOffset(),
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const hasSubfolders =
    (folder.subFolderIds && folder.subFolderIds.length > 0) || false;

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const onClickHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        folderId: getShortId(folder.id),
      }),
    });
  }, [folder.id, navigate, queryParams]);

  return (
    <FolderWrapper
      hasOpenMenu={isMenuOpened}
      isDragging={isDragging}
      ref={folderDropRef}
      onClick={onClickHandler}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}>
      <FolderContextArea ref={folderRef}>
        <FavoriteIcon>
          {folder.accountFavorite && (
            <HeartFullIcon className="heart-full-icon" />
          )}
        </FavoriteIcon>
        <FolderContainer
          className="folder-container"
          ref={folderDragRef}
          isDropEnabled={canAddLink}
          isDraggingOver={isDraggingOver}
          isDragging={isDragging}
          tabIndex={0}
          hasSubfolders={hasSubfolders}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === ENTER_KEY) {
              onClickHandler();
            }
          }}
          data-testid="folder-container"
          data-entityid={folder.id}>
          <FolderIcon
            style={{ backgroundImage: `url(${FolderIconBackground})` }}
            data-testid="folder-icon">
            {folder.image?.contentUrl && (
              <FolderImage
                srcSet={`${folder.image.contentUrl}?w=32&h=30 1x, ${folder.image.contentUrl}?w=64&h=60 2x, ${folder.image.contentUrl}?w=96&h=90 3x`}
                data-testid="folder-image"
              />
            )}
          </FolderIcon>
          <FolderTitle className="folder-title" data-testid="folder-name">
            {folder.name}
          </FolderTitle>

          {(desktop?.id || folder.desktop.id || isFavoritesDesktop) && (
            <FoldersMenu
              trigger={folderRef}
              handleOpenMenu={handleMenuOpenClick}
              handleRemoveFolder={handleDeleteFolder}
              folder={folder}
              toggleFavoriteFolder={toggleFavoriteFolder}
              processContextMenuEvent
            />
          )}

          <StyledFolderOuter data-testid="styled-folder-outer">
            <FolderControls
              onClick={onClickHandler}
              visible={mouseIsOver || isMenuOpened}
              folder={folder}
              handleDeleteFolder={handleDeleteFolder}
              toggleFavoriteFolder={toggleFavoriteFolder}
              currentDesktop={(desktop || folder.desktop.id) ?? null}
              handleOpenMenu={handleMenuOpenClick}
            />
          </StyledFolderOuter>
        </FolderContainer>
      </FolderContextArea>

      <FolderBackground className="folder-background" />
    </FolderWrapper>
  );
};
