import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { UserTranslation } from '../../i18n';
import { useCreateWorkspaceBatchAccountInvitationMutation } from '../../User.hooks';
import { InviteUsersForm } from './InviteUsers.form';
import {
  showToastGraphQLErrors,
  showToastInfoHTMLMessage,
  showToastInfoMessage,
  showToastSuccess,
} from '../../../../shared/components/Toast';
import { InviteUsersFormFields } from '../../User.types';
import { Translation } from '../../../Intl/i18n/i18n.types';
import { useAccountsContext } from '../../../Account';
import { isBotAccount } from '../../User.utils';
import { BatchAccountInvitationApiType } from '../../../Invitation/data/Invitation/types/Invitation.types';

export interface InviteUsersProps {
  displayGroupSelect?: boolean;
  inviteUsersButtonText?: Translation;
  onDone?: () => void;
  onSkip?: () => void;
}

export const InviteUsers: FC<InviteUsersProps> = ({
  displayGroupSelect,
  inviteUsersButtonText,
  onDone,
  onSkip,
}) => {
  const [createWorkspaceBatchAccountInvitation] =
    useCreateWorkspaceBatchAccountInvitationMutation();

  const {
    workspace: { id: workspaceId },
  } = useCurrentWorkspace();
  const { formatMessage } = useIntl();

  const { accountsWithAvailability } = useAccountsContext();

  const accounts = useMemo(
    () =>
      Object.values(accountsWithAvailability).filter(
        user => !isBotAccount(user),
      ),
    [accountsWithAvailability],
  );

  const accountEmails = useMemo(
    () => accounts.map(account => account.email),
    [accounts],
  );

  const handleSubmit = useCallback(
    ({ users }: { users: InviteUsersFormFields[] }) => {
      const inviteUsersFormData = users.filter(
        (user: InviteUsersFormFields) => {
          return !!user.email;
        },
      );

      const inviteEmails = inviteUsersFormData.map(item => item.email);

      const inviteEmailDuplicates = inviteEmails.filter(
        (item, index) => index !== inviteEmails.indexOf(item),
      );

      const alreadyInvitedUsers = inviteEmails.find(element =>
        accountEmails.includes(element),
      );

      if (inviteEmailDuplicates.length) {
        showToastInfoMessage(UserTranslation.inviteUsersDuplicatesToast);
        return false;
      }

      if (alreadyInvitedUsers) {
        showToastInfoHTMLMessage(
          UserTranslation.inviteUsersAlreadyExistsToast,
          {
            email: alreadyInvitedUsers,
          },
        );
        return false;
      }

      const members = inviteUsersFormData.map((user: InviteUsersFormFields) => {
        return {
          email: user.email,
          group: user.groupIdentifier,
        };
      });

      return createWorkspaceBatchAccountInvitation({
        variables: {
          input: {
            workspace: workspaceId,
            members: members as BatchAccountInvitationApiType[],
          },
        },
      })
        .then(() => {
          if (onDone) {
            onDone();
          }

          const toastContent = formatMessage({
            id: UserTranslation.inviteUsersSuccessToast,
          });
          showToastSuccess(toastContent);
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      accountEmails,
      createWorkspaceBatchAccountInvitation,
      formatMessage,
      onDone,
      workspaceId,
    ],
  );

  return (
    <InviteUsersForm
      onSubmit={handleSubmit}
      onSkip={onSkip}
      inviteUsersButtonText={inviteUsersButtonText}
      displayGroupSelect={displayGroupSelect}
    />
  );
};
