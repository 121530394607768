export const AccountLinkRequestFragmentFields = `
  id
  _id
  validUntil
  linked
  createdAt
  modifiedAt
  mainAccount {
      id
      _id
      email
      firstName
      lastName
      image {
        id
        contentUrl
      }
  }
`;
