import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import type { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { TeamApiType } from '../../Team/types/Team.types';

export const getAccountTeamsRestApi = (
  workspaceId: string,
  accountId: string,
): Promise<RestApiClientResponse<Array<TeamApiType>>> => {
  const restClient = getRestApiClient();
  const shortWorkspaceId = getShortId(workspaceId);
  const shortAccountId = getShortId(accountId);

  if (!restClient) {
    throw new Error('Rest client was not provided');
  }

  return restClient.fetch(
    `/workspace/${shortWorkspaceId}/accounts/${shortAccountId}/workspace-teams`,
  );
};
