import React, { Dispatch, FC, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { AccountWithWorkspacesApiType } from '../../../User/User.types';
import { WorkspaceTranslation } from '../../i18n';
import { useCurrentWorkspace } from '../../Workspace.hooks';
import { WorkspacesList } from './WorkspacesList/WorkspacesList';
import { getShortId } from '../../../../shared/utils/id';
import { UserTranslation } from '../../../User/i18n';
import { appEnv } from '../../../../appEnv';

type WorkspaceSelectorMenuProps = {
  triggerRef: RefObject<HTMLButtonElement>;
  setIsMenuOpen: Dispatch<React.SetStateAction<boolean>>;
  identityAccounts: AccountWithWorkspacesApiType[];
  createWorkspaceHandler: () => void;
};

export const WorkspaceSelectorMenu: FC<WorkspaceSelectorMenuProps> = ({
  triggerRef,
  setIsMenuOpen,
  identityAccounts,
  createWorkspaceHandler,
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();

  const handleClickWorkspaceSettings = () => {
    if (isMobile) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          settingsModal: true,
        }),
      });
    } else {
      navigate(
        {
          pathname: `/workspace/${getShortId(
            currentWorkspace.id,
          )}/admin/company`,
        },
        {
          state: {
            returnTo: pathname,
          },
        },
      );
    }
  };

  return (
    <Menu
      data-testid="workspace-selector-menu"
      trigger={triggerRef}
      scrollProbability={`calc(100vh - 110px)`}
      onOpen={() => {
        setIsMenuOpen(true);
      }}
      onClose={() => {
        setIsMenuOpen(false);
      }}
      width="auto"
      vAlign={VerticalAlignment.BOTTOM}
      hAlign={HorizontalAlignment.LEFT}>
      <WorkspacesList identityAccounts={identityAccounts} />
      <MenuSeparator />
      <MenuItem
        data-testid="settings-item"
        onClick={handleClickWorkspaceSettings}>
        <FormattedMessage id={WorkspaceTranslation.menuSettingsLink} />
      </MenuItem>
      <MenuItem
        data-testid="create-workspace-item"
        onClick={createWorkspaceHandler}>
        <FormattedMessage id={WorkspaceTranslation.menuCreateLinkWorkspace} />
      </MenuItem>
      {!currentWorkspace.communicate && appEnv.SUPPORT_URL && (
        <MenuItem
          data-testid="help-center-item"
          onClick={() => window.open(appEnv.SUPPORT_URL)}>
          <FormattedMessage id={UserTranslation.profileMenuHelpCenter} />
        </MenuItem>
      )}
      <MenuSeparator />
      <MenuItem
        data-testid="menu-logout-item"
        onClick={() => {
          navigate(`/logout`);
        }}>
        <FormattedMessage id={WorkspaceTranslation.menuLogoutLink} />
      </MenuItem>
    </Menu>
  );
};
