import styled from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';
import { StyledAvatar } from '../../../UserAvatar/Avatar.styled';

export const StyledExternalInviteUsers = styled.div`
  max-height: 326px;
  overflow: auto;
  margin-bottom: ${p => p.theme.spacing.xxl};
  &:last-child {
    margin-top: 2rem;
  }
`;

export const ExternalInviteUsersContainer = styled.div`
  button {
    margin-top: 0.5rem;
  }
`;

export const ExternalInviteUsersButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.m};
  font-family: ${p => p.theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  border-radius: 4px;
  border: solid 1px ${p => p.theme.colors.BorderDefault};
  box-shadow: 0 1px 1px 0 rgba(195, 199, 204, 0.25);
  transition: all 0.3s ease;
  margin-bottom: ${p => p.theme.spacing.xl};
  cursor: pointer;
  margin: 0 auto;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    border: 1px solid ${p => p.theme.colors.OnSurfaceHover};
  }

  img {
    margin-right: 0.75rem;
  }
`;

export const AuthIframe = styled.iframe`
  border: 0;
  padding: ${p =>
    `0 ${p.theme.spacing.xs} ${p.theme.spacing.xs} ${p.theme.spacing.xs}`};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ExternalContactsList = styled.div`
  margin: ${p => `0 0 ${p.theme.spacing.xs}`};
`;

export const ExternalContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  &:last-child {
    margin: 0;
  }
`;

export const ExternalContactInner = styled.div`
  display: flex;
  align-items: center;
`;

export const ExternalContactAvatar = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-right: ${p => p.theme.spacing.xs};
`;

export const ExternalContactName = styled.div`
  font-size: 0.875rem;
  margin-right: 0.375rem;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ExternalIcon = styled.img`
  width: 16px;
  margin-right: 0.375rem;
`;

export const ExternalContactInitials = styled(StyledAvatar)`
  margin-right: ${p => p.theme.spacing.xs};
`;

export const ExternalContactsEmptyState = styled.div`
  text-align: center;
  font-size: 0.875rem;
`;
