import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { CreateDomainForm } from '../../DomainForms';
import { DomainTranslation } from '../../i18n';

interface CreateDomainModalProps extends ModalProps {
  onDone: () => void;
}

export const CreateDomainModal: FC<CreateDomainModalProps> = ({
  onRequestClose,
  onDone,
  ...props
}) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={DomainTranslation.adminListNewDomainButton} />
      </ModalHeader>
      <CreateDomainForm onDone={onDone} onRequestClose={onRequestClose} />
    </Modal>
  );
};
