import React, { FC, useContext, useMemo, useRef } from 'react';
import {
  Menu,
  MenuItem,
  MenuSeparator,
} from '../../../../../../shared/components/Menu';
import {
  CheckIcon,
  DeleteIcon,
  DotsIcon,
  KeyIcon,
  TeamIcon,
} from '../../../../../../shared/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
  StyledAppListMenuButton,
  StyledDeleteMenuItem,
  StyledDeleteMenuText,
} from './AppListItem.styled';
import { EncryptionTranslation } from '../../../../i18n';
import { SelectVaultContext } from '../../SelectVault.context';
import { getShortId } from '../../../../../../shared/utils/id';
import { SELECT_ALL_VAULTS_QUERY_PARAM } from '../../../../Encryption.constants';
import { PreviewSegmentContext } from '../../../../../PreviewSegment/PreviewSegment.context';
import { SegmentType } from '../../../../../Segment';
import { PREVIEW_SEGMENT_URL_CHANGE_DELAY } from '../../../../../PreviewSegment/PreviewSegment.constants';
import { useCurrentWorkspacePermissions } from '../../../../../Workspace/Workspace.hooks';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { PASSWORDS_VAULT_PATHNAME } from '../../../../../Desktop/Desktop.constants';
import { getVaultAccess, getVaultIri } from '../../../../Encryption.utils';
import {
  useCurrentAccountKeyset,
  useWorkspaceVaultById,
} from '../../../../Encryption.hooks';

const ICON_SIZE = 16;

interface AppListItemMenuProps {
  vaultId: string;
  disableEditButton?: boolean;
  disableShareButton?: boolean;
  onSelectPassword: () => void;
  onUnselectPassword: () => void;
  isVaultItemSelected: boolean;
}

export const AppListItemMenu: FC<AppListItemMenuProps> = ({
  vaultId,
  disableEditButton,
  disableShareButton,
  onSelectPassword,
  onUnselectPassword,
  isVaultItemSelected,
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { selectedVaults, unselectAll } = useContext(SelectVaultContext);
  const { isPreviewMode, navigateToSegment } = useContext(
    PreviewSegmentContext,
  );
  const {
    permissions: { canExplicitlyShareVault },
  } = useCurrentWorkspacePermissions();

  const selectedPasswordsCount = useMemo(() => {
    return Object.keys(selectedVaults).length;
  }, [selectedVaults]);
  const vaultSelected = selectedVaults[vaultId];

  const { pathname } = useLocation();
  const currentOpenedVaultId = useMemo(() => {
    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { vaultId } = match.params;
    return vaultId ? getVaultIri(vaultId) : null;
  }, [pathname]);

  const { vault } = useWorkspaceVaultById(currentOpenedVaultId as string);

  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id || '';
  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keysetId);
  }, [vault, keyset, keysetId]);
  const vaultAccessKey = vaultAccess?.vaultKeyEncrypted;

  const handleEditPasswordClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.PASSWORDS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            editVaultId: getShortId(vaultId),
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  const handleSharePasswordClick = () => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        shareVaultId: getShortId(vaultId),
      }),
    });
  };

  const handleDeletePasswordClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.PASSWORDS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            ...(isVaultItemSelected
              ? {
                  deleteSelectedVaults: true,
                }
              : {
                  deleteVaultId: getShortId(vaultId),
                }),
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  const handleSelectPasswordClick = () => {
    onSelectPassword();
  };

  const handleUnselectPasswordClick = () => {
    onUnselectPassword();
  };

  const handleSelectAllPasswordsClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.PASSWORDS, true);
    }

    setTimeout(
      () => {
        navigate(
          {
            search: getQueryParamsFrom({
              ...queryParams,
              [SELECT_ALL_VAULTS_QUERY_PARAM]: true,
            }),
          },
          { replace: true },
        );
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  const shareButtonDisabled =
    (selectedPasswordsCount > 1 && vaultSelected) ||
    !canExplicitlyShareVault ||
    disableShareButton ||
    !vaultAccessKey;

  return (
    <>
      <StyledAppListMenuButton
        ref={buttonRef}
        icon={DotsIcon}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        data-tooltip-place={TooltipPlace.bottom}
        data-tooltip-content={intl.formatMessage({
          id: EncryptionTranslation.passwordMoreButtonTooltip,
        })}
      />
      <Menu trigger={buttonRef} width={190} viaPortal={!isPreviewMode}>
        <MenuItem
          icon={() => <CheckIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={
            isVaultItemSelected
              ? handleUnselectPasswordClick
              : handleSelectPasswordClick
          }>
          <FormattedMessage
            id={
              isVaultItemSelected
                ? EncryptionTranslation.passwordUnselectPassword
                : EncryptionTranslation.passwordSelectPassword
            }
          />
        </MenuItem>
        {!isVaultItemSelected && (
          <MenuItem
            icon={() => <CheckIcon width={ICON_SIZE} height={ICON_SIZE} />}
            onClick={handleSelectAllPasswordsClick}>
            <FormattedMessage
              id={EncryptionTranslation.passwordSelectAllPasswords}
            />
          </MenuItem>
        )}
        {!!selectedPasswordsCount && (
          <MenuItem
            icon={() => <CheckIcon width={ICON_SIZE} height={ICON_SIZE} />}
            onClick={unselectAll}>
            <FormattedMessage
              id={EncryptionTranslation.passwordUnselectAllPasswords}
            />
          </MenuItem>
        )}
        <MenuSeparator margin="0.25rem 0" />
        <MenuItem
          isDisabled={
            (selectedPasswordsCount > 1 && vaultSelected) ||
            disableEditButton ||
            !vaultAccessKey
          }
          icon={() => <KeyIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={handleEditPasswordClick}>
          <FormattedMessage id={EncryptionTranslation.passwordEditPassword} />
        </MenuItem>
        <MenuItem
          isDisabled={shareButtonDisabled}
          data-tooltip-content={
            shareButtonDisabled
              ? intl.formatMessage({
                  id: EncryptionTranslation.passwordSharePasswordTooltipMessage,
                })
              : undefined
          }
          data-tooltip-place={TooltipPlace.right}
          data-tooltip-id="global-tooltip"
          icon={() => <TeamIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={handleSharePasswordClick}>
          <FormattedMessage id={EncryptionTranslation.passwordSharePassword} />
        </MenuItem>
        <MenuSeparator margin="0.25rem 0" />
        <StyledDeleteMenuItem
          icon={() => <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={handleDeletePasswordClick}>
          <StyledDeleteMenuText>
            <FormattedMessage
              id={
                selectedPasswordsCount > 1 && isVaultItemSelected
                  ? EncryptionTranslation.passwordDeleteSeveralPassword
                  : EncryptionTranslation.passwordDeletePassword
              }
              values={{ selectedPasswordsCount }}
            />
          </StyledDeleteMenuText>
        </StyledDeleteMenuItem>
      </Menu>
    </>
  );
};
