import { ApolloClient } from '@apollo/client';
import {
  FILTER_FOLDERS,
  FilterFoldersResponse,
  FilterFoldersVariables,
} from '../Folder.queries';
import { FolderApiType } from '../Folder.types';

// TODO: fix with new children for mercure event
export const updateFolderInFilteredFoldersCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: FilterFoldersVariables,
  folder: FolderApiType,
) => {
  try {
    const foldersCache = apolloClient.readQuery<
      FilterFoldersResponse,
      FilterFoldersVariables
    >({
      query: FILTER_FOLDERS,
      variables: queryVariables,
    });

    if (!foldersCache) {
      return;
    }

    const updatedCache = {
      ...foldersCache,
      folders: {
        ...foldersCache.folders,
        edges: [
          ...foldersCache.folders.edges.map(folderEdge => {
            return folderEdge.node.id === folder.id
              ? {
                  ...folderEdge,
                  node: {
                    ...folderEdge.node,
                    name: folder.name,
                    links: {
                      ...folderEdge.node.links,
                      edges: folderEdge.node.links?.edges.map(linkEdge => ({
                        ...linkEdge,
                        node: {
                          ...linkEdge.node,
                          folder: {
                            ...linkEdge.node.folder,
                            name: folder.name,
                          },
                        },
                      })),
                    },
                  },
                }
              : folderEdge;
          }),
        ],
      },
    };

    apolloClient.writeQuery({
      query: FILTER_FOLDERS,
      variables: queryVariables,
      data: updatedCache,
    });
  } catch (e) {
    /* There is no cache */
  }
};

export const removeFolderFromFilteredFoldersCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: FilterFoldersVariables,
  folderId: string,
) => {
  try {
    const foldersCache = apolloClient.readQuery<
      FilterFoldersResponse,
      FilterFoldersVariables
    >({
      query: FILTER_FOLDERS,
      variables: queryVariables,
    });
    if (!foldersCache) {
      return;
    }
    apolloClient.writeQuery<FilterFoldersResponse, FilterFoldersVariables>({
      query: FILTER_FOLDERS,
      variables: queryVariables,
      data: {
        ...foldersCache,
        folders: {
          ...foldersCache.folders,
          edges: foldersCache.folders.edges.filter(
            ({ node }) => node.id !== folderId,
          ),
        },
      },
    });
  } catch (e) {
    /* There is no cache */
  }
};

// TODO @bqk -> deprecate this if favorites works as expected
// export const removeFavoriteFolderFromFavoritesFoldersCache = (
//   apolloClient: ApolloClient<any>,
//   queryVariables: GetFavoritesVariables,
//   folderId: string,
// ) => {
//   try {
//     const favoritesCache = apolloClient.readQuery<
//       GetFavoritesResponse,
//       GetFavoritesVariables
//     >({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//     });
//     if (!favoritesCache) {
//       return;
//     }

//     const data: GetFavoritesResponse = {
//       ...favoritesCache,
//       favorites: {
//         ...favoritesCache.favorites,
//         edges: favoritesCache.favorites.edges.filter(
//           ({ node }) => !node.folder || node.folder?.id !== folderId,
//         ),
//       },
//     };

//     apolloClient.writeQuery({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//       data: data,
//     });
//   } catch (e) {
//     /* There is no cache */
//   }
// };

// TODO @bqk -> deprecate this if favorites works as expected
// export const addDesktopFoldersToFavoritesFoldersCache = (
//   apolloClient: ApolloClient<any>,
//   queryVariables: GetFavoritesVariables,
//   folders: Array<FolderApiType>,
// ) => {
//   try {
//     const favoritesCache = apolloClient.readQuery<
//       GetFavoritesResponse,
//       GetFavoritesVariables
//     >({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//     });
//     if (!favoritesCache) {
//       return;
//     }

//     const favoriteFoldersIds = extractNodes(
//       getFavoriteFolders(favoritesCache).folders,
//     ).map(folder => folder.id);

//     const updatedFavoritesCache = {
//       ...favoritesCache,
//       favorites: {
//         ...favoritesCache.favorites,
//         edges: [
//           ...favoritesCache.favorites.edges,
//           ...folders
//             .filter(
//               folder =>
//                 !folder.accountFavorite &&
//                 folder.parentId &&
//                 !favoriteFoldersIds.includes(folder.id),
//             )
//             .map(folder => ({
//               __typename: GRAPHQL_TYPENAME_FAVORITE_EDGE,
//               node: {
//                 id: `/favorites${folder.id}`,
//                 folder: folder,
//                 desktopApp: null,
//                 link: null,
//                 __typename: GRAPHQL_TYPENAME_FAVORITE,
//               },
//             })),
//         ],
//       },
//     };

//     apolloClient.writeQuery({
//       query: GET_FAVORITES,
//       variables: queryVariables,
//       data: updatedFavoritesCache,
//     });
//   } catch (e) {
//     /* There is no cache */
//   }
// };
