import React, { FC } from 'react';
import { DocumentIcon } from '../../../../../shared/icons';
import { StyledPdfLink } from './PdfLink.styled';

interface PdfLinkProps {
  value: {
    contentUrl: string;
  };
}

export const PdfLink: FC<PdfLinkProps> = ({ value }) => {
  if (!value.contentUrl) {
    return null;
  }

  return (
    <StyledPdfLink href={value.contentUrl} download>
      <DocumentIcon />
    </StyledPdfLink>
  );
};
