import React, { ReactElement } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  GlobalTranslation,
  Translation,
} from '../../../domains/Intl/i18n/i18n.types';
import { CloseIcon } from '../../../shared/icons';
import {
  BrowserNotFullySupportedContainer,
  ReloadAppButton,
  ReloadAppMessage,
  ReloadAppMessageContainer,
  ScheduledDowntimeContainer,
  StyledCloseButton,
  StyledToastContainer,
} from './Toast.styled';
import SuccessIcon from '../../assets/images/success.svg';
import InfoIcon from '../../assets/images/info.svg';
import { WarningIcon } from './icons/WarningIcon';
import 'react-toastify/dist/ReactToastify.css';
import { DesktopTranslation } from '../../../domains/Desktop/i18n';
import { removeErrorCodeFrom } from '../../utils/error.utils';
import {
  isNativeWrapperAvailable,
  sendMessageToNativeWrapper,
} from '../../../domains/NativeWrapper';
import { GraphQLError } from 'graphql';
import { clearBrowserStorageData } from '../../../domains/BrowserStorage/BrowserStorage.utils';

export const CloseButton = () => <StyledCloseButton icon={CloseIcon} />;

export const DEFAULT_POSITION = 'bottom-right';

const BROWSER_SUPPORT_TOAST_ID = 'browser-support-toast-id';

export const showToastSuccess = (contentString: string) => {
  if (!contentString) return;
  toast.success(
    <>
      <img src={SuccessIcon} alt="" />
      {contentString}
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
    },
  );
};

export const showToastSuccessMessage = (
  message: Translation,
  values?: { [key: string]: any },
) => {
  toast.success(
    <>
      <img src={SuccessIcon} alt="" />
      <FormattedMessage id={message} values={values} />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
    },
  );
};

export const showToastSuccessHTMLMessage = (
  message: Translation,
  values?: { [key: string]: any },
) => {
  toast.success(
    <>
      <img src={SuccessIcon} alt="" />
      <FormattedHTMLMessage id={message} values={values} />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
    },
  );
};

export const showToastInfoMessage = (
  message: Translation | null,
  values?: { [key: string]: any },
  options?: ToastOptions,
) => {
  if (!message) {
    return;
  }

  toast.info(
    <>
      <img src={InfoIcon} alt="" />
      <FormattedMessage id={message} values={values} />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      ...options,
    },
  );
};

export const showToastInfoHTMLMessage = (
  message: Translation | null,
  values?: { [key: string]: any },
) => {
  if (!message) {
    return;
  }

  toast.info(
    <>
      <img src={InfoIcon} alt="" />
      <FormattedHTMLMessage id={message} values={values} />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
    },
  );
};

export const showToastError = (
  contentString: string,
  options?: ToastOptions,
) => {
  if (!contentString) return;
  return toast.error(
    <>
      <WarningIcon className="toast-icon" />
      {contentString}
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      autoClose: false,
      ...options,
    },
  );
};

export const showToastErrorMessage = (
  message: Translation,
  values?: { [key: string]: any },
  options?: ToastOptions,
) => {
  toast.error(
    <>
      <WarningIcon className="toast-icon" />
      <FormattedHTMLMessage id={message} values={values} />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      autoClose: false,
      ...options,
    },
  );
};

export const showToastGraphQLErrors = (
  graphQLErrors: ReadonlyArray<GraphQLError>,
) => {
  if (graphQLErrors && graphQLErrors.length) {
    // @ts-ignore – debugMessage will be deprecated
    const message = graphQLErrors[0].debugMessage || graphQLErrors[0].message;

    toast.error(
      <>
        <WarningIcon className="toast-icon" />
        {removeErrorCodeFrom(message)}
      </>,
      {
        hideProgressBar: true,
        position: DEFAULT_POSITION,
        closeButton: <CloseButton />,
        autoClose: false,
      },
    );
  } else {
    console.error('Something went wrong.', graphQLErrors);
  }
};

export const showToastRestApiErrors = (restApiErrors: ReadonlyArray<any>) => {
  if (restApiErrors && restApiErrors.length) {
    const message = restApiErrors[0].message;

    toast.error(
      <>
        <WarningIcon className="toast-icon" />
        {message}
      </>,
      {
        hideProgressBar: true,
        position: DEFAULT_POSITION,
        closeButton: <CloseButton />,
        autoClose: false,
      },
    );
  } else {
    console.error('Something went wrong.', restApiErrors);
  }
};

export const showReloadAppToast = () => {
  const handleReloadButtonClick = async () => {
    await clearBrowserStorageData();
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(swReg => {
          if (swReg.waiting) {
            swReg?.waiting?.postMessage({ type: 'FORCE_UPDATE' });
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
    window.location.reload();
  };

  if (isNativeWrapperAvailable()) {
    sendMessageToNativeWrapper({
      type: 'RELOAD_APP_TOAST_SHOWN',
    });
  }

  toast.warn(
    <ReloadAppMessageContainer>
      <ReloadAppMessage>
        <FormattedMessage id={GlobalTranslation.reloadAppMessage} />
      </ReloadAppMessage>
      <ReloadAppButton onClick={handleReloadButtonClick}>
        <FormattedMessage id={GlobalTranslation.reloadAppButton} />
      </ReloadAppButton>
    </ReloadAppMessageContainer>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      autoClose: false,
      closeOnClick: false,
    },
  );
};

export const showChromeExtensionAvailableToast = () => {
  toast.info(
    <>
      <img src={InfoIcon} alt="" />
      <FormattedHTMLMessage
        id={DesktopTranslation.chromeExtensionCredentials}
        values={{ extensionLink: process.env.SUPPORT_IMPORT_BOOKMARKS_URL }}
      />
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      autoClose: false,
    },
  );
};

export const showBrowserNotFullySupportedToast = () => {
  toast.info(
    <>
      <img src={InfoIcon} alt="" />
      <BrowserNotFullySupportedContainer>
        <FormattedHTMLMessage
          tagName="h3"
          id={GlobalTranslation.browserNotFullySupportedTitle}
        />
        <FormattedHTMLMessage
          tagName="p"
          id={GlobalTranslation.browserNotFullySupportedMessage}
          values={{
            supportPageUrl: process.env.SUPPORT_TECHNICAL_REQUIREMENTS_URL,
          }}
        />
      </BrowserNotFullySupportedContainer>
    </>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      autoClose: false,
      toastId: BROWSER_SUPPORT_TOAST_ID,
    },
  );
};

export const showScheduledDowntimeMessage = (
  content: ReactElement,
  options?: ToastOptions,
) => {
  toast.error(
    <ScheduledDowntimeContainer>
      <WarningIcon className="toast-icon" />
      {content}
    </ScheduledDowntimeContainer>,
    {
      hideProgressBar: true,
      position: DEFAULT_POSITION,
      closeButton: <CloseButton />,
      autoClose: false,
      ...options,
    },
  );
};

export { StyledToastContainer };
