import styled from 'styled-components';

export const StyledAddressName = styled.p`
  font-size: 1.25rem;
  font-family: ${p => p.theme.fonts.Semibold};
  line-height: 120%;
  margin-bottom: 0.5rem;
`;

export const StyledAddressLine = styled.p`
  line-height: 130%;
`;
