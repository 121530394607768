import OpenCrypto from '../../3rd-party/opencrypto';
import { VaultApiType } from './Encryption.types';
import {
  getVaultAccessKey,
  getVaultLogin,
  getVaultPassword,
} from './Encryption.utils';

const openCrypto = new OpenCrypto();

/*
 * PUBLIC KEY IMPORT/EXPORT
 * */

export const generateRsaKeys = () => {
  return openCrypto.getRSAKeyPair(
    2048,
    'SHA-256',
    'RSA-OAEP',
    ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'],
    true,
  );
};
export const exportPublicKey = (publicKey: CryptoKey): Promise<string> => {
  return openCrypto.cryptoPublicToPem(publicKey);
};
export const importPublicKey = (publicKey: string): Promise<CryptoKey> => {
  return openCrypto.pemPublicToCrypto(publicKey, {
    name: 'RSA-OAEP',
    hash: 'SHA-256',
    usages: ['encrypt', 'wrapKey'],
    isExtractable: true,
  });
};

/*
 * PRIVATE KEY ENCRYPT/DECRYPT
 * */

export const encryptPrivateKey = (
  privateKey: CryptoKey,
  passphrase: string,
  accountId: string, // used as salt for password
): Promise<string> => {
  return openCrypto.encryptPrivateKey(
    privateKey,
    passphrase + accountId,
    64000,
    'SHA-256',
    'AES-GCM',
    256,
  );
};
export const decryptPrivateKey = (
  encryptedPrivateKey: string,
  passphrase: string,
  accountId: string, // used as salt for password
): Promise<CryptoKey> => {
  return openCrypto.decryptPrivateKey(
    encryptedPrivateKey,
    passphrase + accountId,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
      usages: ['decrypt', 'unwrapKey'],
      isExtractable: true,
    },
  );
};
export const reencryptPrivateKey = (
  encryptedPrivateKey: string,
  currentPassphrase: string,
  newPassphrase: string,
  accountId: string, // used as salt for password
): Promise<string> => {
  return decryptPrivateKey(
    encryptedPrivateKey,
    currentPassphrase,
    accountId,
  ).then(decryptedPrivateKey => {
    return encryptPrivateKey(decryptedPrivateKey, newPassphrase, accountId);
  });
};

/*
 * VAULT ACCESS ENCRYPT/DECRYPT
 * */

export const generateVaultAccessKey = () => {
  return openCrypto.getSharedKey(256, {});
};
export const encryptVaultAccessKey = (
  publicKey: CryptoKey,
  vaultAccessKey: CryptoKey,
): Promise<string> => {
  return openCrypto.encryptKey(publicKey, vaultAccessKey);
};
export const decryptVaultAccessKey = (
  privateKey: CryptoKey,
  vaultAccessKey: string,
): Promise<CryptoKey> => {
  return openCrypto.decryptKey(privateKey, vaultAccessKey, {});
};
export const reencryptVaultAccessKey = (
  encryptedVaultAccessKey: string,
  privateKey: CryptoKey,
  publicKey: CryptoKey,
): Promise<string | null> => {
  return decryptVaultAccessKey(privateKey, encryptedVaultAccessKey)
    .then(decryptedVaultAccessKey => {
      return encryptVaultAccessKey(publicKey, decryptedVaultAccessKey);
    })
    .catch(() => null);
};

/*
 * VAULT ITEM ENCRYPT/DECRYPT
 * */

export const encryptVaultItem = (
  vaultAccessKey: CryptoKey,
  data: string,
): Promise<string> => {
  const dataAb = openCrypto.stringToArrayBuffer(data);
  return openCrypto.encrypt(vaultAccessKey, dataAb);
};
export const decryptVaultItem = (
  vaultAccessKey: CryptoKey,
  data: string,
): Promise<string> => {
  return openCrypto
    .decrypt(vaultAccessKey, data, { cipher: 'AES-GCM' })
    .then(openCrypto.arrayBufferToString);
};

export const getDecryptedVaultLogin = async (
  vault: VaultApiType,
  keysetId: string,
  privateKey: CryptoKey,
): Promise<string | null> => {
  const vaultAccessKey = getVaultAccessKey(vault, keysetId);
  const vaultLogin = getVaultLogin(vault);
  if (vaultLogin && vaultAccessKey) {
    const decryptedVaultAccessKey = await decryptVaultAccessKey(
      privateKey,
      vaultAccessKey,
    );
    return await decryptVaultItem(decryptedVaultAccessKey, vaultLogin);
  }
  return null;
};
export const getDecryptedVaultPassword = async (
  vault: VaultApiType,
  keysetId: string,
  privateKey: CryptoKey,
): Promise<string | null> => {
  const vaultAccessKey = getVaultAccessKey(vault, keysetId);
  const vaultPassword = getVaultPassword(vault);
  if (vaultPassword && vaultAccessKey) {
    const decryptedVaultAccessKey = await decryptVaultAccessKey(
      privateKey,
      vaultAccessKey,
    );
    return await decryptVaultItem(decryptedVaultAccessKey, vaultPassword);
  }
  return Promise.resolve(null);
};
