import React, { FC, memo } from 'react';
import { SkeletonBox } from '../../../../../../../shared/components/SkeletonBox';
import { useTheme } from 'styled-components';
import { ConversationListItemSkeletonContainer } from '../ConversationListItem.styled';

export const ConversationListItemSkeleton: FC = memo(() => {
  const { colors } = useTheme();

  return (
    <ConversationListItemSkeletonContainer>
      <SkeletonBox
        width={1.7}
        height={1.5}
        margin={[0, 0.5, 0, 0]}
        color={colors.ChatViewSkeleton}
        borderRadius={1}
      />
      <SkeletonBox
        height={1.5}
        color={colors.ChatViewSkeleton}
        borderRadius={0.3}
      />
    </ConversationListItemSkeletonContainer>
  );
});
