import React, { FC, useCallback, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useIntl } from 'react-intl';
import { ChatTranslation } from '../../i18n';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../User/User.types';
import { CustomOption } from './CustomOption';
import { getAccountName } from '../../../User/User.utils';
import { NewOption } from './ConversationSelect.types';
import { shouldShowOption } from './ConversationSelect.utils';
import { useTheme } from 'styled-components';

interface ConversationSelectProps {
  groupedAccounts: { label: string; options: AccountWithCountsApiType[] }[];
  onSelect: (accountOrEmail: AccountWithCountsApiType | string) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const ConversationSelect: FC<ConversationSelectProps> = ({
  groupedAccounts,
  onSelect,
  isDisabled = false,
  isLoading = false,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [value, setValue] = useState<AccountWithCountsApiType | null>(null);

  const handleChange = useCallback(
    (newValue: AccountWithCountsApiType | NewOption | null) => {
      if (!newValue) {
        return;
      }
      if ('id' in newValue) {
        setValue(newValue);
      }
      onSelect('id' in newValue ? newValue : newValue.value);
      setValue(null);
    },
    [onSelect],
  );

  return (
    <div data-testid="conversation-users-select">
      <CreatableSelect<AccountWithCountsApiType>
        components={{ Option: CustomOption }}
        placeholder={intl.formatMessage({
          id: ChatTranslation.conversationSelectPlaceholder,
        })}
        isDisabled={isDisabled}
        isLoading={isLoading}
        options={groupedAccounts}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option: AccountApiType | NewOption) => {
          if ('label' in option) {
            return option.label;
          }
          return getAccountName(option) || '';
        }}
        getOptionValue={(option: AccountApiType | NewOption) => {
          if ('label' in option) {
            return option.value;
          }
          return option.id;
        }}
        filterOption={(option, rawInput) => {
          const data: AccountApiType | NewOption = option.data as
            | AccountApiType
            | NewOption;
          if ('label' in data && data.__isNew__) {
            return true;
          }
          return shouldShowOption(data as AccountApiType, rawInput);
        }}
        isValidNewOption={(rawInput, value, options) => {
          const noExistingOptions = options.every(option => {
            if ('options' in option) {
              return true;
            }
            return !shouldShowOption(option as AccountApiType, rawInput);
          });
          return noExistingOptions && rawInput.includes('@');
        }}
        formatCreateLabel={rawInput =>
          intl.formatMessage(
            {
              id: ChatTranslation.conversationSelectInviteText,
            },
            {
              email: rawInput,
            },
          )
        }
        noOptionsMessage={() =>
          intl.formatMessage({
            id: ChatTranslation.conversationSelectEmptyText,
          })
        }
        styles={{
          placeholder: styles => ({
            ...styles,
            zIndex: 2,
          }),
          input: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            width: '100%',
            zIndex: 3,
            '*': {
              width: '100% !important',
            },
          }),
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            fontWeight: 500,
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
        }}
      />
    </div>
  );
};
