import { GetMentionsResponse } from './Inbox.queries';

export const handleFetchMoreResult = (
  previousResult: GetMentionsResponse,
  fetchMoreResult: GetMentionsResponse,
) => {
  const newEdges = fetchMoreResult.notifications.edges;
  const pageInfo = fetchMoreResult.notifications.pageInfo;

  return newEdges.length
    ? {
        notifications: {
          __typename: previousResult.notifications.__typename,
          edges: [...previousResult.notifications.edges, ...newEdges],
          pageInfo,
        },
      }
    : previousResult;
};
