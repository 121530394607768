import styled from 'styled-components';

export const StyledDesktopPreview = styled.div`
  position: relative;
  display: block;
  padding: 0.375rem 2.625rem 0.375rem 2.125rem;
  border: none;
  border-radius: 0.25rem;
  color: ${p => p.theme.colors.PrimaryTextLight};
  background-color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
  line-height: 1.175rem;
  text-decoration: none;
  word-break: break-all;
  word-break: break-word;
  box-shadow: 0 0 0 0 transparent;
  transform: rotateZ(0deg);
  transition: box-shadow 0.2s ease-out, background-color 0.2s linear,
    transform linear 0.2s;
  width: 212px;
`;
