import styled from 'styled-components';
import { OnlineStatusCircle } from '../../../domains/Account/components/OnlineStatus/OnlineStatusCircle';
import { StyledAvatarImage } from '../../../domains/Chat/ChatAvatar/AvatarImage/AvatarImage.styled';
import { StyledAvatarPlaceholder } from '../../../domains/Chat/ChatAvatar/AvatarPlaceholder/AvatarPlaceholder.styled';

export const StyledEditor = styled.div<{
  mentionIsActive?: boolean;
}>`
  .ql-editor {
    padding: 0;

    &.ql-blank {
      &::before {
        ${({ theme }) => theme.fontTemplates.regular};
        color: ${({ theme }) => theme.colors.PlaceholderTextColor};
        left: 0;
        font-style: normal;
      }
    }
  }

  *:focus-visible {
    outline: none;
  }

  .mentionContainer {
    z-index: 6;
  }

  .mentionItem {
    ${({ theme }) => theme.fontTemplates.regularSemibold};
    appearance: none;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.Surface};
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    transition: background-color 0.2s ease;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &:hover,
    &.selected {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }
  }

  .mentionList {
    width: 11.25rem;
    max-height: 20rem;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.Surface};
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.colors.BorderDefault};
    padding: 0.25rem;
  }

  .mention {
    color: ${({ theme }) => theme.colors.Primary};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.MentionBackgroundColor};
    padding: 1px;
    display: inline-block;

    &:target,
    *:target {
      background-color: rgba(54, 159, 255, 0.2);
    }
  }

  .specialMention {
    appearance: none;
    border: none;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    transition: background-color 0.2s ease;
    cursor: pointer;
    margin-bottom: 0.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0 0 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  .userMention {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;

    .avat-with-online-status {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }

    .avatar {
      position: relative;
      flex-shrink: 0;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      color: ${p => p.theme.colors.PrimaryTextLight};

      ${StyledAvatarImage}, ${StyledAvatarPlaceholder} {
        border-radius: 50%;
      }

      ${OnlineStatusCircle} {
        position: absolute;
        bottom: 0;
        right: 0;
        border-width: 0.125rem;
        border-style: solid;
      }
    }

    .static-online-status {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      border-color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      border-width: 0.125rem;
      border-style: solid;

      &.online {
        background-color: #50b83c;
      }

      &.away {
        background-color: #eec200;
      }

      &.busy {
        background-color: #de3618;
      }

      &.offline {
        background-color: #fff;

        ::after {
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(2, 17, 33, 0.12);
        }
      }
    }
  }
`;

export const StyledEditorContainer = styled.div`
  position: relative;

  .ql-editor {
    position: relative;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    flex: 1 1 0;
    max-height: 8rem;
    min-height: 2rem;
    overflow-y: auto;
    padding: 1px;
    font-size: 0.875rem;
    word-break: break-word;
  }
`;
