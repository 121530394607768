import React, { FC, Ref, useRef } from 'react';
import { SupportButton } from './SupportMenu.styled';
import { InfoQuestionIcon } from '../../../../shared/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkspaceTranslation } from '../../../Workspace/i18n';
import { Menu, MenuItem } from '../../../../shared/components/Menu';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { appEnv } from '../../../../appEnv';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { TooltipPlace } from '../../../../shared/components/Tooltip';

export const SupportMenu: FC = () => {
  const onboardingInfo: Ref<any> = useRef();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <>
      <SupportButton
        ref={onboardingInfo}
        data-tooltip-place={TooltipPlace.right}
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: WorkspaceTranslation.tooltipHelpCenterButton,
        })}
        data-testid="help-button">
        <InfoQuestionIcon width={24} height={24} />
      </SupportButton>

      <Menu trigger={onboardingInfo} width={196}>
        <MenuItem
          onClick={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                openOnboardingQuickTour: true,
              }),
            })
          }
          data-testid="quick-start-guide-item">
          <FormattedMessage
            id={WorkspaceTranslation.onboardingQuickStartGuid}
          />
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                forceLatestAnnouncementModal: true,
              }),
            })
          }
          data-testid="latest-update-item">
          <FormattedMessage id={WorkspaceTranslation.onboardingLatestUpdate} />
        </MenuItem>
        {appEnv.SUPPORT_URL && (
          <MenuItem
            onClick={() => window.open(appEnv.SUPPORT_URL)}
            data-tesitd="help-center-item">
            <FormattedMessage id={WorkspaceTranslation.onboardingHelpCenter} />
          </MenuItem>
        )}
        {appEnv.ONBOARDING_CONTACT_DESKTOP_URL && (
          <MenuItem
            onClick={() => window.open(appEnv.ONBOARDING_CONTACT_DESKTOP_URL)}
            data-testid="contact-support-item">
            <FormattedMessage
              id={WorkspaceTranslation.onboardingContactSupport}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
