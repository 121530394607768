import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { DesktopTranslation } from '../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListItem } from '../ListItem';
import { TeamAvatar } from '../TeamItem/TeamItem.styled';
import { UserIcon } from '../../../../../shared/icons';
import { useConfirm } from '../../../../../shared/components/Modal';
import { UserTranslation } from '../../../../User/i18n';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { useRevokeInvitationMutation } from '../../../../User/User.hooks';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import type { DesktopApiType } from '../../../data/Desktop/types/Desktop.types';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';
import { deleteInvitationFromIDDB } from '../../../../Invitation/data/Invitation/operations/InvitationIDDB.operations';

interface InviteItemProps {
  invite: AccountInvitationApiType;
  desktop: DesktopApiType;
}

export const InviteItem: FC<InviteItemProps> = ({ invite, desktop }) => {
  const { workspace } = useCurrentWorkspace();
  const [processing, setProcessing] = useState(false);

  const [revokeInvitationMutation] = useRevokeInvitationMutation();
  const revokeInvitation = useCallback(() => {
    setProcessing(true);

    revokeInvitationMutation({
      variables: {
        input: {
          id: invite.id,
        },
      },
    })
      .then(() => deleteInvitationFromIDDB(invite.id))
      .catch(err => showToastGraphQLErrors(err.graphQLErrors))
      .finally(() => setProcessing(false));
  }, [revokeInvitationMutation, invite.id]);

  const { formatMessage } = useIntl();
  const { askConfirmation } = useConfirm();
  return (
    <ListItem
      title={invite.recipients[0]?.email}
      subtitle={
        <FormattedMessage id={DesktopTranslation.accessPendingInvitation} />
      }
      prepend={
        <TeamAvatar>
          <UserIcon />
        </TeamAvatar>
      }
      append={
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.xs}
          disabled={processing}
          onClick={async () => {
            if (
              await askConfirmation(
                formatMessage({
                  id: UserTranslation.adminDangerZoneRevokeConfirm,
                }),
              )
            ) {
              revokeInvitation();
            }
          }}>
          <FormattedMessage
            id={DesktopTranslation.accessRevokePendingInvitation}
          />
        </Button>
      }
    />
  );
};
