import React, { FC, MutableRefObject, useMemo } from 'react';
import { Spinner } from '../../../../../shared/components/Spinner';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { LinkItem } from '../SearchItems';
import { GlobalSearchResultLinkHit } from '../../../GlobalSearch.queries';
import { GlobalSearchTranslation } from '../../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  StyledRecentTitle,
  StyledSearchRecentWrapper,
} from './RecentViews.styled';
import { useGetRecentLinksQuery } from '../../../../Link/Link.hooks';
import { makeRecentLink } from './RecentViews.utils';

export const RecentLinks: FC<{ skipBlurRef: MutableRefObject<boolean> }> = ({
  skipBlurRef,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { data, loading } = useGetRecentLinksQuery({
    variables: {
      workspace: workspace.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const links = useMemo(
    () => data?.getRecentLinks.map(elem => makeRecentLink(elem)),
    [data],
  );

  if (loading) {
    return <Spinner size={64} />;
  }

  return (
    <StyledSearchRecentWrapper>
      <StyledRecentTitle>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchRecentLinks}
        />
      </StyledRecentTitle>
      {links?.length
        ? links.map((link, idx) => (
            <LinkItem
              {...(link as GlobalSearchResultLinkHit)}
              key={`${link.id}-${idx}`}
              skipBlurRef={skipBlurRef}
            />
          ))
        : null}
    </StyledSearchRecentWrapper>
  );
};
