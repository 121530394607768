import {
  StreamEventRequest,
  StreamVaultAccessEvent,
} from '../../Mercure/General';
import { Dispatch, SetStateAction } from 'react';
import { VaultApiType } from '../Encryption.types';
import { getKeysetIri } from '../Encryption.utils';
import {
  addVaultAccess,
  makeVaultAccess,
  makeVaultAccessParent,
  makeVaultFromVaultAccessEvent,
  removeVaultAccess,
} from '../utils/VaultAccess.utils';
import { removeVault } from '../utils/Vault.utils';

export const handleVaultAccessMercureEvent = (
  event: StreamVaultAccessEvent,
  setWorkspaceVaultsCache: Dispatch<
    SetStateAction<Record<string, VaultApiType[]>>
  >,
  currentAccountKeysetId?: string,
) => {
  if (event['@request'] === StreamEventRequest.NEW) {
    const vault = makeVaultFromVaultAccessEvent(event);
    const vaultAccess = makeVaultAccess(event);
    const vaultAccessParent = makeVaultAccessParent(event);
    const workspaceId = event.vault.workspace.id;
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspaceId]: addVaultAccess(
        cache[workspaceId],
        vault,
        vaultAccess,
        vaultAccessParent,
      ),
    }));
  }
  if (event['@request'] === StreamEventRequest.REMOVE) {
    const vaultId = event.vault.id;
    const vaultAccessId = event.id;
    const vaultAccessKeysetId = getKeysetIri(event.keyset._id);
    const workspaceId = event.vault.workspace.id;
    if (event.shared && currentAccountKeysetId === vaultAccessKeysetId) {
      setWorkspaceVaultsCache(cache => ({
        ...cache,
        [workspaceId]: removeVault(cache[workspaceId], vaultId),
      }));
    } else {
      setWorkspaceVaultsCache(cache => ({
        ...cache,
        [workspaceId]: removeVaultAccess(cache[workspaceId], vaultAccessId),
      }));
    }
  }
};
