import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { InstallAppContext } from './InstallApp.context';

// TODO: remove logs after Install App toast logic debug
export const InstallAppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [installEvent, setInstallEvent] =
    useState<WindowEventMap['beforeinstallprompt']>();

  const clearInstallEvent = useCallback(() => {
    return setInstallEvent(undefined);
  }, []);

  useEffect(() => {
    const handler = (e: WindowEventMap['beforeinstallprompt']) => {
      e.preventDefault();
      setInstallEvent(e);
    };
    window.addEventListener('beforeinstallprompt', handler);
    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      window.location.reload();
    };
    window.addEventListener('appinstalled', handler);
    return () => {
      window.removeEventListener('appinstalled', handler);
    };
  }, []);

  return (
    <InstallAppContext.Provider value={{ installEvent, clearInstallEvent }}>
      {children}
    </InstallAppContext.Provider>
  );
};
