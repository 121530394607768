import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderSubtitle,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ManageRelatedDesktops } from '../../../../Desktop/ManageRelatedDesktops';
import { TeamTranslation } from '../../../i18n';
import type { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

interface ManageRelatedDesktopsModal extends ModalProps {
  subtitle?: string;
  relatedDesktops: DesktopApiType[];
  onSubmit: (desktops: DesktopApiType[]) => Promise<unknown>;
}

export const ManageRelatedDesktopsModal: FC<ManageRelatedDesktopsModal> = ({
  relatedDesktops,
  onRequestClose,
  subtitle,
  onSubmit,
  ...props
}) => {
  return (
    <Modal width={356} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={TeamTranslation.adminTeamDesktopsManageModalHeader}
            />
          </ModalHeaderTitle>
          {subtitle && (
            <ModalHeaderSubtitle data-testid="subtitle">
              {subtitle}
            </ModalHeaderSubtitle>
          )}
        </div>
      </ModalHeader>
      <ManageRelatedDesktops
        onSubmit={onSubmit}
        relatedDesktops={relatedDesktops}
      />
    </Modal>
  );
};
