import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderSubtitle,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ManageRelatedUsers } from '../../../../User/ManageRelatedUsers';
import { AccountApiType } from '../../../../User/User.types';
import { TeamTranslation } from '../../../i18n';

interface ManageRelatedUsersModalProps extends ModalProps {
  subtitle?: string;
  relatedUsers: AccountApiType[];
  onSubmit: (users: AccountApiType[]) => Promise<unknown>;
}

export const ManageRelatedUsersModal: FC<ManageRelatedUsersModalProps> = ({
  relatedUsers,
  onRequestClose,
  subtitle,
  onSubmit,
  ...props
}) => {
  return (
    <Modal width={356} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={TeamTranslation.adminTeamMembersManageModalHeader}
            />
          </ModalHeaderTitle>
          {subtitle && (
            <ModalHeaderSubtitle data-testid="subtitle">
              {subtitle}
            </ModalHeaderSubtitle>
          )}
        </div>
      </ModalHeader>
      <ManageRelatedUsers onSubmit={onSubmit} relatedUsers={relatedUsers} />
    </Modal>
  );
};
