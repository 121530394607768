import styled from 'styled-components';
import {
  Button,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { Separator } from '../../../../../shared/components/Separator';
import { Link, LinkSize } from '../../../../../shared/components/Link';

export const StyledJoinChatOptions = styled.div`
  margin-top: 1rem;

  & > * ~ * {
    margin-top: 1rem;
  }
`;

export const JoinChatButton = styled(Button).attrs({
  fullWidth: true,
  size: ButtonSize.lg,
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.2;

  svg,
  img {
    margin-right: 0.5rem;
  }
`;

export const JoinChatLink = styled(Link).attrs({
  fullWidth: true,
  size: LinkSize.lg,
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.2;

  svg,
  img {
    margin-right: 0.5rem;
  }
`;

export const JoinChatOptionsSeparator = styled(Separator).attrs({
  noWrap: true,
  uppercase: true,
})`
  margin: 1.5rem 0;
  color: ${p => p.theme.colors.Divider};
`;
