import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { BroadcastChannelContext } from './BroadcastChannel.context';
import {
  BroadcastChannelMessage,
  BroadcastChannelMessageType,
} from './BroadcastChannel.types';

const MAIN_BROADCAST_CHANNEL_NAME = 'desktop_main';

export const BroadcastChannelProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel>();

  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const newBroadcastChannel = new BroadcastChannel(
        MAIN_BROADCAST_CHANNEL_NAME,
      );
      setBroadcastChannel(newBroadcastChannel);
    }
  }, []);

  const postMessage = (type: BroadcastChannelMessageType) => {
    broadcastChannel?.postMessage({ type });
  };

  const subscribe = (
    type: BroadcastChannelMessageType,
    callback: (data?: BroadcastChannelMessage) => void,
  ) => {
    if (!broadcastChannel) {
      return;
    }

    broadcastChannel!.onmessage = event => {
      if (event.data?.type === type) {
        callback(event.data);
      }
    };
  };

  return (
    <BroadcastChannelContext.Provider
      value={{
        postMessage,
        subscribe,
      }}>
      {children}
    </BroadcastChannelContext.Provider>
  );
};
