import styled from 'styled-components';

export const StyledIdentifierContainer = styled.div`
  display: flex;

  .main-domain {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    margin-left: 0.5rem;
    margin-top: 2rem;
  }
`;
