import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import {
  StyledChatItemBody,
  StyledChatMessage,
  StyledHighlighter,
  StyledItemHeader,
  StyledItemType,
  StyledNavLink,
} from './SearchItems.styled';
import {
  GlobalSearchResultChatConversationHit,
  GlobalSearchResultChatMessageHit,
} from '../../../GlobalSearch.queries';
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { getAccountName } from '../../../../User/User.utils';
import { format, parseISO } from 'date-fns';
import { DateLocales } from '../../../../Intl/i18n/i18n.dates';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../../../Workspace/Workspace.hooks';
import { ChatAvatar } from '../../../../Chat/ChatAvatar';
import { AccountApiType } from '../../../../User/User.types';
import { useConversationStateControls } from '../../../../Chat/ChatView/ConversationState/ConversationState.hooks';
import { openUrl } from '../../../../../shared/utils/url.utils';
import { generatePath } from 'react-router-dom';
import { getShortId } from '../../../../../shared/utils/id';
import { CHAT_ID_PATHNAME } from '../../../../Chat/Chat.constants';
import { convertToMessageParts } from '../../../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.utils';
import { MessagePartTypes } from '../../../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.types';
import { Mention } from '../../../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/Mention';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { OpenType } from '../../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { useSearchMessageData } from '../../../../Chat/Chat.hooks';
import { useAccountByIri } from '../../../../Account';
import { getSearchResultExcerpt } from './SearchItems.utils';
import { useSearchResultsConversationName } from '../SearchOnlyByConversation.hooks';
import { DELETED_ACCOUNT_ID } from '../../../../Account/Account.constants';
import { useOpenThreadSidebar } from '../../../../Chat/ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.hooks';
import { useChatConversationRepository } from '../../../../Chat/Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { ConversationViewContext } from '../../../../Chat/ChatView/ConversationView/ConversationView.context';

const SKIP_BLUR_TAG_NAMES = ['A'];

interface ChatItemInterface
  extends GlobalSearchResultChatMessageHit,
    Pick<GlobalSearchResultChatConversationHit, 'users' | 'type'> {
  onBlur: () => void;
  searchQuery: string;
  customConversationTitle: string | undefined;
  searchOnlyByConversation: boolean;
  conversationIri: string;
}

interface ChatConversationInterface
  extends Pick<GlobalSearchResultChatConversationHit, 'users' | 'type'> {
  id?: string;
  accountIri?: string;
  createdAt?: string;
  parentChatMessageIri?: string | null;
  message?: string;
  onBlur: () => void;
  searchQuery: string;
  customConversationTitle: string | undefined;
  searchOnlyByConversation: boolean;
  conversationIri: string;
}

type ChatItemProps = ChatItemInterface | ChatConversationInterface;

export const ChatItem: FC<ChatItemProps> = memo(
  ({
    id,
    accountIri,
    createdAt,
    conversationIri,
    parentChatMessageIri,
    customConversationTitle,
    searchQuery,
    users,
    type,
    searchOnlyByConversation,
    message = '',
    onBlur,
  }) => {
    const { locale } = useIntl();
    const { workspace } = useCurrentWorkspace();
    const dateFnsLocale = DateLocales[locale];
    const { mobileNavigationSidebarIsOpen, mobileNavigationSidebarClose } =
      useMobileNavigationSidebar();

    const conversationName = useSearchResultsConversationName(
      users,
      type,
      customConversationTitle,
    );
    const { openThreadSidebar } = useOpenThreadSidebar();

    const { setState } = useConversationStateControls();

    const { setLoadingListState } = useContext(ConversationViewContext);

    const { unHideConversation } = useChatConversationRepository();

    const { setSearchMessage } = useSearchMessageData();

    const setConversationState = useCallback(
      (event: React.MouseEvent) => {
        if (mobileNavigationSidebarIsOpen) {
          mobileNavigationSidebarClose();
        }

        if (SKIP_BLUR_TAG_NAMES.includes(event.currentTarget.tagName)) {
          onBlur();
          return null;
        }

        setState({
          scrollToMessageId: id,
          openThreadOfMessageId: parentChatMessageIri,
        });

        onBlur();
      },
      [
        mobileNavigationSidebarIsOpen,
        setState,
        id,
        parentChatMessageIri,
        onBlur,
        mobileNavigationSidebarClose,
      ],
    );

    const handleChatItemClick = useCallback(
      async (event: React.MouseEvent) => {
        setConversationState(event);
        unHideConversation(conversationIri);

        if (parentChatMessageIri) {
          openThreadSidebar(parentChatMessageIri, conversationIri, id, true);
          return;
        }

        if (conversationIri) {
          setLoadingListState();

          const searchMessageData = {
            id: parentChatMessageIri || id || '',
            createdAt: createdAt || '',
            isThreadMessage: parentChatMessageIri !== null,
          };

          setSearchMessage(searchMessageData);
        }
      },
      [
        setConversationState,
        conversationIri,
        createdAt,
        id,
        openThreadSidebar,
        parentChatMessageIri,
        setLoadingListState,
        setSearchMessage,
        unHideConversation,
      ],
    );

    const pathName = useMemo(
      () =>
        generatePath(CHAT_ID_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          chatId: getShortId(conversationIri),
        }),
      [conversationIri, workspace.id],
    );

    const messageParts = useMemo(
      () => convertToMessageParts(message),
      [message],
    );
    const account = useAccountByIri(accountIri || '');
    const { account: currentAccount } = useCurrentAccount();
    const openType = currentAccount?.identity.openLink || OpenType.newTab;

    const openLink = useCallback(
      (event: React.MouseEvent, linkUrl: string) => {
        event.preventDefault();
        openUrl(openType, linkUrl, linkUrl, event);
      },
      [openType],
    );

    if (!pathName || getShortId(account.id) === DELETED_ACCOUNT_ID) {
      return null;
    }

    return (
      <StyledNavLink onClick={handleChatItemClick} to={pathName}>
        {account && (
          <ChatAvatar
            account={account as AccountApiType}
            showOnlineStatus={false}
            className="avatar"
            avatarSize="1.25rem"
          />
        )}
        <StyledChatItemBody>
          <StyledItemHeader>
            {account ? (
              <span>{getAccountName(account)}</span>
            ) : (
              <FormattedMessage
                id={GlobalSearchTranslation.expandedSearchDeletedAccount}
              />
            )}
            {!searchOnlyByConversation && (
              <>
                &nbsp;
                <FormattedMessage
                  id={GlobalSearchTranslation.expandedSearchChatIn}
                />
                &nbsp;
                <span>{`#${conversationName}`}</span>
              </>
            )}
            {createdAt && (
              <span>
                &nbsp;
                {'- '}
                {format(parseISO(createdAt), 'PPpp', {
                  locale: dateFnsLocale,
                })}
              </span>
            )}
          </StyledItemHeader>
          {message && (
            <StyledChatMessage>
              {messageParts.map(({ type, value }, i) => {
                if (type === MessagePartTypes.Mention) {
                  return (
                    <Mention key={i} skipAccountPopover>
                      {value}
                    </Mention>
                  );
                }
                if (type === MessagePartTypes.Link) {
                  return (
                    <a
                      key={i}
                      onClick={e => openLink(e, value)}
                      href={value}
                      rel="noopener noreferrer">
                      {value}
                    </a>
                  );
                }
                return (
                  <StyledHighlighter
                    key={i}
                    highlightClassName="textHighlighter"
                    searchWords={[searchQuery]}
                    autoEscape={true}
                    textToHighlight={getSearchResultExcerpt(searchQuery, value)}
                  />
                );
              })}
            </StyledChatMessage>
          )}
        </StyledChatItemBody>
        <StyledItemType>
          <FormattedMessage
            id={
              message
                ? GlobalSearchTranslation.expandedSearchChatType
                : GlobalSearchTranslation.expandedSearchConversationType
            }
          />
        </StyledItemType>
      </StyledNavLink>
    );
  },
);
