import {
  GRAPHQL_TYPENAME_WORKSPACE,
  GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_CONNECTION,
  GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_EDGE,
} from './Workspace.constants';
import { PaymentPlan } from '../Billing/Billing.types';
import type { WorkspaceNotificationsApiType } from '../Admin/WorkspaceNotifications';
import type { ApiConnection, ImageApiType } from '../../shared/api/api.types';
import type {
  DesktopApiType,
  DesktopPermissionsApiType,
} from '../Desktop/data/Desktop/types/Desktop.types';

export interface WorkspaceApiType {
  __typename?: typeof GRAPHQL_TYPENAME_WORKSPACE;
  id: string;
  name: string;
  description: string;
  contactEmail: string;
  homepageUrl: string;
  image: ImageApiType | null;
  mfaRequired: boolean;
  membersCount: number;
  isUpgrading: boolean;
  desktops?: ApiConnection<
    DesktopApiType,
    typeof GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_CONNECTION,
    typeof GRAPHQL_TYPENAME_WORKSPACE_DESKTOP_EDGE
  >;
  freeTrialDaysLeft?: number | null;
  billingRequired?: boolean;
  billingOk?: boolean;
  isDowngrading?: boolean;
  type?: PaymentPlan;
  canceledAt?: string | null;
  nextRenewAt?: string | null;
  identifier?: string;
  uri: string;
  accountSortPosition: number;
  gracePeriodFinishedAt?: string;
  organizeAndSearch: boolean;
  communicate: boolean;
}

export interface WorkspaceEdgeApiType extends WorkspacePermissions {
  id: string;
  workspace: WorkspaceApiType;
  accountWorkspaceId: string;
  isMfaRequired: boolean;
  isMfaValid: boolean;
  webPushEnabled?: boolean;
  webPushEndpoints?: string[];
  accountWorkspaceSetting: WorkspaceNotificationsApiType;
}

export interface WorkspaceFormValues {
  name: string;
  description: string;
  contactEmail: string;
  homepageUrl: string;
  image?: string;
  mfaRequired: boolean;
  workspaceDomain?: string;
  identifier?: string;
}

export enum RoleInCompany {
  Director = 'Director',
  SalesAndMarketing = 'SalesAndMarketing',
  Technology = 'Technology',
  HumanResources = 'HumanResources',
  Other = 'Other',
}

export interface ActivateWorkspaceInputType {
  name: string;
  identifier?: string;
  companySize?: string;
  role?: RoleInCompany | '';
}

export interface UpgradeWorkspaceInputType {
  id: string;
  newType: PaymentPlan;
  reuseOldBilling: boolean;
}

export interface DowngradeWorkspaceInputType {
  id: string;
}

export interface EditWorkspaceInputType extends WorkspaceFormValues {
  id: string;
}

export enum WorkspacePermissionKey {
  canAccessAdminPanel = 'canAccessAdminPanel',
  canCreateDesktop = 'canCreateDesktop',
  canCreateCompanyDesktop = 'canCreateCompanyDesktop',
  canEditWorkspace = 'canEditWorkspace',
  canResetMfaRequest = 'canResetMfaRequest',
  canAddCustomApp = 'canAddCustomApp',
  canRemoveCustomApp = 'canRemoveCustomApp',
  canMenuCustomAppsPage = 'canMenuCustomAppsPage',
  canViewCustomAppsPage = 'canViewCustomAppsPage',
  canCreateScheduleCalls = 'canCreateScheduleCalls',
  canMenuBillingPage = 'canMenuBillingPage',
  canViewBillingPage = 'canViewBillingPage',
  canEditBillingPage = 'canEditBillingPage',
  canUpgrade = 'canUpgrade',
  canDowngrade = 'canDowngrade',
  canEnableMfa = 'canEnableMfa',
  canMenuSsoPage = 'canMenuSsoPage',
  canViewSsoPage = 'canViewSsoPage',
  canEditSsoPage = 'canEditSsoPage',
  canChangeIdentifier = 'canChangeIdentifier',
  canTogglePersona = 'canTogglePersona',
  canMenuGroupsPage = 'canMenuGroupsPage',
  canViewGroupsPage = 'canViewGroupsPage',
  canEditGroupsPage = 'canEditGroupsPage',
  canMenuDesktopsPage = 'canMenuDesktopsPage',
  canViewDesktopsPage = 'canViewDesktopsPage',
  canEditDesktopsPage = 'canEditDesktopsPage',
  canViewProfilePage = 'canViewProfilePage',
  canInviteUsers = 'canInviteUsers',
  canMenuUsersPage = 'canMenuUsersPage',
  canViewUsersPage = 'canViewUsersPage',
  canEditUsersPage = 'canEditUsersPage',
  canChangeWorkspaceName = 'canChangeWorkspaceName',
  canChangeWorkspaceAdminEmail = 'canChangeWorkspaceAdminEmail',
  canViewFullWorkspaceInfo = 'canViewFullWorkspaceInfo',
  canMenuDomainPage = 'canMenuDomainPage',
  canViewDomainPage = 'canViewDomainPage',
  canEditDomainPage = 'canEditDomainPage',
  canViewWorkspaceUserDesktops = 'canViewWorkspaceUserDesktops',
  canUnlockCredentials = 'canUnlockCredentials',
  canSeeMyPasswords = 'canSeeMyPasswords',
  canCreateGroupChat = 'canCreateGroupChat',
  canCreateGroupCall = 'canCreateGroupCall',
  canUpdateGroupChat = 'canUpdateGroupChat',
  canExplicitlyShareVault = 'canExplicitlyShareVault',
  canViewWorkspaceAppIntegrations = 'canViewWorkspaceAppIntegrations',
  canViewPersonalAppIntegrations = 'canViewPersonalAppIntegrations',
  canEditWorkspaceFeatures = 'canEditWorkspaceFeatures',
  canMenuAppIntegrationsPage = 'canMenuAppIntegrationsPage',
  canViewGlobalSearchIntegrations = 'canViewGlobalSearchIntegrations',
  canViewGlobalSearchApps = 'canViewGlobalSearchApps',
  canViewGlobalSearchLinks = 'canViewGlobalSearchLinks',
  canViewGlobalSearchChats = 'canViewGlobalSearchChats',
  canViewGlobalSearchMeetings = 'canViewGlobalSearchMeetings',
  canMenuDesktopsSidebarItem = 'canMenuDesktopsSidebarItem',
  canMenuChatsSidebarItem = 'canMenuChatsSidebarItem',
  canMenuMeetingsSidebarItem = 'canMenuMeetingsSidebarItem',
  canAccessDesktopPage = 'canAccessDesktopPage',
  canAccessChatPage = 'canAccessChatPage',
  canAccessMeetingPage = 'canAccessMeetingPage',
  canViewInboxNotifications = 'canViewInboxNotifications',
  canRedirectToDesktopByDefault = 'canRedirectToDesktopByDefault',
  canViewDesktopChatSection = 'canViewDesktopChatSection',
  canCallAllDesktopMembers = 'canCallAllDesktopMembers',
  canCallWorkspaceMembers = 'canCallWorkspaceMembers',
  canMessageWorkspaceMembers = 'canMessageWorkspaceMembers',
  canSeeInstallPluginToast = 'canSeeInstallPluginToast',
}

export type WorkspacePermissions = {
  [key in WorkspacePermissionKey]: boolean;
};

export type WorkspacePermissionsApiType = {
  [key in WorkspacePermissionKey]: boolean;
} & {
  desktops: DesktopPermissionsApiType[];
};

export interface CreateSubdomainInputValues {
  id: string;
  identifier: string;
}

export interface CreateSubdomainFormValues {
  identifier: string;
}

export interface WorkspaceSortType {
  id: string;
  position: number;
}
