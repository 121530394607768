import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { extractNodes } from '../../../shared/api/api.utils';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { Spinner } from '../../../shared/components/Spinner';
import { showToastSuccessMessage } from '../../../shared/components/Toast';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderTitle,
  AdminListEmptyMessage,
  AdminPage,
  DrawerHeader,
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionHeaderTitle,
} from '../../Admin';
import { WorkspaceTranslation } from '../../Workspace/i18n';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { useWorkspaceDomainsQuery } from '../Domain.hooks';
import { WorkspaceDomainApiType } from '../Domain.types';
import { DomainTranslation } from '../i18n';
import { CreateDomainModal } from './CreateDomainModal';
import { DomainItem } from './Domain';
import { EditDomainModal } from './EditDomainModal';
import { getShortId } from '../../../shared/utils/id';

const PageContent = styled.div`
  padding: 1.25rem 0;
`;

const DomainsList = styled.ul`
  transition: background-color 0.3s ease;

  li {
    cursor: pointer;

    &:hover {
      background-color: ${p => p.theme.colors.OnSurfaceHover};
    }
  }
`;

export const DomainAdmin: FC<{}> = () => {
  const isMobile = useMobile();
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  const queryParams = useQueryParams();
  const { createDomainModal, editDomainModal } = queryParams;
  const [currentDomain, setCurrentDomain] =
    useState<WorkspaceDomainApiType | null>(null);

  const { data, loading, refetch } = useWorkspaceDomainsQuery({
    variables: {
      workspace: workspace!.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const workspaceDomains = useMemo(
    () => extractNodes(data?.workspaceDomains),
    [data],
  );

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const {
    permissions: { canViewDomainPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  if (permissionsLoaded && !canViewDomainPage) {
    navigate(`/workspace/${getShortId(workspace.id)}/admin`);
  } else if (!permissionsLoaded) {
    return null;
  }

  const handleDoneCreateDomain = () => {
    refetch();
    showToastSuccessMessage(
      DomainTranslation.adminListCreateDomainToastMessage,
    );
  };

  return (
    <AdminPage data-testid="domain-admin">
      {isMobile && (
        <>
          <DrawerHeader
            onRequestClose={() =>
              navigate(`/workspace/${getShortId(workspace.id)}`)
            }>
            <FormattedMessage id={WorkspaceTranslation.adminNavDomainsLink} />
          </DrawerHeader>
        </>
      )}
      <AdminHeader data-testid="header">
        {!isMobile && (
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage id={WorkspaceTranslation.adminNavDomainsLink} />
          </AdminHeaderTitle>
        )}

        <AdminHeaderControls data-testid="controls">
          <Button
            size={ButtonSize.sm}
            mode={ButtonMode.primary}
            disabled={false}
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  createDomainModal: true,
                }),
              })
            }
            data-testid="new-domain-button"
            data-tourid="admin-create-domain-button">
            <FormattedMessage
              id={DomainTranslation.adminListCreateDomainButton}
            />
          </Button>
        </AdminHeaderControls>
      </AdminHeader>

      <PageContent>
        <Section data-testid="note-section">
          <SectionHeader data-testid="section-header">
            <SectionHeaderTitle data-testid="title">
              <FormattedMessage
                id={DomainTranslation.adminListDomainSectionHeader}
              />
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionBody data-testid="section-body">
            <SectionDescription noBottomMargin data-testid="domains-info">
              <FormattedMessage
                id={DomainTranslation.adminListDomainSectionDescription}
              />
            </SectionDescription>
          </SectionBody>
        </Section>

        <Section data-testid="list-section">
          <SectionHeader data-testid="section-header">
            <SectionHeaderTitle data-testid="title">
              <FormattedMessage
                id={DomainTranslation.adminListDomainSectionTitle}
              />
            </SectionHeaderTitle>
          </SectionHeader>

          {loading && !data ? (
            <Spinner />
          ) : (
            <DomainsList data-testid="domains-list">
              {workspaceDomains?.map(item => (
                <DomainItem
                  key={item.id}
                  domain={item}
                  handleCurrentDomain={setCurrentDomain}
                  refetch={refetch}
                />
              ))}

              {!workspaceDomains.length && (
                <AdminListEmptyMessage>
                  <FormattedMessage
                    id={DomainTranslation.adminListEmptyMessage}
                  />
                </AdminListEmptyMessage>
              )}
            </DomainsList>
          )}
        </Section>
      </PageContent>

      {createDomainModal && (
        <CreateDomainModal
          visible={true}
          onDone={handleDoneCreateDomain}
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                createDomainModal: undefined,
              }),
            })
          }
        />
      )}

      {editDomainModal && currentDomain && (
        <EditDomainModal
          currentDomain={currentDomain}
          visible={true}
          onDone={refetch}
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                editDomainModal: undefined,
              }),
            })
          }
        />
      )}
    </AdminPage>
  );
};
