import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PwaForceReinstallText,
  StyledPwaForceReinstallScreen,
} from './PwaForceReinstallScreen.styled';
import { PwaTranslation } from '../../i18n';
import { DesktopLogoIcon } from '../../../../shared/icons';

export const PwaForceReinstallScreen: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <StyledPwaForceReinstallScreen>
      <DesktopLogoIcon width={64} height={64} />
      <FormattedMessage
        id={PwaTranslation.requiresReinstallWithNewDomain}
        tagName={PwaForceReinstallText}
      />
    </StyledPwaForceReinstallScreen>
  );
};
