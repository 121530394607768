import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { SignUpFlow } from '../../../Onboarding.types';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

interface RegistrationTitleProps {
  flow: SignUpFlow;
  invitation?: AccountInvitationApiType;
}

export const RegistrationTitle: FC<RegistrationTitleProps> = ({
  flow,
  invitation,
}) => {
  if (flow === SignUpFlow.INVITATION && invitation) {
    return (
      <FormattedMessage
        id={OnboardingTranslation.onboardingRegistrationPageInvitationTitle}
        values={{ workspace: invitation?.workspaceName }}
      />
    );
  } else if (flow === SignUpFlow.FREE) {
    return (
      <FormattedMessage
        id={OnboardingTranslation.onboardingRegistrationPageFreeTitle}
      />
    );
  } else if (flow === SignUpFlow.PRO) {
    return (
      <FormattedMessage
        id={OnboardingTranslation.onboardingRegistrationPageProTitle}
      />
    );
  } else if (flow === SignUpFlow.ENTERPRISE) {
    return (
      <FormattedMessage
        id={OnboardingTranslation.onboardingRegistrationPageEnterpriseTitle}
      />
    );
  }

  return null;
};
