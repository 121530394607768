import React, { FC, useEffect, useMemo, useState } from 'react';
import { matchPath, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { StyledChatsPage } from './ChatsPage.styled';
import { ChatView } from '../ChatView';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { CreateConversationModal } from '../CreateConversation/CreateConversationModal';
import {
  CHATS_CONVERSATION_PATHNAME,
  CHATS_CONVERSATION_PATHNAME_SLUG,
  CHATS_FIND_ACCOUNT_CHAT_SLUG,
} from '../../Desktop/Desktop.constants';
import { AccountChatFinder } from './AccountChatFinder';
import { MembersUniversal } from '../MembersUniversal';
import { getShortId } from '../../../shared/utils/id';
import _debounce from 'lodash/debounce';
import { ChatConversationInternalType } from '../Chat.types';
import { Route } from '../../ErrorInterceptor/router.injectors';
import { SegmentEmpty } from '../../Segment';
import { FormattedMessage } from 'react-intl';
import { ChatTranslation } from '../i18n';

const RERENDER_ON_RESIZE_TIMEOUT = 500;
const REDIRECT_TO_CREATED_CHAT_TIMEOUT = 200;

type ChatsPageProps = {
  listChatConversations: ChatConversationInternalType[];
};

export const ChatsPage: FC<ChatsPageProps> = ({ listChatConversations }) => {
  const isMobile = useMobile();
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { createChatConversationModal } = queryParams;
  const { pathname } = useLocation();
  const [, triggerRerender] = useState(0);
  const {
    permissions: { canCreateGroupChat },
  } = useCurrentWorkspacePermissions();

  useEffect(() => {
    const handleWindowResize = _debounce(
      () => triggerRerender(prevState => prevState + 1),
      RERENDER_ON_RESIZE_TIMEOUT,
    );
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const currentConversationId = useMemo(() => {
    const match = matchPath(CHATS_CONVERSATION_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { conversationId } = match.params;
    return conversationId;
  }, [pathname]);

  const handleOnConversationCreated = (chatConversationId: string) => {
    setTimeout(() => {
      navigate({
        pathname: `/workspace/${getShortId(workspace.id)}/chats/${getShortId(
          chatConversationId,
        )}`,
        search: getQueryParamsFrom({
          ...queryParams,
          createChatConversationModal: undefined,
        }),
      });
    }, REDIRECT_TO_CREATED_CHAT_TIMEOUT);
  };

  return (
    <StyledChatsPage
      isMobile={isMobile}
      isChatVisible={!!currentConversationId}
      data-testid="chats-page">
      {!listChatConversations.length ? (
        <SegmentEmpty>
          <FormattedMessage id={ChatTranslation.chatEmptyState} />
        </SegmentEmpty>
      ) : (
        <Routes>
          <Route
            path={CHATS_FIND_ACCOUNT_CHAT_SLUG}
            element={<AccountChatFinder />}
          />
          <Route
            path={CHATS_CONVERSATION_PATHNAME_SLUG}
            element={<ChatView />}
          />
        </Routes>
      )}

      {createChatConversationModal && canCreateGroupChat && (
        <CreateConversationModal
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                createChatConversationModal: undefined,
              }),
            });
          }}
          onConversationCreated={handleOnConversationCreated}
        />
      )}

      <MembersUniversal addGridArea />
    </StyledChatsPage>
  );
};
