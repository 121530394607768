import React, { FC, useCallback, useMemo } from 'react';
import Select, { Options } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { useAccountsContext } from '../../Account';
import { getAccountName } from '../User.utils';
import { AccountWithCountsApiType } from '../User.types';
import { ChatAvatar } from '../../Chat/ChatAvatar';

interface UserSelectProps {
  selectedAccountIds: string[];
  filterAccountOptions?: (account: AccountWithCountsApiType) => boolean;
  disabled?: boolean;
  onSelect?: (accountIds: string[]) => void;
}

const OptionInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

const OptionChatAvatar = styled(ChatAvatar)`
  margin-right: 0.625rem;
`;

const StyledCustomOptionLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const UserSelect: FC<UserSelectProps> = ({
  selectedAccountIds,
  filterAccountOptions,
  disabled,
  onSelect,
}) => {
  const theme = useTheme();
  const { accountsWithAvailability: workspaceAccountsMap } =
    useAccountsContext();
  const selectOptions = useMemo(() => {
    return Object.values(workspaceAccountsMap)
      .filter(filterAccountOptions || (() => true))
      .map(account => ({
        value: account.id,
        label: getAccountName(account),
      }));
  }, [workspaceAccountsMap, filterAccountOptions]);

  const selectedOptions = useMemo(() => {
    return selectedAccountIds.map(accountId => ({
      value: accountId,
      label: getAccountName(workspaceAccountsMap[accountId]),
    }));
  }, [selectedAccountIds, workspaceAccountsMap]);

  const CustomOption = (props: any) => {
    const { innerProps, isDisabled, label, value } = props;
    const currentAccount = workspaceAccountsMap[value];

    return !isDisabled ? (
      <OptionInner {...innerProps}>
        {currentAccount && <OptionChatAvatar account={currentAccount} />}
        <StyledCustomOptionLabel>{label}</StyledCustomOptionLabel>
      </OptionInner>
    ) : null;
  };

  const handleSelect = useCallback(
    (values: Options<{ label?: string; value: string }>) => {
      if (onSelect) {
        onSelect(values.map(option => option.value));
      }
    },
    [onSelect],
  );

  return (
    <div data-testid="user-select">
      <Select
        components={{ Option: CustomOption }}
        options={selectOptions}
        value={selectedOptions}
        isMulti
        isDisabled={disabled}
        onChange={handleSelect}
        styles={{
          input: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceMediumEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          multiValue: styles => ({
            ...styles,
            backgroundColor: theme.colors.Background,
          }),
          multiValueLabel: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontWeight: 600,
            fontSize: '0.875rem',
            maxWidth: '15rem',
          }),
          multiValueRemove: styles => ({
            ...styles,
            cursor: 'pointer',
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              backgroundColor: theme.colors.BackgroundLight,
              color: theme.colors.OnSurfaceMediumEmphasis,
            },
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            fontWeight: 500,
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
        }}
      />
    </div>
  );
};
