const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (email: string): boolean => {
  return EMAIL_REGEXP.test(String(email).toLowerCase());
};

export const getEmailDomain = (email: string) => {
  if (!isEmail(email)) {
    return false;
  }

  return email.replace(/.*@/, '');
};
