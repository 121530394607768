import styled, { css } from 'styled-components';
import { ChatAvatar } from '../../../ChatAvatar';

export const OptionInner = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  ${p =>
    p.isFocused &&
    css`
      background-color: ${p => p.theme.colors.OnSurfaceSelected};
    `}
`;

export const OptionLabel = styled.span`
  margin-right: 0.25rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const OptionChatAvatar = styled(ChatAvatar)`
  margin-right: 0.625rem;
`;

export const StyledUserRole = styled.span`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.75rem;
`;
