import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  ConferenceControls,
  ConferenceInner,
  ConferenceStatusContainer,
  ControlsGroup,
  StyledConferencesListItem,
} from './ConferencesListItem.styled';
import {
  ConferenceApiType,
  ConferenceCallType,
  ConferencesTabsValues,
  ConferenceStatus as ConferenceStatusType,
} from '../../../../Conference.types';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { ConferenceStatus } from './ConferenceStatus';
import { ConferenceTitleDesktop, ConferenceTitleUser } from './ConferenceTitle';
import { useCurrentConference } from '../../../../Conference.hooks';
import { ConferenceAvatar } from '../../../../ConferenceAvatar';
import {
  CallIcon,
  MissedCallIcon,
  VideoCallIcon,
} from '../../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { ConferenceTranslation } from '../../../../i18n';
import { getShortId } from '../../../../../../shared/utils/id';

interface ConferencesListItemProps {
  conference: ConferenceApiType;
  onSeen?: (id: string) => void;
}

export const ConferencesListItem: FC<ConferencesListItemProps> = ({
  conference,
  onSeen,
}) => {
  const itemRef = useRef<any>(null);
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();
  const { conference: currentConference } = useCurrentConference();

  const { status } = conference;

  const conferenceStatus = useMemo(() => {
    switch (status) {
      case ConferenceStatusType.outgoing:
        return ConferenceTranslation.conferenceStatusOutgoing;
      case ConferenceStatusType.incoming:
        return ConferenceTranslation.conferenceStatusIncoming;
      case ConferenceStatusType.missed:
        return ConferenceTranslation.conferenceStatusMissed;
      default:
        return '';
    }
  }, [status]);

  useEffect(() => {
    if (onSeen) {
      const observer = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          onSeen(conference.id);
        }
      });
      if (itemRef.current) {
        observer.observe(itemRef.current);
      }
      return () => {
        observer.disconnect();
      };
    }
  }, [conference.id, onSeen]);

  const isActive = conference.id === currentConference?.id;
  const conferenceLink = `/workspace/${getShortId(
    workspace.id,
  )}/calls/${getShortId(conference.id)}?${getQueryParamsFrom({
    ...queryParams,
    meetingsActiveTab: ConferencesTabsValues.CALLS,
  })}`;

  if (!('participants' in conference.statistics)) {
    return null;
  }

  return (
    <StyledConferencesListItem
      ref={itemRef}
      to={conferenceLink}
      className={isActive ? 'active' : ''}>
      <ConferenceAvatar
        conference={conference}
        avatarSize="2.625rem"
        onlineStatusSize="0.875rem"
      />
      <div className="wrapper">
        <ConferenceInner>
          <div className="wrapper">
            {conference.desktop ? (
              <ConferenceTitleDesktop
                isActive={isActive}
                conference={conference}
              />
            ) : (
              <ConferenceTitleUser
                isActive={isActive}
                conference={conference}
              />
            )}
            <ConferenceStatusContainer>
              {conference.callType === ConferenceCallType.audio ? (
                status === ConferenceStatusType.missed ? (
                  <MissedCallIcon />
                ) : (
                  <CallIcon />
                )
              ) : (
                <VideoCallIcon />
              )}
              <ConferenceStatus isActive={isActive} status={conferenceStatus} />
            </ConferenceStatusContainer>
          </div>
          <ConferenceControls>
            <ControlsGroup>
              {/*{pinned && (*/}
              {/*  <ButtonWithIcon*/}
              {/*    icon={PinIcon}*/}
              {/*    aria-label={intl.formatMessage({*/}
              {/*      id: ChatTranslation.switcherListItemPinButton,*/}
              {/*    })}*/}
              {/*  />*/}
              {/*)}*/}
              {/*{conversation.unreadMessagesCount ? (*/}
              {/*  <div className="countBadgeWrapper">*/}
              {/*    <CountBadge*/}
              {/*      count={conversation.unreadMessagesCount}*/}
              {/*      color={colors.Primary}*/}
              {/*      positionMode={PositionType.relative}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*) : null}*/}
            </ControlsGroup>
          </ConferenceControls>
        </ConferenceInner>
      </div>
    </StyledConferencesListItem>
  );
};
