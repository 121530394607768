import React from 'react';
import {
  InstallExtensionBannerLink,
  StyledInstallExtensionBanner,
} from './InstallExtensionBanner.styled';
import { FormattedMessage } from 'react-intl';
import { ChromeExtensionTranslation } from '../../../i18n';

export const InstallExtensionBanner = () => {
  return (
    <StyledInstallExtensionBanner>
      <FormattedMessage
        id={ChromeExtensionTranslation.installExtensionBannerText}
      />
      <InstallExtensionBannerLink>
        <FormattedMessage
          id={ChromeExtensionTranslation.installExtensionBannerLink}
        />
      </InstallExtensionBannerLink>
    </StyledInstallExtensionBanner>
  );
};
