import { ApolloClient } from '@apollo/client';
import { NewChatConferenceEvent } from '../ChatMercure.types';
import { makeChatConference } from './chatConference.utils';
import {
  addConferenceToListCache,
  updateConferenceInListCache,
} from '../../../Conference/cache/Conference.cache';
import { DEFAULT_PAGE_SIZE } from '../../../Conference/ConferencesPage';
import { AccountApiType } from '../../../User/User.types';
import { captureException } from '@sentry/react';
import { UseAccountApiRepositoryProps } from '../../../Account/data/CurrentAccount/types/CurrentAccountRepository.types';

export const handleNewChatConference = (
  event: NewChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  const chatConference = makeChatConference(event);
  addConferenceToListCache(
    apolloClient,
    {
      first: DEFAULT_PAGE_SIZE,
      workspace: event.workspace.id,
    },
    chatConference,
  );
};

export const handleUpdateChatConference = async (
  event: NewChatConferenceEvent,
  apolloClient: ApolloClient<any>,
  currentAccount: AccountApiType | null | undefined,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  accountApiRepository: UseAccountApiRepositoryProps,
) => {
  const chatConference = makeChatConference(event);

  const { getAccountUnreadChatConferences } = accountApiRepository;

  updateConferenceInListCache(
    apolloClient,
    {
      first: DEFAULT_PAGE_SIZE,
      workspace: event.workspace.id,
    },
    chatConference,
  );

  const isCurrentUserParticipated =
    chatConference.statistics.participants?.some(
      participant =>
        participant.id === currentAccount?.id && participant.participated,
    );

  if (
    chatConference.createdBy.id === currentAccount?.id ||
    !chatConference.statistics.has_ended ||
    isCurrentUserParticipated ||
    ![null, 'missed'].includes(chatConference.status)
  ) {
    return;
  }

  try {
    const unreadChatConferences = await getAccountUnreadChatConferences();

    updateAccount(account => ({
      ...account,
      unreadChatConferences,
    }));
  } catch (err) {
    captureException(err);
  }
};
