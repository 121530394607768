import styled from 'styled-components';

export const InvitationActionsDescription = styled.p`
  margin: 2.25rem 0;
  line-height: 1.43;
  letter-spacing: -0.14px;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  b {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const InvitationActionPreview = styled.div`
  svg {
    display: block;
    margin: 0.5rem auto;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  }
`;

export const WorkspacePreview = styled.div`
  display: flex;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  margin-bottom: 2.25rem;
`;

export const WorkspacePreviewData = styled.div`
  margin: auto 0 auto 0.75rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const WorkspacePreviewName = styled.div`
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const WorkspacePreviewAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const WorkspacePreviewAvatar = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
  color: ${p => p.theme.colors.PrimaryTextLight};

  ${WorkspacePreviewAvatarImage} {
    border-radius: 50%;
  }
`;
