import React, { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../../shared/hooks';
import { CalendarIcon, VideoCallIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { PREVIEW_SEGMENT_URL_CHANGE_DELAY } from '../../../../PreviewSegment/PreviewSegment.constants';
import { usePreviewSegment } from '../../../../PreviewSegment/PreviewSegment.hooks';
import { SegmentType } from '../../../../Segment';
import {
  ConferenceNavigatorTitleActionsContainer,
  NavigatorActionButton,
} from '../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';
import { ScheduleConferencePopover } from '../../../ConferenceSchedule/ScheduleConferenceModal/ScheduleConferencePopover';
import { ConferenceTranslation } from '../../../i18n';

export const ConferenceNavigatorTitleActions: FC = () => {
  const {
    permissions: { canCreateScheduleCalls },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const { isPreviewMode, closePreview, navigateToSegment } =
    usePreviewSegment();

  const popopverRef = useRef(null);

  const handleScheduleConferenceClick = () => {
    if (isPreviewMode) {
      closePreview();
      navigateToSegment(SegmentType.MEETINGS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            scheduleConferenceOpened: true,
            newScheduleConference: true,
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  return canCreateScheduleCalls ? (
    <ConferenceNavigatorTitleActionsContainer ref={popopverRef}>
      <NavigatorActionButton
        secondaryButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: ConferenceTranslation.tooltipScheduleMeeting,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={CalendarIcon}
        iconSize={20}
        onClick={handleScheduleConferenceClick}
        data-testid="scheduled-call-button"
      />

      <NavigatorActionButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: ConferenceTranslation.tooltipStartNewScheduleMeeting,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={VideoCallIcon}
        iconSize={20}
        data-testid="scheduled-call-button"
      />

      <ScheduleConferencePopover triggerRef={popopverRef} />
    </ConferenceNavigatorTitleActionsContainer>
  ) : null;
};
