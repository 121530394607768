import { useContext } from 'react';
import {
  ConversationStateContext,
  ConversationStateControlsContext,
} from './ConversationState.context';

export const useConversationState = () => useContext(ConversationStateContext);

export const useConversationStateControls = () =>
  useContext(ConversationStateControlsContext);

export const useMountedFormsState = () => {
  const { mountedMessageFormsCount } = useConversationState();
  return {
    singleFormMounted: mountedMessageFormsCount === 1,
    multipleFormsMounted: mountedMessageFormsCount > 1,
  };
};
