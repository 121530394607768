import styled, { css } from 'styled-components';

export const SortableArea = styled.div<{ visualizeArea?: boolean }>`
  ${({ visualizeArea, theme }) =>
    visualizeArea &&
    css`
      /*
        NOTE:
        This feature is experimental and has to run through Theodore before being used.
        If it will be used a design update is expected.
      */
      border-radius: 4px;
      background-color: ${theme.colors.BackgroundLight};
    `};
`;

export const SortableItem = styled.div<{ isDragging?: boolean }>`
  position: relative;

  /* This is to avoid triggering any <Link to={...}> links */
  ${({ isDragging }) =>
    isDragging &&
    css`
      * {
        pointer-events: none;
        z-index: 1;
      }
    `}
`;
