import React, {
  FC,
  memo,
  MouseEventHandler,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import type {
  ChatMessageAssetApiType,
  ChatTextMessageInternalType,
} from '../../../../Chat.types';
import { MessageItemContainer } from '../ChatMessage.styled';
import { MessageAsset } from '../MessageAsset';
import { MOBILE_MAX_ASSET_TO_VIEW } from './AssetView';
import { usePrepareChatMessageAsset } from './MessageAsset.hooks';

interface MessageAssetContainerProps {
  message: ChatTextMessageInternalType;
  hasReactions: boolean;
  ownMessage: boolean;
  isMobile: boolean;
  allowReply: boolean;
  onAssetMenu: (
    chatMessage: ChatTextMessageInternalType,
    asset: ChatMessageAssetApiType,
    domRef: RefObject<HTMLElement>,
  ) => void;
  addMessageReaction: MouseEventHandler<HTMLButtonElement>;
  isEditing: boolean;
}

export const MessageAssetContainer: FC<MessageAssetContainerProps> = memo(
  ({ message, ownMessage, isMobile, onAssetMenu, isEditing }) => {
    const { assets, imageAssetsCount } = usePrepareChatMessageAsset(
      message.context.assets,
      isMobile,
    );

    const lastAssetCount = useCallback(
      (asset: ChatMessageAssetApiType): number | undefined => {
        if (
          !imageAssetsCount ||
          imageAssetsCount < MOBILE_MAX_ASSET_TO_VIEW ||
          !isMobile
        ) {
          return;
        }

        const lastAsset = assets[assets.length - 1];
        const assetCount = imageAssetsCount - MOBILE_MAX_ASSET_TO_VIEW;

        if (asset.assetIri === lastAsset.assetIri && assetCount > 0) {
          return assetCount;
        }
      },
      [assets, imageAssetsCount, isMobile],
    );

    const handleAssetMenuButton = useCallback(
      (asset: ChatMessageAssetApiType, ref: RefObject<HTMLElement>) => {
        if (asset) {
          onAssetMenu(message, asset, ref);
        }
      },
      [message, onAssetMenu],
    );

    const render = useMemo(() => {
      if (!assets.length) {
        return null;
      }

      return (
        <MessageItemContainer
          isMobile={isMobile}
          visible={isEditing}
          withAsset={true}
          data-testid="message-assets">
          {assets.map(asset => (
            <MessageAsset
              ownMessage={ownMessage}
              key={asset.assetIri}
              asset={asset}
              messageAssets={message.context.assets}
              chatMessage={message}
              originalAsset={asset ?? null}
              assetCounter={lastAssetCount(asset)}
              assetMenu={handleAssetMenuButton}
            />
          ))}
        </MessageItemContainer>
      );
    }, [
      assets,
      handleAssetMenuButton,
      isEditing,
      isMobile,
      lastAssetCount,
      message,
      ownMessage,
    ]);

    return render;
  },
);
