/*
 * Current implementation works for cases when page numbers starts from 0
 * */
export const getNextPage = (
  loadedItemsNumber = 0,
  pageSize = 0,
): number | null => {
  const lastPageLoaded = !!(loadedItemsNumber % pageSize);
  if (lastPageLoaded || !pageSize) {
    return null;
  }
  return Math.floor(loadedItemsNumber / pageSize);
};
