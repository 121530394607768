import styled from 'styled-components';

export const StyledMarketplaceItemWrapper = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: 0.5rem;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Surface};
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    border-color: ${p => p.theme.colors.SurfaceBorder};

    button {
      color: ${p => p.theme.colors.Primary};
    }
  }

  .name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    color: ${p => p.theme.colors.OnSurface};
  }

  .body {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .logo {
    width: 2.25rem !important;
    height: 2.25rem !important;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .description {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  .controls {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    font-weight: 400;

    button {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
`;
