export enum LinkDetailsTab {
  COMMENTS = 'comments',
  DETAILS = 'details',
}

export const LINK_DETAILS_MODAL_WIDTH_MOBILE = 428;
export const LINK_DETAILS_MODAL_WIDTH_TABLET = 736;
export const LINK_DETAILS_MODAL_WIDTH_LAPTOP = 1024;
export const LINK_DETAILS_MODAL_WIDTH_DESKTOP = 1600;
export const LINK_DETAILS_MODAL_PANE_WIDTH = 320;
