import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import { StyledDeleteVaultModal } from './UnshareVaultModal.styled';
import { UnshareVault } from '../UnshareVault';

interface UnshareVaultModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose'> {
  vaultId: string;
  onUnshared: (ids: string[]) => void;
}

export const UnshareVaultModal: FC<UnshareVaultModalProps> = ({
  vaultId,
  onUnshared,
  ...modalProps
}) => {
  return (
    <Modal visible={true} width={486} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage id={EncryptionTranslation.deleteVaultModalHeader} />
      </ModalHeader>
      <StyledDeleteVaultModal>
        <UnshareVault
          vaultId={vaultId}
          onUnshared={onUnshared}
          onCancelled={modalProps.onRequestClose}
        />
      </StyledDeleteVaultModal>
    </Modal>
  );
};
