import {
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  GET_ACCOUNT_GOOGLE_CONTACTS,
  GET_ACCOUNT_MICROSOFT_CONTACTS,
  GET_GOOGLE_AUTH_URL,
  GET_MICROSOFT_AUTH_URL,
  GetAccountGoogleContactsResponse,
  GetAccountGoogleContactsVariables,
  GetAccountMicrosoftContactsResponse,
  GetAccountMicrosoftContactsVariables,
  GetGoogleAuthUrlResponse,
  GetGoogleAuthUrlVariables,
  GetMicrosoftAuthUrlResponse,
  GetMicrosoftAuthUrlVariables,
} from './User.queries';

import {
  ACCEPT_ACCOUNT_INVITATION,
  AcceptAccountInvitationResponse,
  AcceptAccountInvitationVariables,
  ACTIVATE_WORKSPACE_USER,
  ActivateWorkspaceUserResponse,
  ActivateWorkspaceUserVariables,
  CHANGE_OWNER_WORKSPACE,
  CHANGE_PASSWORD_ACCOUNT,
  CHANGE_WORKSPACE_ACCOUNT_GROUP,
  ChangeOwnerWorkspaceResponse,
  ChangeOwnerWorkspaceVaribles,
  ChangePasswordAccountResponse,
  ChangePasswordAccountVariables,
  ChangeWorkspaceGroupForAccountResponse,
  ChangeWorkspaceGroupForAccountVariables,
  CREATE_ACCOUNT,
  CREATE_WORKSPACE_ACCOUNT_INVITATION,
  CREATE_WORKSPACE_BATCH_ACCOUNT_INVITATION,
  CreateAccountResponse,
  CreateAccountVariables,
  CreateWorkspaceAccountInvitationResponse,
  CreateWorkspaceAccountInvitationVariables,
  CreateWorkspaceBatchAccountInvitationResponse,
  CreateWorkspaceBatchAccountInvitationVariables,
  DELETE_ACCOUNT,
  DeleteAccountResponse,
  DeleteAccountVariables,
  REMOVE_USER_FROM_WORKSPACE,
  RemoveUserWorkspaceResponse,
  RemoveUserWorkspaceVariables,
  RESEND_INVITATION,
  ResendInvitationResponse,
  ResendInvitationVariables,
  REVOKE_EMAIL_CHANGE,
  REVOKE_INVITATION,
  RevokeEmailChangeResponse,
  RevokeEmailChangeVariables,
  RevokeInvitationResponse,
  RevokeInvitationVariables,
  SUSPEND_WORKSPACE_USER,
  SuspendWorkspaceUserResponse,
  SuspendWorkspaceUserVariables,
  UPDATE_ACCOUNT,
  UpdateAccountResponse,
  UpdateAccountVariables,
} from './User.mutations';
import { useCallback } from 'react';
import { updateAccountInvitationCache } from './cache';
import { AvailabilityStatusType } from './User.types';
import { AvailabilityAccountsType } from '../Account/providers/AccountsProvider/AccountsProvider.types';
import { useAccountsActions } from '../Account/data/Account/Account.actions';
import { AccountInvitationApiType } from '../Invitation/data/Invitation/types/Invitation.types';
import { GetForRegistrationAccountInvitationVariables } from '../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const useCreateAccountMutation = () =>
  useMutation<CreateAccountResponse, CreateAccountVariables>(CREATE_ACCOUNT);

export const useDeleteAccountMutation = () =>
  useMutation<DeleteAccountResponse, DeleteAccountVariables>(DELETE_ACCOUNT);

export const useRemoveUserWorkspaceMutation = () =>
  useMutation<RemoveUserWorkspaceResponse, RemoveUserWorkspaceVariables>(
    REMOVE_USER_FROM_WORKSPACE,
  );

export const useSuspendWorkspaceUserMutation = () =>
  useMutation<SuspendWorkspaceUserResponse, SuspendWorkspaceUserVariables>(
    SUSPEND_WORKSPACE_USER,
  );

export const useActivateWorkspaceUserMutation = () =>
  useMutation<ActivateWorkspaceUserResponse, ActivateWorkspaceUserVariables>(
    ACTIVATE_WORKSPACE_USER,
  );

export const useChangeWorkspaceGroupForAccount = () =>
  useMutation<
    ChangeWorkspaceGroupForAccountResponse,
    ChangeWorkspaceGroupForAccountVariables
  >(CHANGE_WORKSPACE_ACCOUNT_GROUP);

export const useCreateWorkspaceAccountInvitation = () =>
  useMutation<
    CreateWorkspaceAccountInvitationResponse,
    CreateWorkspaceAccountInvitationVariables
  >(CREATE_WORKSPACE_ACCOUNT_INVITATION);

export const useCreateWorkspaceBatchAccountInvitationMutation = () =>
  useMutation<
    CreateWorkspaceBatchAccountInvitationResponse,
    CreateWorkspaceBatchAccountInvitationVariables
  >(CREATE_WORKSPACE_BATCH_ACCOUNT_INVITATION);

export const useResendInvitationMutation = () =>
  useMutation<ResendInvitationResponse, ResendInvitationVariables>(
    RESEND_INVITATION,
  );

export const useRevokeInvitationMutation = () =>
  useMutation<RevokeInvitationResponse, RevokeInvitationVariables>(
    REVOKE_INVITATION,
  );

export const useAcceptAccountInvitation = () =>
  useMutation<
    AcceptAccountInvitationResponse,
    AcceptAccountInvitationVariables
  >(ACCEPT_ACCOUNT_INVITATION);

export const useUpdateAccountMutation = () =>
  useMutation<UpdateAccountResponse, UpdateAccountVariables>(UPDATE_ACCOUNT);

export const useChangePasswordAccountMutation = () =>
  useMutation<ChangePasswordAccountResponse, ChangePasswordAccountVariables>(
    CHANGE_PASSWORD_ACCOUNT,
  );

export const useChangeOwnerWorkspaceMutation = () =>
  useMutation<ChangeOwnerWorkspaceResponse, ChangeOwnerWorkspaceVaribles>(
    CHANGE_OWNER_WORKSPACE,
  );

export const useRevokeEmailChangeMutation = () =>
  useMutation<RevokeEmailChangeResponse, RevokeEmailChangeVariables>(
    REVOKE_EMAIL_CHANGE,
  );

export const useUserCacheUpdate = () => {
  const apolloClient = useApolloClient();
  const { updateAccount } = useAccountsActions();

  return {
    updateAccountInvitationCache: useCallback(
      (
        queryVariables: GetForRegistrationAccountInvitationVariables,
        invitation: AccountInvitationApiType,
      ) =>
        updateAccountInvitationCache(apolloClient, queryVariables, invitation),
      [apolloClient],
    ),
    updateAccountOnlineStatusInCache: useCallback(
      (
        identityId: string,
        newStatus: AvailabilityStatusType,
        timestamp: string,
        accountsWithAvailability: AvailabilityAccountsType,
      ) => {
        const accountToUpdate = Object.values(accountsWithAvailability).find(
          account => account.identityId === identityId,
        );

        if (accountToUpdate?.id) {
          updateAccount(accountToUpdate.id, account => ({
            ...account,
            onlineStatus: newStatus,
            lastActionAt: timestamp,
          }));
        }
      },
      [updateAccount],
    ),
  };
};

export const useGoogleAuthUrlQuery = (
  options?: QueryHookOptions<
    GetGoogleAuthUrlResponse,
    GetGoogleAuthUrlVariables
  >,
) =>
  useQuery<GetGoogleAuthUrlResponse, GetGoogleAuthUrlVariables>(
    GET_GOOGLE_AUTH_URL,
    options,
  );

export const useAccountGoogleContactsQuery = (
  options?: QueryHookOptions<
    GetAccountGoogleContactsResponse,
    GetAccountGoogleContactsVariables
  >,
) =>
  useQuery<GetAccountGoogleContactsResponse, GetAccountGoogleContactsVariables>(
    GET_ACCOUNT_GOOGLE_CONTACTS,
    options,
  );

export const useMicrosoftAuthUrlQuery = (
  options?: QueryHookOptions<
    GetMicrosoftAuthUrlResponse,
    GetMicrosoftAuthUrlVariables
  >,
) =>
  useQuery<GetMicrosoftAuthUrlResponse, GetMicrosoftAuthUrlVariables>(
    GET_MICROSOFT_AUTH_URL,
    options,
  );

export const useAccountMicrosoftContactsQuery = (
  options?: QueryHookOptions<
    GetAccountMicrosoftContactsResponse,
    GetAccountMicrosoftContactsVariables
  >,
) =>
  useQuery<
    GetAccountMicrosoftContactsResponse,
    GetAccountMicrosoftContactsVariables
  >(GET_ACCOUNT_MICROSOFT_CONTACTS, options);
