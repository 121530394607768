import React, { FC, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { WorkspaceApiType, WorkspaceFormValues } from '../Workspace.types';
import { FormTextarea } from '../../../shared/components/Textarea';
import { WorkspaceTranslation } from '../i18n';
import {
  FormControls,
  FormFields,
  IdentifierFormInputBlock,
  ImageRemoveButton,
  StyledIdentifierContainer,
  StyledWorkspaceFormControls,
  StyledWorkspaceFormImage,
  StyledWorkspaceFormImageContainer,
} from './WorkspaceForm.styled';
import { useCurrentWorkspacePermissions } from '../Workspace.hooks';
import { appEnv } from '../../../appEnv';
import { SubLabel } from '../../../shared/components/SubLabel';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { ImageUpload, ImageUploadBody } from '../../Image';
import UploadImagePlaceholder from '../../../shared/assets/images/image-upload.svg';

const CompanyFormSchema: Schema<WorkspaceFormValues> = Yup.object().shape({
  name: Yup.string().required(WorkspaceTranslation.formNameRequiredError),
  contactEmail: Yup.string().email(WorkspaceTranslation.formEmailError),
  description: Yup.string(),
  homepageUrl: Yup.string(),
  mfaRequired: Yup.boolean(),
  identifier: Yup.string(),
});

interface WorkspaceFormProps {
  workspace?: WorkspaceApiType;
  disabled?: boolean;
  disabledWorkspaceName?: boolean;
  onSubmit: (values: WorkspaceFormValues) => Promise<any>;
  onCancel: () => void;
}

export const WorkspaceForm: FC<WorkspaceFormProps> = ({
  workspace,
  disabled,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();
  const {
    permissions: {
      canChangeIdentifier,
      canChangeWorkspaceName,
      canChangeWorkspaceAdminEmail,
    },
  } = useCurrentWorkspacePermissions();
  const { formatMessage } = useIntl();

  const getInitialValues = (
    workspace?: WorkspaceApiType,
  ): WorkspaceFormValues => ({
    image: workspace?.image?.id ?? '',
    name: workspace?.name ?? '',
    contactEmail: workspace?.contactEmail ?? '',
    description: workspace?.description ?? '',
    homepageUrl: workspace?.homepageUrl ?? '',
    mfaRequired: workspace?.mfaRequired || false,
    ...(!canChangeIdentifier ? { identifier: workspace?.identifier } : {}),
  });

  const [imageUrl, setImageUrl] = useState<string>(
    workspace?.image?.contentUrl || '',
  );

  const initialValues: WorkspaceFormValues = getInitialValues(workspace);

  const workspaceDomain = `.${appEnv.WORKSPACE_DOMAIN}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanyFormSchema}
      onSubmit={(values, actions) => {
        onSubmit(values).then(response => {
          if (workspace && response?.data?.updateWorkspace?.workspace) {
            const formValues = getInitialValues(
              response.data.updateWorkspace.workspace,
            );
            actions.resetForm(formValues as any);
          }
          actions.setSubmitting(false);
        });
      }}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form data-testid="workspace-form">
          <FormFields>
            <div data-testid="workspace-logo-panel">
              <StyledWorkspaceFormImageContainer
                data-tooltip-place={TooltipPlace.bottom}
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: WorkspaceTranslation.tooltipChangeWorkspaceImage,
                })}>
                <ImageUpload
                  disabled={disabled || isSubmitting}
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('image', id);
                    setImageUrl(contentUrl);
                  }}>
                  <ImageUploadBody>
                    <StyledWorkspaceFormImage
                      src={imageUrl || UploadImagePlaceholder}
                      data-testid="workspace-logo"
                    />
                  </ImageUploadBody>
                </ImageUpload>
              </StyledWorkspaceFormImageContainer>
              <StyledWorkspaceFormControls>
                <ImageUpload
                  disabled={disabled || isSubmitting}
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('image', id);
                    setImageUrl(contentUrl);
                  }}>
                  <Button
                    type="button"
                    size={ButtonSize.sm}
                    mode={ButtonMode.secondary}
                    disabled={disabled || isSubmitting}
                    data-testid="workspace-logo-upload-button"
                    fullWidth>
                    <FormattedMessage
                      id={
                        imageUrl
                          ? WorkspaceTranslation.editFormChangeImageButton
                          : WorkspaceTranslation.editFormUploadImageButton
                      }
                    />
                  </Button>
                </ImageUpload>
                {imageUrl && (
                  <ImageRemoveButton
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      setFieldValue('image', '');
                      setImageUrl('');
                    }}
                    size={ButtonSize.sm}
                    mode={ButtonMode.secondary}
                    disabled={disabled || isSubmitting}
                    data-testid="workspace-logo-delete-button"
                    fullWidth>
                    <FormattedMessage
                      id={WorkspaceTranslation.editFormRemoveImageButton}
                    />
                  </ImageRemoveButton>
                )}
              </StyledWorkspaceFormControls>
            </div>
            <Field
              disabled={!canChangeWorkspaceName || isSubmitting}
              name="name"
              type="text"
              data-testid="workspace-form-name-input"
              label={intl.formatMessage({
                id: WorkspaceTranslation.formNameLabel,
              })}
              component={FormInput}
            />
            {workspace && (
              <>
                <Field name="contactEmail" type="email">
                  {(props: FieldProps) => {
                    return (
                      <div>
                        <FormInput
                          {...props}
                          disabled={
                            !canChangeWorkspaceAdminEmail || isSubmitting
                          }
                          data-testid="workspace-form-contactEmail-input"
                          label={intl.formatMessage({
                            id: WorkspaceTranslation.formEmailLabel,
                          })}
                        />
                        <SubLabel>
                          <FormattedMessage
                            id={WorkspaceTranslation.formEmailSubLabel}
                          />
                        </SubLabel>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  disabled={disabled || isSubmitting}
                  name="description"
                  type="text"
                  data-testid="workspace-form-description-input"
                  label={intl.formatMessage({
                    id: WorkspaceTranslation.formDescriptionLabel,
                  })}
                  component={FormTextarea}
                />
                <Field
                  disabled={disabled || isSubmitting}
                  name="homepageUrl"
                  type="text"
                  data-testid="workspace-form-homepageUrl-input"
                  placeholder={intl.formatMessage({
                    id: WorkspaceTranslation.formUrlPlaceholder,
                  })}
                  label={intl.formatMessage({
                    id: WorkspaceTranslation.formUrlLabel,
                  })}
                  component={FormInput}
                />

                <Field name="identifier" type="text">
                  {(props: FieldProps) => {
                    return (
                      <>
                        <StyledIdentifierContainer>
                          <IdentifierFormInputBlock>
                            <FormInput
                              {...props}
                              disabled={!canChangeIdentifier || isSubmitting}
                              data-testid="workspace-form-identifier"
                              label={intl.formatMessage({
                                id: WorkspaceTranslation.formSubdomainLabel,
                              })}
                            />
                            <div className="main-domain">{workspaceDomain}</div>
                          </IdentifierFormInputBlock>
                        </StyledIdentifierContainer>
                        {canChangeIdentifier && (
                          <SubLabel>
                            <FormattedMessage
                              id={WorkspaceTranslation.formSubdomainWarning}
                            />
                          </SubLabel>
                        )}
                      </>
                    );
                  }}
                </Field>
              </>
            )}
          </FormFields>

          {!disabled && (
            <FormControls>
              <Button
                data-testid="workspace-form-cancel-button"
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                disabled={isSubmitting}
                onClick={onCancel}>
                <FormattedMessage id={WorkspaceTranslation.formCancelButton} />
              </Button>
              <Button
                data-testid="workspace-form-submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={isSubmitting}>
                <FormattedMessage id={WorkspaceTranslation.formSaveButton} />
              </Button>
            </FormControls>
          )}
        </Form>
      )}
    </Formik>
  );
};
