import styled from 'styled-components';

export const StyledTimelineDesktopPopover = styled.div`
  width: 24rem;
  overflow-y: auto;
  max-width: 100%;
  max-height: 80vh;
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  padding: 0 0.5rem;
`;

export const TimelineDesktopModalBody = styled.div`
  padding: 0 0.5rem;
`;
