import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../shared/components/Spinner';
import { InboxMentionItem } from '../../InboxMentionItem';
import { EmptyMessage, FetchMoreButton } from '../InboxList.styled';
import { InboxTranslation } from '../../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  MENTIONS_FETCH_MORE_LOAD_COUNT,
  MENTIONS_INITIAL_LOAD_COUNT,
} from '../../Inbox.constants';
import { handleFetchMoreResult } from '../../Inbox.utils';
import { useGetMentionsQuery } from '../../Inbox.hooks';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';

export const MentionsList = () => {
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();

  const { data, loading, fetchMore } = useGetMentionsQuery({
    variables: {
      accountId: account.id,
      workspaceId: workspace.id,
      first: MENTIONS_INITIAL_LOAD_COUNT,
    },
    fetchPolicy: 'network-only',
  });

  const items = useMemo(
    () => extractNodes(data?.notifications).map(node => node),
    [data],
  );

  const fetchMoreHandler = useCallback(() => {
    if (!data) {
      return;
    }

    fetchMore({
      variables: {
        cursor: data.notifications.pageInfo.endCursor,
        first: MENTIONS_FETCH_MORE_LOAD_COUNT,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return handleFetchMoreResult(previousResult, fetchMoreResult || data);
      },
    });
  }, [fetchMore, data]);

  if (loading) {
    return <Spinner />;
  }

  if (items.length === 0) {
    return (
      <EmptyMessage data-testid="inbox-empty">
        <FormattedMessage id={InboxTranslation.emptyMentionsMessage} />
      </EmptyMessage>
    );
  }

  return (
    <>
      {items.map(item => (
        <InboxMentionItem key={item.id} item={item} />
      ))}

      <FetchMoreButton>
        <Button
          mode={ButtonMode.tertiary}
          size={ButtonSize.sm}
          disabled={!data?.notifications.pageInfo.hasNextPage || loading}
          onClick={fetchMoreHandler}
          data-testid="inbox-load-more">
          <FormattedMessage id={InboxTranslation.fetchMoreNotifications} />
        </Button>
      </FetchMoreButton>
    </>
  );
};
