import {
  MutationHookOptions,
  QueryHookOptions,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import {
  ACCEPT_INBOUND_CALL,
  AcceptInboundCallResponse,
  AcceptInboundCallVariables,
  CREATE_CHAT_CONFERENCE,
  CREATE_REPEATING_SCHEDULE_CHAT_CONFERENCE,
  CREATE_SCHEDULED_CHAT_CONFERENCE,
  CreateChatConferenceResponse,
  CreateChatConferenceVariables,
  CreateRepeatingScheduledChatConferenceResponse,
  CreateRepeatingScheduledChatConferenceVariables,
  CreateScheduledChatConferenceResponse,
  CreateScheduledChatConferenceVariables,
  DECLINE_REPEATING_SCHEDULE_CHAT_CONFERENCE,
  DECLINE_SCHEDULE_CHAT_CONFERENCE,
  DeclineRepeatingScheduleChatConferenceResponse,
  DeclineRepeatingScheduleChatConferenceVariables,
  DeclineScheduleChatConferenceResponse,
  DeclineScheduleChatConferenceVariables,
  DELETE_REPEATING_SCHEDULED_CHAT_CONFERENCES,
  DELETE_SCHEDULED_CHAT_CONFERENCE,
  DeleteRepeatingScheduledChatConferenceResponse,
  DeleteRepeatingScheduledChatConferenceVariables,
  DeleteScheduledChatConferenceResponse,
  DeleteScheduledChatConferenceVariables,
  JOIN_ANONYMOUS_CHAT_CONFERENCE,
  JOIN_CHAT_CONFERENCE,
  JoinAnonymousChatConferenceResponse,
  JoinAnonymousChatConferenceVariables,
  JoinChatConferenceResponse,
  JoinChatConferenceVariables,
  REJECT_INBOUND_CALL,
  RejectInboundCallResponse,
  RejectInboundCallVariables,
  RepeatingScheduledChatConferenceRepeatPattern,
  SEEN_CHAT_CONFERENCE,
  UPDATE_REPEATING_SCHEDULED_CHAT_CONFERENCE,
  UPDATE_SCHEDULED_CHAT_CONFERENCE,
  UpdateRepeatingScheduledChatConferenceResponse,
  UpdateRepeatingScheduledChatConferenceVariables,
  UpdateScheduledChatConferenceResponse,
  UpdateScheduledChatConferenceVariables,
  UpdateSeenChatConferenceResponse,
  UpdateSeenChatConferenceVariables,
} from './Conference.mutations';
import {
  GET_CHAT_CONFERENCE,
  GET_CHAT_CONFERENCES,
  GET_SCHEDULED_CHAT_CONFERENCE,
  GET_SCHEDULED_CHAT_CONFERENCES,
  GetChatConferenceResponse,
  GetChatConferencesResponse,
  GetChatConferencesVariables,
  GetChatConferenceVariables,
  GetScheduledChatConferenceResponse,
  GetScheduledChatConferencesResponse,
  GetScheduledChatConferencesVariables,
  GetScheduledChatConferenceVariables,
} from './Conference.queries';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../Workspace/Workspace.hooks';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAccountsContext } from '../Account';
import { getAccountName } from '../User/User.utils';
import {
  ConferenceApiType,
  ConferencesTabsValues,
  ScheduleConferenceRepeatingOnMonthOptionsValues,
  ScheduledChatConferenceApiType,
  ScheduledConferenceRepeatingValues,
  ScheduledRepeatedConferenceFormValues,
  ScheduleRepeatingRepeatFreqValues,
} from './Conference.types';
import { ConferenceContext } from './Conference.context';
import {
  addConferenceToListCache,
  updateConferenceInListCache,
} from './cache/Conference.cache';
import { AvailabilityStatusType } from '../User/User.types';
import {
  getTZOffsetFromTZName,
  openConferenceWindow,
} from './Conference.utils';
import { REPEAT_ON_WEEKS_OPTIONS } from './Conference.constants';
import { extractNodes } from '../../shared/api/api.utils';
import { useIntl } from 'react-intl';
import { ConferenceTranslation } from './i18n';
import { format, formatISO } from 'date-fns';
import { TIME_ZONES } from '../TimeDate/TimeDate.constants';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../shared/hooks';
import { useConfirm } from '../../shared/components/Modal';
import { useRefreshableQuery } from '../Api/Api.hooks';
import { showToastGraphQLErrors } from '../../shared/components/Toast';
import {
  CONFERENCES_CONFERENCE_PATHNAME,
  DESKTOP_ROOT_PATHNAME,
} from '../Desktop/Desktop.constants';
import _orderBy from 'lodash/orderBy';
import { usePreviewSegment } from '../PreviewSegment/PreviewSegment.hooks';
import { getShortId } from '../../shared/utils/id';

export const useCreateScheduleChatConferenceMutation = () =>
  useMutation<
    CreateScheduledChatConferenceResponse,
    CreateScheduledChatConferenceVariables
  >(CREATE_SCHEDULED_CHAT_CONFERENCE);

export const useChatConferenceQuery = (
  options?: QueryHookOptions<
    GetChatConferenceResponse,
    GetChatConferenceVariables
  >,
) =>
  useRefreshableQuery<GetChatConferenceResponse, GetChatConferenceVariables>(
    GET_CHAT_CONFERENCE,
    options,
  );

export const useUpdateScheduledChatConferenceMutation = () =>
  useMutation<
    UpdateScheduledChatConferenceResponse,
    UpdateScheduledChatConferenceVariables
  >(UPDATE_SCHEDULED_CHAT_CONFERENCE);

export const useUpdateRepeatingScheduledChatConferenceMutation = () =>
  useMutation<
    UpdateRepeatingScheduledChatConferenceResponse,
    UpdateRepeatingScheduledChatConferenceVariables
  >(UPDATE_REPEATING_SCHEDULED_CHAT_CONFERENCE);

export const useScheduledChatConferenceQuery = (
  options?: QueryHookOptions<
    GetScheduledChatConferenceResponse,
    GetScheduledChatConferenceVariables
  >,
) =>
  useRefreshableQuery<
    GetScheduledChatConferenceResponse,
    GetScheduledChatConferenceVariables
  >(GET_SCHEDULED_CHAT_CONFERENCE, options);

export const useChatConferencesQuery = (
  options?: QueryHookOptions<
    GetChatConferencesResponse,
    GetChatConferencesVariables
  >,
) =>
  useRefreshableQuery<GetChatConferencesResponse, GetChatConferencesVariables>(
    GET_CHAT_CONFERENCES,
    options,
  );

export const useScheduledChatConferencesQuery = (
  options: QueryHookOptions<
    GetScheduledChatConferencesResponse,
    GetScheduledChatConferencesVariables
  >,
) =>
  useRefreshableQuery<
    GetScheduledChatConferencesResponse,
    GetScheduledChatConferencesVariables
  >(GET_SCHEDULED_CHAT_CONFERENCES, options);

export const useCreateChatConferenceMutation = () =>
  useMutation<CreateChatConferenceResponse, CreateChatConferenceVariables>(
    CREATE_CHAT_CONFERENCE,
  );

export const useAcceptInboundCallMutation = () =>
  useMutation<AcceptInboundCallResponse, AcceptInboundCallVariables>(
    ACCEPT_INBOUND_CALL,
  );

export const useRejectInboundCallMutation = () =>
  useMutation<RejectInboundCallResponse, RejectInboundCallVariables>(
    REJECT_INBOUND_CALL,
  );

export const useInboundCallControls = () => {
  const [rejectInboundCallMutation] = useRejectInboundCallMutation();
  const rejectInboundCall = useCallback(
    (conferenceId: string, onSuccess?: () => void) => {
      rejectInboundCallMutation({
        variables: {
          input: {
            id: conferenceId,
          },
        },
      })
        .then(() => {
          onSuccess?.();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [rejectInboundCallMutation],
  );

  const [acceptInboundCallMutation] = useAcceptInboundCallMutation();
  const acceptAndOpenInboundCall = useCallback(
    (conferenceId: string, onSuccess?: () => void) => {
      acceptInboundCallMutation({
        variables: {
          input: {
            id: conferenceId,
          },
        },
      })
        .then(() => {
          onSuccess?.();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
      openConferenceWindow(conferenceId, {
        ignoreAcceptMutation: true,
      });
    },
    [acceptInboundCallMutation],
  );
  return {
    rejectInboundCall,
    acceptAndOpenInboundCall,
  };
};

export const useJoinAnonymousChatConferenceMutation = () =>
  useMutation<
    JoinAnonymousChatConferenceResponse,
    JoinAnonymousChatConferenceVariables
  >(JOIN_ANONYMOUS_CHAT_CONFERENCE);

export const useJoinChatConferenceMutation = () =>
  useMutation<JoinChatConferenceResponse, JoinChatConferenceVariables>(
    JOIN_CHAT_CONFERENCE,
  );

export const useUpdateSeenChatConferenceMutation = () =>
  useMutation<
    UpdateSeenChatConferenceResponse,
    UpdateSeenChatConferenceVariables
  >(SEEN_CHAT_CONFERENCE);

export const useDeleteScheduledChatConferenceMutation = (
  options?: MutationHookOptions<
    DeleteScheduledChatConferenceResponse,
    DeleteScheduledChatConferenceVariables
  >,
) =>
  useMutation<
    DeleteScheduledChatConferenceResponse,
    DeleteScheduledChatConferenceVariables
  >(DELETE_SCHEDULED_CHAT_CONFERENCE, options);

export const useDeleteRepeatingScheduledChatConferencesMutation = (
  options?: MutationHookOptions<
    DeleteRepeatingScheduledChatConferenceResponse,
    DeleteRepeatingScheduledChatConferenceVariables
  >,
) =>
  useMutation<
    DeleteRepeatingScheduledChatConferenceResponse,
    DeleteRepeatingScheduledChatConferenceVariables
  >(DELETE_REPEATING_SCHEDULED_CHAT_CONFERENCES, options);

export const useCreateRepeatingScheduleChatConference = (
  options?: MutationHookOptions<
    CreateRepeatingScheduledChatConferenceResponse,
    CreateRepeatingScheduledChatConferenceVariables
  >,
) =>
  useMutation<
    CreateRepeatingScheduledChatConferenceResponse,
    CreateRepeatingScheduledChatConferenceVariables
  >(CREATE_REPEATING_SCHEDULE_CHAT_CONFERENCE, options);

export const useDeclineScheduleChatConference = (
  options?: MutationHookOptions<
    DeclineScheduleChatConferenceResponse,
    DeclineScheduleChatConferenceVariables
  >,
) =>
  useMutation<
    DeclineScheduleChatConferenceResponse,
    DeclineScheduleChatConferenceVariables
  >(DECLINE_SCHEDULE_CHAT_CONFERENCE, options);

export const useDeclineRepeatingScheduleChatConference = (
  options?: MutationHookOptions<
    DeclineRepeatingScheduleChatConferenceResponse,
    DeclineRepeatingScheduleChatConferenceVariables
  >,
) =>
  useMutation<
    DeclineRepeatingScheduleChatConferenceResponse,
    DeclineRepeatingScheduleChatConferenceVariables
  >(DECLINE_REPEATING_SCHEDULE_CHAT_CONFERENCE, options);

export const useConferenceParticipants = (
  conference?: ConferenceApiType,
  excludeCurrentUser?: boolean,
) => {
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();
  return useMemo(() => {
    const participants =
      (excludeCurrentUser
        ? conference?.statistics.participants?.filter(
            participant => participant.id !== account.id,
          )
        : conference?.statistics.participants) || [];

    return participants
      .map(({ id }) => accountsWithAvailability[id])
      .filter(Boolean);
  }, [account.id, conference, accountsWithAvailability, excludeCurrentUser]);
};

export const useScheduledConferenceMembers = (withOrganizer = false) => {
  const { scheduledConference } = useCurrentConference();
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();
  const { workspace } = useCurrentWorkspace();

  return useMemo(() => {
    const scheduleChatConferenceMembers = extractNodes(
      scheduledConference?.scheduleChatConferenceMembers,
    ).map(member => ({
      ...member,
      workspaceCache: {
        ...(member?.account?.id && {
          [workspace.id]: accountsWithAvailability[member.account.id],
        }),
      },
    }));

    const repeatingScheduleChatConference = extractNodes(
      scheduledConference?.repeatingScheduleChatConference
        ?.scheduleChatConferenceMembers,
    ).map(member => ({
      ...member,
      workspaceCache: {
        ...(member?.account?.id && {
          [workspace.id]: accountsWithAvailability[member.account.id],
        }),
      },
    }));

    const scheduleMembers = withOrganizer
      ? scheduleChatConferenceMembers
      : scheduleChatConferenceMembers.filter(
          member => account.id !== member.account?.id,
        );
    const repeatingScheduleMembers = withOrganizer
      ? repeatingScheduleChatConference
      : repeatingScheduleChatConference.filter(
          member => account.id !== member.account?.id,
        );

    return [
      ...scheduleMembers?.filter(
        member => !!member?.workspaceCache?.[workspace.id],
      ),
      ...scheduleMembers?.filter(member => !member.account),
      ...(scheduledConference?.membersOverridden
        ? []
        : [
            ...repeatingScheduleMembers.filter(
              member => !!member?.workspaceCache?.[workspace.id],
            ),
            ...repeatingScheduleMembers.filter(member => !member.account),
          ]),
    ];
  }, [
    account.id,
    accountsWithAvailability,
    scheduledConference?.repeatingScheduleChatConference
      ?.scheduleChatConferenceMembers,
    scheduledConference?.scheduleChatConferenceMembers,
    scheduledConference?.membersOverridden,
    withOrganizer,
    workspace.id,
  ]);
};

export const useConferenceParticipantsTitle = (
  conference?: ConferenceApiType,
) => {
  const otherParticipants = useConferenceParticipants(conference, true);
  return useMemo(
    () =>
      otherParticipants
        .map(participant => getAccountName(participant))
        .join(', '),
    [otherParticipants],
  );
};

export const useCurrentConference = () => {
  return useContext(ConferenceContext);
};

export const useConferenceCacheUpdate = () => {
  const apolloClient = useApolloClient();
  return {
    addConferenceToListCache: useCallback(
      (
        queryVariables: GetChatConferencesVariables,
        conference: ConferenceApiType,
      ) => addConferenceToListCache(apolloClient, queryVariables, conference),
      [apolloClient],
    ),
    updateConferenceInListCache: useCallback(
      (
        queryVariables: GetChatConferencesVariables,
        conference: ConferenceApiType,
      ) => {
        updateConferenceInListCache(apolloClient, queryVariables, conference);
      },
      [apolloClient],
    ),
  };
};

export const useAccountAvailableForCall = (
  accountId?: string,
  fallbackValue = true,
) => {
  const { accountsWithAvailability } = useAccountsContext();

  if (!accountId) {
    return fallbackValue;
  }

  const onlineStatus = accountsWithAvailability[accountId]?.onlineStatus;
  const onCall = accountsWithAvailability[accountId]?.onCall;

  if (onCall) {
    return false;
  }

  switch (onlineStatus) {
    case AvailabilityStatusType.Busy: {
      return false;
    }

    default: {
      return true;
    }
  }
};

export const useTimeZoneOptions = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return useMemo(() => {
    const options = TIME_ZONES.map((tz: string) => {
      return { value: tz, label: `${tz} ${getTZOffsetFromTZName(tz)}` };
    });
    if (!TIME_ZONES.includes(userTimeZone)) {
      options.unshift({
        value: userTimeZone,
        label: `${userTimeZone} ${getTZOffsetFromTZName(userTimeZone)}`,
      });
    }

    return options;
  }, [userTimeZone]);
};

export const useCountOfWeekInWord = (value?: number): string | undefined => {
  const intl = useIntl();
  if (!value) {
    return;
  }

  const weeksCounts: { [key: number]: string } = {
    1: intl.formatMessage({
      id: ConferenceTranslation.scheduleRepeatingConferenceFirstWeekInMonth,
    }),
    2: intl.formatMessage({
      id: ConferenceTranslation.scheduleRepeatingConferenceSecondWeekInMonth,
    }),
    3: intl.formatMessage({
      id: ConferenceTranslation.scheduleRepeatingConferenceThirdWeekInMonth,
    }),
    4: intl.formatMessage({
      id: ConferenceTranslation.scheduleRepeatingConferenceFourthWeekInMonth,
    }),
    5: intl.formatMessage({
      id: ConferenceTranslation.scheduleRepeatingConferenceFifthWeekInMonth,
    }),
  };

  return weeksCounts[value];
};

export const useRepeatOptions = (selectedRepeatingOption?: string) => {
  const intl = useIntl();

  return useMemo(() => {
    const options = [
      {
        value: ScheduledConferenceRepeatingValues.DOES_NOT_REPEAT,
        label: intl.formatMessage({
          id: ConferenceTranslation.scheduleRepeatingConferenceDoesNotRepeat,
        }),
      },
      {
        value: ScheduledConferenceRepeatingValues.SELECTED_OPTION,
        label: '',
      },
      {
        value: ScheduledConferenceRepeatingValues.CUSTOM_OPTION,
        label: intl.formatMessage({
          id: ConferenceTranslation.scheduleRepeatingConferenceDoesCustomOption,
        }),
      },
    ];

    if (selectedRepeatingOption) {
      options.splice(1, 1, {
        value: ScheduledConferenceRepeatingValues.SELECTED_OPTION,
        label: selectedRepeatingOption,
      });
    }

    return options;
  }, [intl, selectedRepeatingOption]);
};

export const useGenerateFullDayName = (): { [key: string]: string } => {
  const intl = useIntl();
  return {
    Mon: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceMonday,
    }),
    Tue: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceTuesday,
    }),
    Wed: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceWednesday,
    }),
    Thu: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceThursday,
    }),
    Fri: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceFriday,
    }),
    Sat: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceSaturday,
    }),
    Sun: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceSunday,
    }),
  };
};

export const useWeekCounts = (
  selectedDay: Date,
  data?: RepeatingScheduledChatConferenceRepeatPattern,
) => {
  const intl = useIntl();
  const savedWeekNumber = useCountOfWeekInWord(data?.WEEK_NO);

  const weekNumberByDay = Math.ceil(selectedDay.getDate() / 7);

  const countOfWeekInWord = useCountOfWeekInWord(weekNumberByDay);

  const fullDayNames = useGenerateFullDayName();
  const currentMonthDayNumber =
    data?.MONTH_DAY || Number(format(selectedDay, 'd'));
  const dayOfWeek = format(selectedDay, 'EEEE');

  const options: {
    value: ScheduleConferenceRepeatingOnMonthOptionsValues;
    label: string;
  }[] = [
    {
      value: ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_DAY,
      label: `${intl.formatMessage({
        id: ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnDay,
      })} ${currentMonthDayNumber}`,
    },
    {
      value: ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_WEEK_DAY,
      label: `${intl.formatMessage({
        id: ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnThe,
      })} ${countOfWeekInWord} ${dayOfWeek}`,
    },
  ];

  if (data) {
    if (data.MONTH_DAY && data.MONTH_DAY !== currentMonthDayNumber) {
      options.push({
        value:
          ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_CUSTOM_DATE,
        label: `${intl.formatMessage({
          id: ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnDay,
        })} ${data.MONTH_DAY}`,
      });
    } else if (
      data.WEEK_NO &&
      data.WEEK_DAY &&
      savedWeekNumber !== countOfWeekInWord
    ) {
      options.push({
        value:
          ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_CUSTOM_DATE,
        label: `${intl.formatMessage({
          id: ConferenceTranslation.scheduleRepeatingConferenceMonthlyOnThe,
        })} ${savedWeekNumber} ${fullDayNames[data.WEEK_DAY]}`,
      });
    }
  }
  return options;
};

export const useRepeatPatternGenerator = (selectedDate: Date) => {
  const intl = useIntl();

  return {
    generateRepeatPatternForDayFreq: useCallback(
      (
        values: ScheduledRepeatedConferenceFormValues,
        data?: RepeatingScheduledChatConferenceRepeatPattern,
      ): RepeatingScheduledChatConferenceRepeatPattern | undefined => {
        if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceNever,
          })
        ) {
          return {
            // If never_end selected create 365 events
            COUNT: 365,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.DAY,
            REPEAT_EVERY: values.intervalValue,
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceOn,
          })
        ) {
          return {
            UNTIL: formatISO(new Date(values.endsOnDate)),
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.DAY,
            REPEAT_EVERY: values.intervalValue,
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceAfter,
          })
        ) {
          return {
            COUNT: values.occurrences,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.DAY,
            REPEAT_EVERY: values.intervalValue,
          };
        }
      },
      [intl],
    ),
    generateRepeatPatternForWeekFreq: useCallback(
      (
        values: ScheduledRepeatedConferenceFormValues,
        data?: RepeatingScheduledChatConferenceRepeatPattern,
      ): RepeatingScheduledChatConferenceRepeatPattern | undefined => {
        const WEEK_DAYS = values.repeatOnWeeks
          .filter(day => day.active)
          .map(day => day.value);

        if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceNever,
          })
        ) {
          return {
            // If never_end selected create 365 events
            COUNT: 365,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.WEEK,
            REPEAT_EVERY: values.intervalValue,
            WEEK_DAYS: WEEK_DAYS,
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceOn,
          })
        ) {
          return {
            UNTIL: formatISO(new Date(values.endsOnDate)),
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.WEEK,
            REPEAT_EVERY: values.intervalValue,
            WEEK_DAYS: WEEK_DAYS,
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceAfter,
          })
        ) {
          return {
            COUNT: values.occurrences,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.WEEK,
            REPEAT_EVERY: values.intervalValue,
            WEEK_DAYS: WEEK_DAYS,
          };
        }
      },
      [intl],
    ),
    generateRepeatPatternForMonthFreq: useCallback(
      (
        values: ScheduledRepeatedConferenceFormValues,
        data?: RepeatingScheduledChatConferenceRepeatPattern,
      ): RepeatingScheduledChatConferenceRepeatPattern | undefined => {
        const getMonthOrWeekDay = () => {
          if (Number(values.repeatOnMonths) === 1) {
            return { MONTH_DAY: Number(format(selectedDate, 'd')) };
          } else if (Number(values.repeatOnMonths) === 2) {
            return {
              WEEK_NO: Math.ceil(selectedDate.getDate() / 7),
              WEEK_DAY: format(selectedDate, 'eee'),
            };
          } else {
            if (data?.MONTH_DAY) {
              return { MONTH_DAY: data?.MONTH_DAY };
            }
            return {
              WEEK_NO: data?.WEEK_NO,
              WEEK_DAY: data?.WEEK_DAY,
            };
          }
        };

        if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceNever,
          })
        ) {
          return {
            // If never_end selected create 365 events
            COUNT: 365,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.MONTH,
            REPEAT_EVERY: values.intervalValue,
            ...getMonthOrWeekDay(),
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceOn,
          })
        ) {
          return {
            UNTIL: formatISO(new Date(values.endsOnDate)),
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.MONTH,
            REPEAT_EVERY: values.intervalValue,
            ...getMonthOrWeekDay(),
          };
        } else if (
          values.endsOn ===
          intl.formatMessage({
            id: ConferenceTranslation.scheduleRepeatingConferenceAfter,
          })
        ) {
          return {
            COUNT: values.occurrences,
            REPEAT_FREQ: ScheduleRepeatingRepeatFreqValues.MONTH,
            REPEAT_EVERY: values.intervalValue,
            ...getMonthOrWeekDay(),
          };
        }
      },
      [intl, selectedDate],
    ),
  };
};

export const useFormValuesGenerator = (selectedDate: Date) => {
  const intl = useIntl();
  const currentDay = format(selectedDate, 'E');
  const dayOfMonth = format(selectedDate, 'd');

  return {
    generateDefaultFormValues: useCallback(() => {
      return {
        intervalValue: 1,
        intervalRange: ScheduleRepeatingRepeatFreqValues.WEEK,
        repeatOnWeeks: REPEAT_ON_WEEKS_OPTIONS([currentDay]),
        repeatOnMonths: 1,
        endsOn: intl.formatMessage({
          id: ConferenceTranslation.scheduleRepeatingConferenceNever,
        }),
        endsOnDate: selectedDate,
        occurrences: 10,
      };
    }, [currentDay, intl, selectedDate]),
    generateFormValuesFromDayFreq: useCallback(
      (data: RepeatingScheduledChatConferenceRepeatPattern) => {
        return {
          intervalValue: data.REPEAT_EVERY,
          intervalRange: ScheduleRepeatingRepeatFreqValues.DAY,
          repeatOnWeeks: REPEAT_ON_WEEKS_OPTIONS([currentDay]),
          repeatOnMonths: 1,
          endsOn: intl.formatMessage({
            id: data.UNTIL
              ? ConferenceTranslation.scheduleRepeatingConferenceOn
              : ConferenceTranslation.scheduleRepeatingConferenceAfter,
          }),
          endsOnDate: data.UNTIL ? new Date(data.UNTIL) : new Date(),
          occurrences: data.UNTIL ? 0 : data.COUNT,
        };
      },
      [currentDay, intl],
    ),
    generateFormValuesFromWeekFreq: useCallback(
      (data: RepeatingScheduledChatConferenceRepeatPattern) => {
        return {
          intervalValue: data.REPEAT_EVERY,
          intervalRange: ScheduleRepeatingRepeatFreqValues.WEEK,
          repeatOnWeeks: REPEAT_ON_WEEKS_OPTIONS(
            data.WEEK_DAYS ? data.WEEK_DAYS : [currentDay],
          ),
          repeatOnMonths:
            data.WEEK_DAY || data.WEEK_NO || data.MONTH_DAY ? 3 : 1,
          endsOn: intl.formatMessage({
            id: data.UNTIL
              ? ConferenceTranslation.scheduleRepeatingConferenceOn
              : ConferenceTranslation.scheduleRepeatingConferenceAfter,
          }),
          endsOnDate: data.UNTIL ? new Date(data.UNTIL) : new Date(),
          occurrences: data.UNTIL ? 0 : data.COUNT,
        };
      },
      [currentDay, intl],
    ),
    generateFormValuesFromMonthFreq: useCallback(
      (data: RepeatingScheduledChatConferenceRepeatPattern) => {
        return {
          intervalValue: data.REPEAT_EVERY,
          intervalRange: ScheduleRepeatingRepeatFreqValues.MONTH,
          repeatOnWeeks: REPEAT_ON_WEEKS_OPTIONS([currentDay]),
          repeatOnMonths:
            (data.WEEK_NO && data.WEEK_DAY && data.WEEK_DAY !== currentDay) ||
            (data.MONTH_DAY && data.MONTH_DAY !== Number(dayOfMonth))
              ? ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_CUSTOM_DATE
              : data.MONTH_DAY
              ? ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_DAY
              : ScheduleConferenceRepeatingOnMonthOptionsValues.REPEAT_ON_WEEK_DAY,
          endsOn: intl.formatMessage({
            id: data.UNTIL
              ? ConferenceTranslation.scheduleRepeatingConferenceOn
              : ConferenceTranslation.scheduleRepeatingConferenceAfter,
          }),
          endsOnDate: data.UNTIL ? new Date(data.UNTIL) : new Date(),
          occurrences: data.UNTIL ? 0 : data.COUNT,
        };
      },
      [dayOfMonth, currentDay, intl],
    ),
  };
};

export const useRepeatPattern = (selectedDate: Date) => {
  const {
    generateRepeatPatternForDayFreq,
    generateRepeatPatternForWeekFreq,
    generateRepeatPatternForMonthFreq,
  } = useRepeatPatternGenerator(selectedDate);
  const {
    generateDefaultFormValues,
    generateFormValuesFromDayFreq,
    generateFormValuesFromWeekFreq,
    generateFormValuesFromMonthFreq,
  } = useFormValuesGenerator(selectedDate);

  return {
    generateRepeatPattern: useCallback(
      (values: ScheduledRepeatedConferenceFormValues) => {
        if (values.intervalRange === ScheduleRepeatingRepeatFreqValues.DAY) {
          return generateRepeatPatternForDayFreq(values);
        } else if (
          values.intervalRange === ScheduleRepeatingRepeatFreqValues.WEEK
        ) {
          return generateRepeatPatternForWeekFreq(values);
        } else if (
          values.intervalRange === ScheduleRepeatingRepeatFreqValues.MONTH
        ) {
          return generateRepeatPatternForMonthFreq(values);
        }
      },
      [
        generateRepeatPatternForDayFreq,
        generateRepeatPatternForMonthFreq,
        generateRepeatPatternForWeekFreq,
      ],
    ),
    generateFormInitialValues: useCallback(
      (data?: RepeatingScheduledChatConferenceRepeatPattern) => {
        if (!data) {
          return generateDefaultFormValues();
        } else if (data.REPEAT_FREQ === ScheduleRepeatingRepeatFreqValues.DAY) {
          return generateFormValuesFromDayFreq(data);
        } else if (
          data.REPEAT_FREQ === ScheduleRepeatingRepeatFreqValues.WEEK
        ) {
          return generateFormValuesFromWeekFreq(data);
        } else if (
          data.REPEAT_FREQ === ScheduleRepeatingRepeatFreqValues.MONTH
        ) {
          return generateFormValuesFromMonthFreq(data);
        } else {
          return generateDefaultFormValues();
        }
      },
      [
        generateDefaultFormValues,
        generateFormValuesFromDayFreq,
        generateFormValuesFromWeekFreq,
        generateFormValuesFromMonthFreq,
      ],
    ),
  };
};

export const useGenerateRepeatingOption = (
  selectedDay: Date,
  repeatPattern?: RepeatingScheduledChatConferenceRepeatPattern,
) => {
  const intl = useIntl();
  const fullDayNames = useGenerateFullDayName();
  const monthlyRepeat = useWeekCounts(selectedDay, repeatPattern);

  if (!repeatPattern) {
    return undefined;
  }

  const REPEAT_ON_MAP: { [key: string]: string } = {
    [ScheduleRepeatingRepeatFreqValues.DAY]: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceDaily,
    }),
    [ScheduleRepeatingRepeatFreqValues.WEEK]: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceWeekly,
    }),
    [ScheduleRepeatingRepeatFreqValues.MONTH]: intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceMonthly,
    }),
  };

  const repeatingFreq = `${
    REPEAT_ON_MAP[repeatPattern.REPEAT_FREQ]
  } ${intl.formatMessage({
    id: ConferenceTranslation.scheduledConferenceOn,
  })}`;
  const repeatTimes = repeatPattern.COUNT
    ? `${repeatPattern.COUNT} ` +
      intl.formatMessage({
        id: ConferenceTranslation.scheduledConferenceTimes,
      })
    : intl.formatMessage({
        id: ConferenceTranslation.scheduledConferenceUntil,
      }) +
      ` ${format(new Date(repeatPattern.UNTIL as string), 'EEEE, MMMM dd')}`;

  if (ScheduleRepeatingRepeatFreqValues.DAY === repeatPattern.REPEAT_FREQ) {
    const repeatEvery = `${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceEvery,
    })} ${repeatPattern.REPEAT_EVERY} ${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceDay,
    })}`;
    return `${repeatingFreq} ${repeatEvery} ${repeatTimes}`;
  } else if (
    ScheduleRepeatingRepeatFreqValues.WEEK === repeatPattern.REPEAT_FREQ
  ) {
    const repeatEvery = `${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceEvery,
    })} ${repeatPattern.REPEAT_EVERY} ${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceWeek,
    })}`;
    const repeatOnDays = repeatPattern.WEEK_DAYS?.map(day => fullDayNames[day]);
    return `${repeatingFreq} ${repeatEvery} ${repeatOnDays?.join(
      ', ',
    )} ${repeatTimes}`;
  } else if (
    ScheduleRepeatingRepeatFreqValues.MONTH === repeatPattern.REPEAT_FREQ
  ) {
    const repeatEvery = `${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceEvery,
    })} ${repeatPattern.REPEAT_EVERY} ${intl.formatMessage({
      id: ConferenceTranslation.scheduledConferenceMonth,
    })}`;
    const repeatOn = repeatPattern.MONTH_DAY
      ? monthlyRepeat[0].label
      : monthlyRepeat[1].label;
    return `${repeatOn}, ${repeatEvery} ${repeatTimes}`;
  }
};

export const useDeleteScheduledConference = (
  conference?: ScheduledChatConferenceApiType,
) => {
  const { scheduledConference } = useCurrentConference();
  const currentConference = conference ? conference : scheduledConference;
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const [
    deleteScheduledConference,
    { loading: deleteScheduledConferenceLoading },
  ] = useDeleteScheduledChatConferenceMutation();

  return {
    handleDeleteConference: useCallback(() => {
      if (currentConference?.repeatingScheduleChatConference) {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            scheduleConferenceDeleteConfirmationModalOpen: true,
          }),
        });
        return Promise.resolve(false);
      }

      if (currentConference && workspace.id) {
        return askConfirmation(
          intl.formatMessage({
            id: ConferenceTranslation.scheduledConferenceDeleteConfirmationMessage,
          }),
          intl.formatMessage({
            id: ConferenceTranslation.scheduledConferenceDeleteConfirmationTitle,
          }),
          {
            width: 450,
          },
        ).then(confirm => {
          if (!confirm) {
            return Promise.resolve(false);
          }
          return deleteScheduledConference({
            variables: {
              input: {
                _id: '',
                id: currentConference.id,
                workspace: workspace.id,
              },
            },
          }).then(() => {
            navigate({
              pathname: `/workspace/${getShortId(workspace.id)}/calls`,
              search: getQueryParamsFrom({
                ...queryParams,
                meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
                scheduleConferenceOpened: undefined,
              }),
            });
            return Promise.resolve(false);
          });
        });
      }

      return Promise.resolve(true);
    }, [
      askConfirmation,
      currentConference,
      deleteScheduledConference,
      navigate,
      intl,
      queryParams,
      workspace,
    ]),
    deleteScheduledConferenceLoading: deleteScheduledConferenceLoading,
  };
};

/**
 * Redirect meetings logic
 */
export const useRedirectMeetings = (
  conferences: ConferenceApiType[],
  currentConferenceId?: string,
) => {
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canAccessMeetingPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { isPreviewMode } = usePreviewSegment();

  useEffect(() => {
    if (isPreviewMode) {
      return;
    }

    if (permissionsLoaded && !canAccessMeetingPage) {
      navigate(
        generatePath(DESKTOP_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
        { replace: true },
      );
    } else if (!currentConferenceId) {
      const sortedConferences = _orderBy(conferences, ['createdAt'], ['desc']);

      if (sortedConferences.length) {
        navigate(
          {
            pathname: generatePath(CONFERENCES_CONFERENCE_PATHNAME, {
              workspaceId: getShortId(workspace.id),
              conferenceId: sortedConferences[0]._id,
            }),
            search: getQueryParamsFrom(queryParams),
          },
          { replace: true },
        );
      }
    }
  }, [
    conferences,
    currentConferenceId,
    navigate,
    queryParams,
    isPreviewMode,
    workspace.id,
    permissionsLoaded,
    canAccessMeetingPage,
  ]);
};
