import { useContext } from 'react';
import { WindowMessageContext } from './WindowMessage.context';
import { WindowMessage, WindowMessageType } from './WindowMessage.types';

export const useWindowMessageContext = () => {
  return useContext(WindowMessageContext);
};

export const useWindowMessageCallback = (type: WindowMessageType) => {
  const { setMessageCallback } = useWindowMessageContext();
  return {
    setMessageCallback: (callback: (data?: WindowMessage) => void) =>
      setMessageCallback(type, callback),
  };
};
