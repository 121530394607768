import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_TIMELINE_EVENT,
  CreateTimelineEventResponse,
  CreateTimelineEventVariables,
  UNDO_TIMELINE_EVENT,
  UndoTimelineEventResponse,
  UndoTimelineEventVariables,
} from './Timeline.mutations';
import { useCallback, useContext } from 'react';
import { TimelineEventAction, TimelineEventScope } from './Timeline.constants';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';
import { useCurrentDesktop } from '../Desktop/Desktop.hooks';
import {
  SEARCH_IN_TIMELINES,
  SearchInTimelinesResponse,
  SearchInTimelinesVariables,
} from './Timeline.queries';
import { TimelineDesktopPopoverContext } from './Timeline.context';
import { getShortId } from '../../shared/utils/id';

export const useSearchInTimelinesQuery = (
  options?: QueryHookOptions<
    SearchInTimelinesResponse,
    SearchInTimelinesVariables
  >,
) =>
  useQuery<SearchInTimelinesResponse, SearchInTimelinesVariables>(
    SEARCH_IN_TIMELINES,
    options,
  );

export const useCreateTimelineEventMutation = () =>
  useMutation<CreateTimelineEventResponse, CreateTimelineEventVariables>(
    CREATE_TIMELINE_EVENT,
  );

export const useTimelineEventTracker = () => {
  const { workspace } = useCurrentWorkspace();
  const desktop = useCurrentDesktop();
  const [createTimelineEventMutation] = useCreateTimelineEventMutation();

  return useCallback(
    (
      scope: TimelineEventScope,
      scopeId: string,
      action: TimelineEventAction,
    ) => {
      return createTimelineEventMutation({
        variables: {
          input: {
            scope,
            scopeId,
            action,
            workspace: workspace.id,
            context: {
              desktop: desktop?.id || '',
              desktop_id: getShortId(desktop?.id || '') || '',
              desktop_name: desktop?.name || '',
              desktop_shareable: desktop?.shareable,
              member_count: desktop?.memberCount,
            },
          },
        },
      });
    },
    [desktop, workspace, createTimelineEventMutation],
  );
};

export const useUndoTimelineEventMutation = () =>
  useMutation<UndoTimelineEventResponse, UndoTimelineEventVariables>(
    UNDO_TIMELINE_EVENT,
  );

export const useTimelineDesktopPopoverControls = () =>
  useContext(TimelineDesktopPopoverContext);
