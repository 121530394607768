import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import { StyledDeleteMultipleVaultsModal } from './DeleteMultipleVaultsModal.styled';
import { DeleteMultipleVaults } from '../DeleteMultipleVaults';

interface DeleteMultipleVaultsModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose'> {
  vaultIds: string[];
  onDeleted: (ids: string[]) => void;
}

export const DeleteMultipleVaultsModal: FC<DeleteMultipleVaultsModalProps> = ({
  vaultIds,
  onDeleted,
  ...modalProps
}) => {
  return (
    <Modal visible={true} width={486} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage
          id={EncryptionTranslation.deleteMultipleVaultsModalHeader}
          values={{ vaultsCount: vaultIds?.length }}
        />
      </ModalHeader>
      <StyledDeleteMultipleVaultsModal>
        <DeleteMultipleVaults
          vaultIds={vaultIds}
          onDeleted={onDeleted}
          onCancelled={modalProps.onRequestClose}
        />
      </StyledDeleteMultipleVaultsModal>
    </Modal>
  );
};
