import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditUser } from '../../UserForms/EditUserForm';
import { AccountApiType } from '../../User.types';
import { UserTranslation } from '../../i18n';
import { WorkspaceAccountGroupIdentity } from '../../User.constants';

interface EditUserModalProps {
  account: AccountApiType;
  groupIdentifier: WorkspaceAccountGroupIdentity;
}

export const EditUserModal: FC<ModalProps & EditUserModalProps> = ({
  onRequestClose,
  account,
  groupIdentifier,
  ...props
}) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={UserTranslation.editModalHeader} />
      </ModalHeader>
      <EditUser
        onDone={onRequestClose}
        account={account}
        groupIdentifier={groupIdentifier}
      />
    </Modal>
  );
};
