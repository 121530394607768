import { ApolloClient } from '@apollo/client';
import _pick from 'lodash/pick';
import {
  GET_SCHEDULED_CHAT_CONFERENCE,
  GET_SCHEDULED_CHAT_CONFERENCES,
  GetScheduledChatConferenceResponse,
  GetScheduledChatConferencesResponse,
  GetScheduledChatConferencesVariables,
  GetScheduledChatConferenceVariables,
} from '../Conference.queries';
import {
  FilterPeriod,
  ScheduledChatConferenceApiType,
} from '../Conference.types';
import { existsInNestedArray } from '../../../shared/utils/list.utils';
import { GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_EDGE } from '../Conference.constants';
import { DEFAULT_PAGE_SIZE } from '../ConferencesPage';
import { ScheduledConferenceFields } from '../Conference.fragments';
import _orderBy from 'lodash/orderBy';

export const addScheduledConferenceToListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetScheduledChatConferencesVariables,
  scheduledConference: ScheduledChatConferenceApiType,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      conferencesListCache,
      'scheduleChatConferences.edges',
      { node: { id: scheduledConference.id } },
    );

    if (alreadyExists) {
      return;
    }

    proxy.writeQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        scheduleChatConferences: {
          ...conferencesListCache.scheduleChatConferences,
          edges: _orderBy(
            [
              {
                __typename: GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_EDGE,
                node: scheduledConference,
              },
              ...conferencesListCache.scheduleChatConferences.edges,
            ],
            edge => edge.node.startAt,
            variables.filterPeriod === FilterPeriod.future ? 'asc' : 'desc',
          ),
          ...(conferencesListCache.scheduleChatConferences.pageInfo
            ? {
                pageInfo: {
                  ...conferencesListCache.scheduleChatConferences.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(
                          conferencesListCache.scheduleChatConferences.pageInfo
                            .endCursor,
                        ),
                        10,
                      ) + 1
                    ).toString(),
                  ),
                },
              }
            : null),
        },
      },
    });
  } catch (e) {}
};

export const updateScheduledConferenceInListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetScheduledChatConferencesVariables,
  scheduledConference: ScheduledChatConferenceApiType,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache) {
      return;
    }

    if (
      variables.filterPeriod === FilterPeriod.past &&
      Number(new Date(scheduledConference.startAt)) > Number(new Date())
    ) {
      addScheduledConferenceToListCache(
        proxy,
        {
          first: DEFAULT_PAGE_SIZE,
          workspace: variables.workspace,
          filterPeriod: FilterPeriod.future,
        },
        scheduledConference,
      );

      removeScheduledConferenceFromListCache(
        proxy,
        {
          first: DEFAULT_PAGE_SIZE,
          workspace: variables.workspace,
          filterPeriod: FilterPeriod.past,
        },
        scheduledConference.id,
        true,
      );
      return;
    }

    proxy.writeQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        scheduleChatConferences: {
          ...conferencesListCache.scheduleChatConferences,
          edges: _orderBy(
            [
              ...conferencesListCache.scheduleChatConferences.edges.map(
                edge => {
                  if (edge.node.id === scheduledConference.id) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        ..._pick(
                          scheduledConference,
                          ScheduledConferenceFields.trim()
                            .split('\n')
                            .map(key => key.trim()),
                        ),
                        //TODO Delete this logic after will https://norselab.atlassian.net/browse/NODESKMVP-2311?jql=project%20%3D%20NODESKMVP%20AND%20component%20%3D%20%22Backend%20-%20Tech%20debt%22
                        // be done
                        repeatingScheduleChatConference:
                          edge.node.repeatingScheduleChatConference &&
                          !scheduledConference.repeatingScheduleChatConference
                            ? edge.node.repeatingScheduleChatConference
                            : scheduledConference.repeatingScheduleChatConference,
                      },
                    };
                  }
                  return edge;
                },
              ),
            ],
            edge => edge.node.startAt,
            variables.filterPeriod === FilterPeriod.future ? 'asc' : 'desc',
          ),
        },
      },
    });
  } catch (e) {}
};

export const updateScheduledConferenceDetailsInCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  scheduledConference: ScheduledChatConferenceApiType,
) => {
  try {
    const conferenceCache = proxy.readQuery<
      GetScheduledChatConferenceResponse,
      GetScheduledChatConferenceVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCE,
      variables: {
        id: scheduledConference.id,
      },
    });
    if (!conferenceCache) {
      return;
    }

    proxy.writeQuery<
      GetScheduledChatConferenceResponse,
      GetScheduledChatConferenceVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCE,
      variables: {
        id: scheduledConference.id,
      },
      data: {
        ...conferenceCache,
        scheduleChatConference: {
          ...conferenceCache.scheduleChatConference,
          ...scheduledConference,
        },
      },
    });
  } catch (e) {}
};

export const updateScheduledConferenceInCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  workspaceId: string,
  scheduledConference: ScheduledChatConferenceApiType,
) => {
  updateScheduledConferenceInListCache(
    proxy,
    {
      first: DEFAULT_PAGE_SIZE,
      workspace: workspaceId,
      filterPeriod: FilterPeriod.past,
    },
    scheduledConference,
  );
  updateScheduledConferenceInListCache(
    proxy,
    {
      first: DEFAULT_PAGE_SIZE,
      workspace: workspaceId,
      filterPeriod: FilterPeriod.future,
    },
    scheduledConference,
  );
  updateScheduledConferenceDetailsInCache(proxy, scheduledConference);
};

export const removeScheduledConferenceFromListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetScheduledChatConferencesVariables,
  scheduledConferenceId: string,
  recursiveCall: boolean = false,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
    });

    const isDeletedElementExist =
      conferencesListCache?.scheduleChatConferences.edges.find(
        conference => conference.node.id === scheduledConferenceId,
      );

    if (!isDeletedElementExist && !recursiveCall) {
      removeScheduledConferenceFromListCache(
        proxy,
        {
          first: variables.first,
          workspace: variables.workspace,
          filterPeriod: FilterPeriod.past,
        },
        scheduledConferenceId,
        !recursiveCall,
      );

      return;
    }

    if (!conferencesListCache) {
      return;
    }

    proxy.writeQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        scheduleChatConferences: {
          ...conferencesListCache.scheduleChatConferences,
          edges: [
            ...conferencesListCache.scheduleChatConferences.edges.filter(
              scheduledConference =>
                '/schedule-chat-conferences/' + scheduledConference.node._id !==
                scheduledConferenceId,
            ),
          ],
        },
      },
    });
  } catch (e) {}
};
