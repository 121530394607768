import React, { FC } from 'react';
import {
  HiddenButton,
  LoggedOutText,
  StyledLoggedOutOverlay,
} from './LoggedOutOverlay.styled';
import FocusTrap from 'focus-trap-react';
import { FormattedMessage } from 'react-intl';
import { AuthTranslation } from '../../i18n';

export const LoggedOutOverlay: FC = () => {
  return (
    <StyledLoggedOutOverlay>
      <FormattedMessage
        id={AuthTranslation.loggedOutOverlayMessageText}
        tagName={LoggedOutText}
      />
      <FocusTrap>
        <div>
          <HiddenButton />
        </div>
      </FocusTrap>
    </StyledLoggedOutOverlay>
  );
};
