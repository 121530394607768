import { useCallback, useState } from 'react';
import { getLongId, getShortId } from '../../../shared/utils/id';
import { getDesktopFoldersRepository } from '../deprecatedData/Repository/DesktopRepository';

export const useCaseGetDesktopFolders = () => {
  const [desktopFoldersLoading, setDesktopFoldersLoading] = useState(false);

  const getDesktopFolders = useCallback(
    async (desktopId: string, workspaceId: string) => {
      setDesktopFoldersLoading(true);
      const response = await getDesktopFoldersRepository(
        getShortId(desktopId),
        getLongId('workspaces', workspaceId),
      );

      if (response) {
        setDesktopFoldersLoading(false);
        return response;
      }
    },
    [],
  );

  return {
    getDesktopFolders,
    desktopFoldersLoading,
  };
};
