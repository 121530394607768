import styled, { css } from 'styled-components';

export const ImportLinksWarning = styled.div`
  max-width: 540px;
  margin: 0 auto 20px;
`;

export const StyledImportLinksList = styled.div`
  height: 480px;
  background-color: ${p => p.theme.colors.Background};
  border-radius: 4px;
  padding: 1.25rem;
  overflow: auto;
`;

const ImportListItem = styled.div`
  margin-bottom: 0.625rem;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const ImportFolder = styled(ImportListItem)`
  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    margin: 0 0.375rem;
  }
`;

export const ImportFolderInner = styled.div`
  display: flex;
  align-items: center;
`;

const importItemName = css`
  font-size: 0.875rem;
`;

export const ImportFolderName = styled.div`
  ${importItemName};
`;

export const ImportLink = styled(ImportListItem)`
  display: flex;
  align-items: center;
  svg {
    margin: 0 1rem 0 0.625rem;
  }
`;

export const ImportLinkImage = styled.img`
  margin: 0 1rem 0 0.625rem;
`;

export const ImportLinkName = styled.div`
  ${importItemName};
`;

export const ImportFolderLinks = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;
