import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { OnboardingStepHeader } from '../OnboardingPage.styled';

export const AboutYouStepHeader = styled(OnboardingStepHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`;

export const AboutYouNextButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xxl};
`;

export const AboutYouFormField = styled.div`
  &:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;
