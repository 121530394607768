import { AvailabilityStatusType } from '../../User/User.types';

export type PingEvent = {
  identity: string;
  state: AvailabilityStatusType;
  time: string;
};

export enum IsTypingEventType {
  startTypingInChat = 'startTypingInChat',
  stopTypingInChat = 'stopTypingInChat',
}

export interface IsTypingPingEventType extends PingEvent {
  account: string;
  chatConversation: string;
  type: IsTypingEventType;
}
