import React, { FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { BillingTranslation } from '../../../../i18n';
import { StyledPlanTrialInfo } from './PlanTrialInfo.styled';

interface PlanTrialProps {
  daysLeft: number;
}

export const PlanTrialInfo: FC<PlanTrialProps> = ({ daysLeft }) => {
  if (!daysLeft) {
    return null;
  }

  return (
    <StyledPlanTrialInfo data-testid="free-trial-days-left">
      <FormattedHTMLMessage
        tagName="div"
        id={BillingTranslation.planTrialNote}
        values={{
          days: daysLeft,
        }}
      />
    </StyledPlanTrialInfo>
  );
};
