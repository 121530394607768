import React, { FC, PropsWithChildren, useState } from 'react';
import { ChatMercureClient } from './Chat/ChatMercureClient';
import { GeneralMercureClient } from './General/GeneralMercureClient';
import { MercureContext } from './Mercure.context';
import { PingMercureClient } from './Ping/PingMercureClient';

export const MercureProvider: FC<PropsWithChildren> = ({ children }) => {
  const [generalMercureClient, setGeneralMercureClient] =
    useState<GeneralMercureClient | null>(null);
  const [chatMercureClient, setChatMercureClient] =
    useState<ChatMercureClient | null>(null);
  const [pingMercureClient, setPingMercureClient] =
    useState<PingMercureClient | null>(null);

  return (
    <MercureContext.Provider
      value={{
        generalMercureClient,
        chatMercureClient,
        pingMercureClient,
        setGeneralMercureClient,
        setChatMercureClient,
        setPingMercureClient,
      }}>
      {children}
    </MercureContext.Provider>
  );
};
