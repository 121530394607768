import React, { FC } from 'react';
import { LinkMessageSkeleton, StyledLinkMessage } from './LinkMessage.styled';
import { Link } from '../../../../../Link/LinksView/Link';
import { useLinkWithExternalServiceAuth } from '../../../../../ExternalServiceAuth';
import { useVideoProviderPreviewReady } from '../../../../../VideoPlayer/VideoPlayer.hooks';
import { LinkVideoMetaData } from '../../../../../Link/Link.types';
import { useMobile } from '../../../../../../shared/hooks';
import type { ChatTextMessageInternalType } from '../../../../Chat.types';

interface LinkMessageProps {
  className?: string;
  messageContainerWidth?: string;
  ownMessage: boolean;
  chatLinkIri: string;
  chatMessage: ChatTextMessageInternalType;
}

export const LinkMessage: FC<LinkMessageProps> = ({
  ownMessage,
  chatMessage,
}) => {
  const isMobile = useMobile();

  useLinkWithExternalServiceAuth(chatMessage.linkData);

  const videoProviderPreviewReady: boolean = useVideoProviderPreviewReady(
    (chatMessage.linkData?.data.metadata as LinkVideoMetaData)?.type,
  );

  return (
    <StyledLinkMessage
      isMobile={isMobile}
      isVideoPreview={videoProviderPreviewReady}
      isThread={!!chatMessage?.parentId}
      data-testid="message-link-container"
      ownMessage={ownMessage}>
      {!chatMessage.linkData ? (
        <LinkMessageSkeleton />
      ) : (
        chatMessage.linkData && (
          <Link
            data-testid="message-link"
            link={chatMessage.linkData}
            chatLink
            disableTrackTimelineEvent
            linkChatMessage={chatMessage}
          />
        )
      )}
    </StyledLinkMessage>
  );
};
