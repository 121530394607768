import styled, { css } from 'styled-components';

export const contentStyle = css`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  line-height: 1.3;
`;

export const ProUpgrade = styled.div`
  margin-bottom: 50px;
  padding: 18px;
  background-color: ${p => p.theme.colors.ProUpgradeBackground};
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.ProUpgradeBorder};

  button {
    margin-right: 1rem;
  }
`;

export const ProUpgradeTitle = styled.h3`
  margin-bottom: 8px;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  line-height: 1.2;
`;

export const ProUpgradeDescription = styled.p`
  margin-bottom: 30px;
  ${contentStyle};
`;

export const ProFeatures = styled.ul`
  max-width: 500px;
  ${contentStyle};
  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const ProFeature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    width: 50%;
  `}
`;

export const ProFeatureLabel = styled.span`
  ${contentStyle};
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.Primary};
`;

export const UpgradeConfirmationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const LearnMoreLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${p => p.theme.colors.Primary};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  transition: opacity 0.3 ease;

  &:hover {
    opacity: 0.8;
  }
`;
