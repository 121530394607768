import React, { FC, useCallback } from 'react';
import { OnboardingTranslation } from '../../Onboarding/i18n';
import { OnboardingPage } from '../../Onboarding/OnboardingPage/OnboardingPage';
import { ResetMfaPageControls } from './ResetMfaPage.styled';
import { useResetMfaAccount } from '../Mfa.hooks';
import {
  OnboardingStep,
  OnboardingStepHeader,
} from '../../Onboarding/OnboardingPage/OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { useQueryParams } from '../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { LOGIN_PATHNAME } from '../../Auth/Auth.constants';

export const ResetMfaPage: FC = () => {
  const navigate = useNavigate();
  const [resetMfaAccountMutation] = useResetMfaAccount();
  const { id, hash } = useQueryParams();

  const handleResetMFAClick = useCallback(() => {
    if (typeof hash === 'string' && typeof id === 'string') {
      resetMfaAccountMutation({
        variables: {
          input: {
            hash: hash,
            id: '/accounts/' + id,
          },
        },
      })
        .then(() => {
          navigate({
            pathname: LOGIN_PATHNAME,
          });
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    }
  }, [navigate, hash, id, resetMfaAccountMutation]);

  return (
    <OnboardingPage displayAccountSection={false}>
      <OnboardingStep data-testid="reset-mfa-account">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage id={OnboardingTranslation.resetMfaPageTitle} />
        </OnboardingStepHeader>
        <ResetMfaPageControls>
          <Button
            type="button"
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            className="cancel-button"
            onClick={handleResetMFAClick}
            data-testid="cancel-button">
            <FormattedMessage
              id={OnboardingTranslation.resetMfaPageConfirmButton}
            />
          </Button>
        </ResetMfaPageControls>
      </OnboardingStep>
    </OnboardingPage>
  );
};
