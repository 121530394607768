import _isEqual from 'lodash/isEqual';
import { FileAssetId, ImageAssetId } from '../../../../Asset/Asset.types';
import {
  Asset,
  Attachment,
} from '../../../../Asset/Attachment/Attachment.types';
import { ChatMessageAssetApiType } from '../../../Chat.types';
import { makeAsset } from '../EditMessageForm/EditMessageForm.utils';

export const getAssetIds = (attachments: Attachment[]) =>
  attachments.reduce<string[]>(
    (acc, item) => (item.asset ? [...acc, item.asset.id] : acc),
    [],
  );

export const areAttachmentsEqual = (
  currentAttachments: Attachment[],
  previousAttachments: Attachment[],
) => {
  return _isEqual(
    getAssetIds(currentAttachments).sort(),
    getAssetIds(previousAttachments).sort(),
  );
};

export const chatMessageAssetToApiType = (
  attachments: Attachment[],
): ChatMessageAssetApiType[] =>
  attachments.map(attachment => {
    const asset = attachment.asset as Asset;
    const assetIri: FileAssetId | ImageAssetId = asset.id as
      | FileAssetId
      | ImageAssetId;

    return {
      assetIri,
      filename: attachment.file?.name || '',
      hash: asset.id || '',
      mimeType: asset.mimeType || '',
      size: asset.fileSize || '',
      url: asset.contentUrl || '',
      contentUrl: asset.contentUrl || '',
      metadata: null,
      dimensions: '',
    };
  });

export const chatMessageAssetApiTypeToAttachmentType = (
  assets?: ChatMessageAssetApiType[],
): Attachment[] => {
  if (!assets) {
    return [];
  }

  return assets.map(asset => ({
    id: asset.assetIri,
    asset: makeAsset(asset),
  }));
};
