import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { CreateDesktopForImport } from '../../../Desktop/DesktopForm/CreateDesktopForImport';
import { ChromeExtensionTranslation } from '../../i18n';
import {
  ImportToDesktopModalContent,
  ImportToDesktopModalTitle,
} from './CreateDesktopForImportModal.styled';

interface ImportToDesktopModalProps extends ModalProps {
  visible: boolean;
  onRequestClose: () => void;
}

export const CreateDesktopForImportModal: FC<ImportToDesktopModalProps> = ({
  visible,
  onRequestClose,
}) => {
  return (
    <Modal
      visible={visible}
      width={960}
      minHeight={730}
      onRequestClose={onRequestClose}>
      <ModalHeader underline={false} onRequestClose={onRequestClose} />
      <ImportToDesktopModalTitle>
        <FormattedMessage id={ChromeExtensionTranslation.importLocation} />
      </ImportToDesktopModalTitle>
      <ImportToDesktopModalContent>
        <FormattedMessage
          id={ChromeExtensionTranslation.importDesktopDescription}
        />
      </ImportToDesktopModalContent>
      <CreateDesktopForImport />
    </Modal>
  );
};
