import styled from 'styled-components';

export const StyledConfigurationTutorial = styled.div`
  margin-top: 2rem;

  .tutorial {
    line-height: 1.5;

    h1 {
      font-size: 1.125rem;
    }

    ol {
      list-style: none;
      padding-left: 0.5rem;
      counter-reset: tutorial-steps;

      > li {
        counter-increment: tutorial-steps;
        padding-left: 1.5rem;
        margin: 1.25rem 0;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          content: counter(tutorial-steps);
          color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
        }
      }
    }

    pre {
      overflow: auto;
      border-radius: 0.25rem;
      background-color: ${props => props.theme.colors.Background};
      font-size: 85%;
      padding: 0.75rem;
      font-family: ${p => p.theme.fonts.Medium};
    }

    .badge {
      display: inline-block;
      padding: 0.1875rem 0.375rem;
      background-color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
      color: ${p => p.theme.colors.PrimaryTextLight};
      border-radius: 0.25rem;
      font-size: 0.75rem;
    }

    p {
      margin-top: 1rem;
    }

    a {
      color: ${p => p.theme.colors.Primary};
    }

    img {
      max-width: 100%;
    }
  }
`;
