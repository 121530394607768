import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { Spinner } from '../../../../shared/components/Spinner';
import { ListItemContent } from '../../../../shared/components/List';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { KeyboardArrowRightIcon } from '../../../../shared/icons';
import { CreateDesktopModal } from './CreateDesktopModal';
import { useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderSubtitle,
  AdminHeaderTitle,
  AdminHeaderTitleContainer,
  AdminList,
  AdminListEmptyMessage,
  AdminListItem,
  AdminListItemLink,
} from '../../../Admin';
import { DesktopTranslation } from '../../i18n';
import { Search } from '../../../../shared/components/Search';
import { InputSize } from '../../../../shared/components/Input';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentRouteUrl } from '../../../../shared/hooks/router.hooks';
import { useDesktopsRepository } from '../../data/Desktop/Desktop.repositories';
import { DesktopApiType } from '../../data/Desktop/types/Desktop.types';
import { getShortId } from '../../../../shared/utils/id';

const FILTER_QUERY_PARAM_NAME = 'filter';
const CREATE_DESKTOP_MODAL_QUERY_PARAM_NAME = 'createDesktop';

export const DesktopList: FC = () => {
  const url = useCurrentRouteUrl();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const { desktops: desktopsData, loading: desktopsLoading } =
    useDesktopsRepository({
      variables: {
        shareable: false,
      },
    });

  const searchValue = (queryParams[FILTER_QUERY_PARAM_NAME] as string) || null;

  const sortedDesktops = useMemo(
    () => desktopsData.sort((a, b) => a.name.localeCompare(b.name)),
    [desktopsData],
  );

  const filteredDesktops: DesktopApiType[] | null = useMemo(() => {
    if (searchValue) {
      return desktopsData.filter(
        desktopData =>
          desktopData.name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1,
      );
    }

    return null;
  }, [desktopsData, searchValue]);

  const handleNewDesktopButtonClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        [CREATE_DESKTOP_MODAL_QUERY_PARAM_NAME]: true,
      }),
    });
  }, [navigate, queryParams]);

  const desktops = filteredDesktops || sortedDesktops || [];

  return (
    <>
      <AdminHeader data-testid="header">
        {!isMobile && (
          <AdminHeaderTitleContainer>
            <AdminHeaderTitle data-testid="title">
              <FormattedMessage id={DesktopTranslation.adminListHeaderTitle} />
            </AdminHeaderTitle>
            <AdminHeaderSubtitle data-testid="subtitle">
              <FormattedMessage
                id={DesktopTranslation.adminListHeaderSubtitle}
              />
            </AdminHeaderSubtitle>
          </AdminHeaderTitleContainer>
        )}
        <AdminHeaderControls data-testid="controls">
          <Search
            queryParamName={FILTER_QUERY_PARAM_NAME}
            size={InputSize.sm}
          />
          <Button
            size={ButtonSize.sm}
            mode={ButtonMode.primary}
            disabled={desktopsLoading}
            onClick={handleNewDesktopButtonClick}
            data-testid="new-desktop-button"
            data-tourid="admin-create-desktop-button">
            <FormattedMessage id={DesktopTranslation.adminListAddButton} />
          </Button>
        </AdminHeaderControls>
      </AdminHeader>

      {true && !desktops ? (
        <Spinner />
      ) : (
        <AdminList data-testid="desktops-list">
          {desktops.map(desktop => (
            <AdminListItem
              key={desktop.id}
              data-testid="desktop-item"
              data-entityid={desktop.id}>
              <AdminListItemLink
                to={`${url}/${getShortId(desktop.id)}`}
                data-testid="desktop-link">
                <ListItemContent
                  title={desktop.name}
                  subtitle={desktop.description}>
                  <ButtonWithIcon
                    icon={KeyboardArrowRightIcon}
                    data-testid="arrow-button"
                  />
                </ListItemContent>
              </AdminListItemLink>
            </AdminListItem>
          ))}

          {!desktops.length && (
            <AdminListEmptyMessage>
              <FormattedMessage id={DesktopTranslation.adminListEmptyMessage} />
            </AdminListEmptyMessage>
          )}
        </AdminList>
      )}

      {queryParams[CREATE_DESKTOP_MODAL_QUERY_PARAM_NAME] && (
        <CreateDesktopModal
          visible={true}
          onDone={() => navigate({})}
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                [CREATE_DESKTOP_MODAL_QUERY_PARAM_NAME]: undefined,
              }),
            })
          }
        />
      )}
    </>
  );
};
