import React, { FC } from 'react';
import { AssetViewCounterStyled } from '.';

interface AssetViewCounterProps {
  count: number;
  onClick: (e: React.SyntheticEvent) => void;
}

export const AssetViewCounter: FC<AssetViewCounterProps> = ({
  count,
  onClick,
}) => {
  return (
    <AssetViewCounterStyled data-testid="asset-view-counter" onClick={onClick}>
      +{count}
    </AssetViewCounterStyled>
  );
};
