import styled, { css } from 'styled-components';

const cardSharedStyles = css`
  --webkit-appearance: none;
  padding: 8px 8px 8px 18px;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  background-color: ${p => p.theme.colors.Surface};

  input {
    background-color: ${p => p.theme.colors.Surface};
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const StyledCardDetails = styled.div`
  display: grid;
  grid-template-areas:
    'number number'
    'expiry cvc';

  iframe {
    max-height: 15px;
  }

  .CardNumber {
    ${cardSharedStyles};

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .CardExpiry {
    ${cardSharedStyles};

    border-top: 0;
    border-bottom-left-radius: 4px;
  }
  .CardCvc {
    ${cardSharedStyles};

    border-top: 0;
    border-left: 0;
    border-bottom-right-radius: 4px;
  }
  .CardInput--invalid {
    box-shadow: inset 0 0 0 1px ${p => p.theme.colors.CriticalDark};
  }
`;

export const StyledCardNumberContainer = styled.div`
  grid-area: number;
  position: relative;
`;

export const StyledCardExpiryContainer = styled.div`
  grid-area: expiry;
`;

export const StyledCardCvcContainer = styled.div`
  grid-area: cvc;
  position: relative;
`;

export const StyledCardFieldIcons = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
  }
`;
