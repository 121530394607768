import { MAX_FILE_SIZE } from './ExternalUrlDropZone.constants';

import {
  ExternalFileCategory,
  ExternalFileCategoryUnsupportedType,
  ExternalFileCategoryUrlType,
  ExternalFileSupportedExtension,
} from './ExternalUrlDropZone.types';

/**
 * This function parses the .url files. Those are just a plain text files.
 * The format is:
 * ```
 * [InternetShortcut]
 * URL=https://example.com/
 * ```
 * So we need to extract the part after `URL=` from the second line.
 *
 * @param file File
 */
const extractUrlFromUrlFile = async (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return null;
  }

  const content = await file.text();
  const lines = content.split('\n');

  if (lines.length < 2 || lines[0].trim() !== '[InternetShortcut]') {
    return null;
  }

  let url: URL | undefined;

  try {
    url = new URL(lines[1].substr(4).trim());
  } catch (e) {}

  return url ? url.toString() : null;
};

const extractUrlFromWeblocFile = async (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return null;
  }

  const content = await file.text();
  const parser = new DOMParser();
  const xmlDom = parser.parseFromString(content, 'application/xml');
  const dict = xmlDom.documentElement.getElementsByTagName('dict')[0];

  if (dict) {
    const urlKey = dict.getElementsByTagName('key')[0].textContent;
    if (urlKey === 'URL') {
      return dict.getElementsByTagName('string')[0].textContent;
    }
  }

  return null;
};

export const validateExternallyDroppedFile = async (
  file: File,
  supportedFiles: ExternalFileSupportedExtension[],
) => {
  const fileExtension = file.name.split('.').pop();

  if (
    supportedFiles.includes(fileExtension as ExternalFileSupportedExtension)
  ) {
    switch (fileExtension) {
      case ExternalFileSupportedExtension.URL:
        return {
          type: ExternalFileCategory.URL,
          url: await extractUrlFromUrlFile(file),
        } as ExternalFileCategoryUrlType;
      case ExternalFileSupportedExtension.WEBLOC:
        return {
          type: ExternalFileCategory.URL,
          url: await extractUrlFromWeblocFile(file),
        } as ExternalFileCategoryUrlType;
    }
  }

  return {
    type: ExternalFileCategory.UNSUPPORTED,
    fileName: file.name,
    fileExtension: fileExtension,
  } as ExternalFileCategoryUnsupportedType;
};
