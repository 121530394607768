import jwtDecode from 'jwt-decode';

const HOUR_IN_SECONDS = 3600;

export const isTokenAboutToExpire = (
  token: string,
  expirationThreshold = HOUR_IN_SECONDS,
) => {
  let result = true;
  try {
    const parsedToken = jwtDecode<{ exp: number }>(token);
    const currentTimeInSeconds = new Date().getTime() / 1000;
    if (currentTimeInSeconds + expirationThreshold < parsedToken.exp) {
      result = false;
    }
  } catch (e) {}
  return result;
};

export const extractCustomClaim = (token: string, claim: 'identity_id') => {
  let result = null;
  try {
    const parsedToken = jwtDecode<{
      ['https://custom-claims/identity_id']?: string;
    }>(token);
    const parsedClaim = parsedToken[`https://custom-claims/${claim}` as const];
    if (parsedClaim) {
      result = parsedClaim;
    }
  } catch (e) {
    console.error(e);
  }
  return result;
};
