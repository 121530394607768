import React, { FC, PropsWithChildren, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  NotificationMessageApiType,
  NotificationMessageDesktopAppFields,
  NotificationMessageDesktopChatEnabledFields,
  NotificationMessageDesktopLinkAbusedFields,
  NotificationMessageDesktopLinkFields,
  NotificationMessageDesktopUserAddedFields,
  NotificationMessagePasswordRecoveryRequestCreated,
  NotificationMessageScheduledConferenceBeforeStart,
  NotificationMessageScheduledConferenceInvitedMember,
  NotificationMessageUserAddedToDesktop,
  NotificationMessageVaultAccessSharedWithYouFields,
  NotificationType,
} from '../../Notifications.types';
import {
  NotificationButton,
  NotificationLink,
  StyledNotificationContainer,
} from './NotificationContainer.styled';
import {
  CONFERENCES_CONFERENCE_PATHNAME,
  DESKTOP_ID_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  DesktopTab,
  PASSWORDS_VAULT_PATHNAME,
} from '../../../Desktop/Desktop.constants';
import { ADMIN_BILLING_PATHNAME } from '../../../Billing/Billing.constants';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../../Workspace/Workspace.hooks';
import { NotificationLinkModal } from './NotificationLinkModal/NotificationLinkModal';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { openConferenceWindow } from '../../../Conference/Conference.utils';
import { ConferencesTabsValues } from '../../../Conference/Conference.types';
import { useQueryParams } from '../../../../shared/hooks';
import { getShortId } from '../../../../shared/utils/id';

interface NotificationContainerProps {
  message: NotificationMessageApiType;
}

export const NotificationContainer: FC<
  PropsWithChildren<NotificationContainerProps>
> = ({ message, children }) => {
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { type, fields } = message;

  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();

  const clickHandler = useCallback(mobileNavigationSidebarClose, [
    mobileNavigationSidebarClose,
  ]);

  const openDesktopLinkInDetailsModal = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      mobileNavigationSidebarClose();
      if ((fields as NotificationMessageDesktopLinkFields).link_id) {
        event.preventDefault();
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            linkDetails: (fields as NotificationMessageDesktopLinkFields)
              .link_id,
            linkDetailsTab: undefined,
          }),
        });
      }
    },
    [fields, navigate, mobileNavigationSidebarClose, queryParams],
  );

  const renderContainer = () => {
    return (
      <StyledNotificationContainer data-testid="notification-item">
        {children}
      </StyledNotificationContainer>
    );
  };

  switch (type) {
    /**
     * Desktop related notification types
     */

    case NotificationType.DESKTOP_LINK_ADDED:
      const desktopLinkNewPath = generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageDesktopLinkFields).desktop_id,
        tab: DesktopTab.links,
      });

      return (
        <NotificationLink
          to={desktopLinkNewPath}
          onClick={openDesktopLinkInDetailsModal}>
          {renderContainer()}
        </NotificationLink>
      );
    case NotificationType.DESKTOP_LINK_REMOVED:
      const desktopLinksPath = generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageDesktopLinkFields).desktop_id,
        tab: DesktopTab.links,
      });
      return (
        <NotificationLink to={desktopLinksPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    case NotificationType.DESKTOP_LINK_ABUSED:
      return (
        <NotificationLinkModal
          fields={fields as NotificationMessageDesktopLinkAbusedFields}>
          {renderContainer()}
        </NotificationLinkModal>
      );

    case NotificationType.DESKTOP_APP_ADDED:
    case NotificationType.DESKTOP_APP_REMOVED:
      const desktopAppsPath = generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageDesktopAppFields).desktop_id,
        tab: DesktopTab.apps,
      });
      return (
        <NotificationLink to={desktopAppsPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    case NotificationType.DESKTOP_USER_ADDED: {
      const desktopPath = generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageDesktopUserAddedFields)
          .desktop_id,
      });
      return (
        <NotificationLink to={desktopPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );
    }

    case NotificationType.DESKTOP_CHAT_ENABLED: {
      const desktopPath = generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageDesktopChatEnabledFields)
          .desktop_id,
      });
      return (
        <NotificationLink to={desktopPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );
    }

    /**
     * User related notification types
     */

    case NotificationType.USER_BOOKMARK_IMPORT_FINISHED:
    case NotificationType.USER_DESKTOP_ADDED:
      const desktopPath = generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: (fields as NotificationMessageUserAddedToDesktop).desktop_id,
      });
      return (
        <NotificationLink to={desktopPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    /**
     * Billing related notification types
     */

    case NotificationType.PAYMENT_NEW_INVOICE:
      const billingPath = generatePath(ADMIN_BILLING_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return (
        <NotificationLink to={billingPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    case NotificationType.PAYMENT_FAILED: {
      const billingPath = generatePath(ADMIN_BILLING_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return (
        <NotificationLink to={billingPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );
    }

    case NotificationType.PAYMENT_INVALID_VAT: {
      const billingPath = generatePath(ADMIN_BILLING_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return (
        <NotificationLink to={billingPath} onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );
    }

    /**
     * Password manager notification types
     */

    case NotificationType.PASSWORD_RECOVERY_REQUEST_CREATED:
      return (
        <NotificationLink
          to={{
            search: getQueryParamsFrom({
              reencryptVaultAccesses: true,
              reencryptVaultAccessesRequestId: (
                fields as NotificationMessagePasswordRecoveryRequestCreated
              ).request_id,
            }),
          }}
          onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    case NotificationType.VAULT_ITEM_SHARED_WITH_YOU:
      return (
        <NotificationLink
          to={generatePath(PASSWORDS_VAULT_PATHNAME, {
            workspaceId: getShortId(workspace.id),
            vaultId: (
              fields as NotificationMessageVaultAccessSharedWithYouFields
            ).vault_id,
          })}
          onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    /**
     * Conference notification types
     */

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_BEFORE_START:
      return (
        <NotificationButton
          onClick={() =>
            openConferenceWindow(
              (fields as NotificationMessageScheduledConferenceBeforeStart)
                .chat_conference_id,
            )
          }>
          {renderContainer()}
        </NotificationButton>
      );

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_UPDATED:
    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_INVITED_MEMBER:
      return (
        <NotificationLink
          to={{
            pathname: generatePath(CONFERENCES_CONFERENCE_PATHNAME, {
              workspaceId: getShortId(workspace.id),
              conferenceId: (
                fields as NotificationMessageScheduledConferenceInvitedMember
              ).schedule_chat_conference_id,
            }),
            search: getQueryParamsFrom({
              meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
            }),
          }}
          onClick={clickHandler}>
          {renderContainer()}
        </NotificationLink>
      );

    default:
      // Notifications with no links will end up here
      return renderContainer();
  }
};
