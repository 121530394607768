import { useMemo } from 'react';
import {
  getAssetMediaIri,
  isImageMimeType,
  isVideoMimeType,
} from '../../../../../Asset';
import type { VideoMetaData } from '../../../../../Asset/Asset.types';
import { isDocumentMimeType } from '../../../../../Preview/DocumentPreview';
import type { ChatMessageAssetApiType } from '../../../../Chat.types';
import { MOBILE_MAX_ASSET_TO_VIEW } from './AssetView';
import sortBy from 'lodash/sortBy';
export interface PrepareChatMessageAssetProps {
  assets: ChatMessageAssetApiType[];
  sliderAssets?: ChatMessageAssetApiType[];
  imageAssetsCount?: number;
  videoAssetsCount?: number;
}

export const usePrepareChatMessageAsset = (
  assets: ChatMessageAssetApiType[] | undefined,
  isMobile: boolean,
): PrepareChatMessageAssetProps => {
  return useMemo(() => {
    if (!assets?.length) {
      return {
        assets: [],
      };
    }
    const processedVideoAssetMetadata =
      assets?.filter(
        asset => (asset.metadata as VideoMetaData)?.is_preview && asset,
      ) || [];

    const originalVideoAssetMetadata = assets?.filter(
      asset =>
        isVideoMimeType(asset.mimeType) &&
        !(asset.metadata as VideoMetaData)?.is_preview,
    );

    const imageAssets =
      assets?.filter(asset => isImageMimeType(asset.mimeType)) ?? [];

    let videoAssets: ChatMessageAssetApiType[] =
      !processedVideoAssetMetadata.length ? originalVideoAssetMetadata : [];

    if (processedVideoAssetMetadata.length) {
      originalVideoAssetMetadata?.forEach(
        (original: ChatMessageAssetApiType) => {
          processedVideoAssetMetadata.forEach(
            (processed: ChatMessageAssetApiType) => {
              if (
                getAssetMediaIri(
                  (processed.metadata as VideoMetaData).original_asset_id,
                ) === original.assetIri
              ) {
                videoAssets.push({
                  ...original,
                  filename: original.filename,
                  size: processed.size,
                  url: processed.url,
                });
              }
            },
          );
        },
        [],
      );
    }

    const fileAssets =
      assets?.filter(
        asset =>
          !isImageMimeType(asset.mimeType) && !isVideoMimeType(asset.mimeType),
      ) ?? [];

    const imageAssetsCount = imageAssets.length;

    let mobileImageAssets: ChatMessageAssetApiType[] = imageAssets;

    if (isMobile && imageAssetsCount > MOBILE_MAX_ASSET_TO_VIEW) {
      mobileImageAssets = imageAssets.slice(0, MOBILE_MAX_ASSET_TO_VIEW);
    }

    const data = [
      ...videoAssets,
      ...(isMobile ? mobileImageAssets : imageAssets),
      ...fileAssets,
    ];

    return {
      assets: sortBy(data, [
        asset => {
          if (isVideoMimeType(asset.mimeType)) {
            return 0;
          }

          if (
            (asset.metadata &&
              'type' in asset.metadata &&
              isDocumentMimeType(asset.metadata.type)) ||
            isDocumentMimeType(asset.mimeType)
          ) {
            return 1;
          }

          return 2;
        },
      ]),
      imageAssetsCount,
      videoAssetsCount: videoAssets.length,
      sliderAssets: imageAssets,
    };
  }, [assets, isMobile]);
};
