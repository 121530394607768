import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePreviewSegment } from '../../PreviewSegment/PreviewSegment.hooks';
import { SegmentEmpty } from '../../Segment';
import type {
  ConferenceApiType,
  ScheduledChatConferenceApiType,
} from '../Conference.types';
import { ConferenceTranslation } from '../i18n';
import { ConferenceView } from './ConferenceView';
import { ScheduledConferenceView } from './ScheduledConferenceView';

interface ConferencesPageContentProps {
  currentConferenceId?: string;
  currentScheduledConference?: ScheduledChatConferenceApiType;
  currentConference?: ConferenceApiType;
}

export const ConferencesPageContent = memo<ConferencesPageContentProps>(
  ({ currentScheduledConference, currentConferenceId, currentConference }) => {
    const { isPreviewMode } = usePreviewSegment();

    if (currentScheduledConference) {
      return (
        <ScheduledConferenceView
          currentConferenceId={currentConferenceId}
          data-testid="scheduled-conference-view"
        />
      );
    } else if (currentConference) {
      return (
        <ConferenceView
          currentConferenceId={currentConferenceId}
          data-testid="conference-view"
        />
      );
    }

    if (isPreviewMode) {
      return null;
    }

    return (
      <SegmentEmpty>
        <FormattedMessage
          id={ConferenceTranslation.conferenceViewEmptyContent}
        />
      </SegmentEmpty>
    );
  },
);
