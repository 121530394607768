import styled, { css } from 'styled-components';
import { Modal } from '../../../shared/components/Modal';

export const AssetModal = styled(Modal)<{
  width: number;
}>`
  .modal-window {
    width: 100% !important;
    max-width: ${p => `${p.width}px`};
  }
`;

export const DownloadLink = styled.a`
  display: flex;
  color: ${p => p.theme.colors.AssetsModalHeaderTextColor};
  margin-left: 0.625rem;
`;

export const AssetPreviewModalBody = styled.div<{
  isMobile: boolean;
}>`
  padding: ${p => (p.isMobile ? 0 : p.theme.spacing.l)};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 75vh;
  }
`;

export const AssetPreviewMeta = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const AssetPreviewMetaInner = styled.div`
  margin-left: 1rem;
  overflow: hidden;
`;

export const ModalHeaderInner = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${p =>
    p.isMobile &&
    css`
      padding-right: 0.625rem;
    `}
`;

export const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
`;

export const AssetAccountName = styled.span<{
  isMobile: boolean;
}>`
  font-family: ${p => p.theme.fonts.Regular};
  color: ${p => p.theme.colors.PrimaryTextLight};

  ${p =>
    p.isMobile &&
    css`
      font-size: 1rem;
    `}
`;

export const AssetCreatedAt = styled.span<{
  isMobile: boolean;
}>`
  font-family: ${p => p.theme.fonts.Regular};
  color: ${p => p.theme.colors.AssetsModalHeaderTextColor};

  ${p =>
    p.isMobile &&
    css`
      font-size: 0.75rem;
    `}
`;
