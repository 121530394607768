import React, { FC, useCallback, useMemo } from 'react';
import { useAppIntegrationsMarketplaceItems } from '../../../AppIntegrations.hooks';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AdminHeaderSeparator } from '../../../../Admin';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { AppIntegrationsTranslation } from '../../../i18n';
import {
  GoogleApiUsageDisclosure,
  StyledDivider,
  StyledListTitle,
  StyledMarketplaceCategory,
  StyledMarketplaceItemsList,
  StyledMarketplaceItemsWrapper,
} from './MarketplaceItems.styled';
import { Spinner } from '../../../../../shared/components/Spinner';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { AppIntegrationsCategories } from '../../../AppIntegrations.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { MarketplaceItem } from '../MarketplaceItem';
import { MarketplaceApp } from '../MarketplaceItem/MarketplaceApp';
import { MARKETPLACE_CATEGORY_PATH } from '../../../AppIntegrations.constants';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../../Workspace/Workspace.constants';
import { CreateAppIntegrationCredentialModal } from '../MarketplaceItem/CreateAppIntegrationCredentialModal';
import { getShortId } from '../../../../../shared/utils/id';
import { UserTranslation } from '../../../../User/i18n';
import { appEnv } from '../../../../../appEnv';

const GOOGLE_CONNECTOR_DRIVER = 'google';

export const MarketplaceItems: FC = () => {
  const { marketplaceCategoryId, marketplaceCategoryItemId, workspaceId } =
    useParams<{
      marketplaceCategoryItemId: string;
      marketplaceCategoryId: string;
      workspaceId: string;
    }>();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const {
    integrationsCategory,
    createAppIntegrationCredentialMarketplaceItemId,
  } = queryParams;

  const { data, loading, refetch } = useAppIntegrationsMarketplaceItems({
    variables: {
      marketplaceCategory: marketplaceCategoryId as string,
      personal: integrationsCategory === AppIntegrationsCategories.personal,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onRequestClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createAppIntegrationCredentialMarketplaceItemId: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const marketplaceItems = useMemo(() => {
    return extractNodes(data?.appIntegrationMarketplaceItems);
  }, [data]);

  const handleMoveToCategoryButtonClick = useCallback(() => {
    navigate({
      pathname: generatePath(APPS_INTEGRATIONS_PATHNAME, {
        workspaceId: workspaceId,
      }),
    });
  }, [navigate, workspaceId]);

  const showMarketplaceItems = !loading && marketplaceItems.length > 0;
  const marketplaceCategory = marketplaceItems[0]?.marketplaceCategory;

  const MarketplaceItemsList = () => {
    return (
      <>
        <StyledMarketplaceCategory>
          <img
            className="logo"
            src={marketplaceCategory.image.contentUrl}
            alt=""
          />
          <div className="body">
            <span className="title">{marketplaceCategory.name}</span>
          </div>
        </StyledMarketplaceCategory>
        <StyledListTitle>
          <FormattedMessage
            id={AppIntegrationsTranslation.appIntegrationAvailable}
          />
        </StyledListTitle>
        <StyledMarketplaceItemsList>
          {!showMarketplaceItems ? (
            <Spinner />
          ) : (
            marketplaceItems.map(marketplaceItem => (
              <MarketplaceItem
                workspaceId={workspaceId as string}
                marketplaceItem={marketplaceItem}
                key={marketplaceItem.id}
                refetchMarketplaceItems={refetch}
              />
            ))
          )}
        </StyledMarketplaceItemsList>
        {data?.appIntegrationMarketplaceItems?.edges?.[0]?.node
          ?.connectorConfiguration?.driver === GOOGLE_CONNECTOR_DRIVER && (
          <FormattedHTMLMessage
            id={UserTranslation.inviteGoogleUsersDisclosure}
            tagName={GoogleApiUsageDisclosure}
            values={{
              googleApiServicesDataPolicyUrl:
                appEnv.GOOGLE_API_SERVICES_DATA_POLICY_URL,
            }}
          />
        )}
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          onClick={handleMoveToCategoryButtonClick}>
          <FormattedMessage
            id={AppIntegrationsTranslation.backToAppIntegrationsPage}
          />
        </Button>
      </>
    );
  };

  const selectedMarketplaceItem = useMemo(() => {
    return marketplaceItems?.find(
      item => getShortId(item.id) === marketplaceCategoryItemId,
    );
  }, [marketplaceCategoryItemId, marketplaceItems]);

  const handleBackToListClick = () => {
    if (selectedMarketplaceItem) {
      navigate({
        pathname: generatePath(MARKETPLACE_CATEGORY_PATH, {
          workspaceId: workspaceId,
          marketplaceCategoryId: marketplaceCategoryId,
        }),
        search: getQueryParamsFrom({
          ...queryParams,
        }),
      });
    }
  };

  const handleOnDone = useCallback(() => {
    onRequestClose();
    refetch();
  }, [onRequestClose, refetch]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <StyledMarketplaceItemsWrapper>
      <div data-testid="title" className="header">
        <span className="title" onClick={handleMoveToCategoryButtonClick}>
          <FormattedMessage
            id={AppIntegrationsTranslation.appIntegrationTitle}
          />
        </span>{' '}
        <AdminHeaderSeparator />
        <span onClick={handleBackToListClick}>{marketplaceCategory.name}</span>
        {!!selectedMarketplaceItem && (
          <>
            <AdminHeaderSeparator />
            <span>{selectedMarketplaceItem.name}</span>
          </>
        )}
      </div>
      <StyledDivider />
      {!!selectedMarketplaceItem ? (
        <MarketplaceApp marketplaceItem={selectedMarketplaceItem} />
      ) : (
        <MarketplaceItemsList />
      )}
      {createAppIntegrationCredentialMarketplaceItemId && (
        <CreateAppIntegrationCredentialModal
          onRequestClose={onRequestClose}
          onDone={handleOnDone}
        />
      )}
    </StyledMarketplaceItemsWrapper>
  );
};
