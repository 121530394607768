import React, { FC, useMemo } from 'react';
import { AccountApiType } from '../../../User/User.types';
import { getAccountInitials } from '../../../User/User.utils';
import { StyledAvatarPlaceholder } from './AvatarPlaceholder.styled';
import { getPastelColorByString } from './AvatarPlaceholder.utils';

interface AvatarPlaceholderProps {
  account?: AccountApiType;
  email?: string;
  bgColor?: string;
}

export const AvatarPlaceholder: FC<AvatarPlaceholderProps> = ({
  account,
  email,
  bgColor,
  ...rest
}) => {
  const backgroundColor = useMemo(
    () =>
      bgColor ??
      (account ? getPastelColorByString(account.id || '-', 40, 55) : undefined),
    [account, bgColor],
  );
  return (
    <StyledAvatarPlaceholder
      data-testid="avatar-placeholder"
      bgColor={backgroundColor}
      {...rest}>
      {account ? getAccountInitials(account) : email ? email.charAt(0) : null}
    </StyledAvatarPlaceholder>
  );
};
