import { useCurrentWorkspace } from './../../../../../Workspace/Workspace.hooks';
import { useCallback } from 'react';
import { useCurrentAccount } from '../../../../../Auth/Auth.hooks';
import { usePingMercureUrl } from '../../../../../Mercure/Ping/PingMercure.hooks';
import { IsTypingEventType } from '../../../../../Mercure/Ping/PingMercure.types';

export const usePublishTypingPing = () => {
  const { account: currentAccount } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const url = usePingMercureUrl();
  const pingMercureInfo = currentAccount?.mercureInfo?.ping;

  return useCallback(
    (chatConversation: string, typingEventType: IsTypingEventType) => {
      if (!url || !currentAccount || !chatConversation || !pingMercureInfo) {
        return;
      }

      const data = {
        type: typingEventType,
        chatConversation: chatConversation,
        account: currentAccount.id,
      };

      window.DESKTOPCOM_LOG_PING_TYPING_PUBLISH_MERCURE_EVENTS &&
        console.log('[PING event OUT]', data);

      const formData = new URLSearchParams();
      formData.append('data', JSON.stringify(data));

      (pingMercureInfo.topics.workspaces as []).forEach(
        (topicWorkspace: string) => {
          if (workspace.id === topicWorkspace) {
            formData.append('topic', topicWorkspace);
          }
        },
      );

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${pingMercureInfo.authorization}`,
        },
        body: formData,
      };

      fetch(url, requestOptions)
        .then(async response => {
          if (!response.ok) {
            const isJson = response.headers
              .get('content-type')
              ?.includes('application/json');
            const data = isJson && (await response.json());

            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [currentAccount, pingMercureInfo, url, workspace.id],
  );
};
