import {
  NewChatMessageEvent,
  RemoveChatMessageEvent,
  UpdateChatMessageEvent,
} from '../ChatMercure.types';
import {
  getChatLinkIri,
  makeNewChatMessage,
  makeUpdatedChatMessage,
  updateAccountChatMessageUnreads,
} from './chatMessage.utils';
import {
  convertGraphQLApiChatMessageToInternal,
  getChatMessageIri,
} from '../../../Chat/Chat.utils';
import type { AccountApiType } from '../../../User/User.types';
import {
  createIDDBChatMessage,
  deleteIDDBChatMessage,
  getIDDBChatMessage,
  updateIDDBChatMessage,
} from '../../../Chat/Data/Repository/ChatMessage/ChatMessageIDDBRepository';
import type { ConversationInstance } from '../../../Chat/Data/Repository/ChatMessage/ChatMessage.types';
import { GetChatLinkResponse } from '../../../Chat/Chat.queries';
import { LinkApiType } from '../../../Link/Link.types';
import { captureException } from '@sentry/react';
import {
  getConversationIDDB,
  updateConversationIDDB,
} from '../../../Chat/Data/DataSource/ChatConversation/ChatConversationsIndexedDBSource';
import { UNREAD_REQUEST_TIMEOUT } from '../../../Account/data/CurrentAccount/CurrentAccount.constants';
import { UseAccountApiRepositoryProps } from '../../../Account/data/CurrentAccount/types/CurrentAccountRepository.types';
import { MessageType } from '../../../Chat/Chat.types';

let unreadChatMessagesRequested: boolean = false;

export const handleNewChatMessage = async (
  event: NewChatMessageEvent,
  currentAccount: AccountApiType | null | undefined,
  updateAccount: (updater: (account: AccountApiType) => AccountApiType) => void,
  conversationInstance: ConversationInstance | null,
  accountApiRepository: UseAccountApiRepositoryProps,
) => {
  if (!currentAccount?.id) {
    return;
  }

  const chatMessage = makeNewChatMessage(event);
  const convertedMessage = convertGraphQLApiChatMessageToInternal(chatMessage, {
    conversationId: event.conversationIri,
    accountId: currentAccount!.id,
  });

  const cachedMessage = await getIDDBChatMessage(chatMessage.id);
  const optimisticCachedMessage = await getIDDBChatMessage(event.optimisticId);

  const conversation = await getConversationIDDB(event.conversationIri);

  if (conversation?.isHidden) {
    updateConversationIDDB({
      ...conversation,
      isHidden: false,
      isHiddenAt: null,
    });
  }

  if (
    conversationInstance &&
    !conversationInstance.canLoadAfter &&
    !cachedMessage &&
    !optimisticCachedMessage
  ) {
    await createIDDBChatMessage(convertedMessage).catch(() => {});
  }

  if (event.accountIri !== currentAccount!.id) {
    if (unreadChatMessagesRequested) {
      return;
    }

    unreadChatMessagesRequested = true;

    setTimeout(() => {
      updateAccountChatMessageUnreads(
        accountApiRepository,
        currentAccount,
        updateAccount,
        event.workspaceIri,
      ).finally(() => {
        unreadChatMessagesRequested = false;
      });
    }, UNREAD_REQUEST_TIMEOUT);
  }
};

export const handleUpdateChatMessage = async (
  event: UpdateChatMessageEvent,
  currentAccount: AccountApiType | null | undefined,
  getChatLink: (linkId: string) => Promise<GetChatLinkResponse | undefined>,
) => {
  if (!currentAccount?.id) {
    return;
  }

  const chatMessage = makeUpdatedChatMessage(event);
  let linkData: LinkApiType | null = null;

  const singleChatLinkIri = getChatLinkIri(chatMessage.context);

  if (singleChatLinkIri) {
    await getChatLink(singleChatLinkIri)
      .then(data => {
        linkData = data?.chatLink || null;
      })
      .catch(err => captureException(err));
  }

  const convertedMessage = convertGraphQLApiChatMessageToInternal(
    { ...chatMessage, linkData },
    {
      conversationId: event.conversationIri,
      accountId: currentAccount.id,
    },
  );

  if (convertedMessage?.type === MessageType.Conference) {
    console.log(`[Chat mercure] Conference ChatMessage update — ${event.id}
        active = ${convertedMessage?.context?.active}
        particimants.length = ${convertedMessage?.context?.participants?.length}
        particimants_total.length = ${convertedMessage?.context?.participants_total?.length}`);
  }

  let cachedMessage = await getIDDBChatMessage(convertedMessage.id);

  if (!cachedMessage) {
    console.log(
      '[Chat mercure] Conference ChatMessage for update was not found in IDDB',
      event.id,
    );
    return;
  }

  updateIDDBChatMessage(convertedMessage.id, {
    ...convertedMessage,
    ...cachedMessage,
  });
};

export const handleRemoveChatMessage = (
  event: RemoveChatMessageEvent,
  closeThreadSideBar: () => void,
  sidebarChatMessageId: string | null,
) => {
  const chatMessageId = event['@iri'];

  if (
    sidebarChatMessageId &&
    getChatMessageIri(sidebarChatMessageId) === chatMessageId
  ) {
    closeThreadSideBar();
  }

  deleteIDDBChatMessage(chatMessageId);
};
