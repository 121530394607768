import styled from 'styled-components';

export const StyledMarketplaceAppWrapper = styled.div`
  .configuration-title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

export const StyledMarketplaceApp = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: 0.5rem;
  border: solid 1px ${({ theme }) => theme.colors.SurfaceBorder};
  background-color: ${({ theme }) => theme.colors.Surface};
  margin-bottom: 3.375rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    border-color: ${({ theme }) => theme.colors.SurfaceBorder};
  }

  .name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.OnSurface};
  }

  .body {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .logo {
    width: 2.25rem !important;
    height: 2.25rem !important;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .description {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  }

  .controls {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
    font-weight: 400;

    button {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledMarketplaceAppInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3.25rem;

  .logo {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.75rem;
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125rem;
  }

  .sub-title {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
`;
