import React, { FC } from 'react';
import { CategoryApiType } from '../../../../../../shared/api/api.types';
import { useNavigate } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { Expand } from '../../../../../../shared/components/Expand';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from '../../../../../../shared/icons';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { AppStoreTranslation } from '../../../../i18n';
import { FormattedMessage } from 'react-intl';
import { useHideScrollbarWhenIdle } from '../../../../../../shared/hooks/element.hooks';
import {
  CategoriesList,
  CategoriesTitle,
  Category,
  StyledCategories,
} from './Categories.styled';

interface CategoriesProps {
  categories: CategoryApiType[];
}

export const Categories: FC<CategoriesProps> = ({ categories }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { categoryId } = queryParams;
  const isMobile = useMobile();
  const [categoriesRef] = useHideScrollbarWhenIdle();

  return (
    <StyledCategories data-testid="categories-panel" ref={categoriesRef}>
      {!isMobile && (
        <CategoriesTitle
          data-testid="title"
          data-tourid="app-store-categories-header">
          <FormattedMessage id={AppStoreTranslation.categoriesSectionTitle} />
        </CategoriesTitle>
      )}
      <Expand
        renderTrigger={
          isMobile
            ? (trigger, expanded) => (
                <ButtonWithIcon
                  size={ButtonSize.md}
                  mode={ButtonMode.secondary}
                  onClick={trigger}
                  icon={expanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                  fullWidth>
                  <FormattedMessage
                    id={AppStoreTranslation.categoriesSectionTitle}
                  />
                </ButtonWithIcon>
              )
            : null
        }>
        <CategoriesList data-testid="categories-list">
          {categories.map(category => (
            <Category
              className={categoryId === category._id ? 'active' : ''}
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    categoryId: category._id,
                  }),
                })
              }
              key={category.id}
              data-testid="category">
              {category.name}
            </Category>
          ))}
        </CategoriesList>
      </Expand>
    </StyledCategories>
  );
};
