import _pick from 'lodash/pick';
import {
  NewScheduledChatConferenceEvent,
  UpdateScheduledChatConferenceEvent,
} from '../ChatMercure.types';
import {
  GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE,
  GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBERS,
  SCHEDULE_CHAT_CONFERENCE_MEMBER_IRI,
} from '../../../Conference/Conference.constants';
import {
  ScheduleChatMembersApiType,
  ScheduledChatConferenceApiType,
} from '../../../Conference/Conference.types';
import { AccountWithCountsApiType } from '../../../User/User.types';
import { getShortId } from '../../../../shared/utils/id';

const mapChatConferenceMemberEdge = (
  member: NewScheduledChatConferenceEvent['scheduleChatConferenceMembers'][number],
): {
  __typename: typeof GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBERS;
  node: ScheduleChatMembersApiType;
} => ({
  __typename: GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBERS,
  node: {
    __typename: 'ScheduleChatConferenceMember',
    id: SCHEDULE_CHAT_CONFERENCE_MEMBER_IRI + member._id,
    account: member.account
      ? {
          ...(_pick(member.account, [
            '__typename',
            '_id',
            'firstName',
            'lastName',
            'email',
          ]) as AccountWithCountsApiType),
          id: member.account.id,
        }
      : null,
    email: member.email,
    icalendarUrl: member.icalendarUrl,
  },
});

export const makeScheduledChatConference = (
  scheduledChatConference:
    | NewScheduledChatConferenceEvent
    | UpdateScheduledChatConferenceEvent,
): ScheduledChatConferenceApiType => ({
  id: scheduledChatConference['@iri'],
  _id: getShortId(scheduledChatConference['@iri']),
  endAt: scheduledChatConference.endAt,
  startAt: scheduledChatConference.startAt,
  timezone: scheduledChatConference.timezone,
  title: scheduledChatConference.title,
  description: scheduledChatConference.description,
  sequence: scheduledChatConference.sequence,
  membersOverridden: scheduledChatConference.membersOverridden,
  scheduleChatConferenceMembers: {
    edges: scheduledChatConference.scheduleChatConferenceMembers.map(
      mapChatConferenceMemberEdge,
    ),
    __typename: GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBERS,
  },
  repeatingScheduleChatConference:
    scheduledChatConference.repeatingScheduleChatConference
      ? {
          id: scheduledChatConference.repeatingScheduleChatConference.id,
          scheduleChatConferenceMembers:
            scheduledChatConference.repeatingScheduleChatConference
              .scheduleChatConferenceMembers,
          repeatPattern:
            scheduledChatConference?.repeatingScheduleChatConference
              .repeatPattern,
        }
      : null,
  __typename: GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE,
});
