import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { HIDE_SCROLL_CLASSNAME } from '../../domains/AppRoot/AppRoot.styled';

const HIDE_SCROLLBAR_DELAY = 700;

export const useElementHasScroll = (element: RefObject<HTMLElement>) => {
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    element.current
      ? setHasScroll(
          element.current.scrollHeight > element.current.clientHeight,
        )
      : setHasScroll(false);
  }, [element]);

  return {
    hasScroll,
  };
};

export const useHideScrollbarWhenIdle = () => {
  const timer = useRef<any>(null);
  const [refNode, setRefNode] = useState<HTMLElement | undefined>(undefined);

  const hideScrollbarHandler = useCallback(() => {
    refNode!.classList.add(HIDE_SCROLL_CLASSNAME);
  }, [refNode]);

  const handleScrollEvent = useCallback(() => {
    refNode!.classList.remove(HIDE_SCROLL_CLASSNAME);
    clearTimeout(timer.current);
    timer.current = window.setTimeout(
      hideScrollbarHandler,
      HIDE_SCROLLBAR_DELAY,
    );
  }, [hideScrollbarHandler, refNode]);

  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setRefNode(node);
    }
  }, []);

  useEffect(() => {
    if (refNode) {
      refNode.addEventListener('scroll', handleScrollEvent);
      refNode.classList.add(HIDE_SCROLL_CLASSNAME);
    }

    return () => {
      if (refNode) {
        refNode!.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [handleScrollEvent, ref, refNode]);

  return [ref];
};
