import { useEffect } from 'react';
import { sendOnboardingTrackingEvent } from './OnboardingTracking.utils';
import { OnboardingTrackingAction } from './OnboardingTracking.types';

export const useOnboardingTrackingEventOnInit = (
  action: OnboardingTrackingAction,
) => {
  useEffect(() => {
    sendOnboardingTrackingEvent(action);
  }, []); // eslint-disable-line
};
