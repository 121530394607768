import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ClockIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18.5 10C18.5 12.2543 17.6045 14.4163 16.0104 16.0104C14.4163 17.6045 12.2543 18.5 10 18.5C7.74566 18.5 5.58365 17.6045 3.98959 16.0104C2.39553 14.4163 1.5 12.2543 1.5 10C1.5 7.74566 2.39553 5.58365 3.98959 3.98959C5.58365 2.39553 7.74566 1.5 10 1.5C12.2543 1.5 14.4163 2.39553 16.0104 3.98959C17.6045 5.58365 18.5 7.74566 18.5 10ZM20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM10.5 5.75C10.5 5.55109 10.421 5.36032 10.2803 5.21967C10.1397 5.07902 9.9489 5 9.75 5C9.5511 5 9.3603 5.07902 9.2197 5.21967C9.079 5.36032 9 5.55109 9 5.75V10.25C9 10.585 9.222 10.88 9.544 10.971L13.044 11.971C13.1393 12.0003 13.2396 12.0103 13.3388 12.0004C13.4381 11.9905 13.5344 11.9609 13.622 11.9133C13.7097 11.8657 13.787 11.8012 13.8494 11.7233C13.9118 11.6455 13.9581 11.5561 13.9855 11.4601C14.0129 11.3642 14.0209 11.2638 14.009 11.1648C13.9971 11.0657 13.9656 10.9701 13.9163 10.8834C13.867 10.7967 13.8009 10.7206 13.7219 10.6598C13.6428 10.599 13.5524 10.5545 13.456 10.529L10.5 9.684V5.75Z"
      />
    </svg>
  );
});
