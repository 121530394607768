import type { CategoryApiType } from '../../shared/api/api.types';
import type { DesktopAppApiType } from '../Desktop/data/Desktop/types/Desktop.types';

export const getTemplateId = (shortId: string): string => {
  return '/templates/' + shortId;
};

export const getAppsWithCategories = (
  categories: CategoryApiType[],
  apps: DesktopAppApiType[],
  minAppsNumberInCategory?: number,
) => {
  const appsMap = new Map();

  categories.forEach(category => {
    const appsData = apps.filter(app => {
      if (!app.category?.name) {
        return false;
      }
      return app.category.name === category.name;
    });

    if (category.name && appsData.length) {
      appsMap.set(category.name, appsData);
    }
  });

  if (minAppsNumberInCategory) {
    appsMap.forEach((value, key) => {
      if (appsMap.get(key).length < minAppsNumberInCategory) {
        appsMap.delete(key);
      }
    });
  }

  return Object.fromEntries(appsMap);
};
