import styled, { css } from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';

const truncate = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledDesktopListContainer = styled.div.attrs({
  'data-testid': 'desktop-list-container',
})`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const Collapse = styled.div<{ isOpened: boolean }>`
  transition: max-height 0.2s, opacity 0.2s;

  ${p =>
    p.isOpened
      ? css`
          max-height: 3000px; // some big value, which we never hit in theory
        `
      : css`
          opacity: 0;
          max-height: 0;
          pointer-events: none;
        `}
`;

export const StyledDesktopList = styled.ul.attrs({
  'data-testid': 'desktop-list',
})<{}>`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const StyledDesktopListItem = styled.li`
  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const StyledDesktopListHeader = styled.div.attrs({
  'data-testid': 'header',
})`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  color: ${p => p.theme.colors.PrimaryTextLight};
  padding-left: ${({ theme }) => theme.spacing.xs};
`;

export const DesktopListToggleButton = styled(Button)`
  ${({ theme }) => theme.fontTemplates.headerList};
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const StyledDesktopListTitle = styled.h2.attrs({
  'data-testid': 'title',
})<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.OnNavbarMediumEmphasis};
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.75rem;
  line-height: 1.3;

  svg {
    margin-right: 0.5rem;
    transition: transform 0.2s;
  }

  ${p =>
    !p.isOpened &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}
`;

export const ExternallySharedLabel = styled.div<{
  visible: boolean;
}>`
  white-space: nowrap;
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  text-transform: uppercase;
  display: ${p => (p.visible ? 'block' : 'none')};
`;

export const DesktopListEmptyState = styled.div.attrs({
  'data-testid': 'company-desktops-empty',
})`
  ${({ theme }) => theme.fontTemplates.smallSemibold}
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  padding: 0.5rem 1.125rem 0.5rem 2.125rem;

  a {
    display: block;
    color: ${p => p.theme.colors.Primary};
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.4;
    }
  }
`;

export const DesktopName = styled.span<{ hasIcon?: boolean }>`
  ${truncate}
  flex: 1;
  margin-left: ${({ theme, hasIcon }) =>
    hasIcon ? theme.spacing.xs : theme.spacing.l};
`;

export const DesktopListItemSkeletonContainer = styled.div`
  margin-bottom: 0.5rem;
`;
