import React, { FC, useCallback, useEffect, useRef } from 'react';
import { ConferenceCallType } from '../../Conference.types';
import { ConferenceBannerType } from '../ConferencePage';
import { loadScript } from '../../../../shared/utils/script.utils';
import { appEnv } from '../../../../appEnv';
import _debounce from 'lodash/debounce';

const PARTICIPANT_JOIN_DEBOUNCE_ON_LIMIT_CHECK = 200;

interface ConferenceViewProps {
  callType: ConferenceCallType;
  jwtToken: string;
  roomName: string;
  limit: number;
  setBannerToDisplay: (_: ConferenceBannerType) => void;
  workspaceId?: string;
  chatId?: string;
  prejoinPageEnabled: boolean;
}

export const ConferenceView: FC<ConferenceViewProps> = ({
  callType,
  jwtToken,
  roomName,
  limit,
  setBannerToDisplay,
  workspaceId,
  chatId,
  prejoinPageEnabled,
}) => {
  const api = useRef<any>(null);

  // const [chatVisible, setChatVisible] = useState<boolean | null>(null);
  // const handleChatClick = () => {
  //   setChatVisible(prevState => !prevState);
  // };
  const checkConferenceLimit = useCallback(() => {
    const participants = api.current?.getParticipantsInfo();
    if (participants.length > limit) {
      setBannerToDisplay(ConferenceBannerType.LIMIT_REACHED);
    }
    api.current.removeEventListener('participantJoined');
  }, [limit, setBannerToDisplay]);

  useEffect(() => {
    loadScript(appEnv.JITSI_EXTERNAL_API_SCRIPT_LINK).then(() => {
      const options = {
        roomName: roomName as string,
        parentNode: document.querySelector('#meet') as HTMLElement,
        width: '100%',
        height: '100%',
        jwt: jwtToken,
        configOverwrite: {
          startWithVideoMuted: callType === ConferenceCallType.audio,
          ...(prejoinPageEnabled
            ? { prejoinConfig: { enabled: true }, requireDisplayName: true }
            : { prejoinConfig: { enabled: false }, readOnlyName: true }),
        },
      };
      api.current = new window.JitsiMeetExternalAPI(
        appEnv.JITSI_DOMAIN,
        options,
      ); // @ts-ignore

      api.current.addEventListener('readyToClose', () => {
        setBannerToDisplay(ConferenceBannerType.CLOSED);
      });

      api.current.addEventListener(
        'participantJoined',
        _debounce(
          checkConferenceLimit,
          PARTICIPANT_JOIN_DEBOUNCE_ON_LIMIT_CHECK,
        ),
      );
    });
  }, []); // eslint-disable-line

  return <div id="meet" style={{ height: '100%' }} />;

  // return (
  //   <>
  //     <StyledConference id="meet" isChatVisible={chatVisible} />
  //     <ConferenceChat
  //       workspaceId={workspaceId}
  //       chatVisible={chatVisible}
  //       setChatVisible={handleChatClick}
  //       chatId={chatId}
  //     />
  //   </>
  // );
};
