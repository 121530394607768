import type { AccountTrackingAction } from './AccountTracking.types';
import { EventCategoryType, sendTrackingEvent } from '../../Tracking';

export const sendAccountStatusTrackingEvent = (
  action: AccountTrackingAction,
) => {
  sendTrackingEvent({
    category: EventCategoryType.STATUS,
    action,
  });
};
