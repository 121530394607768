import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { ButtonSize } from '../../../../../shared/components/Button/Button';
import { Auth0Connection } from '../../../../Auth/Auth.service';

export const StyledExternalProvidersList = styled.div`
  margin-bottom: 28px;
`;

export const ExternalProviderButton = styled(ButtonWithIcon).attrs({
  fullWidth: true,
  size: ButtonSize.md,
  iconFirst: true,
  type: 'button',
})<{ externalProvider: Auth0Connection }>`
  ${({ externalProvider, theme: { colors } }) =>
    externalProvider === Auth0Connection.google &&
    css`
      background-color: ${colors.ExternalProviderGoogle};
      border: 1px solid ${colors.BorderLight};
      color: ${colors.OnSurfaceHighEmphasis};
    `}

  ${({ externalProvider, theme: { colors } }) =>
    externalProvider === Auth0Connection.facebook &&
    css`
      background-color: ${colors.ExternalProviderFacebook};
      box-shadow: 0 1px 1px rgba(223, 227, 232, 0.2);
      color: ${colors.PrimaryTextLight};
    `}

  ${({ externalProvider, theme: { colors } }) =>
    externalProvider === Auth0Connection.apple &&
    css`
      background-color: ${colors.ExternalProviderApple};
      box-shadow: 0 1px 1px rgba(223, 227, 232, 0.2);
      color: ${colors.PrimaryTextLight};
    `}

  ${({ externalProvider, theme: { colors } }) =>
    externalProvider === Auth0Connection.microsoft &&
    css`
      background-color: ${colors.ExternalProviderMicrosoft};
      border: 1px solid ${colors.BorderLight};
      box-sizing: border-box;
      box-shadow: 0 1px 1px rgba(223, 227, 232, 0.2);
      color: ${colors.OnSurfaceHighEmphasis};
    `}

  & ~ & {
    margin-top: 8px;
  }
`;

export const FacebookConfirmationBody = styled.div`
  p {
    line-height: 1.3;
  }

  p + p {
    margin-top: 1rem;
  }
`;
