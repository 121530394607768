import { useCallback, useState } from 'react';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { deleteAppFromDesktopRepository } from '../Data/Repository/AppStoreRepository';
import { captureException } from '@sentry/react';

export const useCaseDeleteAppFromDesktop = () => {
  const [deleteAppFromDesktopLoading, setDeleteAppFromDesktopLoading] =
    useState(false);
  const { workspace } = useCurrentWorkspace();

  const deleteAppFromDesktopUseCase = useCallback(
    async (desktopId: string, appId: string) => {
      setDeleteAppFromDesktopLoading(true);
      try {
        const response = await deleteAppFromDesktopRepository(
          workspace.id,
          desktopId,
          appId,
        );

        if (response) {
          return response;
        }
      } catch (e) {
        captureException(e);
      } finally {
        setDeleteAppFromDesktopLoading(false);
      }
    },
    [workspace.id],
  );

  return {
    deleteAppFromDesktopUseCase,
    deleteAppFromDesktopLoading,
  };
};
