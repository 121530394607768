import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AppSsoGroupApiType,
  AppStoreAppApiType,
} from '../../../../AppStore.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { extractNodes } from '../../../../../../shared/api/api.utils';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { AppStoreTranslation } from '../../../../i18n';
import {
  getImageSrcSet,
  getQueryParamsFrom,
} from '../../../../../../shared/utils/url.utils';
import { StyledSsoApp, StyledSsoStatus } from './SsoAppCard.styled';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../../shared/hooks';
import {
  AppLogo,
  CardCategory,
  CardData,
  CardTitle,
} from '../../../AppCard/AppCard.styled';

const APP_LOGO_SIZE = 32;

type AppStoreAppProps =
  | {
      ssoGroup: AppSsoGroupApiType;
    }
  | {
      app: AppStoreAppApiType;
      onConfigure: (app: AppStoreAppApiType) => Promise<void>;
    };

export const SsoAppCard: FC<AppStoreAppProps> = React.memo(props => {
  const ssoGroup = 'ssoGroup' in props ? props.ssoGroup : undefined;
  const app = 'app' in props ? props.app : undefined;
  const onConfigure = 'onConfigure' in props ? props.onConfigure : undefined;

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const workspaceConfig = useMemo(
    () =>
      ssoGroup
        ? extractNodes(ssoGroup.workspaceConfigs).find(
            ({ workspace }) => workspace.id === currentWorkspace.id,
          )
        : undefined,
    [ssoGroup, currentWorkspace.id],
  );

  const apps = useMemo(
    () => (ssoGroup ? ssoGroup.compatibleApps : []),
    [ssoGroup],
  );

  const category = useMemo(() => {
    if (app) {
      return app.category.name;
    }
    return apps.length === 1 ? apps[0].category?.name : undefined;
  }, [app, apps]);

  const logo = app?.logo ?? ssoGroup?.logo;

  const name = app?.name ?? ssoGroup?.name;

  const [processing, setProcessing] = useState(false);

  const handleClick = useCallback(async () => {
    if (ssoGroup) {
      return navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          ssoGroupId: ssoGroup._id,
        }),
      });
    }

    if (!app) {
      return;
    }

    setProcessing(true);
    await onConfigure?.(app);
    setProcessing(false);
  }, [app, navigate, onConfigure, queryParams, ssoGroup]);

  return (
    <StyledSsoApp data-testid="app">
      {logo ? (
        <AppLogo
          size={APP_LOGO_SIZE}
          src={logo.contentUrl}
          srcSet={getImageSrcSet(logo.contentUrl, APP_LOGO_SIZE)}
          data-testid="logo"
        />
      ) : null}

      <CardData data-testid="data">
        <CardTitle data-testid="name">{name}</CardTitle>
        {category ? (
          <CardCategory data-testid="category">{category}</CardCategory>
        ) : null}
        {workspaceConfig && (
          <StyledSsoStatus enabled={workspaceConfig.enabled}>
            {workspaceConfig.enabled ? (
              <FormattedMessage
                id={
                  apps.length > 1
                    ? AppStoreTranslation.ssoAppCardLabelsEnabledForApps
                    : AppStoreTranslation.ssoAppCardLabelsEnabled
                }
                values={{ appCount: apps.length }}
              />
            ) : (
              <FormattedMessage
                id={
                  apps.length > 1
                    ? AppStoreTranslation.ssoAppCardLabelsDisabledForApps
                    : AppStoreTranslation.ssoAppCardLabelsDisabled
                }
                values={{ appCount: apps.length }}
              />
            )}
          </StyledSsoStatus>
        )}
      </CardData>

      <Button
        data-testid="sso-app-card-configure-button"
        disabled={processing}
        mode={ButtonMode.secondary}
        size={ButtonSize.sm}
        onClick={handleClick}>
        {!workspaceConfig ? (
          <FormattedMessage
            id={AppStoreTranslation.ssoAppCardLabelsConfigure}
          />
        ) : (
          <FormattedMessage id={AppStoreTranslation.ssoAppCardLabelsEdit} />
        )}
      </Button>
    </StyledSsoApp>
  );
});
