import { formatDistance } from 'date-fns';
import { getShortId } from '../../../../../shared/utils/id';
import type { ChatMessagesTableType } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { CHAT_MESSAGE_THREAD_DOM_ID_PREFIX } from '../ChatMessage/ChatMessage.constants';
import { ChatMessageThreadVisibility } from './ChatMessageThread.types';

export const canLoadAllThreads = (
  message: ChatMessagesTableType,
  visibilityMode: ChatMessageThreadVisibility,
  canLoad: boolean,
): boolean =>
  (canLoad && visibilityMode === ChatMessageThreadVisibility.ShowAll) ||
  message.data.threadMessagesCount > 1 ||
  false;

export const getLastMessage = (messages: ChatMessagesTableType[] | null) =>
  messages?.length ? messages[messages.length - 1] : null;

export const getTimeDistance = (date: Date) =>
  formatDistance(date, Date.now(), {
    addSuffix: true,
  }).replace('about', '');

export const makeChatMessageThreadDomId = (id: string) =>
  `${CHAT_MESSAGE_THREAD_DOM_ID_PREFIX}${getShortId(id)}`;

export const getThreadMessageNode = (id: string) => document.getElementById(id);
