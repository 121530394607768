import styled from 'styled-components';

export const StyledRecentTitle = styled.div`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledSearchRecentWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  font-size: 0.875rem;
`;
