import countries from 'i18n-iso-countries';
import { PaymentPlan } from './Billing.types';
import { appEnv } from '../../appEnv';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
export const COUNTRIES = countries.getNames('en');

export const BILLING_SLUG = 'billing';
export const BILLING_PATHNAME = `/workspace/:workspaceId/${BILLING_SLUG}`;

export const countryVatOptions: { title: string; value: string }[] = [
  { title: 'United Arab Emirates TRN', value: 'ae_trn' },
  { title: 'Australian Business Number (AU ABN)', value: 'au_abn' },
  { title: 'Brazilian CNPJ number', value: 'br_cnpj' },
  { title: 'Brazilian CPF number', value: 'br_cpf' },
  { title: 'Canadian BN', value: 'ca_bn' },
  { title: 'Canadian QST number', value: 'ca_qst' },
  { title: 'Switzerland VAT number', value: 'ch_vat' },
  { title: 'Chilean TIN', value: 'cl_tin' },
  { title: 'Spanish CIF number', value: 'es_cif' },
  { title: 'European VAT number', value: 'eu_vat' },
  { title: 'Hong Kong BR number', value: 'hk_br' },
  { title: 'Indonesian NPWP number', value: 'id_npwp' },
  { title: 'Indian GST number', value: 'in_gst' },
  { title: 'Japanese Corporate Number', value: 'jp_cn' },
  { title: 'Korean BRN', value: 'kr_brn' },
  { title: 'Liechtensteinian UID number', value: 'li_uid' },
  { title: 'Mexican RFC number', value: 'mx_rfc' },
  { title: 'Malaysian FRP number', value: 'my_frp' },
  { title: 'Malaysian ITN', value: 'my_itn' },
  { title: 'Malaysian SST number', value: 'my_sst' },
  { title: 'Norwegian VAT number', value: 'no_vat' },
  { title: 'New Zealand GST number', value: 'nz_gst' },
  { title: 'Russian INN', value: 'ru_inn' },
  { title: 'Saudi Arabia VAT', value: 'sa_vat' },
  { title: 'Singaporean GST', value: 'sg_gst' },
  { title: 'Singaporean UEN', value: 'sg_uen' },
  { title: 'Thai VAT', value: 'th_vat' },
  { title: 'Taiwanese VAT', value: 'tw_vat' },
  { title: 'United States EIN', value: 'us_ein' },
  { title: 'South African VAT number', value: 'za_vat' },
];

export const ADMIN_BILLING_PATHNAME = '/workspace/:workspaceId/admin/billing';

export const PAYMENT_PLAN_COST = {
  [PaymentPlan.FREE]: appEnv.PAYMENT_PRICE_FREE,
  [PaymentPlan.PRO]: appEnv.PAYMENT_PRICE_PRO,
  [PaymentPlan.ENTERPRISE]: appEnv.PAYMENT_PRICE_ENTERPRISE,
};

export const GTM_SUCCESSFUL_UPGRADE_EVENT_SENT_STORAGE_KEY =
  '@desktop/gtm-successful-upgrade-sent';
