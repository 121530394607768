import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { getWebPushSubscriptionForAPI } from './WebPush.utils';
import { useUpdateWebPushAccountIdentityMutation } from './WebPush.hooks';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import { appEnv } from '../../appEnv';
import { WebPushSubscriptionApiType } from './WebPush.types';
import { NotificationPermissionModal } from './NotificationPermissionModal/NotificationPermissionModal';
import { useCookieStorage } from '../../shared/utils/storage';
import {
  HIDE_PERMISSION_MODAL_STORAGE_EXPIRES,
  HIDE_PERMISSION_MODAL_STORAGE_KEY,
} from './WebPush.constants';
import { useModalControls } from '../../shared/components/Modal/Modal.hooks';
import { SignUpStep } from '../Onboarding/Onboarding.types';
import { matchPath, useLocation } from 'react-router-dom';
import { ONBOARDING_END_PATHNAME } from '../Onboarding/Onboarding.constants';
import { useQueryParams } from '../../shared/hooks';
import {
  WorkspaceContext,
  WorkspaceContextType,
} from '../Workspace/Workspace.context';

enum NotificationPermissionTypes {
  Default = 'default',
}

export const WebPushProvider: FC<PropsWithChildren> = ({ children }) => {
  const { account } = useCurrentAccount();
  const { workspace, identityWorkspaces } = useContext(
    WorkspaceContext,
  ) as Partial<WorkspaceContextType>;
  const [hidePermissionModal, setHidePermissionModal] = useCookieStorage(
    HIDE_PERMISSION_MODAL_STORAGE_KEY,
    '',
    {
      expires: HIDE_PERMISSION_MODAL_STORAGE_EXPIRES,
    },
  );

  const subscriptioRequestedRef = useRef<boolean>(false);

  const { openOnboardingQuickTour } = useQueryParams();

  const notificationPermissionModal = useModalControls();

  const location = useLocation();

  const isNotificationAllowed =
    'serviceWorker' in navigator &&
    'Notification' in window &&
    Notification.permission === NotificationPermissionTypes.Default &&
    process.env.NODE_ENV === 'production' &&
    hidePermissionModal !== 'true' &&
    !matchPath(ONBOARDING_END_PATHNAME, location.pathname) &&
    !openOnboardingQuickTour &&
    account?.currentStep === SignUpStep.completed;

  const identityId: undefined | string = account?.identityId;
  const workspaceId: undefined | string = workspace?.id;

  const accountWorkspace = useMemo(() => {
    return identityWorkspaces?.find(
      identityWorkspace => identityWorkspace.workspace.id === workspaceId,
    );
  }, [identityWorkspaces, workspaceId]);

  const getPushSubscription = useCallback(() => {
    if (!('serviceWorker' in navigator)) {
      return Promise.reject(
        new Error('This browser does not support service workers.'),
      );
    }
    return navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
      return serviceWorkerRegistration.pushManager.getSubscription();
    });
  }, []);

  const createPushSubscription = useCallback(() => {
    if (!('serviceWorker' in navigator)) {
      return Promise.reject(
        new Error('This browser does not support service workers.'),
      );
    }
    return navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
      const options = {
        applicationServerKey: appEnv.WEB_PUSH_SERVER_KEY,
        userVisibleOnly: true,
      };

      return serviceWorkerRegistration.pushManager.subscribe(options);
    });
  }, []);

  const [updateWebPushAccountIdentityMutation] =
    useUpdateWebPushAccountIdentityMutation();

  const registerPushSubscription = useCallback(
    (pushSubscription: WebPushSubscriptionApiType) => {
      if (!identityId) {
        return Promise.reject('No identityId available');
      }

      return updateWebPushAccountIdentityMutation({
        variables: {
          input: {
            id: identityId,
            ...pushSubscription,
          },
        },
      });
    },
    [identityId, updateWebPushAccountIdentityMutation],
  );

  useEffect(() => {
    if (isNotificationAllowed) {
      notificationPermissionModal.open();
    }
  }, [isNotificationAllowed, notificationPermissionModal]);

  const requestSubscription = useCallback(() => {
    getPushSubscription()
      .then(pushSubscription => pushSubscription ?? createPushSubscription())
      .then(pushSubscription => {
        const pushSubscriptionForAPI =
          getWebPushSubscriptionForAPI(pushSubscription);
        if (!pushSubscriptionForAPI) {
          return Promise.reject('Failed to get Push subscription for API');
        }
        return registerPushSubscription(pushSubscriptionForAPI);
      })
      .catch(e => {
        console.error(e.message);
      });
  }, [createPushSubscription, getPushSubscription, registerPushSubscription]);

  const hideModalWithPermission = useCallback(() => {
    notificationPermissionModal.close();
    setHidePermissionModal('true');
  }, [notificationPermissionModal, setHidePermissionModal]);

  const requestNotificationPrompt = useCallback(() => {
    hideModalWithPermission();
    window.Notification.requestPermission().then(() => {
      requestSubscription();
    });
  }, [hideModalWithPermission, requestSubscription]);

  const webPushEndpoints = useMemo(
    () =>
      (account?.identity.webPushEndpoints ||
        accountWorkspace?.webPushEndpoints) ??
      [],
    [account?.identity.webPushEndpoints, accountWorkspace?.webPushEndpoints],
  );

  const requestAnotherSubscription = useCallback(() => {
    getPushSubscription()
      .then(pushSubscription => {
        if (
          pushSubscription &&
          !webPushEndpoints.includes(pushSubscription.endpoint)
        ) {
          requestSubscription();
        }
      })
      .catch(e => {
        console.error(e.message);
      });
  }, [getPushSubscription, requestSubscription, webPushEndpoints]);

  useEffect(() => {
    if (subscriptioRequestedRef.current) {
      return;
    }

    subscriptioRequestedRef.current = true;
    if (webPushEndpoints.length) {
      requestAnotherSubscription();
    }

    if (
      !accountWorkspace?.webPushEnabled &&
      !account?.identity.webPushEnabled &&
      !isNotificationAllowed
    ) {
      requestSubscription();
    }
  }, [
    account,
    accountWorkspace,
    isNotificationAllowed,
    requestAnotherSubscription,
    requestSubscription,
    webPushEndpoints.length,
    workspace,
  ]);

  return (
    <>
      {children}
      {isNotificationAllowed && notificationPermissionModal.visible && (
        <NotificationPermissionModal
          onSubmit={requestNotificationPrompt}
          onClose={hideModalWithPermission}
          onBackdropClick={hideModalWithPermission}
        />
      )}
    </>
  );
};
