import React, { FC, ReactNode } from 'react';
import { ItemBody, StyledListItem, Subtitle, Title } from './ListItem.styled';

interface ListItemProps {
  prepend: ReactNode;
  append?: ReactNode;
  title: string | ReactNode;
  subtitle: string | ReactNode;
}

export const ListItem: FC<ListItemProps> = ({
  prepend,
  append,
  title,
  subtitle,
}) => {
  return (
    <StyledListItem>
      {prepend}
      <ItemBody>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </ItemBody>
      {append}
    </StyledListItem>
  );
};
