import { StreamEventRequest, StreamVaultEvent } from '../../Mercure/General';
import { Dispatch, SetStateAction } from 'react';
import { VaultApiType } from '../Encryption.types';
import { removeVault } from '../utils/Vault.utils';

export const handleVaultMercureEvent = (
  event: StreamVaultEvent,
  setWorkspaceVaultsCache: Dispatch<
    SetStateAction<Record<string, VaultApiType[]>>
  >,
) => {
  if (event['@request'] === StreamEventRequest.REMOVE) {
    const vaultId = event['@iri'];
    const workspaceId = event.workspace.id;
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspaceId]: removeVault(cache[workspaceId], vaultId),
    }));
  }
};
