import { useCallback, useState } from 'react';
import { getLongId } from '../../../shared/utils/id';
import { getWorkspaceFavoritesRepository } from '../Data/Repository/WorkspaceFavoritesRepository';

export const useCaseGetWorkspaceFavorites = () => {
  const [favoritesLoading, setFavoritesLoading] = useState(false);

  const getWorkspaceFavoritesUseCase = useCallback(
    async (workspaceId: string) => {
      setFavoritesLoading(true);
      const response = await getWorkspaceFavoritesRepository(
        getLongId('workspaces', workspaceId),
      );

      setFavoritesLoading(false);

      if (response) {
        return response;
      }
    },
    [],
  );

  return {
    getWorkspaceFavoritesUseCase,
    favoritesLoading,
  };
};
