import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import type { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { DesktopApiType } from '../../Desktop/types/Desktop.types';

export const getAccountDesktopsRestApi = (
  accountId: string,
  workspaceId: string,
): Promise<RestApiClientResponse<DesktopApiType[]>> => {
  const restClient = getRestApiClient();
  const shortAccountId = getShortId(accountId);
  const shortWorkspaceId = getShortId(workspaceId);

  if (!restClient) {
    throw new Error('Rest client was not provided');
  }

  return restClient.fetch(
    `/workspace/${shortWorkspaceId}/accounts/${shortAccountId}/internal/desktops`,
  );
};
