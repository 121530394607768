import React, { FC } from 'react';
import { ConferenceTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  NonSafariIOSBannerControls,
  NonSafariIOSBannerMessage,
  StyledNonSafariIOSBanner,
} from './NonSafariIOSBanner.styled';

interface NonSafariIOSBannerProps {
  onProceed: () => void;
}

export const NonSafariIOSBanner: FC<NonSafariIOSBannerProps> = ({
  onProceed,
}) => {
  const canClose = !!window.opener;
  return (
    <StyledNonSafariIOSBanner>
      <FormattedMessage
        tagName={NonSafariIOSBannerMessage}
        id={ConferenceTranslation.nonSafariIOSBannerMessage}
      />
      <NonSafariIOSBannerControls>
        {canClose && (
          <Button
            size={ButtonSize.md}
            mode={ButtonMode.secondary}
            onClick={() => window.close()}>
            <FormattedMessage
              id={ConferenceTranslation.nonSafariIOSBannerCloseButton}
            />
          </Button>
        )}
        <Button
          size={ButtonSize.md}
          mode={ButtonMode.primary}
          onClick={onProceed}>
          <FormattedMessage
            id={ConferenceTranslation.nonSafariIOSBannerProceedButton}
          />
        </Button>
      </NonSafariIOSBannerControls>
    </StyledNonSafariIOSBanner>
  );
};
