import { Translation } from '../../domains/Intl/i18n/i18n.types';
import { showToastErrorMessage } from '../components/Toast';

export const isClipboardSupported =
  navigator.clipboard &&
  !!navigator.clipboard.writeText &&
  !!navigator.clipboard.readText;

const CLIPBOARD_ERROR_MESSAGE_TOAST_ID = 'clipboard-toast-id';

export const readClipboard = (errorMessage?: Translation) => {
  if (!isClipboardSupported) {
    return Promise.reject();
  }
  return navigator.clipboard.readText().catch(() => {
    if (errorMessage) {
      showToastErrorMessage(
        errorMessage,
        {},
        {
          toastId: CLIPBOARD_ERROR_MESSAGE_TOAST_ID,
        },
      );
    }
    return '';
  });
};
