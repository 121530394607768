import { SpecialMentionType } from '../../../domains/Chat/Chat.types';
import {
  AccountApiType,
  AvailabilityStatusType,
} from '../../../domains/User/User.types';

export interface WordPosition {
  start: number;
  end: number;
}

export enum WordPositionDirection {
  before = 'before',
  after = 'after',
}

export enum EditorEventType {
  blur = 'blur',
  focus = 'focus',
}

export interface MentionData extends AccountApiType {
  id: string;
  value: string;
  type?: string;
  avatar?: string;
  online?: AvailabilityStatusType;
}
export interface EditorMention {
  denotationChar: string;
  id: string;
  index: string;
  value: string;
}

export type EditorOpInsertType = {
  mention: EditorMention;
};

export interface SpecialMention {
  id: string;
  type: SpecialMentionType;
  value: SpecialMentionType;
}

export enum EditorSource {
  API = 'api',
  USER = 'user',
  SILENT = 'silent',
}

export enum BindigKeyCode {
  space = ' ',
  arrowUp = 38,
  arrowDown = 40,
  tab = 'Tab',
  enter = 'Enter',
  escape = 'Escape',
  delete = 46,
}

export enum BindigKeyType {
  space = 'space',
  arrowDown = 'arrowDown',
  arrowUp = 'arrowUp',
  tab = 'tab',
  enter = 'enter',
  escape = 'escape',
  delete = 'delete',
}

export interface WordBeforeArgs {
  word: string;
  position: WordPosition;
}
