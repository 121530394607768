import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { Checkbox } from './Checkbox';

const StyledFormCheckbox = styled.div<{ invalid: boolean }>`
  ${({ invalid, theme }) =>
    invalid &&
    css`
      input {
        border: 0.063rem solid ${theme.colors.CriticalLight};
        &:focus {
          border: 0.125rem solid ${theme.colors.CriticalLight};
        }
      }
    `}
`;

const FormCheckboxLabel = styled.label`
  display: block;
  margin: 0 0 0.25rem 0.375rem;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
`;

const ErrorMessage = styled.div`
  color: ${p => p.theme.colors.CriticalDark};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: 0.25rem;
`;

interface FormCheckboxProps {
  label?: string;
  className?: string;
}

export const FormCheckbox: FC<FieldProps & FormCheckboxProps> = ({
  field,
  form,
  label,
  className,
  ...props
}) => {
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );
  return (
    <StyledFormCheckbox className={className} invalid={!!errorIdToDisplay}>
      <CheckboxContainer>
        <Checkbox id={field.name} {...field} {...props} />
        {label && (
          <FormCheckboxLabel htmlFor={field.name}>{label}</FormCheckboxLabel>
        )}
      </CheckboxContainer>
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormCheckbox>
  );
};
