import styled from 'styled-components';

export const StyledShareVaultModal = styled.div`
  margin: 1rem;
`;

export const ImportantMessageContainer = styled.div`
  .important-message {
    margin: 0;
  }
`;
