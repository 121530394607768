import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { UseCaseFormValues, UseCaseType } from '../../Onboarding.types';
import { FormattedMessage } from 'react-intl';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../i18n';
import { BusinessIcon, PeopleIcon } from '../../../../shared/icons';
import {
  OnboardingCaseButton,
  OnboardingCaseFieldsGroup,
  UseCaseNextButton,
} from './UseCasePage.styled';

export interface UseCaseFormProps {
  onSubmit: (values: UseCaseFormValues) => void;
}

export const initialValues: UseCaseFormValues = {
  useCase: UseCaseType.business,
};

export const UseCaseForm: FC<UseCaseFormProps> = ({ onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form data-testid="use-case-form">
            <OnboardingCaseFieldsGroup>
              <Field
                name="useCase"
                type="radio"
                value={UseCaseType.business}
                component={() => (
                  <OnboardingCaseButton
                    icon={BusinessIcon}
                    iconFirst={true}
                    mode={
                      values.useCase === UseCaseType.business
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('useCase', UseCaseType.business)
                    }
                    fullWidth
                    data-testid="use-case-business">
                    <span data-testid="use-case-business-title">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingBusinessUseCaseTitle
                        }
                      />
                    </span>
                    <span data-testid="use-case-business-subtitle">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingBusinessUseCaseSubtitle
                        }
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />

              <Field
                name="useCase"
                type="radio"
                value={UseCaseType.personal}
                component={() => (
                  <OnboardingCaseButton
                    icon={PeopleIcon}
                    iconFirst={true}
                    mode={
                      values.useCase === UseCaseType.personal
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('useCase', UseCaseType.personal)
                    }
                    fullWidth
                    data-testid="use-case-personal">
                    <span data-testid="use-case-personal-title">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingPersonalUseCaseTitle
                        }
                      />
                    </span>
                    <span data-testid="use-case-personal-subtitle">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingPersonalUseCaseSubtitle
                        }
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />
            </OnboardingCaseFieldsGroup>

            <UseCaseNextButton
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              fullWidth
              type="submit"
              disabled={isSubmitting || !values.useCase}
              data-testid="use-case-next-button">
              <FormattedMessage
                id={OnboardingTranslation.onboardingUseCaseNextButton}
              />
            </UseCaseNextButton>
          </Form>
        );
      }}
    </Formik>
  );
};
