import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FolderEmptyMessage,
  StyledFolderEmptyState,
} from './FolderEmptyState.styled';
import { FolderTranslation } from '../i18n';

export const FolderEmptyState: FC = () => {
  return (
    <StyledFolderEmptyState data-testid="empty-state">
      <FolderEmptyMessage data-testid="no-links-message">
        <FormattedMessage id={FolderTranslation.emptyFolderMessage} />
      </FolderEmptyMessage>
    </StyledFolderEmptyState>
  );
};
