import styled from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';

export const ControlsWrapper = styled.div`
  text-align: right;
`;

export const AddContentButton = styled(Button).attrs({
  mode: ButtonMode.primary,
  size: ButtonSize.md,
})`
  margin-top: 1rem;
`;

styled(Button).attrs({
  mode: ButtonMode.secondary,
  size: ButtonSize.md,
})`
  margin-left: 0.5rem;
`;

export const TemplateListLabel = styled.div`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin-bottom: 0.25rem;
`;
