import styled from 'styled-components';

export const StyledEmojiAutocomplete = styled.div`
  width: 14rem;
  max-height: 20rem;
  overflow: hidden auto;
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.colors.BorderLight};
  padding: 0.25rem;
`;
