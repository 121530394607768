import styled from 'styled-components';

export const PlanTitleHeader = styled.div`
  font-size: 1.25rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-family: ${p => p.theme.fonts.Semibold};
  line-height: 1.5;
  display: flex;
  align-items: center;
`;

export const PlanDescription = styled.div`
  margin-top: 1rem;
  line-height: 1.2;
`;
