import styled, { css } from 'styled-components';

export const StyledLinkAccountPlanSelect = styled.form`
  margin-top: 24px;
  width: 100%;
`;

export const LinkAccountPlanSelectLabel = styled.label<{
  highlighted: boolean;
}>`
  display: flex;
  align-items: center;
  min-height: 64px;
  background: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  border: 2px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 22px 11px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.825rem;
  line-height: 1.3;
  cursor: pointer;

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      border: 2px solid ${theme.colors.Primary};
    `}

  input {
    margin-right: 12px;
  }

  & ~ & {
    margin-top: 24px;
  }
`;

export const LinkAccountPlanSelectLabelText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 100%;
`;

export const LinkAccountPlanSelectLabelPrice = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 1;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  text-transform: none;

  .cost {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 1.5rem;
    line-height: 1;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    text-transform: none;
  }
`;
export const LinkAccountPlanSelectLabelPriceCaption = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;
