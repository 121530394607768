import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BillingTranslation } from '../../../i18n';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../Admin';
import { PaymentMethodsTable } from '../../../BillingTables';
import {
  useAddPaymentMethodPaymentCustomerMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useMakePaymentMethodDefaultPaymentCustomerMutation,
} from '../../../Billing.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { AddPaymentMethodModal } from '../../../BillingModals/AddPaymentMethodModal';
import { useModalControls } from '../../../../../shared/components/Modal/Modal.hooks';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { stripeErrorCodeToMessage } from '../../../Billing.utils';
import { FormikHelpers } from 'formik';
import { AddPaymentMethodFormValues } from '../../../BillingForms/AddPaymentMethodForm';
import { GET_PAYMENT_METHODS } from '../../../Billing.queries';
import { useConfirm } from '../../../../../shared/components/Modal';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';

interface PaymentMethodsProps {
  id: string | null;
}

export const PaymentMethods: FC<PaymentMethodsProps> = ({ id }) => {
  const {
    permissions: { canEditBillingPage },
  } = useCurrentWorkspacePermissions();
  const addPaymentMethodModal = useModalControls();
  const { formatMessage } = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const { askConfirmation } = useConfirm();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [
    getPaymentMethods,
    {
      data: paymentMethodsData,
      loading: paymentMethodsLoading,
      refetch: paymentRefetch,
    },
  ] = useGetPaymentMethodsQuery({
    variables: {
      paymentCustomer: id as string,
    },
    fetchPolicy: 'cache-and-network',
  });

  useMemo(() => {
    if (id) {
      getPaymentMethods();
    }
  }, [getPaymentMethods, id]);

  const [deletePaymentMethodMutation] = useDeletePaymentMethodMutation();
  const handleDeletePaymentMethod = useCallback(
    (paymentMethodId: string) => {
      askConfirmation(
        formatMessage({
          id: BillingTranslation.adminPaymentMethodDeleteConfirmation,
        }),
        formatMessage({
          id: BillingTranslation.adminPaymentMethodDeleteConfirmationHeader,
        }),
        {
          width: 360,
          dangerConfirm: true,
          confirmButtonText: formatMessage({
            id: BillingTranslation.adminPaymentMethodDeleteConfirmationButtonText,
          }),
        },
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        setIsUpdating(true);
        deletePaymentMethodMutation({
          variables: {
            input: {
              id: id as string,
              paymentMethodId: paymentMethodId,
            },
          },
          refetchQueries: [
            {
              query: GET_PAYMENT_METHODS,
              variables: {
                paymentCustomer: id,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
          .then(() => {
            showToastSuccessMessage(
              BillingTranslation.adminPaymentMethodDeleteSuccess,
            );
            setIsUpdating(false);
          })
          .catch(err => {
            showToastGraphQLErrors(err.graphQLErrors);
            setIsUpdating(false);
          });
      });
    },
    [askConfirmation, deletePaymentMethodMutation, formatMessage, id],
  );

  const [makePaymentMethodDefaultPaymentCustomer] =
    useMakePaymentMethodDefaultPaymentCustomerMutation();
  const handleUpdateDefaultPaymentMethod = useCallback(
    (
      values: {
        paymentMethodId: string;
      },
      formikHelpers: FormikHelpers<any>,
    ) => {
      const { setSubmitting } = formikHelpers;
      setIsUpdating(true);
      makePaymentMethodDefaultPaymentCustomer({
        variables: {
          input: {
            id: id!,
            ...values,
          },
        },
        refetchQueries: [
          {
            query: GET_PAYMENT_METHODS,
            variables: {
              paymentCustomer: id,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(() => {
          showToastSuccessMessage(
            BillingTranslation.adminPaymentMethodChangeSuccess,
          );
          setSubmitting(false);
          setIsUpdating(false);
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
          setSubmitting(false);
          setIsUpdating(false);
        });
    },
    [makePaymentMethodDefaultPaymentCustomer, id],
  );

  const [addPaymentMethodPaymentCustomer] =
    useAddPaymentMethodPaymentCustomerMutation();
  const addPaymentMethod = useCallback(
    (
      { paymentMethod }: { paymentMethod: { id: string } },
      values: AddPaymentMethodFormValues,
      setSubmitting: (value: boolean) => void,
    ) => {
      addPaymentMethodPaymentCustomer({
        variables: {
          input: {
            id: id!,
            paymentMethodId: paymentMethod.id,
            ...values,
          },
        },
      })
        .then(() => {
          if (paymentRefetch) {
            paymentRefetch();
          }
          setSubmitting(false);
          addPaymentMethodModal.close();
          showToastSuccessMessage(
            BillingTranslation.adminPaymentMethodChangeSuccess,
          );
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
          setSubmitting(false);
        });
    },
    [
      addPaymentMethodModal,
      addPaymentMethodPaymentCustomer,
      id,
      paymentRefetch,
    ],
  );

  const handleAddPaymentMethod = (
    values: AddPaymentMethodFormValues,
    formikHelpers: FormikHelpers<AddPaymentMethodFormValues>,
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const { setSubmitting } = formikHelpers;

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) || {
          token: 'TODO: fix this type',
        },
      })
      .then(result => {
        const { error } = result;
        if (error) {
          showToastErrorMessage(stripeErrorCodeToMessage(error.code));
          setSubmitting(false);
        } else {
          addPaymentMethod(result, values, setSubmitting);
        }
      });
  };

  return (
    <>
      <Section data-testid="section-payment-methods">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage
              id={BillingTranslation.adminPaymentMethodsTitle}
            />
          </SectionHeaderTitle>
          {canEditBillingPage && (
            <SectionHeaderButton
              onClick={addPaymentMethodModal.open}
              disabled={paymentMethodsLoading}
              data-testid="add">
              <FormattedMessage
                id={BillingTranslation.adminPaymentMethodsAddButton}
              />
            </SectionHeaderButton>
          )}
        </SectionHeader>
        <SectionBody data-testid="body">
          {!paymentMethodsLoading && paymentMethodsData ? (
            <PaymentMethodsTable
              tableData={paymentMethodsData.getPaymentMethods}
              onUpdate={handleUpdateDefaultPaymentMethod}
              isUpdating={isUpdating}
              handleDeletePaymentMethod={handleDeletePaymentMethod}
            />
          ) : (
            <Spinner />
          )}
        </SectionBody>
      </Section>
      <AddPaymentMethodModal
        visible={addPaymentMethodModal.visible}
        onRequestClose={addPaymentMethodModal.close}
        onSubmit={handleAddPaymentMethod}
      />
    </>
  );
};
