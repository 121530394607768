import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { WorkerQueueContext } from './WorkerQueue.context';
import {
  GenericMercureEvent,
  QueueItem,
  WorkerQueueContextType,
} from './WorkerQueue.types';

export const WorkerQueueProvider: FC<PropsWithChildren> = ({ children }) => {
  // We have special copyPlugin rule in the webpack.config for this file
  const [worker] = useState(
    // @ts-ignore
    () => new Worker(new URL('./WorkerQueue.worker.js', import.meta.url)),
  );

  const addToQueue: WorkerQueueContextType['addToQueue'] = useCallback(
    (tag, event) => {
      worker.postMessage({
        type: 'event',
        tag,
        event,
      });
    },
    [worker],
  );

  const handlerMapRef = useRef<{
    [tag: string]:
      | (<T extends GenericMercureEvent>(event: T) => void)
      | undefined;
  }>({});

  const onMessage: WorkerQueueContextType['onMessage'] = useCallback(
    (tag, handler) => {
      handlerMapRef.current[tag] = handler as <T extends GenericMercureEvent>(
        event: T,
      ) => void;
    },
    [],
  );

  useEffect(() => {
    worker.addEventListener('message', (message: MessageEvent<QueueItem>) => {
      handlerMapRef.current[message.data.tag]?.(message.data.event);
    });
    return () => {
      worker.terminate();
    };
  }, [worker]);

  return (
    <WorkerQueueContext.Provider value={{ addToQueue, onMessage }}>
      {children}
    </WorkerQueueContext.Provider>
  );
};
