import { gql } from '@apollo/client';

export const APPLY_TEMPLATES_TO_DESKTOP = gql`
  mutation ApplyTemplatesToDesktop($input: applyTemplatesToDesktopInput!) {
    applyTemplatesToDesktop(input: $input) {
      clientMutationId
    }
  }
`;

export interface ApplyTemplatesToDesktopVariables {
  input: {
    id: string;
    templates: string[];
  };
}

export interface ApplyTemplatesToDesktopResponse {
  clientMutationId: string;
}
