import { useContext } from 'react';
import { InboxContext } from './Inbox.context';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_MENTIONS,
  GET_THREADS,
  GetMentionsResponse,
  GetMentionsVariables,
  GetThreadsResponse,
  GetThreadsVariables,
} from './Inbox.queries';
import {
  MARK_ALL_MENTION_NOTIFICATIONS_AS_READ,
  MARK_ALL_THREAD_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  MarkAllMentionNotificationsAsReadResponse,
  MarkAllMentionNotificationsAsReadVariables,
  MarkAllThreadNotificationsAsReadResponse,
  MarkAllThreadNotificationsAsReadVariables,
  MarkNotificationAsReadResponse,
  MarkNotificationAsReadVariables,
} from './Inbox.mutations';

export const useInbox = () => useContext(InboxContext);

export const useGetMentionsQuery = (
  options?: QueryHookOptions<GetMentionsResponse, GetMentionsVariables>,
) => useQuery<GetMentionsResponse, GetMentionsVariables>(GET_MENTIONS, options);

export const useGetThreadsQuery = (
  options?: QueryHookOptions<GetThreadsResponse, GetThreadsVariables>,
) => useQuery<GetThreadsResponse, GetThreadsVariables>(GET_THREADS, options);

export const useMarkAllMentionsAsReadMutation = () =>
  useMutation<
    MarkAllMentionNotificationsAsReadResponse,
    MarkAllMentionNotificationsAsReadVariables
  >(MARK_ALL_MENTION_NOTIFICATIONS_AS_READ);

export const useMarkAllThreadsAsReadMutation = () =>
  useMutation<
    MarkAllThreadNotificationsAsReadResponse,
    MarkAllThreadNotificationsAsReadVariables
  >(MARK_ALL_THREAD_NOTIFICATIONS_AS_READ);

export const useMarkNotificationAsReadMutation = () =>
  useMutation<MarkNotificationAsReadResponse, MarkNotificationAsReadVariables>(
    MARK_NOTIFICATION_AS_READ,
  );
