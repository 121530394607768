import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { StyledAddPaymentMethodModal } from './AddPaymentMethodModal.styled';
import { BillingTranslation } from '../../i18n';
import {
  AddPaymentMethodForm,
  AddPaymentMethodFormValues,
} from '../../BillingForms/AddPaymentMethodForm';
import { FormikHelpers } from 'formik';

interface AddPaymentMethodModalProps extends ModalProps {
  subtitle?: string;
  onSubmit: (
    values: AddPaymentMethodFormValues,
    formikHelpers: FormikHelpers<AddPaymentMethodFormValues>,
  ) => void;
}

export const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = ({
  onRequestClose,
  subtitle,
  onSubmit,
  ...props
}) => {
  return (
    <Modal width={400} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={BillingTranslation.adminPaymentMethodModalTitle}
            />
          </ModalHeaderTitle>
        </div>
      </ModalHeader>
      <StyledAddPaymentMethodModal>
        <AddPaymentMethodForm onSubmit={onSubmit} />
      </StyledAddPaymentMethodModal>
    </Modal>
  );
};
