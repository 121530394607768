import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { TooltipPlace } from '../../../../../../../../shared/components/Tooltip';
import { IconProps } from '../../../../../../../../shared/icons/icon.types';

interface TitleBarButtonProps {
  icon: FC<IconProps> | null;
  identifier: string;
  tooltipText?: string | null;
  onClick?: () => void;
  fullsizeIcon?: boolean;
  noMargin?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

const StyledTitleBarButton = styled.button<{
  fullsizeIcon?: boolean;
  noMargin?: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  appearance: none;
  border: 0;
  background: transparent;
  width: 44px;
  height: 44px;
  padding: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus,
  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }

  svg {
    pointer-events: none;
  }

  ${({ fullsizeIcon }) =>
    fullsizeIcon &&
    css`
      padding: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    `};

  ${({ theme, fullsizeIcon, noMargin }) => theme.breakpoints.md`
    width: 32px;
    height: 32px;

    ${
      fullsizeIcon &&
      css`
        padding: 0;

        svg {
          width: 24px;
          height: 24px;
        }
      `
    };
  `};
`;

export const TitleBarButton = React.forwardRef<
  HTMLButtonElement,
  TitleBarButtonProps
>(
  (
    {
      icon: Icon,
      identifier,
      tooltipText,
      children,
      disabled = false,
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <>
        <StyledTitleBarButton
          disabled={disabled}
          ref={ref}
          {...buttonProps}
          data-tooltip-id="global-tooltip"
          data-tooltip-place={TooltipPlace.bottom}
          data-tooltip-content={!!tooltipText ? tooltipText : ''}>
          {Icon && <Icon />}
        </StyledTitleBarButton>
      </>
    );
  },
);
