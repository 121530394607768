import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useCalcDimension } from './DocumentPreview.hooks';
import { EmbedPreview } from './EmbedPreview/EmbedPreview';
import { DocViewerContainer } from './DocumentPreview.styled';
import { LinkApiType, LinkDocumentMetaData } from '../../Link/Link.types';
import { ChatMessageAssetApiType } from '../../Chat/Chat.types';

interface DocumentPreviewProps {
  link?: LinkApiType;
  messageAsset?: ChatMessageAssetApiType;
  height?: string | number;
  width?: string | number;
  inModal?: boolean;
  fullHeight?: boolean;
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({
  link,
  messageAsset,
  width = '100%',
  height = '100%',
  inModal = false,
}) => {
  const calculatedWidth = useCalcDimension(width);
  const calculatedHeight = useCalcDimension(height);
  const [url, setUrl] = useState<string>();

  const prepareUrl = useCallback(() => {
    const metaUrl =
      (link?.data.metadata as LinkDocumentMetaData)?.preview_url ||
      (messageAsset?.metadata as LinkDocumentMetaData)?.preview_url;

    setUrl(metaUrl);
  }, [link?.data.metadata, messageAsset?.metadata]);

  useEffect(() => {
    if (!url) {
      prepareUrl();
    }
  }, [prepareUrl, url]);

  const title = useMemo(
    () => link?.data.title || messageAsset?.filename || '',
    [link?.data.title, messageAsset?.filename],
  );

  return (
    <DocViewerContainer inModal={inModal}>
      <EmbedPreview
        title={title}
        url={url ?? ''}
        width={calculatedWidth}
        height={calculatedHeight}
        isLink={!!link}
      />
    </DocViewerContainer>
  );
};
