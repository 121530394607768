import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../../../i18n';
import { PaymentCouponApiType, PaymentPlan } from '../../../../Billing.types';
import { PlanTitleHeader } from './PlanTitle.styled';
import { PaymentCoupon } from '../PaymentCoupon';
import { appEnv } from '../../../../../../appEnv';

interface PlanTitleProps {
  plan: PaymentPlan;
  coupon?: PaymentCouponApiType;
}

export const PlanTitle: FC<PlanTitleProps> = ({ plan, coupon }) => {
  switch (plan) {
    case PaymentPlan.FREE:
      return (
        <PlanTitleHeader data-testid="title">
          <FormattedMessage id={BillingTranslation.planFreeTitle} />
        </PlanTitleHeader>
      );

    case PaymentPlan.PRO:
      return (
        <PlanTitleHeader data-testid="title">
          <FormattedMessage id={BillingTranslation.planProTitle} />
          {coupon && <PaymentCoupon coupon={coupon} />}
        </PlanTitleHeader>
      );

    case PaymentPlan.ENTERPRISE:
      if (appEnv.ALLOW_ENTERPRISE_REGISTRATION !== 'true') {
        return null;
      }

      return (
        <PlanTitleHeader data-testid="title">
          <FormattedMessage id={BillingTranslation.planEnterpriseTitle} />
          {coupon && <PaymentCoupon coupon={coupon} />}
        </PlanTitleHeader>
      );

    default:
      return null;
  }
};
