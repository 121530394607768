import { useCallback, useState } from 'react';
import { getLongId } from '../../../shared/utils/id';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { getLinkRepository } from '../Data/Repository/LinkRepository';

export const useCaseGetLink = () => {
  const { workspace } = useCurrentWorkspace();
  const [linkLoading, setLinkLoading] = useState(false);

  const getLinkUseCase = useCallback(
    async (linkId: string) => {
      setLinkLoading(true);
      const response = await getLinkRepository(
        getLongId('workspaces', workspace.id),
        getLongId('links', linkId),
      );

      if (response) {
        setLinkLoading(false);
        return response;
      }
    },
    [workspace.id],
  );

  return {
    getLinkUseCase,
    linkLoading,
  };
};
