import styled from 'styled-components';

export const StyledNavigation = styled.div`
  padding: 16px;
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 8px;
`;

export const NavigationItem = styled.div`
  display: block;
  font-style: normal;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  cursor: pointer;

  &.active {
    font-family: ${p => p.theme.fonts.Semibold};
    color: ${p => p.theme.colors.Primary};
  }

  &:hover {
    text-decoration: underline;
  }

  & ~ div {
    margin-top: 8px;
  }
`;
