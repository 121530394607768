export const hashText = async (
  text: string,
  salt: string,
  iterations: number = 64000,
  hash: string = 'SHA-256',
): Promise<string> => {
  const saltUint8 = new TextEncoder().encode(salt);
  const pwUtf8 = new TextEncoder().encode(text);

  // create pw key
  const pwKey = await crypto.subtle.importKey('raw', pwUtf8, 'PBKDF2', false, [
    'deriveBits',
  ]);

  // derive key
  const keyBuffer = await crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      salt: saltUint8,
      hash,
      iterations,
    },
    pwKey,
    256,
  );

  // convert to hex
  const uint8Array = new Uint8Array(keyBuffer);
  return Array.from(uint8Array)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
};

/*
 * PASSWORDS HASHING
 * */

export const hashAuthPassword = (
  password: string,
  email: string,
): Promise<string> => {
  return hashText(password, email.toLowerCase(), 24000, 'SHA-256');
};

export const hashMasterPassword = (
  password: string,
  email: string,
): Promise<string> => {
  return hashText(password, email.toLowerCase(), 24000, 'SHA-512');
};
