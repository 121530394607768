import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const WebIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.543 7.25H4.276C4.42 5.176 5.142 3.494 5.856 2.302C5.976 2.105 6.093 1.921 6.209 1.75C4.97549 2.10434 3.87434 2.81558 3.04408 3.79423C2.21383 4.77289 1.69156 5.97524 1.543 7.25ZM4.276 8.75H1.543C1.69156 10.0248 2.21383 11.2271 3.04408 12.2058C3.87434 13.1844 4.97549 13.8957 6.209 14.25C6.08628 14.0695 5.96889 13.8854 5.857 13.698C5.142 12.506 4.42 10.824 4.276 8.75ZM5.78 8.75H10.22C10.0934 10.2287 9.62691 11.6582 8.857 12.927C8.551 13.437 8.245 13.846 8 14.142C7.68451 13.7589 7.39805 13.3527 7.143 12.927C6.37309 11.6582 5.90664 10.2287 5.78 8.75ZM10.22 7.25H5.78C5.90664 5.77129 6.37309 4.34181 7.143 3.073C7.449 2.563 7.755 2.154 8 1.858C8.245 2.154 8.55 2.563 8.857 3.073C9.62687 4.34182 10.0933 5.7713 10.22 7.25ZM11.724 8.75C11.58 10.824 10.858 12.506 10.144 13.698C10.024 13.895 9.907 14.079 9.791 14.25C11.0245 13.8957 12.1257 13.1844 12.9559 12.2058C13.7862 11.2271 14.3084 10.0248 14.457 8.75H11.724ZM14.457 7.25H11.724C11.58 5.176 10.858 3.494 10.144 2.302C10.0315 2.11475 9.91378 1.93068 9.791 1.75C11.0245 2.10434 12.1257 2.81558 12.9559 3.79423C13.7862 4.77289 14.3084 5.97524 14.457 7.25ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0V0Z"
        fill="currentColor"
      />
    </svg>
  );
});
