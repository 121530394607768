import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectTemplatesForm } from './SelectTemplatesForm';
import { extractNodes } from '../../../shared/api/api.utils';
import { Spinner } from '../../../shared/components/Spinner';
import { TemplateApiType } from '../Template.types';
import {
  useApplyTemplatesToDesktopMutation,
  useGetTemplatesQuery,
} from '../Template.hooks';
import { FormattedMessage } from 'react-intl';
import { Translation } from '../../Intl/i18n/i18n.types';
import { AddContentButton, ControlsWrapper } from './ApplyTemplates.styled';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { SpinnerFullscreen } from '../../../shared/components/SpinnerFullscreen';
import { SelectAppsForm } from './SelectAppsForm';
import { useAddAppsDesktopMutation } from '../../ChromeExtension/ChromeExtension.hooks';
import { GraphQLError } from 'graphql';
import { useDesktopsRepository } from '../../Desktop/data/Desktop/Desktop.repositories';

interface ApplyTemplatesProps {
  applyButtonText: Translation;
  onTemplateSelect: (templates: TemplateApiType[]) => void;
  onTemplatesApply: (
    setTemplatesCookie?: boolean,
    templates?: TemplateApiType[],
  ) => void;
}

export const ApplyTemplates: FC<ApplyTemplatesProps> = ({
  applyButtonText,
  onTemplateSelect,
  onTemplatesApply,
}) => {
  const [isApplying, setIsApplying] = useState(false);

  const { workspace } = useCurrentWorkspace();

  const { desktops, loading: desktopsLoading } = useDesktopsRepository({
    variables: {
      filterOnlyAccessible: true,
    },
  });

  const generalDesktopId = desktops[0]?.id;

  const { data, loading } = useGetTemplatesQuery({
    fetchPolicy: 'no-cache',
  });

  const templates = useMemo(() => {
    return extractNodes(data?.templates);
  }, [data]);

  const [selectedTemplates, setSelectedTemplates] = useState<TemplateApiType[]>(
    [],
  );
  const [selectedApps, setSelectedApps] = useState<Set<string>>(new Set());

  useEffect(() => {
    onTemplateSelect(selectedTemplates);
  }, [selectedTemplates, onTemplateSelect]);

  const [applyTemplatesToDesktop] = useApplyTemplatesToDesktopMutation();
  const [addAppsDesktop] = useAddAppsDesktopMutation();

  const applyTemplates = useCallback(
    (
      selectedTemplates: TemplateApiType[],
      setTemplatesCookie: boolean = false,
      selectedAppsTemplate?: Set<string>,
    ) => {
      setIsApplying(true);

      let selectedAppsData: string[] = [];

      if (selectedAppsTemplate && selectedAppsTemplate.size) {
        selectedAppsData = Array.from(selectedAppsTemplate);
      }

      return applyTemplatesToDesktop({
        variables: {
          input: {
            id: generalDesktopId,
            templates: selectedTemplates.map(template => template.id),
          },
        },
      })
        .then(async () => {
          if (selectedAppsData.length) {
            await addAppsDesktop({
              variables: {
                input: {
                  id: generalDesktopId,
                  apps: selectedAppsData,
                },
              },
            }).catch(e => {
              e.catch((e: { graphQLErrors: GraphQLError[] }) =>
                showToastGraphQLErrors(e.graphQLErrors),
              );

              return;
            });
          }

          return onTemplatesApply(setTemplatesCookie, selectedTemplates);
        })
        .catch(e => {
          e.catch((e: { graphQLErrors: GraphQLError[] }) =>
            showToastGraphQLErrors(e.graphQLErrors),
          );
        })
        .finally(() => setIsApplying(false));
    },
    [
      generalDesktopId,
      applyTemplatesToDesktop,
      addAppsDesktop,
      onTemplatesApply,
    ],
  );

  const handleApplyTemplates = useCallback(() => {
    const defaultTemplate = templates.filter(
      template => template.appSet === null,
    );

    applyTemplates(
      selectedTemplates.length ? selectedTemplates : defaultTemplate,
      selectedTemplates ? true : false,
      selectedApps,
    );
  }, [templates, selectedTemplates, applyTemplates, selectedApps]);

  const handleSelectApp = useCallback(
    (appId: string) => {
      const newSelectedApps = new Set(selectedApps);

      if (selectedApps.has(appId)) {
        newSelectedApps.delete(appId);
        setSelectedApps(newSelectedApps);
      } else {
        newSelectedApps.add(appId);
        setSelectedApps(newSelectedApps);
      }
    },
    [setSelectedApps, selectedApps],
  );

  if (loading || desktopsLoading) {
    return <Spinner containerHeight={180} />;
  }

  return (
    <>
      <SelectTemplatesForm
        templates={templates}
        onTemplateSelect={setSelectedTemplates}
      />

      <SelectAppsForm
        selectedApps={selectedApps}
        onSelectApp={handleSelectApp}
      />

      <ControlsWrapper>
        <AddContentButton
          onClick={handleApplyTemplates}
          disabled={isApplying}
          data-testid="apply-button">
          <FormattedMessage id={applyButtonText} />
        </AddContentButton>
      </ControlsWrapper>

      {isApplying && <SpinnerFullscreen />}
    </>
  );
};
