import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InputPlaceholder,
  InputWrapper,
  MessageFormContainer,
  StyledMessageForm,
  StyledMessageInputContainer,
} from './MessageForm.styled';
import { ChatTranslation } from '../../../i18n';
import {
  isImageMimeType,
  useCreateFileAssetMutation,
  useCreateImageAssetMutation,
} from '../../../../Asset';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../Workspace/Workspace.hooks';
import { makeAttachmentId, useAttachments } from '../../../../Asset/Attachment';
import {
  showToastGraphQLErrors,
  showToastInfoMessage,
} from '../../../../../shared/components/Toast';
import { AttachmentsContainer } from './AttachmentsContainer';
import { useChatConversationTitle } from '../../../Chat.hooks';
import {
  ERROR_CODE,
  getErrorCodeFrom,
} from '../../../../../shared/utils/error.utils';
import { useConfirm } from '../../../../../shared/components/Modal';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { getAccountName, isAccountActive } from '../../../../User/User.utils';
import {
  ChatConversationInternalType,
  ChatMessageAssetApiType,
  ConversationType,
} from '../../../Chat.types';
import { MessageFormType } from './MessageForm.types';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { IsTypingContainer, usePublishTypingPing } from './isTypingContainer';
import { ConversationDropZone } from '../ConversationDropZone';
import { useAccountsContext } from '../../../../Account';
import {
  chatMessageAssetApiTypeToAttachmentType,
  chatMessageAssetToApiType,
  getAssetIds,
} from './MessageForm.utils';
import {
  convertToApiFormat,
  Editor,
  EditorControls,
  MentionData,
  replaceEmoticons,
  SPECIAL_MENTION_CHANNEL,
  SPECIAL_MENTION_HERE,
} from '../../../../../shared/components/Editor';
import { IsTypingEventType } from '../../../../Mercure/Ping/PingMercure.types';
import { useMobile } from '../../../../../shared/hooks';
import { ApolloError, FetchResult } from '@apollo/client';
import { AccountWithCountsApiType } from '../../../../User/User.types';
import {
  CreateFileAssetResponse,
  CreateImageAssetResponse,
} from '../../../../Asset/Asset.mutations';
import { useChatMessageRepository } from '../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { ConversationDraftContext } from '../../ConversationDraft/ConversationDraft.context';
import { GlobalTranslation } from '../../../../Intl/i18n';
import { isFileSizeWithinLimit } from '../../../../Image/ImageUpload/ImageUpload.utils';
import { MessageFormFooter } from './MessageFormFooter';

const TYPING_DEBOUNCE_TIME = 3000;
const TYPING_TIME_EACH_FIVE_SECONDS = 5000;

export interface MessageFormProps {
  formId: string;
  conversation: ChatConversationInternalType;
  onSubmit: (
    value: string,
    parentChatMessageId?: string | null,
    assets?: ChatMessageAssetApiType[],
  ) => Promise<void>;
  canSaveDraft: boolean;
  dragAttachments?: File[];
  clearDropZoneState?: () => void;
  onCancel?: () => void;
  formType?: MessageFormType;
  buttonsLables?: {
    cancelLabel: ReactNode;
    submitLabel: ReactNode;
  };
  allowAttachments?: boolean;
  draftLoaded?: boolean;
  linkDesktopId?: string;
  formDisabled?: boolean;
  typingDisabled?: boolean;
}

export interface MessageFormPropsControls {
  focus: (charIndex?: number) => void;
}

export const MessageForm = React.forwardRef<
  MessageFormPropsControls,
  MessageFormProps
>(
  (
    {
      formId,
      conversation,
      onSubmit,
      dragAttachments,
      canSaveDraft,
      clearDropZoneState,
      onCancel,
      formType = MessageFormType.NewMessage,
      buttonsLables,
      allowAttachments = true,
      draftLoaded,
      linkDesktopId,
      formDisabled = false,
      typingDisabled = false,
    },
    ref,
  ) => {
    const { getDraftMessage, setDraftMessage, resetDraftMessage } = useContext(
      ConversationDraftContext,
    );

    const intl = useIntl();
    const { askConfirmation } = useConfirm();
    const isFirstRender = useRef(false);

    const draftMessage = getDraftMessage(formId);
    const draftAttachments = chatMessageAssetApiTypeToAttachmentType(
      draftMessage?.context.assets,
    );

    const value = draftMessage?.message || '';

    const { workspace } = useCurrentWorkspace();
    const conversationTitle = useChatConversationTitle(conversation) ?? '';
    const { account: currentAccount } = useCurrentWorkspaceAccount();

    const workspaceId = workspace.id;
    const desktopId = conversation?.desktopId || linkDesktopId;

    const isUserTyping = useRef(false);
    const isTypingRef =
      useRef<
        (chatConversation: string, typingEventType: IsTypingEventType) => void
      >();
    const isMobile = useMobile();

    isTypingRef.current = usePublishTypingPing();

    const [debouncedTypingValue] = useDebounce(value, TYPING_DEBOUNCE_TIME);

    const [droppedAttachments, onFilesDropped] = useState<File[] | undefined>();

    const clipboardFileProcessing = useRef(false);

    const isUserTypingTimeInterval = useRef<ReturnType<typeof setInterval>>();

    const privateChatUserAccountId = useMemo(() => {
      if (conversation && conversation.type === ConversationType.private) {
        const userId = conversation.userIds.filter(
          item => item !== currentAccount.id,
        );
        return userId[0] || false;
      }
    }, [conversation, currentAccount.id]);

    const { accountsWithAvailability } = useAccountsContext();

    const isPrivateChatUserSuspended =
      privateChatUserAccountId &&
      accountsWithAvailability[privateChatUserAccountId]
        ? !isAccountActive(
            accountsWithAvailability[privateChatUserAccountId],
            workspaceId,
          )
        : false;

    const [
      { attachments },
      {
        uploadAttachmentStarted,
        uploadAttachmentSuccess,
        uploadAttachmentFailed,
        removeAttachment,
        resetAttachments,
      },
    ] = useAttachments();

    const hasAttachments = attachments.length > 0;

    const attachmentsUploading = useMemo(
      () => attachments.filter(({ file, asset }) => file && !asset).length > 0,
      [attachments],
    );

    const editorRef = useRef<EditorControls>(null);

    const hasValue = value.trim().length > 0;

    const placeholderVisible = value.replaceAll(/\r?\n|\r/g, '').length;

    const canSubmit =
      (hasValue || hasAttachments) &&
      !attachmentsUploading &&
      !isPrivateChatUserSuspended;

    const formRef = useRef<HTMLDivElement>(null);

    const uploadAssetsRef = useRef<HTMLInputElement>(null);

    const draftProcessingPromiseRef = useRef<Promise<void> | null>(null);

    const shouldShowSpecialMentions =
      conversation?.type === ConversationType.group ||
      conversation?.type === ConversationType.desktop ||
      conversation?.type === ConversationType.pending;

    const chatParticipants = useMemo(
      () =>
        conversation
          ? conversation.userIds
              .map(userId =>
                userId !== currentAccount.id
                  ? accountsWithAvailability[userId]
                  : undefined,
              )
              .filter((account): account is AccountWithCountsApiType =>
                Boolean(account),
              )
          : [],
      [accountsWithAvailability, conversation, currentAccount.id],
    );

    const mentionData = useMemo((): MentionData[] => {
      const conversationAccs = chatParticipants?.map(acc => ({
        ...acc,
        value: getAccountName(acc) || '',
      }));

      return [
        ...(shouldShowSpecialMentions
          ? ([
              ...[SPECIAL_MENTION_HERE],
              ...[SPECIAL_MENTION_CHANNEL],
            ] as MentionData[])
          : []),
        ...conversationAccs,
      ];
    }, [chatParticipants, shouldShowSpecialMentions]);

    const { createDraftChatMessage } = useChatMessageRepository();

    const createDraftChatMessageApi = useCallback(
      async (value: string) => {
        await createDraftChatMessage(formId, value, getAssetIds(attachments));
      },
      [attachments, createDraftChatMessage, formId],
    );

    const clearTypingTimeInterval = useCallback(() => {
      if (isUserTypingTimeInterval.current) {
        clearTimeout(isUserTypingTimeInterval.current);
        isUserTypingTimeInterval.current = undefined;
      }
    }, []);

    const stopTyping = useCallback(() => {
      if (!conversation?.id || !isUserTyping.current) {
        return;
      }

      isUserTyping.current = false;
      clearTypingTimeInterval();

      isTypingRef.current?.(
        conversation.id,
        IsTypingEventType.stopTypingInChat,
      );
    }, [clearTypingTimeInterval, conversation]);

    const resetEditor = useCallback(() => {
      const editor = editorRef.current;

      if (!editor) {
        return;
      }

      stopTyping();
      resetAttachments([]);
      resetDraftMessage(formId);
    }, [resetDraftMessage, formId, stopTyping, resetAttachments]);

    useEffect(() => {
      const editor = editorRef.current;

      if (!editor || !mentionData.length) {
        return;
      }

      if (draftLoaded && value.length > 0 && editor.isEmpty()) {
        editor.insertDraftValue(value);
      }
    }, [value, draftLoaded, mentionData.length]);

    const submitMessage = async () => {
      const editor = editorRef.current;

      if (!canSubmit || !editor) {
        return;
      }

      editor.blur();

      editor.resetValue();

      resetEditor();
      const chatMessageAssets = chatMessageAssetToApiType(attachments);

      await onSubmit(
        replaceEmoticons(convertToApiFormat(value, mentionData).trim()),
        formType === MessageFormType.NewMessage ? null : formId,
        chatMessageAssets,
      );

      editor.focus();

      if (droppedAttachments && droppedAttachments?.length > 0) {
        onFilesDropped(undefined);
      }
    };

    useEffect(
      () => () => {
        stopTyping();
      },
      [stopTyping, conversation.id],
    );

    const inputContainerRef = useRef<HTMLDivElement>(null);

    const [createFileAssetMutation] = useCreateFileAssetMutation();
    const [createImageAssetMutation] = useCreateImageAssetMutation();

    const startTyping = () => {
      if (!conversation || isUserTyping.current) {
        return;
      }

      isTypingRef.current?.(
        conversation.id,
        IsTypingEventType.startTypingInChat,
      );

      isUserTyping.current = true;

      if (!isUserTypingTimeInterval.current) {
        isUserTypingTimeInterval.current = setInterval(() => {
          isTypingRef.current?.(
            conversation.id,
            IsTypingEventType.startTypingInChat,
          );
        }, TYPING_TIME_EACH_FIVE_SECONDS);
      }
    };

    useEffect(() => {
      if (
        value.replace(/\s/g, '').length === 0 &&
        isUserTypingTimeInterval.current
      ) {
        stopTyping();
      }
    }, [stopTyping, value]);

    const debouncedDraftValue = useDebouncedCallback(() => {
      if (conversation?.id && canSaveDraft) {
        createDraftChatMessageApi(value);
      }
    }, 300);

    const handleTyping = (inputValue: string) => {
      const assets = chatMessageAssetToApiType(attachments);

      setDraftMessage(
        formId,
        {
          message: inputValue,
          context: {
            assets,
          },
        },
        conversation.id,
      );

      debouncedDraftValue();

      if (inputValue.replace(/\s/g, '').length > 0) {
        startTyping();
      }
    };

    const createFileAsset = useCallback(
      (file: File, attachmentId: string, autoArchive?: boolean) => {
        clipboardFileProcessing.current = true;

        return createFileAssetMutation({
          variables: {
            input: {
              file,
              workspace: workspaceId,
              desktop: desktopId,
              ...(autoArchive ? { autoArchive: true } : {}),
            },
          },
        })
          .then((response: FetchResult<CreateFileAssetResponse>) => {
            uploadAttachmentSuccess(
              attachmentId,
              response.data!.createFileAsset.fileAsset,
            );

            debouncedDraftValue();

            clipboardFileProcessing.current = false;
          })
          .catch((error: ApolloError) => {
            if (
              getErrorCodeFrom(error.graphQLErrors) ===
              ERROR_CODE.FILE_STORAGE_LIMIT_REACHED
            ) {
              askConfirmation(
                intl.formatHTMLMessage({
                  id: ChatTranslation.newMessageFormFileUploadAccountLimitReached,
                }),
              ).then(confirm => {
                if (confirm) {
                  return createFileAsset(file, attachmentId, true);
                }
                uploadAttachmentFailed(attachmentId);
              });
            } else {
              showToastGraphQLErrors(error.graphQLErrors);
              uploadAttachmentFailed(attachmentId);
            }
            clipboardFileProcessing.current = false;
          });
      },
      [
        createFileAssetMutation,
        workspaceId,
        desktopId,
        uploadAttachmentSuccess,
        debouncedDraftValue,
        askConfirmation,
        intl,
        uploadAttachmentFailed,
      ],
    );

    const createImageAsset = useCallback(
      (file: File, attachmentId: string) => {
        clipboardFileProcessing.current = true;

        return createImageAssetMutation({
          variables: {
            input: {
              file,
              workspace: workspaceId,
              desktop: desktopId,
            },
          },
        })
          .then((response: FetchResult<CreateImageAssetResponse>) => {
            uploadAttachmentSuccess(
              attachmentId,
              response.data!.createImageAsset.imageAsset,
            );
            debouncedDraftValue();
            clipboardFileProcessing.current = false;
          })
          .catch((error: ApolloError) => {
            showToastGraphQLErrors(error.graphQLErrors);
            uploadAttachmentFailed(attachmentId);
            clipboardFileProcessing.current = false;
          });
      },
      [
        createImageAssetMutation,
        workspaceId,
        desktopId,
        uploadAttachmentSuccess,
        debouncedDraftValue,
        uploadAttachmentFailed,
      ],
    );

    const attachMedia = useCallback(
      (files: FileList | File[]) => {
        editorRef.current?.focus();
        Array.from(files).map(async file => {
          const isFileSizeAvailableToUpload = isFileSizeWithinLimit(file);

          if (isFileSizeAvailableToUpload) {
            const attachmentId = makeAttachmentId();
            uploadAttachmentStarted(attachmentId, file);
            if (isImageMimeType(file.type)) {
              return await createImageAsset(file, attachmentId);
            }
            return await createFileAsset(file, attachmentId);
          } else {
            showToastInfoMessage(GlobalTranslation.uploadFileSizeMessage);
          }
        });

        clearDropZoneState?.();
        if (uploadAssetsRef?.current?.value) {
          uploadAssetsRef.current.value = '';
        }
      },
      [
        uploadAttachmentStarted,
        createFileAsset,
        createImageAsset,
        clearDropZoneState,
      ],
    );

    const handleAttachFromClick: ChangeEventHandler<HTMLInputElement> =
      useCallback(
        event => {
          if (!event?.target?.files) {
            return;
          }

          attachMedia(event?.target?.files);
          event.target.value = '';
        },
        [attachMedia],
      );

    const handleAttachFromDrop = useCallback(
      (attachment?: FileList | File[]) => {
        if (!attachment) {
          return;
        }

        attachMedia(attachment);
      },
      [attachMedia],
    );

    useEffect(
      () => handleAttachFromDrop(dragAttachments),
      [dragAttachments, handleAttachFromDrop],
    );

    useEffect(
      () => handleAttachFromDrop(droppedAttachments),
      [droppedAttachments, handleAttachFromDrop],
    );

    useEffect(() => {
      isFirstRender.current = draftLoaded || false;

      if (!formDisabled && draftLoaded && editorRef.current && !isMobile) {
        editorRef.current.focus();
      }
    }, [draftLoaded, formDisabled, isMobile]);

    const inputFileNodeId = `upload-assets-${formType}-${formId}`;

    useEffect(() => {
      if (debouncedTypingValue) {
        stopTyping();
      }
    }, [debouncedTypingValue, stopTyping]);

    const isTypingContainerVisible =
      !typingDisabled && formType === MessageFormType.NewMessage;

    useEffect(
      () => () => {
        clearTypingTimeInterval();
        if (draftProcessingPromiseRef.current) {
          draftProcessingPromiseRef.current = null;
        }
      },
      [clearTypingTimeInterval],
    );

    useEffect(() => {
      if (inputContainerRef.current) {
        inputContainerRef.current.dataset.replicatedValue = value;
      }
    }, [value]);

    useEffect(() => {
      if (draftAttachments.length > 0 && attachments.length === 0) {
        resetAttachments(draftAttachments);
      }
    }, [attachments.length, draftAttachments, resetAttachments]);

    const handleRemoveAttachment = (id: string) => {
      removeAttachment(id);

      debouncedDraftValue();
    };

    useEffect(() => {
      if (
        draftLoaded &&
        draftAttachments.length > 0 &&
        attachments.length === 0 &&
        isFirstRender.current
      ) {
        resetAttachments(draftAttachments);
        isFirstRender.current = false;
      }
    }, [attachments.length, draftAttachments, resetAttachments, draftLoaded]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        editorRef.current?.focus();
      },
    }));

    const handleMessageFormClick = () => {
      editorRef.current?.focus();
    };

    return (
      <>
        <MessageFormContainer key={formId}>
          <AttachmentsContainer
            attachments={attachments}
            onRemove={handleRemoveAttachment}
          />

          <ConversationDropZone
            width={formRef?.current?.offsetWidth || 0}
            height={formRef?.current?.offsetHeight || 0}
            onFilesDropped={onFilesDropped}
            multiple
          />

          <StyledMessageForm
            hasAttachments={hasAttachments}
            isMobile={isMobile}
            ref={formRef}
            onClick={handleMessageFormClick}>
            <StyledMessageInputContainer>
              <InputWrapper>
                <Editor
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={
                    isPrivateChatUserSuspended
                      ? intl.formatMessage({
                          id: ChatTranslation.tooltipDisabledInputMessageForSuspendedUser,
                        })
                      : ''
                  }
                  data-tooltip-place={TooltipPlace.top}
                  ref={editorRef}
                  onValueChange={handleTyping}
                  readOnly={
                    isPrivateChatUserSuspended || formDisabled || !draftLoaded
                  }
                  mentionData={mentionData}
                  onSubmit={submitMessage}
                  data-testid="conversation-editor"
                  data-ignorelinkpaste="true"
                  onImagePasted={attachMedia}>
                  {!placeholderVisible && (
                    <InputPlaceholder data-testid="message-placeholder">
                      <FormattedMessage
                        id={
                          formType === MessageFormType.EditMessage
                            ? ChatTranslation.newMessageFormInputEditPlaceholder
                            : formType === MessageFormType.ThreadReply
                            ? ChatTranslation.newMessageFormInputReplyPlaceholder
                            : conversation?.type === ConversationType.link
                            ? ChatTranslation.newMessageFormInputLinkChatPlaceholder
                            : ChatTranslation.newMessageFormInputPlaceholder
                        }
                        values={{
                          name: conversationTitle,
                        }}
                      />
                    </InputPlaceholder>
                  )}
                </Editor>
              </InputWrapper>

              <input
                type="file"
                id={inputFileNodeId}
                multiple
                onChange={handleAttachFromClick}
                data-testid="file-input"
              />
            </StyledMessageInputContainer>

            {editorRef.current && (
              <MessageFormFooter
                editorControls={editorRef.current}
                resetEditor={resetEditor}
                droppedAttachments={droppedAttachments}
                onFilesDropped={onFilesDropped}
                mentionData={mentionData}
                inputFileNodeId={inputFileNodeId}
                canSubmit={canSubmit}
                submitMessage={submitMessage}
                formType={formType}
                onCancel={onCancel}
                allowAttachments={allowAttachments}
                buttonsLables={buttonsLables}
              />
            )}
          </StyledMessageForm>
        </MessageFormContainer>
        <IsTypingContainer showTyping={isTypingContainerVisible} />
      </>
    );
  },
);
