import styled from 'styled-components';
import { TabPanel, Tabs } from 'react-tabs';

export const StyledAppsIntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

export const StyledSearchResult = styled.div`
  margin-top: 1rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;
  font-weight: 600;
`;

export const StyledTabName = styled.span`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  margin-right: 0.5rem;
`;

export const StyledSpinner = styled.div`
  width: 1rem;
`;

export const StyledFilter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 -1.5rem;
  padding: 0 1.5rem;
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
`;

export const StyledAppsSection = styled.div`
  margin-top: ${p => p.theme.spacing.m};
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  font-size: 0.875rem;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ul {
    overflow-x: auto;
    border: none;
  }
`;

export const StyledTabsControls = styled.div`
  display: flex;
  margin: 0.875rem 0;

  button {
    margin-left: 1rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledSearchResultList = styled.div`
  max-height: 11rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-size: 0.75rem;
  font-weight: 500;

  span {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    font-size: 0.875rem;
  }
`;

export const StyledResultItem = styled.a`
  display: flex;
  width: 100%;
  padding: 0.625rem;
  cursor: pointer;

  div {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    img,
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
    span {
      margin-left: ${p => p.theme.spacing.s};
    }
  }

  .global-search-icon-hover {
    opacity: 0;
    margin-left: 0.625rem;
  }

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    .global-search-icon-hover {
      opacity: 1;
      color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    }
  }
`;

export const StyledDesktopIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
`;

export const StyledManageIntegrations = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  margin-left: 1rem;
  svg {
    margin-right: 0.25rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;

  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

export const StyledBackButton = styled.div`
  font-weight: 600;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.OnSurface};
  border-radius: 0.25rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }
  .enter-icon {
    display: none;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    .enter-icon {
      display: block;
    }
  }

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }

  &:focus,
  &:focus-visible {
    background-color: ${p => p.theme.colors.OnSurfacePressed};
  }
`;

export const StyledAppIntegrationNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTabPanel = styled(TabPanel)`
  overflow: auto;

  &.react-tabs__tab-panel--selected {
    height: 100%;
    width: 100%;
  }
`;
