import styled from 'styled-components';
import { GroupTag } from '../../../../User/UserGroupTag';

export const GuestLabel = styled(GroupTag)`
  padding: 0 0.375rem;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  margin-left: 0.5rem;
  margin-bottom: -0.25rem;
  line-height: inherit;
`;
