import React, { FC } from 'react';
import { Folder } from '../../../Folder/Folder';
import { DragFolderItemType } from '../../Drag.types';
import { StyledFolderPreview } from './FolderPreview.styled';

export const FolderPreview: FC<{ item: DragFolderItemType }> = ({ item }) => {
  return (
    <StyledFolderPreview
      clientOffset={item.clientOffset!}
      sourceOffset={item.sourceOffset!}>
      <Folder folder={item.folder} />
    </StyledFolderPreview>
  );
};
