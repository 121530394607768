import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import {
  getImageSrcSet,
  getQueryParamsFrom,
  openUrl,
} from '../../../../../shared/utils/url.utils';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { useAccountsContext } from '../../../../Account';
import { useDocumentIsSupportedPreviewType } from '../../../../Preview/DocumentPreview';
import { DocumentDynamicIcon } from '../../../../Preview/DocumentPreview/DocumentDynamicIcon';
import { TimelineTranslation } from '../../../../Timeline/i18n';
import {
  TIMELINE_EVENTS_PAGE_SIZE,
  TimelineEventAction,
  TimelineEventScope,
} from '../../../../Timeline/Timeline.constants';
import {
  useSearchInTimelinesQuery,
  useTimelineEventTracker,
} from '../../../../Timeline/Timeline.hooks';
import { TimelineEventApiType } from '../../../../Timeline/Timeline.types';
import { AccountApiType } from '../../../../User/User.types';
import { Avatar } from '../../../../User/UserAvatar/Avatar';
import { AvatarMode } from '../../../../User/UserAvatar/Avatar.styled';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { LinkTranslation } from '../../../i18n';
import { LinkApiType } from '../../../Link.types';
import { getLinkTitle } from '../../../Link.utils';
import {
  Favicon,
  LinkWarning,
} from '../../../LinksView/Link/LinkData/LinkData.styled';
import { LinkImage } from '../../../LinksView/Link/LinkImage/LinkImage';
import { LinkDetailsPanel } from '../LinkDetailsModal.styled';
import {
  DetailEntity,
  EntityButton,
  EntityHeaderContainer,
  EntityLinkImage,
  EntityLinkTag,
  EntityLinkTags,
  EntityLinkTagsEmpty,
  EntityLinkTitle,
  EntityLinkUrl,
  EntityLocationDesktop,
  EntityLocationDesktopLabel,
  EntityTimelineAction,
  EntityTimelineCreator,
  EntityTimelineList,
  EntityTimelineListItem,
  EntityTitle,
  StyledLinkDetails,
} from './LinkDetails.styled';
import { LinkDetailsSidebarHeader } from '../LinkDetailsSidebarHeader/LinkDetailsSidebarHeader';
import { useCurrentDesktopPermissions } from '../../../../Desktop/Desktop.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import {
  FOLDER_PATHNAME,
  FolderCrumbsLocationContext,
} from '../../../../Folder/Folder.constants';
import { FolderCrumbs } from '../../../../Folder/Views/FolderCrumbs/FolderCrumbs';
import { getShortId } from '../../../../../shared/utils/id';
import { useDesktopsRepository } from '../../../../Desktop/data/Desktop/Desktop.repositories';
import { useLinkTrackingEventOnInit } from '../../../tracking/LinkTracking.hooks';

type LinkDetailsProps = {
  link?: LinkApiType | null;
};

export const LinkDetails: FC<LinkDetailsProps> = ({ link }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { chatLink } = queryParams;
  const isChatLink = Boolean(chatLink);
  const trackTimelineEvent = useTimelineEventTracker();
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const { accountsWithAvailability: workspaceAccountsMap } =
    useAccountsContext();
  const { canEditLink } = useCurrentDesktopPermissions();
  const { formatMessage } = useIntl();
  const canPreviewDocument = useDocumentIsSupportedPreviewType(link!);

  useLinkTrackingEventOnInit('open_link_details');

  const [linkDetailsPanelRef] = useHideScrollbarWhenIdle();

  const linkTitle = useMemo(
    () => (link ? getLinkTitle(link) : undefined),
    [link],
  );

  const linkUrl = useMemo(() => link!.data.url, [link]);

  const openLink = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      trackTimelineEvent(
        TimelineEventScope.links,
        link!.id,
        TimelineEventAction.open,
      );

      openUrl(account!.identity.openLink, linkUrl, linkTitle as string, event);
    },
    [trackTimelineEvent, link, account, linkUrl, linkTitle],
  );

  const linkTags = useMemo(() => {
    if (link?.tags?.edges) {
      return extractNodes(link.tags);
    }

    return [];
  }, [link]);

  /**
   * Activity
   */
  const { data: activityData, loading: activityLoading } =
    useSearchInTimelinesQuery({
      skip: !link?.id,
      variables: {
        workspace: workspace.id,
        scope: 'links',
        scopeId: link?.id,
        pageSize: TIMELINE_EVENTS_PAGE_SIZE,
        page: 0,
      },
      fetchPolicy: 'cache-and-network',
    });

  const timelineEvents: TimelineEventApiType[] = useMemo(
    () => extractNodes(activityData?.searchInTimelineEvents),
    [activityData],
  );

  const getTimelineAction = (action: string) => {
    switch (action) {
      case TimelineEventAction.add:
        return TimelineTranslation.eventLinkDetailsAdd;
      case TimelineEventAction.update:
        return TimelineTranslation.eventLinkDetailsUpdate;
      case TimelineEventAction.comment:
        return TimelineTranslation.eventLinkDetailsComment;
      case TimelineEventAction.open:
        return TimelineTranslation.eventLinkDetailsOpen;
      default:
        return action;
    }
  };

  const renderTimelineActivity = (activity: TimelineEventApiType) => {
    let activityAccount: AccountApiType =
      workspaceAccountsMap[activity.context.created_by!];

    return (
      <>
        <Avatar account={activityAccount} size={24} mode={AvatarMode.circle} />
        <EntityTimelineCreator data-testid="timeline-creator">
          {`${activityAccount?.firstName} ${activityAccount?.lastName}`}
        </EntityTimelineCreator>
        <FormattedMessage
          tagName={EntityTimelineAction}
          id={getTimelineAction(activity.action)}
        />
      </>
    );
  };

  const { desktops } = useDesktopsRepository({
    variables: {
      filterOnlyAccessible: true,
    },
    fetchPolicy: 'cache-only',
  });

  const linkDesktop = useMemo(
    () => desktops.find(desktop => getShortId(desktop.id) === link?.desktopId),
    [desktops, link?.desktopId],
  );

  const openDesktopHandler = useCallback(() => {
    if (!linkDesktop?.id) {
      return;
    }

    const desktopPath = generatePath(FOLDER_PATHNAME, {
      workspaceId: getShortId(workspace.id),
      desktopId: getShortId((linkDesktop as any).id),
    });

    navigate({
      pathname: desktopPath,
      search: getQueryParamsFrom({
        folderId: link?.folder?.id ? getShortId(link?.folder?.id) : undefined,
      }),
    });
  }, [navigate, link, linkDesktop, workspace]);

  const isAbuseLink =
    link?.data.abuseChecked && !link.data.safe && link.data.abuseDescription;

  if (!link) {
    return null;
  }

  return (
    <LinkDetailsPanel solidBackground data-testid="link-details-panel">
      <LinkDetailsSidebarHeader
        solidBackground
        heading={formatMessage({
          id: LinkTranslation.linkDetailsInformationHeading,
        })}
      />
      <StyledLinkDetails ref={linkDetailsPanelRef} data-testid="link-details">
        <DetailEntity data-testid="link-properties-panel">
          <EntityHeaderContainer data-testid="header">
            <FormattedMessage
              tagName={EntityTitle}
              id={LinkTranslation.linkDetailsInformationPreview}
            />
            {!isChatLink && canEditLink && (
              <EntityButton
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: LinkTranslation.tooltipEditLinkDetails,
                })}
                data-tooltip-place={TooltipPlace.top}
                onClick={() => {
                  navigate({
                    search: getQueryParamsFrom({
                      ...queryParams,
                      editLink: getShortId(link.id),
                    }),
                  });
                }}
                data-testid="edit-link-button">
                {formatMessage({
                  id: LinkTranslation.linkDetailsInformationEditAction,
                })}
              </EntityButton>
            )}
          </EntityHeaderContainer>
          <EntityLinkTitle data-testid="link-title">
            {linkTitle}
          </EntityLinkTitle>
          <EntityLinkImage data-testid="link-image">
            {canPreviewDocument ? (
              <DocumentDynamicIcon link={link} withBackground fullSize />
            ) : (
              <LinkImage link={link} width={265} height={182} fullSize />
            )}
          </EntityLinkImage>
          <EntityLinkUrl onClick={openLink} data-testid="link-url-panel">
            <div className="favicon">
              {link.data.icon && (
                <Favicon
                  src={link.data.icon.contentUrl}
                  srcSet={getImageSrcSet(link.data.icon.contentUrl, 15)}
                  data-testid="link-favicon"
                />
              )}
            </div>
            <span className="link-url" data-testid="link-url">
              {linkUrl}
            </span>
          </EntityLinkUrl>
        </DetailEntity>
        {isAbuseLink && (
          <LinkWarning
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage(
              {
                id: LinkTranslation.linksViewLinkPotentialDanger,
              },
              { abuseDescription: link.data.abuseDescription },
            )}
            data-tooltip-place={TooltipPlace.right}>
            <FormattedMessage id={LinkTranslation.linksViewLinkWarningLabel} />
          </LinkWarning>
        )}

        {linkDesktop && (
          <DetailEntity data-testid="link-location-panel">
            <EntityHeaderContainer noMarginBottom data-testid="title">
              <FormattedMessage
                tagName={EntityTitle}
                id={LinkTranslation.linkDetailsInformationLocation}
              />
              {/* TODO: Add entitybutton for move here in R2107 */}
            </EntityHeaderContainer>

            <EntityLocationDesktop data-testid="link-location">
              <Button
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: LinkTranslation.linkDetailsInformationLocationOpenDesktop,
                })}
                data-tooltip-place={TooltipPlace.left}
                mode={ButtonMode.tertiary}
                size={ButtonSize.xs}
                onClick={openDesktopHandler}
                data-testid="open-desktop-button">
                {linkDesktop?.name}
              </Button>
              <FormattedMessage
                tagName={EntityLocationDesktopLabel}
                id={LinkTranslation.linkDetailsInformationLocationDesktopLabel}
              />
            </EntityLocationDesktop>

            {link?.folder?.id && linkDesktop && (
              <FolderCrumbs
                injectedData={{
                  desktop: linkDesktop,
                  folderId: getShortId(link.folder.id),
                }}
                locationContext={FolderCrumbsLocationContext.LINKDETAILS}
              />
            )}
          </DetailEntity>
        )}
        {!isChatLink && (
          <>
            <DetailEntity data-testid="tags-panel">
              <EntityHeaderContainer data-testid="header">
                <FormattedMessage
                  tagName={EntityTitle}
                  id={LinkTranslation.linkDetailsInformationTags}
                />
                {canEditLink ? (
                  <EntityButton
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: linkTags.length
                        ? LinkTranslation.tooltipEditLinkTags
                        : LinkTranslation.tooltipAddLinkTags,
                    })}
                    data-tooltip-place={TooltipPlace.top}
                    onClick={() => {
                      navigate({
                        search: getQueryParamsFrom({
                          ...queryParams,
                          manageTag: true,
                          linkId: getShortId(link.id),
                        }),
                      });
                    }}
                    data-testid="manage-tags-button">
                    {linkTags.length
                      ? formatMessage({
                          id: LinkTranslation.linkDetailsInformationEditAction,
                        })
                      : formatMessage({
                          id: LinkTranslation.linkDetailsInformationAddAction,
                        })}
                  </EntityButton>
                ) : null}
              </EntityHeaderContainer>
              <EntityLinkTags data-testid="tags">
                {linkTags.length ? (
                  linkTags.map(tag => (
                    <EntityLinkTag key={tag._id} data-testid="tag-name">
                      {tag.name}
                    </EntityLinkTag>
                  ))
                ) : (
                  <FormattedMessage
                    tagName={EntityLinkTagsEmpty}
                    id={LinkTranslation.linkDetailsInformationTagsEmpty}
                  />
                )}
              </EntityLinkTags>
            </DetailEntity>

            <DetailEntity data-testid="activity-panel">
              <EntityHeaderContainer data-testid="header">
                <FormattedMessage
                  tagName={EntityTitle}
                  id={LinkTranslation.linkDetailsInformationTimeline}
                />
              </EntityHeaderContainer>
              {!activityData && activityLoading ? (
                <div data-testid="loading">loading</div>
              ) : (
                <EntityTimelineList data-testid="timeline-list">
                  {timelineEvents?.map(activity => (
                    <EntityTimelineListItem
                      key={activity.id}
                      data-testid="timeline-list-item">
                      {renderTimelineActivity(activity)}
                    </EntityTimelineListItem>
                  ))}
                </EntityTimelineList>
              )}
            </DetailEntity>
          </>
        )}
      </StyledLinkDetails>
    </LinkDetailsPanel>
  );
};
