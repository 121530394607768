import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChatTranslation } from '../../../i18n';
import { ThreadDateDelimiter } from './ChatMessageThread.styled';

interface ChatMessageThreadDateDelimiterProps {
  dateDelimiter: string;
  currentDate: string;
}

export const ChatMessageThreadDateDelimiter: FC<ChatMessageThreadDateDelimiterProps> =
  memo(props => {
    const { dateDelimiter, currentDate } = props;

    return (
      <ThreadDateDelimiter
        date={dateDelimiter}
        data-testid="thread-date-delimiter">
        {currentDate === dateDelimiter ? (
          <FormattedMessage id={ChatTranslation.chatDateDelimiterToday} />
        ) : (
          dateDelimiter
        )}
      </ThreadDateDelimiter>
    );
  });
