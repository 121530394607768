import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useLocation } from 'react-router-dom';
import { useAppleTouchDevice } from '../../../../../shared/hooks';
import { ShieldIcon } from '../../../../../shared/icons';
import { StyledNavigatorLinkContent } from '../../../../Segment/Temp/NavigatorLink/NavigatorLink.styled';
import { PASSWORDS_ROOT_PATHNAME } from '../../../Desktop.constants';
import { DesktopTranslation } from '../../../i18n';
import { DesktopName } from '../DesktopList/DesktopList.styled';
import styled, { css } from 'styled-components';

type VaultLinkProps = {
  workspaceId: string;
  disabled?: boolean;
};

const StyledNavLink = styled(NavLink)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      background-color: unset;
      cursor: not-allowed;
    `};
`;

export const VaultLink: FC<VaultLinkProps> = ({
  workspaceId,
  disabled = false,
}) => {
  const location = useLocation();
  const isAppleTouchDevice = useAppleTouchDevice();

  const vaultPath = generatePath(PASSWORDS_ROOT_PATHNAME, {
    workspaceId: workspaceId,
  });

  return (
    <StyledNavLink
      onClick={e => disabled && e.preventDefault()}
      to={vaultPath}
      disabled={disabled}>
      <StyledNavigatorLinkContent
        enableHover={isAppleTouchDevice}
        isActive={location.pathname.includes(vaultPath)}
        data-testid="vault-link">
        <ShieldIcon width={16} height={18} />
        <DesktopName hasIcon={true} data-testid="vault-text">
          <FormattedMessage id={DesktopTranslation.listVault} />
        </DesktopName>
      </StyledNavigatorLinkContent>
    </StyledNavLink>
  );
};
