import styled from 'styled-components';

export const StyledSegmentEmpty = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  text-align: center;
  height: 100%;
`;
