import { useMutation } from '@apollo/client';
import {
  UpdateNotificationSettingsVariables,
  UpdateNotificationsSettingsResponse,
  UPDATE_NOTIFICATIONS_SETTINGS,
} from './NotificationsSettingsAdmin.mutations';

export const useUpdateNotificationsSettingsMutation = () =>
  useMutation<
    UpdateNotificationsSettingsResponse,
    UpdateNotificationSettingsVariables
  >(UPDATE_NOTIFICATIONS_SETTINGS);
