import styled, { css } from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';
import Color from 'color';

export const StyledInput = styled.input`
  height: 0;
  opacity: 0;
`;

export const StyledSubTitle = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled(Button)<{
  isDragging: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  appearance: none;
  cursor: pointer;
  border-radius: 0.5rem;
  border: dashed 1px ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Background};
  svg {
    margin-left: 1rem;
  }
  :hover:not(:disabled) {
  }
  & span > span {
    color: ${p => p.theme.colors.Primary};
  }

  ${p =>
    p.isDragging &&
    css`
      background-color: ${Color(p.theme.colors.Primary)
        .alpha(0.8)
        .rgb()
        .string()};
    `}
`;

export const UploadButtonWrapper = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const StyledImportPasswords = styled.div`
  padding: 0 1.5rem 0.875rem 1.5rem;
`;

export const ImportPasswordsControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    cursor: pointer;
    color: ${p => p.theme.colors.Primary};
  }

  & > div > button:last-child {
    margin-left: 1rem;
  }
`;

export const ImportPasswordsFileInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Background};
  margin-bottom: 1.5rem;
`;

export const ImportPasswordsFileInfo = styled.div`
  width: 80%;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ImportPasswordsImportingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2rem 2rem 1rem;

  > * ~ * {
    margin-top: 1rem;
  }
`;
