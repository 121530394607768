import styled, { css } from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';

export const ConfigurationBody = styled.div`
  padding: 0 1rem 1rem;

  .label {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    line-height: 1.3;
    font-family: ${p => p.theme.fonts.Semibold};
    margin-bottom: 0.25rem;

    &--danger {
      color: ${p => p.theme.colors.CriticalLight};
    }
  }

  .danger-zone {
    margin-top: 2rem;
    border-top: 2px solid ${p => p.theme.colors.BorderLight};
    padding-top: 1rem;

    &__body {
      border: 1px solid ${p => p.theme.colors.BorderDefault};
      padding: 1rem;
      border-radius: 0.25rem;
      ${p => p.theme.breakpoints.md`
        display: flex;
        align-items: center;
      `}

      p {
        font-size: 0.75rem;
        margin: 1rem 0 0 0;

        ${p => p.theme.breakpoints.md`
          margin: 0 0 0 2rem;
        `}
      }
    }
  }

  .apps {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

    .app {
      min-width: 6rem;
      margin: 0.5rem;
    }
  }
`;

export const Header = styled.div`
  background-color: ${p => p.theme.colors.Surface};
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
  margin-bottom: 20px;
  padding: 24px 16px 24px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    padding: 0 16px 24px 20px;
    min-height: 4.25rem;
  `}
`;

export const ToggleButton = styled(Button)<{ danger: boolean }>`
  ${p =>
    p.danger &&
    css`
      :not([disabled]) {
        background-color: ${p => p.theme.colors.CriticalLight} !important;
      }

      :not([disabled]):hover {
        background-color: ${p => p.theme.colors.CriticalDark} !important;
      }
    `}
`;
