import styled from 'styled-components';

export const CompleteLinkAccountData = styled.div`
  margin: 2.25rem 0;

  & > * ~ * {
    margin-top: 1rem;
  }
`;

export const CompleteLinkAccountControls = styled.div`
  display: flex;
  flex-direction: column;

  & > * ~ * {
    margin-top: 1rem;
  }
`;
