import styled, { css } from 'styled-components';
import {
  EXTERNAL_VIDEO_PLAYER_CONTAINER_HEIGHT,
  EXTERNAL_VIDEO_PLAYER_CONTAINER_HEIGHT_MOBILE,
  EXTERNAL_VIDEO_PLAYER_CONTAINER_THREAD_HEIGHT_MOBILE,
  LINK_CONTAINER_MIN_HEIGHT,
} from '../../../../../VideoPlayer/VideoPlayer.constants';
import { MessageBubble } from '../ChatMessage.styled';

export const StyledLinkMessage = styled(MessageBubble)<{
  isVideoPreview?: boolean;
  isThread?: boolean;
  isMobile?: boolean;
}>`
  padding: 0.25rem;
  max-width: 26rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;

  ${({ isVideoPreview, isThread, isMobile }) =>
    isVideoPreview
      ? css`
          height: ${p =>
            isThread && isMobile
              ? EXTERNAL_VIDEO_PLAYER_CONTAINER_THREAD_HEIGHT_MOBILE
              : isMobile
              ? EXTERNAL_VIDEO_PLAYER_CONTAINER_HEIGHT_MOBILE
              : EXTERNAL_VIDEO_PLAYER_CONTAINER_HEIGHT}px;
        `
      : css`
          height: ${LINK_CONTAINER_MIN_HEIGHT}px;
        `}
`;

export const LinkMessageSkeleton = styled.div`
  background-color: ${p => p.theme.colors.Background};
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex: 1;
  justify-content: center;
  justify-content: space-between;
`;
