import React, { FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import {
  EmptyStateDescription,
  EmptyStateSupportText,
  StyledEmptyState,
} from './EmptyState.styled';
import { EncryptionTranslation } from '../../i18n';
import { appEnv } from '../../../../appEnv';
import { generatePath } from 'react-router-dom';
import { ADMIN_PREFERENCES_PATHNAME } from '../../../User/User.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../shared/utils/id';

export const EmptyState: FC = () => {
  const { workspace } = useCurrentWorkspace();

  return (
    <StyledEmptyState>
      <EmptyStateDescription>
        <FormattedHTMLMessage
          id={EncryptionTranslation.passwordsPageEmptyStateDescription}
          values={{
            url: generatePath(ADMIN_PREFERENCES_PATHNAME, {
              workspaceId: getShortId(workspace.id),
            }),
          }}
        />
      </EmptyStateDescription>
      <EmptyStateSupportText>
        <FormattedHTMLMessage
          id={EncryptionTranslation.passwordsPageEmptyStateSupportText}
          values={{
            url: appEnv.SUPPORT_PASSWORD_MANAGER_URL,
          }}
        />
      </EmptyStateSupportText>
    </StyledEmptyState>
  );
};
