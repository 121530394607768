import React, {
  FC,
  memo,
  RefObject,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useMobile } from '../../../../../shared/hooks';
import { ChatReactionIcon, DotsIcon } from '../../../../../shared/icons';
import { ChatReplyIcon } from '../../../../../shared/icons/ChatReply.icon';
import {
  ChatMessageInternalType,
  ChatTextMessageInternalType,
  MessageType,
} from '../../../Chat.types';
import { ChatTranslation } from '../../../i18n';
import { useOpenThreadSidebar } from '../ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.hooks';
import { ChatMessageContext } from './ChatMessage.context';
import {
  ChatMessageMenuButton,
  StyledChatMessageActions,
} from './ChatMessage.styled';

interface ChatMessageActionProps {
  message: ChatMessageInternalType;
  hasThreadMessages: boolean;
  allowReply: boolean;
  ownMessage: boolean;
  messageType: MessageType;
  hasReactions: boolean;
  addMessageReaction: React.MouseEventHandler<HTMLButtonElement>;
  onMenu: (
    message: ChatTextMessageInternalType,
    domRef: RefObject<HTMLElement>,
    messageType?: MessageType,
  ) => void;
  systemMessage?: boolean;
  threadMessageDomId?: string;
}

export const ChatMessageAction: FC<ChatMessageActionProps> = memo(
  ({
    message,
    hasThreadMessages,
    allowReply,
    systemMessage,
    ownMessage,
    messageType,
    hasReactions,
    addMessageReaction,
    onMenu,
    threadMessageDomId,
  }) => {
    const { openThreadSidebar } = useOpenThreadSidebar();
    const { messageIsEditing, replyFormVisible } =
      useContext(ChatMessageContext);
    const isEditing = messageIsEditing(message.id);

    const replyFormIsVisible = replyFormVisible(message.id);
    const isMobile = useMobile();
    const { formatMessage } = useIntl();
    const chatMessageActionsRef = useRef<HTMLDivElement>(null);

    const handleReplyButtonClick = useCallback(() => {
      openThreadSidebar(message.id, message.conversationId);
    }, [message.conversationId, message.id, openThreadSidebar]);

    const handleMessageMenuButtonClick = useCallback(() => {
      onMenu(
        {
          ...(message as ChatTextMessageInternalType),
          id: threadMessageDomId ? threadMessageDomId : message.id,
        },
        chatMessageActionsRef,
        messageType,
      );
    }, [message, threadMessageDomId, onMenu, messageType]);

    const chatMessageMenuButtonVisible =
      !hasThreadMessages &&
      allowReply &&
      !systemMessage &&
      !replyFormIsVisible &&
      !isEditing &&
      !message.isOptimistic;

    return (
      <StyledChatMessageActions
        isMobile={isMobile}
        ref={chatMessageActionsRef}
        className="chat-message-actions"
        data-testid="chat-message-actions">
        {chatMessageMenuButtonVisible && (
          <ChatMessageMenuButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: ChatTranslation.tooltipReplyInTreadButton,
            })}
            data-tooltip-place={TooltipPlace.left}
            type="button"
            icon={ChatReplyIcon}
            onClick={handleReplyButtonClick}
            data-testid="reply-in-thread-btn"
          />
        )}

        {ownMessage && (
          <ChatMessageMenuButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: ChatTranslation.tooltipMoreActionsButton,
            })}
            data-tooltip-place={TooltipPlace.left}
            type="button"
            icon={DotsIcon}
            onClick={handleMessageMenuButtonClick}
            data-testid="message-menu-btn"
          />
        )}

        {!(messageType === MessageType.Conference) &&
          !hasReactions &&
          !systemMessage && (
            <ChatMessageMenuButton
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: ChatTranslation.tooltipAddReactionButton,
              })}
              data-tooltip-place={TooltipPlace.left}
              type="button"
              icon={ChatReactionIcon}
              onClick={addMessageReaction}
              data-testid="pick-reaction-btn"
            />
          )}
      </StyledChatMessageActions>
    );
  },
);
