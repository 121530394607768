import React, { FC, useCallback, useReducer, useState } from 'react';
import { ListItemContent } from '../../../../shared/components/List';
import { Checkbox } from '../../../../shared/components/Checkbox';
import { TeamsList } from './RelatedTeamsForm.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { TeamTranslation } from '../../i18n';
import { TeamApiType } from '../../data/Team/types/Team.types';

interface RelatedTeamsFormProps {
  teams: TeamApiType[];
  relatedTeams: TeamApiType[];
  disableSystemTeams?: boolean;
  onSubmit: (teams: TeamApiType[]) => void;
}

export const RelatedTeamsForm: FC<RelatedTeamsFormProps> = ({
  teams,
  relatedTeams,
  disableSystemTeams,
  onSubmit,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const [teamCheckboxesMap, toggleTeamCheckbox] = useReducer(
    (state: { [key: string]: boolean }, id: string) => {
      return { ...state, [id]: !state[id] };
    },
    relatedTeams.reduce((acc, current) => ({ ...acc, [current.id]: true }), {}),
  );

  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    const selectedTeams = teams.filter(team => teamCheckboxesMap[team.id]);
    onSubmit(selectedTeams);
  }, [teamCheckboxesMap, teams, onSubmit]);

  return (
    <div data-testid="related-teams-form">
      <TeamsList data-testid="teams-list">
        {teams.map(team => (
          <li data-testid="teams-list-item" key={team.id}>
            <ListItemContent
              title={team.name}
              subtitle={team.description}
              spaced={false}>
              <Checkbox
                data-testid="teams-list-item-checkbox"
                size={32}
                onChange={() => toggleTeamCheckbox(team.id)}
                checked={!!teamCheckboxesMap[team.id]}
                disabled={disableSystemTeams && team.system}
              />
            </ListItemContent>
          </li>
        ))}
      </TeamsList>

      <Button
        disabled={submitted}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        onClick={handleSubmit}
        data-testid="submit-button">
        <FormattedMessage id={TeamTranslation.manageModalUpdateButton} />
      </Button>
    </div>
  );
};
