import styled from 'styled-components';
import { SectionBody } from '../../../../Admin';

export const AppsSectionBody = styled(SectionBody)`
  padding: 0;
  min-height: 120px;
  display: flex;
  align-items: center;

  & > * {
    width: 100%;
  }
`;
