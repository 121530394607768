import { useCallback, useState } from 'react';
import { getLongId, getShortId } from '../../../shared/utils/id';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { getDesktopChatRepository } from '../deprecatedData/Repository/DesktopRepository';

export const useCaseGetDesktopChat = () => {
  const { account } = useCurrentAccount();
  const [desktopChatLoading, setDesktopChatLoading] = useState(false);

  const getDesktopChat = useCallback(
    async (desktopId: string, workspaceId: string) => {
      setDesktopChatLoading(true);
      const response = await getDesktopChatRepository(
        getShortId(desktopId),
        getLongId('workspaces', workspaceId),
        account?.id || '',
      );

      if (response) {
        setDesktopChatLoading(false);
        return response;
      }
    },
    [account?.id],
  );

  return {
    getDesktopChat,
    desktopChatLoading,
  };
};
