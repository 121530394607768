import styled, { css } from 'styled-components';
import { Button, ButtonSize } from '../../../shared/components/Button/Button';
import { Favicon } from '../../Link/LinksView/Link/LinkData/LinkData.styled';

export const ExternalServiceAuthDescriptionStyled = styled.div`
  margin-bottom: 1rem;
  ${({ theme }) => theme.fontTemplates.headerList};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const ExternalServiceAuthButtonIcon = styled(Favicon)`
  margin-right: 0.3rem;
`;

export const ExternalServiceAuthButton = styled(Button).attrs({
  fullWidth: true,
  size: ButtonSize.md,
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { colors } }) =>
    css`
      background-color: ${colors.ExternalProviderGoogle};
      border: 1px solid ${colors.BorderLight};
      color: ${colors.OnSurfaceHighEmphasis};
    `}
`;
