import React, { FC } from 'react';
import { Checkbox } from '../../../../shared/components/Checkbox';
import {
  ImportApp,
  ImportAppImage,
  ImportAppName,
  ImportAppsGridContainer,
  StyledImportAppsGrid,
} from './ImportAppsGrid.styled';
import type { DesktopAppApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

export interface ImportAppsGridProps {
  apps: DesktopAppApiType[];
  selectedApps: Set<any>;
  handleSelectApp: (appId: string) => void;
}

export const ImportAppsGrid: FC<ImportAppsGridProps> = ({
  apps,
  selectedApps,
  handleSelectApp,
}) => {
  return (
    <ImportAppsGridContainer>
      <StyledImportAppsGrid>
        {apps?.map((app: DesktopAppApiType) => {
          return (
            <ImportApp key={app.id} onClick={() => handleSelectApp(app.id)}>
              <Checkbox
                checked={selectedApps.has(app.id)}
                data-testid="import-app-checkbox"
                size={16}
              />
              <ImportAppImage src={app.logo.contentUrl} />
              <ImportAppName>{app.fullName}</ImportAppName>
            </ImportApp>
          );
        })}
      </StyledImportAppsGrid>
    </ImportAppsGridContainer>
  );
};
