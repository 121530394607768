import React, { FC, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { GlobalTranslation } from '../../Intl/i18n';
import { differenceInSeconds, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { SCHEDULED_DOWNTIME_TOAST_ID } from '../ScheduledDowntime.constants';

interface ScheduledDowntimeCounterProps {
  scheduledDowntime: string;
}

export const ScheduledDowntimeCounter: FC<ScheduledDowntimeCounterProps> = ({
  scheduledDowntime,
}) => {
  const [remainingTime, setRemainingTime] = useState<number>();

  useEffect(() => {
    if (remainingTime === 0) {
      toast.dismiss(SCHEDULED_DOWNTIME_TOAST_ID);
    }
    const interval = setInterval(() => {
      const currentTime = new Date();
      let timeDifference = differenceInSeconds(
        parseISO(scheduledDowntime),
        currentTime,
      );
      setRemainingTime(Math.ceil(timeDifference / 60));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [remainingTime, scheduledDowntime]);

  return (
    <FormattedHTMLMessage
      id={GlobalTranslation.scheduledDowntimeMessage}
      values={{
        expectedValue: remainingTime,
      }}
    />
  );
};
