import React, { FC, useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import {
  CustomConfigurationMapApiType,
  SsoConfigurationFieldApiType,
  WorkspaceAppConfigurationApiType,
} from '../../../../../AppStore.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../../shared/components/Button/Button';
import { StyledConfigureSsoForm } from './ConfigureSsoForm.styled';
import { AppStoreTranslation } from '../../../../../i18n';
import { SsoFormInput } from './SsoFormInput';
import { checkIfSsoCanBeToggled } from '../Configuration.utils';
import { SSO_CONFIG_FIELD_PREFIX } from '../Configuration.constants';
import { ClaimsMapper } from './ClaimsMapper';
import { SsoFormCheckbox } from './SsoFormCheckbox';
import { SsoFormSelect } from './SsoFormSelect';

export interface ConfigureSsoFormValues {
  [identifier: string]: string | boolean;
}

export type ConfigureSsoFormSubmitHandler = (
  formValues: ConfigureSsoFormValues,
  formikHelpers: FormikHelpers<ConfigureSsoFormValues>,
) => void;

interface ConfigureSsoFormProps {
  ssoConfigurationFields: SsoConfigurationFieldApiType[];
  workspaceConfig?: WorkspaceAppConfigurationApiType;
  customConfigurationMap?: CustomConfigurationMapApiType;
  onSubmit: ConfigureSsoFormSubmitHandler;
}

export const ConfigureSsoForm: FC<ConfigureSsoFormProps> = ({
  ssoConfigurationFields,
  workspaceConfig,
  customConfigurationMap,
  onSubmit,
}) => {
  const filteredSsoConfigurationFields = useMemo(
    () => ssoConfigurationFields.filter(field => !field.hidden),
    [ssoConfigurationFields],
  );

  const [readOnly, setReadOnly] = useState(() =>
    checkIfSsoCanBeToggled(filteredSsoConfigurationFields, workspaceConfig),
  );

  useEffect(() => {
    setReadOnly(
      checkIfSsoCanBeToggled(filteredSsoConfigurationFields, workspaceConfig),
    );
  }, [filteredSsoConfigurationFields, workspaceConfig]);

  const validationSchema: Yup.Schema<ConfigureSsoFormValues> = useMemo(
    () =>
      Yup.object().shape(
        filteredSsoConfigurationFields.reduce<{
          [field: string]: Yup.StringSchema | Yup.BooleanSchema;
        }>(
          (acc, field) => ({
            ...acc,
            [field.identifier]:
              field.type === 'boolean'
                ? Yup.boolean()
                : field.required
                ? Yup.string().required(
                    AppStoreTranslation.ssoAppConfigureFieldRequired,
                  )
                : field.required_enable && workspaceConfig?.enabled
                ? Yup.string().required(
                    AppStoreTranslation.ssoAppConfigureFieldRequiredEnabled,
                  )
                : Yup.string(),
          }),
          {},
        ),
      ),
    [filteredSsoConfigurationFields, workspaceConfig],
  );

  const initialValues = useMemo(
    () =>
      filteredSsoConfigurationFields.reduce<ConfigureSsoFormValues>(
        (acc, field) => ({
          ...acc,
          [field.identifier]:
            workspaceConfig?.values[field.identifier] ||
            ('default' in field && typeof field.default !== 'undefined'
              ? field.default
              : ''),
        }),
        {},
      ),
    [filteredSsoConfigurationFields, workspaceConfig],
  );

  const needsInitialize = useMemo(
    () =>
      !customConfigurationMap &&
      !workspaceConfig &&
      !filteredSsoConfigurationFields.find(field => field.required),
    [customConfigurationMap, filteredSsoConfigurationFields, workspaceConfig],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => (
        <Form autoComplete="off">
          <StyledConfigureSsoForm>
            {needsInitialize ? (
              <Button
                disabled={isSubmitting}
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                type="submit">
                <FormattedMessage
                  id={AppStoreTranslation.ssoAppCardLabelsInitialize}
                />
              </Button>
            ) : (
              <>
                <div className="form">
                  {filteredSsoConfigurationFields.map(field =>
                    field.type === 'boolean' ? (
                      <Field
                        type="checkbox"
                        key={field.identifier}
                        className="field"
                        name={field.identifier}
                        label={field.label}
                        component={SsoFormCheckbox}
                        disabled={readOnly}
                        id={`${SSO_CONFIG_FIELD_PREFIX}${field.identifier}`}
                        description={field.description}
                      />
                    ) : field.type === 'selection' ? (
                      <Field
                        key={field.identifier}
                        className="field"
                        name={field.identifier}
                        label={field.label}
                        component={SsoFormSelect}
                        disabled={readOnly}
                        id={`${SSO_CONFIG_FIELD_PREFIX}${field.identifier}`}
                        description={field.description}>
                        {field.options?.map(optionValue => (
                          <option key={optionValue} value={optionValue}>
                            {optionValue}
                          </option>
                        ))}
                      </Field>
                    ) : (
                      <Field
                        key={field.identifier}
                        className="field"
                        name={field.identifier}
                        label={field.label}
                        component={SsoFormInput}
                        readOnly={readOnly}
                        suffix={field.suffix}
                        id={`${SSO_CONFIG_FIELD_PREFIX}${field.identifier}`}
                        placeholder={field.placeholder}
                        description={field.description}
                      />
                    ),
                  )}
                  {customConfigurationMap && (
                    <ClaimsMapper
                      customConfigurationMap={customConfigurationMap}
                      workspaceConfig={workspaceConfig}
                      readOnly={readOnly}
                    />
                  )}
                </div>

                <div className={`sidebar${readOnly ? ' guarded' : ''}`}>
                  <Button
                    className="guard-btn"
                    mode={ButtonMode.secondary}
                    size={ButtonSize.md}
                    type="button"
                    onClick={() => {
                      setReadOnly(false);
                    }}>
                    <FormattedMessage
                      id={AppStoreTranslation.ssoAppCardLabelsEdit}
                    />
                  </Button>
                  <Button
                    className="submit-btn"
                    disabled={!isValid || isSubmitting}
                    mode={ButtonMode.primary}
                    size={ButtonSize.md}
                    type="submit">
                    <FormattedMessage
                      id={AppStoreTranslation.ssoAppCardLabelsSave}
                    />
                  </Button>
                </div>
              </>
            )}
          </StyledConfigureSsoForm>
        </Form>
      )}
    </Formik>
  );
};
