import { getShortId } from '../../shared/utils/id';
import { isAppleTouchDevice } from '../../shared/utils/user-agent';
import {
  ConferenceCallType,
  ConferenceCreateBy,
  ScheduleChatMembersApiType,
  ScheduleConferenceFormMembers,
  ScheduledChatConferenceApiType,
} from './Conference.types';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import {
  add,
  format,
  getHours,
  getMinutes,
  isSameDay,
  parseISO,
} from 'date-fns';
import { format as formatTZ, zonedTimeToUtc } from 'date-fns-tz';
import {
  SCHEDULED_CONFERENCE_END_AT_FORMAT,
  SCHEDULED_CONFERENCE_START_AT_FORMAT,
} from './Conference.constants';
import { isEmail } from '../../shared/utils/email.utils';
import { appEnv } from '../../appEnv';

interface ConferencePageLinksOptions {
  token?: string;
  ignoreAcceptMutation?: true;
}

export const getConferencePageLink = (
  conferenceId: string,
  { token, ignoreAcceptMutation }: ConferencePageLinksOptions = {},
): string => {
  const url = new URL(
    '/meet/' + getShortId(conferenceId),
    window.location.origin,
  );
  if (token) {
    url.searchParams.append('token', token);
  }
  if (ignoreAcceptMutation) {
    url.searchParams.append('ignoreAcceptMutation', '1');
  }
  return `${url.pathname}${url.search}`;
};

export const openConferenceWindow: (
  ...params: Parameters<typeof getConferencePageLink>
) => void = (conferenceId, options) => {
  const conferencePageLink = getConferencePageLink(conferenceId, options);
  const newWindow = window.open(
    conferencePageLink,
    '',
    `width=${window.innerWidth}px,height=${window.innerHeight}px`,
  );

  if (isAppleTouchDevice() && windowOpenIsBlocked(newWindow)) {
    window.location.href = conferencePageLink;
  }
};

export interface ConferenceCreateOptions {
  currentAccountId: string;
  currentWorkspaceId: string;
  callType: ConferenceCallType;
  createBy: ConferenceCreateBy;
  id?: string;
}

export const getConferenceCreateLink = (
  options: ConferenceCreateOptions,
): string => {
  const queryParamsString = getQueryParamsFrom({
    ...options,
    currentAccountId: getShortId(options.currentAccountId),
    currentWorkspaceId: getShortId(options.currentWorkspaceId),
    id: options.id ? getShortId(options.id) : undefined,
  });
  return `/meet?${queryParamsString}`;
};

export const openConferenceCreateWindow = (
  options: ConferenceCreateOptions,
) => {
  const conferenceCreateLink = getConferenceCreateLink(options);
  const newWindow = window.open(
    conferenceCreateLink,
    '',
    `width=${window.innerWidth}px,height=${window.innerHeight}px`,
  );

  if (isAppleTouchDevice() && windowOpenIsBlocked(newWindow)) {
    window.location.href = conferenceCreateLink;
  }
};

export const windowOpenIsBlocked = (windowObject: Window | null) => {
  return (
    !windowObject ||
    windowObject?.closed ||
    typeof windowObject?.closed === 'undefined'
  );
};

// Format should be yyyy-mm-ddT00:00:00 + selectedTimezone offset
export const getFormattedDate = (
  date: Date,
  selectedDay: Date,
  timezone: string,
) => {
  const currentDate = add(selectedDay, {
    hours: getHours(date),
    minutes: getMinutes(date),
  });

  return zonedTimeToUtc(currentDate, timezone);
};

export const getTZOffsetFromTZName = (timeZone: string): string => {
  return formatTZ(new Date(), 'zzz', { timeZone });
};

export const getScheduledConferenceFormattedDate = (
  startDate: string,
  endDate: string,
) => {
  return `${format(
    parseISO(startDate),
    isSameDay(new Date(startDate), new Date(endDate))
      ? appEnv.TIME_FORMAT
      : SCHEDULED_CONFERENCE_START_AT_FORMAT,
  )} to ${format(
    parseISO(endDate),
    isSameDay(new Date(startDate), new Date(endDate))
      ? appEnv.TIME_FORMAT
      : SCHEDULED_CONFERENCE_END_AT_FORMAT,
  )}`;
};

export const getScheduledConferenceDay = (startDate: string) => {
  return `${format(parseISO(startDate), 'MMM')}`;
};

export const getScheduledConferenceMonth = (startDate: string) => {
  return `${format(parseISO(startDate), 'dd')}`;
};

export const sortScheduledConferencesByStartAt = (
  conferences: { __typename?: string; node: ScheduledChatConferenceApiType }[],
) => {
  return conferences.sort(
    (
      a: { __typename?: string; node: ScheduledChatConferenceApiType },
      b: { __typename?: string; node: ScheduledChatConferenceApiType },
    ) => {
      return (
        Number(new Date(a.node.startAt)) - Number(new Date(b.node.startAt))
      );
    },
  );
};

export const getAddedAndDeletedMembers = (
  modifiedMembers: ScheduleConferenceFormMembers[],
  initialMembers: ScheduleChatMembersApiType[],
) => {
  const usersIds = modifiedMembers.map(member => {
    return member.email ? member.email : getShortId(member.value);
  });
  const formattedInitialUsersIds = initialMembers.map(userId =>
    userId.email
      ? userId.email
      : userId.account
      ? getShortId(userId.account.id)
      : '',
  );
  const deletedMembers = initialMembers
    .filter(
      member =>
        !usersIds.includes(
          (member.account ? getShortId(member.account.id) : member.email) || '',
        ),
    )
    .map(member => getShortId(member.id));
  const addedMembers = usersIds
    .filter(member => !formattedInitialUsersIds.includes(member))
    .map(memberId =>
      isEmail(memberId) ? { email: memberId } : { accountId: memberId },
    );
  return { addedMembers, deletedMembers };
};

export const getRoundedNextHour = () => {
  return add(new Date().setMinutes(0), { hours: 1 });
};
