import React, {
  FC,
  FocusEvent,
  PropsWithChildren,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AddFolderIcon,
  AddIcon,
  ChatIcon,
  KebabIcon,
  MaximiseIcon,
  SearchIcon,
} from '../../../../../../../shared/icons';
import {
  Controls,
  SearchInputContainer,
  Separator,
  StyledSectionTitlebar,
  TabbedControls,
  Title,
} from './SectionTitleBar.styled';
import { TitleBarButton } from './TitleBarButton/TitleBarButton';
import { SearchInput } from './SearchInput/SearchInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../../../../../../shared/hooks';
import { useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../../../i18n';
import { onComponentBlur } from '../../../../../../../shared/utils/blur.util';
import { FolderCrumbs } from '../../../../../../Folder/Views/FolderCrumbs/FolderCrumbs';
import { getQueryParamsFrom } from '../../../../../../../shared/utils/url.utils';
import { FolderCrumbsLocationContext } from '../../../../../../Folder/Folder.constants';
import {
  useCurrentDesktop,
  useCurrentDesktopContent,
} from '../../../../../Desktop.hooks';
import { DesktopLayout, DesktopTab } from '../../../../../Desktop.constants';
import { ChatMessageThreadSidebarContext } from '../../../../../../Chat/ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.context';

type SectionTitleBarProps = {
  identifier: string;
  title: string;
  itemsCount?: number;
  tabPath?: string;
  chatLinkPath?: string;
  chatLinkTooltipText?: string;
  menuRef?: Ref<HTMLButtonElement>;
  addItemHandler?: (() => void) | null;
  addItemTooltipText?: string;
  addFolderHandler?: (() => void) | null;
  addFolderTooltipText?: string;
  searchInputOnChange?: (value: string) => void;
  searchInputTooltipText?: string;
  searchInputPlaceholderText?: string;
  displayMaximizeButton?: boolean;
  contentLayoutSelectorComponent?: ReactNode;
};

export const SectionTitleBar: FC<PropsWithChildren<SectionTitleBarProps>> = ({
  identifier,
  title,
  itemsCount,
  tabPath,
  chatLinkPath,
  chatLinkTooltipText,
  menuRef,
  addItemHandler,
  addItemTooltipText,
  addFolderHandler,
  addFolderTooltipText,
  searchInputOnChange,
  searchInputTooltipText,
  searchInputPlaceholderText = '',
  displayMaximizeButton = false,
  contentLayoutSelectorComponent,
  children,
}) => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const sectionSearchRef = useRef<any>();
  const [searchInputIsActive, setSearchInputIsActive] = useState(false);
  const queryParams = useQueryParams();
  const desktop = useCurrentDesktop();
  const { desktopLayout, setDesktopLayout } = useCurrentDesktopContent();
  const { setDisableLastCommentAt } = useContext(
    ChatMessageThreadSidebarContext,
  );

  const { tab } = useParams<{ tab?: DesktopTab }>();

  const openInTabHandler = useCallback(() => {
    if (tabPath) {
      setDesktopLayout(DesktopLayout.TABBED);
      navigate({
        pathname: tabPath,
        search: getQueryParamsFrom(queryParams),
      });
    }
  }, [navigate, queryParams, setDesktopLayout, tabPath]);

  const openLinkHandler = useCallback(() => {
    if (chatLinkPath) {
      navigate(chatLinkPath);
    }
  }, [navigate, chatLinkPath]);

  const enableSearchHandler = useCallback(() => {
    setSearchInputIsActive(true);
  }, []);

  const closeSearchInput = useCallback(() => {
    setSearchInputIsActive(false);
  }, []);

  const onBlurComponentHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onComponentBlur(e, () => {
        if (sectionSearchRef.current) {
          sectionSearchRef.current.forceBlur();
        }
      });
    },
    [],
  );

  useEffect(() => {
    setDisableLastCommentAt(desktopLayout === DesktopLayout.CONSOLIDATED);
  }, [desktopLayout, setDisableLastCommentAt]);

  // hide search input when a folder is clicked
  useEffect(() => {
    setSearchInputIsActive(false);
  }, [queryParams.folderId]);

  return (
    <StyledSectionTitlebar
      isMobile={isMobile}
      isTabbed={desktopLayout === DesktopLayout.TABBED}>
      {desktopLayout === DesktopLayout.CONSOLIDATED && (
        <Title data-testid="section-title">
          {title}
          {itemsCount !== undefined && itemsCount >= 0 && (
            <span className="items-count" data-testid="items-count">
              {itemsCount}
            </span>
          )}
        </Title>
      )}
      {desktopLayout === DesktopLayout.TABBED && !searchInputIsActive && (
        <TabbedControls>
          {contentLayoutSelectorComponent}
          {desktopLayout === DesktopLayout.TABBED &&
            tab === DesktopTab.links && (
              <FolderCrumbs
                locationContext={FolderCrumbsLocationContext.LINKS_TAB}
                injectedData={{
                  desktop: desktop!,
                }}
              />
            )}
        </TabbedControls>
      )}
      <Controls>
        {children}
        {searchInputOnChange && desktop && (
          <SearchInputContainer
            isActive={searchInputIsActive}
            onBlur={onBlurComponentHandler}>
            {!searchInputIsActive && (
              <TitleBarButton
                icon={SearchIcon}
                identifier={`${identifier}-enable-search`}
                tooltipText={
                  searchInputTooltipText ? searchInputTooltipText : null
                }
                onClick={enableSearchHandler}
                data-testid="enable-search"
              />
            )}
            {searchInputIsActive && (
              <SearchInput
                ref={sectionSearchRef}
                placeholder={searchInputPlaceholderText}
                onChange={searchInputOnChange}
                clearSearch={closeSearchInput}
                onBlur={closeSearchInput}
              />
            )}
          </SearchInputContainer>
        )}
        {!isMobile && !searchInputIsActive && (
          <>
            {addFolderHandler && (
              <TitleBarButton
                fullsizeIcon
                icon={AddFolderIcon}
                identifier={`${identifier}-add-folder`}
                tooltipText={addFolderTooltipText ? addFolderTooltipText : null}
                onClick={addFolderHandler}
                data-testid="section-add-folder"
              />
            )}
            {addItemHandler && (
              <TitleBarButton
                icon={AddIcon}
                identifier={`${identifier}-add-item`}
                tooltipText={addItemTooltipText ? addItemTooltipText : null}
                onClick={addItemHandler}
                data-testid="section-add-item"
              />
            )}
            {chatLinkPath && (
              <TitleBarButton
                icon={ChatIcon}
                identifier={`${identifier}-link`}
                tooltipText={chatLinkTooltipText ? chatLinkTooltipText : null}
                data-testid="section-link"
                onClick={openLinkHandler}
              />
            )}
          </>
        )}
        {(!isMobile || (isMobile && !searchInputIsActive)) && (
          <>
            {menuRef && (
              <TitleBarButton
                icon={KebabIcon}
                identifier={`${identifier}-menu`}
                tooltipText={formatMessage({
                  id: DesktopTranslation.sectionTitlebarOptionsTooltip,
                })}
                data-testid="section-options"
                ref={menuRef}
              />
            )}
            {tabPath &&
              desktopLayout === DesktopLayout.CONSOLIDATED &&
              displayMaximizeButton && (
                <>
                  <Separator />
                  <TitleBarButton
                    noMargin
                    icon={MaximiseIcon}
                    identifier={`${identifier}-open-in-tab`}
                    tooltipText={formatMessage({
                      id: DesktopTranslation.sectionTitlebarMaximiseTooltip,
                    })}
                    onClick={openInTabHandler}
                    data-testid="section-open-tab"
                  />
                </>
              )}
          </>
        )}
      </Controls>
    </StyledSectionTitlebar>
  );
};
