import { gql } from '@apollo/client';

export const SUGGEST_APPS_FROM_BROWSER_HISTORY = gql`
  mutation SuggestAppsFromBrowserHistory(
    $input: suggestAppsFromBrowserHistoryInput!
  ) {
    suggestAppsFromBrowserHistory(input: $input) {
      clientMutationId
    }
  }
`;

export interface SuggestAppsFromBrowserHistoryVariables {
  input: {
    history: string[];
  };
}

export interface SuggestAppsFromBrowserHistoryResponse {
  clientMutationId: string;
}

export const ADD_APPS_DESKTOP = gql`
  mutation AddAppsDesktop($input: addAppsDesktopInput!) {
    addAppsDesktop(input: $input) {
      clientMutationId
    }
  }
`;

export interface AddAppsDesktopVariables {
  input: {
    id: string;
    apps: string[];
  };
}

export interface AddAppsDesktopResponse {
  clientMutationId: string;
}

export const IMPORT_BOOKMARK = gql`
  mutation ImportBookmark($input: importBookmarkInput!) {
    importBookmark(input: $input) {
      clientMutationId
    }
  }
`;

export interface ImportBookmarkVariables {
  input: {
    desktop: string;
    bookmarks: any[];
  };
}

export interface ImportBookmarkResponse {
  clientMutationId: string;
}
