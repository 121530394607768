import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useMobile, useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { LinkTranslation } from '../../../i18n';

const StyledLinkDetailsSidebarHeader = styled.div<{ solidBackground: boolean }>`
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Divider};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.m};

  ${({ solidBackground }) =>
    solidBackground &&
    css`
      background-color: ${({ theme }) => theme.colors.Background};
    `}
`;

export const LinkDetailsSidebarHeader: FC<{
  heading: string;
  solidBackground?: boolean;
  commentsContext?: boolean;
}> = ({ heading, solidBackground = false, commentsContext = false }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const isMobile = useMobile();
  const { formatMessage } = useIntl();

  const hideSidebarHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        linkDetailsTab: undefined,
      }),
    });
  }, [navigate, queryParams]);

  if (isMobile) return null;

  return (
    <StyledLinkDetailsSidebarHeader
      solidBackground={solidBackground}
      data-testid="sidebar-header">
      {heading}
      <Button
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: commentsContext
            ? LinkTranslation.tooltipHideLinkComments
            : LinkTranslation.tooltipHideLinkDetails,
        })}
        data-tooltip-place={TooltipPlace.top}
        mode={ButtonMode.secondary}
        size={ButtonSize.sm}
        onClick={hideSidebarHandler}
        data-testid="hide-button">
        Hide
      </Button>
    </StyledLinkDetailsSidebarHeader>
  );
};
