import React, { FC, useMemo } from 'react';
import { LinkApiType, LinkStatus } from '../../../Link.types';
import {
  LinkImageContainer,
  LinkImagePlaceholder,
  StyledLinkImage,
} from './LinkImage.styled';
import { Spinner } from '../../../../../shared/components/Spinner';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from './../Link.constants';
import {
  dimensionsToKeys,
  ImageFormat,
} from '../../../../../shared/utils/image.utils';

type LinkImageProps = {
  link: LinkApiType;
  width?: number;
  height?: number;
  fullSize?: boolean;
};

type ImageInfoType =
  | { url: string; width: number; height: number; format: ImageFormat }
  | undefined;

export const LinkImage: FC<LinkImageProps> = ({
  link,
  width = IMAGE_WIDTH,
  height = IMAGE_HEIGHT,
  fullSize = false,
}) => {
  const {
    customImage,
    data: { image, screenshot },
  } = link;

  let imageInfo: ImageInfoType = useMemo(() => {
    const imageAsset = customImage || image || screenshot;

    if (!imageAsset) {
      return;
    }
    const size = dimensionsToKeys(imageAsset);
    return {
      url: imageAsset.contentUrl,
      ...size,
    };
  }, [customImage, image, screenshot]);

  const fitValue = !imageInfo
    ? null
    : imageInfo.format === ImageFormat.LANDSCAPE
    ? 'crop'
    : 'contain';

  return imageInfo ? (
    <LinkImageContainer fullSize={fullSize}>
      <StyledLinkImage
        height={height}
        srcSet={`${imageInfo.url}?w=${width}&h=${height}&fit=${fitValue} 1x,
          ${imageInfo.url}?w=${width * 2}&h=${height * 2}&fit=${fitValue} 2x,`}
        data-testid="link-image"
      />
    </LinkImageContainer>
  ) : (
    <LinkImageContainer fullSize={fullSize}>
      <LinkImagePlaceholder data-testid="link-image-placeholder">
        {link.data.status === LinkStatus.PENDING && <Spinner />}
      </LinkImagePlaceholder>
    </LinkImageContainer>
  );
};
