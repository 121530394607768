import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledCustomAppBanner = styled.div`
  background-color: ${p => p.theme.colors.Background};
  border-radius: 0.25rem;
  padding: 1rem;
`;

export const BannerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const BannerDescription = styled.p`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
`;

export const BannerButton = styled(ButtonWithIcon)`
  width: 100%;
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.Primary};

  & span ~ svg {
    margin-left: 0.375rem;
  }
`;
