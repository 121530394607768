import { GraphQLError } from 'graphql';
import { getApolloClient } from '../../../Api/Api.utils';
import {
  CreateFolderResponse,
  CreateFolderVariables,
  CREATE_FOLDER,
} from '../Operations/Mutations/CreateFolder.mutation';
import {
  RemoveFolderResponse,
  RemoveFolderVariables,
  REMOVE_FOLDER,
} from '../Operations/Mutations/DeleteFolder.mutation';
import {
  EditFolderInputVariables,
  EditFolderResponse,
  EditFolderVariables,
  EDIT_FOLDER,
} from '../Operations/Mutations/EditFolder.mutation';

/**
 * Edit folder using API
 * @param folderId
 * @param data
 * @returns Folder
 */
export const editFolderApi = async (
  folderId: string,
  updatedData: Omit<EditFolderInputVariables, 'id'>,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: EditFolderResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      EditFolderResponse,
      EditFolderVariables
    >({
      mutation: EDIT_FOLDER,
      variables: {
        input: {
          id: folderId,
          ...updatedData,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Create folder using API
 * @param name Folder name
 * @param desktop Desktop ID
 * @param parent Parent folder ID
 * @returns Folder
 */
export const createFolderApi = async (
  name: string,
  desktop: string,
  parent?: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: CreateFolderResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      CreateFolderResponse,
      CreateFolderVariables
    >({
      mutation: CREATE_FOLDER,
      variables: {
        input: {
          name,
          desktop,
          parent,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

/**
 * Delete folder using API
 * @param folderId
 */
export const deleteFolderApi = async (
  folderId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: RemoveFolderResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      RemoveFolderResponse,
      RemoveFolderVariables
    >({
      mutation: REMOVE_FOLDER,
      variables: {
        input: {
          id: folderId,
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
