import type { RecentAppsApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import type { RecentLinksApiType } from '../../../../Link/Link.types';

export const makeRecentApp = (item: RecentAppsApiType) => ({
  id: item.desktopApp.id,
  app: {
    id: item.desktopApp.app.id,
    groupAppId: item.desktopApp.app.groupApp?.id,
    name: item.desktopApp.app.name,
    fullName: item.desktopApp.app.fullName,
    logo: item.desktopApp.app.logo,
    uri: item.desktopApp.app.loginUri,
    loginUriPattern: item.desktopApp.app.loginUriPattern,
  },
  desktop: {
    id: item.desktopApp.desktop?.id,
  },
});

export const makeRecentLink = (item: RecentLinksApiType) => ({
  id: item.link.id,
  customTitle: item.link.customTitle,
  data: item.link.data,
  desktop: { id: item.link.desktopId },
  folder: item.link.folder,
});
