import React, { FC, useCallback } from 'react';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../Workspace/Workspace.hooks';
import {
  useAccountAvailableForCall,
  useConferenceParticipants,
  useCurrentConference,
} from '../../../Conference.hooks';
import { CHATS_CONVERSATION_PATHNAME } from '../../../../Desktop/Desktop.constants';
import { getShortId } from '../../../../../shared/utils/id';
import {
  ConferenceCallType,
  ConferenceCreateBy,
} from '../../../Conference.types';
import {
  openConferenceCreateWindow,
  openConferenceWindow,
} from '../../../Conference.utils';
import { CallIcon, VideoCallIcon } from '../../../../../shared/icons';
import { MessageIcon } from '../../../../Chat/icons';
import { NavigatorActionButton } from '../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const ConferenceTitleControls: FC = () => {
  const navigate = useNavigate();
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const { conference } = useCurrentConference();
  const { formatMessage } = useIntl();

  const handleMessageClick = useCallback(() => {
    if (conference?.chatConversationIri) {
      navigate(
        generatePath(CHATS_CONVERSATION_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          conversationId: getShortId(conference.chatConversationIri),
        }),
      );
    }
  }, [navigate, workspace.id, conference]);

  const handleJoinClick = useCallback(() => {
    if (conference) {
      openConferenceWindow(conference.id);
    }
  }, [conference]);

  const otherParticipants = useConferenceParticipants(conference, true);
  const interlocutor =
    !conference?.desktop && otherParticipants.length === 1
      ? otherParticipants[0]
      : null;
  const availableForCall =
    useAccountAvailableForCall(interlocutor?.id) && !!otherParticipants?.length;

  if (!conference) {
    return null;
  }

  return (
    <>
      <NavigatorActionButton
        data-tooltip-content={formatMessage({
          id: ConferenceTranslation.conferenceViewControlMessage,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={MessageIcon}
        iconSize={20}
        onClick={handleMessageClick}
        data-testid="conference-message-button"
        disabled={!conference.desktop && !otherParticipants?.length}
      />

      {conference.statistics.has_ended ||
      conference.callType === ConferenceCallType.audio ? (
        <>
          <NavigatorActionButton
            data-tooltip-content={formatMessage({
              id: ConferenceTranslation.conferenceViewControlVideoCall,
            })}
            data-tooltip-place={TooltipPlace.bottom}
            icon={VideoCallIcon}
            iconSize={20}
            onClick={() => {
              openConferenceCreateWindow({
                currentAccountId: account.id,
                currentWorkspaceId: workspace.id,
                callType: ConferenceCallType.video,
                createBy: ConferenceCreateBy.conversationId,
                id: conference.chatConversationIri,
              });
            }}
            data-testid="conference-videocall-button"
            disabled={!availableForCall}
          />
        </>
      ) : (
        <ButtonWithIcon
          data-testid="conference-join-videocall-button"
          onClick={handleJoinClick}
          mode={ButtonMode.primary}
          size={ButtonSize.sm}
          icon={VideoCallIcon}
          iconFirst>
          <FormattedMessage
            id={ConferenceTranslation.conferenceViewControlJoinVideoCall}
          />
        </ButtonWithIcon>
      )}

      {conference.statistics.has_ended ||
      conference.callType === ConferenceCallType.video ? (
        <>
          <NavigatorActionButton
            data-tooltip-content={formatMessage({
              id: ConferenceTranslation.conferenceViewControlCall,
            })}
            data-tooltip-place={TooltipPlace.bottom}
            icon={CallIcon}
            iconSize={20}
            onClick={() => {
              openConferenceCreateWindow({
                currentAccountId: account.id,
                currentWorkspaceId: workspace.id,
                callType: ConferenceCallType.audio,
                createBy: ConferenceCreateBy.conversationId,
                id: conference.chatConversationIri,
              });
            }}
            data-testid="conference-audiocall-button"
            disabled={!availableForCall}
          />
        </>
      ) : (
        <ButtonWithIcon
          data-testid="conference-join-audiocall-button"
          onClick={handleJoinClick}
          mode={ButtonMode.primary}
          size={ButtonSize.sm}
          icon={CallIcon}
          iconFirst>
          <FormattedMessage
            id={ConferenceTranslation.conferenceViewControlJoinCall}
          />
        </ButtonWithIcon>
      )}
    </>
  );
};
