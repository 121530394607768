import React, { FC, Ref, useCallback, useRef } from 'react';
import {
  InboxListHeader,
  InboxListItems,
  InboxListKebab,
  InboxListTitle,
  StyledInboxList,
  StyledTabs,
} from './InboxList.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../shared/components/Menu';
import { CheckmarkIcon, KebabIcon } from '../../../shared/icons';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { InboxTranslation } from '../i18n';
import {
  useInbox,
  useMarkAllMentionsAsReadMutation,
  useMarkAllThreadsAsReadMutation,
} from '../Inbox.hooks';
import { GET_MENTIONS, GET_THREADS } from '../Inbox.queries';
import {
  MENTIONS_INITIAL_LOAD_COUNT,
  THREADS_INITIAL_LOAD_COUNT,
} from '../Inbox.constants';
import { useHideScrollbarWhenIdle } from '../../../shared/hooks/element.hooks';
import {
  StyledTab,
  StyledTabList,
  TabsType,
} from '../../../shared/components/Tabs';
import { MentionsList } from './MentionsList';
import { ThreadsList } from './ThreadsList';
import { CounterType, useUnreadCounter } from '../../Counters';
import {
  CountBadge,
  PositionType,
} from '../../../shared/components/CountBadge';
import { TooltipPlace } from '../../../shared/components/Tooltip';

interface InboxListProps {
  selectedTab: number;
  onTabChange: (tabIndex: number) => void;
}

export const InboxList: FC<InboxListProps> = ({ selectedTab, onTabChange }) => {
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const inboxContext = useInbox();
  const [markAllMentionsAsReadMutation] = useMarkAllMentionsAsReadMutation();
  const [markAllThreadsAsReadMutation] = useMarkAllThreadsAsReadMutation();
  const { formatMessage } = useIntl();
  const KebabRef: Ref<HTMLButtonElement> = useRef(null);

  const markAllMentionsAsRead = useCallback(() => {
    markAllMentionsAsReadMutation({
      variables: {
        input: {
          workspace: workspace!.id,
        },
      },
      refetchQueries: [
        {
          query: GET_MENTIONS,
          variables: {
            accountId: account.id,
            workspaceId: workspace!.id,
            first: MENTIONS_INITIAL_LOAD_COUNT,
          },
        },
      ],
    });
  }, [markAllMentionsAsReadMutation, workspace, account.id]);

  const markAllThreadsAsRead = useCallback(() => {
    markAllThreadsAsReadMutation({
      variables: {
        input: {
          workspace: workspace!.id,
        },
      },
      refetchQueries: [
        {
          query: GET_THREADS,
          variables: {
            accountId: account.id,
            workspaceId: workspace!.id,
            first: THREADS_INITIAL_LOAD_COUNT,
          },
        },
      ],
    });
  }, [markAllThreadsAsReadMutation, workspace, account.id]);

  const [inboxListItemsRef] = useHideScrollbarWhenIdle();

  const unreadMentions = useUnreadCounter(workspace.id, CounterType.Mentions);
  const unreadThreads = useUnreadCounter(workspace.id, CounterType.Threads);

  return (
    <>
      <StyledInboxList data-testid="inbox-list">
        <InboxListHeader data-testid="inbox-header">
          <InboxListTitle data-testid="inbox-title">
            <FormattedMessage id={InboxTranslation.inboxHeader} />
          </InboxListTitle>
          <StyledTabs selectedIndex={selectedTab} onSelect={onTabChange}>
            <StyledTabList type={TabsType.switcher}>
              <StyledTab
                data-tooltip-place={TooltipPlace.top}
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: InboxTranslation.tooltipInboxMentionsTab,
                })}>
                <FormattedMessage id={InboxTranslation.mentions} />
                <CountBadge
                  count={unreadMentions}
                  limitCount
                  positionMode={PositionType.relative}
                  className="count-badge"
                />
              </StyledTab>
              <StyledTab
                data-tooltip-place={TooltipPlace.top}
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: InboxTranslation.tooltipInboxThreadsTab,
                })}>
                <FormattedMessage id={InboxTranslation.threads} />
                <CountBadge
                  count={unreadThreads}
                  limitCount
                  positionMode={PositionType.relative}
                  className="count-badge"
                />
              </StyledTab>
            </StyledTabList>
          </StyledTabs>
          <InboxListKebab
            ref={KebabRef}
            data-tooltip-place={TooltipPlace.top}
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: InboxTranslation.tooltipInboxOptionsButton,
            })}
            data-testid="inbox-three-dots">
            <KebabIcon />
          </InboxListKebab>
          <Menu
            trigger={KebabRef}
            onOpen={() => inboxContext.keepInboxOpen(true)}
            onClose={() => inboxContext.keepInboxOpen(false)}
            width={230}
            vAlign={VerticalAlignment.BOTTOM}
            hAlign={HorizontalAlignment.RIGHT}
            data-testid="menu">
            <MenuItem
              icon={CheckmarkIcon}
              onClick={
                selectedTab === 0 ? markAllMentionsAsRead : markAllThreadsAsRead
              }
              data-testid="inbox-read-all">
              <FormattedMessage id={InboxTranslation.markAllRead} />
            </MenuItem>
          </Menu>
        </InboxListHeader>
        <InboxListItems ref={inboxListItemsRef}>
          {selectedTab === 0 ? <MentionsList /> : <ThreadsList />}
        </InboxListItems>
      </StyledInboxList>
    </>
  );
};
