import { useParams, useResolvedPath } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getRoutePath } from '../utils/router.utils';

export const useCurrentRoutePath = () => {
  const location = useLocation();
  const params = useParams();
  return getRoutePath(location, params);
};

export const useCurrentRouteUrl = () => {
  return useResolvedPath('').pathname;
};
