import styled from 'styled-components';

export const LinkAccountMFAWarningContainer = styled.div`
  margin-top: 1rem;
`;
export const StyledLinkAccountMFAWarning = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkAccountMFAWarningLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
