import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { ConferenceTranslation } from '../i18n';
import { getAccountInitials, getAccountName } from '../../User/User.utils';
import { getGlideImageSrcSet } from '../../../shared/utils/url.utils';
import { OnlineStatusCircle } from '../../Account/components/OnlineStatus/OnlineStatusCircle';
import { StyledConferenceAvatar } from './ConferenceAvatar.styled';
import { useConferenceParticipants } from '../Conference.hooks';
import { ConferenceAvatarProps } from './ConferenceAvatar';

const IMAGE_SIZE = 64;

// TODO: move ConferenceUserAvatar abd ChatAvatar components logic into UserAvatar component (User domain)
export const ConferenceAvatarUser = React.forwardRef<
  HTMLDivElement,
  ConferenceAvatarProps
>(
  (
    {
      conference,
      onClick,
      avatarSize = '2rem',
      showOnlineStatus = true,
      onlineStatusSize = '0.5rem',
      onlineStatusBorderColor,
      className,
    },
    ref,
  ) => {
    const intl = useIntl();
    const theme = useTheme();

    const conferenceAccounts = useConferenceParticipants(conference, true);
    const conferenceAccount = conferenceAccounts[0];
    const onlineStatus = conferenceAccount?.onlineStatus;

    // TODO: add empty state
    if (!conferenceAccount) {
      return null;
    }

    return (
      <StyledConferenceAvatar
        ref={ref}
        className={className}
        avatarSize={avatarSize}
        onlineStatusBorderColor={
          onlineStatusBorderColor || theme.colors.OnlineStatusDefaultBorder
        }
        onClick={onClick}>
        {conferenceAccount.image ? (
          <img
            src={conferenceAccount.image.contentUrl}
            srcSet={getGlideImageSrcSet(conferenceAccount.image.contentUrl, {
              w: IMAGE_SIZE,
              h: IMAGE_SIZE,
              fit: 'crop-center',
            })}
            alt={intl.formatMessage(
              {
                id: ConferenceTranslation.conferenceAvatarAlt,
              },
              {
                accountName: getAccountName(conferenceAccount),
              },
            )}
          />
        ) : (
          <div className="initials">
            {getAccountInitials(conferenceAccount)}
          </div>
        )}
        {showOnlineStatus && onlineStatus && (
          <OnlineStatusCircle
            onlineStatus={onlineStatus}
            circleSize={onlineStatusSize}
          />
        )}
      </StyledConferenceAvatar>
    );
  },
);
