import React from 'react';
import { LinkApiType } from '../../Link/Link.types';

interface DesktopCopyPasteContextType {
  copiedLinks: LinkApiType[];
  copyLinks: (links: LinkApiType[]) => void;
}

export const DesktopCopyPasteContext =
  React.createContext<DesktopCopyPasteContextType>({
    copiedLinks: [],
    copyLinks: () => {},
  });
