import { extractNodes } from '../../../../shared/api/api.utils';
import { getLongId } from '../../../../shared/utils/id';
import { database } from '../../../Database';
import { DesktopAppApiTypeToDesktopAppsTableRow } from '../../../Database/DesktopAppsTable/DesktopAppsTable.utils';
import {
  addAppToDesktopApi,
  deleteAppFromDesktopApi,
} from '../DataSource/AppStoreApiDataSource';

export const addAppToDesktopRepository = async (
  workspaceId: string,
  desktopId: string,
  appId: string,
) => {
  const response = await addAppToDesktopApi(desktopId, appId);
  const { data } = response;
  if (data) {
    try {
      const appsRowsArray = extractNodes(data.addAppDesktop.desktop.apps).map(
        app =>
          DesktopAppApiTypeToDesktopAppsTableRow(app, desktopId, workspaceId),
      );
      database.desktopApps.bulkPut(appsRowsArray);
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};

export const deleteAppFromDesktopRepository = async (
  workspaceId: string,
  desktopId: string,
  appId: string,
) => {
  const response = await deleteAppFromDesktopApi(desktopId, appId);
  // todo: optimistic?
  const { data } = response;
  if (data) {
    // Update dexie database with new folder
    try {
      const desktopAppsArray = await database.desktopApps
        .where({ desktopId: getLongId('desktops', desktopId) })
        .toArray();

      const deleteDesktopApp = desktopAppsArray.filter(
        desktopapp => desktopapp.sourceData.app.id === getLongId('apps', appId),
      )[0];

      if (deleteDesktopApp) {
        database.desktopApps.delete(deleteDesktopApp.id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return response;
};
