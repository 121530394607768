import React, { FC, MutableRefObject, useMemo } from 'react';
import { useGetRecentAppsQuery } from '../../../../Desktop/Desktop.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { AppItem } from '../SearchItems';
import { GlobalSearchResultAppHit } from '../../../GlobalSearch.queries';
import { GlobalSearchTranslation } from '../../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  StyledRecentTitle,
  StyledSearchRecentWrapper,
} from './RecentViews.styled';
import { makeRecentApp } from './RecentViews.utils';

export const RecentApps: FC<{
  onBlur: () => void;
  skipBlurRef: MutableRefObject<boolean>;
}> = ({ onBlur, skipBlurRef }) => {
  const { workspace } = useCurrentWorkspace();
  const { data, loading } = useGetRecentAppsQuery({
    variables: {
      workspace: workspace.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const apps = useMemo(
    () => data?.getRecentApps.map(item => makeRecentApp(item)),
    [data],
  );

  if (loading) {
    return <Spinner size={64} />;
  }

  return (
    <StyledSearchRecentWrapper>
      <StyledRecentTitle>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchRecentApps}
        />
      </StyledRecentTitle>
      {apps?.length
        ? apps.map((item, idx) => (
            <AppItem
              {...(item as GlobalSearchResultAppHit)}
              key={`${item.id}-${idx}`}
              onBlur={onBlur}
              skipBlurRef={skipBlurRef}
            />
          ))
        : null}
    </StyledSearchRecentWrapper>
  );
};
