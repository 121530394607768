import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { setDefaultImage } from '../../../../../../../shared/utils/image.utils';
import { ChatTranslation } from '../../../../../i18n';
import UploadImagePlaceholder from '../../../../../../../shared/assets/images/image-upload.svg';
import { useIntl } from 'react-intl';
import {
  AssetViewCounter,
  AssetViewImageContainerStyled,
  AssetViewImageStyled,
  imageOrientation,
  ImageOrientationType,
  imageSrcSet,
  LANDSCAPE_MAX_HEIGHT,
  LANDSCAPE_WIDTH,
} from '.';

import { useMobile } from '../../../../../../../shared/hooks';
import { ChatMessageAssetApiType } from '../../../../../Chat.types';
import { useImageSize } from './AssetView.hooks';

interface AssetViewImageProps {
  asset: ChatMessageAssetApiType;
  multipleImage: boolean;
  showPreview: (e: React.SyntheticEvent) => void;
  assetsLength: number;
  assetCounter?: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const RETRY_SET_IMAGE_TIMEOUT = 3000;

export const AssetViewImage: FC<PropsWithChildren<AssetViewImageProps>> = ({
  asset,
  showPreview,
  multipleImage,
  assetsLength,
  assetCounter,
  onMouseEnter,
  onMouseLeave,
  children,
  ...props
}) => {
  const needSetDefaultImg = useRef(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const [needRetryRenderImage, setNeedRetryRenderImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);

  const intl = useIntl();

  const isMobile = useMobile();

  const orientation: ImageOrientationType = imageOrientation(asset.dimensions);

  const { width, height } = useImageSize(
    asset.dimensions,
    orientation,
    multipleImage,
  );

  const imageAssetUrl = imageUrl ? imageUrl : asset.url;

  useEffect(() => {
    if (needRetryRenderImage) {
      setTimeout(() => {
        setImageUrl(asset.url + `&t=${new Date().getTime()}`);
        setNeedRetryRenderImage(false);
        needSetDefaultImg.current = true;
      }, RETRY_SET_IMAGE_TIMEOUT);
    }
  }, [asset.url, needRetryRenderImage]);

  const imageErrorHandler = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (!needRetryRenderImage && !needSetDefaultImg.current) {
        setNeedRetryRenderImage(true);
      }

      if (needSetDefaultImg.current && !needRetryRenderImage) {
        setDefaultImage(event.target, UploadImagePlaceholder, 102);
      }
    },
    [needRetryRenderImage],
  );

  const srcSet = imageSrcSet(
    orientation,
    multipleImage,
    imageAssetUrl,
    asset.dimensions,
    isMobile,
  );

  const imageOnload = useCallback(() => {
    setImageLoaded(true);
  }, []);

  return (
    <AssetViewImageContainerStyled
      orientation={orientation}
      multipleImage={multipleImage}
      naturalWidth={width}
      naturalHeight={height}
      isMobile={isMobile}
      imageCount={assetsLength}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      imageLoaded={imageLoaded}
      {...props}>
      {children}
      <AssetViewImageStyled
        ref={imageRef}
        onClick={showPreview}
        loading="lazy"
        naturalWidth={width || LANDSCAPE_WIDTH}
        naturalHeight={height || LANDSCAPE_MAX_HEIGHT}
        orientation={orientation}
        multipleImage={multipleImage}
        onError={e => imageErrorHandler(e)}
        src={imageAssetUrl}
        height={height || LANDSCAPE_MAX_HEIGHT}
        width={width || LANDSCAPE_WIDTH}
        srcSet={srcSet}
        data-testid="asset-view-image"
        alt={intl.formatMessage({
          id: ChatTranslation.chatMessageAssetAltText,
        })}
        onLoad={imageOnload}
        isMobile={isMobile}
        imageLoaded={imageLoaded}
      />
      {assetCounter && assetCounter > 0 && (
        <AssetViewCounter onClick={showPreview} count={assetCounter} />
      )}
    </AssetViewImageContainerStyled>
  );
};
