import React, { FC, PropsWithChildren, Ref, useMemo, useRef } from 'react';

import { FluidIcon, GridIcon, KeyboardArrowDownIcon } from '../../shared/icons';
import { ButtonMode, ButtonSize } from '../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  HorizontalAlignment,
  Menu,
  VerticalAlignment,
} from '../../shared/components/Menu';
import { ContentLayout } from '../ContentLayout/ContentLayout.constants';

interface ContentLayoutSelectorProps {
  currentLayout: ContentLayout;
  iconSize?: number;
}

export const ContentLayoutSelector: FC<
  PropsWithChildren<ContentLayoutSelectorProps>
> = ({ children, currentLayout, iconSize = 14 }) => {
  const layoutSelector: Ref<HTMLButtonElement> = useRef(null);

  const currentLayoutIcon = useMemo(() => {
    switch (currentLayout) {
      case ContentLayout.FLUID:
        return <FluidIcon width={iconSize} height={iconSize} />;
      case ContentLayout.GRID:
      default:
        return <GridIcon width={iconSize} height={iconSize} />;
    }
  }, [currentLayout, iconSize]);

  return (
    <>
      <ButtonWithIcon
        ref={layoutSelector}
        icon={KeyboardArrowDownIcon}
        mode={ButtonMode.tertiary}
        size={ButtonSize.xs}
        data-tourid="change-apps-layout-button">
        {currentLayoutIcon}
      </ButtonWithIcon>
      <Menu
        trigger={layoutSelector}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.LEFT}>
        {children}
      </Menu>
    </>
  );
};
