export const AccountFields = `
  id
  firstName
  lastName
  email
  phoneNumber
  pendingEmail
  image {
    contentUrl
  }
  timezone
  companyName
  companyUrl
  onCall
`;
