import styled from 'styled-components';

export const SupportButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};

  svg {
    margin-bottom: 0.3125rem;
    cursor: pointer;
  }
`;
