import {
  useCurrentWorkspace,
  useCurrentWorkspaceInfo,
  useWorkspaceOwnerAccount,
} from '../../Workspace/Workspace.hooks';
import { ProcessedFileResult } from './ImportPasswords.types';
import {
  encryptVaultAccessKey,
  encryptVaultItem,
  generateVaultAccessKey,
  getDecryptedVaultLogin,
  getDecryptedVaultPassword,
  importPublicKey,
} from '../Encryption.crypto.utils';
import { showToastErrorMessage } from '../../../shared/components/Toast';
import { EncryptionTranslation } from '../i18n';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceAccountPublicKey,
  useCurrentWorkspaceVaultsList,
} from '../Encryption.hooks';
import { useCallback, useMemo } from 'react';
import { VaultAccessInputType } from '../Encryption.types';
import { getAccountKeyset, getVaultItem } from '../Encryption.utils';
import { AccountApiType } from '../../User/User.types';
import { VaultItemForBatchCreation } from '../Encryption.mutations';
import { captureException } from '../../ErrorInterceptor';
import { logImportPasswordsInfo } from './ImportPasswords.utils';
import { WorkspaceAccountGroupIdentity } from '../../User/User.constants';

export const useCreateVaultEntities = () => {
  const { workspace } = useCurrentWorkspace();
  const { workspaceInfo } = useCurrentWorkspaceInfo();
  const isOwnerForCurrentWorkspace =
    workspaceInfo?.role === WorkspaceAccountGroupIdentity.OWNER;
  const { workspaceOwnerAccount } = useWorkspaceOwnerAccount(workspace.id);
  const { keyset } = useCurrentAccountKeyset();
  const { publicKey } = useCurrentWorkspaceAccountPublicKey();

  const workspaceOwnerKeyset = useMemo(() => {
    return getAccountKeyset(workspaceOwnerAccount as AccountApiType);
  }, [workspaceOwnerAccount]);

  logImportPasswordsInfo(
    'isOwnerForCurrentWorkspace',
    isOwnerForCurrentWorkspace,
  );
  logImportPasswordsInfo('workspaceOwnerAccount', workspaceOwnerAccount);
  logImportPasswordsInfo('workspaceOwnerKeyset', workspaceOwnerKeyset);
  logImportPasswordsInfo('publicKey', publicKey);

  const getWorkspaceOwnerVaultAccess = useCallback(
    (
      vaultAccessKey: CryptoKey,
    ): Promise<Omit<VaultAccessInputType, 'vault' | 'workspace'>> => {
      if (!workspaceOwnerKeyset) {
        return Promise.reject(new Error('No workspaceOwnerKeyset'));
      }
      return importPublicKey(workspaceOwnerKeyset.pubKey)
        .then(ownerPublicKey => {
          return encryptVaultAccessKey(ownerPublicKey, vaultAccessKey);
        })
        .then(vaultKeyEncryptedForOwner => {
          return {
            keyset: workspaceOwnerKeyset.id,
            vaultKeyEncrypted: vaultKeyEncryptedForOwner,
            isImplicitlyShared: true,
          };
        });
    },
    [workspaceOwnerKeyset],
  );

  const getCurrentUserVaultAccess = useCallback(
    (
      vaultAccessKey: CryptoKey,
    ): Promise<Omit<VaultAccessInputType, 'vault' | 'workspace'>> => {
      if (!keyset?.id) {
        return Promise.reject(new Error('No keysetId'));
      }
      return encryptVaultAccessKey(publicKey, vaultAccessKey).then(
        vaultKeyEncryptedForCurrentUser => {
          return {
            keyset: keyset.id,
            vaultKeyEncrypted: vaultKeyEncryptedForCurrentUser,
          };
        },
      );
    },
    [keyset, publicKey],
  );
  const { vaults } = useCurrentWorkspaceVaultsList();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const keysetId = keyset?.id || '';

  const checkForDuplicates = (item: ProcessedFileResult) => {
    return Promise.allSettled(
      vaults
        .filter(vault => !!getVaultItem(vault))
        .map(vault => {
          const vaultItem = getVaultItem(vault);

          if (item?.url === vaultItem.url) {
            return getDecryptedVaultLogin(vault, keysetId, privateKey).then(
              login => {
                if (login === item?.login) {
                  return getDecryptedVaultPassword(
                    vault,
                    keysetId,
                    privateKey,
                  ).then(password => {
                    return Promise.resolve(password === item?.password);
                  });
                } else {
                  return Promise.resolve(false);
                }
              },
            );
          } else {
            return Promise.resolve(false);
          }
        }),
    );
  };

  return (data: ProcessedFileResult[]) => {
    let duplicatedPasswordsCount = 0;

    logImportPasswordsInfo('CreateVaultEntities entry data', data);

    const result = Promise.allSettled(
      data.map(elem =>
        generateVaultAccessKey()
          .then(
            newVaultAccessKey => {
              return checkForDuplicates(elem).then(res => {
                if (
                  res.some(
                    promise =>
                      promise.status === 'fulfilled' && promise?.value === true,
                  )
                ) {
                  duplicatedPasswordsCount++;
                  return Promise.reject('Duplicated');
                }

                if (!elem?.login || !elem?.password || !elem.url) {
                  return Promise.reject('Not valid data row');
                }
                return Promise.all([
                  encryptVaultItem(newVaultAccessKey, elem.login),
                  encryptVaultItem(newVaultAccessKey, elem.password),
                  getCurrentUserVaultAccess(newVaultAccessKey),
                  getWorkspaceOwnerVaultAccess(newVaultAccessKey),
                ]);
              });
            },
            e => {
              captureException(e);
              showToastErrorMessage(
                EncryptionTranslation.editVaultFormEncryptionErrorMessage,
              );
            },
          )
          .then(encryptedData => {
            if (encryptedData) {
              const [
                encryptedLogin,
                encryptedPassword,
                currentUserVaultAccess,
                workspaceOwnerVaultAccess,
              ] = encryptedData;

              return Promise.resolve({
                login: encryptedLogin,
                password: encryptedPassword,
                url: elem.url,
                workspace: workspace.id,
                keyset: keyset?.id,
                vaultKeyEncrypted: currentUserVaultAccess.vaultKeyEncrypted,
                implicitlySharedVaultKeyEncrypted: isOwnerForCurrentWorkspace
                  ? null
                  : workspaceOwnerVaultAccess.vaultKeyEncrypted,
              });
            }

            return Promise.reject(new Error('No valid encrypted data'));
          }),
      ),
    ).then(res => {
      if (duplicatedPasswordsCount > 0) {
        showToastErrorMessage(
          EncryptionTranslation.passwordsDuplicatedMessage,
          {
            duplicatedCount: duplicatedPasswordsCount,
          },
        );
      }
      return res
        .filter(promise => promise.status === 'fulfilled')
        .map(
          (promise: PromiseSettledResult<VaultItemForBatchCreation>) =>
            promise.status === 'fulfilled' && promise.value,
        );
    });
    logImportPasswordsInfo('CreateVaultEntities result data', result);
    return result;
  };
};
