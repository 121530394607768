import styled from 'styled-components';

export const StyledConferenceClosedBanner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.Surface};
`;

export const ConferenceClosedBannerControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button ~ button {
    margin-left: 0.5rem;
  }
`;

export const ConferenceClosedBannerMessage = styled.h2`
  margin-bottom: 1rem;
`;
