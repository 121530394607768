import { gql } from '@apollo/client';
import { AccountLinkRequestFragmentFields } from './Onboarding.fragments';
import { AccountLinkRequestApiType, IndustryApiType } from './Onboarding.types';
import { AccountApiType } from '../User/User.types';
import { ApiConnection } from '../../shared/api/api.types';

export const ACCOUNT_LINK_REQUEST = gql`
  query AccountLinkRequest($id: ID!) {
    accountLinkRequest(id: $id) {
     ${AccountLinkRequestFragmentFields}
    }
  }
`;

export interface AccountLinkRequestVariables {
  id: string;
}

export interface AccountLinkRequestResponse {
  accountLinkRequest: AccountLinkRequestApiType;
}

export const GET_ACCOUNT = gql`
  query account($id: ID!) {
    account(id: $id) {
      id
      _id
      firstName
      lastName
      email
      pendingEmail
      phoneNumber
      companyName
      companyUrl
      timezone
      origin
      identityId
      keysets {
        edges {
          node {
            id
            pubKey
            privKeyEncrypted
            createdAt
          }
        }
      }
    }
  }
`;

export interface GetAccountVariables {
  id: string;
}

export interface GetAccountResponse {
  account: AccountApiType;
}

export const GET_INDUSTRY_CATEGORIES = gql`
  query GetIndustryCategories {
    industries {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export interface GetIndustryCategoriesResponse {
  industries: ApiConnection<IndustryApiType>;
}
