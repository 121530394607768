import styled from 'styled-components';

export const StyledFolderEmptyState = styled.div<{}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  grid-area: desktop-items;
`;

export const FolderEmptyMessage = styled.div<{}>`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 1.12rem;
  margin-bottom: 40px;
`;
