import { RefObject, useEffect } from 'react';

type useOnClickOutsideProps = {
  refs: RefObject<any>[];
  action: (event: Event) => void;
  ignoreWhen?: boolean;
};

export const useOnClickOutside = (props: useOnClickOutsideProps) => {
  const { refs, action, ignoreWhen } = props;

  useEffect(() => {
    if (!refs.length || ignoreWhen) {
      return;
    }

    const listener = (event: Event) => {
      let didClickInside = false;

      for (let i = 0; i < refs.length; i++) {
        if (!refs[i].current || refs[i].current.contains(event.target)) {
          didClickInside = true;
          break;
        }
      }

      if (didClickInside) {
        return;
      }

      action(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, action, ignoreWhen]);
};
