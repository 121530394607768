import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const PauseIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2164:74067)">
        <rect
          x="12.5"
          y="10"
          width="2.5"
          height="12"
          rx="1.25"
          fill="currentColor"
        />
        <rect
          x="17.5"
          y="10"
          width="2.5"
          height="12"
          rx="1.25"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2164:74067">
          <rect
            width="16"
            height="16"
            fill="currentColor"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});
