import React, { FC } from 'react';
import { useDrop } from 'react-dnd';
import { DragNativeFileItemType } from '../../../Drag';
import { NativeTypes } from 'react-dnd-html5-backend';
import { StyledDropZone } from './ConversationView.styled';

interface ConversationDropZoneProps {
  width: number;
  height: number;
  multiple?: boolean;
  onFilesDropped: (file: File[]) => void;
}

export const ConversationDropZone: FC<ConversationDropZoneProps> = ({
  width,
  height,
  multiple = false,
  onFilesDropped,
}) => {
  const [{ isDraggingOver }, chatDropRef] = useDrop<
    DragNativeFileItemType,
    unknown,
    { isDraggingOver: boolean }
  >({
    accept: [NativeTypes.FILE],
    canDrop: () => true,
    drop: item => {
      onFilesDropped(item.files);
    },
    collect: monitor => ({
      isDraggingOver: monitor.isOver(),
    }),
  });

  return (
    <StyledDropZone
      ref={chatDropRef}
      width={width}
      height={height}
      multiple={multiple}
      isDraggingOver={isDraggingOver}
      data-testid="conversation-drop-zone"
    />
  );
};
