import { FileAssetId, ImageAssetId } from './Asset.types';
import { FILE_ASSET_ID_PREFIX, IMAGE_ASSET_ID_PREFIX } from './Asset.constants';
import { urlHasProtocol } from '../../shared/utils/url.utils';
import { getShortId } from '../../shared/utils/id';

const imageMimeTypeStartsWith = 'image/';
const videoMimeTypeStartsWith = 'video/';

export const isImageMimeType = (mimeType?: string) =>
  (mimeType ?? '').startsWith(imageMimeTypeStartsWith);

export const isImageAsset = (
  assetIri: FileAssetId | ImageAssetId,
): assetIri is ImageAssetId => assetIri.startsWith(IMAGE_ASSET_ID_PREFIX);

export const getDownloadUrl = (assetUrl: string) => {
  const url = new URL(
    urlHasProtocol(assetUrl) ? assetUrl : `https:${assetUrl}`,
  );
  url.searchParams.append('dl', '1');
  return url.toString();
};

export const getAssetImageIri = (id: string): string => {
  const shortId = getShortId(id);

  return '/assets/images/' + shortId;
};

export const getAssetMediaIri = (id: string): string => {
  const shortId = getShortId(id);

  return '/assets/media/' + shortId;
};

export const isVideoAsset = (
  assetIri: FileAssetId | ImageAssetId,
): assetIri is ImageAssetId => assetIri.startsWith(FILE_ASSET_ID_PREFIX);

export const isVideoMimeType = (mimeType?: string) =>
  (mimeType ?? '').startsWith(videoMimeTypeStartsWith);
