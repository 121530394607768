import styled from 'styled-components';

export const StyledScheduleRepeatingConferenceModal = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  text-align: left;
  & > div:first-child {
    padding: 1.375rem 0;
  }
  .divider {
    height: 1px;
    background-color: ${p => p.theme.colors.Divider};
    margin: 0 -1.75rem;
  }

  svg {
    margin: 0;
  }
`;

export const ButtonControls = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  & > button {
    margin-right: 0.875rem;
  }
`;
