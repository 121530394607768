import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { CogIcon } from '../../../shared/icons';
import { GeneralSettingsTranslation } from '../i18n';
import { Button } from '../../../shared/components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { getShortId } from '../../../shared/utils/id';

const StyledGeneralSettingsButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};

  svg {
    margin-bottom: 0.3125rem;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      transform: rotate(180deg);
    }
  }
`;

export const GeneralSettingsButton: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => {
    if (isMobile) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          settingsModal: true,
        }),
      });
    } else {
      navigate(
        {
          pathname: `/workspace/${getShortId(workspace.id)}/admin/settings`,
        },
        {
          state: {
            returnTo: pathname,
          },
        },
      );
    }
  }, [isMobile, navigate, queryParams, workspace, pathname]);

  return (
    <>
      <StyledGeneralSettingsButton
        onClick={handleClick}
        data-tooltip-content={formatMessage({
          id: GeneralSettingsTranslation.tooltipGeneralSettingsButton,
        })}
        data-tooltip-place={TooltipPlace.right}
        data-testid="general-settings-button">
        <CogIcon width={22} height={22} />
      </StyledGeneralSettingsButton>
    </>
  );
};
