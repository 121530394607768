import React from 'react';
import {
  GoogleAuthStatusInBrowserType,
  GoogleAuthStatusTypes,
} from './BrowserGoogleAuthStatus.types';

export const BrowserGoogleAuthStatusContext = React.createContext<GoogleAuthStatusInBrowserType>(
  {
    clearGoogleAuthStatusInBrowser: () => {},
    browserGoogleAuthorizationStatus: GoogleAuthStatusTypes.initialization,
    browserGoogleAuthUrl: '',
  },
);
