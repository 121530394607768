import { useMutation } from '@apollo/client';
import {
  SetAnnouncementVersionResponse,
  SetAnnouncementVersionVariables,
  SET_ANNOUNCEMENT_VERSION,
} from './Announcement.mutations';

export const useSetAnnouncementVersionMutation = () =>
  useMutation<SetAnnouncementVersionResponse, SetAnnouncementVersionVariables>(
    SET_ANNOUNCEMENT_VERSION,
  );
