import styled from 'styled-components';

export const StyledConferenceHeader = styled.div`
  display: flex;
`;

export const ConferenceHeaderTitle = styled.div`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.5;
  margin-left: 1.125rem;
  word-break: break-all;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: text;
`;
