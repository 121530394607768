import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonMode } from '../../../../../shared/components/Button/Button';
import {
  JoinChatButton,
  JoinChatLink,
  JoinChatOptionsSeparator,
  StyledJoinChatOptions,
} from './JoinChatOptions.styled';
import { OnboardingTranslation } from '../../../i18n';
import { DesktopLogoIcon } from '../../../../../shared/icons';
import SlackLogo from './slack-logo.svg';
import TeamsLogo from './microsoft-teams-logo.svg';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { GlobalTranslation } from '../../../../Intl/i18n';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_FLOW,
  INVITATION_PATHNAME,
  REGISTRATION_PATHNAME,
} from '../../../Onboarding.constants';
import { LinkMode } from '../../../../../shared/components/Link';
import { LOGIN_PATHNAME } from '../../../../Auth/Auth.constants';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

const ICON_SIZE = 20;

interface JoinChatOptionsProps {
  invitation: AccountInvitationApiType;
}

export const JoinChatOptions: FC<JoinChatOptionsProps> = ({ invitation }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { slackShareUrl, _id } = invitation;

  const handleJoinWithNewDesktopAccountClick = () => {
    navigate(`${REGISTRATION_PATHNAME}/${DEFAULT_FLOW}?id=${_id}`);
  };

  const handleJoinWithExistingDesktopAccountClick = () => {
    navigate(`${LOGIN_PATHNAME}?returnTo=${INVITATION_PATHNAME}?id=${_id}`);
  };

  return (
    <StyledJoinChatOptions>
      <JoinChatButton
        mode={ButtonMode.primary}
        onClick={handleJoinWithExistingDesktopAccountClick}>
        <DesktopLogoIcon
          width={ICON_SIZE}
          height={ICON_SIZE}
          withCurrentColor
        />
        <FormattedMessage
          id={
            OnboardingTranslation.onboardingJoinChatOptionExistingDesktopAccountButton
          }
        />
      </JoinChatButton>

      {slackShareUrl ? (
        <JoinChatLink href={slackShareUrl} mode={LinkMode.secondary}>
          <img
            src={SlackLogo}
            width={ICON_SIZE}
            height={ICON_SIZE}
            alt={intl.formatMessage({
              id: OnboardingTranslation.onboardingJoinChatOptionSlackLogoAlt,
            })}
          />
          <FormattedMessage
            id={
              OnboardingTranslation.onboardingJoinChatOptionSlackAccountButton
            }
          />
        </JoinChatLink>
      ) : (
        <>
          <div
            className="tooltip-wrapper"
            data-tooltip-content={intl.formatMessage({
              id: OnboardingTranslation.onboardingJoinChatOptionSlackUnavailable,
            })}
            data-tooltip-place={TooltipPlace.bottom}>
            <JoinChatButton disabled mode={ButtonMode.secondary}>
              <img
                src={SlackLogo}
                width={ICON_SIZE}
                height={ICON_SIZE}
                alt={intl.formatMessage({
                  id: OnboardingTranslation.onboardingJoinChatOptionSlackLogoAlt,
                })}
              />
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingJoinChatOptionSlackAccountButton
                }
              />
            </JoinChatButton>
          </div>
        </>
      )}

      <div
        className="tooltip-wrapper"
        data-tooltip-content={intl.formatMessage({
          id: GlobalTranslation.featureNotAvailable,
        })}
        data->
        <JoinChatButton disabled mode={ButtonMode.secondary}>
          <img
            src={TeamsLogo}
            width={ICON_SIZE}
            height={ICON_SIZE}
            alt={intl.formatMessage({
              id: OnboardingTranslation.onboardingJoinChatOptionTeamsLogoAlt,
            })}
          />
          <FormattedMessage
            id={
              OnboardingTranslation.onboardingJoinChatOptionTeamsAccountButton
            }
          />
        </JoinChatButton>
      </div>

      <JoinChatOptionsSeparator
        message={intl.formatMessage({
          id: OnboardingTranslation.onboardingJoinChatOptionsSeparatorMessage,
        })}
      />

      <JoinChatButton
        mode={ButtonMode.secondary}
        onClick={handleJoinWithNewDesktopAccountClick}>
        <FormattedMessage
          id={
            OnboardingTranslation.onboardingJoinChatOptionNewDesktopAccountButton
          }
        />
      </JoinChatButton>
    </StyledJoinChatOptions>
  );
};
