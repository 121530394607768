import React, { FC } from 'react';
import {
  ContentLayout,
  ContentLayoutSelector,
  FluidLayoutOption,
  GridLayoutOption,
} from '../../../ContentLayout';
import { StyledAppsContainer } from './AppsContainer.styled';
import {
  AppsBlockSkeleton,
  FiltersBlockSkeleton,
} from '../../DesktopView/AppsView/AppsViewSkeleton';
import { EmptyState } from '../../DesktopView/AppsView/EmptyState';
import { DesktopFilter } from '../../DesktopView/DesktopFilter';
import { DesktopFilterButton } from '../../DesktopView/DesktopFilter/DesktopFilterButton';
import { DesktopFilterEmptyState } from '../../DesktopView/DesktopFilter/DesktopFilterEmptyState';
import {
  StyledDesktopToolbar,
  ToolbarFilterContainer,
  ToolbarOptionsContainer,
  ToolbarPrimaryActionContainer,
  ToolbarToggleOptions,
} from '../../DesktopView/DesktopToolbar';
import { AppsContainerViewModel } from './ViewModel';
import { AppsLayout } from '../AppsLayout/AppsLayout';

interface AppsViewProps {
  hideLayoutPicker?: boolean;
  adminView?: boolean;
}

export const AppsContainer: FC<AppsViewProps> = ({
  hideLayoutPicker,
  adminView,
}) => {
  const {
    isAdminPage,
    isNormalDesktop,
    loading,
    apps,
    desktopName,
    desktopFilter,
    layout,
    updateLayout,
  } = AppsContainerViewModel();

  if (!desktopName.length && loading) {
    return (
      <>
        <FiltersBlockSkeleton />
        <AppsBlockSkeleton />
      </>
    );
  }

  return (
    <>
      {!isAdminPage && (!!apps.length || !!desktopFilter) && (
        <StyledDesktopToolbar hasDesktopFilter={desktopFilter !== undefined}>
          {!hideLayoutPicker && desktopFilter === undefined && (
            <ToolbarPrimaryActionContainer>
              <ContentLayoutSelector currentLayout={layout}>
                <GridLayoutOption
                  setLayout={updateLayout}
                  currentLayout={layout}
                />
                <FluidLayoutOption
                  setLayout={updateLayout}
                  currentLayout={layout}
                />
              </ContentLayoutSelector>
            </ToolbarPrimaryActionContainer>
          )}

          {desktopFilter === undefined && (
            <ToolbarOptionsContainer>
              <ToolbarToggleOptions />
            </ToolbarOptionsContainer>
          )}

          {isNormalDesktop && (
            <ToolbarFilterContainer filterOpened={desktopFilter !== undefined}>
              {desktopFilter !== undefined ? (
                <DesktopFilter />
              ) : (
                <DesktopFilterButton />
              )}
            </ToolbarFilterContainer>
          )}
        </StyledDesktopToolbar>
      )}

      <StyledAppsContainer data-testid="apps-view">
        {!loading && !!apps.length && (
          <AppsLayout
            apps={apps}
            layout={hideLayoutPicker ? ContentLayout.GRID : layout}
          />
        )}

        {loading ? (
          <AppsBlockSkeleton />
        ) : !desktopFilter && !apps.length ? (
          //TODO Review usage EmptyState
          <EmptyState adminView={adminView} />
        ) : desktopFilter && !apps.length ? (
          <DesktopFilterEmptyState />
        ) : null}
      </StyledAppsContainer>
    </>
  );
};
