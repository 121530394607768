import styled, { css } from 'styled-components';

export const StyledDesktopToolbar = styled.div<{ hasDesktopFilter: boolean }>`
  display: grid;
  grid-template-areas: 'toolbar-layout toolbar-options toolbar-filter';
  grid-template-columns: 1fr 2fr 1fr;

  grid-area: desktop-toolbar;
  height: 56px;
  position: relative;

  ${({ hasDesktopFilter }) =>
    hasDesktopFilter &&
    css`
      grid-template-columns: 0 0 1fr;
    `}

  ${p => p.theme.breakpoints.md`
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}
`;

export const ToolbarPrimaryActionContainer = styled.div`
  grid-area: toolbar-layout;
  display: flex;
`;

export const ToolbarOptionsContainer = styled.div`
  grid-area: toolbar-options;
  display: flex;
  justify-content: center;
`;

export const ToolbarFilterContainer = styled.div<{ filterOpened?: boolean }>`
  grid-area: toolbar-filter;
  display: flex;

  ${({ filterOpened }) =>
    filterOpened &&
    css`
      width: 100%;
    `}
`;
