import React, { FC, useCallback } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import {
  ErrorMessage,
  ExternalRegistrationField,
  ExternalRegistrationFormControls,
  InvitationWorkspaceName,
  PlanSection,
  PredefinedPlan,
  RadioInput,
  RadioLabel,
  SelectPlanGroup,
  SelectPlanLabel,
  StyledExternalRegistrationForm,
} from './ExternalRegistrationForm.styled';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { AccountApiType } from '../../../../User/User.types';
import { TermsOfUseLink } from '../../RegisterationPage/RegistrationForm/RegistrationForm.styled';
import { FormCheckbox } from '../../../../../shared/components/Checkbox';
import { appEnv } from '../../../../../appEnv';
import {
  ExternalRegistrationFormValues,
  SignUpFlow,
} from '../../../Onboarding.types';
import { useNativeWrapper } from '../../../../NativeWrapper';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

const ExternalRegistrationFormSchema: Schema<ExternalRegistrationFormValues> =
  Yup.object().shape({
    flow: Yup.string().required(
      OnboardingTranslation.onboardingRegistrationFormEmailRequired,
    ),
    email: Yup.string().email(),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      OnboardingTranslation.onboardingRegistrationTermsOfUseCheckboxRequired,
    ),
    newsSubscription: Yup.boolean(),
  });

interface RegistrationFormProps {
  account: AccountApiType;
  flow?: SignUpFlow;
  invitation?: AccountInvitationApiType;
  onSubmit: (values: ExternalRegistrationFormValues) => Promise<unknown>;
  invitationEmailError?: boolean;
}

export const ExternalRegistrationForm: FC<RegistrationFormProps> = ({
  account,
  flow,
  invitation,
  onSubmit,
  invitationEmailError,
}) => {
  const intl = useIntl();

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const initialValues = {
    flow: (flow as string) || SignUpFlow.FREE,
    email: account?.email || '',
    acceptTerms: false,
    newsSubscription: false,
  };

  let invitationEmail = invitation?.recipients[0].email;
  if (invitationEmail) {
    const emailUsername = invitationEmail
      .split('@')[0]
      .replace(/(.{3})(.*)/, (_, p1, p2) => p1 + '*'.repeat(p2.length));
    const emailDomain = '@' + invitationEmail.split('@')[1];
    invitationEmail = `${emailUsername}${emailDomain}`;
  }

  const handleSubmit = useCallback(
    (values: ExternalRegistrationFormValues) => {
      onSubmit(values).catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
      });
    },
    [onSubmit],
  );

  if (
    account &&
    account.currentFlow === SignUpFlow.ENTERPRISE &&
    appEnv.ALLOW_ENTERPRISE_REGISTRATION !== 'true'
  ) {
    return null;
  }

  const getPredefinedPlan = (flow: SignUpFlow) => {
    switch (flow) {
      case SignUpFlow.ENTERPRISE:
        return (
          <FormattedMessage
            id={OnboardingTranslation.onboardingPlanPageEnterprise}
          />
        );
      case SignUpFlow.PRO:
        return (
          <FormattedMessage id={OnboardingTranslation.onboardingPlanPagePro} />
        );
      default:
        return (
          <FormattedMessage id={OnboardingTranslation.onboardingPlanPageFree} />
        );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ExternalRegistrationFormSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, values, setFieldValue }) => (
        <StyledExternalRegistrationForm data-testid="external-registration-form">
          {!isNativeWrapperAvailable && (
            <PlanSection>
              {flow ? (
                <>
                  <FormattedMessage
                    id={
                      OnboardingTranslation.onboardingExternalRegistrationPlanLabel
                    }
                  />
                  :{' '}
                  <PredefinedPlan data-testid="predefined-plan">
                    {getPredefinedPlan(flow)}
                  </PredefinedPlan>
                  <br />
                  {invitation?.workspaceName && (
                    <>
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingExternalRegistrationInvitationWorkspaceNameLabel
                        }
                      />
                      :{' '}
                      <InvitationWorkspaceName data-testid="invitation-workspace-name">
                        {invitation.workspaceName}
                      </InvitationWorkspaceName>
                    </>
                  )}
                </>
              ) : (
                <>
                  <SelectPlanLabel>
                    <FormattedMessage
                      id={
                        OnboardingTranslation.onboardingExternalRegistrationPlanLabel
                      }
                    />
                  </SelectPlanLabel>
                  <SelectPlanGroup>
                    <RadioInput
                      name="flow"
                      data-testid="flow-option-free"
                      id="flow-option-free"
                      defaultChecked={values.flow === SignUpFlow.FREE}
                      onChange={() => setFieldValue('flow', SignUpFlow.FREE)}
                    />
                    <RadioLabel htmlFor="flow-option-free">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingPlanPageFree}
                      />
                    </RadioLabel>
                  </SelectPlanGroup>
                  <SelectPlanGroup>
                    <RadioInput
                      name="flow"
                      data-testid="flow-option-pro"
                      id="flow-option-pro"
                      defaultChecked={values.flow === SignUpFlow.PRO}
                      onChange={() => setFieldValue('flow', SignUpFlow.PRO)}
                    />
                    <RadioLabel htmlFor="flow-option-pro">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingPlanPagePro}
                      />
                    </RadioLabel>
                  </SelectPlanGroup>
                </>
              )}
            </PlanSection>
          )}

          <ExternalRegistrationField>
            <Field
              name="email"
              type="email"
              data-testid="email-input"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingExternalRegistrationFormEmailLabel,
              })}
              component={FormInput}
              disabled
            />
          </ExternalRegistrationField>
          {invitationEmailError && (
            <ErrorMessage>
              <FormattedHTMLMessage
                id={
                  OnboardingTranslation.onboardingExternalRegistrationFormWrongEmailMessage
                }
                values={{
                  email: invitationEmail,
                }}
              />
            </ErrorMessage>
          )}
          <ExternalRegistrationField>
            <Field
              name="acceptTerms"
              type="checkbox"
              data-testid="accept-terms-checkbox"
              label={intl.formatMessage(
                {
                  id: OnboardingTranslation.onboardingRegistrationTermsOfUseMessage,
                },
                {
                  termsLink: (
                    <TermsOfUseLink href={process.env.TERMS_OF_USE_URL}>
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingRegistrationTermsOfUseLink
                        }
                      />
                    </TermsOfUseLink>
                  ),
                  privacyPolicyLink: (
                    <TermsOfUseLink href={process.env.PRIVACY_POLICY_URL}>
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingRegistrationPrivacyPolicyLink
                        }
                      />
                    </TermsOfUseLink>
                  ),
                },
              )}
              component={FormCheckbox}
            />
          </ExternalRegistrationField>

          <ExternalRegistrationField>
            <Field
              name="newsSubscription"
              type="checkbox"
              data-testid="marketing-consent-checkbox"
              label={intl.formatMessage({
                id: OnboardingTranslation.onboardingRegistrationMarketingConsentLabel,
              })}
              component={FormCheckbox}
            />
          </ExternalRegistrationField>

          <ExternalRegistrationFormControls>
            <Button
              type="submit"
              data-testid="submit-button"
              disabled={isSubmitting || !isValid}
              size={ButtonSize.md}
              mode={ButtonMode.primary}
              fullWidth>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingExternalRegistrationFormSubmitButton
                }
              />
            </Button>
          </ExternalRegistrationFormControls>
        </StyledExternalRegistrationForm>
      )}
    </Formik>
  );
};
