import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Modal, ModalProps } from '../../../../shared/components/Modal';
import {
  QuickGuideDescription,
  QuickGuideHeader,
  QuickGuideSubHeaderContent,
  StyledCards,
  StyledCardsWrapper,
  StyledCardTitle,
  StyledIcon,
  StyledOnboardingModal,
  StyledVideo,
} from './OnboardingQuickTourModal.styled';
import { WorkspaceTranslation } from '../../i18n';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { appEnv } from '../../../../appEnv';
import { useCookieStorage } from '../../../../shared/utils/storage';
import { OPEN_QUICK_START_GUIDE_STORAGE_KEY } from '../../../Onboarding/Onboarding.constants';
import {
  AddIcon,
  CallIcon,
  ChatIcon,
  CloseIcon,
  DevicesIcon,
  DownloadIcon,
} from '../../../../shared/icons';
import { getCurrentTheme } from '../../../Theme';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useCurrentWorkspace } from '../../Workspace.hooks';

interface OnboardingQuickTourModalProps
  extends Omit<ModalProps, 'onRequestClose' | 'visible'> {}

export const OnboardingQuickTourModal: FC<OnboardingQuickTourModalProps> = ({
  ...props
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();

  const cardsList = useMemo(
    () => [
      {
        title: WorkspaceTranslation.onboardingInviteUsersToChat,
        icon: (
          <ChatIcon
            color={getCurrentTheme().colors.OnboardingInviteUsers}
            height={18}
            width={20}
          />
        ),
        bgColor: getCurrentTheme().colors.OnboardingInviteUsersBackground,
        link: appEnv.ONBOARDING_CHAT_URL,
        enabled: workspace.communicate,
      },
      {
        title: WorkspaceTranslation.onboardingCreateFirstDesktop,
        icon: (
          <AddIcon
            color={getCurrentTheme().colors.OnboardingCreateFirstDesktop}
            width={14}
            height={14}
          />
        ),
        bgColor:
          getCurrentTheme().colors.OnboardingCreateFirstDesktopBackground,
        link: appEnv.ONBOARDING_CREATE_USER_DESKTOP_URL,
        enabled: workspace.organizeAndSearch,
      },
      {
        title: WorkspaceTranslation.onboardingQuickImport,
        icon: (
          <DownloadIcon
            color={getCurrentTheme().colors.OnboardingQuickImport}
            height={20}
            width={16}
          />
        ),
        bgColor: getCurrentTheme().colors.OnboardingQuickImportBackground,
        link: appEnv.ONBOARDING_HOW_TO_IMPORT_APPS,
        enabled: workspace.organizeAndSearch,
      },
      {
        title: WorkspaceTranslation.onboardingFirstCall,
        icon: (
          <CallIcon
            color={getCurrentTheme().colors.OnboardingFirstCall}
            height={18}
            width={18}
          />
        ),
        bgColor: getCurrentTheme().colors.OnboardingFirstCallBackground,
        link: appEnv.ONBOARDING_FIRST_CALL_AND_VIDEO_URL,
        enabled: workspace.communicate,
      },
      {
        title: WorkspaceTranslation.onboardingAddDesktop,
        icon: (
          <DevicesIcon
            color={getCurrentTheme().colors.OnboardingAddDesktop}
            height={20}
            width={24}
          />
        ),
        bgColor: getCurrentTheme().colors.OnboardingAddDesktopBackground,
        link: appEnv.ONBOARDING_FIRST_HOW_TO_INSTALL_DESKTOP,
        fullWidth: true,
        enabled: true,
      },
    ],
    [workspace],
  );

  const [, setOpenQuickStartGuide] = useCookieStorage(
    OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  );

  const onClose = useCallback(() => {
    setOpenQuickStartGuide('false');

    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        openOnboardingQuickTour: undefined,
      }),
    });
  }, [navigate, queryParams, setOpenQuickStartGuide]);

  const filteredByFeatureCards = useMemo(() => {
    return cardsList.filter(card => card.enabled);
  }, [cardsList]);

  return (
    <Modal width={550} visible={true} onRequestClose={onClose} {...props}>
      <StyledOnboardingModal>
        <QuickGuideHeader>
          <ButtonWithIcon
            icon={CloseIcon}
            onClick={onClose}
            data-testid="quick-guide-close-icon"
          />
        </QuickGuideHeader>

        <QuickGuideSubHeaderContent>
          <FormattedHTMLMessage
            id={WorkspaceTranslation.onboardingGetStartedIntroVideo}
          />
        </QuickGuideSubHeaderContent>

        <QuickGuideDescription>
          <FormattedHTMLMessage
            id={WorkspaceTranslation.onboardingGetStartedIntroDesctription}
          />
        </QuickGuideDescription>

        {appEnv.ONBOARDING_QUICK_TOUR_VIDEO && (
          <StyledVideo src={appEnv.ONBOARDING_QUICK_TOUR_VIDEO} />
        )}

        <StyledCardsWrapper>
          {filteredByFeatureCards.map((card, idx) => {
            return (
              <StyledCards
                key={card.bgColor + idx}
                href={card.link}
                target="_blank"
                rel="noopener noreferrer"
                fullWidthLink={card.fullWidth}>
                <StyledIcon bgColor={card.bgColor}>{card.icon}</StyledIcon>
                <StyledCardTitle>
                  <FormattedMessage id={card.title} />
                </StyledCardTitle>
              </StyledCards>
            );
          })}
        </StyledCardsWrapper>
      </StyledOnboardingModal>
    </Modal>
  );
};
