import styled from 'styled-components';

export const TeamAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
