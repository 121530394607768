import styled, { css } from 'styled-components';
import { SegmentPage } from '../../../Segment/Segment.styled';

export const StyledPasswordsPage = styled(SegmentPage)<{
  isPasswordVisible: boolean;
  showContentOnFullPage: boolean;
}>`
  display: flex;
  justify-content: center;
  overflow-y: auto;

  ${({ isPasswordVisible }) =>
    isPasswordVisible &&
    css`
      .apps-list {
        display: none;
      }
    `}
`;
