import styled from 'styled-components';

export const StyledShareDesktopPopover = styled.div`
  width: 27rem;
  max-width: 100%;
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  padding: 1rem 1.5rem;
`;

export const ShareDesktopModalBody = styled.div`
  padding: 1rem 1.5rem;
`;
