import { useEffect } from 'react';
import { sendTimelineTrackingEvent } from './TimelineTracking.utils';
import { TimelineTrackingAction } from './TimelineTracking.types';

export const useTimelineTrackingEventOnInit = (
  action: TimelineTrackingAction,
) => {
  useEffect(() => {
    sendTimelineTrackingEvent(action);
  }, []); // eslint-disable-line
};
