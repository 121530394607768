import styled from 'styled-components';

export const StyledLoggedOutOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${p => p.theme.colors.Surface};
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${p => p.theme.zIndexLevels.MaintenanceOverlay};
`;

export const LoggedOutText = styled.p`
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const HiddenButton = styled.button.attrs({
  type: 'button',
})`
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  border: 0;
  margin: 0;
  pointer-events: none;
`;
