import { QueryHookOptions, useApolloClient, useQuery } from '@apollo/client';
import {
  FILTER_FOLDERS,
  FilterFoldersResponse,
  FilterFoldersVariables,
  GET_FOLDER,
  GetFolderResponse,
  GetFolderVariables,
} from './Folder.queries';
import { useCallback } from 'react';
import { removeFolderFromFilteredFoldersCache } from './cache';
import { useRefreshableQuery } from '../Api/Api.hooks';

export const useFilterFoldersQuery = (
  options?: QueryHookOptions<FilterFoldersResponse, FilterFoldersVariables>,
) =>
  useQuery<FilterFoldersResponse, FilterFoldersVariables>(
    FILTER_FOLDERS,
    options,
  );

export const useFolderQuery = (
  options?: QueryHookOptions<GetFolderResponse, GetFolderVariables>,
) =>
  useRefreshableQuery<GetFolderResponse, GetFolderVariables>(
    GET_FOLDER,
    options,
  );

// todo: this is part of desktopContent -> move to desktop hooks?
export const useFolderCacheUpdate = () => {
  const apolloClient = useApolloClient();

  return {
    removeFolderFromFilteredFoldersCache: useCallback(
      (queryVariables: FilterFoldersVariables, folderId: string) =>
        removeFolderFromFilteredFoldersCache(
          apolloClient,
          queryVariables,
          folderId,
        ),
      [apolloClient],
    ),

    // TODO @bqk -> deprecate this if favorites works as expected
    // removeFavoriteFolderFromFavoritesFoldersCache: useCallback(
    //   (queryVariables: GetFavoritesVariables, folderId: string) =>
    //     removeFavoriteFolderFromFavoritesFoldersCache(
    //       apolloClient,
    //       queryVariables,
    //       folderId,
    //     ),
    //   [apolloClient],
    // ),

    // TODO @bqk -> deprecate this if favorites works as expected
    // addDesktopFoldersToFavoritesFoldersCache: useCallback(
    //   (queryVariables: GetFavoritesVariables, folders: Array<FolderApiType>) =>
    //     addDesktopFoldersToFavoritesFoldersCache(
    //       apolloClient,
    //       queryVariables,
    //       folders,
    //     ),
    //   [apolloClient],
    // ),
  };
};
