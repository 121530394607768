import React, { memo } from 'react';
import { StyledUnreadBadge } from './ChatMessage.styled';
import { CSSTransition } from 'react-transition-group';

const TRANSITION_TIME = 500;

interface ChatMessageUnreadBadgeProps {
  show: boolean;
}

export const ChatMessageUnreadBadge = memo<ChatMessageUnreadBadgeProps>(
  ({ show }) => (
    <CSSTransition in={show} timeout={TRANSITION_TIME}>
      <StyledUnreadBadge show={show} />
    </CSSTransition>
  ),
);
