import styled, { css } from 'styled-components';
import { ComponentPropsWithRef } from 'react';

export interface LinkProps extends ComponentPropsWithRef<'a'> {
  mode?: LinkMode;
  size?: LinkSize;
  fullWidth?: boolean;
}

export enum LinkMode {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  dark = 'dark',
}

export enum LinkSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

/*
 * Link HTML component that can be styled like button.
 * */

export const Link = styled.a<LinkProps>`
  display: inline-block;
  appearance: none;
  border: 0;
  border-radius: 4px;
  text-align: left;
  word-wrap: break-all;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  color: inherit;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      text-align: center;
    `};

  ${({ size }) =>
    size === LinkSize.sm &&
    css`
      padding: 8px 20px;
      font-size: 0.75rem;
      font-family: ${p => p.theme.fonts.Medium};
      line-height: 14px;
    `};

  ${({ size }) =>
    size === LinkSize.md &&
    css`
      padding: 8px 24px;
      font-size: 0.875rem;
      font-family: ${({ theme }) => theme.fonts.Semibold};
      line-height: 18px;
    `};

  ${({ size }) =>
    size === LinkSize.lg &&
    css`
      padding: 11px 48px;
      font-size: 1.25rem;
      font-family: ${({ theme }) => theme.fonts.Semibold};
      line-height: 30px;
    `};

  ${({ mode, theme }) =>
    mode === LinkMode.primary &&
    css`
      background-color: ${theme.colors.Primary};
      color: ${theme.colors.PrimaryTextLight};

      &:hover {
        background-color: ${theme.colors.PrimaryLight};
      }

      &:focus {
        outline: none;
        background-color: ${theme.colors.PrimaryLight};
      }

      &:active {
        outline: none;
        background-color: ${theme.colors.PrimaryActiveButtonLinkBackground};
      }
    `}

  ${({ mode, theme }) =>
    mode === LinkMode.secondary &&
    css`
      color: ${theme.colors.OnSurfaceHighEmphasis};
      background-color: ${theme.colors.Surface};
      border: 1px solid ${theme.colors.BorderDefault};

      &:hover {
        color: ${theme.colors.PrimaryLight};
      }
      &:focus,
      &:active {
        outline: none;
        color: ${theme.colors.PrimaryLight};
      }
    `}

  ${({ mode, theme }) =>
    mode === LinkMode.tertiary &&
    css`
      color: ${theme.colors.OnSurfaceLightEmphasis};

      &:hover {
        color: ${theme.colors.PrimaryLight};
      }

      &:focus,
      &:active {
        outline: none;
        color: ${theme.colors.PrimaryLight};
      }
    `}

    ${({ mode, theme }) =>
    mode === LinkMode.dark &&
    css`
      color: ${theme.colors.PrimaryTextLight};
      background-color: ${theme.colors.PrimaryDark};

      &:hover {
        background-color: ${theme.colors.PrimaryLight};
      }

      &:focus {
        outline: none;
        background-color: ${theme.colors.PrimaryLight};
      }

      &:active {
        outline: none;
        background-color: ${theme.colors.DarkActiveButtonLinkBackground};
      }
    `}
`;
