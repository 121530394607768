import React, { FC, useMemo } from 'react';
import { useCurrentConference } from '../../../Conference.hooks';
import {
  ScheduledConferenceHeaderDate,
  ScheduledConferenceHeaderTitle,
  ScheduledConferenceHeaderWrapper,
  ScheduledConferenceSubtitle,
} from './ScheduledConferenceHeader.styled';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import {
  SCHEDULED_CONFERENCE_START_AT_FORMAT,
  SCHEDULED_CONFERENCE_END_AT_FORMAT,
} from '../../../Conference.constants';
import {
  ConferenceViewHeaderSection,
  ScheduledConferenceContentInner,
} from '../../ConferenceView/ConferenceView.styled';

export const ScheduledConferenceHeader: FC = () => {
  const { scheduledConference } = useCurrentConference();

  const date = useMemo(() => {
    if (!scheduledConference) {
      return '';
    }

    return `
        ${format(
          parseISO(scheduledConference.startAt),
          SCHEDULED_CONFERENCE_START_AT_FORMAT,
        )}
        -
        ${format(
          parseISO(scheduledConference.endAt),
          SCHEDULED_CONFERENCE_END_AT_FORMAT,
        )}
        `;
  }, [scheduledConference]);

  return (
    <ScheduledConferenceHeaderWrapper>
      <div className="title-wrapper">
        <ScheduledConferenceHeaderTitle>
          {scheduledConference?.title}
        </ScheduledConferenceHeaderTitle>
        <ScheduledConferenceContentInner>
          <ConferenceViewHeaderSection>
            <ScheduledConferenceSubtitle>
              <FormattedMessage
                id={
                  ConferenceTranslation.scheduledUpcomingConferenceMeetingDate
                }
              />
            </ScheduledConferenceSubtitle>
          </ConferenceViewHeaderSection>
          <div className="conference-date-body">
            <ScheduledConferenceHeaderDate>
              {date}
            </ScheduledConferenceHeaderDate>
          </div>
        </ScheduledConferenceContentInner>
      </div>
    </ScheduledConferenceHeaderWrapper>
  );
};
