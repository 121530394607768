import styled from 'styled-components';
import {
  DATE_DELIMETER_HEIGHT,
  MOBILE_DATE_DELIMETER_HEIGHT,
} from '../ChatMessage/ChatMessage.constants';

export const StyledDateDelimiter = styled.div<{
  isMobile?: boolean;
}>`
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  height: ${p =>
    p.isMobile ? MOBILE_DATE_DELIMETER_HEIGHT : DATE_DELIMETER_HEIGHT}px;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${p => p.theme.colors.Divider};
  flex: 1;
`;

export const Value = styled.div`
  margin: 0 1rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  padding: 0 0.25rem;
  font-size: 0.75rem;
`;
