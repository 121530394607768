import styled from 'styled-components';

export const StyledLinkDetails = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xl};
  overflow: hidden;
  overflow-y: auto;
`;

export const DetailEntity = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  &:first-child {
    margin-top: unset;
  }

  &:last-child {
    margin-bottom: unset;
  }
`;

export const EntityHeaderContainer = styled.div<{ noMarginBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme, noMarginBottom }) =>
    noMarginBottom ? 'unset' : theme.spacing.xs};
  min-height: 28px;
`;

export const EntityTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.headerList};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  line-height: 1.33;
`;

export const EntityButton = styled.button`
  ${({ theme }) => theme.fontTemplates.regularSemibold};
  appearance: none;
  margin: unset;
  border: unset;
  background: unset;
  padding: 4px;
  border-radius: 4px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.Primary};
  line-height: 1.43;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }
`;

export const EntityLinkTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.headerMain};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EntityLinkImage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const EntityLinkUrl = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  cursor: pointer;
  column-gap: ${({ theme }) => theme.spacing.xxs};

  .favicon {
    text-align: center;
  }

  .link-url {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding-top: ${({ theme }) => theme.spacing.xxs};

    color: ${({ theme }) => theme.colors.Primary};
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.75rem;
    line-height: 1.33;
  }

  svg {
    margin-left: ${({ theme }) => theme.spacing.s};
  }
`;

export const EntityLinkTags = styled.div`
  display: flex;
  flex-flow: wrap;
  row-gap: 0.5rem;
`;

export const EntityLinkTagsEmpty = styled.div`
  background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  font-family: ${({ theme }) => theme.fonts.Medium};
  font-size: 0.75rem;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EntityLinkTag = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.fontTemplates.smallSemibold};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
`;

export const EntityTimelineList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.xs};
`;

export const EntityTimelineListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.xs};
`;

export const EntityTimelineCreator = styled.div`
  ${({ theme }) => theme.fontTemplates.regularSemibold};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 50%;
`;

export const EntityTimelineAction = styled.div.attrs({
  'data-testid': 'timeline-action',
})`
  font-family: ${({ theme }) => theme.fonts.Medium};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const EntityLocationDesktop = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.xxs};

  button {
    ${({ theme }) => theme.fontTemplates.regularSemibold};
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    margin-left: -${({ theme }) => theme.spacing.xxs};

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const EntityLocationDesktopLabel = styled.div`
  margin-top: 2px;
  ${({ theme }) => theme.fontTemplates.smallSemibold};
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  text-transform: uppercase;
  letter-spacing: 0.48px;
`;
