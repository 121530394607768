import { useMutation } from '@apollo/client';
import {
  ADD_APPS_DESKTOP,
  AddAppsDesktopResponse,
  AddAppsDesktopVariables,
  IMPORT_BOOKMARK,
  ImportBookmarkResponse,
  ImportBookmarkVariables,
  SUGGEST_APPS_FROM_BROWSER_HISTORY,
  SuggestAppsFromBrowserHistoryResponse,
  SuggestAppsFromBrowserHistoryVariables,
} from './ChromeExtension.mutations';

export const useSuggestAppsFromBrowserHistoryMutation = () =>
  useMutation<
    SuggestAppsFromBrowserHistoryResponse,
    SuggestAppsFromBrowserHistoryVariables
  >(SUGGEST_APPS_FROM_BROWSER_HISTORY);

export const useAddAppsDesktopMutation = () =>
  useMutation<AddAppsDesktopResponse, AddAppsDesktopVariables>(
    ADD_APPS_DESKTOP,
  );

export const useImportBookmarkMutation = () =>
  useMutation<ImportBookmarkResponse, ImportBookmarkVariables>(IMPORT_BOOKMARK);
