import React, { FC, useEffect } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { FormattedHTMLMessage } from 'react-intl';
import { WorkspaceTranslation } from '../../i18n';
import { FormToggle } from '../../../../shared/components/Toggle/FormToggle';
import { WorkspaceApiType } from '../../Workspace.types';
import { Schema } from 'yup';
import * as Yup from 'yup';
import {
  FeaturesFormField,
  FeaturesFormFieldTitle,
  FeaturesFormFieldTitleWrapper,
} from './WorkspaceFeaturesForm.styled';

export interface WorkspaceFeaturesFormValues {
  organizeAndSearch: boolean;
  communicate: boolean;
}

const WorkspaceFeaturesFormSchema: Schema<WorkspaceFeaturesFormValues> =
  Yup.object().shape({
    organizeAndSearch: Yup.boolean(),
    communicate: Yup.boolean(),
  });

interface WorkspaceFeaturesFormProps {
  workspace: WorkspaceApiType;
  disabled?: boolean;
  onSubmit: (
    formValues: WorkspaceFeaturesFormValues,
    formikHelpers: any,
  ) => void;
}

export const FormikAutoSave: FC = () => {
  const { submitForm, values, dirty } = useFormikContext();
  useEffect(() => {
    if (values && dirty) {
      submitForm();
    }
  }, [dirty, submitForm, values]);

  return null;
};

export const WorkspaceFeaturesForm: FC<WorkspaceFeaturesFormProps> = ({
  workspace,
  disabled,
  onSubmit,
}) => {
  const initialValues: WorkspaceFeaturesFormValues = {
    organizeAndSearch: workspace.organizeAndSearch,
    communicate: workspace.communicate,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={WorkspaceFeaturesFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <FeaturesFormField>
            <FeaturesFormFieldTitleWrapper>
              <FormattedHTMLMessage
                id={WorkspaceTranslation.formFeaturesDescription}
              />
            </FeaturesFormFieldTitleWrapper>
          </FeaturesFormField>
          <FeaturesFormField>
            <FeaturesFormFieldTitleWrapper>
              <FeaturesFormFieldTitle data-testid="organize-feature-title">
                <FormattedHTMLMessage
                  id={WorkspaceTranslation.formFeatureOrganizeLabel}
                />
              </FeaturesFormFieldTitle>
              <FormattedHTMLMessage
                id={WorkspaceTranslation.formFeatureOrganizeDescription}
              />
            </FeaturesFormFieldTitleWrapper>
            <Field
              id="organize-feature-toggle"
              data-testid="organize-feature-toggle"
              checked={values['organizeAndSearch']}
              disabled={isSubmitting || disabled}
              name="organizeAndSearch"
              component={FormToggle}
            />
          </FeaturesFormField>
          <FeaturesFormField>
            <FeaturesFormFieldTitleWrapper>
              <FeaturesFormFieldTitle data-testid="communicate-feature-title">
                <FormattedHTMLMessage
                  id={WorkspaceTranslation.formFeatureCommunicateLabel}
                />
              </FeaturesFormFieldTitle>
              <FormattedHTMLMessage
                id={WorkspaceTranslation.formFeatureCommunicateDescription}
              />
            </FeaturesFormFieldTitleWrapper>
            <Field
              id="communicate-feature-toggle"
              data-testid="communicate-feature-toggle"
              checked={values['communicate']}
              disabled={isSubmitting || disabled}
              name="communicate"
              component={FormToggle}
            />
          </FeaturesFormField>
          <FormikAutoSave />
        </Form>
      )}
    </Formik>
  );
};
