import styled from 'styled-components';

export const StyledFolderItemMenu = styled.div`
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
`;

export const StyledFolderListWrapper = styled.div`
  max-height: 25rem;
  overflow-y: scroll;
`;
