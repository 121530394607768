import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import {
  OptionChatAvatar,
  OptionInner,
  OptionLabel,
  OptionTeamIconContainer,
  StyledUserRole,
} from './CustomOption.styled';
import { NewOption } from '../DesktopSelect.types';
import { AccountWithCountsApiType } from '../../../../../User/User.types';
import { TeamIcon } from '../../../../../../shared/icons';
import { useGetUserRoleTitle } from '../../../../../User/User.utils';
import type { TeamApiType } from '../../../../../Team/data/Team/types/Team.types';

export const CustomOption: FC<
  OptionProps<AccountWithCountsApiType | TeamApiType, false>
> = ({ label, isFocused, data, innerProps }) => {
  const option: AccountWithCountsApiType | TeamApiType | NewOption = data;
  const getUserRole = useGetUserRoleTitle();
  const { id, onClick, onMouseMove, onMouseOver } = innerProps;

  return (
    <OptionInner
      isFocused={isFocused}
      id={id}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseOver={onMouseOver}>
      <div>
        {'email' in option && (
          <OptionChatAvatar
            account={option}
            className="avatar"
            avatarSize="1.5rem"
            onlineStatusSize="0.6rem"
          />
        )}
        {'name' in option && (
          <OptionTeamIconContainer>
            <TeamIcon />
          </OptionTeamIconContainer>
        )}
        <OptionLabel>{label}</OptionLabel>
      </div>
      <StyledUserRole>
        {getUserRole(data as AccountWithCountsApiType)}
      </StyledUserRole>
    </OptionInner>
  );
};
