import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StyledSpecialMention } from '../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/Mention/Mention.styled';

export const StyledInboxThreadItem = styled(Link)`
  display: flex;
  padding: 0.75rem 1rem;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-right: 0.5rem;
`;

export const TopLeftCorner = styled.div`
  position: relative;
  border-left: 2px solid ${p => p.theme.colors.SurfaceBorder};
  border-top: 2px solid ${p => p.theme.colors.SurfaceBorder};
  border-top-left-radius: 0.25rem;
  margin-top: 0.375rem;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
  width: 1.375rem;
  height: 0.675rem;
`;

export const BodyContainer = styled.div`
  position: relative;
  flex: 1;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  overflow: hidden;
`;

export const ParentMessageContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 0.25rem;
`;

export const ParentMessage = styled.div`
  position: absolute;
  left: 1.25rem;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${StyledSpecialMention}, a {
    color: inherit;
  }
`;

export const AuthorName = styled.div`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MessageText = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

export const UnreadBadge = styled.div`
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${p => p.theme.colors.Primary};
  border-radius: 50%;
  right: 0;
  top: calc(50% - 0.25rem);
`;
