import React, { FC, useCallback } from 'react';
import { ListItem } from '../ListItem';
import {
  getAccountName,
  isAccountAdmin,
  isAccountGuest,
} from '../../../../User/User.utils';
import { ChatAvatar } from '../../../../Chat/ChatAvatar';
import { DesktopTranslation } from '../../../i18n';
import { useIntl } from 'react-intl';
import { useConfirm } from '../../../../../shared/components/Modal';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../../User/User.constants';
import { UserTranslation } from '../../../../User/i18n';
import { GuestLabel } from './AccountItem.styled';
import { ListItemMenu } from '../ListItemMenu';
import { useAccountsContext } from '../../../../Account';
import type {
  DesktopAccessType,
  DesktopApiType,
} from '../../../data/Desktop/types/Desktop.types';
import { AccessApiType } from '../../../../Access/data/types/Access.types';
import { useAccessActions } from '../../../../Access/data/Access.actions';
import { AccountOrigin } from '../../../../User/User.types';

interface AccountItemProps {
  access: AccessApiType;
  desktop: DesktopApiType;
}

export const AccountItem: FC<AccountItemProps> = ({ access, desktop }) => {
  const { accountsWithAvailability } = useAccountsContext();
  const { updateAccess, removeAccessById } = useAccessActions();

  const editDesktop = useCallback(
    (accessType: DesktopAccessType) => {
      updateAccess(desktop.id, access.id, accessType);
    },
    [access.id, desktop.id, updateAccess],
  );
  const deleteAccess = useCallback(() => {
    removeAccessById(access.id, desktop.id);
  }, [removeAccessById, desktop.id, access.id]);

  const { formatMessage } = useIntl();
  const { askConfirmation } = useConfirm();

  const account = accountsWithAvailability[access.account!.id];

  const isUserGuest = isAccountGuest(account);
  const displayEmail = account?.origin !== AccountOrigin.slack;

  const handleDelete = useCallback(async () => {
    if (
      await askConfirmation(
        formatMessage({
          id: DesktopTranslation.accessRemoveAccessConfirmation,
        }),
      )
    ) {
      deleteAccess();
    }
  }, [askConfirmation, deleteAccess, formatMessage]);

  return (
    <ListItem
      title={
        <>
          {getAccountName(access.account!)}
          {isUserGuest && (
            <GuestLabel groupIdentifier={GroupIdentity.GUEST}>
              {formatMessage({
                id: UserTranslation[GroupIdentity.GUEST],
              })}
            </GuestLabel>
          )}
        </>
      }
      subtitle={displayEmail ? access.account!.email : ''}
      prepend={
        <ChatAvatar account={access.account!} showOnlineStatus={false} />
      }
      append={
        <ListItemMenu
          onDelete={handleDelete}
          onEdit={editDesktop}
          accessType={access.accessType}
          isGuest={isUserGuest}
          isOnlyFullAccessAllowed={
            !desktop.shareable && access.account
              ? isAccountAdmin(accountsWithAvailability[access.account.id])
              : undefined
          }
        />
      }
    />
  );
};
