import {
  StreamEventRequest,
  StreamVaultItemEvent,
} from '../../Mercure/General';
import { Dispatch, SetStateAction } from 'react';
import { VaultApiType } from '../Encryption.types';
import { updateVaultItem } from '../utils/VaultItem.utils';
import { getAssetImageIri } from '../../Asset';

export const handleVaultItemMercureEvent = (
  event: StreamVaultItemEvent,
  setWorkspaceVaultsCache: Dispatch<
    SetStateAction<Record<string, VaultApiType[]>>
  >,
) => {
  if (event['@request'] === StreamEventRequest.UPDATE) {
    const vaultId = event.vault.id;
    const { login, password, url, image } = event;
    const workspaceId = event.vault.workspace.id;
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspaceId]: updateVaultItem(cache[workspaceId], vaultId, {
        login,
        password,
        url,
        ...(image
          ? {
              image: {
                ...image,
                id: getAssetImageIri(image.id),
              },
            }
          : { image: null }),
      }),
    }));
  }
};
