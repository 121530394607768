import React, { useCallback } from 'react';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useUpdateWorkspaceMutation,
} from '../../Workspace.hooks';
import { WorkspaceMfaForm, WorkspaceMfaFormValues } from '../WorkspaceMfaForm';
import { GET_WORKSPACES_BY_IDENTITY } from '../../Workspace.queries';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { WorkspaceTranslation } from '../../i18n';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';

export const EditWorkspaceMfaSettings = () => {
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canEditWorkspace },
    refetchPermissions,
  } = useCurrentWorkspacePermissions();

  const [updateWorkspaceMutation] = useUpdateWorkspaceMutation();

  const handleSubmit = useCallback(
    (formValues: WorkspaceMfaFormValues) => {
      return updateWorkspaceMutation({
        variables: {
          input: {
            ...formValues,
            name: workspace.name,
            description: workspace.description,
            contactEmail: workspace.contactEmail,
            homepageUrl: workspace.homepageUrl,
            id: workspace.id,
            ...(workspace.image?.id ? { image: workspace.image?.id } : {}),
          },
        },
        refetchQueries: [
          {
            query: GET_WORKSPACES_BY_IDENTITY,
            variables: {
              id: account!.identityId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(res => {
          showToastSuccessMessage(WorkspaceTranslation.formSuccessMessage);
          refetchPermissions();

          return res;
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [account, refetchPermissions, updateWorkspaceMutation, workspace],
  );

  return (
    <WorkspaceMfaForm
      workspace={workspace}
      disabled={!canEditWorkspace}
      onSubmit={handleSubmit}
    />
  );
};
