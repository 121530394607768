import React, { FC, PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { useSafari } from '../../../../shared/hooks';
import { KeyboardArrowDownIcon } from '../../../../shared/icons';
import {
  StyledKeyboardArrow,
  StyledTitleBarTitle,
  Title,
} from './TitleBarTitle.styled';

type TitleBarTitleProps = {
  withChevron?: boolean;
};

export const TitleBarTitle: FC<PropsWithChildren<TitleBarTitleProps>> = ({
  children,
  withChevron,
}) => {
  const isSafari = useSafari();
  const theme = useTheme();

  return (
    <StyledTitleBarTitle data-testid="titlebar-title">
      <Title isSafari={isSafari}>{children}</Title>
      {withChevron && (
        <StyledKeyboardArrow>
          <KeyboardArrowDownIcon color={theme.colors.OnSurfaceMediumEmphasis} />
        </StyledKeyboardArrow>
      )}
    </StyledTitleBarTitle>
  );
};
