import React from 'react';
import { InboxControls } from './Inbox.types';

export const InboxContext = React.createContext<InboxControls>({
  toggleInbox: () => {},
  closeInbox: () => {},
  keepInboxOpen: (isOpen: boolean) => {},
  isOpen: false,
  keepOpen: false,
});
