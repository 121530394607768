import styled from 'styled-components';

export const SuccessUpgradeModalBody = styled.div`
  padding: 0 1.5rem 1.5rem;
`;

export const SuccessUpgradeModalIllustration = styled.div`
  display: flex;
  justify-content: center;
`;

export const SuccessUpgradeModalIntro = styled.div`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1.25rem;
  margin: 1.5rem auto 2.5rem;
  text-align: center;
  line-height: 1.2;
`;
