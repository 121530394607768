import React from 'react';
import {
  ConferenceApiType,
  ScheduledChatConferenceApiType,
} from './Conference.types';

interface ConferenceContextType {
  conference?: ConferenceApiType;
  scheduledConference?: ScheduledChatConferenceApiType;
  scheduledConferenceLoading?: boolean;
  hideLoadMoreConferencesFuture?: boolean;
  hideLoadMoreConferencesPast?: boolean;
  refetchConferenceFuture?: () => void;
}

export const ConferenceContext = React.createContext<ConferenceContextType>({
  conference: undefined,
  scheduledConference: undefined,
  scheduledConferenceLoading: undefined,
  hideLoadMoreConferencesFuture: undefined,
  hideLoadMoreConferencesPast: undefined,
  refetchConferenceFuture: undefined,
});
