import React, { FC, Ref, useCallback, useMemo, useRef } from 'react';
import {
  Section,
  SectionContent,
  SectionProps,
  SectionTitleBar,
} from '../Section';
import { getShortId } from '../../../../../../shared/utils/id';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
  useEditHidingChatMutation,
} from '../../../../Desktop.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../../Workspace/Workspace.hooks';
import { generatePath } from 'react-router-dom';
import {
  DESKTOP_TAB_PATHNAME,
  DesktopTab,
} from '../../../../Desktop.constants';
import { CHAT_ID_PATHNAME } from '../../../../../Chat/Chat.constants';
import { ChatView } from '../../../../../Chat/ChatView';
import { DesktopTranslation } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { useElementHasScroll } from '../../../../../../shared/hooks/element.hooks';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../../../shared/components/Menu';
import { EyeClosedIcon, SearchIcon } from '../../../../../../shared/icons';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import { NavigatorActionButton } from '../../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { TABS } from '../../../../../GlobalSearch/ExpandedSearch/ExpandedSearch.constants';
import { useGlobalSearchContext } from '../../../../../GlobalSearch/GlobalSearch.hooks';
import { useDesktopsActions } from '../../../../data/Desktop/Desktop.actions';

interface ChatSectionProps extends SectionProps {
  isOnlySection?: boolean;
}

export const ChatSection: FC<ChatSectionProps> = ({
  isOnlySection,
  ...props
}) => {
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();
  const searchRef = useRef<HTMLDivElement>(null);
  const MenuRef: Ref<HTMLButtonElement> = useRef(null);
  const { canHideChat } = useCurrentDesktopPermissions();
  const [editHidingChatMutation] = useEditHidingChatMutation();
  const { setIsFocused, setSelectedTab } = useGlobalSearchContext();
  const { updateDesktopInIDDB } = useDesktopsActions();
  const { account } = useCurrentWorkspaceAccount();

  const tabbedViewUrl = useMemo(() => {
    if (!desktop?.id) {
      return undefined;
    }

    return generatePath(DESKTOP_TAB_PATHNAME, {
      workspaceId: getShortId(workspace.id),
      desktopId: getShortId(desktop.id),
      tab: DesktopTab.chat,
    });
  }, [desktop, workspace.id]);

  const chatIdUrl = useMemo(() => {
    if (!desktop?.id || !desktop.chatConversationIri) {
      return undefined;
    }

    return generatePath(CHAT_ID_PATHNAME, {
      workspaceId: getShortId(workspace.id),
      chatId: getShortId(desktop.chatConversationIri),
    });
  }, [desktop, workspace.id]);

  /**
   * Detect if section has scrollbars
   */

  const ChatSectionRef = useRef(null);
  const { hasScroll: sectionHasScroll } = useElementHasScroll(ChatSectionRef);

  const handleHideChatClick = useCallback(() => {
    if (!desktop?.id) {
      return;
    }

    updateDesktopInIDDB(desktop.id, {
      ...desktop,
      accountsThatChatHidden: [...desktop.accountsThatChatHidden, account.id],
    });

    editHidingChatMutation({
      variables: {
        input: {
          id: desktop!.id,
          isChatHidden: true,
        },
      },
    }).catch(e => showToastGraphQLErrors(e.graphQLErrors));
  }, [desktop, updateDesktopInIDDB, account.id, editHidingChatMutation]);

  return (
    <Section {...props}>
      <div ref={searchRef}>
        <SectionTitleBar
          identifier="chat"
          title={formatMessage({ id: DesktopTranslation.headerChat })}
          menuRef={canHideChat ? MenuRef : null}
          tabPath={tabbedViewUrl}
          chatLinkPath={chatIdUrl}
          chatLinkTooltipText={formatMessage({
            id: DesktopTranslation.sectionTitlebarOpenInChatsTooltip,
          })}
          displayMaximizeButton={!isOnlySection}
          searchInputPlaceholderText={formatMessage({
            id: DesktopTranslation.sectionTitlebarFilterMessagePlaceHolder,
          })}
          searchInputTooltipText={formatMessage({
            id: DesktopTranslation.sectionTitlebarFilterMessagePlaceHolder,
          })}>
          <NavigatorActionButton
            secondaryButton={!!desktop}
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: DesktopTranslation.sectionTitlebarFilterMessagePlaceHolder,
            })}
            data-tooltip-place={TooltipPlace.bottom}
            icon={SearchIcon}
            iconSize={desktop ? 16 : 20}
            onClick={() => {
              setIsFocused(true);
              setSelectedTab(TABS.CHATS);
            }}
            data-testid="search-message-btn"
          />
        </SectionTitleBar>
      </div>
      {canHideChat && (
        <Menu
          trigger={MenuRef}
          width={230}
          vAlign={VerticalAlignment.BOTTOM}
          hAlign={HorizontalAlignment.RIGHT}
          data-testid="menu">
          <MenuItem icon={EyeClosedIcon} onClick={handleHideChatClick}>
            <FormattedMessage id={DesktopTranslation.hideChatButtonLabel} />
          </MenuItem>
        </Menu>
      )}
      <SectionContent
        sectionHasScroll={sectionHasScroll}
        ref={ChatSectionRef}
        innerContentOverflowYAuto>
        <ChatView withHeader={false} />
      </SectionContent>
    </Section>
  );
};
