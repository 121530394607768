import { useMemo } from 'react';
import type { DesktopAppEdgeApiType } from '../../data/Desktop/types/Desktop.types';

interface AppsCategories {
  [key: string]: DesktopAppEdgeApiType[];
}

enum StaticCategory {
  OTHER = 'other',
}

export const AppsLayoutFluidViewModel = (apps: DesktopAppEdgeApiType[]) => {
  const categories = useMemo(() => {
    const appsCategorized: AppsCategories = {};
    for (let i = 0; i < apps.length; i++) {
      const appEdge = apps[i];
      if (appEdge.app?.category?.id) {
        const categoryId = appEdge.app?.category?.id;
        if (!appsCategorized[categoryId]) {
          appsCategorized[categoryId] = [];
        }
        appsCategorized[categoryId].push(appEdge);
      } else {
        if (!appsCategorized[StaticCategory.OTHER]) {
          appsCategorized[StaticCategory.OTHER] = [];
        }
        appsCategorized[StaticCategory.OTHER].push(appEdge);
      }
    }
    return appsCategorized;
  }, [apps]);

  return {
    categories,
  };
};
