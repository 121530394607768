import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../../../Theme';
import { SECTION_BORDER_RADIUS } from '../Section';

export const StyledSectionTitlebar = styled.div<{
  isMobile: boolean;
  isTabbed: boolean;
}>`
  height: var(--section-title-bar-height, 28px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  position: relative;

  ${({ isTabbed }) =>
    isTabbed &&
    css`
      height: 42px;
      padding: 0 0.5rem;
      background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
      border-top-left-radius: ${SECTION_BORDER_RADIUS};
      border-top-right-radius: ${SECTION_BORDER_RADIUS};
      margin-bottom: 0;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 1rem;
      background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
      position: relative;
      border-radius: 0;
    `}
`;

export const Title = styled.h3`
  flex: 1;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  letter-spacing: -0.14px;
  font-family: ${p => p.theme.fonts.Medium};

  .items-count {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const TabbedControls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
`;

export const Controls = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Separator = styled.div`
  margin: 0 0.5rem;
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.Divider};
`;

export const SearchInputContainer = styled.div<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: ${Breakpoints.sm - 1}px) {
    ${({ isActive }) =>
      isActive &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        padding: 4px 14px;
      `}
  }
`;
