import styled, { keyframes } from 'styled-components';

export const StyledIsTypingContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  display: ${p => (p.visible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  bottom: 1px;
`;

export const StyledIsTypingInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.fonts.Regular};
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0;
`;

export const StyledIsTypingDotsContainer = styled.div`
  width: 2.25rem;
  height: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 0.525rem }
  100% { margin-bottom: 0 }
`;

export const Dot = styled.div<{ delay: string }>`
  background-color: ${({ theme }) => theme.colors.ChatTypingDot};
  border-radius: 50%;
  width: 0.2rem;
  height: 0.2rem;
  margin-left: 0.2rem;

  /* Animation */
  animation: ${BounceAnimation} 0.87s linear infinite;
  animation-delay: ${props => props.delay};

  &:last-child {
    margin-right: 0.25rem;
  }
`;

export const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
