import React, { FC } from 'react';
import { CheckmarkIcon } from '../../../../../shared/icons';
import { useTheme } from 'styled-components';
import {
  LinkAccountSuccessIcon,
  LinkAccountSuccessMessage,
  StyledLinkAccountSuccessScreen,
} from './LinkAccountSuccessScreen.styled';
import { OnboardingTranslation } from '../../../i18n';
import { FormattedMessage } from 'react-intl';

export const LinkAccountSuccessScreen: FC = () => {
  const theme = useTheme();

  return (
    <StyledLinkAccountSuccessScreen>
      <LinkAccountSuccessIcon>
        <CheckmarkIcon width={20} height={20} color={theme.colors.Success} />
      </LinkAccountSuccessIcon>
      <LinkAccountSuccessMessage>
        <FormattedMessage
          id={OnboardingTranslation.onboardingLinkAccountSuccessMessage}
        />
      </LinkAccountSuccessMessage>
    </StyledLinkAccountSuccessScreen>
  );
};
