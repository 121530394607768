import styled, { css } from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';
import { GeneralInfoImage, GeneralInfoMeta } from '../../Admin';

export const WorkspaceInfoContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WorkspaceInfoImage = styled(GeneralInfoImage)`
  margin-bottom: 1rem;
`;

export const WorkspaceInfoTitle = styled.h4`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 1rem;
`;

export const WorkspaceInfoDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  margin-bottom: 1rem;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const WorkspaceInfoItem = styled.div`
  display: flex;
  width: 100%;

  & ~ & {
    margin-top: 0.5rem;
  }

  & > * {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const WorkspaceInfoMeta = styled(GeneralInfoMeta)<{
  isMobile?: boolean;
}>`
  ${p =>
    p.isMobile &&
    css`
      ${WorkspaceInfoItem} {
        flex-direction: column;
      }
    `}
`;

export const WorkspaceInfoItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  min-width: 7.5rem;
`;

export const WorkspaceInfoData = styled.div`
  color: ${({ theme }) => theme.colors.Primary};
`;

export const WorkspaceInfoEditButton = styled(Button)`
  margin-top: 1rem;
`;
