import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const WorkspaceFeatureConfirmation = styled.div`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin-top: 0.25rem;

  & > div {
    margin: 1.5rem 0;
  }
`;

export const WorkspaceFeatureConfirmationControls = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button} ~ ${Button} {
    margin-left: 0.625rem;
  }
`;
