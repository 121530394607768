import React, { FC } from 'react';
import {
  BillingPageBannerItem,
  BillingPageBannerItemDescription,
  BillingPageBannerItemTitle,
  StyledBillingPageBannerBanner,
} from './BillingPageBanner.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';

export const BillingPageBanner: FC = () => {
  return (
    <StyledBillingPageBannerBanner>
      <BillingPageBannerItem>
        <FormattedMessage
          id={OnboardingTranslation.billingPageBannerItemChargeNowTitle}
          tagName={BillingPageBannerItemTitle}
        />
        <FormattedMessage
          id={OnboardingTranslation.billingPageBannerItemChargeNowDescription}
          tagName={BillingPageBannerItemDescription}
        />
      </BillingPageBannerItem>
      <BillingPageBannerItem>
        <FormattedMessage
          id={OnboardingTranslation.billingPageBannerItemCancelTitle}
          tagName={BillingPageBannerItemTitle}
        />
        <FormattedMessage
          id={OnboardingTranslation.billingPageBannerItemCancelDescription}
          tagName={BillingPageBannerItemDescription}
        />
      </BillingPageBannerItem>
      <BillingPageBannerItem>
        <FormattedMessage
          id={OnboardingTranslation.billingPageBannerItemChangeMethodTitle}
          tagName={BillingPageBannerItemTitle}
        />
        <FormattedMessage
          id={
            OnboardingTranslation.billingPageBannerItemChangeMethodDescription
          }
          tagName={BillingPageBannerItemDescription}
        />
      </BillingPageBannerItem>
    </StyledBillingPageBannerBanner>
  );
};
