import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';

export const StyledConversationNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:focus-visible {
    border: 2px solid ${p => p.theme.colors.PrimaryLight};
    border-radius: 0.25rem;
    outline: none;
  }
`;

export const StyledConversationListItem = styled.span<{
  hasHover: boolean;
  highlighted: boolean;
}>`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xs};
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
  margin: 0;
  font-weight: 400;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  max-height: 32px;
  height: 32px;

  .wrapper {
    width: 100%;
  }

  .countBadgeWrapper {
    margin-left: 0.375rem;
  }

  ${({ hasHover }) =>
    !hasHover &&
    css`
      &:hover button {
        visibility: visible;
      }

      &:hover {
        background-color: ${p => p.theme.colors.OnSurfaceHover};
      }
    `};

  ${({ theme, highlighted }) =>
    highlighted &&
    css`
      font-family: ${theme.fonts.Semibold};
      color: ${theme.colors.OnSurfaceHighEmphasis};
    `}

  &.active {
    background-color: ${p => p.theme.colors.Primary};
    color: ${p => p.theme.colors.White};
  }
`;

export const ConversationName = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  display: -webkit-box;

  div {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  svg {
    margin-left: 0.25rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const ConversationInner = styled.div`
  display: flex;
  align-items: center;
`;

export const ConversationControls = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  max-width: 100%;
  flex-direction: column;
  padding-left: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const Message = styled.div`
  font-size: 0.75rem;
  word-break: break-all;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  .title {
    display: flex;
    justify-content: space-between;
  }
`;

export const ControlsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

export const OptionsButton = styled(Button)<{
  visible: boolean;
  isActive: boolean;
}>`
  outline: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.OnSurfaceHover};
    `};

  svg {
    margin-right: unset;
  }

  ${({ visible }) =>
    !visible &&
    css`
      &:hover {
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
      }
    `};
`;

export const ConversationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IntegrationBadgeContainer = styled.div`
  margin-left: 0.25rem;
`;

export const StyledConversationSharpIcon = styled.div<{
  highlighted: boolean;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
`;

export const ConversationListItemSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;
