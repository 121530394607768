import React, { FC } from 'react';
import {
  SelectVaultListItemRadioInput,
  SelectVaultListItemTitle,
  StyledSelectVaultListItem,
} from './ChooseVaultForDesktopListItem.styled';
import { ButtonSize } from '../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { KeyboardArrowDownIcon } from '../../../../shared/icons';
import { EncryptionTranslation } from '../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';

interface ChooseVaultForDesktopItemProps {
  checked: boolean;
  disabled?: boolean;
  title: string;
  toggle?: () => void;
  onSelect: () => void;
}

export const ChooseVaultForDesktopListItem: FC<
  ChooseVaultForDesktopItemProps
> = ({ checked, disabled = false, title, toggle, onSelect }) => {
  const intl = useIntl();

  return (
    <StyledSelectVaultListItem data-testid="select-vault-list-item">
      <SelectVaultListItemTitle data-testid="select-vault-list-item-title">
        <SelectVaultListItemRadioInput
          checked={checked}
          disabled={disabled}
          onClick={onSelect}
          data-testid="select-vault-list-item-radio-input"
        />
        {title || (
          <FormattedMessage
            id={EncryptionTranslation.selectVaultEncryptedItemPlaceholder}
          />
        )}
      </SelectVaultListItemTitle>
      {toggle && (
        <ButtonWithIcon
          title={intl.formatMessage({
            id: EncryptionTranslation.passwordVaultItemExpandTooltip,
          })}
          aria-label={intl.formatMessage({
            id: EncryptionTranslation.selectVaultItemExpandButton,
          })}
          icon={KeyboardArrowDownIcon}
          type="button"
          size={ButtonSize.sm}
          onClick={toggle}
          data-testid="select-vault-item-expand-button"
        />
      )}
    </StyledSelectVaultListItem>
  );
};
