import styled from 'styled-components';

export const StyledEmptyState = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  max-width: 25.5rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  line-height: 1.7;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const EmptyStateDescription = styled.p`
  font-size: 0.875rem;
  margin-top: 1rem;
`;

export const EmptyStateSupportText = styled.p`
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;
