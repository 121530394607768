import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import {
  ConferenceOrganizerHeaderSection,
  ConferenceOrganizerInner,
  ConferenceOrganizerWrapper,
  StyledConferenceOrganizer,
  StyledConferenceOrganizerTitle,
} from './ScheduledConferenceOrganizer.styled';
import {
  useCurrentConference,
  useScheduledConferenceMembers,
} from '../../../Conference.hooks';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { MemberItem } from '../../../../Chat/MembersList/MemberItem';
import { ScheduledConferenceLinkHeader } from '../ScheduledConferenceLink/ScheduledConferenceLink.styled';

export const ScheduledConferenceOrganizer: FC = () => {
  const { scheduledConference } = useCurrentConference();
  const { account } = useCurrentAccount();
  const members = useScheduledConferenceMembers(true).map(
    member => member.account,
  );
  const createdBy = useMemo(
    () =>
      [...members, account].find(
        member => member?.id === scheduledConference?.createdBy?.id,
      ),
    [account, members, scheduledConference],
  );

  return (
    <ConferenceOrganizerWrapper>
      <ConferenceOrganizerInner>
        <ConferenceOrganizerHeaderSection>
          <ScheduledConferenceLinkHeader>
            <StyledConferenceOrganizerTitle>
              <FormattedMessage
                id={ConferenceTranslation.scheduledUpcomingConferenceOrganizer}
              />
            </StyledConferenceOrganizerTitle>
          </ScheduledConferenceLinkHeader>
        </ConferenceOrganizerHeaderSection>
        <StyledConferenceOrganizer>
          {createdBy && <MemberItem account={createdBy} showPopover />}
        </StyledConferenceOrganizer>
      </ConferenceOrganizerInner>
    </ConferenceOrganizerWrapper>
  );
};
