import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { StyledToastContainer } from './Toast.styled';

export const ToastContainer: FC = () => {
  return ReactDOM.createPortal(
    <StyledToastContainer />,
    document.getElementById('toast') as Element,
  );
};
