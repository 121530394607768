import React from 'react';
import { ClipboardContextTypes } from './Clipboard.types';

export const ClipboardContext = React.createContext<ClipboardContextTypes>({
  clipboardData: null,
  canPaste: false,
  clearClipboardData: () => {},
  readClipboardData: () => {},
  createClipboardData: (data, onCreate) => {},
});
