import React, { FC, PropsWithChildren } from 'react';
import { StyledReactions } from './MessageReactions.styled';
import type { ChatMessageInternalType } from '../../../../Chat.types';
import { MessageReactionsWrapper } from './MessageReactionsWrapper';

interface MessageReactionsProps {
  chatMessage: ChatMessageInternalType;
}

export const MessageReactions: FC<PropsWithChildren<MessageReactionsProps>> = ({
  chatMessage,
  children,
}) => {
  return (
    <StyledReactions data-testid="message-reactions">
      {chatMessage.reactions.map(({ emoji, userIds }) => (
        <MessageReactionsWrapper
          key={emoji}
          emoji={emoji}
          accounts={userIds}
          chatMessage={chatMessage}
        />
      ))}
      {children}
    </StyledReactions>
  );
};
