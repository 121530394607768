export const MULTIPLE_LANDSCAPE_WIDTH = 320;

export const LANDSCAPE_WIDTH = 400;
export const LANDSCAPE_MAX_HEIGHT = 237;
export const LANDSCAPE_MAX_HEIGHT_MOBILE = 105;

export const PORTRAIT_HEIGHT = 300;
export const MOBILE_PORTRAIT_HEIGHT = 180;
export const MULTIPLE_PORTRAIT_WIDTH = 144;

export const MULTIPLE_IMAGE_HEIGHT = 224;

export const SQUARE_MIN_SIZE = 50;

export const MOBILE_MULTIPLE_IMAGE_SIZE = 105;

export const MOBILE_MULTIPLE_IMAGE_SIZE_WITH_COUNT = 75;

export const MOBILE_MAX_ASSET_TO_VIEW = 4;
