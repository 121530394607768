import React, { FC, useEffect, useRef } from 'react';
import { useIsInViewport } from '../../../shared/hooks/dom.hooks';

interface NotificationObserverProps {
  onMarkAsRead: () => void;
}

export const NotificationObserver: FC<NotificationObserverProps> = ({
  onMarkAsRead,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const messageIsInView = useIsInViewport(elementRef);

  useEffect(() => {
    if (messageIsInView) {
      onMarkAsRead();
    }
  }, [messageIsInView, onMarkAsRead]);

  return <div ref={elementRef} />;
};
