import React, { FC, useCallback, useState } from 'react';
import _get from 'lodash/get';
import { StyledActivateWorkspaceForm } from './ActivateWorkspaceForm.styled';
import {
  useActivateWorkspaceMutation,
  useCurrentWorkspace,
} from '../../Workspace.hooks';
import { GET_WORKSPACES_BY_IDENTITY } from '../../Workspace.queries';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  showToastError,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { WorkspaceTranslation } from '../../i18n';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { RegisterWorkspaceFormSubmitHandler } from './ActivateWorkspaceForm.types';
import { RegisterFreeProWorkspaceForm } from './RegisterFreeProWorkspaceForm';
import { WorkspaceApiType } from '../../Workspace.types';
import { SignUpFlow } from '../../../Onboarding/Onboarding.types';
import { RegisterEnterpriseWorkspaceForm } from './RegisterEnterpriseWorkspaceForm';
import { useIntl } from 'react-intl';

interface ActivateWorkspaceProps {
  onActivate: (workspace: WorkspaceApiType) => void;
  handleChangeWorkspaceName: (name: string) => void;
}

export const ActivateWorkspace: FC<ActivateWorkspaceProps> = ({
  onActivate,
  handleChangeWorkspaceName,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { account } = useCurrentAccount();
  const [activateWorkspaceMutation] = useActivateWorkspaceMutation();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace?.id;
  const intl = useIntl();

  const onDone = useCallback(
    (workspace: WorkspaceApiType) => {
      onActivate(workspace);
      showToastSuccessMessage(WorkspaceTranslation.formSuccessMessage);
    },
    [onActivate],
  );

  const handleSubmit: RegisterWorkspaceFormSubmitHandler = useCallback(
    (activateWorkspaceFormInput, { setFieldError }) => {
      setLoading(true);

      return activateWorkspaceMutation({
        variables: {
          input: {
            id: workspaceId,
            ...activateWorkspaceFormInput,
          },
        },
        refetchQueries: [
          {
            query: GET_WORKSPACES_BY_IDENTITY,
            variables: {
              id: account!.identityId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(res => {
          if (!res.data) {
            return;
          }
          onDone(res.data.activateWorkspace.workspace);
        })
        .catch(e => {
          const graphQLErrorMessage = _get(e, 'graphQLErrors.0.message');
          if (
            graphQLErrorMessage &&
            graphQLErrorMessage.includes('identifier')
          ) {
            setFieldError(
              'identifier',
              intl.formatMessage(
                {
                  id: WorkspaceTranslation.formSubdomainErrorMessage,
                },
                {
                  subdomainName: activateWorkspaceFormInput.identifier,
                },
              ),
            );
          } else {
            showToastError(e.message);
          }
          setLoading(false);
        });
    },
    [activateWorkspaceMutation, workspaceId, account, onDone, intl],
  );

  return (
    <StyledActivateWorkspaceForm data-testid="workspace-edit-form">
      {account?.currentFlow === SignUpFlow.ENTERPRISE ? (
        <RegisterEnterpriseWorkspaceForm onSubmit={handleSubmit} />
      ) : (
        <RegisterFreeProWorkspaceForm
          handleChangeWorkspaceName={handleChangeWorkspaceName}
          onSubmit={handleSubmit}
        />
      )}

      {loading && <SpinnerFullscreen />}
    </StyledActivateWorkspaceForm>
  );
};
