import { ApiConnection, ImageApiType } from '../../shared/api/api.types';
import type {
  GRAPHQL_TYPENAME_LINK,
  GRAPHQL_TYPENAME_RECENTLINKS,
} from './Link.constants';
import { DocumentType } from '../Preview/DocumentPreview';
import { VideoProvider } from '../VideoPlayer/VideoPlayer.hooks';
import { AccountApiType } from '../User/User.types';
import { ReactSelectOptionType } from '../../shared/components/ReactSelect/ReactSelect.types';

export interface LinkApiType {
  __typename?: typeof GRAPHQL_TYPENAME_LINK;
  id: string;
  _id: string;
  accountFavorite: boolean;
  createdBy?: LinkCreatedByType;
  createdAt: string;
  movedAt: string;
  data: LinkData;
  folder: {
    id: string;
    _id: string;
  } | null;
  tags: ApiConnection<TagApiType>;
  customTitle?: string;
  customImage?: ImageApiType;
  workspaceId: string;
  desktopId: string;
  chatConversationIri: string | null;
  messagesCount: number;
}

interface LinkCreatedByType
  extends Pick<
    AccountApiType,
    'email' | 'id' | 'firstName' | 'lastName' | 'image'
  > {
  createdAt: string;
  modifiedAt: string;
}

export interface LinkDocumentMetaData {
  type: DocumentType;
  preview_url: string;
  preview_available: boolean;
  name?: string;
  authorization_url?: string;
  no_access?: boolean;
}

export interface LinkVideoMetaData {
  type: VideoProvider;
  embed_link: string;
}

export interface LinkData {
  _id: string;
  id: string;
  url: string;
  realUrl?: string;
  title?: string;
  description?: string;
  icon?: ImageApiType;
  image?: ImageApiType;
  screenshot?: ImageApiType;
  status: LinkStatus;
  abuseChecked: boolean;
  safe: boolean;
  abuseDescription?: string;
  metadata?: LinkDocumentMetaData | LinkVideoMetaData | null;
}

export enum LinkStatus {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  NOT_ATTACHED = 'NOT_ATTACHED',
}

export interface LinkFormValues {
  customImage?: string;
  name: string;
  url: string;
  tags: string[];
}

export interface TagApiType {
  id: string;
  _id: string;
  name: string;
}

export interface TagFormValues {
  tags: ReactSelectOptionType[];
}

export interface RecentLinksApiType {
  link: LinkApiType;
  __typename: typeof GRAPHQL_TYPENAME_RECENTLINKS;
}
