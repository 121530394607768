import React, { FC, useMemo, useRef } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import { TimelineTranslation } from '../../i18n';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../Timeline.constants';
import { TimelineEventApiType } from '../../Timeline.types';
import { LinkInfo } from './LinkInfo';
import { TimelineAppInfo } from './TimelineAppInfo';
import { TimelineDesktopInfo } from './TimelineDesktopInfo';
import { TimelineEventTitleWrapper } from './TimelineEvent.styled';
import { TimelineFolderInfo } from './TimelineFolderInfo';
import { getShortId } from '../../../../shared/utils/id';

interface TimelineEventTitleProps {
  event: TimelineEventApiType;
}

export const TimelineEventTitle: FC<TimelineEventTitleProps> = ({ event }) => {
  const { context } = event;
  const intl = useIntl();
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const eventPersonalCreated = context.created_by === account!.id;
  const iconRef = useRef(null);

  const renderTimelineEventTitle = useMemo(() => {
    switch (event.scopeType) {
      case TimelineEventScope.desktops:
        switch (event.action) {
          case TimelineEventAction.create:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventDesktopCreated}
                values={{
                  desktopInfo: (
                    <Link
                      className="highlighted"
                      to={`/workspace/${getShortId(workspace.id)}/desktop/${
                        context.desktop_id
                      }/apps`}>
                      {context.desktop_name}
                    </Link>
                  ),
                }}
              />
            );
          case TimelineEventAction.delete:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventDesktopDeleted}
                values={{
                  desktopInfo: (
                    <Link
                      className="highlighted"
                      to={`/workspace/${getShortId(workspace.id)}/desktop/${
                        context.desktop_id
                      }/apps`}>
                      {context.desktop_name}
                    </Link>
                  ),
                }}
              />
            );
          case TimelineEventAction.add_team:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventTeamAddedToDesktop}
                values={{
                  teamInfo: (
                    <span className="highlighted">{context.team_name}</span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add_team}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove_team:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventTeamRemovedFromDesktop}
                values={{
                  teamInfo: (
                    <span className="highlighted">{context.team_name}</span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove_team}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.add_receiver:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventMemberAddedToDesktop}
                values={{
                  accountInfo: (
                    <span className="highlighted">
                      {intl.formatMessage({
                        id: TimelineTranslation.eventThisAccountLabel,
                      })}
                    </span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add_receiver}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove_receiver:
            return (
              <FormattedMessage
                id={
                  context.created_by === context.created_for
                    ? TimelineTranslation.eventMemberLeftTheDesktop
                    : TimelineTranslation.eventMemberRemovedFromDesktop
                }
                values={{
                  accountInfo: (
                    <span className="highlighted">
                      {eventPersonalCreated
                        ? intl.formatMessage({
                            id: TimelineTranslation.eventThisAccountLabel,
                          })
                        : context.created_by_name}
                    </span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove_receiver}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.add_sender:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventUserAddedDesktop}
                values={{
                  memberInfo: (
                    <span className="highlighted">
                      {context.created_for_name}
                    </span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add_sender}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove_sender:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventUserRemovedFromDesktop}
                values={{
                  memberInfo: (
                    <span className="highlighted">
                      {context.created_for_name}
                    </span>
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove_sender}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.guest_limit_exceed:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventGuestLimitExceeded}
                values={{
                  memberName: context.created_for_name,
                  desktopName: context.desktop_name,
                }}
              />
            );
          case TimelineEventAction.remove_app_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppSectionRemoved}
                values={{
                  appsNumber: context.removed_items_count,
                }}
              />
            );
          case TimelineEventAction.remove_link_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkSectionRemoved}
                values={{
                  linksNumber: context.removed_items_count,
                }}
              />
            );
        }
        break;

      case TimelineEventScope.desktop_apps:
        switch (event.action) {
          case TimelineEventAction.open:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppOpened}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isApp
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.open}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
        }
        break;

      case TimelineEventScope.apps:
        switch (event.action) {
          case TimelineEventAction.open:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppOpened}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isApp
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.open}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.create_custom:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppAddedToDesktop}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isApp
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove_custom:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppRemovedFromDesktop}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isApp
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.add_custom_to_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventCustomAppAddedToDesktop}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isApp
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add_custom_to_desktop}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove_custom_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventCustomAppRemovedFromDesktop}
                values={{
                  appInfo: (
                    <TimelineAppInfo
                      appName={context.app_name}
                      appUrl={context.app_url}
                      appId={event.scopeId}
                      iconRef={iconRef}
                      isLinkInactive
                      isApp
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={
                        TimelineEventAction.remove_custom_from_desktop
                      }
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                    />
                  ),
                }}
              />
            );
        }
        break;

      case TimelineEventScope.links:
        switch (event.action) {
          case TimelineEventAction.open:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkOpened}
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLink
                    />
                  ),
                  folderInfo: (
                    <TimelineFolderInfo
                      folderName={context.folder_name}
                      folderId={context.folder_id}
                      eventScope={TimelineEventScope.links}
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.open}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.add:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkAddedToDesktop}
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLink
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.update:
            return event.context.diff?.folder ? (
              <FormattedMessage
                id={
                  event.context.diff.folder.new
                    ? TimelineTranslation.eventLinkMovedToFolder
                    : TimelineTranslation.eventLinkMovedFromFolder
                }
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLink
                    />
                  ),
                  // TODO: implement TimelineFolderInfo after backend will support more folder data in diff object
                  folderInfo: event.context.diff.folder.new ? (
                    <TimelineFolderInfo
                      folderId={event.context.folder_id}
                      folderName={event.context.folder_name}
                    />
                  ) : (
                    <b>{event.context.diff.folder.old}</b>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id={TimelineTranslation.eventLinkUpdated}
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLink
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.update}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.remove:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkRemovedFromDesktop}
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLinkInactive
                      isLink
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.comment:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkComment}
                values={{
                  linkInfo: (
                    <LinkInfo
                      id={event.scopeId}
                      context={event.context}
                      iconRef={iconRef}
                      isLink
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
        }
        break;

      case TimelineEventScope.folders:
        switch (event.action) {
          case TimelineEventAction.add:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventFolderAddedToDesktop}
                values={{
                  folderInfo: (
                    <TimelineFolderInfo
                      folderName={context.folder_name}
                      folderId={context.folder_id}
                    />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.add}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
          case TimelineEventAction.delete:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventFolderRemovedFromDesktop}
                values={{
                  folderInfo: (
                    <TimelineFolderInfo folderName={context.folder_name} />
                  ),
                  desktopInfo: (
                    <TimelineDesktopInfo
                      eventAction={TimelineEventAction.remove}
                      desktopId={context.desktop_id}
                      desktopName={context.desktop_name}
                      shareable={context.desktop_shareable}
                      memberCount={context.member_count}
                    />
                  ),
                }}
              />
            );
        }
        break;

      case TimelineEventScope.tags:
        switch (event.action) {
          case TimelineEventAction.add:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventTagAddedToLink}
                values={{
                  tagName: context.tag_name,
                }}
              />
            );
        }
        break;

      case TimelineEventScope.workspace_teams:
        switch (event.action) {
          case TimelineEventAction.add:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAddTeam}
                values={{
                  teamName: context.team_name,
                }}
              />
            );
          case TimelineEventAction.remove:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventTeamRemoved}
                values={{
                  teamName: context.team_name,
                }}
              />
            );
          case TimelineEventAction.add_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountAddToTeam}
                values={{
                  memberName: context.created_for_name,
                  teamName: context.team_name,
                }}
              />
            );
          case TimelineEventAction.add_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountAddedToTeam}
                values={{
                  teamName: context.team_name,
                }}
              />
            );
          case TimelineEventAction.remove_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountToRemoveFromTeam}
                values={{
                  memberName: context.created_for_name,
                  teamName: context.team_name,
                }}
              />
            );
          case TimelineEventAction.remove_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountRemovedFromTeam}
                values={{
                  teamName: context.team_name,
                }}
              />
            );
        }
        break;

      case TimelineEventScope.payment_customers:
        switch (event.action) {
          case TimelineEventAction.failed_payment_attempt:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventPaymentFailed}
              />
            );
        }
        break;

      case TimelineEventScope.workspaces:
        switch (event.action) {
          case TimelineEventAction.account_activated_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountActivated}
                values={{
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.account_activated_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountToActivate}
                values={{
                  memberName: context.created_for_name,
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.account_deactivated_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountToSuspend}
                values={{
                  memberName: context.created_for_name,
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.account_deactivated_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountSuspended}
                values={{
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.account_removed_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountToRemove}
                values={{
                  memberName: context.created_for_name,
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.account_removed_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountRemoved}
                values={{
                  workspaceName: context.workspace_name,
                }}
              />
            );
          case TimelineEventAction.set_admin_sender:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountToAssignAdmin}
                values={{
                  memberName: context.created_for_name,
                }}
              />
            );
          case TimelineEventAction.set_admin_receiver:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountAssignedAdmin}
                values={{
                  memberName: context.created_for_name,
                }}
              />
            );
          case TimelineEventAction.lost_owner:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountLostOwner}
                values={{
                  memberName: context.created_by_name,
                }}
              />
            );
          case TimelineEventAction.gained_owner:
            return (
              <FormattedHTMLMessage
                id={TimelineTranslation.eventAccountGainedOwner}
                values={{
                  memberName: context.created_by_name,
                }}
              />
            );
        }
        break;

      default:
        return null;
    }
  }, [
    context.app_name,
    context.app_url,
    context.created_by,
    context.created_by_name,
    context.created_for,
    context.created_for_name,
    context.desktop_id,
    context.desktop_name,
    context.desktop_shareable,
    context.folder_id,
    context.folder_name,
    context.member_count,
    context.removed_items_count,
    context.tag_name,
    context.team_name,
    context.workspace_name,
    event,
    eventPersonalCreated,
    intl,
    workspace.id,
  ]);

  return (
    <TimelineEventTitleWrapper>
      {renderTimelineEventTitle}
    </TimelineEventTitleWrapper>
  );
};
