import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { LinkApiType } from '../Link/Link.types';
import { StreamEventActionRequest, StreamEventType } from '../Mercure/General';
import { ExternalServiceAuthContext } from './ExternalServiceAuth.context';
import { ExternalServiceAuthContextType } from './ExternalServiceAuth.types';
import { DocumentType } from '../Preview/DocumentPreview';
import { GET_LINK_DATA_SYNC } from '.';
import { useApolloClient } from '@apollo/client';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';
import { showToastGraphQLErrors } from '../../shared/components/Toast';
import { GraphQLError } from 'graphql';
import { useMercureListener } from '../Mercure/General/GeneralMercure.hooks';

// Provider that saves, chat or desktop (or something similar) links that require authorization (google, jira and etc) followed by an update request data
export const ExternalServiceAuthProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const apolloClient = useApolloClient();

  const linksRequireAuth = useRef<Map<string, LinkApiType[]>>(
    new Map<string, LinkApiType[]>(),
  );

  const { addListener, removeListener } = useMercureListener();

  const addLinkWithAuthUrl = useCallback((linkData: LinkApiType) => {
    const linkType = linkData.data?.metadata?.type ?? null;
    const links = linksRequireAuth.current;

    if (!linkType || !links) {
      return;
    }

    const linkTypeLinks = links.get(linkType) ?? [];

    const linkExists = linkTypeLinks.find(
      (link: LinkApiType) => linkData.id === link.id,
    );

    if (linkExists) {
      return;
    }

    links.set(linkType, [...linkTypeLinks, linkData]);
  }, []);

  const refetchLinksData = useCallback(() => {
    const messageLinks = linksRequireAuth.current;

    if (messageLinks.size === 0) {
      return;
    }

    const messagesToFetch = [
      ...(messageLinks.get(DocumentType.GOOGLE_DRIVE) ?? []),
      ...(messageLinks.get(DocumentType.GOOGLE_DOCUMENT) ?? []),
      ...(messageLinks.get(DocumentType.GOOGLE_SPREADSHEETS) ?? []),
      ...(messageLinks.get(DocumentType.GOOGLE_FOLDER) ?? []),
      ...(messageLinks.get(DocumentType.GOOGLE_PRESENTATION) ?? []),
    ]?.map(message => message.data.id);

    apolloClient
      .query({
        query: GET_LINK_DATA_SYNC,
        variables: {
          ids: messagesToFetch,
          workspaceIri: currentWorkspace.id,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      })
      .catch((e: { graphQLErrors: GraphQLError[] }) => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [apolloClient, currentWorkspace]);

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = e => {
      if (
        e['@type'] === StreamEventType.ACTION &&
        e['@request'] === StreamEventActionRequest.GOOGLE_ACCOUNT_CONNECTED
      ) {
        refetchLinksData();
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, refetchLinksData, removeListener]);

  const contextValue: ExternalServiceAuthContextType = useMemo(
    () => ({
      addLinkWithAuthUrl,
    }),
    [addLinkWithAuthUrl],
  );

  return (
    <ExternalServiceAuthContext.Provider value={contextValue}>
      {children}
    </ExternalServiceAuthContext.Provider>
  );
};
