import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { Breakpoints } from '../../../../../../../Theme';
import { Button } from '../../../../../../../../shared/components/Button/Button';

export const StyledSearchInput = styled.div<{
  styles?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}>`
  display: grid;
  grid-template-columns: 20px 1fr 24px;
  padding: 0.375rem;
  align-items: center;
  max-width: 24px;
  width: 100%;
  height: 1.75rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
  border-radius: 4px;
  transition: 0.2s max-width ease-out;
  overflow: hidden;

  &.is-active {
    max-width: 220px;
  }

  @media (max-width: ${Breakpoints.sm - 1}px) {
    &.is-active {
      max-width: 500px;
    }
  }

  button {
    height: 24px;
  }

  ${({ styles }) => styles}
`;

export const Input = styled.input`
  font-size: 0.875rem;
  appearance: none;
  border: 0;
  background: transparent;
  width: 100%;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  outline: none;
`;

export const CancelButton = styled(Button)`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.Primary};
`;
