import React, { FC, useMemo } from 'react';
import { FieldProps } from 'formik';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import _get from 'lodash/get';
import { GlobalTranslation } from '../../../Intl/i18n/i18n.types';
import { ErrorMessage } from '../../../../shared/components/Input';
import { FormattedMessage } from 'react-intl';
import { ScheduleConferenceFormValues } from '../../Conference.types';
import { ReactSelectOptionsType } from '../../../../shared/components/ReactSelect/ReactSelect.types';

interface RepeatingSelectProps extends ReactSelectOptionsType {
  label?: string;
  disabled: boolean;
  onChange: () => void;
  onBlur: (
    value: ScheduleConferenceFormValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate: boolean | undefined,
    ) => void,
  ) => void;
}

export const RepeatingSelect: FC<FieldProps & RepeatingSelectProps> = ({
  options,
  field,
  form,
  disabled,
  onBlur,
  onChange,
}) => {
  const theme = useTheme();
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  return (
    <div data-testid="react-select">
      <Select
        isDisabled={disabled}
        options={options}
        name={field.name}
        filterOption={option => !!option.label}
        value={options[field.value.value]}
        onChange={onChange}
        onBlur={() => {
          onBlur(form.values, form.setFieldValue);
        }}
        styles={{
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceMediumEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            height: '41px',
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
          // TODO: fix problem with types
          // @ts-ignore
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? theme.colors.OnSurfaceSelected
                : isFocused
                ? theme.colors.OnSurfacePressed
                : null,
              color: isSelected
                ? theme.colors.OnSurfaceHighEmphasis
                : theme.colors.OnSurfaceHighEmphasis,

              ':active': {
                ...styles[':active'],
                backgroundColor: theme.colors.OnSurfaceLightEmphasis,
              },
            };
          },
        }}
      />
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </div>
  );
};
