import {
  ActionType,
  ConversationStateAction,
  ConversationStateState,
} from './ConversationState.types';

export const conversationStateReducer = (
  state: ConversationStateState,
  action: ConversationStateAction,
): ConversationStateState => {
  switch (action.type) {
    case ActionType.SetScrollToMessageId: {
      return {
        ...state,
        scrollToMessageId: action.messageId,
      };
    }
    case ActionType.UnsetScrollToMessageId: {
      return {
        ...state,
        scrollToMessageId: null,
      };
    }
    case ActionType.SetOpenThreadOfMessageId: {
      return {
        ...state,
        openThreadOfMessageId: action.messageId,
      };
    }
    case ActionType.UnsetOpenThreadOfMessageId: {
      return {
        ...state,
        openThreadOfMessageId: null,
      };
    }
    case ActionType.SetState: {
      const { type, ...partialState } = action;
      return {
        ...state,
        ...partialState,
      };
    }
    case ActionType.IncreaseMountedMessageForms: {
      return {
        ...state,
        mountedMessageFormsCount: state.mountedMessageFormsCount + 1,
      };
    }
    case ActionType.DecreaseMountedMessageForms: {
      const mountedMessageFormsCount = state.mountedMessageFormsCount - 1;

      if (mountedMessageFormsCount < 0) {
        return state;
      }

      return {
        ...state,
        mountedMessageFormsCount,
      };
    }
    default: {
      return state;
    }
  }
};
