import styled, { css } from 'styled-components';

export const WorkspaceMfaActivateBanner = styled.div<{
  isMobile?: boolean;
}>`
  background-color: ${p => p.theme.colors.SelectedItemLight};
  padding: 1.125rem 1.5rem 1.125rem 1.125rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 0.625rem;

  .icon {
    margin-right: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
    color: ${p => p.theme.colors.PrimaryDark};
  }

  ${p =>
    p.isMobile &&
    css`
      padding: 0.75rem;
    `}
`;

export const WorkspaceMfaActivateBannerContent = styled.div`
  display: flex;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.01em;
  margin-right: 1rem;

  &:not(:last-child) {
    margin: 0 1rem 0 0;
  }
`;

export const WorkspaceMfaActivateField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WorkspaceMfaActivateFieldTitle = styled.div<{
  isActive?: boolean;
}>`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  &:not(:last-child) {
    margin-right: 1rem;
  }

  ${p =>
    p.isActive &&
    css`
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    `}
`;
