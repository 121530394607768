import React, { FC } from 'react';
import { FieldProps } from 'formik';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { Textarea } from './Textarea';

const StyledFormInput = styled.div<{ invalid: boolean }>`
  ${({ invalid, theme }) =>
    invalid &&
    css`
      input {
        box-shadow: inset 0 0 0 0.063rem ${theme.colors.CriticalLight};
        &:focus {
          box-shadow: inset 0 0 0 0.125rem ${theme.colors.CriticalLight};
        }
      }
    `}
`;

const FormTextareaLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.2;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: ${p => p.theme.colors.CriticalDark};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-top: 0.25rem;
`;

interface FormTextareaProps {
  label?: string;
  maxLength?: number;
}

export const FormTextarea: FC<FieldProps & FormTextareaProps> = ({
  field,
  form,
  label,
  maxLength = 255,
  ...props
}) => {
  const errorIdToDisplay = form.touched[field.name] && form.errors[field.name];
  return (
    <StyledFormInput invalid={!!errorIdToDisplay}>
      {label && (
        <FormTextareaLabel htmlFor={field.name}>{label}</FormTextareaLabel>
      )}
      <Textarea id={field.name} maxLength={maxLength} {...field} {...props} />
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </StyledFormInput>
  );
};
