import { QueryHookOptions, useMutation } from '@apollo/client';
import {
  CREATE_WORKSPACE_DOMAIN,
  CreateWorkspaceDomainResponse,
  CreateWorkspaceDomainVariables,
  DELETE_WORKSPACE_DOMAIN,
  DeleteWorkspaceDomainResponse,
  DeleteWorkspaceDomainVariables,
  UPDATE_WORKSPACE_DOMAIN,
  UpdateWorkspaceDomainResponse,
  UpdateWorkspaceDomainVariables,
} from './Domain.mutations';
import {
  GET_WORKSPACE_DOMAINS,
  WorkspaceDomainsResponse,
  WorkspaceDomainsVariables,
} from './Domain.queries';
import { useRefreshableQuery } from '../Api/Api.hooks';

export const useCreateWorkspaceDomainMutation = () =>
  useMutation<CreateWorkspaceDomainResponse, CreateWorkspaceDomainVariables>(
    CREATE_WORKSPACE_DOMAIN,
  );

export const useWorkspaceDomainsQuery = (
  options?: QueryHookOptions<
    WorkspaceDomainsResponse,
    WorkspaceDomainsVariables
  >,
) =>
  useRefreshableQuery<WorkspaceDomainsResponse, WorkspaceDomainsVariables>(
    GET_WORKSPACE_DOMAINS,
    options,
  );

export const useUpdateWorkspaceDomainMutation = () =>
  useMutation<UpdateWorkspaceDomainResponse, UpdateWorkspaceDomainVariables>(
    UPDATE_WORKSPACE_DOMAIN,
  );

export const useDeleteWorkspaceDomainMutation = () =>
  useMutation<DeleteWorkspaceDomainResponse, DeleteWorkspaceDomainVariables>(
    DELETE_WORKSPACE_DOMAIN,
  );
