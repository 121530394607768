import React, { FC, useCallback } from 'react';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../../Desktop/Desktop.hooks';
import { FolderFormValues } from '../../Folder.types';
import { StyledCreateFolder } from './CreateFolder.styles';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { FolderForm } from '../FolderForm';
import { useQueryParams } from '../../../../shared/hooks';
import { getShortId } from '../../../../shared/utils/id';
import { useCaseCreateFolder } from '../../UseCase/createFolder';
import { PermissionContext } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface CreateFolderProps {
  onDone: () => void;
}

export const CreateFolder: FC<CreateFolderProps> = ({ onDone }) => {
  const desktop = useCurrentDesktop();
  const queryParams = useQueryParams();
  const { folderId } = queryParams;

  const { canEditLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(desktop?.id || ''),
  );

  const { createFolderUseCase } = useCaseCreateFolder();

  const createLink = useCallback(
    (values: FolderFormValues) => {
      return createFolderUseCase(
        values.name,
        desktop?.id as string,
        folderId ? `/folders/${folderId}` : undefined,
      )
        .then(() => {
          // TODO: fix error handling
          // if (errors) {
          //   const message = errors[0].message;
          //   switch (message) {
          //     case 'A folder with this name already exists in this desktop':
          //       showToastErrorMessage(
          //         FolderTranslation.folderAlreadyExistInDesktop,
          //         {
          //           folderName: values.name,
          //         },
          //       );
          //       break;
          //     case 'Unable to add folder to desktop. Limit of max folders per desktop is reached.':
          //       showToastErrorMessage(
          //         FolderTranslation.folderLimitDesktopReached,
          //         {
          //           folderName: values.name,
          //         },
          //       );
          //       break;
          //     default:
          //       showToastGraphQLErrors([...errors]);
          //       break;
          //   }
          // }
          // if (!data) {
          //   return;
          // }
        })
        .then(() => {
          onDone();
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [createFolderUseCase, desktop?.id, folderId, onDone],
  );

  return (
    <StyledCreateFolder>
      <FolderForm
        onSubmit={createLink}
        onCancel={onDone}
        autoFocus={true}
        disabled={!canEditLink}
      />
    </StyledCreateFolder>
  );
};
