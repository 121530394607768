import { useEffect } from 'react';
import { sendBillingTrackingEvent } from './BillingTracking.utils';
import { BillingTrackingAction } from './BillingTracking.types';

export const useBillingTrackingEventOnInit = (
  action: BillingTrackingAction,
) => {
  useEffect(() => {
    sendBillingTrackingEvent(action);
  }, []); // eslint-disable-line
};
