import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  HeartFullIcon,
  HeartIcon,
  CursorPointerIcon,
} from '../../../../../../shared/icons';
import { DesktopTranslation } from '../../../../i18n';
import {
  AppContainer,
  AppHoverDemoStyled,
  AppPlaceholder,
  CursorContainer,
  FavIconContainer,
} from './AppHoverDemo.styled';

export const AppHoverDemo = () => {
  return (
    <AppHoverDemoStyled>
      <AppContainer>
        <FavIconContainer>
          <HeartIcon width={16} height={14} className="non-favorite" />
          <HeartFullIcon width={16} height={14} className="favorite" />
        </FavIconContainer>
        <AppPlaceholder>
          <FormattedMessage id={DesktopTranslation.emptyAppsAppDummyTitle} />
        </AppPlaceholder>
      </AppContainer>
      <CursorContainer>
        <CursorPointerIcon />
      </CursorContainer>
    </AppHoverDemoStyled>
  );
};
