import React, { forwardRef, PropsWithChildren, useMemo } from 'react';
import {
  useCurrentDesktopContent,
  useCurrentDesktopPermissions,
} from '../../../../../Desktop/Desktop.hooks';
import {
  MenuItem,
  MenuSeparator,
} from '../../../../../../shared/components/Menu';
import {
  AddFolderIcon,
  FolderIcon,
  HomeIcon,
} from '../../../../../../shared/icons';
import { useNavigate } from 'react-router-dom';
import { LinkApiType } from '../../../../Link.types';
import { FormattedMessage } from 'react-intl';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { FolderTranslation } from '../../../../../Folder/i18n';
import { sortByCreationDate } from '../../../../../../shared/utils/list.utils';
import {
  StyledFolderItemMenu,
  StyledFolderListWrapper,
} from './FoldersList.styled';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { useLinkActions } from '../../../LinkActions/LinkActions.hooks';

interface FoldersListProps {
  link: LinkApiType;
  closeLinksMenu: () => void;
}

export const FoldersList = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FoldersListProps>
>(({ link, closeLinksMenu }, ref) => {
  const navigate = useNavigate();
  const { canEditLink } = useCurrentDesktopPermissions();
  const queryParams = useQueryParams();
  const { desktopFolders } = useCurrentDesktopContent();
  const { onLinkMoveTo } = useLinkActions();

  const foldersForMenu = useMemo(
    () => desktopFolders.sort(sortByCreationDate),
    [desktopFolders],
  );

  return (
    <div ref={ref}>
      {canEditLink && (
        <MenuItem
          closeOnClick={false}
          onClick={() => {
            closeLinksMenu();

            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                createFolder: true,
              }),
            });
          }}
          icon={AddFolderIcon}
          data-testid="create-folder">
          <FormattedMessage id={FolderTranslation.createFolder} />
        </MenuItem>
      )}
      <MenuSeparator />
      <StyledFolderListWrapper>
        {foldersForMenu.map(folder => {
          return (
            <StyledFolderItemMenu key={folder.id}>
              <MenuItem
                icon={FolderIcon}
                displayCheckMark={link.folder?.id === folder.id}
                isDisabled={link.folder?.id === folder.id}
                onClick={() => {
                  onLinkMoveTo(link, folder);
                  closeLinksMenu();
                }}
                data-testid="folder"
                data-tooltip-id="global-tooltip"
                data-tooltip-content={folder.name.length > 8 ? folder.name : ''}
                data-tooltip-place={TooltipPlace.left}>
                {folder.name}
              </MenuItem>
            </StyledFolderItemMenu>
          );
        })}
      </StyledFolderListWrapper>
      <MenuItem
        key="home-folder"
        icon={HomeIcon}
        displayCheckMark={link.folder?.id === undefined}
        isDisabled={link.folder?.id === undefined}
        onClick={() => {
          onLinkMoveTo(link);
          closeLinksMenu();
        }}
        data-testid="home-folder">
        <FormattedMessage id={FolderTranslation.desktopRootFolderLabel} />
      </MenuItem>
    </div>
  );
});
