import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import { FormInput } from '../../../../shared/components/Input';
import { FormTextarea } from '../../../../shared/components/Textarea';
import {
  ButtonControls,
  DateGroupFields,
  RepeatGroupFields,
  ScheduleConferenceFormField,
  StyledFieldDescription,
  StyledImportantMessage,
  StyledText,
} from './ScheduleConferenceForm.styled';
import { Calendar } from '../../../../shared/components/Calendar';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { GlobalTranslation } from '../../../Intl/i18n';
import { useAllWorkspaceAccountsSorted } from '../../../Chat/Chat.hooks';
import {
  useCurrentConference,
  useDeleteScheduledConference,
  useGenerateRepeatingOption,
  useRepeatOptions,
  useScheduledConferenceMembers,
  useTimeZoneOptions,
} from '../../Conference.hooks';
import {
  ScheduleConferenceFormProps,
  ScheduleConferenceFormValues,
  ScheduledConferenceRepeatingValues,
} from '../../Conference.types';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import { getListMap } from '../../../../shared/utils/list.utils';
import { ReactSelect } from '../../../../shared/components/ReactSelect';
import { SelectMembers } from './SelectMembers';
import { add, isBefore, isEqual } from 'date-fns';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import {
  ScheduleRepeatingConferenceModal,
  ScheduleRepeatingConfirmationModal,
} from '../ScheduleRepeatingConferenceModal';
import { getAccountName } from '../../../User/User.utils';
import { RepeatingScheduledChatConferenceRepeatPattern } from '../../Conference.mutations';
import { TimePicker } from '../../../../shared/components/TimePicker';
import { RepeatingSelect } from './RepeatingSelect';
import {
  useInitialValuesScheduleForm,
  useOnSubmit,
  useScheduleFormValidation,
  useUpdateScheduledConference,
} from './ScheduleConferenceForm.hooks';
import {
  CopyIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
} from '../../../../shared/icons';
import { useTheme } from 'styled-components';
import { InputWithIcon } from '../../../../shared/components/InputWithIcon/InputWithIcon';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { getConferencePageLink } from '../../Conference.utils';
import { ImportantMessageType } from '../../../../shared/components/ImportantMessage';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const ScheduleConferenceForm: FC<ScheduleConferenceFormProps> = ({
  onClose,
  toggleRepeatingConferenceInfo,
}) => {
  const theme = useTheme();
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { formatMessage } = useIntl();
  const { scheduledConference } = useCurrentConference();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const accounts = useAllWorkspaceAccountsSorted();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { newScheduleConference } = queryParams;

  const currentRepeatPattern = useRef(
    newScheduleConference
      ? undefined
      : scheduledConference?.repeatingScheduleChatConference?.repeatPattern,
  );
  const selectedRepeatingOption = useGenerateRepeatingOption(
    selectedDate,
    currentRepeatPattern.current,
  );

  const repeatSelectOptions = useRepeatOptions(selectedRepeatingOption);
  const initialMembers = useScheduledConferenceMembers();
  const scheduleFormSchema = useScheduleFormValidation();
  const timeZoneOptions = useTimeZoneOptions();
  const initialValues = useInitialValuesScheduleForm({
    newScheduleConference: newScheduleConference as string,
    scheduledConference,
    repeatSelectOptions,
    initialMembers,
  });

  const { updateScheduledConference } = useUpdateScheduledConference({
    scheduledConference,
    initialMembers,
    onClose,
  });

  const isScheduledConferenceOwner =
    account?.id === scheduledConference?.createdBy?.id;
  const { handleDeleteConference, deleteScheduledConferenceLoading } =
    useDeleteScheduledConference();

  const deleteConferenceClickHandler = useCallback(async () => {
    const deleted = await handleDeleteConference();

    if (deleted) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          scheduleConferenceOpened: undefined,
        }),
      });
    }
  }, [handleDeleteConference, navigate, queryParams]);

  const accountsMap = useMemo(() => {
    return getListMap(accounts);
  }, [accounts]);

  const selectMembersOptions = useMemo(
    () =>
      accounts
        .map(account => ({
          value: account.id,
          label: getAccountName(account),
          emailForSearch: account.email,
          origin: account.origin,
          active: account?.workspaceCache?.[account.id]?.active,
        }))
        .filter(account => account.active),
    [accounts],
  );

  const handleRepeatingBlur = useCallback(
    (values: ScheduleConferenceFormValues, setFieldValue: any) => {
      const { repeating, customRepeating } = values;
      if (
        !customRepeating &&
        newScheduleConference &&
        Number(repeating.value) ===
          ScheduledConferenceRepeatingValues.CUSTOM_OPTION
      ) {
        setFieldValue(
          'repeating',
          repeatSelectOptions[
            ScheduledConferenceRepeatingValues.SELECTED_OPTION
          ],
        );
      }
    },
    [newScheduleConference, repeatSelectOptions],
  );

  const handleRepeatingMeetingChange = useCallback(
    (
      option: {
        label: string;
        value: ScheduledConferenceRepeatingValues;
      },
      setFieldValue: any,
    ) => {
      if (
        Number(option.value) ===
        ScheduledConferenceRepeatingValues.CUSTOM_OPTION
      ) {
        setFieldValue(
          'repeating',
          repeatSelectOptions[
            ScheduledConferenceRepeatingValues.SELECTED_OPTION
          ],
        );
      } else {
        setFieldValue('repeating', option);
      }

      if (
        Number(option.value) ===
        ScheduledConferenceRepeatingValues.CUSTOM_OPTION
      ) {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            scheduleRepeatingConferenceOpened: true,
          }),
        });
      } else if (
        Number(option.value) ===
        ScheduledConferenceRepeatingValues.SELECTED_OPTION
      ) {
        setFieldValue('customRepeating', currentRepeatPattern.current);
      } else {
        setFieldValue('customRepeating', undefined);
      }
    },
    [navigate, queryParams, repeatSelectOptions],
  );

  const { onSubmit, isSubmitting } = useOnSubmit({
    accountId: account.id,
    workspaceId: workspace.id,
    toggleRepeatingConferenceInfo,
    newScheduleConference: newScheduleConference as string,
    scheduledConference,
    initialMembers,
    onClose,
  });

  const conferenceLink = useMemo(
    () =>
      window.location.origin +
      getConferencePageLink(scheduledConference?.chatConference?.id || ''),
    [scheduledConference],
  );

  const handleCopyLinkToClipBoard = useCallback(() => {
    navigator.clipboard.writeText(conferenceLink).then(() => {
      showToastSuccessMessage(
        ConferenceTranslation.scheduledUpcomingConferenceCopyLinkToastText,
      );
    });
  }, [conferenceLink]);

  const timePickerIcon = (
    <KeyboardArrowDownIcon
      width={20}
      height={20}
      color={theme.colors.OnSurfaceMediumEmphasis}
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={scheduleFormSchema}
      onSubmit={onSubmit}>
      {({ setFieldValue, values, validateForm }) => (
        <Form>
          <ScheduleConferenceFormField>
            <Field
              name="title"
              type="text"
              placeholder={formatMessage({
                id: ConferenceTranslation.scheduleConferenceEnterTopic,
              })}
              label={formatMessage({
                id: ConferenceTranslation.scheduleConferenceFormTopic,
              })}
              component={FormInput}
              onBlur={() => {}}
            />
          </ScheduleConferenceFormField>
          <ScheduleConferenceFormField>
            <Field
              name="description"
              placeholder={formatMessage({
                id: ConferenceTranslation.scheduleConferenceEnterDescription,
              })}
              label={formatMessage({
                id: ConferenceTranslation.scheduleConferenceFormDescription,
              })}
              maxLength={1000}
              component={FormTextarea}
            />
          </ScheduleConferenceFormField>
          <DateGroupFields>
            <Field
              name="selectedDay"
              label={formatMessage({
                id: ConferenceTranslation.scheduleConferenceFormDateTime,
              })}
              component={Calendar}
              onChange={(value: Date) => {
                const { startAt, endAt } = values;
                if (isBefore(endAt, startAt)) {
                  setFieldValue('selectedEndDay', add(value, { days: 1 }));
                } else {
                  setFieldValue('selectedEndDay', value);
                }
                setSelectedDate(value);
                setFieldValue('selectedDay', value);
                setTimeout(() => validateForm(), 0);
              }}
            />
            <Field
              name="startAt"
              onChange={(value: Date) => {
                setFieldValue('startAt', value);
                if (value && !isNaN(value.getMonth())) {
                  setFieldValue('endAt', add(value, { hours: 1 }));
                }
                setTimeout(() => validateForm(), 0);
              }}
              keyboardIcon={timePickerIcon}
              component={TimePicker}
            />
            <StyledText>
              <FormattedMessage
                id={ConferenceTranslation.scheduleConferenceTo}
              />
            </StyledText>
            <Field
              name="endAt"
              keyboardIcon={timePickerIcon}
              component={TimePicker}
              onChange={(value: Date) => {
                const { startAt, selectedDay, selectedEndDay } = values;
                if (
                  (isBefore(value, startAt) &&
                    isEqual(selectedEndDay, selectedDay)) ||
                  isBefore(selectedEndDay, selectedDay)
                ) {
                  setFieldValue(
                    'selectedEndDay',
                    add(selectedDay, { days: 1 }),
                  );
                  setFieldValue('endAt', value);
                } else {
                  setFieldValue('endAt', value);
                  setFieldValue('selectedEndDay', selectedDay);
                }
                setTimeout(() => validateForm(), 0);
              }}
            />
            <Field
              name="selectedEndDay"
              component={Calendar}
              onChange={(value: Date) => {
                setFieldValue('selectedEndDay', value);
                setTimeout(() => validateForm(), 0);
              }}
            />
          </DateGroupFields>
          <RepeatGroupFields>
            <Field
              name="timezone"
              component={ReactSelect}
              onChange={(value: string) => setFieldValue('timezone', value)}
              options={timeZoneOptions}
            />
          </RepeatGroupFields>
          <Field
            name="repeating"
            component={RepeatingSelect}
            options={repeatSelectOptions}
            onBlur={() => handleRepeatingBlur(values, setFieldValue)}
            onChange={(data: {
              label: string;
              value: ScheduledConferenceRepeatingValues;
            }) => handleRepeatingMeetingChange(data, setFieldValue)}
          />
          <ScheduleConferenceFormField>
            <InputWithIcon
              readOnly
              reverseIcon
              data-tooltip-content={conferenceLink}
              data-tooltip-place={TooltipPlace.top}
              iconOnClick={
                !!newScheduleConference ? undefined : handleCopyLinkToClipBoard
              }
              onClick={handleCopyLinkToClipBoard}
              icon={CopyIcon}
              disabled={!!newScheduleConference}
              value={
                newScheduleConference
                  ? formatMessage({
                      id: ConferenceTranslation.scheduledConferenceEmptyMeetingLink,
                    })
                  : conferenceLink
              }
              label={formatMessage({
                id: ConferenceTranslation.scheduleConferenceFormMeetingId,
              })}
            />
          </ScheduleConferenceFormField>
          <ScheduleConferenceFormField>
            <Field
              name="members"
              label={formatMessage({
                id: ConferenceTranslation.scheduleConferenceFormInvitees,
              })}
              component={SelectMembers}
              options={selectMembersOptions}
              accounts={accountsMap}
            />
          </ScheduleConferenceFormField>
          <StyledFieldDescription>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduleConferenceYouAreAddedAsOrganizer
              }
            />
          </StyledFieldDescription>
          <StyledImportantMessage type={ImportantMessageType.INFO}>
            <FormattedHTMLMessage id={GlobalTranslation.checkEmailMessage} />
          </StyledImportantMessage>
          <ButtonControls>
            {scheduledConference?._id &&
              isScheduledConferenceOwner &&
              !newScheduleConference && (
                <ButtonWithIcon
                  data-testid="delete-meeting-button"
                  type="button"
                  iconFirst={true}
                  icon={DeleteIcon}
                  disabled={deleteScheduledConferenceLoading}
                  mode={ButtonMode.danger}
                  size={ButtonSize.sm}
                  onClick={deleteConferenceClickHandler}>
                  <FormattedMessage
                    id={
                      ConferenceTranslation.scheduledUpcomingConferenceDeleteMeeting
                    }
                  />
                </ButtonWithIcon>
              )}
            <Button
              data-testid="cancel-meeting-form-button"
              type="button"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              onClick={onClose}>
              <FormattedMessage id={GlobalTranslation.cancel} />
            </Button>
            <Button
              data-testid="save-meeting-form-button"
              type="submit"
              disabled={isSubmitting}
              mode={ButtonMode.primary}
              size={ButtonSize.md}>
              <FormattedMessage
                id={ConferenceTranslation.scheduleConferenceFormSave}
              />
            </Button>
          </ButtonControls>
          {queryParams?.scheduleRepeatingConferenceOpened && (
            <ScheduleRepeatingConferenceModal
              data={
                !newScheduleConference
                  ? scheduledConference?.repeatingScheduleChatConference
                      ?.repeatPattern
                  : currentRepeatPattern.current
              }
              onClose={() => {
                setFieldValue(
                  'repeating',
                  repeatSelectOptions[
                    ScheduledConferenceRepeatingValues.DOES_NOT_REPEAT
                  ],
                );
              }}
              onSubmit={(
                value: RepeatingScheduledChatConferenceRepeatPattern,
              ) => {
                currentRepeatPattern.current = value;
                setFieldValue('customRepeating', value);
                setFieldValue(
                  'repeating',
                  repeatSelectOptions[
                    ScheduledConferenceRepeatingValues.SELECTED_OPTION
                  ],
                );
              }}
              selectedDate={selectedDate}
            />
          )}
          {queryParams.scheduleRepeatingConfirmationOpened && (
            <ScheduleRepeatingConfirmationModal
              onSubmit={updateScheduledConference}
              values={values}
              reapeatingPattern={currentRepeatPattern.current}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
