import React from 'react';
import { useTheme } from 'styled-components';
import { SkeletonBox } from '../../../shared/components/SkeletonBox';
import { StyledSegmentSkeleton } from './SegmentSkeleton.styled';

export const SegmentSkeleton = () => {
  const { colors } = useTheme();
  return (
    <StyledSegmentSkeleton>
      <div className="header">
        <SkeletonBox
          width={5}
          height={1.5}
          borderRadius={0.25}
          color={colors.WorkspaceSwitcherSkeleton}
        />
        <SkeletonBox
          width={1.5}
          height={1.5}
          borderRadius={0.25}
          color={colors.WorkspaceSwitcherSkeleton}
        />
      </div>
      <div className="body">
        <div className="list">
          <SkeletonBox
            width={1.5}
            height={1.5}
            borderRadius={0.25}
            margin={[0.25, 0, 0.375, 0]}
            color={colors.WorkspaceSwitcherSkeleton}
          />
          <SkeletonBox
            width={6}
            height={1.5}
            borderRadius={0.25}
            color={colors.WorkspaceSwitcherSkeleton}
            margin={[0.25, 0, 0.375, 0.5]}
          />
        </div>
        <div className="list">
          <SkeletonBox
            width={8.75}
            height={1}
            borderRadius={0.25}
            margin={[0, 0, 0.25, 0]}
            color={colors.WorkspaceSwitcherSkeleton}
          />
          <div className="list-item">
            <SkeletonBox
              width={7}
              height={1.5}
              borderRadius={0.25}
              margin={[0.25, 0, 1.875, 1.25]}
              color={colors.WorkspaceSwitcherSkeleton}
            />
          </div>
        </div>
        <div className="list">
          <SkeletonBox
            width={8.75}
            height={1}
            borderRadius={0.25}
            margin={[0, 0, 0.25, 0]}
            color={colors.WorkspaceSwitcherSkeleton}
          />
          <div className="list-item">
            <SkeletonBox
              width={7}
              height={1.5}
              borderRadius={0.25}
              margin={[0.25, 0, 1.875, 1.25]}
              color={colors.WorkspaceSwitcherSkeleton}
            />
            <SkeletonBox
              width={2}
              height={1.5}
              borderRadius={0.25}
              margin={[0.25, 0.5, 1.875, 0]}
              color={colors.WorkspaceSwitcherSkeleton}
            />
          </div>
        </div>
        <div className="list">
          <SkeletonBox
            width={8.75}
            height={1}
            borderRadius={0.25}
            margin={[0, 0, 0.25, 0]}
            color={colors.WorkspaceSwitcherSkeleton}
          />
          <div className="list-item">
            <SkeletonBox
              width={7}
              height={1.5}
              borderRadius={0.25}
              margin={[0.25, 0, 0.375, 1.25]}
              color={colors.WorkspaceSwitcherSkeleton}
            />
          </div>
        </div>
      </div>
    </StyledSegmentSkeleton>
  );
};
