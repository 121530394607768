import React, { FC, useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from '../../../../shared/components/Spinner';
import { AppStoreTranslation } from '../../i18n';
import { SsoAppCard } from './components/SsoAppCard';
import { ConfigurationPage } from './components/ConfigurationPage';
import {
  AdminViewHeader,
  CustomAppSsoBanner,
  IntroTextContainer,
  StyledSsoAppsGrid,
} from './SsoAppsGrid.styled';
import { DrawerHeader } from '../../../Admin/DrawerHeader';
import {
  AdminHeader,
  AdminHeaderTitle,
  SectionBody,
  SectionDescription,
} from '../../../Admin';
import { SectionTitle } from './components/SectionTitle';
import { InformationIcon } from '../../../../shared/icons';
import { LoadMoreObserver } from '../../../../shared/components/LoadMore';
import {
  StyledHeader,
  HeaderTitle,
  HeaderTitleContainer,
} from '../AppStore/components/Header';
import { AppsFilter } from '../AppStore/components/AppsFilter';
import { AppsGrid, NoAppsAvailable } from '../AppStore/AppStore.styled';
import { CreateCustomAppModal } from '../../Modals/CreateCustomAppModal';
import { useMobile } from '../../../../shared/hooks';
import { SsoAppsGridViewModel } from './ViewModel';
import { useLocation } from 'react-router-dom';
import { WorkspaceContext } from '../../../Workspace/Workspace.context';

interface SsoAppsGridProps {
  adminView?: boolean;
}

export const SsoAppsGrid: FC<SsoAppsGridProps> = ({ adminView = false }) => {
  const isMobile = useMobile();
  const intl = useIntl();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  const {
    permissionsLoaded,
    canViewSsoPage,
    loading,
    compatibleAppsLoading,
    compatibleAppsData,
    configuredAppSsoGroups,
    configuredAppSsoGroupsApps,
    compatibleAppSsoGroups,
    compatibleAppSsoGroupsApps,
    ssoAppGroup,
    ssoCompatibleApps,
    createCustomSsoGroup,
    createCustomAppModal,
    loadingMoreApps,
    handleLoadMore,
    handleRedirectToAdmin,
    handleCloseDrawer,
    handleCreateCustomAppDone,
  } = SsoAppsGridViewModel();

  /**
   * Set last admin path
   */
  useEffect(() => {
    if (adminView) {
      setLastOpenedAdminPath(pathname);
    }
  }, [adminView, pathname, setLastOpenedAdminPath]);

  if (ssoAppGroup) {
    return (
      <StyledSsoAppsGrid>
        <ConfigurationPage ssoAppGroup={ssoAppGroup} adminView={adminView} />
      </StyledSsoAppsGrid>
    );
  }

  if (permissionsLoaded && !canViewSsoPage) {
    handleRedirectToAdmin();
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <StyledSsoAppsGrid>
      {adminView ? (
        isMobile ? (
          <DrawerHeader
            data-testid="heading"
            onRequestClose={handleCloseDrawer}>
            <AdminViewHeader>
              <FormattedMessage id={AppStoreTranslation.ssoAppGridTitle} />
            </AdminViewHeader>
          </DrawerHeader>
        ) : (
          <AdminHeader data-testid="header">
            <AdminHeaderTitle>
              <AdminViewHeader>
                <FormattedMessage id={AppStoreTranslation.ssoAppGridTitle} />
              </AdminViewHeader>
            </AdminHeaderTitle>
            <AppsFilter />
          </AdminHeader>
        )
      ) : (
        <StyledHeader>
          <HeaderTitleContainer>
            <HeaderTitle>
              <FormattedMessage id={AppStoreTranslation.ssoAppGridTitle} />
            </HeaderTitle>
          </HeaderTitleContainer>
          <AppsFilter />
        </StyledHeader>
      )}

      <IntroTextContainer>
        <SectionBody>
          <SectionDescription noBottomMargin>
            <FormattedHTMLMessage
              id={AppStoreTranslation.ssoAppGridIntroText}
              values={{
                supportSsoUrl: process.env.SUPPORT_SSO_URL,
                supportSsoCustomAppUrl: process.env.SUPPORT_SSO_CUSTOM_APP_URL,
              }}
            />
          </SectionDescription>
        </SectionBody>
      </IntroTextContainer>

      <CustomAppSsoBanner>
        <div className="icon">
          <InformationIcon />
        </div>
        <FormattedMessage
          id={AppStoreTranslation.ssoAppGridCustomAppSsoMessage}
        />
        <button className="button" onClick={createCustomAppModal.open}>
          <FormattedMessage
            id={AppStoreTranslation.ssoAppGridCustomAppSsoButton}
          />
        </button>
      </CustomAppSsoBanner>
      {loading ? (
        <>
          <SectionTitle
            title={intl.formatMessage({
              id: AppStoreTranslation.ssoAppGridConfiguredTitle,
            })}
          />
          <Spinner size={64} containerHeight={200} />
        </>
      ) : (
        <>
          {configuredAppSsoGroups.length > 0 && (
            <>
              <SectionTitle
                title={intl.formatMessage({
                  id: AppStoreTranslation.ssoAppGridConfiguredTitle,
                })}
                subtitle={intl.formatMessage(
                  {
                    id: AppStoreTranslation.ssoAppGridPredefinedSubtitle,
                  },
                  {
                    groupCount: configuredAppSsoGroups.length,
                    appCount: configuredAppSsoGroupsApps,
                  },
                )}
              />
              <AppsGrid data-testid="apps-grid">
                {configuredAppSsoGroups.map(ssoGroup => (
                  <SsoAppCard key={ssoGroup.id} ssoGroup={ssoGroup} />
                ))}
              </AppsGrid>
            </>
          )}
          {compatibleAppSsoGroups.length > 0 && (
            <>
              <SectionTitle
                title={intl.formatMessage({
                  id: AppStoreTranslation.ssoAppGridPredefinedTitle,
                })}
                subtitle={intl.formatMessage(
                  {
                    id: AppStoreTranslation.ssoAppGridPredefinedSubtitle,
                  },
                  {
                    groupCount: compatibleAppSsoGroups.length,
                    appCount: compatibleAppSsoGroupsApps,
                  },
                )}
              />
              <AppsGrid data-testid="apps-grid">
                {compatibleAppSsoGroups.map(ssoGroup => (
                  <SsoAppCard key={ssoGroup.id} ssoGroup={ssoGroup} />
                ))}
              </AppsGrid>
            </>
          )}
        </>
      )}

      <SectionTitle
        title={intl.formatMessage({
          id: AppStoreTranslation.ssoAppGridCompatibleTitle,
        })}
        subtitle={
          compatibleAppsData?.apps.totalCount
            ? intl.formatMessage(
                {
                  id: AppStoreTranslation.ssoAppGridCompatibleSubtitle,
                },
                {
                  appCount: compatibleAppsData?.apps.totalCount,
                },
              )
            : undefined
        }
      />
      {!compatibleAppsData && compatibleAppsLoading ? (
        <Spinner size={64} containerHeight={200} />
      ) : ssoCompatibleApps.length ? (
        <>
          <AppsGrid>
            {ssoCompatibleApps.map(app => (
              <SsoAppCard
                key={app.id}
                app={app}
                onConfigure={createCustomSsoGroup}
              />
            ))}
          </AppsGrid>
          {compatibleAppsData?.apps.pageInfo?.hasNextPage && (
            <LoadMoreObserver
              loading={loadingMoreApps}
              onLoadMore={handleLoadMore}
            />
          )}
        </>
      ) : (
        <NoAppsAvailable>
          <FormattedMessage
            id={AppStoreTranslation.ssoAppGridCompatibleEmptyText}
          />
        </NoAppsAvailable>
      )}

      {createCustomAppModal.visible && (
        <CreateCustomAppModal
          showBannerOnDone={false}
          onDone={handleCreateCustomAppDone}
          onClose={createCustomAppModal.close}
        />
      )}
    </StyledSsoAppsGrid>
  );
};
