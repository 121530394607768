import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../../../shared/components/Button/Button';
import { CopyButton, StyledCopyableBlock } from './CopyableBlock.styled';

interface CopyableBlockProps {
  buttonText: string;
}

export const CopyableBlock: FC<PropsWithChildren<CopyableBlockProps>> = ({
  children,
  buttonText,
}) => {
  const preRef = useRef<HTMLPreElement>(null);

  const copyContent = useCallback(() => {
    if (!preRef.current) {
      return;
    }

    const range = document.createRange();
    range.selectNode(preRef.current);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand('copy');
  }, []);

  return (
    <StyledCopyableBlock>
      <CopyButton
        onClick={copyContent}
        mode={ButtonMode.secondary}
        size={ButtonSize.md}>
        {buttonText}
      </CopyButton>
      <pre ref={preRef}>{children}</pre>
    </StyledCopyableBlock>
  );
};
