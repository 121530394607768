import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { StreamEventActionRequest } from '../../../Mercure/General';
import {
  useAddAppsDesktopMutation,
  useSuggestAppsFromBrowserHistoryMutation,
} from '../../ChromeExtension.hooks';
import { ImportStepTypes } from '../../ChromeExtension.types';
import { importAppsFromHistory } from '../../ChromeExtension.utils';
import { ChromeExtensionTranslation } from '../../i18n';
import { ImportAppsGrid } from '../../ImportAppsView/ImportAppsGrid';
import {
  ButtonGroup,
  ImportControls,
  ImportModalDescription,
  ImportModalTitle,
} from './importModal.styled';
import { InstallExtensionBanner } from './InstallExtensionBanner';
import type { DesktopAppApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { useMercureListener } from '../../../Mercure/General/GeneralMercure.hooks';

const PROTOCOL = 'https://';

export const ImportAppsView: FC<{}> = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const currentDesktop = useCurrentDesktop();
  const [loading, setLoading] = useState(false);
  const [extensionNotInstalled, setExtensionNotInstalled] = useState(false);
  const [importApps, setImportApps] = useState<DesktopAppApiType[] | null>(
    null,
  );
  const [selectedApps, setSelectedApps] = useState<Set<any>>(new Set());

  const [suggestAppsFromBrowserHistory] =
    useSuggestAppsFromBrowserHistoryMutation();

  const { addListener, removeListener } = useMercureListener();

  useEffect(() => {
    setLoading(true);
    importAppsFromHistory()
      .then((data: any) => {
        if (data) {
          const appsLinks = data.map((item: string) => `${PROTOCOL}` + item);

          suggestAppsFromBrowserHistory({
            variables: {
              input: {
                history: appsLinks,
              },
            },
          }).catch(e => {
            setLoading(false);
            showToastGraphQLErrors(e.graphQLErrors);
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setExtensionNotInstalled(true);
      });
  }, [suggestAppsFromBrowserHistory]);

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = event => {
      if (
        event['@request'] ===
        StreamEventActionRequest.SUGGESTED_APPS_FROM_BROWSER_HISTORY
      ) {
        // TODO: fix types
        setImportApps(event['@payload'] as any);
        setLoading(false);
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [setImportApps, addListener, removeListener]);

  const [addAppsDesktop] = useAddAppsDesktopMutation();

  const importSelectedApps = useCallback(() => {
    if (!selectedApps.size) {
      return;
    }

    setLoading(true);
    const selectedAppToImport = Array.from(selectedApps);

    addAppsDesktop({
      variables: {
        input: {
          id: currentDesktop!.id,
          apps: selectedAppToImport,
        },
      },
    })
      .then(() => {
        setLoading(false);
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            importStep: ImportStepTypes.importAppsSuccess,
          }),
        });
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [addAppsDesktop, currentDesktop, selectedApps, navigate, queryParams]);

  const handleSelectApp = (appId: string) => {
    const newSelectedApps = new Set(selectedApps);

    if (selectedApps.has(appId)) {
      newSelectedApps.delete(appId);
      setSelectedApps(newSelectedApps);
    } else {
      newSelectedApps.add(appId);
      setSelectedApps(newSelectedApps);
    }
  };

  const selectAllApps = () => {
    if (importApps) {
      const appIds = importApps.map((app: DesktopAppApiType) => app.id);
      const allApps = new Set(appIds);
      setSelectedApps(allApps);
    }
  };

  const clearAllApps = () => {
    const clearedApps = new Set();
    setSelectedApps(clearedApps);
  };

  return (
    <>
      {loading ? (
        <SpinnerFullscreen size={60} darkIcon>
          <FormattedMessage
            id={ChromeExtensionTranslation.importAppsSpinnerMessage}
          />
        </SpinnerFullscreen>
      ) : (
        <>
          <ImportModalTitle>
            <FormattedMessage id={ChromeExtensionTranslation.importAppsTitle} />
          </ImportModalTitle>

          {extensionNotInstalled ? (
            <InstallExtensionBanner />
          ) : (
            <>
              <ImportModalDescription>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importAppsDescription}
                />
              </ImportModalDescription>
              {importApps && (
                <ImportAppsGrid
                  selectedApps={selectedApps}
                  apps={importApps}
                  handleSelectApp={handleSelectApp}
                />
              )}
            </>
          )}

          <ImportControls>
            <ButtonGroup>
              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                type="button"
                onClick={selectAllApps}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSelectAllButton}
                />
              </Button>

              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                type="button"
                onClick={clearAllApps}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importClearAllButton}
                />
              </Button>
            </ButtonGroup>

            <ButtonGroup>
              <Button
                mode={ButtonMode.tertiary}
                size={ButtonSize.md}
                type="button"
                onClick={() => {
                  navigate({
                    search: getQueryParamsFrom({
                      ...queryParams,
                      importStep: ImportStepTypes.selectLinks,
                    }),
                  });
                }}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSkipToLinksButton}
                />
              </Button>

              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                type="button"
                disabled={!selectedApps.size}
                onClick={importSelectedApps}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSelectedAppsButton}
                />
              </Button>
            </ButtonGroup>
          </ImportControls>
        </>
      )}
    </>
  );
};
