import styled, { createGlobalStyle, css } from 'styled-components';
import { Button } from '../../../shared/components/Button/Button';

const LOGO_SECTION_HEIGHT = 60;

/*
 * Onboarding template structure
 * */

export const OnboardingPageBodyGlobalStyles = createGlobalStyle`
  body {
    background-color: ${p => p.theme.colors.OnboardingPageBackground};
  }
`;

export const StyledOnboardingPage = styled.div`
  height: 100%;
  max-height: 100%;
  max-height: fill-available;
  overflow-y: auto;
`;

export const OnboardingLogo = styled.div`
  display: flex;
  justify-content: center;
  height: ${LOGO_SECTION_HEIGHT}px;
  padding: 0.5rem;
`;

export const OnboardingContentWrapper = styled.div`
  min-height: calc(100vh - ${LOGO_SECTION_HEIGHT}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OnboardingContent = styled.div<{ horizontallyCentered?: boolean }>`
  padding: 2rem 1rem;
  min-height: 60vh;
  width: 100%;

  ${({ theme: { breakpoints }, horizontallyCentered }) => breakpoints.md`
    display: flex;
    justify-content: center;
    overflow-y: auto;
    flex: 0 1 100%;

    ${
      horizontallyCentered &&
      css`
        align-items: center;
      `
    }

    > * ~ * {
      margin-left: 20px;
    }
  `}

  ${({ theme: { breakpoints } }) => breakpoints.lg`
    > * ~ * {
      margin-left: 96px;
    }
  `}
`;

/*
 * Content Parts
 * */

export const OnboardingContentMainPart = styled.div<{
  contentWidth: number;
}>`
  ${({ theme: { breakpoints }, contentWidth }) => breakpoints.md`
    max-width: ${contentWidth}px;
  `}
`;

export const OnboardingContentBeforePart = styled.div<{
  contentWidth: number;
}>`
  ${({ theme: { breakpoints }, contentWidth }) => breakpoints.md`
    max-width: ${contentWidth}px;
  `}
`;

export const OnboardingContentAfterPart = styled.div<{
  contentWidth: number;
}>`
  ${({ theme: { breakpoints }, contentWidth }) => breakpoints.md`
    max-width: ${contentWidth}px;
  `}
`;

export const OnboardingAccountSection = styled.div`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.875rem;
  line-height: 1.2;
  color: ${p => p.theme.colors.OnboardingAccountSection};
  text-align: center;
  margin-top: 1rem;

  a {
    margin-left: 6px;
    color: ${p => p.theme.colors.Primary};
  }
`;

export const StyledResetPasswordHelpText = styled.div`
  margin: 1.5rem 0 1.875rem 0;
`;

export const StyledResetPasswordButton = styled.div`
  display: flex;
  justify-content: center;
`;

/*
 * COntent Main part reusable components
 * */

export const OnboardingStep = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;
  background-color: ${p => p.theme.colors.OnboardingStepBackground};
  border-radius: 1rem;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    min-width: 384px;
    margin-top: 0;
  `};
`;

export const OnboardingStepHeader = styled.h1`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
  text-align: center;
`;

export const OnboardingStepText = styled.p<{
  centered?: boolean;
  noIndents?: boolean;
}>`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin: ${({ theme }) => theme.spacing.xl} 0;

  ${p =>
    p.centered &&
    css`
      text-align: center;
    `}

  ${p =>
    p.noIndents &&
    css`
      margin: 0;
    `}
    
  strong {
    word-break: break-all;
  }
`;

export const OnboardingStepButton = styled(Button)`
  margin-top: 20px;
`;

export const OnboardingExampleWorkspaceName = styled.p<{ centered?: boolean }>`
  font-weight: normal;
  font-size: 1rem;
  line-height: 18px;
  margin: 16px 0 36px 0;

  ${p =>
    p.centered &&
    css`
      text-align: center;
    `}

  a {
    color: ${a => a.theme.colors.Primary};
  }
`;
