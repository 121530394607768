import React, { FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppIntegrationsTranslation } from '../i18n';
import { StyledAppsIntegrationsWrapper } from './AppIntegrationsView.styled';
import { AdminHeaderTitle } from '../../Admin';
import { PersonalIntegrations } from '../Integrations/PersonalIntegrations';
import { WorkspaceIntegrations } from '../Integrations/WorkspaceIntegrations';
import { CustomIntegrations } from '../Integrations/CustomIntegrations';
import { useCurrentWorkspacePermissions } from '../../Workspace/Workspace.hooks';
import { useLocation } from 'react-router-dom';
import { WorkspaceContext } from '../../Workspace/Workspace.context';

export const AppIntegrationsView: FC = () => {
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const {
    permissions: {
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
    },
  } = useCurrentWorkspacePermissions();

  return (
    <>
      <StyledAppsIntegrationsWrapper>
        <AdminHeaderTitle data-testid="title" className="header">
          <FormattedMessage
            id={AppIntegrationsTranslation.appIntegrationTitle}
          />
        </AdminHeaderTitle>

        {canViewWorkspaceAppIntegrations && <WorkspaceIntegrations />}
        {canViewPersonalAppIntegrations && <PersonalIntegrations />}
        {canViewWorkspaceAppIntegrations && <CustomIntegrations />}
      </StyledAppsIntegrationsWrapper>
    </>
  );
};
