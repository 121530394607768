import React, { FC, useCallback, useMemo, useRef } from 'react';
import { StyledMention, StyledSpecialMention } from './Mention.styled';
import { useAccountPopoverControls } from '../../../../../AccountPopover';
import { useAccountsContext } from '../../../../../../Account';
import { AccountApiType } from '../../../../../../User/User.types';
import { getAccountName } from '../../../../../../User/User.utils';

import { useMobile } from '../../../../../../../shared/hooks';
import { SpecialMentionType } from '../../../../../Chat.types';

export const Mention: FC<{
  children: string;
  skipAccountPopover?: boolean;
}> = ({ skipAccountPopover = false, children }) => {
  const mentionedId = useMemo(
    () => children.substring(2, children.length - 1),
    [children],
  );
  const { showAccountPopover } = useAccountPopoverControls();
  const { accountsWithAvailability: accounts } = useAccountsContext();
  const domRef = useRef<HTMLButtonElement>(null);
  const isMobile = useMobile();

  const mentionedAccount: AccountApiType | undefined = accounts[mentionedId];

  const handleClick = useCallback(() => {
    if (!isMobile && !skipAccountPopover) {
      showAccountPopover(mentionedAccount, domRef);
    }
  }, [skipAccountPopover, isMobile, mentionedAccount, showAccountPopover]);

  if (
    mentionedId === SpecialMentionType.Here ||
    mentionedId === SpecialMentionType.Channel
  ) {
    return <StyledSpecialMention>@{mentionedId}</StyledSpecialMention>;
  }

  if (!mentionedAccount) {
    return null;
  }

  return (
    <StyledMention
      as="button"
      ref={domRef}
      onClick={handleClick}
      data-testid="mention">
      @{getAccountName(mentionedAccount)}
    </StyledMention>
  );
};
