import { GraphQLError } from 'graphql';
import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../shared/components/Toast';
import { useQueryParams } from '../../shared/hooks';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import { ONBOARDING_END_PATHNAME } from '../Onboarding/Onboarding.constants';
import { SignUpStep } from '../Onboarding/Onboarding.types';
import {
  ANNOUNCEMENT_VERSION,
  announcementModalQueryParamKey,
} from './Announcement.constants';
import { useSetAnnouncementVersionMutation } from './Announcement.hooks';
import { AnnouncementModal } from './AnnouncementModal/AnnouncementModal';
import {
  WORKSPACE_PATHNAME,
  WORKSPACE_ROOT_PATHNAME,
} from '../Workspace/Workspace.constants';
import { DESKTOP_ROOT_PATHNAME } from '../Desktop/Desktop.constants';

export const AnnouncementProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [setAnnouncementVersionMutation] = useSetAnnouncementVersionMutation();
  const announcementVisible =
    queryParams[announcementModalQueryParamKey] === 'true' ||
    queryParams.forceLatestAnnouncementModal;
  const { account, refetchAccountData } = useCurrentAccount();
  const location = useLocation();
  const { pathname } = location;
  const announcementClosed = useRef(false);

  const announcementModalAvailable =
    !queryParams.openOnboardingQuickTour &&
    !queryParams[announcementModalQueryParamKey] &&
    !announcementClosed.current &&
    account?.currentStep === SignUpStep.completed &&
    !matchPath(WORKSPACE_PATHNAME, pathname) &&
    !matchPath(WORKSPACE_ROOT_PATHNAME, pathname) &&
    !matchPath(DESKTOP_ROOT_PATHNAME, pathname) &&
    !matchPath(ONBOARDING_END_PATHNAME, pathname) &&
    typeof account?.identity?.announcementSeenVersion === 'number' &&
    account?.identity?.announcementSeenVersion < ANNOUNCEMENT_VERSION;

  useEffect(() => {
    if (announcementModalAvailable) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          [announcementModalQueryParamKey]: true,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementModalAvailable, pathname]);

  const closeAndUpdateAnnouncementVersion = () => {
    announcementClosed.current = true;
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        [announcementModalQueryParamKey]: undefined,
        forceLatestAnnouncementModal: undefined,
      }),
    });

    if (queryParams.forceLatestAnnouncementModal) {
      return null;
    }

    if (account?.identity?.announcementSeenVersion !== ANNOUNCEMENT_VERSION) {
      setAnnouncementVersionMutation({
        variables: {
          input: {
            announcementSeenVersion: ANNOUNCEMENT_VERSION,
            id: account?.identityId,
          },
        },
      })
        .then(() => refetchAccountData && refetchAccountData())
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    }
  };

  return (
    <>
      {children}
      {announcementVisible && (
        <AnnouncementModal onRequestClose={closeAndUpdateAnnouncementVersion} />
      )}
    </>
  );
};
