import React, { FC, useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import {
  RequestResetPasswordControls,
  RequestResetPasswordField,
} from './RequestResetPasswordForm.styled';
import { RequestResetPasswordFormValues } from '../../../Onboarding.types';

const RequestResetPasswordFormSchema: Schema<RequestResetPasswordFormValues> = Yup.object().shape(
  {
    email: Yup.string()
      .email(
        OnboardingTranslation.onboardingRequestResetPasswordFormEmailInvalid,
      )
      .required(
        OnboardingTranslation.onboardingRequestResetPasswordFormEmailRequired,
      ),
  },
);

interface RequestResetPasswordFormProps {
  onSubmit: (values: RequestResetPasswordFormValues) => Promise<unknown>;
}

export const RequestResetPasswordForm: FC<RequestResetPasswordFormProps> = ({
  onSubmit,
}) => {
  const intl = useIntl();

  const initialValues = {
    email: '',
  };

  const handleSubmit = useCallback(
    (values: RequestResetPasswordFormValues) => {
      return onSubmit(values).catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
      });
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RequestResetPasswordFormSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="request-reset-password-form">
          <RequestResetPasswordField>
            <Field
              name="email"
              type="email"
              data-testid="email-input"
              label={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingRequestResetPasswordFormEmailLabel,
              })}
              placeholder={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingRequestResetPasswordFormEmailPlaceholder,
              })}
              component={FormInput}
            />
          </RequestResetPasswordField>

          <RequestResetPasswordControls>
            <Button
              type="submit"
              data-testid="submit-button"
              disabled={isSubmitting}
              size={ButtonSize.md}
              mode={ButtonMode.primary}>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingRequestResetPasswordFormEmailSubmitButton
                }
              />
            </Button>
          </RequestResetPasswordControls>
        </Form>
      )}
    </Formik>
  );
};
