import styled, { css } from 'styled-components';
import { ChatAvatar } from '../../../ChatAvatar';
import { AVATARS_TO_DISPLAY } from './ParticipantAvatars';

export const StyledParticipantAvatars = styled.div`
  display: flex;
  cursor: pointer;
`;

const BORDER_SIZE = 2;

export const ParticipantAvatar = styled(ChatAvatar)<{
  index: number;
  darkText?: boolean;
  overlaySize: string;
}>`
  border: ${BORDER_SIZE}px solid ${p => p.theme.colors.Surface};
  z-index: ${p => AVATARS_TO_DISPLAY + 1 - p.index};
  border-radius: 50%;
  margin-left: ${p => (p.index ? p.overlaySize : 0)};
  background: ${p => p.theme.colors.ChatParticipantBackground};

  ${p =>
    p.darkText &&
    css`
      background-color: ${p.theme.colors.Background};
      color: ${p.theme.colors.OnSurfaceMediumEmphasis};
      border: ${BORDER_SIZE}px solid transparent;
    `}
`;

export const ParticipantCount = styled.div<{
  avatarSize: string;
  overlaySize: string;
}>`
  width: ${p => p.avatarSize};
  height: ${p => p.avatarSize};
  border: ${BORDER_SIZE}px solid ${p => p.theme.colors.Surface};
  border-radius: 50%;
  margin-left: ${p => p.overlaySize};
  z-index: 0;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.563rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  background-color: ${({ theme }) => theme.colors.Background};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
`;
