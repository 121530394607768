import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { DropZone, StyledNavigatorLinkContent } from './NavigatorLink.styled';
import { useDrop } from 'react-dnd';
import {
  DragAppItemType,
  DragFolderItemType,
  DragLinkItemType,
  DragType,
} from '../../../Drag';
import { useConfirm } from '../../../../shared/components/Modal';
import { DesktopTranslation } from '../../../Desktop/i18n';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import {
  useCurrentWorkspacePermissions,
  useMobileNavigationSidebar,
} from '../../../Workspace/Workspace.hooks';
import { getPermissionsForDesktopId } from '../../../Desktop/Desktop.utils';
import { NavLink } from 'react-router-dom';
import {
  useCurrentDesktopIdFromTheUrl,
  useMoveAppMutation,
} from '../../../Desktop/Desktop.hooks';
import { useAppleTouchDevice } from '../../../../shared/hooks';
import { useCaseMoveLink } from '../../../Link/UseCase/editLink';
import { useCaseEditFolder } from '../../../Folder/UseCase/editFolder';

interface NavigatorLinkProps {
  className?: string;
  to: string;
  dropZone?: boolean;
  desktopName?: string;
  desktopId?: string;
  workspaceId?: string;
  isActive?: boolean;
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

export const NavigatorLink: FC<PropsWithChildren<NavigatorLinkProps>> = ({
  to,
  isActive,
  children,
  dropZone = false,
  desktopName,
  desktopId,
  isDragging,
  isDraggingOver,
}) => {
  const { formatMessage } = useIntl();
  const { askConfirmation } = useConfirm();
  const {
    permissions: { desktops: desktopsPermissions },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  const desktopPermissions = useMemo(
    () =>
      permissionsLoaded
        ? getPermissionsForDesktopId(desktopsPermissions, desktopId!)
        : undefined,
    [desktopId, desktopsPermissions, permissionsLoaded],
  );
  const currentOpenedDesktopId = useCurrentDesktopIdFromTheUrl();

  const canAddLink = desktopPermissions?.user_context?.canAddLink;
  const canCreateFolder = desktopPermissions?.user_context?.canCreateFolder;
  const canAddApp = desktopPermissions?.user_context?.canAddApp;

  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();

  const isAppleTouchDevice = useAppleTouchDevice();

  const { editFolderUseCase } = useCaseEditFolder();

  const [moveAppMutation] = useMoveAppMutation();
  const updateApp = useCallback(
    (appItem: DragAppItemType) => {
      return moveAppMutation({
        variables: {
          input: {
            id: currentOpenedDesktopId!,
            app: appItem.app.app.id,
            toDesktop: desktopId!,
          },
        },
      })
        .then(res => {
          // Mercure events are removing and adding apps for now.
          // TODO: Add fallback cache update when API has correct response data
          showToastSuccessMessage(DesktopTranslation.movedAppToDesktopSuccess, {
            desktopName: desktopName,
          });
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    },
    [desktopId, desktopName, moveAppMutation, currentOpenedDesktopId],
  );

  const { moveLinkToDesktop } = useCaseMoveLink();

  // TODO: replace react-dnd with dnd-kit solution
  const [{ isDraggingOver: isDraggingOverDeprecated }, desktopDropRef] =
    useDrop<
      DragLinkItemType | DragFolderItemType | DragAppItemType,
      unknown,
      { isDraggingOver: boolean }
    >({
      accept: [DragType.LINK, DragType.FOLDER, DragType.APP],
      drop: item => {
        if (
          (item.type === DragType.LINK &&
            currentOpenedDesktopId === desktopId) ||
          (item.type === DragType.FOLDER &&
            currentOpenedDesktopId === desktopId) ||
          (item.type === DragType.APP && currentOpenedDesktopId === desktopId)
        ) {
          return;
        }

        if (
          (!canAddLink && item.type === DragType.LINK) ||
          (!canCreateFolder && item.type === DragType.FOLDER) ||
          (!canAddApp && item.type === DragType.APP)
        ) {
          switch (item.type) {
            case DragType.LINK:
              showToastErrorMessage(DesktopTranslation.noMoveLinkPermission, {
                linkName: item.link.data.title || item.link.data.url,
                desktopName: desktopName,
              });
              break;
            case DragType.FOLDER:
              showToastErrorMessage(DesktopTranslation.noMoveFolderPermission, {
                folderName: item.folder.name,
                desktopName: desktopName,
              });
              break;
            case DragType.APP:
              showToastErrorMessage(DesktopTranslation.noMoveAppPermission, {
                appName: item.app.app.name,
                desktopName: desktopName,
              });
              break;
            default:
              return null;
          }
          return;
        }

        switch (item.type) {
          case DragType.LINK:
            const linkIsPresentInSelectedLinks =
              item.currentDesktopSelectedLinksMap?.[item.link.id];
            const linkIdsToMove = linkIsPresentInSelectedLinks
              ? Object.keys(item.currentDesktopSelectedLinksMap || {})
              : [item.link.id];
            askConfirmation(
              linkIsPresentInSelectedLinks && linkIdsToMove.length > 1 ? (
                <FormattedHTMLMessage
                  id={DesktopTranslation.moveItemsToDesktopConfirmation}
                  values={{
                    count: linkIdsToMove.length,
                    desktopName: desktopName,
                  }}
                />
              ) : (
                <FormattedHTMLMessage
                  id={DesktopTranslation.moveItemToDesktopConfirmation}
                  values={{
                    itemName: item.link.data.title || item.link.data.url,
                    desktopName: desktopName,
                  }}
                />
              ),
              formatMessage({ id: DesktopTranslation.moveLinkConfirmHeader }),
              {
                confirmButtonText: formatMessage({
                  id: DesktopTranslation.moveItemToDesktopConfirmButtonText,
                }),
              },
            )
              .then(confirm => {
                if (!confirm) {
                  return;
                }

                return moveLinkToDesktop(linkIdsToMove, desktopId!);
              })
              .then(response => {
                if (!response || response?.errors) {
                  return;
                }

                if (linkIdsToMove.length > 1) {
                  showToastSuccessMessage(
                    DesktopTranslation.movedLinksToDesktopSuccess,
                    {
                      count: linkIdsToMove.length,
                      desktopName,
                    },
                  );
                } else {
                  showToastSuccessMessage(
                    DesktopTranslation.movedLinkToDesktopSuccess,
                    {
                      desktopName,
                    },
                  );
                }
              });
            break;
          case DragType.FOLDER:
            askConfirmation(
              <FormattedHTMLMessage
                id={DesktopTranslation.moveItemToDesktopConfirmation}
                values={{
                  itemName: item.folder.name,
                  desktopName: desktopName,
                }}
              />,
              formatMessage({ id: DesktopTranslation.moveFolderConfirmHeader }),
              {
                confirmButtonText: formatMessage({
                  id: DesktopTranslation.moveItemToDesktopConfirmButtonText,
                }),
              },
            ).then(confirm => {
              if (!confirm) {
                return;
              }
              editFolderUseCase(
                item.folder.id,
                {
                  name: item.folder.name,
                  parent: undefined,
                  desktop: desktopId,
                },
                desktopName,
              );
            });
            break;
          case DragType.APP:
            askConfirmation(
              <FormattedHTMLMessage
                id={DesktopTranslation.moveItemToDesktopConfirmation}
                values={{
                  itemName: item.app.app.name,
                  desktopName: desktopName,
                }}
              />,
              formatMessage({ id: DesktopTranslation.moveAppConfirmHeader }),
              {
                confirmButtonText: formatMessage({
                  id: DesktopTranslation.moveItemToDesktopConfirmButtonText,
                }),
              },
            ).then(confirm => {
              if (!confirm) {
                return;
              }
              updateApp(item);
            });
            break;
          default:
            break;
        }
      },
      collect: monitor => ({
        isDraggingOver: monitor.isOver(),
      }),
    });

  const clickHandler = useCallback(mobileNavigationSidebarClose, [
    mobileNavigationSidebarClose,
  ]);

  return (
    <DropZone
      ref={dropZone ? desktopDropRef : null}
      isDraggingOver={isDraggingOver || isDraggingOverDeprecated}
      isActive={isActive}>
      <NavLink onClick={clickHandler} to={to}>
        <StyledNavigatorLinkContent
          enableHover={isAppleTouchDevice}
          isDragging={isDragging}
          isDraggingOver={isDraggingOver || isDraggingOverDeprecated}
          isActive={isActive}>
          {children}
        </StyledNavigatorLinkContent>
      </NavLink>
    </DropZone>
  );
};
