import React, { FC, useCallback, useMemo } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import {
  CustomIntegrationHookFormValues,
  IntegrationClientFullApiType,
} from '../../../../AppIntegrations.types';
import {
  useCreateIntegrationClientHookMutation,
  useGetIntegrationClient,
} from '../../../../AppIntegrations.hooks';
import { GraphQLError } from 'graphql';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import { IntegrationHookForm } from '../IntegrationHookForm';
import { useQueryParams } from '../../../../../../shared/hooks';
import { getShortId } from '../../../../../../shared/utils/id';
import { AppIntegrationsTranslation } from '../../../../i18n';

interface IntegrationHookProps extends ModalProps {
  integrationClient: IntegrationClientFullApiType;
}

export const IntegrationHookModal: FC<IntegrationHookProps> = ({
  visible,
  onRequestClose,
  integrationClient,
}) => {
  const queryParams = useQueryParams();
  const { hookId } = queryParams;

  const integrationHook = useMemo(() => {
    const hook = integrationClient.hooks.edges.filter(
      hook => getShortId(hook.node.id) === hookId,
    );
    return hook[0];
  }, [hookId, integrationClient]);

  const [
    createIntegrationClientHookMutation,
  ] = useCreateIntegrationClientHookMutation();

  const { refetch } = useGetIntegrationClient({
    skip: !integrationClient,
    variables: {
      id: integrationClient.id,
    },
  });

  const handleSubmit = useCallback(
    (formValues: CustomIntegrationHookFormValues) => {
      const { desktop } = formValues;

      return createIntegrationClientHookMutation({
        variables: {
          input: {
            integrationClient: integrationClient?.id!,
            desktop: desktop,
          },
        },
      })
        .then(() => {
          refetch();
          onRequestClose();
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      createIntegrationClientHookMutation,
      integrationClient,
      onRequestClose,
      refetch,
    ],
  );

  return (
    <Modal width={400} visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <ModalHeaderTitle>
          {integrationHook ? (
            integrationHook.node.desktop.name
          ) : (
            <FormattedMessage
              id={
                AppIntegrationsTranslation.appCustomIntegrationAddNewHookLabel
              }
            />
          )}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent>
        <IntegrationHookForm
          integrationHook={integrationHook && integrationHook.node}
          onSubmit={handleSubmit}
          onCancel={onRequestClose}
        />
      </ModalContent>
    </Modal>
  );
};
