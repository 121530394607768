import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button, ButtonMode, ButtonSize } from '../Button/Button';
import { DropboxErrorPageStyled } from './DropboxErrorPage.styled';
import { DropBoxErrorPageTranslation } from './i18n';

export const DropboxErrorPage: FC = () => {
  return (
    <DropboxErrorPageStyled>
      <FormattedHTMLMessage
        id={DropBoxErrorPageTranslation.errorPageDescription}
        tagName="h3"
      />

      <Button
        type="button"
        onClick={() => {
          window.close();
        }}
        mode={ButtonMode.primary}
        size={ButtonSize.md}>
        <FormattedMessage id={DropBoxErrorPageTranslation.errorPageClose} />
      </Button>
    </DropboxErrorPageStyled>
  );
};
