import styled, { css } from 'styled-components';
import { ImportantMessageType } from './ImportantMessage';

interface StyledImportantMessageProps {
  type: ImportantMessageType;
  withIcon?: boolean;
}

export const StyledImportantMessage = styled.div<StyledImportantMessageProps>`
  border-radius: 0.25rem;
  width: 100%;
  padding: 1.125rem 1rem 1.125rem 1.125rem;
  font-size: 0.875rem;
  line-height: 20px;
  margin: 2rem 0;
  word-break: break-word;

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: flex-start;

      svg {
        flex-shrink: 0;
        margin-right: 0.625rem;
      }
    `};

  ${({ type, theme }) =>
    type === ImportantMessageType.DEFAULT &&
    css`
      color: ${theme.colors.OnSurfaceMediumEmphasis};
      svg {
        color: ${theme.colors.OnSurfaceMediumEmphasis};
      }
    `};

  ${({ type, theme }) =>
    type === ImportantMessageType.CRITICAL &&
    css`
      background-color: ${theme.colors.CriticalLighter};
      color: ${theme.colors.CriticalDark};
      svg {
        color: ${theme.colors.CriticalDark};
      }
    `};

  ${({ type, theme }) =>
    type === ImportantMessageType.WARNING &&
    css`
      background-color: ${theme.colors.WarningLight};
      color: ${theme.colors.WarningDark};
      svg {
        color: ${theme.colors.WarningDark};
      }
    `};

  ${({ type, theme }) =>
    type === ImportantMessageType.INFO &&
    css`
      background-color: ${theme.colors.SelectedItemLight};
      color: ${theme.colors.OnSurfaceHighEmphasis};
      svg {
        color: ${theme.colors.Primary};
      }
    `};

  a {
    color: ${p => p.theme.colors.PrimaryDark};
  }
`;
