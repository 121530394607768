import { AccountMercureCategoryInfoApiType } from '../../User/User.types';
import { StreamTopic } from '../Mercure.types';

export const getChatMercureUrl = (
  mercureCategoryInfo?: AccountMercureCategoryInfoApiType,
) => {
  if (!mercureCategoryInfo || !process.env.CHAT_MERCURE_URL) {
    return null;
  }

  const url = new URL(process.env.CHAT_MERCURE_URL);

  let chatTopics = mercureCategoryInfo.topics[StreamTopic.chat];
  if (!Array.isArray(chatTopics)) {
    chatTopics = [chatTopics];
  }

  chatTopics.forEach(topicUrl => {
    url.searchParams.append('topic', topicUrl);
  });

  return url.toString();
};
