import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledConferencesListItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.375rem 0.625rem 0.375rem 0.375rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${p => p.theme.colors.OnSurfacePressed};
  }

  &.active {
    background-color: ${p => p.theme.colors.Primary};

    svg,
    path {
      color: ${p => p.theme.colors.CallTextColor};
      stroke: ${p => p.theme.colors.CallTextColor};
    }
  }

  .wrapper {
    width: 100%;
    margin-left: 0.5rem;
  }

  .countBadgeWrapper {
    margin-left: 0.375rem;
  }
`;

export const ConferenceInner = styled.div`
  display: flex;
  line-height: 1.5rem;
`;

export const ConferenceControls = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  max-width: 100%;
  flex-direction: column;
  padding-left: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const ConferenceDate = styled.div<{ unreadCount?: number }>`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  margin-bottom: 0.25rem;

  ${({ unreadCount, theme: { colors } }) =>
    unreadCount &&
    css`
      color: ${colors.Primary};
    `}
`;

export const ControlsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ConferenceStatusContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.375rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;
