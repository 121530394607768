import styled from 'styled-components';

export const ResetPasswordField = styled.div`
  display: flex;

  > * {
    flex-basis: 100%;
  }
  > * ~ * {
    margin-left: 16px;
  }

  & ~ & {
    margin-top: 16px;
  }
`;

export const ResetPasswordControls = styled.div`
  margin-top: 32px;
`;

export const HiddenInput = styled.input`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  border: 0;
  margin: 0;
  pointer-events: none;
`;
