import styled, { css } from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const StyledEditUserProfileForm = styled.div`
  padding: 12px 24px 24px;

  .fieldBox {
    margin-bottom: 26px;
  }

  .imageUpload {
    flex-shrink: 0;
  }
`;

export const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 15px;
  }
`;

export const StyledAvatarBox = styled.div<{
  size?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0 auto;

  ${({ size }) =>
    size &&
    css`
      max-width: ${size}px;
    `}
`;

export const StyledAvatar = styled.img<{
  size?: number;
}>`
  display: flex;
  border-radius: 3px;

  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}
`;

export const ImageUploadLabel = styled.span`
  display: block;
  margin-bottom: 0.25rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
`;

export const ImageButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 2rem 0;
`;

export const ImageUploadButton = styled(Button)`
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const ImageRemoveButton = styled(Button)`
  margin-top: 0.5rem;
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.CriticalDark};
`;

export const EmailChangeRevokeButton = styled(Button).attrs({ type: 'button' })`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.Primary};
`;
