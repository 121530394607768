import { gql } from '@apollo/client';
import { ApiConnection } from '../../shared/api/api.types';
import { FolderApiType, FolderWithLinksApiType } from './Folder.types';
import { FolderFragment } from './Folder.fragments';

export const GET_FOLDER = gql`
  query GetFolder($id: ID!) {
    folder(id: $id) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export interface GetFolderVariables {
  id: string;
}

export interface GetFolderResponse {
  folder: FolderApiType;
}

export const FILTER_FOLDERS = gql`
  query GetFolders(
    $desktop: String
    $desktop_workspace: String
    $filterFreeTextSearch: String
    $filterOnlyAccessible: Boolean
  ) {
    folders(
      desktop: $desktop
      desktop_workspace: $desktop_workspace
      filterFreeTextSearch: $filterFreeTextSearch
      filterOnlyAccessible: $filterOnlyAccessible
      first: 500
    ) {
      edges {
        node {
          ...FolderFragment
        }
      }
    }
  }
  ${FolderFragment}
`;

export interface FilterFoldersVariables {
  desktop?: string;
  desktop_workspace: string;
  filterFreeTextSearch: string;
  filterOnlyAccessible: boolean;
}

export interface FilterFoldersResponse {
  folders: ApiConnection<FolderWithLinksApiType>;
}
