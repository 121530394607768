import React, { FC, useCallback, useMemo } from 'react';
import {
  DesktopSidebarIdentifier,
  useDesktopSidebar,
} from '../../../../Desktop/DesktopSidebar';
import { AccountApiType } from '../../../../User/User.types';
import {
  ParticipantAvatar,
  ParticipantCount,
  StyledParticipantAvatars,
} from './ParticipantAvatars.styled';

export const AVATARS_TO_DISPLAY = 4;

interface ParticipantAvatarsProps {
  participants: AccountApiType[];
  pendingEmails?: string[];
  avatarSize?: string;
  overlaySize?: string;
  onClick?: () => void;
}

export const ParticipantAvatars: FC<ParticipantAvatarsProps> = ({
  participants,
  pendingEmails,
  avatarSize = '1.5rem',
  overlaySize = '-0.5rem',
}) => {
  // const theme = useTheme();
  const participantsToDisplay = useMemo(
    () => [...participants].slice(0, AVATARS_TO_DISPLAY),
    [participants],
  );

  const { setIdentifier } = useDesktopSidebar();
  const pendingEmailsCount = pendingEmails ? pendingEmails.length : 0;
  const otherParticipantsCount =
    participants.length - participantsToDisplay.length;
  const countAccount: AccountApiType | null = useMemo(
    () =>
      otherParticipantsCount || pendingEmailsCount
        ? ({
            id: '',
            firstName: '+',
            lastName: (otherParticipantsCount + pendingEmailsCount).toString(),
          } as AccountApiType)
        : null,
    [otherParticipantsCount, pendingEmailsCount],
  );

  const handleClick = useCallback(() => {
    setIdentifier(DesktopSidebarIdentifier.MEMBERS);
  }, [setIdentifier]);

  return (
    <StyledParticipantAvatars
      onClick={handleClick}
      data-testid="participant-avatars">
      {participantsToDisplay.map((participant, i) => (
        <ParticipantAvatar
          key={participant.id}
          account={participant}
          showOnlineStatus={false}
          avatarSize={avatarSize}
          index={i}
          overlaySize={overlaySize}
        />
      ))}
      {participants.length > AVATARS_TO_DISPLAY && countAccount && (
        <ParticipantCount avatarSize={avatarSize} overlaySize={overlaySize}>
          {participants.length}
        </ParticipantCount>
      )}
    </StyledParticipantAvatars>
  );
};
