import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  KEY_TO_STORAGE_KEY_MAP,
  PERSISTED_STORAGE_KEY_AUTH_CONFIG,
  PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT,
} from './PersistedStorage.constants';
import {
  PersistedStorageContextType,
  PredefinedData,
} from './PersistedStorage.types';
import { PersistedStorageContext } from './PersistedStorage.context';
import { AppShellSkeleton } from '../AppRoot/AppShellSkeleton';
import { makePersistedStorage } from './PersistedStorage.utils';
import { captureException } from '../ErrorInterceptor';

export const PersistedStorageProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [storageInstance] = useState(() => makePersistedStorage());

  const [available, setAvailable] = useState<boolean>();
  const [initialized, setInitialized] = useState(false);
  const [predefinedData, setPredefinedData] = useState<PredefinedData>({
    authConfig: null,
    authenticatedAccount: null,
  });

  const checkStorageAvailability = useCallback(async () => {
    try {
      const randomValue = Math.random();
      await storageInstance.setItem('check-storage', randomValue);
      if ((await storageInstance.getItem('check-storage')) === randomValue) {
        await storageInstance.removeItem('check-storage');
        setAvailable(true);
      }
    } catch (e) {
      captureException(e as Error, 'Persistent storage not available');
      setAvailable(false);
    }
  }, [storageInstance]);

  useEffect(() => {
    if (typeof available === 'undefined') {
      checkStorageAvailability();
      return;
    }

    if (!available) {
      setInitialized(true);
      return;
    }

    Promise.all([
      storageInstance.getItem<PredefinedData['authConfig']>(
        PERSISTED_STORAGE_KEY_AUTH_CONFIG,
      ),
      storageInstance.getItem<PredefinedData['authenticatedAccount']>(
        PERSISTED_STORAGE_KEY_AUTHENTICATED_ACCOUNT,
      ),
    ]).then(([authConfig, authenticatedAccount]) => {
      setPredefinedData({
        authConfig,
        authenticatedAccount,
      });
      setInitialized(true);
    });
  }, [available, checkStorageAvailability, storageInstance]);

  const setPredefinedItem: PersistedStorageContextType['setItem'] = useCallback(
    async (key, value) => {
      if (available) {
        await storageInstance.setItem<typeof value>(
          KEY_TO_STORAGE_KEY_MAP[key],
          value,
        );
      }
      setPredefinedData(data => ({
        ...data,
        [key]: value,
      }));
    },
    [available, storageInstance],
  );

  if (!initialized || typeof available === 'undefined') {
    return <AppShellSkeleton />;
  }

  return (
    <PersistedStorageContext.Provider
      value={{
        available,
        storage: storageInstance,
        data: predefinedData,
        setItem: setPredefinedItem,
      }}>
      {children}
    </PersistedStorageContext.Provider>
  );
};
