import React, { FC, useCallback, useEffect, useState } from 'react';
import { EncryptionTranslation } from '../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LockScreenErrorMessage,
  LockScreenIcon,
  LockScreenMessage,
  LockScreenPasswordInput,
  LockScreenPasswordInputWrapper,
  LockScreenRow,
  LockScreenUnlockButton,
  MasterPasswordLockForm,
  PasswordInputHint,
} from './MasterPasswordLockScreen.styled';
import { CheckmarkIcon, LockIcon } from '../../../shared/icons';

export interface MasterPasswordLockScreenProps {
  decryptCurrentAccountPrivateKey: (_: string) => Promise<CryptoKey>;
  onUnlock?: () => void;
}

export const MasterPasswordLockScreen: FC<MasterPasswordLockScreenProps> = ({
  decryptCurrentAccountPrivateKey,
  onUnlock,
}) => {
  const [inputState, setInputState] = useState('');
  const [decrypting, setDecrypting] = useState(false);
  const [showError, setShowError] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setShowError(false);
  }, [inputState]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDecrypting(true);

      decryptCurrentAccountPrivateKey(inputState)
        .then(() => onUnlock?.())
        .catch(() => {
          setShowError(true);
          setDecrypting(false);
        });
    },
    [inputState, decryptCurrentAccountPrivateKey, onUnlock],
  );

  return (
    <MasterPasswordLockForm
      onSubmit={handleSubmit}
      data-testid="master-password-lock-form">
      <LockScreenIcon>
        <LockIcon />
      </LockScreenIcon>
      <span data-testid="title">
        <FormattedMessage
          tagName={LockScreenMessage}
          id={EncryptionTranslation.masterPasswordUnlockMessageText}
        />
      </span>
      <LockScreenRow data-testid="first-row">
        <CheckmarkIcon />
        <FormattedMessage
          id={EncryptionTranslation.masterPasswordUnlockMessageFirstRow}
        />
      </LockScreenRow>
      <LockScreenRow data-testid="second-row">
        <CheckmarkIcon />
        <FormattedMessage
          id={EncryptionTranslation.masterPasswordUnlockMessageSecondRow}
        />
      </LockScreenRow>
      <LockScreenRow data-testid="third-row">
        <CheckmarkIcon />
        <FormattedMessage
          id={EncryptionTranslation.masterPasswordUnlockMessageThirdRow}
        />
      </LockScreenRow>
      <LockScreenPasswordInputWrapper>
        <span data-testid="input-label">
          <FormattedMessage
            tagName={PasswordInputHint}
            id={EncryptionTranslation.masterPasswordUnlockInputTitle}
          />
        </span>
        <LockScreenPasswordInput
          autoFocus
          placeholder={intl.formatMessage({
            id: EncryptionTranslation.masterPasswordUnlockInputPlaceholder,
          })}
          onChange={e => setInputState(e.target.value)}
          data-testid="lock-screen-password-input"
        />
      </LockScreenPasswordInputWrapper>
      {showError && (
        <span data-testid="error-message">
          <FormattedMessage
            tagName={LockScreenErrorMessage}
            id={EncryptionTranslation.masterPasswordUnlockScreenErrorMessage}
          />
        </span>
      )}
      <LockScreenUnlockButton
        type="submit"
        disabled={decrypting}
        data-testid="lock-screen-unlock-button">
        <FormattedMessage
          id={EncryptionTranslation.masterPasswordUnlockButtonText}
        />
      </LockScreenUnlockButton>
    </MasterPasswordLockForm>
  );
};
