import React, { FC, useCallback, useContext, useMemo } from 'react';
import {
  generatePath,
  Navigate,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Navigation,
  NavigationGroup,
  NavigationGroupHeader,
  NavigationItem,
  NavigationLink,
  StyledAdminView,
  StyledContent,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderTitle,
  StyledSwitch,
} from './WorkspaceAdmin.styled';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  BellIcon,
  CloseIcon,
  CompanyIcon,
  CreditcardIcon,
  CustomAppsIcon,
  DesktopIcon,
  KeyIcon,
  PreferencesIcon,
  TeamIcon,
  UserIcon,
} from '../../../shared/icons';
import {
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../Workspace.hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopsAdmin } from '../../Desktop';
import { UsersAdmin } from '../../User/UsersAdmin';
import { TeamsAdmin } from '../../Team';
import { BillingAdmin } from '../../Billing/BillingAdmin';
import { WorkspaceTranslation } from '../i18n';
import { useMobile } from '../../../shared/hooks';
import { GeneralSettingsAdmin } from '../../GeneralSettings';
import { UserProfileAdmin } from '../../User/UserProfileAdmin';
import { SsoAppsGrid } from '../../AppStore/Views/SsoAppsGrid';
import { AppStoreTranslation } from '../../AppStore/i18n';
import { CustomAppsAdmin } from '../../AppStore/Views/AppStore/CustomAppsAdmin';
import { WorkspaceSelect } from './WorkspaceSelect';
import { WebIcon } from '../../../shared/icons/Web.icon';
import { DomainAdmin } from '../../Domain/DomainAdmin';
import { VerifyFeatureAccess } from '../../Billing/VerifyFeatureAccess';
import { BillingTranslation } from '../../Billing/i18n';
import { useHideScrollbarWhenIdle } from '../../../shared/hooks/element.hooks';
import { AppIntegrationIcon } from '../../../shared/icons/AppIntegration.icon';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { NotificationsSettingsAdmin } from '../../GeneralSettings/NotificationsSettingsAdmin';
import { WorkspaceNotifications } from '../../Admin/WorkspaceNotifications';
import { useNativeWrapper } from '../../NativeWrapper';
import { Route } from '../../ErrorInterceptor/router.injectors';
import { AppIntegrations } from '../../AppIntegrations';
import { WorkspaceSettingsAdmin } from '../WorkspaceSettingsAdmin';
import { WorkspaceContext } from '../Workspace.context';
import { WORKSPACE_ADMIN_PATHNAME } from '../Workspace.constants';
import { useCurrentRouteUrl } from '../../../shared/hooks/router.hooks';
import { getShortId } from '../../../shared/utils/id';

const ICON_SIZE = 16;

export const WorkspaceAdmin: FC = () => {
  const url = useCurrentRouteUrl();
  const navigate = useNavigate();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { lastOpenedAdminPath: lastOpenedAdminPathMap } =
    useContext(WorkspaceContext);
  const {
    permissions: {
      canMenuBillingPage,
      canViewBillingPage,
      canViewProfilePage,
      canMenuUsersPage,
      canViewUsersPage,
      canMenuGroupsPage,
      canViewGroupsPage,
      canMenuDesktopsPage,
      canViewDesktopsPage,
      canMenuCustomAppsPage,
      canViewCustomAppsPage,
      canMenuSsoPage,
      canViewSsoPage,
      canMenuDomainPage,
      canViewDomainPage,
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
      canMenuAppIntegrationsPage,
    },
  } = useCurrentWorkspacePermissions();
  const isMobile = useMobile();
  const canViewWorkspaceNavigationHeader =
    canViewBillingPage ||
    canViewProfilePage ||
    canViewUsersPage ||
    canViewGroupsPage ||
    canViewDesktopsPage ||
    canViewCustomAppsPage ||
    canViewSsoPage ||
    canViewDomainPage;

  const { isNativeWrapperAvailable } = useNativeWrapper();
  const { formatMessage } = useIntl();

  const [adminViewRef] = useHideScrollbarWhenIdle();

  const { state } = useLocation();
  const returnTo = useMemo(() => {
    if (
      state &&
      typeof state.returnTo === 'string' &&
      state.returnTo.includes(getShortId(currentWorkspace.id))
    ) {
      return state.returnTo;
    }
    return '/';
  }, [currentWorkspace, state]);

  const handleReturnToButtonClick = useCallback(() => {
    navigate(returnTo);
  }, [navigate, returnTo]);

  return (
    <StyledAdminView data-testid="admin-view" ref={adminViewRef}>
      {!isMobile && (
        <StyledHeaderContainer>
          <StyledHeader data-testid="header">
            <StyledHeaderTitle data-testid="title">
              <FormattedMessage id={WorkspaceTranslation.adminHeaderTitle} />
            </StyledHeaderTitle>
            <ButtonWithIcon
              icon={CloseIcon}
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              onClick={handleReturnToButtonClick}
              data-testid="close-view-button"
              data-tourid="admin-close-button">
              <FormattedMessage
                id={WorkspaceTranslation.adminHeaderCloseButton}
              />
            </ButtonWithIcon>
          </StyledHeader>
        </StyledHeaderContainer>
      )}
      <StyledContent>
        {!isMobile && (
          <Navigation data-testid="navigation">
            <NavigationGroup>
              <NavigationGroupHeader>
                <FormattedMessage
                  id={WorkspaceTranslation.adminNavGroupGeneralHeader}
                />
              </NavigationGroupHeader>

              <NavigationItem data-tourid="user-settings-page-link">
                <NavigationLink
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={formatMessage({
                    id: WorkspaceTranslation.tooltipAdminNavUserSettingsLink,
                  })}
                  data-tooltip-place={TooltipPlace.right}
                  end
                  to={`${url}/settings`}
                  data-testid="user-settings">
                  <PreferencesIcon width={ICON_SIZE} height={ICON_SIZE} />
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavUserSettingsLink}
                  />
                </NavigationLink>
              </NavigationItem>
              <NavigationItem data-tourid="notification-settings-page-link">
                <NavigationLink
                  end
                  to={`${url}/notifications`}
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={formatMessage({
                    id: WorkspaceTranslation.tooltipAdminNavNotificationsSettingsLink,
                  })}
                  data-tooltip-place={TooltipPlace.right}
                  data-testid="notification-settings">
                  <BellIcon width={ICON_SIZE} height={ICON_SIZE} />
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavNotificationSettingsLink}
                  />
                </NavigationLink>
              </NavigationItem>
            </NavigationGroup>

            <NavigationGroup>
              {canViewWorkspaceNavigationHeader && (
                <NavigationGroupHeader>
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavGroupAccountHeader}
                  />
                </NavigationGroupHeader>
              )}
              <WorkspaceSelect
                onSelect={workspace => {
                  const lastOpenedAdminPath = lastOpenedAdminPathMap.get(
                    getShortId(workspace.id),
                  );

                  if (lastOpenedAdminPath) {
                    navigate(lastOpenedAdminPath);
                  } else {
                    navigate(
                      generatePath(WORKSPACE_ADMIN_PATHNAME, {
                        workspaceId: getShortId(workspace.id),
                      }),
                    );
                  }
                }}
              />
              <NavigationItem data-tourid="user-profile-page-link">
                <NavigationLink
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={formatMessage({
                    id: WorkspaceTranslation.tooltipAdminNavUserProfile,
                  })}
                  data-tooltip-place={TooltipPlace.right}
                  end
                  to={`${url}/profile`}
                  data-testid="user-profile">
                  <UserIcon width={ICON_SIZE} height={ICON_SIZE} />
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavUserProfileLink}
                  />
                </NavigationLink>
              </NavigationItem>
              {/* NOTIFICATIONS */}
              <NavigationItem data-tourid="workspace-notification-tooltip">
                <NavigationLink
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={formatMessage({
                    id: WorkspaceTranslation.tooltipAdminNavUserNotificationsLink,
                  })}
                  data-tooltip-place={TooltipPlace.right}
                  end
                  to={`${url}/workspace-notifications`}
                  data-testid="workspace-notifications">
                  <BellIcon width={ICON_SIZE} height={ICON_SIZE} />
                  <FormattedMessage
                    id={WorkspaceTranslation.adminNavUserNotificationsLink}
                  />
                </NavigationLink>
              </NavigationItem>
              {/* DOMAIN */}
              <VerifyFeatureAccess
                canDisplay={canMenuDomainPage}
                canAccess={canViewDomainPage}
                restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                <NavigationItem data-tourid="domains-page-link">
                  <NavigationLink
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: WorkspaceTranslation.tooltipAdminNavDomains,
                    })}
                    data-tooltip-place={TooltipPlace.right}
                    end
                    to={`${url}/domains`}
                    data-testid="domains">
                    <WebIcon width={ICON_SIZE} height={ICON_SIZE} />
                    <FormattedMessage
                      id={WorkspaceTranslation.adminNavDomainsLink}
                    />
                  </NavigationLink>
                </NavigationItem>
              </VerifyFeatureAccess>
              {/* PROFILE */}
              {canViewProfilePage && (
                <NavigationItem data-tourid="company-profile-admin-page-link">
                  <NavigationLink
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: WorkspaceTranslation.tooltipAdminNavCompanyProfile,
                    })}
                    data-tooltip-place={TooltipPlace.right}
                    end
                    to={`${url}/company`}
                    data-testid="company-profile">
                    <CompanyIcon width={ICON_SIZE} height={ICON_SIZE} />
                    <FormattedMessage
                      id={WorkspaceTranslation.adminNavCompanyProfileLink}
                    />
                  </NavigationLink>
                </NavigationItem>
              )}
              {/* USERS */}
              <VerifyFeatureAccess
                canDisplay={canMenuUsersPage}
                canAccess={canViewUsersPage}
                restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                <NavigationItem data-tourid="users-admin-page-link">
                  <NavigationLink
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: WorkspaceTranslation.tooltipAdminNavUsers,
                    })}
                    data-tooltip-place={TooltipPlace.right}
                    to={`${url}/users`}
                    data-testid="users">
                    <UserIcon width={ICON_SIZE} height={ICON_SIZE} />
                    <FormattedMessage
                      id={WorkspaceTranslation.adminNavUsersLink}
                    />
                  </NavigationLink>
                </NavigationItem>
              </VerifyFeatureAccess>
              {/* GROUPS */}
              <VerifyFeatureAccess
                canDisplay={canMenuGroupsPage}
                canAccess={canViewGroupsPage}
                restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                <NavigationItem data-tourid="teams-admin-page-link">
                  <NavigationLink
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: WorkspaceTranslation.tooltipAdminNavGroups,
                    })}
                    data-tooltip-place={TooltipPlace.right}
                    to={`${url}/groups`}
                    data-testid="teams">
                    <TeamIcon width={ICON_SIZE} height={ICON_SIZE} />
                    <FormattedMessage
                      id={WorkspaceTranslation.adminNavTeamsLink}
                    />
                  </NavigationLink>
                </NavigationItem>
              </VerifyFeatureAccess>
              {/* DESKTOPS */}
              {(canMenuDesktopsPage || canViewDesktopsPage) && (
                <VerifyFeatureAccess
                  canDisplay={canMenuDesktopsPage}
                  canAccess={canViewDesktopsPage}
                  restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                  <NavigationItem data-tourid="desktops-admin-page-link">
                    <NavigationLink
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: WorkspaceTranslation.tooltipAdminDesktops,
                      })}
                      data-tooltip-place={TooltipPlace.right}
                      to={`${url}/desktops`}
                      data-testid="desktops">
                      <DesktopIcon width={ICON_SIZE} height={ICON_SIZE} />
                      <FormattedMessage
                        id={WorkspaceTranslation.adminNavDesktopsLink}
                      />
                    </NavigationLink>
                  </NavigationItem>
                </VerifyFeatureAccess>
              )}
              {/* BILLING */}
              {!isNativeWrapperAvailable && (
                <VerifyFeatureAccess
                  canDisplay={canMenuBillingPage}
                  canAccess={canViewBillingPage}
                  restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                  <NavigationItem data-tourid="billing-admin-page-link">
                    <NavigationLink
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: WorkspaceTranslation.tooltipAdminNavBilling,
                      })}
                      data-tooltip-place={TooltipPlace.right}
                      end
                      to={`${url}/billing`}
                      data-testid="billing">
                      <CreditcardIcon width={ICON_SIZE} height={ICON_SIZE} />
                      <FormattedMessage
                        id={WorkspaceTranslation.adminNavBillingLink}
                      />
                    </NavigationLink>
                  </NavigationItem>
                </VerifyFeatureAccess>
              )}
              {/* SSO */}
              {(canMenuSsoPage || canViewSsoPage) && (
                <VerifyFeatureAccess
                  canDisplay={canMenuSsoPage}
                  canAccess={canViewSsoPage}
                  restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                  <NavigationItem data-tourid="sso-admin-page-link">
                    <NavigationLink
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: WorkspaceTranslation.tooltipAdminNavSso,
                      })}
                      data-tooltip-place={TooltipPlace.right}
                      end
                      to={`${url}/sso`}
                      data-testid="sso">
                      <KeyIcon width={ICON_SIZE} height={ICON_SIZE} />
                      <FormattedMessage
                        id={AppStoreTranslation.ssoAppTabName}
                      />
                    </NavigationLink>
                  </NavigationItem>
                </VerifyFeatureAccess>
              )}
              {/* APPS INTEGRATION */}
              {(canMenuAppIntegrationsPage ||
                canViewWorkspaceAppIntegrations ||
                canViewPersonalAppIntegrations) && (
                <VerifyFeatureAccess
                  canDisplay={true}
                  canAccess={
                    canViewWorkspaceAppIntegrations ||
                    canViewPersonalAppIntegrations
                  }
                  restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                  <NavigationItem data-tourid="apps-integrations-admin-page-link">
                    <NavigationLink
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: WorkspaceTranslation.tooltipAdminNavAppsIntegrations,
                      })}
                      data-tooltip-place={TooltipPlace.right}
                      to={`${url}/apps-integrations`}
                      data-testid="apps-integrations">
                      <AppIntegrationIcon
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                      />
                      <FormattedMessage
                        id={AppStoreTranslation.customAppsIntegrationsTabFilter}
                      />
                    </NavigationLink>
                  </NavigationItem>
                </VerifyFeatureAccess>
              )}
              {/* CUSTOM APPS */}
              {(canMenuCustomAppsPage || canViewCustomAppsPage) && (
                <VerifyFeatureAccess
                  canDisplay={canMenuCustomAppsPage}
                  canAccess={canViewCustomAppsPage}
                  restrictionMessage={BillingTranslation.generalUpgradeMessage}>
                  <NavigationItem data-tourid="custom-apps-admin-page-link">
                    <NavigationLink
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: WorkspaceTranslation.tooltipAdminNavCustomApps,
                      })}
                      data-tooltip-place={TooltipPlace.right}
                      end
                      to={`${url}/custom-apps`}
                      data-testid="custom-apps">
                      <CustomAppsIcon width={ICON_SIZE} height={ICON_SIZE} />
                      <FormattedMessage
                        id={AppStoreTranslation.customAppsTabFilter}
                      />
                    </NavigationLink>
                  </NavigationItem>
                </VerifyFeatureAccess>
              )}
            </NavigationGroup>
          </Navigation>
        )}

        <StyledSwitch>
          <Routes>
            <Route path={`profile`} element={<UserProfileAdmin />} />
            <Route path={`settings`} element={<GeneralSettingsAdmin />} />
            <Route
              path={`notifications`}
              element={<NotificationsSettingsAdmin />}
            />
            <Route
              path={`workspace-notifications`}
              element={<WorkspaceNotifications />}
            />
            <Route path={`billing`} element={<BillingAdmin />} />
            <Route path={`domains`} element={<DomainAdmin />} />
            <Route path={`users/*`} element={<UsersAdmin />} />
            <Route path={`groups/*`} element={<TeamsAdmin />} />
            <Route path={`desktops/*`} element={<DesktopsAdmin />} />
            <Route path={`company`} element={<WorkspaceSettingsAdmin />} />
            <Route path={`sso`} element={<SsoAppsGrid adminView />} />
            <Route path={`apps-integrations/*`} element={<AppIntegrations />} />
            <Route path={`custom-apps`} element={<CustomAppsAdmin />} />

            <Route
              index
              element={
                <Navigate
                  to={`${url}/${canViewProfilePage ? 'company' : 'profile'}`}
                />
              }
            />
          </Routes>
        </StyledSwitch>
      </StyledContent>
    </StyledAdminView>
  );
};
