import { AccountInvitationApiType } from '../types/Invitation.types';
import { InvitationsTableType } from '../types/InvitationIDDB.types';

export const getRedundantCacheInvitations = (
  cacheInvitations: InvitationsTableType[] = [],
  apiInvitations: AccountInvitationApiType[] = [],
) => {
  const apiInvitationsMap = apiInvitations.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, AccountInvitationApiType>,
  );

  return cacheInvitations.filter(desktop => !apiInvitationsMap[desktop.id]);
};
