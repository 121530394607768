import styled, { keyframes } from 'styled-components';

export const AppHoverDemoStyled = styled.div`
  position: relative;
  padding: 0 36px;
  margin-bottom: 36px;
`;

export const AppContainer = styled.div`
  padding: 28px;
  border: 2px solid ${p => p.theme.colors.SurfaceBorder};
  border-radius: 10px;
  position: relative;
`;

export const AppPlaceholder = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  &:before {
    content: ' ';
    display: inline-block;
    width: 46px;
    height: 46px;
    background-color: ${p => p.theme.colors.SurfaceBorder};
    margin-bottom: 8px;
    border-radius: 10px;
  }
`;

const nonFavoriteIconAnim = keyframes`
  0% {
    opacity: 0;
  }

  31% {
    opacity: 0;
  }

  32% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

const favoriteIconAnim = keyframes`
  0% {
    opacity: 0;
  }

  41% {
    opacity: 0;
  }

  42% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

export const FavIconContainer = styled.div`
  position: absolute;
  width: 16px;
  height: 14px;
  top: 0;
  right: 0;
  margin: 8px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  svg {
    position: absolute;

    &.non-favorite {
      opacity: 0;
      animation: ${nonFavoriteIconAnim} 2s linear infinite;
    }

    &.favorite {
      opacity: 0;
      animation: ${favoriteIconAnim} 2s linear infinite;
    }
  }
`;

const cursorMovementAnim = keyframes`
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  50% {
    transform: matrix(1, 0, 0, 1, -44, -5);
  }

  100% {
    transform: matrix(1, 0, 0, 1, -44, -5);
  }
`;

export const CursorContainer = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  position: absolute;
  top: 20px;
  right: 0;
  animation: ${cursorMovementAnim} 2s linear infinite;

  svg {
    fill: ${p => p.theme.colors.OnNavbar};
  }
`;
