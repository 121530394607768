import styled from 'styled-components';

export const StyledConferenceStatus = styled.div<{ isActive?: boolean }>`
  font-size: 0.75rem;
  word-break: break-all;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  color: ${p =>
    p.isActive
      ? p.theme.colors.CallTextColor
      : p.theme.colors.OnSurfaceLightEmphasis};
`;
