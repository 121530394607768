import { format } from 'date-fns';
import { getShortId } from '../../../../../shared/utils/id';
import type { AccountWithCountsApiType } from '../../../../User/User.types';
import { isBotAccount } from '../../../../User/User.utils';
import { getChatMessageIri } from '../../../Chat.utils';
import {
  CHAT_MESSAGE_DOM_ID_PREFIX,
  HIGHLIGHTED_CLASS_NAME,
  HIGHLIGHTED_DURATION,
} from './ChatMessage.constants';

export const makeChatMessageDomId = (id: string) =>
  `/${CHAT_MESSAGE_DOM_ID_PREFIX}/${getShortId(id)}`;

export const revertChatMessageDomId = (id: string) => {
  const split = id.split(`${CHAT_MESSAGE_DOM_ID_PREFIX}-`);
  if (split[1]) {
    return getChatMessageIri(split[1]);
  }

  return id;
};

export const getMessageNode = (id: string) =>
  document.getElementById(`${makeChatMessageDomId(id)}`);

export const scrollChatMessageIntoView = (
  messageNode: HTMLDivElement,
  options: ScrollIntoViewOptions,
) => {
  messageNode.scrollIntoView(options);
};

export const highlightChatMessage = (messageNode: HTMLElement) => {
  messageNode.classList.add(HIGHLIGHTED_CLASS_NAME);
  setTimeout(() => {
    messageNode.classList.remove(HIGHLIGHTED_CLASS_NAME);
  }, HIGHLIGHTED_DURATION);
};

export const messageTooltipFullDateTime = (inputDate: Date): string => {
  const dateFormat = "EEEE, MMMM d, yyyy 'at' p";

  return format(new Date(inputDate), dateFormat);
};

export const isSystemMessage = (
  authorId: string | null,
  messageAuthor: AccountWithCountsApiType | undefined,
) => !authorId || (messageAuthor && isBotAccount(messageAuthor));
