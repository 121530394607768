import React, { FC, useCallback } from 'react';
import { ChatConversationInternalType } from '../../Chat.types';
import { ChatForm, ChatFormValues } from './ChatForm';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useChatConversationRepository } from '../../Data/Repository/ChatConversation/ChatConversationsApiRepository';

interface RenameChatFormProps {
  chatConversation: ChatConversationInternalType;
  onDone?: () => void;
}

export const RenameChatForm: FC<RenameChatFormProps> = ({
  chatConversation,
  onDone,
}) => {
  const { renameConversation } = useChatConversationRepository();

  const handleSubmit = useCallback(
    ({ title }: ChatFormValues) => {
      onDone?.();
      renameConversation(chatConversation.id, title).catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
    },
    [chatConversation.id, onDone, renameConversation],
  );

  return (
    <ChatForm chatConversation={chatConversation} onSubmit={handleSubmit} />
  );
};
