import styled from 'styled-components';

export const UnshareVaultControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  > * ~ * {
    margin-left: 1rem;
  }
`;
