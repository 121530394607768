import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import {
  RequestMethod,
  RestApiClientResponse,
} from '../../../../Api/RestApiClient/RestApiClient.types';
import { TeamApiType } from '../../Team/types/Team.types';

export const addAccountToTeamRestApi = (
  accountId: string,
  workspaceTeamId: string,
) => {
  try {
    const shortWorkspaceTeamId = getShortId(workspaceTeamId);
    const shortAccountId = getShortId(accountId);
    const restApiClient = getRestApiClient();

    if (!restApiClient) {
      return Promise.resolve(null);
    }

    return restApiClient
      .fetch(
        `/workspace-team/${shortWorkspaceTeamId}/account/${shortAccountId}`,
        RequestMethod.POST,
      )
      .then(({ data }: RestApiClientResponse<TeamApiType>) => data);
  } catch (e) {
    console.error(e);

    return Promise.resolve(null);
  }
};

export const deleteAccountFromTeamRestApi = (
  accountId: string,
  workspaceTeamId: string,
) => {
  try {
    const shortWorkspaceTeamId = getShortId(workspaceTeamId);
    const shortAccountId = getShortId(accountId);
    const restApiClient = getRestApiClient();

    if (!restApiClient) {
      return Promise.resolve(null);
    }

    return restApiClient
      .fetch(
        `/workspace-team/${shortWorkspaceTeamId}/account/${shortAccountId}`,
        RequestMethod.DELETE,
      )
      .then(({ data }: RestApiClientResponse<TeamApiType>) => data);
  } catch (e) {
    console.error(e);

    return Promise.resolve(null);
  }
};
