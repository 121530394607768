import React, { FC, useMemo } from 'react';
import {
  StyledScheduledConferenceDate,
  StyledScheduledConferenceIcon,
  StyledScheduledConferenceIconDay,
  StyledScheduledConferenceIconMonth,
  StyledScheduledConferencesListItem,
  StyledScheduledConferenceTitle,
} from './ScheduledConferencesListItem.styled';
import {
  ConferencesTabsValues,
  ScheduledChatConferenceApiType,
} from '../../../../Conference.types';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { VideoCallIcon } from '../../../../../../shared/icons';
import { ScheduledConferencesMenu } from './ScheduledConferencesMenu';
import {
  getScheduledConferenceDay,
  getScheduledConferenceFormattedDate,
  getScheduledConferenceMonth,
} from '../../../../Conference.utils';
import { getShortId } from '../../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { useCurrentConference } from '../../../../Conference.hooks';

interface ConferencesListItemProps {
  conference: ScheduledChatConferenceApiType;
}

export const ScheduledConferencesListItem: FC<ConferencesListItemProps> = ({
  conference,
}) => {
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const { scheduledConference: currentScheduleConference } =
    useCurrentConference();
  const date = useMemo(
    () =>
      getScheduledConferenceFormattedDate(conference.startAt, conference.endAt),
    [conference.startAt, conference.endAt],
  );
  const conferenceDay = useMemo(
    () => getScheduledConferenceDay(conference.startAt),
    [conference.startAt],
  );
  const conferenceMonth = useMemo(
    () => getScheduledConferenceMonth(conference.startAt),
    [conference.startAt],
  );
  const conferenceLink = useMemo(
    () =>
      `/workspace/${getShortId(workspace.id)}/calls/${getShortId(
        conference.id,
      )}?${getQueryParamsFrom({
        ...queryParams,
        meetingsActiveTab: ConferencesTabsValues.SCHEDULED,
      })}`,
    [conference.id, queryParams, workspace.id],
  );
  const isEditDisabled = false;
  const isActive = conference.id === currentScheduleConference?.id;

  return (
    <>
      <StyledScheduledConferencesListItem
        className={isActive ? 'active' : undefined}
        to={conferenceLink}
        data-tooltip-content={date}
        data-tooltip-place={TooltipPlace.top}>
        <StyledScheduledConferenceIcon>
          <StyledScheduledConferenceIconDay>
            {conferenceDay}
          </StyledScheduledConferenceIconDay>
          <StyledScheduledConferenceIconMonth>
            {conferenceMonth}
          </StyledScheduledConferenceIconMonth>
        </StyledScheduledConferenceIcon>
        <div className="wrapper">
          <StyledScheduledConferenceTitle>
            {conference.title}
          </StyledScheduledConferenceTitle>
          <StyledScheduledConferenceDate>
            <VideoCallIcon width={16} />
            <span>{date}</span>
          </StyledScheduledConferenceDate>
        </div>
        <ScheduledConferencesMenu
          isEditDisabled={isEditDisabled}
          conference={conference}
        />
      </StyledScheduledConferencesListItem>
    </>
  );
};
