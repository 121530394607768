import React from 'react';
import { RestApiClient } from './RestApiClient';

interface RestApiClientProviderContextType {
  restApiClient: RestApiClient | null;
}

export const RestApiClientProviderContext =
  React.createContext<RestApiClientProviderContextType>({
    restApiClient: null,
  });
