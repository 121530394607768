import { GetChatMessagesVariables } from '../../Chat.queries';
import type {
  ChatMessageApiType,
  ChatMessageInternalType,
} from '../../Chat.types';
import { convertGraphQLApiChatMessageToInternal } from '../../Chat.utils';
import type { GetChatMessagesRestResponse } from '../DataSource/ChatMessage/ChatMessages.types';
import { UserContextProps } from './ChatMessage/ChatMessage.types';
import { ChatMessagesTableType } from '../../../Database/ChatMessagesTable/ChatMessagesTable';

interface ConvertToMessageAndThreadMessageProps {
  messages: ChatMessageInternalType[];
  threadMessages: ChatMessageInternalType[];
}

export const convertToMessageAndThreadMessage = (
  chatMessages: ChatMessageApiType[],
  userContext: UserContextProps,
  firstUnreadMessage: ChatMessageApiType | null,
): ConvertToMessageAndThreadMessageProps => {
  const messages: ChatMessageInternalType[] = [];
  const threadMessages: ChatMessageInternalType[] = [];

  chatMessages.forEach(item => {
    messages.push(
      convertGraphQLApiChatMessageToInternal(
        item,
        userContext,
        firstUnreadMessage,
      ),
    );
  });

  return {
    messages,
    threadMessages,
  };
};

export const withFirstUnreadMessage = (
  resp: GetChatMessagesRestResponse,
  variables: Partial<GetChatMessagesVariables>,
): ChatMessageApiType | null => {
  const { createdAtLessThan, createdAtGreaterThanEquals } = variables;
  const firstUnreadMessage = resp.firstUnreadMessage;
  if (!firstUnreadMessage || createdAtLessThan || createdAtGreaterThanEquals) {
    return null;
  }

  const unreadExistInCurrentData = resp.data?.find(
    message => message.id === resp.firstUnreadMessage?.id,
  );

  if (unreadExistInCurrentData) {
    return null;
  }

  return firstUnreadMessage;
};

export const getRedundantCacheMessages = (
  cacheMessages: ChatMessagesTableType[] = [],
  apiMessages: ChatMessageApiType[] = [],
) => {
  const apiMessagesMap = apiMessages.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, ChatMessageApiType>,
  );

  return cacheMessages.filter(message => !apiMessagesMap[message.id]);
};

export const messageIdIsOptimistic = (id: string) => {
  return /^\/chat-messages\/\d+$/.test(id);
};
