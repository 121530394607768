import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLaptop,
  useMediumScreen,
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../../shared/hooks';
import { FolderApiType } from '../../../../../Folder/Folder.types';
import { Link } from '../../../Link';
import { LinksGridDropZone } from '../../../LinksView.styled';
import { LinkBlockSkeleton } from '../../../LinksViewSkeleton';
import {
  useCurrentDesktopContent,
  useCurrentDesktopPermissions,
} from '../../../../../Desktop/Desktop.hooks';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { ListContainer, ListRowItem } from './LinksList.styled';
import { LinkApiType } from '../../../../Link.types';
import { Folder } from '../../../../../Folder/Folder';
import { chunkArray } from '../../../../../../shared/utils/chunkArray.util';
import { GRAPHQL_TYPENAME_FOLDER } from '../../../../../Folder/Folder.constants';
import { DesktopLayout } from '../../../../../Desktop/Desktop.constants';
import { useHideScrollbarWhenIdle } from '../../../../../../shared/hooks/element.hooks';
import { useCurrentDesktopSelectedLinksMap } from '../../../../../Desktop/DesktopItemMultiselect/DesktopItemMultiselect.hooks';
import {
  DesktopSidebarIdentifier,
  useDesktopSidebar,
} from '../../../../../Desktop/DesktopSidebar';

const LINKS_LIST_OVERSCAN = 500;
const LINKS_LIST_INCREASE_VIEWPORT = 500;

export const LinksList: FC<{
  links: LinkApiType[];
  folders: FolderApiType[];
  filterString?: string;
  containerRef: HTMLElement | null;
}> = ({ links, folders, filterString, containerRef }) => {
  const { desktopLinksLoading, desktopLayout, hasChat } =
    useCurrentDesktopContent();
  const { canRemoveLink, canRemoveFolder } = useCurrentDesktopPermissions();
  const queryParams = useQueryParams();
  const [listHideScrollRef] = useHideScrollbarWhenIdle();
  const virtuosoScrollerRef = useRef<HTMLElement | Window | null>(null);
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isLaptop = useLaptop();
  const isMediumScreen = useMediumScreen();
  const desktopSidebar = useDesktopSidebar();
  const { currentDesktopSelectedLinksMap } =
    useCurrentDesktopSelectedLinksMap();
  const isMultiselectMode = useMemo(() => {
    return (
      Object.values(currentDesktopSelectedLinksMap || {}).some(
        value => value,
      ) || false
    );
  }, [currentDesktopSelectedLinksMap]);

  /**
   * Generate joined content list
   */

  const definedColumns = useMemo(() => {
    if (isMobile) {
      return {
        folders: 2,
        links: 1,
      };
    }

    if (isTablet) {
      return {
        folders: 4,
        links: 2,
      };
    }

    if (desktopLayout === DesktopLayout.CONSOLIDATED && hasChat) {
      if (isLaptop) {
        return {
          folders: 3,
          links: 1,
        };
      }

      if (isMediumScreen) {
        return {
          folders: 3,
          links: 2,
        };
      }

      if (
        !isMobile &&
        !isTablet &&
        desktopSidebar.identifier !== DesktopSidebarIdentifier.MEMBERS
      ) {
        return {
          folders: 5,
          links: 3,
        };
      }

      if (
        !isMobile &&
        !isTablet &&
        desktopSidebar.identifier === DesktopSidebarIdentifier.MEMBERS
      ) {
        return {
          folders: 5,
          links: 2,
        };
      }
    }

    if (desktopLayout === DesktopLayout.CONSOLIDATED && !hasChat) {
      if (isLaptop) {
        return {
          folders: 5,
          links: 2,
        };
      }

      if (isMediumScreen) {
        return {
          folders: 6,
          links: 2,
        };
      }
    }

    // tabbed
    if (isLaptop) {
      return {
        folders: 5,
        links: 2,
      };
    }
    if (isMediumScreen) {
      return {
        folders: 6,
        links: 2,
      };
    }

    return {
      folders: 6,
      links: 4,
    };
  }, [
    desktopLayout,
    hasChat,
    isLaptop,
    isMediumScreen,
    isMobile,
    isTablet,
    desktopSidebar,
  ]);

  const listRows: Array<(LinkApiType | FolderApiType)[]> = useMemo(() => {
    const folderChunks = chunkArray(folders, definedColumns.folders);
    const linksChunks = chunkArray(links, definedColumns.links);

    return [...folderChunks, ...linksChunks];
  }, [definedColumns.folders, definedColumns.links, folders, links]);

  /**
   * Force re-creation of the links grid when switching folders.
   */
  const { folderId } = queryParams;
  const [displayGrid, setDisplayGrid] = useState(true);
  useEffect(() => {
    setDisplayGrid(false);

    const timeout = setTimeout(() => {
      setDisplayGrid(true);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [folderId]);

  /**
   * virtuoso
   */

  const linksRef = useRef<VirtuosoHandle>(null);

  const renderItemContent = useCallback(
    (index: number, row: (LinkApiType | FolderApiType)[]) => {
      if (index < 0) {
        return;
      }

      if (!row[0]) {
        return null;
      }

      if ((row[0] as FolderApiType).__typename === GRAPHQL_TYPENAME_FOLDER) {
        return (
          <ListRowItem
            type={'folders'}
            columns={definedColumns.folders}
            key={index}>
            {row.map(folder => (
              <Folder
                key={folder.id}
                folder={folder as FolderApiType}
                dragEnabled={canRemoveFolder}
                searchString={filterString}
              />
            ))}
          </ListRowItem>
        );
      }

      return (
        <ListRowItem type={'links'} columns={definedColumns.links} key={index}>
          {row.map(link => (
            <Link
              key={link.id}
              link={link as LinkApiType}
              index={index}
              selected={currentDesktopSelectedLinksMap?.[link.id] || false}
              multiselectMode={isMultiselectMode}
              forceHoverControls={currentDesktopSelectedLinksMap?.[link.id]}
              dragEnabled={canRemoveLink}
            />
          ))}
        </ListRowItem>
      );
    },
    [
      definedColumns.links,
      definedColumns.folders,
      canRemoveFolder,
      filterString,
      isMultiselectMode,
      canRemoveLink,
      currentDesktopSelectedLinksMap,
    ],
  );

  /**
   END!
   */

  if (!displayGrid) {
    return null;
  }

  if (!containerRef || (!listRows.length && desktopLinksLoading)) {
    return (
      <ListContainer>
        <LinkBlockSkeleton />
        <LinkBlockSkeleton />
      </ListContainer>
    );
  }

  return (
    <>
      <Virtuoso
        ref={linksRef}
        overscan={LINKS_LIST_OVERSCAN}
        increaseViewportBy={LINKS_LIST_INCREASE_VIEWPORT}
        scrollerRef={scrollerRef => {
          listHideScrollRef(scrollerRef as HTMLElement);
          virtuosoScrollerRef.current = scrollerRef;
        }}
        customScrollParent={containerRef}
        components={{
          Scroller: ListContainer,
        }}
        data={listRows}
        itemContent={renderItemContent}
        defaultItemHeight={88}
      />
      <LinksGridDropZone />
    </>
  );
};
