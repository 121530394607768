import { useLiveQuery } from 'dexie-react-hooks';
import { useMemo } from 'react';
import { useAccountsContext } from '../../../Account';
import { ConversationType } from '../../../Chat/Chat.types';
import { database } from '../../../Database';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';

export const useSearchOnlyByConversationName = (
  chatConversationId: string | undefined,
) => {
  const chatConversation = useFindChatConversationById(chatConversationId);
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();

  return useMemo(() => {
    if (!chatConversation) {
      return null;
    }

    const privateChatAccountId =
      chatConversation.type === ConversationType.private ||
      chatConversation.type === ConversationType.appIntegration
        ? chatConversation.userIds.find(id => account.id !== id)
        : undefined;

    return privateChatAccountId
      ? accountsWithAvailability[privateChatAccountId]?.firstName || ''
      : chatConversation.title;
  }, [account.id, accountsWithAvailability, chatConversation]);
};

export const useSearchResultsConversationName = (
  users: string[],
  type: string,
  privateConversationTitle: string | undefined,
) => {
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();

  return useMemo(() => {
    const privateChatAccountId =
      type === ConversationType.private ||
      type === ConversationType.appIntegration
        ? users.find(id => account.id !== id)
        : undefined;

    return privateChatAccountId
      ? accountsWithAvailability[privateChatAccountId]?.firstName
      : privateConversationTitle;
  }, [
    account.id,
    accountsWithAvailability,
    privateConversationTitle,
    type,
    users,
  ]);
};

export const useFindChatConversationById = (
  conversationId: string | undefined,
) => {
  const { workspace } = useCurrentWorkspace();

  const conversations = useLiveQuery(
    () =>
      database.chatConversations
        .where('workspaceId')
        .equals(workspace.id)
        .toArray()
        .catch(() => []),
    [workspace.id],
    [],
  );

  return useMemo(() => {
    if (!conversationId) {
      return null;
    }

    return conversations.find(
      conversation => conversation.id === conversationId,
    );
  }, [conversationId, conversations]);
};
