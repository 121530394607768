import React, { FC, useCallback, useState } from 'react';
import generator from 'generate-password-browser';
import { Checkbox } from '../../../../shared/components/Checkbox';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  CheckBoxWrapper,
  GeneratePasswordButton,
  GeneratePasswordWrapper,
  PasswordLength,
  RangeOutput,
} from './PasswordGenerator.styled';
import { Range } from '../../../../shared/components/Range';
import { ErrorMessage } from '../../../../shared/components/Input';

export interface GeneratePasswordValues {
  length: number;
  uppercase: boolean;
  lowercase: boolean;
  symbols: boolean;
  numbers: boolean;
}

interface GeneratePasswordProps {
  onPasswordGenerate: (value: string) => void;
}

const MIN_RANGE = 10;
const MAX_RANGE = 32;
const RANGE_LENGTH = 10;

export const PasswordGenerator: FC<GeneratePasswordProps> = ({
  onPasswordGenerate,
}) => {
  const [
    generatorOptions,
    setGeneratorOptions,
  ] = useState<GeneratePasswordValues>({
    length: RANGE_LENGTH,
    uppercase: true,
    lowercase: true,
    symbols: true,
    numbers: true,
  });
  const [showError, setShowError] = useState(false);

  const handleGenerate = () => {
    const generatorOptionsWithStrictValue = {
      ...generatorOptions,
      strict: true,
    };

    if (Object.values(generatorOptions).some(value => value === true)) {
      const password = generator.generate(generatorOptionsWithStrictValue);
      onPasswordGenerate(password);
    } else {
      setShowError(true);
    }
  };

  const handleOnChange = useCallback(
    (event: any): void => {
      const value = event.target.checked;
      if (value) {
        setShowError(false);
      }
      setGeneratorOptions({
        ...generatorOptions,
        [event.target.name]: value,
      });
    },
    [generatorOptions],
  );

  const handleRangeChange = (value: number): void => {
    setGeneratorOptions({
      ...generatorOptions,
      length: value,
    });
  };

  return (
    <GeneratePasswordWrapper data-testid="generate-password-panel">
      <PasswordLength data-testid="password-length-panel">
        <span data-testid="password-length-label">
          <FormattedMessage
            id={EncryptionTranslation.editPasswordsPasswordLength}
          />
        </span>
        <Range
          min={MIN_RANGE}
          max={MAX_RANGE}
          value={generatorOptions.length}
          onChange={handleRangeChange}
        />
        <RangeOutput data-testid="password-length-range-output">
          {generatorOptions.length}
        </RangeOutput>
      </PasswordLength>
      <CheckBoxWrapper>
        <FormattedMessage id={EncryptionTranslation.editPasswordsUppercase} />
        <Checkbox
          data-testid="password-generator-uppercase"
          name="uppercase"
          checked={generatorOptions.uppercase}
          onChange={handleOnChange}
        />
      </CheckBoxWrapper>
      <CheckBoxWrapper>
        <FormattedMessage id={EncryptionTranslation.editPasswordsLowercase} />
        <Checkbox
          data-testid="password-generator-lowercase"
          name="lowercase"
          checked={generatorOptions.lowercase}
          onChange={handleOnChange}
        />
      </CheckBoxWrapper>
      <CheckBoxWrapper>
        <FormattedMessage id={EncryptionTranslation.editPasswordsSymbols} />
        <Checkbox
          data-testid="password-generator-symbols"
          name="symbols"
          checked={generatorOptions.symbols}
          onChange={handleOnChange}
        />
      </CheckBoxWrapper>
      <CheckBoxWrapper>
        <FormattedMessage id={EncryptionTranslation.editPasswordsDigits} />
        <Checkbox
          data-testid="password-generator-numbers"
          name="numbers"
          checked={generatorOptions.numbers}
          onChange={handleOnChange}
        />
      </CheckBoxWrapper>
      <GeneratePasswordButton
        data-testid="generate-new-password"
        type="button"
        mode={ButtonMode.secondary}
        size={ButtonSize.sm}
        onClick={handleGenerate}
        fullWidth>
        <FormattedMessage
          id={EncryptionTranslation.editPasswordsGenerateNewPassword}
        />
      </GeneratePasswordButton>
      {showError && (
        <ErrorMessage data-testid="password-generator-error">
          <FormattedMessage id={EncryptionTranslation.editPasswordsErrorText} />
        </ErrorMessage>
      )}
    </GeneratePasswordWrapper>
  );
};
