import type { ChatMessageReactionInternalType } from '../../../../Chat.types';

export const hasAccountReaction = (
  reactions: ChatMessageReactionInternalType[],
  emoji: string,
  accountId: string,
) =>
  reactions.some(
    reaction =>
      reaction.emoji === emoji && reaction.userIds.includes(accountId),
  );
