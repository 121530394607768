import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../../shared/components/Modal';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import { useQueryParams } from '../../../../shared/hooks';
import { getShortId } from '../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { TabId } from '../../../AppStore/AppStore.constants';
import { useCaseDeleteAppFromDesktop } from '../../../AppStore/UseCase/deleteAppFromDesktop';
import { database } from '../../../Database';
import {
  AppLoginMethod,
  DesktopType,
} from '../../../Desktop/Desktop.constants';
import {
  useAppLoginMethod,
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../../Desktop/Desktop.hooks';
import {
  getDesktopType,
  useCurrentTypeOfPage,
} from '../../../Desktop/Desktop.utils';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { getDecryptedVaultPassword } from '../../../Encryption/Encryption.crypto.utils';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useDesktopAppVault,
  useDesktopAppVaultLogin,
} from '../../../Encryption/Encryption.hooks';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { useCurrentRoutePath } from '../../../../shared/hooks/router.hooks';
import {
  DesktopAppEdgeApiType,
  PermissionContext,
} from '../../../Desktop/data/Desktop/types/Desktop.types';

export const AppLinkMenuViewModel = (appNode: DesktopAppEdgeApiType) => {
  // hooks
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const desktop = useCurrentDesktop();
  const intl = useIntl();
  const path = useCurrentRoutePath();
  const { app } = appNode;
  const isWorkspaceAdminView =
    path === '/workspace/:workspaceId/admin/desktops/:desktopId';
  const {
    permissions: { canUnlockCredentials },
  } = useCurrentWorkspacePermissions();
  const { canRemoveApp, canAddApp } = useCurrentDesktopPermissions(
    isWorkspaceAdminView ? PermissionContext.admin : PermissionContext.user,
    getShortId(appNode?.desktop?.id || ''),
  );
  const vault = useDesktopAppVault(
    app.id,
    app.groupApp?.id,
    appNode.desktop?.id,
  );
  const { keyset } = useCurrentAccountKeyset();
  const { login } = useDesktopAppVaultLogin(app.id, appNode.desktop?.id);
  const appLoginMethod = useAppLoginMethod(appNode);
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const { askConfirmation } = useConfirm();

  const { deleteAppFromDesktopUseCase } = useCaseDeleteAppFromDesktop();

  const [hasPassword, setHasPassword] = useState(false);

  const isFavorite = appNode.accountFavorite || isFavoritesDesktop;

  useEffect(() => {
    if (!vault || !keyset?.id || !privateKey) {
      setHasPassword(false);
      return;
    }

    getDecryptedVaultPassword(vault, keyset.id, privateKey).then(password => {
      if (!password) {
        setHasPassword(false);
        return;
      }

      setHasPassword(true);
    });
  }, [vault, keyset?.id, privateKey]);

  // Handlers

  const handleSelectVaultClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        selectVaultForAppId: getShortId(app.id),
        selectVaultForGroupAppId: getShortId(app.groupApp?.id),
        selectVaultForDesktopIdOverride:
          (isFavoritesDesktop &&
            appNode.desktop?.id &&
            getShortId(appNode.desktop?.id)) ||
          undefined,
      }),
    });
  }, [
    navigate,
    queryParams,
    app.id,
    app.groupApp?.id,
    isFavoritesDesktop,
    appNode.desktop?.id,
  ]);

  const handleCopyAppLoginClick = useCallback(() => {
    if (login) {
      navigator.clipboard.writeText(login).then(() => {
        showToastSuccessMessage(DesktopTranslation.appLinkLoginCopiedMessage);
      });
      navigator.clipboard.writeText(login);
    }
  }, [login]);

  const handleCopyAppPasswordClick = useCallback(() => {
    if (vault && keyset?.id && privateKey) {
      getDecryptedVaultPassword(vault, keyset.id, privateKey).then(password => {
        if (password) {
          navigator.clipboard.writeText(password).then(() => {
            showToastSuccessMessage(
              DesktopTranslation.appLinkPasswordCopiedMessage,
            );
          });
        }
      });
    }
  }, [vault, keyset?.id, privateKey]);

  const disableSelectVaultOption = !!(
    desktop &&
    getDesktopType(desktop) === DesktopType.INTERNAL &&
    appLoginMethod === AppLoginMethod.SSO
  );

  const handleDeleteAppFromDesktop = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: DesktopTranslation.appLinkRemoveAppConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      database.desktopApps.get(appNode.id).then(response => {
        if (response) {
          deleteAppFromDesktopUseCase(response.desktopId, appNode.app.id);
        }
      });
    });
  }, [askConfirmation, intl, appNode, deleteAppFromDesktopUseCase]);

  const handleEditAppClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editAppId: getShortId(appNode.app.id),
        tabId: appNode.app.custom ? TabId.customApps : undefined,
      }),
    });
  }, [navigate, queryParams, appNode.app]);

  return {
    canUnlockCredentials,
    canRemoveApp,
    canAddApp,
    login,
    hasPassword,
    keyset,
    isFavorite,
    disableSelectVaultOption,
    handleSelectVaultClick,
    handleCopyAppLoginClick,
    handleCopyAppPasswordClick,
    handleDeleteAppFromDesktop,
    handleEditAppClick,
  };
};
