import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { MenuItem } from '../../../../../../shared/components/Menu';

export const StyledAppListItemWrapper = styled.div<{
  selected: boolean;
  isMobile: boolean;
  isTablet: boolean;
}>`
  display: flex;
  overflow: hidden;
  grid-column-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }

  &.active {
    ${p =>
      !p.isMobile &&
      !p.isTablet &&
      css`
        background-color: ${p => p.theme.colors.Primary};
        color: ${({ theme }) => theme.colors.White};

        .login {
          color: ${({ theme }) => theme.colors.White};
        }

        button {
          color: ${({ theme }) => theme.colors.White};
        }
      `}
  }

  button {
    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:focus,
    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.SelectedItemLight};
    `}
`;

export const StyledPasswordCheckboxWrapper = styled.div<{ isHidden: boolean }>`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const StyledPasswordImageWrapper = styled.div<{ isHidden: boolean }>`
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const StyledAppListItemBody = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 1rem);
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledAppListMenuButton = styled(ButtonWithIcon)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const StyledAppListTitle = styled.div<{
  isPreviewMode: boolean;
  selected: boolean;
}>`
  font-family: ${p => p.theme.fonts.Semibold};
  display: flex;
  font-weight: 600;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.3;

  div {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ isPreviewMode, selected }) =>
      !selected &&
      isPreviewMode &&
      css`
        color: ${p => p.theme.colors.OnSurface};
      `}
  }

  svg {
    margin-left: 0.5rem;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  }
`;

export const StyledAppListLogin = styled.div`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  line-height: 1.33;
  margin-top: 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
`;

export const StyledDeleteMenuItem = styled(MenuItem)`
  svg {
    color: ${p => p.theme.colors.CriticalDark} !important;
  }
`;

export const StyledDeleteMenuText = styled.div`
  color: ${p => p.theme.colors.CriticalDark};
`;

export const StyledOpenLinkWIthCredentialsButton = styled(ButtonWithIcon)`
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  justify-self: center;
`;
