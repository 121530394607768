import React, { FC, useCallback, useRef } from 'react';
import { RegistrationForm } from './RegistrationForm';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { OnboardingPage } from '../OnboardingPage';
import {
  ACTIVATE_ACCOUNT_PATHNAME,
  DEFAULT_FLOW,
  INVITATION_PATHNAME,
  OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  REGISTRATION_PATHNAME,
} from '../../Onboarding.constants';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { useCreateAccountMutation } from '../../../User/User.hooks';
import { OnboardingTranslation } from '../../i18n';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { showToastErrorMessage } from '../../../../shared/components/Toast';
import { RegistrationTitle } from './RegistrationTitle';
import { Spinner } from '../../../../shared/components/Spinner';
import { ExternalProvidersList } from './ExternalProvidersList';
import { RegistrationSeparator } from './RegistrationPage.styled';
import { useIntl } from 'react-intl';
import { useCreateCurrentAccountKeysetFirstTimeMutation } from '../../../Encryption/Encryption.hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import {
  hashAuthPassword,
  hashMasterPassword,
} from '../../../../shared/utils/hash.utils';
import { RegistrationBanner } from './RegistrationBanner';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { LOGIN_PATHNAME } from '../../../Auth/Auth.constants';
import { RegistrationFormValues, SignUpFlow } from '../../Onboarding.types';
import { useCookieStorage } from '../../../../shared/utils/storage';
import {
  encryptPrivateKey,
  exportPublicKey,
  generateRsaKeys,
} from '../../../Encryption/Encryption.crypto.utils';
import {
  rememberNativePlatform,
  useNativePlatformFromUrl,
} from '../../../NativeWrapper';
import { sendOnboardingTrackingEvent } from '../../tracking/OnboardingTracking.utils';
import { getShortId } from '../../../../shared/utils/id';
import { useGetForRegistrationAccountInvitation } from '../../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const RegistrationPage: FC = () => {
  const intl = useIntl();
  const isMobile = useMobile();
  const { account } = useCurrentAccount();
  const { id: inviteId, linkRequestId } = useQueryParams();
  const { flowIdentifier = SignUpFlow.FREE } = useParams<{
    flowIdentifier: any;
  }>();
  // TODO: I(bqk) need help with type here
  const flowID: keyof typeof SignUpFlow = flowIdentifier.toUpperCase();
  const flow = SignUpFlow[flowID];

  const nativePlatform = useNativePlatformFromUrl();

  // ref needed here for correct env placeholders replacement on multistep production build
  const enableSocialRegistration = useRef(
    process.env.ENABLE_SOCIAL_REGISTRATION,
  );

  const { data: invitationData, loading: invitationLoading } =
    useGetForRegistrationAccountInvitation({
      skip: !inviteId,
      variables: {
        id: `/account-invitations/${inviteId}`,
      },
    });

  const invitation = invitationData?.getForRegistrationAccountInvitation;
  const navigate = useNavigate();
  const [createAccountMutation] = useCreateAccountMutation();

  const [createCurrentAccountKeysetFirstTimeMutation] =
    useCreateCurrentAccountKeysetFirstTimeMutation();

  const [, setOpenQuickStartGuide] = useCookieStorage(
    OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  );

  const sendKeys = useCallback(
    (accountId: string, email: string, password: string) => {
      return Promise.all([
        generateRsaKeys(),
        hashMasterPassword(password, email),
      ])
        .then(([{ privateKey, publicKey }, masterPasswordHash]) => {
          return Promise.all([
            exportPublicKey(publicKey),
            encryptPrivateKey(privateKey, masterPasswordHash, accountId),
          ]);
        })
        .then(([pubKey, privKeyEncrypted]) => {
          return createCurrentAccountKeysetFirstTimeMutation({
            variables: {
              input: {
                pubKey,
                privKeyEncrypted,
                account: accountId,
              },
            },
          });
        });
    },
    [createCurrentAccountKeysetFirstTimeMutation],
  );

  const handleSubmit = useCallback(
    ({ acceptTerms, ...account }: RegistrationFormValues) => {
      return hashAuthPassword(account.password, account.email)
        .then(authPasswordHash => {
          return createAccountMutation({
            variables: {
              input: {
                ...account,
                password: authPasswordHash,
                repeatPassword: authPasswordHash,
                accountInvitation: inviteId
                  ? `/account-invitations/${inviteId as string}`
                  : null,
                flow: SignUpFlow[flowID],
                ...(linkRequestId ? { accountLinkRequest: linkRequestId } : {}),
              },
            },
          });
        })
        .then(res => {
          if (!res?.data?.createAccount) {
            return res;
          }
          return sendKeys(
            res.data.createAccount.account.id,
            res.data.createAccount.account.email,
            account.password,
          ).then(() => {
            return res;
          });
        })
        .then(res => {
          setOpenQuickStartGuide('true');

          if (inviteId) {
            sendOnboardingTrackingEvent('create_account_by_invite');

            navigate({
              pathname: LOGIN_PATHNAME,
              search: getQueryParamsFrom({
                loginHint: res.data!.createAccount.account.email,
              }),
            });
          } else {
            sendOnboardingTrackingEvent('create_account');
            if (nativePlatform) {
              rememberNativePlatform(nativePlatform);
            }
            navigate({
              pathname: ACTIVATE_ACCOUNT_PATHNAME,
              search: getQueryParamsFrom({
                id: getShortId(res.data!.createAccount.account.id),
                email: res.data!.createAccount.account.email,
                loginHint: res.data!.createAccount.account.email,
              }),
            });
          }
        });
    },
    [
      createAccountMutation,
      flowID,
      navigate,
      inviteId,
      linkRequestId,
      nativePlatform,
      sendKeys,
      setOpenQuickStartGuide,
    ],
  );

  if (account) {
    return (
      <Navigate to={inviteId ? `${INVITATION_PATHNAME}?id=${inviteId}` : '/'} />
    );
  }

  if (inviteId && SignUpFlow[flowID] !== SignUpFlow.INVITATION) {
    return (
      <Navigate to={`${REGISTRATION_PATHNAME}/invitation?id=${inviteId}`} />
    );
  } else if (
    !flowID ||
    !SignUpFlow[flowID] ||
    (SignUpFlow[flowID] === SignUpFlow.INVITATION && !inviteId)
  ) {
    return <Navigate to={`${REGISTRATION_PATHNAME}/${DEFAULT_FLOW}`} />;
  }

  if (inviteId && invitation?.accountExists) {
    return (
      <Navigate
        to={`${LOGIN_PATHNAME}?returnTo=${INVITATION_PATHNAME}?id=${inviteId}`}
      />
    );
  }

  if (
    inviteId &&
    !invitationLoading &&
    (!invitationData ||
      (invitationData && !invitationData.getForRegistrationAccountInvitation))
  ) {
    showToastErrorMessage(
      OnboardingTranslation.onboardingInvitationExpiredError,
    );
    return <Navigate to={`${REGISTRATION_PATHNAME}`} />;
  }

  if (invitationLoading) {
    return <Spinner size={32} />;
  }

  return (
    <>
      {flow === SignUpFlow.FREE ? (
        <HeadTitle
          translationId={
            OnboardingTranslation.onboardingRegistrationPageFreeHeadTitle
          }
        />
      ) : flow === SignUpFlow.PRO ? (
        <HeadTitle
          translationId={
            OnboardingTranslation.onboardingRegistrationPageProHeadTitle
          }
        />
      ) : (
        <HeadTitle
          translationId={OnboardingTranslation.onboardingRegistrationPageTitle}
        />
      )}
      <OnboardingPage
        before={!isMobile && RegistrationBanner}
        after={isMobile && RegistrationBanner}>
        <OnboardingStep data-testid="registration-step">
          <OnboardingStepHeader data-testid="header">
            <RegistrationTitle flow={flow} invitation={invitation} />
          </OnboardingStepHeader>
          {enableSocialRegistration.current === 'true' && (
            <>
              <ExternalProvidersList
                flow={flow}
                invitation={invitation}
                linkRequestId={linkRequestId as string}
                platform={nativePlatform}
              />
              <RegistrationSeparator
                value={intl.formatMessage({
                  id: OnboardingTranslation.onboardingRegistrationPageSeparator,
                })}
              />
            </>
          )}
          <RegistrationForm invitation={invitation} onSubmit={handleSubmit} />
        </OnboardingStep>
      </OnboardingPage>
    </>
  );
};
