import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { DeleteVaultControls } from './DeleteVault.styled';
import { EncryptionTranslation } from '../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceKeysetsToAccountsMap,
  useDeleteVaultItemMutation,
  useEncryptionContext,
  useRemoveVaultAccessMutation,
  useWorkspaceVaultById,
} from '../Encryption.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { getVaultAccess, getVaultApp, getVaultItem } from '../Encryption.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { getHostname } from '../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.utils';
import { extractNodes } from '../../../shared/api/api.utils';
import { sortByCreationDate } from '../../../shared/utils/list.utils';
import { removeVault } from '../utils/Vault.utils';

interface DeleteVaultProps {
  vaultId: string;
  onDeleted?: (ids: string[]) => void;
  onCancelled?: () => void;
}

export const DeleteVault: FC<DeleteVaultProps> = ({
  vaultId,
  onDeleted,
  onCancelled,
}) => {
  const { keyset } = useCurrentAccountKeyset();
  const keysetsToAccountsMap = useCurrentWorkspaceKeysetsToAccountsMap();
  const { vault } = useWorkspaceVaultById(vaultId);
  const vaultItem = useMemo(() => {
    return vault && getVaultItem(vault);
  }, [vault]);
  const vaultApp = useMemo(() => {
    return getVaultApp(vault);
  }, [vault]);
  const vaultItemId = vaultItem?.id;
  const vaultItemDomain = useMemo(() => {
    return vaultItem?.url && getHostname(vaultItem.url);
  }, [vaultItem]);

  const [isDeleting, setDeleting] = useState(false);
  const { setWorkspaceVaultsCache } = useEncryptionContext();

  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const { account } = useCurrentWorkspaceAccount();
  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keyset.id);
  }, [vault, keyset]);
  const isVaultOwner = useMemo(() => {
    if (!vault && !keyset) {
      return null;
    }
    const vaultAccess = extractNodes(vault?.vaultAccesses)
      .sort(sortByCreationDate)
      .find(access => !access.shared && !access.isImplicitlyShared);
    return (
      keysetsToAccountsMap.get(vaultAccess?.keyset.id as string)?.id ===
      account.id
    );
  }, [account.id, keyset, keysetsToAccountsMap, vault]);

  useEffect(() => {
    if (!vaultItemId && onCancelled) {
      onCancelled();
    }
  }, [vaultItemId, onCancelled]);

  const [deleteVaultItemMutation] = useDeleteVaultItemMutation();
  const [removeVaultAccessMutation] = useRemoveVaultAccessMutation();
  const deleteVaultItem = () => {
    setDeleting(true);
    const mutation = isVaultOwner
      ? deleteVaultItemMutation({
          variables: {
            input: {
              id: vaultItemId!,
            },
          },
        })
      : removeVaultAccessMutation({
          variables: {
            input: {
              workspace: workspaceId,
              id: vaultAccess?.id as string,
            },
          },
        });

    return mutation
      .then(
        res => {
          if (res?.data) {
            showToastSuccessMessage(
              EncryptionTranslation.editVaultFormSuccessMessage,
            );
            setWorkspaceVaultsCache(cache => ({
              ...cache,
              [workspaceId]: removeVault(cache[workspaceId], vault!.id),
            }));

            if (onDeleted) {
              onDeleted([vault!.id]);
            }
          }
        },
        e => {
          showToastGraphQLErrors(e.graphQLErrors);
        },
      )
      .catch(e => showToastGraphQLErrors(e))
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <FormattedHTMLMessage
        id={EncryptionTranslation.deleteVaultDescription}
        values={{ name: vaultApp?.name || vaultItemDomain }}
      />
      <DeleteVaultControls>
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={isDeleting}
          onClick={onCancelled}
          data-testid="cancel-button">
          <FormattedMessage
            id={EncryptionTranslation.deleteVaultCancelButton}
          />
        </Button>
        <Button
          mode={ButtonMode.danger}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={isDeleting}
          onClick={deleteVaultItem}
          data-testid="delete-button">
          <FormattedMessage
            id={EncryptionTranslation.deleteVaultDeleteButton}
          />
        </Button>
      </DeleteVaultControls>
    </>
  );
};
