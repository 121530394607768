import React, { memo, RefObject, useContext } from 'react';
import { AccountApiType } from '../../../../User/User.types';
import { ChatItemType, ChatMessageInternalType } from '../../../Chat.types';
import { ChatMessage } from '../ChatMessage';
import { ChatMessageContext } from '../ChatMessage/ChatMessage.context';
import { useChatMessageMenu } from '../ConversationView.hooks';
import { DateDelimiter } from '../DateDelimiter';

interface MessageListRowProps {
  chatItem: ChatItemType;
  isScrolling: boolean;
  onAvatarClick?: (
    account: AccountApiType,
    ref: RefObject<HTMLElement>,
  ) => void;
  allowReply?: boolean;
}

export const MessageListRow = memo<MessageListRowProps>(
  ({ chatItem, isScrolling, onAvatarClick, allowReply = true }) => {
    const { messageIsEditing } = useContext(ChatMessageContext);

    const {
      showChatMessageMenu,
      cancelMessageEditing,
      updateChatMessageHook,
      showChatMessageAssetMenu,
    } = useChatMessageMenu((chatItem as ChatMessageInternalType).id);

    const messageEditing =
      'id' in chatItem && messageIsEditing(chatItem.id)
        ? {
            isEditing: true,
            onEditSave: updateChatMessageHook,
            onEditCancel: cancelMessageEditing,
          }
        : null;

    if ('dateDelimiter' in chatItem) {
      return (
        <DateDelimiter
          key={`${chatItem.dateDelimiter}`}
          date={chatItem.dateDelimiter}
        />
      );
    }

    return (
      <ChatMessage
        key={`${chatItem.id}`}
        isScrolling={isScrolling}
        chatMessage={chatItem}
        secondary={chatItem.secondary}
        onAvatarClick={onAvatarClick}
        onMenu={showChatMessageMenu}
        onAssetMenu={showChatMessageAssetMenu}
        allowReply={allowReply}
        {...messageEditing}
      />
    );
  },
);
