import styled from 'styled-components';

export const PreviewFrame = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .page {
    padding: ${({ theme }) => theme.spacing.xxxl}
      ${({ theme }) => theme.spacing.m};
    ${({ theme }) => theme.fontTemplates.regularSemibold};
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    max-width: 600px;
    margin: auto;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.Merriweather};
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
    display: inline-block;
  }

  h1 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 1.875rem;
    line-height: 1.33;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 1.5625rem;
    line-height: 1.33;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 1rem;
    line-height: 1.56;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  h5 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.875rem;
    line-height: 1.56;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  h6 {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.75rem;
    line-height: 1.56;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  figure {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  figcaption {
    margin-top: ${({ theme }) => theme.spacing.m};
    font-family: ${({ theme }) => theme.fonts.Medium};
    font-size: 0.875rem;
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  a {
    color: ${({ theme }) => theme.colors.Primary};
  }

  p:empty {
    display: none;
  }
`;

export const PreviewIframeInjectedCSS = styled.style`
  body {
    background: ${({ theme }) => theme.colors.OnSurface};
  }
`;
