import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { appEnv } from '../../appEnv';
import { sendTrackingEvent } from './Tracking.utils';
import { TrackingEvent } from './Tracking.types';

export const useGoogleAnalytics = () => {
  const location = useLocation();
  const initializedRef = useRef(false);
  const pathRef = useRef('');

  useEffect(() => {
    if (!!appEnv.UA_CODE) {
      ReactGA.initialize(appEnv.UA_CODE, {
        testMode: !!(appEnv.UA_DEBUG === 'true'),
      });
      initializedRef.current = true;
    }
  }, []);

  useEffect(() => {
    const newPath = `${location.pathname}${location.search}`;
    if (initializedRef.current && pathRef.current !== newPath) {
      pathRef.current = newPath;
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location]);
};

export const useTrackingEventOnFirstRender = (event: TrackingEvent) => {
  useEffect(() => {
    sendTrackingEvent(event);
  }, []); // eslint-disable-line
};
