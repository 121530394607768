import { gql } from '@apollo/client';
import { FolderFragmentFields } from '../../../Folder.fragments';
import { FolderApiType } from '../../../Folder.types';

export const CREATE_FOLDER = gql`
  mutation CreateFolder($input: createFolderInput!) {
    __typename
    createFolder(input: $input) {
      folder {
        ${FolderFragmentFields}
      }
    }
  }
`;

interface CreateFolderInputVariables {
  name: string;
  desktop: string;
  parent?: string;
}

export interface CreateFolderVariables {
  input: CreateFolderInputVariables;
}

export interface CreateFolderResponse {
  createFolder: {
    folder: FolderApiType;
  };
}
