import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { UserTranslation } from '../../i18n';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { InviteUsers } from '../../UserForms/InviteUsersForm';
import { InviteUserModalContent } from './InviteUserModal.styled';

interface InviteUserModalProps extends ModalProps {
  onDone?: () => void;
}

export const InviteUserModal: FC<InviteUserModalProps> = ({
  onRequestClose,
  onDone,
  ...props
}) => {
  const {
    workspace: { name: workspaceName },
  } = useCurrentWorkspace();

  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage
          id={UserTranslation.inviteUsersModalHeader}
          values={{ workspaceName: workspaceName }}
        />
      </ModalHeader>
      <InviteUserModalContent>
        <InviteUsers displayGroupSelect onDone={onDone} />
      </InviteUserModalContent>
    </Modal>
  );
};
