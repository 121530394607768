import React, { FC, useContext, useEffect } from 'react';
import { TeamsList } from './TeamsList';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { TeamView } from './TeamView';
import { AdminPage, DrawerHeader } from '../../Admin';
import { FormattedMessage } from 'react-intl';
import { TeamTranslation } from '../i18n';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { useMobile } from '../../../shared/hooks';
import { Route } from '../../ErrorInterceptor/router.injectors';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const TeamsAdmin: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);
  const isMobile = useMobile();
  const {
    permissions: { canViewGroupsPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  if (permissionsLoaded && !canViewGroupsPage) {
    navigate(`/workspace/${getShortId(workspace.id)}/admin`);
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <AdminPage data-testid="team-admin">
      {isMobile && (
        <DrawerHeader
          onRequestClose={() =>
            navigate(`/workspace/${getShortId(workspace.id)}`)
          }>
          <FormattedMessage id={TeamTranslation.adminListHeaderTitle} />
        </DrawerHeader>
      )}

      <Routes>
        <Route path={`:teamId`} element={<TeamView />} />
        <Route index element={<TeamsList />} />
      </Routes>
    </AdminPage>
  );
};
