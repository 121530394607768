import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useLocation } from 'react-router-dom';
import { useAppleTouchDevice } from '../../../../../shared/hooks';
import { HeartIcon } from '../../../../../shared/icons';
import { StyledNavigatorLinkContent } from '../../../../Segment/Temp/NavigatorLink/NavigatorLink.styled';
import { FAVORITES_ROOT_PATHNAME } from '../../../Desktop.constants';
import { DesktopTranslation } from '../../../i18n';
import { DesktopName } from '../DesktopList/DesktopList.styled';

type FavoritesLinkProps = {
  workspaceId: string;
};

export const FavoritesLink: FC<FavoritesLinkProps> = ({ workspaceId }) => {
  const location = useLocation();
  const isAppleTouchDevice = useAppleTouchDevice();

  const favoritesPath = generatePath(FAVORITES_ROOT_PATHNAME, {
    workspaceId: workspaceId,
  });

  return (
    <NavLink to={favoritesPath}>
      <StyledNavigatorLinkContent
        enableHover={isAppleTouchDevice}
        isActive={location.pathname.includes(favoritesPath)}
        data-testid="favorites-link">
        <HeartIcon width={16} height={16} />
        <DesktopName hasIcon={true} data-testid="favorites-text">
          <FormattedMessage id={DesktopTranslation.listFavorites} />
        </DesktopName>
      </StyledNavigatorLinkContent>
    </NavLink>
  );
};
