import styled from 'styled-components';

export const MessageListContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

export const NoMessagesMessage = styled.div`
  height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
`;

export const NoMessagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
