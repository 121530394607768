import {
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FILTER_LINKS,
  FilterLinksResponse,
  FilterLinksVariables,
  GET_LINKS,
  GET_RECENT_LINKS,
  GET_TAGS,
  GetLinksResponse,
  GetLinksVariables,
  GetRecentLinksResponse,
  GetRecentLinksVariables,
  GetTagsResponse,
  GetTagsVariables,
  SEARCH_TAGS,
  SearchTagsResponse,
  SearchTagsVariables,
} from './Link.queries';
import {
  CREATE_LINK_CHAT_CONVERSATION,
  CREATE_TAG,
  CreateLinkChatConversationResponse,
  CreateLinkChatConversationVariables,
  CreateTagResponse,
  CreateTagVariables,
} from './Link.mutations';
import { useCallback } from 'react';
import { LinkApiType, TagApiType } from './Link.types';
import {
  addLinkToLinksCache,
  addTagToTagsCache,
  removeLinkFromFilteredLinksCache,
  removeLinkFromLinksCache,
  updateLinkInLinksCache,
} from './cache';

/**
 * LINKS
 */

export const useLinksQuery = (
  options?: QueryHookOptions<GetLinksResponse, GetLinksVariables>,
) => useQuery<GetLinksResponse, GetLinksVariables>(GET_LINKS, options);

export const useFilterLinksQuery = (
  options?: QueryHookOptions<FilterLinksResponse, FilterLinksVariables>,
) => useQuery<FilterLinksResponse, FilterLinksVariables>(FILTER_LINKS, options);

export const useGetRecentLinksQuery = (
  options?: QueryHookOptions<GetRecentLinksResponse, GetRecentLinksVariables>,
) =>
  useQuery<GetRecentLinksResponse, GetRecentLinksVariables>(
    GET_RECENT_LINKS,
    options,
  );

/**
 * TAGS
 */

export const useTagsQuery = (
  options?: QueryHookOptions<GetTagsResponse, GetTagsVariables>,
) => useQuery<GetTagsResponse, GetTagsVariables>(GET_TAGS, options);

export const useSearchTagsQuery = () => {
  const apolloClient = useApolloClient();

  return {
    searchTags: useCallback(
      (options?: SearchTagsVariables) => {
        return apolloClient.query<SearchTagsResponse, SearchTagsVariables>({
          query: SEARCH_TAGS,
          variables: options,
          fetchPolicy: 'network-only',
        });
      },
      [apolloClient],
    ),
  };
};

export const useCreateTagMutation = () =>
  useMutation<CreateTagResponse, CreateTagVariables>(CREATE_TAG, {
    errorPolicy: 'all',
  });

export const useLinkCacheUpdate = () => {
  const apolloClient = useApolloClient();

  return {
    addLinkToLinksCache: useCallback(
      (queryVariables: GetLinksVariables, link: LinkApiType) =>
        addLinkToLinksCache(apolloClient, queryVariables, link),
      [apolloClient],
    ),

    removeLinkFromLinksCache: useCallback(
      (queryVariables: GetLinksVariables, linkId: string) =>
        removeLinkFromLinksCache(apolloClient, queryVariables, linkId),
      [apolloClient],
    ),

    removeLinkFromFilteredLinksCache: useCallback(
      (queryVariables: FilterLinksVariables, linkId: string) =>
        removeLinkFromFilteredLinksCache(apolloClient, queryVariables, linkId),
      [apolloClient],
    ),

    updateLinkInLinksCache: useCallback(
      (queryVariables: GetLinksVariables, link: LinkApiType) =>
        updateLinkInLinksCache(apolloClient, queryVariables, link),
      [apolloClient],
    ),

    addTagToTagsCache: useCallback(
      (queryVariables: GetTagsVariables, tag: TagApiType) =>
        addTagToTagsCache(apolloClient, queryVariables, tag),
      [apolloClient],
    ),

    moveLinkToDesktopCache: useCallback(
      (
        options: {
          target: GetLinksVariables;
          source: GetLinksVariables;
        },
        link: LinkApiType,
      ) => {
        addLinkToLinksCache(apolloClient, options.target, link);
        removeLinkFromLinksCache(apolloClient, options.source, link.id);
      },
      [apolloClient],
    ),

    // TODO @bqk -> deprecate this if favorites works as expected
    // removeFavoriteLinkFromFavoritesLinksCache: useCallback(
    //   (queryVariables: GetFavoritesVariables, linkId: string) =>
    //     removeFavoriteLinkFromFavoritesLinksCache(
    //       apolloClient,
    //       queryVariables,
    //       linkId,
    //     ),
    //   [apolloClient],
    // ),
  };
};

export const useCreateLinkChatConversationMutation = () =>
  useMutation<
    CreateLinkChatConversationResponse,
    CreateLinkChatConversationVariables
  >(CREATE_LINK_CHAT_CONVERSATION);
