import React, { FC, useCallback, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { SetPasswordFormValues } from '../../Password.types';
import {
  PASSWORD_CHARACTER_REGEX,
  PASSWORD_NUMBER_REGEX,
} from '../../../Onboarding/Onboarding.constants';
import { FormInput } from '../../../../shared/components/Input';
import {
  HiddenInput,
  RegistrationField,
  RegistrationFormControls,
  StyledImportantMessage,
} from './SetPasswordForm.styled';
import { ImportantMessageType } from '../../../../shared/components/ImportantMessage';
import { appEnv } from '../../../../appEnv';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { PasswordTranslation } from '../../i18n';
import { EyeIcon } from '../../../../shared/icons';

interface RegistrationFormProps {
  onSubmit: (account: SetPasswordFormValues) => Promise<unknown>;
  showWarningMessage: boolean;
  submitButtonMessageId: string;
}

export const SetPasswordForm: FC<RegistrationFormProps> = ({
  onSubmit,
  showWarningMessage,
  submitButtonMessageId,
}) => {
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisibility] =
    useState(false);

  const registerFormSchema: Schema<SetPasswordFormValues> = Yup.object().shape({
    password: Yup.string()
      .required(PasswordTranslation.passwordRequired)
      .min(8, PasswordTranslation.passwordMinimum)
      .matches(
        PASSWORD_CHARACTER_REGEX,
        PasswordTranslation.passwordContainLetter,
      )
      .matches(
        PASSWORD_NUMBER_REGEX,
        PasswordTranslation.passwordContainNumber,
      ),
    repeatPassword: Yup.string()
      .required(PasswordTranslation.repeatPasswordRequired)
      .min(8, PasswordTranslation.repeatPasswordMinimum)
      .matches(
        PASSWORD_CHARACTER_REGEX,
        PasswordTranslation.repeatPasswordContainLetter,
      )
      .matches(
        PASSWORD_NUMBER_REGEX,
        PasswordTranslation.repeatPasswordContainNumber,
      )
      .oneOf(
        [Yup.ref('password'), null],
        PasswordTranslation.repeatPasswordMatch,
      ),
  });

  const initialValues = {
    password: '',
    repeatPassword: '',
  };

  const handleSubmit = useCallback(
    (values: SetPasswordFormValues) => {
      setSubmitting(true);
      onSubmit(values).finally(() => {
        setSubmitting(false);
      });
    },
    [onSubmit],
  );

  const handlePasswordIconClick = useCallback(() => {
    setPasswordVisibility(prevState => !prevState);
  }, []);

  const handleConfirmPasswordIconClick = useCallback(() => {
    setConfirmPasswordVisibility(prevState => !prevState);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerFormSchema}
      onSubmit={handleSubmit}>
      {({ isValid, dirty }) => (
        <Form data-testid="registration-form">
          {showWarningMessage && (
            <StyledImportantMessage type={ImportantMessageType.WARNING}>
              <FormattedHTMLMessage
                id={PasswordTranslation.profilePasswordImportantMessage}
                values={{
                  learnMoreLink: appEnv.SUPPORT_PASSWORD_MANAGER_URL,
                }}
              />
            </StyledImportantMessage>
          )}

          {/*Chrome password autocomplete disabling workaround*/}
          <HiddenInput type="password" tabIndex={-1} />

          <RegistrationField>
            <Field
              name="password"
              type={isPasswordVisible ? 'text' : 'password'}
              iconOnClick={handlePasswordIconClick}
              data-testid="password-input"
              label={intl.formatMessage({
                id: PasswordTranslation.passwordLabel,
              })}
              placeholder={intl.formatMessage({
                id: PasswordTranslation.passwordPlaceholder,
              })}
              reverseIcon
              icon={EyeIcon}
              // TODO add FormInputWithIcon and use here
              component={FormInput}
            />
          </RegistrationField>

          <RegistrationField>
            <Field
              name="repeatPassword"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              iconOnClick={handleConfirmPasswordIconClick}
              data-testid="repeat-password-input"
              label={intl.formatMessage({
                id: PasswordTranslation.repeatPasswordLabel,
              })}
              placeholder={intl.formatMessage({
                id: PasswordTranslation.repeatPasswordPlaceholder,
              })}
              reverseIcon
              icon={EyeIcon}
              // TODO add FormInputWithIcon and use here
              component={FormInput}
            />
          </RegistrationField>

          <RegistrationFormControls>
            <Button
              type="submit"
              data-testid="submit-button"
              disabled={submitting || !isValid || !dirty}
              size={ButtonSize.md}
              mode={ButtonMode.primary}
              fullWidth>
              <FormattedMessage id={submitButtonMessageId} />
            </Button>
          </RegistrationFormControls>
        </Form>
      )}
    </Formik>
  );
};
