import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ChatHeader,
  ChatHeaderDivider,
  StyledRedirectToDesktopButton,
} from '../../ChatHeader';
import { ParticipantAvatars } from './ParticipantAvatars';
import {
  CallIcon,
  DeleteIcon,
  EditIcon,
  LogoutIcon,
  SearchIcon,
  TeamIcon,
  VideoCallIcon,
} from '../../../../shared/icons';
import {
  ConferenceCallType,
  ConferenceCreateBy,
} from '../../../Conference/Conference.types';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { ConversationType } from '../../Chat.types';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  MenuSeparator,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { ChatTranslation } from '../../i18n';
import { useModalControls } from '../../../../shared/components/Modal/Modal.hooks';
import { RenameChatModal } from '../../RenameChatModal';
import { ManageUsersModal } from '../../ManageUsersModal';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { ConversationAvatar } from '../../ChatSegment/ChatNavigator/ConversationList/ConversationListItem/ConversationAvatar';
import {
  ConversationHeaderTitle,
  ConversationTitle,
  TitleBarAvatar,
  TitleBarControls,
  TitleBarDivider,
  TitleBarTitle,
  TitleBarTitleButtonWrapper,
  TitleBarTitleContainer,
} from '../../../Segment/SegmentPageTitleBar';
import {
  NavigatorActionButton,
  StyledRedirectToDesktopButtonContainer,
} from '../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { TABS } from '../../../GlobalSearch/ExpandedSearch/ExpandedSearch.constants';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import {
  useChatConversationTitle,
  useCurrentConversation,
} from '../../Chat.hooks';
import { useConfirm } from '../../../../shared/components/Modal';
import { getShortId } from '../../../../shared/utils/id';
import { generatePath, useNavigate } from 'react-router-dom';
import { DESKTOP_ID_PATHNAME } from '../../../Desktop/Desktop.constants';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { openConferenceCreateWindow } from '../../../Conference/Conference.utils';
import { CallAllMembersList } from '../../../Desktop/ShareDesktopPopover/CallAllMembersList';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { useAccountsContext } from '../../../Account';
import { useAccountAvailableForCall } from '../../../Conference/Conference.hooks';
import { useChatConversationRepository } from '../../Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { useGlobalSearchContext } from '../../../GlobalSearch/GlobalSearch.hooks';

export const ConversationHeader: FC = () => {
  const isMobile = useMobile();
  const editChatButtonRef = useRef<HTMLButtonElement>(null);
  const { leaveConversation, removeConversation } =
    useChatConversationRepository();

  const { setIsFocused, setSelectedTab, setSearchOnlyByConversation } =
    useGlobalSearchContext();
  const { account } = useCurrentWorkspaceAccount();
  const { permissions } = useCurrentWorkspacePermissions();
  const { accountsWithAvailability } = useAccountsContext();
  const { conversation } = useCurrentConversation();
  const { formatMessage } = useIntl();
  const renameChatModalControls = useModalControls();
  const manageUsersModalControls = useModalControls();
  const { askConfirmation } = useConfirm();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const conversationTitle = useChatConversationTitle(conversation);
  const currentDesktop = useCurrentDesktop();
  const aloneUserInChat = conversation?.userIds.length === 1;

  const [isCallButtonDisabled, setCallButtonDisabled] = useState(false);

  const participants = useMemo(() => {
    if (conversation?.userIds.length && accountsWithAvailability) {
      return conversation.userIds
        .map(id => accountsWithAvailability[id])
        .filter(Boolean)
        .filter(account => account.workspaceCache?.[account.id]?.active);
    }

    return [];
  }, [accountsWithAvailability, conversation?.userIds]);

  const privateChatAccountId = useMemo(
    () =>
      conversation?.type === ConversationType.private ||
      conversation?.type === ConversationType.appIntegration
        ? conversation?.userIds.find(id => account.id !== id)
        : undefined,
    [account.id, conversation],
  );

  const privateChatAccount = privateChatAccountId
    ? accountsWithAvailability[privateChatAccountId]
    : undefined;

  const isCurrentAccountAllowedToCall = useMemo(
    () =>
      !participants.find(participant => participant.id === account.id)?.onCall,
    [account, participants],
  );

  const interlocutor = useMemo(
    () =>
      conversation?.type === ConversationType.private
        ? participants.find(participant => participant.id !== account.id)
        : null,
    [account, conversation, participants],
  );

  const displayCallButtons = !(
    (conversation?.type === ConversationType.group ||
      conversation?.type === ConversationType.desktop) &&
    !permissions.canCreateGroupCall
  );

  const availableForCall = useAccountAvailableForCall(interlocutor?.id);

  const isChatEditable =
    (conversation?.type === ConversationType.group &&
      permissions.canUpdateGroupChat) ||
    conversation?.type === ConversationType.pending;

  const isCommunicateFeatureOnly =
    workspace.communicate && !workspace.organizeAndSearch;

  const handleRemoveChatClick = useCallback(() => {
    const chatTitle =
      conversation?.title?.length &&
      conversation.title !== ConversationType.group
        ? conversation.title
        : formatMessage({
            id: ChatTranslation.removeChatModalCaseWithoutChatName,
          });

    askConfirmation(
      formatMessage(
        {
          id: ChatTranslation.removeChatModalTitle,
        },
        {
          chatTitle: chatTitle,
        },
      ),
      '',
      {
        width: 320,
        dangerConfirm: true,
        cancelButtonText: formatMessage({
          id: ChatTranslation.removeChatModalCancelButtonLabel,
        }),
        confirmButtonText: formatMessage({
          id: ChatTranslation.removeChatModalSubmitButtonLabel,
        }),
      },
    ).then(confirm => {
      if (!confirm || !conversation?.id) {
        return;
      }

      removeConversation(conversation.id);
    });
  }, [conversation, formatMessage, askConfirmation, removeConversation]);

  const handleRedirectToDesktop = useCallback(() => {
    const desktopId = getShortId(conversation?.desktopId ?? '');

    navigate({
      pathname: generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId,
      }),
      search: getQueryParamsFrom({
        ...queryParams,
      }),
    });
  }, [navigate, workspace, conversation, queryParams]);

  const handleLeaveChatClick = useCallback(() => {
    const chatTitle =
      conversation?.title && conversation.title !== ConversationType.group
        ? conversation.title
        : formatMessage({
            id: ChatTranslation.leaveChatModalCaseWithoutChatName,
          });

    askConfirmation(
      formatMessage(
        {
          id: ChatTranslation.leaveChatModalTitle,
        },
        {
          chatTitle: chatTitle,
        },
      ),
      '',
      {
        width: 300,
        dangerConfirm: true,
        cancelButtonText: formatMessage({
          id: ChatTranslation.leaveChatModalCancelButtonLabel,
        }),
        confirmButtonText: formatMessage({
          id: ChatTranslation.leaveChatModalSubmitButtonLabel,
        }),
      },
    ).then(confirm => {
      if (!confirm || !conversation?.id) {
        return;
      }

      leaveConversation(conversation.id);
    });
  }, [formatMessage, askConfirmation, leaveConversation, conversation]);

  if (!conversation) {
    return <ChatHeader />;
  }

  const handleOpenConference = (type: ConferenceCallType) => {
    setCallButtonDisabled(true);
    setTimeout(() => setCallButtonDisabled(false), 1000);
    if (conversation.desktopId || conversation.userIds.length > 2) {
      confirmOpenConferenceCreateWindow(type);
    } else {
      handleOpenConferenceCreateWindow(type);
    }
  };

  const handleOpenConferenceCreateWindow = (type: ConferenceCallType) => {
    openConferenceCreateWindow({
      currentAccountId: account.id,
      currentWorkspaceId: workspace.id,
      callType: type,
      createBy: ConferenceCreateBy.conversationId,
      id: conversation.id,
    });
  };

  const confirmOpenConferenceCreateWindow = (type: ConferenceCallType) => {
    const accounts = participants.filter(acc => acc.id !== account.id);

    askConfirmation(
      formatMessage(
        {
          id: DesktopTranslation.createConferenceConfirmationDescription,
        },
        {
          desktopName: conversation?.title,
          countOfUsersForCall: accounts.length,
        },
      ),
      formatMessage(
        {
          id: DesktopTranslation.createConferenceConfirmationTitle,
        },
        {
          desktopName: conversation?.title,
        },
      ),
      {
        disabled: accounts.length === 0,
        confirmButtonText: formatMessage({
          id: DesktopTranslation.callAllMembersButton,
        }),
        width: 360,
        userList: <CallAllMembersList accounts={accounts} />,
      },
    ).then(confirm => {
      if (!confirm) return;
      handleOpenConferenceCreateWindow(type);
    });
  };

  return (
    <>
      <TitleBarTitleContainer>
        <TitleBarAvatar>
          <ConversationAvatar
            account={privateChatAccount}
            conversation={conversation!}
            defaultMargin={false}
          />
        </TitleBarAvatar>
        {isChatEditable ? (
          <>
            <TitleBarTitleButtonWrapper
              data-tooltip-id="global-tooltip"
              data-tooltip-content={conversationTitle}
              data-tooltip-place={TooltipPlace.top}
              ref={editChatButtonRef}
              data-testid="conversation-title">
              <TitleBarTitle withChevron={true}>
                {conversationTitle}
              </TitleBarTitle>
            </TitleBarTitleButtonWrapper>
            <Menu
              trigger={editChatButtonRef}
              width={180}
              vAlign={VerticalAlignment.BOTTOM}
              hAlign={HorizontalAlignment.LEFT}
              data-testid="edit-chat-menu">
              <MenuItem
                onClick={manageUsersModalControls.open}
                icon={() => <TeamIcon width={20} height={20} />}
                data-testid="manage-users-item">
                <FormattedMessage
                  id={ChatTranslation.groupChatMenuManageUsers}
                />
              </MenuItem>
              {conversation.type !== ConversationType.pending && (
                <MenuItem
                  onClick={renameChatModalControls.open}
                  icon={() => <EditIcon width={20} height={20} />}
                  data-testid="rename-chat-item">
                  <FormattedMessage
                    id={ChatTranslation.groupChatMenuRenameChat}
                  />
                </MenuItem>
              )}
              <>
                <MenuSeparator />
                {!aloneUserInChat && (
                  <MenuItem
                    highlightRed
                    onClick={handleLeaveChatClick}
                    icon={() => <LogoutIcon width={20} height={20} />}
                    data-testid="leave-chat-item">
                    <FormattedMessage
                      id={ChatTranslation.leaveChatModalSubmitButtonLabel}
                    />
                  </MenuItem>
                )}
              </>
              {(conversation.createdById === account.id || aloneUserInChat) && (
                <MenuItem
                  highlightRed
                  onClick={handleRemoveChatClick}
                  icon={() => <DeleteIcon width={20} height={20} />}
                  data-testid="delete-chat-item">
                  <FormattedMessage
                    id={ChatTranslation.removeChatModalSubmitButtonLabel}
                  />
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <ConversationHeaderTitle>
            <ConversationTitle>
              <TitleBarTitle>{conversationTitle}</TitleBarTitle>
            </ConversationTitle>
            {conversation?.type === ConversationType.desktop &&
              !isCommunicateFeatureOnly && (
                <>
                  <ChatHeaderDivider />
                  <StyledRedirectToDesktopButtonContainer>
                    <StyledRedirectToDesktopButton
                      data-testid="redirect-to-desktop-from-chat"
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: ChatTranslation.tooltipGoToDesktopButton,
                      })}
                      data-tooltip-place={TooltipPlace.bottom}
                      onClick={handleRedirectToDesktop}
                    />
                  </StyledRedirectToDesktopButtonContainer>
                </>
              )}
          </ConversationHeaderTitle>
        )}
      </TitleBarTitleContainer>
      <TitleBarControls>
        {!isMobile && (
          <ParticipantAvatars
            participants={participants}
            pendingEmails={conversation.pendingEmails}
          />
        )}
        <NavigatorActionButton
          secondaryButton={!!currentDesktop}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: DesktopTranslation.sectionTitlebarFilterMessagePlaceHolder,
          })}
          data-tooltip-place={TooltipPlace.bottom}
          icon={SearchIcon}
          iconSize={currentDesktop ? 16 : 20}
          onClick={() => {
            setIsFocused?.(true);
            setSelectedTab?.(TABS.CHATS);

            if (!!conversation.id) {
              setSearchOnlyByConversation?.(true);
            }
          }}
          data-testid="search-message-btn"
        />
        {displayCallButtons &&
          conversation?.type !== ConversationType.appIntegration && (
            <>
              {!isMobile && <TitleBarDivider />}
              <NavigatorActionButton
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: ChatTranslation.tooltipChatAudioCallButton,
                })}
                data-tooltip-place={TooltipPlace.bottom}
                icon={CallIcon}
                iconSize={20}
                onClick={() => handleOpenConference?.(ConferenceCallType.audio)}
                data-testid="conversation-audio-call-btn"
                disabled={
                  !availableForCall ||
                  isCallButtonDisabled ||
                  !isCurrentAccountAllowedToCall
                }
              />

              <NavigatorActionButton
                data-tooltip-id="global-tooltip"
                data-tooltip-place={TooltipPlace.bottom}
                data-tooltip-content={formatMessage({
                  id: ChatTranslation.tooltipChatVideoCallButton,
                })}
                icon={VideoCallIcon}
                iconSize={20}
                onClick={() => handleOpenConference?.(ConferenceCallType.video)}
                data-testid="conversation-video-call-btn"
                disabled={!availableForCall || !isCurrentAccountAllowedToCall}
              />
            </>
          )}
      </TitleBarControls>
      <RenameChatModal
        visible={renameChatModalControls.visible}
        onRequestClose={renameChatModalControls.close}
      />
      <ManageUsersModal
        visible={manageUsersModalControls.visible}
        onRequestClose={manageUsersModalControls.close}
      />
    </>
  );
};
