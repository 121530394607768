import React, { RefObject, useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { format, formatISO, parseISO } from 'date-fns';
import { useConfirm } from '../../../../shared/components/Modal';
import { ChatTranslation } from '../../i18n';
import { useMenuPopoverControls } from '../../../../shared/components/Menu/MenuPopover';
import { MenuItem } from '../../../../shared/components/Menu';
import type { ChatMessageAssetApiType } from '../../Chat.types';
import {
  ChatItemType,
  ChatTextMessageInternalType,
  MessageType,
} from '../../Chat.types';
import { HrefMenuItem } from './ConversationView.styled';
import {
  getAssetMediaIri,
  getDownloadUrl,
  isVideoMimeType,
} from '../../../Asset';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';
import { appEnv } from '../../../../appEnv';
import {
  FileAssetId,
  ImageAssetId,
  VideoMetaData,
} from '../../../Asset/Asset.types';
import { ChatMessagesTableType } from '../../../Database/ChatMessagesTable/ChatMessagesTable';
import { TODAY_DATE_FORMAT } from './ConversationView.constants';
import { useChatMessageRepository } from '../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import {
  getIDDBChatMessage,
  updateIDDBChatMessage,
} from '../../Data/Repository/ChatMessage/ChatMessageIDDBRepository';
import { FILE_ASSET_ID_PREFIX } from '../../../Asset/Asset.constants';
import { ChatMessageContext } from './ChatMessage/ChatMessage.context';
import { ConversationDraftContext } from '../ConversationDraft/ConversationDraft.context';
import { ChatMessageThreadSidebarContext } from './ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.context';
import {
  CHAT_MESSAGE_ID_PREFIX,
  CHAT_MESSAGE_THREAD_DOM_ID_PREFIX,
} from './ChatMessage/ChatMessage.constants';
import { useQueryParams } from '../../../../shared/hooks';
import { getChatMessageIri } from '../../Chat.utils';

export const useChatMessageMenu = (messageId: string) => {
  const { askConfirmation } = useConfirm();
  const queryParams = useQueryParams();
  const { thread } = queryParams;
  const { formatMessage } = useIntl();
  const { account } = useCurrentWorkspaceAccount();
  const { removeChatMessage, editChatMessage } = useChatMessageRepository();
  const { replyFormVisible, setMessageIsEditing } =
    useContext(ChatMessageContext);
  const { setDraftMessage } = useContext(ConversationDraftContext);
  const { closeThreadSideBar, threadSidebarIsOpen } = useContext(
    ChatMessageThreadSidebarContext,
  );

  const removeMessage = useCallback(
    async (chatItem: ChatTextMessageInternalType) => {
      const chatMessageIdFromQueryParams = thread
        ? getChatMessageIri(thread as string)
        : null;
      const isThreadMessage = chatItem.id.includes(
        CHAT_MESSAGE_THREAD_DOM_ID_PREFIX,
      );

      const cachedMessage = await getIDDBChatMessage(
        chatItem.id.replace(
          CHAT_MESSAGE_THREAD_DOM_ID_PREFIX,
          CHAT_MESSAGE_ID_PREFIX,
        ),
      );

      if (!cachedMessage) {
        return;
      }

      askConfirmation(
        formatMessage({
          id: ChatTranslation.messageDeleteConfirmation,
        }),
      ).then(async confirmed => {
        if (!confirmed) {
          return;
        }

        if (isThreadMessage && !cachedMessage.parentId && threadSidebarIsOpen) {
          closeThreadSideBar();
        }

        if (chatMessageIdFromQueryParams === cachedMessage.id) {
          closeThreadSideBar();
        }

        await removeChatMessage(cachedMessage.id);
      });
    },
    [
      askConfirmation,
      closeThreadSideBar,
      formatMessage,
      removeChatMessage,
      thread,
      threadSidebarIsOpen,
    ],
  );

  const { showMenuPopover, hideMenuPopover } = useMenuPopoverControls();

  const handleEditMessage = useCallback(
    (chatMessage: ChatTextMessageInternalType) => {
      setMessageIsEditing(chatMessage.id, true);

      setDraftMessage(chatMessage.id, chatMessage, chatMessage.conversationId);
    },
    [setMessageIsEditing, setDraftMessage],
  );

  const editMessageMenuItem = useCallback(
    (chatMessage: ChatTextMessageInternalType, messageType?: MessageType) =>
      messageType !== MessageType.Conference &&
      !replyFormVisible(chatMessage.id) ? (
        <MenuItem
          closeOnClick
          onClick={() => handleEditMessage(chatMessage)}
          data-testid="edit-message-button">
          <FormattedMessage id={ChatTranslation.messageMenuItemEdit} />
        </MenuItem>
      ) : null,
    [handleEditMessage, replyFormVisible],
  );

  const removeMessageMenuItem = useCallback(
    (chatItem: ChatTextMessageInternalType) => (
      <MenuItem
        highlightRed
        closeOnClick
        onClick={() => {
          removeMessage(chatItem);
        }}
        data-testid="remove-message-button">
        <FormattedMessage id={ChatTranslation.messageMenuItemDelete} />
      </MenuItem>
    ),
    [removeMessage],
  );

  const showChatMessageMenu = useCallback(
    (
      chatItem: ChatTextMessageInternalType,
      domRef: RefObject<HTMLElement>,
      messageType?: MessageType,
    ) => {
      showMenuPopover(
        <>
          {editMessageMenuItem(chatItem, messageType)}
          {removeMessageMenuItem(chatItem)}
        </>,
        domRef,
      );
    },
    [editMessageMenuItem, removeMessageMenuItem, showMenuPopover],
  );

  const cancelMessageEditing = useCallback(() => {
    setMessageIsEditing(messageId, false);
  }, [messageId, setMessageIsEditing]);

  const updateChatMessageHook = useCallback(
    async (message: string, assets: ChatMessageAssetApiType[]) => {
      editChatMessage(messageId, message, assets);

      cancelMessageEditing();
    },
    [cancelMessageEditing, editChatMessage, messageId],
  );

  const removeMessageAsset = useCallback(
    async (
      chatMessage: ChatTextMessageInternalType,
      assetIri: FileAssetId | ImageAssetId,
    ) => {
      const confirmed = await askConfirmation(
        formatMessage({
          id: ChatTranslation.messageAssetDeleteConfirmation,
        }),
      );

      if (!confirmed || !chatMessage.context.assets) {
        return;
      }

      const assets: ChatMessageAssetApiType[] = chatMessage.context.assets
        .filter(asset => asset.assetIri !== assetIri)
        .filter(asset => {
          const originalAssetId = assetIri.replace(FILE_ASSET_ID_PREFIX, '');
          return (
            originalAssetId !==
            (asset.metadata as VideoMetaData)?.original_asset_id
          );
        });

      if (!assets.length && !chatMessage.message) {
        return removeChatMessage(chatMessage.id);
      }

      const newIddbMessage = {
        ...chatMessage,
        context: {
          assets,
        },
      };

      updateIDDBChatMessage(chatMessage.id, newIddbMessage);

      return await editChatMessage(chatMessage.id, chatMessage.message, assets);
    },
    [askConfirmation, editChatMessage, formatMessage, removeChatMessage],
  );

  const showChatMessageAssetMenu = useCallback(
    (
      chatMessage: ChatTextMessageInternalType,
      asset: ChatMessageAssetApiType,
      domRef: RefObject<HTMLElement>,
    ) => {
      if (!asset) {
        return;
      }

      const compressedVideoAssetMetadata = chatMessage.context.assets?.find(
        chatMessageAsset =>
          isVideoMimeType(asset.mimeType) &&
          getAssetMediaIri(
            (chatMessageAsset.metadata as VideoMetaData).original_asset_id,
          ) === asset.assetIri &&
          (chatMessageAsset.metadata as VideoMetaData)?.is_preview,
      );

      const originalVideoAssetMetadata = chatMessage.context.assets?.find(
        chatMessageAsset =>
          isVideoMimeType(asset.mimeType) &&
          chatMessageAsset.assetIri === asset.assetIri &&
          !(chatMessageAsset.metadata as VideoMetaData)?.is_preview,
      );

      showMenuPopover(
        <>
          {!isVideoMimeType(asset.mimeType) && (
            <MenuItem
              renderContent={() => (
                <HrefMenuItem
                  href={getDownloadUrl(asset.url)}
                  target="_self"
                  download={asset.filename}
                  onClick={hideMenuPopover}
                  rel="noopener noreferrer">
                  <FormattedMessage
                    id={ChatTranslation.messageAssetMenuItemDownload}
                  />
                </HrefMenuItem>
              )}
            />
          )}
          {originalVideoAssetMetadata && (
            <MenuItem
              renderContent={() => (
                <HrefMenuItem
                  href={getDownloadUrl(originalVideoAssetMetadata!.url)}
                  target="_self"
                  download={originalVideoAssetMetadata!.filename}
                  onClick={hideMenuPopover}
                  rel="noopener noreferrer">
                  <FormattedMessage
                    id={ChatTranslation.messageAssetMenuItemDownloadOriginal}
                  />
                </HrefMenuItem>
              )}
            />
          )}

          {compressedVideoAssetMetadata && (
            <MenuItem
              renderContent={() => (
                <HrefMenuItem
                  href={getDownloadUrl(compressedVideoAssetMetadata!.url)}
                  target="_self"
                  download={compressedVideoAssetMetadata!.filename}
                  onClick={hideMenuPopover}
                  rel="noopener noreferrer">
                  <FormattedMessage
                    id={ChatTranslation.messageAssetMenuItemDownloadCompressed}
                  />
                </HrefMenuItem>
              )}
            />
          )}

          {/* TODO: COPY LINK DISABLED FOR RELEASE R21-03 */}
          {/* <MenuItem
            closeOnClick
            onClick={() => copyLinkToClipboard(`https:${asset.url}`)}>
            <FormattedMessage id={ChatTranslation.messageMenuItemCopyLink} />
          </MenuItem> */}

          {account?.id === chatMessage.authorId && (
            <MenuItem
              highlightRed
              closeOnClick
              onClick={() => removeMessageAsset(chatMessage, asset.assetIri)}>
              <FormattedMessage
                id={ChatTranslation.messageAssetMenuItemDelete}
              />
            </MenuItem>
          )}
        </>,
        domRef,
      );
    },
    [account?.id, removeMessageAsset, hideMenuPopover, showMenuPopover],
  );

  return {
    showChatMessageMenu,
    cancelMessageEditing,
    updateChatMessageHook,
    showChatMessageAssetMenu,
  };
};

export const useChatItems = (messages: ChatMessagesTableType[]) =>
  useMemo(
    () =>
      messages.reduce<{
        lastDate: string;
        lastTime: string;
        lastAccountId: string;
        lastThreadMessagesCount: number;
        lastMessageSeen: boolean;
        data: ChatItemType[];
      }>(
        (acc, item) => {
          const { data } = item;
          const itemDate = parseISO(
            formatISO(
              typeof item.createdAt === typeof Date
                ? item.createdAt
                : new Date(item.createdAt),
            ),
          );
          const time = format(itemDate, appEnv.TIME_FORMAT);
          const date = format(itemDate, 'yyyy-MM-dd');
          const accountId = data.authorId ? data.authorId : MessageType.System;
          const isMessageSeen = data.isSeen;

          return {
            lastTime: time,
            lastDate: date,
            lastAccountId: accountId,
            lastThreadMessagesCount: data.threadMessagesCount,
            lastMessageSeen: isMessageSeen,
            data: [
              ...acc.data,
              ...(date !== acc.lastDate
                ? [{ dateDelimiter: format(itemDate, TODAY_DATE_FORMAT) }]
                : []),
              {
                ...data,
                secondary:
                  !isMessageSeen && acc.lastMessageSeen
                    ? false
                    : data.threadMessagesCount === 0 &&
                      acc.lastThreadMessagesCount === 0 &&
                      accountId === acc.lastAccountId &&
                      time === acc.lastTime,
              },
            ],
          };
        },
        {
          lastDate: '',
          lastTime: '',
          lastAccountId: '',
          lastThreadMessagesCount: 0,
          lastMessageSeen: true,
          data: [],
        },
      ),
    [messages],
  );
