import React from 'react';
import { flatPolyfill, fromEntriesPolyfill } from './polyfill';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'normalize.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css';
import { showReloadAppToast } from './shared/components/Toast';
import { initErrorReporting } from './domains/ErrorInterceptor';
import { createRoot } from 'react-dom/client';
import App from './App';

flatPolyfill();
fromEntriesPolyfill();

initErrorReporting();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

if ('serviceWorker' in navigator) {
  serviceWorkerRegistration.register({
    onUpdate: () => {
      if (window.location.href.includes(process.env.DEFAULT_APP_HOST)) {
        showReloadAppToast();
      }
    },
  });
}
