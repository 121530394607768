import styled, { css } from 'styled-components';

const truncate = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledUserListItem = styled.div`
  display: flex;
  padding: 0 1rem;
`;

export const StyledDetails = styled.div`
  width: 40%;
  flex: 1 1 100%;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    width: 250px;
    flex: 1 0 auto;
  `}
`;

export const StyledTeams = styled.div`
  ${truncate};
  flex: 1;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledDesktops = styled.div`
  ${truncate};
  flex: 1;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledInformation = styled.div`
  padding: 0 13px;
  flex: 1 1 100%;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledName = styled.h4`
  ${truncate};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 20px;
`;

export const StyledBadges = styled.div`
  margin-left: 0.5rem;
  margin-top: -0.25rem;
`;

export const StyledEmail = styled.p`
  ${truncate};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 14px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const StyledItemControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledGroup = styled.div`
  flex: 1;
`;
