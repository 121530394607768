import styled from 'styled-components';

export const StyledWorkspaceSwitcherSkeleton = styled.div`
  grid-area: workspaces;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.Background};

  * {
    display: block;
  }

  .body {
    padding: 0.5rem;
    flex-grow: 1;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem;
  }
`;
