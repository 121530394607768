import React, { FC, useCallback, useReducer, useState } from 'react';
import { ListItemContent } from '../../../../shared/components/List';
import { Checkbox } from '../../../../shared/components/Checkbox';
import { DesktopsList } from './RelatedDesktopsForm.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../i18n';
import { getShortId } from '../../../../shared/utils/id';
import type { DesktopApiType } from '../../data/Desktop/types/Desktop.types';

interface DesktopSelectProps {
  desktops: DesktopApiType[];
  relatedDesktops: DesktopApiType[];
  deletableRelatedDesktopsMap?: { [key: string]: boolean };
  onSubmit: (desktops: DesktopApiType[]) => void;
}

export const RelatedDesktopsForm: FC<DesktopSelectProps> = ({
  desktops,
  relatedDesktops,
  deletableRelatedDesktopsMap,
  onSubmit,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const [desktopCheckboxesMap, toggleDesktopCheckbox] = useReducer(
    (state: { [key: string]: boolean }, id: string) => {
      return { ...state, [id]: !state[id] };
    },
    relatedDesktops.reduce(
      (acc, current) => ({ ...acc, [getShortId(current.id)]: true }),
      {},
    ),
  );

  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    const selectedDesktops = desktops.filter(
      desktop => desktopCheckboxesMap[getShortId(desktop.id)],
    );

    onSubmit(selectedDesktops);
  }, [desktopCheckboxesMap, desktops, onSubmit]);

  const handleSelectListItemClick = useCallback(
    (desktopId: string) => () => {
      toggleDesktopCheckbox(desktopId);
    },
    [],
  );

  return (
    <div>
      <DesktopsList data-testid="desktop-select-list">
        {desktops.map(
          desktop =>
            // TODO: remove this check when cascade deletion will be finished in API
            desktop && (
              <li data-testid="desktop-select-list-item" key={desktop.id}>
                <ListItemContent
                  title={desktop.name}
                  subtitle={desktop.description}
                  spaced={false}>
                  <Checkbox
                    data-testid="desktop-select-list-item-checkbox"
                    size={32}
                    onChange={handleSelectListItemClick(getShortId(desktop.id))}
                    checked={!!desktopCheckboxesMap[getShortId(desktop.id)]}
                    disabled={
                      deletableRelatedDesktopsMap &&
                      deletableRelatedDesktopsMap.hasOwnProperty(desktop.id) &&
                      !deletableRelatedDesktopsMap[desktop.id]
                    }
                  />
                </ListItemContent>
              </li>
            ),
        )}
      </DesktopsList>

      <Button
        disabled={submitted}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        onClick={handleSubmit}
        data-testid="desktop-select-submit-button">
        <FormattedMessage id={DesktopTranslation.manageModalUpdateButton} />
      </Button>
    </div>
  );
};
