import styled, { css } from 'styled-components';

export const StyledLinkCheckboxWrapper = styled.div<{ isHidden?: boolean }>`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
  input, input:checked {
    background-color: transparent;
    border: 2px solid white;
  }
`;
