import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const SendIcon: FC<IconProps> = props => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="chat-send-message-icon"
      {...props}>
      <path
        d="M0.339919 12L14.3333 6L0.339919 0L0.333252 4.66667L10.3333 6L0.333252 7.33333L0.339919 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
