import { useLiveQuery } from 'dexie-react-hooks';
import React, { FC, PropsWithChildren } from 'react';
import { database } from '../../../../Database';
import { ChatMessageToChatMessageTableRow } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { useCurrentConversation } from '../../../Chat.hooks';
import type { ChatMessageInternalType } from '../../../Chat.types';
import { ChatMessageThreadContext } from './ChatMessageThread.context';
import { useThreadMessageAuthors } from './ChatMessageThread.hooks';

interface ChatMessageThreadProviderProps extends PropsWithChildren {
  chatMessage: ChatMessageInternalType;
}

export const ChatMessageThreadProvider: FC<ChatMessageThreadProviderProps> = ({
  chatMessage,
  children,
}) => {
  const { conversation } = useCurrentConversation();
  const messageRow = ChatMessageToChatMessageTableRow(chatMessage);

  const threadMessages = useLiveQuery(
    () =>
      database.chatMessages
        .where('parentId')
        .equals(messageRow.data.id)
        .sortBy('createdAt')
        .then(response => {
          if (!conversation?.id) {
            return [];
          }

          return response;
        })
        .catch(() => []),
    [
      conversation?.id,
      messageRow.data.threadMessagesCount,
      messageRow.data.threadMessagesAccounts,
    ],
    [],
  );

  const threadMessageAuthors = useThreadMessageAuthors(
    messageRow.data.threadMessagesAccounts ?? [],
  );

  return (
    <ChatMessageThreadContext.Provider
      value={{
        threadMessages,
        threadMessageAuthors,
        chatMessage,
      }}>
      {children}
    </ChatMessageThreadContext.Provider>
  );
};
