import React, { FC, useCallback, useState } from 'react';
import { useQueryParams } from '../../../shared/hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  StyledDescription,
  StyledTitle,
  StyledUnsubscribePage,
} from './UnsubscribePage.styled';
import { SubscriptionTranslation } from '../i18n';
import { APP_URL } from '../Subscription.constans';
import { useUnsubscribeProductInformationEmailsMutation } from '../Subscription.hooks';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';

export const UnsubscribePage: FC = () => {
  const [
    UnsubscribeProductInformationEmailsMutation,
  ] = useUnsubscribeProductInformationEmailsMutation();
  const queryParams = useQueryParams();
  const { id, token } = queryParams;
  const [unsubscribed, setUnsubscribed] = useState(false);

  const unsubscribeHandleClick = useCallback(() => {
    UnsubscribeProductInformationEmailsMutation({
      variables: {
        input: {
          id: '/account-identities/' + id,
          token: token as string,
        },
      },
    })
      .then(() => setUnsubscribed(true))
      .catch(e => showToastGraphQLErrors(e.graphQLErrors));
  }, [UnsubscribeProductInformationEmailsMutation, id, token]);

  return (
    <StyledUnsubscribePage>
      <StyledTitle>
        <FormattedMessage id={SubscriptionTranslation.unsubscribeTitle} />
      </StyledTitle>
      <StyledDescription>
        {unsubscribed ? (
          <FormattedMessage
            id={SubscriptionTranslation.unsubscribeEmailsSuccessMessage}
          />
        ) : (
          <FormattedHTMLMessage
            id={SubscriptionTranslation.unsubscribePageInfo}
            values={{
              appUrl: APP_URL,
            }}
          />
        )}
      </StyledDescription>
      {!unsubscribed && (
        <Button
          mode={ButtonMode.primary}
          size={ButtonSize.md}
          onClick={unsubscribeHandleClick}>
          <FormattedMessage id={SubscriptionTranslation.unsubscribeTitle} />
        </Button>
      )}
    </StyledUnsubscribePage>
  );
};
