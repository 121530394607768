import React, { FC, useCallback, useMemo, useState } from 'react';
import { UserSelect } from '../../../User/UserSelect';
import { useAccountsContext } from '../../../Account';
import { getListMap } from '../../../../shared/utils/list.utils';
import { getAccountKeyset } from '../../Encryption.utils';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../User/User.types';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { StyledShareVaultForm } from './ShareVaultForm.styled';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import { isAccountActive, isAccountGuest } from '../../../User/User.utils';
import { useCurrentWorkspaceKeysetsToAccountsMap } from '../../Encryption.hooks';

interface ShareVaultProps {
  excludeKeysets: string[];
  onShare: (keysetIds: string[]) => Promise<unknown>;
}

export const ShareVaultForm: FC<ShareVaultProps> = ({
  excludeKeysets,
  onShare,
}) => {
  const [explicitlySharedWithKeysetIds, setExplicitlySharedWithKeysetIds] =
    useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;

  const { account: currentWorkspaceAccount } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability: workspaceAccountsMap } =
    useAccountsContext();
  const accountsWithKeysetsMap = useMemo(() => {
    return getListMap(
      Object.values(workspaceAccountsMap).filter(account =>
        getAccountKeyset(account),
      ),
    );
  }, [workspaceAccountsMap]);
  const keysetsToAccountsMap = useCurrentWorkspaceKeysetsToAccountsMap();

  const selectedAccountIds = useMemo(() => {
    return explicitlySharedWithKeysetIds
      .map(keysetId => keysetsToAccountsMap.get(keysetId)?.id)
      .filter(Boolean) as string[];
  }, [explicitlySharedWithKeysetIds, keysetsToAccountsMap]);

  const filterAccountOptions = useCallback(
    (account: AccountWithCountsApiType) => {
      return (
        account.id !== currentWorkspaceAccount?.id &&
        !excludeKeysets.includes(getAccountKeyset(account)?.id as string) &&
        !!getAccountKeyset(account) &&
        !isAccountGuest(account) &&
        isAccountActive(account, workspaceId)
      );
    },
    [currentWorkspaceAccount, excludeKeysets, workspaceId],
  );

  const handleSelect = useCallback(
    (accountIds: string[]) => {
      const shareWithKeysets = accountIds
        .map(
          userId =>
            getAccountKeyset(
              accountsWithKeysetsMap.get(userId) as AccountApiType,
            )?.id,
        )
        .filter(Boolean) as string[];

      setExplicitlySharedWithKeysetIds(shareWithKeysets);
    },
    [accountsWithKeysetsMap],
  );

  const handleShareClick = useCallback(() => {
    setProcessing(true);
    onShare(explicitlySharedWithKeysetIds)
      .then(() => setExplicitlySharedWithKeysetIds([]))
      .finally(() => setProcessing(false));
  }, [onShare, explicitlySharedWithKeysetIds]);

  return (
    <StyledShareVaultForm>
      <UserSelect
        selectedAccountIds={selectedAccountIds}
        filterAccountOptions={filterAccountOptions}
        disabled={processing}
        onSelect={handleSelect}
      />
      <Button
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        disabled={processing}
        onClick={handleShareClick}
        data-testid="share-button">
        <FormattedMessage
          id={EncryptionTranslation.sharePasswordsFormShareButton}
        />
      </Button>
    </StyledShareVaultForm>
  );
};
