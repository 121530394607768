import React, { FC } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { generatePath, Navigate } from 'react-router-dom';
import { SignUpStep } from '../../Onboarding.types';
import { InviteUsers } from '../../../User/UserForms/InviteUsersForm';
import { useChangeOnboardingStepAccountMutation } from '../../Onboarding.hooks';
import {
  INSTALL_PLUGIN_PATHNAME,
  ONBOARDING_END_PATHNAME,
} from '../../Onboarding.constants';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const InviteUsersPage: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const { account, refetchAccountData } = useCurrentAccount();

  const [changeOnboardingStepAccountMutation] =
    useChangeOnboardingStepAccountMutation();

  const handleUsersInvited = () => {
    return changeOnboardingStepAccountMutation({
      variables: {
        input: {
          onboardingStep: !workspace.organizeAndSearch
            ? SignUpStep.completed
            : SignUpStep.install_extension,
          ...(!workspace.organizeAndSearch ? { force: true } : {}),
        },
      },
    }).then(() => refetchAccountData?.());
  };

  if (account?.currentStep === SignUpStep.install_extension) {
    return (
      <Navigate
        to={{
          pathname: generatePath(INSTALL_PLUGIN_PATHNAME, {
            workspaceId: getShortId(workspace.id),
          }),
        }}
      />
    );
  }

  if (
    !workspace.organizeAndSearch &&
    account?.currentStep === SignUpStep.completed
  ) {
    const path = generatePath(ONBOARDING_END_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={path} />;
  }

  if (account?.currentStep !== SignUpStep.invite_users) {
    return <Navigate to={`/workspace/${getShortId(workspace.id)}`} />;
  }

  return (
    <>
      <HeadTitle translationId={OnboardingTranslation.inviteUsersPageTitle} />
      <OnboardingPage horizontallyCentered>
        <OnboardingStep data-testid="invite-users-step">
          <OnboardingStepsCounter currentStep={account?.currentStep} />
          <OnboardingStepHeader data-testid="header">
            <FormattedMessage
              id={OnboardingTranslation.inviteUsersPageHeading}
            />
          </OnboardingStepHeader>

          <OnboardingStepText data-testid="description">
            <FormattedMessage
              id={OnboardingTranslation.inviteUsersPageDescription}
            />
          </OnboardingStepText>

          <InviteUsers
            onDone={handleUsersInvited}
            onSkip={handleUsersInvited}
            inviteUsersButtonText={OnboardingTranslation.inviteUserInviteButton}
          />
        </OnboardingStep>
      </OnboardingPage>
    </>
  );
};
