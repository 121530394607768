import React, { FC, RefObject, useCallback, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ConversationSelect } from '../ConversationSelect';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  useChatConversationInvitations,
  useGroupedSortedAccounts,
} from '../../Chat.hooks';
import { UserListItem } from './UserListItem';
import { MenuItem } from '../../../../shared/components/Menu';
import { ChatTranslation } from '../../i18n';
import { useMenuPopoverControls } from '../../../../shared/components/Menu/MenuPopover';
import { AccountOrEmail } from './ConversationUsers.types';
import {
  FormControls,
  ImportantMessageContainer,
  NewConversationTitleContainer,
  TitleInput,
  TitleMaximumSymbolsError,
  UserList,
} from './ConversationUsers.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { removeChatInvitationFromChatInvitationsCache } from '../../cache/Chat.cache';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { ChatConversationInternalType } from '../../Chat.types';
import { useConfirm } from '../../../../shared/components/Modal';
import { useAccountsContext } from '../../../Account';
import { AccountWithCountsApiType } from '../../../User/User.types';
import { extractNodes } from '../../../../shared/api/api.utils';
import { useRevokeInvitationMutation } from '../../../User/User.hooks';
import { UserTranslation } from '../../../User/i18n';
import { GRAPHQL_TYPENAME_MUTATION } from '../../../../shared/api/api.constants';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { appEnv } from '../../../../appEnv';
import { JOIN_CHAT_PATHNAME } from '../../../Onboarding/Onboarding.constants';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { getShortId } from '../../../../shared/utils/id';
import { PaymentPlan } from '../../../Billing/Billing.types';
import { useNativeWrapper } from '../../../NativeWrapper/NativeWrapper.hooks';
import { useChatConversationRepository } from '../../Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { MAXIMUM_CHARACTERS_IN_TITLE } from '../../Chat.constants';

interface ConversationUsersProps {
  chatConversation?: ChatConversationInternalType;
  onCancel: () => void;
  onDone: (chatConversationId: string) => void;
}

// TODO: rework component structure, add Formik
export const ConversationUsers: FC<ConversationUsersProps> = ({
  chatConversation,
  onCancel,
  onDone,
}) => {
  const intl = useIntl();
  const { isNativeWrapperAvailable } = useNativeWrapper();

  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();

  const allAccounts = useGroupedSortedAccounts();
  const { accountsWithAvailability } = useAccountsContext();
  const [processing, setProcessing] = useState(false);
  const [participants, setParticipants] = useState<AccountOrEmail[]>([]);
  const [title, setTitle] = useState('');

  const {
    createConversation,
    inviteConversationGuest,
    addConversationParticipant,
    removeConversationParticipant,
    createPendingConversation,
  } = useChatConversationRepository();

  const { data: invitationsData } = useChatConversationInvitations({
    skip: !chatConversation,
    variables: {
      chatConversationIri: chatConversation?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const invitationsMap = useMemo(
    () =>
      extractNodes(invitationsData?.workspaceAccountInvitations).reduce<
        Map<string, string>
      >((acc, item) => {
        const email: string | undefined = item.recipients[0]?.email;
        if (email) {
          acc.set(email, item.id);
        }
        return acc;
      }, new Map()),
    [invitationsData?.workspaceAccountInvitations],
  );

  const chatParticipants = useMemo(
    () =>
      chatConversation
        ? chatConversation.userIds
            .map(userId =>
              userId !== account.id
                ? accountsWithAvailability[userId]
                : undefined,
            )
            .filter((account): account is AccountWithCountsApiType =>
              Boolean(account),
            )
        : null,
    [account.id, accountsWithAvailability, chatConversation],
  );

  const canSubmit =
    title.length <= MAXIMUM_CHARACTERS_IN_TITLE &&
    participants.length > 0 &&
    !processing;

  const { participantIds, emails } = useMemo(
    () =>
      chatParticipants
        ? {
            participantIds: chatParticipants.map(({ id }) => id),
            emails: [] as string[],
          }
        : participants.reduce<{
            participantIds: string[];
            emails: string[];
          }>(
            (acc, item) => {
              if (typeof item === 'string') {
                acc.emails.push(item);
              } else {
                acc.participantIds.push(item.id);
              }
              return acc;
            },
            {
              participantIds: [],
              emails: [],
            },
          ),
    [chatParticipants, participants],
  );
  const shouldSendTitle =
    participantIds.length + emails.length > 1 && !chatConversation;

  const groupedAccounts = useMemo(
    () =>
      allAccounts.map(group => ({
        ...group,
        options: group.options.filter(({ id }) => !participantIds.includes(id)),
      })),
    [allAccounts, participantIds],
  );

  const inviteGuestToChatConversation = useCallback(
    (id: string, email: string) => {
      return inviteConversationGuest(id, email);
    },
    [inviteConversationGuest],
  );

  const addMemberToChatConversation = useCallback(
    (participantId: string) => {
      if (!chatConversation) {
        return Promise.reject();
      }

      return addConversationParticipant(chatConversation.id, participantId);
    },
    [addConversationParticipant, chatConversation],
  );

  const removeMemberFromChatConversation = useCallback(
    (participantId: string) => {
      if (!chatConversation) {
        return Promise.reject();
      }

      return removeConversationParticipant(chatConversation.id, participantId);
    },
    [chatConversation, removeConversationParticipant],
  );

  const addUser = useCallback(
    async (accountOrEmail: AccountOrEmail) => {
      if (!chatConversation) {
        setParticipants(prevState => {
          if (
            typeof accountOrEmail === 'string' &&
            prevState.includes(accountOrEmail)
          ) {
            return prevState;
          }
          return [...prevState, accountOrEmail];
        });
        return;
      }

      setProcessing(true);

      if (typeof accountOrEmail === 'string') {
        await inviteGuestToChatConversation(
          chatConversation.id,
          accountOrEmail,
        );
        setProcessing(false);
        return;
      }

      await addMemberToChatConversation(accountOrEmail.id);
      setProcessing(false);
    },
    [
      addMemberToChatConversation,
      chatConversation,
      inviteGuestToChatConversation,
    ],
  );

  const { askConfirmation } = useConfirm();

  const removeUser = useCallback(
    async (accountOrEmail: AccountOrEmail) => {
      if (!chatConversation) {
        setParticipants(prevState =>
          prevState.filter(item => item !== accountOrEmail),
        );
        return;
      }

      const canRemove = await askConfirmation(
        intl.formatMessage({
          id: ChatTranslation.conversationUsersMenuRemoveConfirmation,
        }),
      );

      if (!canRemove) {
        return;
      }

      if (typeof accountOrEmail === 'string') {
        // this should never happen
        return;
      }

      setProcessing(true);
      await removeMemberFromChatConversation(accountOrEmail.id);
      setProcessing(false);
    },
    [askConfirmation, chatConversation, intl, removeMemberFromChatConversation],
  );

  const { showMenuPopover } = useMenuPopoverControls();

  const showUserMenu = useCallback(
    (accountOrEmail: AccountOrEmail, domRef: RefObject<HTMLElement>) => {
      showMenuPopover(
        <MenuItem
          highlightRed
          closeOnClick
          onClick={() => {
            removeUser(accountOrEmail);
          }}
          data-testid="remove-item">
          <FormattedMessage
            id={ChatTranslation.conversationUsersMenuRemoveFromChat}
          />
        </MenuItem>,
        domRef,
      );
    },
    [removeUser, showMenuPopover],
  );

  const [revokeInvitationMutation] = useRevokeInvitationMutation();
  const revokeInvitation = useCallback(
    async (invitationId: string) => {
      const canRevoke = await askConfirmation(
        intl.formatMessage({
          id: UserTranslation.adminDangerZoneRevokeConfirm,
        }),
      );

      if (!canRevoke) {
        return;
      }

      setProcessing(true);
      await revokeInvitationMutation({
        variables: {
          input: {
            id: invitationId,
          },
        },
        optimisticResponse: {
          __typename: GRAPHQL_TYPENAME_MUTATION,
          deleteAccountInvitation: {
            __typename: 'deleteAccountInvitationPayload',
            accountInvitation: {
              __typename: 'deleteAccountInvitationPayloadData',
              id: invitationId,
            },
          },
        },
        update: (proxy, { data }) => {
          if (!data) {
            return;
          }
          removeChatInvitationFromChatInvitationsCache(
            proxy,
            {
              chatConversationIri: chatConversation?.id,
            },
            data.deleteAccountInvitation.accountInvitation.id,
          );
        },
      }).catch(err => showToastGraphQLErrors(err.graphQLErrors));
      setProcessing(false);
    },
    [askConfirmation, intl, revokeInvitationMutation, chatConversation?.id],
  );

  const copyInvitationUrl = useCallback((invitationId: string) => {
    const invitationUrl = `https://${
      window.location.hostname
    }${JOIN_CHAT_PATHNAME}?${getQueryParamsFrom({
      id: getShortId(invitationId),
    })}`;

    navigator.clipboard.writeText(invitationUrl).then(() => {
      showToastSuccessMessage(
        ChatTranslation.conversationUsersMenuCopyInviteLinkSuccessMessage,
      );
    });
  }, []);

  const showInvitationMenu = useCallback(
    (
      accountOrEmail: AccountOrEmail,
      domRef: RefObject<HTMLElement>,
      invitationId?: string,
    ) => {
      if (!invitationId) {
        return;
      }

      showMenuPopover(
        <>
          <MenuItem
            highlightRed
            closeOnClick
            data-testid="revoke-invitation"
            onClick={() => {
              revokeInvitation(invitationId);
            }}>
            <FormattedMessage
              id={ChatTranslation.conversationUsersMenuRevokeInvitation}
            />
          </MenuItem>
          <MenuItem
            closeOnClick
            onClick={() => copyInvitationUrl(invitationId)}
            data-testid="copy-invite-link">
            <FormattedMessage
              id={ChatTranslation.conversationUsersMenuCopyInviteLink}
            />
          </MenuItem>
        </>,
        domRef,
      );
    },
    [copyInvitationUrl, revokeInvitation, showMenuPopover],
  );

  const createChatConversation = useCallback(() => {
    setProcessing(true);
    if (emails.length) {
      return createPendingConversation(
        [account.id, ...participantIds],
        emails,
        shouldSendTitle ? title : undefined,
      ).then(conversationId => {
        if (conversationId) {
          onDone(conversationId);
        }
      });
    }

    return createConversation(
      [account.id, ...participantIds],
      shouldSendTitle ? title : undefined,
    )
      .then(conversationId => {
        if (conversationId) {
          onDone(conversationId);
        }
      })
      .catch(() => {
        setProcessing(false);
      });
  }, [
    emails,
    createConversation,
    account.id,
    participantIds,
    shouldSendTitle,
    title,
    createPendingConversation,
    onDone,
  ]);

  const titleMaximumSymbolsError = title.length > MAXIMUM_CHARACTERS_IN_TITLE;

  return (
    <>
      {shouldSendTitle && (
        <NewConversationTitleContainer>
          <TitleInput
            placeholder={intl.formatMessage({
              id: ChatTranslation.createConversationTitleFieldPlaceholder,
            })}
            value={title}
            withError={titleMaximumSymbolsError}
            onChange={e => setTitle(e.target.value)}
            data-testid="title-input"
            aria-label={intl.formatMessage({
              id: ChatTranslation.createConversationTitleFieldPlaceholder,
            })}
          />
          {titleMaximumSymbolsError && (
            <TitleMaximumSymbolsError>
              <FormattedMessage
                id={ChatTranslation.chatFormTitleMaximumSymbols}
              />
            </TitleMaximumSymbolsError>
          )}
        </NewConversationTitleContainer>
      )}

      <ConversationSelect
        isDisabled={processing}
        groupedAccounts={groupedAccounts}
        onSelect={addUser}
      />
      {!isNativeWrapperAvailable && (
        <ImportantMessageContainer>
          <ImportantMessage
            type={ImportantMessageType.INFO}
            withIcon={true}
            circleIcon>
            <FormattedHTMLMessage
              id={ChatTranslation.createConversationImportantMessage}
              values={{
                learnMoreUrl: appEnv.SUPPORT_INVITE_TO_CHAT_URL,
                proFeatureLabel:
                  workspace.type === PaymentPlan.FREE
                    ? ' ' +
                      intl.formatMessage({
                        id: ChatTranslation.createConversationImportantMessageProFeatureLabel,
                      })
                    : '',
              }}
            />
          </ImportantMessage>
        </ImportantMessageContainer>
      )}

      <UserList data-testid="added-user-list">
        {chatConversation?.pendingEmails.map(email => {
          const invitationId = invitationsMap.get(email);
          return (
            <UserListItem
              key={email}
              email={email}
              invitationId={invitationId}
              onMenu={invitationId ? showInvitationMenu : undefined}
            />
          );
        })}
        {(chatParticipants || participants).map(accountOrEmail => (
          <UserListItem
            {...(typeof accountOrEmail === 'string'
              ? { key: accountOrEmail, email: accountOrEmail }
              : {
                  key: accountOrEmail.id,
                  account: accountOrEmail,
                })}
            onMenu={showUserMenu}
          />
        ))}
      </UserList>
      <FormControls>
        {chatConversation ? (
          <Button
            data-testid="close-button"
            type="button"
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={onCancel}
            disabled={processing}>
            <FormattedMessage
              id={ChatTranslation.conversationUsersCloseButton}
            />
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              type="button"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              onClick={onCancel}>
              <FormattedMessage
                id={ChatTranslation.cancelCreateConversationButton}
              />
            </Button>
            <Button
              data-testid="submit-button"
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={!canSubmit}
              onClick={createChatConversation}>
              <FormattedMessage id={ChatTranslation.createConversationButton} />
            </Button>
          </>
        )}
      </FormControls>
    </>
  );
};
