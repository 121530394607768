import React, { FC, MutableRefObject, useCallback, useRef } from 'react';
import {
  StyledItem,
  StyledItemBody,
  StyledItemType,
  StyledMenuItem,
} from './SearchItems.styled';
import { GlobalSearchResultLinkHit } from '../../../GlobalSearch.queries';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { Menu } from '../../../../../shared/components/Menu';
import {
  CopyIcon,
  DotsIcon,
  FolderIcon,
  InformationIcon,
} from '../../../../../shared/icons';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { showToastSuccessMessage } from '../../../../../shared/components/Toast';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  getQueryParamsFrom,
  openUrl,
} from '../../../../../shared/utils/url.utils';
import { getShortId } from '../../../../../shared/utils/id';
import {
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopTab,
} from '../../../../Desktop/Desktop.constants';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { OpenType } from '../../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { LinkDetailsTab } from '../../../../Link/Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useQueryParams } from '../../../../../shared/hooks';
import { useCurrentDesktopContent } from '../../../../Desktop/Desktop.hooks';

export const LinkItem: FC<
  GlobalSearchResultLinkHit & { skipBlurRef: MutableRefObject<boolean> }
> = ({ customTitle, data, desktop, folder, id, skipBlurRef }) => {
  const queryParams = useQueryParams();
  const { account } = useCurrentAccount();
  const openType = account?.identity.openLink || OpenType.newTab;
  const { workspace } = useCurrentWorkspace();
  const { setDesktopLayout } = useCurrentDesktopContent();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleLinkClick = useCallback(() => {
    openUrl(openType, data.url, data.url);
  }, [openType, data]);

  const handleButtonClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(data.url).then(() => {
      showToastSuccessMessage(
        GlobalSearchTranslation.expandedSearchLinkWasCopied,
      );
    });
  }, [data]);

  const handleOpenLinkDetails = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        linkDetails: getShortId(id),
        linkDetailsTab: LinkDetailsTab.DETAILS,
        chatLink: undefined,
      }),
    });
  }, [navigate, queryParams, id]);

  const handleFolderClick = useCallback(() => {
    setDesktopLayout(DesktopLayout.TABBED);
    if (folder) {
      navigate({
        pathname: generatePath(DESKTOP_TAB_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          desktopId: getShortId(desktop.id),
          tab: DesktopTab.links,
        }),
        search: getQueryParamsFrom({
          ...queryParams,
          folderId: getShortId(folder.id),
        }),
      });
    } else {
      navigate({
        pathname: generatePath(DESKTOP_TAB_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          desktopId: getShortId(desktop.id),
          tab: DesktopTab.links,
        }),
        search: getQueryParamsFrom({
          ...queryParams,
          folderId: undefined,
        }),
      });
    }
  }, [
    desktop.id,
    folder,
    navigate,
    queryParams,
    setDesktopLayout,
    workspace.id,
  ]);

  return (
    <StyledItem onClick={handleLinkClick}>
      <StyledItemBody>
        <img src={data.icon?.contentUrl} alt="" />
        <span>
          {customTitle ||
            data.metadata?.name ||
            data.title ||
            data.name ||
            data.url}
        </span>
        <ButtonWithIcon
          ref={buttonRef}
          icon={DotsIcon}
          onClick={handleButtonClick}
        />
        <Menu
          trigger={buttonRef}
          width={180}
          onOpen={() => (skipBlurRef.current = true)}
          onClose={() => (skipBlurRef.current = false)}>
          <StyledMenuItem onClick={handleCopyLink}>
            <CopyIcon />
            <FormattedMessage
              tagName="span"
              id={GlobalSearchTranslation.expandedSearchCopyLink}
            />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleOpenLinkDetails}>
            <InformationIcon />
            <FormattedMessage
              tagName="span"
              id={GlobalSearchTranslation.expandedSearchLinkDetails}
            />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleFolderClick}>
            <FolderIcon />
            <FormattedMessage
              tagName="span"
              id={GlobalSearchTranslation.expandedSearchOpenLinkLocation}
            />
          </StyledMenuItem>
        </Menu>
      </StyledItemBody>
      <StyledItemType>
        <FormattedMessage id={GlobalSearchTranslation.expandedSearchLinkType} />
      </StyledItemType>
    </StyledItem>
  );
};
