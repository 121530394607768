import React, { FC, useCallback, useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import {
  AccountDetails,
  ButtonsContainer,
  GroupTagContainer,
  InCallBadge,
  InfoItem,
  InfoItemContent,
  InfoItemLabel,
  InfoItems,
  ManageUserContainer,
  StyledAccountName,
  StyledAccountPopover,
} from './AccountPopover.styled';
import {
  AccountOrigin,
  AccountWithCountsApiType,
  AvailabilityStatusType,
} from '../../User/User.types';
import { Popover, PopoverProps } from '../../../shared/components/Popover';
import { CallIcon, MessageIcon, VideoCallIcon } from '../icons';
import { getAccountName } from '../../User/User.utils';
import { ChatTranslation } from '../i18n';
import { ChatAvatar } from '../ChatAvatar';
import { CHATS_FIND_ACCOUNT_CHAT_PATHNAME } from '../../Desktop/Desktop.constants';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
  useMobileNavigationSidebar,
} from '../../Workspace/Workspace.hooks';
import { generatePath, Link } from 'react-router-dom';
import {
  ConferenceCallType,
  ConferenceCreateBy,
} from '../../Conference/Conference.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { openConferenceCreateWindow } from '../../Conference/Conference.utils';
import { useAccountAvailableForCall } from '../../Conference/Conference.hooks';
import { useDesktopSidebar } from '../../Desktop/DesktopSidebar';
import { useMobile } from '../../../shared/hooks';
import { GroupTag } from '../../User/UserGroupTag';
import {
  ADMIN_PROFILE_PATHNAME,
  ADMIN_USER_PATHNAME,
  WorkspaceAccountGroupIdentity as GroupIdentity,
} from '../../User/User.constants';
import { UserTranslation } from '../../User/i18n';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { CallFilledIcon, CogIcon } from '../../../shared/icons';
import parsePhoneNumber from 'libphonenumber-js';
import { getLocalAccountTime } from '../../TimeDate/TimeDate.utils';
import { useAccountsContext } from '../../Account';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { IntegrationBadge } from '../ChatAvatar/IntegrationBadge';
import { getShortId } from '../../../shared/utils/id';

interface AccountPopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRef'> {
  account: AccountWithCountsApiType;
}

export const AccountPopover: FC<AccountPopoverProps> = ({
  account,
  onRequestClose,
  ...popoverProps
}) => {
  const { formatMessage, formatHTMLMessage } = useIntl();
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const availableForCall = useAccountAvailableForCall(account.id);
  const { setIdentifier } = useDesktopSidebar();
  const isMobile = useMobile();
  const {
    permissions: { canMessageWorkspaceMembers, canCallWorkspaceMembers },
  } = useCurrentWorkspacePermissions();

  const {
    permissions: { canEditUsersPage },
  } = useCurrentWorkspacePermissions();

  // TODO: It is a complex rewrite to change the related "account"s to AccountWithCountsApiType higher up
  // Find a better approach
  const popoverAccount = account as AccountWithCountsApiType;
  const popoverAccountWorkspace = popoverAccount?.workspaceCache?.[account.id];

  const handleManageUserClick = useCallback(() => {
    const userProfilePath = generatePath(ADMIN_USER_PATHNAME, {
      workspaceId: getShortId(workspace.id),
      userId: getShortId(account.id),
    });

    navigate(userProfilePath);
    onRequestClose();
  }, [account.id, navigate, onRequestClose, workspace.id]);

  const phoneNumber = popoverAccount.phoneNumber
    ? parsePhoneNumber(popoverAccount.phoneNumber)
    : null;

  const accountLocalTime = getLocalAccountTime(popoverAccount);

  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();

  const { accountsWithAvailability } = useAccountsContext();
  const accountWithAvailability =
    accountsWithAvailability[account.id] || undefined;
  const OnlineStatus = accountWithAvailability?.onlineStatus;

  const showInCallBadge =
    account.onCall &&
    OnlineStatus !== AvailabilityStatusType.Offline &&
    account.id !== currentAccount?.id;
  const accountOrigin =
    accountWithAvailability?.origin ||
    account.origin ||
    AccountOrigin.webtopcom;
  const isSlackUser = accountOrigin === AccountOrigin.slack;

  const adminProfilePathName = generatePath(ADMIN_PROFILE_PATHNAME, {
    workspaceId: getShortId(workspace.id),
  });

  const accountEmail = useMemo(() => {
    if (popoverAccount.origin === AccountOrigin.slack) {
      return popoverAccount.originEmail;
    }

    return popoverAccount.email;
  }, [popoverAccount]);

  return (
    <Popover
      pageBottomMargin={16}
      horizontalMargin={8}
      onRequestClose={onRequestClose}
      {...popoverProps}>
      <StyledAccountPopover>
        <AccountDetails>
          {canEditUsersPage && (
            <ManageUserContainer>
              <ButtonWithIcon
                className="manage-user-button"
                icon={CogIcon}
                iconFirst={true}
                mode={ButtonMode.tertiary}
                size={ButtonSize.xs}
                onClick={handleManageUserClick}>
                <FormattedMessage id={ChatTranslation.manageUserButtonLabel} />
              </ButtonWithIcon>
            </ManageUserContainer>
          )}
          {!!popoverAccountWorkspace && (
            <GroupTagContainer>
              <GroupTag
                groupIdentifier={
                  popoverAccountWorkspace.active
                    ? popoverAccountWorkspace.groupIdentifier
                    : GroupIdentity.SUSPENDED
                }
                data-testid="group">
                {formatMessage({
                  id: UserTranslation[
                    popoverAccountWorkspace.active
                      ? (popoverAccountWorkspace.groupIdentifier as GroupIdentity)
                      : (GroupIdentity.SUSPENDED as GroupIdentity)
                  ],
                })}
              </GroupTag>
              {isSlackUser && (
                <IntegrationBadge origin={AccountOrigin.slack} badgeSize={20} />
              )}
            </GroupTagContainer>
          )}

          <ChatAvatar
            account={account}
            className="avatar"
            avatarSize="3.5rem"
            onlineStatusSize="0.875rem"
          />

          <StyledAccountName>{getAccountName(account)}</StyledAccountName>

          {showInCallBadge && (
            <InCallBadge
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: ChatTranslation.peoplePanelInACallBadge,
              })}
              data-tooltip-place={TooltipPlace.bottom}>
              <FormattedHTMLMessage
                id={ChatTranslation.peoplePanelInACallBadge}
              />
              <CallFilledIcon />
            </InCallBadge>
          )}
        </AccountDetails>
        {account.id !== currentAccount?.id && (
          <ButtonsContainer>
            <div
              data-tooltip-id="global-tooltip"
              data-tooltip-html={
                !canMessageWorkspaceMembers
                  ? (formatHTMLMessage({
                      id: ChatTranslation.accountPopoverContactDisabledFeature,
                    }) as string)
                  : ''
              }
              data-tooltip-place={TooltipPlace.bottom}>
              <Button
                data-testid="popover-create-message-btn"
                disabled={
                  !account?.workspaceCache?.[account.id]?.active ||
                  !canMessageWorkspaceMembers ||
                  false
                }
                onClick={() => {
                  mobileNavigationSidebarClose();

                  navigate(
                    generatePath(CHATS_FIND_ACCOUNT_CHAT_PATHNAME, {
                      workspaceId: getShortId(workspace.id),
                      accountId: getShortId(account.id),
                    }),
                  );

                  if (isMobile) {
                    setIdentifier(null);
                  }
                  onRequestClose();
                }}>
                <div className="icon-container">
                  <MessageIcon />
                </div>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverMessageButton}
                />
              </Button>
            </div>

            {popoverAccountWorkspace?.active && (
              <>
                <div
                  data-tooltip-id="global-tooltip"
                  data-tooltip-html={
                    !canCallWorkspaceMembers
                      ? (formatHTMLMessage({
                          id: ChatTranslation.accountPopoverContactDisabledFeature,
                        }) as string)
                      : ''
                  }
                  data-tooltip-place={TooltipPlace.bottom}>
                  <Button
                    data-testid="popover-call-btn"
                    disabled={!availableForCall || !canCallWorkspaceMembers}
                    onClick={() => {
                      openConferenceCreateWindow({
                        currentAccountId: currentAccount.id,
                        currentWorkspaceId: workspace.id,
                        callType: ConferenceCallType.audio,
                        createBy: ConferenceCreateBy.accountId,
                        id: account.id,
                      });
                      onRequestClose();
                    }}>
                    <div className="icon-container">
                      <CallIcon />
                    </div>
                    <FormattedMessage
                      id={ChatTranslation.accountPopoverCallButton}
                    />
                  </Button>
                </div>
                <div
                  data-tooltip-id="global-tooltip"
                  data-tooltip-html={
                    !canCallWorkspaceMembers
                      ? (formatHTMLMessage({
                          id: ChatTranslation.accountPopoverContactDisabledFeature,
                        }) as string)
                      : ''
                  }
                  data-tooltip-place={TooltipPlace.bottom}>
                  <Button
                    data-testid="popover-video-call-btn"
                    disabled={!availableForCall || !canCallWorkspaceMembers}
                    onClick={() => {
                      openConferenceCreateWindow({
                        currentAccountId: currentAccount.id,
                        currentWorkspaceId: workspace.id,
                        callType: ConferenceCallType.video,
                        createBy: ConferenceCreateBy.accountId,
                        id: account.id,
                      });
                      onRequestClose();
                    }}>
                    <div className="icon-container">
                      <VideoCallIcon />
                    </div>
                    <FormattedMessage
                      id={ChatTranslation.accountPopoverVideoCallButton}
                    />
                  </Button>
                </div>
              </>
            )}
          </ButtonsContainer>
        )}

        <InfoItems>
          {accountEmail && (
            <InfoItem>
              <InfoItemLabel>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverEmailLabel}
                />
              </InfoItemLabel>
              <InfoItemContent>
                <a href={`mailto:${accountEmail}`}>{accountEmail}</a>
              </InfoItemContent>
            </InfoItem>
          )}

          {phoneNumber && (
            <InfoItem>
              <InfoItemLabel>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverPhoneLabel}
                />
              </InfoItemLabel>
              <InfoItemContent>
                <a href={phoneNumber.getURI()}>
                  {phoneNumber.formatInternational()}
                </a>
              </InfoItemContent>
            </InfoItem>
          )}

          {!phoneNumber && currentAccount.id === popoverAccount.id && (
            <InfoItem>
              <InfoItemLabel>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverPhoneLabel}
                />
              </InfoItemLabel>
              <InfoItemContent>
                <Link
                  onClick={onRequestClose}
                  to={{
                    pathname: adminProfilePathName,
                    search: getQueryParamsFrom({
                      editUserProfileModal: true,
                    }),
                  }}>
                  <FormattedMessage
                    id={ChatTranslation.accountPopoverAddPhoneNumberText}
                  />
                </Link>
              </InfoItemContent>
            </InfoItem>
          )}

          {accountLocalTime && (
            <InfoItem>
              <InfoItemLabel>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverLocalTimeLabel}
                />
              </InfoItemLabel>
              <InfoItemContent>
                <div title={popoverAccount.timezone}>{accountLocalTime}</div>
              </InfoItemContent>
            </InfoItem>
          )}

          {popoverAccount.companyName && (
            <InfoItem>
              <InfoItemLabel>
                <FormattedMessage
                  id={ChatTranslation.accountPopoverCompanyNameLabel}
                />
              </InfoItemLabel>
              <InfoItemContent>
                {!!popoverAccount.companyUrl ? (
                  <a
                    href={popoverAccount.companyUrl}
                    rel="noopener noreferrer"
                    target="_blank">
                    {popoverAccount.companyName}
                  </a>
                ) : (
                  popoverAccount.companyName
                )}
              </InfoItemContent>
            </InfoItem>
          )}

          {!popoverAccount.companyName &&
            currentAccount.id === popoverAccount.id && (
              <InfoItem>
                <InfoItemLabel>
                  <FormattedMessage
                    id={ChatTranslation.accountPopoverCompanyNameLabel}
                  />
                </InfoItemLabel>
                <InfoItemContent>
                  <Link
                    onClick={onRequestClose}
                    to={{
                      pathname: adminProfilePathName,
                      search: getQueryParamsFrom({
                        editUserProfileModal: true,
                      }),
                    }}>
                    <FormattedMessage
                      id={ChatTranslation.accountPopoverAddCompanyText}
                    />
                  </Link>
                </InfoItemContent>
              </InfoItem>
            )}
        </InfoItems>
      </StyledAccountPopover>
    </Popover>
  );
};
