import styled, { css } from 'styled-components';
import { ButtonMode } from '../Button/Button';

export const KeyboardTimePickerWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled.div<{ mode: string }>`
  appearance: none;
  border: 0;
  border-radius: 4px;
  text-align: left;
  word-wrap: break-word;
  margin: 0;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  color: inherit;
  padding: 8px 24px;
  font-size: 0.875rem !important;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 18px;

  ${({ mode, theme }) =>
    mode === ButtonMode.primary &&
    css`
      background-color: ${theme.colors.Primary};
      color: ${theme.colors.PrimaryTextLight};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.PrimaryDark};
        }

        &:focus:not(:focus-visible),
        &:active {
          outline: none;
          background-color: ${theme.colors.PrimaryDarker};
        }

        &:focus-visible {
          outline: none;
          background-color: ${theme.colors.Primary};
          border: 2px solid ${theme.colors.PrimaryLight};
        }
      }

      &[disabled] {
        background-color: ${p => p.theme.colors.SurfaceBorder};
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
        opacity: 0.5;
        cursor: default;
      }
    `}

  ${({ mode, theme }) =>
    mode === ButtonMode.secondary &&
    css`
      color: ${theme.colors.OnSurfaceHighEmphasis};
      border: 1px solid ${theme.colors.BorderDefault};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.OnSurfaceHover};
          border: 1px solid ${theme.colors.OnSurfaceHover};
        }

        &:focus:not(:focus-visible),
        &:active {
          outline: none;
          background-color: ${theme.colors.OnSurfacePressed};
          border: 1px solid ${theme.colors.BorderDefault};
        }

        &:focus-visible {
          outline: none;
          background-color: ${theme.colors.OnSurfacePressed};
          border: 2px solid ${theme.colors.PrimaryLight};
        }
      }

      &[disabled] {
        background-color: ${p => p.theme.colors.SurfaceSubdued};
        color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
        border: 1px solid ${theme.colors.BorderDefault};
        cursor: default;
      }
    `}

  ${({ mode, theme }) =>
    mode === ButtonMode.tertiary &&
    css`
      color: ${theme.colors.OnSurfaceLightEmphasis};

      &:not([disabled]) {
        &:hover {
          color: ${theme.colors.PrimaryLight};
        }

        &:focus,
        &:active {
          outline: none;
          color: ${theme.colors.PrimaryLight};
        }
      }
    `}

    ${({ mode, theme }) =>
    mode === ButtonMode.dark &&
    css`
      color: ${theme.colors.PrimaryTextLight};
      background-color: ${theme.colors.PrimaryDark};

      &:not([disabled]) {
        &:hover {
          background-color: ${theme.colors.PrimaryLight};
        }

        &:focus {
          outline: none;
          background-color: ${theme.colors.PrimaryLight};
        }

        &:active {
          outline: none;
          background-color: ${theme.colors.DarkActiveButtonLinkBackground};
        }
      }
    `}
`;
