import React, { FC } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { useQueryParams } from '../../../../shared/hooks';
import { Link, LinkMode, LinkSize } from '../../../../shared/components/Link';
import SuccessIcon from '../../../../shared/assets/images/success-light.svg';
import { SuccessImage } from './JoinChatPage.styled';

export const JoinChatSuccessPage: FC = () => {
  const { slackWorkspace } = useQueryParams();
  const intl = useIntl();

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="join-chat-success-step">
        <FormattedMessage
          tagName={OnboardingStepHeader}
          id={OnboardingTranslation.onboardingJoinChatSuccessHeader}
        />
        <SuccessImage
          src={SuccessIcon}
          alt={intl.formatMessage({
            id: OnboardingTranslation.onboardingJoinChatSuccessHeader,
          })}
        />
        <FormattedMessage
          tagName={OnboardingStepText}
          id={OnboardingTranslation.onboardingJoinChatSuccessDescription}
        />
        {slackWorkspace && (
          <Link
            fullWidth
            href={slackWorkspace as string}
            rel="noopener noreferrer"
            target="_blank"
            mode={LinkMode.primary}
            size={LinkSize.md}>
            <FormattedMessage
              id={
                OnboardingTranslation.onboardingJoinChatSuccessOpenSlackButton
              }
            />
          </Link>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
