import styled from 'styled-components';

export const StyledSsoAppsGrid = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export const AdminViewHeader = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex-direction: row;
    align-items: center;
  `}
`;

export const CustomAppSsoBanner = styled.div`
  background-color: ${p => p.theme.colors.PrimaryLightest};
  margin: 0 1rem;
  padding: 1.25rem 1.5rem;
  color: ${p => p.theme.colors.PrimaryDark};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  .icon {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    appearance: none;
    border: none;
    background: none;
    padding: 0.25rem;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const IntroTextContainer = styled.div`
  margin: 0 1rem 22px;

  p ~ p {
    margin-top: 0.5rem;
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;
