import { getRestApiClient } from '../../../Api/Api.utils';
import { RequestMethod } from '../../../Api/RestApiClient/RestApiClient.types';
import type { SignUpFlow } from '../../../Onboarding/Onboarding.types';
import type { AccountApiType } from '../../../User/User.types';

export const createLinkedAccountWorkspaceApi = (
  flowType: SignUpFlow,
): Promise<AccountApiType> => {
  const restApiClient = getRestApiClient();

  return restApiClient!
    .fetch('/create_workspace_for_invited_account', RequestMethod.POST, {
      flow: flowType,
    })
    .then(({ data }) => data);
};
