import { useMutation } from '@apollo/client';
import {
  UPDATE_GENERAL_SETTINGS,
  UpdateGeneralSettingsResponse,
  UpdateGeneralSettingsVariables,
} from './GeneralSettings.mutations';

export const useUpdateGeneralSettingsMutation = () =>
  useMutation<UpdateGeneralSettingsResponse, UpdateGeneralSettingsVariables>(
    UPDATE_GENERAL_SETTINGS,
  );
