import { AccountApiType, AccountWithCountsApiType } from '../User/User.types';
import { ApiConnection } from '../../shared/api/api.types';
import { RepeatingScheduledChatConferenceRepeatPattern } from './Conference.mutations';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { TimezoneType } from '../TimeDate/TimeDate.types';
import type { DesktopApiType } from '../Desktop/data/Desktop/types/Desktop.types';

export interface ConferenceApiType {
  id: string;
  _id: string;
  jwtToken: string;
  chatConversationIri: string;
  statistics: ConferenceStatistics;
  callType: ConferenceCallType;
  status: ConferenceStatus;
  seenBy: string[];
  limit: number;
  createdAt: string;
  lastStartedAt: string | null;
  createdBy: AccountApiType;
  desktop: DesktopApiType | null;
  workspace?: WorkspaceApiType;
  __typename: string;
}

export interface ScheduledChatConferenceApiType {
  _id: string;
  id: string;
  startAt: string;
  endAt: string;
  title: string;
  description?: string;
  sequence: number;
  timezone: string;
  createdBy?: AccountApiType;
  membersOverridden: boolean;
  scheduleChatConferenceMembers?: ApiConnection<ScheduleChatMembersApiType>;
  repeatingScheduleChatConference?: {
    id?: string;
    repeatPattern?: RepeatingScheduledChatConferenceRepeatPattern;
    scheduleChatConferenceMembers?: ApiConnection<ScheduleChatMembersApiType>;
  } | null;
  chatConference?: {
    id: string;
    workspace?: WorkspaceApiType;
  };
  __typename?: 'ScheduleChatConference';
}

export interface ScheduleChatMembersApiType {
  __typename?: 'ScheduleChatConferenceMember';
  id: string;
  icalendarUrl: string;
  email?: string;
  account?: AccountWithCountsApiType | null;
}

export interface ConferenceInputType {
  callType: ConferenceCallType;
  chatConversation?: string;
}

export enum ConferenceCallType {
  audio = 'audio',
  video = 'video',
}

export enum ConferenceStatus {
  outgoing = 'outgoing',
  incoming = 'incoming',
  missed = 'missed',
}

export interface ConferenceStatistics {
  total_duration: number;
  has_started: boolean;
  has_ended: boolean;
  participants?: ConferenceParticipant[];
}

export interface ChatConferenceUser {
  id: string;
  _id: string;
  account: {
    id: string;
  };
  __typename: string;
}

export interface ConferenceParticipant {
  id: string;
  participated: boolean;
  joined: string | null;
  left: string | null;
  duration: number;
}

export enum ConferenceCreateBy {
  conversationId = 'conversationId',
  accountId = 'accountId',
  empty = 'empty',
}

export enum FilterPeriod {
  past = 'past',
  future = 'future',
}

export interface ScheduleConferenceFormMembers {
  value: string;
  label: string;
  email?: string;
}

export interface ScheduleConferenceFormValues {
  title: string;
  description: string;
  startAt: Date;
  endAt: Date;
  members: ScheduleConferenceFormMembers[];
  selectedDay: Date;
  selectedEndDay: Date;
  timezone: TimezoneType;
  repeating: {
    value: number;
    label: string;
  };
  customRepeating?: RepeatingScheduledChatConferenceRepeatPattern;
}

export interface ScheduleConferenceFormProps {
  onClose: () => void;
  toggleRepeatingConferenceInfo: () => void;
  selectedDate?: Date;
}

export interface ScheduleRepeatingConferenceModalProps {
  data?: RepeatingScheduledChatConferenceRepeatPattern;
  onSubmit: (value: RepeatingScheduledChatConferenceRepeatPattern) => void;
  onClose: () => void;
  selectedDate: Date;
}

export interface ScheduledConferenceUpdateConfirmationModalProps {
  values: ScheduleConferenceFormValues;
  onSubmit: (
    value: ScheduleConferenceFormValues,
    updateRepeatingThisAndFollowing: boolean,
    updateAllConferences: boolean,
  ) => void;
  reapeatingPattern?: RepeatingScheduledChatConferenceRepeatPattern;
}

export interface ScheduledConferenceDeleteConfirmationModalProps {
  onSubmit: (
    deleteAllConferences: boolean,
    deleteThisAndFollowing: boolean,
  ) => void;
}

export interface ScheduleRepeatingConferenceFormProps
  extends ScheduleRepeatingConferenceModalProps {}

export interface ScheduleRepeatingConferenceFormValues {
  intervalValue: number;
  intervalRange: string;
  repeatOnWeeks: {
    label: string;
    value: string;
    active: boolean;
  }[];
  repeatOnMonths: number;
  endsOn: string;
  endsOnDate: string;
  occurrences: number;
}

export enum ScheduledConferenceRepeatingValues {
  DOES_NOT_REPEAT = 0,
  SELECTED_OPTION = 1,
  CUSTOM_OPTION = 2,
}

export enum ScheduleConferenceRepeatingOnMonthOptionsValues {
  REPEAT_ON_DAY = 1,
  REPEAT_ON_WEEK_DAY = 2,
  REPEAT_ON_CUSTOM_DATE = 3,
}

export enum ScheduleRepeatingRepeatFreqValues {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum ScheduleRepeatingRepeatFreqLabels {
  DAY = 'scheduleRepeatingConferenceDays',
  WEEK = 'scheduleRepeatingConferenceWeeks',
  MONTH = 'scheduleRepeatingConferenceMonthly',
}

export interface ScheduleRepeatingRepeatRepeatOnWeeksValues {
  label: string;
  value: string;
  active: boolean;
}

export interface ScheduledRepeatedConferenceFormValues {
  intervalValue: number;
  intervalRange: ScheduleRepeatingRepeatFreqValues;
  repeatOnWeeks: ScheduleRepeatingRepeatRepeatOnWeeksValues[];
  repeatOnMonths: number;
  endsOn: string;
  endsOnDate: Date | string;
  occurrences: number | undefined;
}

export enum ConferencesTabsValues {
  CALLS = 0,
  SCHEDULED = 1,
}
