import {
  EventSourceMessage,
  fetchEventSource,
} from '../../../3rd-party/fetch-event-source';
import { AvailabilityAccountsType } from '../../Account/providers/AccountsProvider/AccountsProvider.types';
import { AvailabilityStatusType } from '../../User/User.types';
import {
  LAST_PING_EVENT_ID,
  MERCURE_RETRY_INTERVAL,
} from '../Mercure.constants';
import { MercureStatus } from '../Mercure.types';
import { MercureClient, MercureClientArgs } from '../MercureClient';
import { PingEvent } from './PingMercure.types';

export interface PingMercureClientArgs extends MercureClientArgs {
  currentWorkspaceId: string;
  updateAccountOnlineStatusInCache: (
    identityId: string,
    newStatus: AvailabilityStatusType,
    timestamp: string,
    accountsWithAvailability: AvailabilityAccountsType,
  ) => void;
  accountsWithAvailability: AvailabilityAccountsType;
  listenersRef: React.MutableRefObject<((e: PingEvent) => void)[]>;
}

export class PingMercureClient extends MercureClient {
  currentWorkspaceId: string;
  updateAccountOnlineStatusInCache: (
    identityId: string,
    newStatus: AvailabilityStatusType,
    timestamp: string,
    accountsWithAvailability: AvailabilityAccountsType,
  ) => void;
  accountsWithAvailability: AvailabilityAccountsType;
  listenersRef: React.MutableRefObject<((e: PingEvent) => void)[]>;

  constructor(props: PingMercureClientArgs) {
    super(props);
    this.currentWorkspaceId = props.currentWorkspaceId;
    this.updateAccountOnlineStatusInCache =
      props.updateAccountOnlineStatusInCache;
    this.accountsWithAvailability = props.accountsWithAvailability;
    this.listenersRef = props.listenersRef;
  }

  logMercureEvent = (event: PingEvent) => {
    window.DESKTOPCOM_LOG_PING_SUBSCRIPTION_MERCURE_EVENTS &&
      console.log(`[PING event IN]`, event);
  };

  handleMercureEvent = (event: PingEvent) => {
    this.logMercureEvent(event);

    if (this.currentWorkspaceId) {
      this.updateAccountOnlineStatusInCache(
        event.identity,
        event.state,
        event.time,
        this.accountsWithAvailability,
      );
    }

    this.listenersRef.current.forEach(listener => {
      listener(event);
    });
  };

  set lastEventId(id: string) {
    localStorage.setItem(LAST_PING_EVENT_ID, id);
  }

  get lastEventId() {
    return localStorage.getItem(LAST_PING_EVENT_ID) ?? '';
  }

  onMessageHandler = (e: EventSourceMessage) => {
    const event: PingEvent = JSON.parse(e.data);

    if (e.id) {
      this.lastEventId = e.id;
    }

    this.handleMercureEvent(event);
  };

  reconnect = () => {
    this.mercureStatus = MercureStatus.reconnecting;

    this.esContoller.abort();
    this.init();
  };

  init = () => {
    fetchEventSource(this.getMercureUrl(), {
      disconnectMercureClient: this.disconnectMercureClient,
      mercureClientRequestController: this.esContoller,
      headers: {
        Authorization: `Bearer ${this.getInitialAuthToken()}`,
      },
      onopen: this.handleMercureStreamOpen,
      onerror: this.handleMercureStreamError,
      onclose: this.handleMercureStreamClose,
      openWhenHidden: true,
      signal: this.esContoller.signal,
      lastEventId: this.lastEventId,
      onmessage: this.onMessageHandler,
    }).catch(err => {
      this.esContoller.abort();

      setTimeout(() => {
        if (this.mercureStatus !== MercureStatus.connected && this.isOnline) {
          this.reconnect();
        }
      }, MERCURE_RETRY_INTERVAL);
    });

    this.mercureStatus = MercureStatus.connected;
  };

  pong = async (body: URLSearchParams) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this.getInitialAuthToken()}`,
      },
      body,
    };

    await fetch(this.getMercureUrl(), requestOptions)
      .then(async response => {
        if (!response.ok) {
          if (response.status === 401) {
            this.refreshAccountMercureInfo();
          }

          const isJson = response.headers
            .get('content-type')
            ?.includes('application/json');
          const data = isJson && (await response.json());

          const error = (data && data.message) || response.status;

          console.error(error);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  updateVariables = (args: PingMercureClientArgs) => {
    this.currentWorkspaceId = args.currentWorkspaceId;
    this.updateAccountOnlineStatusInCache =
      args.updateAccountOnlineStatusInCache;
    this.accountsWithAvailability = args.accountsWithAvailability;
    this.listenersRef = args.listenersRef;
    this.updateAccount = args.updateAccount;
  };
}
