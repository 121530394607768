import styled, { css } from 'styled-components';
import { APPTOPBAR_MOBILE_HEIGHT } from '../AppTopBar/AppTopBar.styled';
import { SEGMENT_TITLEBAR_HEIGHT_MOBILE } from './SegmentPageTitleBar/SegmentPageTitleBar.styled';

export const StyledSegment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: fill-available;
`;

export const SegmentMobileWorkspaceSwitcher = styled.div`
  width: 100%;
  height: ${SEGMENT_TITLEBAR_HEIGHT_MOBILE}px;
  background-color: ${p => p.theme.colors.Background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;

export const SegmentLayout = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  max-width: 100vw;
  max-height: fill-available;
  overflow: hidden;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: ${APPTOPBAR_MOBILE_HEIGHT}px;
    `};
`;

export const SegmentPage = styled.div.attrs({
  'data-testid': 'segment-page',
})`
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const NoAccessScreen = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-column-end: span 3;
  background-color: ${p => p.theme.colors.Surface};

  .logoutButton {
    margin-top: 1rem;
  }
`;
