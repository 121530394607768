import { useCallback, useReducer } from 'react';
import { attachmentReducer } from './Attachment.reducer';
import { Asset, Attachment, AttachmentState } from './Attachment.types';
import {
  ACTION_TYPE_REMOVE_ATTACHMENT,
  ACTION_TYPE_RESET_ATTACHMENTS,
  ACTION_TYPE_UPLOAD_ATTACHMENT_FAILED,
  ACTION_TYPE_UPLOAD_ATTACHMENT_STARTED,
  ACTION_TYPE_UPLOAD_ATTACHMENT_SUCCESS,
} from './Attachment.constants';

export const useAttachments = (
  attachments: Attachment[] = [],
): [
  AttachmentState,
  {
    uploadAttachmentStarted: (id: string, file: File) => void;
    uploadAttachmentSuccess: (id: string, asset: Asset) => void;
    uploadAttachmentFailed: (id: string) => void;
    removeAttachment: (id: string) => void;
    resetAttachments: (attachments: Attachment[]) => void;
  },
] => {
  const [state, dispatch] = useReducer(attachmentReducer, {
    attachments,
  });
  const uploadAttachmentStarted = useCallback((id: string, file: File) => {
    dispatch({
      type: ACTION_TYPE_UPLOAD_ATTACHMENT_STARTED,
      id,
      file,
    });
  }, []);
  const uploadAttachmentSuccess = useCallback((id: string, asset: Asset) => {
    dispatch({
      type: ACTION_TYPE_UPLOAD_ATTACHMENT_SUCCESS,
      id,
      asset,
    });
  }, []);
  const uploadAttachmentFailed = useCallback((id: string) => {
    dispatch({
      type: ACTION_TYPE_UPLOAD_ATTACHMENT_FAILED,
      id,
    });
  }, []);
  const removeAttachment = useCallback((id: string) => {
    dispatch({
      type: ACTION_TYPE_REMOVE_ATTACHMENT,
      id,
    });
  }, []);
  const resetAttachments = useCallback((attachments: Attachment[]) => {
    dispatch({
      type: ACTION_TYPE_RESET_ATTACHMENTS,
      attachments,
    });
  }, []);
  return [
    state,
    {
      uploadAttachmentStarted,
      uploadAttachmentSuccess,
      uploadAttachmentFailed,
      removeAttachment,
      resetAttachments,
    },
  ];
};
