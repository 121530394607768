import styled, { css } from 'styled-components';

const truncate = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledPendingUserListItem = styled.div`
  display: flex;
  padding: 0 1rem;
`;

export const StyledDetails = styled.div`
  width: 40%;
`;

export const StyledInformation = styled.div`
  padding: 0 13px;
  flex: 1 1 100%;
  min-width: 0;

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    flex: 1 1 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const StyledName = styled.h4`
  ${truncate};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 20px;
`;

export const StyledEmail = styled.p`
  ${truncate};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 14px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const StyledItemControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledGroup = styled.div`
  flex: 1;

  div:not(:first-child) {
    margin-left: 4px;
  }
`;

export const StyledExpires = styled.div`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  flex: 1;
`;

export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.AvatarPlaceholder};
`;
