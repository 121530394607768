import React, { FC, useCallback } from 'react';
import { DesktopForm } from '../DesktopForm';
import { StyledCreateDesktopForm } from './CreateDesktopForm.styled';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import { useCreateDesktopMutation } from '../../Desktop.hooks';
import type {
  DesktopApiType,
  DesktopFormValues,
} from '../../data/Desktop/types/Desktop.types';
import { useDesktopsActions } from '../../data/Desktop/Desktop.actions';

interface CreateDesktopFormProps {
  onlyInternalDesktopCreation?: boolean;
  onDone: (createdDesktop?: DesktopApiType) => void;
  onRequestClose: () => void;
}

export const CreateDesktopForm: FC<CreateDesktopFormProps> = ({
  onlyInternalDesktopCreation,
  onRequestClose,
  onDone,
}) => {
  const {
    workspace: { id: workspace },
  } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { refetchPermissions } = useCurrentWorkspacePermissions();

  const [createDesktopMutation] = useCreateDesktopMutation();
  const { createDesktop } = useDesktopsActions();

  const handleSubmit = useCallback(
    (formValues: DesktopFormValues) => {
      return createDesktop({
        ...formValues,
        workspace,
      }).then(newDesktop => {
        if (newDesktop) {
          onDone(newDesktop);
        }
      });
    },
    [createDesktop, workspace, onDone],
  );

  return (
    <StyledCreateDesktopForm>
      <DesktopForm
        onSubmit={handleSubmit}
        onCancel={onRequestClose}
        onlyInternalDesktopCreation={onlyInternalDesktopCreation}
      />
    </StyledCreateDesktopForm>
  );
};
