import styled from 'styled-components';
import {
  ModalHeaderTitle,
  StyledModalHeader,
  StyledModalHeaderData,
} from '../../../shared/components/Modal';

export const StyledAnnouncementModal = styled.div`
  & ${StyledModalHeader} {
    border-bottom: 1px solid ${p => p.theme.colors.ModalHeaderBorder};
  }

  & ${ModalHeaderTitle} {
    text-align: center;
    margin-left: 2rem;
    font-size: 1rem;

    ${({ theme }) => theme.breakpoints.md`
      margin-left: 2.5rem;
      font-size: 1.25rem;
    `}
  }

  & ${StyledModalHeaderData} {
    padding-right: 0;
  }
`;
