import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Navigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { OnboardingTranslation } from '../../i18n';
import { useChangeAboutYouForAccountMutation } from '../../Onboarding.hooks';
import { AboutYouFormValues, SignUpStep } from '../../Onboarding.types';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep } from '../OnboardingPage.styled';
import { AboutYouForm } from './AboutYou.form';
import { AboutYouStepHeader } from './AboutYouPage.styled';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const AboutYouPage: FC = () => {
  const { account, refetchAccountData } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const [changeAboutYouForAccount] = useChangeAboutYouForAccountMutation();
  const onSubmit = useCallback(
    (values: AboutYouFormValues) => {
      const { jobTitle, industry } = values;
      if (!account?.id) {
        return;
      }
      return changeAboutYouForAccount({
        variables: {
          input: {
            id: account.id,
            jobTitle,
            industry,
          },
        },
      })
        .then(() => refetchAccountData?.())
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [account?.id, changeAboutYouForAccount, refetchAccountData],
  );

  if (account?.currentStep !== SignUpStep.about_you) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <OnboardingPage horizontallyCentered>
      <OnboardingStep data-testid="registration-step">
        <OnboardingStepsCounter currentStep={account?.currentStep} />
        <AboutYouStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingAboutYouPageTitle}
          />
        </AboutYouStepHeader>

        <AboutYouForm onSubmit={onSubmit} />
      </OnboardingStep>
    </OnboardingPage>
  );
};
