import { GraphQLError } from 'graphql';
import { getApolloClient } from '../../../Api/Api.utils';
import {
  UPDATE_DESKTOP_APP_SORT,
  UpdateDesktopAppSortResponse,
  UpdateDesktopAppSortVariables,
} from '../../Desktop.mutations';
import type { DesktopAppSortType } from '../../data/Desktop/types/Desktop.types';

export const updateDesktopAppSort = async (
  desktopApps: DesktopAppSortType[],
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: UpdateDesktopAppSortResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      UpdateDesktopAppSortResponse,
      UpdateDesktopAppSortVariables
    >({
      mutation: UPDATE_DESKTOP_APP_SORT,
      variables: {
        input: {
          desktopApps,
        },
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });

    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
