import React, { FC } from 'react';
import {
  AppsLinksColumn,
  StyledLayoutConsolidated,
} from './LayoutConsolidated.styled';
import { AppsSection, ChatSection } from '../Section';
import {
  CLASS_NAME_APPS_LINKS_COLUMN,
  CLASS_NAME_APPS_SECTION,
  CLASS_NAME_CHAT_SECTION,
  CLASS_NAME_LINKS_SECTION,
} from './LayoutConsolidated.constants';
import { MembersUniversal } from '../../../../Chat/MembersUniversal';
import { DesktopLayout } from '../../../Desktop.constants';
import { SectionLinks } from '../../../../Link/LinksView/SectionLinks/SectionLinks';
import { useCurrentDesktopContent } from '../../../Desktop.hooks';
import { useCurrentTypeOfPage } from '../../../Desktop.utils';

export const LayoutConsolidated: FC = () => {
  const {
    desktopApps,
    desktopLinks,
    desktopFolders,
    hasChat,
    desktopAppsLoading,
  } = useCurrentDesktopContent();

  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const hasApps = !!desktopApps.length;
  const hasLinks = !!desktopLinks.length;
  const hasFolders = !!desktopFolders.length;

  return (
    <StyledLayoutConsolidated data-testid="desktop-layout-consolidated">
      {(hasApps || hasLinks || hasFolders) && (
        <AppsLinksColumn
          className={CLASS_NAME_APPS_LINKS_COLUMN}
          hasLinks={hasLinks || hasFolders}>
          {hasApps && (
            <AppsSection
              className={CLASS_NAME_APPS_SECTION}
              isOnlySection={hasApps && !hasLinks && !hasFolders && !hasChat}
            />
          )}
          {(hasLinks || hasFolders) && (!desktopAppsLoading || hasApps) && (
            <SectionLinks
              //TODO Discuss better solution with Bjorn for favoritesLinks
              favoritesLinks={isFavoritesDesktop ? desktopLinks : undefined}
              desktopLayout={DesktopLayout.CONSOLIDATED}
              className={CLASS_NAME_LINKS_SECTION}
              isOnlySection={(hasLinks || hasFolders) && !hasApps && !hasChat}
            />
          )}
        </AppsLinksColumn>
      )}
      {hasChat && (
        <ChatSection
          className={CLASS_NAME_CHAT_SECTION}
          isOnlySection={hasChat && !hasApps && !hasLinks && !hasFolders}
        />
      )}
      <MembersUniversal />
    </StyledLayoutConsolidated>
  );
};
