import React, { FC, useCallback, useEffect, useState } from 'react';
import { LinkDetailsPanel } from '../LinkDetailsModal.styled';
import { LinkApiType } from '../../../Link.types';
import { useCreateLinkChatConversationMutation } from '../../../Link.hooks';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { ChatContext } from '../../../../Chat/Chat.context';
import { StyledConversationView } from './LinkComments.styled';
import { LinkDetailsSidebarHeader } from '../LinkDetailsSidebarHeader/LinkDetailsSidebarHeader';
import { useIntl } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import { useCaseGetLink } from '../../../UseCase/getLink';
import { Spinner } from '../../../../../shared/components/Spinner';
import { ChatProvider } from '../../../../Chat/Chat.provider';
import { useLiveQuery } from 'dexie-react-hooks';
import { database } from '../../../../Database';
import { useChatConversationRepository } from '../../../../Chat/Data/Repository/ChatConversation/ChatConversationsApiRepository';

interface LinkCommentsProps {
  link: LinkApiType;
}

export const LinkComments: FC<LinkCommentsProps> = ({ link }) => {
  const { formatMessage } = useIntl();
  const [linkData, setLinkData] = useState<LinkApiType>(link);
  const chatConversationIri = linkData.chatConversationIri;
  const { getConversationById } = useChatConversationRepository();

  const [createLinkChatConversationMutation] =
    useCreateLinkChatConversationMutation();
  const { getLinkUseCase } = useCaseGetLink();

  const createLinkChatConversation = useCallback(() => {
    return createLinkChatConversationMutation({
      variables: {
        input: {
          link: linkData.id,
        },
      },
      update: (cache, result) => {
        if (!result.data) {
          return;
        }

        getLinkUseCase(linkData.id).then(response => {
          if (response?.data?.link) {
            setLinkData(response?.data?.link);
          }
        });
      },
    }).catch(e => {
      showToastGraphQLErrors(e.graphQLErrors);
    });
  }, [createLinkChatConversationMutation, getLinkUseCase, linkData.id]);

  useEffect(() => {
    if (!chatConversationIri) {
      createLinkChatConversation();
    }
  }, [chatConversationIri, createLinkChatConversation]);

  const chatConversation = useLiveQuery(
    () =>
      chatConversationIri
        ? database.chatConversations
            .where('id')
            .equals(chatConversationIri as string)
            .toArray()
            .then(response => {
              if (!response.length) {
                getConversationById(chatConversationIri as string);

                return undefined;
              }

              return response[0];
            })
            .catch(() => undefined)
        : undefined,
    [chatConversationIri],
    undefined,
  );

  return (
    <LinkDetailsPanel>
      <LinkDetailsSidebarHeader
        heading={formatMessage({
          id: LinkTranslation.linkDetailsCommentsHeading,
        })}
        commentsContext
      />
      <ChatContext.Provider value={{ conversation: chatConversation }}>
        <ChatProvider>
          {!linkData.chatConversationIri ? (
            <Spinner />
          ) : (
            <StyledConversationView
              allowAttachments={false}
              linkDesktopId={linkData.desktopId}
              allowReply={false}
            />
          )}
        </ChatProvider>
      </ChatContext.Provider>
    </LinkDetailsPanel>
  );
};
