import { gql } from '@apollo/client';
import { TemplateFragmentFields } from './Template.fragments';
import { ApiConnection } from '../../shared/api/api.types';
import { TemplateApiType } from './Template.types';

export const GET_TEMPLATES = gql`
  query GetTemplates {
    templates {
      edges {
        node {
          ${TemplateFragmentFields}
        }
      }
    }
  }
`;

export interface GetTemplatesVariables {}

export interface GetTemplatesResponse {
  templates: ApiConnection<TemplateApiType>;
}
