import styled, { css } from 'styled-components';

export const ExpandContainer = styled.div`
  position: relative;
`;

export const ExpandContent = styled.div<{ expanded: boolean }>`
  opacity: ${p => (p.expanded ? 1 : 0)};
  height: ${p => (p.expanded ? '100%' : 0)};
  transition: opacity 0.2s;
  overflow: hidden;
  margin-top: 0.375rem;
`;

export const ExpandTitle = styled.div<{
  withBadge: boolean;
}>`
  ${p =>
    p.withBadge &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.375rem 0.625rem 0.375rem 0;
    `}
`;
