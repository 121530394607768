import React, { FC, Ref, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import { CloseIcon, SearchThinIcon } from '../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../shared/hooks';
import {
  CancelButton,
  ClearButton,
  DesktopFilterInner,
  FilterInput,
  SearchIconWrapper,
  StyledDesktopFilter,
} from './DesktopFilter.styled';
import { DesktopTranslation } from '../../i18n';

const SEARCH_DEBOUNCE_TIME = 500;

export const DesktopFilter: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();
  const { desktopFilter } = queryParams;
  const [filterValue, setFilterValue] = useState('');
  const clearInputValue = () => setFilterValue('');
  const inputRef: Ref<any> = useRef();
  const [debouncedSearchValue] = useDebounce(filterValue, SEARCH_DEBOUNCE_TIME);
  const { formatMessage } = useIntl();
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    navigate({
      pathname,
      search: getQueryParamsFrom({
        ...queryParams,
        desktopFilter: debouncedSearchValue || '',
      }),
    });
  }, [debouncedSearchValue]); //eslint-disable-line

  useEffect(() => {
    setFilterValue(desktopFilter as string);
  }, [desktopFilter]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <StyledDesktopFilter>
      <DesktopFilterInner>
        <SearchIconWrapper>
          <SearchThinIcon width={14} height={14} />
        </SearchIconWrapper>
        <FilterInput
          data-testid="filter-input"
          ref={inputRef}
          value={filterValue}
          placeholder={formatMessage({ id: DesktopTranslation.filterButton })}
          onChange={e => setFilterValue(e.target.value)}
        />
        <ClearButton
          icon={() => <CloseIcon width={9} height={9} />}
          onClick={clearInputValue}
        />
        <CancelButton
          onClick={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                desktopFilter: undefined,
              }),
            })
          }>
          <FormattedMessage id={DesktopTranslation.filterCancelButtonText} />
        </CancelButton>
      </DesktopFilterInner>
    </StyledDesktopFilter>
  );
};
