import styled, { css } from 'styled-components';

import { IMAGE_HEIGHT, IMAGE_WIDTH } from './../Link.constants';

export const LinkImageContainer = styled.div<{
  isAsset?: boolean;
  isChatAssetThumb?: boolean;
  fullSize?: boolean;
}>`
  display: flex;
  min-height: ${IMAGE_HEIGHT}px;
  width: 100%;
  border-radius: ${({ isAsset }) => (isAsset ? '0.125rem' : '0.375rem')};
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  justify-content: center;

  ${p =>
    !p.isChatAssetThumb &&
    css`
      margin-left: ${p.theme.spacing.xs};
    `}

  max-width: 96px;

  ${({ fullSize }) =>
    fullSize &&
    css`
      justify-content: flex-start;
      margin-left: unset;
      max-width: unset;
    `};
`;

export const LinkImagePlaceholder = styled.div`
  background: ${p => p.theme.colors.BackgroundLight};
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  flex: 0 0 ${IMAGE_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const StyledLinkImage = styled.img<{ height?: number }>`
  border-radius: 0.375rem;
  width: auto;
  height: ${({ height }) => height}px;
  max-width: 100%;
`;
