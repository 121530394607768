import styled from 'styled-components';

export const DeleteMultipleVaultsControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;

  > * ~ * {
    margin-left: 1rem;
  }
`;

export const DeleteMultipleVaultsInputWrapper = styled.div`
  margin-top: 0.75rem;

  input {
    margin-top: 0.625rem;
  }

  .highlighted {
    padding: 0 0.25rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    background-color: ${p => p.theme.colors.BackgroundLight};
    border-radius: 3px;
  }
`;
