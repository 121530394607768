import styled from 'styled-components';

export const Textarea = styled.textarea`
  --webkit-appearance: none;
  background-color: ${p => p.theme.colors.Surface};
  border: 0;
  outline: 0;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.2;
  border: 0.063rem solid ${p => p.theme.colors.BorderDefault};
  padding: 0.5rem 0.5rem 0.5rem 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  width: 100%;
  min-height: 36px;
  resize: vertical;

  ::placeholder {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    cursor: not-allowed;
  }

  &:focus {
    border: 0.125rem solid ${p => p.theme.colors.Primary};
    padding: 0.438rem 0.438rem 0.438rem 0.813rem;
  }
`;
