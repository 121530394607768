import React, { FC, useMemo } from 'react';
import {
  Content,
  ContentWrapper,
  DesktopList,
  Header,
  Main,
  SearchBar,
  Sidebar,
  StepsIllustrationWrapper,
  TopPanel,
} from './StepsIllustration.styled';
import DefaultAppsImage from './images/default-apps.jpg';
import GoogleAppsImage from './images/google-services.jpg';
import MicrosoftAppsImage from './images/microsoft-services.jpg';
import LinksImage from './images/links.jpg';
import ChatImage from './images/chat.jpg';
import { TemplateAppSet } from '../../../Template/Template.constants';

interface StepsIllustrationProps {
  companyName?: string;
  displayDesktopList?: boolean;
  displayCloudServicesApps?: TemplateAppSet[];
  displayIllustrationSections?: string[];
  applyOpacity?: boolean;
}

export enum IllustrationSections {
  apps = 'apps',
  links = 'links',
  chat = 'chat',
}

const stringArrayToObject = (stringArray: string[]) => {
  return stringArray.reduce(
    (acc, curr) => ({
      [curr]: true,
      ...acc,
    }),
    {},
  );
};

export const StepsIllustration: FC<StepsIllustrationProps> = ({
  companyName,
  applyOpacity = false,
  displayDesktopList = false,
  displayCloudServicesApps = [],
  displayIllustrationSections = [],
}) => {
  const sectionsMap: { [key: string]: true } = useMemo(() => {
    return stringArrayToObject(displayIllustrationSections);
  }, [displayIllustrationSections]);

  const servicesMap: { [key: string]: true } = useMemo(() => {
    return stringArrayToObject(displayCloudServicesApps);
  }, [displayCloudServicesApps]);

  return (
    <StepsIllustrationWrapper
      data-testid="steps-illustration"
      applyOpacity={applyOpacity}>
      <Main>
        <Sidebar data-testid="sidebar">
          <Header>{companyName && <span>{companyName}</span>}</Header>
          <DesktopList
            displayDesktopList={displayDesktopList}
            data-testid="desktop-list"
          />
        </Sidebar>
        <ContentWrapper>
          <SearchBar />
          <TopPanel />
          <Content>
            {sectionsMap[IllustrationSections.apps] && (
              <section data-testid="apps-section">
                <img src={DefaultAppsImage} alt="default apps" />
                {servicesMap[TemplateAppSet.google] && (
                  <img src={GoogleAppsImage} alt="google apps" />
                )}
                {servicesMap[TemplateAppSet.microsoft] && (
                  <img src={MicrosoftAppsImage} alt="microsoft apps" />
                )}
              </section>
            )}

            {sectionsMap[IllustrationSections.links] && (
              <section data-testid="links-section">
                <img src={LinksImage} alt="links" />
              </section>
            )}

            {sectionsMap[IllustrationSections.chat] && (
              <section data-testid="chat-section">
                <img src={ChatImage} alt="chat" />
              </section>
            )}
          </Content>
        </ContentWrapper>
      </Main>
    </StepsIllustrationWrapper>
  );
};
