import React, { FC, useMemo } from 'react';
import {
  ChatConversationInternalType,
  ConversationType,
} from '../../../../../Chat.types';
import {
  DeletedUserIcon,
  DirectMessageGroup,
  DirectMessageGroupCount,
  ImageWrapper,
} from './ConversationAvatar.styled';
import {
  PeopleIcon,
  SharpIcon,
  UserIcon,
} from '../../../../../../../shared/icons';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../../../../User/User.types';
import { ChatAvatar } from '../../../../../ChatAvatar';
import { useAccountsContext } from '../../../../../../Account';
import { isBotAccount } from '../../../../../../User/User.utils';

const ICON_SIZE = 16;

type ConversationAvatarProps = {
  conversation: ChatConversationInternalType;
  account?: AccountApiType;
  defaultMargin?: boolean;
};

export const ConversationAvatar: FC<ConversationAvatarProps> = ({
  conversation,
  account,
  defaultMargin = true,
}) => {
  const { accountsWithAvailability } = useAccountsContext();
  const otherParticipants: Array<AccountWithCountsApiType | string> = useMemo(
    () => [
      ...conversation.userIds
        .map(userId => accountsWithAvailability[userId])
        .filter(Boolean),
      ...conversation.pendingEmails,
    ],
    [
      accountsWithAvailability,
      conversation.pendingEmails,
      conversation.userIds,
    ],
  );

  switch (conversation.type) {
    case ConversationType.private:
    case ConversationType.appIntegration:
      return (
        <ImageWrapper
          defaultMargin={defaultMargin}
          className="conversation-avatar"
          data-testid="conversation-avatar">
          {account ? (
            <ChatAvatar
              account={account}
              avatarSize="1.25rem"
              showOnlineStatus={!isBotAccount(account)}
            />
          ) : (
            <DeletedUserIcon data-testid="deleted-user-icon">
              <UserIcon width={ICON_SIZE} height={ICON_SIZE} />
            </DeletedUserIcon>
          )}
        </ImageWrapper>
      );

    case ConversationType.desktop:
      return (
        <ImageWrapper
          className="conversation-avatar"
          defaultMargin={defaultMargin}
          data-testid="desktop-conversation-avatar">
          <SharpIcon width={20} />
        </ImageWrapper>
      );
    case ConversationType.group:
    case ConversationType.pending:
      return (
        <ImageWrapper
          className="conversation-avatar"
          defaultMargin={defaultMargin}>
          <DirectMessageGroup data-testid="direct-messages-group">
            <PeopleIcon width={16} height={16} />
            <DirectMessageGroupCount>
              <span>{otherParticipants.length}</span>
            </DirectMessageGroupCount>
          </DirectMessageGroup>
        </ImageWrapper>
      );
    default:
      console.warn('Unknown conversation type');
      return null;
  }
};
