import React, {
  FC,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CountBadge } from '../../../shared/components/CountBadge';
import { Badge, BadgeWrapper, StyledInboxButton } from './InboxButton.styled';
import { useInbox } from '../Inbox.hooks';
import { InboxList } from '../InboxList';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { InboxIcon } from '../../../shared/icons/Inbox.icon';
import { CounterType, useUnreadCounter } from '../../Counters';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { DesktopTranslation } from '../../Desktop/i18n';
import { useIntl } from 'react-intl';

export const InboxButton: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const inboxNode: Ref<HTMLDivElement> = useRef(null);
  const inboxContext = useInbox();
  const toggleInboxHandler = useCallback(() => {
    inboxContext.toggleInbox();
  }, [inboxContext]);
  const unreadMentions = useUnreadCounter(workspace.id, CounterType.Mentions);
  const unreadThreads = useUnreadCounter(workspace.id, CounterType.Threads);
  const { formatMessage } = useIntl();
  const closeInbox = useCallback(() => {
    inboxContext.closeInbox();
  }, [inboxContext]);
  /**
   * Clicks outside should close the list
   */
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        !inboxContext.keepOpen &&
        inboxContext.isOpen &&
        inboxNode.current &&
        inboxNode.current.contains(e.target as HTMLElement)
      ) {
        return;
      }
      if (!inboxContext.keepOpen) {
        closeInbox();
      }
    },
    [closeInbox, inboxContext.isOpen, inboxContext.keepOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabDataRef = useRef({
    currentTabIndex: 0,
    unreadMentions: 0,
    unreadThreads: 0,
  });

  tabDataRef.current.currentTabIndex = currentTabIndex;
  tabDataRef.current.unreadMentions = unreadMentions;
  tabDataRef.current.unreadThreads = unreadThreads;

  useEffect(() => {
    if (!inboxContext.isOpen) {
      return;
    }

    if (
      (!tabDataRef.current.unreadMentions &&
        !tabDataRef.current.unreadThreads) ||
      (tabDataRef.current.unreadMentions && tabDataRef.current.unreadThreads)
    ) {
      return;
    }

    if (tabDataRef.current.unreadMentions) {
      if (tabDataRef.current.currentTabIndex !== 0) {
        setCurrentTabIndex(0);
      }
      return;
    }

    if (tabDataRef.current.currentTabIndex !== 1) {
      setCurrentTabIndex(1);
    }
  }, [inboxContext.isOpen]);

  return (
    <StyledInboxButton ref={inboxNode}>
      <BadgeWrapper>
        <Badge
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: DesktopTranslation.tooltipInboxButton,
          })}
          data-tooltip-place={TooltipPlace.bottom}
          icon={() => <InboxIcon width={20} />}
          hasUnread={false}
          onClick={toggleInboxHandler}
          open={inboxContext.isOpen}
          contentFill={true}
          data-tourid="inbox-bell"
          data-testid="inbox-bell"
        />
        <CountBadge
          offsetRight={-3}
          offsetTop={-2}
          count={unreadMentions + unreadThreads}
          limitCount={true}
        />
      </BadgeWrapper>
      {inboxContext.isOpen && (
        <InboxList
          selectedTab={currentTabIndex}
          onTabChange={setCurrentTabIndex}
        />
      )}
    </StyledInboxButton>
  );
};
