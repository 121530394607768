import styled, { css } from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const DesktopControlButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.375rem;
  }
`;

export const MobileMenuButton = styled(ButtonWithIcon)<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colors.Primary};
  margin-left: 0.5rem;
  width: 32px;
  height: 32px;
  svg {
    height: 24px;
    width: 24px;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.OnSurfaceSelected};
    `}
`;
