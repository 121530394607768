import React, {
  ChangeEvent,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ClearButton,
  GlobalSearchInner,
  GlobalSearchOuter,
  OptionsButton,
  SearchInput,
  StyledGlobalSearch,
} from './GlobalSearch.styled';

import { CloseIcon, SearchThinIcon } from '../../shared/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { useAppleTouchDevice, useQueryParams } from '../../shared/hooks';
import { useIntl } from 'react-intl';
import { GlobalSearchTranslation } from './i18n';
import { ExpandedSearch, GlobalSearchTab } from './ExpandedSearch';
import _noop from 'lodash/noop';
import { useGlobalSearchContext } from './GlobalSearch.hooks';
import { TABS } from './ExpandedSearch/ExpandedSearch.constants';

const SEARCH_DEBOUNCE_TIME = 500;

type GlobalSearchProps = {
  onBlur?: () => void;
  autoFocus?: boolean;
};

export const GlobalSearch = forwardRef<any, GlobalSearchProps>(
  ({ onBlur = _noop, autoFocus = false }, componentRef) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const queryParams = useQueryParams();
    const { globalSearch, globalSearchTab } = queryParams;
    const [searchValue, setSearchValue] = useState('');
    const clearInputValue = () => setSearchValue('');
    const containerRef = useRef<HTMLDivElement>(null);
    const skipBlurRef = useRef<boolean>(false);
    const inputRef: Ref<any> = useRef();
    const isAppleTouchDevice = useAppleTouchDevice();

    const {
      isFocused,
      setIsFocused,
      setSelectedTab,
      setSearchOnlyByConversation,
    } = useGlobalSearchContext();

    const [debouncedSearchValue] = useDebounce(
      searchValue,
      SEARCH_DEBOUNCE_TIME,
    );
    const { formatMessage } = useIntl();

    const searchSectionBlur = useCallback(() => {
      setIsFocused(false);
      setSelectedTab(TABS.ALL_CONTENT);
      setSearchOnlyByConversation(false);
      onBlur();
    }, [onBlur, setIsFocused, setSearchOnlyByConversation, setSelectedTab]);

    const outsideClickHandler = useCallback(
      (event: Event) => {
        if (skipBlurRef.current) {
          return null;
        }

        if (
          isFocused &&
          containerRef.current &&
          !containerRef.current.contains(event.target as HTMLElement)
        ) {
          searchSectionBlur();
        }
      },
      [isFocused, searchSectionBlur],
    );

    const isFirstRun = useRef(true);

    useEffect(() => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      navigate({
        pathname,
        search: getQueryParamsFrom({
          ...queryParams,
          globalSearch: debouncedSearchValue || undefined,
        }),
      });
    }, [debouncedSearchValue]); //eslint-disable-line

    useEffect(() => {
      setSearchValue((globalSearch as string) || '');
    }, [globalSearch]);

    useEffect(() => {
      document.addEventListener('mousedown', outsideClickHandler);

      return () => {
        document.removeEventListener('mousedown', outsideClickHandler);
      };
    }, [outsideClickHandler]);

    useEffect(() => {
      if (inputRef.current && autoFocus) {
        inputRef.current.focus();
      }
    }, [autoFocus, inputRef]);

    const handleOnFocus = useCallback(() => {
      setIsFocused(true);

      if (!globalSearchTab) {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            globalSearchTab: GlobalSearchTab.apps,
          }),
        });
      }
    }, [globalSearchTab, navigate, queryParams, setIsFocused]);

    useEffect(() => {
      if (inputRef.current && isFocused) {
        inputRef.current.focus();
      }
    }, [isFocused, inputRef]);

    const isAppsIntegrationsSelected =
      globalSearchTab === GlobalSearchTab.appsIntegrations;
    const searchInputPlaceholder = useMemo(
      () =>
        `${formatMessage({
          id: isAppsIntegrationsSelected
            ? GlobalSearchTranslation.searchAppIntegrationsInputPlaceholder
            : GlobalSearchTranslation.searchInputPlaceholder,
        })}`,
      [formatMessage, isAppsIntegrationsSelected],
    );
    const handleSearchChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
      },
      [],
    );

    useEffect(() => {
      if (!isFocused || !isAppleTouchDevice) {
        return;
      }
      const globalSearchInputRef = inputRef.current;
      const globalSearchInputDisplayStyle = globalSearchInputRef.style.display;

      globalSearchInputRef.style.display = 'none';
      setTimeout(() => {
        globalSearchInputRef.style.display = globalSearchInputDisplayStyle;
        globalSearchInputRef.focus();
      }, 100);
    }, [isAppleTouchDevice, isFocused]);

    return (
      <GlobalSearchOuter
        isFocused={isFocused}
        data-testid="global-search-outer">
        <GlobalSearchInner ref={containerRef}>
          <StyledGlobalSearch isFocused={isFocused}>
            <OptionsButton icon={null}>
              <SearchThinIcon width={14} height={14} />
            </OptionsButton>
            <SearchInput
              ref={inputRef}
              onFocus={handleOnFocus}
              data-testid="search-input"
              value={searchValue}
              placeholder={searchInputPlaceholder}
              onChange={handleSearchChange}
            />
            <ClearButton
              visible={!!(searchValue && searchValue.length)}
              icon={CloseIcon}
              onClick={clearInputValue}
            />
          </StyledGlobalSearch>
          {isFocused && globalSearchTab && (
            <ExpandedSearch
              searchSectionBlur={searchSectionBlur}
              skipBlurRef={skipBlurRef}
            />
          )}
        </GlobalSearchInner>
      </GlobalSearchOuter>
    );
  },
);
