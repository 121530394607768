import { DefaultTheme } from 'styled-components';

export const sharedCardClasses = {
  complete: 'CardInput--complete',
  empty: 'CardInput-empty',
  focus: 'CardInput--focus',
  invalid: 'CardInput--invalid',
};

export const sharedCardStyles = (theme: DefaultTheme) => {
  return {
    base: {
      backgroundColor: theme.colors.Surface,
      color: theme.colors.OnSurfaceHighEmphasis,
      fontSize: '14px',
      '::placeholder': {
        color: theme.colors.OnSurfaceLightEmphasis,
      },
    },
    invalid: {
      color: theme.colors.CriticalDark,
      ':focus': {
        color: theme.colors.CriticalLight,
      },
    },
  };
};
