import { useCallback, useState } from 'react';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { addAppToDesktopRepository } from '../Data/Repository/AppStoreRepository';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { captureException } from '@sentry/react';

export const useCaseAddAppToDesktop = () => {
  const [addAppToDesktopLoading, setAddAppToDesktopLoading] = useState(false);
  const { workspace } = useCurrentWorkspace();

  const addAppToDesktopUseCase = useCallback(
    async (desktopId: string, appId: string) => {
      setAddAppToDesktopLoading(true);

      try {
        const response = await addAppToDesktopRepository(
          workspace.id,
          desktopId,
          appId,
        );

        if (response) {
          if (response.errors) {
            showToastGraphQLErrors(response.errors);
          }

          return response;
        }
      } catch (e) {
        captureException(e);
      } finally {
        setAddAppToDesktopLoading(false);
      }
    },
    [workspace.id],
  );

  return {
    addAppToDesktopUseCase,
    addAppToDesktopLoading,
  };
};
