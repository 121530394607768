import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { AppStoreAppApiType } from '../../AppStore.types';
import { useCaseAddAppToDesktop } from '../../UseCase/addAppToDesktop';
import { useCaseDeleteAppFromDesktop } from '../../UseCase/deleteAppFromDesktop';

export const AppCardViewModel = (
  app: AppStoreAppApiType,
  onDeleteCustomAppClick: (app: AppStoreAppApiType) => void,
) => {
  const {
    permissions: { canRemoveCustomApp },
  } = useCurrentWorkspacePermissions();
  const currentDesktop = useCurrentDesktop();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { addAppToDesktopUseCase, addAppToDesktopLoading } =
    useCaseAddAppToDesktop();

  const { deleteAppFromDesktopUseCase, deleteAppFromDesktopLoading } =
    useCaseDeleteAppFromDesktop();

  const handleAddClick = useCallback(() => {
    addAppToDesktopUseCase(currentDesktop!.id, app.id).catch(
      (e: { graphQLErrors: GraphQLError[] }) =>
        showToastGraphQLErrors(e.graphQLErrors),
    );
  }, [addAppToDesktopUseCase, currentDesktop, app.id]);

  const handleDeleteClick = useCallback(() => {
    deleteAppFromDesktopUseCase(currentDesktop!.id, app.id).catch(e =>
      showToastGraphQLErrors(e.graphQLErrors),
    );
  }, [deleteAppFromDesktopUseCase, currentDesktop, app.id]);

  const handleDeleteCustomAppClick = useCallback(() => {
    onDeleteCustomAppClick(app);
  }, [app, onDeleteCustomAppClick]);

  const handleEditCustomAppClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editAppId: app._id,
      }),
    });
  }, [app, navigate, queryParams]);

  return {
    canRemoveCustomApp,
    addAppToDesktopLoading,
    deleteAppFromDesktopLoading,
    handleAddClick,
    handleDeleteClick,
    handleDeleteCustomAppClick,
    handleEditCustomAppClick,
  };
};
