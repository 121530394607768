import {
  StyledLinkCardsBlock,
  StyledLinksBlock,
} from './AppsViewSkeleton.styled';
import { SkeletonBox } from '../../../../../shared/components/SkeletonBox';
import React from 'react';
import { AppsHeader } from './AppsViewSkeleton';
import { useTheme } from 'styled-components';
import { LinksSectionSkeleton } from '../../../../Link/LinksView/LinksViewSkeleton';
import { ListContainer } from '../../../../Link/LinksView/SectionLinks/components/LinksList/LinksList.styled';

export const LinksViewSkeleton = () => {
  const { colors } = useTheme();

  return (
    <>
      <AppsHeader buttonsLength={5} />
      <StyledLinksBlock>
        <div className="links">
          <SkeletonBox width={4} height={4} color={colors.LinksViewSkeleton} />
          <SkeletonBox
            width={4}
            height={1}
            color={colors.LinksViewSkeleton}
            margin={[1, 0, 0, 0]}
          />
        </div>
        <div className="links">
          <SkeletonBox width={4} height={4} color={colors.LinksViewSkeleton} />
          <SkeletonBox
            width={4}
            height={1}
            color={colors.LinksViewSkeleton}
            margin={[1, 0, 0, 0]}
          />
        </div>
      </StyledLinksBlock>
      <StyledLinkCardsBlock>
        <ListContainer>
          <LinksSectionSkeleton />
        </ListContainer>
      </StyledLinkCardsBlock>
    </>
  );
};
