import React, { FC, memo } from 'react';
import { ChatMessageViewThreadViewAuthorsCounterStyled } from './ChatMessageThread.styled';

export interface ChatMessageViewThreadViewAuthorsCounterProps {
  count: number;
}

export const ChatMessageViewThreadViewAuthorsCounter: FC<ChatMessageViewThreadViewAuthorsCounterProps> =
  memo(({ count }) => {
    return (
      <ChatMessageViewThreadViewAuthorsCounterStyled
        avatarSize="1.5rem"
        overlaySize="1.5rem">
        +{count}
      </ChatMessageViewThreadViewAuthorsCounterStyled>
    );
  });
