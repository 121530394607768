import React, { FC, RefObject, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountApiType, AccountOrigin } from '../../../../User/User.types';
import { ChatAvatar } from '../../../ChatAvatar';
import {
  AvatarPlaceholder,
  MenuButton,
  MenuContainer,
  PendingLabel,
  StyledUserListItem,
  StyledUserListItemContent,
  UserEmail,
  UserName,
  UserNameWrapper,
} from './UserListItem.styled';
import { getAccountName } from '../../../../User/User.utils';
import { DotsIcon } from '../../../../../shared/icons';
import { AccountOrEmail } from '../ConversationUsers.types';
import { ChatTranslation } from '../../../i18n';
import { IntegrationBadge } from '../../../ChatAvatar/IntegrationBadge';
import { StyledBadges } from '../../../../User/UsersAdmin/UsersList/UserListItem/UserListItem.styled';

interface UserListItemProps {
  account?: AccountApiType;
  email?: string;
  invitationId?: string;
  onMenu?: (
    accountOrEmail: AccountOrEmail,
    domRef: RefObject<HTMLElement>,
    invitationId?: string,
  ) => void;
}

export const UserListItem: FC<UserListItemProps> = ({
  account,
  email = '',
  invitationId,
  onMenu,
}) => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const isExternalUser = account?.origin !== AccountOrigin.webtopcom;
  return (
    <StyledUserListItem
      data-testid="user-list-item"
      data-entityid={account?.id}>
      {account ? (
        <ChatAvatar
          account={account}
          avatarSize="2.625rem"
          onlineStatusSize="0.875rem"
        />
      ) : (
        <AvatarPlaceholder>{email.charAt(0)}</AvatarPlaceholder>
      )}
      <StyledUserListItemContent>
        {account ? (
          <>
            <UserNameWrapper>
              <UserName data-testid="user-name">
                {getAccountName(account)}
              </UserName>
              {isExternalUser && (
                <StyledBadges>
                  <IntegrationBadge
                    origin={AccountOrigin.slack}
                    badgeSize={20}
                  />
                </StyledBadges>
              )}
            </UserNameWrapper>

            {account.origin !== AccountOrigin.slack && (
              <UserEmail data-testid="user-email">{account.email}</UserEmail>
            )}
          </>
        ) : (
          <UserEmail data-testid="user-email">
            {email}
            {invitationId && (
              <PendingLabel data-testid="pending-label">
                <FormattedMessage
                  id={ChatTranslation.conversationUsersPendingLabel}
                />
              </PendingLabel>
            )}
          </UserEmail>
        )}
      </StyledUserListItemContent>
      <MenuContainer>
        {onMenu && (
          <MenuButton
            type="button"
            ref={menuButtonRef}
            icon={DotsIcon}
            onClick={() => {
              onMenu(account ?? email, menuButtonRef, invitationId);
            }}
            data-testid="menu-button"
          />
        )}
      </MenuContainer>
    </StyledUserListItem>
  );
};
