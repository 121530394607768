import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { useQueryParams } from '../../../../shared/hooks';
import { Navigate } from 'react-router-dom';
import { useAuthService, useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
} from '../../../../shared/components/Toast';
import {
  useActivateAccountMutation,
  useResendActivateAccountMutation,
} from '../../Onboarding.hooks';
import EmailConfirmationImageUrl from './email-confirmation.jpg';
import { Spinner } from '../../../../shared/components/Spinner';
import { EmailVerificationNeeded } from './EmailVerificationNeeded';
import { EmailVerified } from './EmailVerified';
import { useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { EmailConfirmationImage } from './VerifyEmailPage.styled';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import {
  clearStoredNativePlatform,
  getStoredNativePlatform,
  isIOSNativeWrapperAvailable,
  openDeepLinkInNativeWrapper,
  useNativePlatformFromUrl,
} from '../../../NativeWrapper';

export const VerifyEmailPage: FC = () => {
  const { id, hash, email } = useQueryParams();
  const { account, refetchAccountData } = useCurrentAccount();
  const [emailVerified, setEmailVerified] = useState(false);
  const intl = useIntl();
  const [activatedEmail, setActivatedEmail] = useState<string>();

  const [activateAccountMutation] = useActivateAccountMutation();
  const activateAccount = useCallback(() => {
    activateAccountMutation({
      variables: {
        input: {
          id: '/accounts/' + id,
          activationHash: hash as string,
        },
      },
    })
      .then(res => {
        if (res.errors) {
          const message = res.errors[0].message;
          if (message === 'The activation hash you provided is not valid') {
            showToastErrorMessage(
              OnboardingTranslation.onboardingVerificationEmailExpiredMessage,
            );
          } else {
            showToastGraphQLErrors(res.errors);
          }
        }

        const activatedAccount = res.data?.activateAccount?.account;
        if (!activatedAccount) {
          return;
        }
        if (activatedAccount.email) {
          setActivatedEmail(activatedAccount.email);
        }
        if (activatedAccount.verifiedEmail) {
          setEmailVerified(true);
        }
        if (account) {
          refetchAccountData?.();
        }
      })
      .catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
      });
  }, [id, hash, account, activateAccountMutation, refetchAccountData]);

  const [resendActivateAccountMutation] = useResendActivateAccountMutation();
  const resendActivateAccount = useCallback(() => {
    return resendActivateAccountMutation({
      variables: {
        input: {
          id: id ? '/accounts/' + id : '/accounts/' + account!.id,
          email: (email as string) || account!.email,
        },
      },
    });
  }, [id, email, account, resendActivateAccountMutation]);

  const platformFromUrl = useNativePlatformFromUrl();
  const authService = useAuthService();

  const [canGoNext, setCanGoNext] = useState(true);

  const handleNextClick = useCallback(() => {
    if (isIOSNativeWrapperAvailable()) {
      authService.login({
        loginHint: activatedEmail,
      });
      return;
    }
    const nativePlatform = getStoredNativePlatform() || platformFromUrl;
    if (nativePlatform) {
      clearStoredNativePlatform();
      if (activatedEmail) {
        openDeepLinkInNativeWrapper(
          nativePlatform,
          `login/email?email=${activatedEmail}`,
        );
      }
      return;
    }
    authService.login({
      loginHint: activatedEmail,
    });
    setCanGoNext(false);
  }, [activatedEmail, authService, platformFromUrl]);

  const emailResendAvailable = useMemo(() => {
    return !!(id && email) || !!(account?.id && account?.email);
  }, [id, email, account]);

  useEffect(() => {
    if (id && hash && !emailVerified) {
      activateAccount();
    }
  }, [id, hash, emailVerified]); // eslint-disable-line

  const displayIllustration = !emailVerified && !id && !hash;

  if (!emailVerified && account?.verifiedEmail) {
    return <Navigate to="/" />;
  }

  const ActivateAccountPageBanner = () => (
    <EmailConfirmationImage
      src={EmailConfirmationImageUrl}
      alt={intl.formatMessage({
        id: OnboardingTranslation.onboardingActivationPageImage,
      })}
    />
  );

  return (
    <>
      <HeadTitle
        translationId={OnboardingTranslation.onboardingActivationPageTitle}
      />
      <OnboardingPage
        before={displayIllustration && ActivateAccountPageBanner}
        displayAccountSection={!emailVerified}>
        {id && hash ? (
          <>
            {emailVerified ? (
              <EmailVerified
                onNextClick={handleNextClick}
                canGoNext={canGoNext}
              />
            ) : (
              <Spinner />
            )}
          </>
        ) : (
          <EmailVerificationNeeded
            onResendEmail={resendActivateAccount}
            emailResendAvailable={emailResendAvailable}
          />
        )}
      </OnboardingPage>
    </>
  );
};
