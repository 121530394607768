import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ManageRelatedTeamsModal } from '../../../../UserModals/ManageRelatedTeamsModal';
import { useModalControls } from '../../../../../../shared/components/Modal/Modal.hooks';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { getListChanges } from '../../../../../../shared/utils/list.utils';
import { showToastGraphQLErrors } from '../../../../../../shared/components/Toast';
import {
  Section,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../../Admin';
import { getAccountName } from '../../../../User.utils';
import { UserTranslation } from '../../../../i18n';
import { RelatedTeamsList } from '../../../../../Team/ManageRelatedTeams';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import type { DesktopAccessType } from '../../../../../Desktop/data/Desktop/types/Desktop.types';
import type { AccountApiType } from '../../../../User.types';
import type { TeamApiType } from '../../../../../Team/data/Team/types/Team.types';
import {
  addRelatedAccountsToTeamsIDDB,
  removeRelatedAccountsFromTeamsIDDB,
} from '../../../../../Team/data/AccountTeam/operations/AccountTeamIDDB.oprations';
import {
  addRelatedTeamsToAccountsIDDB,
  removeRelatedTeamsFromAccountsIDDB,
} from '../../../../../Account/data/TeamAccounts/operations/TeamAccount.operations';
import {
  addAccountToTeamRestApi,
  deleteAccountFromTeamRestApi,
} from '../../../../../Team/data/AccountTeam/operations/AccountTeamRest.operations';

interface TeamsProps {
  relatedTeams: (TeamApiType & {
    accessType?: DesktopAccessType;
  })[];
  account: AccountApiType;
  onTeamListUpdate: () => void;
}

export const TeamsSection: FC<TeamsProps> = ({
  account,
  relatedTeams,
  onTeamListUpdate,
}) => {
  const intl = useIntl();
  const { askConfirmation } = useConfirm();
  const relatedTeamsModal = useModalControls();

  const handleManageTeamsSubmit = useCallback(
    (selectedTeams: TeamApiType[]) => {
      const { added, removed } = getListChanges(relatedTeams, selectedTeams);

      addRelatedAccountsToTeamsIDDB(
        added.map(team => team.id),
        [account.id],
      );
      addRelatedTeamsToAccountsIDDB(
        [account.id],
        added.map(team => team.id),
      );

      removeRelatedAccountsFromTeamsIDDB(
        removed.map(team => team.id),
        [account.id],
      );
      removeRelatedTeamsFromAccountsIDDB(
        [account.id],
        removed.map(team => team.id),
      );

      Promise.all([
        ...added.map((team: TeamApiType) => {
          return addAccountToTeamRestApi(account.id, team.id);
        }),
        ...removed.map((team: TeamApiType) => {
          return deleteAccountFromTeamRestApi(account.id, team.id);
        }),
      ])
        .then(() => onTeamListUpdate())
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });

      relatedTeamsModal.close();
    },
    [relatedTeams, account, relatedTeamsModal, onTeamListUpdate],
  );

  const handleDeleteRelatedTeamClick = useCallback(
    (team: TeamApiType) => {
      askConfirmation(
        intl.formatMessage({
          id: UserTranslation.adminUserTeamRemoveConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        removeRelatedAccountsFromTeamsIDDB([team.id], [account.id]);
        removeRelatedTeamsFromAccountsIDDB([account.id], [team.id]);

        deleteAccountFromTeamRestApi(account.id, team.id)
          .then(() => onTeamListUpdate())
          .catch(e => {
            showToastGraphQLErrors(e.graphQLErrors);
          });
      });
    },
    [askConfirmation, intl, account, onTeamListUpdate],
  );

  return (
    <>
      <Section data-testid="teams">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={UserTranslation.adminTeamsHeader} />
          </SectionHeaderTitle>
          <SectionHeaderButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: UserTranslation.tooltipManageUserGroup,
            })}
            data-tooltip-place={TooltipPlace.top}
            onClick={relatedTeamsModal.open}
            data-testid="manage-button">
            <FormattedMessage id={UserTranslation.adminManageTeamsButton} />
          </SectionHeaderButton>
        </SectionHeader>
        <RelatedTeamsList
          teams={relatedTeams}
          emptyMessage={intl.formatMessage({
            id: UserTranslation.adminTeamsEmptyMessage,
          })}
          onRemove={handleDeleteRelatedTeamClick}
          hideSystemTeamsRemoveButtons
        />
      </Section>

      <ManageRelatedTeamsModal
        subtitle={getAccountName(account)}
        relatedTeams={relatedTeams}
        onSubmit={handleManageTeamsSubmit}
        visible={relatedTeamsModal.visible}
        onRequestClose={relatedTeamsModal.close}
      />
    </>
  );
};
