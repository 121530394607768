import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledTimelineEvent = styled.div`
  display: flex;
  padding: 8px 8px 8px 0;
`;

export const TimelineEventImageContainer = styled.div`
  flex: 0 0 14px;
  margin-right: 8px;
  padding-top: 2px;
`;

export const TimelineEventImage = styled.img`
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
  cursor: pointer;
`;

export const TimelineEventInfo = styled.div``;

export const TimelineEventTitleWrapper = styled.h4`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  .highlighted {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    word-break: break-word;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  a.highlighted:not(.inactive):hover {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const TimelineContent = css`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 1.2;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const TimelineCreatedBy = styled.span`
  ${TimelineContent};
  margin-right: 3px;
`;

export const TimelineEventDate = styled.span`
  ${TimelineContent}
`;

export const TimelineMenuButton = styled(ButtonWithIcon)`
  padding: 0;
  width: 28px;
  height: 28px;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfaceSelected};
  }

  svg {
    width: 16px;
    height: 16px;
  }

  span ~ svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`;

export const TimeLineLabelInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
