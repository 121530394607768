import styled from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';
import {
  CONFERENCE_MESSAGE_HEIGHT,
  CONFERENCE_MOBILE_MESSAGE_HEIGHT,
} from '../ChatMessage.constants';

export const StyledConferenceMessage = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 24rem;
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.colors.BorderLight};
  background-color: ${p => p.theme.colors.Surface};
  height: ${p =>
    p.isMobile
      ? CONFERENCE_MOBILE_MESSAGE_HEIGHT
      : CONFERENCE_MESSAGE_HEIGHT}px;

  header {
    padding: 1rem;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    display: flex;
    align-items: center;
  }

  .divider {
    height: 1px;
    background-color: ${p => p.theme.colors.BorderLight};
  }

  footer {
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const IconWrapper = styled.div<{
  active: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: ${p =>
    p.active ? p.theme.colors.BackgroundLight : p.theme.colors.CriticalLight};
  color: ${p =>
    p.active
      ? p.theme.colors.OnSurfaceMediumEmphasis
      : p.theme.colors.PrimaryTextLight};
  margin-right: 0.75rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

export const JoinButton = styled(Button)`
  border-color: ${p => p.theme.colors.SuccessDark};
  background-color: ${p => p.theme.colors.SuccessDark};
  color: ${p => p.theme.colors.PrimaryTextLight};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${p => p.theme.colors.OnNavbar} !important;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const ParticipantsCounter = styled.div`
  color: #687787;
  font-size: 0.875rem;
`;

export const StyledNoParticipantsInConference = styled.div`
  ${({ theme }) => theme.fontTemplates.regular};
  display: flex;
  width: 100%;
`;

export const StyledConferenceMessageSpacer = styled.div`
  height: 0.625rem;
`;
