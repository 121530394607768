import React, { FC, PropsWithChildren, useState } from 'react';
import {
  ChatMessageContext,
  ChatMessageContextProps,
} from './ChatMessage.context';

export const ChatMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messageContext, setMessageContext] =
    useState<ChatMessageContextProps>();

  const toggleReplyForm = (messageId: string, visible: boolean) => {
    setMessageContext({ messageId, replyFormIsVisible: visible });
  };

  const replyFormVisible = (chatMessageId: string) =>
    (messageContext?.messageId === chatMessageId &&
      messageContext?.replyFormIsVisible) ||
    false;

  const setMessageIsEditing = (messageId: string, state: boolean) => {
    setMessageContext(prevState => ({
      ...prevState,
      messageId,
      isEditing: state,
    }));
  };

  const messageIsEditing = (messageId?: string) =>
    (messageContext?.messageId === messageId && messageContext?.isEditing) ??
    false;

  return (
    <ChatMessageContext.Provider
      value={{
        toggleReplyForm,
        replyFormVisible,
        setMessageIsEditing,
        messageIsEditing,
      }}>
      {children}
    </ChatMessageContext.Provider>
  );
};
