import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const AppIntegrationIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.17 14H6.75A4.75 4.75 0 0 1 2 9.25v-.5a.75.75 0 0 1 1.5 0v.5a3.25 3.25 0 0 0 3.25 3.25h3.291a3 3 0 1 1 .13 1.5zM1 0a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3.75h3.25A3.25 3.25 0 0 1 12.5 7v.5a.75.75 0 0 0 1.5 0V7a4.75 4.75 0 0 0-4.75-4.75H6V1a1 1 0 0 0-1-1H1z"
        fill="currentColor"
        fillOpacity=".87"
      />
    </svg>
  );
});
