import React, { FC } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { generatePath, Navigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { BillingTranslation } from '../../../Billing/i18n';
import { BillingPageBanner } from './BillingPageBanner';
import { AddPaymentMethod } from '../../../Billing/BillingModals/UpgradeFreeModal/AddPaymentMethod/AddPaymentMethod';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { OnboardingTranslation } from '../../i18n';
import { ImportantMessageType } from '../../../../shared/components/ImportantMessage';
import { BillingImportantMessage } from './BillingPage.styled';
import { getShortId } from '../../../../shared/utils/id';

export const BillingPage: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const intl = useIntl();

  if (
    !workspace.billingRequired ||
    (workspace.billingRequired && workspace.billingOk)
  ) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <OnboardingPage after={BillingPageBanner}>
      <OnboardingStep data-testid="billing-step">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage id={BillingTranslation.onboardingBillingTitle} />
        </OnboardingStepHeader>

        <BillingImportantMessage type={ImportantMessageType.INFO}>
          <FormattedMessage
            id={BillingTranslation.onboardingChangeCardInfoMessage}
          />
        </BillingImportantMessage>

        <AddPaymentMethod
          ctaText={intl.formatMessage({
            id: OnboardingTranslation.billingPageSubmitButton,
          })}
          withCurrentTheme={false}
        />
      </OnboardingStep>
    </OnboardingPage>
  );
};
