import React, { FC } from 'react';
import { ConferenceTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  LimitReachedBannerControls,
  LimitReachedBannerMessage,
  StyledLimitReachedBanner,
} from './LimitReachedBanner.styled';

interface LimitReachedBannerProps {
  limit: number;
}

export const LimitReachedBanner: FC<LimitReachedBannerProps> = ({ limit }) => {
  const canClose = !!window.opener;
  return (
    <StyledLimitReachedBanner>
      <FormattedMessage
        tagName={LimitReachedBannerMessage}
        id={ConferenceTranslation.limitReachedBannerMessage}
        values={{
          limit,
        }}
      />
      <LimitReachedBannerControls>
        {canClose && (
          <Button
            size={ButtonSize.md}
            mode={ButtonMode.secondary}
            onClick={() => window.close()}>
            <FormattedMessage
              id={ConferenceTranslation.limitReachedBannerCloseButton}
            />
          </Button>
        )}
        <Button
          size={ButtonSize.md}
          mode={ButtonMode.primary}
          onClick={() => window.location.reload()}>
          <FormattedMessage
            id={ConferenceTranslation.limitReachedBannerRetryButton}
          />
        </Button>
      </LimitReachedBannerControls>
    </StyledLimitReachedBanner>
  );
};
