import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const VideoCallIcon: FC<IconProps> = props => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.750001C16 0.479801 15.8547 0.230481 15.6195 0.097351C15.3844 -0.035779 15.0958 -0.032139 14.8641 0.106881L11 2.42536V1.75C11 0.783501 10.2165 1.08059e-06 9.25 1.08059e-06H1.75C0.783502 1.08059e-06 0 0.783501 0 1.75V8.25C0 9.2165 0.783502 10 1.75 10H9.25C10.2165 10 11 9.2165 11 8.25V7.5746L14.8641 9.8931C15.0958 10.0321 15.3844 10.0358 15.6195 9.9026C15.8547 9.7695 16 9.5202 16 9.25V0.750001ZM11 5.82536L14.5 7.9254V2.07464L11 4.17464V5.82536ZM9.5 3.75V1.75C9.5 1.61193 9.38807 1.5 9.25 1.5H1.75C1.61193 1.5 1.5 1.61193 1.5 1.75V8.25C1.5 8.3881 1.61193 8.5 1.75 8.5H9.25C9.38807 8.5 9.5 8.3881 9.5 8.25V6.25V3.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
