import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FullScreenStyled,
  LeftSideControllersStyled,
  MuteButtonStyled,
  PlayPauseStyled,
  PlayTimerContainerStyled,
  PlayTimerSeparatorStyled,
  RightSideControllersStyled,
  SeekBarStyled,
  TimeBlockStyled,
  VideoControlsInnerContainer,
  VideoControlsStyled,
  VolumeSeekBarContainerStyled,
} from './VideoPlayer.controls.styled';
import {
  FullScreenIcon,
  PauseIcon,
  PlayIcon,
  SoundDisabledIcon,
  SoundEnabledIcon,
} from '../icons';
import { addSeconds, format } from 'date-fns';
import {
  PlayerProgressProps,
  VideoPlayerProgress,
} from './VideoPlayer.progress';
import { VideoPlayerTranslation } from '../i18n';
import { useIntl } from 'react-intl';

type VideoPlayerControlsProps = {
  player: HTMLVideoElement;
};

enum PlayerVolume {
  muted = 0,
  unmuted = 1,
}

export interface PlayerControlsProps {
  togglePlay: () => void;
}

export const VideoPlayerControls = forwardRef<
  PlayerControlsProps,
  VideoPlayerControlsProps
>(({ player }, ref) => {
  const [pause, setPause] = useState(!player.paused);

  const progressRef = useRef<PlayerProgressProps>(null);

  const fullscreenButtonRef = useRef<HTMLButtonElement>(null);

  const timeLapsRef = useRef<HTMLDivElement>(null);

  const volumeSeekBarRef = useRef<HTMLInputElement>(null);

  const [volume, setVolume] = useState(player.volume);

  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    togglePlay,
  }));

  const togglePlay = useCallback(() => {
    setPause(!pause);

    if (player.paused) {
      return player.play();
    }

    return player.pause();
  }, [pause, player]);

  const keyboardHandler = useCallback(
    (e: KeyboardEvent) => {
      // NEED TO BE TESTED ON ALL BROWSERS = { CHROME: TESTED/WORKS }
      if (e.key === 'k' || e.code === 'Space') {
        togglePlay();
      }
    },
    [togglePlay],
  );

  const formatTime = useCallback((timeInSeconds: number) => {
    return format(addSeconds(0, timeInSeconds), 'm:ss');
  }, []);

  const timeElapsed = useCallback(() => {
    if (!timeLapsRef.current) {
      return '';
    }

    timeLapsRef.current.innerText = formatTime(Math.round(player.currentTime));

    progressRef.current?.updateProgress();
  }, [formatTime, player.currentTime]);

  const timeupdate = useCallback(() => {
    if (player.ended) {
      setPause(false);
    }
    timeElapsed();
  }, [player.ended, timeElapsed]);

  const videoTime = useMemo(() => {
    const videoDuration = Math.round(player.duration);
    const time = formatTime(videoDuration);

    return time;
  }, [formatTime, player.duration]);

  // TODO FOR FULLSCREEN (NEED TO BE COMPLETED)
  const updateFullscreenButton = useCallback(() => {
    if (!fullscreenButtonRef.current) {
      return;
    }

    const fullScreenIcons = fullscreenButtonRef.current.querySelectorAll('use');

    fullScreenIcons.forEach(icon => icon.classList.toggle('hidden'));

    if (document.fullscreenElement) {
      fullscreenButtonRef.current.setAttribute(
        'data-title',
        `${intl.formatMessage({
          id: VideoPlayerTranslation.playerPlayButton,
        })}`,
      );
    } else {
      fullscreenButtonRef.current.setAttribute('data-title', 'Full screen (f)');
    }
  }, [intl]);

  const initListeners = useCallback(() => {
    document.addEventListener('fullscreenchange', updateFullscreenButton);
    document.addEventListener('keydown', keyboardHandler);
    player.addEventListener('timeupdate', timeupdate);
  }, [keyboardHandler, player, timeupdate, updateFullscreenButton]);

  const deactivateListeners = useCallback(() => {
    document.removeEventListener('fullscreenchange', updateFullscreenButton);
    document.removeEventListener('keydown', keyboardHandler);
    player.removeEventListener('timeupdate', timeupdate);
  }, [keyboardHandler, player, timeupdate, updateFullscreenButton]);

  useEffect(() => {
    initListeners();

    return () => {
      deactivateListeners();
    };
  }, [deactivateListeners, initListeners]);

  const toggleFullscreen = useCallback(() => {
    //TODO: NEED TO FIX TYPES -- NEED TO ADD -- declare global {
    const document: any = window.document;
    const vidPlayer: any = player;

    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
      // Need this to support Safari
      document.webkitExitFullscreen();
    } else if (vidPlayer.webkitRequestFullscreen) {
      // Need this to support Safari
      vidPlayer.webkitRequestFullscreen();
    } else {
      vidPlayer.requestFullscreen();
    }
    updateFullscreenButton();
  }, [player, updateFullscreenButton]);

  const toggleMute = useCallback(() => {
    if (player.muted) {
      setVolume(1);
      return (player.muted = false);
    }
    player.muted = true;
    setVolume(0);
  }, [player]);

  const handleHoverMute = useCallback(() => {
    if (!volumeSeekBarRef.current) {
      return null;
    }

    if (volumeSeekBarRef.current.style.display === 'none') {
      volumeSeekBarRef.current.style.display = 'block';
    } else {
      volumeSeekBarRef.current.style.display = 'none';
    }
  }, []);

  const renderProgress = useMemo(
    () => (
      <VideoPlayerProgress
        ref={progressRef}
        player={player}
        playerDurationRef={timeLapsRef}
      />
    ),
    [player],
  );

  const onVolumeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      player.volume = Number(event.target.value);

      if (player.volume === PlayerVolume.muted) {
        setVolume(PlayerVolume.muted);
      } else {
        setVolume(PlayerVolume.unmuted);
      }
    },
    [player],
  );

  return (
    <VideoControlsStyled data-testid="video-controls">
      {renderProgress}
      <VideoControlsInnerContainer data-testid="video-controls-inner-container">
        <LeftSideControllersStyled data-testid="video-controls-left-container">
          <PlayPauseStyled
            data-title={
              !pause
                ? `${intl.formatMessage({
                    id: VideoPlayerTranslation.playerPauseButton,
                  })}`
                : `${intl.formatMessage({
                    id: VideoPlayerTranslation.playerPlayButton,
                  })}`
            }
            icon={pause ? PauseIcon : PlayIcon}
            data-testid="player-play-button"
            onClick={togglePlay}
          />

          <PlayTimerContainerStyled data-testid="play-timer-container">
            <TimeBlockStyled ref={timeLapsRef} />
            <PlayTimerSeparatorStyled>/</PlayTimerSeparatorStyled>
            <TimeBlockStyled>{videoTime}</TimeBlockStyled>
          </PlayTimerContainerStyled>
        </LeftSideControllersStyled>

        <RightSideControllersStyled data-testid="video-controls-right-container">
          <VolumeSeekBarContainerStyled
            data-testid="volume-seek-bar-container"
            onMouseEnter={handleHoverMute}
            onMouseLeave={handleHoverMute}>
            <SeekBarStyled
              data-testid="volume-seek-bar"
              ref={volumeSeekBarRef}
              className="volume-bar"
              width={64}
              onChange={onVolumeChange}
              type="range"
              defaultValue={volume}
              max="1"
              min="0"
              step="0.01"
            />
          </VolumeSeekBarContainerStyled>

          <MuteButtonStyled
            icon={volume === 0 ? SoundDisabledIcon : SoundEnabledIcon}
            onMouseEnter={handleHoverMute}
            onMouseLeave={handleHoverMute}
            data-testid="player-mute-button"
            onClick={toggleMute}
          />

          <FullScreenStyled
            ref={fullscreenButtonRef}
            icon={FullScreenIcon}
            data-testid="player-fullscreen-button"
            onClick={toggleFullscreen}
          />
        </RightSideControllersStyled>
      </VideoControlsInnerContainer>
    </VideoControlsStyled>
  );
});
