import React, { FC, useCallback, useRef } from 'react';
import { ConferenceApiType } from '../../Conference.types';
import { getConferencePageLink } from '../../Conference.utils';
import {
  ConferencePreviewCloseButton,
  ConferencePreviewContainer,
  ConferencePreviewControls,
  ConferencePreviewJoinButton,
  ConferencePreviewLink,
  StyledConferencePreview,
  ConferencePreviewInputCopyLinkButton,
  ConferencePreviewNewMeetingTitle,
  PreviewInputWithIcon,
} from './ConferencePreview.styled';
import { ConferenceTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import { useMobile } from '../../../../shared/hooks';
import {
  useJoinCallInNativeWrapper,
  useNativeWrapper,
} from '../../../NativeWrapper';
import { isPWAWindow } from '../../../../shared/utils/pwa.utils';

interface ConferencePreviewProps {
  conference: ConferenceApiType;
}

export const ConferencePreview: FC<ConferencePreviewProps> = ({
  conference,
}) => {
  const isMobile = useMobile();
  const link = window.location.origin + getConferencePageLink(conference.id);
  const linkInputRef = useRef<HTMLInputElement>(null);

  const { isNativeWrapperAvailable } = useNativeWrapper();
  const joinCallInNativeWrapper = useJoinCallInNativeWrapper();

  const isPWA = isPWAWindow();

  const handleJoinButtonClick: () => void = useCallback(() => {
    if (isNativeWrapperAvailable) {
      joinCallInNativeWrapper({
        roomName: conference._id,
        token: conference.jwtToken,
        callType: conference.callType,
      });
      return;
    }

    if (isPWA) {
      window.open(
        link,
        'new-window',
        `width=${window.innerWidth}, height=${window.innerHeight}`,
      );
      return;
    }

    window.open(link, '_blank');
  }, [
    conference._id,
    conference.callType,
    conference.jwtToken,
    isNativeWrapperAvailable,
    isPWA,
    joinCallInNativeWrapper,
    link,
  ]);

  const handleCopyLinkButtonClick = useCallback(() => {
    navigator.clipboard.writeText(link).then(() => {
      showToastSuccessMessage(ConferenceTranslation.previewLinkCopiedMessage);
    });
  }, [link]);

  const handleCloseButton = useCallback(() => {
    window.close();
    setTimeout(() => {
      window.location.href = '/';
    }, 250);
  }, []);

  const handleLinkClick = useCallback(() => {
    linkInputRef.current?.select();
  }, []);

  const renderTitle = (
    <ConferencePreviewNewMeetingTitle>
      <FormattedMessage id={ConferenceTranslation.previewNewMeetingTitle} />
    </ConferencePreviewNewMeetingTitle>
  );

  const renderInput = (
    <PreviewInputWithIcon onClick={handleLinkClick} value={link}>
      <ConferencePreviewInputCopyLinkButton
        onClick={handleCopyLinkButtonClick}
      />
    </PreviewInputWithIcon>
  );

  return (
    <ConferencePreviewContainer>
      <StyledConferencePreview>
        {renderTitle}
        <ConferencePreviewLink onClick={handleLinkClick}>
          {renderInput}
        </ConferencePreviewLink>
        <ConferencePreviewControls>
          {isMobile && (
            <ConferencePreviewCloseButton onClick={handleCloseButton}>
              <FormattedMessage id={ConferenceTranslation.previewCloseButton} />
            </ConferencePreviewCloseButton>
          )}
          <ConferencePreviewJoinButton onClick={handleJoinButtonClick}>
            <FormattedMessage id={ConferenceTranslation.previewJoinButton} />
          </ConferencePreviewJoinButton>
        </ConferencePreviewControls>
      </StyledConferencePreview>
    </ConferencePreviewContainer>
  );
};
