import React, { FC, memo, useMemo } from 'react';
import { convertToMessageParts } from './RichMessage.utils';
import { MessagePartTypes } from './RichMessage.types';
import { Mention } from './Mention';

import RichMessageUrl from './RichMessageUrl';
import { RichMessageText } from './RichMessageText';

export const RichMessage: FC<{
  children: string;
  isSystemMessage?: boolean;
}> = memo(({ children, isSystemMessage }) => {
  const messageParts = useMemo(
    () => convertToMessageParts(children),
    [children],
  );

  return (
    <>
      {messageParts.map(({ type, value }, i) => {
        if (type === MessagePartTypes.Mention) {
          return <Mention key={`mention_${i}_${value}`}>{value}</Mention>;
        }
        if (type === MessagePartTypes.Link) {
          return <RichMessageUrl url={value} key={`link_${i}_${value}`} />;
        }
        return isSystemMessage ? (
          <span
            key={`system_message_${i}_${value}`}
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />
        ) : (
          <RichMessageText
            key={`rich_message_text_${i}_${value}`}
            value={value}
          />
        );
      })}
    </>
  );
});
