import React, { FC, useCallback, useState } from 'react';
import { Modal, ModalHeader } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../i18n';
import { CreateCustomApp } from '../../Forms/CustomAppForm';
import { CreateCustomAppModalBody } from './CreateCustomAppModal.styled';
import { CustomAppPreview } from '../../Forms/CustomAppForm/CreateCustomApp/CustomAppPreview';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import type { DesktopAppApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface CreateCustomAppModalProps {
  showBannerOnDone?: boolean;
  onDone: (app?: DesktopAppApiType) => Promise<unknown> | void;
  onClose?: () => void;
}

export const CreateCustomAppModal: FC<CreateCustomAppModalProps> = ({
  showBannerOnDone = true,
  onDone,
  onClose,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [createdApp, setCreatedApp] = useState<DesktopAppApiType | null>(null);

  const onRequestClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createCustomAppModal: undefined,
      }),
    });
    onClose?.();
  }, [onClose, navigate, queryParams]);

  const handleCustomAppCreated = useCallback(
    async (app: DesktopAppApiType) => {
      await onDone(app);
      if (showBannerOnDone) {
        setCreatedApp(app);
      } else {
        onRequestClose();
      }
    },
    [onDone, onRequestClose, showBannerOnDone],
  );

  return (
    <Modal visible={true} width={486} onRequestClose={onRequestClose}>
      {createdApp ? (
        <CreateCustomAppModalBody>
          <CustomAppPreview app={createdApp} onRequestClose={onRequestClose} />
        </CreateCustomAppModalBody>
      ) : (
        <>
          <ModalHeader onRequestClose={onRequestClose}>
            <FormattedMessage
              id={AppStoreTranslation.createCustomAppModalHeader}
            />
          </ModalHeader>
          <CreateCustomAppModalBody>
            <CreateCustomApp onDone={handleCustomAppCreated} />
          </CreateCustomAppModalBody>
        </>
      )}
    </Modal>
  );
};
