import styled from 'styled-components';

export const StyledTimelineDateRange = styled.div`
  .rdrInputRange:last-child {
    display: none;
  }

  .rdrDateRangePickerWrapper {
    flex-direction: column-reverse;

    ${({ theme: { breakpoints } }) => breakpoints.md`
      flex-direction: row;
    `}
  }

  .rdrStaticRange {
    background: ${p => p.theme.colors.Surface};
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    border-bottom: 1px solid ${p => p.theme.colors.BorderDefault};
  }

  .rdrStaticRangeSelected {
    color: ${p => p.theme.colors.Primary}!important;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel {
    background: ${p => p.theme.colors.OnSurfaceHover};
  }

  .rdrStaticRange:focus .rdrStaticRangeLabel {
    background: ${p => p.theme.colors.SurfaceSubdued};
  }

  .rdrDefinedRangesWrapper {
    background: ${p => p.theme.colors.Surface};
    border-right: solid 1px ${p => p.theme.colors.BorderDefault};
  }

  .rdrDateDisplayWrapper {
    background: ${p => p.theme.colors.Surface};
  }

  .rdrCalendarWrapper {
    background: ${p => p.theme.colors.Surface};
  }

  .rdrWeekDay {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  .rdrDay {
    span {
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: ${p => p.theme.colors.Primary}!important;
  }

  .rdrSelected ~ .rdrDayNumber span {
    color: ${p => p.theme.colors.PrimaryTextLight}!important;
  }

  .rdrDayNumber {
    span::after {
      color: ${p => p.theme.colors.Primary};
    }
  }

  .rdrDayDisabled {
    background-color: ${p => p.theme.colors.SurfaceSubdued};

    span {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis}!important;
    }
  }

  .rdrInputRangeInput {
    background: ${p => p.theme.colors.SurfaceSubdued};
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    border: 1px solid ${p => p.theme.colors.BorderDefault};
  }

  .rdrDayPassive {
    background-color: ${p => p.theme.colors.SurfaceSubdued};

    span {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis}!important;
    }
  }

  .rdrMonthAndYearWrapper {
    button {
      background: ${p => p.theme.colors.Primary};
    }

    .rdrPprevButton {
      i {
        border-color: transparent ${p => p.theme.colors.IconLight} transparent
          transparent;
      }
    }

    .rdrNextButton {
      i {
        border-color: transparent transparent transparent
          ${p => p.theme.colors.IconLight};
      }
    }
  }

  .rdrDateDisplay {
    span {
      background: ${p => p.theme.colors.Primary};

      input {
        color: ${p => p.theme.colors.PrimaryTextLight};
      }

      &.rdrDateDisplayItemActive {
        outline: none;
        background-color: ${p => p.theme.colors.PrimaryDarker};
      }
    }
  }

  .rdrDateDisplayItem {
    border: none;
    box-shadow: none;
  }

  .rdrMonthPicker {
    select {
      color: ${p => p.theme.colors.PrimaryTextLight};
      background: ${p => p.theme.colors.Primary};
      padding: 0.625rem 1.25rem;
      line-height: 1;
    }
  }

  .rdrYearPicker {
    select {
      color: ${p => p.theme.colors.PrimaryTextLight};
      background: ${p => p.theme.colors.Primary};
      padding: 0.625rem 1.25rem;
      line-height: 1;
    }
  }

  .rdrDateDisplay span:not(.rdrDateDisplayItemActive),
  .rdrMonthAndYearWrapper button,
  .rdrMonthPicker select,
  .rdrYearPicker select {
    &:hover {
      background-color: ${p => p.theme.colors.PrimaryDark};
    }

    &:focus:not(:focus-visible),
    &:active {
      outline: none;
      background-color: ${p => p.theme.colors.PrimaryDarker};
    }
  }

  .rdrDateDisplay span,
  .rdrMonthAndYearWrapper button {
    &:focus-visible {
      outline: none;
      background-color: ${p => p.theme.colors.Primary};
      border: 2px solid ${p => p.theme.colors.PrimaryLight};
    }
  }
`;
