import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  AdminHeader,
  AdminHeaderSubtitle,
  AdminHeaderTitle,
  AdminHeaderTitleContainer,
  AdminPage,
  DrawerHeader,
} from '..';
import {
  NotificationsIntegrations,
  NotificationsIntegrationsApiType,
  useUpdateWorkspaceNotificationsIntegrationsMutation,
  useUpdateWorkspaceNotificationsSettingMutation,
  WorkspaceNotificationsApiType,
  WorkspaceNotificationsFormValues,
} from '.';
import { useMobile } from '../../../shared/hooks';
import { WorkspaceTranslation } from '../../Workspace/i18n';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { GlobalTranslation } from '../../Intl/i18n';
import {
  WorkspaceContext,
  WorkspaceContextType,
} from '../../Workspace/Workspace.context';
import { GraphQLError } from 'graphql';
import { WorkspaceNotificationsForm } from './WorkspaceNotificationsForm';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import { AccountApiType, AccountOrigin } from '../../User/User.types';
import { WorkspaceNotificationsIntegrations } from './WorkspaceNotificationsIntegrations';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { WORKSPACE_PATHNAME } from '../../Workspace/Workspace.constants';
import { useAccountsRepository } from '../../Account/data/Account/Account.repositories';
import { getShortId } from '../../../shared/utils/id';

export const WorkspaceNotifications: FC = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { refetchWorkspaces, identityWorkspaces } = useContext(
    WorkspaceContext,
  ) as Partial<WorkspaceContextType>;
  const { account } = useCurrentWorkspaceAccount();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);
  const { pathname } = useLocation();

  const workspaceId = workspace.id;

  const accountWorkspace = useMemo(() => {
    return identityWorkspaces?.find(
      identityWorkspace => identityWorkspace.workspace.id === workspaceId,
    );
  }, [identityWorkspaces, workspaceId]);

  const { accounts, fetchAccounts } = useAccountsRepository({
    variables: {
      workspaceId,
    },
  });

  const integrationsAccount: AccountApiType[] = useMemo(
    () =>
      accounts
        .filter(Boolean)
        .filter(item => item.origin === AccountOrigin.bot)
        .filter(item => item.id !== account.id),
    [account.id, accounts],
  );

  const settings = useMemo(
    () => accountWorkspace && accountWorkspace.accountWorkspaceSetting,
    [accountWorkspace],
  );

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const [updateAccountWorkspaceSetting] =
    useUpdateWorkspaceNotificationsSettingMutation();

  const [updateIntegrationsAccountWorkspaceSetting] =
    useUpdateWorkspaceNotificationsIntegrationsMutation();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleSubmit = useCallback(
    (values: WorkspaceNotificationsFormValues) => {
      setIsSubmiting(true);
      updateAccountWorkspaceSetting({
        variables: {
          input: {
            id: values.id,
            messagesNotifications: values.messagesNotifications,
            generalBellNotificationsEnable: Boolean(
              values.generalBellNotificationsEnable,
            ),
            billingNotificationsEnable: Boolean(
              values.billingNotificationsEnable,
            ),
            upcomingMeetingNotificationsEnable: Boolean(
              values.upcomingMeetingNotificationsEnable,
            ),
            soundsEnable: Boolean(values.soundsEnable),
            useGlobalSettings: Boolean(values.useGlobalSettings),
          },
        },
      })
        .then(async () => {
          await refetchWorkspaces?.();
          showToastSuccessMessage(GlobalTranslation.toastSaveMessage);
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        })
        .finally(() => {
          setIsSubmiting(false);
        });
    },
    [refetchWorkspaces, updateAccountWorkspaceSetting],
  );

  const handleIntegrationsSubmit = useCallback(
    (values: NotificationsIntegrationsApiType) => {
      setIsSubmiting(true);
      updateIntegrationsAccountWorkspaceSetting({
        variables: {
          input: values,
        },
      })
        .then(async () => {
          await fetchAccounts();
          showToastSuccessMessage(GlobalTranslation.toastSaveMessage);
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        })
        .finally(() => {
          setIsSubmiting(false);
        });
    },
    [fetchAccounts, updateIntegrationsAccountWorkspaceSetting],
  );

  const integrationValue = (integration: AccountApiType): boolean => {
    const { integrationsNotificationsSettings } =
      settings as WorkspaceNotificationsApiType;

    if (!integrationsNotificationsSettings) {
      return true;
    }

    const integrations = Object.keys(integrationsNotificationsSettings);

    return integrations.every((key: string) => {
      if (key === integration.id) {
        return integrationsNotificationsSettings[key as keyof Object];
      }

      return true;
    });
  };

  const integrations = (): NotificationsIntegrations[] => {
    if (settings) {
      return integrationsAccount.map(intergration => ({
        id: settings.id,
        botAccount: intergration.id,
        value: integrationValue(intergration),
        name: intergration.firstName,
        image: intergration.image?.contentUrl || '',
      }));
    }

    return [];
  };

  return (
    <AdminPage data-testid="workspace-notifications">
      {isMobile ? (
        <DrawerHeader
          onRequestClose={() =>
            navigate(
              generatePath(WORKSPACE_PATHNAME, {
                workspaceId: getShortId(workspace.id),
              }),
            )
          }>
          <FormattedHTMLMessage
            id={WorkspaceTranslation.adminNavUserNotificationsLink}
          />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitleContainer>
            <AdminHeaderTitle data-testid="title">
              <FormattedMessage
                id={WorkspaceTranslation.adminNavUserNotificationsLink}
              />
            </AdminHeaderTitle>
            <AdminHeaderSubtitle data-testid="subtitle">
              <FormattedMessage
                id={WorkspaceTranslation.adminNavUserNotificationsLinkSubTitle}
                values={{
                  workspace: workspace?.name,
                }}
              />
            </AdminHeaderSubtitle>
          </AdminHeaderTitleContainer>
        </AdminHeader>
      )}

      {settings && (
        <>
          <WorkspaceNotificationsForm
            settings={settings}
            onSubmit={handleSubmit}
            isSubmitting={isSubmiting}
          />

          <WorkspaceNotificationsIntegrations
            integrations={integrations()}
            onSubmit={handleIntegrationsSubmit}
            isSubmitting={isSubmiting}
          />
        </>
      )}
    </AdminPage>
  );
};
