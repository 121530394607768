import React from 'react';
import ReactDOM from 'react-dom';
import { usePreview } from 'react-dnd-preview';
import {
  DragAppItemType,
  DragFolderItemType,
  DragLinkItemType,
  DragSortItemType,
  DragType,
} from '../Drag.types';
import { LinkPreview } from './LinkPreview/LinkPreview';
import { FolderPreview } from './FolderPreview/FolderPreview';
import { AppPreview } from './AppPreview/AppPreview';
import { WorkspacePreview } from './WorkspacePreview/WorkspacePreview';
import { DesktopPreview } from './DesktopPreview/DesktopPreview';
import { getRestrictedAxisStyle } from '../Drag.utils';

export const DraggedPreview = () => {
  const { display, item, style } = usePreview<
    DragLinkItemType | DragFolderItemType | DragAppItemType | DragSortItemType
  >();

  if (!display || !item) {
    return null;
  }

  switch (item.type) {
    case DragType.LINK:
      return ReactDOM.createPortal(
        <div style={{ ...style, zIndex: 5000 }}>
          <LinkPreview item={item as DragLinkItemType} />
        </div>,
        document.getElementById('dnd') as Element,
      );
    case DragType.FOLDER:
      return ReactDOM.createPortal(
        <div style={{ ...style, zIndex: 5000 }}>
          <FolderPreview item={item as DragFolderItemType} />
        </div>,
        document.getElementById('dnd') as Element,
      );
    case DragType.APP:
      return ReactDOM.createPortal(
        <div style={{ ...style, zIndex: 5000 }}>
          <AppPreview item={item as DragAppItemType} />
        </div>,
        document.getElementById('dnd') as Element,
      );
    case DragType.WORKSPACE: {
      const restrictedStyle = {
        ...style,
        ...getRestrictedAxisStyle(style, item.initialOffset?.x),
      };

      return ReactDOM.createPortal(
        <div style={{ ...restrictedStyle, zIndex: 5000 }}>
          <WorkspacePreview item={item} />
        </div>,
        document.getElementById('dnd') as Element,
      );
    }
    case DragType.PRIVATE_DESKTOPS_LIST:
    case DragType.INTERNAL_DESKTOPS_LIST:
    case DragType.SHARED_DESKTOPS_LIST: {
      const restrictedStyle = {
        ...style,
        ...getRestrictedAxisStyle(style, item.initialOffset?.x),
      };

      return ReactDOM.createPortal(
        <div style={{ ...restrictedStyle, zIndex: 5000 }}>
          <DesktopPreview item={item} />
        </div>,
        document.getElementById('dnd') as Element,
      );
    }
    default:
      return null;
  }
};
