import React, { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  StyledAppContentSkeleton,
  StyledAppShellSkeleton,
  StyledFallbackSkeleton,
} from './AppShellSkeleton.styled';
import { DesktopViewSkeleton } from '../../Desktop/DesktopView/DesktopViewSkeleton';
import { AppTopBarSkeleton } from '../../AppTopBar/AppTopBarSkeleton';
import { WorkspaceSwitcherSkeleton } from '../../Workspace/WorkspaceSwitcher/WorkspaceSwitcherSkeleton';
import { SpinnerFullscreen } from '../../../shared/components/SpinnerFullscreen';
import { useMobile } from '../../../shared/hooks';
import { SegmentSkeleton } from '../../Segment/SegmentSkeleton';

interface AppShellSkeletonProps {
  message?: string;
}

export const AppShellSkeleton: FC<AppShellSkeletonProps> = memo(
  ({ message }) => {
    const { pathname } = useLocation();
    const isMobile = useMobile();

    if (
      pathname === '/' ||
      (pathname.includes('/workspace') && !pathname.includes('/admin'))
    ) {
      return (
        <StyledAppShellSkeleton>
          <AppTopBarSkeleton />
          <StyledAppContentSkeleton>
            {!isMobile && (
              <>
                <WorkspaceSwitcherSkeleton />
                <SegmentSkeleton />
              </>
            )}
            <DesktopViewSkeleton />
          </StyledAppContentSkeleton>
        </StyledAppShellSkeleton>
      );
    }

    return (
      <StyledFallbackSkeleton>
        <SpinnerFullscreen size={48}>{message}</SpinnerFullscreen>
      </StyledFallbackSkeleton>
    );
  },
);
