import { useCallback } from 'react';
import { getLongId } from '../../../shared/utils/id';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { createFolderRepository } from '../Data/Repository/FolderRepository';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';

export const useCaseCreateFolder = () => {
  const { workspace } = useCurrentWorkspace();

  const createFolderUseCase = useCallback(
    async (name: string, desktopId: string, parentId?: string) => {
      const response = await createFolderRepository(
        getLongId('workspaces', workspace.id),
        name,
        getLongId('desktops', desktopId),
        parentId && getLongId('folders', parentId),
      );

      if (response.errors) {
        showToastGraphQLErrors(response.errors);
      }

      return response;
    },
    [workspace.id],
  );

  return {
    createFolderUseCase,
  };
};
