import React, { FC, useEffect, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { useCurrentConference } from '../../../../Conference.hooks';

interface ConferenceDurationCounterProps {
  startedAt: string;
}

export const ConferenceDurationCounter: FC<ConferenceDurationCounterProps> = ({
  startedAt,
}) => {
  const { conference } = useCurrentConference();
  const [durationInWords, setDurationInWords] = useState('');

  useEffect(() => {
    if (conference) {
      const conferenceStart = new Date(startedAt);
      setDurationInWords(formatDistanceToNowStrict(conferenceStart));

      const interval = setInterval(() => {
        const conferenceStart = new Date(startedAt);
        setDurationInWords(formatDistanceToNowStrict(conferenceStart));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [conference, startedAt]);

  return <>{durationInWords}</>;
};
