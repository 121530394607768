import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { SortableItem } from '../../../../Drag';
import {
  DesktopName,
  ExternallySharedLabel,
  StyledDesktopListItem,
} from './DesktopList.styled';
import { NavigatorLink } from '../../../../Segment/Temp/NavigatorLink';
import { generatePath, useLocation } from 'react-router-dom';
import {
  useCurrentDesktopIdFromTheUrl,
  useIsSharedExternally,
  useMoveAppMutation,
} from '../../../Desktop.hooks';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../i18n';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { DESKTOP_ID_PATHNAME } from '../../../Desktop.constants';
import { useMobile } from '../../../../../shared/hooks';
import { getShortId } from '../../../../../shared/utils/id';
import { DragEndEvent, useDroppable } from '@dnd-kit/core';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { useConfirm } from '../../../../../shared/components/Modal';
import { useWorkspaceDndContext } from '../../../../Workspace/WorkspaceDndProvider/WorkspaceDnd.hooks';
import { APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID } from '../../../Views/AppsLayoutGrid/AppsLayoutGrid';
import { DesktopApiType } from '../../../data/Desktop/types/Desktop.types';

type PropsType = {
  desktop: DesktopApiType;
};

export const DesktopListItem: FC<PropsType> = ({ desktop }) => {
  const { workspace } = useCurrentWorkspace();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const isMobile = useMobile();
  const currentOpenedDesktopId = useCurrentDesktopIdFromTheUrl();

  const isSharedExternally = useIsSharedExternally();
  const desktopIsSharedExtarnally = useMemo(() => {
    return isSharedExternally(desktop);
  }, [desktop, isSharedExternally]);

  const desktopPath = useMemo(
    () =>
      generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: getShortId(desktop.id),
      }),
    [desktop.id, workspace.id],
  );

  // dnd
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: getShortId(desktop.id) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const {
    setNodeRef: setDroppableNodeRef,
    over,
    active,
  } = useDroppable({
    id: 'droppable_' + getShortId(desktop.id),
  });
  const isDraggingOver =
    over?.id === getShortId(desktop.id) &&
    active?.data?.current?.sortable?.containerId ===
      APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID;

  const [moveAppMutation] = useMoveAppMutation();
  const moveApp = useCallback(
    (appStoreAppId: string) => {
      return moveAppMutation({
        variables: {
          input: {
            id: currentOpenedDesktopId!,
            app: appStoreAppId,
            toDesktop: desktop.id!,
          },
        },
      })
        .then(() =>
          showToastSuccessMessage(DesktopTranslation.movedAppToDesktopSuccess, {
            desktopName: desktop.name,
          }),
        )
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    },
    [desktop, moveAppMutation, currentOpenedDesktopId],
  );

  const { askConfirmation } = useConfirm();
  const handleAppDroppedIntoDesktop = useCallback(
    (e: DragEndEvent) => {
      const appName = e.active?.data?.current?.appName as string;
      const appStoreAppId = e.active?.data?.current?.appStoreAppId as string;
      askConfirmation(
        <FormattedHTMLMessage
          id={DesktopTranslation.moveItemToDesktopConfirmation}
          values={{
            itemName: appName,
            desktopName: desktop.name,
          }}
        />,
        formatMessage({ id: DesktopTranslation.moveAppConfirmHeader }),
        {
          confirmButtonText: formatMessage({
            id: DesktopTranslation.moveItemToDesktopConfirmButtonText,
          }),
        },
      ).then(confirm => {
        if (!confirm) {
          return;
        }
        moveApp(appStoreAppId as string);
      });
    },
    [desktop, moveApp, askConfirmation, formatMessage],
  );

  const { setAppDroppedToDesktopHandler } = useWorkspaceDndContext();
  useEffect(() => {
    setAppDroppedToDesktopHandler({
      desktopId: desktop.id,
      handler: handleAppDroppedIntoDesktop,
    });
  }, [handleAppDroppedIntoDesktop, setAppDroppedToDesktopHandler]); // eslint-disable-line

  // dnd end

  if (isMobile) {
    return (
      <StyledDesktopListItem
        data-tourid="desktop-link"
        data-testid="desktop-link">
        <NavigatorLink
          to={desktopPath}
          isDragging={isDragging}
          desktopName={desktop.name}
          desktopId={desktop.id}
          dropZone={false}
          isActive={location.pathname.includes(desktopPath)}>
          <DesktopName data-testid="desktop-name">{desktop.name}</DesktopName>

          {desktopIsSharedExtarnally && (
            <ExternallySharedLabel
              visible={true}
              data-testid="externally-shared"
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: DesktopTranslation.tooltipExt,
              })}
              data-tooltip-place={TooltipPlace.right}>
              <FormattedMessage
                id={DesktopTranslation.listExternallySharedDesktopLabel}
              />
            </ExternallySharedLabel>
          )}
        </NavigatorLink>
      </StyledDesktopListItem>
    );
  }

  return (
    <SortableItem
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      data-sortindex={desktop.accountSortPosition}
      {...attributes}
      {...listeners}>
      <StyledDesktopListItem
        data-tourid="desktop-link"
        data-testid="desktop-link"
        ref={setDroppableNodeRef}
        data-entityid={desktop.id}>
        <NavigatorLink
          dropZone
          to={desktopPath}
          isDragging={isDragging}
          isDraggingOver={isDraggingOver}
          desktopName={desktop.name}
          desktopId={desktop.id}
          isActive={location.pathname.includes(desktopPath)}>
          <DesktopName data-testid="desktop-name">{desktop.name}</DesktopName>

          {desktopIsSharedExtarnally && (
            <ExternallySharedLabel
              visible={true}
              data-testid="externally-shared"
              data-tooltip-id="global-tooltip"
              data-tooltip-content={formatMessage({
                id: DesktopTranslation.tooltipExt,
              })}
              data-tooltip-place={TooltipPlace.right}>
              <FormattedMessage
                id={DesktopTranslation.listExternallySharedDesktopLabel}
              />
            </ExternallySharedLabel>
          )}
        </NavigatorLink>
      </StyledDesktopListItem>
    </SortableItem>
  );
};
