import styled from 'styled-components';

const HEADER_HEIGHT = 100;

export const StyledAppsList = styled.div<{ isMobile: boolean }>`
  position: relative;
  max-height: calc(100% - ${HEADER_HEIGHT}px);
  overflow: auto;
  height: ${p => `${p.isMobile ? '60' : '55'}px !important`};
  margin: 0.5rem;
  margin-right: 0;
  padding-right: 0.5rem;
`;
