import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { getGlideImageSrcSet } from '../../../../shared/utils/url.utils';
import { ChatTranslation } from '../../i18n';
import { getAccountName } from '../../../User/User.utils';
import { AccountApiType } from '../../../User/User.types';
import { StyledAvatarImage } from './AvatarImage.styled';

const IMAGE_SIZE = 64;

interface AvatarImageProps {
  account: AccountApiType;
}

export const AvatarImage: FC<AvatarImageProps> = ({ account }) => {
  const intl = useIntl();
  if (!account.image) {
    return null;
  }
  return (
    <StyledAvatarImage
      src={account.image.contentUrl}
      srcSet={getGlideImageSrcSet(account.image.contentUrl, {
        w: IMAGE_SIZE,
        h: IMAGE_SIZE,
        fit: 'crop-center',
      })}
      alt={intl.formatMessage(
        {
          id: ChatTranslation.chatAvatarAlt,
        },
        {
          accountName: getAccountName(account),
        },
      )}
      data-testid="avatar-image"
    />
  );
};
