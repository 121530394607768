import { SpecialMentionType } from '../../../domains/Chat/Chat.types';
import { escapeStringRegex } from '../../utils/regex.utils';
import { SpecialMention } from './Editor.types';

export const MESSAGE_INPUT_MENTION_REGEX = /^@(\w+)$/;
export const MESSAGE_INPUT_EMOJI_REGEX = /^:(\w+)$/;

export const MENTION_TRIGGER_SYMBOL = '@';

export const EMOJI_LENGTH_IN_EDITOR = 2;

export const EMOTICONS_REPLACE_MAP: Record<string, string> = {
  ':o)': '🐵',
  '=)': '😃',
  '=-)': '😃',
  'C:': '😄',
  'c:': '😄',
  ':D': '😄',
  ':-D': '😄',
  ':>': '😆',
  ':->': '😆',
  ':)': '🙂',
  '(:': '🙂',
  ':-)': '🙂',
  ';)': '😉',
  ';-)': '😉',
  ':*': '😘',
  ':-*': '😘',
  ':p': '😛',
  ':-p': '😛',
  ':P': '😛',
  ':-P': '😛',
  ':b': '😛',
  ':-b': '😛',
  ';p': '😜',
  ';-p': '😜',
  ';b': '😜',
  ';-b': '😜',
  ';P': '😜',
  ';-P': '😜',
  ':|': '😐',
  ':-|': '😐',
  '8)': '😎',
  ':\\': '😕',
  ':-\\': '😕',
  ':/': '😕',
  ':-/': '😕',
  ':o': '😮',
  ':-o': '😮',
  ':O': '😮',
  ':-O': '😮',
  'D:': '😧',
  ":'(": '😢',
  '):': '😞',
  ':(': '😞',
  ':-(': '😞',
  '>:(': '😠',
  '>:-(': '😠',
  '</3': '💔',
  '<3': '❤️',
};

export const EMOTICONS_REPLACE_REGEX = new RegExp(
  `(^|\\s)(${Object.keys(EMOTICONS_REPLACE_MAP)
    .map(escapeStringRegex)
    .join('|')})($|\\s)`,
  'g',
);

export const SPECIAL_MENTION_HERE: SpecialMention = {
  id: SpecialMentionType.Here,
  type: SpecialMentionType.Here,
  value: SpecialMentionType.Here,
};

export const SPECIAL_MENTION_CHANNEL: SpecialMention = {
  id: SpecialMentionType.Channel,
  type: SpecialMentionType.Channel,
  value: SpecialMentionType.Channel,
};
