import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { AuthService } from './Auth.service';
import { AuthContext } from './Auth.context';
import { AUTH_CONFIG, AuthConfigResponse } from './Auth.mutations';
import { REQUIRE_MFA_SCOPE } from '../Onboarding/Onboarding.constants';
import { isMfaRequired } from './Auth.utils';
import { AppShellSkeleton } from '../AppRoot/AppShellSkeleton';
import { AUTH0_REDIRECT_URI } from './Auth.constants';
import {
  useIsPersistedStorageAvailable,
  usePersistedAuthConfig,
  usePersistedStorage,
} from '../PersistedStorage';
import { ApiProvider } from '../Api';
import { useNativeWrapper } from '../NativeWrapper/NativeWrapper.hooks';
import { NativeWrapperAuthService } from '../NativeWrapper/Auth';
import { AuthSessionHandler } from './Auth.types';
import { captureException } from '../ErrorInterceptor';
import { showReloadAppToast } from '../../shared/components/Toast';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { storage } = usePersistedStorage();
  const [authConfig, setAuthConfig] = usePersistedAuthConfig();
  const {
    isNativeWrapperAvailable,
    sendMessageToNativeWrapper,
    callRefreshToken,
    callTriggerMfa,
    initialAccessToken,
  } = useNativeWrapper();

  const [authService, setAuthService] = useState<AuthSessionHandler | null>(
    null,
  );

  const fetchAuthConfig = useCallback(() => {
    return fetch(process.env.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: AUTH_CONFIG,
        variables: {
          input: {},
        },
      }),
    })
      .then((response): Promise<{ data?: AuthConfigResponse }> => {
        if (response.status === 307) {
          showReloadAppToast();
        }
        return response.json();
      })
      .then(response => {
        if (response.data) {
          setAuthConfig(response.data.authConfig.config);
          return;
        }
        throw new Error('Failed to fetch auth config');
      })
      .catch(e => {
        captureException(e);
      });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isNativeWrapperAvailable) {
      setAuthService(
        new NativeWrapperAuthService(
          sendMessageToNativeWrapper,
          callRefreshToken,
          callTriggerMfa,
          initialAccessToken,
        ),
      );
      return;
    }

    if (authConfig) {
      return;
    }

    fetchAuthConfig();
    // eslint-disable-next-line
  }, [
    callRefreshToken,
    callTriggerMfa,
    initialAccessToken,
    isNativeWrapperAvailable,
    sendMessageToNativeWrapper,
    fetchAuthConfig,
  ]);

  const isPersistedStorageAvailable = useIsPersistedStorageAvailable();

  useEffect(() => {
    if (authConfig) {
      createAuth0Client({
        domain: authConfig.domain,
        client_id: authConfig.clientId,
        audience: authConfig.audience,
        redirect_uri: AUTH0_REDIRECT_URI,
        scope: isMfaRequired() ? REQUIRE_MFA_SCOPE : undefined,
        cache: isPersistedStorageAvailable
          ? {
              async set(key, entry) {
                await storage.setItem(key, entry);
              },
              get(key) {
                return storage.getItem(key);
              },
              async remove(key) {
                await storage.removeItem(key);
              },
            }
          : undefined,
      }).then(auth0Client => {
        setAuthService(new AuthService(auth0Client));
      });
    }
  }, [authConfig, isPersistedStorageAvailable, storage]);

  if (!authService) {
    return <AppShellSkeleton />;
  }

  return (
    <AuthContext.Provider
      value={{
        authService,
        fetchAuthConfig,
      }}>
      <ApiProvider>{children}</ApiProvider>
    </AuthContext.Provider>
  );
};
