import { useCallback } from 'react';
import {
  editLinkRepository,
  moveLinksRepository,
} from '../Data/Repository/LinkRepository';
import { EditLinkInputVariables } from '../Data/Operations/Mutations/EditLink.mutation';
import { getLongId } from '../../../shared/utils/id';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { LinkApiType } from '../Link.types';
import { database } from '../../Database';

export const useCaseEditLink = () => {
  const { workspace } = useCurrentWorkspace();

  const editLinkUseCase = useCallback(
    async (link: LinkApiType, data: Omit<EditLinkInputVariables, 'id'>) => {
      const response = await editLinkRepository(
        getLongId('links', link.id),
        getLongId('workspaces', workspace.id),
        data,
      );

      // Subtract the link from linkCount in previous folder
      if (
        // The link was moved from a folder to the desktop root
        (link.folder?.id && data.folder === null) ||
        // The link was moved to a folder from another folder
        (link.folder?.id && data.folder && link.folder.id !== data.folder)
      ) {
        database.desktopFolders.get(link.folder.id).then(folder => {
          if (!folder) {
            return;
          }
          const currentLinksCount = folder.sourceData.linksCount;
          database.desktopFolders.update(folder.id, {
            'sourceData.linksCount': Math.max(0, currentLinksCount - 1),
          });
        });
      }

      // Add the link to linkCount for new folder
      if (data.folder && response.data?.editLink.link.folder?.id) {
        database.desktopFolders
          .get(response.data.editLink.link.folder.id)
          .then(folder => {
            if (!folder) {
              return;
            }
            const currentLinksCount = folder.sourceData.linksCount;
            database.desktopFolders.update(folder.id, {
              'sourceData.linksCount': currentLinksCount + 1,
            });
          });
      }

      return response;
    },
    [workspace.id],
  );

  return { editLinkUseCase };
};

export const useCaseMoveLink = () => {
  const moveLinksToFolder = useCallback(
    async (linkIds: string[], folderId: string | null) => {
      const response = await moveLinksRepository(
        linkIds.map(linkId => getLongId('links', linkId)),
        undefined,
        folderId ? getLongId('folders', folderId) : undefined,
      );

      // Add the link to linkCount for new folder
      if (folderId && !response.errors) {
        database.desktopFolders.get(folderId).then(folder => {
          if (!folder) {
            return;
          }
          const currentLinksCount = folder.sourceData.linksCount;
          database.desktopFolders.update(folder.id, {
            'sourceData.linksCount': currentLinksCount + linkIds.length,
          });
        });
      }

      return response;
    },
    [],
  );

  const moveLinkToDesktop = useCallback(
    async (linkIds: string[], desktopId: string) => {
      const response = await moveLinksRepository(
        linkIds.map(linkId => getLongId('links', linkId)),
        getLongId('desktops', desktopId),
      );

      return response;
    },
    [],
  );

  return { moveLinksToFolder, moveLinkToDesktop };
};

export const useCasePasteLink = () => {
  const pasteLinks = useCallback(
    async (linkIds: string[], desktopId?: string, folderId?: string) => {
      const response = await moveLinksRepository(
        linkIds.map(linkId => getLongId('links', linkId)),
        desktopId ? getLongId('folders', desktopId) : undefined,
        folderId ? getLongId('folders', folderId) : undefined,
        true,
      );

      return response;
    },
    [],
  );

  return { pasteLinks };
};
