import React from 'react';
import type { ChatMessagesTableType } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import type { AccountWithCountsApiType } from '../../../../User/User.types';
import type { ChatMessageInternalType } from '../../../Chat.types';

interface ChatMessageThreadContextType {
  threadMessages: ChatMessagesTableType[];
  threadMessageAuthors: AccountWithCountsApiType[];
  chatMessage?: ChatMessageInternalType | null;
}

export const ChatMessageThreadContext =
  React.createContext<ChatMessageThreadContextType>({
    threadMessages: [],
    threadMessageAuthors: [],
    chatMessage: undefined,
  });
