import styled from 'styled-components';

export const NotificationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 25px 0;
`;

export const NotificationWrapper = styled.div`
  font-size: 0.9375rem;
  margin-bottom: 40px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button ~ button {
    margin-left: 1rem;
  }
`;
