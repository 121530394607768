import styled, { css } from 'styled-components';

const TABS_HEIGHT = 54;

export const StyledScheduledConferencesList = styled.ul<{ isEmpty: boolean }>`
  position: relative;
  padding: 0.625rem;
  height: calc(100% - ${TABS_HEIGHT}px);
  overflow: auto;

  overflow: auto;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const StyledListTitle = styled.li`
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
