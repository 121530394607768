import React from 'react';
import { FolderApiType } from '../../../Folder/Folder.types';
import { LinkApiType } from '../../Link.types';

interface LinkActionsContextValue {
  onLinkCopy: (link: LinkApiType) => Promise<void>;
  onLinkMoveTo: (link: LinkApiType, folder?: FolderApiType) => Promise<void>;
  onLinkDelete: (link: LinkApiType) => Promise<void>;

  // mutliselect
  onSelectClick: (linkId: string) => void;
  onUnselectClick: (linkId: string) => void;
  onSelectAllClick: () => void;
  onUnselectAllClick: () => void;
}

export const LinkActionsContext = React.createContext<LinkActionsContextValue>({
  onLinkCopy: () => Promise.resolve(),
  onLinkMoveTo: () => Promise.resolve(),
  onLinkDelete: () => Promise.resolve(),
  onSelectClick: () => {},
  onUnselectClick: () => {},
  onSelectAllClick: () => {},
  onUnselectAllClick: () => {},
});
