import React, { memo } from 'react';
import { HoverPreviewContentArrowDarkIcon } from '../../shared/icons/HoverPreviewContentArrowDarkIcon';
import { HoverPreviewContentArrowIcon } from '../../shared/icons/HoverPreviewContentArrowIcon';
import type { SegmentType } from '../Segment';
import { getCurrentThemeType, ThemeType } from '../Theme';
import { PreviewSegmentProvider } from './PreviewSegment.provider';
import {
  PreviewSegmentArrowContainer,
  PreviewSegmentContainer,
} from './PreviewSegment.styled';

import { PreviewSegmentRenderer } from './PreviewSegmentRenderer';

const RESERVED_HEIGHT = 26;

interface PreviewSegmentProps {
  contentType: SegmentType;
  hoverRef: HTMLElement | null;
  navigateToSegment: (segment: SegmentType, isPreviewMode?: boolean) => void;
}

export const PreviewSegment = memo(
  ({ contentType, hoverRef, navigateToSegment }: PreviewSegmentProps) => {
    const currentThemeType = getCurrentThemeType();

    if (!hoverRef) {
      return null;
    }

    const arrowRenderer =
      currentThemeType === ThemeType.dark ? (
        <HoverPreviewContentArrowDarkIcon />
      ) : (
        <HoverPreviewContentArrowIcon />
      );

    const arrowPositionFromTop =
      hoverRef.getBoundingClientRect().top - RESERVED_HEIGHT;

    return (
      <PreviewSegmentProvider
        hoverRef={hoverRef}
        navigateToSegment={navigateToSegment}>
        <PreviewSegmentContainer>
          <PreviewSegmentArrowContainer top={arrowPositionFromTop}>
            {arrowRenderer}
          </PreviewSegmentArrowContainer>

          <PreviewSegmentRenderer contentType={contentType} />
        </PreviewSegmentContainer>
      </PreviewSegmentProvider>
    );
  },
);
