export const getSearchResultExcerpt = (
  searchQuery: string,
  textToHighlight: string,
) => {
  const positionOfSearchWord = textToHighlight
    .toLocaleLowerCase()
    .indexOf(searchQuery.toLocaleLowerCase());

  const isEmptyBeforePosition = !textToHighlight.charAt(
    positionOfSearchWord - 1,
  ).length;

  const hasMorePrevWords = positionOfSearchWord - 1 > 0;
  const numberOfPreviousWords = isEmptyBeforePosition ? 3 : 4;

  const previousWords = hasMorePrevWords
    ? textToHighlight
        .slice(0, positionOfSearchWord)
        .split(' ')
        .slice(-numberOfPreviousWords)
        .join(' ')
    : '';

  const sentence =
    previousWords +
    textToHighlight.slice(positionOfSearchWord, textToHighlight.length);

  return sentence;
};
