import styled from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';

export const EmailVerificationButton = styled(Button)`
  color: ${p => p.theme.colors.Primary};
`;

export const EmailVerificationResendBlock = styled.div`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 1rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  margin: 20px 0;
`;
