import { AccountApiType, AvailabilityStatusType } from '../../User/User.types';
import { getAccountName } from '../../User/User.utils';
import { AvailabilityAccountsType } from '../../Account/providers/AccountsProvider/AccountsProvider.types';

const OnlineStatusWeights: Record<AvailabilityStatusType, number> = {
  [AvailabilityStatusType.Online]: 3,
  [AvailabilityStatusType.Away]: 2,
  [AvailabilityStatusType.Busy]: 1,
  [AvailabilityStatusType.Invisible]: 0,
  [AvailabilityStatusType.Offline]: 0,
};

export const sortMembersByAvailabilityAndName = (
  a: AccountApiType,
  b: AccountApiType,
  accountsWithAvailability: AvailabilityAccountsType,
) => {
  const aOnlineStatus =
    accountsWithAvailability[a.id]?.onlineStatus ??
    AvailabilityStatusType.Offline;
  const aAvailability = OnlineStatusWeights[aOnlineStatus] ?? 0;

  const bOnlineStatus =
    accountsWithAvailability[b.id]?.onlineStatus ??
    AvailabilityStatusType.Offline;
  const bAvailability = OnlineStatusWeights[bOnlineStatus] ?? 0;

  const availabilityDiff = bAvailability - aAvailability;
  if (availabilityDiff !== 0) {
    return availabilityDiff;
  }

  const aName = getAccountName(a) ?? '';
  const bName = getAccountName(b) ?? '';

  return aName.localeCompare(bName, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};
