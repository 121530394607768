import React, { FC, useMemo, useRef } from 'react';
import { AppStoreAppApiType } from '../../AppStore.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../i18n';
import { MeatballsMenuIcon } from '../../../../shared/icons';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { getImageSrcSet } from '../../../../shared/utils/url.utils';
import { useMobile } from '../../../../shared/hooks';
import { setDefaultImage } from '../../../../shared/utils/image.utils';
import UploadImagePlaceholder from '../../../../shared/assets/images/image-upload.svg';
import {
  AppLogo,
  CardCategory,
  CardData,
  CardTitle,
  MenuButton,
  StyledAppCard,
} from './AppCard.styled';
import { AppCardViewModel } from './ViewModel';

const APP_LOGO_SIZE = 32;

interface AppStoreAppProps {
  index: number;
  totalApps: number;
  app: AppStoreAppApiType;
  added?: boolean;
  width?: number;
  onDeleteCustomAppClick: (app: AppStoreAppApiType) => void;
}

export const AppCard: FC<AppStoreAppProps> = React.memo(
  ({ index, totalApps, app, added, width, onDeleteCustomAppClick }) => {
    const isMobile = useMobile();
    const appMenuRef = useRef(null);

    const {
      canRemoveCustomApp,
      addAppToDesktopLoading,
      deleteAppFromDesktopLoading,
      handleAddClick,
      handleDeleteClick,
      handleDeleteCustomAppClick,
      handleEditCustomAppClick,
    } = AppCardViewModel(app, onDeleteCustomAppClick);

    const menuVerticalAlign = useMemo(
      () =>
        index + 1 < totalApps - (totalApps % 2 === 0 ? 1 : 0)
          ? VerticalAlignment.BOTTOM
          : VerticalAlignment.TOP,
      [index, totalApps],
    );

    const menuHorizontalAlign = useMemo(
      () =>
        index % 2 || isMobile
          ? HorizontalAlignment.RIGHT
          : HorizontalAlignment.LEFT,
      [index, isMobile],
    );

    return (
      <StyledAppCard width={width} data-testid="app" data-entityid={app.id}>
        <AppLogo
          size={APP_LOGO_SIZE}
          src={app.logo?.contentUrl}
          srcSet={getImageSrcSet(app.logo?.contentUrl, APP_LOGO_SIZE)}
          data-testid="logo"
          onError={e =>
            setDefaultImage(e.target, UploadImagePlaceholder, APP_LOGO_SIZE)
          }
        />

        <CardData data-testid="data">
          <CardTitle data-testid="name">{app.fullName}</CardTitle>
          <CardCategory data-testid="category">
            {app.category?.name}
          </CardCategory>
        </CardData>

        {added === undefined ? null : added ? (
          <Button
            disabled={addAppToDesktopLoading || deleteAppFromDesktopLoading}
            data-testid="app-card-delete-button"
            mode={ButtonMode.secondary}
            size={ButtonSize.sm}
            onClick={handleDeleteClick}>
            <FormattedMessage id={AppStoreTranslation.appCardDeleteButton} />
          </Button>
        ) : (
          <Button
            disabled={addAppToDesktopLoading || deleteAppFromDesktopLoading}
            data-testid="app-card-add-button"
            mode={ButtonMode.secondary}
            size={ButtonSize.sm}
            onClick={handleAddClick}>
            <FormattedMessage id={AppStoreTranslation.appCardAddButton} />
          </Button>
        )}
        {app.custom && (
          <MenuButton
            ref={appMenuRef}
            icon={MeatballsMenuIcon}
            size={ButtonSize.sm}
            data-testid="app-card-menu"
          />
        )}

        <Menu
          trigger={appMenuRef}
          vAlign={menuVerticalAlign}
          hAlign={menuHorizontalAlign}>
          {/* TODO: Add separate permission condition for edit option when API will support it */}
          <MenuItem
            onClick={handleEditCustomAppClick}
            data-testid="custom-app-edit-button">
            <FormattedMessage id={AppStoreTranslation.editCustomAppButton} />
          </MenuItem>
          {app.custom && canRemoveCustomApp && (
            <MenuItem
              onClick={handleDeleteCustomAppClick}
              data-testid="custom-app-delete-button">
              <FormattedMessage
                id={AppStoreTranslation.deleteCustomAppButton}
              />
            </MenuItem>
          )}
        </Menu>
      </StyledAppCard>
    );
  },
);
