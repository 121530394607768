import React, { ChangeEvent, FC } from 'react';
import { StyledToggle } from './Toggle.styled';

export interface ToggleProps {
  label?: string;
  id?: string;
  disabled?: boolean;
  value?: boolean;
  onChange?: (value?: ChangeEvent) => void;
}

export const Toggle: FC<ToggleProps> = props => {
  const { id, label, disabled, value, onChange } = props;

  return (
    <StyledToggle>
      <label htmlFor={id ?? 'toggle-button'} className="text">
        {label ?? ''}
      </label>
      <input
        disabled={disabled ?? false}
        checked={value}
        onChange={onChange}
        type="checkbox"
        id={id ?? 'toggle-button'}
        className="toggle-button"
      />
    </StyledToggle>
  );
};
