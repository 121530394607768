import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalControls } from '../../../../shared/components/Modal/Modal.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import { TeamContext } from '../../Team.context';
import { EditTeamModal } from './EditTeamModal';
import {
  AdminEntityNotFound,
  AdminHeader,
  AdminHeaderSeparator,
  AdminHeaderTitle,
  DangerZone,
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneHeaderTitle,
  DangerZoneWarning,
  GeneralInfoSubtitle,
  GeneralInfoTitle,
  RelatedDesktopsList,
  RelatedUsersList,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../Admin';
import { ManageRelatedDesktopsModal } from './ManageRelatedDesktopsModal';
import { getListChanges } from '../../../../shared/utils/list.utils';
import { useConfirm } from '../../../../shared/components/Modal';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ManageRelatedUsersModal } from './ManageRelatedUsersModal';
import { TeamTranslation } from '../../i18n';
import {
  DesktopAccessType,
  DesktopApiType,
} from '../../../Desktop/data/Desktop/types/Desktop.types';
import type {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../User/User.types';
import { useTeamRepository } from '../../data/Team/Team.repositories';
import { useTeamsActions } from '../../data/Team/Team.actions';
import { useTeamDesktopsRepository } from '../../../Desktop/data/TeamDesktop/TeamDesktops.repositories';
import { useTeamAccountsRepository } from '../../../Account/data/TeamAccounts/TeamAccounts.repository';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { getLongId } from '../../../../shared/utils/id';
import { useCurrentWorkspaceDesktopAccessesRepository } from '../../../Access/data/Access.repositories';
import { createAccessMap } from '../../../Access/data/utils/Access.utils';
import { useAccessActions } from '../../../Access/data/Access.actions';

export const TeamView: FC<{}> = () => {
  const { teamId, workspaceId } = useParams<{
    teamId: string;
    workspaceId?: string;
  }>();

  const queryParams = useQueryParams();
  const { relatedUsersModal, relatedDesktopsModal } = queryParams;
  const intl = useIntl();
  const navigate = useNavigate();

  const editTeamModal = useModalControls();

  const { askConfirmation } = useConfirm();

  const { team, loading } = useTeamRepository({
    teamId: getLongId('workspace-teams', teamId as string),
  });

  const { desktops } = useTeamDesktopsRepository({
    teamId: getLongId('workspace-teams', teamId as string),
    workspaceId: getLongId('workspaces', workspaceId as string),
  });

  const { accounts, loading: accountsLoading } = useTeamAccountsRepository({
    teamId: getLongId('workspace-teams', teamId as string),
    workspaceId: getLongId('workspaces', workspaceId as string),
  });

  const { accesses } = useCurrentWorkspaceDesktopAccessesRepository({});
  const desktopAccessesMap = useMemo(
    () => createAccessMap(accesses),
    [accesses],
  );

  const {
    addDesktopToTeamTeam,
    deleteDesktopFromTeam,
    addMemberInTeam,
    deleteMemberFromTeam,
    deleteTeam,
  } = useTeamsActions();

  const handleOpenRelatedDesktopModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        relatedDesktopsModal: true,
      }),
    });
  }, [navigate, queryParams]);

  const handleCloseRelatedDesktopModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        relatedDesktopsModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const handleManageDesktopsSubmit = useCallback(
    (selectedDesktops: DesktopApiType[]) => {
      if (!team) {
        return Promise.resolve();
      }

      const { added, removed } = getListChanges(desktops, selectedDesktops);

      return Promise.all([
        ...added.map((desktop: DesktopApiType) => {
          return addDesktopToTeamTeam(team.id, desktop.id);
        }),
        ...removed.map((desktop: DesktopApiType) => {
          return deleteDesktopFromTeam(team.id, desktop.id);
        }),
      ]).finally(() => {
        handleCloseRelatedDesktopModal();
      });
    },
    [
      team,
      desktops,
      addDesktopToTeamTeam,
      deleteDesktopFromTeam,
      handleCloseRelatedDesktopModal,
    ],
  );

  const handleDeleteTeamClick = useCallback(() => {
    if (!team) {
      return;
    }

    askConfirmation(
      intl.formatMessage({
        id: TeamTranslation.adminTeamDangerZoneDeleteConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      deleteTeam(team.id).finally(() => {
        navigate(`/workspace/${workspaceId}/admin/groups`);
      });
    });
  }, [team, askConfirmation, intl, deleteTeam, navigate, workspaceId]);

  const handleDeleteRelatedUserClick = useCallback(
    (user: AccountApiType) => {
      if (!team) {
        return;
      }

      askConfirmation(
        intl.formatMessage({
          id: TeamTranslation.adminTeamMembersRemoveConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        deleteMemberFromTeam(team.id, user.id);
      });
    },
    [team, askConfirmation, intl, deleteMemberFromTeam],
  );

  const { updateAccess } = useAccessActions();
  const handleDesktopAccessChange = (
    desktop: DesktopApiType,
    newAccessType: DesktopAccessType,
  ) => {
    const desktopAccess = desktopAccessesMap?.[desktop.id]?.find(
      access => access.workspaceTeam?.id === team?.id,
    );
    if (desktopAccess) {
      updateAccess(desktop.id, desktopAccess.id, newAccessType);
    }
  };

  const handleDeleteTeamFromDesktop = useCallback(
    (item: DesktopApiType) => {
      if (!team) {
        return;
      }

      deleteDesktopFromTeam(team.id, item.id);
    },
    [deleteDesktopFromTeam, team],
  );
  const handleOpenRelatedUsersModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        relatedUsersModal: true,
      }),
    });
  }, [navigate, queryParams]);

  const handleCloseRelatedUsersModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        relatedUsersModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const handleManageUsersSubmit = useCallback(
    (selectedUsers: AccountApiType[]) => {
      if (!team) {
        return Promise.resolve();
      }

      const { added, removed } = getListChanges(accounts, selectedUsers);

      return Promise.all([
        ...added.map((account: AccountWithCountsApiType) => {
          return addMemberInTeam(team.id, account.id);
        }),
        ...removed.map((account: AccountWithCountsApiType) => {
          return deleteMemberFromTeam(team.id, account.id);
        }),
      ]).finally(() => {
        handleCloseRelatedUsersModal();
      });
    },
    [
      team,
      accounts,
      addMemberInTeam,
      deleteMemberFromTeam,
      handleCloseRelatedUsersModal,
    ],
  );

  if (!team && loading) {
    return <Spinner />;
  }

  if (!team) {
    return (
      <AdminEntityNotFound>
        <FormattedMessage id={TeamTranslation.adminTeamNotFound} />
      </AdminEntityNotFound>
    );
  }

  return (
    <TeamContext.Provider value={team}>
      <AdminHeader data-testid="header">
        <AdminHeaderTitle data-testid="title">
          <FormattedMessage id={TeamTranslation.adminListHeaderTitle} />{' '}
          <AdminHeaderSeparator /> {team.name}
        </AdminHeaderTitle>
      </AdminHeader>

      <Section data-testid="general-info-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={TeamTranslation.adminTeamGeneralInfo} />
          </SectionHeaderTitle>
          {!team.system && (
            <SectionHeaderButton
              onClick={editTeamModal.open}
              data-testid="edit-button">
              <FormattedMessage id={TeamTranslation.adminTeamEditButton} />
            </SectionHeaderButton>
          )}
        </SectionHeader>
        <SectionBody data-testid="section-body">
          <GeneralInfoTitle data-testid="title">{team.name}</GeneralInfoTitle>
          <GeneralInfoSubtitle data-testid="subtitle">
            {team.description}
          </GeneralInfoSubtitle>
        </SectionBody>
      </Section>

      <Section data-testid="related-members-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={TeamTranslation.adminTeamMembers} />
          </SectionHeaderTitle>
          {!team.system && (
            <SectionHeaderButton
              onClick={handleOpenRelatedUsersModal}
              data-testid="manage-button">
              <FormattedMessage
                id={TeamTranslation.adminTeamMembersManageButton}
              />
            </SectionHeaderButton>
          )}
        </SectionHeader>

        {accountsLoading ? (
          <Spinner />
        ) : (
          <RelatedUsersList
            users={accounts}
            emptyMessage={intl.formatMessage({
              id: TeamTranslation.adminTeamMembersEmptyMessage,
            })}
            hideRemoveButtons={team.system}
            onRemove={handleDeleteRelatedUserClick}
          />
        )}
      </Section>

      <Section data-testid="related-desktops-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage id={TeamTranslation.adminTeamDesktops} />
          </SectionHeaderTitle>
          <SectionHeaderButton
            onClick={handleOpenRelatedDesktopModal}
            data-testid="manage-button">
            <FormattedMessage
              id={TeamTranslation.adminTeamDesktopsManageButton}
            />
          </SectionHeaderButton>
        </SectionHeader>

        <RelatedDesktopsList
          desktops={desktops}
          teamId={team?.id}
          desktopAccessesMap={desktopAccessesMap}
          emptyMessage={intl.formatMessage({
            id: TeamTranslation.adminTeamDesktopsEmptyMessage,
          })}
          onChangeAccess={handleDesktopAccessChange}
          onRemove={handleDeleteTeamFromDesktop}
        />
      </Section>

      {!team.system && (
        <Section data-testid="danger-zone-section">
          <SectionHeader data-testid="header">
            <DangerZoneHeaderTitle data-testid="title">
              <FormattedMessage id={TeamTranslation.adminTeamDangerZone} />
            </DangerZoneHeaderTitle>
          </SectionHeader>

          <SectionBody noPadding={true} data-testid="section-body">
            <DangerZone data-testid="danger-zone">
              <DangerZoneButton
                data-testid="delete-button"
                size={ButtonSize.sm}
                mode={ButtonMode.secondary}
                dangerMode={DangerZoneButtonMode.critical}
                onClick={handleDeleteTeamClick}>
                <FormattedMessage
                  id={TeamTranslation.adminTeamDangerZoneDeleteButton}
                />
              </DangerZoneButton>
              <DangerZoneWarning data-testid="delete-warning">
                <FormattedMessage
                  id={TeamTranslation.adminTeamDangerZoneDeleteWarning}
                />
              </DangerZoneWarning>
            </DangerZone>
          </SectionBody>
        </Section>
      )}

      <EditTeamModal
        visible={editTeamModal.visible}
        onRequestClose={editTeamModal.close}
      />
      <ManageRelatedDesktopsModal
        subtitle={team.name}
        relatedDesktops={desktops}
        onSubmit={handleManageDesktopsSubmit}
        visible={!!relatedDesktopsModal}
        onRequestClose={handleCloseRelatedDesktopModal}
      />
      <ManageRelatedUsersModal
        subtitle={team.name}
        relatedUsers={accounts}
        onSubmit={handleManageUsersSubmit}
        visible={!!relatedUsersModal}
        onRequestClose={handleCloseRelatedUsersModal}
      />
    </TeamContext.Provider>
  );
};
