import { NewChatConversationEvent } from '../ChatMercure.types';
import { ChatConversationApiType } from '../../../Chat/Chat.types';
import { GRAPHQL_TYPENAME_CHAT_CONVERSATION } from '../../../Chat/Chat.constants';

export const makeChatConversation = (
  chatConversationEvent: Pick<
    NewChatConversationEvent,
    | '@iri'
    | 'id'
    | 'type'
    | 'users'
    | 'conversationTitle'
    | 'lastMessageAt'
    | 'createdAt'
    | 'desktopIri'
    | 'desktop'
    | 'pendingEmails'
    | 'accountsThatPinned'
    | 'accountsThatHidden'
    | 'createdByIri'
  >,
): ChatConversationApiType => ({
  __typename: GRAPHQL_TYPENAME_CHAT_CONVERSATION,
  id: chatConversationEvent['@iri'],
  _id: chatConversationEvent.id,
  type: chatConversationEvent.type,
  users: chatConversationEvent.users,
  conversationTitle: chatConversationEvent.conversationTitle,
  lastMessageAt: chatConversationEvent.lastMessageAt,
  createdAt: chatConversationEvent.createdAt,
  desktopIri:
    chatConversationEvent.desktop?.id || chatConversationEvent.desktopIri,
  pendingEmails: chatConversationEvent.pendingEmails,
  accountsThatPinned: chatConversationEvent.accountsThatPinned,
  createdByIri: chatConversationEvent.createdByIri,
  accountsThatHidden: chatConversationEvent.accountsThatHidden,
});
