import React, { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import {
  Modal,
  ModalHeaderColor,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import {
  ButtonControls,
  StyledScheduledConferenceDeleteConfirmationModal,
  StyledScheduledConferenceMessage,
} from './ScheduledConferenceDeleteConfirmationModal.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { ScheduledConferenceDeleteConfirmationModalProps } from '../../../Conference.types';

export const ScheduledConferenceDeleteConfirmationModal: FC<
  ScheduledConferenceDeleteConfirmationModalProps
> = ({ onSubmit }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const modalBackgroundColor = theme.colors.Surface;
  const modalTextColor = theme.colors.OnSurfaceHighEmphasis;

  const onClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleConferenceDeleteConfirmationModalOpen: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const handleDeleteSingleConference = useCallback(() => {
    onSubmit(false, false);
    onClose();
  }, [onClose, onSubmit]);

  const handleDeleteAllConferences = useCallback(() => {
    onSubmit(true, false);
    onClose();
  }, [onClose, onSubmit]);

  const handleDeleteThisAndFollowingConferences = useCallback(() => {
    onSubmit(true, true);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal onRequestClose={onClose} visible width={480} containerZIndex={26}>
      <StyledScheduledConferenceDeleteConfirmationModal>
        <ModalHeaderColor
          onRequestClose={onClose}
          backgroundColor={modalBackgroundColor}
          textColor={modalTextColor}>
          <strong>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeleteConfirmationTitleRepeating
              }
            />
          </strong>
        </ModalHeaderColor>
        <StyledScheduledConferenceMessage>
          <FormattedMessage
            id={
              ConferenceTranslation.scheduledConferenceDeleteConfirmationMessageRepeating
            }
          />
        </StyledScheduledConferenceMessage>
        <ButtonControls>
          <Button
            type="button"
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={handleDeleteSingleConference}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeleteConfirmationModalUpdateOne
              }
            />
          </Button>
          <Button
            type="button"
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={handleDeleteThisAndFollowingConferences}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeleteConfirmationModalThisAndFollowing
              }
            />
          </Button>

          <Button
            type="button"
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={handleDeleteAllConferences}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceDeleteConfirmationModalUpdateAll
              }
            />
          </Button>
        </ButtonControls>
      </StyledScheduledConferenceDeleteConfirmationModal>
    </Modal>
  );
};
