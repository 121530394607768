import React, { FC, useMemo, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';
import _get from 'lodash/get';
import { GlobalTranslation } from '../../../Intl/i18n';
import { ErrorMessage } from '../../../../shared/components/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkTranslation } from '../../i18n';
import { useSearchTagsQuery } from '../../Link.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { SearchTagsResponse } from '../../Link.queries';
import {
  ReactSelectOptionsType,
  ReactSelectOptionType,
} from '../../../../shared/components/ReactSelect/ReactSelect.types';
import { compareStringsWithLowerCase } from '../../Link.utils';

interface RepeatingSelectProps extends ReactSelectOptionsType {
  label?: string;
  disabled: boolean;
  onChange: () => void;
  createTag: (value: string) => Promise<any>;
}

export const TagSelect: FC<FieldProps & RepeatingSelectProps> = ({
  options,
  field,
  form,
  disabled,
  createTag,
}) => {
  const { workspace } = useCurrentWorkspace();
  const intl = useIntl();
  const inputRef = useRef<any>(null);
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [searchTagsTimerState, setSearchTagsTimerState] =
    useState<NodeJS.Timeout>();

  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );
  const { searchTags } = useSearchTagsQuery();
  const createTagOptionValue = 'CREATE_TAG_ID';

  const filterTags = (inputValue: string, options?: SearchTagsResponse) => {
    if (!inputValue) {
      return null;
    }
    const tags = options?.searchTags.map(option => ({
      label: option.name,
      value: option.id,
    }));

    if (
      tags?.length &&
      !tags.some(tag => compareStringsWithLowerCase(tag.label, inputValue))
    ) {
      return [
        {
          label: intl.formatMessage({
            id: LinkTranslation.tagFormCreateTagOption,
          }),
          value: createTagOptionValue,
        },
        ...tags,
      ];
    } else {
      return tags;
    }
  };

  const loadOptions = (inputValue: string, callback: (arg0: any) => void) => {
    searchTagsTimerState && clearTimeout(searchTagsTimerState);

    const searchTagsTimer = setTimeout(() => {
      searchTags({
        workspace: workspace.id,
        text: inputValue,
      }).then(res => {
        callback(filterTags(inputValue, res.data));
      });
    }, 500);

    setSearchTagsTimerState(searchTagsTimer);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleOnChange = (values: any) => {
    if (
      values.find(
        (tag: ReactSelectOptionType) => tag.value === createTagOptionValue,
      )
    ) {
      handleCreateTag(inputValue);
    } else {
      form.setFieldValue(field.name, values);
      setInputValue('');
    }
  };

  const handleCreateTag = (value: string) => {
    createTag(value).then(res => {
      if (res) {
        const newTag = {
          label: res.name,
          value: res.id,
        };

        handleOnChange([...field.value, newTag]);
        inputRef.current.blur();
        inputRef.current.focus();
      }
    });
  };

  const handleAddNewOption = (event: any, value: string) => {
    if (value) {
      handleCreateTag(value);
    }
  };

  const handleKeyDown = (event: any) => {
    const {
      key,
      target: { value },
    } = event;
    switch (key) {
      case 'Enter':
        handleAddNewOption(event, value);
        break;
      case 'Tab':
        handleAddNewOption(event, value);
        break;
    }
  };

  return (
    <div data-testid="tags-select">
      <AsyncSelect
        isMulti
        cacheOptions
        data-testid="tags-selector"
        ref={inputRef}
        defaultOptions={options}
        isDisabled={disabled}
        name={field.name}
        onKeyDown={handleKeyDown}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        value={field.value ? field.value : ''}
        noOptionsMessage={() =>
          intl.formatMessage({
            id: LinkTranslation.tagsSelectNoOptionsMessage,
          })
        }
        inputValue={inputValue}
        styles={{
          input: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          singleValue: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
          }),
          menuList: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
            fontSize: '0.875rem',
            backgroundColor: theme.colors.Surface,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: theme.colors.BorderDefault,
          }),
          multiValue: styles => ({
            ...styles,
            backgroundColor: theme.colors.Background,
          }),
          multiValueLabel: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceHighEmphasis,
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: theme.colors.OnSurfaceLightEmphasis,
            ':hover': {
              color: theme.colors.OnSurfaceLightEmphasis,
            },
          }),
          control: styles => ({
            ...styles,
            fontSize: '1rem',
            fontWeight: 500,
            backgroundColor: theme.colors.Surface,
            color: theme.colors.OnSurfaceMediumEmphasis,
            borderColor: theme.colors.BorderDefault,
            ':hover': {
              borderColor: theme.colors.BorderDefault,
            },
          }),
          // TODO: fix problem with types
          // @ts-ignore
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? theme.colors.OnSurfaceSelected
                : isFocused
                ? theme.colors.OnSurfacePressed
                : null,
              color: isSelected
                ? theme.colors.OnSurfaceHighEmphasis
                : theme.colors.OnSurfaceHighEmphasis,

              ':active': {
                ...styles[':active'],
                backgroundColor: theme.colors.OnSurfaceLightEmphasis,
              },
            };
          },
        }}
      />
      {errorIdToDisplay && (
        <ErrorMessage>
          <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
        </ErrorMessage>
      )}
    </div>
  );
};
