import { useCallback } from 'react';
import { showToastSuccessMessage } from '../../../shared/components/Toast';
import { getLongId } from '../../../shared/utils/id';
import { DesktopTranslation } from '../../Desktop/i18n';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { EditFolderInputVariables } from '../Data/Operations/Mutations/EditFolder.mutation';
import { editFolderRepository } from '../Data/Repository/FolderRepository';

export const useCaseEditFolder = () => {
  const { workspace } = useCurrentWorkspace();

  const editFolderUseCase = useCallback(
    async (
      folderId: string,
      data: Omit<EditFolderInputVariables, 'id'>,
      movedToDesktopName?: string,
    ) => {
      const result = await editFolderRepository(
        getLongId('folders', folderId),
        getLongId('workspaces', workspace.id),
        data,
      );

      if (result) {
        if (data.desktop && movedToDesktopName && !result.errors?.length) {
          showToastSuccessMessage(
            DesktopTranslation.movedFolderToDesktopSuccess,
            {
              desktopName: movedToDesktopName,
            },
          );
        }
        return result;
      }
    },
    [workspace.id],
  );

  return { editFolderUseCase };
};
