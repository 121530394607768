import {
  AppVaultItemConfigApiType,
  VaultApiType,
  VaultItemApiType,
} from '../Encryption.types';
import { ApiConnection } from '../../../shared/api/api.types';
import { extractNodes } from '../../../shared/api/api.utils';
import { StreamVaultItemConfigDesktopRequestEvent } from '../../Mercure/General';
import {
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP,
  GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
} from '../Encryption.constants';

export const addVaultItemConfigDesktop = (
  vaults: VaultApiType[] = [],
  vaultId: string,
  newAppConfig: AppVaultItemConfigApiType,
): VaultApiType[] => {
  const appConfigExists = (
    configs?: ApiConnection<AppVaultItemConfigApiType>,
    configId?: string,
  ) => {
    return (
      configs && extractNodes(configs).some(config => config.id === configId)
    );
  };

  return vaults.map(vault =>
    vault.id !== vaultId
      ? vault
      : {
          ...vault,
          vaultItems: {
            ...vault.vaultItems,
            edges: [
              {
                node: {
                  ...vault.vaultItems?.edges[0].node,
                  appVaultItemConfigs: {
                    ...vault?.vaultItems?.edges[0].node.appVaultItemConfigs,
                    edges: appConfigExists(
                      vault?.vaultItems?.edges[0].node.appVaultItemConfigs,
                      newAppConfig.id,
                    )
                      ? vault?.vaultItems?.edges[0].node.appVaultItemConfigs.edges.map(
                          configNode =>
                            configNode.node.id !== newAppConfig.id
                              ? configNode
                              : {
                                  ...configNode,
                                  node: {
                                    ...configNode.node,
                                    appVaultItemConfigDesktops: {
                                      ...configNode.node
                                        .appVaultItemConfigDesktops,
                                      edges: [
                                        ...configNode.node
                                          .appVaultItemConfigDesktops.edges,
                                        newAppConfig.appVaultItemConfigDesktops
                                          .edges[0],
                                      ],
                                    },
                                  },
                                },
                        )
                      : [
                          ...(vault?.vaultItems?.edges[0].node
                            .appVaultItemConfigs.edges || []),
                          { node: newAppConfig },
                        ],
                  },
                },
              },
            ],
          } as ApiConnection<VaultItemApiType>,
        },
  );
};

export const removeVaultItemConfigDesktop = (
  vaults: VaultApiType[] = [],
  appVaultItemConfigDesktopId: string,
): VaultApiType[] => {
  return vaults.map(vault => ({
    ...vault,
    vaultItems: {
      ...vault.vaultItems,
      edges: [
        {
          node: {
            ...vault.vaultItems?.edges[0].node,
            appVaultItemConfigs: {
              ...vault.vaultItems?.edges[0].node.appVaultItemConfigs,
              edges:
                vault?.vaultItems?.edges[0].node.appVaultItemConfigs.edges.map(
                  appConfig => ({
                    node: {
                      ...appConfig.node,
                      appVaultItemConfigDesktops: {
                        ...appConfig.node.appVaultItemConfigDesktops,
                        edges:
                          appConfig.node.appVaultItemConfigDesktops.edges.filter(
                            appConfigDesktop =>
                              appConfigDesktop.node.id !==
                              appVaultItemConfigDesktopId,
                          ),
                      },
                    },
                  }),
                ),
            },
          },
        },
      ],
    } as ApiConnection<VaultItemApiType>,
  }));
};

export const makeVaultItemConfigDesktop = (
  vaultItemConfigDesktopEvent: StreamVaultItemConfigDesktopRequestEvent,
): AppVaultItemConfigApiType => ({
  __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG,
  id: vaultItemConfigDesktopEvent.appVaultItemConfig.id,
  app: vaultItemConfigDesktopEvent.appVaultItemConfig.app,
  appVaultItemConfigDesktops: {
    __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP_EDGE,
    edges: [
      {
        node: {
          __typename: GRAPHQL_TYPENAME_VAULT_APP_CONFIG_DESKTOP,
          id: vaultItemConfigDesktopEvent['@iri'],
          desktop: {
            id: vaultItemConfigDesktopEvent.desktop.id,
          },
        },
      },
    ],
  },
});
