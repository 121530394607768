import React, { FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CountBadge } from '../../../../shared/components/CountBadge';
import { useMobile } from '../../../../shared/hooks';
import { CalendarIcon } from '../../../../shared/icons';
import { CounterType, useUnreadCounter } from '../../../Counters';
import { PreviewSegment } from '../../../PreviewSegment/PreviewSegment';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { SegmentTranslation } from '../../i18n';
import { SegmentType } from '../../Segment.constants';
import { useGetCurrentSegment } from '../../Segment.hooks';
import {
  SegmentButton,
  SegmentButtonContainer,
  SegmentButtonTitle,
} from '../SegmentSwitcher.styled';

export const SegmentButtonMeetings: FC<{
  navigateToSegment: (segment: SegmentType, isPreviewMode?: boolean) => void;
}> = ({ navigateToSegment }) => {
  const currentSegment = useGetCurrentSegment();
  const { workspace } = useCurrentWorkspace();
  const segmentButtonContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();

  const unreadChatConferenceCount = useUnreadCounter(
    workspace.id,
    CounterType.ChatConferences,
  );

  const isActive = currentSegment === SegmentType.MEETINGS;

  const handleClick = useCallback(() => {
    navigateToSegment(SegmentType.MEETINGS, !isMobile && !isActive);
  }, [isActive, isMobile, navigateToSegment]);

  return (
    <SegmentButtonContainer ref={segmentButtonContainerRef}>
      <SegmentButton
        data-testid="segment-meetings"
        id={SegmentType.MEETINGS}
        onClick={handleClick}
        isActive={isActive}>
        <CalendarIcon width={23} height={23} />
        <CountBadge
          offsetTop={-4}
          offsetRight={-4}
          count={unreadChatConferenceCount}
        />
      </SegmentButton>

      {!isMobile && (
        <SegmentButtonTitle isActive={isActive} onClick={handleClick}>
          <FormattedMessage id={SegmentTranslation.segmentMeetings} />
        </SegmentButtonTitle>
      )}

      {!isMobile && !isActive && (
        <PreviewSegment
          contentType={SegmentType.MEETINGS}
          hoverRef={segmentButtonContainerRef.current}
          navigateToSegment={navigateToSegment}
        />
      )}
    </SegmentButtonContainer>
  );
};
