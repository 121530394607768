import React, { Dispatch, SetStateAction } from 'react';
import type {
  ChatConversationInternalType,
  ChatMessageInternalType,
} from './Chat.types';

export type SearchMessageType = {
  id: string;
  createdAt: string;
  isThreadMessage?: boolean;
};

interface ChatSearchMessageContextType {
  searchMessage?: SearchMessageType;
  setSearchMessage: Dispatch<SetStateAction<SearchMessageType | undefined>>;
}

interface ChatContextType {
  conversation?: ChatConversationInternalType;
  initialData?: ChatMessageInternalType[];
}

export const ChatSearchMessageContext =
  React.createContext<ChatSearchMessageContextType>({
    searchMessage: undefined,
    setSearchMessage: () => {},
  });

export const ChatContext = React.createContext<ChatContextType>({
  conversation: undefined,
  initialData: undefined,
});
