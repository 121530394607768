import { gql } from '@apollo/client';
import { TimelineEventApiType, TimelineEventInputType } from './Timeline.types';

export const CREATE_TIMELINE_EVENT = gql`
  mutation CreateTimelineEvent($input: createTimelineEventInput!) {
    createTimelineEvent(input: $input) {
      timelineEvent {
        scope
        scopeId
        action
        context
        workspaceIri
        occurredAt
      }
    }
  }
`;

export interface CreateTimelineEventVariables {
  input: TimelineEventInputType;
}

export interface CreateTimelineEventResponse {
  createTimelineEvent: TimelineEventApiType;
}

export const UNDO_TIMELINE_EVENT = gql`
  mutation UndoTimelineEvent($input: undoTimelineEventInput!) {
    undoTimelineEvent(input: $input) {
      clientMutationId
    }
  }
`;

export interface UndoTimelineEventVariables {
  input: {
    id: string;
  };
}

export interface UndoTimelineEventResponse {
  clientMutationId: string;
}
