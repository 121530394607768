import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useHideScrollbarWhenIdle } from '../../../shared/hooks/element.hooks';
import { useCurrentWorkspacePermissions } from '../../Workspace/Workspace.hooks';
import {
  HeaderActions,
  HeaderTitle,
  NavigatorContainer,
  SegmentHeader,
  StyledSegmentNavigator,
} from './SegmentNavigator.styled';
import { UpgradeButton } from './UpgradeButton';

type SegmentNavigatorProps = {
  segmentTitle?: string;
  navigatorTitleActions?: ReactNode;
  defaultPadding?: boolean;
  segmentBottomButton?: ReactNode;
  secondarySegment?: boolean;
};

export const SegmentNavigator: FC<PropsWithChildren<SegmentNavigatorProps>> = ({
  segmentTitle,
  navigatorTitleActions,
  defaultPadding = true,
  segmentBottomButton,
  secondarySegment = false,
  children,
}) => {
  const [NavigatorContainerRef] = useHideScrollbarWhenIdle();
  const {
    permissions: { canUpgrade },
  } = useCurrentWorkspacePermissions();

  return (
    <StyledSegmentNavigator
      data-testid="segment-navigator"
      className="segment-navigator"
      secondarySegment={secondarySegment}>
      {segmentTitle && (
        <SegmentHeader data-testid="header">
          <HeaderTitle>{segmentTitle}</HeaderTitle>
          {navigatorTitleActions && (
            <HeaderActions data-testid="actions">
              {navigatorTitleActions}
            </HeaderActions>
          )}
        </SegmentHeader>
      )}
      <NavigatorContainer
        className="segment-navigator-container"
        data-testid="navigator-items"
        defaultPadding={defaultPadding}
        ref={NavigatorContainerRef}>
        {children}
      </NavigatorContainer>
      {/* TODO(bqk): awaiting info if UpgradeButton should be restrictrd to desktop segment */}
      {canUpgrade && !secondarySegment && <UpgradeButton />}
      {segmentBottomButton && <div>{segmentBottomButton}</div>}
    </StyledSegmentNavigator>
  );
};
