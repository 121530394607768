import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderSubtitle,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ManageRelatedTeams } from '../../../../../Team/ManageRelatedTeams';
import { DesktopTranslation } from '../../../../i18n';
import { TeamApiType } from '../../../../../Team/data/Team/types/Team.types';

interface ManageRelatedTeamsModal extends ModalProps {
  subtitle?: string;
  relatedTeams: TeamApiType[];
  onSubmit: (desktops: TeamApiType[]) => Promise<unknown>;
}

export const ManageRelatedTeamsModal: FC<ManageRelatedTeamsModal> = ({
  relatedTeams,
  onRequestClose,
  subtitle,
  onSubmit,
  ...props
}) => {
  return (
    <Modal width={356} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={DesktopTranslation.adminDesktopTeamsManageModalHeader}
            />
          </ModalHeaderTitle>
          {subtitle && (
            <ModalHeaderSubtitle data-testid="subtitle">
              {subtitle}
            </ModalHeaderSubtitle>
          )}
        </div>
      </ModalHeader>
      <ManageRelatedTeams onSubmit={onSubmit} relatedTeams={relatedTeams} />
    </Modal>
  );
};
