import styled, { css } from 'styled-components';
import { ChatMessageMenuButton, MessageActions } from '../ChatMessage.styled';

export const StyledMessageAssetContainer = styled.div<{
  isSingleImage: boolean;
  withDocAsset?: boolean | null;
}>`
  display: flex;
  background-color: ${p => p.theme.colors.Surface};
  border-radius: 0.4rem;
  padding: 0.5rem;
  align-items: center;
  cursor: pointer;
  width: 100%;
  flex-direction: column;
  ${p =>
    p.withDocAsset &&
    css`
      justify-content: space-between;
    `}
  ${p =>
    p.isSingleImage &&
    css`
      height: 100%;
    `};
`;

export const StyledMessageAsset = styled.a<{ reverseRow?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;

  ${({ reverseRow }) =>
    reverseRow &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}
`;

export const VideoPlayerIconContainer = styled.div`
  max-width: 42px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
`;

export const PreviewContainer = styled.div<{ isSingleImage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${p => (p.isSingleImage ? '100%' : '3rem')};
  height: ${p => (p.isSingleImage ? '100%' : '3rem')};

  img {
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const FileSize = styled.div`
  font-size: inherit;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const FileType = styled.div`
  font-size: inherit;
  text-transform: capitalize;
`;

export const StyledAssetMenuButton = styled(ChatMessageMenuButton)<{
  isMobile: boolean;
  visible: boolean;
}>`
  display: ${p => (p.visible ? 'block' : 'none')};
  color: ${p => p.theme.colors.White};
  background-color: ${({ theme }) => theme.colors.ModalBackdrop};
  border-radius: ${p => (!p.isMobile ? '50%' : '0.3rem')};
`;

export const StyledAssetActions = styled(MessageActions)`
  top: 0.25rem;
  right: 0.25rem;
  z-index: 2;
  padding: 0.25rem;
`;
