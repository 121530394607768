import styled from 'styled-components';
import { Switch, withStyles } from '@material-ui/core';
import { getCurrentTheme } from '../../Theme';

export const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: getCurrentTheme().colors.Primary,
    },
    '&$checked + $track': {
      backgroundColor: getCurrentTheme().colors.Primary,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const FormBody = styled.div`
  padding-top: 0.75rem;
`;

export const ImportantMessageBody = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.ImportantInfoMessage};
  line-height: 1.5;
  display: flex;
  flex-direction: column;

  ${p => p.theme.breakpoints.sm`
    flex-direction: unset;
  `};

  .title {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    display: flex;

    span {
      margin-left: 0.5rem;
    }
  }

  .content {
    flex: 1;
  }

  .action {
    display: flex;
    align-items: center;
  }
`;

export const DesktopField = styled.div`
  & ~ & {
    margin-top: 16px;
  }
`;

export const DesktopFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 6px;
  }
`;

export const StyledSubTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  border-radius: 0.25rem;
  padding: 0.125rem 0.375rem;
`;
