import styled from 'styled-components';
import { Input } from '../../../../shared/components/Input';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledMembersFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

export const StyledButtonWithIcon = styled(ButtonWithIcon)`
  height: 100%;
  width: 1.75rem;
  &:hover {
    background-color: ${p => p.theme.colors.SurfaceSubdued};
  }
`;

export const StyledMembersFilters = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  svg {
    position: absolute;
    left: 8px;
  }
`;

export const FilterInput = styled(Input)`
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  border: none;
  line-height: 1.43;
  letter-spacing: -0.14px;

  &:focus {
    padding: 0.125rem 0.375rem 0.125rem 1.375rem;
  }

  &::placeholder {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const NoMembersMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  height: 100%;
`;
