import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import {
  AccountApiType,
  InviteUsersFormFields,
  MicrosoftContactsApiType,
} from '../../../../User.types';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import {
  useAccountMicrosoftContactsQuery,
  useMicrosoftAuthUrlQuery,
} from '../../../../User.hooks';
import {
  StreamEventActionRequest,
  StreamEventType,
} from '../../../../../Mercure/General';
import { Spinner } from '../../../../../../shared/components/Spinner';
import {
  ExternalContact,
  ExternalContactAvatar,
  ExternalContactInitials,
  ExternalContactInner,
  ExternalContactName,
  ExternalContactsEmptyState,
  ExternalContactsList,
  ExternalIcon,
  ExternalInviteUsersButton,
  StyledExternalInviteUsers,
} from '../ExternalInviteUsers.styled';
import MicrosoftLogo from '../../../../../../shared/assets/images/microsoft-logo.svg';
import { UserTranslation } from '../../../../i18n';
import {
  AvatarMode,
  StyledInitials,
} from '../../../../UserAvatar/Avatar.styled';
import { AccountInvitationApiType } from '../../../../../Invitation/data/Invitation/types/Invitation.types';
import { useMercureListener } from '../../../../../Mercure/General/GeneralMercure.hooks';

const INITIALS_ICON_SIZE = 22;

export interface MicrosoftInviteUsersProps {
  users: InviteUsersFormFields[];
  setFormData: (field: string, value: any) => void;
  removeContact: (index: number) => void;
  handleShowAuthPopup: (
    e: React.SyntheticEvent,
    contacts: InviteUsersFormFields[],
    visibleHandler: (visibleStatus: boolean) => void,
    authUrl: string,
  ) => void;
  existingAccounts: AccountApiType[];
  invitedUsers: AccountInvitationApiType[];
}

export const MicrosoftInviteUsers: FC<MicrosoftInviteUsersProps> = ({
  users,
  setFormData,
  removeContact,
  handleShowAuthPopup,
  existingAccounts,
  invitedUsers,
}) => {
  const { workspace } = useCurrentWorkspace();
  const [microsoftContactsUsers, setMicrosoftContactsUsers] = useState<
    MicrosoftContactsApiType[]
  >([]);
  const [visible, setVisible] = useState(false);

  const { data, loading: microsoftAuthUrlLoading } = useMicrosoftAuthUrlQuery({
    skip: !workspace,
    variables: {
      workspace: workspace.id,
    },
  });

  const microsoftAuthUrl = data?.microsoftAuthUrl.authUrl || '';

  const {
    data: microsoftContactsData,
    loading: contactsLoading,
    refetch: refetchContacts,
  } = useAccountMicrosoftContactsQuery({
    variables: {
      workspace: workspace.id,
    },
  });

  const { addListener, removeListener } = useMercureListener();

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = async e => {
      if (
        e['@type'] === StreamEventType.ACTION &&
        e['@request'] === StreamEventActionRequest.MICROSOFT_ACCOUNT_CONNECTED
      ) {
        await refetchContacts().finally(() => {
          setVisible(true);
        });
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, removeListener, refetchContacts]);

  const microsoftContacts = useMemo(
    () => microsoftContactsData?.accountMicrosoftContacts || [],
    [microsoftContactsData],
  );

  const handleRemoveContact = useCallback(
    (contact: MicrosoftContactsApiType, index: number) => {
      microsoftContactsUsers.splice(index, 1);

      removeContact(users.findIndex(user => contact.email === user.email));
    },
    [microsoftContactsUsers, removeContact, users],
  );

  const isFirstRun = useRef(false);

  const readyForRender = !(
    !microsoftContacts.length ||
    microsoftContactsUsers.length > 0 ||
    !visible
  );

  const renderInvitationList = useCallback(() => {
    if (!readyForRender) {
      return;
    }

    const invited =
      [
        ...invitedUsers.map(
          (user: AccountInvitationApiType) => user.recipients[0],
        ),
        ...existingAccounts,
      ] ?? [];

    const formContacts: MicrosoftContactsApiType[] = microsoftContacts
      .map((contact: MicrosoftContactsApiType) => ({
        ...contact,
        type: 'external-user',
      }))
      .filter(
        contact => !invited.some(invited => invited.email === contact.email),
      );

    setMicrosoftContactsUsers(formContacts);

    setFormData('users', [...users, ...formContacts]);

    isFirstRun.current = true;
  }, [
    existingAccounts,
    invitedUsers,
    microsoftContacts,
    readyForRender,
    setFormData,
    users,
  ]);

  useEffect(() => {
    if (!isFirstRun.current) {
      renderInvitationList();
    }
  }, [renderInvitationList]);

  useEffect(
    () => () => {
      setVisible(false);
    },
    [],
  );

  if (microsoftAuthUrlLoading || contactsLoading) {
    return <Spinner />;
  }

  if (!visible) {
    return (
      <ExternalInviteUsersButton
        type="button"
        fullWidth
        onClick={e =>
          handleShowAuthPopup(
            e,
            microsoftContacts,
            setVisible,
            microsoftAuthUrl,
          )
        }
        data-testid="invite-microsoft-team">
        <img src={MicrosoftLogo} alt="microsoft" />
        <FormattedMessage id={UserTranslation.inviteMicrosoftUsersButton} />
      </ExternalInviteUsersButton>
    );
  }

  return (
    <>
      {visible && !!microsoftContactsUsers.length && (
        <StyledExternalInviteUsers>
          <ExternalContactsList>
            <h4>
              <FormattedMessage
                id={UserTranslation.inviteMicrosoftUsersTitle}
              />
            </h4>
            {microsoftContactsUsers.map(
              (contact: MicrosoftContactsApiType, index: number) => {
                return (
                  <ExternalContact key={contact.id}>
                    <ExternalContactInner>
                      {contact?.image ? (
                        <ExternalContactAvatar
                          src={contact.image}
                          alt={contact.displayName}
                        />
                      ) : (
                        <ExternalContactInitials
                          size={INITIALS_ICON_SIZE}
                          mode={AvatarMode.circle}>
                          <StyledInitials
                            size={INITIALS_ICON_SIZE}
                            data-testid="initials">
                            {contact.displayName.trim().charAt(0)}
                          </StyledInitials>
                        </ExternalContactInitials>
                      )}

                      <ExternalContactName>
                        {contact.displayName}
                      </ExternalContactName>
                      <ExternalIcon src={MicrosoftLogo} alt="microsoft" />
                    </ExternalContactInner>
                    <Button
                      mode={ButtonMode.secondary}
                      size={ButtonSize.sm}
                      onClick={() => handleRemoveContact(contact, index)}>
                      <FormattedMessage
                        id={UserTranslation.inviteRemoveExternalUsersButton}
                      />
                    </Button>
                  </ExternalContact>
                );
              },
            )}
          </ExternalContactsList>
          {!microsoftContacts.length && (
            <ExternalContactsEmptyState>
              <FormattedMessage
                id={UserTranslation.inviteMicrosoftUsersEmptyStateMessage}
              />
            </ExternalContactsEmptyState>
          )}
        </StyledExternalInviteUsers>
      )}
    </>
  );
};
