import React, { FC, useCallback, useMemo } from 'react';
import {
  StyledSharedByTitle,
  StyledSharedWithList,
  StyledSharedWithWrapper,
} from './PasswordSharedWith.styled';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../../i18n';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { AccountApiType } from '../../../../User/User.types';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceKeysetsToAccountsMap,
} from '../../../Encryption.hooks';
import { VaultApiType } from '../../../Encryption.types';
import { MemberItem } from '../../../../Chat/MembersList/MemberItem';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';
import { sortByCreationDate } from '../../../../../shared/utils/list.utils';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';

interface PasswordSharedWithProps {
  vault?: VaultApiType;
}

export const PasswordSharedWith: FC<PasswordSharedWithProps> = ({ vault }) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { keyset } = useCurrentAccountKeyset();
  const keysetsToAccountsMap = useCurrentWorkspaceKeysetsToAccountsMap();
  const { account } = useCurrentWorkspaceAccount();

  const sharedWithKeysetIds = useMemo(() => {
    return extractNodes(vault?.vaultAccesses)
      .sort(sortByCreationDate)
      .filter(vaultAccess => vaultAccess.shared)
      .map(access => access.keyset.id);
  }, [vault]);

  const explicitlySharedWithAccounts = useMemo(() => {
    return sharedWithKeysetIds
      .filter(keysetId => keysetId !== keyset?.id)
      .map(keysetId => keysetsToAccountsMap.get(keysetId))
      .filter(Boolean) as AccountApiType[];
  }, [sharedWithKeysetIds, keysetsToAccountsMap, keyset]);

  const createdByAccount = useMemo(() => {
    if (!vault && !keyset) {
      return null;
    }
    const vaultAccess = extractNodes(vault?.vaultAccesses)
      .sort(sortByCreationDate)
      .find(access => !access.shared && !access.isImplicitlyShared);
    return keysetsToAccountsMap.get(
      vaultAccess?.keyset.id as string,
    ) as AccountApiType;
  }, [keyset, keysetsToAccountsMap, vault]);

  const isShared = useMemo(() => {
    if (!vault && !keyset) {
      return false;
    }
    const vaultAccesses = extractNodes(vault?.vaultAccesses);
    return vaultAccesses.some(
      access => access.shared && keysetsToAccountsMap.has(access.keyset.id),
    );
  }, [keyset, keysetsToAccountsMap, vault]);
  const sharedByCurrentAccount = createdByAccount?.id === account.id;

  const handleSharedWithItemClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        shareCurrentVault: true,
      }),
    });
  }, [navigate, queryParams]);

  if (!isShared) {
    return null;
  }

  return (
    <StyledSharedWithWrapper data-testid="password-shared">
      {sharedByCurrentAccount ? (
        <>
          <StyledSharedByTitle data-testid="password-shared-title">
            <FormattedMessage
              id={EncryptionTranslation.passwordControlsSharedWithTitle}
            />
          </StyledSharedByTitle>
          <StyledSharedWithList>
            {explicitlySharedWithAccounts.map(account => (
              <MemberItem
                account={account}
                key={account.id}
                showPopover
                onMemberItemNameClick={handleSharedWithItemClick}
              />
            ))}
          </StyledSharedWithList>
        </>
      ) : (
        <>
          {createdByAccount && (
            <StyledSharedByTitle data-testid="password-shared-title">
              <FormattedMessage
                id={EncryptionTranslation.passwordControlsSharedByTitle}
              />
              <MemberItem account={createdByAccount!} showPopover />
            </StyledSharedByTitle>
          )}
        </>
      )}
    </StyledSharedWithWrapper>
  );
};
