import React, { FC, useCallback, useReducer, useState } from 'react';
import { ListItemContent } from '../../../../shared/components/List';
import { Checkbox } from '../../../../shared/components/Checkbox/Checkbox';
import { UsersList } from './RelatedUsersForm.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { Avatar } from '../../UserAvatar/Avatar';
import { getAccountName } from '../../User.utils';
import { AccountApiType } from '../../User.types';
import { AvatarMode } from '../../UserAvatar/Avatar.styled';
import { UserTranslation } from '../../i18n';
import { getShortId } from '../../../../shared/utils/id';

interface RelatedUsersFormProps {
  users: AccountApiType[];
  relatedUsers: AccountApiType[];
  onSubmit: (users: AccountApiType[]) => Promise<unknown>;
}

export const RelatedUsersForm: FC<RelatedUsersFormProps> = ({
  users,
  relatedUsers,
  onSubmit,
}) => {
  const [submiting, setSubmiting] = useState(false);

  const [checkboxesMap, toggleCheckbox] = useReducer(
    (state: { [key: string]: boolean }, id: string) => {
      return { ...state, [id]: !state[id] };
    },

    relatedUsers.reduce((acc, current) => {
      return {
        ...acc,
        ...(current ? { [getShortId(current.id)]: true } : {}),
      };
    }, {}),
  );

  const handleSubmit = useCallback(() => {
    setSubmiting(true);

    const selectedUsers = users.filter(
      user => checkboxesMap[getShortId(user.id)],
    );

    onSubmit(selectedUsers).then(() => {
      return () => setSubmiting(false);
    });
  }, [checkboxesMap, users, onSubmit]);

  const handleUserItemClick = useCallback(
    (userId: string) => () => {
      toggleCheckbox(userId);
    },
    [],
  );

  return (
    <div data-testid="related-users-form">
      <UsersList data-testid="users-list">
        {users.map(
          user =>
            // TODO: remove this check when cascade deletion will be finished in API
            user && (
              <li data-testid="users-list-item" key={user.id}>
                <ListItemContent
                  title={getAccountName(user)}
                  subtitle={user.email}
                  spaced={false}
                  image={
                    <Avatar account={user} size={30} mode={AvatarMode.circle} />
                  }>
                  <Checkbox
                    data-testid="users-list-item-checkbox"
                    size={32}
                    onChange={handleUserItemClick(getShortId(user.id))}
                    checked={!!checkboxesMap[getShortId(user.id)]}
                  />
                </ListItemContent>
              </li>
            ),
        )}
      </UsersList>

      <Button
        disabled={submiting}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        onClick={handleSubmit}
        data-testid="submit-button">
        <FormattedMessage id={UserTranslation.manageModalUpdateButton} />
      </Button>
    </div>
  );
};
