import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import { AccountApiType } from '../../../../User/User.types';

export const getTeamAccountsApi = (
  teamId: string,
): Promise<RestApiClientResponse<Array<AccountApiType>>> => {
  const shortTeamId = getShortId(teamId);
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient.fetch(`/workspace-team/${shortTeamId}/accounts`);
};
