import styled from 'styled-components';

import { Form } from 'formik';

export const StyledExternalRegistrationForm = styled(Form)`
  width: 100%;
  max-width: 384px;
`;

export const ExternalRegistrationField = styled.div`
  display: flex;

  > * {
    flex-basis: 100%;
    width: 384px;
  }
  > * ~ * {
    margin-left: 16px;
  }

  & ~ & {
    margin-top: 16px;
  }
`;

export const ExternalRegistrationFormControls = styled.div`
  margin-top: 32px;
`;

export const PlanSection = styled.div`
  margin-bottom: 1rem;
`;

export const SelectPlanLabel = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.25;
`;

export const SelectPlanGroup = styled.div`
  & ~ & {
    margin-top: 0.25rem;
  }
`;

export const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  cursor: pointer;
  & ~ & {
    margin-left: 16px;
  }
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  margin-left: 4px;
`;

export const PredefinedPlan = styled.span`
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const InvitationWorkspaceName = styled.span`
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const ErrorMessage = styled.div`
  font-size: 0.875rem;
  padding: 0.75rem;
  line-height: 1.2;
  margin-top: 0.75rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.CriticalLighter};
  color: ${({ theme }) => theme.colors.CriticalDark};

  .message-title {
    font-family: ${p => p.theme.fonts.Medium};
  }
`;
