import React, { FC, Ref, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  HorizontalAlignment,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useMobile } from '../../../../shared/hooks';
import { DotsIcon, HeartFullIcon, HeartIcon } from '../../../../shared/icons';
import { getImageSrcSet } from '../../../../shared/utils/url.utils';
import { ContentLayout } from '../../../ContentLayout';
import { AppLoginMethod } from '../../../Desktop/Desktop.constants';
import { DesktopTranslation } from '../../../Desktop/i18n';
import {
  APP_WITH_OPENED_MENU_Z_INDEX,
  APP_Z_INDEX,
  LOGO_SIZE,
} from '../../App.constants';
import { AppLinkMenu } from '../AppLinkMenu/AppLinkMenu';
import {
  AppImage,
  AppImageWrap,
  AppLogin,
  AppName,
  FavoriteIcon,
  MenuButton,
  StyledAppLink,
  StyledAppWrapper,
} from './AppLink.styled';
import { AppLinkViewModel } from './ViewModel';
import { AppLinkSortableWrapper } from './AppLinkSortableWrapper/AppLinkSortableWrapper';
import type { DesktopAppEdgeApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface AppLinkProps {
  app: DesktopAppEdgeApiType;
  appsLayout: ContentLayout;
  dragEnabled?: boolean;
  isDragging?: boolean;
  processHover?: boolean;
  heartIconOnlyOnFavorited?: boolean;
}

export const AppLink: FC<AppLinkProps> = ({
  app,
  appsLayout,
  dragEnabled = false,
  isDragging = false,
  processHover = true,
}) => {
  const isMobile = useMobile();
  const intl = useIntl();

  // refs
  const appLinkRef: Ref<HTMLAnchorElement> = useRef(null);
  const menuButtonRef = useRef(null);

  const {
    isFavoritesDesktop,
    showIosOptions,
    accountFavorite,
    toggleFavoriteApp,
    openApp,
    appLinkTarget,
    favoriteUiText,
    hasSsoOverride,
    openUri,
    loginUri,
    isMenuOpened,
    isContextMenuOpened,
    setContextMenuOpened,
    appLoginMethod,
    logo,
    login,
    name,
    setMenuOpened,
  } = AppLinkViewModel(app, dragEnabled);

  return (
    <AppLinkSortableWrapper
      appId={app.id}
      appName={app.app.name}
      appStoreAppId={app.app.id}>
      <StyledAppWrapper
        className="app-link"
        appsLayout={appsLayout}
        data-testid="app"
        data-entityid={app.id}
        isDragging={isDragging}
        isDragEnabled={dragEnabled}
        showIosOptions={!!showIosOptions}
        processHover={processHover}
        zIndex={
          isMenuOpened || isContextMenuOpened
            ? APP_WITH_OPENED_MENU_Z_INDEX
            : APP_Z_INDEX
        }>
        {/* TODO: Make one menu after clarification */}
        <AppLinkMenu
          appNode={app}
          trigger={appLinkRef}
          width={208}
          processContextMenuEvent
          onOpen={() => {
            setContextMenuOpened(true);
          }}
          onClose={() => {
            setContextMenuOpened(false);
          }}
          vAlign={VerticalAlignment.BOTTOM}
          hAlign={HorizontalAlignment.LEFT}
          onFavoriteClick={() => toggleFavoriteApp()}
        />
        <>
          <AppLinkMenu
            appNode={app}
            trigger={menuButtonRef}
            width={208}
            vAlign={VerticalAlignment.BOTTOM}
            hAlign={HorizontalAlignment.LEFT}
            onOpen={() => {
              setMenuOpened(true);
            }}
            onClose={() => {
              setMenuOpened(false);
            }}
            onFavoriteClick={() => toggleFavoriteApp()}
          />
          <MenuButton
            data-tooltip-id="global-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: DesktopTranslation.tooltipAppMoreActions,
            })}
            data-tooltip-place={TooltipPlace.top}
            ref={menuButtonRef}
            icon={DotsIcon}
            appsLayout={appsLayout}
            data-testid="app-more-button"
          />
        </>
        {(!isMobile ||
          (isMobile && showIosOptions) ||
          accountFavorite ||
          isFavoritesDesktop) && (
          <FavoriteIcon
            data-tooltip-id="global-tooltip"
            data-tooltip-content={favoriteUiText}
            data-tooltip-place={TooltipPlace.top}
            className="favorite-icon"
            isFavorite={accountFavorite || isFavoritesDesktop}
            aria-label={
              accountFavorite || isFavoritesDesktop
                ? intl.formatMessage({
                    id: DesktopTranslation.appUnMarkAsFavorite,
                  })
                : intl.formatMessage({
                    id: DesktopTranslation.appMarkAsFavorite,
                  })
            }
            onClick={() => toggleFavoriteApp(true)}
            showIosOptions={!!showIosOptions}
            processHover={processHover}
            appsLayout={appsLayout}
            data-testid="favorite-icon">
            {accountFavorite || isFavoritesDesktop ? (
              <HeartFullIcon width={16} height={16} />
            ) : (
              <HeartIcon width={16} height={16} />
            )}
          </FavoriteIcon>
        )}
        <StyledAppLink
          onClick={openApp}
          className="appLink with-focus-visible"
          ref={appLinkRef}
          href={hasSsoOverride ? openUri : loginUri}
          rel="noopener noreferrer"
          target={appLinkTarget()}
          data-testid="app-link">
          <AppImageWrap>
            {logo?.contentUrl && (
              <AppImage
                size={LOGO_SIZE}
                src={logo.contentUrl}
                srcSet={getImageSrcSet(logo.contentUrl, LOGO_SIZE)}
                alt={name}
                data-testid="app-icon"
              />
            )}
          </AppImageWrap>
          <AppName data-testid="app-name">{name}</AppName>

          <AppLogin data-testid="app-login">
            {appLoginMethod === AppLoginMethod.PWM && login}
            {appLoginMethod === AppLoginMethod.SSO && (
              <FormattedMessage id={DesktopTranslation.appLinkSSOLabel} />
            )}
          </AppLogin>
        </StyledAppLink>
      </StyledAppWrapper>
    </AppLinkSortableWrapper>
  );
};
