import styled from 'styled-components';

export const StyledGeneralSettingsForm = styled.div`
  .fieldBox {
    margin-bottom: 1.125rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme: { breakpoints } }) => breakpoints.md`
    margin: 0;

    .fieldBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    label {
      font-size: 1rem;
    }
  `}
`;
