import styled, { css } from 'styled-components';
import SidebarImage from './images/sidebar-initial.jpg';
import SidebarDesktopsImage from './images/sidebar-desktops-list.jpg';
import NavbarImage from './images/navbar.jpg';

const HEADER_HEIGHT = 32;
const SIDEBAR_WIDTH = 114;
const SEARCH_BAR_HEIGHT = 32;
const TOP_PANEL_HEIGHT = 32;

export const StepsIllustrationWrapper = styled.div<{ applyOpacity: boolean }>`
  width: 384px;
  height: 414px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05), 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  ${({ applyOpacity }) =>
    applyOpacity &&
    css`
      opacity: 0.6;
    `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  padding-left: 12px;
  background-color: ${p =>
    p.theme.colors.OnboardingStepsIllustrationBackground};

  > span {
    max-width: 100%;
    font-size: 0.75rem;
    color: ${p => p.theme.colors.PrimaryTextLight};
    font-family: ${({ theme }) => theme.fonts.Semibold};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Main = styled.div`
  height: 100%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

export const Sidebar = styled.aside`
  height: 100%;
  width: ${SIDEBAR_WIDTH}px;
`;

export const DesktopList = styled.div<{ displayDesktopList: boolean }>`
  height: calc(100% - ${HEADER_HEIGHT}px);
  background-color: ${p =>
    p.theme.colors.OnboardingStepsIllustrationBackground};
  background-image: url(${SidebarImage});
  background-size: cover;
  ${({ displayDesktopList }) =>
    displayDesktopList &&
    css`
      background-image: url(${SidebarDesktopsImage});
    `}
`;

export const ContentWrapper = styled.main`
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  height: 100%;
  background-color: ${p => p.theme.colors.Background};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  height: calc(100% - ${TOP_PANEL_HEIGHT}px - ${SEARCH_BAR_HEIGHT}px);
  overflow: hidden;

  img {
    max-width: 100%;
    margin-bottom: 0.3125rem;
  }
`;

export const SearchBar = styled.div`
  height: ${SEARCH_BAR_HEIGHT}px;
  background-color: ${p =>
    p.theme.colors.OnboardingStepsIllustrationBackground};
`;

export const TopPanel = styled.div`
  height: ${TOP_PANEL_HEIGHT}px;
  margin-bottom: 0.3125rem;
  background-color: ${p => p.theme.colors.Surface};
  background-image: url(${NavbarImage});
  background-size: contain;
`;
