import { ApolloClient, gql } from '@apollo/client';
import {
  GET_CHAT_CONVERSATION_INVITATIONS,
  GET_CHAT_CONVERSATIONS,
  GET_CHAT_MESSAGES,
  GetChatConversationInvitationsResponse,
  GetChatConversationInvitationsVariables,
  GetChatConversationsResponse,
  GetChatConversationsVariables,
  GetChatMessagesResponse,
  GetChatMessagesVariables,
} from '../Chat.queries';
import { ChatConversationApiType, ChatMessageApiType } from '../Chat.types';
import {
  GRAPHQL_TYPENAME_CHAT_CONVERSATION,
  GRAPHQL_TYPENAME_CHAT_MESSAGE,
  GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
  GRAPHQL_TYPENAME_LINK_DATA,
} from '../Chat.constants';
import { existsInNestedArray } from '../../../shared/utils/list.utils';
import { LinkApiType } from '../../Link/Link.types';
import { LinkDataFragment } from '../../Link/Link.fragments';

export const addChatMessageToConversationCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetChatMessagesVariables,
  message: ChatMessageApiType,
  createIfEmpty = false,
) => {
  let conversationCache = null;

  try {
    conversationCache = proxy.readQuery<
      GetChatMessagesResponse,
      GetChatMessagesVariables
    >({
      query: GET_CHAT_MESSAGES,
      variables,
    });
  } catch (e) {}

  if (!conversationCache) {
    if (createIfEmpty) {
      proxy.writeQuery<GetChatMessagesResponse, GetChatMessagesVariables>({
        query: GET_CHAT_MESSAGES,
        variables,
        data: {
          listChatMessages: {
            __typename: 'ChatMessageConnection',
            edges: [
              {
                __typename: GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
                node: message,
              },
            ],
            pageInfo: {
              __typename: 'ChatMessagePageInfo',
              hasNextPage: false,
              hasPreviousPage: false,
              startCursor: '',
              endCursor: window.btoa('1'),
            },
          },
        },
      });
    }

    return;
  }

  try {
    const isNewMessage = !conversationCache.listChatMessages.edges.find(
      ({ node }) => node.id === message.id,
    );
    if (!isNewMessage) {
      return;
    }

    proxy.writeQuery<GetChatMessagesResponse, GetChatMessagesVariables>({
      query: GET_CHAT_MESSAGES,
      variables,
      data: {
        ...conversationCache,
        listChatMessages: {
          ...conversationCache.listChatMessages,
          ...(conversationCache.listChatMessages.pageInfo
            ? {
                pageInfo: {
                  ...conversationCache.listChatMessages.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(
                          conversationCache.listChatMessages.pageInfo.endCursor,
                        ),
                        10,
                      ) + 1
                    ).toString(),
                  ),
                },
              }
            : null),
          edges: [
            {
              __typename: GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
              node: message,
            },
            ...conversationCache.listChatMessages.edges,
          ],
        },
      },
    });
  } catch (e) {}
};

export const removeChatMessageFromConversationCache = (
  proxy: Pick<ApolloClient<any>, 'readQuery' | 'writeQuery'>,
  variables: GetChatMessagesVariables,
  messageId: string,
) => {
  try {
    const conversationCache = proxy.readQuery<
      GetChatMessagesResponse,
      GetChatMessagesVariables
    >({
      query: GET_CHAT_MESSAGES,
      variables,
    });
    if (!conversationCache) {
      return;
    }

    const exists = existsInNestedArray(
      conversationCache,
      'listChatMessages.edges',
      { node: { id: messageId } },
    );
    if (!exists) {
      return;
    }

    proxy.writeQuery<GetChatMessagesResponse, GetChatMessagesVariables>({
      query: GET_CHAT_MESSAGES,
      variables,
      data: {
        ...conversationCache,
        listChatMessages: {
          ...conversationCache.listChatMessages,
          ...(conversationCache.listChatMessages.pageInfo
            ? {
                pageInfo: {
                  ...conversationCache.listChatMessages.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(
                          conversationCache.listChatMessages.pageInfo.endCursor,
                        ),
                        10,
                      ) - 1
                    ).toString(),
                  ),
                },
              }
            : null),
          edges: conversationCache.listChatMessages.edges.filter(
            ({ node }) => node.id !== messageId,
          ),
        },
      },
    });
  } catch (e) {}
};

const ChatConversationFragment = gql`
  fragment ChatConversationFragment on ChatConversation {
    lastMessageAt
    conversationTitle
    users
    type
    pendingEmails
    accountsThatPinned
  }
`;

type ChatConversationFragmentType = Pick<
  ChatConversationApiType,
  | 'lastMessageAt'
  | 'conversationTitle'
  | 'users'
  | 'pendingEmails'
  | 'type'
  | 'accountsThatPinned'
  // add more if needed
>;

export const updateConversationInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  conversationId: string,
  updater: (
    conversation: ChatConversationFragmentType,
  ) => Partial<ChatConversationFragmentType>,
) => {
  try {
    const fragmentCache = proxy.readFragment<
      ChatConversationFragmentType,
      undefined
    >({
      id: `${GRAPHQL_TYPENAME_CHAT_CONVERSATION}:${conversationId}`,
      fragment: ChatConversationFragment,
    });

    if (!fragmentCache) {
      return;
    }

    const updatedCache = {
      ...fragmentCache,
      ...updater(fragmentCache),
    };
    updatedCache.users = Array.from(new Set(updatedCache.users));

    proxy.writeFragment<ChatConversationFragmentType, undefined>({
      id: `${GRAPHQL_TYPENAME_CHAT_CONVERSATION}:${conversationId}`,
      fragment: ChatConversationFragment,
      data: updatedCache,
    });
  } catch {}
};

export const removeFromChatConversationListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  conversationId: string,
  variables: GetChatConversationsVariables,
) => {
  try {
    const conversationListCache = proxy.readQuery<
      GetChatConversationsResponse,
      GetChatConversationsVariables
    >({
      query: GET_CHAT_CONVERSATIONS,
      variables,
    });

    if (!conversationListCache) {
      return;
    }

    proxy.writeQuery<
      GetChatConversationsResponse,
      GetChatConversationsVariables
    >({
      query: GET_CHAT_CONVERSATIONS,
      variables,
      data: {
        ...conversationListCache,
        workspaceChats: {
          ...conversationListCache.workspaceChats,
          edges: [
            ...conversationListCache.workspaceChats.edges.filter(
              edge => edge.node.chatConversation.id !== conversationId,
            ),
          ],
        },
      },
    });
  } catch (e) {}
};

export const addChatConversationToListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetChatConversationsVariables,
  chatConversation: ChatConversationApiType,
) => {
  try {
    const conversationListCache = proxy.readQuery<
      GetChatConversationsResponse,
      GetChatConversationsVariables
    >({
      query: GET_CHAT_CONVERSATIONS,
      variables,
    });

    if (!conversationListCache) {
      return;
    }

    const alreadyExists = existsInNestedArray(
      conversationListCache,
      'workspaceChats.edges',
      { node: { chatConversation: { id: chatConversation.id } } },
    );

    if (alreadyExists) {
      return;
    }

    proxy.writeQuery<
      GetChatConversationsResponse,
      GetChatConversationsVariables
    >({
      query: GET_CHAT_CONVERSATIONS,
      variables,
      data: {
        ...conversationListCache,
        workspaceChats: {
          ...conversationListCache.workspaceChats,
          edges: [
            ...conversationListCache.workspaceChats.edges,
            {
              __typename: 'WorkspaceChatItemEdge',
              node: {
                __typename: 'WorkspaceChatItem',
                chatConversation,
                chatMessages: {
                  edges: [],
                },
              },
            },
          ],
        },
      },
    });
  } catch (e) {}
};

const ChatMessageFragment = gql`
  fragment ChatMessageFragment on ChatMessage {
    context
    message
    reactions
    modified
    threadMessagesCount
    seenBy
  }
`;

export type ChatMessageFragmentType = Pick<
  ChatMessageApiType,
  | 'context'
  | 'message'
  | 'reactions'
  | 'modified'
  | 'threadMessagesCount'
  | 'seenBy'
  // add more if needed
>;

export const updateChatMessageInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  chatMessageId: string,
  updater: (
    chatMessage: ChatMessageFragmentType,
  ) => Partial<ChatMessageFragmentType>,
) => {
  try {
    const cacheId = `${GRAPHQL_TYPENAME_CHAT_MESSAGE}:${chatMessageId}`;

    const fragmentCache = proxy.readFragment<
      ChatMessageFragmentType,
      undefined
    >({
      id: cacheId,
      fragment: ChatMessageFragment,
    });
    if (!fragmentCache) {
      return;
    }

    proxy.writeFragment<ChatMessageFragmentType, undefined>({
      id: cacheId,
      fragment: ChatMessageFragment,
      data: {
        ...fragmentCache,
        ...updater(fragmentCache),
      },
    });
  } catch (e) {}
};

export const removeChatInvitationFromChatInvitationsCache = (
  proxy: Pick<ApolloClient<any>, 'readQuery' | 'writeQuery'>,
  variables: GetChatConversationInvitationsVariables,
  invitationId: string,
) => {
  try {
    const chatInvitationsCache = proxy.readQuery<
      GetChatConversationInvitationsResponse,
      GetChatConversationInvitationsVariables
    >({
      query: GET_CHAT_CONVERSATION_INVITATIONS,
      variables: variables,
    });
    if (!chatInvitationsCache) {
      return;
    }

    proxy.writeQuery<
      GetChatConversationInvitationsResponse,
      GetChatConversationInvitationsVariables
    >({
      query: GET_CHAT_CONVERSATION_INVITATIONS,
      variables: variables,
      data: {
        workspaceAccountInvitations: {
          ...chatInvitationsCache.workspaceAccountInvitations,
          edges: chatInvitationsCache.workspaceAccountInvitations.edges.filter(
            ({ node }) => node.id !== invitationId,
          ),
        },
      },
    });
  } catch (e) {}
};

export type LinkDataFragmentType = Pick<LinkApiType, 'data' | 'id'>;

export const updateLinkDataInCache = (
  proxy: {
    readFragment: ApolloClient<any>['readFragment'];
    writeFragment: ApolloClient<any>['writeFragment'];
  },
  linkDataId: string,
  updater: (link: LinkDataFragmentType) => Partial<LinkDataFragmentType>,
) => {
  try {
    const cacheId = `${GRAPHQL_TYPENAME_LINK_DATA}:${linkDataId}`;

    const fragmentCache = proxy.readFragment<LinkDataFragmentType, undefined>({
      id: cacheId,
      fragment: LinkDataFragment,
    });

    if (!fragmentCache) {
      return;
    }

    proxy.writeFragment<LinkDataFragmentType, undefined>({
      id: cacheId,
      fragment: LinkDataFragment,
      data: {
        ...fragmentCache,
        ...updater(fragmentCache),
      },
    });
  } catch (e) {}
};

export const updateMessageInThreadCache = (
  apolloClient: ApolloClient<any>,
  queryVariables: GetChatMessagesVariables,
  updatedMessage: ChatMessageApiType,
) => {
  try {
    const chatCache = apolloClient.readQuery<
      GetChatMessagesResponse,
      GetChatMessagesVariables
    >({
      query: GET_CHAT_MESSAGES,
      variables: queryVariables,
    });

    if (!chatCache) {
      return;
    }

    const messageExists = chatCache.listChatMessages.edges.find(
      edge => edge.node.id === updatedMessage.id,
    );

    const updatedCache = {
      listChatMessages: {
        ...chatCache.listChatMessages,
        edges: messageExists
          ? chatCache.listChatMessages.edges.map(({ node, __typename }) =>
              node.id !== updatedMessage.id
                ? { node, __typename }
                : {
                    node: updatedMessage,
                    __typename,
                  },
            )
          : [
              ...chatCache.listChatMessages.edges,
              {
                node: updatedMessage,
                __typename: GRAPHQL_TYPENAME_CHAT_MESSAGE_EDGE,
              },
            ],
      },
    };

    apolloClient.writeQuery({
      query: GET_CHAT_MESSAGES,
      variables: queryVariables,

      data: updatedCache,
    });
  } catch (e) {
    /* There is no cache */
  }
};
