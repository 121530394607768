import { useMemo } from 'react';
import {
  ConversationListSections,
  ConversationsSortedByGroups,
  ExpandedConversationsType,
} from '../../../Chat.types';
import { CONVERSATION_LIST_SEARCH_BAR } from './ConversationList.constants';
export interface CollapsedSectionsProps {
  pinned: boolean;
  appIntegrations: boolean;
  desktop: boolean;
  private: boolean;
}

export interface VirtualisedConversationsProps {
  virtuosoConversations: ExpandedConversationsType[];
  collapsedSections: CollapsedSectionsProps;
}

export const useVirtualisedConversations = (
  conversations: ConversationsSortedByGroups,
  sectionCollapsed: ConversationListSections[],
): VirtualisedConversationsProps => {
  return useMemo(() => {
    const collapsedSections: CollapsedSectionsProps = {
      pinned: sectionCollapsed.includes(ConversationListSections.pinned),
      appIntegrations: sectionCollapsed.includes(
        ConversationListSections.appIntegrations,
      ),
      desktop: sectionCollapsed.includes(ConversationListSections.desktop),
      private: sectionCollapsed.includes(ConversationListSections.private),
    };

    const conversationPinnedGroup = [
      conversations.pinned.length ? ConversationListSections.pinned : '',
      ...(!conversations.pinned.length || collapsedSections.pinned
        ? []
        : conversations.pinned),
    ];

    const conversationAppIntegrationGroup = [
      conversations.appIntegrations.length
        ? ConversationListSections.appIntegrations
        : '',
      ...(!conversations.appIntegrations.length ||
      collapsedSections.appIntegrations
        ? []
        : conversations.appIntegrations),
    ];

    const conversationDesktopGroup = [
      conversations.desktop.length ? ConversationListSections.desktop : '',
      ...(!conversations.desktop.length || collapsedSections.desktop
        ? []
        : conversations.desktop),
    ];

    const conversationPrivateGroup = [
      conversations.private.length ? ConversationListSections.private : '',
      ...(!conversations.private.length || collapsedSections.private
        ? []
        : conversations.private),
    ];
    return {
      collapsedSections,
      virtuosoConversations: [
        CONVERSATION_LIST_SEARCH_BAR, // Identifier for search bar, on the basis of which the search is embedded in the list
        ...conversationPinnedGroup,
        ...conversationAppIntegrationGroup,
        ...conversationDesktopGroup,
        ...conversationPrivateGroup,
      ].filter(item => item),
    };
  }, [
    conversations.appIntegrations,
    conversations.desktop,
    conversations.pinned,
    conversations.private,
    sectionCollapsed,
  ]);
};
