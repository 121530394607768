import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormCheckbox } from '../../../shared/components/Checkbox';
import { DomainFormValues, WorkspaceDomainApiType } from '../Domain.types';
import { DomainField, DomainFormControls } from './Domain.form.styled';
import { DomainTranslation } from '../i18n';

const DomainFormSchema: Schema<DomainFormValues> = Yup.object().shape({
  domain: Yup.string().required(DomainTranslation.adminListFormDomainRequired),
  enabled: Yup.boolean(),
});

interface DomainFormProps {
  currentDomain?: WorkspaceDomainApiType;
  onSubmit: (values: DomainFormValues) => void;
  onCancel: () => void;
}

export const DomainForm: FC<DomainFormProps> = ({
  currentDomain,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();
  const initialValues: DomainFormValues = {
    domain: currentDomain?.domain || '',
    enabled: currentDomain?.enabled || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DomainFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="domain-form">
          <div>
            <DomainField>
              <Field
                name="domain"
                type="text"
                data-testid="domain-form-name-input"
                placeholder={intl.formatMessage({
                  id: DomainTranslation.adminListFormNamePlaceholder,
                })}
                label={intl.formatMessage({
                  id: DomainTranslation.adminListFormNameLabel,
                })}
                component={FormInput}
              />
            </DomainField>

            <DomainField>
              <Field
                name="enabled"
                type="checkbox"
                data-testid="domain-form-enabled-input"
                label={intl.formatMessage({
                  id: DomainTranslation.adminListFormCheckboxLabel,
                })}
                component={FormCheckbox}
              />
            </DomainField>

            <DomainFormControls>
              {currentDomain ? (
                <Button
                  data-testid="domain-form-submit-button"
                  type="submit"
                  mode={ButtonMode.primary}
                  size={ButtonSize.md}
                  disabled={isSubmitting}>
                  <FormattedMessage
                    id={DomainTranslation.adminListEditSubmitButton}
                  />
                </Button>
              ) : (
                <>
                  <Button
                    data-testid="domain-form-cancel-button"
                    type="button"
                    mode={ButtonMode.secondary}
                    size={ButtonSize.md}
                    onClick={() => onCancel()}>
                    <FormattedMessage
                      id={DomainTranslation.adminListCancelButton}
                    />
                  </Button>
                  <Button
                    data-testid="domain-form-submit-button"
                    type="submit"
                    mode={ButtonMode.primary}
                    size={ButtonSize.md}
                    disabled={isSubmitting}>
                    <FormattedMessage
                      id={DomainTranslation.adminListCreateSubmitButton}
                    />
                  </Button>
                </>
              )}
            </DomainFormControls>
          </div>
        </Form>
      )}
    </Formik>
  );
};
