import styled from 'styled-components';

export const LinkDetailsTitleContainer = styled.div``;

export const StyledLinkDetailsTitle = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xxs};

  &:hover .hide-unless-hover-parent {
    opacity: 1;
  }
`;

export const LinkDetailsTitleHeading = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const LinkDetailsTitleButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  outline: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &.hide-unless-hover-parent {
    opacity: 0;
    transition: 0.2s opacity ease-out;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    opacity: 1;
  }
`;

export const LinkDetailsTitleSubtitle = styled.div`
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
`;
