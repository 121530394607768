import React, { FC, useMemo } from 'react';
import { EmptyState, StyledMembersList } from './MembersList.styled';
import { AccountItem } from './AccountItem';
import { InviteItem } from './InviteItem';
import { FormattedMessage } from 'react-intl';
import { DesktopTranslation } from '../../i18n';
import { Spinner } from '../../../../shared/components/Spinner';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';
import type { DesktopApiType } from '../../data/Desktop/types/Desktop.types';
import { TeamItem } from './TeamItem';
import { AccessApiType } from '../../../Access/data/types/Access.types';
import { AccountInvitationApiType } from '../../../Invitation/data/Invitation/types/Invitation.types';

interface MembersListProps {
  desktop: DesktopApiType;
  accesses: AccessApiType[];
  invites: AccountInvitationApiType[];
  loading: boolean;
}

export const MembersList: FC<MembersListProps> = ({
  desktop,
  accesses = [],
  invites,
  loading,
}) => {
  const [listRef] = useHideScrollbarWhenIdle();

  const hasMembers = (accesses.length || invites.length) > 0;

  const accountAccesses = useMemo(() => {
    return accesses.filter(access => !!access.account?.id);
  }, [accesses]);

  const teamAccesses = useMemo(() => {
    return accesses.filter(access => !!access.workspaceTeam?.id);
  }, [accesses]);

  return (
    <StyledMembersList ref={listRef}>
      {!hasMembers && loading ? (
        <Spinner />
      ) : hasMembers ? (
        <>
          {teamAccesses.map(access => (
            <TeamItem key={access.id} access={access} desktop={desktop} />
          ))}
          {accountAccesses.map(access => (
            <AccountItem key={access.id} access={access} desktop={desktop} />
          ))}

          {invites.map(invite => (
            <InviteItem key={invite.id} invite={invite} desktop={desktop} />
          ))}
        </>
      ) : (
        <EmptyState>
          <FormattedMessage id={DesktopTranslation.accessNotShared} />
        </EmptyState>
      )}
    </StyledMembersList>
  );
};
