import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

const MOBILE_HEADER_BAR_HEIGHT = '44px';

export const StyledAppsSwitcher = styled.div`
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

export const PasswordsHeader = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: ${MOBILE_HEADER_BAR_HEIGHT};
  border-bottom: 1px solid ${p => p.theme.colors.SurfaceBorder};
  background-color: ${p => p.theme.colors.Background};

  ${({ theme }) => theme.breakpoints.sm`
       min-height: ${theme.fixedValues.segmentHeaderHeight};
       padding: 0.25rem 1rem;
    `}

  button ~ button {
    margin-left: 1rem;
  }

  svg {
    height: 24px;
    width: 24px;
    color: ${p => p.theme.colors.Primary};

    + div {
      margin-left: 0.5rem;
    }
  }

  .create-password {
    padding: 0.25rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }
  }

  .burgerButton {
    svg {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    }
  }

  .back-button {
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    margin-right: 0.75rem;
  }

  button[disabled] {
    background-color: ${p => p.theme.colors.Surface};
    opacity: 0.5;
  }
`;

export const PasswordsListWrapper = styled.div`
  max-height: calc(100% - 3.5rem);
  overflow-y: auto;
`;

export const StyledMenuItemIcon = styled.div`
  margin-right: 0.75rem;
`;

export const StyledPasswordFilter = styled.div`
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
`;

export const StyledPasswordsGroups = styled.div`
  margin-top: 0.5rem;
`;

export const StyledPasswordsSwitcherExpand = styled(ButtonWithIcon)<{
  expanded?: boolean;
}>`
  width: 100%;
  display: flex;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  letter-spacing: 0.48px;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: ${p => p.theme.fonts.Semibold};
  padding: 0 0.5rem;

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    margin-right: 0.563rem;
    transition: transform 0.2s;
  }

  ${p =>
    !p.expanded &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}
`;
