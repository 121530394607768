import React, { FC, useContext, useEffect } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { UsersList } from './UsersList';
import { UserView } from './UserView';
import { InvitationView } from './InvitationView';
import { AdminPage, DrawerHeader } from '../../Admin';
import { FormattedHTMLMessage } from 'react-intl';
import { UserTranslation } from '../i18n';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { useMobile } from '../../../shared/hooks';
import { Route } from '../../ErrorInterceptor/router.injectors';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const UsersAdmin: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);
  const {
    permissions: { canViewUsersPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  if (permissionsLoaded && !canViewUsersPage) {
    navigate(`/workspace/${getShortId(workspace.id)}/admin`);
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <AdminPage data-testid="user-admin">
      {isMobile && (
        <DrawerHeader
          onRequestClose={() =>
            navigate(`/workspace/${getShortId(workspace.id)}`)
          }>
          <FormattedHTMLMessage
            id={UserTranslation.adminListHeaderTitle}
            values={{
              count: '',
            }}
          />
        </DrawerHeader>
      )}

      <Routes>
        <Route
          path={`/invitation/:invitationId`}
          element={<InvitationView />}
        />
        <Route path={`/:userId`} element={<UserView />} />
        <Route index element={<UsersList />} />
      </Routes>
    </AdminPage>
  );
};
