import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { WorkspaceTranslation } from '../../i18n';
import { EmptyWorkspaceCreation } from './EmptyWorkspacesCreation';

export const EmptyWorkspaceCreationModal: FC<ModalProps> = memo(props => {
  return (
    <Modal width={380} {...props}>
      <ModalHeader onRequestClose={props.onRequestClose}>
        <FormattedMessage id={WorkspaceTranslation.createLinkedUserWorkspace} />
      </ModalHeader>

      <EmptyWorkspaceCreation />
    </Modal>
  );
});
