import React, { FC, useCallback, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import {
  LINK_ACCOUNT_PATHNAME,
  REGISTRATION_PATHNAME,
} from '../../../../Onboarding/Onboarding.constants';
import { useAuthService } from '../../../../Auth/Auth.hooks';
import {
  LinkAccountHeadingIcon,
  LinkAccountOption,
  LinkAccountOptionButton,
  LinkAccountOptionDescription,
  LinkAccountOptionHeading,
  LinkAccountOptionLearnMore,
  LinkAccountOptionLearnMoreLink,
  LinkAccountOptions,
} from './LinkAccountModal.styled';
import { AddIcon, LinkIcon } from '../../../../../shared/icons';
import { useTheme } from 'styled-components';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkspaceTranslation } from '../../../i18n';
import {
  Separator,
  SeparatorMode,
} from '../../../../../shared/components/Separator';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { LinkAccountPlanSelect } from './LinkAccountPlanSelect';
import { useMobile } from '../../../../../shared/hooks';
import { appEnv } from '../../../../../appEnv';
import { getAuth0ReturnUriHost } from '../../../../Auth/Auth.utils';
import { useCreateAccountLinkRequestMutation } from '../../../../Onboarding/Onboarding.hooks';
import { LOGIN_PATHNAME } from '../../../../Auth/Auth.constants';
import { SignUpFlowPath } from '../../../../Onboarding/Onboarding.types';
import { useNativeWrapper } from '../../../../NativeWrapper';

export const LinkAccountModal: FC<ModalProps> = props => {
  const theme = useTheme();
  const intl = useIntl();
  const authService = useAuthService();
  const [
    createAccountLinkRequestMutation,
  ] = useCreateAccountLinkRequestMutation();
  const [linkNewAccountFlow, setLinkNewAccountFlow] = useState(
    SignUpFlowPath.PRO,
  );
  const isMobile = useMobile();

  const [linkNewAccountLoading, setLinkNewAccountLoading] = useState(false);
  const [linkExistingAccountLoading, setLinkExistingAccountLoading] = useState(
    false,
  );

  const createAccountLinkRequest = useCallback(() => {
    return createAccountLinkRequestMutation({
      variables: {
        input: {},
      },
    });
  }, [createAccountLinkRequestMutation]);

  const handleLinkNewAccountClick = useCallback(() => {
    setLinkNewAccountLoading(true);
    createAccountLinkRequest()
      .then(res => {
        if (res.data?.createAccountLinkRequest?.accountLinkRequest?.id) {
          authService?.logout({
            returnTo: `${getAuth0ReturnUriHost()}${REGISTRATION_PATHNAME}/${linkNewAccountFlow}?linkRequestId=${
              res.data.createAccountLinkRequest.accountLinkRequest.id
            }`,
          });
        }
      })
      .catch(e => {
        setLinkNewAccountLoading(false);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [createAccountLinkRequest, authService, linkNewAccountFlow]);

  const handleLinkExistingAccountClick = useCallback(() => {
    setLinkExistingAccountLoading(true);
    createAccountLinkRequest()
      .then(res => {
        if (res.data?.createAccountLinkRequest?.accountLinkRequest?.id) {
          authService?.logout({
            returnTo: `${getAuth0ReturnUriHost()}${LOGIN_PATHNAME}?returnTo=${
              window.location.origin
            }${LINK_ACCOUNT_PATHNAME}?linkRequestId=${
              res.data.createAccountLinkRequest.accountLinkRequest.id
            }`,
          });
        }
      })
      .catch(e => {
        setLinkExistingAccountLoading(false);
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [createAccountLinkRequest, authService]);

  const { isNativeWrapperAvailable } = useNativeWrapper();

  return (
    <Modal width={716} {...props}>
      <ModalHeader onRequestClose={props.onRequestClose}>
        <FormattedMessage id={WorkspaceTranslation.linkAccountModalHeader} />
      </ModalHeader>
      <LinkAccountOptions>
        <LinkAccountOption>
          <LinkAccountOptionHeading>
            <LinkAccountHeadingIcon>
              <LinkIcon
                width={14}
                height={14}
                color={theme.colors.OnSurfaceMediumEmphasis}
              />
            </LinkAccountHeadingIcon>
            <FormattedMessage
              id={WorkspaceTranslation.linkAccountExistingHeading}
            />
          </LinkAccountOptionHeading>
          <LinkAccountOptionDescription>
            <FormattedMessage
              id={WorkspaceTranslation.linkAccountExistingDescription}
            />
          </LinkAccountOptionDescription>
          <LinkAccountOptionButton
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={handleLinkExistingAccountClick}
            disabled={linkExistingAccountLoading}>
            <FormattedMessage
              id={WorkspaceTranslation.linkAccountExistingButton}
              tagName="span"
            />
          </LinkAccountOptionButton>
        </LinkAccountOption>

        {!isNativeWrapperAvailable && (
          <>
            <Separator
              message={intl.formatMessage({
                id: WorkspaceTranslation.linkAccountSeparatorMessage,
              })}
              mode={
                isMobile ? SeparatorMode.horizontal : SeparatorMode.vertical
              }
              size={280}
            />

            <LinkAccountOption>
              <LinkAccountOptionHeading>
                <LinkAccountHeadingIcon>
                  <AddIcon
                    width={14}
                    height={14}
                    color={theme.colors.OnSurfaceMediumEmphasis}
                  />
                </LinkAccountHeadingIcon>
                <FormattedMessage
                  id={WorkspaceTranslation.linkAccountNewHeading}
                  tagName="div"
                />
              </LinkAccountOptionHeading>
              <LinkAccountOptionDescription>
                <FormattedMessage
                  id={WorkspaceTranslation.linkAccountNewDescription}
                />
              </LinkAccountOptionDescription>
              <LinkAccountOptionLearnMore>
                <LinkAccountOptionLearnMoreLink href={appEnv.PRICING_URL}>
                  <FormattedMessage
                    id={WorkspaceTranslation.linkAccountNewLearnMore}
                  />
                </LinkAccountOptionLearnMoreLink>
              </LinkAccountOptionLearnMore>

              <LinkAccountPlanSelect
                checked={linkNewAccountFlow}
                onChange={setLinkNewAccountFlow}
              />

              <LinkAccountOptionButton
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={handleLinkNewAccountClick}
                disabled={linkNewAccountLoading}
                data-testid="span">
                <FormattedMessage
                  id={WorkspaceTranslation.linkAccountNewButton}
                  tagName="span"
                />
              </LinkAccountOptionButton>
            </LinkAccountOption>
          </>
        )}
      </LinkAccountOptions>
    </Modal>
  );
};
