import styled from 'styled-components';
import { ImportantMessage } from '../../../../shared/components/ImportantMessage';

export const ScheduleConferenceFormField = styled.div`
  label {
    text-align: left;
    margin: 0.75rem 0 0.5rem 0;
  }

  input {
    height: 2.625rem;
    vertical-align: top;
  }

  textarea {
    height: 6.25rem;
    resize: none;
  }
`;

export const DateGroupFields = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  ${({ theme: { breakpoints } }) => breakpoints.md`
     flex-wrap: nowrap;
  `}
  font-size: 1rem;

  & > div {
    flex-basis: 9rem;
  }

  & > div:first-child {
    flex-basis: 100%;
    margin-bottom: 0.75rem;
    ${({ theme: { breakpoints } }) => breakpoints.md`
     margin-bottom: 0;
     flex-basis: 11rem;
  `}
  }

  & > div:last-child {
    flex-basis: 100%;
    margin: 0.75rem 0;
    ${({ theme: { breakpoints } }) => breakpoints.md`
     margin-bottom: 0;
     flex-basis: 11rem;
  `}
  }

  label {
    text-align: left;
    margin: 0.75rem 0 0.5rem 0;
  }

  & > div:not(:last-child) {
    margin-right: 0;
    ${({ theme: { breakpoints } }) => breakpoints.md`
     margin-right: 0.875rem;
  `}
  }
`;

export const RepeatGroupFields = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.75rem 0;

  ${({ theme: { breakpoints } }) => breakpoints.md`
     flex-wrap: nowrap;
  `}
  & > div {
    flex-basis: 100%;
    margin-bottom: 0.75rem;
    ${({ theme: { breakpoints } }) => breakpoints.md`
     flex-basis: 15rem;
     margin-bottom: 0;
  `}
  }

  & > div:first-child {
    flex-basis: 100%;
    ${({ theme: { breakpoints } }) => breakpoints.md`
     flex-basis: 11.125rem;
  `}
  }
`;

export const StyledText = styled.span`
  padding: 0 0.75rem 0.625rem 0;
`;

export const ButtonControls = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: 0.875rem;
  }
`;

export const StyledImportantMessage = styled(ImportantMessage)`
  text-align: left;
  font-family: ${p => p.theme.fonts.Regular};
  margin-bottom: 0;
`;

export const StyledFieldDescription = styled.div`
  text-align: center;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;
