import React, { FC } from 'react';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { Spinner } from '../../../shared/components/Spinner';
import { RelatedDesktopsForm } from './RelatedDesktopsForm/RelatedDesktopsForm';
import styled from 'styled-components';
import { useDesktopsRepository } from '../data/Desktop/Desktop.repositories';
import type { DesktopApiType } from '../data/Desktop/types/Desktop.types';

const StyledManageDesktops = styled.div`
  padding: 16px 25px 23px;
`;

interface ManageRelatedDesktopsProps {
  relatedDesktops: DesktopApiType[];
  deletableRelatedDesktopsMap?: { [key: string]: boolean };
  onSubmit: (desktops: DesktopApiType[]) => void;
}

export const ManageRelatedDesktops: FC<ManageRelatedDesktopsProps> = ({
  relatedDesktops,
  deletableRelatedDesktopsMap,
  onSubmit,
}) => {
  const { workspace } = useCurrentWorkspace();

  const { desktops, loading } = useDesktopsRepository({
    variables: {
      shareable: false,
    },
  });

  return (
    <StyledManageDesktops>
      {loading ? (
        <Spinner />
      ) : (
        <RelatedDesktopsForm
          desktops={desktops}
          relatedDesktops={relatedDesktops}
          deletableRelatedDesktopsMap={deletableRelatedDesktopsMap}
          onSubmit={onSubmit}
        />
      )}
    </StyledManageDesktops>
  );
};
