import { gql } from '@apollo/client';
import { NotificationApiType } from '../Notifications/Notifications.types';

export const MARK_ALL_MENTION_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllMentionNotificationsAsRead(
    $input: markAllAsReadMentionNotificationInput!
  ) {
    markAllAsReadMentionNotification(input: $input) {
      clientMutationId
    }
  }
`;

export interface MarkAllMentionNotificationsAsReadVariables {
  input: {
    workspace: string;
  };
}

export interface MarkAllMentionNotificationsAsReadResponse {
  clientMutationId: string;
}

export const MARK_ALL_THREAD_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllThreadNotificationsAsRead(
    $input: markAllAsReadThreadNotificationInput!
  ) {
    markAllAsReadThreadNotification(input: $input) {
      clientMutationId
    }
  }
`;

export interface MarkAllThreadNotificationsAsReadVariables {
  input: {
    workspace: string;
  };
}

export interface MarkAllThreadNotificationsAsReadResponse {
  clientMutationId: string;
}

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkAsReadNotification($input: markAsReadNotificationInput!) {
    __typename
    markAsReadNotification(input: $input) {
      notification {
        id
        _id
        message {
          id
          fields
          type
        }
        status
        createdAt
      }
    }
  }
`;

export interface MarkNotificationAsReadVariables {
  input: {
    id: string;
    markAsRead: boolean;
  };
}

export interface MarkNotificationAsReadResponse {
  markAsReadMention: {
    notification: NotificationApiType;
  };
  clientMutationId: string;
}
