import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useAcceptInboundCallMutation,
  useJoinAnonymousChatConferenceMutation,
  useJoinChatConferenceMutation,
} from '../Conference.hooks';
import { ConferenceCallType } from '../Conference.types';
import { ConferenceClosedBanner } from './ConferenceClosedBanner';
import { LimitReachedBanner } from './LimitReachedBanner';
import {
  useAppleTouchDevice,
  useQueryParams,
  useSafari,
} from '../../../shared/hooks';
import { NonSafariIOSBanner } from './NonSafariIOSWarningBanner';
import { ConferenceView } from './ConferenceView';
import { getShortId } from '../../../shared/utils/id';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { shouldLogCallJoinToSentry } from '../../../shared/utils/logging.utils';
import {
  useJoinCallInNativeWrapper,
  useNativeWrapper,
} from '../../NativeWrapper';
import { captureException, captureMessage } from '../../ErrorInterceptor';

export enum ConferenceBannerType {
  CLOSED = 'CLOSED',
  LIMIT_REACHED = 'LIMIT_REACHED',
  NON_SAFARI_IOS_WARNING = 'NON_SAFARI_IOS_WARNING',
}

const DEFAULT_CONFERENCE_LIMIT = 5;
const RETRY_TIMEOUT = 200;
const RETRY_LIMIT = 5;

// TODO: remove conference limit workaround after proper backend implementation
// TODO: remove conference fetch retry workaround event sourcing issue solved on backend
export const ConferencePage: FC = () => {
  const isSafari = useSafari();
  const isAppleTouchDevice = useAppleTouchDevice();
  const { roomName } = useParams<{ roomName: string }>();
  const { account } = useCurrentAccount();

  const { ignoreAcceptMutation } = useQueryParams();

  const [bannerToDisplay, setBannerToDisplay] =
    useState<ConferenceBannerType | null>(null);
  const [jwtToken, setJwtToken] = useState<string>();
  const [callType, setCallType] = useState<ConferenceCallType>();
  const [workspaceId, setWorkspaceId] = useState<string>();
  const [chatId, setChatId] = useState<string>();
  const [prejoinPageEnabled, setPrejoinPageEnabled] = useState(false);

  const getConferenceRetries = useRef(0);
  const conferenceLimit = useRef(DEFAULT_CONFERENCE_LIMIT);

  const { isNativeWrapperAvailable } = useNativeWrapper();
  const joinCallInNativeWrapper = useJoinCallInNativeWrapper();

  const [joinChatConferenceMutation] = useJoinChatConferenceMutation();
  const [acceptInboundCallMutation] = useAcceptInboundCallMutation();
  const [joinAnonymousChatConferenceMutation] =
    useJoinAnonymousChatConferenceMutation();
  const joinAnonymously = useCallback(() => {
    return joinAnonymousChatConferenceMutation({
      variables: {
        input: {
          id: '/chat-conferences/' + roomName,
        },
      },
    }).then(res => {
      if (res?.data?.joinAnonymousChatConference.chatConference) {
        const { limit, jwtToken, callType } =
          res?.data?.joinAnonymousChatConference.chatConference;

        if (isNativeWrapperAvailable) {
          joinCallInNativeWrapper({
            roomName: roomName as string,
            token: jwtToken,
            callType,
          });
          return;
        }

        conferenceLimit.current = limit;
        setPrejoinPageEnabled(true);
        setCallType(callType);
        setJwtToken(jwtToken);
      }
    });
  }, [
    isNativeWrapperAvailable,
    joinAnonymousChatConferenceMutation,
    joinCallInNativeWrapper,
    roomName,
  ]);

  const getConference = useCallback(() => {
    return joinChatConferenceMutation({
      variables: {
        input: {
          id: '/chat-conferences/' + roomName,
        },
      },
    })
      .then(res => {
        const chatConference =
          res?.data?.joinFromAnotherWorkspaceChatConference?.chatConference;

        if (chatConference) {
          if (!ignoreAcceptMutation) {
            acceptInboundCallMutation({
              variables: {
                input: {
                  id: chatConference.id,
                },
              },
            });
          }
          const { limit, jwtToken, callType, workspace, chatConversationIri } =
            chatConference;

          if (isNativeWrapperAvailable) {
            joinCallInNativeWrapper({
              roomName: roomName as string,
              token: jwtToken,
              callType,
            });
            return;
          }

          conferenceLimit.current = limit;
          setJwtToken(jwtToken);
          setCallType(callType);
          setChatId(getShortId(chatConversationIri));
          workspace?.id && setWorkspaceId(getShortId(workspace?.id));
        }
      })
      .catch(e => {
        captureException(e);
        if (getConferenceRetries.current < RETRY_LIMIT) {
          if (shouldLogCallJoinToSentry()) {
            captureMessage('call getConference() from ConferencePage (retry)');
          }
          setTimeout(() => {
            getConferenceRetries.current += 1;
            getConference();
          }, RETRY_TIMEOUT);
        } else {
          if (shouldLogCallJoinToSentry()) {
            captureMessage(
              'call joinAnonymously() from ConferencePage (after retries)',
            );
          }
          joinAnonymously();
        }
      });
  }, [
    joinChatConferenceMutation,
    roomName,
    ignoreAcceptMutation,
    isNativeWrapperAvailable,
    acceptInboundCallMutation,
    joinCallInNativeWrapper,
    joinAnonymously,
  ]);

  const { state: locationState } = useLocation();

  useEffect(() => {
    if (locationState) {
      setJwtToken(locationState.jwtToken);
      setCallType(locationState.callType);
      return;
    }
    if (account) {
      if (shouldLogCallJoinToSentry()) {
        captureMessage('call getConference() from ConferencePage');
      }
      getConference();
    } else {
      if (shouldLogCallJoinToSentry()) {
        captureMessage(
          'call joinAnonymously() from ConferencePage (no account)',
        );
      }
      joinAnonymously();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isAppleTouchDevice && !isSafari) {
      setBannerToDisplay(ConferenceBannerType.NON_SAFARI_IOS_WARNING);
    }
  }, [isAppleTouchDevice, isSafari]);

  switch (bannerToDisplay) {
    case ConferenceBannerType.CLOSED:
      return <ConferenceClosedBanner />;
    case ConferenceBannerType.LIMIT_REACHED:
      return <LimitReachedBanner limit={conferenceLimit.current} />;
    case ConferenceBannerType.NON_SAFARI_IOS_WARNING:
      return <NonSafariIOSBanner onProceed={() => setBannerToDisplay(null)} />;
    default:
      return jwtToken && roomName && callType ? (
        <ConferenceView
          callType={callType}
          jwtToken={jwtToken}
          workspaceId={workspaceId}
          chatId={chatId}
          roomName={roomName}
          limit={conferenceLimit.current}
          setBannerToDisplay={setBannerToDisplay}
          prejoinPageEnabled={prejoinPageEnabled}
        />
      ) : null;
  }
};
