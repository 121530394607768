import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  DESKTOP_ROOT_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  DesktopTab,
  FAVORITES_TAB_PATHNAME,
} from '../../../Desktop/Desktop.constants';
import {
  useCurrentDesktop,
  useCurrentDesktopContent,
  useCurrentDesktopPermissions,
  useDeleteSectionMutation,
} from '../../../Desktop/Desktop.hooks';
import { useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { useFilterLinksQuery } from '../../Link.hooks';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  sortByCreationDate,
  sortByMoveDate,
} from '../../../../shared/utils/list.utils';
import { useCurrentTypeOfPage } from '../../../Desktop/Desktop.utils';
import { useConfirm } from '../../../../shared/components/Modal';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import { useFilterFoldersQuery } from '../../../Folder/Folder.hooks';
import { getSubFoldersByFolderId } from '../../../Folder/Folder.utils';
import { useDebounce } from 'use-debounce';
import { GET_TOTAL_LINKS_COUNT } from '../../Link.queries';
import { useApolloClient } from '@apollo/client';
import { LinkApiType } from '../../Link.types';
import { useClipboard } from '../../../Clipboard';
import { isCurrentAccountGuest } from '../../../User/User.utils';
import { getShortId } from '../../../../shared/utils/id';
import { CopyPasteSectionDataContext } from '../../../Desktop/CopyPasteSectionDataProvider';
import { useCurrentDesktopSelectedLinksMap } from '../../../Desktop/DesktopItemMultiselect/DesktopItemMultiselect.hooks';
import { isMacOS } from '../../../../shared/utils/user-agent';
import { LinkTranslation } from '../../i18n';

const FILTER_DEBOUNCE_TIME = 500;

export const SectionLinksViewModel = () => {
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const desktop = useCurrentDesktop();
  const { account } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const { createClipboardData } = useClipboard();

  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [filterString, setFilterString] = useState<string>('');
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const [deleteSectionMutation] = useDeleteSectionMutation();
  const apolloClient = useApolloClient();
  const [loadingTotalCount, setLoadingTotalCount] = useState(false);
  const { desktopFolders, desktopLinks, desktopLinksLoading } =
    useCurrentDesktopContent();
  const { currentDesktopSelectedLinksMap, unselectAllCurrentDesktopLinks } =
    useCurrentDesktopSelectedLinksMap();

  const {
    canAddLink,
    canCreateFolder,
    canCopyDesktopLinksSection,
    canDeleteDesktopLinksSection,
  } = useCurrentDesktopPermissions();

  const { handleCopyLinksDesktopSection } = useContext(
    CopyPasteSectionDataContext,
  );

  const tabbedViewUrl = useMemo(() => {
    if (isFavoritesDesktop) {
      return generatePath(FAVORITES_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        tab: DesktopTab.links,
      });
    } else if (desktop?.id) {
      return generatePath(DESKTOP_TAB_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: getShortId(desktop.id),
        tab: DesktopTab.links,
      });
    } else {
      return generatePath(DESKTOP_ROOT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
    }
  }, [workspace.id, desktop?.id, isFavoritesDesktop]);

  const addLinkHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createLink: true,
      }),
    });
  }, [navigate, queryParams]);

  const addFolderHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createFolder: true,
      }),
    });
  }, [navigate, queryParams]);

  /**
   * Filtered links
   */

  const {
    data: filteredLinksData,
    loading: filteredLinksLoading,
    refetch: refetchFilteredLinksData,
  } = useFilterLinksQuery({
    skip: !filterString,
    variables: {
      desktop: desktop?.id,
      desktop_workspace: workspace.id,
      filterOnlyAccessible: true,
      filterFreeTextSearch: filterString,
    },
    fetchPolicy: 'no-cache',
  });

  const filteredLinks = useMemo(() => {
    const extractedLinks = extractNodes(filteredLinksData?.links);
    return extractedLinks.sort(sortByMoveDate);
  }, [filteredLinksData]);

  const links = filterString.length
    ? filteredLinks
    : isFavoritesDesktop
    ? desktopLinks
    : desktopLinks?.filter(link => {
        const folderId = link.folder?.id
          ? getShortId(link.folder.id)
          : undefined;
        return folderId === queryParams.folderId;
      });
  /**
   * Filter folders
   */

  // TODO: deprecate? Use simple front end filter?

  const { data: filteredFoldersData, loading: filteredFoldersLoading } =
    useFilterFoldersQuery({
      skip: !filterString,
      variables: {
        desktop: desktop?.id,
        desktop_workspace: workspace.id,
        filterOnlyAccessible: true,
        filterFreeTextSearch: filterString,
      },
      fetchPolicy: 'no-cache',
    });

  const filteredFolders = useMemo(
    () => extractNodes(filteredFoldersData?.folders).sort(sortByCreationDate),
    [filteredFoldersData?.folders],
  );

  const folders = filterString.length
    ? filteredFolders
    : getSubFoldersByFolderId(
        desktopFolders,
        queryParams.folderId as string,
        isFavoritesDesktop,
      );

  const filtersLoading = filteredLinksLoading || filteredFoldersLoading;
  const [filterValue, setFilterValue] = useState<string>('');
  const [debouncedFilterValue] = useDebounce(filterValue, FILTER_DEBOUNCE_TIME);

  const filterLinksHandler = useCallback((value: string) => {
    setFilterValue(value.trim());
  }, []);

  useEffect(() => {
    setFilterString(filterValue);
  }, [debouncedFilterValue]); // eslint-disable-line

  const filterEmptyResults =
    !filtersLoading &&
    !!filterString.length &&
    !filteredLinks.length &&
    !filteredFolders.length;

  // clear search input when a folder is clicked
  useEffect(() => {
    setFilterString('');
  }, [queryParams.folderId]);

  const handleDeleteSection = useCallback(() => {
    if (!desktop?.id) {
      return null;
    }
    setLoadingTotalCount(true);

    apolloClient
      .query({
        query: GET_TOTAL_LINKS_COUNT,
        variables: {
          desktopId: desktop.id,
          workspaceId: workspace.id,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        askConfirmation(
          intl.formatMessage(
            {
              id: DesktopTranslation.sectionDeleteLinksConfirmationText,
            },
            {
              deleteCount: data.links.totalCount,
            },
          ),
          undefined,
          {
            dangerConfirm: true,
          },
        ).then(confirm => {
          if (!confirm) {
            return;
          }
          deleteSectionMutation({
            variables: {
              input: {
                id: desktop.id,
                sections: [DesktopTab.links],
              },
            },
          });
        });
      })
      .finally(() => {
        setLoadingTotalCount(false);
      });
  }, [
    desktop?.id,
    apolloClient,
    workspace.id,
    askConfirmation,
    intl,
    deleteSectionMutation,
  ]);

  const showMenuButton =
    !!desktop && (canDeleteDesktopLinksSection || canDeleteDesktopLinksSection);

  const handleCopyLinks = useCallback(
    (linkIds: string[]) => {
      const linksForClipboard: string = links
        .filter(link => linkIds.includes(link.id))
        .map((link: LinkApiType) => link.data.realUrl || link.data.url)
        .join('\n');

      createClipboardData(linksForClipboard);
      handleCopyLinksDesktopSection && handleCopyLinksDesktopSection(linkIds);
    },
    [links, createClipboardData, handleCopyLinksDesktopSection],
  );

  const handleCopyLinksSection = useCallback(() => {
    handleCopyLinksDesktopSection && handleCopyLinksDesktopSection();
    const linksForClipboard: string = links
      .map((link: LinkApiType) => link.data.realUrl || link.data.url)
      .join('\n');

    createClipboardData(linksForClipboard);
  }, [handleCopyLinksDesktopSection, links, createClipboardData]);

  const canDeleteShareableDesktop = desktop?.shareable
    ? desktop.createdBy?.id === account.id
    : true;

  const hasSectionContent = !!(links.length || folders.length);

  const itemsCount = links.length;

  const currentAccountGuest = useMemo(() => {
    return isCurrentAccountGuest(account, getShortId(workspace.id));
  }, [workspace.id, account]);

  const isLinksSectionLoading =
    desktopLinksLoading && !links.length && !folders.length;

  const copyLinksOnCtrlKeydown = useCallback(
    (event: KeyboardEvent) => {
      const selectedText = document.getSelection()?.toString();
      const selectedLinks = Object.keys(currentDesktopSelectedLinksMap);
      if (
        ((isMacOS() && event.metaKey) || (!isMacOS() && event.ctrlKey)) &&
        event.code === 'KeyC' &&
        selectedLinks.length > 0 &&
        !selectedText
      ) {
        handleCopyLinks(selectedLinks);
        if (selectedLinks.length > 1) {
          showToastSuccessMessage(LinkTranslation.linksCopiedMessage, {
            count: selectedLinks.length,
          });
        } else {
          showToastSuccessMessage(LinkTranslation.linkCopiedMessage);
        }
        unselectAllCurrentDesktopLinks();
      }
    },
    [
      currentDesktopSelectedLinksMap,
      handleCopyLinks,
      unselectAllCurrentDesktopLinks,
    ],
  );

  useEffect(() => {
    document.addEventListener('keydown', copyLinksOnCtrlKeydown, false);
    return () => {
      document.removeEventListener('keydown', copyLinksOnCtrlKeydown, false);
    };
  }, [copyLinksOnCtrlKeydown]);

  return {
    showMenuButton,
    handleCopyLinksSection,
    handleCopyLinks,
    handleDeleteSection,
    addLinkHandler,
    addFolderHandler,
    filterLinksHandler: !isFavoritesDesktop ? filterLinksHandler : () => {},
    tabbedViewUrl,
    canAddLink: canAddLink && !isFavoritesDesktop,
    canCreateFolder: canCreateFolder && !isFavoritesDesktop,
    canCopyDesktopSection: canCopyDesktopLinksSection,
    canDeleteShareableDesktop,
    itemsCount,
    hasSectionContent,
    currentAccountGuest,
    links,
    desktopLinksLoading: isLinksSectionLoading,
    folders,
    filterString,
    filtersLoading,
    filterEmptyResults,
    loadingTotalCount,
    desktop,
    refetchFilteredLinksData,
  };
};
