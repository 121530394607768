import { useCallback, useMemo } from 'react';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';
import { useMultipleEntityRepository } from '../../../../shared/hooks/repository/multipleEntityRepository.hooks';
import { useCurrentRoutePath } from '../../../../shared/hooks/router.hooks';
import { isWorkspaceSettingsView } from '../../../../shared/utils/url.utils';
import {
  AccountDesktopsRepositoryProps,
  AccountDesktopsRepositoryResp,
} from './types/DesktopRepository.types';
import { useAccountDesktopsActions } from './AccountDesktops.actions';
import { DesktopApiTypeToDesktopTableType } from '../../../Database/DesktopTable/DesktopsTable.utils';
import { DesktopApiType } from '../Desktop/types/Desktop.types';
import { getWorkspaceDesktopsFromIDDB } from '../Desktop/dataSources/DesktopIDDB.dataSource';
import { createDesktopRowsMap } from '../Desktop/utils/DesktopIDDB.utils';
import { getAccountDesktopsRestApi } from './dataSources/AccountDesktopsRestApi.dataSources';
import { addEntityIdsToIDDBRelatedEntitiesList } from '../../../../shared/utils/iddb.utils';
import { getRedundantCacheDesktops } from '../Desktop/utils/DesktopRepository.utils';
import { removeRelatedDesktopsFromAccountsIDDB } from '../../../Account/data/DesktopAccounts/operations/DesktopAccountIDDB.operations';
import { removeRelatedAccountsFromDesktopsIDDB } from './operations/AccountDesktop.operations';
import { getAccountDesktopsByIdFromIDDB } from './dataSources/AccountDesktopsIDDB.dataSources';

export const useAccountDesktopsRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: AccountDesktopsRepositoryProps): AccountDesktopsRepositoryResp => {
  const { accountId, workspaceId, relatedDesktopsModal } = args;

  const { bulkPutAccountDesktopsToIDDB } = useAccountDesktopsActions();
  const { account } = useCurrentWorkspaceAccount();
  const path = useCurrentRoutePath();

  const isWorkspaceSettings = isWorkspaceSettingsView(path);

  const fetchDesktops = useCallback(async () => {
    try {
      const { data: desktopsFromAPI, ok } = await getAccountDesktopsRestApi(
        accountId,
        workspaceId,
      );

      if (ok) {
        const cachedDesktopRows = await getWorkspaceDesktopsFromIDDB(
          workspaceId,
        );
        const cachedDesktopRowsMap = createDesktopRowsMap(cachedDesktopRows);
        const redundantCacheDesktops = getRedundantCacheDesktops(
          cachedDesktopRows,
          desktopsFromAPI,
        );

        const desktopRows = desktopsFromAPI.map(desktop => {
          const desktopTableType = DesktopApiTypeToDesktopTableType(
            desktop,
            workspaceId,
            desktop.accountIds.includes(account.id),
          );
          return {
            ...desktopTableType,
            relatedTeams: cachedDesktopRowsMap?.[desktop.id]?.relatedTeams,
            relatedAccounts: addEntityIdsToIDDBRelatedEntitiesList(
              cachedDesktopRowsMap?.[desktop.id]?.relatedAccounts,
              [accountId],
            ),
          };
        });

        bulkPutAccountDesktopsToIDDB(desktopRows);

        removeRelatedDesktopsFromAccountsIDDB(
          [accountId],
          redundantCacheDesktops.map(desktop => desktop.id),
        );
        removeRelatedAccountsFromDesktopsIDDB(
          redundantCacheDesktops.map(desktop => desktop.id),
          [accountId],
        );
      }

      return desktopsFromAPI;
    } catch (error) {
      console.error(error);
    }
  }, [bulkPutAccountDesktopsToIDDB, accountId, workspaceId, account.id]);

  const { entities: desktopsEntities, loading } =
    useMultipleEntityRepository<DesktopApiType>({
      fetchFunction: fetchDesktops,
      iddbQuerier: () => getAccountDesktopsByIdFromIDDB(accountId, workspaceId),
      dependencies: [accountId, workspaceId, relatedDesktopsModal],
      fetchPolicy,
    });

  const desktops = useMemo(() => {
    if (isWorkspaceSettings) {
      return desktopsEntities.filter(desktop => !desktop.shareable);
    }

    return desktopsEntities.filter(desktop =>
      desktop.accountIds.includes(account.id),
    );
  }, [account.id, desktopsEntities, isWorkspaceSettings]);

  return useMemo(
    () => ({
      desktops,
      loading,
      refetchDesktops: fetchDesktops,
    }),
    [desktops, loading, fetchDesktops],
  );
};
