import { database } from '../../../Database';
import { TeamsTableType } from '../../../Database/WorkspaceTeams/WorkspaceTeamsTable';

export const useAccountTeamActions = () => {
  const bulkPutAccountTeamsToIDDB = async (teams: TeamsTableType[]) => {
    try {
      database.teams.bulkPut(teams);
    } catch (error) {
      console.error(error);
    }
  };

  return { bulkPutAccountTeamsToIDDB };
};
