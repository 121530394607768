import React, { FC } from 'react';
import { WorkspaceApiType } from '../../../Workspace.types';
import { getWorkspaceInitials } from '../../../../User/User.utils';
import { StyledInitials } from '../../../../User/UserAvatar/Avatar.styled';

interface WorkspaceIconPlaceholderProps {
  workspace: WorkspaceApiType;
}

export const WorkspaceIconPlaceholder: FC<WorkspaceIconPlaceholderProps> = ({
  workspace,
}) => {
  const initials = getWorkspaceInitials(workspace);
  return (
    <StyledInitials size={47} data-testid="initials">
      {initials}
    </StyledInitials>
  );
};
