import React, { FC, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChatConversationInternalType } from '../../../Chat.types';
import { FormInput } from '../../../../../shared/components/Input';
import { ChatTranslation } from '../../../i18n';
import {
  DEFAULT_GROUP_CHAT_NAME,
  MAXIMUM_CHARACTERS_IN_TITLE,
} from '../../../Chat.constants';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { ChatFormControls } from './ChatForm.styled';

export interface ChatFormValues {
  title: string;
}

const ChatFormSchema: Yup.Schema<ChatFormValues> = Yup.object().shape({
  title: Yup.string().max(
    MAXIMUM_CHARACTERS_IN_TITLE,
    ChatTranslation.chatFormTitleMaximumSymbols,
  ),
});

interface ChatFormProps {
  chatConversation: ChatConversationInternalType;
  onSubmit: (values: ChatFormValues) => void;
}

export const ChatForm: FC<ChatFormProps> = ({ chatConversation, onSubmit }) => {
  const initialValues: ChatFormValues = useMemo(
    () => ({
      title:
        chatConversation.title === DEFAULT_GROUP_CHAT_NAME
          ? ''
          : chatConversation.title,
    }),
    [chatConversation.title],
  );

  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChatFormSchema}
      validateOnChange={true}
      onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <Form data-testid="chat-form">
          <Field
            name="title"
            type="text"
            data-testid="chat-form-title-input"
            placeholder={intl.formatMessage({
              id: ChatTranslation.chatFormTitleFieldPlaceholder,
            })}
            label={intl.formatMessage({
              id: ChatTranslation.chatFormTitleFieldLabel,
            })}
            component={FormInput}
          />
          <ChatFormControls>
            <Button
              data-testid="chat-form-submit-button"
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={isSubmitting || !!errors.title}>
              <FormattedMessage
                id={ChatTranslation.chatFormSubmitButtonLabel}
              />
            </Button>
          </ChatFormControls>
        </Form>
      )}
    </Formik>
  );
};
