import { createTheme } from '@material-ui/core';
import { getCurrentTheme } from '../../../domains/Theme';

// If we will use other components from Material make this material theme global
export const materialTheme = () =>
  createTheme({
    typography: {
      fontFamily: getCurrentTheme().fonts.Regular,
      button: {
        textTransform: 'none',
      },
    },
    overrides: {
      MuiFormControl: {
        root: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      },

      MuiInputBase: {
        root: {
          height: '2.5625rem',
          fontFamily: getCurrentTheme().fonts.Semibold,
          fontSize: '1rem',
          color: getCurrentTheme().colors.OnSurfaceHighEmphasis,
          borderColor: getCurrentTheme().colors.BorderDefault,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: getCurrentTheme().colors.BorderDefault,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: getCurrentTheme().colors.Primary,
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: getCurrentTheme().colors.Primary,
        },
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: getCurrentTheme().colors.Primary,
        },
        thumb: {
          borderColor: getCurrentTheme().colors.Primary,
        },
      },
      MuiPickersClockNumber: {
        clockNumber: {
          color: getCurrentTheme().colors.OnSurface,
        },
        clockNumberSelected: {
          backgroundColor: getCurrentTheme().colors.Primary,
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: getCurrentTheme().colors.Surface,
        },
      },
      MuiDialogActions: {
        root: {
          backgroundColor: getCurrentTheme().colors.Surface,
        },
      },
      MuiPickersModal: {},
      MuiButton: {
        root: {
          backgroundColor: 'transparent !important',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
        disableTouchRipple: true,
        focusRipple: true,
        disabled: true,
      },
    },
    transitions: {
      create: () => 'none',
    },
  });
