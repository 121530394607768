import { GraphQLError } from 'graphql/error/GraphQLError';
import { getLongId } from '../../../../shared/utils/id';
import { getApolloClient } from '../../../Api/Api.utils';
import {
  AddAppToDesktopResponse,
  AddAppToDesktopVariables,
  ADD_APP_DESKTOP,
} from '../Operations/Mutations/AddAppToDesktop.mutation';
import {
  DeleteAppFromDesktopResponse,
  DeleteAppFromDesktopVariables,
  DELETE_APP_DESKTOP,
} from '../Operations/Mutations/DeleteAppFromDesktop.mutation';

/**
 * Add app to desktop using API
 * @param id Desktop id
 * @param app App id
 * @returns Desktop
 */
export const addAppToDesktopApi = async (
  desktopId: string,
  appId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: AddAppToDesktopResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      AddAppToDesktopResponse,
      AddAppToDesktopVariables
    >({
      mutation: ADD_APP_DESKTOP,
      variables: {
        input: {
          id: getLongId('desktops', desktopId),
          app: getLongId('apps', appId),
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
/**
 * Delete app from desktop using API
 * @param id Desktop id
 * @param app App id
 */
export const deleteAppFromDesktopApi = async (
  desktopId: string,
  appId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: DeleteAppFromDesktopResponse | null;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.mutate<
      DeleteAppFromDesktopResponse,
      DeleteAppFromDesktopVariables
    >({
      mutation: DELETE_APP_DESKTOP,
      variables: {
        input: {
          id: getLongId('desktops', desktopId),
          app: getLongId('apps', appId),
        },
      },
      errorPolicy: 'all',
    });
    return Promise.resolve({ errors, data: data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
