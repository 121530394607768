import React, { FC } from 'react';
import { ConversationList } from './ConversationList';
import { ConversationsSortedByGroups } from '../../Chat.types';

interface ChatNavigatorProps {
  isLoading: boolean;
  conversations: ConversationsSortedByGroups;
  onSearch: (value: string) => void;
}

export const ChatNavigator: FC<ChatNavigatorProps> = ({
  isLoading,
  conversations,
  onSearch,
}) => (
  <ConversationList
    conversations={conversations}
    onSearch={onSearch}
    isLoading={isLoading}
  />
);
