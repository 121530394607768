import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
} from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../i18n';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import {
  StyledListInfo,
  StyledModalBody,
  StyledTitle,
} from './DowngradeModal.styled';
import { DowngradeForm } from '../../BillingForms/DowngradeForm';

export const DowngradeModal: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const handleClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        isDowngradeModalOpened: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <Modal visible width={600} onRequestClose={handleClose}>
      <ModalHeader onRequestClose={handleClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage id={BillingTranslation.downgradeModalTitle} />
          </ModalHeaderTitle>
        </div>
      </ModalHeader>
      <StyledModalBody data-testid="downgrade-modal-body">
        <StyledTitle>
          <FormattedMessage id={BillingTranslation.downgradeModalHeaderText} />
        </StyledTitle>
        <FormattedMessage id={BillingTranslation.downgradeModalText} />
        <StyledListInfo>
          <li>
            <FormattedMessage
              id={BillingTranslation.downgradeModalTextBullet1}
            />
          </li>
        </StyledListInfo>
        <FormattedMessage id={BillingTranslation.downgradeModalTextBullet2} />
        <DowngradeForm onClose={handleClose} />
      </StyledModalBody>
    </Modal>
  );
};
