import styled from 'styled-components';

export const PlanLayout = styled.div`
  display: grid;
  grid-template-areas: 'info info price';
`;

export const PlanInfo = styled.div`
  grid-area: info;
`;

export const PlanPriceContainer = styled.div`
  grid-area: price;
  padding-top: 46px;
  text-align: right;
  padding-right: 24px;
`;

export const PlanPriceNumber = styled.div`
  font-size: 1.875rem;
  font-family: ${p => p.theme.fonts.Semibold};
  margin-top: 0;
  display: inline-block;
`;

export const PlanPriceCurrency = styled.div`
  display: inline-block;
  font-family: ${p => p.theme.fonts.Semibold};
  position: relative;
  top: -11px;
  text-transform: uppercase;
`;

export const PlanPricePeriod = styled.div`
  display: inline-block;
  :before {
    content: '/ ';
  }
`;

export const WorkspaceMembersCount = styled.p`
  margin-top: 24px;
`;

export const DowngradeButtonContainer = styled.div`
  margin-top: 1rem;
`;
