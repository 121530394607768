export enum ActionType {
  SetScrollToMessageId = 'set_scroll_to_message_id',
  UnsetScrollToMessageId = 'unset_scroll_to_message_id',
  SetOpenThreadOfMessageId = 'set_open_thread_of_message_id',
  UnsetOpenThreadOfMessageId = 'unset_open_thread_of_message_id',
  SetState = 'set_state',
  IncreaseMountedMessageForms = 'increase_mounted_message_forms',
  DecreaseMountedMessageForms = 'decrease_mounted_message_forms',
  SetDraftMessages = 'set_draft_messages',
}

export type ConversationStateAction =
  | {
      type: ActionType.SetScrollToMessageId;
      messageId: string;
    }
  | {
      type: ActionType.UnsetScrollToMessageId;
    }
  | {
      type: ActionType.SetOpenThreadOfMessageId;
      messageId: string;
    }
  | {
      type: ActionType.UnsetOpenThreadOfMessageId;
    }
  | ({
      type: ActionType.SetState;
    } & Partial<ConversationStateState>)
  | {
      type: ActionType.IncreaseMountedMessageForms;
    }
  | {
      type: ActionType.DecreaseMountedMessageForms;
    };

export type ConversationStateState = {
  scrollToMessageId: string | null;
  openThreadOfMessageId: string | null;
  mountedMessageFormsCount: number;
};

export type ConversationStateControls = {
  setScrollToMessage: (id: string) => void;
  unsetScrollToMessage: () => void;
  setOpenThreadOfMessage: (id: string) => void;
  unsetOpenThreadOfMessage: () => void;
  setState: (state: Partial<ConversationStateState>) => void;
};
