import styled from 'styled-components';

export const NotificationAvatar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NotificationAvatarPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${p => p.theme.colors.AvatarPlaceholder};
  background-color: ${p => p.theme.colors.Surface};
`;

export const NotificationAvatarAssets = styled.div`
  position: relative;
`;

export const NotificationSubstanceImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 32px;
  max-height: 32px;
`;

export const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.3;

  > * {
    word-break: break-all;
    word-break: break-word;
  }

  .green {
    color: ${({ theme }) => theme.colors.SuccessDark};
  }
  .red {
    color: ${({ theme }) => theme.colors.CriticalLight};
  }

  .notification-item-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  .notification-link-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NotificationCreatedAt = styled.time`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;
`;

export const StyledNotificationStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;

  &:after {
    content: ' ';
    width: 8px;
    height: 8px;
    background-color: ${p => p.theme.colors.Primary};
    border-radius: 50%;
  }
`;
