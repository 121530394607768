import React, { FC } from 'react';
import { TemplateApiType } from '../../../Template.types';
import {
  Checkbox,
  CheckboxShapeType,
} from '../../../../../shared/components/Checkbox';
import {
  TemplateCheckboxMessage,
  TemplateCheckboxWrapper,
  TemplateIcon,
  TemplateIconsWrapper,
  TemplateLogo,
  TemplateName,
} from './TemplateCheckbox.styled';
import { TemplateAppSet } from '../../../Template.constants';
import GoogleLogo from './logos/google.svg';
import MicrosoftLogo from './logos/microsoft.svg';
import GoogleGmailIcon from './logos/google-gmail.svg';
import GoogleSlidesIcon from './logos/google-slides.svg';
import GoogleDocsIcon from './logos/google-docs.svg';
import MicrosoftExcelIcon from './logos/office-excel.svg';
import MicrosoftWordIcon from './logos/office-word.svg';
import MicrosoftPowerpointIcon from './logos/office-powerpoint.svg';

interface TemplateItemProps {
  template: TemplateApiType;
  checked: boolean;
  onTemplateCheck: (template: TemplateApiType) => void;
}

export const TemplateCheckbox: FC<TemplateItemProps> = ({
  template,
  checked,
  onTemplateCheck,
  ...props
}) => {
  const getTemplateLogo = (template: TemplateApiType): string | null => {
    switch (template.appSet) {
      case TemplateAppSet.google:
        return GoogleLogo;
      case TemplateAppSet.microsoft:
        return MicrosoftLogo;
      default:
        return null;
    }
  };
  const getTemplateAlt = (template: TemplateApiType): string => {
    switch (template.appSet) {
      case TemplateAppSet.google:
        return 'Google logo';
      case TemplateAppSet.microsoft:
        return 'Microsoft logo';
      default:
        return '';
    }
  };

  const getTemplateIcons = (template: TemplateApiType) => {
    switch (template.appSet) {
      case TemplateAppSet.google:
        return (
          <>
            <TemplateIcon src={GoogleGmailIcon} alt="Google Gmail" />
            <TemplateIcon src={GoogleSlidesIcon} alt="Google Slides" />
            <TemplateIcon src={GoogleDocsIcon} alt="Google Docs" />
          </>
        );
      case TemplateAppSet.microsoft:
        return (
          <>
            <>
              <TemplateIcon src={MicrosoftExcelIcon} alt="Microsoft Excel" />
              <TemplateIcon src={MicrosoftWordIcon} alt="Microsoft Word" />
              <TemplateIcon
                src={MicrosoftPowerpointIcon}
                alt="Microsoft Powerpoint"
              />
            </>
          </>
        );
      default:
        return null;
    }
  };

  const templateLogoUrl = getTemplateLogo(template);
  const templateLogoAlt = getTemplateAlt(template);
  const templateIcons = getTemplateIcons(template);

  return (
    <TemplateCheckboxWrapper
      htmlFor={template.id}
      {...props}
      selected={checked}>
      <TemplateCheckboxMessage>
        {templateLogoUrl && (
          <TemplateLogo src={templateLogoUrl} alt={templateLogoAlt} />
        )}
        <TemplateName>{template.name}</TemplateName>
        <TemplateIconsWrapper>{templateIcons}</TemplateIconsWrapper>
      </TemplateCheckboxMessage>
      <Checkbox
        hidden={checked ? false : true}
        id={template.id}
        value={checked ? 'on' : 'off'}
        onChange={() => onTemplateCheck(template)}
        shape={CheckboxShapeType.circle}
      />
    </TemplateCheckboxWrapper>
  );
};
