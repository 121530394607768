import React, { FC, useEffect } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { FormSelect } from '../../../../../shared/components/Select';
import { StyledGeneralSettingsForm } from './GeneralSettingsForm.styled';
import {
  FORM_FIELD_APPS_OPEN_TYPE,
  FORM_FIELD_LINKS_OPEN_TYPE,
  FORM_FIELD_MFA_TYPE,
  FORM_FIELD_THEME_TYPE,
  OpenType,
} from './GeneralSettingsForm.constants';
import { GeneralSettingsTranslation } from '../../../i18n';
import {
  GeneralSettingsApiType,
  GeneralSettingsFormValues,
} from '../../../GeneralSettings.types';
import { ThemeType } from '../../../../Theme';
import {
  MFASectionBody,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderTitle,
} from '../../../../Admin';
import { FormToggle } from '../../../../../shared/components/Toggle/FormToggle';
import { appEnv } from '../../../../../appEnv';
import { Spinner } from '../../../../../shared/components/Spinner';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import {
  FORM_FIELD_PRODUCT_INFORMATION_TYPE,
  FORM_FIELD_SERVICE_UPDATES_TYPE,
  FORM_FIELD_TRANSACTIONAL_EMAILS_TYPE,
} from '../../../NotificationsSettingsAdmin/NotificationsSettingsForm/NotificationsSettingsForm.constants';
import {
  NotificationsSectionBody,
  NotificationsSectionBodyItem,
  NotificationsSectionTitle,
  NotificationsSectionTitleWrapper,
} from '../../../NotificationsSettingsAdmin/NotificationsSettingsForm/NotificationsSettingsForm.styled';

const openTypeSchemaValues = [OpenType.newTab, OpenType.separateWindow];

const themeTypeSchemaValues = [
  // ThemeType.system,
  ThemeType.light,
  ThemeType.dark,
];

const GeneralSettingsFormSchema: Yup.Schema<GeneralSettingsFormValues> =
  Yup.object().shape({
    [FORM_FIELD_APPS_OPEN_TYPE]: Yup.mixed()
      .oneOf(openTypeSchemaValues)
      .required(),
    [FORM_FIELD_LINKS_OPEN_TYPE]: Yup.mixed()
      .oneOf(openTypeSchemaValues)
      .required(),
    [FORM_FIELD_THEME_TYPE]: Yup.mixed()
      .oneOf(themeTypeSchemaValues)
      .required(),
    [FORM_FIELD_MFA_TYPE]: Yup.boolean(),
    [FORM_FIELD_SERVICE_UPDATES_TYPE]: Yup.boolean(),
    [FORM_FIELD_PRODUCT_INFORMATION_TYPE]: Yup.boolean(),
  });

export interface GeneralSettingsFormProps {
  settings: GeneralSettingsApiType | null;
  onSubmit: (formValues: GeneralSettingsFormValues, formikHelpers: any) => void;
}

export const FormikAutoSave: FC = () => {
  const { submitForm, values, dirty } = useFormikContext();
  useEffect(() => {
    if (values && dirty) {
      submitForm();
    }
  }, [dirty, submitForm, values]);

  return null;
};

export const GeneralSettingsForm: FC<GeneralSettingsFormProps> = ({
  onSubmit,
  settings,
}) => {
  const intl = useIntl();
  // TODO investigate why settings can be change on initial renders
  // Related to account data

  if (!settings) {
    return <Spinner />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        openApp: settings.openApp,
        openLink: settings.openLink,
        mfaRequired: settings.mfaRequired,
        theme: settings.theme || ThemeType.light,
        serviceUpdatesEmailsEnabled: settings.serviceUpdatesEmailsEnabled,
        productInformationEmailsEnabled:
          settings.productInformationEmailsEnabled,
      }}
      validationSchema={GeneralSettingsFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleChange, values }) => (
        <Form>
          <StyledGeneralSettingsForm>
            <Section>
              <SectionHeader>
                <SectionHeaderTitle data-testid="title">
                  <FormattedMessage
                    id={GeneralSettingsTranslation.behaviorSectionHeader}
                  />
                </SectionHeaderTitle>
              </SectionHeader>

              <SectionBody data-testid="section-body">
                <Field
                  onChange={handleChange}
                  disabled={isSubmitting}
                  className="fieldBox"
                  name={FORM_FIELD_APPS_OPEN_TYPE}
                  label={intl.formatMessage({
                    id: GeneralSettingsTranslation.selectAppLabel,
                  })}
                  component={FormSelect}>
                  <option value={OpenType.newTab}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionNewTab,
                    })}
                  </option>
                  <option value={OpenType.separateWindow}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionSeparatedWindow,
                    })}
                  </option>
                </Field>
                <Field
                  onChange={handleChange}
                  disabled={isSubmitting}
                  className="fieldBox"
                  name={FORM_FIELD_LINKS_OPEN_TYPE}
                  label={intl.formatMessage({
                    id: GeneralSettingsTranslation.selectLinkLabel,
                  })}
                  component={FormSelect}>
                  <option value={OpenType.newTab}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionNewTab,
                    })}
                  </option>
                  <option value={OpenType.separateWindow}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionSeparatedWindow,
                    })}
                  </option>
                </Field>
              </SectionBody>
            </Section>

            <Section>
              <SectionHeader>
                <SectionHeaderTitle data-testid="title">
                  <FormattedMessage
                    id={GeneralSettingsTranslation.mfaSectionHeader}
                  />
                </SectionHeaderTitle>
              </SectionHeader>

              <MFASectionBody data-testid="mfa-section-body">
                <FormattedHTMLMessage
                  id={GeneralSettingsTranslation.mfaInputTitle}
                  values={{
                    learnMoreUrl: appEnv.MFA_LEARN_MORE_URL,
                  }}
                />
                <Field
                  id="mfa-toggle"
                  data-testid="mfa-toggle"
                  checked={values[FORM_FIELD_MFA_TYPE]}
                  disabled={isSubmitting}
                  className="fieldBox"
                  name={FORM_FIELD_MFA_TYPE}
                  component={FormToggle}
                />
              </MFASectionBody>
            </Section>

            <Section>
              <SectionHeader>
                <SectionHeaderTitle data-testid="title">
                  <FormattedMessage
                    id={GeneralSettingsTranslation.themeSectionHeader}
                  />
                </SectionHeaderTitle>
              </SectionHeader>

              <SectionBody data-testid="section-body">
                <Field
                  onChange={handleChange}
                  disabled={isSubmitting}
                  className="fieldBox"
                  name={FORM_FIELD_THEME_TYPE}
                  label={intl.formatMessage({
                    id: GeneralSettingsTranslation.optionThemeTitle,
                  })}
                  component={FormSelect}>
                  {/*<option value={ThemeType.system}>*/}
                  {/*  {intl.formatMessage({*/}
                  {/*    id: GeneralSettingsTranslation.optionThemeSystemPreference,*/}
                  {/*  })}*/}
                  {/*</option>*/}
                  <option value={ThemeType.light}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionThemeLight,
                    })}
                  </option>
                  <option value={ThemeType.dark}>
                    {intl.formatMessage({
                      id: GeneralSettingsTranslation.optionThemeDark,
                    })}
                  </option>
                </Field>
              </SectionBody>
            </Section>

            <Section>
              <SectionHeader>
                <SectionHeaderTitle data-testid="title">
                  <FormattedMessage
                    id={GeneralSettingsTranslation.notificationsSectionHeader}
                  />
                </SectionHeaderTitle>
              </SectionHeader>

              <NotificationsSectionBody data-testid="notifications-section-body">
                <NotificationsSectionBodyItem>
                  <NotificationsSectionTitleWrapper>
                    <NotificationsSectionTitle data-testid="service-updates-title">
                      <FormattedMessage
                        id={GeneralSettingsTranslation.serviceUpdatesInputTitle}
                      />
                    </NotificationsSectionTitle>
                    <FormattedHTMLMessage
                      id={
                        GeneralSettingsTranslation.serviceUpdatesInputDescription
                      }
                    />
                  </NotificationsSectionTitleWrapper>
                  <Field
                    id="service-updates-toggle"
                    data-testid="service-updates-toggle"
                    checked={values[FORM_FIELD_SERVICE_UPDATES_TYPE]}
                    disabled={isSubmitting}
                    name={FORM_FIELD_SERVICE_UPDATES_TYPE}
                    component={FormToggle}
                  />
                </NotificationsSectionBodyItem>
                <NotificationsSectionBodyItem>
                  <NotificationsSectionTitleWrapper>
                    <NotificationsSectionTitle data-testid="product-information-title">
                      <FormattedMessage
                        id={
                          GeneralSettingsTranslation.productInformationInputTitle
                        }
                      />
                    </NotificationsSectionTitle>
                    <FormattedHTMLMessage
                      id={
                        GeneralSettingsTranslation.productInformationInputDescription
                      }
                    />
                  </NotificationsSectionTitleWrapper>
                  <Field
                    id="product-information-toggle"
                    data-testid="product-information-toggle"
                    checked={values[FORM_FIELD_PRODUCT_INFORMATION_TYPE]}
                    disabled={isSubmitting}
                    name={FORM_FIELD_PRODUCT_INFORMATION_TYPE}
                    component={FormToggle}
                  />
                </NotificationsSectionBodyItem>
                <NotificationsSectionBodyItem>
                  <NotificationsSectionTitleWrapper>
                    <NotificationsSectionTitle data-testid="transactional-emails-title">
                      <FormattedMessage
                        id={
                          GeneralSettingsTranslation.transactionalEmailsInputTitle
                        }
                      />
                    </NotificationsSectionTitle>
                    <FormattedHTMLMessage
                      id={
                        GeneralSettingsTranslation.transactionalEmailsInputDescription
                      }
                    />
                  </NotificationsSectionTitleWrapper>
                  <div
                    data-tooltip-content={intl.formatMessage({
                      id: GeneralSettingsTranslation.transactionalEmailsInputTooltip,
                    })}
                    data-tooltip-place={TooltipPlace.bottom}>
                    <Field
                      id="transactional-emails-toggle"
                      data-testid="transactional-emails-toggle"
                      checked
                      disabled
                      name={FORM_FIELD_TRANSACTIONAL_EMAILS_TYPE}
                      component={FormToggle}
                    />
                  </div>
                </NotificationsSectionBodyItem>
              </NotificationsSectionBody>
            </Section>
            <FormikAutoSave />
          </StyledGeneralSettingsForm>
        </Form>
      )}
    </Formik>
  );
};
