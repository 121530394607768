import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Modal, ModalProps } from '../../../shared/components/Modal';
import { StyledMfaModal } from './MfaModal.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { Spinner } from '../../../shared/components/Spinner';
import { WorkspaceTranslation } from '../../Workspace/i18n';
import { WorkspaceEdgeApiType } from '../../Workspace/Workspace.types';
import { useLoginWithMfa } from '../../Workspace/Workspace.hooks';
import { AccountApiType } from '../../User/User.types';
import { appEnv } from '../../../appEnv';
import { getShortId } from '../../../shared/utils/id';

interface MfaModalProps extends Omit<ModalProps, 'onRequestClose' | 'visible'> {
  accountWorkspaces: WorkspaceEdgeApiType[];
  accountWorkspace: WorkspaceEdgeApiType;
  mainAccount: AccountApiType;
}

export const MfaModal: FC<MfaModalProps> = ({
  accountWorkspace,
  accountWorkspaces,
  mainAccount,
  ...props
}) => {
  const workspace = accountWorkspace.workspace;
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);

  const loginWithMfa = useLoginWithMfa();
  const handleLoginWithMfa = useCallback(() => {
    setProcessing(true);
    loginWithMfa(accountWorkspace.id);
  }, [accountWorkspace.id, loginWithMfa]);

  const workspaceWithoutMfaRequired = useMemo(
    () =>
      accountWorkspaces.find(
        ({ isMfaRequired, workspace: currWorkspace }) =>
          !isMfaRequired && workspace.id !== currWorkspace.id,
      ),
    [accountWorkspaces, workspace.id],
  );

  const cancelMfa = useCallback(() => {
    navigate(
      `/workspace/${getShortId(
        workspaceWithoutMfaRequired?.workspace?.id || '',
      )}`,
    );
  }, [navigate, workspaceWithoutMfaRequired]);

  useEffect(() => {
    if (!workspaceWithoutMfaRequired) {
      loginWithMfa(accountWorkspace.id);
    }
  }, []); // eslint-disable-line

  if (!workspaceWithoutMfaRequired) {
    return null;
  }

  return (
    <Modal width={384} visible onRequestClose={handleLoginWithMfa} {...props}>
      <StyledMfaModal>
        <h1 className="header">
          <FormattedMessage id={WorkspaceTranslation.mfaModalTitle} />
        </h1>
        <p className="body">
          <FormattedHTMLMessage
            id={WorkspaceTranslation.mfaModalText}
            values={{
              workspaceName: workspace.name,
              mfaLearnMoreUrl: appEnv.SUPPORT_MFA_URL,
            }}
          />
        </p>
        <div className="footer">
          {processing ? (
            <Spinner />
          ) : (
            <>
              <Button
                type="button"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={handleLoginWithMfa}
                fullWidth>
                <FormattedMessage
                  id={WorkspaceTranslation.mfaModalConfirmButton}
                />
              </Button>
              <Button
                disabled={!workspaceWithoutMfaRequired}
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={cancelMfa}
                fullWidth>
                <FormattedMessage
                  id={WorkspaceTranslation.mfaModalCancelButton}
                />
              </Button>
            </>
          )}
        </div>
      </StyledMfaModal>
    </Modal>
  );
};
