import Quill from 'quill';
import { EditorMention } from '..';

const Embed = Quill.import('blots/embed');

// MentionBlot fixe issue with deleting Mention on mobile
export class MentionBlot extends Embed {
  static create(data: EditorMention) {
    const node = super.create();

    const denotationChar = document.createElement('span');
    denotationChar.className = 'ql-mention-denotation-char';
    denotationChar.innerHTML = data.denotationChar;
    denotationChar.setAttribute('contenteditable', '');

    const dataContainer = document.createElement('span');
    dataContainer.innerHTML = data.value;
    dataContainer.setAttribute('contenteditable', '');

    const AndroidBackspaceFix = document.createElement('span');
    AndroidBackspaceFix.innerHTML = '&nbsp;';

    AndroidBackspaceFix.setAttribute(
      'style',
      'display: inline-block; height: 1px; width: 1px; overflow: hidden; ',
    );

    node.appendChild(denotationChar);
    node.appendChild(dataContainer);
    node.appendChild(AndroidBackspaceFix);

    return MentionBlot.setDataValues(node, data);
  }

  static setDataValues(element: HTMLElement, data: EditorMention) {
    setTimeout(() => {
      element
        .getElementsByTagName('span')[0]
        .setAttribute('contenteditable', 'inherit');
    }, 0);

    const domNode: HTMLElement = element;
    (Object.keys(data) as string[]).forEach(key => {
      domNode.dataset[key] = data[key as keyof EditorMention];
    });
    return domNode;
  }

  static value(domNode: HTMLElement) {
    return domNode.dataset;
  }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention';
