import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FolderTranslation } from '../i18n';
import { FolderApiType, FolderFormValues } from '../Folder.types';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';

const FolderFormSchema: Schema<FolderFormValues> = Yup.object().shape({
  name: Yup.string().required(FolderTranslation.folderFormNameRequired),
});

interface FolderFormProps {
  folder?: FolderApiType;
  onSubmit: (values: FolderFormValues) => Promise<unknown>;
  onCancel: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
}

const FormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button ~ button {
    margin-left: 12px;
  }
`;

export const FolderForm: FC<FolderFormProps> = ({
  folder,
  onSubmit,
  onCancel,
  autoFocus = false,
  disabled,
}) => {
  const intl = useIntl();
  const initialValues: FolderFormValues = {
    name: folder?.name || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FolderFormSchema}
      onSubmit={(values, actions) => {
        onSubmit(values).then(() => {
          actions.setSubmitting(false);
        });
      }}>
      {({ isSubmitting }) => (
        <Form data-testid="folder-form">
          <div>
            <Field
              name="name"
              type="text"
              data-testid="name-input"
              label={intl.formatMessage({
                id: FolderTranslation.folderFormNameLabel,
              })}
              placeholder={intl.formatMessage({
                id: FolderTranslation.folderFormNamePlaceholder,
              })}
              autoFocus={autoFocus}
              component={FormInput}
              disabled={disabled}
            />

            <FormControls>
              <Button
                data-testid="cancel-button"
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}>
                <FormattedMessage
                  id={FolderTranslation.folderFormCancelButton}
                />
              </Button>
              <Button
                data-testid="submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={isSubmitting || disabled}>
                {folder ? (
                  <FormattedMessage
                    id={FolderTranslation.folderFormsSaveButton}
                  />
                ) : (
                  <FormattedMessage
                    id={FolderTranslation.folderFormsCreateButton}
                  />
                )}
              </Button>
            </FormControls>
          </div>
        </Form>
      )}
    </Formik>
  );
};
