import { gql } from '@apollo/client';

export interface SetAnnouncementVersionVariables {
  input: {
    announcementSeenVersion: number;
    id: string | undefined;
  };
}

export interface SetAnnouncementVersionResponse {
  accountIdentity: {
    announcementSeenVersion: number;
  };
}

export const SET_ANNOUNCEMENT_VERSION = gql`
  mutation SetAnnouncementSeenVersion(
    $input: setAnnouncementSeenVersionAccountIdentityInput!
  ) {
    setAnnouncementSeenVersionAccountIdentity(input: $input) {
      accountIdentity {
        id
        announcementSeenVersion
        __typename
      }
    }
  }
`;
