import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  DangerZoneSection,
  GeneralInformationSection,
  UrlSection,
} from './Sections';
import {
  AdminEntityNotFound,
  AdminHeader,
  AdminHeaderSeparator,
  AdminHeaderTitle,
} from '../../../Admin';
import { UserTranslation } from '../../i18n';
import { useParams } from 'react-router-dom';
import { useInvitationRepository } from '../../../Invitation/data/Invitation/Invitation.repositories';

export const InvitationView: FC<{}> = () => {
  const { invitationId } = useParams<{ invitationId: string }>();

  const { invitation, loading } = useInvitationRepository({
    variables: {
      id: invitationId,
    },
  });

  if (!invitation) {
    return loading ? (
      <Spinner />
    ) : (
      <AdminEntityNotFound>
        <FormattedMessage id={UserTranslation.adminInvitationNotFound} />
      </AdminEntityNotFound>
    );
  }

  return (
    <>
      <AdminHeader data-testid="header">
        <AdminHeaderTitle data-testid="title">
          <FormattedMessage id={UserTranslation.adminSingleUserHeaderTitle} />{' '}
          <AdminHeaderSeparator />{' '}
          <FormattedMessage
            id={UserTranslation.adminListInvitationHeaderTitle}
          />{' '}
          <AdminHeaderSeparator /> {invitation.recipients[0].email}
        </AdminHeaderTitle>
      </AdminHeader>

      <GeneralInformationSection invitation={invitation} />
      <UrlSection invitation={invitation} />
      <DangerZoneSection invitation={invitation} />
    </>
  );
};
