import React, { FC, useCallback, useState } from 'react';
import {
  BannerButton,
  BannerDescription,
  BannerTitle,
  StyledCustomAppBanner,
} from './CreateCustomAppBanner.styled';
import {
  CloseIcon,
  KeyboardArrowRightIcon,
} from '../../../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../../../i18n';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const CreateCustomAppBanner: FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const [isHelpTextCollapsed, setHelpTextCollapsed] = useState(true);
  const handleSetHelpTextCollapsed = useCallback(() => {
    setHelpTextCollapsed(false);
  }, []);

  return (
    <StyledCustomAppBanner>
      {isHelpTextCollapsed && (
        <>
          <BannerTitle>
            <FormattedMessage id={AppStoreTranslation.customAppBannerTitle} />
            <ButtonWithIcon
              icon={CloseIcon}
              onClick={handleSetHelpTextCollapsed}
            />
          </BannerTitle>
          <BannerDescription>
            <FormattedMessage
              id={AppStoreTranslation.customAppBannerDescription}
            />
          </BannerDescription>
        </>
      )}
      <BannerButton
        data-tourid="app-store-create-custom-app-link"
        icon={KeyboardArrowRightIcon}
        onClick={onClick}>
        <FormattedMessage id={AppStoreTranslation.customAppBannerButton} />
      </BannerButton>
    </StyledCustomAppBanner>
  );
};
