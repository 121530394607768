import styled from 'styled-components';
import { Input } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';

export const MasterPasswordLockForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
`;

export const LockScreenIcon = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background-color: ${p => p.theme.colors.SuccessLighter};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${p => p.theme.colors.SuccessDark};
    width: 1.5rem;
    height: 2.375rem;

    ${({ theme: { breakpoints } }) => breakpoints.sm`
      width: 2rem;
    `}
  }

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    width: 5rem;
    height: 5rem;
  `}
`;

export const LockScreenMessage = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  padding: 0.5rem 0 1rem;

  text-align: center;

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    font-size: 1.5rem;
    padding: 1rem 0 1.5rem 0;
  `}
`;

export const LockScreenPasswordInputWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  text-align: left;

  & > span {
    display: inline-block;
    margin-bottom: 0.25rem;
  }
`;

export const LockScreenPasswordInput = styled(Input).attrs({
  type: 'password',
})``;

export const LockScreenUnlockButton = styled(Button).attrs({
  className: 'unlock-password-manager',
  fullWidth: true,
  mode: ButtonMode.primary,
  size: ButtonSize.md,
})`
  margin-top: 1rem;
`;

export const LockScreenErrorMessage = styled.p`
  color: ${p => p.theme.colors.CriticalLight};
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

export const LockScreenRow = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.8rem;
  line-height: 1.5;

  svg {
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    color: ${p => p.theme.colors.Primary};

    ${({ theme: { breakpoints } }) => breakpoints.sm`
      height: 1rem;
      width: 1rem;
    `}
  }

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    font-size: 1rem;
    margin-bottom: 1rem;
  `}
`;

export const PasswordInputHint = styled.span`
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    font-size: 0.875rem;
  `}
`;
