import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FilterEmptyStateImage from '../../../../shared/assets/images/SearchEmptyState.svg';
import { DesktopTranslation } from '../../i18n';

const StyledDesktopFilterEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;
  grid-area: desktop-items;
`;

const EmptyStateImage = styled.img`
  margin-bottom: 2.375rem;
`;

const EmptyStateTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const EmptyStateInner = styled.div`
  max-width: 380px;
`;

type DesktopFilterEmptyStateProps = {
  showIllustration?: boolean;
};

export const DesktopFilterEmptyState: FC<DesktopFilterEmptyStateProps> = ({
  showIllustration = true,
}) => {
  return (
    <StyledDesktopFilterEmptyState>
      <EmptyStateInner>
        {showIllustration && <EmptyStateImage src={FilterEmptyStateImage} />}
        <EmptyStateTitle>
          <FormattedMessage id={DesktopTranslation.filterEmptyStateTitle} />
        </EmptyStateTitle>
        <p>
          <FormattedMessage
            id={DesktopTranslation.filterEmptyStateDescription}
          />
        </p>
      </EmptyStateInner>
    </StyledDesktopFilterEmptyState>
  );
};
