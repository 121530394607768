import { AccessTableType } from '../../../Database/AccessTable/AccessTable';
import { AccessApiType } from '../types/Access.types';

export const getRedundantCacheAccesses = (
  cacheAccesses: AccessTableType[] = [],
  apiAccesses: AccessApiType[] = [],
) => {
  const apiAccessesMap = apiAccesses.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, AccessApiType>,
  );

  return cacheAccesses.filter(access => !apiAccessesMap[access.id]);
};
