import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { Input } from '../../../../shared/components/Input';

export const StyledDesktopFilter = styled.div`
  width: 100%;
  padding: 0.875rem 1rem;
  box-shadow: inset 0px -1px 0px ${p => p.theme.colors.BoxShadowDefault};
  background-color: ${p => p.theme.colors.BackgroundLight};
`;

export const DesktopFilterInner = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const SearchIconWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0.625rem;
  background-color: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const CancelButton = styled(Button)`
  margin-left: 0.5rem;
  padding: 0.375rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const FilterInput = styled(Input)`
  padding: 0.25rem 1rem;
  background-color: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  border-radius: 0;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  border-color: transparent;
  box-shadow: none;
  &:focus {
    padding: 0.25rem 1rem;
  }
`;

export const ClearButton = styled(ButtonWithIcon)`
  padding: 0.625rem 0.875rem;
  background-color: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
