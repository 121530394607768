import { useMemo, useState } from 'react';

export type StaticLoadMoreResponse<T> = {
  dataToDisplay: T[];
  isEndReached: boolean;
  addMore: () => void;
};

export const useStaticLoadMore = <T>(
  data: T[],
  itemsToLoad: number,
): StaticLoadMoreResponse<T> => {
  const [dataSize, setDataSize] = useState(itemsToLoad);

  return useMemo(() => {
    return {
      dataToDisplay: data.slice(0, dataSize),
      isEndReached: dataSize >= data.length,
      addMore: () => {
        setDataSize(prevState => prevState + itemsToLoad);
      },
    };
  }, [data, dataSize, itemsToLoad]);
};
