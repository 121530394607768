import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { WakeUpContext } from './WakeUpProvider.context';
import { isAppleTouchDevice } from '../../shared/utils/user-agent';

const INTERVAL_TIMEOUT = 5000;

// OFFSET can't be less then 5 minutes, because of browser optimization (sleep / away mode)
const OFFSET_MINUTES = 1000 * 60 * 5; // five minutes
const OFFSET_HOURS = 1000 * 60 * 60 * 5; // five hours
let lastTime = Date.now();

//This provider will set intervals on the first load of the app and will check if the app in the background mode.
export const WakeUpProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAwake, setIsAwake] = useState(true);

  const reloadAppleTouchDevice = (currentTime: number) => {
    if (!isAppleTouchDevice()) {
      return;
    }

    if (currentTime > lastTime + INTERVAL_TIMEOUT + OFFSET_HOURS) {
      document.location.reload();
    }
  };

  const wakeUpDetector = useCallback(
    () =>
      setInterval(() => {
        const currentTime = Date.now();

        reloadAppleTouchDevice(currentTime);

        setIsAwake(
          !(currentTime > lastTime + INTERVAL_TIMEOUT + OFFSET_MINUTES),
        );

        lastTime = currentTime;
      }, INTERVAL_TIMEOUT),
    [],
  );

  useEffect(() => {
    const interval = wakeUpDetector();
    return () => {
      clearInterval(interval);
    };
  }, [wakeUpDetector]);

  return (
    <WakeUpContext.Provider value={{ isAwake }}>
      {children}
    </WakeUpContext.Provider>
  );
};
