import styled from 'styled-components';

export const RegistrationField = styled.div`
  display: flex;

  > * {
    flex-basis: 100%;
  }
  > * ~ * {
    margin-left: 16px;
  }

  & ~ & {
    margin-top: 16px;
  }
`;

export const RegistrationFormControls = styled.div`
  margin-top: 32px;
`;

export const HiddenInput = styled.input`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  border: 0;
  margin: 0;
  pointer-events: none;
`;

export const TermsOfUseLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${p => p.theme.colors.Primary};
  padding: 3px;
`;

export const ReCAPTCHAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

export const StyledImportantMessage = styled.div`
  margin: 1rem 0 3.125rem 0;
`;
