import styled from 'styled-components';

export const StyledListItem = styled.li`
  min-height: 3.25rem;
  display: flex;
  align-items: center;
`;

export const ItemBody = styled.div`
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 1rem;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50vw;

    ${({ theme: { breakpoints } }) => breakpoints.md`
      max-width: 12.5rem;
   `}
  }
`;

export const Title = styled.div`
  font-size: 0.875rem;
  font-family: ${p => p.theme.fonts.Medium};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  line-height: 1.43;
`;

export const Subtitle = styled.div`
  font-size: 0.75rem;
  font-family: ${p => p.theme.fonts.Medium};
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  line-height: 1.33;
`;
