import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useCurrentWorkspace, useWorkspacesQuery } from '../Workspace.hooks';
import { WorkspaceIcon } from '../WorkspaceIcon';
import {
  Chevron,
  StyledWorkspaceSelector,
  WorkspaceIconContainer,
  WorkspaceSelectorContainer,
} from './WorkspaceSelector.styled';
import {
  useAllWorkspacesUnreadCounterGetter,
  useUnreadCounterGetter,
} from '../../Counters';
import { extractNodes } from '../../../shared/api/api.utils';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { KeyboardArrowDownIcon } from '../../../shared/icons';
import { WorkspaceSelectorMenu } from './WorkspaceSelectorMenu/WorkspaceSelectorMenu';
import { useModalControls } from '../../../shared/components/Modal/Modal.hooks';
import { LinkAccountModal } from '../WorkspaceSwitcher/WorkspacesList/LinkAccountModal';
import { WorkspaceInfo } from './WorkspaceInfo';
import { OtherWorkspaceNotifications } from './OtherWorkspaceNotifications/OtherWorkspaceNotifications';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { useIntl } from 'react-intl';
import { WorkspaceTranslation } from '../i18n';

type WorkspaceSelectorProps = {
  lightBackground?: boolean;
};

export const WorkspaceSelector: FC<WorkspaceSelectorProps> = ({
  lightBackground,
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { account: authenticatedAccount } = useCurrentAccount();
  const WorkspaceSelectorRef = useRef<HTMLButtonElement>(null);
  const getUnreadCounter = useUnreadCounterGetter();
  const getAllWorkspacesUnreadCounter = useAllWorkspacesUnreadCounterGetter();
  const linkAccountsModalControls = useModalControls();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = useIntl();

  const { data } = useWorkspacesQuery({
    variables: {
      id: authenticatedAccount!.identityId,
    },
  });

  const identityAccounts = useMemo(
    () => (data ? extractNodes(data.accountIdentity.myAccounts) : []),
    [data],
  );

  const identityWorkspaces = useMemo(
    () =>
      identityAccounts
        ? identityAccounts
            .map(account => extractNodes(account.workspaces))
            .flat()
        : [],
    [identityAccounts],
  );

  const workspaces = useMemo(
    () => identityWorkspaces.map(({ workspace }) => workspace),
    [identityWorkspaces],
  );

  const currentWorkspaceNotifications = getUnreadCounter(currentWorkspace.id);
  const allWorkspacesNotifications = getAllWorkspacesUnreadCounter(workspaces);

  const handleClickAdditionalNotifications = useCallback(() => {
    if (WorkspaceSelectorRef.current) WorkspaceSelectorRef.current?.click();
  }, []);

  return (
    <>
      <WorkspaceSelectorContainer>
        <StyledWorkspaceSelector
          data-tooltip-place={TooltipPlace.bottom}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: WorkspaceTranslation.workspaceSelectorTooltip,
          })}
          ref={WorkspaceSelectorRef}
          isMenuOpen={isMenuOpen}
          data-testid="workspace-selector">
          <WorkspaceIconContainer>
            <WorkspaceIcon workspace={currentWorkspace} size={24} />
          </WorkspaceIconContainer>
          <WorkspaceInfo lightBackground={lightBackground} />
          <Chevron lightBackground={lightBackground}>
            <KeyboardArrowDownIcon width={16} />
          </Chevron>
        </StyledWorkspaceSelector>
        {allWorkspacesNotifications > currentWorkspaceNotifications && (
          <OtherWorkspaceNotifications
            currentWorkspaceId={currentWorkspace.id}
            workspaces={workspaces}
            onClickAdditionalNotifications={handleClickAdditionalNotifications}
          />
        )}
      </WorkspaceSelectorContainer>
      <WorkspaceSelectorMenu
        triggerRef={WorkspaceSelectorRef}
        setIsMenuOpen={setIsMenuOpen}
        identityAccounts={identityAccounts}
        createWorkspaceHandler={linkAccountsModalControls.open}
      />
      <LinkAccountModal
        visible={linkAccountsModalControls.visible}
        onRequestClose={linkAccountsModalControls.close}
      />
    </>
  );
};
