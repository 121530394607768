import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  StyledAppsBlock,
  StyledAppsBlockSkeleton,
  StyledAppsHeaderBlock,
  StyledAppsLayoutGrid,
  StyledAppsNavigation,
  StyledAppsViewSkeleton,
  StyledFiltersBlockSkeleton,
} from './AppsViewSkeleton.styled';
import { SkeletonBox } from '../../../../../shared/components/SkeletonBox';
import { useMobile, useTablet } from '../../../../../shared/hooks';
import { ChatViewSkeleton } from './ChatViewSkeleton';
import { LinksViewSkeleton } from './LinksViewSkeleton';

export const FiltersBlockSkeleton = () => {
  const { colors } = useTheme();
  return (
    <StyledFiltersBlockSkeleton>
      <SkeletonBox width={3} height={1.125} color={colors.AppsViewSkeleton} />
    </StyledFiltersBlockSkeleton>
  );
};

export const AppsHeader: FC<{ buttonsLength: number }> = ({
  buttonsLength,
}) => {
  const { colors } = useTheme();
  const isMobile = useMobile();

  return (
    <StyledAppsHeaderBlock>
      <SkeletonBox
        width={isMobile ? 2.5 : 3}
        height={1.125}
        color={colors.AppsViewSkeleton}
      />
      <div>
        {new Array(buttonsLength).fill(null).map((_, i) => (
          <SkeletonBox
            key={i}
            width={1.125}
            height={1.125}
            color={colors.AppsViewSkeleton}
            margin={[0, 0, 0, 1]}
          />
        ))}
      </div>
    </StyledAppsHeaderBlock>
  );
};

export const AppsListSkeleton = () => {
  const { colors } = useTheme();
  const isMobile = useMobile();
  const isTablet = useTablet();

  return (
    <>
      <SkeletonBox
        width="100%"
        height={isTablet ? '100%' : 15}
        color={colors.Surface}
        borderRadius={0.5}>
        <StyledAppsLayoutGrid>
          {new Array(isMobile ? 9 : 10).fill(null).map((_, i) => (
            <SkeletonBox
              key={i}
              width={isMobile ? '113px' : '116px'}
              height={isMobile ? '113px' : '116px'}
              borderRadius={0.5}
              color={colors.SurfaceSubdued}
            />
          ))}
        </StyledAppsLayoutGrid>
      </SkeletonBox>
    </>
  );
};

export const AppsBlockSkeleton = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { colors } = useTheme();

  return (
    <StyledAppsBlockSkeleton isMobile={isMobile}>
      <div className="appLinksWrapper">
        {isTablet && (
          <StyledAppsNavigation isMobile={isMobile}>
            <SkeletonBox width={4} height={1} color={colors.AppsViewSkeleton} />
            <SkeletonBox
              width={4}
              height={1}
              color={colors.AppsViewSkeleton}
              margin={isMobile ? [0, 0, 0, 3] : [0, 0, 0, 1.125]}
            />
            <SkeletonBox
              width={4}
              height={1}
              color={colors.AppsViewSkeleton}
              margin={isMobile ? [0, 0, 0, 3] : [0, 0, 0, 1.125]}
            />
          </StyledAppsNavigation>
        )}
        <AppsHeader buttonsLength={isMobile ? 3 : 4} />
        <StyledAppsBlock>
          <AppsListSkeleton />
        </StyledAppsBlock>
        {!isTablet && <LinksViewSkeleton />}
      </div>
      {!isTablet && <ChatViewSkeleton />}
    </StyledAppsBlockSkeleton>
  );
};

export const AppsViewSkeleton = () => (
  <StyledAppsViewSkeleton>
    <AppsBlockSkeleton />
  </StyledAppsViewSkeleton>
);
