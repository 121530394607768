import { gql } from '@apollo/client';
import { DesktopAppFragmentFields } from '../../../../Desktop/Desktop.fragments';
import { FolderFragmentFields } from '../../../../Folder/Folder.fragments';
import { LinkFragmentFields } from '../../../../Link/Link.fragments';
import type { FolderApiType } from '../../../../Folder/Folder.types';
import type { LinkApiType } from '../../../../Link/Link.types';
import type { ApiConnection } from '../../../../../shared/api/api.types';
import type { DesktopAppEdgeApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

export const GET_WORKSPACE_FAVORITES_REST = gql`
  query GetWorkspaceFavoritesRest($workspaceId: ID!) {
    workspaceFavorites(workspaceId: $workspaceId)
      @rest(path: "/workspace/{args.workspaceId}/favorites")
      @connection(
        key: "workspace_favorites_rest"
        filter: ["args.workspaceId"]
      ) {
      edges {
        node {
          ${DesktopAppFragmentFields}
        }
      }
      edges {
        node {
          ${LinkFragmentFields}
        }
      }
      edges {
        node {
          ${FolderFragmentFields}
        }
      }
    }
  }
`;

export interface GetWorkspaceFavoritesRestVariables {
  workspaceId: string;
}

export interface GetWorkspaceFavoritesRestResponse {
  workspaceFavorites: ApiConnection<
    LinkApiType | FolderApiType | DesktopAppEdgeApiType
  >;
}
