import styled from 'styled-components';

export const WorkspaceMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const WorkspaceMenuItemDragTrigger = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  cursor: grab;
  z-index: 1;
  color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
  touch-action: none;
`;
