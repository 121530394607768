import styled from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';

export const ImageUploadLabel = styled.label`
  ${({ theme }) => theme.fontTemplates.regularSemibold};
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  line-height: 1.43;
  cursor: pointer;
`;

export const NewCustomIntegrationFormImage = styled.img<{ invalid?: boolean }>`
  width: 4rem;
  height: 4rem;
  border-radius: 2px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid
    ${({ theme, invalid }) =>
      !invalid ? theme.colors.BorderDefault : theme.colors.CriticalLight};
`;

export const ImageUploadError = styled.div`
  color: ${p => p.theme.colors.CriticalLight};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: ${({ theme }) => theme.spacing.xxs};
`;

export const ImageUploadButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xs};
`;
