export const chunkArray = (array: any[], chunkSize: number): Array<any[]> => {
  if (chunkSize < 1 || !array.length) {
    return [];
  }

  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
};
