import React, { FC, PropsWithChildren, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
// https://github.com/stripe/stripe-js/issues/43#issuecomment-614864800
import { loadStripe } from '@stripe/stripe-js/pure';

export const StripeElementsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stripePromise] = useState(() =>
    loadStripe(process.env.STRIPE_PUBLISHABLE_KEY),
  );

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
