import styled from 'styled-components';
import {
  Button,
  ButtonSize,
} from '../../../../shared/components/Button/Button';

export const StyledWorkspaceSelect = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};
  margin: 0.25rem 0;
`;

export const WorkspaceSelectButton = styled(Button).attrs({
  size: ButtonSize.md,
  fullWidth: true,
})`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`;

export const CurrentSelectedWorkspace = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
`;

export const CurrentSelectedWorkspaceName = styled.div`
  margin-left: 0.5rem;
  word-break: break-all;
  word-break: break-word;
  text-align: left;
  line-height: 1;
`;

export const SwitchToHeading = styled.h3`
  padding: 0.5rem 1rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 1.5;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  text-transform: uppercase;
`;

export const SwitchToWorkspaceItem = styled.h3`
  padding: 0 2rem 0 0;
  display: flex;
  align-items: center;
`;

export const SwitchToWorkspaceName = styled.h3`
  margin-left: 0.5rem;
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.825rem;
  line-height: 1;
  word-break: break-all;
  word-break: break-word;
`;

export const SwitchToWorkspaceList = styled.div`
  button {
    padding: 0 1rem;
  }
`;
