import React, { FC } from 'react';
import { BaseEmoji } from 'emoji-mart';
import { Popover, PopoverProps } from '../../../../shared/components/Popover';
import { StyledEmojiAutocomplete } from './EmojiAutocompletePopover.styled';
import { getBottomLeftAnchor } from '../../../../shared/components/Popover/Popover.utils';
import { EmojiListItem } from './EmojiListItem';

const HORIZONTAL_MARGIN = 8;

interface EmojiAutocompletePopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRect'> {
  emojis: BaseEmoji[];
  selectedIndex: number;
  onSelect: (emoji: BaseEmoji) => void;
  onIndexChange: (index: number) => void;
}

export const EmojiAutocompletePopover: FC<EmojiAutocompletePopoverProps> = React.memo(
  ({ emojis, selectedIndex, onSelect, onIndexChange, ...popoverProps }) => {
    return (
      <Popover
        verticalMargin={HORIZONTAL_MARGIN}
        anchorGetter={getBottomLeftAnchor}
        {...popoverProps}>
        <StyledEmojiAutocomplete>
          {emojis.map((emoji, i) => (
            <EmojiListItem
              key={emoji.colons}
              emoji={emoji}
              onSelect={onSelect}
              active={selectedIndex === i}
              index={i}
              onIndexChange={onIndexChange}
            />
          ))}
        </StyledEmojiAutocomplete>
      </Popover>
    );
  },
);
