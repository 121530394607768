import React, { FC } from 'react';
import {
  HiddenButton,
  MaintenanceText,
  StyledMaintenanceOverlay,
} from './MaintenanceOverlay.styled';
import { Spinner } from '../../../shared/components/Spinner';
import FocusTrap from 'focus-trap-react';
import { FormattedHTMLMessage } from 'react-intl';
import { MaintenanceTranslation } from '../i18n';

export const MaintenanceOverlay: FC = () => {
  return (
    <StyledMaintenanceOverlay>
      <Spinner size={64} />
      <FormattedHTMLMessage
        id={MaintenanceTranslation.overlayMessageText}
        tagName={MaintenanceText}
      />
      <FocusTrap>
        <div>
          <HiddenButton />
        </div>
      </FocusTrap>
    </StyledMaintenanceOverlay>
  );
};
