import React, { FC, PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';
import { MaintenanceContext } from './Maintenance.context';
import { MaintenanceOverlay } from './MaintenanceOverlay';

const MaintenanceWrapper = styled.div<{ pointerEventsNone: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-height: fill-available;

  ${p =>
    p.pointerEventsNone &&
    css`
      pointer-events: none !important;
    `}
`;

export const MaintenanceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [maintenanceOn, setMaintenance] = useState(false);
  return (
    <MaintenanceContext.Provider value={{ maintenanceOn, setMaintenance }}>
      <MaintenanceWrapper pointerEventsNone={maintenanceOn}>
        {children}
      </MaintenanceWrapper>
      {maintenanceOn && <MaintenanceOverlay />}
    </MaintenanceContext.Provider>
  );
};
