import { useContext } from 'react';
import { QueryHookOptions, useMutation } from '@apollo/client';
import {
  GET_NOTIFICATIONS,
  GetNotificationsResponse,
  GetNotificationsVariables,
} from './Notifications.queries';
import {
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATIONS_AS_READ,
  MarkAllAsReadNotificationResponse,
  MarkAllAsReadNotificationVariables,
  MarkAsReadNotificationResponse,
  MarkAsReadNotificationVariables,
} from './Notifications.mutations';
import { NotificationsContext } from './Notifications.context';
import { useRefreshableQuery } from '../Api/Api.hooks';

export const useGetNotificationsQuery = (
  options?: QueryHookOptions<
    GetNotificationsResponse,
    GetNotificationsVariables
  >,
) =>
  useRefreshableQuery<GetNotificationsResponse, GetNotificationsVariables>(
    GET_NOTIFICATIONS,
    options,
  );

export const useMarkAllAsReadNotificationMutation = () =>
  useMutation<
    MarkAllAsReadNotificationResponse,
    MarkAllAsReadNotificationVariables
  >(MARK_ALL_NOTIFICATIONS_AS_READ);

export const useMarkAsReadNotificationMutation = () =>
  useMutation<MarkAsReadNotificationResponse, MarkAsReadNotificationVariables>(
    MARK_NOTIFICATIONS_AS_READ,
  );

export const useNotifications = () => useContext(NotificationsContext);
