import styled, { css } from 'styled-components';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { ExternalUrlDropZone } from '../../Drag/ExternalUrlDropZone';

export const LINK_COLUMN_GAP = '1rem';
export const LINK_ROW_GAP = '0.5rem';

export const OpenLinkConfirmationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const OpenLinkConfirmButton = styled(Button).attrs({
  size: ButtonSize.md,
})`
  background-color: ${p => p.theme.colors.Warning};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const OpenLinkCancelButton = styled(Button).attrs({
  size: ButtonSize.md,
  mode: ButtonMode.secondary,
})`
  flex-shrink: 0;
  flex-grow: 0;
`;

const Grid = css`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
`;

export const FoldersGrid = styled.div`
  ${Grid};
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  padding: 0.125rem 0.125rem 0.5rem 0.125rem;
`;

export const LinksGridDropZone = styled(ExternalUrlDropZone)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
