import React, { FC, PropsWithChildren, useMemo } from 'react';
import {
  OnboardingAccountSection,
  OnboardingContent,
  OnboardingContentAfterPart,
  OnboardingContentBeforePart,
  OnboardingContentMainPart,
  OnboardingContentWrapper,
  OnboardingLogo,
  OnboardingPageBodyGlobalStyles,
  StyledOnboardingPage,
} from './OnboardingPage.styled';
import DesktopLogo from '../../../shared/assets/images/logo.svg';
import DesktopLogoLight from '../../../shared/assets/images/logo-light.svg';
import { GlobalTranslation } from '../../Intl/i18n/i18n.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { OnboardingTranslation } from '../i18n';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { LOGIN_PATHNAME, LOGOUT_PATHNAME } from '../../Auth/Auth.constants';
import { useTheme } from '../../Theme';
import { ThemeType } from '../../Theme/Theme.types';
import {
  getStoredNativePlatform,
  useNativePlatformFromUrl,
} from '../../NativeWrapper';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';

interface OnboardingPageProps {
  before?: FC | null | false;
  after?: FC | null | false;
  displayAccountSection?: boolean;
  horizontallyCentered?: boolean;
  wrapperWidth?: number;
}

export const OnboardingPage: FC<PropsWithChildren<OnboardingPageProps>> = ({
  children,
  before: Before,
  after: After,
  displayAccountSection = true,
  horizontallyCentered = false,
  wrapperWidth = 384,
}) => {
  const intl = useIntl();
  const { account } = useCurrentAccount();
  const { themeType } = useTheme();

  const currentThemeLogo =
    themeType === ThemeType.dark ? DesktopLogoLight : DesktopLogo;

  const platformParam = useNativePlatformFromUrl();
  const nativePlatform = useMemo(() => {
    if (platformParam) {
      return platformParam;
    }
    return getStoredNativePlatform();
  }, [platformParam]);

  return (
    <>
      <OnboardingPageBodyGlobalStyles />
      <StyledOnboardingPage data-testid="onboarding-page">
        <OnboardingLogo data-testid="logo-panel">
          <img
            src={currentThemeLogo}
            alt={intl.formatMessage({ id: GlobalTranslation.logoAlt })}
            data-testid="logo-img"
          />
        </OnboardingLogo>
        <OnboardingContentWrapper>
          <OnboardingContent
            data-testid="onboarding-content"
            horizontallyCentered={horizontallyCentered}>
            {Before && (
              <OnboardingContentBeforePart
                data-testid="onboarding-content-before-part"
                contentWidth={wrapperWidth}>
                <Before />
              </OnboardingContentBeforePart>
            )}
            <OnboardingContentMainPart contentWidth={wrapperWidth}>
              {children}
              {displayAccountSection && (
                <>
                  {account ? (
                    <OnboardingAccountSection data-testid="logout-panel">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingPageLogoutText}
                      />{' '}
                      <Link
                        to={{
                          pathname: LOGOUT_PATHNAME,
                          search: getQueryParamsFrom({
                            sendLogoutMessageToOtherTabs: true,
                          }),
                        }}
                        data-testid="logout-link">
                        <FormattedMessage
                          id={OnboardingTranslation.onboardingPageLogoutLink}
                        />
                      </Link>
                    </OnboardingAccountSection>
                  ) : !nativePlatform ? (
                    <OnboardingAccountSection data-testid="login-panel">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingPageLoginText}
                      />{' '}
                      <Link to={LOGIN_PATHNAME} data-testid="login-link">
                        <FormattedMessage
                          id={OnboardingTranslation.onboardingPageLoginLink}
                        />
                      </Link>
                    </OnboardingAccountSection>
                  ) : null}
                </>
              )}
            </OnboardingContentMainPart>
            {After && (
              <OnboardingContentAfterPart
                data-testid="onboarding-content-after-part"
                contentWidth={wrapperWidth}>
                <After />
              </OnboardingContentAfterPart>
            )}
          </OnboardingContent>
        </OnboardingContentWrapper>
      </StyledOnboardingPage>
    </>
  );
};
