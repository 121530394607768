import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  FileSize,
  FileType,
  StyledAssetActions,
  StyledAssetMenuButton,
  StyledMessageAsset,
  StyledMessageAssetContainer,
  VideoPlayerIconContainer,
} from './MessageAsset.styled';
import type {
  ChatMessageAssetApiType,
  ChatTextMessageInternalType,
} from '../../../../Chat.types';
import {
  isImageAsset,
  isImageMimeType,
  isVideoMimeType,
} from '../../../../../Asset';
import { useAssetPreviewControls } from '../../../../../Asset/AssetPreviewProvider';
import {
  DocumentType,
  useDocumentIsSupportedPreviewType,
} from '../../../../../Preview/DocumentPreview';
import { DocumentDynamicIcon } from '../../../../../Preview/DocumentPreview/DocumentDynamicIcon';
import { AssetViewImage } from './AssetView';
import {
  ChatDocumentIcon,
  ChatVideoIcon,
  DotsIcon,
} from '../../../../../../shared/icons';
import { LinkImageContainer } from '../../../../../Link/LinksView/Link/LinkImage/LinkImage.styled';
import { StyledDocumentDynamicIcon } from '../../../../../Preview/DocumentPreview/DocumentDynamicIcon/DocumentDynamicIcon.styled';
import { OriginalVideoMetaData } from '../../../../../Asset/Asset.types';
import {
  Hostname,
  StyledLinkData,
  Title,
} from '../../../../../Link/LinksView/Link/LinkData/LinkData.styled';
import { prettyFilename } from './MessageAsset.utils';
import prettyBytes from 'pretty-bytes';
import { AssetBubble, AssetBubbleContainer } from '../ChatMessage.styled';
import { VideoPlayerPreview } from '../../../../../VideoPlayer/VideoPlayer.preview';
import { useMobile } from '../../../../../../shared/hooks';
import { ChatTranslation } from '../../../../i18n';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { useIntl } from 'react-intl';
import { usePrepareChatMessageAsset } from './MessageAsset.hooks';

export interface MessageAssetProps {
  originalAsset: ChatMessageAssetApiType | null;
  chatMessage: ChatTextMessageInternalType;
  asset: ChatMessageAssetApiType;
  ownMessage: boolean;
  assetMenu: (
    asset: ChatMessageAssetApiType,
    ref: React.RefObject<HTMLButtonElement>,
  ) => void;
  messageAssets?: ChatMessageAssetApiType[];
  assetCounter?: number;
}

export const MessageAsset: FC<MessageAssetProps> = React.memo(
  ({
    asset,
    originalAsset,
    chatMessage,
    ownMessage,
    assetMenu,
    messageAssets,
    assetCounter,
  }) => {
    const isMobile = useMobile();
    const { showAssetPreview } = useAssetPreviewControls();

    const { assets, sliderAssets } = usePrepareChatMessageAsset(
      messageAssets,
      isMobile,
    );

    const moreActionsButtonRef = useRef<HTMLButtonElement>(null);

    const [assetMenuVisible, setAssetMenuVisible] = useState(false);

    const docData =
      asset?.metadata && 'type' in asset.metadata ? asset.metadata : null;

    const canPreviewDocument = useDocumentIsSupportedPreviewType(asset);

    const isVideoPreviewReady = isVideoMimeType(asset.mimeType);

    const isImage = isImageMimeType(asset.mimeType);

    const { formatMessage } = useIntl();

    const isMultipleImage = useMemo(() => {
      if (!isImage || !assets) {
        return false;
      }

      const imageCount =
        assets.filter(asset => isImageMimeType(asset.mimeType)).length ?? 0;

      return imageCount > 1;
    }, [assets, isImage]);

    const showAssetPreviewHandler = useCallback(
      (event: React.SyntheticEvent<Element, Event>) => {
        if (isImageAsset(asset.assetIri)) {
          event.preventDefault();
          showAssetPreview(
            sliderAssets?.length ? sliderAssets : assets,
            chatMessage,
            asset.assetIri,
          );
        }
      },
      [sliderAssets, asset.assetIri, showAssetPreview, assets, chatMessage],
    );

    const showFileAssetPreview = useCallback(
      (event: React.SyntheticEvent<Element, Event>) => {
        if (canPreviewDocument && docData) {
          event.preventDefault();
          showAssetPreview(assets, chatMessage, asset.assetIri);
        }
      },
      [
        asset.assetIri,
        assets,
        canPreviewDocument,
        chatMessage,
        docData,
        showAssetPreview,
      ],
    );

    const handleChatMessageMenuButton = useCallback(() => {
      assetMenu(asset, moreActionsButtonRef);
    }, [asset, assetMenu]);

    const handleMouseEnter = useCallback(() => {
      if (isMobile) {
        return;
      }
      setAssetMenuVisible(true);
    }, [isMobile]);

    const handleMouseLeave = useCallback(() => {
      if (isMobile) {
        return;
      }
      setAssetMenuVisible(false);
    }, [isMobile]);

    const assetMenuRender = useMemo(
      () => (
        <StyledAssetActions data-testid="asset-actions">
          <StyledAssetMenuButton
            ref={moreActionsButtonRef}
            data-testid="asset-more-actions-btn"
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: ChatTranslation.tooltipMoreActionsButton,
            })}
            data-tooltip-place={TooltipPlace.bottom}
            type="button"
            icon={DotsIcon}
            isMobile={isMobile}
            visible={isMobile || assetMenuVisible}
            onClick={handleChatMessageMenuButton}
          />
        </StyledAssetActions>
      ),
      [assetMenuVisible, formatMessage, handleChatMessageMenuButton, isMobile],
    );

    if (chatMessage.context.assets && isImage) {
      return (
        <AssetViewImage
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          asset={asset}
          showPreview={showAssetPreviewHandler}
          multipleImage={isMultipleImage}
          assetsLength={chatMessage.context.assets.length}
          assetCounter={assetCounter}
          data-testid="message-asset-container">
          {assetMenuRender}
        </AssetViewImage>
      );
    }
    const isVideoAsset = isVideoMimeType(asset.mimeType);

    return (
      <AssetBubbleContainer data-testid="message-asset-container">
        <AssetBubble
          ownMessage={ownMessage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isSingleImageAsset={isMultipleImage}
          data-testid="asset-bubble">
          <StyledMessageAssetContainer
            isSingleImage={isMultipleImage}
            withDocAsset={canPreviewDocument}>
            <StyledMessageAsset
              reverseRow={isVideoPreviewReady}
              href={isVideoAsset ? undefined : asset.url}
              rel="noopener noreferrer"
              {...{ download: asset.filename }}
              onClick={showFileAssetPreview}
              data-testid="message-asset">
              {!isMultipleImage && (
                <StyledLinkData
                  data-testid="link-data"
                  lowHeight={isVideoPreviewReady}>
                  <Title data-testid="link-title">
                    {prettyFilename(asset.filename)}
                  </Title>
                  <Hostname data-testid="link-file-info">
                    {canPreviewDocument && docData && asset.metadata && (
                      <>
                        <DocumentDynamicIcon
                          messageAsset={asset}
                          iconWidth={20}
                          iconHeight={20}
                        />
                        <FileType data-testid="file-type">
                          {docData.type}
                        </FileType>
                      </>
                    )}
                    <FileSize data-testid="file-size">
                      {prettyBytes(parseInt(asset.size, 10))}
                    </FileSize>
                  </Hostname>
                </StyledLinkData>
              )}

              {canPreviewDocument && docData && (
                <DocumentDynamicIcon
                  messageAsset={asset}
                  withBackground
                  isAsset
                />
              )}

              {!canPreviewDocument && isVideoPreviewReady ? (
                <VideoPlayerIconContainer data-testid="video-icon-container">
                  <ChatVideoIcon width={46} height={46} />
                </VideoPlayerIconContainer>
              ) : (
                !isImage &&
                !canPreviewDocument && (
                  <LinkImageContainer
                    isAsset={true}
                    data-testid="link-image-container">
                    <StyledDocumentDynamicIcon type={DocumentType.COMMON}>
                      <ChatDocumentIcon width={30} height={38} />
                    </StyledDocumentDynamicIcon>
                  </LinkImageContainer>
                )
              )}
            </StyledMessageAsset>

            <VideoPlayerPreview
              mimeType={asset.mimeType}
              url={asset.url}
              previewImageSrc={
                (originalAsset?.metadata as OriginalVideoMetaData)
                  ?.thumbnail_url ?? ''
              }
              withPadding={false}
            />
          </StyledMessageAssetContainer>
          {assetMenuRender}
        </AssetBubble>
      </AssetBubbleContainer>
    );
  },
);
