import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EncryptionContext, EncryptionContextType } from './Encryption.context';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
  useWorkspacesQuery,
} from '../Workspace/Workspace.hooks';
import {
  getAccountKeyset,
  getCEPH,
  getVaultAccess,
  setCEPH,
} from './Encryption.utils';
import {
  useCurrentAccountKeyset,
  useGetVaultsRestQuery,
} from './Encryption.hooks';
import { extractNodes } from '../../shared/api/api.utils';
import { VaultApiType } from './Encryption.types';
import { toast, ToastPosition } from 'react-toastify';
import { MasterPasswordLockScreen } from './MasterPasswordLockScreen';
import { MASTER_PASSWORD_LOCK_TOAST_ID } from './Encryption.constants';
import { useQueryParams } from '../../shared/hooks';
import { ReencryptVaultAccessesModal } from './ReencryptVaultAccesses';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { hashMasterPassword } from '../../shared/utils/hash.utils';
import { decryptPrivateKey } from './Encryption.crypto.utils';
import { useIntl } from 'react-intl';
import { GlobalTranslation } from '../Intl/i18n';
import { StyledCloseButton } from '../../shared/components/Toast/Toast.styled';
import { CloseIcon } from '../../shared/icons';
import { useNativeWrapper } from '../NativeWrapper';
import { MessageFromNativeWrapper } from '../NativeWrapper/NativeWrapper.types';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import { AccountWithWorkspacesApiType } from '../User/User.types';
import { useWindowMessageCallback } from '../WindowMessage/WindowMessage.hooks';
import { WindowMessageType } from '../WindowMessage/WindowMessage.types';
import {
  useEncryptionKeysMaps,
  useEncryptionVaultsMaps,
} from './Encryption.crypto.hooks';
import { StreamEvent, StreamEventType } from '../Mercure/General';
import { handleVaultMercureEvent } from './events/Vault.mercure';
import { handleVaultItemMercureEvent } from './events/VaultItem.mercure';
import { handleVaultAccessMercureEvent } from './events/VaultAccess.mercure';
import { handleVaultItemConfigDesktopMercureEvent } from './events/VaultItemConfigDesktop.mercure';
import { useMercureListener } from '../Mercure/General/GeneralMercure.hooks';

export const EncryptionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: { canUnlockCredentials },
  } = useCurrentWorkspacePermissions();
  const { account } = useCurrentWorkspaceAccount();
  const { keyset, previousKeysets } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;
  const { formatMessage } = useIntl();
  const { setMessageCallback } = useWindowMessageCallback(
    WindowMessageType.CEPH_UPDATED,
  );

  const {
    sendMessageToNativeWrapper,
    isNativeWrapperAvailable,
    subscribeToMessages,
  } = useNativeWrapper();

  const queryParams = useQueryParams();
  const { reencryptVaultAccesses, reencryptVaultAccessesRequestId } =
    queryParams;
  const navigate = useNavigate();

  const {
    accountPublicKeysMap,
    accountPrivateKeysMap,
    setAccountPrivateKeysMap,
  } = useEncryptionKeysMaps();

  const accountId = account?.id;
  const accountEmail = account?.email;
  const currentAccountPrivateKeyString = keyset?.privKeyEncrypted;
  const currentAccountPrivateKey =
    accountId && accountPrivateKeysMap[accountId];

  const {
    data: workspaceVaultsData,
    loading: workspaceVaultsLoading,
    refetch: refetchWorkspaceVaults,
  } = useGetVaultsRestQuery({
    skip:
      !account ||
      !workspace ||
      !accountPrivateKeysMap[accountId] ||
      !canUnlockCredentials,
    variables: {
      workspaceId: workspace?.id,
      vaultItemConfigDesktopsAccountId: accountId,
    },
    fetchPolicy: 'no-cache',
  });

  // TODO: rework to repository architecture
  const [workspaceVaultsCache, setWorkspaceVaultsCache] = useState<
    Record<string, VaultApiType[]>
  >({});
  useEffect(() => {
    if (!workspaceVaultsData) {
      return;
    }
    setWorkspaceVaultsCache(cache => ({
      ...cache,
      [workspace.id]: cache[workspace.id]
        ? cache[workspace.id]
        : extractNodes(workspaceVaultsData?.vaults)
            .filter(vault => !!vault?.vaultItems?.edges)
            .filter(
              (vault: VaultApiType) =>
                !!getVaultAccess(vault, keysetId) ||
                previousKeysets?.some(
                  previousKeyset => !!getVaultAccess(vault, previousKeyset.id),
                ),
            ),
    }));
  }, [workspaceVaultsData, keysetId, previousKeysets, workspace.id]);

  const { addListener, removeListener } = useMercureListener();
  useEffect(() => {
    const listener = (event: StreamEvent) => {
      switch (event['@type']) {
        case StreamEventType.VAULT:
          handleVaultMercureEvent(event, setWorkspaceVaultsCache);
          break;
        case StreamEventType.VAULT_ACCESS:
          handleVaultAccessMercureEvent(
            event,
            setWorkspaceVaultsCache,
            keysetId,
          );
          break;
        case StreamEventType.VAULT_ITEM:
          handleVaultItemMercureEvent(event, setWorkspaceVaultsCache);
          break;
        case StreamEventType.VAULT_ITEM_CONFIG_DESKTOP:
          handleVaultItemConfigDesktopMercureEvent(
            event,
            setWorkspaceVaultsCache,
          );
          break;
      }
    };
    addListener(listener);
    return () => removeListener(listener);
  }, [addListener, removeListener, keysetId]);

  const {
    workspaceVaultsMap,
    workspaceAppVaultsMap,
    workspaceAppDesktopVaultsMap,
    workspaceAppGroupVaultsMap,
    workspaceVaultLoginsMap,
  } = useEncryptionVaultsMaps(
    workspaceVaultsCache[workspace.id],
    accountPrivateKeysMap,
  );

  const hideMasterPasswordLockToast = useCallback(() => {
    toast.dismiss(MASTER_PASSWORD_LOCK_TOAST_ID);
  }, []);

  const decryptAndSetPrivateKey = useCallback(
    async (privKeyEncrypted: string, accountId: string, hash: string) => {
      const decryptedPrivateKey = await decryptPrivateKey(
        privKeyEncrypted,
        hash,
        accountId,
      );
      setAccountPrivateKeysMap(map => ({
        ...map,
        [accountId]: decryptedPrivateKey,
      }));
      hideMasterPasswordLockToast();
      return decryptedPrivateKey;
    },
    // eslint-disable-next-line
    [hideMasterPasswordLockToast],
  );

  const decryptCurrentAccountPrivateKeyWithHash = useCallback(
    async (hash: string, sendHashToExtension?: boolean) => {
      if (!currentAccountPrivateKeyString) {
        return Promise.reject('No private key');
      }

      const decryptedPrivateKey = await decryptAndSetPrivateKey(
        currentAccountPrivateKeyString,
        accountId,
        hash,
      );

      if (decryptedPrivateKey && sendHashToExtension) {
        setCEPH(accountEmail, hash).catch(() => {});
      }

      if (isNativeWrapperAvailable && sendHashToExtension) {
        sendMessageToNativeWrapper({
          type: 'SET_CEPH',
          accountId,
          CEPH: hash,
        });
      }

      return decryptedPrivateKey;
    },
    [
      currentAccountPrivateKeyString,
      decryptAndSetPrivateKey,
      accountId,
      isNativeWrapperAvailable,
      accountEmail,
      sendMessageToNativeWrapper,
    ],
  );

  const { account: mainAccount } = useCurrentAccount();
  const { data: workspaceData } = useWorkspacesQuery({
    skip: !mainAccount,
    variables: {
      id: mainAccount?.identityId as string,
    },
    fetchPolicy: 'cache-only',
  });

  const accountMap = useMemo(() => {
    return (
      extractNodes(
        workspaceData?.accountIdentity.myAccounts,
      ) as AccountWithWorkspacesApiType[]
    ).reduce<Record<string, AccountWithWorkspacesApiType>>(
      (acc, item: AccountWithWorkspacesApiType) => {
        return {
          ...acc,
          [item.id]: item,
        };
      },
      {},
    );
  }, [workspaceData]);

  const nativeWrapperMessageSubscriber = useCallback(
    (message: MessageFromNativeWrapper) => {
      if (message.type !== 'SET_CEPH') {
        return;
      }

      const relatedAccount = accountMap[message.accountId];
      if (!relatedAccount) {
        return;
      }
      const privKeyEncrypted =
        getAccountKeyset(relatedAccount)?.privKeyEncrypted;
      if (!privKeyEncrypted) {
        return;
      }
      decryptAndSetPrivateKey(
        privKeyEncrypted,
        message.accountId,
        message.CEPH,
      );
    },
    [accountMap, decryptAndSetPrivateKey],
  );

  useEffect(() => {
    if (!isNativeWrapperAvailable) {
      return;
    }
    const subscription = subscribeToMessages(nativeWrapperMessageSubscriber);
    sendMessageToNativeWrapper({ type: 'CEPH_SUBSCRIBED' });
    return () => {
      subscription.remove();
    };
  }, []); // eslint-disable-line

  const decryptCurrentAccountPrivateKeyWithPassword = useCallback(
    async (password: string) => {
      if (!currentAccountPrivateKeyString) {
        return Promise.reject('No private key');
      }

      const hash = await hashMasterPassword(password, accountEmail);
      return decryptCurrentAccountPrivateKeyWithHash(hash, true);
    },
    [
      accountEmail,
      currentAccountPrivateKeyString,
      decryptCurrentAccountPrivateKeyWithHash,
    ],
  );

  useEffect(() => {
    if (currentAccountPrivateKeyString) {
      getCEPH(accountEmail)
        .then(hash => {
          if (hash) {
            decryptCurrentAccountPrivateKeyWithHash(hash);
          }
        })
        .catch(() => {});
    }
  }, [
    currentAccountPrivateKeyString,
    decryptCurrentAccountPrivateKeyWithHash,
    accountEmail,
  ]);

  useEffect(() => {
    if (
      currentAccountPrivateKeyString &&
      accountEmail &&
      !currentAccountPrivateKey
    ) {
      setMessageCallback(() => {
        getCEPH(accountEmail)
          .then(hash => {
            if (hash) {
              decryptCurrentAccountPrivateKeyWithHash(hash);
            }
          })
          .catch(() => {});
      });
    }
    // eslint-disable-next-line
  }, [
    currentAccountPrivateKeyString,
    accountEmail,
    currentAccountPrivateKey,
    decryptCurrentAccountPrivateKeyWithHash,
  ]);

  const CloseButton = ({ closeToast }: { closeToast?: () => void }) => (
    <StyledCloseButton
      icon={CloseIcon}
      onClick={closeToast}
      aria-label={formatMessage({
        id: GlobalTranslation.labelClose,
      })}
    />
  );

  const showMasterPasswordLockToast = useCallback(() => {
    if (!currentAccountPrivateKey) {
      toast(
        <MasterPasswordLockScreen
          decryptCurrentAccountPrivateKey={
            decryptCurrentAccountPrivateKeyWithPassword
          }
        />,
        {
          hideProgressBar: true,
          autoClose: false,
          closeOnClick: false,
          closeButton: <CloseButton />,
          draggable: false,
          position: ToastPosition.BOTTOM_RIGHT,
          toastId: MASTER_PASSWORD_LOCK_TOAST_ID,
        },
      );
    }
  }, [currentAccountPrivateKey, decryptCurrentAccountPrivateKeyWithPassword]); // eslint-disable-line

  return (
    <EncryptionContext.Provider
      value={
        {
          accountPublicKeysMap,
          accountPrivateKeysMap,
          workspaceVaultsMap,
          workspaceAppVaultsMap,
          workspaceAppGroupVaultsMap,
          workspaceAppDesktopVaultsMap,
          workspaceVaultLoginsMap,
          workspaceVaultsLoading:
            !workspaceVaultsCache[workspace.id] && workspaceVaultsLoading,
          decryptCurrentAccountPrivateKey:
            decryptCurrentAccountPrivateKeyWithPassword,
          showMasterPasswordLockToast,
          refetchWorkspaceVaults,
          setWorkspaceVaultsCache,
        } as EncryptionContextType
      }>
      {children}

      {reencryptVaultAccesses && (
        <ReencryptVaultAccessesModal
          reencryptVaultAccessesRequestId={
            reencryptVaultAccessesRequestId as string
          }
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                reencryptVaultAccesses: undefined,
              }),
            })
          }
        />
      )}
    </EncryptionContext.Provider>
  );
};
