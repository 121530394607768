import { useMutation } from '@apollo/client';
import {
  UPDATE_WEB_PUSH_ACCOUNT_IDENTITY,
  UpdateWebPushAccountIdentityResponse,
  UpdateWebPushAccountIdentityVariables,
} from './WebPush.mutations';

export const useUpdateWebPushAccountIdentityMutation = () =>
  useMutation<
    UpdateWebPushAccountIdentityResponse,
    UpdateWebPushAccountIdentityVariables
  >(UPDATE_WEB_PUSH_ACCOUNT_IDENTITY);
