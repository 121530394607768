import styled from 'styled-components';

export const StyledCategories = styled.div`
  padding: 12px 0;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex-basis: 100%;
    overflow-y: auto;
  `}
`;

export const CategoriesTitle = styled.h3`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 18px;
  padding-left: 16px;
`;

export const CategoriesList = styled.ul`
  margin-top: 4px;
  padding: 4px 16px;
`;

export const Category = styled.li`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.875rem;
  line-height: 18px;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  cursor: pointer;

  &.active {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    color: ${p => p.theme.colors.Primary};
  }

  & ~ & {
    margin-top: 6px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
