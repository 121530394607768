import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  FILTER_DESKTOP_APPS,
  FilterDesktopAppsResponse,
  FilterDesktopAppsVariables,
  GET_CLIPBOARD,
  GET_RECENT_APPS,
  GetClipboardResponse,
  GetClipboardVariables,
  GetRecentAppsResponse,
  GetRecentAppsVariables,
} from './Desktop.queries';
import {
  CurrentDesktopContentContext,
  DesktopContext,
} from './Desktop.context';
import {
  COPY_APPS_TO_DESKTOP,
  COPY_LINKS_TO_DESKTOP,
  CopyAppsToDesktopResponse,
  CopyAppsToDesktopVariables,
  CopyLinksToDesktopResponse,
  CopyLinksToDesktopVariables,
  CREATE_CLIPBOARD,
  CREATE_DESKTOP,
  CreateClipboardResponse,
  CreateClipboardVariables,
  CreateDesktopResponse,
  CreateDesktopVariables,
  DELETE_SECTION,
  DeleteSectionResponse,
  DeleteSectionVariables,
  EDIT_HIDING_CHAT,
  EditHidingChatResponse,
  EditHidingChatVariables,
  LEAVE_DESKTOP,
  LeaveDesktopResponse,
  LeaveDesktopVariables,
  MOVE_APP,
  MoveAppResponse,
  MoveAppVariables,
  UPDATE_DESKTOP_SORT,
  UpdateDesktopSortResponse,
  UpdateDesktopSortVariables,
} from './Desktop.mutations';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../Workspace/Workspace.hooks';
import {
  AppLoginMethod,
  DESKTOP_ID_PATHNAME,
  DesktopType,
  NO_PERMISSIONS,
} from './Desktop.constants';
import { useAccountsContext } from '../Account';
import { WorkspaceAccountGroupIdentity } from '../User/User.constants';
import { useDesktopAppVaultLogin } from '../Encryption/Encryption.hooks';
import {
  getDesktopIdFromUrl,
  getDesktopIri,
  getDesktopType,
  useCurrentTypeOfPage,
} from './Desktop.utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../shared/hooks';
import { SegmentType, useSetLastUsedSegmentPath } from '../Segment';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { isCurrentAccountGuest } from '../User/User.utils';
import { CHAT_ROOT_PATHNAME } from '../Chat/Chat.constants';
import { usePreviewSegment } from '../PreviewSegment/PreviewSegment.hooks';
import { getShortId } from '../../shared/utils/id';
import type {
  DesktopApiType,
  DesktopAppEdgeApiType,
  DesktopPermissionsContextApiType,
} from './data/Desktop/types/Desktop.types';
import { PermissionContext } from './data/Desktop/types/Desktop.types';

export const useFilterDesktopAppsQuery = (
  options?: QueryHookOptions<
    FilterDesktopAppsResponse,
    FilterDesktopAppsVariables
  >,
) =>
  useQuery<FilterDesktopAppsResponse, FilterDesktopAppsVariables>(
    FILTER_DESKTOP_APPS,
    options,
  );

export const useCurrentDesktop = () => useContext(DesktopContext);

export const useCurrentDesktopContent = () =>
  useContext(CurrentDesktopContentContext);

export const useCreateDesktopMutation = () =>
  useMutation<CreateDesktopResponse, CreateDesktopVariables>(CREATE_DESKTOP);

export const useEditHidingChatMutation = () =>
  useMutation<EditHidingChatResponse, EditHidingChatVariables>(
    EDIT_HIDING_CHAT,
  );

export const useLeaveDesktopMutation = () =>
  useMutation<LeaveDesktopResponse, LeaveDesktopVariables>(LEAVE_DESKTOP);

export const useGetRecentAppsQuery = (
  options?: QueryHookOptions<GetRecentAppsResponse, GetRecentAppsVariables>,
) =>
  useQuery<GetRecentAppsResponse, GetRecentAppsVariables>(
    GET_RECENT_APPS,
    options,
  );

export const useUpdateDesktopSortMutation = () =>
  useMutation<UpdateDesktopSortResponse, UpdateDesktopSortVariables>(
    UPDATE_DESKTOP_SORT,
  );

export const useDeleteSectionMutation = () =>
  useMutation<DeleteSectionResponse, DeleteSectionVariables>(DELETE_SECTION);

export const useMoveAppMutation = () =>
  useMutation<MoveAppResponse, MoveAppVariables>(MOVE_APP);

export const useDesktopPermissions = (
  desktopId?: string,
  context?: PermissionContext,
): DesktopPermissionsContextApiType => {
  const {
    permissions: { desktops },
  } = useCurrentWorkspacePermissions();
  const desktopPermissions = useMemo(() => {
    return desktops?.find(desktop => desktop.desktop === desktopId);
  }, [desktops, desktopId]);

  return (
    (desktopPermissions &&
      desktopPermissions[context || PermissionContext.user]) ||
    NO_PERMISSIONS
  );
};

export const useCurrentDesktopPermissions = (
  context?: PermissionContext,
  desktopId?: string,
) => {
  const currentDesktop = useCurrentDesktop();

  return useDesktopPermissions(
    currentDesktop?.id || (desktopId ? getDesktopIri(desktopId) : undefined),
    context,
  );
};

export const useIsSharedExternally = () => {
  const { accountsWithAvailability } = useAccountsContext();

  return useCallback(
    ({ accountIds }: Pick<DesktopApiType, 'accountIds'>) =>
      accountIds.some(
        accountId =>
          accountsWithAvailability[accountId]?.workspaceCache?.[accountId]
            ?.groupIdentifier === WorkspaceAccountGroupIdentity.GUEST,
      ),
    [accountsWithAvailability],
  );
};

export const useCopyAppsToDesktopMutation = () =>
  useMutation<CopyAppsToDesktopResponse, CopyAppsToDesktopVariables>(
    COPY_APPS_TO_DESKTOP,
  );

export const useCopyLinksToDesktopMutation = () =>
  useMutation<CopyLinksToDesktopResponse, CopyLinksToDesktopVariables>(
    COPY_LINKS_TO_DESKTOP,
  );

export const useGetClipboardQuery = (
  options?: QueryHookOptions<GetClipboardResponse, GetClipboardVariables>,
) =>
  useQuery<GetClipboardResponse, GetClipboardVariables>(GET_CLIPBOARD, options);

export const useCreateClipboardMutation = () =>
  useMutation<CreateClipboardResponse, CreateClipboardVariables>(
    CREATE_CLIPBOARD,
  );

export const useAppLoginMethod = (
  desktopApp: DesktopAppEdgeApiType,
): AppLoginMethod => {
  const { login } = useDesktopAppVaultLogin(
    desktopApp?.app.id,
    desktopApp?.desktop?.id,
  );
  const {
    permissions: { canUnlockCredentials },
  } = useCurrentWorkspacePermissions();
  const desktop = useCurrentDesktop();
  const desktopType = desktop && getDesktopType(desktop);

  const hasPWMLoginAvailable = canUnlockCredentials && login;
  const hasSSOOverrideAvailable = desktopApp?.app.hasSsoOverride;

  // Internal desktops has SSO priority over PWM
  if (desktopType === DesktopType.INTERNAL) {
    if (hasSSOOverrideAvailable) {
      return AppLoginMethod.SSO;
    } else if (hasPWMLoginAvailable) {
      return AppLoginMethod.PWM;
    }
  } else {
    if (hasPWMLoginAvailable) {
      return AppLoginMethod.PWM;
    } else if (hasSSOOverrideAvailable) {
      return AppLoginMethod.SSO;
    }
  }

  return AppLoginMethod.DEFAULT;
};

export const useCurrentDesktopIdFromTheUrl = () => {
  const url = window.location.pathname;
  return useMemo(() => getDesktopIdFromUrl(url)?.id, [url]);
};

/**
 * Redirect desktops logic
 */
export const useDesktopSegmentRedirects = (
  desktops: DesktopApiType[],
  desktopsLoading: boolean,
  desktopId?: string,
  pathname?: string,
) => {
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const {
    permissions: { canAccessDesktopPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { account } = useCurrentWorkspaceAccount();
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.DESKTOPS);
  const { isPreviewMode } = usePreviewSegment();

  const {
    isNormalDesktop,
    isChatsPage,
    isConferencesPage,
    isFavoritesDesktop,
    isPasswordsPage,
  } = useCurrentTypeOfPage();

  const initialRedirectIsDone = useRef(false);

  useEffect(() => {
    if (initialRedirectIsDone.current) {
      initialRedirectIsDone.current = false;
    }
  }, [workspaceId]); // eslint-disable-line

  useEffect(() => {
    if (desktops.length && !desktopId) {
      initialRedirectIsDone.current = false;
    }
  }, [desktopId, desktops.length]); // eslint-disable-line

  useEffect(() => {
    if (permissionsLoaded && !canAccessDesktopPage) {
      navigate(
        {
          pathname: generatePath(CHAT_ROOT_PATHNAME, {
            workspaceId: getShortId(workspace.id),
          }),
          search: getQueryParamsFrom(queryParams),
        },
        { replace: true },
      );
      return;
    }

    const isUndefinedPage =
      !isNormalDesktop &&
      !isChatsPage &&
      !isConferencesPage &&
      !isFavoritesDesktop &&
      !isPreviewMode &&
      !isPasswordsPage;

    if (
      isUndefinedPage &&
      !desktopsLoading &&
      !desktops.length &&
      isCurrentAccountGuest(account, getShortId(workspace.id))
    ) {
      navigate(
        {
          pathname: generatePath(CHAT_ROOT_PATHNAME, {
            workspaceId: getShortId(workspace.id),
          }),
          search: getQueryParamsFrom(queryParams),
        },
        { replace: true },
      );
      return;
    }

    if (
      isUndefinedPage &&
      !desktopId &&
      !desktopsLoading &&
      desktops.length &&
      !initialRedirectIsDone.current
    ) {
      const firstDesktopId = desktops && getShortId(desktops[0]?.id);
      navigate(
        {
          pathname: generatePath(DESKTOP_ID_PATHNAME, {
            workspaceId: getShortId(workspace.id),
            ...(firstDesktopId
              ? {
                  desktopId: firstDesktopId,
                }
              : {}),
          }),
          search: getQueryParamsFrom(queryParams),
        },
        { replace: true },
      );
      initialRedirectIsDone.current = true;
    }
  }, [
    isPreviewMode,
    desktopId,
    desktopsLoading,
    desktops,
    navigate,
    queryParams,
    workspace,
    isNormalDesktop,
    isChatsPage,
    isConferencesPage,
    isPasswordsPage,
    isFavoritesDesktop,
    account,
    setLastSegmentPath,
    pathname,
    permissionsLoaded,
    canAccessDesktopPage,
  ]);
};

export const useGetIsChatHidden = () => {
  const currentDesktop = useCurrentDesktop();
  const { account } = useCurrentWorkspaceAccount();

  return useMemo(
    () =>
      !!currentDesktop?.accountsThatChatHidden?.find(
        accountId => account?.id === accountId,
      ),
    [account, currentDesktop],
  );
};
