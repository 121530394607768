import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useConfirm } from '../../../shared/components/Modal';
import { getLongId } from '../../../shared/utils/id';
import { database } from '../../Database';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { deleteFolderRepository } from '../Data/Repository/FolderRepository';
import { useFolderCacheUpdate } from '../Folder.hooks';
import { FolderApiType } from '../Folder.types';
import { FolderTranslation } from '../i18n';

export const useCaseDeleteFolder = () => {
  const { askConfirmation } = useConfirm();
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();

  const { removeFolderFromFilteredFoldersCache } = useFolderCacheUpdate();

  const deleteFolderUseCase = useCallback(
    async (folder: FolderApiType, searchString?: string) => {
      return await askConfirmation(
        formatMessage({
          id: FolderTranslation.linksViewRemoveFolderConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        return deleteFolderRepository(getLongId('folders', folder.id)).then(
          async ({ data }) => {
            if (!data) {
              return;
            }

            try {
              const folderLinks = await database.desktopLinks
                .where('folder')
                .equals(folder.id)
                .toArray();

              if (folderLinks?.length) {
                await database.desktopLinks.bulkDelete(
                  folderLinks.map(link => link.id),
                );
              }
            } catch (err) {
              captureException(err);
            }

            if (searchString) {
              removeFolderFromFilteredFoldersCache(
                {
                  desktop: folder.desktop.id,
                  desktop_workspace: workspace.id,
                  filterOnlyAccessible: true,
                  filterFreeTextSearch: searchString,
                },
                data.removeFolder.folder.id,
              );
            }
          },
        );
      });
    },
    [
      askConfirmation,
      formatMessage,
      removeFolderFromFilteredFoldersCache,
      workspace.id,
    ],
  );

  return {
    deleteFolderUseCase,
  };
};
