import React, { FC, useCallback, useMemo, useState } from 'react';
import { useCreateTagMutation, useTagsQuery } from '../../Link.hooks';
import { StyledManageTag } from './ManageTag.styles';
import {
  showToastGraphQLErrors,
  showToastInfoMessage,
} from '../../../../shared/components/Toast';
import { TagForm } from '../TagForm';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useQueryParams } from '../../../../shared/hooks';
import { extractNodes } from '../../../../shared/api/api.utils';
import { GraphQLError } from 'graphql';
import _orderBy from 'lodash/orderBy';
import { getTagId } from '../../Link.utils';
import { useCurrentDesktopPermissions } from '../../../Desktop/Desktop.hooks';
import { useCaseEditLink } from '../../UseCase/editLink';
import { LinkApiType } from '../../Link.types';
import { useCaseGetLink } from '../../UseCase/getLink';
import { useEffectOnce } from 'react-use';
import { ApolloError } from '@apollo/client';
import { Spinner } from '../../../../shared/components/Spinner';
import { LinkTranslation } from '../../i18n';
import { PermissionContext } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { useLinkTrackingEventOnInit } from '../../tracking/LinkTracking.hooks';

interface ManageTagProps {
  onDone: () => void;
  onCancel: () => void;
  onCreateTags?: (value: string[]) => void;
  selectedTags?: string[];
}

export const ManageTag: FC<ManageTagProps> = ({
  onDone,
  onCancel,
  onCreateTags,
  selectedTags,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { linkId } = useQueryParams();

  const [linkData, setLinkData] = useState<LinkApiType>();
  const { getLinkUseCase, linkLoading } = useCaseGetLink();

  useLinkTrackingEventOnInit('manage_link_tags');

  const getLinkData = async () => {
    const response = await getLinkUseCase(linkId as string);
    if (response?.data?.link) {
      setLinkData(response?.data?.link);
    }
  };

  useEffectOnce(() => {
    if (!linkId) {
      return;
    }
    getLinkData();
  });

  const { canEditLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    linkData?.desktopId,
  );

  const { data: tagsData, loading: tagsLoading } = useTagsQuery({
    variables: {
      workspace: workspace.id,
    },
    fetchPolicy: 'network-only',
  });

  const tagsOptions = useMemo(() => {
    return tagsData?.tags
      ? _orderBy(
          extractNodes(tagsData?.tags).map(tag => ({
            label: tag.name,
            value: tag.id,
          })),
          'label',
        )
      : [];
  }, [tagsData]);

  const selectedTagsOptions = useMemo(() => {
    return linkData?.tags?.edges?.length
      ? extractNodes(linkData?.tags).map(tag => ({
          label: tag.name,
          value: getTagId(tag.id),
        }))
      : (selectedTags?.map(tag =>
          tagsOptions.find(elem => elem.value === tag),
        ) as { value: string; label: string }[]) || [];
  }, [linkData, tagsOptions, selectedTags]);

  const [createTagMutation, { loading }] = useCreateTagMutation();
  const { editLinkUseCase } = useCaseEditLink();

  const editTags = useCallback(
    (tags: string[] = []) => {
      if (!linkData) {
        onDone();
        return;
      }

      editLinkUseCase(linkData, {
        tags,
      })
        .then(() => onDone())
        .catch((err: ApolloError) => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [editLinkUseCase, linkData, onDone],
  );

  const createTag = useCallback(
    (tag: string) => {
      return createTagMutation({
        variables: {
          input: {
            workspace: workspace.id,
            name: tag,
          },
        },
        update: (proxy, { errors }) => {
          if (errors) {
            showToastGraphQLErrors(errors as GraphQLError[]);
          }
        },
      })
        .then(({ data }) => {
          const createdTag = data?.createTag?.tag;
          if (createdTag) {
            const isTagAlreadyExists = tagsOptions.find(item => {
              return item.value === createdTag.id;
            });
            if (!isTagAlreadyExists) {
              return Promise.resolve(createdTag);
            } else {
              showToastInfoMessage(LinkTranslation.linkDuplicateTagMessage);
            }
          }
          return;
        })
        .catch((err: ApolloError) => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [createTagMutation, tagsOptions, workspace.id],
  );

  if (tagsLoading) {
    return (
      <StyledManageTag>
        <Spinner />
      </StyledManageTag>
    );
  }

  return (
    <StyledManageTag>
      <TagForm
        disabled={!canEditLink}
        createTag={createTag}
        onCancel={onCancel}
        tagsOptions={tagsOptions}
        selectedTags={selectedTagsOptions}
        editTags={editTags}
        onCreateTags={onCreateTags}
        disabledControls={linkLoading || tagsLoading || loading}
        isLinkDataLoading={linkLoading || (!linkData && !!linkId)}
      />
    </StyledManageTag>
  );
};
