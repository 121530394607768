import { gql } from '@apollo/client';
import { GoogleContactsApiType, MicrosoftContactsApiType } from './User.types';

export const GET_GOOGLE_AUTH_URL = gql`
  query GoogleAuthUrl($workspace: ID!) {
    googleAuthUrl(workspace: $workspace, scopeType: "contacts") {
      authUrl
    }
  }
`;

export interface GetGoogleAuthUrlVariables {
  workspace: string;
}

export interface GetGoogleAuthUrlResponse {
  googleAuthUrl: {
    authUrl: string;
  };
}

export const GET_ACCOUNT_GOOGLE_CONTACTS = gql`
  query AccountGoogleContacts($workspace: ID!) {
    accountGoogleContacts(workspace: $workspace) {
      id
      email
      displayName
      imageUrl
    }
  }
`;

export interface GetAccountGoogleContactsVariables {
  workspace: string;
}

export interface GetAccountGoogleContactsResponse {
  accountGoogleContacts: GoogleContactsApiType[];
}

export const GET_MICROSOFT_AUTH_URL = gql`
  query MicrosoftAuthUrl($workspace: ID!) {
    microsoftAuthUrl(workspace: $workspace, scopeType: "contacts") {
      authUrl
    }
  }
`;

export interface GetMicrosoftAuthUrlVariables {
  workspace: string;
}

export interface GetMicrosoftAuthUrlResponse {
  microsoftAuthUrl: {
    authUrl: string;
  };
}

export const GET_ACCOUNT_MICROSOFT_CONTACTS = gql`
  query AccountMicrosoftContacts($workspace: ID!) {
    accountMicrosoftContacts(workspace: $workspace) {
      id
      email
      displayName
      image
    }
  }
`;

export interface GetAccountMicrosoftContactsVariables {
  workspace: string;
}

export interface GetAccountMicrosoftContactsResponse {
  accountMicrosoftContacts: MicrosoftContactsApiType[];
}
