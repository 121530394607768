import type {
  AccountApiType,
  UnreadChatMessagesConversationType,
} from '../../User/User.types';
import type {
  ChatConversationApiType,
  ChatMessageApiType,
  MessageType,
} from '../../Chat/Chat.types';
import type { WorkspaceApiType } from '../../Workspace/Workspace.types';
import type {
  ConferenceApiType,
  ScheduledChatConferenceApiType,
} from '../../Conference/Conference.types';
import { RepeatingScheduledChatConferenceRepeatPattern } from '../../Conference/Conference.mutations';
import type { LinkDataFragmentType } from '../../Chat/cache/Chat.cache';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';

export enum ChatEventRequests {
  New = 'new',
  Update = 'update',
  Remove = 'remove',
  PlaySound = 'App\\Chat\\Background\\Message\\Event\\PlaySoundNewChatMessageEvent',
}

export enum ChatEventTypes {
  ChatMessage = 'ChatMessage',
  ChatConversation = 'ChatConversation',
  ChatConference = 'ChatConference',
  ScheduledChatConference = 'ScheduleChatConference',
  CustomScheduledChatConferenceRemove = 'App\\ScheduleChatConference\\Background\\Message\\Event\\ScheduleChatConferenceDeletedEvent',
  CustomScheduledRepeatingChatConferenceRemove = 'App\\ScheduleChatConference\\Background\\Message\\Event\\RepeatingScheduleChatConferenceDeletedEvent',
  RepeatingScheduleChatConference = 'RepeatingScheduleChatConference',
  ChatLink = 'ChatLink',
}

export interface BaseChatEvent {
  '@iri': string;
  '@request': ChatEventRequests;
  '@type': ChatEventTypes;
  '@timestamp': number;
}

export interface NewChatMessageEvent
  extends BaseChatEvent,
    Pick<
      ChatMessageApiType,
      | 'accountIri'
      | 'createdAt'
      | 'microtimeAt'
      | 'modified'
      | 'message'
      | 'context'
      | 'reactions'
      | 'threadMessagesCount'
      | 'parentChatMessageIri'
      | 'seenBy'
      | 'threadMessagesAccounts'
      | 'lastMessageInThreadAt'
    > {
  '@request': ChatEventRequests.New;
  '@type': ChatEventTypes.ChatMessage;
  type: MessageType;
  conversationIri: string;
  id: string;
  optimisticId: string;
  workspaceIri: string;
}

export interface UpdateChatMessageEvent
  extends Omit<NewChatMessageEvent, '@request'> {
  '@request': ChatEventRequests.Update;
}

export interface RemoveChatMessageEvent
  extends Omit<NewChatMessageEvent, '@request'> {
  '@request': ChatEventRequests.Remove;
}

export interface PlaySoundChatMessageEvent
  extends Pick<NewChatMessageEvent, '@request' | '@timestamp'> {
  '@type': ChatEventRequests.PlaySound;
  onCall: boolean;
  createdAt: string;
}

export interface NewChatConversationEvent
  extends BaseChatEvent,
    Omit<ChatConversationApiType, '_id'> {
  workspace: WorkspaceApiType;
  '@type': ChatEventTypes.ChatConversation;
  '@request': ChatEventRequests.New;
  desktop?: DesktopApiType;
}

export interface UpdateChatConversationEvent
  extends BaseChatEvent,
    Omit<ChatConversationApiType, '_id'> {
  workspace: WorkspaceApiType;
  '@type': ChatEventTypes.ChatConversation;
  '@request': ChatEventRequests.Update;
  unreadCountersMap: Record<string, UnreadChatMessagesConversationType> | [];
}

export interface RemoveChatConversationEvent extends BaseChatEvent {
  '@type': ChatEventTypes.ChatConversation;
  '@request': ChatEventRequests.Remove;
  '@iri': string;
  workspace: Pick<WorkspaceApiType, 'id'>;
}

export interface NewChatConferenceEvent
  extends BaseChatEvent,
    ConferenceApiType {
  workspace: WorkspaceApiType;
  createdBy: AccountApiType;
  '@type': ChatEventTypes.ChatConference;
  '@request': ChatEventRequests.New | ChatEventRequests.Update;
}

export interface NewScheduledChatConferenceEvent
  extends BaseChatEvent,
    Omit<
      ScheduledChatConferenceApiType,
      'chatConference' | 'scheduleChatConferenceMembers'
    > {
  chatConference: ConferenceApiType;
  scheduleChatConferenceMembers: Array<{
    _id: string;
    account?: AccountApiType;
    email?: string;
    icalendarUrl: string;
  }>;
  '@type': ChatEventTypes.ScheduledChatConference;
  '@request': ChatEventRequests.New;
}

export interface UpdateScheduledChatConferenceEvent
  extends Omit<NewScheduledChatConferenceEvent, '@request'> {
  '@request': ChatEventRequests.Update;
}

export interface RemoveScheduledChatConferenceEvent
  extends Omit<BaseChatEvent, '@iri'> {
  '@class': string;
  '@iri': null;
  '@request': ChatEventRequests.Remove;
  '@type': ChatEventTypes.CustomScheduledChatConferenceRemove;
  workspaceId: string;
  id: string;
}

export interface NewScheduledRepeatingChatConferenceEvent
  extends BaseChatEvent,
    ScheduledChatConferenceApiType {
  repeatPattern: RepeatingScheduledChatConferenceRepeatPattern;
  scheduleChatConferences: ScheduledChatConferenceApiType[];
  '@type': ChatEventTypes.RepeatingScheduleChatConference;
  '@request': ChatEventRequests.New;
}

export interface UpdateScheduledRepeatingChatConferenceEvent
  extends Omit<NewScheduledRepeatingChatConferenceEvent, '@request'> {
  '@request': ChatEventRequests.Update;
}

export interface RemoveScheduledRepeatingChatConferenceEvent
  extends BaseChatEvent {
  '@request': ChatEventRequests.Remove;
  '@type': ChatEventTypes.CustomScheduledRepeatingChatConferenceRemove;
  scheduleChatConferenceIds: string[];
  id: string;
  workspaceId: string;
}

export interface ChatMessageLinkUpdateEvent extends BaseChatEvent {
  '@class': string;
  '@iri': string;
  '@request': ChatEventRequests.Update;
  '@type': ChatEventTypes.ChatLink;
  id: string;
  data: LinkDataFragmentType;
}

export type ChatEvent =
  | NewChatMessageEvent
  | UpdateChatMessageEvent
  | RemoveChatMessageEvent
  | PlaySoundChatMessageEvent
  | NewChatConversationEvent
  | UpdateChatConversationEvent
  | RemoveChatConversationEvent
  | NewChatConferenceEvent
  | NewScheduledChatConferenceEvent
  | UpdateScheduledChatConferenceEvent
  | RemoveScheduledChatConferenceEvent
  | NewScheduledRepeatingChatConferenceEvent
  | RemoveScheduledRepeatingChatConferenceEvent
  | UpdateScheduledRepeatingChatConferenceEvent
  | ChatMessageLinkUpdateEvent;
