import styled from 'styled-components';
import {
  Link,
  LinkMode,
  LinkSize,
} from '../../../../../shared/components/Link';
import { appEnv } from '../../../../../appEnv';

export const StyledInstallExtensionBanner = styled.div`
  height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InstallExtensionBannerLink = styled(Link).attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
  href: appEnv.IMPORT_EXTENSION_LINK,
  mode: LinkMode.primary,
  size: LinkSize.md,
})`
  margin-top: 1rem;
`;
