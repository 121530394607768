import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useCreateWorkspaceDomainMutation } from '../../Domain.hooks';
import { DomainFormValues } from '../../Domain.types';
import { DomainForm } from '../Domain.form';

const StyledCreateDomainForm = styled.div`
  padding: 0.75rem 1.5rem 1.5rem;
`;

interface CreateDomainFormProps {
  onRequestClose: () => void;
  onDone: () => void;
}

export const CreateDomainForm: FC<CreateDomainFormProps> = ({
  onDone,
  onRequestClose,
}) => {
  const {
    workspace: { id: workspace },
  } = useCurrentWorkspace();

  const [createWorkspaceDomainMutation] = useCreateWorkspaceDomainMutation();

  const handleSubmit = useCallback(
    (formValues: DomainFormValues) =>
      createWorkspaceDomainMutation({
        variables: {
          input: {
            ...formValues,
            workspace,
          },
        },
      })
        .then(() => {
          onDone();
          onRequestClose();
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [createWorkspaceDomainMutation, workspace, onDone, onRequestClose],
  );

  return (
    <StyledCreateDomainForm>
      <DomainForm onCancel={onRequestClose} onSubmit={handleSubmit} />
    </StyledCreateDomainForm>
  );
};
