import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import ChromeImportImage from '../../../../shared/assets/images/import-illustration.png';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import {
  ExtensionIconsContainer,
  ExtensionModalContent,
  ExtensionWarning,
  InstallExtensionLink,
} from './InfoModal.styled';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ChromeExtensionTranslation } from '../../i18n';
import { appEnv } from '../../../../appEnv';
import { Spinner } from '../../../../shared/components/Spinner';
import { checkExtensionInstalled } from '../../../../shared/utils/extension.utils';

interface ExtensionInfoModalProps extends ModalProps {
  visible: boolean;
  onRequestClose: () => void;
}

const CHECK_EXTENSION_INTERVAL = 2000;

export const ExtensionInfoModal: FC<ExtensionInfoModalProps> = ({
  visible,
  onRequestClose,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const intl = useIntl();
  const [extensionChecked, setExtensionChecked] = useState(false);
  const [extensionInstalled, setExtensionInstalled] = useState(false);

  const checkInterval = useRef<any>(null);

  useEffect(() => {
    checkExtensionInstalled().then(installed => {
      setExtensionChecked(true);
      setExtensionInstalled(installed);

      if (!installed) {
        checkInterval.current = setInterval(() => {
          checkExtensionInstalled().then(installed => {
            setExtensionChecked(true);
            setExtensionInstalled(installed);
          });
        }, CHECK_EXTENSION_INTERVAL);
      }
    });
    return () => {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (extensionInstalled && checkInterval.current) {
      clearInterval(checkInterval.current);
    }
  }, [extensionInstalled]);

  return (
    <Modal visible={visible} width={540} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={ChromeExtensionTranslation.import} />
      </ModalHeader>
      <ExtensionWarning>
        <ImportantMessage type={ImportantMessageType.WARNING} withIcon>
          <FormattedHTMLMessage
            id={ChromeExtensionTranslation.importantMessage}
            values={{ faqUrl: appEnv.SUPPORT_IMPORT_BOOKMARKS_URL }}
          />
        </ImportantMessage>
      </ExtensionWarning>
      <ExtensionIconsContainer>
        <img
          alt={intl.formatMessage({ id: ChromeExtensionTranslation.import })}
          src={ChromeImportImage}
        />
      </ExtensionIconsContainer>
      <ExtensionModalContent>
        <FormattedMessage id={ChromeExtensionTranslation.importInfo} />

        {extensionChecked ? (
          <div>
            {extensionInstalled ? (
              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={() => {
                  navigate({
                    search: getQueryParamsFrom({
                      ...queryParams,
                      extensionInfoModal: undefined,
                      importModal: true,
                      importStep: queryParams.nextStep,
                    }),
                  });
                }}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importFromChrome}
                />
              </Button>
            ) : (
              <FormattedMessage
                tagName={InstallExtensionLink}
                id={ChromeExtensionTranslation.importInfoInstallExtensionLink}
              />
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </ExtensionModalContent>
    </Modal>
  );
};
