import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  useCreateVaultWithVaultAccess,
  useCreateWithoutAppVaultItemWithAccess,
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceAccountPublicKey,
} from '../../Encryption.hooks';
import { getAccountKeyset } from '../../Encryption.utils';
import { addProtocol } from '../../../../shared/utils/url.utils';
import { MasterPasswordLockScreen } from '../../MasterPasswordLockScreen';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useWorkspaceOwnerAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  VaultAccessInputType,
  VaultItemFormValues,
} from '../../Encryption.types';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { EncryptionTranslation } from '../../i18n';
import { AccountApiType } from '../../../User/User.types';
import _uniqBy from 'lodash/uniqBy';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { FormattedMessage } from 'react-intl';
import { displayInstallPluginToast } from '../../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { VaultItemForm } from '../VaultItemForm';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import {
  encryptVaultAccessKey,
  encryptVaultItem,
  generateVaultAccessKey,
  importPublicKey,
} from '../../Encryption.crypto.utils';
import {
  CreateVaultItemWithAccessResponse,
  CreateWithoutAppVaultItemWithAccessResponse,
} from '../../Encryption.mutations';
import { VaultItemGroupSelect } from './VaultItemGroupSelect';
import { StyledCreateVaultItem } from './CreateVaultItem.styled';
import { useQueryParams } from '../../../../shared/hooks';
import { captureException } from '../../../ErrorInterceptor';
import { getDesktopIri } from '../../../Desktop/Desktop.utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { getShortId } from '../../../../shared/utils/id';
import { PASSWORDS_VAULT_PATHNAME } from '../../../Desktop/Desktop.constants';

interface EditVaultItemProps {
  appId?: string;
  displayGroupSelect?: boolean;
  displayUrlField?: boolean;
  displayImageField?: boolean;
  onDone?: () => void;
}

export const CreateVaultItem: FC<EditVaultItemProps> = ({
  appId,
  displayGroupSelect,
  displayUrlField,
  displayImageField,
  onDone,
}) => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const { account } = useCurrentWorkspaceAccount();
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { publicKey } = useCurrentWorkspaceAccountPublicKey();
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;
  const { selectVaultForDesktopIdOverride } = useQueryParams();
  const desktop = useCurrentDesktop();
  const currentDesktopId = selectVaultForDesktopIdOverride
    ? getDesktopIri(selectVaultForDesktopIdOverride as string)
    : desktop?.id;
  const [groupAppId, setGroupAppId] = useState<string>();
  const vaultAppId = groupAppId || appId;

  const [processing, setProcessing] = useState(false);

  const { workspaceOwnerAccount, workspaceOwnerAccountLoading } =
    useWorkspaceOwnerAccount(workspace?.id);
  const workspaceOwnerKeyset = useMemo(() => {
    return getAccountKeyset(workspaceOwnerAccount as AccountApiType);
  }, [workspaceOwnerAccount]);
  const displayOwnerNoSharingWarning =
    workspaceOwnerAccount?.id === account?.id;

  const getWorkspaceOwnerVaultAccess = useCallback(
    (
      vaultAccessKey: CryptoKey,
    ): Promise<Omit<VaultAccessInputType, 'vault' | 'workspace'>> => {
      if (!workspaceOwnerKeyset) {
        return Promise.reject(new Error('No workspaceOwnerKeyset'));
      }
      return importPublicKey(workspaceOwnerKeyset.pubKey)
        .then(ownerPublicKey => {
          return encryptVaultAccessKey(ownerPublicKey, vaultAccessKey);
        })
        .then(vaultKeyEncryptedForOwner => {
          return {
            keyset: workspaceOwnerKeyset.id,
            vaultKeyEncrypted: vaultKeyEncryptedForOwner,
            isImplicitlyShared: true,
          };
        });
    },
    [workspaceOwnerKeyset],
  );

  const getCurrentUserVaultAccess = useCallback(
    (
      vaultAccessKey: CryptoKey,
    ): Promise<Omit<VaultAccessInputType, 'vault' | 'workspace'>> => {
      if (!keysetId) {
        return Promise.reject(new Error('No keysetId'));
      }
      return encryptVaultAccessKey(publicKey, vaultAccessKey).then(
        vaultKeyEncryptedForCurrentUser => {
          return {
            keyset: keysetId,
            vaultKeyEncrypted: vaultKeyEncryptedForCurrentUser,
          };
        },
      );
    },
    [keysetId, publicKey],
  );

  const [createVaultWithVaultAccess] = useCreateVaultWithVaultAccess();
  const [createWithoutAppVaultItemWithAccess] =
    useCreateWithoutAppVaultItemWithAccess();
  const createVaultItem = useCallback(
    (
      encryptedLogin: string,
      encryptedPassword: string,
      encryptedVaultAccesses: Pick<
        VaultAccessInputType,
        'keyset' | 'vaultKeyEncrypted' | 'isImplicitlyShared'
      >[],
      url?: string,
      image?: string,
    ) => {
      return (
        vaultAppId
          ? createVaultWithVaultAccess({
              variables: {
                input: {
                  login: encryptedLogin,
                  password: encryptedPassword,
                  app: vaultAppId,
                  desktop: currentDesktopId as string,
                  workspace: workspaceId,
                  vaultAccesses: encryptedVaultAccesses.map(elem => ({
                    ...elem,
                    workspace: workspaceId,
                  })),
                },
              },
            })
          : createWithoutAppVaultItemWithAccess({
              variables: {
                input: {
                  url: url,
                  login: encryptedLogin,
                  password: encryptedPassword,
                  workspace: workspaceId,
                  ...{
                    ...(image && { image }),
                  },
                  vaultAccesses: encryptedVaultAccesses.map(elem => ({
                    ...elem,
                    workspace: workspaceId,
                  })),
                },
              },
            })
      )
        .then(
          response => {
            const createVaultItemWithAccessResponse = (
              response?.data as CreateVaultItemWithAccessResponse
            ).createVaultItemWithVaultAccess;

            const createWithoutAppVaultItemWithAccessResponse = (
              response?.data as CreateWithoutAppVaultItemWithAccessResponse
            ).createWithoutAppVaultItemWithVaultAccess;

            if (
              (createVaultItemWithAccessResponse?.vaultAccess ||
                createWithoutAppVaultItemWithAccessResponse?.vaultAccess) &&
              !appId
            ) {
              const vaultId =
                createVaultItemWithAccessResponse?.vaultAccess.vault?.id ||
                createWithoutAppVaultItemWithAccessResponse?.vaultAccess.vault
                  ?.id;

              return vaultId ? vaultId : null;
            }
          },
          e => {
            showToastGraphQLErrors(e.graphQLErrors);
          },
        )
        .finally(() => setProcessing(false));
    },
    [
      vaultAppId,
      createVaultWithVaultAccess,
      currentDesktopId,
      workspaceId,
      createWithoutAppVaultItemWithAccess,
      appId,
    ],
  );

  const handleSubmit = useCallback(
    ({ login, password, url, image }: VaultItemFormValues) => {
      setProcessing(true);
      return generateVaultAccessKey()
        .then(
          newVaultAccessKey => {
            return Promise.all([
              encryptVaultItem(newVaultAccessKey, login),
              encryptVaultItem(newVaultAccessKey, password),
              getCurrentUserVaultAccess(newVaultAccessKey),
              getWorkspaceOwnerVaultAccess(newVaultAccessKey),
            ]);
          },
          e => {
            captureException(e);
            setProcessing(false);
            showToastErrorMessage(
              EncryptionTranslation.editVaultFormEncryptionErrorMessage,
            );
          },
        )
        .then(encryptedData => {
          if (encryptedData) {
            const [
              encryptedLogin,
              encryptedPassword,
              currentUserVaultAccessEncrypted,
              workspaceOwnerVaultAccessEncrypted,
            ] = encryptedData;
            // remove duplicates (for case when current user is owner)
            const uniqueEncryptedVaultAccesses = _uniqBy(
              [
                currentUserVaultAccessEncrypted,
                workspaceOwnerVaultAccessEncrypted,
              ],
              'keyset',
            );

            return createVaultItem(
              encryptedLogin,
              encryptedPassword,
              uniqueEncryptedVaultAccesses,
              addProtocol(url),
              image,
            ).then(vaultId => {
              onDone?.();
              if (vaultId) {
                navigate({
                  pathname: generatePath(PASSWORDS_VAULT_PATHNAME, {
                    workspaceId: getShortId(workspaceId),
                    vaultId: getShortId(vaultId),
                  }),
                });
              }
              showToastSuccessMessage(
                EncryptionTranslation.editVaultFormSuccessMessage,
              );
              displayInstallPluginToast();
            });
          }
        });
    },
    [
      getCurrentUserVaultAccess,
      getWorkspaceOwnerVaultAccess,
      createVaultItem,
      navigate,
      workspaceId,
      onDone,
    ],
  );

  if (workspaceOwnerAccountLoading) {
    return <Spinner containerHeight={120} />;
  }

  if (!workspaceOwnerKeyset) {
    return (
      <ImportantMessage type={ImportantMessageType.WARNING}>
        <FormattedMessage
          id={EncryptionTranslation.editVaultOwnerHasNoKeysetErrorMessage}
        />
      </ImportantMessage>
    );
  }

  if (!privateKey) {
    return (
      <MasterPasswordLockScreen
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  return (
    <StyledCreateVaultItem data-testid="create-vault-item-panel">
      {displayGroupSelect && (
        <VaultItemGroupSelect onChange={appId => setGroupAppId(appId)} />
      )}
      <VaultItemForm
        displayOwnerNoSharingWarning={displayOwnerNoSharingWarning}
        displayUrlField={displayUrlField}
        displayImageField={displayImageField}
        onSubmit={handleSubmit}
        processing={processing}
        displayGroupSelect={displayGroupSelect}
        groupAppId={groupAppId}
        appId={appId}
      />
    </StyledCreateVaultItem>
  );
};
