import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { ChatMercureContext } from '../../Mercure/Chat/ChatMercure.context';
import {
  ChatEvent,
  ChatEventRequests,
  ChatEventTypes,
} from '../../Mercure/Chat/ChatMercure.types';
import { IncomingCallToast } from './IncomingCallToast';
import { useCurrentWorkspaceAccount } from '../../Workspace/Workspace.hooks';
import { useAccountAvailableForCall } from '../Conference.hooks';
import callSoundMp3 from './IncomingCallToast/call.mp3';
import { isEventFresh } from '../../Mercure/General/GeneralMercure.utils';
import { isNativeWrapperAvailable } from '../../NativeWrapper';
import { MercureContext } from '../../Mercure/General/GeneralMercure.context';
import {
  StreamEvent,
  StreamEventActionRequest,
  StreamEventType,
} from '../../Mercure/General';

export const CallToast: FC = () => {
  const chatMercureControls = useContext(ChatMercureContext);

  const callsMapRef = useRef<Record<string, boolean>>({});

  const { account } = useCurrentWorkspaceAccount();
  const availableForCall = useAccountAvailableForCall(account?.id);

  const [callSoundUrl, setCallSoundUrl] = useState<string>(callSoundMp3);

  useEffect(() => {
    // preloading the sound file
    fetch(callSoundMp3)
      .then(response => response.blob())
      .then(blob => {
        setCallSoundUrl(URL.createObjectURL(blob));
      });
  }, []);

  const handleCallEvent = useCallback(
    (event: ChatEvent) => {
      if (account && event['@type'] === ChatEventTypes.ChatConference) {
        const { '@iri': toastId, statistics, createdBy, callType } = event;

        const caller = createdBy.id !== account.id ? createdBy : null;
        if (
          availableForCall &&
          typeof callsMapRef.current[toastId] === 'undefined' &&
          isEventFresh(event) &&
          event['@request'] === ChatEventRequests.New &&
          caller
        ) {
          callsMapRef.current[toastId] = true;
          toast(
            <IncomingCallToast
              toastId={toastId}
              caller={caller}
              callType={callType}
              conferenceId={toastId}
              callsMapRef={callsMapRef}
              callSoundUrl={callSoundUrl}
            />,
            {
              hideProgressBar: true,
              autoClose: false,
              closeOnClick: false,
              closeButton: false,
              draggable: false,
              toastId,
            },
          );
        }
        if (statistics.has_ended && callsMapRef.current[toastId]) {
          toast.dismiss(toastId);
          callsMapRef.current[toastId] = false;
        }
      }
    },
    [account, availableForCall, callSoundUrl],
  );

  useEffect(() => {
    if (isNativeWrapperAvailable()) {
      // Native wrapper will handle incoming calls
      return;
    }
    chatMercureControls.addListener(handleCallEvent);
    return () => {
      chatMercureControls.removeListener(handleCallEvent);
    };
  }, [chatMercureControls, handleCallEvent]);

  const mercureControls = useContext(MercureContext);

  const handleInboundCallStatusEvent = useCallback((event: StreamEvent) => {
    if (
      event['@type'] === StreamEventType.ACTION &&
      (event['@request'] === StreamEventActionRequest.ACCEPT_INBOUND_CALL ||
        event['@request'] === StreamEventActionRequest.REJECT_INBOUND_CALL)
    ) {
      const toastId = event['@payload'].id;
      if (callsMapRef.current[toastId]) {
        toast.dismiss(toastId);
        callsMapRef.current[toastId] = false;
      }
    }
  }, []);

  useEffect(() => {
    mercureControls.addListener(handleInboundCallStatusEvent);
    return () => {
      mercureControls.removeListener(handleInboundCallStatusEvent);
    };
  }, [handleInboundCallStatusEvent, mercureControls]);

  return null;
};
