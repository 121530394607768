import { useCallback } from 'react';
import { createLinkRepository } from '../Data/Repository/LinkRepository';
import { CreateLinkInputVariables } from '../Data/Operations/Mutations/CreateLink.mutation';
import { getLongId } from '../../../shared/utils/id';
import { database } from '../../Database';

export const useCaseCreateLink = () => {
  const createLinkUseCase = useCallback(
    async (workspaceId: string, linkData: CreateLinkInputVariables) => {
      const response = await createLinkRepository(
        getLongId('workspaces', workspaceId),
        linkData,
      );

      // Add the link to linkCount for folder link was created in
      if (linkData.folder) {
        database.desktopFolders.get(linkData.folder).then(folder => {
          if (!folder) {
            return;
          }
          const currentLinksCount = folder.sourceData.linksCount;
          database.desktopFolders.update(folder.id, {
            'sourceData.linksCount': currentLinksCount + 1,
          });
        });
      }

      return response;
    },
    [],
  );

  return {
    createLinkUseCase,
  };
};
