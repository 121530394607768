import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AppStoreAppApiType,
  AppStoreAppFormValues,
} from '../../AppStore.types';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { Field, Form, Formik } from 'formik';
import { FormInput } from '../../../../shared/components/Input';
import {
  AppPreviewImage,
  AppPreviewImageBox,
  AppPreviewImageDescription,
  AppPreviewImageOuter,
  CheckBoxLabel,
  CustomAppField,
  CustomAppFormBody,
  CustomAppFormControls,
  CustomAppFormFieldsPart,
  CustomAppFormImage,
  CustomAppFormUploadPart,
  ImageUploadButton,
  ImageUploadError,
  ImageUploadLabel,
} from './CustomAppForm.styled';
import { AppStoreTranslation } from '../../i18n';
import {
  useAppStoreCategories,
  useCreatePreviewImageAssetMutation,
} from '../../AppStore.hooks';
import { extractNodes } from '../../../../shared/api/api.utils';
import { FormSelect } from '../../../../shared/components/Select/FormSelect';
import { ImageUpload, ImageUploadBody } from '../../../Image/ImageUpload';
import UploadImagePlaceholder from './../../../../shared/assets/images/image-upload.svg';
import { Checkbox } from '../../../../shared/components/Checkbox';
import { setDefaultImage } from '../../../../shared/utils/image.utils';
import { useDebounce } from 'use-debounce';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  useCurrentAccountKeyset,
  useEncryptionContext,
} from '../../../Encryption/Encryption.hooks';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';

const CustomAppFormSchema: Schema<AppStoreAppFormValues> = Yup.object().shape({
  name: Yup.string().required(AppStoreTranslation.customAppFormNameRequired),
  uri: Yup.string()
    .url(AppStoreTranslation.customAppFormUrlIsNotValid)
    .required(AppStoreTranslation.customAppFormUrlRequired),
  category: Yup.string().required(
    AppStoreTranslation.customAppFormCategoryRequired,
  ),
  logo: Yup.string().required(),
});

interface CustomAppFormProps {
  app?: AppStoreAppApiType;
  onManagePasswordsClick?: () => void;
  onSubmit: (values: AppStoreAppFormValues) => Promise<unknown>;
}

const APP_URL_DEBOUNCE_TIME = 500;
const PREVIEW_ICON_SIZE = 120;

export const CustomAppForm: FC<CustomAppFormProps> = ({
  app,
  onManagePasswordsClick,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const [appUrl, setAppUrl] = useState<string>('https://');
  const [canFetchImageByUrl, setCanFetchImageByUrl] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(app?.logo?.contentUrl || '');
  const {
    permissions: { canUnlockCredentials },
  } = useCurrentWorkspacePermissions();
  const { refetchWorkspaceVaults } = useEncryptionContext();
  const desktop = useCurrentDesktop();

  const { keyset } = useCurrentAccountKeyset();

  const [debouncedAppUrlValue] = useDebounce(appUrl, APP_URL_DEBOUNCE_TIME);

  const initialValues: AppStoreAppFormValues = {
    name: app?.name || '',
    uri: app?.uri || debouncedAppUrlValue,
    category: app?.category?.id || '',
    logo: app?.logo?.id || '',
  };

  const formDisabled = Boolean(app && !app.custom);

  const { data: categoriesData } = useAppStoreCategories();
  const categories = useMemo(
    () => extractNodes(categoriesData?.categories),
    [categoriesData],
  );

  const handleSubmit = useCallback(
    (values: AppStoreAppFormValues) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const [createPreviewImageAssetMutation, { data, loading }] =
    useCreatePreviewImageAssetMutation();

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (canFetchImageByUrl) {
      createPreviewImageAssetMutation({
        variables: {
          input: {
            url: debouncedAppUrlValue,
          },
        },
      }).catch(() => {});
    }
  }, [
    debouncedAppUrlValue,
    canFetchImageByUrl,
    createPreviewImageAssetMutation,
  ]);

  const createdPreviewImageAsset = data?.createPreviewImageAsset?.imageAsset;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CustomAppFormSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, dirty, errors, touched }) => (
        <Form data-testid="custom-app-form">
          <CustomAppFormBody>
            <CustomAppFormFieldsPart>
              <CustomAppField>
                <Field
                  name="name"
                  type="text"
                  data-testid="name-input"
                  disabled={formDisabled}
                  placeholder={formatMessage({
                    id: AppStoreTranslation.customAppFormNamePlaceholder,
                  })}
                  label={formatMessage({
                    id: AppStoreTranslation.customAppFormNameLabel,
                  })}
                  component={FormInput}
                />
              </CustomAppField>
              <CustomAppField>
                <Field
                  onChange={(e: any) => {
                    setAppUrl(e.target.value);
                    setFieldValue('uri', e.target.value);
                  }}
                  name="uri"
                  type="text"
                  data-testid="uri-input"
                  disabled={formDisabled}
                  maxLength={null}
                  placeholder={formatMessage({
                    id: AppStoreTranslation.customAppFormUrlPlaceholder,
                  })}
                  label={formatMessage({
                    id: AppStoreTranslation.customAppFormUrlLabel,
                  })}
                  component={FormInput}
                />
              </CustomAppField>
              {!app?.logo && (
                <CustomAppField>
                  <CheckBoxLabel htmlFor="fetch-logo-checkbox">
                    <Checkbox
                      id="fetch-logo-checkbox"
                      data-testid="fetch-logo-checkbox"
                      checked={canFetchImageByUrl}
                      disabled={formDisabled}
                      onChange={() => {
                        setCanFetchImageByUrl(!canFetchImageByUrl);
                        setFieldValue('logo', '');
                        setImageUrl('');
                      }}>
                      <FormattedMessage
                        id={AppStoreTranslation.customAppFormFetchAppImageByUrl}
                      />
                    </Checkbox>
                  </CheckBoxLabel>
                </CustomAppField>
              )}

              <CustomAppField>
                <Field
                  name="category"
                  type="text"
                  data-testid="category-input"
                  disabled={formDisabled}
                  placeholder={formatMessage({
                    id: AppStoreTranslation.customAppFormCategoryPlaceholder,
                  })}
                  label={formatMessage({
                    id: AppStoreTranslation.customAppFormCategoryLabel,
                  })}
                  component={FormSelect}>
                  <option value="">
                    {formatMessage({
                      id: AppStoreTranslation.customAppFormCategoryPlaceholder,
                    })}
                  </option>
                  {categories?.map(category => (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Field>
              </CustomAppField>
            </CustomAppFormFieldsPart>

            <CustomAppFormUploadPart>
              <ImageUploadLabel htmlFor="custom-app-image-upload">
                <FormattedMessage
                  id={AppStoreTranslation.customAppFormLogoLabel}
                />
              </ImageUploadLabel>

              {!canFetchImageByUrl || app?.logo ? (
                <ImageUpload
                  id="custom-app-image-upload"
                  disabled={formDisabled}
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('logo', id);
                    setImageUrl(contentUrl);
                  }}>
                  <ImageUploadBody>
                    <CustomAppFormImage
                      invalid={!!errors.logo && !!touched.logo}
                      src={imageUrl || UploadImagePlaceholder}
                      data-testid="app-logo"
                    />
                    {errors.logo && touched.logo && (
                      <ImageUploadError>
                        <FormattedMessage
                          id={AppStoreTranslation.customAppFormLogoRequired}
                        />
                      </ImageUploadError>
                    )}
                    <ImageUploadButton
                      disabled={formDisabled}
                      size={ButtonSize.sm}
                      mode={ButtonMode.secondary}>
                      <FormattedMessage
                        id={AppStoreTranslation.customAppFormLogoButton}
                      />
                    </ImageUploadButton>
                  </ImageUploadBody>
                </ImageUpload>
              ) : (
                <AppPreviewImageBox>
                  <AppPreviewImageOuter>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <AppPreviewImage
                        invalid={!!errors.logo && touched.uri}
                        data-testid="app-preview"
                        src={createdPreviewImageAsset?.contentUrl || ''}
                        onLoad={() => {
                          if (createdPreviewImageAsset) {
                            setFieldValue('logo', createdPreviewImageAsset.id);
                          }
                        }}
                        onError={e => {
                          setDefaultImage(
                            e.target,
                            UploadImagePlaceholder,
                            PREVIEW_ICON_SIZE,
                          );
                          setFieldValue('logo', '');
                        }}
                      />
                    )}
                  </AppPreviewImageOuter>
                  <AppPreviewImageDescription>
                    {appUrl && errors.logo && touched.uri && !loading ? (
                      <ImageUploadError>
                        <FormattedMessage
                          id={AppStoreTranslation.customAppFormPreviewError}
                        />
                      </ImageUploadError>
                    ) : (
                      <FormattedMessage
                        id={AppStoreTranslation.customAppFormPreviewInfo}
                      />
                    )}
                  </AppPreviewImageDescription>
                </AppPreviewImageBox>
              )}
            </CustomAppFormUploadPart>
          </CustomAppFormBody>

          <CustomAppFormControls>
            {app &&
              canUnlockCredentials &&
              desktop &&
              onManagePasswordsClick && (
                <>
                  <span
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={
                      !keyset
                        ? formatMessage({
                            id: AppStoreTranslation.customAppFormManagePasswordsDisabledTooltip,
                          })
                        : ''
                    }
                    data-tooltip-place={TooltipPlace.top}>
                    <Button
                      data-testid="manage-passwords-button"
                      type="button"
                      mode={ButtonMode.secondary}
                      size={ButtonSize.md}
                      disabled={!keyset}
                      onClick={onManagePasswordsClick}>
                      <FormattedMessage
                        id={
                          AppStoreTranslation.customAppFormManagePasswordsButton
                        }
                      />
                    </Button>
                  </span>
                </>
              )}
            <Button
              data-testid="submit-button"
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={!dirty || isSubmitting || loading || formDisabled}>
              <FormattedMessage
                id={
                  app
                    ? AppStoreTranslation.customAppFormUpdateButton
                    : AppStoreTranslation.customAppFormCreateButton
                }
              />
            </Button>
          </CustomAppFormControls>
        </Form>
      )}
    </Formik>
  );
};
