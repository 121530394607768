export const tidyDocument = (doc: Document): Document => {
  // make sure all links open in new tab
  const anchors = doc.getElementsByTagName('a');
  for (var i = 0; i < anchors.length; i++) {
    anchors[i].target = '_blank';
    anchors[i].rel = 'noopener noreferrer';
  }

  // remove ul in header as its probably destination breadcrumbs
  const headerUl = doc.querySelector('header ul');
  headerUl?.remove();

  return doc;
};
