import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import type { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import type { DesktopApiType } from '../../Desktop/types/Desktop.types';

export const getTeamDesktopsRestApi = (
  teamId: string,
): Promise<RestApiClientResponse<Array<DesktopApiType>>> => {
  const restClient = getRestApiClient();
  const shortTeamId = getShortId(teamId);

  if (!restClient) {
    throw new Error('Rest client was not provided');
  }

  return restClient.fetch(`/workspace-team/${shortTeamId}/desktops`);
};
