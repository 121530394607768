import styled from 'styled-components';

export const ScheduledConferenceLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScheduledConferenceLinkHeader = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;

export const ScheduledConferenceMeetingLink = styled.div<{ isMobile: boolean }>`
  display: flex;

  a {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.Primary};
    font-family: ${p => p.theme.fonts.Semibold};
    padding-right: 0.625rem;
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    height: 1rem;
    display: ${p => (p.isMobile ? 'block' : 'none')};
  }

  &:hover button {
    display: block;
  }
`;

export const ScheduledConferenceIcalFile = styled.a`
  display: flex;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.Primary};
  font-family: ${p => p.theme.fonts.Semibold};
`;
