import React, { FC, useMemo } from 'react';
import { hasAccountReaction } from './MessageReactions.utils';
import { Reaction } from './MessageReactions.styled';
import { useCurrentWorkspaceAccount } from '../../../../../Workspace/Workspace.hooks';
import { useMessageReactionControls } from './MessageReactions.hooks';
import { Data, getEmojiDataFromNative } from 'emoji-mart';
import { ChatTranslation } from '../../../../i18n';
import { useAccountsContext } from '../../../../../Account';
import { getAccountName } from '../../../../../User/User.utils';
import { useIntl } from 'react-intl';
import data from 'emoji-mart/data/all.json';
import type { ChatMessageInternalType } from '../../../../Chat.types';

interface MessageReactionsWrapperInterface {
  emoji: string;
  accounts: string[];
  chatMessage: ChatMessageInternalType;
}

export const MessageReactionsWrapper: FC<MessageReactionsWrapperInterface> = ({
  emoji,
  accounts,
  chatMessage,
}) => {
  const { account } = useCurrentWorkspaceAccount();
  const { accountsWithAvailability } = useAccountsContext();
  const { toggleChatMessageReaction } = useMessageReactionControls(chatMessage);
  const hasOwnReaction = hasAccountReaction(
    chatMessage.reactions,
    emoji,
    account?.id,
  );
  const { formatMessage, formatHTMLMessage } = useIntl();

  const emojiData = useMemo(
    () => getEmojiDataFromNative(emoji, 'apple', data as unknown as Data),
    [emoji],
  );

  const getUserNames = useMemo(() => {
    if (hasOwnReaction && accounts.length === 1) {
      return formatMessage({
        id: ChatTranslation.reactionsCurrentUserOnly,
      });
    }

    const userNames = accounts
      .filter(acc => accountsWithAvailability[acc] && account.id !== acc)
      .map(foundAccountId => {
        return getAccountName(accountsWithAvailability[foundAccountId]);
      });

    return userNames.filter(name => name).join(', ');
  }, [
    account.id,
    accounts,
    accountsWithAvailability,
    formatMessage,
    hasOwnReaction,
  ]);

  return (
    <Reaction
      data-tooltip-id="global-tooltip"
      data-tooltip-html={
        formatHTMLMessage(
          { id: ChatTranslation.reactionsOtherUsers },
          {
            userNames: getUserNames,
            userNamesSuffix:
              hasOwnReaction && accounts.length > 1
                ? formatMessage({
                    id: ChatTranslation.reactionsCurrentUserSuffix,
                  })
                : '',
            emojiName: emojiData.id,
            emoji: emoji,
          },
        ) as string
      }
      data-html={true}
      hasOwnReaction={hasOwnReaction}
      onClick={() => toggleChatMessageReaction(emoji)}
      data-testid="message-reaction">
      {emoji}
      <span data-testid="people-count">{accounts.length}</span>
    </Reaction>
  );
};
