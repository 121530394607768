import React, { FC, MouseEventHandler, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AccountApiType,
  AccountOrigin,
  AvailabilityStatusType,
} from '../../../User/User.types';
import { getAccountName, isBotAccount } from '../../../User/User.utils';
import {
  MemberBadge,
  MemberName,
  MemberTitle,
  MemberType,
  StyledMemberItem,
  StyledMemberItemWrapper,
} from './MemberItem.styled';
import { ChatAvatar } from '../../ChatAvatar';
import { useAccountPopoverControls } from '../../AccountPopover';
import { ChatTranslation } from '../../i18n';
import { IntegrationBadge } from '../../ChatAvatar/IntegrationBadge';
import { useAccountsContext } from '../../../Account';
import { CallFilledIcon } from '../../../../shared/icons';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';

interface MemberItemProps {
  account: AccountApiType;
  showPopover?: boolean;
  isGuest?: boolean;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMemberItemNameClick?: () => void;
}

export const MemberItem: FC<MemberItemProps> = ({
  account,
  showPopover = false,
  isGuest = false,
  onMouseDown,
  active,
  onMouseEnter,
  onMemberItemNameClick,
}) => {
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { showAccountPopover } = useAccountPopoverControls();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { formatMessage } = useIntl();

  const { accountsWithAvailability } = useAccountsContext();
  const accountWithAvailability =
    accountsWithAvailability[account.id] || account;
  const accountOrigin =
    accountWithAvailability?.origin ||
    account.origin ||
    AccountOrigin.webtopcom;

  const isSlackUser = accountOrigin === AccountOrigin.slack;

  const botAccount = isBotAccount(account);

  useEffect(() => {
    if (active) {
      buttonRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [active]);

  const showInCallBadge =
    accountWithAvailability.onCall &&
    accountWithAvailability.onlineStatus !== AvailabilityStatusType.Offline;

  const memberName =
    account.id === currentAccount?.id
      ? formatMessage({
          id: ChatTranslation.labelsYou,
        })
      : getAccountName(account);

  const handleTitleClick = () => {
    if (onMemberItemNameClick) {
      return onMemberItemNameClick();
    }

    if (showPopover && !botAccount) {
      showAccountPopover(account, buttonRef);
    }
  };
  return (
    <StyledMemberItemWrapper active={active}>
      <StyledMemberItem
        ref={buttonRef}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        data-testid="member-item"
        data-entityid={account.id}>
        <div
          data-tooltip-id="global-tooltip"
          data-tooltip-content={
            !botAccount
              ? formatMessage({ id: ChatTranslation.tooltipMemberDetails })
              : ''
          }
          data-tooltip-place={TooltipPlace.left}>
          <ChatAvatar
            account={account}
            avatarSize="1.5rem"
            onlineStatusSize="0.625rem"
            showOnlineStatus={!botAccount}
            onClick={() => {
              if (showPopover && !botAccount) {
                showAccountPopover(account, buttonRef);
              }
            }}
          />
        </div>

        <div className="member-name" data-testid="member-name-panel">
          <MemberTitle
            data-testid="member-name-title"
            onClick={handleTitleClick}>
            <MemberName data-testid="member-name-value">
              {memberName}
            </MemberName>
            {(isSlackUser || showInCallBadge) && (
              <MemberBadge data-testid="member-badge">
                {isSlackUser && (
                  <IntegrationBadge
                    origin={AccountOrigin.slack}
                    badgeSize={20}
                  />
                )}
                {showInCallBadge && (
                  <CallFilledIcon
                    aria-label={formatMessage({
                      id: ChatTranslation.peoplePanelInACallBadge,
                    })}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: ChatTranslation.peoplePanelInACallBadge,
                    })}
                    data-tooltip-place={TooltipPlace.bottom}
                    data-testid="user-in-call-badge"
                  />
                )}
              </MemberBadge>
            )}
          </MemberTitle>
          {isGuest && (
            <MemberType data-testid="member-guest-msg">
              <FormattedMessage id={ChatTranslation.sidebarGuest} />
            </MemberType>
          )}
        </div>
      </StyledMemberItem>
    </StyledMemberItemWrapper>
  );
};
