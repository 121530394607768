import React from 'react';
import { TABS } from './ExpandedSearch/ExpandedSearch.constants';
import { GlobalSearchContextTypes } from './GlobalSearch.types';

export const GlobalSearchContext =
  React.createContext<GlobalSearchContextTypes>({
    isFocused: false,
    setIsFocused: (state: boolean) => {},
    selectedTab: TABS.ALL_CONTENT,
    setSelectedTab: (tab: TABS) => {},
    searchOnlyByConversation: false,
    setSearchOnlyByConversation: (state: boolean) => {},
    selectedTabCategoryKey: TABS.ALL_CONTENT,
  });
