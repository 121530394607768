import styled from 'styled-components';

export const StyledCallAllMembersListContainer = styled.div`
  margin-top: 1rem;
  background: ${p => p.theme.colors.SurfaceSubdued};
  padding: 0.6rem 0;
  max-height: 200px;
  overflow: auto;
  border-radius: 0.25rem;
`;

export const StyledCallToOneMemberContainer = styled.div`
  margin-top: 1rem;
  background: ${p => p.theme.colors.ImportantInfoMessageBackground};
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: flex-start;
`;

export const StyledAttentionImage = styled.img`
  margin-right: 0.875rem;
`;

export const StyledEmptyList = styled.div`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const IntegrationBadgeContainer = styled.div`
  margin-left: 0.25rem;
`;
