import React, {
  FocusEvent,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  MobileSearchWrapper,
  Option,
  OtherOptionsSection,
  SearchSection,
  StyledTopBar,
} from './AppTopBar.styled';
import { GlobalSearch } from '../GlobalSearch';
import { Notifications } from '../Notifications';
import { UserProfile } from '../User/UserProfile';
import {
  DesktopSidebarIdentifier,
  useDesktopSidebar,
} from '../Desktop/DesktopSidebar';
import { SearchThinIcon, SidebarIcon } from '../../shared/icons';
import { ButtonWithIcon } from '../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useNotifications } from '../Notifications/Notifications.hooks';
import { ChatTranslation } from '../Chat/i18n';
import { useMobile, useQueryParams } from '../../shared/hooks';
import { onComponentBlur } from '../../shared/utils/blur.util';
import { InboxButton } from '../Inbox';
import { useInbox } from '../Inbox/Inbox.hooks';
import { TooltipPlace } from '../../shared/components/Tooltip';
import { DesktopTranslation } from '../Desktop/i18n';
import { WorkspaceSelector } from '../Workspace/WorkspaceSelector/WorkspaceSelector';
import { useGlobalSearchContext } from '../GlobalSearch/GlobalSearch.hooks';
import { useCurrentWorkspacePermissions } from '../Workspace/Workspace.hooks';

export const AppTopBar = forwardRef<any>((props, ref) => {
  const { identifier, setIdentifier: sidebarItemClick } = useDesktopSidebar();
  const { formatMessage } = useIntl();
  const { isOpen: notificationsIsOpen } = useNotifications();
  const { isOpen: inboxIsOpen } = useInbox();
  const isMobile = useMobile();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const globalSearchRef = useRef<any>();
  const queryParams = useQueryParams();
  const { globalSearchTab } = queryParams;
  const { isFocused } = useGlobalSearchContext();
  const {
    permissions: { canViewInboxNotifications },
  } = useCurrentWorkspacePermissions();

  const handleSearchButtonClick = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);

  const onBlurComponentHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onComponentBlur(e, () => {
        if (globalSearchRef.current) {
          globalSearchRef.current.forceBlur();
        }
      });
    },
    [],
  );

  const handleMembersButtonClick = useCallback(() => {
    sidebarItemClick(DesktopSidebarIdentifier.MEMBERS);
  }, [sidebarItemClick]);

  return (
    <>
      {(showSearch || (isFocused && isMobile)) && (
        <MobileSearchWrapper>
          <GlobalSearch
            autoFocus={true}
            onBlur={() => {
              setShowSearch(false);
            }}
          />
        </MobileSearchWrapper>
      )}
      <StyledTopBar ref={ref} isMobile={isMobile} data-testid="app-top-bar">
        {!isMobile && (
          <>
            <WorkspaceSelector />
            <SearchSection
              data-tooltip-id="global-tooltip"
              data-tooltip-content={
                !globalSearchTab
                  ? formatMessage({
                      id: DesktopTranslation.tooltipGlobalSearchWorkspace,
                    })
                  : ''
              }
              data-tooltip-place={TooltipPlace.bottom}
              onBlur={onBlurComponentHandler}
              data-testid="global-search-section">
              <GlobalSearch ref={globalSearchRef} />
            </SearchSection>
          </>
        )}
        <OtherOptionsSection data-testid="other-options-section">
          {canViewInboxNotifications && (
            <Option active={inboxIsOpen} data-testid="inbox-bell-option">
              <InboxButton />
            </Option>
          )}

          {isMobile && (
            <Option active={showSearch}>
              <ButtonWithIcon
                icon={() => <SearchThinIcon width={22} height={20} />}
                data-testid="search-button"
                data-tourid="search-button"
                onClick={handleSearchButtonClick}
              />
            </Option>
          )}
          <Option
            active={notificationsIsOpen}
            data-testid="bell-notifications-option">
            <Notifications />
          </Option>

          <Option
            active={identifier === DesktopSidebarIdentifier.MEMBERS}
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: DesktopTranslation.tooltipPeopleButton,
            })}
            data-tooltip-place={TooltipPlace.bottom}
            data-testid="members-button-option">
            <ButtonWithIcon
              icon={() => <SidebarIcon height={20} />}
              data-testid="members-button"
              data-tourid="members-button"
              contentFill={true}
              opacityDisabled={true}
              onClick={handleMembersButtonClick}
              aria-label={formatMessage({
                id:
                  identifier === DesktopSidebarIdentifier.MEMBERS
                    ? ChatTranslation.closeMembersButton
                    : ChatTranslation.openMembersButton,
              })}
            />
          </Option>
          <Option data-testid="user-profile-option">
            <UserProfile />
          </Option>
        </OtherOptionsSection>
      </StyledTopBar>
    </>
  );
});
