import styled, { css } from 'styled-components';

export const AppsContentGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 24px;
  justify-content: space-between;
  padding: 1rem 0;

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    grid-template-columns: 1fr 1fr;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.md`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${({ theme: { breakpoints } }) => breakpoints.lg`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const AppTile = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  margin-bottom: 1rem;
  padding: 0.625rem 0.875rem;
  border-radius: 8px;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${p => p.theme.colors.BackgroundInputAutofill};
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${p => p.theme.colors.BackgroundInputAutofill};
    `};
`;

export const AppTileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AppTileIcon = styled.img`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  user-select: none;
  object-fit: contain;
  overflow: hidden;
  margin-right: 12px;
`;

export const AppTileName = styled.div`
  margin-right: 0.5rem;
  font-family: 'ProximaNova-Semibold', Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
