import React, { FC, RefObject, useCallback, useEffect } from 'react';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../../Timeline.constants';
import { openUrl } from '../../../../../shared/utils/url.utils';
import { useTimelineEventTracker } from '../../../Timeline.hooks';
import { OpenType } from '../../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { LinkTranslation } from '../../../../Link/i18n';
import {
  OpenLinkCancelButton,
  OpenLinkConfirmationControls,
  OpenLinkConfirmButton,
} from '../../../../Link/LinksView/LinksView.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirm } from '../../../../../shared/components/Modal';
import { TimelineLinkOpenConfirmation } from './TimelineLinkOpenConfirmation';
import { StyledLink } from './LinkInfo.styled';
import { TimelineEventContext } from '../../../Timeline.types';
import { TimelineTranslation } from '../../../i18n';

interface TimelineLinkInfoProps {
  id: string;
  context: TimelineEventContext;
  iconRef?: RefObject<HTMLImageElement>;
  isLink?: boolean;
  isLinkInactive?: boolean;
}

export const LinkInfo: FC<TimelineLinkInfoProps> = ({
  id,
  context,
  iconRef,
  isLink,
  isLinkInactive,
}) => {
  const {
    link_url = '',
    link_name = '',
    abuse_checked = false,
    abuse_description = '',
    safe = false,
  } = context;
  const { account } = useCurrentAccount();
  const openType = account?.identity.openLink || OpenType.newTab;
  const trackTimelineEvent = useTimelineEventTracker();
  const { askConfirmation } = useConfirm();
  const intl = useIntl();

  const openLink = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      trackTimelineEvent(
        TimelineEventScope.links,
        id,
        TimelineEventAction.open,
      );
      openUrl(openType, link_url, link_name, event);
    },
    [id, link_url, link_name, trackTimelineEvent, openType],
  );

  const openUntrustedLinkWarning = useCallback(() => {
    return askConfirmation(
      <TimelineLinkOpenConfirmation
        abuseChecked={abuse_checked}
        abuseDescription={abuse_description}
      />,
      intl.formatMessage({
        id: abuse_checked
          ? LinkTranslation.untrustedLinkModalHeader
          : LinkTranslation.uncheckedLinkModalHeader,
      }),
      {
        width: 384,
        customControls: (onConfirm, onCancel) => (
          <OpenLinkConfirmationControls>
            <OpenLinkCancelButton onClick={onCancel}>
              <FormattedMessage
                id={
                  abuse_checked
                    ? LinkTranslation.untrustedLinkOpenCancel
                    : LinkTranslation.uncheckedLinkOpenCancel
                }
              />
            </OpenLinkCancelButton>
            <OpenLinkConfirmButton onClick={onConfirm}>
              <FormattedMessage
                id={
                  abuse_checked
                    ? LinkTranslation.untrustedLinkOpenConfirm
                    : LinkTranslation.uncheckedLinkOpenConfirm
                }
              />
            </OpenLinkConfirmButton>
          </OpenLinkConfirmationControls>
        ),
      },
    );
  }, [askConfirmation, intl, abuse_checked, abuse_description]);

  const handleLinkClick = useCallback(
    (event: MouseEvent) => {
      if (abuse_checked && safe) {
        openLink(event);
        return;
      }

      openUntrustedLinkWarning().then(confirm => {
        if (!confirm) {
          return;
        }
        openLink(event);
      });
    },
    [openLink, openUntrustedLinkWarning, abuse_checked, safe],
  );

  useEffect(() => {
    iconRef?.current?.addEventListener('click', handleLinkClick);
    return () => {
      /* eslint-disable */
      iconRef?.current?.removeEventListener('click', handleLinkClick);
    };
  }, [handleLinkClick, iconRef]);

  return (
    <StyledLink
      className={`highlighted${isLinkInactive ? ' inactive' : ''}`}
      onClick={() => (!isLinkInactive ? handleLinkClick : undefined)}>
      {isLink && (
        <FormattedMessage id={TimelineTranslation.eventTitleTheLink} />
      )}
      {link_name || link_url}
    </StyledLink>
  );
};
