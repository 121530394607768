import styled from 'styled-components';

export const StyledWorkspaceMenuItem = styled.div<{
  isPreview?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${({ theme }) => theme.spacing.xs};
  margin: ${({ theme, isPreview }) => (isPreview ? 0 : theme.spacing.xs)} 0;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.sm`
      max-width: 50vw;
  `}

  ${({ theme }) => theme.breakpoints.md`
      max-width: 25vw;
  `}
`;

export const WorkspaceIconWithCountBadge = styled.div`
  position: relative;
`;

export const WorkspaceInfo = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.xs};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const WorkspaceName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.fontTemplates.regularSemibold};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
`;

export const WorkspaceRole = styled.div`
  font-family: ${({ theme }) => theme.fonts.Medium};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
`;
