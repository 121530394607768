import React, { FC } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { JoinChatOptions } from './JoinChatOptions';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { Navigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { INVITATION_PATHNAME } from '../../Onboarding.constants';
import { useGetForRegistrationAccountInvitation } from '../../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const JoinChatPage: FC = () => {
  const { account } = useCurrentAccount();
  const { id: inviteId } = useQueryParams();

  const { data: invitationData, loading: invitationLoading } =
    useGetForRegistrationAccountInvitation({
      skip: !!account || !inviteId,
      variables: {
        id: `/account-invitations/${inviteId}`,
      },
    });
  const invitation = invitationData?.getForRegistrationAccountInvitation;

  if (account) {
    return <Navigate to={`${INVITATION_PATHNAME}?id=${inviteId}`} />;
  }

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="join-chat-step">
        {invitationLoading ? (
          <Spinner containerHeight={200} />
        ) : (
          <>
            <FormattedMessage
              tagName={OnboardingStepHeader}
              id={OnboardingTranslation.onboardingJoinChatHeader}
            />
            {invitation ? (
              <>
                <FormattedMessage
                  tagName={OnboardingStepText}
                  id={OnboardingTranslation.onboardingJoinChatDescription}
                />
                <JoinChatOptions invitation={invitation} />
              </>
            ) : (
              <ImportantMessage
                withIcon={true}
                type={ImportantMessageType.WARNING}>
                <FormattedMessage
                  id={OnboardingTranslation.onboardingJoinChatInviteIdError}
                />
              </ImportantMessage>
            )}
          </>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
