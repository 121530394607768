export function cloneObject<T>(obj: T): T {
  return {
    ...obj,
  };
}

export const getRestrictedAxisStyle = (
  style: object,
  initialOffsetX?: number,
): { transform: string; WebkitTransform: string } | {} => {
  if (!initialOffsetX) {
    return {};
  }

  // @ts-ignore
  const transform = style.transform.replace(/\(.+\,/, `(${initialOffsetX}px,`);

  return {
    transform,
    WebkitTransform: transform,
  };
};
