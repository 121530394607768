import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../shared/hooks';
import {
  extractUrlFromString,
  getEntityFromUrl,
  getQueryParamsFrom,
} from '../../shared/utils/url.utils';
import { readClipboard } from '../../shared/utils/clipboard.util';
import { matchPath, useLocation } from 'react-router';
import {
  DESKTOP_ID_PATHNAME,
  DESKTOP_SECTION_COPY_LINKS_KEY,
} from '../Desktop/Desktop.constants';
import { GlobalTranslation } from '../Intl/i18n';
import { CMD_KEY, CTRL_KEY, V_KEY } from './Keyboard.constants';

export const KeyboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();
  const { createLink } = queryParams;

  const ctrlDown = useRef(false);
  const ctrlVDown = useRef(false);

  const onCtrlKeydown = useCallback((event: any) => {
    if (event.keyCode === CTRL_KEY || event.keyCode === CMD_KEY) {
      ctrlDown.current = true;
    }
  }, []);

  const onCtrlKeyup = useCallback((event: any) => {
    if (event.keyCode === CTRL_KEY || event.keyCode === CMD_KEY) {
      ctrlDown.current = false;
      ctrlVDown.current = false;
    }
  }, []);

  const onCtrlVKeydown = useCallback(
    async (event: any) => {
      if (ctrlDown.current && event.keyCode === V_KEY) {
        if (ctrlVDown.current) {
          return false;
        }

        const isChatInputSelected =
          document.activeElement?.classList.contains('ql-editor');

        if (isChatInputSelected) {
          return false;
        }

        const hasOpenedDesktop = !!matchPath(pathname, DESKTOP_ID_PATHNAME);

        const clipboardText = await readClipboard(
          GlobalTranslation.copyClipboardErrorMessage,
        );

        const clipboardUrl = extractUrlFromString(clipboardText || '')[0];
        const isInputActiveElement =
          document.activeElement &&
          (['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName) ||
            document.activeElement.getAttribute('data-ignorelinkpaste'));

        const copiedLinkSectionData = localStorage.getItem(
          DESKTOP_SECTION_COPY_LINKS_KEY,
        );

        if (
          clipboardUrl &&
          hasOpenedDesktop &&
          !isInputActiveElement &&
          !createLink &&
          !copiedLinkSectionData
        ) {
          const workspaceId = getEntityFromUrl(pathname, 'workspace');
          const desktopId = getEntityFromUrl(pathname, 'desktop');

          navigate(
            {
              pathname: `/workspace/${workspaceId}/desktop/${desktopId}/links`,
              search: getQueryParamsFrom({
                ...queryParams,
                createLink: true,
              }),
            },
            {
              state: clipboardUrl,
            },
          );
          event.preventDefault();
        }
        ctrlVDown.current = true;
      }
    },
    [createLink, navigate, pathname, queryParams],
  );

  useEffect(() => {
    document.addEventListener('keydown', onCtrlKeydown, false);
    document.addEventListener('keyup', onCtrlKeyup, false);
    document.addEventListener('keydown', onCtrlVKeydown, false);
    return () => {
      document.removeEventListener('keydown', onCtrlKeydown, false);
      document.removeEventListener('keyup', onCtrlKeyup, false);
      document.removeEventListener('keydown', onCtrlVKeydown, false);
    };
  }, [onCtrlKeydown, onCtrlKeyup, onCtrlVKeydown]);

  return <>{children}</>;
};
