import React, { memo, RefObject } from 'react';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import { ConferenceCallType, ConferenceCreateBy } from '../../Conference.types';
import { CreateConferencePage } from '../../CreateConferencePage';
import {
  HorizontalAlignment,
  Menu,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { getShortId } from '../../../../shared/utils/id';

interface ScheduleConferencePopoverProps {
  triggerRef: RefObject<HTMLElement>;
}

export const ScheduleConferencePopover = memo<ScheduleConferencePopoverProps>(
  ({ triggerRef }) => {
    const { account } = useCurrentWorkspaceAccount();
    const { workspace } = useCurrentWorkspace();

    if (!account) {
      return null;
    }

    return (
      <Menu
        trigger={triggerRef}
        width={290}
        maxHeight={128}
        viaPortal={false}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.CENTER}
        data-testid="schedule-conference-popover">
        <CreateConferencePage
          createConferenceData={{
            currentAccountId: getShortId(account.id),
            currentWorkspaceId: getShortId(workspace.id),
            callType: ConferenceCallType.video,
            createBy: ConferenceCreateBy.empty,
            id: account.id,
          }}
        />
      </Menu>
    );
  },
);
