import styled from 'styled-components';

export const StyledVaultItemGroupSelect = styled.div`
  > div {
    margin: 0;
    padding: 1rem;
  }
`;

export const StyledVaultItemGroupSelectDescription = styled.p`
  margin-bottom: 1rem;
`;

export const StyledOptionInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const StyledVaultItemGroupSelectImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`;
