import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_APP_SSO_GROUPS,
  GET_APPSTORE_APPS,
  GET_APPSTORE_CATEGORIES,
  GET_CUSTOM_APPSTORE_APP,
  GET_CUSTOM_APPSTORE_APPS,
  GET_SSO_COMPATIBLE_APPS,
  GetAppSsoGroupsResponse,
  GetAppSsoGroupsVariables,
  GetAppStoreAppResponse,
  GetAppStoreAppsResponse,
  GetAppStoreAppsVariables,
  GetAppStoreAppVariables,
  GetAppStoreCategoriesResponse,
  GetCustomAppStoreAppsResponse,
  GetCustomAppStoreAppsVariables,
  GetSsoCompatibleAppsResponse,
  GetSsoCompatibleAppsVariables,
  GetAppStoreCategoriesVariables,
} from './AppStore.queries';
import {
  CREATE_CUSTOM_APP,
  CREATE_CUSTOM_APP_SSO_GROUP,
  CREATE_PREVIEW_IMAGE_ASSET,
  CREATE_SSO_CONFIGURATION,
  CreateCustomAppResponse,
  CreateCustomAppSsoGroupResponse,
  CreateCustomAppSsoGroupVariables,
  CreateCustomAppVariables,
  CreatePreviewImageAssetResponse,
  CreatePreviewImageAssetVariables,
  CreateSsoConfigurationResponse,
  CreateSsoConfigurationVariables,
  DELETE_CUSTOM_APP,
  DELETE_SSO_CONFIGURATION,
  DeleteCustomAppResponse,
  DeleteCustomAppVariables,
  DeleteSsoConfigurationResponse,
  DeleteSsoConfigurationVariables,
  EDIT_CUSTOM_APP,
  EditCustomAppResponse,
  EditCustomAppVariables,
  UPDATE_SSO_CONFIGURATION,
  UpdateSsoConfigurationResponse,
  UpdateSsoConfigurationVariables,
} from './AppStore.mutations';
import { useRefreshableQuery } from '../Api/Api.hooks';

export const useAppStoreApps = (
  options?: QueryHookOptions<GetAppStoreAppsResponse, GetAppStoreAppsVariables>,
) =>
  useQuery<GetAppStoreAppsResponse, GetAppStoreAppsVariables>(
    GET_APPSTORE_APPS,
    options,
  );

export const useCustomAppStoreApps = (
  options?: QueryHookOptions<
    GetCustomAppStoreAppsResponse,
    GetCustomAppStoreAppsVariables
  >,
) =>
  useRefreshableQuery<
    GetCustomAppStoreAppsResponse,
    GetCustomAppStoreAppsVariables
  >(GET_CUSTOM_APPSTORE_APPS, options);

export const useCustomAppStoreApp = (
  options?: QueryHookOptions<GetAppStoreAppResponse, GetAppStoreAppVariables>,
) =>
  useRefreshableQuery<GetAppStoreAppResponse, GetAppStoreAppVariables>(
    GET_CUSTOM_APPSTORE_APP,
    options,
  );

export const useAppStoreCategories = (
  options?: QueryHookOptions<
    GetAppStoreCategoriesResponse,
    GetAppStoreCategoriesVariables
  >,
) =>
  useRefreshableQuery<
    GetAppStoreCategoriesResponse,
    GetAppStoreCategoriesVariables
  >(GET_APPSTORE_CATEGORIES, options);

export const useCreateCustomAppMutation = () =>
  useMutation<CreateCustomAppResponse, CreateCustomAppVariables>(
    CREATE_CUSTOM_APP,
  );

export const useEditCustomAppMutation = () =>
  useMutation<EditCustomAppResponse, EditCustomAppVariables>(EDIT_CUSTOM_APP);

export const useDeleteCustomAppMutation = () =>
  useMutation<DeleteCustomAppResponse, DeleteCustomAppVariables>(
    DELETE_CUSTOM_APP,
  );

export const useAppSsoGroups = (
  options?: QueryHookOptions<GetAppSsoGroupsResponse, GetAppSsoGroupsVariables>,
) =>
  useRefreshableQuery<GetAppSsoGroupsResponse, GetAppSsoGroupsVariables>(
    GET_APP_SSO_GROUPS,
    options,
  );

export const useCreateSsoConfiguration = () =>
  useMutation<CreateSsoConfigurationResponse, CreateSsoConfigurationVariables>(
    CREATE_SSO_CONFIGURATION,
  );

export const useUpdateSsoConfiguration = () =>
  useMutation<UpdateSsoConfigurationResponse, UpdateSsoConfigurationVariables>(
    UPDATE_SSO_CONFIGURATION,
  );

export const useDeleteSsoConfiguration = () =>
  useMutation<DeleteSsoConfigurationResponse, DeleteSsoConfigurationVariables>(
    DELETE_SSO_CONFIGURATION,
  );

export const useCreatePreviewImageAssetMutation = () =>
  useMutation<
    CreatePreviewImageAssetResponse,
    CreatePreviewImageAssetVariables
  >(CREATE_PREVIEW_IMAGE_ASSET);

export const useSsoCompatibleAppsQuery = (
  options?: QueryHookOptions<
    GetSsoCompatibleAppsResponse,
    GetSsoCompatibleAppsVariables
  >,
) =>
  useRefreshableQuery<
    GetSsoCompatibleAppsResponse,
    GetSsoCompatibleAppsVariables
  >(GET_SSO_COMPATIBLE_APPS, options);

export const useCreateCustomAppSsoGroupMutation = () =>
  useMutation<
    CreateCustomAppSsoGroupResponse,
    CreateCustomAppSsoGroupVariables
  >(CREATE_CUSTOM_APP_SSO_GROUP);
