import styled from 'styled-components';
import { TabPanel, Tabs } from 'react-tabs';
import { StyledTab } from '../../../../shared/components/Tabs';

export const StyledConferencesNavigator = styled.div`
  height: 100%;
  overflow: hidden;
  background-color: ${p => p.theme.colors.Background};
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;
`;

export const ConferencesStyledTab = styled(StyledTab)`
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

export const StyledTabPanel = styled(TabPanel)`
  &.react-tabs__tab-panel--selected {
    height: 100%;
  }
  overflow: auto;
`;
