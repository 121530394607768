import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyledLink,
  StyledLinkContainer,
  StyledLinkControls,
  StyledLinkInner,
  StyledLinkInnerContainer,
} from './Link.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { LinkTranslation } from '../../i18n';
import { useConfirm } from '../../../../shared/components/Modal';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { LinksMenu } from './LinksMenu';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import {
  getQueryParamsFrom,
  openUrl,
} from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { OpenType } from '../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { LinkOpenConfirmation } from '../../LinkOpenConfirmation';
import {
  OpenLinkCancelButton,
  OpenLinkConfirmationControls,
  OpenLinkConfirmButton,
} from '../LinksView.styled';
import { useTimelineEventTracker } from '../../../Timeline/Timeline.hooks';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../../Timeline/Timeline.constants';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragLinkItemType, DragType } from '../../../Drag';
import { ENTER_KEY } from '../../../Keyboard/Keyboard.constants';
import { useDeleteChatLinkMutation } from '../../../Chat/Chat.hooks';
import { updateChatMessageInCache } from '../../../Chat/cache/Chat.cache';
import { LinkData } from './LinkData/LinkData';
import { LinkImage } from './LinkImage/LinkImage';
import { getLinkTitle } from '../../Link.utils';
import { LinkControls } from './LinkControls/LinkControls';
import { LinkControlsMenu } from './LinkControls/LinkControls.menu';
import {
  DocumentType,
  useDocumentIsSupportedPreviewType,
  useGoogleOauth,
} from '../../../Preview/DocumentPreview';
import { DocumentDynamicIcon } from '../../../Preview/DocumentPreview/DocumentDynamicIcon';
import { useVideoProviderPreviewReady } from '../../../VideoPlayer/VideoPlayer.hooks';
import { VideoPlayerExternal } from '../../../VideoPlayer/VideoPlayer.external';
import { useLinkWithExternalServiceAuth } from '../../../ExternalServiceAuth';
import { LinkDetailsTab } from '../../Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useCurrentTypeOfPage } from '../../../Desktop/Desktop.utils';
import type { ChatTextMessageInternalType } from '../../../Chat/Chat.types';
import type { LinkApiType, LinkVideoMetaData } from '../../Link.types';
import { useCaseCreateFavorite } from '../../../Desktop/UseCase/createFavorite';
import { useCaseDeleteFavorite } from '../../../Desktop/UseCase/deleteFavorite';
import { getShortId } from '../../../../shared/utils/id';
import { LinkHoverControls } from './LinkHover';
import { useLinkActions } from '../LinkActions/LinkActions.hooks';
import { useCurrentDesktopSelectedLinksMap } from '../../../Desktop/DesktopItemMultiselect/DesktopItemMultiselect.hooks';
import { isClipboardSupported } from '../../../../shared/utils/clipboard.util';
import { showToastSuccessMessage } from '../../../../shared/components/Toast';
import { sendLinkTrackingEvent } from '../../tracking/LinkTracking.utils';

interface LinkProps {
  link: LinkApiType;
  dragEnabled?: boolean;
  index?: number | undefined;
  chatLink?: boolean;
  selected?: boolean;
  deckView?: boolean;
  linkChatMessage?: ChatTextMessageInternalType;
  multiselectMode?: boolean;
  forceHoverControls?: boolean;
  disableTrackTimelineEvent?: boolean;
  onLinkCheckboxClick?: (linkId: string) => void;
  onDeleteClick?: () => void;
}

export const Link: FC<LinkProps> = ({
  link,
  dragEnabled = false,
  index = undefined,
  chatLink = false,
  linkChatMessage,
  selected = false,
  deckView = false, // used for DnD preview (when multiple links selected)
  multiselectMode = false,
  forceHoverControls = false,
  disableTrackTimelineEvent = false,
}) => {
  const linkHoverControlsRef = useRef(null);
  const intl = useIntl();
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace?.id;
  const { account } = useCurrentAccount();
  const navigate = useNavigate();
  const { askConfirmation } = useConfirm();
  const trackTimelineEvent = useTimelineEventTracker();
  const linkTitle = useMemo(() => getLinkTitle(link), [link]);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const isAvaliableLinksMenu =
    (desktop || chatLink || isFavoritesDesktop) &&
    !isMobile &&
    (mouseIsOver || isMenuOpened);

  const canPreviewDocument = useDocumentIsSupportedPreviewType(link);
  const { createFavoriteUseCase } = useCaseCreateFavorite();
  const { deleteFavoriteUseCase } = useCaseDeleteFavorite();

  const {
    onLinkCopy,
    onLinkDelete,
    onSelectClick,
    onUnselectClick,
    onSelectAllClick,
    onUnselectAllClick,
  } = useLinkActions();

  const handleCopyLinks = (link: LinkApiType) => {
    if (isClipboardSupported) {
      navigator.clipboard
        .writeText(link.data.realUrl || link.data.url)
        .then(() => {
          showToastSuccessMessage(LinkTranslation.linkCopiedMessage);
        });
    }
  };

  const { currentDesktopSelectedLinksMap } =
    useCurrentDesktopSelectedLinksMap();

  useLinkWithExternalServiceAuth(link);

  const videoProviderPreviewReady: boolean | null =
    useVideoProviderPreviewReady(
      (link.data.metadata as LinkVideoMetaData)?.type ?? null,
    );

  const handleEditLink = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editLink: getShortId(link.id),
      }),
    });
  }, [link, navigate, queryParams]);

  const linkChatMessageId = linkChatMessage?.id;

  const [deleteChatLinkMutation] = useDeleteChatLinkMutation();
  const handleDeleteChatLink = useCallback(() => {
    if (!linkChatMessageId) {
      return;
    }
    askConfirmation(
      intl.formatMessage({
        id: LinkTranslation.linksViewDeleteChatLinkConfirmation,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      deleteChatLinkMutation({
        variables: {
          input: {
            id: link.id,
          },
        },
        update: (proxy, { data }) => {
          if (!data) {
            return;
          }

          updateChatMessageInCache(proxy, linkChatMessageId, cachedMessage => {
            return {
              ...cachedMessage,
              context:
                'links' in cachedMessage.context && cachedMessage.context.links
                  ? {
                      ...cachedMessage.context,
                      links: cachedMessage.context.links.filter(
                        (node: string) => node !== link.id,
                      ),
                    }
                  : cachedMessage.context,
            };
          });
        },
      });
    });
  }, [
    linkChatMessageId,
    askConfirmation,
    intl,
    deleteChatLinkMutation,
    link.id,
  ]);

  const openLink = useCallback(
    (event: MouseEvent | React.SyntheticEvent) => {
      event.preventDefault();
      if (!disableTrackTimelineEvent) {
        trackTimelineEvent(
          TimelineEventScope.links,
          link.id,
          TimelineEventAction.open,
        );
      }

      const url = link.data.url;
      openUrl(account!.identity.openLink, url, linkTitle, event as MouseEvent);
    },
    [disableTrackTimelineEvent, link, account, linkTitle, trackTimelineEvent],
  );

  const openUntrustedLinkWarning = useCallback(() => {
    return askConfirmation(
      <LinkOpenConfirmation
        abuseChecked={link?.data.abuseChecked}
        abuseDescription={link?.data.abuseDescription}
        link={link}
      />,
      intl.formatMessage({
        id: link.data.abuseChecked
          ? LinkTranslation.untrustedLinkModalHeader
          : LinkTranslation.uncheckedLinkModalHeader,
      }),
      {
        width: 384,
        customControls: (onConfirm, onCancel) => (
          <OpenLinkConfirmationControls>
            <OpenLinkCancelButton onClick={onCancel}>
              <FormattedMessage
                id={
                  link.data.abuseChecked
                    ? LinkTranslation.untrustedLinkOpenCancel
                    : LinkTranslation.uncheckedLinkOpenCancel
                }
              />
            </OpenLinkCancelButton>
            <OpenLinkConfirmButton onClick={onConfirm}>
              <FormattedMessage
                id={
                  link.data.abuseChecked
                    ? LinkTranslation.untrustedLinkOpenConfirm
                    : LinkTranslation.uncheckedLinkOpenConfirm
                }
              />
            </OpenLinkConfirmButton>
          </OpenLinkConfirmationControls>
        ),
      },
    );
  }, [askConfirmation, intl, link]);

  /**
   * Mark as favorite
   */

  const [isUpdatingFavorite, setUpdatingFavorite] = useState(false);

  const toggleFavoriteLink = useCallback(
    (
      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      triggeredWithIcon?: boolean,
    ) => {
      if (e) e.stopPropagation();

      if (isUpdatingFavorite) {
        return;
      }
      setUpdatingFavorite(true);
      if (link.accountFavorite) {
        deleteFavoriteUseCase(workspaceId, link.id).then(() => {
          setUpdatingFavorite(false);
        });
        sendLinkTrackingEvent(
          triggeredWithIcon
            ? 'unfavorite_link_from_icon'
            : 'unfavorite_link_from_menu',
        );
      } else {
        createFavoriteUseCase(workspaceId, { link: link.id }).then(() => {
          setUpdatingFavorite(false);
        });
        sendLinkTrackingEvent(
          triggeredWithIcon
            ? 'favorite_link_from_icon'
            : 'favorite_link_from_menu',
        );
      }
    },
    [
      createFavoriteUseCase,
      deleteFavoriteUseCase,
      isUpdatingFavorite,
      link.accountFavorite,
      link.id,
      workspaceId,
    ],
  );

  /**
   * Clicking link
   */

  const handleLinkClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (link.data.abuseChecked && link.data.safe) {
        openLink(event);
        return;
      }

      openUntrustedLinkWarning().then(confirm => {
        if (!confirm) {
          return;
        }
        openLink(event);
      });
    },
    [
      link.data.abuseChecked,
      link.data.safe,
      openUntrustedLinkWarning,
      openLink,
    ],
  );

  /**
   * DnD
   */

  const dragLinkItemData: DragLinkItemType = useMemo(
    () => ({
      type: DragType.LINK,
      workspaceId,
      link,
      currentDesktopSelectedLinksMap,
    }),
    [currentDesktopSelectedLinksMap, link, workspaceId],
  );

  const [{ isDragging }, linkDragRef, preview] = useDrag<
    DragLinkItemType,
    unknown,
    { isDragging: boolean }
  >({
    item: dragLinkItemData,
    canDrag: dragEnabled && !isMenuOpened,
    begin: monitor => {
      return {
        ...dragLinkItemData,
        clientOffset: monitor.getInitialClientOffset(),
        sourceOffset: monitor.getInitialSourceClientOffset(),
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // do not display native preview
  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const handleMenuOpenClick = (isOpened: boolean) => setMenuOpened(isOpened);
  const authWindow = useRef<Window | null>(null);

  const handleOpenLinkDetails = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        linkDetails: getShortId(link.id),
        linkDetailsTab: !chatLink ? LinkDetailsTab.DETAILS : undefined,
        chatLink: chatLink ? true : undefined,
        conversation: undefined,
        thread: undefined,
      }),
    });
  }, [chatLink, navigate, link.id, queryParams]);

  const googleAuthUrl = useGoogleOauth(link);

  const openGoogleAuthWindow = useCallback(() => {
    if (!googleAuthUrl) {
      return;
    }

    authWindow.current = window.open(
      googleAuthUrl,
      intl.formatMessage({
        id: LinkTranslation.linkMenuItemSignInWithGoogle,
      }),
      'height=600, width=450',
    );

    if (authWindow.current && authWindow.current.focus) {
      authWindow.current.focus();
    }
  }, [googleAuthUrl, intl]);

  useEffect(
    () => () => {
      if (authWindow.current && authWindow.current.close) {
        authWindow.current.close();
      }
    },
    [],
  );

  const videoPreviewReady = videoProviderPreviewReady && chatLink;
  const displayHoverControls =
    ((mouseIsOver || isMenuOpened) && !isMobile) || forceHoverControls;
  const displayPreviewLink =
    linkChatMessage?.linkData?.data?.metadata?.type !== DocumentType.JIRA;

  return (
    <StyledLinkContainer
      data-testid="link-container"
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}>
      <StyledLinkInnerContainer
        ref={linkDragRef}
        isDragging={isDragging}
        isDragEnabled={dragEnabled}
        className={`Link LinkId-${link.id}`}
        deckView={deckView}
        data-entityid={link.id}
        tabIndex={0}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ENTER_KEY) {
            handleLinkClick(event);
          }
        }}>
        {isAvaliableLinksMenu && (
          <LinksMenu
            trigger={linkHoverControlsRef}
            handleDeleteLink={
              chatLink ? handleDeleteChatLink : () => onLinkDelete(link)
            }
            handleEditLink={handleEditLink}
            link={link}
            processContextMenuEvent
            handleOpenMenu={handleMenuOpenClick}
            handleCopyLink={
              chatLink ? () => handleCopyLinks(link) : () => onLinkCopy(link)
            }
            chatLinksMenu={chatLink}
            toggleFavoriteLink={toggleFavoriteLink}
            isUpdatingFavorite={isUpdatingFavorite}
            displayPreviewOption={displayPreviewLink}
            displayUnselectOption={selected}
            displayUnselectAllOption={multiselectMode}
            displaySingleLinkOptions={!selected}
            isDocumentLink={canPreviewDocument}
            handleSelectClick={() => onSelectClick(link.id)}
            handleUnselectClick={() => onUnselectClick(link.id)}
            handleSelectAllClick={onSelectAllClick}
            handleUnselectAllClick={onUnselectAllClick}
          />
        )}

        {videoPreviewReady ? (
          <VideoPlayerExternal link={link} />
        ) : (
          <StyledLinkControls
            selected={selected}
            hasOpenMenu={isMenuOpened}
            data-testid="link-outer">
            <LinkHoverControls
              ref={linkHoverControlsRef}
              visible={displayHoverControls}
              handleOpenLinkDetails={handleOpenLinkDetails}
              handleOpenLink={handleLinkClick}
              isChatLink={chatLink}
              displayPreviewLink={displayPreviewLink}
              linkOpenTarget={
                account?.identity.openLink === OpenType.newTab
                  ? '_blank'
                  : '_self'
              }
            />

            <StyledLink
              target={
                account?.identity.openLink === OpenType.newTab
                  ? '_blank'
                  : '_self'
              }
              rel="noopener noreferrer"
              onClick={e => isMobile && handleLinkClick(e)}
              data-testid="link">
              <StyledLinkInner>
                <LinkData link={link} />
                {canPreviewDocument ? (
                  <DocumentDynamicIcon link={link} withBackground />
                ) : (
                  <LinkImage link={link} />
                )}
              </StyledLinkInner>
            </StyledLink>

            <LinkControls
              visible={mouseIsOver || isMenuOpened || selected}
              link={link}
              linkSelected={selected}
              chatLink={chatLink}
              withLinkHover={displayHoverControls}
              openGoogleAuthWindow={openGoogleAuthWindow}
              toggleFavoriteLink={e => toggleFavoriteLink(e, true)}
              onLinkCheckboxChange={() => onSelectClick(link.id)}
            />

            <LinkControlsMenu
              visible={mouseIsOver || isMenuOpened || isMobile || selected}
              link={link}
              chatLink={chatLink}
              linkChatMessage={linkChatMessage}
              index={index}
              handleDeleteChatLink={handleDeleteChatLink}
              handleDeleteLink={() => onLinkDelete(link)}
              handleEditLink={handleEditLink}
              handleCopyLink={
                chatLink ? () => handleCopyLinks(link) : () => onLinkCopy(link)
              }
              handleMenuOpenClick={handleMenuOpenClick}
              toggleFavoriteLink={toggleFavoriteLink}
              isUpdatingFavorite={isUpdatingFavorite}
              isDocumentLink={canPreviewDocument}
              displayPreviewOption={displayPreviewLink}
              displayUnselectOption={selected}
              displayUnselectAllOption={multiselectMode}
              displaySingleLinkOptions={!selected}
              openGoogleAuthWindow={openGoogleAuthWindow}
              handleSelectClick={() => onSelectClick(link.id)}
              handleUnselectClick={() => onUnselectClick(link.id)}
              handleSelectAllClick={onSelectAllClick}
              handleUnselectAllClick={onUnselectAllClick}
            />
          </StyledLinkControls>
        )}
      </StyledLinkInnerContainer>
    </StyledLinkContainer>
  );
};
