import { useApolloClient } from '@apollo/client';
import { useCallback, useContext } from 'react';
import {
  CreatedChatMessageApiType,
  DeletedChatMessageApiType,
  GET_CHAT_CONVERSATION_UPDATES,
  GetChatConversationUpdatesResponse,
  GetChatConversationUpdatesVariables,
  UpdatedMessageDeltaType,
} from '../../Chat/Chat.queries';
import { extractNodes } from '../../../shared/api/api.utils';
import {
  convertGraphQLApiChatMessageToInternal,
  getChatMessageIri,
} from '../../Chat/Chat.utils';
import { ChatMercureContext } from './ChatMercure.context';
import {
  createIDDBChatMessage,
  updateIDDBChatMessage,
} from '../../Chat/Data/Repository/ChatMessage/ChatMessageIDDBRepository';
import { useCurrentWorkspaceAccount } from '../../Workspace/Workspace.hooks';
import { getShortId } from '../../../shared/utils/id';
import { bulkDeleteChatMessagesFromIDDB } from '../../Chat/Data/DataSource/ChatMessage/ChatMessagesIndexedDBSource';

export const useChatMessagesDelta = () => {
  const apolloClient = useApolloClient();
  const { account } = useCurrentWorkspaceAccount();

  const handleCreatedMessages = useCallback(
    (createdMessages: CreatedChatMessageApiType[]) => {
      createdMessages.forEach(createdMessage => {
        const { conversationIri, ...chatMessage } = createdMessage;

        const convertedMessage = convertGraphQLApiChatMessageToInternal(
          chatMessage,
          {
            conversationId: conversationIri,
            accountId: account.id,
          },
        );

        createIDDBChatMessage(convertedMessage);
      });
    },
    [account.id],
  );

  const handleUpdatedMessages = useCallback(
    (updatedMessages: UpdatedMessageDeltaType[]) => {
      updatedMessages.forEach(updatedMessage => {
        const { conversationIri, ...chatMessage } = updatedMessage;
        const convertedMessage = convertGraphQLApiChatMessageToInternal(
          chatMessage,
          {
            conversationId: conversationIri,
            accountId: account.id,
          },
        );

        updateIDDBChatMessage(convertedMessage.id, convertedMessage);
      });
    },
    [account.id],
  );

  const handleDeletedMessages = useCallback(
    async (deletedMessages: DeletedChatMessageApiType[]) => {
      const deletedMessagesIds = deletedMessages.map(deletedMessage =>
        getChatMessageIri(getShortId(deletedMessage.id)),
      );

      await bulkDeleteChatMessagesFromIDDB(deletedMessagesIds);
    },
    [],
  );

  return useCallback(
    (variables: GetChatConversationUpdatesVariables) => {
      return apolloClient
        .query<
          GetChatConversationUpdatesResponse,
          GetChatConversationUpdatesVariables
        >({
          query: GET_CHAT_CONVERSATION_UPDATES,
          fetchPolicy: 'no-cache',
          variables,
        })
        .then(response => {
          const createdMessages = extractNodes(
            response.data.deltaCreatedChatMessages,
          );
          const createdMessageIds = createdMessages.map(({ id }) => id);
          handleCreatedMessages(createdMessages);
          handleUpdatedMessages(
            extractNodes(response.data.deltaUpdatedChatMessages).filter(
              ({ id }) => !createdMessageIds.includes(id),
            ),
          );
          handleDeletedMessages(
            extractNodes(response.data.deltaDeletedChatMessages),
          );
        });
    },
    [
      apolloClient,
      handleCreatedMessages,
      handleDeletedMessages,
      handleUpdatedMessages,
    ],
  );
};

export const useChatMercureListener = () => useContext(ChatMercureContext);
