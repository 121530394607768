export const HIGHLIGHTED_CLASS_NAME = 'highlighted';
export const HIGHLIGHTED_DURATION = 3000;
export const CHAT_MESSAGE_DOM_ID_PREFIX = 'chat-messages';
export const CHAT_MESSAGE_THREAD_DOM_ID_PREFIX = '/chat-messages-thread/';
export const CHAT_MESSAGE_ID_PREFIX = '/chat-messages/';
export const UNREAD_CLASS = 'unread';
export const CONFERENCE_MESSAGE_HEIGHT = 155;
export const THREAD_MESSAGE_HEIGHT = 52;
export const THREAD_MOBILE_MESSAGE_HEIGHT = 201;
export const CONFERENCE_MOBILE_MESSAGE_HEIGHT = 147;
export const THREAD_MESSAGE_WITH_REACTION_HEIGHT = 84;
export const THREAD_MOBILE_WITH_REACTION_MESSAGE_HEIGHT = 102;
export const THREAD_DATE_DELIMETER_HEIGHT = 56;
export const DATE_DELIMETER_HEIGHT = 48;
export const MOBILE_DATE_DELIMETER_HEIGHT = 54;
