import { useContext, useEffect, useMemo } from 'react';
import { LinkApiType } from '../Link/Link.types';
import { getEntityMetadata } from '../Preview/DocumentPreview';
import { ExternalServiceAuthContext } from './ExternalServiceAuth.context';
import {
  ExternalServiceAuthType,
  GoogleTypes,
} from './ExternalServiceAuth.types';

export const useExternalServiceAuth = () =>
  useContext(ExternalServiceAuthContext);

export const useLinkWithExternalServiceAuth = (
  link: LinkApiType | undefined | null,
) => {
  const linkMetaData = getEntityMetadata(link);

  const { addLinkWithAuthUrl } = useExternalServiceAuth();

  useEffect(() => {
    if (link && linkMetaData && 'authorization_url' in linkMetaData) {
      addLinkWithAuthUrl(link);
    }
  }, [link, linkMetaData, addLinkWithAuthUrl]);
};

export const useIsGoogle = (type: string | undefined): boolean => {
  return useMemo(
    () => Object.values(GoogleTypes).includes(type as GoogleTypes) ?? false,
    [type],
  );
};

export const useServiceName = (type: string): ExternalServiceAuthType => {
  return useMemo(() => {
    if (Object.values(GoogleTypes).includes(type as GoogleTypes)) {
      return ExternalServiceAuthType.google;
    }

    return ExternalServiceAuthType.external;
  }, [type]);
};
