import styled from 'styled-components';

export const GoogleInviteUsersDisclosure = styled.p`
  font-size: 0.7rem;
  padding: 0.5rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  a {
    color: ${p => p.theme.colors.PrimaryLight};
  }
`;
