import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import {
  StreamEventActionRequest,
  StreamEventType,
} from '../../../../../Mercure/General';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { UserTranslation } from '../../../../i18n';
import {
  useAccountGoogleContactsQuery,
  useGoogleAuthUrlQuery,
} from '../../../../User.hooks';
import {
  AccountApiType,
  GoogleContactsApiType,
  InviteUsersFormFields,
} from '../../../../User.types';
import {
  AvatarMode,
  StyledInitials,
} from '../../../../UserAvatar/Avatar.styled';
import {
  ExternalContact,
  ExternalContactAvatar,
  ExternalContactInitials,
  ExternalContactInner,
  ExternalContactName,
  ExternalContactsEmptyState,
  ExternalContactsList,
  ExternalIcon,
  ExternalInviteUsersButton,
  StyledExternalInviteUsers,
} from '../ExternalInviteUsers.styled';
import GoogleLogo from '../../../../../../shared/assets/images/google.svg';
import { Spinner } from '../../../../../../shared/components/Spinner';
import { AccountInvitationApiType } from '../../../../../Invitation/data/Invitation/types/Invitation.types';
import { useMercureListener } from '../../../../../Mercure/General/GeneralMercure.hooks';
import { GoogleInviteUsersDisclosure } from './GoogleInviteUsres.styled';
import { appEnv } from '../../../../../../appEnv';

const INITIALS_ICON_SIZE = 22;

export interface GoogleInviteUsersProps {
  users: InviteUsersFormFields[];
  setFormData: (field: string, value: any) => void;
  removeContact: (index: number) => void;
  handleShowAuthPopup: (
    e: React.SyntheticEvent,
    contacts: InviteUsersFormFields[],
    visibleHandler: (visibleStatus: boolean) => void,
    authUrl: string,
  ) => void;
  existingAccounts: AccountApiType[];
  invitedUsers: AccountInvitationApiType[];
}

export const GoogleInviteUsers: FC<GoogleInviteUsersProps> = ({
  users,
  setFormData,
  removeContact,
  handleShowAuthPopup,
  existingAccounts,
  invitedUsers,
}) => {
  const { workspace } = useCurrentWorkspace();
  const [googleContactsUsers, setGoogleContactsUsers] = useState<
    GoogleContactsApiType[]
  >([]);
  const [visible, setVisible] = useState(false);

  const { data, loading: googleAuthUrlLoading } = useGoogleAuthUrlQuery({
    skip: !workspace,
    variables: {
      workspace: workspace.id,
    },
  });

  const googleAuthUrl = data?.googleAuthUrl.authUrl || '';
  const {
    data: googleContactsData,
    loading: contactsLoading,
    refetch: refetchContacts,
  } = useAccountGoogleContactsQuery({
    skip: !googleAuthUrl,
    variables: {
      workspace: workspace.id,
    },
  });

  const { addListener, removeListener } = useMercureListener();

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = async e => {
      if (
        e['@type'] === StreamEventType.ACTION &&
        e['@request'] === StreamEventActionRequest.GOOGLE_ACCOUNT_CONNECTED
      ) {
        await refetchContacts().finally(() => {
          setVisible(true);
        });
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, removeListener, refetchContacts]);

  const googleContacts = useMemo(
    () => googleContactsData?.accountGoogleContacts || [],
    [googleContactsData],
  );

  const handleRemoveContact = useCallback(
    (contact: GoogleContactsApiType, index: number) => {
      googleContactsUsers.splice(index, 1);

      removeContact(users.findIndex(user => contact.email === user.email));
    },
    [googleContactsUsers, removeContact, users],
  );

  const isFirstRun = useRef(false);

  const readyForRender = !(
    !googleContacts.length ||
    googleContactsUsers.length > 0 ||
    !visible
  );

  const renderInvitationList = useCallback(() => {
    if (!readyForRender) {
      return;
    }

    const invited =
      [
        ...invitedUsers.map(
          (user: AccountInvitationApiType) => user.recipients[0],
        ),
        ...existingAccounts,
      ] ?? [];

    const formContacts: GoogleContactsApiType[] = googleContacts
      .map((contact: GoogleContactsApiType) => ({
        ...contact,
        type: 'external-user',
      }))
      .filter(
        contact => !invited.some(invited => invited.email === contact.email),
      );

    setGoogleContactsUsers(formContacts);

    setFormData('users', [...users, ...formContacts]);

    isFirstRun.current = true;
  }, [
    existingAccounts,
    googleContacts,
    invitedUsers,
    readyForRender,
    setFormData,
    users,
  ]);

  useEffect(() => {
    if (!isFirstRun.current) {
      renderInvitationList();
    }
  }, [renderInvitationList]);

  useEffect(
    () => () => {
      setVisible(false);
    },
    [],
  );

  if (googleAuthUrlLoading || contactsLoading) {
    return <Spinner />;
  }

  if (!visible) {
    return (
      <>
        <ExternalInviteUsersButton
          type="button"
          fullWidth
          onClick={e =>
            handleShowAuthPopup(e, googleContacts, setVisible, googleAuthUrl)
          }
          data-testid="invite-google-team">
          <img src={GoogleLogo} alt="google" />
          <FormattedMessage id={UserTranslation.inviteGoogleUsersButton} />
        </ExternalInviteUsersButton>
        <FormattedHTMLMessage
          id={UserTranslation.inviteGoogleUsersDisclosure}
          tagName={GoogleInviteUsersDisclosure}
          values={{
            googleApiServicesDataPolicyUrl:
              appEnv.GOOGLE_API_SERVICES_DATA_POLICY_URL,
          }}
        />
      </>
    );
  }

  return (
    <>
      {visible && !!googleContactsUsers.length && (
        <StyledExternalInviteUsers>
          <ExternalContactsList>
            <h4>
              <FormattedMessage id={UserTranslation.inviteGoogleUsersTitle} />
            </h4>
            {googleContactsUsers.map(
              (contact: GoogleContactsApiType, index: number) => {
                return (
                  <ExternalContact key={contact.id}>
                    <ExternalContactInner>
                      {contact?.imageUrl ? (
                        <ExternalContactAvatar
                          src={contact.imageUrl}
                          alt={contact.displayName}
                        />
                      ) : (
                        <ExternalContactInitials
                          size={INITIALS_ICON_SIZE}
                          mode={AvatarMode.circle}>
                          <StyledInitials
                            size={INITIALS_ICON_SIZE}
                            data-testid="initials">
                            {contact.displayName.trim().charAt(0)}
                          </StyledInitials>
                        </ExternalContactInitials>
                      )}

                      <ExternalContactName>
                        {contact.displayName}
                      </ExternalContactName>
                      <ExternalIcon src={GoogleLogo} alt="google" />
                    </ExternalContactInner>
                    <Button
                      mode={ButtonMode.secondary}
                      size={ButtonSize.sm}
                      onClick={() => handleRemoveContact(contact, index)}>
                      <FormattedMessage
                        id={UserTranslation.inviteRemoveExternalUsersButton}
                      />
                    </Button>
                  </ExternalContact>
                );
              },
            )}
          </ExternalContactsList>
          {!googleContacts.length && (
            <ExternalContactsEmptyState>
              <FormattedMessage
                id={UserTranslation.inviteGoogleUsersEmptyStateMessage}
              />
            </ExternalContactsEmptyState>
          )}
        </StyledExternalInviteUsers>
      )}
    </>
  );
};
