import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputWithIcon } from '../../../../../../shared/components/InputWithIcon/InputWithIcon';
import { SearchIcon } from '../../../../../../shared/icons';
import { AppStoreTranslation } from '../../../../i18n';
import { useQueryParams } from '../../../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useDebounce } from 'use-debounce';

export const FILTER_DEBOUNCE_TIME = 500;

export const AppsFilter: FC = () => {
  const queryParams = useQueryParams();
  const { filter } = queryParams;
  const navigate = useNavigate();
  const intl = useIntl();

  const [filterValue, setFilterValue] = useState<string>(
    (filter as string) || '',
  );
  const [debouncedFilterValue] = useDebounce(filterValue, FILTER_DEBOUNCE_TIME);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        filter: debouncedFilterValue || undefined,
      }),
    });
  }, [debouncedFilterValue]); // eslint-disable-line

  useEffect(() => {
    setFilterValue((filter as string) || '');
  }, [filter]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValue(event.target?.value);
    },
    [],
  );

  return (
    <InputWithIcon
      data-testid="app-store-filter-input"
      data-tourid="app-store-search-input-field"
      icon={SearchIcon}
      value={filterValue}
      placeholder={intl.formatMessage({
        id: AppStoreTranslation.appsSectionSearchPlaceholder,
      })}
      onChange={handleOnChange}
    />
  );
};
