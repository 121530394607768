import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../../shared/hooks';
import { MinimizeIcon } from '../../../../../../shared/icons';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../../Workspace/Workspace.hooks';
import {
  DESKTOP_ID_PATHNAME,
  DESKTOP_ROOT_PATHNAME,
  DESKTOP_TAB_PATHNAME,
  DesktopLayout,
  DesktopTab,
  FAVORITES_ROOT_PATHNAME,
  FAVORITES_TAB_PATHNAME,
} from '../../../../Desktop.constants';
import {
  useCurrentDesktop,
  useCurrentDesktopContent,
} from '../../../../Desktop.hooks';
import { Tab } from './Tab/Tab';
import { TabBarButton } from './TabBarButton/TabBarButton';
import { StyledTabBar, TabBarButtons, TabsContainer } from './TabBar.styled';
import { useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../../i18n';
import { useCurrentTypeOfPage } from '../../../../Desktop.utils';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { getShortId } from '../../../../../../shared/utils/id';

export const TabBar: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const { account: currentWorkspaceAccount } = useCurrentWorkspaceAccount();
  const desktop = useCurrentDesktop();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const {
    setDesktopLayout,
    desktopLinksTotal,
    desktopApps,
    desktopLinks,
    desktopFolders,
  } = useCurrentDesktopContent();
  const queryParams = useQueryParams();

  const { tab: currentTab } = useParams<{ tab?: DesktopTab }>();

  const itemsCount = useMemo(() => {
    if (isFavoritesDesktop) {
      return desktopLinks?.filter(
        link => link.folder?._id === queryParams.folderId,
      ).length;
    }

    return desktopLinksTotal;
  }, [
    desktopLinksTotal,
    isFavoritesDesktop,
    desktopLinks,
    queryParams.folderId,
  ]);

  const tabPath = useCallback(
    (tab: DesktopTab) => {
      if (isFavoritesDesktop) {
        return generatePath(FAVORITES_TAB_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          tab: tab,
        });
      } else if (desktop?.id) {
        return generatePath(DESKTOP_TAB_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          desktopId: getShortId(desktop?.id || ''),
          tab: tab,
        });
      } else {
        return generatePath(DESKTOP_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        });
      }
    },
    [workspace.id, desktop?.id, isFavoritesDesktop],
  );

  const closeTabHandler = useCallback(() => {
    let consolidatedPath: string;

    if (isFavoritesDesktop) {
      consolidatedPath = generatePath(FAVORITES_ROOT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
    } else {
      consolidatedPath = generatePath(DESKTOP_ID_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        desktopId: getShortId(desktop!.id),
      });
    }

    setDesktopLayout(DesktopLayout.CONSOLIDATED);
    navigate({
      pathname: consolidatedPath,
      search: getQueryParamsFrom(queryParams),
    });
  }, [
    isFavoritesDesktop,
    setDesktopLayout,
    navigate,
    queryParams,
    workspace.id,
    desktop,
  ]);

  const isTablet = useTablet();

  const hasLinksOrFolders = !!desktopFolders.length || !!desktopLinks.length;

  const isChatHidden = useMemo(
    () =>
      desktop?.accountsThatChatHidden.some(
        accountId => currentWorkspaceAccount.id === accountId,
      ),
    [currentWorkspaceAccount.id, desktop?.accountsThatChatHidden],
  );

  useEffect(() => {
    if (isChatHidden && currentTab === DesktopTab.chat) {
      navigate({
        pathname: generatePath(DESKTOP_TAB_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          desktopId: getShortId(desktop?.id || ''),
          tab: DesktopTab.apps,
        }),
      });
    }
  }, [currentTab, desktop?.id, isChatHidden, navigate, workspace.id]);

  return (
    <StyledTabBar>
      <TabsContainer isMobile={isMobile}>
        {!!desktopApps.length && (
          <Tab
            title={formatMessage({ id: DesktopTranslation.headerApps })}
            itemCount={desktopApps.length}
            path={tabPath(DesktopTab.apps)}
            isActive={currentTab === DesktopTab.apps}
          />
        )}
        {hasLinksOrFolders && (
          <Tab
            title={formatMessage({ id: DesktopTranslation.headerLinks })}
            itemCount={itemsCount}
            path={tabPath(DesktopTab.links)}
            isActive={currentTab === DesktopTab.links}
          />
        )}

        {!isChatHidden && desktop?.chatEnabled && (
          <Tab
            title={formatMessage({ id: DesktopTranslation.headerChat })}
            path={tabPath(DesktopTab.chat)}
            isActive={currentTab === DesktopTab.chat}
          />
        )}
      </TabsContainer>
      {!isTablet && (
        <TabBarButtons>
          <TabBarButton
            icon={MinimizeIcon}
            identifier="tabs-close-tab"
            tooltipText={formatMessage({
              id: DesktopTranslation.minimizeButtonTooltip,
            })}
            onClick={closeTabHandler}
            data-testid="close-tab"
          />
        </TabBarButtons>
      )}
    </StyledTabBar>
  );
};
