import styled from 'styled-components';

export const StyledCreateDesktopForImport = styled.div`
  padding: 60px 40px 75px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  font-size: 0.875rem;

  button {
    margin-top: 24px;
  }
`;

export const DesktopForImportField = styled.div`
  text-align: left;
`;
