import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useMobile } from '../../../../../../shared/hooks';
import { DesktopLayout } from '../../../../Desktop.constants';
import { useCurrentDesktopContent } from '../../../../Desktop.hooks';

export const SECTION_BORDER_RADIUS = '16px';

const StyledSection = styled.div<{
  isMobile: boolean;
  isTabbed: boolean;
}>`
  --section-title-bar-height: 1.75rem;

  display: flex;
  flex-direction: column;

  ${({ isTabbed, isMobile }) =>
    isTabbed &&
    css`
      position: absolute;
      top: 0;
      left: ${!isMobile ? 1 : 0}rem;
      right: ${!isMobile ? 1 : 0}rem;
      bottom: ${!isMobile ? 1 : 0}rem;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      --section-title-bar-height: 2.5rem;

      flex: 1;
    `};
`;

export type SectionProps = Omit<JSX.IntrinsicElements['div'], 'ref'>;

export const Section: FC<SectionProps> = ({ children, ...props }) => {
  const isMobile = useMobile();
  const { desktopLayout } = useCurrentDesktopContent();

  return (
    <StyledSection
      isMobile={isMobile}
      isTabbed={desktopLayout === DesktopLayout.TABBED}
      {...props}
      data-testid="section">
      {children}
    </StyledSection>
  );
};
