import styled from 'styled-components';

export const ImportAppsGridContainer = styled.div`
  padding: 1.25rem 0;
  background-color: ${p => p.theme.colors.Background};
  border-radius: 4px;
`;

export const StyledImportAppsGrid = styled.div`
  height: 400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(234px, auto));
  grid-auto-rows: min-content;
  grid-gap: 16px 20px;
  padding: 0 40px;
  overflow: auto;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    padding: 0 74px;
  `}
`;

export const ImportApp = styled.div`
  display: flex;
  align-items: center;
  min-height: 68px;
  background-color: ${p => p.theme.colors.Surface};
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const ImportAppImage = styled.img`
  max-width: 32px;
  height: auto;
  margin: 0 1rem 0 0.625rem;
`;

export const ImportAppName = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
