import styled, { css } from 'styled-components';
import { LINK_DETAILS_MODAL_PANE_WIDTH } from './LinkDetailsModal.constants';

export const LinkDetailsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 70vh;
  flex: 1;
`;

export const LinkDetailsLoadingError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LinkDetailsPanel = styled.div<{ solidBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.colors.Divider};
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.md`
    min-width: ${LINK_DETAILS_MODAL_PANE_WIDTH}px;
    max-width: ${LINK_DETAILS_MODAL_PANE_WIDTH}px;
  `}

  ${({ theme, solidBackground }) =>
    solidBackground &&
    css`
      background-color: ${({ theme }) => theme.colors.Background};
    `}
`;
