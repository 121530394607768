import React, { FC } from 'react';
import SlackIcon from '../../../../shared/assets/images/slack.svg';
import MicrosoftIcon from '../../../../shared/assets/images/microsoft.svg';
import styled from 'styled-components';
import { AccountOrigin } from '../../../User/User.types';

interface IntegrationBadgeProps {
  origin: string;
  badgeSize?: number;
}

export const StyledIntegrationBadge = styled.div<{ badgeSize: number }>`
  width: ${p => p.badgeSize}px;
  height: ${p => p.badgeSize}px;
  margin-right: 0.25rem;

  img {
    height: 100%;
  }
`;

export const IntegrationBadge: FC<IntegrationBadgeProps> = ({
  origin,
  badgeSize = 18,
}) => {
  return (
    <StyledIntegrationBadge
      badgeSize={badgeSize}
      data-testid="integration-badge">
      {origin === AccountOrigin.slack && (
        <img src={SlackIcon} alt="" data-testid="slack-logo" />
      )}
      {origin === AccountOrigin.microsoft && (
        <img src={MicrosoftIcon} alt="" data-testid="microsoft-logo" />
      )}
    </StyledIntegrationBadge>
  );
};
