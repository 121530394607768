import {
  VaultApiType,
  WorkspaceAppDesktopVaultsMap,
  WorkspaceAppGroupVaultsMap,
  WorkspaceAppVaultsMap,
  WorkspaceVaultLoginsMap,
  WorkspaceVaultsMap,
} from './Encryption.types';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../Workspace/Workspace.hooks';
import { useEffect, useState } from 'react';
import {
  getVaultApp,
  getVaultAppIds,
  getVaultItemDesktopsMap,
} from './Encryption.utils';
import {
  getDecryptedVaultLogin,
  importPublicKey,
} from './Encryption.crypto.utils';
import { useCurrentAccountKeyset } from './Encryption.hooks';

export const useEncryptionKeysMaps = () => {
  const { account } = useCurrentWorkspaceAccount();
  const { keyset } = useCurrentAccountKeyset();

  const [accountPublicKeysMap, setAccountPubicKeysMap] = useState<
    Record<string, CryptoKey>
  >({});
  const [accountPrivateKeysMap, setAccountPrivateKeysMap] = useState<
    Record<string, CryptoKey>
  >({});

  const accountId = account?.id;
  const currentAccountPublicKeyString = keyset?.pubKey;
  const currentAccountPublicKey = accountId && accountPublicKeysMap[accountId];

  useEffect(() => {
    if (
      currentAccountPublicKeyString &&
      accountId &&
      !currentAccountPublicKey
    ) {
      importPublicKey(currentAccountPublicKeyString).then(publicKey => {
        setAccountPubicKeysMap(map => ({ ...map, [accountId]: publicKey }));
      });
    }
  }, [currentAccountPublicKeyString, currentAccountPublicKey, accountId]);

  return {
    accountPublicKeysMap,
    accountPrivateKeysMap,
    setAccountPrivateKeysMap,
  };
};

export const useEncryptionVaultsMaps = (
  workspaceVaults: VaultApiType[],
  accountPrivateKeysMap: Record<string, CryptoKey>,
) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { keyset } = useCurrentAccountKeyset();
  const [workspaceVaultsMap, setWorkspaceVaultsMap] =
    useState<WorkspaceVaultsMap>({});
  const [workspaceAppVaultsMap, setWorkspaceAppVaultsMap] =
    useState<WorkspaceAppVaultsMap>({});
  const [workspaceAppDesktopVaultsMap, setWorkspaceAppDesktopVaultsMap] =
    useState<WorkspaceAppDesktopVaultsMap>({});
  const [workspaceAppGroupVaultsMap, setWorkspaceAppGroupVaultsMap] =
    useState<WorkspaceAppGroupVaultsMap>({});
  const [workspaceVaultLoginsMap, setWorkspaceVaultLoginsMap] =
    useState<WorkspaceVaultLoginsMap>({});

  const accountId = account?.id;
  const currentAccountPrivateKey =
    accountId && accountPrivateKeysMap[accountId];

  useEffect(() => {
    if (workspace && workspaceVaults) {
      setWorkspaceVaultsMap(map => ({
        ...map,
        [workspace.id]: workspaceVaults.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: curr,
          };
        }, {} as Record<string, VaultApiType>),
      }));

      setWorkspaceAppVaultsMap(map => ({
        ...map,
        [workspace.id]: workspaceVaults.reduce((acc, curr) => {
          const currentAppIds = getVaultAppIds(curr);

          const appsVaultsMap = currentAppIds.reduce(
            (accAppVaults, currAppId) => {
              const currAppIdVaults = acc[currAppId];
              return {
                ...accAppVaults,
                [currAppId]: { ...currAppIdVaults, [curr.id]: curr },
              };
            },
            {},
          );

          return {
            ...acc,
            ...appsVaultsMap,
          };
        }, {} as Record<string, Record<string, VaultApiType>>),
      }));

      setWorkspaceAppGroupVaultsMap(map => ({
        ...map,
        [workspace.id]: workspaceVaults.reduce((acc, curr) => {
          const currentGroupApp = getVaultApp(curr);
          const currentGroupAppId = currentGroupApp?.hidden
            ? currentGroupApp.id
            : currentGroupApp?.groupApp?.id;
          const currentGroupVaults =
            (currentGroupAppId && acc[currentGroupAppId]) || {};
          return {
            ...acc,
            ...(currentGroupAppId
              ? {
                  [currentGroupAppId]: {
                    ...currentGroupVaults,
                    [curr.id]: true,
                  },
                }
              : {}),
          };
        }, {} as Record<string, Record<string, boolean>>),
      }));
    }
  }, [workspaceVaults, workspace]); // eslint-disable-line

  useEffect(() => {
    if (workspace && keyset && currentAccountPrivateKey && workspaceVaults) {
      setWorkspaceAppDesktopVaultsMap(map => {
        return {
          ...map,
          [workspace.id]: workspaceVaults.reduce((accVault, currVault) => {
            const vaultAppIds = getVaultAppIds(currVault);
            return {
              ...accVault,
              ...vaultAppIds.reduce((accMaps, currAppId) => {
                const vaultDesktopIds = Object.keys(
                  getVaultItemDesktopsMap(currVault, currAppId),
                );
                return {
                  ...accMaps,
                  [currAppId]: {
                    ...accVault[currAppId],
                    ...vaultDesktopIds.reduce(
                      (accDesktop, currDesktop) => ({
                        ...accDesktop,
                        [currDesktop]: currVault.id,
                      }),
                      {},
                    ),
                  },
                };
              }, {}),
            };
          }, {} as Record<string, Record<string, string>>),
        };
      });

      let loginsMap = {} as WorkspaceVaultLoginsMap;
      Promise.allSettled(
        workspaceVaults.map(vault => {
          return getDecryptedVaultLogin(
            vault,
            keyset.id,
            currentAccountPrivateKey,
          ).then(decryptedLogin => {
            if (decryptedLogin || decryptedLogin === '') {
              loginsMap = {
                ...loginsMap,
                [workspace.id]: {
                  ...loginsMap[workspace.id],
                  [vault.id]: decryptedLogin,
                },
              };
            }
          });
        }),
      ).then(() => setWorkspaceVaultLoginsMap(loginsMap));
    }
  }, [workspaceVaults, workspace, keyset, currentAccountPrivateKey]); // eslint-disable-line

  return {
    workspaceVaultsMap,
    workspaceAppVaultsMap,
    workspaceAppDesktopVaultsMap,
    workspaceAppGroupVaultsMap,
    workspaceVaultLoginsMap,
  };
};
