import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentLayout } from '../../../ContentLayout';
import { AppsLayoutFluid } from '../AppsLayoutFluid/AppsLayoutFluid';
import { AppsLayoutGrid } from '../AppsLayoutGrid/AppsLayoutGrid';
import type { DesktopAppEdgeApiType } from '../../data/Desktop/types/Desktop.types';

const StyledAppsLayout = styled.div`
  flex: 1;
`;

interface AppsProps {
  apps: DesktopAppEdgeApiType[];
  layout: string;
}

export const AppsLayout: FC<AppsProps> = ({ apps, layout }) => {
  return (
    <StyledAppsLayout data-testid="apps">
      {layout === ContentLayout.FLUID ? (
        <AppsLayoutFluid apps={apps} />
      ) : (
        <AppsLayoutGrid apps={apps} />
      )}
    </StyledAppsLayout>
  );
};
