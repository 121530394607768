import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { Input } from '../../../../../shared/components/Input';

export const StyledAppIntegrationSection = styled.div<{
  expanded?: boolean;
  hasMissedMessages?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    width: 85%;
  }

  & > svg {
    cursor: pointer;
    box-sizing: content-box;
    padding-right: ${({ theme }) => theme.spacing.xxs};
    border-radius: 4px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
    }
  }
`;

export const StyledConversationExpand = styled(ButtonWithIcon)<{
  expanded?: boolean;
}>`
  padding-left: 0.375rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  letter-spacing: 0.04rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1rem;
  font-family: ${p => p.theme.fonts.Semibold};

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    margin-right: 0.563rem;
    transition: transform 0.2s;
  }

  ${p =>
    !p.expanded &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}
`;

export const StyledConversationsFilters = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  svg {
    position: absolute;
    left: 8px;
  }
`;

export const StyledConversationSearch = styled(Input)`
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  border: none;
  line-height: 1.43;
  letter-spacing: -0.14px;

  &:focus {
    padding: 0.125rem 0.375rem 0.125rem 1.375rem;
  }

  &::placeholder {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledExpandContainer = styled.div`
  margin-top: 1.5rem;
`;

export const StyledConversationSearchCloseButton = styled(ButtonWithIcon)`
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  cursor pointer;
`;
