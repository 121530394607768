import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import { CountBadge, PositionType } from '../CountBadge';
import { ExpandContainer, ExpandContent, ExpandTitle } from './Expand.styled';

interface ExpandProps {
  renderTrigger: ((toggle: () => void, expanded: boolean) => ReactNode) | null;
  renderExpandedOnly?: boolean;
  expandedByDefault?: boolean;
  badgeCount?: number;
  dataTestId?: string;
  dataEntityId?: string;
}

export const Expand: FC<PropsWithChildren<ExpandProps>> = ({
  children,
  renderTrigger,
  renderExpandedOnly,
  expandedByDefault = false,
  badgeCount = 0,
  dataTestId,
  dataEntityId,
}) => {
  const [expanded, setExpanded] = useState(expandedByDefault);

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  if (!renderTrigger) {
    return <>{children}</>;
  }

  return (
    <ExpandContainer data-testid={dataTestId} data-entityid={dataEntityId}>
      <ExpandTitle
        withBadge={badgeCount > 0 && !expanded}
        data-testid="expand-title">
        {renderTrigger(toggle, expanded)}
        {badgeCount > 0 && !expanded && (
          <div className="countBadgeWrapper">
            <CountBadge
              count={badgeCount}
              positionMode={PositionType.relative}
            />
          </div>
        )}
      </ExpandTitle>
      <ExpandContent data-testid="expand-content" expanded={expanded}>
        {renderExpandedOnly ? expanded && children : children}
      </ExpandContent>
    </ExpandContainer>
  );
};
