import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditVaultItem } from '../EditVaultItem';
import { EncryptionTranslation } from '../../../i18n';
import { StyledEditVaultItemModal } from './EditVaultItemModal.styled';

interface EditVaultItemModalProps
  extends Pick<ModalProps, 'containerZIndex' | 'onRequestClose'> {
  vaultId: string;
  onDone: () => void;
}

export const EditVaultItemModal: FC<EditVaultItemModalProps> = ({
  vaultId,
  ...modalProps
}) => {
  return (
    <Modal visible={true} width={486} {...modalProps}>
      <ModalHeader onRequestClose={modalProps.onRequestClose}>
        <FormattedMessage id={EncryptionTranslation.editPasswordsModalHeader} />
      </ModalHeader>
      <StyledEditVaultItemModal>
        <EditVaultItem vaultId={vaultId} onDone={modalProps.onDone} />
      </StyledEditVaultItemModal>
    </Modal>
  );
};
