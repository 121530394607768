import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import type { GetChatMessagesVariables } from '../../Chat.queries';

export const encodeMessageVariablesToQueryString = (
  variables: GetChatMessagesVariables,
): string | null => {
  const {
    parentChatMessage,
    first,
    after,
    createdAtLessThan,
    createdAtGreaterThanEquals,
    order,
  } = variables;

  const params = [
    { parentChatMessage },
    { first },
    { after },
    { createdAtLessThan },
    { createdAtGreaterThanEquals },
    { order },
  ]
    .map(param => getQueryParamsFrom(param))
    .filter(param => param);

  return params.length > 0 ? params.join('&') : null;
};
