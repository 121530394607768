import { useCallback } from 'react';
import { deleteFavoriteRepository } from '../deprecatedData/Repository/FavoritesRepository';

export const useCaseDeleteFavorite = () => {
  const deleteFavoriteUseCase = useCallback(
    async (workspaceId: string, itemId: string) => {
      return await deleteFavoriteRepository(workspaceId, itemId);
    },
    [],
  );

  return {
    deleteFavoriteUseCase,
  };
};
