import styled, { css } from 'styled-components';
import { ConferenceStatus } from '../../../../../Conference.types';

export const StyledConferenceTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledConferenceTitle = styled.div<{
  status?: ConferenceStatus;
  isActive?: boolean;
}>`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${({ status }) =>
    status === ConferenceStatus.missed &&
    css`
      color: ${p => p.theme.colors.CriticalLight};
    `};

   ${({ isActive }) =>
     isActive &&
     css`
       color: ${p => p.theme.colors.PrimaryTextLight};
     `};
  }}
`;
