import React from 'react';
import { WindowMessage, WindowMessageType } from './WindowMessage.types';

export interface WindowMessageContextType {
  setMessageCallback: (
    messageType: WindowMessageType,
    callback: (data?: WindowMessage) => void,
  ) => void;
}

export const WindowMessageContext = React.createContext<WindowMessageContextType>(
  {
    setMessageCallback: () => {},
  },
);
