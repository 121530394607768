import { DEFAULT_MODAL_CONTAINER_Z_INDEX } from '../../shared/components/Modal';

export enum TabId {
  appStore = 'app-store',
  personal = 'personal',
  company = 'company',
  customApps = 'custom-apps',
  sso = 'sso',
  passwords = 'passwords',
}

export const EDIT_APP_MODAL_Z_INDEX = DEFAULT_MODAL_CONTAINER_Z_INDEX + 100;
export const MANAGE_APP_PASSWORDS_MODAL_Z_INDEX = EDIT_APP_MODAL_Z_INDEX + 100;
export const SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX =
  MANAGE_APP_PASSWORDS_MODAL_Z_INDEX + 100;
export const CREATE_VAULT_PASSWORDS_MODAL_Z_INDEX =
  SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX + 100;
