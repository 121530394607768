import React, { FC } from 'react';
import { useMobile } from '../../../../../shared/hooks';
import { EmptyStateMobileVersion } from './EmptyStateMobileVersion';
import { EmptyStateDesktopVersion } from './EmptyStateDesktopVersion';

interface EmptyStateProps {
  adminView?: boolean;
}

export const EmptyState: FC<EmptyStateProps> = ({ adminView }) => {
  const isMobile = useMobile();

  return (
    <>
      {isMobile ? (
        <EmptyStateMobileVersion adminView={adminView} />
      ) : (
        <EmptyStateDesktopVersion adminView={adminView} />
      )}
    </>
  );
};
