import React, { FC, Ref, useCallback, useMemo, useRef } from 'react';
import { CheckmarkIcon, CogIcon, KebabIcon } from '../../../shared/icons';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../shared/components/Menu';
import {
  EmptyMessage,
  FetchMoreButton,
  NotificationsListHeader,
  NotificationsListItems,
  NotificationsListKebab,
  NotificationsListTitle,
  StyledNotificationsList,
} from './NotificationsList.styled';
import { NotificationTranslation } from '../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { Spinner } from '../../../shared/components/Spinner';
import {
  GET_NOTIFICATIONS,
  GetNotificationsResponse,
} from '../Notifications.queries';
import { extractNodes } from '../../../shared/api/api.utils';
import { Notification } from '../Notification';
import {
  useMarkAllAsReadNotificationMutation,
  useNotifications,
} from '../Notifications.hooks';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  ADMIN_NOTIFICATIONS_PATHNAME,
  NOTIFICATIONS_FETCHMORE_LOAD_COUNT,
} from '../Notifications.constants';
import { useHideScrollbarWhenIdle } from '../../../shared/hooks/element.hooks';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { GraphQLError } from 'graphql';
import { generatePath, useNavigate } from 'react-router-dom';
import { WorkspaceInfoApiType } from '../../WorkspaceInfo/data/WorkspaceInfo/types/WorkspaceInfo.types';
import { getShortId } from '../../../shared/utils/id';

interface NotificationsListProps {
  notifications: GetNotificationsResponse;
  loading: boolean;
  fetchMore: any;
  workspaceInfo: WorkspaceInfoApiType | null | undefined;
}

export const NotificationsList: FC<NotificationsListProps> = ({
  notifications,
  loading,
  fetchMore,
  workspaceInfo,
}) => {
  const { workspace } = useCurrentWorkspace();
  const KebabRef: Ref<HTMLButtonElement> = useRef(null);
  const notificationsControls = useNotifications();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const items = useMemo(
    () => extractNodes(notifications.notifications).map(node => node),
    [notifications],
  );

  const [markAllAsReadNotificationMutation] =
    useMarkAllAsReadNotificationMutation();

  const handleMenuOpen = useCallback(() => {
    notificationsControls.keepNotificationsOpen(true);
  }, [notificationsControls]);

  const markAllAsRead = useCallback(() => {
    notificationsControls.closeNotifications();
    markAllAsReadNotificationMutation({
      variables: {
        input: {
          workspace: workspace!.id,
        },
      },
      // TODO: Replace refetchQueries with cache manipulation when mercure is implemented
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS,
          variables: {
            accountId: workspaceInfo!.account,
            workspaceId: workspace!.id,
            first: items.length,
          },
        },
      ],
    })
      .then(() => {
        showToastSuccessMessage(
          NotificationTranslation.markAllReadSuccessMessage,
        );
      })
      .catch((e: { graphQLErrors: GraphQLError[] }) => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    notificationsControls,
    markAllAsReadNotificationMutation,
    workspace,
    workspaceInfo,
    items.length,
  ]);

  const fetchMoreHandler = useCallback(() => {
    fetchMore({
      variables: {
        cursor: notifications.notifications.pageInfo.endCursor,
        first: NOTIFICATIONS_FETCHMORE_LOAD_COUNT,
      },
      updateQuery: (
        previousResult: GetNotificationsResponse,
        { fetchMoreResult }: { fetchMoreResult: GetNotificationsResponse },
      ) => {
        const newEdges = fetchMoreResult.notifications.edges;
        const pageInfo = fetchMoreResult.notifications.pageInfo;

        return newEdges.length
          ? {
              notifications: {
                __typename: previousResult.notifications.__typename,
                edges: [...previousResult.notifications.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  }, [fetchMore, notifications]);

  const [notificationsListItemsRef] = useHideScrollbarWhenIdle();

  if (!notifications) {
    // TODO: Add an empty state message ?
    return null;
  }

  return (
    <>
      <StyledNotificationsList data-testid="notification-list">
        <NotificationsListHeader data-testid="header">
          <NotificationsListTitle data-testid="title">
            <FormattedMessage
              id={NotificationTranslation.notificationsHeader}
            />
          </NotificationsListTitle>

          <NotificationsListKebab
            ref={KebabRef}
            data-tooltip-content={formatMessage({
              id: NotificationTranslation.tooltipNotificationOptionsButton,
            })}
            data-tooltip-place={TooltipPlace.top}
            data-testid="three-dots">
            <KebabIcon />
          </NotificationsListKebab>
          <Menu
            onOpen={handleMenuOpen}
            trigger={KebabRef}
            width={230}
            vAlign={VerticalAlignment.BOTTOM}
            hAlign={HorizontalAlignment.RIGHT}
            data-testid="notifications-three-dots-menu">
            <MenuItem
              icon={CheckmarkIcon}
              onClick={markAllAsRead}
              data-testid="read-all">
              <FormattedMessage id={NotificationTranslation.markAllRead} />
            </MenuItem>
            <MenuItem
              icon={CogIcon}
              onClick={() => {
                navigate(
                  generatePath(ADMIN_NOTIFICATIONS_PATHNAME, {
                    workspaceId: getShortId(workspace.id),
                  }),
                );
              }}
              data-testid="notification-settings">
              <FormattedMessage id={NotificationTranslation.settings} />
            </MenuItem>
          </Menu>
        </NotificationsListHeader>
        <NotificationsListItems ref={notificationsListItemsRef}>
          {loading ? (
            <Spinner />
          ) : items.length > 0 ? (
            items.map(item => <Notification key={item.id} item={item} />)
          ) : (
            <EmptyMessage data-testid="empty">
              <FormattedMessage id={NotificationTranslation.emptyMessage} />
            </EmptyMessage>
          )}

          <FetchMoreButton>
            <Button
              mode={ButtonMode.tertiary}
              size={ButtonSize.sm}
              disabled={!notifications.notifications.pageInfo.hasNextPage}
              onClick={fetchMoreHandler}
              data-testid="load-more">
              <FormattedMessage
                id={NotificationTranslation.fetchMoreNotifications}
              />
            </Button>
          </FetchMoreButton>
        </NotificationsListItems>
      </StyledNotificationsList>
    </>
  );
};
