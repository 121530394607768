import React, { FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile } from '../../../../shared/hooks';
import { DesktopsIcon } from '../../../../shared/icons';
import { PreviewSegment } from '../../../PreviewSegment/PreviewSegment';
import { SegmentTranslation } from '../../i18n';
import { SegmentType } from '../../Segment.constants';
import { useGetCurrentSegment } from '../../Segment.hooks';
import {
  SegmentButton,
  SegmentButtonContainer,
  SegmentButtonTitle,
} from '../SegmentSwitcher.styled';

export const SegmentButtonDesktops: FC<{
  navigateToSegment: (segment: SegmentType, isPreviewMode?: boolean) => void;
}> = ({ navigateToSegment }) => {
  const currentSegment = useGetCurrentSegment();
  const segmentButtonContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();

  const isActive =
    currentSegment === SegmentType.DESKTOPS ||
    currentSegment === SegmentType.PASSWORDS;

  const handleClick = useCallback(() => {
    navigateToSegment(SegmentType.DESKTOPS, !isMobile && !isActive);
  }, [isActive, isMobile, navigateToSegment]);

  return (
    <SegmentButtonContainer ref={segmentButtonContainerRef}>
      <SegmentButton
        data-testid="segment-desktops"
        id={SegmentType.DESKTOPS}
        onClick={handleClick}
        isActive={isActive}>
        <DesktopsIcon width={22} height={22} />
      </SegmentButton>

      {!isMobile && (
        <SegmentButtonTitle isActive={isActive} onClick={handleClick}>
          <FormattedMessage id={SegmentTranslation.segmentDesktops} />
        </SegmentButtonTitle>
      )}

      {!isMobile && !isActive && (
        <PreviewSegment
          contentType={SegmentType.DESKTOPS}
          hoverRef={segmentButtonContainerRef.current}
          navigateToSegment={navigateToSegment}
        />
      )}
    </SegmentButtonContainer>
  );
};
