import React, { FC, useCallback, useMemo } from 'react';
import { ShareVaultForm } from './ShareVaultForm';
import {
  useCreateMultipleVaultAccessMutation,
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceKeysetsToAccountsMap,
  useRemoveVaultAccessMutation,
  useWorkspaceVaultById,
} from '../Encryption.hooks';
import { extractNodes } from '../../../shared/api/api.utils';
import { VaultAccessInputType } from '../Encryption.types';
import { getAccountKeyset, getVaultAccess } from '../Encryption.utils';
import { AccountApiType } from '../../User/User.types';
import { useConfirm } from '../../../shared/components/Modal';
import { SharedWithAccountsList } from './ShareVault.styled';
import { SharedWIthAccountsListItem } from './SharedWIthAccountsListItem';
import { Spinner } from '../../../shared/components/Spinner';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { EncryptionTranslation } from '../i18n';
import { useIntl } from 'react-intl';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import {
  decryptVaultAccessKey,
  encryptVaultAccessKey,
  importPublicKey,
} from '../Encryption.crypto.utils';

interface ShareVaultProps {
  vaultId: string;
}

export const ShareVault: FC<ShareVaultProps> = ({ vaultId }) => {
  const intl = useIntl();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace?.id;
  const { keyset } = useCurrentAccountKeyset();
  const { vault, workspaceVaultsLoading } = useWorkspaceVaultById(vaultId);
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const vaultAccess = useMemo(() => {
    if (!vault || !keyset) {
      return null;
    }
    return getVaultAccess(vault, keyset.id);
  }, [vault, keyset]);

  const vaultAccessId = vaultAccess?.id;
  const vaultAccessKey = vaultAccess?.vaultKeyEncrypted;

  const { askConfirmation } = useConfirm();

  const keysetsToAccountsMap = useCurrentWorkspaceKeysetsToAccountsMap();

  const sharedWithKeysetIds = useMemo(() => {
    return extractNodes(vault?.vaultAccesses)
      .filter(vaultAccess => vaultAccess.shared)
      .map(access => access.keyset.id);
  }, [vault]);

  const explicitlySharedWIthAccounts = useMemo(() => {
    return sharedWithKeysetIds
      .filter(keysetId => keysetId !== keyset?.id)
      .map(keysetId => keysetsToAccountsMap.get(keysetId))
      .filter(Boolean) as AccountApiType[];
  }, [sharedWithKeysetIds, keysetsToAccountsMap, keyset]);

  const makeExplicitlySharedVaultAccesses = useCallback(
    (
      vaultAccessKey: string,
      keysetIds: string[],
    ): Promise<Omit<VaultAccessInputType, 'vault' | 'workspace'>[]> => {
      return decryptVaultAccessKey(privateKey, vaultAccessKey).then(
        vaultAccessKeyObject => {
          return Promise.all(
            keysetIds.map(keysetId => {
              const accountToShareWith = keysetsToAccountsMap.get(keysetId);
              const accountPublicKey = getAccountKeyset(
                accountToShareWith as AccountApiType,
              )?.pubKey as string;
              return importPublicKey(accountPublicKey)
                .then(accountPublicKeyObject => {
                  return encryptVaultAccessKey(
                    accountPublicKeyObject,
                    vaultAccessKeyObject,
                  );
                })
                .then(vaultKeyEncryptedForCurrentUser => {
                  return {
                    keyset: keysetId,
                    vaultKeyEncrypted: vaultKeyEncryptedForCurrentUser,
                    parent: vaultAccessId,
                  };
                });
            }),
          );
        },
      );
    },
    [keysetsToAccountsMap, privateKey, vaultAccessId],
  );

  const [
    createMultipleVaultAccessMutation,
  ] = useCreateMultipleVaultAccessMutation();
  const createMultipleVaultAccess = useCallback(
    (
      vaultId: string,
      encryptedVaultAccesses: Pick<
        VaultAccessInputType,
        'keyset' | 'vaultKeyEncrypted' | 'isImplicitlyShared'
      >[],
    ) => {
      if (!encryptedVaultAccesses.length) {
        return Promise.resolve(null);
      }
      return createMultipleVaultAccessMutation({
        variables: {
          input: {
            vaultAccesses: encryptedVaultAccesses.map(access => ({
              vault: vaultId,
              workspace: workspaceId,
              ...access,
            })),
          },
        },
      });
    },
    [createMultipleVaultAccessMutation, workspaceId],
  );

  const [removeVaultAccessMutation] = useRemoveVaultAccessMutation();

  const handleShare = (keysetIds: string[]) => {
    if (!vault || !vaultAccessKey || !keysetIds) {
      return Promise.resolve();
    }

    return makeExplicitlySharedVaultAccesses(vaultAccessKey, keysetIds)
      .then(encryptedVaultAccesses => {
        return createMultipleVaultAccess(vault?.id, encryptedVaultAccesses);
      })
      .catch(e => showToastGraphQLErrors(e.graphQLErrors));
  };

  const handleDelete = (account: AccountApiType) => {
    return askConfirmation(
      intl.formatMessage({
        id:
          EncryptionTranslation.sharePasswordsAccountsListDeleteConfirmationMessage,
      }),
    ).then(confirm => {
      if (!confirm) {
        return Promise.reject(null);
      }

      const keysetId = getAccountKeyset(account)?.id;
      const vaultAccessId = extractNodes(vault?.vaultAccesses).find(
        access => access.keyset.id === keysetId && access.shared,
      )?.id;

      if (!vaultAccessId) {
        return Promise.reject(null);
      }

      return removeVaultAccessMutation({
        variables: {
          input: {
            id: vaultAccessId,
            workspace: workspaceId,
          },
        },
      }).catch(e => showToastGraphQLErrors(e.graphQLErrors));
    });
  };

  return (
    <>
      <ShareVaultForm
        excludeKeysets={sharedWithKeysetIds}
        onShare={handleShare}
      />
      {workspaceVaultsLoading ? (
        <Spinner containerHeight={200} />
      ) : (
        <SharedWithAccountsList>
          {explicitlySharedWIthAccounts.map(account => (
            <SharedWIthAccountsListItem
              key={account.id}
              account={account}
              onDeleteButtonClick={handleDelete}
            />
          ))}
        </SharedWithAccountsList>
      )}
    </>
  );
};
