import styled from 'styled-components';

export const StyledAppTopBarSkeleton = styled.div`
  grid-area: topbar;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.IdentityBar};

  .header {
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .search {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
