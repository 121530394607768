import styled, { css } from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';
import { KeyboardArrowRightIcon } from '../../../../../shared/icons';
import { Breakpoints } from '../../../../Theme';
import { AvatarPlaceholder } from '../../../ChatAvatar/AvatarPlaceholder';
import { ParticipantCount } from '../../ConversationHeader/ParticipantAvatars/ParticipantAvatars.styled';
import {
  THREAD_MESSAGE_WITH_REACTION_HEIGHT,
  THREAD_MOBILE_WITH_REACTION_MESSAGE_HEIGHT,
  THREAD_DATE_DELIMETER_HEIGHT,
  THREAD_MESSAGE_HEIGHT,
} from '../ChatMessage/ChatMessage.constants';
import { MessageLoader } from '../ConversationView.styled';
import { DateDelimiter } from '../DateDelimiter';
import {
  THREADS_SIDEBAR_WIDTH_PERCENT,
  THREAD_HEADER_HEIGHT,
} from './ChatMessageThread.constants';
import {
  CONVERSATION_MIN_WIDTH,
  CONVERSATION_MIN_WIDTH_FOR_FULL_THREAD_WIDTH,
} from './ChatMessageThreadSidebar/ChatMessageThreadSidebar.constants';

export const StyledChatMessageThreadSydebar = styled.div<{
  isMobile: boolean;
  isDesktopSegment: boolean;
  currentConversationWidth: number;
}>`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  position: absolute;

  .thread-chat-message {
    margin: 0 0.5rem;
    min-height: ${THREAD_MESSAGE_HEIGHT}px;
  }

  .thread-chat-message.is-secondary {
    margin-top: 0;
  }

  background-color: ${p => p.theme.colors.Surface};
  border-left: 1px solid ${p => p.theme.colors.SurfaceBorder};
  height: 100%;
  top: 0;
  overflow-y: scroll;
  right: 0;

  ${({ isDesktopSegment, currentConversationWidth }) =>
    css`
      width: ${isDesktopSegment &&
      currentConversationWidth < CONVERSATION_MIN_WIDTH
        ? 100
        : THREADS_SIDEBAR_WIDTH_PERCENT}%;
    `}

  width: ${({ isMobile, currentConversationWidth }) =>
    isMobile ||
    currentConversationWidth < CONVERSATION_MIN_WIDTH_FOR_FULL_THREAD_WIDTH
      ? '100%'
      : `${THREADS_SIDEBAR_WIDTH_PERCENT}%`};

  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  z-index: ${({ theme }) => theme.zIndexLevels.PeoplePanel};
  grid-area: desktop-sidebar;
`;

export const ThreadHeader = styled.div`
  padding: 0.25rem 0;
  display: flex;
`;

export const ThreadToggleButton = styled(Button)`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  margin: 0 0.25rem;
  padding: 0 0.25rem;
  min-height: 1.5rem;

  &[disabled] {
    background-color: unset;
  }
`;

export const ThreadBorder = styled.div`
  position: absolute;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.BorderLight};
  top: 0.25rem;
  bottom: 0;
  left: -1.5rem;
`;

export const ThreadDateDelimiter = styled(DateDelimiter)`
  max-width: 20rem;
  padding: 0 0 0 0.5rem;
  margin: 0 auto;
  height: ${THREAD_DATE_DELIMETER_HEIGHT}px;
`;

export const ThreadReplyButton = styled(Button)`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.75rem;
  font-family: ${p => p.theme.fonts.Semibold};
  margin: 0 0.25rem;
  padding: 0 0.25rem;
  height: 1.5rem;

  svg {
    margin-right: 0.25rem;
  }
`;

export const ThreadSpacer = styled.div`
  height: 0.5rem;
`;

export const StyledChatMessageThreadItem = styled.div<{
  showNone: boolean;
  isMobile: boolean;
  replyFormVisible: boolean;
  withReaction: boolean;
}>`
  ${({ showNone, isMobile, replyFormVisible, withReaction }) => {
    if (replyFormVisible) {
      return css`
        min-height: 'auto';
      `;
    }

    if (withReaction) {
      return css`
        min-height: ${showNone
          ? 0
          : isMobile
          ? `${THREAD_MOBILE_WITH_REACTION_MESSAGE_HEIGHT}px`
          : `${THREAD_MESSAGE_WITH_REACTION_HEIGHT}px`};
        height: inherit;
      `;
    }
  }}
`;

export const ChatMessageThreadViewStyled = styled.div`
  position: relative;
  display: flex;
  height: 35px;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 0.75rem;
  align-items: center;
  overflow: hidden;
  border-radius: 0.3rem;

  .view-thread {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OwnMessageBubble};

    .chat-message-thread-arrow-right {
      display: block;
    }

    .last-reply-at {
      display: none;
    }

    .view-thread {
      display: block;
    }
  }
`;

export const ChatMessageThreadViewCountStyled = styled.div`
  font-size: 0.8rem;
  margin-left: 10px;
  display: flex;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  span {
    color: ${({ theme }) => theme.colors.Primary};
    font-weight: bold;
    margin-right: 5px;
  }
`;

export const ChatMessageThreadViewAuthorsStyled = styled.div`
  display: flex;
`;

export const ChatMessageThreadViewAuthorStyled = styled.div`
  position: relative;
  height: 23px;
  width: 23px;
  margin-left: 2px;

  img,
  .avatar-placeholder {
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.White};
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const ChatMessageThreadViewAuthorAvatarPlaceholderStyled = styled(
  AvatarPlaceholder,
).attrs({
  className: 'avatar-placeholder',
})`
  font-size: 0.8rem;
`;

export const ArrowRightStyled = styled(KeyboardArrowRightIcon).attrs({
  className: 'chat-message-thread-arrow-right',
})`
  position: absolute;
  right: 0.6rem;
  display: none;
  top: 50%;
  transform: translate(0, -50%);
`;

export const ChatMessageViewThreadViewAuthorsCounterStyled = styled(
  ParticipantCount,
)`
  margin-left: 2px;
  font-size: 0.7rem;
`;

export const StyledChatMessageThreadSydebarForm = styled.div`
  padding: 0.2rem 1rem 1.2rem 1rem;
`;

export const ChatMessageThreadSidebarHeaderStyled = styled.div<{
  isMobile: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};
  height: ${THREAD_HEADER_HEIGHT}px;
  padding: 0 0 0 1rem;

  & button {
    @media (max-width: ${Breakpoints.lg - 1}px) {
      padding: 16px;
      transform: none;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 0.6rem 0 1.875em;
    `}
`;

export const ChatMessageThreadSidebarHeaderTitleStyled = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  grid-gap: ${({ theme }) => theme.spacing.xxs};
  max-width: 80%;

  span {
    font-family: ${({ theme }) => theme.fonts.Regular};
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.Primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: baseline;
    margin-left: 5px;
  }

  svg {
    display: flex;
    position: relative;
    top: 2px;
    margin-right: 2px;
  }

  div {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: initial;
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const StyledChatNoThreadMessagesStyled = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
`;

export const ThreadMessageLoader = styled(MessageLoader)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndexLevels.PeoplePanel};
  background-color: ${({ theme }) => theme.colors.Surface};
`;

export const ThreadSpacerWithCounter = styled.div`
  display: flex;
  height: 1px;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  margin: 1.25rem 1.25rem 1.5rem 1.25rem;
  align-items: center;
  font-size: 0.75rem;

  p {
    padding: 0 10px 0 0;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    background-color: ${p => p.theme.colors.Surface};
  }
`;
