import styled from 'styled-components';
import { Button } from '../../../../../../../../shared/components/Button/Button';

export const CopyButton = styled(Button).attrs({
  className: 'copy-button',
})`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`;

export const StyledCopyableBlock = styled.div`
  position: relative;

  &:not(:hover) {
    .copy-button {
      display: none;
    }
  }
`;
