import { useEffect } from 'react';
import { sendSegmentTrackingEvent } from './SegmentTracking.utils';
import { SegmentTrackingAction } from './SegmentTracking.types';

export const useSegmentTrackingEventOnInit = (
  action: SegmentTrackingAction,
) => {
  useEffect(() => {
    sendSegmentTrackingEvent(action);
  }, []); // eslint-disable-line
};
