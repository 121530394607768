import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { SegmentTranslation } from '../../Segment/i18n';
import { SegmentContent } from '../../Segment';
import { SegmentNavigator } from '../../Segment/SegmentNavigator/SegmentNavigator';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace/Workspace.hooks';
import {
  useRedirectChats,
  useSearchChatConversationsQuery,
} from '../Chat.hooks';
import {
  CHATS_FIND_ACCOUNT_URL_PREFIX,
  CHATS_ROOT_PATHNAME,
} from '../../Desktop/Desktop.constants';
import { ChatNavigator } from './ChatNavigator';
import { ChatNavigatorTitleActions } from './ChatNavigator/ChatNavigatorTitleActions/ChatNavigatorTitleActions';
import { ChatContext } from '../Chat.context';
import {
  convertGraphQLApiChatConversationToInternal,
  makeChatConversationIri,
} from '../Chat.utils';
import {
  useCurrentConversationIdFromUrl,
  useSortedConversationsLists,
} from './ChatSegment.hooks';
import { useLiveQuery } from 'dexie-react-hooks';
import { database } from '../../Database';
import { ChatProvider } from '../Chat.provider';
import uniqBy from 'lodash/uniqBy';
import { filterOutNonChatConversations } from './ChatSegment.utils';
import { ChatsPage } from '../ChatsPage';
import { useSegmentTrackingEventOnInit } from '../../Segment/tracking/SegmentTracking.hooks';
import { getShortId } from '../../../shared/utils/id';

export const CHATS_PAGE_SIZE = 100;

export const ChatSegment: FC = () => {
  const { formatMessage } = useIntl();
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const navigate = useNavigate();
  const [chatsSearchValue, setChatsSearchValue] = useState('');

  useSegmentTrackingEventOnInit('open_chats');

  const conversations = useLiveQuery(
    () =>
      database.chatConversations
        .where('workspaceId')
        .equals(workspace.id)
        .sortBy('lastMessageAt')
        .then(response => filterOutNonChatConversations(response))
        .catch(() => []),
    [workspace.id, chatsSearchValue.length],
    [],
  );

  const { data: chatsSearchResult, loading: chatsSearchLoading } =
    useSearchChatConversationsQuery({
      skip: !chatsSearchValue,
      variables: {
        workspace: workspace.id,
        query: chatsSearchValue,
        page: 0,
        pageSize: CHATS_PAGE_SIZE,
        filterOnlyName: true,
      },
      fetchPolicy: 'cache-and-network',
    });

  const listChatConversations = useMemo(
    () =>
      !!chatsSearchValue
        ? uniqBy(
            chatsSearchResult?.searchInChats.map(node => node.chatConversation),
            '_id',
          ).map(item =>
            convertGraphQLApiChatConversationToInternal(item, {
              workspaceId: workspace.id,
              accountId: account.id,
            }),
          )
        : conversations,
    [
      chatsSearchValue,
      chatsSearchResult?.searchInChats,
      conversations,
      workspace.id,
      account.id,
    ],
  );

  const chatConversations = useSortedConversationsLists(
    listChatConversations,
    !!chatsSearchValue.length,
  );

  const currentConversationId = useCurrentConversationIdFromUrl();

  const currentConversation = useMemo(() => {
    if (!currentConversationId) {
      return undefined;
    }
    const currentConversationIri = makeChatConversationIri(
      currentConversationId,
    );
    return listChatConversations.find(
      ({ id }) => id === currentConversationIri,
    );
  }, [currentConversationId, listChatConversations]);

  useRedirectChats(listChatConversations, currentConversationId);

  useEffect(() => {
    if (
      currentConversationId &&
      currentConversationId !== CHATS_FIND_ACCOUNT_URL_PREFIX &&
      !currentConversation &&
      listChatConversations.length
    ) {
      navigate(
        generatePath(CHATS_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
      );
    }
  }, [
    listChatConversations.length,
    currentConversation,
    currentConversationId,
    navigate,
    workspace.id,
  ]);

  return (
    <ChatContext.Provider
      value={{
        conversation: currentConversation,
        initialData: undefined,
      }}>
      <ChatProvider>
        <SegmentNavigator
          segmentTitle={formatMessage({ id: SegmentTranslation.segmentChats })}
          navigatorTitleActions={<ChatNavigatorTitleActions />}>
          <ChatNavigator
            conversations={chatConversations}
            isLoading={chatsSearchLoading}
            onSearch={setChatsSearchValue}
          />
        </SegmentNavigator>
        <SegmentContent>
          <ChatsPage listChatConversations={listChatConversations} />
        </SegmentContent>
      </ChatProvider>
    </ChatContext.Provider>
  );
};
