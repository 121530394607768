import React from 'react';
import { StyledAppTopBarSkeleton } from './AppTopBarSkeleton.styled';
import { SkeletonBox } from '../../../shared/components/SkeletonBox';
import { useTheme } from 'styled-components';

export const AppTopBarSkeleton = () => {
  const { colors } = useTheme();
  return (
    <StyledAppTopBarSkeleton>
      <div className="header">
        <SkeletonBox
          width={1.5}
          height={1.5}
          margin={[0, 0.5, 0, 0]}
          color={colors.AppTopBarSkeleton}
        />
        <SkeletonBox width={8} height={1} color={colors.AppTopBarSkeleton} />
      </div>
      <div className="search">
        <SkeletonBox
          width={25}
          height={1.5}
          margin={[0, 0, 0, 0]}
          color={colors.AppTopBarSkeleton}
        />
      </div>
      <div className="buttons">
        <SkeletonBox
          width={1.25}
          height={1.25}
          margin={[0, 1.5, 0, 0]}
          color={colors.AppTopBarSkeleton}
        />
        <SkeletonBox
          width={1.25}
          height={1.25}
          margin={[0, 1.5, 0, 0]}
          color={colors.AppTopBarSkeleton}
        />
        <SkeletonBox
          width={1.25}
          height={1.25}
          margin={[0, 1.5, 0, 0]}
          color={colors.AppTopBarSkeleton}
        />
        <SkeletonBox
          width={1.75}
          height={1.75}
          margin={[0, 1.5, 0, 0]}
          borderRadius={50}
          color={colors.AppTopBarSkeleton}
        />
      </div>
    </StyledAppTopBarSkeleton>
  );
};
