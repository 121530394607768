import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../shared/components/Modal';
import { StyledAnnouncementModal } from './AnnouncementModal.styled';
import { AnnouncementTranslation } from '../i18n';
import { Announcement5Rebranding } from '../announcements';

interface AnnouncementModalProps extends Omit<ModalProps, 'visible'> {}

export const AnnouncementModal: FC<AnnouncementModalProps> = ({
  onRequestClose,
  ...props
}) => {
  return (
    <Modal
      data-testid="announcement-modal"
      width={512}
      visible={true}
      onRequestClose={onRequestClose}
      {...props}>
      <StyledAnnouncementModal>
        <ModalHeader onRequestClose={onRequestClose}>
          <ModalHeaderTitle>
            <FormattedMessage
              id={AnnouncementTranslation.announcementHeaderTitle}
            />
            &#128293;
          </ModalHeaderTitle>
        </ModalHeader>
        <Announcement5Rebranding />
      </StyledAnnouncementModal>
    </Modal>
  );
};
