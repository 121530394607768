import styled from 'styled-components';

export const StyledMfaModal = styled.div`
  padding: 1.5rem;

  .header {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .body {
    margin-bottom: 1rem;
    line-height: 1.5;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

    a {
      color: ${p => p.theme.colors.Primary};
    }
  }

  .footer {
    button {
      display: block;
      margin-top: 1.5rem;
    }
  }
`;
