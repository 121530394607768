import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import {
  deleteTeamIDDB,
  modifyTeamInIDDB,
  putTeamToIDDB,
} from './operations/TeamIDDB.operations';
import {
  createWorkspaceTeamRestApi,
  deleteTeamRestApi,
  editTeamRestApi,
} from './operations/TeamRestApi.operations';
import { DesktopAccessType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import { deleteAccessByIdRestApi } from '../../../Access/data/operations/AccessRestApi.operations';
import { useAccessActions } from '../../../Access/data/Access.actions';
import type { TeamFormValues } from './types/Team.types';
import {
  addRelatedDesktopsToTeamsIDDB,
  removeRelatedDesktopsFromTeamsIDDB,
} from '../DesktopTeam/operations/DesktopTeam.operations';
import {
  addRelatedTeamsToDesktopsIDDB,
  removeRelatedTeamsFromDesktopsIDDB,
} from '../../../Desktop/data/TeamDesktop/operations/TeamDesktop.operations';
import { getAccessByDesktopIdAndWorkspaceTeamId } from '../../../Access/data/dataSources/AccessIDDB.dataSource';
import { removeAccessByWorkspaceTeamIdAndDesktopIdIDDB } from '../../../Access/data/operations/AccessIDDB.operation';
import {
  addRelatedAccountsToTeamsIDDB,
  removeRelatedAccountsFromTeamsIDDB,
} from '../AccountTeam/operations/AccountTeamIDDB.oprations';
import {
  addRelatedTeamsToAccountsIDDB,
  removeRelatedTeamsFromAccountsIDDB,
} from '../../../Account/data/TeamAccounts/operations/TeamAccount.operations';
import {
  addAccountToTeamRestApi,
  deleteAccountFromTeamRestApi,
} from '../AccountTeam/operations/AccountTeamRest.operations';

export const useTeamsActions = () => {
  const { workspace } = useCurrentWorkspace();

  const { createAccess } = useAccessActions();

  const createTeam = async (team: TeamFormValues) => {
    try {
      const response = await createWorkspaceTeamRestApi({
        ...team,
        workspace: workspace.id,
      });

      if (response) {
        putTeamToIDDB({ ...response, ...team }, workspace.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editTeam = async (
    workspaceTeamId: string,
    teamName: string,
    teamDescription: string,
  ) => {
    modifyTeamInIDDB(workspaceTeamId, teamName, teamDescription);
    editTeamRestApi(workspaceTeamId, teamName, teamDescription);
  };

  const deleteTeam = async (workspaceTeamId: string) => {
    try {
      deleteTeamIDDB(workspaceTeamId);
      deleteTeamRestApi(workspaceTeamId);
    } catch (error) {
      console.error(error);
    }
  };

  const addDesktopToTeamTeam = async (teamId: string, desktopId: string) => {
    try {
      await createAccess(desktopId, {
        accessType: DesktopAccessType.EditLink,
        workspaceTeam: teamId,
      });

      return Promise.allSettled([
        addRelatedDesktopsToTeamsIDDB([teamId], [desktopId]),
        addRelatedTeamsToDesktopsIDDB([desktopId], [teamId]),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const removeDesktopAccess = async (desktopId: string, teamId: string) => {
    const access = await getAccessByDesktopIdAndWorkspaceTeamId(
      desktopId,
      teamId,
    );

    if (!access) {
      return;
    }

    removeAccessByWorkspaceTeamIdAndDesktopIdIDDB(teamId, desktopId);

    deleteAccessByIdRestApi(desktopId, access.id);
  };

  const deleteDesktopFromTeam = async (teamId: string, desktopId: string) => {
    try {
      await removeDesktopAccess(desktopId, teamId);
      return Promise.allSettled([
        removeRelatedDesktopsFromTeamsIDDB([teamId], [desktopId]),
        removeRelatedTeamsFromDesktopsIDDB([desktopId], [teamId]),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const addMemberInTeam = async (
    workspaceTeamId: string,
    accountId: string,
  ) => {
    try {
      await addAccountToTeamRestApi(accountId, workspaceTeamId);
      return Promise.allSettled([
        addRelatedAccountsToTeamsIDDB([workspaceTeamId], [accountId]),
        addRelatedTeamsToAccountsIDDB([accountId], [workspaceTeamId]),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMemberFromTeam = async (
    workspaceTeamId: string,
    accountId: string,
  ) => {
    try {
      await deleteAccountFromTeamRestApi(accountId, workspaceTeamId);
      return Promise.allSettled([
        removeRelatedAccountsFromTeamsIDDB([workspaceTeamId], [accountId]),
        removeRelatedTeamsFromAccountsIDDB([accountId], [workspaceTeamId]),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createTeam,
    editTeam,
    deleteTeam,
    deleteTeamIDDB,
    addDesktopToTeamTeam,
    deleteDesktopFromTeam,
    addMemberInTeam,
    deleteMemberFromTeam,
  };
};
