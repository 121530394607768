import React, { FC, useMemo } from 'react';
import { getDesktopIri } from '../../Desktop.utils';
import { useMobile } from '../../../../shared/hooks';
import { ChooseVaultForDesktopModal } from '../../../Encryption/ChooseVaultForDesktop/ChooseVaultForDesktopModal';
import { getAppIri } from '../../../AppStore/AppStore.utils';
import { SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX } from '../../../AppStore/AppStore.constants';
import { CurrentDesktopContentContext } from '../../Desktop.context';
import { FavoritesDesktopViewModel } from './ViewModel';
import { LoadingState, StyledDesktopContent } from '../Content/Content.styled';
import {
  LayoutConsolidated,
  LayoutTabbed,
} from '../../DesktopView/DesktopLayout';
import { DesktopLayout } from '../../Desktop.constants';
import { DesktopControls } from '../Content/components/Controls/Controls';
import { DesktopEmptyView } from '../../DesktopView/DesktopEmptyView';
import { RenderDelay } from '../../../../shared/hooks/renderDelay.hook';
import { Spinner } from '../../../../shared/components/Spinner';
import { isCurrentAccountGuest } from '../../../User/User.utils';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { MembersUniversal } from '../../../Chat/MembersUniversal';
import { SegmentType, useSetLastUsedSegment } from '../../../Segment';
import { useEffectOnce } from 'react-use';
import { getShortId } from '../../../../shared/utils/id';

export const FavoritesDesktop: FC = () => {
  const isMobile = useMobile();
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const isWorkspaceGuest: boolean | undefined = useMemo(
    () => isCurrentAccountGuest(account, getShortId(workspace.id)),
    [account, workspace.id],
  );

  const {
    currentDesktopContentContextValue,
    desktopLayout,
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
    chooseVaultForDesktopModalCloseHandler,
    favoritesIsLoading,
    favoritesIsEmpty,
  } = FavoritesDesktopViewModel();

  const setLastSegment = useSetLastUsedSegment();
  useEffectOnce(() => {
    setLastSegment(SegmentType.FAVORITES);
  });

  // Loading state
  if (favoritesIsLoading) {
    return (
      <LoadingState isMobile={isMobile}>
        <Spinner />
      </LoadingState>
    );
  }

  // Empty favorites
  if (favoritesIsEmpty) {
    return (
      <RenderDelay>
        <>
          <DesktopEmptyView />
          <MembersUniversal />
        </>
      </RenderDelay>
    );
  }

  return (
    <>
      <CurrentDesktopContentContext.Provider
        value={currentDesktopContentContextValue}>
        <StyledDesktopContent data-testid="desktop-view" isMobile={isMobile}>
          <DesktopControls />
          {desktopLayout === DesktopLayout.CONSOLIDATED ? (
            <LayoutConsolidated />
          ) : (
            <LayoutTabbed />
          )}
        </StyledDesktopContent>
      </CurrentDesktopContentContext.Provider>

      {selectVaultForAppId && !isWorkspaceGuest && (
        <ChooseVaultForDesktopModal
          containerZIndex={SELECT_VAULT_PASSWORDS_MODAL_Z_INDEX}
          appId={getAppIri(selectVaultForAppId as string)}
          groupAppId={
            selectVaultForGroupAppId
              ? getAppIri(selectVaultForGroupAppId as string)
              : undefined
          }
          desktopIdOverride={
            (selectVaultForDesktopIdOverride &&
              getDesktopIri(selectVaultForDesktopIdOverride as string)) ||
            undefined
          }
          onRequestClose={chooseVaultForDesktopModalCloseHandler}
        />
      )}
    </>
  );
};
