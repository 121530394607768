import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { StyledLink, StyledLinkInner } from '../Link.styled';

export const StyledLinkHover = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.colors.LinkHoverBackground};
  display: flex;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${({ theme }) =>
    theme.animationTemplates.opacityOnHoverTransition};
`;

export const StyledLinkHoverContent = styled.div`
  color: ${({ theme }) => theme.colors.LinkHoverButtonText};
  font-size: 0.875rem;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 0.375em;
`;

const StyledLinkControl = `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0.25rem;

  svg {
    margin-right: 0.375em;
  }
`;

export const StyledLinkDetails = styled(ButtonWithIcon)`
  ${StyledLinkControl}
  &:hover {
    background: ${({ theme }) => theme.colors.LinkHoverButtonBackground};
  }
`;

export const StyledOpenLink = styled(StyledLink)`
  ${StyledLinkControl}
  &:hover {
    background: ${({ theme }) => theme.colors.LinkHoverButtonBackground};
  }
`;

export const StyledOpenLinkInner = styled(StyledLinkInner)`
  align-items: center;
  color: ${({ theme }) => theme.colors.LinkHoverButtonText};
`;
