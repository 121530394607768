import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../../shared/hooks';
import { AddIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { PREVIEW_SEGMENT_URL_CHANGE_DELAY } from '../../../../PreviewSegment/PreviewSegment.constants';
import { usePreviewSegment } from '../../../../PreviewSegment/PreviewSegment.hooks';
import { SegmentType } from '../../../../Segment';
import { NavigatorActionButton } from '../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';
import { ChatTranslation } from '../../../i18n';

export const ChatNavigatorTitleActions = () => {
  const {
    permissions: { canCreateGroupChat },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const { isPreviewMode, navigateToSegment } = usePreviewSegment();

  const handleWriteChatMessageButtonClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.CHATS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            createChatConversationModal: true,
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  return canCreateGroupChat ? (
    <>
      <NavigatorActionButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage({
          id: ChatTranslation.tooltipCreateChatButton,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={AddIcon}
        iconSize={14}
        onClick={handleWriteChatMessageButtonClick}
        data-testid="create-chat-btn"
      />
    </>
  ) : null;
};
