import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAccountsContext } from '../../../../../Account';
import { usePingMercure } from '../../../../../Mercure/Ping/PingMercure.hooks';
import {
  IsTypingEventType,
  IsTypingPingEventType,
} from '../../../../../Mercure/Ping/PingMercure.types';
import { AccountWithCountsApiType } from '../../../../../User/User.types';
import { useCurrentWorkspaceAccount } from '../../../../../Workspace/Workspace.hooks';
import { useCurrentConversation } from '../../../../Chat.hooks';
import {
  StyledIsTypingContainer,
  StyledIsTypingInnerContainer,
} from './IsTypingContainer.styled';
import { IsTypingDotsContainer } from './IsTypingDots';

interface IsTypingContainerProps {
  showTyping?: boolean;
}

const ACCOUNTS_TYPING_CLOSE_TIMER = 6000;

export const IsTypingContainer: FC<IsTypingContainerProps> = ({
  showTyping = true,
}) => {
  const { accountsWithAvailability } = useAccountsContext();
  const { conversation } = useCurrentConversation();
  const { account: currentAccount } = useCurrentWorkspaceAccount();

  const [visible, setVisible] = useState(false);
  const [accountsTyping, setAccountTyping] = useState<string[]>([]);
  const closeTimer = useRef<ReturnType<typeof setTimeout>>();

  const stopTyping = useCallback(() => {
    if (!conversation?.id) {
      return;
    }

    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = undefined;
    }

    setVisible(false);
    setAccountTyping([]);
  }, [conversation?.id]);

  const { addListener, removeListener } = usePingMercure();

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = e => {
      const event: IsTypingPingEventType | boolean =
        'type' in e && (e as IsTypingPingEventType);

      if (!event) {
        return;
      }

      if (event.type === IsTypingEventType.startTypingInChat) {
        if (conversation?.id !== event.chatConversation) {
          return;
        }

        if (closeTimer.current) {
          clearTimeout(closeTimer.current);
          closeTimer.current = undefined;
        }

        closeTimer.current = setTimeout(() => {
          stopTyping();
        }, ACCOUNTS_TYPING_CLOSE_TIMER);

        if (
          accountsTyping.filter(account => account === event.account).length > 0
        ) {
          return;
        }

        setAccountTyping(oldState => [...oldState, event.account]);

        return;
      }

      if (event.type === IsTypingEventType.stopTypingInChat) {
        const filteredAccounts = accountsTyping.filter(
          acc => acc !== event.account,
        );
        setAccountTyping(filteredAccounts);
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [
    accountsTyping,
    addListener,
    conversation?.id,
    removeListener,
    stopTyping,
  ]);

  const participantsIsTyping = useMemo((): AccountWithCountsApiType[] => {
    const mapedParticipants: (AccountWithCountsApiType | undefined)[] =
      accountsTyping.map(accountIri => {
        if (currentAccount.id === accountIri) {
          return undefined;
        }

        return accountsWithAvailability[accountIri];
      }) ?? [];

    return mapedParticipants.filter(Boolean) as AccountWithCountsApiType[];
  }, [accountsWithAvailability, accountsTyping, currentAccount]);

  useEffect(() => {
    if (!closeTimer.current) {
      return;
    }

    if (participantsIsTyping.length > 0 && showTyping && !visible) {
      setVisible(true);
    } else if (!showTyping && visible) {
      setVisible(false);
    }
  }, [participantsIsTyping, showTyping, visible]);

  useEffect(() => {
    if (closeTimer.current && participantsIsTyping.length === 0) {
      stopTyping();

      closeTimer.current = undefined;
    }
  }, [participantsIsTyping, stopTyping]);

  return (
    <StyledIsTypingContainer visible={visible} data-testid="is-typing">
      <IsTypingDotsContainer />
      <StyledIsTypingInnerContainer data-testid="names">
        {participantsIsTyping
          .map(participant => participant.firstName)
          .join(', ')}
      </StyledIsTypingInnerContainer>
    </StyledIsTypingContainer>
  );
};
