import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { WorkspaceApiType } from '../../../Workspace.types';
import { MenuItem } from '../../../../../shared/components/Menu';
import { WorkspaceMenuItem } from '../WorkspaceMenuItem/WorkspaceMenuItem';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../../Workspace.hooks';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';
import { appendQueryParamsToPath } from '../../../../../shared/utils/url.utils';
import { SortableItem } from '../../../../Drag';
import { sendLastOpenedWorkspaceExtensionMessage } from '../../../Workspace.utils';
import { DragHandleIcon } from '../../../../../shared/icons';
import {
  WorkspaceMenuItemDragTrigger,
  WorkspaceMenuItemWrapper,
} from './WorkspacesList.styled';
import { WorkspaceAccountGroupIdentity } from '../../../../User/User.constants';
import { getShortId } from '../../../../../shared/utils/id';

type PropsType = {
  workspace: WorkspaceApiType;
  role: WorkspaceAccountGroupIdentity;
};

export const WorkspacesListItem: FC<PropsType> = ({ workspace, role }) => {
  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  // dnd
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: getShortId(workspace.id) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SortableItem ref={setNodeRef} style={style} {...attributes}>
      <MenuItem
        onClick={() => {
          mobileNavigationSidebarClose();
          sendLastOpenedWorkspaceExtensionMessage().catch(() => {});
          navigate(
            `/workspace/${getShortId(workspace.id)}${appendQueryParamsToPath(
              queryParams,
            )}`,
          );
        }}
        displayCheckMark={workspace.id === currentWorkspace.id}
        data-testid="workspace-item"
        data-entityid={workspace.id}>
        <WorkspaceMenuItemWrapper>
          <WorkspaceMenuItemDragTrigger
            className="dragHandleIcon"
            data-testid="item-draghandle"
            ref={setActivatorNodeRef}
            {...listeners}>
            <DragHandleIcon data-testid="drag-icon" />
          </WorkspaceMenuItemDragTrigger>
          <WorkspaceMenuItem workspace={workspace} role={role} />
        </WorkspaceMenuItemWrapper>
      </MenuItem>
    </SortableItem>
  );
};
