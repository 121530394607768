import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const InboxIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M5.803 3.967c.145-.29.369-.535.645-.706.277-.17.595-.261.92-.261h9.266c.661 0 1.265.372 1.563.962l3.723 7.388c.053.105.08.22.08.338v6.562c0 .464-.184.91-.513 1.237-.328.329-.773.513-1.237.513H3.75c-.464 0-.91-.184-1.237-.513C2.184 19.16 2 18.714 2 18.25v-6.5c0-.116.028-.231.08-.335l3.723-7.448zm1.565.533c-.046 0-.092.013-.132.037-.04.025-.071.06-.092.101L3.964 11H8c.131 0 .26.034.374.1.113.065.208.159.274.272L10.18 14h3.638l1.533-2.628c.065-.112.159-.205.27-.27.113-.066.24-.1.37-.102l4.047-.048-3.182-6.315c-.02-.041-.053-.076-.092-.1-.04-.024-.085-.037-.131-.037H7.368zM20.5 12.447l-4.066.048-1.536 2.633c-.066.113-.16.207-.274.272-.114.066-.243.1-.374.1h-4.5c-.131 0-.26-.034-.374-.1-.113-.065-.208-.159-.274-.272L7.57 12.5H3.5v5.75c0 .138.112.25.25.25h16.5c.066 0 .13-.026.177-.073.047-.047.073-.11.073-.177v-5.803z"
      />
    </svg>
  );
});
