import React, { FC, useCallback } from 'react';
import {
  ChatConversationInternalType,
  ChatMessageApiType,
  ConversationType,
  MessageType,
  SearchChatConversationApiType,
  SpecialMentionType,
} from '../../../../Chat.types';
import { ChatTranslation } from '../../../../i18n';
import { getAccountName } from '../../../../../User/User.utils';
import { convertToMessageParts } from '../../../../ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.utils';
import { MessagePartTypes } from '../../../../ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.types';
import { AccountApiType } from '../../../../../User/User.types';
import { useCurrentAccount } from '../../../../../Auth/Auth.hooks';
import { useIntl } from 'react-intl';
import { useAccountsContext, useDeletedAccount } from '../../../../../Account';
import { Message } from './ConversationListItem.styled';

interface ConversationListItemPreviewProps {
  conversation: ChatConversationInternalType;
  hitMessage?: SearchChatConversationApiType['hitMessage'];
}

export const ConversationListItemPreview: FC<
  ConversationListItemPreviewProps
> = ({ conversation, hitMessage }) => {
  const { account: currentAccount } = useCurrentAccount();
  const intl = useIntl();
  const { accountsWithAvailability } = useAccountsContext();

  const isHitMessageOwner =
    hitMessage &&
    (hitMessage.type !== MessageType.System
      ? hitMessage.accountIri === currentAccount!.id
      : false);

  const deletedAccount = useDeletedAccount();

  const getMessageOwnerName = (chatMessage: ChatMessageApiType) => {
    if (chatMessage.type === MessageType.System) {
      return intl.formatMessage({
        id: ChatTranslation.labelsSystem,
      });
    }
    if (isHitMessageOwner) {
      return intl.formatMessage({
        id: ChatTranslation.labelsYou,
      });
    }
    return getAccountName(
      accountsWithAvailability[chatMessage.accountIri] ?? deletedAccount,
    );
  };

  const formatMessage = useCallback(
    (message: string) => {
      return convertToMessageParts(message)
        .map(({ type, value }) => {
          if (type === MessagePartTypes.Mention) {
            const mentionedAccountId = value.substring(2, value.length - 1);
            if (
              mentionedAccountId === SpecialMentionType.Here ||
              mentionedAccountId === SpecialMentionType.Channel
            ) {
              return `@${mentionedAccountId}`;
            }
            const mentionedAccount: AccountApiType | undefined =
              accountsWithAvailability[mentionedAccountId];
            return mentionedAccount
              ? `@${getAccountName(mentionedAccount)}`
              : '';
          }
          return value;
        })
        .join('');
    },
    [accountsWithAvailability],
  );

  return (
    <Message data-testid="conversation-item-preview">
      <div className="title" data-testid="title">
        <span>
          {(conversation.type === ConversationType.desktop ||
            conversation.type === ConversationType.group) &&
            conversation.lastMessageAt &&
            hitMessage &&
            `${getMessageOwnerName(hitMessage)}: `}
        </span>
      </div>
      <div data-testid="content">
        {hitMessage &&
        (hitMessage.type === MessageType.Message ||
          hitMessage.type === MessageType.ThreadMessage)
          ? formatMessage(hitMessage.message)
          : null}
      </div>
    </Message>
  );
};
