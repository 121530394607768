import React, { FC, useCallback } from 'react';
import { TrashIcon } from '../../../../../shared/icons/Trash.icon';
import { PaymentMethodApiType } from '../../../Billing.types';
import { StyledRemovePaymentMethodButton } from './RemovePaymentMethodButton.styled';

interface RemovePaymentMethodButtonProps {
  paymentMethod: PaymentMethodApiType;
  onDeleteClick: (paymentMethodId: string) => void;
  isUpdating: boolean;
}

export const RemovePaymentMethodButton: FC<RemovePaymentMethodButtonProps> = ({
  paymentMethod,
  onDeleteClick,
  isUpdating,
}) => {
  const { paymentMethodId } = paymentMethod;

  const clickHandler = useCallback(() => {
    if (isUpdating) {
      return;
    }
    onDeleteClick(paymentMethodId);
  }, [paymentMethodId, onDeleteClick, isUpdating]);

  return (
    <StyledRemovePaymentMethodButton
      type="button"
      isUpdating={isUpdating}
      disabled={isUpdating}
      onClick={clickHandler}>
      <TrashIcon />
    </StyledRemovePaymentMethodButton>
  );
};
