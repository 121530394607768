import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { useQueryParams } from '../../../../shared/hooks';
import { SearchThinIcon } from '../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { DesktopTranslation } from '../../i18n';

const FilterButton = styled(ButtonWithIcon)`
  margin: 0 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.75rem;

  ${p => p.theme.breakpoints.md`
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    `}
`;

export const DesktopFilterButton = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  return (
    <FilterButton
      data-testid="filter-button"
      icon={SearchThinIcon}
      iconFirst
      onClick={() =>
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            desktopFilter: '',
          }),
        })
      }
      aria-label={intl.formatMessage({
        id: DesktopTranslation.filterButton,
      })}>
      <FormattedMessage id={DesktopTranslation.filterButton} />
    </FilterButton>
  );
};
