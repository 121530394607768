import styled from 'styled-components';
import { Tabs } from 'react-tabs';

const INBOX_LIST_HEADER_PADDING = 12;

export const StyledInboxList = styled.div`
  position: absolute;
  bottom: 55px;
  left: 1rem;
  right: 1rem;
  width: unset;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.Surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  padding-top: 12px;
  z-index: ${p => p.theme.zIndexLevels.NotificationList};
  border: 1px solid ${p => p.theme.colors.BorderDefault};

  &:before {
    content: ' ';
    position: absolute;
    bottom: -8px;
    right: 90.5%;
    /*
      The percentage above is making sure the arrow on the notifications-list
      is *approximately* centered to the notifications icon (which is positioned
      using space-around)
    */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${({ theme }) => theme.colors.Surface};

    ${({ theme: { breakpoints, colors } }) => breakpoints.md`
      top: -8px;
      bottom: unset;
      right: 251px;
      border-top: none;
      border-bottom: 8px solid ${colors.Surface};
   `}
  }

  ${({ theme: { breakpoints } }) => breakpoints.md`
      top: 50px;
      width: 380px;
      bottom: unset;
      left: unset;

      &:before {
        left: 219px;
      }
   `}
`;

export const EmptyMessage = styled.div`
  text-align: center;
  padding: 1rem 0;
  opacity: 0.35;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const InboxListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${INBOX_LIST_HEADER_PADDING}px ${INBOX_LIST_HEADER_PADDING}px;
  height: 40px;
  position: relative;
  border-bottom: 1px solid ${p => p.theme.colors.SurfaceBorder};
`;

export const InboxListTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.5;
  user-select: none;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const InboxListKebab = styled.button`
  appearance: none;
  border: 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  cursor: pointer;
  padding: 0;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }
`;

export const InboxListItems = styled.div`
  overflow-y: auto;
  max-height: 60vh;
`;

export const FetchMoreButton = styled.div`
  text-align: center;
  padding: 0.5rem 0;
`;

export const StyledTabs = styled(Tabs)`
  position: absolute;
  width: calc(100% - (${INBOX_LIST_HEADER_PADDING}px * 2));
  display: flex;
  justify-content: center;

  .count-badge {
    margin-left: 0.5rem;
    height: 0.875rem;
    min-width: 0.875rem;
    font-size: 0.625rem;
  }
`;
