import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryParams } from '../../../../shared/hooks';
import { StyledAppsSwitcher } from './PasswordsSwitcher.styled';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultLoginsMap,
  useCurrentWorkspaceVaultsList,
} from '../../Encryption.hooks';
import {
  SELECT_ALL_VAULTS_QUERY_PARAM,
  VAULTS_LIST_FILTER_QUERY_PARAM,
} from '../../Encryption.constants';
import { getVaultApp, getVaultIri, getVaultItem } from '../../Encryption.utils';
import {
  appPasswordsFilter,
  appPasswordsSort,
  groupPasswordsFilter,
  otherPasswordsFilter,
  otherPasswordsSort,
} from './PasswordsSwitcher.utils';
import { VaultApiType } from '../../Encryption.types';
import { useLocation } from 'react-use';
import { generatePath, matchPath, useNavigate } from 'react-router-dom';
import { PASSWORDS_VAULT_PATHNAME } from '../../../Desktop/Desktop.constants';
import { getShortId } from '../../../../shared/utils/id';
import {
  useCurrentWorkspace,
  useMobileNavigationSidebar,
} from '../../../Workspace/Workspace.hooks';
import { Virtuoso } from 'react-virtuoso';
import { VaultList } from './VaultsList/VaultList';
import {
  ExpandedVaultsType,
  VaultsListSections,
} from './VaultsList/VaultsList.types';
import { useVirtualisedConversations } from './PasswordsSwitcher.hooks';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';
import { SelectVaultContext } from './SelectVault.context';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { usePreviewSegment } from '../../../PreviewSegment/PreviewSegment.hooks';
import { ShareVaultModal } from '../../ShareVault/ShareVaultModal';

export const PasswordsSwitcher: FC = () => {
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { vaults, workspaceVaultsLoading } = useCurrentWorkspaceVaultsList();
  const filterValue = (
    queryParams[VAULTS_LIST_FILTER_QUERY_PARAM] as string
  )?.toLowerCase();
  const { loginsMap } = useCurrentWorkspaceVaultLoginsMap();
  const { mobileNavigationSidebarIsOpen, mobileNavigationSidebarToggle } =
    useMobileNavigationSidebar();
  const [isScrolling, setIsScrolling] = useState(false);
  const [listHideScrollRef] = useHideScrollbarWhenIdle();
  const virtuosoScrollRef = useRef<HTMLElement | Window | null>(null);
  const { selectVaults } = useContext(SelectVaultContext);

  const appsSwitcherRef = useRef<HTMLDivElement>(null);

  const filteredVaults = useMemo(() => {
    if (filterValue) {
      const lowercaseFilterValue = filterValue.toLowerCase();

      return (
        vaults?.filter(vault => {
          const app = getVaultApp(vault);
          const vaultItem = getVaultItem(vault);

          if (app) {
            // search for app/group passwords
            return (
              app?.fullName.toLowerCase().search(lowercaseFilterValue) > -1 ||
              app?.name.toLowerCase().search(lowercaseFilterValue) > -1 ||
              app?.uri.toLowerCase().search(lowercaseFilterValue) > -1 ||
              loginsMap?.[vault.id]?.search(lowercaseFilterValue) > -1
            );
          } else if (vaultItem?.url) {
            // search for manual passwords
            return (
              vaultItem.url.toLowerCase().search(lowercaseFilterValue) > -1 ||
              loginsMap?.[vault.id]?.search(lowercaseFilterValue) > -1
            );
          }
          return false;
        }) || []
      );
    }
    return vaults || [];
  }, [vaults, filterValue, loginsMap]);

  useEffect(() => {
    if (queryParams[SELECT_ALL_VAULTS_QUERY_PARAM]) {
      selectVaults(filteredVaults);
      navigate(
        {
          search: getQueryParamsFrom({
            ...queryParams,
            [SELECT_ALL_VAULTS_QUERY_PARAM]: undefined,
          }),
        },
        { replace: true },
      );
    }
  }, [filteredVaults, navigate, queryParams, selectVaults]);

  const filteredAppsVaults = useMemo(() => {
    return filteredVaults.filter(appPasswordsFilter).sort(appPasswordsSort);
  }, [filteredVaults]);

  const filteredGroupVaults = useMemo(() => {
    return filteredVaults.filter(groupPasswordsFilter).sort(appPasswordsSort);
  }, [filteredVaults]);

  const filteredOtherVaults = useMemo(() => {
    return filteredVaults.filter(otherPasswordsFilter).sort(otherPasswordsSort);
  }, [filteredVaults]);

  const currentVaultId = useMemo(() => {
    let match;
    if (pathname != null) {
      match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    }
    if (!match) {
      return undefined;
    }

    const { vaultId } = match.params;
    return vaultId ? getVaultIri(vaultId) : null;
  }, [pathname]);

  let firstListVault: VaultApiType | undefined;
  if (filteredGroupVaults.length) {
    firstListVault = filteredGroupVaults[0];
  } else if (filteredAppsVaults.length) {
    firstListVault = filteredAppsVaults[0];
  } else if (filteredOtherVaults.length) {
    firstListVault = filteredOtherVaults[0];
  }

  const { isPreviewMode } = usePreviewSegment();

  useEffect(() => {
    if (!currentVaultId && firstListVault && !isPreviewMode) {
      navigate({
        pathname: generatePath(PASSWORDS_VAULT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          vaultId: getShortId(firstListVault.id),
        }),
      });
      if (!mobileNavigationSidebarIsOpen) {
        mobileNavigationSidebarToggle();
      }
    }
  }, [
    firstListVault,
    currentVaultId,
    navigate,
    mobileNavigationSidebarIsOpen,
    mobileNavigationSidebarToggle,
    workspace,
    isPreviewMode,
  ]);

  const [sectionCollapsed, setSectionCollapsed] = useState<
    VaultsListSections[]
  >([]);

  const handleToggle = (name: VaultsListSections) => {
    setSectionCollapsed(prevSections =>
      prevSections.includes(name)
        ? prevSections.filter(el => el !== name)
        : [...prevSections, name],
    );
  };

  const { collapsedSections, virtuosoVaults } = useVirtualisedConversations(
    filteredVaults,
    sectionCollapsed,
  );

  const itemContent = useCallback(
    (index: number, item: ExpandedVaultsType) => (
      <VaultList
        item={item}
        isScrolling={isScrolling}
        collapsedSections={collapsedSections}
        handleToggle={handleToggle}
      />
    ),
    [collapsedSections, isScrolling],
  );

  return (
    <StyledAppsSwitcher
      className="apps-list"
      data-testid="password-switcher"
      ref={appsSwitcherRef}>
      {workspaceVaultsLoading ? (
        <Spinner containerHeight={100} />
      ) : (
        <>
          {!!privateKey && (
            <Virtuoso
              isScrolling={setIsScrolling}
              scrollerRef={(scrollerRef: HTMLElement | Window | null) => {
                listHideScrollRef(scrollerRef as HTMLElement | null);
                virtuosoScrollRef.current = scrollerRef;
              }}
              data={virtuosoVaults}
              itemContent={itemContent}
              overscan={filteredVaults.length}
            />
          )}
        </>
      )}
      {privateKey &&
        (queryParams.shareVaultId ||
          (currentVaultId && queryParams.shareCurrentVault)) && (
          <ShareVaultModal
            vaultId={
              queryParams.shareVaultId
                ? getVaultIri(queryParams.shareVaultId as string)
                : currentVaultId!
            }
            login={
              loginsMap?.[
                queryParams.shareVaultId
                  ? getVaultIri(queryParams.shareVaultId as string)
                  : currentVaultId!
              ]
            }
            onRequestClose={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  shareCurrentVault: undefined,
                  shareVaultId: undefined,
                }),
              })
            }
          />
        )}
    </StyledAppsSwitcher>
  );
};
