import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GRAPHQL_QUERY_PAGE_INFO,
  GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES,
  GRAPHQL_QUERY_PAGINATION_VARIABLES,
} from '../../../../../shared/api/api.constants';
import {
  GetAllInvitedAccountsResponse,
  GetAllInvitedAccountsVariables,
} from '../types/InvitationGraphQl.types';
import { getApolloClient } from '../../../../Api/Api.utils';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { AccountInvitationApiType } from '../types/Invitation.types';
import { getShortId } from '../../../../../shared/utils/id';

export const AccountInvitationFields = `
  _id
  id
  recipients
  groups
  referenceId
  desktopId
  type
  expiresAt
  createdAt
  accountLimit
  accountsCreated
  invitationUri
  workspaceName
  workspaceImageUrl
  accountExists
  slackShareUrl
`;

export const GET_ALL_INVITED_ACCOUNTS_FOR_WORKSPACE = gql`
  query WorkspaceAccountInvitations(
    $type: String
    $referenceId: String
    $desktopId: String
    ${GRAPHQL_QUERY_PAGINATION_VARIABLE_TYPES}
  ) {
    workspaceAccountInvitations(
      type: $type
      referenceId: $referenceId
      desktopId: $desktopId
      ${GRAPHQL_QUERY_PAGINATION_VARIABLES}
    ) {
      ${GRAPHQL_QUERY_PAGE_INFO}
      edges {
        node {
          ${AccountInvitationFields}
        }
      }
    }
  }
`;

export const useGetAllInvitedAccounts = (
  options?: QueryHookOptions<
    GetAllInvitedAccountsResponse,
    GetAllInvitedAccountsVariables
  >,
) =>
  useQuery<GetAllInvitedAccountsResponse, GetAllInvitedAccountsVariables>(
    GET_ALL_INVITED_ACCOUNTS_FOR_WORKSPACE,
    options,
  );

export const getInvitationsGraphQl = (
  workspaceId: string,
  desktopId?: string,
): Promise<AccountInvitationApiType[]> => {
  const apolloClient = getApolloClient();
  return apolloClient!
    .query<GetAllInvitedAccountsResponse, GetAllInvitedAccountsVariables>({
      query: GET_ALL_INVITED_ACCOUNTS_FOR_WORKSPACE,
      variables: {
        type: 'workspace',
        referenceId: getShortId(workspaceId),
        ...(desktopId ? { desktopId: getShortId(desktopId) } : {}),
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => extractNodes(response.data.workspaceAccountInvitations));
};

export const GET_FOR_REGISTRATION_ACCOUNT_INVITATION = gql`
  query AccountInvitation($id: ID!) {
    getForRegistrationAccountInvitation(id: $id) {
      ${AccountInvitationFields}
    }
  }
`;

export interface GetForRegistrationAccountInvitationVariables {
  id: string;
}

export interface GetForRegistrationAccountInvitationResponse {
  getForRegistrationAccountInvitation: AccountInvitationApiType;
}

export const useGetForRegistrationAccountInvitation = (
  options: QueryHookOptions<
    GetForRegistrationAccountInvitationResponse,
    GetForRegistrationAccountInvitationVariables
  >,
) =>
  useQuery<
    GetForRegistrationAccountInvitationResponse,
    GetForRegistrationAccountInvitationVariables
  >(GET_FOR_REGISTRATION_ACCOUNT_INVITATION, {
    ...options,
    fetchPolicy: 'network-only',
  });

export const getInvitationGraphQl = (
  id: string,
): Promise<AccountInvitationApiType> => {
  const apolloClient = getApolloClient();
  return apolloClient!
    .query<
      GetForRegistrationAccountInvitationResponse,
      GetForRegistrationAccountInvitationVariables
    >({
      query: GET_FOR_REGISTRATION_ACCOUNT_INVITATION,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data.getForRegistrationAccountInvitation);
};
