import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { HeadTitle } from '../../../shared/components/HeadTitle';
import { GlobalTranslation } from '../../Intl/i18n';
import { ThemeType, useTheme } from '../../Theme';
import {
  FailedServiceAuthInner,
  FailedServiceAuthTitle,
  FailedServiceAuthDescription,
  AppLogo,
  FailedServiceAuthPageBodyGlobalStyles,
  FailedServiceAuthContent,
} from './FailedServiceAuthPage.styled';
import DesktopLogo from '../../../shared/assets/images/logo.svg';
import DesktopLogoLight from '../../../shared/assets/images/logo-light.svg';
import { ExternalServiceAuthTranslation } from '../ExternalServiceAuthContent/i18n';

export const FailedServiceAuthPage: FC = () => {
  const intl = useIntl();
  const { themeType } = useTheme();

  const onClose = () => {
    if (window.close) {
      window.close();
    }
  };

  const currentThemeLogo =
    themeType === ThemeType.dark ? DesktopLogoLight : DesktopLogo;

  return (
    <>
      <HeadTitle
        translationId={
          ExternalServiceAuthTranslation.authorizationFailedHeadTitle
        }
      />
      <FailedServiceAuthPageBodyGlobalStyles />
      <AppLogo>
        <img
          src={currentThemeLogo}
          alt={intl.formatMessage({ id: GlobalTranslation.logoAlt })}
          data-testid="logo-img"
        />
      </AppLogo>

      <FailedServiceAuthContent>
        <FailedServiceAuthInner>
          <FailedServiceAuthTitle>
            <FormattedMessage
              id={ExternalServiceAuthTranslation.externalAuthServiceTitle}
            />
          </FailedServiceAuthTitle>
          <FailedServiceAuthDescription>
            <FormattedMessage
              id={ExternalServiceAuthTranslation.externalAuthServiceDescription}
            />
          </FailedServiceAuthDescription>
          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            type="button"
            onClick={onClose}
            fullWidth>
            <FormattedMessage
              id={
                ExternalServiceAuthTranslation.externalAuthServiceGoBackButton
              }
            />
          </Button>
        </FailedServiceAuthInner>
      </FailedServiceAuthContent>
    </>
  );
};
