import { ApiConnection } from '../../shared/api/api.types';
import {
  GRAPHQL_TYPENAME_LINK_CONNECTION,
  GRAPHQL_TYPENAME_LINK_EDGE,
} from './Link.constants';
import { LinkApiType, RecentLinksApiType } from './Link.types';
import {
  DocumentType,
  getEntityMetadataType,
} from '../Preview/DocumentPreview';
import { getShortId } from '../../shared/utils/id';

export const getLinkHostname = (
  link: LinkApiType,
  useOriginUrl?: boolean,
): string => {
  try {
    const hostname = useOriginUrl
      ? new URL(link.data?.url as string).hostname
      : new URL((link.data?.realUrl as string) || (link.data?.url as string))
          ?.hostname;

    return hostname.replace('www.', '');
  } catch {
    return '';
  }
};

export const getLinkTitle = (link: LinkApiType) => {
  // NOTE: The following hard coded logic is temporary
  // until google api is implemented in backend
  if (!link) {
    return '';
  }

  const overrideTitles = [
    'Google Sheets: Sign-in',
    'Google Docs: Sign-in',
    'Google Slides: Sign-in',
    'Google Drive: Sign-in',
  ];

  const fileName =
    link.data.metadata &&
    'name' in link.data.metadata &&
    link.data.metadata.name;

  if (
    !fileName &&
    !link.customTitle &&
    overrideTitles.includes(link.data.title!)
  ) {
    const documentMetaType: DocumentType = getEntityMetadataType(link);
    switch (documentMetaType) {
      case DocumentType.GOOGLE_SPREADSHEETS:
        return 'Google Sheet';
      case DocumentType.GOOGLE_DOCUMENT:
        return 'Google Document';
      case DocumentType.GOOGLE_PRESENTATION:
        return 'Google Slide';
      case DocumentType.GOOGLE_DRIVE:
        return 'Google Drive file';
      case DocumentType.GOOGLE_COMMON:
        return 'Google Docs file';
    }
  }

  return link.customTitle || fileName || link.data.title || link.data.url;
};

export const getRecentLinks = (
  data: { getRecentLinks: RecentLinksApiType[] } | undefined,
): { links: ApiConnection<LinkApiType> } | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    links: {
      edges: data.getRecentLinks.map(recentLink => ({
        node: {
          ...recentLink.link,
          folder: null,
        },
        __typename: GRAPHQL_TYPENAME_LINK_EDGE,
      })),
      __typename: GRAPHQL_TYPENAME_LINK_CONNECTION,
    },
  };
};

export const getTagId = (id: string) => {
  return '/tags/' + getShortId(id);
};

export const compareStringsWithLowerCase = (first: string, second: string) => {
  return first.toLowerCase() === second.toLowerCase();
};
