import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LockScreenErrorMessage,
  LockScreenPasswordInput,
  LockScreenPasswordInputWrapper,
  LockScreenUnlockButton,
  MasterPasswordLockForm,
  PasswordInputHint,
} from './MasterPasswordLockScreenOnMakeOwner.styled';
import { EncryptionTranslation } from '../../i18n';

export interface MasterPasswordLockScreenOnMakeOwnerProps {
  decryptCurrentAccountPrivateKey: (_: string) => Promise<CryptoKey>;
  onUnlock?: () => void;
}

export const MasterPasswordLockScreenOnMakeOwner: FC<
  MasterPasswordLockScreenOnMakeOwnerProps
> = ({ decryptCurrentAccountPrivateKey, onUnlock }) => {
  const [inputState, setInputState] = useState('');
  const [decrypting, setDecrypting] = useState(false);
  const [showError, setShowError] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setShowError(false);
  }, [inputState]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDecrypting(true);

      decryptCurrentAccountPrivateKey(inputState)
        .then(() => onUnlock?.())
        .catch(() => {
          setShowError(true);
          setDecrypting(false);
        });
    },
    [inputState, decryptCurrentAccountPrivateKey, onUnlock],
  );

  return (
    <MasterPasswordLockForm
      onSubmit={handleSubmit}
      data-testid="make-owner-confirm-form">
      <LockScreenPasswordInputWrapper>
        <span data-testid="description">
          <FormattedMessage
            tagName={PasswordInputHint}
            id={EncryptionTranslation.masterPasswordUnlockOnMakeOwnerInputTitle}
          />
        </span>
        <LockScreenPasswordInput
          autoFocus
          placeholder={intl.formatMessage({
            id: EncryptionTranslation.masterPasswordUnlockOnMakeOwnerInputPlaceholder,
          })}
          onChange={e => setInputState(e.target.value)}
          data-testid="make-owner-password-input"
        />
      </LockScreenPasswordInputWrapper>
      {showError && (
        <span data-testid="error-message">
          <FormattedMessage
            tagName={LockScreenErrorMessage}
            id={
              EncryptionTranslation.masterPasswordUnlockOnMakeOwnerScreenErrorMessage
            }
          />
        </span>
      )}
      <LockScreenUnlockButton
        type="submit"
        disabled={decrypting}
        data-testid="password-confirm-button">
        <FormattedMessage
          id={EncryptionTranslation.masterPasswordUnlockOnMakeOwnerButtonText}
        />
      </LockScreenUnlockButton>
    </MasterPasswordLockForm>
  );
};
