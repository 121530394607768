import styled from 'styled-components';

export const StyledSsoApp = styled.div`
  background-color: ${p => p.theme.colors.Surface};
  padding: 16px;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    margin-right: 20px;
  `}
`;

export const StyledSsoStatus = styled.div<{ enabled: boolean }>`
  font-size: 0.75rem;
  font-family: ${p => p.theme.fonts.Semibold};
  line-height: 1.2;
  margin-top: 0.25rem;
  color: ${p =>
    p.enabled ? p.theme.colors.Primary : p.theme.colors.CriticalLight};
`;
