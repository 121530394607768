import React, { FC, memo, PropsWithChildren } from 'react';
import { RestApiClient } from './RestApiClient';
import { RestApiClientProviderContext } from './RestApiClient.context';

interface RestApiClientProviderProps extends PropsWithChildren {
  client: RestApiClient;
}

export const RestApiClientProvider: FC<RestApiClientProviderProps> = memo(
  ({ children, client }) => {
    return (
      <RestApiClientProviderContext.Provider
        value={{
          restApiClient: client,
        }}>
        {children}
      </RestApiClientProviderContext.Provider>
    );
  },
);
