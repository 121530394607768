import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { UserListItem } from './UserListItem/UserListItem';
import { PendingUserListItem } from './PendingUserListItem/PendingUserListItem';
import { Spinner } from '../../../../shared/components/Spinner';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { KeyboardArrowRightIcon } from '../../../../shared/icons';
import { InviteUserModal } from '../../UserModals/InviteUserModal';
import { AccountWithCountsApiType } from '../../User.types';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderTitle,
  AdminList,
  AdminListEmptyMessage,
  AdminListItem,
  AdminListItemLink,
  SectionHeader,
  SectionHeaderTitle,
} from '../../../Admin';
import { UserTranslation } from '../../i18n';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { Search } from '../../../../shared/components/Search';
import { InputSize } from '../../../../shared/components/Input';
import { LoadMoreButton } from '../../../../shared/components/LoadMore';
import { GlobalTranslation } from '../../../Intl/i18n';
import { Invitations } from '../InvitationView/InvitationView.styled';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { isBotAccount } from '../../User.utils';
import { useAccountsContext } from '../../../Account';
import { sortAccountsAlphabeticalyByRole } from './UserList.utils';
import { useStaticLoadMore } from '../../../../shared/hooks/useStaticLoadMore';
import { getShortId } from '../../../../shared/utils/id';
import { useCurrentRouteUrl } from '../../../../shared/hooks/router.hooks';
import { AccountInvitationApiType } from '../../../Invitation/data/Invitation/types/Invitation.types';
import { useInvitationsRepository } from '../../../Invitation/data/Invitation/Invitation.repositories';

const FILTER_QUERY_PARAM_NAME = 'filter';
const INVITE_USER_MODAL_QUERY_PARAM_NAME = 'inviteUser';
const ACCOUNTS_PAGE_SIZE = 10;
const INVITATIONS_PAGE_SIZE = 10;
export const INVITES_PAGE_SIZE = 5;

export const UsersList: FC = () => {
  const url = useCurrentRouteUrl();
  const {
    permissions: { canInviteUsers },
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const isMobile = useMobile();
  const { accountsWithAvailability, loading: loadingAccounts } =
    useAccountsContext();

  const { formatMessage } = useIntl();

  const {
    invitations,
    loading: loadingInvitations,
    fetchInvitations,
  } = useInvitationsRepository({});

  const filterNameParam = queryParams[FILTER_QUERY_PARAM_NAME] as string;

  const accounts = useMemo(
    () =>
      Object.values(accountsWithAvailability).filter(
        user => !isBotAccount(user),
      ),
    [accountsWithAvailability],
  );

  const usersData = useMemo(() => {
    const sortedData = sortAccountsAlphabeticalyByRole(accounts);

    return filterNameParam
      ? sortedData.filter(user => {
          return `${user.firstName} ${user.lastName} ${user.email}`
            .toLowerCase()
            .includes(filterNameParam.toLowerCase());
        })
      : sortedData;
  }, [accounts, filterNameParam]);

  const didSendInvitation = useCallback(() => {
    fetchInvitations();
    navigate({});
  }, [navigate, fetchInvitations]);

  const {
    dataToDisplay: accountsToDisplay,
    isEndReached: accountsEndReached,
    addMore: accountsAddMore,
  } = useStaticLoadMore(usersData, ACCOUNTS_PAGE_SIZE);

  const {
    dataToDisplay: invitationsToDisplay,
    isEndReached: invitationsEndReached,
    addMore: invitationsAddMore,
  } = useStaticLoadMore(invitations, INVITATIONS_PAGE_SIZE);

  return (
    <>
      <AdminHeader data-testid="header">
        {!isMobile && (
          <AdminHeaderTitle data-testid="title">
            <FormattedHTMLMessage
              id={UserTranslation.adminListHeaderTitle}
              values={{
                count: accounts.length,
              }}
            />
          </AdminHeaderTitle>
        )}
        <AdminHeaderControls data-testid="controls">
          <Search
            queryParamName={FILTER_QUERY_PARAM_NAME}
            size={InputSize.sm}
          />
          {canInviteUsers && (
            <>
              <Button
                size={ButtonSize.sm}
                mode={ButtonMode.primary}
                onClick={() =>
                  navigate({
                    search: getQueryParamsFrom({
                      ...queryParams,
                      [INVITE_USER_MODAL_QUERY_PARAM_NAME]: true,
                    }),
                  })
                }
                data-tooltip-id="global-tooltip"
                data-tooltip-content={formatMessage({
                  id: UserTranslation.tooltipInviteMembers,
                })}
                data-tooltip-place={TooltipPlace.bottom}
                data-testid="invite-user-btn"
                data-tourid="admin-invite-user-button">
                <FormattedMessage
                  id={UserTranslation.adminListInviteUserButton}
                />
              </Button>
            </>
          )}
        </AdminHeaderControls>
      </AdminHeader>

      {loadingAccounts ? (
        <Spinner />
      ) : accountsToDisplay.length ? (
        <AdminList data-testid="users">
          <>
            {accountsToDisplay.map((account: AccountWithCountsApiType) => (
              <AdminListItem
                key={account.id}
                data-testid="list-item"
                data-entityid={account.id}>
                <AdminListItemLink
                  to={`${url}/${getShortId(account.id)}`}
                  data-testid="user-link">
                  <UserListItem account={account}>
                    <ButtonWithIcon
                      icon={KeyboardArrowRightIcon}
                      data-testid="arrow-button"
                    />
                  </UserListItem>
                </AdminListItemLink>
              </AdminListItem>
            ))}
            {!accountsEndReached && !filterNameParam && (
              <LoadMoreButton
                size={ButtonSize.md}
                mode={ButtonMode.secondary}
                onLoadMore={accountsAddMore}
                style={{ marginTop: 10 }}>
                <FormattedMessage id={GlobalTranslation.loadMoreButton} />
              </LoadMoreButton>
            )}
          </>
        </AdminList>
      ) : (
        <AdminListEmptyMessage>
          <FormattedMessage id={UserTranslation.adminListEmptyMessage} />
        </AdminListEmptyMessage>
      )}

      <Invitations data-testid="invites">
        <SectionHeader data-testid="invites-header">
          <SectionHeaderTitle data-testid="invites-title">
            <FormattedMessage
              id={UserTranslation.adminListPendingInvitesHeader}
            />
          </SectionHeaderTitle>
        </SectionHeader>

        {loadingInvitations ? (
          <Spinner />
        ) : (
          <AdminList data-testid="invites-list">
            {invitationsToDisplay.length ? (
              <>
                {invitationsToDisplay.map(
                  (invite: AccountInvitationApiType) => (
                    <AdminListItem key={invite.id} data-testid="list-item">
                      <AdminListItemLink
                        to={`${url}/invitation/${invite._id}`}
                        data-testid="invitation-link">
                        <PendingUserListItem invite={invite}>
                          <ButtonWithIcon
                            icon={KeyboardArrowRightIcon}
                            data-testid="arrow-button"
                          />
                        </PendingUserListItem>
                      </AdminListItemLink>
                    </AdminListItem>
                  ),
                )}
                {!invitationsEndReached && (
                  <LoadMoreButton
                    size={ButtonSize.md}
                    mode={ButtonMode.secondary}
                    onLoadMore={invitationsAddMore}
                    style={{ marginTop: 10 }}>
                    <FormattedMessage id={GlobalTranslation.loadMoreButton} />
                  </LoadMoreButton>
                )}
              </>
            ) : (
              <AdminListEmptyMessage>
                <FormattedMessage
                  id={UserTranslation.adminListPendingEmptyMessage}
                />
              </AdminListEmptyMessage>
            )}
          </AdminList>
        )}
      </Invitations>

      {queryParams[INVITE_USER_MODAL_QUERY_PARAM_NAME] && (
        <InviteUserModal
          visible={true}
          onDone={didSendInvitation}
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                [INVITE_USER_MODAL_QUERY_PARAM_NAME]: undefined,
              }),
            })
          }
        />
      )}
    </>
  );
};
