import React, { FC, useCallback, useMemo } from 'react';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { EncryptionTranslation } from '../../i18n';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import {
  StyledControlsWrapper,
  StyledDeletePasswordButton,
  StyledEditPasswordButton,
  StyledSharePasswordButtonWrapper,
} from './PasswordView.styled';
import { DeleteIcon, EditIcon, TeamIcon } from '../../../../shared/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useNativeWrapper } from '../../../NativeWrapper';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { PaymentPlan } from '../../../Billing/Billing.types';

interface PasswordViewControlsProps {
  disableShareButton: boolean;
  displayButtons?: ('edit' | 'share' | 'unshare' | 'delete')[];
  disableControls: boolean;
}

const ICON_SIZE = 16;

export const PasswordViewControls: FC<PasswordViewControlsProps> = ({
  disableShareButton,
  displayButtons = ['edit', 'share', 'delete'],
  disableControls,
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();
  const isFreeWorkspace = workspace.type === PaymentPlan.FREE;
  const isMobile = useMobile();

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const displayButtonsSet = useMemo(() => {
    return new Set(displayButtons);
  }, [displayButtons]);

  const handleEditPasswordClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editCurrentVault: true,
      }),
    });
  }, [navigate, queryParams]);

  const handleSharePasswordClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        shareCurrentVault: true,
      }),
    });
  }, [navigate, queryParams]);

  const handleDeletePasswordClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        deleteCurrentVault: true,
      }),
    });
  }, [navigate, queryParams]);

  const unshareDeletePasswordClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        unshareCurrentVault: true,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <StyledControlsWrapper
      data-testid="password-view-controls"
      isMobile={isMobile}>
      {displayButtonsSet.has('edit') && (
        <StyledEditPasswordButton
          data-testid="edit-password"
          iconFirst
          fullWidth
          size={ButtonSize.sm}
          mode={ButtonMode.secondary}
          disabled={disableControls}
          icon={() => <EditIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={handleEditPasswordClick}>
          {!isMobile && (
            <FormattedMessage id={EncryptionTranslation.passwordControlsEdit} />
          )}
        </StyledEditPasswordButton>
      )}
      {displayButtonsSet.has('share') && (
        <StyledSharePasswordButtonWrapper
          data-tooltip-content={
            !isNativeWrapperAvailable && disableShareButton
              ? formatMessage({
                  id: isFreeWorkspace
                    ? EncryptionTranslation.passwordControlsSharingDisabledNonPro
                    : EncryptionTranslation.passwordControlsSharingDisabledNonAdmin,
                })
              : ''
          }
          data-tooltip-place={TooltipPlace.bottom}>
          <StyledEditPasswordButton
            data-testid="share-password"
            iconFirst
            fullWidth
            size={ButtonSize.sm}
            mode={ButtonMode.secondary}
            disabled={disableShareButton || disableControls}
            icon={() => <TeamIcon width={ICON_SIZE} height={ICON_SIZE} />}
            onClick={handleSharePasswordClick}>
            {!isMobile && (
              <FormattedMessage
                id={EncryptionTranslation.passwordControlsShare}
              />
            )}
          </StyledEditPasswordButton>
        </StyledSharePasswordButtonWrapper>
      )}
      {displayButtonsSet.has('unshare') && (
        <StyledDeletePasswordButton
          data-testid="delete-password"
          iconFirst
          fullWidth
          size={ButtonSize.sm}
          mode={ButtonMode.secondary}
          icon={() => <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={unshareDeletePasswordClick}>
          <FormattedMessage id={EncryptionTranslation.passwordControlsDelete} />
        </StyledDeletePasswordButton>
      )}
      {displayButtonsSet.has('delete') && (
        <StyledDeletePasswordButton
          data-testid="delete-password"
          iconFirst
          fullWidth
          size={ButtonSize.sm}
          mode={ButtonMode.secondary}
          icon={() => <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />}
          onClick={handleDeletePasswordClick}>
          {!isMobile && (
            <FormattedMessage
              id={EncryptionTranslation.passwordControlsDelete}
            />
          )}
        </StyledDeletePasswordButton>
      )}
    </StyledControlsWrapper>
  );
};
