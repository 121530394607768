import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChatTranslation } from '../../../../i18n';
import { ThreadSpacerWithCounter } from '../ChatMessageThread.styled';

interface ChatMessageThreadSiderSpacerWithCounterProps {
  count: number;
}

export const ChatMessageThreadSiderSpacerWithCounter: FC<ChatMessageThreadSiderSpacerWithCounterProps> =
  memo(({ count }) => {
    return (
      <ThreadSpacerWithCounter>
        <FormattedMessage
          id={ChatTranslation.threadCount}
          values={{
            count,
          }}
          tagName="p"
        />
      </ThreadSpacerWithCounter>
    );
  });
