import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../i18n';
import {
  getDataFromFile,
  getFileSize,
  getFileType,
} from './ImportPasswords.utils';
import {
  ALLOWED_FILE_TYPES,
  INPUT_UPLOAD_FILE_ID,
} from './ImportPasswords.constants';
import { useCreateVaultEntities } from './ImportPasswords.hooks';
import { FileTypeEnum, ProcessedFileResult } from './ImportPasswords.types';
import {
  ImportPasswordsControls,
  ImportPasswordsFileInfo,
  ImportPasswordsFileInfoWrapper,
  ImportPasswordsImportingMessage,
  StyledImportPasswords,
  StyledInput,
  StyledSubTitle,
  UploadButton,
  UploadButtonWrapper,
} from './ImportPasswords.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { CopyIcon } from '../../../shared/icons';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessHTMLMessage,
} from '../../../shared/components/Toast';
import {
  useCreateMultipleVaultItems,
  useCurrentWorkspaceAccountPrivateKey,
} from '../Encryption.hooks';
import { VaultItemForBatchCreation } from '../Encryption.mutations';
import { appEnv } from '../../../appEnv';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { MasterPasswordLockScreen } from '../MasterPasswordLockScreen';

interface ImportPasswordsProps {
  onDone: () => void;
}

export const ImportPasswords: FC<ImportPasswordsProps> = ({ onDone }) => {
  const [encryptedVaultEntities, setEncryptedVaultEntities] =
    useState<VaultItemForBatchCreation[]>();
  const createVaultEntities = useCreateVaultEntities();
  const [createMultipleVaultItems] = useCreateMultipleVaultItems();
  const [uploadedFileInfo, setUploadedFileInfo] = useState<{
    name?: string;
    size?: string;
  }>();
  const [isImporting, setIsImporting] = useState(false);
  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && !event.target.files![0]) {
        return null;
      }

      const reader = new FileReader();
      const target = event.target;
      const file = event.target.files![0];
      const fileName = file?.name;
      const fileSize = getFileSize(file);
      const fileType = getFileType(file);

      reader.onload = () => {
        if (fileType && reader.result) {
          if (!ALLOWED_FILE_TYPES.includes(fileType.toLowerCase())) {
            showToastErrorMessage(
              EncryptionTranslation.passwordsWrongFileTypeErrorMessage,
            );
            return null;
          }

          const { data, errorsCount, isFileValid } = getDataFromFile(
            reader.result as string,
            fileType.toLowerCase() as FileTypeEnum,
          );

          if (!isFileValid) {
            showToastErrorMessage(
              EncryptionTranslation.passwordsBadFileErrorMessage,
            );
            return null;
          }

          if (data) {
            if (
              data.length > Number(appEnv.IMPORT_PASSWORDS_ROWS_IN_FILE_LIMIT)
            ) {
              showToastErrorMessage(
                EncryptionTranslation.passwordsRowsLimitErrorMessage,
              );
              return null;
            }

            setUploadedFileInfo({
              name: fileName,
              size: fileSize,
            });
            createVaultEntities(data as ProcessedFileResult[])
              .then(res => {
                if (errorsCount) {
                  showToastErrorMessage(
                    EncryptionTranslation.passwordsWrongRowsErrorMessage,
                    {
                      errorsInRowsCount: data.length - res.length,
                      errorsInColumnsCount: errorsCount,
                    },
                  );
                }
                // Workaround to allow upload same file after removing
                target.value = '';
                setEncryptedVaultEntities(res as VaultItemForBatchCreation[]);
              })
              .catch(() =>
                showToastErrorMessage(
                  EncryptionTranslation.importPasswordsErrorInFile,
                ),
              );
          }
        }
      };

      if (file) {
        reader.readAsText(file);
      }
    },
    [createVaultEntities],
  );

  const [{ isDragging }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item) {
      if (handleFileUpload) {
        // @ts-ignore
        handleFileUpload({ target: item });
      }
    },
    collect: monitor => ({
      isDragging: monitor.canDrop(),
    }),
  });

  const handleDeleteFile = useCallback(() => {
    setEncryptedVaultEntities(undefined);
    setUploadedFileInfo(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    if (encryptedVaultEntities) {
      createMultipleVaultItems({
        variables: { input: { vaultItems: encryptedVaultEntities } },
      })
        .then(() => {
          showToastSuccessHTMLMessage(
            EncryptionTranslation.importPasswordsSuccessImportMessage,
            {
              savedVaultsCount: encryptedVaultEntities.length,
            },
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    }
    setIsImporting(true);
  }, [encryptedVaultEntities, createMultipleVaultItems]);

  if (!privateKey) {
    return (
      <MasterPasswordLockScreen
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  if (isImporting) {
    return (
      <ImportPasswordsImportingMessage>
        <FormattedMessage
          tagName="div"
          id={EncryptionTranslation.importPasswordsImportingMessage}
        />
        <Button
          size={ButtonSize.md}
          mode={ButtonMode.secondary}
          onClick={onDone}>
          <FormattedMessage
            id={EncryptionTranslation.importPasswordsImportingOkButton}
          />
        </Button>
      </ImportPasswordsImportingMessage>
    );
  }

  return (
    <StyledImportPasswords ref={drop}>
      <StyledSubTitle>
        <FormattedHTMLMessage
          id={EncryptionTranslation.importPasswordsModalSubTitle}
        />
      </StyledSubTitle>
      <StyledInput
        type="file"
        id={INPUT_UPLOAD_FILE_ID}
        onChange={handleFileUpload}
      />
      {uploadedFileInfo ? (
        <ImportPasswordsFileInfoWrapper>
          <CopyIcon />
          <ImportPasswordsFileInfo>
            <strong>{uploadedFileInfo.name}</strong>
            <span>{uploadedFileInfo.size} KB</span>
          </ImportPasswordsFileInfo>
        </ImportPasswordsFileInfoWrapper>
      ) : (
        <UploadButtonWrapper>
          <UploadButton
            isDragging={isDragging}
            tabIndex={0}
            as="label"
            htmlFor={INPUT_UPLOAD_FILE_ID}
            role="button">
            <FormattedHTMLMessage
              id={
                isDragging
                  ? EncryptionTranslation.importPasswordsModalUploadButtonOnDragging
                  : EncryptionTranslation.importPasswordsModalUploadButton
              }
            />
          </UploadButton>
        </UploadButtonWrapper>
      )}
      <ImportPasswordsControls>
        <a
          href={appEnv.SUPPORT_PASSWORD_IMPORT_HELP_URL}
          rel="noopener noreferrer"
          target="_blank">
          <FormattedHTMLMessage
            tagName="span"
            id={EncryptionTranslation.importPasswordsControlsHelpLink}
          />
        </a>
        <div>
          <Button
            disabled={!uploadedFileInfo}
            data-testid="delete-file-button"
            mode={ButtonMode.danger}
            size={ButtonSize.md}
            onClick={handleDeleteFile}>
            <FormattedHTMLMessage
              id={EncryptionTranslation.importPasswordsControlsRemoveButton}
            />
          </Button>
          <Button
            disabled={!uploadedFileInfo}
            data-testid="upload-file-button"
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={handleSubmit}>
            <FormattedHTMLMessage
              id={EncryptionTranslation.importPasswordsControlsSubmitButton}
            />
          </Button>
        </div>
      </ImportPasswordsControls>
    </StyledImportPasswords>
  );
};
