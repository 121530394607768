import React, { FC } from 'react';
import { TeamIcon, UserIcon } from '../../../shared/icons';
import { isShared } from '../../Desktop/Desktop.utils';
import { DesktopIcon, ImageWrapper } from './ConferenceAvatar.styled';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';

interface ConferenceAvatarDesktopProps {
  onClick: () => void;
  avatarSize: string;
  desktop: DesktopApiType;
}

export const ConferenceAvatarDesktop: FC<ConferenceAvatarDesktopProps> = ({
  avatarSize,
  desktop,
  onClick,
}) => {
  const isSharedDesktop = isShared(desktop);
  return (
    <ImageWrapper onClick={onClick} avatarSize={avatarSize}>
      <DesktopIcon sharedDesktop={desktop.shareable}>
        {(isSharedDesktop && desktop.shareable) || !desktop.shareable ? (
          <TeamIcon width={22} height={20} />
        ) : desktop.shareable ? (
          <UserIcon width={20} height={20} />
        ) : null}
      </DesktopIcon>
    </ImageWrapper>
  );
};
