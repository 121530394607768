import React, { FC, PropsWithChildren, useReducer, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DRAG_ACTIVATION_DISTANCE } from '../../Drag/Drag.constants';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { WorkspaceDndContext } from './WorkspaceDnd.context';
import { DesktopType } from '../../Desktop/Desktop.constants';
import { getDesktopIri } from '../../Desktop/Desktop.utils';
import { APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID } from '../../Desktop/Views/AppsLayoutGrid/AppsLayoutGrid';

export const WorkspaceDndProvider: FC<PropsWithChildren> = ({ children }) => {
  const dndSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: DRAG_ACTIVATION_DISTANCE,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [desktopSortedHandlers, setDesktopSortedHandler] = useReducer(
    (
      prevState: Record<DesktopType, any>,
      action: { id: DesktopType; handler: any },
    ) => {
      const { id, handler } = action;
      return { ...prevState, [id]: handler };
    },
    {} as Record<DesktopType, any>,
  );

  const [appDroppedToDesktopHandler, setAppDroppedToDesktopHandler] =
    useReducer(
      (
        prevState: Record<string, any>,
        action: { desktopId: string; handler: any },
      ) => {
        const { desktopId, handler } = action;
        return { ...prevState, [desktopId]: handler };
      },
      {} as Record<string, any>,
    );

  const [appSortedHandler, setAppSortedHandler] = useState<
    (e: DragEndEvent) => void
  >(() => {});

  return (
    <DndContext
      sensors={dndSensors}
      modifiers={[]}
      onDragEnd={e => {
        const activeId = e.active?.id as string;
        const overId = e.over?.id as string;
        const sortableContainerId =
          e.over?.data?.current?.sortable?.containerId;

        if (
          activeId?.startsWith('/desktop-apps/') &&
          [
            DesktopType.INTERNAL,
            DesktopType.SHARED,
            DesktopType.PRIVATE,
          ].includes(sortableContainerId) &&
          overId
        ) {
          appDroppedToDesktopHandler?.[getDesktopIri(overId)]?.(e);
          return;
        }

        if (
          activeId?.startsWith('/desktop-apps/') &&
          sortableContainerId === APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID
        ) {
          appSortedHandler?.(e);
          return;
        }

        if (
          !activeId?.startsWith('/desktop-apps/') &&
          [
            DesktopType.INTERNAL,
            DesktopType.SHARED,
            DesktopType.PRIVATE,
          ].includes(sortableContainerId)
        ) {
          desktopSortedHandlers?.[sortableContainerId as DesktopType]?.(e);
          return;
        }
      }}>
      <WorkspaceDndContext.Provider
        value={{
          setAppDroppedToDesktopHandler,
          setDesktopSortedHandler,
          setAppSortedHandler,
        }}>
        {children}
      </WorkspaceDndContext.Provider>
    </DndContext>
  );
};
