import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  CountBadge,
  PositionType,
} from '../../../../shared/components/CountBadge';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../shared/hooks';
import { appendQueryParamsToPath } from '../../../../shared/utils/url.utils';
import { useUnreadCounterGetter } from '../../../Counters';
import { WorkspaceTranslation } from '../../i18n';
import { useMobileNavigationSidebar } from '../../Workspace.hooks';
import { WorkspaceApiType } from '../../Workspace.types';
import { WorkspaceIcon } from '../../WorkspaceIcon';
import {
  AdditionalNotificationsCounter,
  AdditionalNotificationsIndicator,
  OtherWorkspaceContainer,
  StyledOtherWorkspaceNotifications,
} from './OtherWorkspaceNotifications.styled';
import { getShortId } from '../../../../shared/utils/id';

type OtherWorkspaceNotificationsProps = {
  currentWorkspaceId: string;
  workspaces: WorkspaceApiType[];
  onClickAdditionalNotifications: () => void;
};

const MAX_WORKSPACE_NOTIFICATIONS = 3;

export const OtherWorkspaceNotifications: FC<
  OtherWorkspaceNotificationsProps
> = ({
  currentWorkspaceId,
  workspaces,
  onClickAdditionalNotifications = () => {},
}) => {
  const getUnreadCounter = useUnreadCounterGetter();
  const navigate = useNavigate();
  const theme = useTheme();
  const queryParams = useQueryParams();
  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();
  const { formatMessage } = useIntl();

  const otherWorkspacesArray = useMemo(
    () =>
      workspaces.filter(
        workspace =>
          workspace.id !== currentWorkspaceId && getUnreadCounter(workspace.id),
      ),
    [currentWorkspaceId, getUnreadCounter, workspaces],
  );

  const additionalWorkspacesNotificationsArray = otherWorkspacesArray.filter(
    (workspace, index) =>
      index >= MAX_WORKSPACE_NOTIFICATIONS &&
      workspace.id !== currentWorkspaceId,
  );

  const additionalNotificationsCount = useMemo(
    () =>
      additionalWorkspacesNotificationsArray
        .map(workspace => getUnreadCounter(workspace.id))
        .reduce((prev, current) => prev + current, 0),
    [additionalWorkspacesNotificationsArray, getUnreadCounter],
  );

  return (
    <StyledOtherWorkspaceNotifications data-testid="other-workspace-notifications">
      {otherWorkspacesArray
        .filter((workspace, index) => index < MAX_WORKSPACE_NOTIFICATIONS)
        .map((workspace, index) => {
          const workspaceUnread = getUnreadCounter(workspace.id);
          if (workspace.id === currentWorkspaceId || !workspaceUnread)
            return null;

          return (
            <OtherWorkspaceContainer
              data-testid="workspace-item"
              data-tooltip-place={TooltipPlace.bottom}
              data-tooltip-id="global-tooltip"
              data-tooltip-content={workspace.name}
              key={index}
              onClick={() => {
                mobileNavigationSidebarClose();
                navigate(
                  `/workspace/${getShortId(
                    workspace.id,
                  )}${appendQueryParamsToPath(queryParams)}`,
                );
              }}>
              <WorkspaceIcon
                workspace={workspace}
                size={20}
                backgroundColor={theme.colors.OnSurfaceLightEmphasis}
              />
              <CountBadge
                count={workspaceUnread}
                positionMode={PositionType.absolute}
                offsetRight={-1}
                offsetTop={-1}
                data-testid="workspace-item-notifications-counter"
                borderColor={theme.colors.IdentityBar}
              />
            </OtherWorkspaceContainer>
          );
        })}
      {additionalWorkspacesNotificationsArray &&
        !!additionalNotificationsCount && (
          <OtherWorkspaceContainer
            onClick={onClickAdditionalNotifications}
            data-testid="additional-workspaces-item"
            data-tooltip-content={formatMessage(
              {
                id: WorkspaceTranslation.workspaceSelectorAdditionalNotifications,
              },
              {
                notificationCount: additionalNotificationsCount,
                workspaceCount: additionalWorkspacesNotificationsArray.length,
              },
            )}
            data-tooltip-place={TooltipPlace.bottom}>
            <AdditionalNotificationsCounter>
              {`+${additionalWorkspacesNotificationsArray.length}`}
            </AdditionalNotificationsCounter>
            <AdditionalNotificationsIndicator />
          </OtherWorkspaceContainer>
        )}
    </StyledOtherWorkspaceNotifications>
  );
};
