import styled from 'styled-components';

export const StyledAccountPopover = styled.div`
  width: 16rem;
  background-color: ${p => p.theme.colors.Surface};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.colors.BorderLight};
`;

export const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0.25rem;
  align-items: center;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  border-radius: 0.25rem 0.25rem 0 0;

  .avatar {
    margin: 0.25rem 0;
  }

  strong {
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.fonts.Semibold};
    line-height: 1.2;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 0.75rem 0.25rem;
  background-color: ${p => p.theme.colors.SurfaceSubdued};

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0;
    min-height: 3.5rem;
    max-width: 3.5rem;
    width: 100%;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.75rem;

    .icon-container {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${p => p.theme.colors.SurfaceSubdued};
      border-radius: 50%;
      transition: 0.2s background-color ease-out;
    }

    svg {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    }

    &:hover {
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

      .icon-container {
        background-color: ${p => p.theme.colors.OnSurfaceHover};
      }
    }

    &[disabled] {
      color: ${p => p.theme.colors.DisabledElement};
      background: none;

      .icon-container {
        background: none;
      }

      svg {
        color: inherit;
      }
    }
  }
`;

export const GroupTagContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    margin: 0;
  }
`;

export const ManageUserContainer = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
`;

export const InfoItems = styled.div`
  padding: 0 1rem 0;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const InfoItemLabel = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.33;
`;

export const InfoItemContent = styled.div`
  font-size: 0.875rem;
  line-height: 1.43;
  word-break: break-word;

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const InCallBadge = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    margin-left: 0.25rem;
  }
`;

export const StyledAccountName = styled.strong`
  word-break: break-word;
`;
