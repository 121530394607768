import React, { FC } from 'react';
import { PaymentCouponApiType } from '../../../../Billing.types';
import { StyledPaymentCoupon } from './PaymentCoupon.styled';
import { format, isPast } from 'date-fns';
import { BillingTranslation } from '../../../../i18n';
import { FormattedHTMLMessage } from 'react-intl';

interface PaymentCouponProps {
  coupon: PaymentCouponApiType;
}

export const PaymentCoupon: FC<PaymentCouponProps> = ({ coupon }) => {
  if (isPast(new Date(coupon.finishedAt))) {
    return null;
  }

  return (
    <FormattedHTMLMessage
      tagName={StyledPaymentCoupon}
      id={BillingTranslation.adminBillingCouponMessage}
      values={{
        couponName: coupon.name,
        percentOff: coupon.percentOff,
        finishedAt: format(new Date(coupon.finishedAt), 'dd.MM.yyyy'),
      }}
    />
  );
};
