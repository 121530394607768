import React, { FC } from 'react';
import {
  StyledListTitle,
  StyledScheduledConferencesList,
} from './ScheduledConferencesList.styled';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import { ScheduledConferencesListItem } from './ScheduledListItem';
import {
  FilterPeriod,
  ScheduledChatConferenceApiType,
} from '../../../Conference.types';
import { LoadMoreButton } from '../../../../../shared/components/LoadMore';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { GlobalTranslation } from '../../../../Intl/i18n/i18n.types';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import { useAccountsContext } from '../../../../Account';
import { Spinner } from '../../../../../shared/components/Spinner';

export const ScheduledConferencesList: FC<{
  scheduledConferencesPast: ScheduledChatConferenceApiType[];
  scheduledConferencesFuture: ScheduledChatConferenceApiType[];
  loadingConferencesFuture: boolean;
  loadingConferencesPast: boolean;
  fetchMoreScheduledConferences: (filterPeriod: FilterPeriod) => void;
  hideLoadMoreConferencesFuture?: boolean;
  hideLoadMoreConferencesPast?: boolean;
}> = ({
  scheduledConferencesPast,
  scheduledConferencesFuture,
  loadingConferencesFuture,
  loadingConferencesPast,
  fetchMoreScheduledConferences,
  hideLoadMoreConferencesFuture,
  hideLoadMoreConferencesPast,
}) => {
  const [scheduledConferencesListRef] = useHideScrollbarWhenIdle();
  const isEmpty =
    !scheduledConferencesFuture.length && !scheduledConferencesPast.length;

  const { loading: accountsAvailabilityLoading } = useAccountsContext();

  return (
    <StyledScheduledConferencesList
      ref={scheduledConferencesListRef}
      isEmpty={isEmpty}>
      {accountsAvailabilityLoading && <Spinner />}
      {isEmpty && !accountsAvailabilityLoading && (
        <FormattedMessage
          id={ConferenceTranslation.scheduleConferenceEmptyStateText}
        />
      )}
      {scheduledConferencesFuture.length ? (
        <>
          <StyledListTitle>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferenceUpcoming}
            />
          </StyledListTitle>
          {scheduledConferencesFuture.map(conference => (
            <li key={conference.id}>
              <ScheduledConferencesListItem conference={conference} />
            </li>
          ))}
          {hideLoadMoreConferencesFuture && (
            <LoadMoreButton
              size={ButtonSize.md}
              mode={ButtonMode.secondary}
              loading={loadingConferencesFuture}
              onLoadMore={() =>
                fetchMoreScheduledConferences(FilterPeriod.future)
              }
              style={{ margin: 10 }}>
              <FormattedMessage id={GlobalTranslation.loadMoreButton} />
            </LoadMoreButton>
          )}
        </>
      ) : null}
      {scheduledConferencesPast.length ? (
        <>
          <StyledListTitle>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferencePast}
            />
          </StyledListTitle>
          {scheduledConferencesPast.map(conference => (
            <li key={conference.id}>
              <ScheduledConferencesListItem conference={conference} />
            </li>
          ))}
          {hideLoadMoreConferencesPast && (
            <LoadMoreButton
              size={ButtonSize.md}
              mode={ButtonMode.secondary}
              loading={loadingConferencesPast}
              onLoadMore={() =>
                fetchMoreScheduledConferences(FilterPeriod.past)
              }
              style={{ margin: 10 }}>
              <FormattedMessage id={GlobalTranslation.loadMoreButton} />
            </LoadMoreButton>
          )}
        </>
      ) : null}
    </StyledScheduledConferencesList>
  );
};
