import { format } from 'date-fns';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile } from '../../../../../shared/hooks';
import { ChatTranslation } from '../../../i18n';
import { TODAY_DATE_FORMAT } from '../ConversationView.constants';
import { Divider, StyledDateDelimiter, Value } from './DateDelimiter.styled';

interface DateDelimiterProps extends PropsWithChildren {
  date: string;
}

export const DateDelimiter: FC<DateDelimiterProps> = React.memo(
  ({ date, ...props }) => {
    const currentDate = format(new Date(), TODAY_DATE_FORMAT);
    const isMobile = useMobile();
    return (
      <StyledDateDelimiter
        data-testid="date-delimiter"
        isMobile={isMobile}
        {...props}>
        <Divider />
        <Value data-testid="date-delimiter-value">
          {currentDate === date ? (
            <FormattedMessage id={ChatTranslation.chatDateDelimiterToday} />
          ) : (
            date
          )}
        </Value>
        <Divider />
      </StyledDateDelimiter>
    );
  },
);
