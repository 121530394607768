import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { FormInput } from '../../../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import {
  AppIntegrationsCategories,
  CustomIntegrationPropertiesFormValues,
  IntegrationClientFullApiType,
} from '../../../../AppIntegrations.types';
import { SecretInputFieldWithIcon } from '../SecretInputFieldWithIcon';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../../../../Workspace/Workspace.hooks';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../../../Workspace/Workspace.constants';
import {
  CustomIntegrationBodyWrapper,
  CustomIntegrationField,
  CustomIntegrationFormBody,
  CustomIntegrationFormControls,
  CustomIntegrationHeader,
} from '../CustomIntegrationView.styled';
import { useEditIntegrationClientMutation } from '../../../../AppIntegrations.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import { GraphQLError } from 'graphql';
import { getShortId } from '../../../../../../shared/utils/id';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { CustomIntegrationDocumentationLink } from '../../CustomIntegrationsDocumentationLink';
import { AppIntegrationsTranslation } from '../../../../i18n';

interface IntegrationPropertiesProps {
  integrationClient: IntegrationClientFullApiType;
}

export const CustomIntegrationProperties: FC<IntegrationPropertiesProps> = ({
  integrationClient,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [editIntegrationClient] = useEditIntegrationClientMutation();

  const CustomIntegrationPropertiesSchema: Schema<CustomIntegrationPropertiesFormValues> =
    Yup.object().shape({
      integrationName: Yup.string().required(
        AppIntegrationsTranslation.appCustomIntegrationNameRequired,
      ),
      clientId: Yup.string().required(),
      secret: Yup.string().required(),
    });

  const initialValues: CustomIntegrationPropertiesFormValues = useMemo(() => {
    return {
      integrationName: integrationClient.name,
      clientId: getShortId(integrationClient.id),
      secret: integrationClient.secret,
    };
  }, [integrationClient]);

  const handleSubmit = useCallback(
    (formValues: CustomIntegrationPropertiesFormValues) => {
      const { integrationName } = formValues;

      return editIntegrationClient({
        variables: {
          input: {
            id: integrationClient.id,
            name: integrationName,
            botName: integrationClient?.bot?.firstName,
            botImage: integrationClient?.bot?.image?.id,
          },
        },
      })
        .then(() => {
          showToastSuccessMessage(
            AppIntegrationsTranslation.appCustomIntegrationNameUpdated,
          );
        })
        .catch((e: { graphQLErrors: GraphQLError[] }) => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [editIntegrationClient, integrationClient],
  );

  if (!integrationClient) {
    return null;
  }

  return (
    <CustomIntegrationBodyWrapper>
      <CustomIntegrationHeader>
        <CustomIntegrationDocumentationLink />
      </CustomIntegrationHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CustomIntegrationPropertiesSchema}>
        {({ isSubmitting, setFieldValue, dirty, errors, touched }) => (
          <Form data-testid="custom-integration-properties-form">
            <CustomIntegrationFormBody>
              <CustomIntegrationField>
                <Field
                  name="integrationName"
                  type="text"
                  data-testid="integration-name-input"
                  placeholder={intl.formatMessage({
                    id: AppIntegrationsTranslation.appCustomIntegrationNamePlaceholder,
                  })}
                  label={intl.formatMessage({
                    id: AppIntegrationsTranslation.appCustomIntegrationNameLabel,
                  })}
                  component={FormInput}
                />
              </CustomIntegrationField>
              <CustomIntegrationField>
                <Field
                  name="clientId"
                  type="text"
                  data-testid="client-id-input"
                  disabled
                  label={intl.formatMessage({
                    id: AppIntegrationsTranslation.appCustomIntegrationClientIdLabel,
                  })}
                  component={FormInput}
                />
              </CustomIntegrationField>

              <CustomIntegrationField>
                <Field
                  name="secret"
                  displayErrors={false}
                  component={SecretInputFieldWithIcon}
                />
              </CustomIntegrationField>
            </CustomIntegrationFormBody>

            <CustomIntegrationFormControls>
              <Button
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                className="cancel-button"
                onClick={() =>
                  navigate({
                    pathname: generatePath(APPS_INTEGRATIONS_PATHNAME, {
                      workspaceId: getShortId(currentWorkspace.id),
                    }),
                    search: getQueryParamsFrom({
                      ...queryParams,
                      integrationCategory: AppIntegrationsCategories.custom,
                    }),
                  })
                }
                data-testid="cancel-button">
                <FormattedMessage
                  id={
                    AppIntegrationsTranslation.appCustomIntegrationCancelButton
                  }
                />
              </Button>
              <Button
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={!dirty || isSubmitting}
                data-testid="submit-button">
                <FormattedMessage
                  id={AppIntegrationsTranslation.appCustomIntegrationSaveButton}
                />
              </Button>
            </CustomIntegrationFormControls>
          </Form>
        )}
      </Formik>
    </CustomIntegrationBodyWrapper>
  );
};
