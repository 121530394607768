import { GetAppIntegrationsMarketplaceCategoriesResponse } from './AppIntegrations.queries';
import { extractNodes } from '../../shared/api/api.utils';
import { MarketplaceCategoryType } from './AppIntegrations.types';

export const extractMarketplaceCategories = (
  data?: GetAppIntegrationsMarketplaceCategoriesResponse,
): MarketplaceCategoryType[] | [] =>
  data?.appIntegrationMarketplaceCategories.edges.map(({ node }) => ({
    ...node,
    marketplaceItems: extractNodes(node.marketplaceItems),
  })) || [];
