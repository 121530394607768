import React, { FC, useCallback, useMemo } from 'react';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderGroup,
  SectionHeaderTitle,
} from '../../../../Admin';
import {
  PlanInfo,
  PlanLayout,
  PlanPriceContainer,
  PlanPriceCurrency,
  PlanPriceNumber,
  PlanPricePeriod,
  WorkspaceMembersCount,
} from './PlanInformation.styled';
import { BillingTranslation } from '../../../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../Workspace/Workspace.hooks';
import { PlanTitle } from './PlanTitle/PlanTitle';
import { PlanTrialInfo } from './PlanTrialInfo/PlanTrialInfo';
import { PlanUsps } from './PlanUsps/PlanUsps';
import { getPaymentPlanByWorkspace } from '../../../Billing.utils';
import {
  useActivateSubscriptionPaymentMutation,
  useCancelDowngradeWorkspaceMutation,
  useGetPaymentCouponQuery,
  useGetPaymentPlansQuery,
} from '../../../Billing.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import {
  PaymentCustomerApiType,
  PaymentPlanInterval,
} from '../../../Billing.types';
import Dinero from 'dinero.js';
import { useConfirm } from '../../../../../shared/components/Modal';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { NextRenewDate } from './NextRenewalDate';
import { useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { DowngradeModal } from '../../../BillingModals/DowngradeModal';
import { useNavigate } from 'react-router-dom';

interface PlanInformationProps {
  paymentCustomer: PaymentCustomerApiType | undefined;
}

export const PlanInformation: FC<PlanInformationProps> = ({
  paymentCustomer,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();

  const { account } = useCurrentAccount();
  const {
    permissions: { canEditBillingPage },
  } = useCurrentWorkspacePermissions();

  const { data: paymentPlans } = useGetPaymentPlansQuery({
    variables: {
      workspace: workspace.id,
    },
  });
  const { data: paymentCoupon } = useGetPaymentCouponQuery({
    skip: !paymentCustomer,
    variables: {
      paymentCustomer: paymentCustomer?.id as string,
    },
    fetchPolicy: 'no-cache',
  });

  const workspacePaymentPlan = useMemo(() => {
    return getPaymentPlanByWorkspace(paymentPlans, workspace);
  }, [paymentPlans, workspace]);

  const { askConfirmation } = useConfirm();
  const intl = useIntl();

  const handleDowngradenClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        isDowngradeModalOpened: true,
      }),
    });
  }, [navigate, queryParams]);

  const [cancelDowngradeMutation] = useCancelDowngradeWorkspaceMutation();
  const [activateSubscriptionMutation] =
    useActivateSubscriptionPaymentMutation();
  const handleActivateSubscriptionClick = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: BillingTranslation.adminBillingActivateSubscriptionConfirmationMessage,
      }),
      intl.formatMessage({
        id: BillingTranslation.adminBillingActivateSubscriptionConfirmationHeader,
      }),
      { width: 360 },
    ).then(confirm => {
      if (!confirm || !paymentCustomer?.id) {
        return;
      }

      activateSubscriptionMutation({
        variables: {
          input: {
            id: paymentCustomer.id,
          },
        },
      })
        .then(() => {
          refetchWorkspaces({
            id: account!.identityId,
          });
          showToastSuccessMessage(
            BillingTranslation.adminBillingActivateSubscriptionSuccessMessage,
          );
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    });
  }, [
    account,
    activateSubscriptionMutation,
    askConfirmation,
    intl,
    paymentCustomer,
    refetchWorkspaces,
  ]);

  const handleCancelDowngradeClick = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: BillingTranslation.adminBillingCancelDowngradeConfirmationMessage,
      }),
      intl.formatMessage({
        id: BillingTranslation.adminBillingCancelDowngradeConfirmationHeader,
      }),
      { width: 360 },
    ).then(confirm => {
      if (!confirm) {
        return;
      }

      cancelDowngradeMutation({
        variables: {
          input: {
            id: workspace.id,
          },
        },
      })
        .then(() => {
          refetchWorkspaces({
            id: account!.identityId,
          });
          showToastSuccessMessage(
            BillingTranslation.adminBillingCancelDowngradeSuccessMessage,
          );
        })
        .catch(e => showToastGraphQLErrors(e.graphQLErrors));
    });
  }, [
    askConfirmation,
    intl,
    cancelDowngradeMutation,
    workspace.id,
    refetchWorkspaces,
    account,
  ]);

  return (
    <Section data-testid="section-plan-information">
      <SectionHeader data-testid="header">
        <SectionHeaderTitle data-testid="title">
          <FormattedMessage id={BillingTranslation.adminBillingPlanTitle} />
        </SectionHeaderTitle>
        {canEditBillingPage && (
          <SectionHeaderGroup>
            {/*<SectionHeaderButton*/}
            {/*  onClick={() => {*/}
            {/*    showToastInfoMessage(GlobalTranslation.featureNotAvailable);*/}
            {/*  }}*/}
            {/*  data-testid="change-plan">*/}
            {/*  <FormattedMessage*/}
            {/*    id={BillingTranslation.adminBillingPlanButton}*/}
            {/*  />*/}
            {/*</SectionHeaderButton>*/}
            {workspace.billingRequired && !workspace.billingOk && (
              <SectionHeaderButton
                onClick={handleActivateSubscriptionClick}
                data-testid="activate">
                <FormattedMessage
                  id={BillingTranslation.adminBillingActivateSubscriptionButton}
                />
              </SectionHeaderButton>
            )}

            {workspace.isDowngrading ? (
              <>
                <SectionHeaderButton
                  onClick={handleCancelDowngradeClick}
                  data-testid="cancel-downgrade">
                  <FormattedMessage
                    id={
                      BillingTranslation.adminBillingCancelDowngradeSubscriptionButton
                    }
                  />
                </SectionHeaderButton>
              </>
            ) : (
              <SectionHeaderButton
                onClick={handleDowngradenClick}
                data-testid="downgrade">
                <FormattedMessage
                  id={BillingTranslation.adminBillingDowngradeButton}
                />
              </SectionHeaderButton>
            )}
          </SectionHeaderGroup>
        )}
      </SectionHeader>

      <SectionBody data-testid="body">
        {workspacePaymentPlan?.workspaceType ? (
          <PlanLayout>
            <PlanInfo>
              <PlanTitle
                plan={workspacePaymentPlan?.workspaceType}
                coupon={paymentCoupon?.getPaymentCoupon}
              />
              {workspace.freeTrialDaysLeft && (
                <PlanTrialInfo daysLeft={workspace.freeTrialDaysLeft} />
              )}
              <NextRenewDate workspace={workspace} />
              <PlanUsps plan={workspacePaymentPlan.workspaceType} />

              <WorkspaceMembersCount data-testid="workspace-members-count">
                <FormattedHTMLMessage
                  id={BillingTranslation.adminBillingWorkspaceMembersCount}
                  values={{ membersCount: workspace.membersCount }}
                />
              </WorkspaceMembersCount>
            </PlanInfo>

            <PlanPriceContainer data-testid="price-panel">
              <PlanPriceNumber data-testid="price">
                {Dinero({ amount: workspacePaymentPlan.amount }).toFormat(
                  '0,0.00',
                )}
              </PlanPriceNumber>
              <PlanPriceCurrency data-testid="currency">
                {workspacePaymentPlan.currency}
              </PlanPriceCurrency>
              <PlanPricePeriod data-testid="period">
                {workspacePaymentPlan.interval === PaymentPlanInterval.DAY && (
                  <FormattedMessage id={BillingTranslation.planIntervalDay} />
                )}
                {workspacePaymentPlan.interval === PaymentPlanInterval.WEEK && (
                  <FormattedMessage id={BillingTranslation.planIntervalWeek} />
                )}
                {workspacePaymentPlan.interval ===
                  PaymentPlanInterval.MONTH && (
                  <FormattedMessage id={BillingTranslation.planIntervalMonth} />
                )}
                {workspacePaymentPlan.interval === PaymentPlanInterval.YEAR && (
                  <FormattedMessage id={BillingTranslation.planIntervalYear} />
                )}
                <FormattedMessage id={BillingTranslation.planIntervalPerUser} />
              </PlanPricePeriod>
            </PlanPriceContainer>
          </PlanLayout>
        ) : (
          <Spinner />
        )}
      </SectionBody>

      {queryParams.isDowngradeModalOpened && <DowngradeModal />}
    </Section>
  );
};
