import React, { FC, useCallback } from 'react';
import { Spinner } from '../../../../shared/components/Spinner';
import { AppCard } from '../AppCard';
import { Navigation } from './components/Navigation';
import { Categories } from './components/Categories';
import { EDIT_APP_MODAL_Z_INDEX, TabId } from '../../AppStore.constants';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../i18n';
import { CreateCustomAppModal } from '../../Modals/CreateCustomAppModal';
import { CreateCustomAppBanner } from './components/CreateCustomAppBanner';
import {
  AppsGrid,
  AppsSection,
  AppStoreContent,
  AppStoreSidebar,
  AppStoreSpacer,
  NoAppsAvailable,
  StyledAppStoreView,
} from './AppStore.styled';
import { Header } from './components/Header';
import { SsoAppsGrid } from '../SsoAppsGrid';
import { LoadMoreObserver } from '../../../../shared/components/LoadMore';
import { EditAppModal } from '../../Modals/EditCustomAppModal';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';
import { AppStoreViewModel } from './ViewModel';

export const AppStore: FC = () => {
  const {
    filter,
    categories,
    categoryId,
    loadingCategories,
    tabId,
    canAddCustomApp,
    loadingApps,
    loadingMoreApps,
    appsAddedToCurrentDesktop,
    defaultAppsData,
    apps,
    refetchCustomApps,
    createCustomAppModal,
    handleCreateCustomAppBannerClick,
    editAppId,
    handleDeleteCustomAppClick,
    handleLoadMore,
  } = AppStoreViewModel();

  const renderNoAppsMessage = useCallback(() => {
    if (filter) {
      return (
        <FormattedHTMLMessage
          id={AppStoreTranslation.noAppsAvailableFilter}
          values={{
            search: filter as string,
          }}
        />
      );
    }
    if (categoryId) {
      return (
        <FormattedMessage id={AppStoreTranslation.noAppsAvailableCategory} />
      );
    }
    switch (tabId) {
      case TabId.customApps:
        return (
          <FormattedHTMLMessage
            id={AppStoreTranslation.noAppsAvailableCustomApps}
            values={{
              supportCustomAppsUrl: process.env.SUPPORT_CUSTOM_APPS_URL,
            }}
          />
        );
      case TabId.company:
        return (
          <FormattedMessage id={AppStoreTranslation.noAppsAvailableComapny} />
        );
      case TabId.passwords:
        return (
          <FormattedHTMLMessage
            id={AppStoreTranslation.noAppsAvailableMyPassword}
            values={{
              supportPasswordManagerUrl:
                process.env.SUPPORT_PASSWORD_MANAGER_URL,
            }}
          />
        );
      case TabId.personal:
        return (
          <FormattedHTMLMessage
            id={AppStoreTranslation.noAppsAvailablePrivateApps}
            values={{
              supportAddAppsUrl: process.env.SUPPORT_ADD_APPS_URL,
            }}
          />
        );
      default:
        return <FormattedMessage id={AppStoreTranslation.noAppsAvailable} />;
    }
  }, [tabId, categoryId, filter]);

  const [appSectionRef] = useHideScrollbarWhenIdle();

  return (
    <StyledAppStoreView data-testid="app-store-view">
      <AppStoreContent data-testid="content">
        {loadingCategories ? (
          <Spinner size={64} containerHeight={200} />
        ) : (
          <>
            <AppStoreSidebar data-testid="sidebar">
              <Navigation />
              {tabId !== TabId.sso ? (
                <Categories categories={categories} />
              ) : (
                <AppStoreSpacer />
              )}
              {canAddCustomApp && (
                <CreateCustomAppBanner
                  onClick={handleCreateCustomAppBannerClick}
                />
              )}
            </AppStoreSidebar>
            <AppsSection ref={appSectionRef}>
              {tabId !== TabId.sso && <Header />}
              {tabId === TabId.sso ? (
                <SsoAppsGrid />
              ) : loadingApps && !loadingMoreApps ? (
                <Spinner size={64} containerHeight={200} />
              ) : !apps.length ? (
                <NoAppsAvailable>{renderNoAppsMessage()}</NoAppsAvailable>
              ) : (
                <>
                  <AppsGrid data-testid="apps-grid">
                    {apps.map((app, index) => (
                      <AppCard
                        key={app.id}
                        app={app}
                        index={index}
                        totalApps={apps.length}
                        added={appsAddedToCurrentDesktop.has(app.id)}
                        onDeleteCustomAppClick={handleDeleteCustomAppClick}
                      />
                    ))}
                  </AppsGrid>
                  {defaultAppsData?.apps.pageInfo?.hasNextPage && (
                    <LoadMoreObserver
                      loading={loadingMoreApps}
                      onLoadMore={handleLoadMore}
                    />
                  )}
                </>
              )}
            </AppsSection>

            {createCustomAppModal && (
              <CreateCustomAppModal onDone={() => refetchCustomApps()} />
            )}

            {editAppId && (
              <EditAppModal containerZIndex={EDIT_APP_MODAL_Z_INDEX} />
            )}
          </>
        )}
      </AppStoreContent>
    </StyledAppStoreView>
  );
};
