import { gql } from '@apollo/client';
import { IntegrationFields } from './AppIntegrations.fragments';
import {
  AppIntegrationsAccountType,
  MarketplaceAppType,
  MarketplaceCategoryType,
  MarketplaceItemType,
  SearchResult,
} from './AppIntegrations.types';
import { ApiConnection, PageInfoApiType } from '../../shared/api/api.types';
import {
  IntegrationClientApiType,
  IntegrationClientFullApiType,
} from './AppIntegrations.types';

export interface GetAppIntegrationsMarketplaceCategoriesResponse {
  appIntegrationMarketplaceCategories: ApiConnection<{
    id: string;
    name: string;
    image: {
      contentUrl: string;
    };
    marketplaceItems: ApiConnection<MarketplaceItemType>;
  }>;
}

export interface GetAppIntegrationsMarketplaceCategoriesVariables {
  personal: boolean;
}

export const APP_INTEGRATIONS_MARKETPLACE_CATEGORIES = gql`
  query GetAppIntegrationsMarketplaceCategories($personal: Boolean) {
    appIntegrationMarketplaceCategories(personal: $personal) {
      edges {
        node {
          id
          name
          image {
            contentUrl
          }
          marketplaceItems {
            edges {
              node {
                id
                name
                app {
                  id
                  name
                  logo {
                    contentUrl
                  }
                }
                authenticated
                accountAppIntegration {
                  id
                  active
                }
                description
                identifier
                searchable
                connectorConfiguration
              }
            }
          }
        }
      }
    }
  }
`;

export interface AppIntegrationsConnectorConfigurationApiType {
  type: string;
  driver: string;
  scopeType: string;
}

export interface AppIntegrationMarketplaceItemType {
  id: string;
  app: MarketplaceAppType;
  name: string;
  marketplaceCategory: Omit<MarketplaceCategoryType, 'marketplaceItems'>;
  authenticated: boolean;
  description: string;
  identifier: string;
  searchable: boolean;
  accountAppIntegration?: AppIntegrationsAccountType;
  connectorConfiguration: AppIntegrationsConnectorConfigurationApiType;
}

export interface GetAppIntegrationsMarketplaceItemsResponse {
  appIntegrationMarketplaceItems: ApiConnection<AppIntegrationMarketplaceItemType>;
}

export interface GetAppIntegrationsMarketplaceItemsVariables {
  marketplaceCategory: string;
  personal: boolean;
}

export const APP_INTEGRATIONS_MARKETPLACE_ITEMS = gql`
  query GetAppIntegrationsMarketplaceItems(
    $personal: Boolean
    $marketplaceCategory: String
  ) {
    appIntegrationMarketplaceItems(
      marketplaceCategory: $marketplaceCategory
      personal: $personal
    ) {
      edges {
        node {
          id
          name
          description
          identifier
          searchable
          connectorConfiguration
          authenticated
          marketplaceCategory {
            id
            name
            image {
              contentUrl
            }
          }
          accountAppIntegration {
            id
            active
          }
          app {
            id
            name
            logo {
              contentUrl
            }
          }
        }
      }
    }
  }
`;

export interface SearchAppIntegrationsResponse {
  listAppIntegrationSearches: ApiConnection<SearchResult> & {
    pageInfo: PageInfoApiType;
    totalCount: String;
  };
}

export interface SearchAppIntegrationsVariables {
  appIntegration: string;
  query: string;
  cursor?: string;
  first: number;
}

export const SEARCH_APP_INTEGRATIONS = gql`
  query SearchAppIntegration(
    $appIntegration: ID!
    $query: String!
    $cursor: String
    $first: Int
  ) {
    listAppIntegrationSearches(
      appIntegration: $appIntegration
      query: $query
      after: $cursor
      first: $first
    ) {
      edges {
        node {
          title
          context
          contextId
          externalURI
          summary
          date
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;

export interface IntegrationClientsVariables {
  workspace: string;
}

export interface IntegrationClientsResponse {
  integrationClients: ApiConnection<IntegrationClientApiType>;
}

export const GET_WORKSPACE_INTEGRATION_CLIENTS = gql`
  query IntegrationClients($workspace: String) {
    integrationClients(workspace: $workspace) {
      edges {
        node {
          ${IntegrationFields}
        }
      }
    }
  }
`;

export interface IntegrationClientVariables {
  id: string;
}

export interface IntegrationClientResponse {
  integrationClient: IntegrationClientFullApiType;
}

export const GET_INTEGRATION_CLIENT = gql`
  query IntegrationClient($id: ID!) {
    integrationClient(id: $id) {
      ${IntegrationFields}
      hooks {
        edges {
          node {
            id
            secret
            desktop {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface GetIntegrationAuthUrlVariables {
  connectorConfiguration?: AppIntegrationsConnectorConfigurationApiType;
  workspaceId: string;
  scope: string;
}

export interface AuthUrlApiType {
  authUrl: string;
}

interface GoogleAuthUrlApiType {
  googleAuthUrl: AuthUrlApiType;
}

export const GET_GOOGLE_AUTH_URL = gql`
  query GetGoogleAuthUrl($scope: String!, $workspaceId: ID!) {
    googleAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface GitHubUrlApiType {
  githubAuthUrl: AuthUrlApiType;
}

export const GET_GITHUB_AUTH_URL = gql`
  query GetGithubAuthUrl($scope: String!, $workspaceId: ID!) {
    githubAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface TrelloAuthUrlApiType {
  trelloAuthUrl: AuthUrlApiType;
}

export const GET_TRELLO_AUTH_URL = gql`
  query GetTrelloAuthUrl($scope: String!, $workspaceId: ID!) {
    trelloAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface DropboxAuthUrlApiType {
  dropboxAuthUrl: AuthUrlApiType;
}

export const GET_DROPBOX_AUTH_URL = gql`
  query GetDropboxAuthUrl($scope: String!, $workspaceId: ID!) {
    dropboxAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface MailchimpAuthUrlApiType {
  mailchimpAuthUrl: AuthUrlApiType;
}

export const GET_MAILCHIMP_AUTH_URL = gql`
  query GetMailchimpAuthUrl($scope: String!, $workspaceId: ID!) {
    mailchimpAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface NotionAuthUrlApiType {
  notionAuthUrl: AuthUrlApiType;
}

export const GET_NOTION_AUTH_URL = gql`
  query GetNotionAuthUrl($workspaceId: ID!) {
    notionAuthUrl(workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface SalesforceAuthUrlAuthUrlApiType {
  salesforceAuthUrl: AuthUrlApiType;
}

export const GET_SALESFORCE_AUTH_URL = gql`
  query GetSalesforceAuthUrl($scope: String!, $workspaceId: ID!) {
    salesforceAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface AsanaAuthUrlAuthUrlApiType {
  asanaAuthUrl: AuthUrlApiType;
}

export const GET_ASANA_AUTH_URL = gql`
  query GetAsanaAuthUrl($scope: String!, $workspaceId: ID!) {
    asanaAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface HubspotAuthUrlAuthUrlApiType {
  hubspotAuthUrl: AuthUrlApiType;
}

export const GET_HUBSPOT_AUTH_URL = gql`
  query GetHubspotAuthUrl($scope: String!, $workspaceId: ID!) {
    hubspotAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

interface MicrosoftAuthUrlAuthUrlApiType {
  microsoftAuthUrl: AuthUrlApiType;
}

export const GET_MICROSOFT_AUTH_URL = gql`
  query GetMicrosoftAuthUrl($scope: String!, $workspaceId: ID!) {
    microsoftAuthUrl(scopeType: $scope, workspace: $workspaceId) {
      authUrl
    }
  }
`;

export interface GetIntegrationAuthUrlResponse
  extends GoogleAuthUrlApiType,
    GitHubUrlApiType,
    TrelloAuthUrlApiType,
    DropboxAuthUrlApiType,
    MailchimpAuthUrlApiType,
    NotionAuthUrlApiType,
    SalesforceAuthUrlAuthUrlApiType,
    AsanaAuthUrlAuthUrlApiType,
    HubspotAuthUrlAuthUrlApiType,
    MicrosoftAuthUrlAuthUrlApiType {}
