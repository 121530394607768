import styled from 'styled-components';

export const ReencryptVaultAccessesControlsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  button ~ button {
    margin-left: 0.5rem;
  }

  > div {
    margin-bottom: 0;
    margin-top: 1rem;
  }
`;

export const ReencryptVaultAccountInfo = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
