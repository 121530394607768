import React, { FC, useEffect, useMemo, useReducer } from 'react';
import { TemplateApiType } from '../../Template.types';
import { TemplateCheckbox } from './TemplateCheckbox';
import { TemplatesList } from './SelectTemplatesForm.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../Onboarding/i18n';
import { TemplateListLabel } from '../ApplyTemplates.styled';

interface SelectTemplatesFormProps {
  templates: TemplateApiType[];
  onTemplateSelect: (selectedTemplates: TemplateApiType[]) => void;
}

export const SelectTemplatesForm: FC<SelectTemplatesFormProps> = ({
  templates,
  onTemplateSelect,
}) => {
  const templatesToDisplay = useMemo(() => {
    return templates.filter(template => template.appSet).filter(Boolean);
  }, [templates]);

  const [selectedTemplateMap, toggleSelectedTemplateCheckbox] = useReducer(
    (state: { [key: string]: boolean }, id: string) => {
      return { ...state, [id]: !state[id] };
    },
    templatesToDisplay.reduce(
      (acc, current) => ({ ...acc, [current.id]: false }),
      {},
    ),
  );

  useEffect(() => {
    onTemplateSelect(
      templatesToDisplay.filter(template => selectedTemplateMap[template.id]),
    );
  }, [templatesToDisplay, selectedTemplateMap, onTemplateSelect]);

  return (
    <>
      <TemplateListLabel>
        <FormattedMessage id={OnboardingTranslation.addContentPlatformTitle} />
      </TemplateListLabel>

      <TemplatesList data-testid="templates-list">
        {templatesToDisplay.map(template => (
          <TemplateCheckbox
            data-testid={template.id}
            key={template.id}
            template={template}
            checked={selectedTemplateMap[template.id]}
            onTemplateCheck={template =>
              toggleSelectedTemplateCheckbox(template.id)
            }
          />
        ))}
      </TemplatesList>
    </>
  );
};
