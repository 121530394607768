import { useCallback, useState } from 'react';
import { getLongId, getShortId } from '../../../shared/utils/id';
import { getDesktopAppsRepository } from '../deprecatedData/Repository/DesktopRepository';

export const useCaseGetDesktopApps = () => {
  const [desktopAppsLoading, setDesktopAppsLoading] = useState(false);

  const getDesktopApps = useCallback(
    async (desktopId: string, workspaceId: string) => {
      setDesktopAppsLoading(true);
      const response = await getDesktopAppsRepository(
        getShortId(desktopId),
        getLongId('workspaces', workspaceId),
      );

      if (response) {
        setDesktopAppsLoading(false);
        return response;
      }
    },
    [],
  );

  return {
    getDesktopApps,
    desktopAppsLoading,
  };
};
