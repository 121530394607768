import styled, { css } from 'styled-components';

export const StyledChatView = styled.div<{
  withHeader?: boolean;
  isChatsPage?: boolean;
  isMobile?: boolean;
}>`
  display: grid;
  min-height: 100%;
  max-height: 100%;
  height: fill-available;
  background-color: ${p => p.theme.colors.Surface};
  border-radius: 0.6rem;
  overflow: hidden;

  ${p =>
    p.withHeader && p.isMobile
      ? css`
          grid-template-rows: 2.5rem 1fr;
        `
      : p.withHeader && !p.isMobile
      ? css`
          grid-template-rows: 3.5rem 1fr;
        `
      : css`
          grid-template-rows: 1fr;
        `};

  ${p =>
    !p.isChatsPage &&
    css`
      grid-area: desktop-toolbar / desktop-items / desktop-items / desktop-items;
    `}
`;
