import { FolderApiType } from '../../Folder.types';
import { getFolderByFolderId } from '../../Folder.utils';

export const getFolderCrumbs = (
  folders: Array<FolderApiType>,
  folderId: string,
): Array<FolderApiType> | [] => {
  const currentFolder = getFolderByFolderId(folders, folderId);
  if (!currentFolder) {
    return [];
  }

  const crumbs: Array<FolderApiType> = [currentFolder];

  while (crumbs[0]?.parent?.id) {
    const parentFolder = getFolderByFolderId(folders, crumbs[0].parent.id);
    if (parentFolder) {
      crumbs.unshift(parentFolder);
    } else {
      break;
    }
  }

  return crumbs;
};
