import React, { FC } from 'react';
import { DrawerModal, ModalProps } from '../../../shared/components/Modal';
import { WorkspaceAdminMenu } from './WorkspaceAdminMenu';
import { WorkspaceTranslation } from '../i18n';
import { FormattedMessage } from 'react-intl';
import { DrawerHeader } from '../../Admin/DrawerHeader';
import { useMobile } from '../../../shared/hooks';

export const WorkspaceAdminMenuDrawer: FC<
  Pick<ModalProps, 'onRequestClose'>
> = ({ onRequestClose }) => {
  const isMobile = useMobile();
  if (!isMobile) {
    onRequestClose();
  }
  return (
    <DrawerModal visible={true} onRequestClose={onRequestClose}>
      <DrawerHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={WorkspaceTranslation.adminHeaderTitle} />
      </DrawerHeader>
      <WorkspaceAdminMenu />
    </DrawerModal>
  );
};
