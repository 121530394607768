import React, { FC } from 'react';
import {
  RegistrationBannerIcon,
  RegistrationBannerItem,
  RegistrationBannerItemDescription,
  RegistrationBannerItemTitle,
  StyledRegistrationBanner,
} from './RegistrationBanner.styled';
import CheckIcon from './check.svg';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';

export const RegistrationBanner: FC = () => {
  return (
    <StyledRegistrationBanner>
      <RegistrationBannerItem>
        <RegistrationBannerIcon src={CheckIcon} />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemAccessTitle}
          tagName={RegistrationBannerItemTitle}
        />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemAccessDescription}
          tagName={RegistrationBannerItemDescription}
        />
      </RegistrationBannerItem>
      <RegistrationBannerItem>
        <RegistrationBannerIcon src={CheckIcon} />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemOrganizeTitle}
          tagName={RegistrationBannerItemTitle}
        />
        <FormattedMessage
          id={
            OnboardingTranslation.registrationFormBannerItemOrganizeDescription
          }
          tagName={RegistrationBannerItemDescription}
        />
      </RegistrationBannerItem>
      <RegistrationBannerItem>
        <RegistrationBannerIcon src={CheckIcon} />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemCollaborateTitle}
          tagName={RegistrationBannerItemTitle}
        />
        <FormattedMessage
          id={
            OnboardingTranslation.registrationFormBannerItemCollaborateDescription
          }
          tagName={RegistrationBannerItemDescription}
        />
      </RegistrationBannerItem>
      <RegistrationBannerItem>
        <RegistrationBannerIcon src={CheckIcon} />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemSearchTitle}
          tagName={RegistrationBannerItemTitle}
        />
        <FormattedMessage
          id={OnboardingTranslation.registrationFormBannerItemSearchDescription}
          tagName={RegistrationBannerItemDescription}
        />
      </RegistrationBannerItem>
    </StyledRegistrationBanner>
  );
};
