export class AsyncTaskQueue {
  private queue: Function[];

  private running: boolean;

  constructor(private options: { runInterval: number } = { runInterval: 40 }) {
    this.queue = [];
    this.running = false;
  }

  public push(task: Function) {
    this.queue.push(task);
    this.run();
  }

  private async run() {
    if (this.running) {
      return;
    }

    this.running = true;

    let task;
    while ((task = this.queue.shift())) {
      await this.schedule(task);
    }

    this.running = false;
  }

  private schedule(task: Function) {
    return new Promise<void>(resolve => {
      setTimeout(async () => {
        await task();
        resolve();
      }, this.options.runInterval);
    });
  }
}
