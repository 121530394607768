import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useModalControls } from '../../../../shared/components/Modal/Modal.hooks';
import { EditDesktopModal } from './EditDesktopModal';
import { Spinner } from '../../../../shared/components/Spinner';
import { DesktopContext } from '../../Desktop.context';
import {
  AdminEntityNotFound,
  AdminHeader,
  AdminHeaderSeparator,
  AdminHeaderTitle,
  DangerZone,
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneHeaderTitle,
  DangerZoneWarning,
  GeneralInfoSubtitle,
  GeneralInfoTitle,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../Admin';
import { useConfirm } from '../../../../shared/components/Modal';
import { DesktopTranslation } from '../../i18n';
import { ManageRelatedUsers } from './MembersSection';
import { useDesktopRepository } from '../../data/Desktop/Desktop.repositories';
import { useDesktopsActions } from '../../data/Desktop/Desktop.actions';
import { getLongId } from '../../../../shared/utils/id';
import { ManageRelatedTeams } from './TeamsSection';
import { ManageRelatedApps } from './AppsSection';

export const DesktopView: FC<{}> = () => {
  const { desktopId, workspaceId } = useParams<{
    desktopId: string;
    workspaceId: string;
  }>();
  const navigate = useNavigate();
  const intl = useIntl();
  const { askConfirmation } = useConfirm();

  const editDesktopModal = useModalControls();

  const { desktop, loading } = useDesktopRepository({
    desktopId: getLongId('desktops', desktopId as string),
  });

  const { removeDesktopById } = useDesktopsActions();
  const handleDeleteDesktopClick = useCallback(() => {
    askConfirmation(
      intl.formatMessage({
        id: DesktopTranslation.adminSingleDesktopDangerZoneDeleteConfirmation,
      }),
    ).then(confirm => {
      if (!confirm || !desktop?.id) {
        return;
      }

      navigate(`/workspace/${workspaceId}/admin/desktops`);

      removeDesktopById(desktop.id);
    });
  }, [
    askConfirmation,
    intl,
    removeDesktopById,
    desktop,
    navigate,
    workspaceId,
  ]);

  if (!desktop) {
    return loading ? (
      <Spinner />
    ) : (
      <AdminEntityNotFound>
        <FormattedMessage id={DesktopTranslation.adminSingleDesktopNotFound} />
      </AdminEntityNotFound>
    );
  }

  return (
    <DesktopContext.Provider value={desktop}>
      <AdminHeader data-testid="header">
        <AdminHeaderTitle data-testid="title">
          <FormattedMessage id={DesktopTranslation.adminListHeaderTitle} />{' '}
          <AdminHeaderSeparator /> {desktop.name}
        </AdminHeaderTitle>
      </AdminHeader>

      <Section data-testid="general-info-section">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage
              id={DesktopTranslation.adminSingleDesktopGeneralInfo}
            />
          </SectionHeaderTitle>
          <SectionHeaderButton
            onClick={editDesktopModal.open}
            data-testid="edit-button">
            <FormattedMessage
              id={DesktopTranslation.adminSingleDesktopEditButton}
            />
          </SectionHeaderButton>
        </SectionHeader>
        <SectionBody data-testid="section-body">
          <GeneralInfoTitle data-testid="title">
            {desktop.name}
          </GeneralInfoTitle>
          <GeneralInfoSubtitle data-testid="subtitle">
            {desktop.description}
          </GeneralInfoSubtitle>
        </SectionBody>
      </Section>

      <ManageRelatedUsers desktop={desktop} />

      <ManageRelatedTeams desktop={desktop} />

      <ManageRelatedApps />

      <Section data-testid="danger-zone-section">
        <SectionHeader data-testid="header">
          <DangerZoneHeaderTitle data-testid="title">
            <FormattedMessage
              id={DesktopTranslation.adminSingleDesktopDangerZone}
            />
          </DangerZoneHeaderTitle>
        </SectionHeader>

        <SectionBody noPadding={true} data-testid="section-body">
          <DangerZone data-testid="danger-zone">
            <DangerZoneButton
              data-testid="delete-button"
              size={ButtonSize.sm}
              mode={ButtonMode.secondary}
              dangerMode={DangerZoneButtonMode.critical}
              onClick={handleDeleteDesktopClick}>
              <FormattedMessage
                id={DesktopTranslation.adminSingleDesktopDangerZoneDeleteButton}
              />
            </DangerZoneButton>
            <DangerZoneWarning data-testid="delete-warning">
              <FormattedMessage
                id={
                  DesktopTranslation.adminSingleDesktopDangerZoneDeleteWarning
                }
              />
            </DangerZoneWarning>
          </DangerZone>
        </SectionBody>
      </Section>

      {editDesktopModal.visible && (
        <EditDesktopModal onRequestClose={editDesktopModal.close} />
      )}
    </DesktopContext.Provider>
  );
};
