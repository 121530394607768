import styled from 'styled-components';

export const StyledAttachmentsContainer = styled.div`
  padding: 0.75rem 1rem 0.25rem;
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderLight};
  border-bottom-width: 0;
  border-radius: 1.25rem 1.25rem 0 0;
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
