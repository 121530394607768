import { gql } from '@apollo/client';
import {
  AppSsoGroupApiType,
  AppStoreAppInput,
  SsoConfigurationInputType,
  WorkspaceAppConfigurationApiType,
} from './AppStore.types';
import { AppFragmentFields } from '../Desktop/Desktop.fragments';
import { AppSsoGroupFields, WorkspaceConfigFields } from './AppStore.fragments';
import type { DesktopAppApiType } from '../Desktop/data/Desktop/types/Desktop.types';

export const CREATE_CUSTOM_APP = gql`
  mutation CreateCustomApp($input: createCustomAppInput!) {
    __typename
    createCustomApp(input: $input) {
      app {
        ${AppFragmentFields}
      }
    }
  }
`;

export interface CreateCustomAppVariables {
  input: AppStoreAppInput;
}

export interface CreateCustomAppResponse {
  createCustomApp: {
    app: DesktopAppApiType;
  };
}

export const EDIT_CUSTOM_APP = gql`
  mutation EditCustomApp($input: editCustomAppInput!) {
    __typename
    editCustomApp(input: $input) {
      app {
        ${AppFragmentFields}
      }
    }
  }
`;

export interface EditCustomAppVariables {
  input: AppStoreAppInput;
}

export interface EditCustomAppResponse {
  editCustomApp: {
    app: DesktopAppApiType;
  };
}

export const DELETE_CUSTOM_APP = gql`
  mutation DeleteCustomApp($input: deleteAppInput!) {
    deleteApp(input: $input) {
      app {
        id
      }
    }
  }
`;

export interface DeleteCustomAppVariables {
  input: {
    id: string;
  };
}

export interface DeleteCustomAppResponse {
  deleteApp: {
    app: {
      id: string;
    };
  };
}

export const CREATE_SSO_CONFIGURATION = gql`
  mutation CreateSsoConfiguration($input: createWorkspaceAppConfigInput!) {
    __typename
    createWorkspaceAppConfig(input: $input) {
      workspaceAppConfig {
        ${WorkspaceConfigFields}
      }
    }
  }
`;

export interface CreateSsoConfigurationVariables {
  input: {
    workspace: string;
    ssoGroup: string;
    fieldValues: SsoConfigurationInputType[];
  };
}

export interface CreateSsoConfigurationResponse {
  createWorkspaceAppConfig: {
    workspaceAppConfig: WorkspaceAppConfigurationApiType;
  };
}

export const UPDATE_SSO_CONFIGURATION = gql`
  mutation UpdateSsoConfiguration($input: updateWorkspaceAppConfigInput!) {
    __typename
    updateWorkspaceAppConfig(input: $input) {
      workspaceAppConfig {
        ${WorkspaceConfigFields}
      }
    }
  }
`;

export interface UpdateSsoConfigurationVariables {
  input: {
    id: string;
    fieldValues?: SsoConfigurationInputType[];
    enabled?: boolean;
  };
}

export interface UpdateSsoConfigurationResponse {
  updateWorkspaceAppConfig: {
    workspaceAppConfig: WorkspaceAppConfigurationApiType;
  };
}

export const DELETE_SSO_CONFIGURATION = gql`
  mutation UpdateSsoConfiguration($input: deleteWorkspaceAppConfigInput!) {
    deleteWorkspaceAppConfig(input: $input) {
      workspaceAppConfig {
        id
      }
    }
  }
`;

export interface DeleteSsoConfigurationVariables {
  input: {
    id: string;
  };
}

export interface DeleteSsoConfigurationResponse {
  deleteWorkspaceAppConfig: {
    workspaceAppConfig: {
      id: string;
    };
  };
}

export const CREATE_PREVIEW_IMAGE_ASSET = gql`
  mutation CreatePreviewImageAsset($input: createPreviewImageAssetInput!) {
    createPreviewImageAsset(input: $input) {
      imageAsset {
        id
        contentUrl
      }
    }
  }
`;

export interface CreatePreviewImageAssetVariables {
  input: {
    url: string;
  };
}

export interface CreatePreviewImageAssetResponse {
  createPreviewImageAsset: {
    imageAsset: {
      id: string;
      contentUrl: string;
    };
  };
}

export const CREATE_CUSTOM_APP_SSO_GROUP = gql`
  mutation CreateCustomAppSsoGroup($workspace: ID!, $app: ID!) {
    __typename
    createCustomAppSsoGroup(input: { workspace: $workspace, app: $app }) {
      appSsoGroup {
        ${AppSsoGroupFields}
      }
    }
  }
`;

export interface CreateCustomAppSsoGroupResponse {
  createCustomAppSsoGroup: {
    appSsoGroup: AppSsoGroupApiType;
  };
}

export interface CreateCustomAppSsoGroupVariables {
  workspace: string;
  app: string;
}
