import React, { FC } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { AccountApiType } from '../../../User/User.types';
import { DesktopTranslation } from '../../i18n';
import { CallAllMemberListItem } from './CallAllMemberList.item';
import {
  StyledAttentionImage,
  StyledCallAllMembersListContainer,
  StyledCallToOneMemberContainer,
  StyledEmptyList,
} from './CallAllMembersList.styled';
import attentionIcon from '../../../../shared/assets/images/attentionIcon.svg';

interface CallAllMembersListProps {
  accounts: AccountApiType[];
}

export const CallAllMembersList: FC<CallAllMembersListProps> = ({
  accounts,
}) => {
  const intl = useIntl();

  return (
    <>
      <StyledCallAllMembersListContainer>
        {accounts.length > 0 ? (
          accounts.map(account => (
            <CallAllMemberListItem key={account.id} account={account} />
          ))
        ) : (
          <StyledEmptyList>
            <FormattedHTMLMessage
              id={DesktopTranslation.callAllMembersEmptyList}
            />
          </StyledEmptyList>
        )}
      </StyledCallAllMembersListContainer>

      <StyledCallToOneMemberContainer>
        <StyledAttentionImage
          src={attentionIcon}
          alt={intl.formatMessage({
            id: DesktopTranslation.callToJustOneOrCoupleUsers,
          })}
        />

        <FormattedHTMLMessage
          id={DesktopTranslation.callToJustOneOrCoupleUsers}
        />
      </StyledCallToOneMemberContainer>
    </>
  );
};
