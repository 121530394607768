import styled from 'styled-components';

export const StyledUpgradeButton = styled.button`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 0.875rem;
  appearance: none;
  border: 0;
  background: transparent;
  margin: 0;
  width: 100%;
  text-align: left;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.BorderDefault};

  &:disabled {
    color: ${({ theme }) => theme.colors.DisabledElement};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
    background: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export const ButtonBorder = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.SurfaceBorder};
  margin: 0 ${({ theme }) => theme.spacing.m};
`;
