import styled, { css } from 'styled-components';

import { WorkspaceAccountGroupIdentity } from '../User.constants';

interface GroupProps {
  groupIdentifier: string;
  hideMembers?: boolean;
}

export const GroupTag = styled.div<GroupProps>`
  display: inline-block;
  padding: 0.125rem 0.375rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
  color: ${({ theme }) => theme.colors.GroupTag};
  font-size: 0.75rem;
  pointer-events: none;
  line-height: 1.34;

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.GUEST &&
    css`
      background-color: ${props.theme.colors.SurfaceSubdued};
      color: ${props.theme.colors.OnSurfaceMediumEmphasis};
    `}

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.MEMBER &&
    css`
      background-color: ${props.theme.colors.Primary};
      color: ${props.theme.colors.PrimaryTextLight};
    `}

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.MEMBER &&
    props.hideMembers &&
    css`
      display: none;
    `}

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.OWNER &&
    css`
      background-color: ${props.theme.colors.OnSurfaceMediumEmphasis};
      color: ${props.theme.colors.PrimaryTextLight};
    `}

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.ADMIN &&
    css`
      background-color: ${props.theme.colors.OnSurfaceMediumEmphasis};
      color: ${props.theme.colors.PrimaryTextLight};
    `}

  ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.PENDING &&
    css`
      background-color: ${props.theme.colors.Pending};
      color: ${props.theme.colors.PrimaryTextLight};
    `}

    ${props =>
    props.groupIdentifier === WorkspaceAccountGroupIdentity.SUSPENDED &&
    css`
      background-color: ${props.theme.colors.SurfaceSubdued};
      color: ${props.theme.colors.OnSurfaceMediumEmphasis};
    `}
`;
