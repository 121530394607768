import { getCookie } from './storage';

const LOG_AUTH_TO_SENTRY_COOKIE = 'webtopCom _LogAuthToSentry';
const LOG_CALL_JOIN_TO_SENTRY_COOKIE = 'webtopCom _LogCallJoinToSentry';
const LOG_CALL_CREATION_TO_SENTRY_COOKIE = 'webtopCom _LogCallCreationToSentry';

export const shouldLogAuthToSentry = () => {
  return getCookie(LOG_AUTH_TO_SENTRY_COOKIE) === 'true';
};

export const shouldLogCallJoinToSentry = () => {
  return getCookie(LOG_CALL_JOIN_TO_SENTRY_COOKIE) === 'true';
};

export const shouldLogCallCreationToSentry = () => {
  return getCookie(LOG_CALL_CREATION_TO_SENTRY_COOKIE) === 'true';
};
