import React, { FC, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../shared/hooks';
import { AppTopBar } from '../AppTopBar';
import {
  NoAccessScreen,
  SegmentLayout,
  SegmentMobileWorkspaceSwitcher,
  StyledSegment,
} from './Segment.styled';
import { LockdownMessage } from '../Workspace/LockdownMessage';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { WORKSPACE_ADMIN_BILLING_PATHNAME } from '../Workspace/Workspace.constants';
import {
  useCurrentWorkspaceInfo,
  useCurrentWorkspacePermissions,
} from '../Workspace/Workspace.hooks';
import { WorkspaceTranslation } from '../Workspace/i18n';
import { billingIsOk } from '../Billing/Billing.utils';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../shared/components/Button/Button';
import { LOGOUT_PATHNAME } from '../Auth/Auth.constants';
import { WorkspaceSelector } from '../Workspace/WorkspaceSelector/WorkspaceSelector';
import {
  ChatSearchMessageContext,
  SearchMessageType,
} from '../Chat/Chat.context';
import { withSegmentWrapper } from './SegmentWrapper';
import { WorkspaceApiType } from '../Workspace/Workspace.types';
import { ManageTagModal } from '../Link/LinksView/Modals/ManageTagModal';
import { EditLinkModal } from '../Link/LinksView/Modals/EditLinkModal';
import { LinkDetailsModal } from '../Link/Modals/LinkDetailsModal/LinkDetailsModal';
import { Route, Routes } from '../ErrorInterceptor/router.injectors';
import { DesktopSegment } from '../Desktop/DesktopSegment/DesktopSegment';
import {
  DESKTOP_ID_SLUG,
  DESKTOP_ROOT_ROUTE_PATH,
  DESKTOP_TAB_SLUG,
  FAVORITES_ROOT_ROUTE_PATH,
  FAVORITES_TAB_SLUG,
  PASSWORDS_ROOT_ROUTE_PATH,
  PASSWORDS_VAULT_SLUG,
} from '../Desktop/Desktop.constants';
import { ConferenceSegment } from '../Conference/ConferenceSegment/ConferenceSegment';
import { CONFERENCE_ROOT_ROUTE_PATH } from '../Conference/Conference.constants';
import { ChatSegment } from '../Chat/ChatSegment/ChatSegment';
import { getQueryParamsFrom } from '../../shared/utils/url.utils';
import { SegmentSwitcher } from './SegmentSwitcher/SegmentSwitcher';
import { CHAT_ROOT_ROUTE_PATH } from '../Chat/Chat.constants';
import { WorkspaceAccountGroupIdentity } from '../User/User.constants';
import { getShortId } from '../../shared/utils/id';

interface SegmentProps {
  workspacesLoading: boolean;
  workspace?: WorkspaceApiType;
}

export const Segment: FC<SegmentProps> = withSegmentWrapper(
  ({ workspacesLoading, workspace }) => {
    const isMobile = useMobile();
    const queryParams = useQueryParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { workspaceInfo } = useCurrentWorkspaceInfo();
    const {
      permissions: { canUpgrade, canDowngrade },
    } = useCurrentWorkspacePermissions();
    const [searchMessageData, setSearchMessageData] =
      useState<SearchMessageType>();

    const isCurrentRoutDesktop = pathname.includes('/desktop');

    return (
      <ChatSearchMessageContext.Provider
        value={{
          searchMessage: searchMessageData,
          setSearchMessage: setSearchMessageData,
        }}>
        <StyledSegment data-testid="segment">
          {isMobile && (
            <SegmentMobileWorkspaceSwitcher>
              <WorkspaceSelector lightBackground={true} />
            </SegmentMobileWorkspaceSwitcher>
          )}
          <AppTopBar />
          <SegmentLayout isMobile={isMobile} data-testid="segment-layout">
            {!workspace && !workspacesLoading && (
              <NoAccessScreen data-testid="no-access-screen">
                <FormattedMessage id={WorkspaceTranslation.noAccessMessage} />
                <Button
                  className="logoutButton"
                  size={ButtonSize.sm}
                  mode={ButtonMode.secondary}
                  onClick={() =>
                    navigate({
                      pathname: LOGOUT_PATHNAME,
                      search: getQueryParamsFrom({
                        sendLogoutMessageToOtherTabs: true,
                      }),
                    })
                  }
                  data-testid="logout-button">
                  <FormattedMessage id={WorkspaceTranslation.menuLogoutLink} />
                </Button>
              </NoAccessScreen>
            )}

            {workspace &&
              !billingIsOk(workspace) &&
              (!workspace.isUpgrading ||
                (workspace.isUpgrading && !canUpgrade && !canDowngrade)) && (
                <LockdownMessage data-testid="lockdown-message">
                  {workspaceInfo?.role ===
                  WorkspaceAccountGroupIdentity.OWNER ? (
                    <FormattedHTMLMessage
                      id={WorkspaceTranslation.billingNotOkOwner}
                      values={{
                        billingUrl: generatePath(
                          WORKSPACE_ADMIN_BILLING_PATHNAME,
                          {
                            workspaceId: getShortId(workspace.id),
                          },
                        ),
                      }}
                    />
                  ) : (
                    <FormattedHTMLMessage
                      id={WorkspaceTranslation.billingNotOk}
                      values={{
                        workspaceName: workspace.name,
                      }}
                    />
                  )}
                </LockdownMessage>
              )}

            {workspace &&
              (billingIsOk(workspace) ||
                (workspace.isUpgrading && (canUpgrade || canDowngrade))) && (
                <>
                  <SegmentSwitcher />
                  <Routes>
                    <Route
                      path={CHAT_ROOT_ROUTE_PATH}
                      element={<ChatSegment />}
                    />
                    <Route
                      path={PASSWORDS_ROOT_ROUTE_PATH}
                      element={<DesktopSegment />}>
                      <Route
                        path={PASSWORDS_VAULT_SLUG}
                        element={<DesktopSegment />}
                      />
                    </Route>
                    <Route
                      path={CONFERENCE_ROOT_ROUTE_PATH}
                      element={<ConferenceSegment />}
                    />

                    <Route
                      path={FAVORITES_ROOT_ROUTE_PATH}
                      element={<DesktopSegment />}>
                      <Route
                        path={FAVORITES_TAB_SLUG}
                        element={<DesktopSegment />}
                      />
                    </Route>

                    <Route
                      path={DESKTOP_ROOT_ROUTE_PATH}
                      element={<DesktopSegment />}>
                      <Route
                        path={DESKTOP_ID_SLUG}
                        element={<DesktopSegment />}>
                        <Route
                          path={DESKTOP_TAB_SLUG}
                          element={<DesktopSegment />}
                        />
                      </Route>
                    </Route>
                  </Routes>
                  {/* Placed here as they may be opened by other segments */}
                  {queryParams.linkDetails && !isCurrentRoutDesktop && (
                    <LinkDetailsModal />
                  )}
                  {queryParams.editLink && !isCurrentRoutDesktop && (
                    <EditLinkModal />
                  )}
                  {queryParams.manageTag && (
                    <ManageTagModal
                      onRequestClose={() => {
                        navigate({
                          search: getQueryParamsFrom({
                            ...queryParams,
                            manageTag: undefined,
                          }),
                        });
                      }}
                      isOpen
                    />
                  )}
                </>
              )}
          </SegmentLayout>
        </StyledSegment>
      </ChatSearchMessageContext.Provider>
    );
  },
);
