import React, { FC, PropsWithChildren, useCallback } from 'react';
import { LinkActionsContext } from './LinkActions.context';
import { LinkApiType } from '../../Link.types';
import { useCaseDeleteLinks } from '../../UseCase/deleteLink';
import { useCurrentDesktopSelectedLinksMap } from '../../../Desktop/DesktopItemMultiselect/DesktopItemMultiselect.hooks';
import { SectionLinksViewModel } from '../SectionLinks/ViewModel';
import { FolderApiType } from '../../../Folder/Folder.types';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { LinkTranslation } from '../../i18n';
import { useCaseMoveLink } from '../../UseCase/editLink';

interface LinkActionsProviderProps extends PropsWithChildren {
  links: LinkApiType[];
}

export const LinkActionsProvider: FC<LinkActionsProviderProps> = ({
  children,
  links,
}) => {
  const { filterString, refetchFilteredLinksData, handleCopyLinks } =
    SectionLinksViewModel();
  const {
    currentDesktopSelectedLinksMap,
    toggleCurrentDesktopSelectedLink,
    selectMultipleCurrentDesktopLinks,
    unselectAllCurrentDesktopLinks,
  } = useCurrentDesktopSelectedLinksMap();

  const handleLinkCopy = useCallback(
    async (linkToCopy: LinkApiType) => {
      const isPresentInSelectedLinks =
        currentDesktopSelectedLinksMap[linkToCopy.id];
      const linksToCopy = isPresentInSelectedLinks
        ? links.filter(link => currentDesktopSelectedLinksMap[link.id])
        : [linkToCopy];
      const linkIdsToCopy = linksToCopy.map(link => link.id);

      // copy to clipboard
      handleCopyLinks(linkIdsToCopy);

      // display success message
      if (linksToCopy.length > 1) {
        showToastSuccessMessage(LinkTranslation.linksCopiedMessage, {
          count: linksToCopy.length,
        });
      } else {
        showToastSuccessMessage(LinkTranslation.linkCopiedMessage);
      }

      // remove links selection
      if (isPresentInSelectedLinks) {
        unselectAllCurrentDesktopLinks();
      }
    },
    [
      links,
      handleCopyLinks,
      currentDesktopSelectedLinksMap,
      unselectAllCurrentDesktopLinks,
    ],
  );

  const { moveLinksToFolder } = useCaseMoveLink();
  const handleLinkMoveTo = useCallback(
    async (linkToMove: LinkApiType, folder?: FolderApiType) => {
      const isPresentInSelectedLinks =
        currentDesktopSelectedLinksMap[linkToMove.id];
      const linksToMove = isPresentInSelectedLinks
        ? links.filter(link => currentDesktopSelectedLinksMap[link.id])
        : [linkToMove];

      const response = await moveLinksToFolder(
        linksToMove.map(link => link.id),
        folder?.id || null,
      );
      if (response?.errors) {
        showToastGraphQLErrors(response.error);
      }

      if (isPresentInSelectedLinks && response && !response.errors) {
        unselectAllCurrentDesktopLinks();
      }
    },
    [
      links,
      moveLinksToFolder,
      currentDesktopSelectedLinksMap,
      unselectAllCurrentDesktopLinks,
    ],
  );

  const { deleteLinks } = useCaseDeleteLinks();
  const handleLinkDelete = useCallback(
    async (linkToDelete: LinkApiType) => {
      const isPresentInSelectedLinks =
        currentDesktopSelectedLinksMap[linkToDelete.id];
      const linksToDelete = isPresentInSelectedLinks
        ? links.filter(link => currentDesktopSelectedLinksMap[link.id])
        : [linkToDelete];

      const response = await deleteLinks(linksToDelete);
      if (response?.errors) {
        showToastGraphQLErrors(response.error);
      }

      if (isPresentInSelectedLinks && response && !response.errors) {
        unselectAllCurrentDesktopLinks();
      }

      // TODO: move this inside the usecase
      if (filterString && response && !response.error) {
        refetchFilteredLinksData();
      }
    },
    [
      links,
      deleteLinks,
      unselectAllCurrentDesktopLinks,
      refetchFilteredLinksData,
      filterString,
      currentDesktopSelectedLinksMap,
    ],
  );

  const toggleSelectedDesktopLink = useCallback(
    (linkId: string) => {
      toggleCurrentDesktopSelectedLink(linkId);
    },
    [toggleCurrentDesktopSelectedLink],
  );

  const handleSelectAllDesktopLinks = useCallback(() => {
    selectMultipleCurrentDesktopLinks(links.map(link => link.id));
  }, [links, selectMultipleCurrentDesktopLinks]);

  const handleUnselectAllDesktopLinks = useCallback(() => {
    unselectAllCurrentDesktopLinks();
  }, [unselectAllCurrentDesktopLinks]);

  return (
    <LinkActionsContext.Provider
      value={{
        onLinkCopy: handleLinkCopy,
        onLinkMoveTo: handleLinkMoveTo,
        onLinkDelete: handleLinkDelete,
        onSelectClick: toggleSelectedDesktopLink,
        onUnselectClick: toggleSelectedDesktopLink,
        onSelectAllClick: handleSelectAllDesktopLinks,
        onUnselectAllClick: handleUnselectAllDesktopLinks,
      }}>
      {children}
    </LinkActionsContext.Provider>
  );
};
