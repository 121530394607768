import React, { FC, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalHeaderTitle,
  ModalProps,
} from '../../../../shared/components/Modal';
import { ReencryptVaultAccesses } from '../ReencryptVaultAccesses';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';
import { ReencryptVaultAccessesModalContent } from './ReencryptVaultAccessesModal.styled';

interface ReencryptVaultAccessesModalProps extends Omit<ModalProps, 'visible'> {
  reencryptVaultAccessesRequestId: string;
}

export const ReencryptVaultAccessesModal: FC<ReencryptVaultAccessesModalProps> = ({
  reencryptVaultAccessesRequestId,
  onRequestClose,
  ...props
}) => {
  useEffect(() => {
    if (!reencryptVaultAccessesRequestId) {
      onRequestClose();
    }
  }, [onRequestClose, reencryptVaultAccessesRequestId]);

  return (
    <Modal
      visible={true}
      width={356}
      onRequestClose={onRequestClose}
      {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <div>
          <ModalHeaderTitle data-testid="title">
            <FormattedMessage
              id={EncryptionTranslation.reencryptAccessesModalHeader}
            />
          </ModalHeaderTitle>
        </div>
      </ModalHeader>
      <ReencryptVaultAccessesModalContent>
        <ReencryptVaultAccesses
          reencryptVaultAccessesRequestId={reencryptVaultAccessesRequestId}
          onDone={onRequestClose}
        />
      </ReencryptVaultAccessesModalContent>
    </Modal>
  );
};
