import React, { FC } from 'react';
import { useCurrentConference } from '../../../Conference.hooks';
import { ConferenceHeaderUsersTitle } from './ConferenceHeaderUsersTitle';
import {
  ConferenceHeaderTitle,
  StyledConferenceHeader,
} from './ConferenceHeader.styled';
import { ConferenceAvatar } from '../../../ConferenceAvatar';

export const ConferenceHeader: FC = () => {
  const { conference } = useCurrentConference();
  return (
    <StyledConferenceHeader>
      {conference && (
        <ConferenceAvatar
          conference={conference}
          avatarSize="80px"
          showOnlineStatus={false}
        />
      )}
      <ConferenceHeaderTitle>
        {conference?.desktop?.name || <ConferenceHeaderUsersTitle />}
      </ConferenceHeaderTitle>
    </StyledConferenceHeader>
  );
};
