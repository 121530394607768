export const OVERSCAN_MAIN = 700;
export const OVERSCAN_REVERSE = 700;
export const DELAY_FOR_SCROLLING_TO_MESSAGE = 0;
export const DELAY_FOR_HIGHLIGHTING_MESSAGE = 300;
export const MESSAGE_MIN_HEIGHT = 60;
export const DELAY_FOR_SCROLL_TO_LAST_MESSAGE = 200;
export const MESSAGE_LIST_PAGE_SIZE = 50;
export const MESSAGE_LIST_YEAR_FROM = 2020;
export const MESSAGE_LIST_START_INDEX = 10000;
export const DEBOUNCED_AT_BOTTOM_STATE_CHANGE = 300;
export const DEBOUNCED_SCROLL_TO_BOTTOM = 200;
