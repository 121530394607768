import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useIntl } from 'react-intl';
import { useQueryParams } from '../../../../shared/hooks';
import { openUrl } from '../../../../shared/utils/url.utils';
import { getGoogleMultipleAccountsOpenUri } from '../../../AppStore/AppStore.utils';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { displayInstallPluginToast } from '../../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { AppLoginMethod } from '../../../Desktop/Desktop.constants';
import { useAppLoginMethod } from '../../../Desktop/Desktop.hooks';
import {
  sendCredentialsToInsert,
  useCurrentTypeOfPage,
} from '../../../Desktop/Desktop.utils';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { useCaseCreateFavorite } from '../../../Desktop/UseCase/createFavorite';
import { useCaseDeleteFavorite } from '../../../Desktop/UseCase/deleteFavorite';
import { DragAppItemType, DragType } from '../../../Drag';
import { getDecryptedVaultPassword } from '../../../Encryption/Encryption.crypto.utils';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useDesktopAppVault,
  useDesktopAppVaultLogin,
} from '../../../Encryption/Encryption.hooks';
import { OpenType } from '../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../../Timeline/Timeline.constants';
import { useTimelineEventTracker } from '../../../Timeline/Timeline.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  DesktopAppEdgeApiType,
  DesktopAppGroupType,
} from '../../../Desktop/data/Desktop/types/Desktop.types';
import { sendAppTrackingEvent } from '../../../Desktop/tracking/DesktopTracking.utils';

export const AppLinkViewModel = (
  app: DesktopAppEdgeApiType,
  dragEnabled: boolean,
) => {
  // hooks
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const intl = useIntl();
  const queryParams = useQueryParams();
  const trackTimelineEvent = useTimelineEventTracker();
  const { keyset } = useCurrentAccountKeyset();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const vault = useDesktopAppVault(
    app?.app.id,
    app?.app.groupApp?.id,
    app.desktop?.id,
  );
  const { login } = useDesktopAppVaultLogin(app?.app.id, app.desktop?.id);
  const appLoginMethod = useAppLoginMethod(app);
  const { createFavoriteUseCase } = useCaseCreateFavorite();
  const { deleteFavoriteUseCase } = useCaseDeleteFavorite();

  // states
  const [isUpdatingFavorite, setUpdatingFavorite] = useState(false);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isContextMenuOpened, setContextMenuOpened] = useState(false);

  const openType = account?.identity.openApp;
  const { showIosOptions } = queryParams;
  const keysetId = keyset?.id;

  const {
    app: {
      name,
      logo,
      loginUri,
      openUri,
      hasSsoOverride,
      loginUriPattern,
      groupApp,
      groupEnabled,
    },
    accountFavorite,
  } = app;

  const toggleFavoriteApp = useCallback(
    (triggeredFromIcon?: boolean) => {
      if (isUpdatingFavorite) {
        return;
      }
      setUpdatingFavorite(true);
      if (accountFavorite || isFavoritesDesktop) {
        deleteFavoriteUseCase(workspace.id, app.id).then(() => {
          setUpdatingFavorite(false);
        });
        sendAppTrackingEvent(
          triggeredFromIcon
            ? 'unfavorite_app_from_icon'
            : 'unfavorite_app_from_menu',
        );
      } else {
        createFavoriteUseCase(workspace.id, { desktopApp: app.id }).then(() => {
          setUpdatingFavorite(false);
        });
        sendAppTrackingEvent(
          triggeredFromIcon
            ? 'favorite_app_from_icon'
            : 'favorite_app_from_menu',
        );
      }
    },
    [
      accountFavorite,
      app.id,
      createFavoriteUseCase,
      deleteFavoriteUseCase,
      isFavoritesDesktop,
      isUpdatingFavorite,
      workspace.id,
    ],
  );

  const fillInCredentials = useCallback(() => {
    if (vault && keysetId && privateKey && login) {
      getDecryptedVaultPassword(vault, keysetId, privateKey).then(password => {
        if (password) {
          sendCredentialsToInsert(loginUri, login, password, loginUriPattern);
        }
      });
    }
  }, [login, vault, keysetId, privateKey, loginUri, loginUriPattern]);

  const openApp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      displayInstallPluginToast();

      trackTimelineEvent(
        TimelineEventScope.apps,
        app.id,
        TimelineEventAction.open,
      );

      const supportMultipleAccounts =
        groupEnabled && groupApp?.id === DesktopAppGroupType.google;
      const multipleAccountsAppOpenUri = supportMultipleAccounts
        ? getGoogleMultipleAccountsOpenUri(openUri, login)
        : null;

      const appOpenUri = multipleAccountsAppOpenUri ?? openUri;

      const appLoginUri = multipleAccountsAppOpenUri ?? loginUri;

      switch (appLoginMethod) {
        case AppLoginMethod.SSO:
          openUrl(account!.identity.openApp, appOpenUri, name, event);
          break;
        case AppLoginMethod.PWM:
          openUrl(account!.identity.openApp, appLoginUri, name, event);
          fillInCredentials();
          break;
        default:
          openUrl(account!.identity.openApp, appLoginUri, name, event);
          break;
      }
    },
    [
      trackTimelineEvent,
      app,
      groupApp,
      groupEnabled,
      login,
      openUri,
      loginUri,
      appLoginMethod,
      account,
      name,
      fillInCredentials,
    ],
  );

  const dragAppItemData: DragAppItemType = {
    type: DragType.APP,
    workspaceId: workspace.id,
    app,
  };

  const [{ isDragging }, appDragRef, preview] = useDrag<
    DragAppItemType,
    unknown,
    { isDragging: boolean }
  >({
    item: dragAppItemData,
    canDrag: dragEnabled && !isMenuOpened && !isContextMenuOpened,
    begin: monitor => {
      return {
        ...dragAppItemData,
        clientOffset: monitor.getInitialClientOffset(),
        sourceOffset: monitor.getInitialSourceClientOffset(),
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // do not display native preview
  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const isAppStandaloneNow = useCallback(() => {
    if ('standalone' in navigator) {
      return !!navigator.standalone;
    }
    return window.matchMedia('(display-mode: standalone)').matches;
  }, []);

  const appLinkTarget = useCallback((): string => {
    return isAppStandaloneNow()
      ? '_blank'
      : openType === OpenType.newTab
      ? '_blank'
      : '_self';
  }, [isAppStandaloneNow, openType]);

  const favoriteUiText = useMemo(
    (): string =>
      intl.formatMessage({
        id: accountFavorite
          ? DesktopTranslation.removeItemFromFavorites
          : DesktopTranslation.addItemToFavorites,
      }),
    [intl, accountFavorite],
  );

  return {
    isFavoritesDesktop,
    showIosOptions,
    accountFavorite,
    toggleFavoriteApp,
    openApp,
    isDragging,
    appLinkTarget,
    favoriteUiText,
    hasSsoOverride,
    openUri,
    loginUri,
    isMenuOpened,
    isContextMenuOpened,
    setContextMenuOpened,
    appLoginMethod,
    logo,
    login,
    name,
    setMenuOpened,
    appDragRef,
  };
};
