import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ExpandedSearchTabs,
  SearchResultCounter,
  SearchTabInner,
  StyledTabs,
} from '../ExpandedSearch.styled';
import {
  StyledTab,
  StyledTabList,
  TabsType,
} from '../../../../shared/components/Tabs';
import { TABS } from '../ExpandedSearch.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalSearchTranslation } from '../../i18n';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  AppsIcon,
  CalendarIcon,
  ChatIcon,
  LinkIcon,
} from '../../../../shared/icons';
import { TabPanel } from 'react-tabs';
import { useMobile, useTablet } from '../../../../shared/hooks';
import { TabsStateType } from '../index';
import { GlobalSearchResponse } from '../../GlobalSearch.queries';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  useGetTabsPermissionsState,
  useGlobalSearchByAllTabs,
  useGlobalSearchContext,
} from '../../GlobalSearch.hooks';
import {
  DEBOUNCE_TIME,
  RATE_LIMIT_CALLS,
  RATE_LIMIT_TIMEOUT,
  SEARCH_CHAR_LIMIT,
} from '../../GlobalSearch.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { SearchResultView } from '../SearchResultView';
import { useDebounce } from 'use-debounce';
import { SearchOnlyByConversation } from '../SearchResultView/SearchOnlyByConversation';
import { TooltipPlace } from '../../../../shared/components/Tooltip';

const ICON_SIZE = 16;

interface GlobalSearchTabsType {
  tabsState: TabsStateType;
  setTabsState: (value: TabsStateType) => void;
  searchQuery: string;
  handleTabSearchState: (prevState: TabsStateType | {}) => void;
  searchSectionBlur: (redirectTo?: string) => void;
  initialTabsState: TabsStateType;
  skipBlurRef: MutableRefObject<boolean>;
}

export const SearchGlobal: FC<GlobalSearchTabsType> = ({
  setTabsState,
  tabsState,
  searchQuery,
  handleTabSearchState,
  searchSectionBlur,
  initialTabsState,
  skipBlurRef,
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();

  const {
    searchOnlyByConversation,
    selectedTabCategoryKey,
    selectedTab,
    setSelectedTab,
  } = useGlobalSearchContext();

  const tabsPermissions = useGetTabsPermissionsState();

  const handleTabClick = useCallback(
    (value: TABS) => {
      setSelectedTab(value);
    },
    [setSelectedTab],
  );

  useEffect(
    () => () => {
      RATE_LIMIT_CALLS.length = 0;
    },
    [],
  );

  const [debouncedFilterValue] = useDebounce(searchQuery, DEBOUNCE_TIME);

  useEffect(() => {
    if (
      debouncedFilterValue &&
      debouncedFilterValue.length >= SEARCH_CHAR_LIMIT &&
      tabsState[selectedTabCategoryKey].prevSearch &&
      tabsState[selectedTabCategoryKey].prevSearch !== debouncedFilterValue
    ) {
      handleTabSearchState(initialTabsState);
    }
  }, [
    debouncedFilterValue,
    handleTabSearchState,
    searchQuery,
    selectedTab,
    tabsState,
    initialTabsState,
    selectedTabCategoryKey,
  ]);

  const collectApiCallsForRateLimit = useCallback(() => {
    RATE_LIMIT_CALLS.push(
      setTimeout(() => {
        RATE_LIMIT_CALLS.shift();
      }, RATE_LIMIT_TIMEOUT),
    );
  }, []);

  const [conversationId, setConversationId] = useState<string>('');

  const onCompleted = (data: GlobalSearchResponse, tab: TABS) => {
    collectApiCallsForRateLimit();
    const { pageInfo, totalCount } = data.listGlobalSearches;
    const extractedData = extractNodes(data.listGlobalSearches);
    const activeTabState = {
      [tab]: {
        data: extractedData,
        pageInfo: pageInfo,
        totalCount: totalCount,
        prevSearch: searchQuery,
        prevConversationId: conversationId ?? '',
      },
    };
    handleTabSearchState((prevState: TabsStateType) => ({
      ...prevState,
      ...activeTabState,
      [TABS.ALL_CONTENT]: {
        data:
          conversationId === prevState[TABS.CHATS]?.prevConversationId ||
          !conversationId
            ? [...prevState[TABS.ALL_CONTENT].data, ...extractedData]
            : [...prevState[TABS.ALL_CONTENT].data],
        totalCount:
          prevState[TABS.ALL_CONTENT].totalCount +
          (!!totalCount &&
          (!conversationId ||
            conversationId === prevState[TABS.CHATS]?.prevConversationId)
            ? totalCount
            : 0),
        prevSearch: searchQuery,
      },
    }));
  };

  const globalSearchQueryData = useGlobalSearchByAllTabs(
    onCompleted,
    searchQuery,
    tabsState,
    workspace.id,
    conversationId,
  );

  const tabLoading = (tab: TABS) => {
    if (tab === TABS.ALL_CONTENT) {
      return [TABS.APPS, TABS.LINKS, TABS.CHATS, TABS.MEETINGS].some(
        tab => globalSearchQueryData[tab].loading,
      );
    }

    return globalSearchQueryData[tab].loading;
  };

  const tabSearchResultCounter = (tab: TABS) => {
    return tabsState[tab]?.totalCount;
  };

  return (
    <>
      <ExpandedSearchTabs>
        <StyledTabs
          selectedIndex={Number(selectedTab) || 0}
          onSelect={() => {}}>
          <>
            <StyledTabList
              type={TabsType.tabs}
              tabwidth={100}
              data-isvisiblescroll={isMobile || isTablet}>
              <StyledTab
                onClick={() => handleTabClick(TABS.ALL_CONTENT)}
                value={TABS.ALL_CONTENT}>
                <FormattedMessage
                  id={GlobalSearchTranslation.expandedSearchAllContent}
                />
                {tabLoading(TABS.ALL_CONTENT) && <Spinner size={16} />}

                {!tabLoading(TABS.ALL_CONTENT) && (
                  <SearchResultCounter>
                    {tabSearchResultCounter(TABS.ALL_CONTENT)}
                  </SearchResultCounter>
                )}
              </StyledTab>
              {tabsPermissions[TABS.APPS] && (
                <StyledTab
                  value={TABS.APPS}
                  onClick={() => handleTabClick(TABS.APPS)}>
                  <AppsIcon height={ICON_SIZE} width={ICON_SIZE} />
                  <FormattedMessage
                    id={GlobalSearchTranslation.expandedSearchAppsTab}
                  />
                  {tabLoading(TABS.APPS) && <Spinner size={16} />}

                  {!tabLoading(TABS.APPS) && (
                    <SearchResultCounter>
                      {tabSearchResultCounter(TABS.APPS)}
                    </SearchResultCounter>
                  )}
                </StyledTab>
              )}
              {tabsPermissions[TABS.LINKS] && (
                <StyledTab
                  value={TABS.LINKS}
                  onClick={() => handleTabClick(TABS.LINKS)}>
                  <LinkIcon height={ICON_SIZE} width={ICON_SIZE} />
                  <FormattedMessage
                    id={GlobalSearchTranslation.expandedSearchLinksTab}
                  />
                  {tabLoading(TABS.LINKS) && <Spinner size={16} />}

                  {!tabLoading(TABS.LINKS) && (
                    <SearchResultCounter>
                      {tabSearchResultCounter(TABS.LINKS)}
                    </SearchResultCounter>
                  )}
                </StyledTab>
              )}
              {tabsPermissions[TABS.CHATS] && (
                <StyledTab
                  value={TABS.CHATS}
                  onClick={() => handleTabClick(TABS.CHATS)}
                  data-value={TABS.CHATS}
                  width={170}>
                  <ChatIcon height={ICON_SIZE} width={ICON_SIZE} />
                  <FormattedMessage
                    id={GlobalSearchTranslation.expandedSearchChatsTab}
                  />
                  {tabLoading(TABS.CHATS) && <Spinner size={16} />}

                  {!tabLoading(TABS.CHATS) && (
                    <SearchResultCounter>
                      {tabSearchResultCounter(TABS.CHATS)}
                    </SearchResultCounter>
                  )}
                </StyledTab>
              )}
              {tabsPermissions[TABS.MEETINGS] && (
                <StyledTab
                  value={TABS.MEETINGS}
                  onClick={() => handleTabClick(TABS.MEETINGS)}>
                  <SearchTabInner
                    data-tooltip-place={TooltipPlace.top}
                    data-tooltip-content={formatMessage({
                      id: GlobalSearchTranslation.tooltipMeetingsTab,
                    })}>
                    <CalendarIcon height={ICON_SIZE} width={ICON_SIZE} />
                    <FormattedMessage
                      id={GlobalSearchTranslation.expandedSearchMeetingsTab}
                    />
                  </SearchTabInner>
                  {tabLoading(TABS.MEETINGS) && <Spinner size={16} />}

                  {!tabLoading(TABS.MEETINGS) && (
                    <SearchResultCounter>
                      {tabSearchResultCounter(TABS.MEETINGS)}
                    </SearchResultCounter>
                  )}
                </StyledTab>
              )}
            </StyledTabList>
            {Object.keys(initialTabsState).map((elem, idx) => (
              <TabPanel key={'tab-global-search-' + idx} />
            ))}
          </>
        </StyledTabs>
      </ExpandedSearchTabs>

      {selectedTabCategoryKey === TABS.CHATS && (
        <SearchOnlyByConversation
          setConversationId={setConversationId}
          checked={!!conversationId || searchOnlyByConversation}
        />
      )}
      <SearchResultView
        tabsState={tabsState}
        searchQuery={searchQuery}
        searchSectionBlur={searchSectionBlur}
        handleTabSearchState={setTabsState}
        globalSearchQueryData={globalSearchQueryData}
        skipBlurRef={skipBlurRef}
        searchOnlyByConversation={!!conversationId || searchOnlyByConversation}
      />
    </>
  );
};
