import React, { FC, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormSelect } from '../../../../shared/components/Select';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountApiType, EditUserFormValues } from '../../User.types';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../User.constants';
import { UserTranslation } from '../../i18n';
import { StyledControls } from './EditUser.styled';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getShortId } from '../../../../shared/utils/id';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';

interface EditUserFormProps {
  account: AccountApiType;
  groupIdentifier: GroupIdentity;
  onSubmit: (values: EditUserFormValues) => void;
  onCancel: () => void;
}

export const EditUserForm: FC<EditUserFormProps> = ({
  account,
  groupIdentifier,
  onSubmit,
  onCancel,
}) => {
  const { formatMessage } = useIntl();
  const initialValues: EditUserFormValues = {
    id: account.id,
    groupIdentifier: groupIdentifier,
  };
  const { workspace } = useCurrentWorkspace();

  const { account: currentAccount } = useCurrentAccount();
  const currentAccountWorkspaceInfo = useMemo(() => {
    return currentAccount?.workspaceInfo?.find(
      info => info.id === getShortId(workspace.id),
    );
  }, [currentAccount, workspace.id]);
  const currentAccountGroupIdentifier = currentAccountWorkspaceInfo?.role;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="user-form">
          <div>
            <Field
              name="groupIdentifier"
              label={formatMessage({
                id: UserTranslation.adminUserFormRoleLabel,
              })}
              data-testid="user-form-group-select"
              component={FormSelect}>
              {groupIdentifier !== GroupIdentity.OWNER && (
                <option value={GroupIdentity.MEMBER}>
                  {formatMessage({
                    id: UserTranslation[GroupIdentity.MEMBER],
                  })}
                </option>
              )}

              {groupIdentifier !== GroupIdentity.OWNER &&
                (currentAccountGroupIdentifier === GroupIdentity.OWNER ||
                  currentAccountGroupIdentifier === GroupIdentity.ADMIN ||
                  groupIdentifier === GroupIdentity.ADMIN) && (
                  <option value={GroupIdentity.ADMIN}>
                    {formatMessage({
                      id: UserTranslation[GroupIdentity.ADMIN],
                    })}
                  </option>
                )}

              {groupIdentifier === GroupIdentity.OWNER && (
                <option value={GroupIdentity.OWNER}>
                  {formatMessage({
                    id: UserTranslation[GroupIdentity.OWNER],
                  })}
                </option>
              )}
            </Field>

            <StyledControls>
              <Button
                data-testid="user-form-cancel-button"
                type="button"
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}>
                <FormattedMessage
                  id={UserTranslation.adminUserFormCancelButton}
                />
              </Button>
              <Button
                data-testid="user-form-submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={isSubmitting}>
                <FormattedMessage
                  id={UserTranslation.adminUserFormSubmitButton}
                />
              </Button>
            </StyledControls>
          </div>
        </Form>
      )}
    </Formik>
  );
};
