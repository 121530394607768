import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const CallIcon: FC<IconProps> = props => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.43466 8.85454C6.34785 8.19075 5.30896 7.15185 4.64516 6.06504C4.46695 5.77326 4.4852 5.40795 4.66111 5.11477L5.0868 4.40529C5.29928 4.05116 5.27221 3.60284 5.01866 3.27685L3.95443 1.90856C3.4496 1.25949 2.47083 1.25278 1.95716 1.89488C0.829103 3.30495 0.475603 5.24063 1.36818 6.81037C2.60814 8.99103 4.28629 10.7714 6.69334 12.141C8.26014 13.0325 10.191 12.6736 11.5987 11.5475C12.2439 11.0313 12.2372 10.0477 11.5849 9.54042L10.2229 8.48104C9.89686 8.2275 9.44855 8.20042 9.09442 8.4129L8.38493 8.83859C8.09175 9.0145 7.72645 9.03276 7.43466 8.85454Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
