import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../i18n';
import {
  BusinessIcon,
  PeopleGroupIcon,
  PeopleIcon,
  PersonIcon,
} from '../../../../shared/icons';
import { AboutTeamFormValues, TeamSizeType } from '../../Onboarding.types';
import {
  AboutTeamFormField,
  AboutTeamNextButton,
} from './AboutTeamPage.styled';
import { OnboardingCaseButton } from '../UseCasePage/UseCasePage.styled';

export interface AboutTeamFormProps {
  onSubmit: (values: AboutTeamFormValues) => void;
}

export const initialValues: AboutTeamFormValues = {
  teamSize: TeamSizeType.just_you,
};

export const AboutTeamForm: FC<AboutTeamFormProps> = ({ onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form data-testid="about-team-form">
            <AboutTeamFormField>
              <Field
                name="teamSize"
                type="radio"
                value={TeamSizeType.just_you}
                component={() => (
                  <OnboardingCaseButton
                    icon={PersonIcon}
                    iconFirst={true}
                    mode={
                      values.teamSize === TeamSizeType.just_you
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('teamSize', TeamSizeType.just_you)
                    }
                    fullWidth
                    data-testid="just-you-button">
                    <span data-testid="title">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingYouTeamSizeTitle}
                      />
                    </span>
                    <span data-testid="subtitle">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingYouTeamSizeSubtitle}
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />

              <Field
                name="teamSize"
                type="radio"
                value={TeamSizeType.small}
                component={() => (
                  <OnboardingCaseButton
                    icon={PeopleIcon}
                    iconFirst={true}
                    mode={
                      values.teamSize === TeamSizeType.small
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('teamSize', TeamSizeType.small)
                    }
                    fullWidth
                    data-testid="small-team-button">
                    <span data-testid="title">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingSmallTeamSizeTitle}
                      />
                    </span>
                    <span data-testid="subtitle">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingSmallTeamSizeSubtitle
                        }
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />

              <Field
                name="teamSize"
                type="radio"
                value={TeamSizeType.medium}
                component={() => (
                  <OnboardingCaseButton
                    icon={PeopleGroupIcon}
                    iconFirst={true}
                    mode={
                      values.teamSize === TeamSizeType.medium
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('teamSize', TeamSizeType.medium)
                    }
                    fullWidth
                    data-testid="medium-team-button">
                    <span data-testid="title">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingMediumTeamSizeTitle}
                      />
                    </span>
                    <span data-testid="subtitle">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingMediumTeamSizeSubtitle
                        }
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />

              <Field
                name="teamSize"
                type="radio"
                value={TeamSizeType.large}
                component={() => (
                  <OnboardingCaseButton
                    icon={BusinessIcon}
                    iconFirst={true}
                    mode={
                      values.teamSize === TeamSizeType.large
                        ? ButtonMode.primary
                        : ButtonMode.secondary
                    }
                    size={ButtonSize.md}
                    onClick={() =>
                      setFieldValue('teamSize', TeamSizeType.large)
                    }
                    fullWidth
                    data-testid="large-team-button">
                    <span data-testid="title">
                      <FormattedMessage
                        id={OnboardingTranslation.onboardingLargeTeamSizeTitle}
                      />
                    </span>
                    <span data-testid="subtitle">
                      <FormattedMessage
                        id={
                          OnboardingTranslation.onboardingLargeTeamSizeSubtitle
                        }
                      />
                    </span>
                  </OnboardingCaseButton>
                )}
              />
            </AboutTeamFormField>

            <AboutTeamNextButton
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              fullWidth
              type="submit"
              disabled={isSubmitting || !values.teamSize}
              data-testid="next-button">
              <FormattedMessage
                id={OnboardingTranslation.onboardingUseCaseNextButton}
              />
            </AboutTeamNextButton>
          </Form>
        );
      }}
    </Formik>
  );
};
