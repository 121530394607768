export enum TABS {
  ALL_CONTENT = 0,
  APPS = 1,
  LINKS = 2,
  CHATS = 3,
  MEETINGS = 4,
}

export const TABS_MAP = {
  [TABS.ALL_CONTENT]: {
    includeDesktopAppHits: true,
    includeChatMessageHits: true,
    includeChatConversationHits: true,
    includeLinkHits: true,
    includeLinkCommentHits: true,
    includeRepeatingScheduleChatConferenceHits: true,
    includeScheduleChatConferenceHits: true,
    includeFolderHits: true,
  },
  [TABS.APPS]: {
    includeDesktopAppHits: true,
  },
  [TABS.LINKS]: {
    includeLinkHits: true,
    includeLinkCommentHits: true,
    includeFolderHits: true,
  },
  [TABS.CHATS]: {
    includeChatMessageHits: true,
    includeChatConversationHits: true,
  },
  [TABS.MEETINGS]: {
    includeRepeatingScheduleChatConferenceHits: true,
    includeScheduleChatConferenceHits: true,
  },
};

export enum SearchResultTypes {
  app = 'desktop_app',
  link = 'link',
  linkComment = 'link_comment',
  folder = 'folder',
  chatConversation = 'chat_conversation',
  chatMessage = 'chat_message',
  meeting = 'schedule_chat_conference',
  repeatableMeeting = 'repeating_schedule_chat_conference',
}

export const TABS_SEARCH_RESULT = {
  [TABS.ALL_CONTENT]: [
    SearchResultTypes.app,
    SearchResultTypes.link,
    SearchResultTypes.linkComment,
    SearchResultTypes.folder,
    SearchResultTypes.chatMessage,
    SearchResultTypes.chatConversation,
    SearchResultTypes.meeting,
    SearchResultTypes.repeatableMeeting,
  ],
  [TABS.APPS]: [SearchResultTypes.app],
  [TABS.LINKS]: [
    SearchResultTypes.link,
    SearchResultTypes.folder,
    SearchResultTypes.linkComment,
  ],
  [TABS.CHATS]: [
    SearchResultTypes.chatMessage,
    SearchResultTypes.chatConversation,
  ],
  [TABS.MEETINGS]: [
    SearchResultTypes.meeting,
    SearchResultTypes.repeatableMeeting,
  ],
};

export const CONFERENCE_PATHNAME =
  '/workspace/:workspaceId/calls/:conferenceId';

export enum TABS_DIRECTION {
  left = 'left',
  right = 'right',
}
