import styled from 'styled-components';

const TABS_HEIGHT = 54;

export const StyledConferencesList = styled.div`
  position: relative;
  padding: 0.5rem;
  height: calc(100% - ${TABS_HEIGHT}px);
  overflow: auto;
`;
