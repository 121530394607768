import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import {
  ContentContainer,
  StyledSegmentSwitcher,
} from './SegmentSwitcher.styled';
import { GeneralSettingsButton } from '../../GeneralSettings';
import { SupportMenu } from './SupportMenu';
import {
  SegmentType,
  useGetCurrentSegment,
  useGetLastUsedSegment,
  useGetLastUsedSegmentPath,
  useSetLastUsedSegment,
} from '../';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  CHATS_ROOT_PATHNAME,
  CONFERENCES_ROOT_PATHNAME,
  DESKTOP_ROOT_PATHNAME,
  FAVORITES_ROOT_PATHNAME,
  PASSWORDS_ROOT_PATHNAME,
} from '../../Desktop/Desktop.constants';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { isCurrentAccountGuest } from '../../User/User.utils';
import { SegmentButtonDesktops } from './SegmentButton/SegmentButtonDesktops';
import { SegmentButtonChats } from './SegmentButton/SegmentButtonChats';
import { SegmentButtonMeetings } from './SegmentButton/SegmentButtonMeetings';
import { getShortId } from '../../../shared/utils/id';

export const SegmentSwitcher = forwardRef<any>((props, ref) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentAccount();
  const navigate = useNavigate();
  const currentSegment = useGetCurrentSegment();
  const lastSegment = useGetLastUsedSegment();
  const setLastSegment = useSetLastUsedSegment();
  const getLastSegmentPath = useGetLastUsedSegmentPath();
  const {
    permissions: {
      canMenuDesktopsSidebarItem,
      canMenuChatsSidebarItem,
      canMenuMeetingsSidebarItem,
      canRedirectToDesktopByDefault,
    },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  const isWorkspaceGuest: boolean | undefined = useMemo(
    () => isCurrentAccountGuest(account, getShortId(workspace.id)),
    [account, workspace.id],
  );

  const navigateToSegment = useCallback(
    (segment: SegmentType) => {
      if (segment !== currentSegment && workspace) {
        const lastSegmentPath = getLastSegmentPath(segment);

        switch (segment) {
          case SegmentType.DESKTOPS:
            navigate(
              lastSegmentPath ||
                generatePath(DESKTOP_ROOT_PATHNAME, {
                  workspaceId: getShortId(workspace.id),
                }),
            );
            break;
          case SegmentType.FAVORITES:
            navigate(
              generatePath(FAVORITES_ROOT_PATHNAME, {
                workspaceId: getShortId(workspace.id),
              }),
            );
            break;
          case SegmentType.CHATS:
            navigate(
              lastSegmentPath ||
                generatePath(CHATS_ROOT_PATHNAME, {
                  workspaceId: getShortId(workspace.id),
                }),
            );
            break;
          case SegmentType.MEETINGS:
            navigate(
              lastSegmentPath ||
                generatePath(CONFERENCES_ROOT_PATHNAME, {
                  workspaceId: getShortId(workspace.id),
                }),
            );
            break;
          case SegmentType.PASSWORDS:
            if (isWorkspaceGuest) break;
            navigate(
              lastSegmentPath ||
                generatePath(PASSWORDS_ROOT_PATHNAME, {
                  workspaceId: getShortId(workspace.id),
                }),
            );
            break;
        }
        setLastSegment(segment);
      }
    },
    [
      currentSegment,
      getLastSegmentPath,
      navigate,
      isWorkspaceGuest,
      setLastSegment,
      workspace,
    ],
  );

  /**
   * Open last segment on workspace changes
   */
  useEffect(() => {
    if (
      currentSegment === SegmentType.UNKNOWN &&
      workspace &&
      permissionsLoaded
    ) {
      if (lastSegment !== SegmentType.UNKNOWN) {
        if (lastSegment === SegmentType.DESKTOPS) {
          if (canRedirectToDesktopByDefault) {
            navigateToSegment(lastSegment);
          } else {
            navigateToSegment(SegmentType.CHATS);
          }
        } else {
          navigateToSegment(lastSegment);
        }
      } else {
        if (canRedirectToDesktopByDefault) {
          navigateToSegment(SegmentType.DESKTOPS);
        } else {
          navigateToSegment(SegmentType.CHATS);
        }
      }
    }
  }, [
    canRedirectToDesktopByDefault,
    currentSegment,
    lastSegment,
    navigateToSegment,
    permissionsLoaded,
    workspace,
  ]);

  return !(
    canMenuDesktopsSidebarItem &&
    !canMenuChatsSidebarItem &&
    !canMenuMeetingsSidebarItem
  ) ? (
    <StyledSegmentSwitcher data-testid="segment-switcher">
      <ContentContainer>
        {canMenuDesktopsSidebarItem && (
          <SegmentButtonDesktops navigateToSegment={navigateToSegment} />
        )}

        {canMenuChatsSidebarItem && (
          <SegmentButtonChats navigateToSegment={navigateToSegment} />
        )}

        {canMenuMeetingsSidebarItem && (
          <SegmentButtonMeetings navigateToSegment={navigateToSegment} />
        )}
      </ContentContainer>
      <ContentContainer>
        <GeneralSettingsButton data-tourid="general-settings-button" />
        <SupportMenu />
      </ContentContainer>
    </StyledSegmentSwitcher>
  ) : null;
});
