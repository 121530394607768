import React, { FC, useEffect, useState } from 'react';
import { Expand } from '../../../shared/components/Expand';
import {
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceAllAppRelatedVaultsList,
  useCurrentWorkspaceVaultLoginsMap,
  useSelectVaultItemForAppMutation,
  useUnselectForAppVaultItemConfigMutation,
} from '../Encryption.hooks';
import { EditVaultItem } from '../VaultItemForm';
import { ChooseVaultForDesktopListItem } from './ChooseVaultForDesktopListItem';
import {
  SelectVaultAddNewButton,
  SelectVaultCloseButton,
  SelectVaultControls,
  SelectVaultExpandBody,
  SelectVaultList,
} from './ChooseVaultForDesktop.styled';
import { useCurrentDesktop } from '../../Desktop/Desktop.hooks';
import { VaultApiType } from '../Encryption.types';
import { getVaultItem, getVaultItemConfigs } from '../Encryption.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { EncryptionTranslation } from '../i18n';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { CreateVaultItemModal } from '../VaultItemForm/CreateVaultItem/CreateVaultItemModal';
import { useModalControls } from '../../../shared/components/Modal/Modal.hooks';
import { CREATE_VAULT_PASSWORDS_MODAL_Z_INDEX } from '../../AppStore/AppStore.constants';
import { Spinner } from '../../../shared/components/Spinner';
import { MasterPasswordLockScreen } from '../MasterPasswordLockScreen';
import { extractNodes } from '../../../shared/api/api.utils';

interface SelectVaultProps {
  appId: string;
  groupAppId?: string;
  desktopIdOverride?: string;
  onDone: () => void;
}

export const ChooseVaultForDesktop: FC<SelectVaultProps> = ({
  appId,
  groupAppId,
  desktopIdOverride,
  onDone,
}) => {
  const intl = useIntl();
  const desktop = useCurrentDesktop();
  const desktopId = desktopIdOverride || desktop?.id;
  const createVaultModal = useModalControls();

  const { privateKey, decryptCurrentAccountPrivateKey } =
    useCurrentWorkspaceAccountPrivateKey();
  const { vaults, workspaceVaultsLoading } =
    useCurrentWorkspaceAllAppRelatedVaultsList(appId, groupAppId);

  const { loginsMap } = useCurrentWorkspaceVaultLoginsMap();

  const [selectedValues, setSelectedValues] = useState<boolean[]>([]);
  const [noneSelected, setNoneSelected] = useState(false);

  useEffect(() => {
    const selectedVaultItemConfigs = vaults.map(vault => {
      const vaultAppConfig = getVaultItemConfigs(vault).find(
        config => config.app.id === appId,
      );
      return extractNodes(vaultAppConfig?.appVaultItemConfigDesktops).some(
        desktopConfig => desktopConfig.desktop.id === desktopId,
      );
    });

    setSelectedValues(selectedVaultItemConfigs);
    setNoneSelected(!selectedVaultItemConfigs.some(value => value));
  }, [desktopId, appId, vaults?.length]); // eslint-disable-line

  const [selectVaultItemForAppMutation] = useSelectVaultItemForAppMutation();
  const handleVaultSelect = async (
    vault: VaultApiType,
    newSelectedIndex: number,
  ) => {
    if (desktopId) {
      setSelectedValues(() => {
        const newSelectedValues = new Array(selectedValues.length).fill(false);
        newSelectedValues[newSelectedIndex] = true;
        return newSelectedValues;
      });
      setNoneSelected(false);

      return selectVaultItemForAppMutation({
        variables: {
          input: {
            app: appId,
            desktop: desktopId,
            vaultItem: getVaultItem(vault).id,
          },
        },
      }).catch(e => showToastGraphQLErrors(e.graphQLErrors));
    }
  };

  const [unselectForAppVaultItemConfigMutation] =
    useUnselectForAppVaultItemConfigMutation();
  const handleNoneSelect = () => {
    if (desktopId) {
      setNoneSelected(true);
      setSelectedValues(() => selectedValues.map(() => false));
      return unselectForAppVaultItemConfigMutation({
        variables: {
          input: {
            id: appId,
            desktop: desktopId,
          },
        },
      }).catch(e => showToastGraphQLErrors(e.graphQLErrors));
    }
  };

  if (workspaceVaultsLoading) {
    return <Spinner containerHeight={120} />;
  }

  if (!privateKey) {
    return (
      <MasterPasswordLockScreen
        decryptCurrentAccountPrivateKey={decryptCurrentAccountPrivateKey}
      />
    );
  }

  return (
    <>
      <SelectVaultList data-testid="select-vault-list">
        {vaults.map((vault, i) => {
          return (
            <li key={vault.id}>
              <Expand
                dataTestId="expand"
                dataEntityId={vault.id}
                renderExpandedOnly
                renderTrigger={toggle => (
                  <ChooseVaultForDesktopListItem
                    checked={selectedValues[i]}
                    title={loginsMap?.[vault.id]}
                    toggle={
                      typeof loginsMap?.[vault.id] === 'string'
                        ? toggle
                        : undefined
                    }
                    onSelect={() =>
                      !selectedValues[i] && handleVaultSelect(vault, i)
                    }
                  />
                )}>
                <SelectVaultExpandBody>
                  <EditVaultItem vaultId={vaults[i].id} appId={appId} />
                </SelectVaultExpandBody>
              </Expand>
            </li>
          );
        })}
        <ChooseVaultForDesktopListItem
          checked={noneSelected}
          title={intl.formatMessage({
            id: EncryptionTranslation.selectVaultNoneOption,
          })}
          onSelect={() => !noneSelected && handleNoneSelect()}
        />
      </SelectVaultList>

      <SelectVaultControls>
        <SelectVaultAddNewButton
          onClick={createVaultModal.open}
          data-testid="add-cred-button">
          <FormattedMessage
            id={EncryptionTranslation.selectVaultAddPasswordButton}
          />
        </SelectVaultAddNewButton>
        <SelectVaultCloseButton onClick={onDone} data-testid="close-button">
          <FormattedMessage id={EncryptionTranslation.selectVaultCloseButton} />
        </SelectVaultCloseButton>
      </SelectVaultControls>

      {createVaultModal.visible && (
        <CreateVaultItemModal
          containerZIndex={CREATE_VAULT_PASSWORDS_MODAL_Z_INDEX}
          appId={appId}
          selectForCustomAppIdOnCreate={appId}
          width={420}
          onRequestClose={createVaultModal.close}
        />
      )}
    </>
  );
};
