import React, { FC, Ref, useContext, useEffect, useRef, useState } from 'react';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { ClipboardIcon, CopyIcon, DeleteIcon } from '../../../../shared/icons';
import { DesktopLayout, DesktopTab } from '../../../Desktop/Desktop.constants';
import {
  Section,
  SectionContent,
  SectionLoading,
  SectionProps,
  SectionTitleBar,
} from '../../../Desktop/DesktopView/DesktopLayout/Section/Section';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { DesktopFilterEmptyState } from '../../../Desktop/DesktopView/DesktopFilter/DesktopFilterEmptyState';
import { StyledDeleteItem } from '../../../Desktop/DesktopView/DesktopLayout/Section/Section.styled';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { FolderCrumbs } from '../../../Folder/Views/FolderCrumbs/FolderCrumbs';
import { FolderEmptyState } from '../../../Folder/FolderEmptyState';
import { FolderCrumbsLocationContext } from '../../../Folder/Folder.constants';
import { LinksList } from './components/LinksList/LinksList';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { SectionLinksViewModel } from './ViewModel';
import { LinkApiType } from '../../Link.types';
import { LinkActionsProvider } from '../LinkActions/LinkActions.provider';
import { CopyPasteSectionDataContext } from '../../../Desktop/CopyPasteSectionDataProvider';
import { FavoritesDesktopViewModel } from '../../../Desktop/Views/FavoritesDesktop/ViewModel';

interface SectionLinksProps extends SectionProps {
  isOnlySection?: boolean;
  desktopLayout: DesktopLayout;
  favoritesLinks?: LinkApiType[];
}

export const SectionLinks: FC<SectionLinksProps> = ({
  isOnlySection,
  desktopLayout,
  favoritesLinks,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const MenuRef: Ref<HTMLButtonElement> = useRef(null);

  // Detect if section has scrollbars
  const SectionLinksRef = useRef<HTMLDivElement | null>(null);

  const { handlePasteClipboardDesktopSectionData, showPasteLinkSectionButton } =
    useContext(CopyPasteSectionDataContext);

  const {
    showMenuButton,
    handleCopyLinksSection,
    handleDeleteSection,
    tabbedViewUrl,
    canAddLink,
    addLinkHandler,
    canCreateFolder,
    canCopyDesktopSection,
    canDeleteShareableDesktop,
    addFolderHandler,
    filterLinksHandler,
    itemsCount,
    hasSectionContent,
    currentAccountGuest,
    links,
    folders,
    filterString,
    filtersLoading,
    desktopLinksLoading,
    filterEmptyResults,
    loadingTotalCount,
    desktop,
  } = SectionLinksViewModel();

  const { linksLoading: folderLinksLoading } = FavoritesDesktopViewModel();

  // Required to re-render virtual links when switching tabs
  const [containerRef, setContainerRef] = useState<any>(undefined);
  useEffect(() => {
    if (SectionLinksRef.current) {
      setContainerRef(SectionLinksRef.current);
    }
  }, []);

  return (
    <>
      <Section {...props}>
        <LinkActionsProvider links={links}>
          <SectionTitleBar
            identifier="links"
            title={formatMessage({ id: DesktopTranslation.headerLinks })}
            itemsCount={itemsCount}
            tabPath={tabbedViewUrl}
            menuRef={showMenuButton ? MenuRef : null}
            addItemHandler={canAddLink ? addLinkHandler : null}
            addItemTooltipText={formatMessage({
              id: DesktopTranslation.sectionTitlebarAddLinkTooltip,
            })}
            addFolderHandler={canCreateFolder ? addFolderHandler : null}
            addFolderTooltipText={formatMessage({
              id: DesktopTranslation.sectionTitlebarCreateFolderTooltip,
            })}
            searchInputOnChange={filterLinksHandler}
            searchInputPlaceholderText={formatMessage({
              id: DesktopTranslation.sectionTitlebarFilterLinksPlaceHolder,
            })}
            searchInputTooltipText={formatMessage({
              id: DesktopTranslation.sectionTitlebarFilterLinksTooltip,
            })}
            displayMaximizeButton={!isOnlySection}
          />

          {showMenuButton && (
            <Menu
              trigger={MenuRef}
              width={230}
              vAlign={VerticalAlignment.BOTTOM}
              hAlign={HorizontalAlignment.RIGHT}
              data-testid="links-section-menu">
              {showPasteLinkSectionButton && (
                <>
                  <MenuItem
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: DesktopTranslation.pasteDesktopLinksSectionTooltip,
                    })}
                    data-tooltip-place={TooltipPlace.left}
                    icon={ClipboardIcon}
                    onClick={() =>
                      handlePasteClipboardDesktopSectionData(DesktopTab.links)
                    }
                    data-testid="paste-desktop-links-item">
                    <FormattedMessage
                      id={DesktopTranslation.pasteDesktopLinksDataMenuItem}
                    />
                  </MenuItem>
                </>
              )}

              {canCopyDesktopSection && (
                <>
                  <MenuItem
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: DesktopTranslation.copyDesktopLinksSectionTooltip,
                    })}
                    data-tooltip-place={TooltipPlace.left}
                    icon={CopyIcon}
                    onClick={handleCopyLinksSection}
                    data-testid="copy-desktop-links-item">
                    <FormattedMessage
                      id={DesktopTranslation.copyDesktopLinksSectionMenuItem}
                    />
                  </MenuItem>
                </>
              )}

              {!currentAccountGuest && canDeleteShareableDesktop && (
                <StyledDeleteItem
                  icon={DeleteIcon}
                  onClick={handleDeleteSection}
                  data-testid="delete-section-item">
                  <FormattedMessage
                    id={DesktopTranslation.sectionDeleteMenuItem}
                  />
                </StyledDeleteItem>
              )}
            </Menu>
          )}
          <SectionContent sectionHasScroll={true} ref={SectionLinksRef}>
            {desktopLayout === DesktopLayout.CONSOLIDATED && (
              <FolderCrumbs
                locationContext={FolderCrumbsLocationContext.LINKS_CONSOLIDATED}
                injectedData={{
                  desktop: desktop!,
                }}
              />
            )}

            {!folderLinksLoading &&
              !hasSectionContent &&
              !desktopLinksLoading &&
              !filterEmptyResults &&
              !filtersLoading && <FolderEmptyState />}

            {hasSectionContent && !filtersLoading && (
              <LinksList
                links={favoritesLinks || links}
                folders={folders}
                filterString={filterString}
                containerRef={containerRef}
              />
            )}

            {filterEmptyResults && (
              <DesktopFilterEmptyState
                showIllustration={desktopLayout === DesktopLayout.TABBED}
              />
            )}

            {filtersLoading && <SectionLoading />}
          </SectionContent>
        </LinkActionsProvider>
      </Section>
      {loadingTotalCount && (
        <SpinnerFullscreen size={48} dimmedBackground={true} />
      )}
    </>
  );
};
