import { useCallback } from 'react';
import { updateDesktopAppSortRepository } from '../deprecatedData/Repository/DesktopAppRepository';
import type { DesktopAppSortType } from '../data/Desktop/types/Desktop.types';

export const useCaseEditDesktopApp = () => {
  const updateDesktopAppSort = useCallback(
    async (desktopApps: DesktopAppSortType[]) => {
      const response = await updateDesktopAppSortRepository(desktopApps);
      return response;
    },
    [],
  );

  return { updateDesktopAppSort };
};
