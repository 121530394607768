import { useCallback, useMemo } from 'react';
import { useMultipleEntityRepository } from '../../../shared/hooks/repository/multipleEntityRepository.hooks';
import {
  getAccessesRestApi,
  getWorkspaceAccessesRestApi,
} from './dataSources/AccessRestApi.dataSources';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import type {
  AccessesRepositoryProps,
  AccessesRepositoryResp,
  WorkspaceAccessesRepositoryProps,
  WorkspaceAccessesRepositoryResp,
} from './types/AccessRepository.types';
import type { AccessApiType } from './types/Access.types';
import {
  bulkDeleteAccessesFromIDDB,
  bulkPutAccessesToIDDB,
} from './operations/AccessIDDB.operation';
import {
  getDesktopAccessesIDDB,
  getWorkspaceDesktopAccessesIDDB,
} from './dataSources/AccessIDDB.dataSource';
import { getRedundantCacheAccesses } from './utils/AccessRepositoty.utils';

export const useAccessesRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: AccessesRepositoryProps): AccessesRepositoryResp => {
  const { workspace } = useCurrentWorkspace();

  const fetchAccesses = useCallback(async () => {
    const { desktopId } = args;
    if (!desktopId) {
      return;
    }

    try {
      const { data: accessesFromApi, ok } = await getAccessesRestApi(desktopId);

      if (ok) {
        const cacheAccessesRows = await getDesktopAccessesIDDB(desktopId);
        const redundantCacheAccesses = getRedundantCacheAccesses(
          cacheAccessesRows,
          accessesFromApi,
        );

        bulkPutAccessesToIDDB(accessesFromApi, workspace.id);
        bulkDeleteAccessesFromIDDB(
          redundantCacheAccesses.map(access => access.id),
        );
      }

      return accessesFromApi;
    } catch (error) {
      console.error(error);
    }
  }, [args, workspace.id]);

  const { entities: accesses, loading } =
    useMultipleEntityRepository<AccessApiType>({
      fetchFunction: fetchAccesses,
      iddbQuerier: () => getDesktopAccessesIDDB(args?.desktopId),
      dependencies: [workspace.id, args],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      accesses,
      loading,
      fetchAccesses,
    }),
    [accesses, loading, fetchAccesses],
  );
};

export const useCurrentWorkspaceDesktopAccessesRepository = ({
  fetchPolicy = 'cache-and-network',
  ...args
}: WorkspaceAccessesRepositoryProps): WorkspaceAccessesRepositoryResp => {
  const { workspace } = useCurrentWorkspace();

  const fetchAccesses = useCallback(async () => {
    const { data: accessesFromApi, ok } = await getWorkspaceAccessesRestApi(
      workspace.id,
    );

    try {
      if (ok) {
        const cacheAccessesRows = await getWorkspaceDesktopAccessesIDDB(
          workspace.id,
        );
        const redundantCacheAccesses = getRedundantCacheAccesses(
          cacheAccessesRows,
          accessesFromApi,
        );

        bulkPutAccessesToIDDB(accessesFromApi, workspace.id);
        bulkDeleteAccessesFromIDDB(
          redundantCacheAccesses.map(access => access.id),
        );
      }

      return accessesFromApi;
    } catch (error) {
      console.error(error);
    }
  }, [workspace.id]);

  const { entities: accesses, loading } =
    useMultipleEntityRepository<AccessApiType>({
      fetchFunction: fetchAccesses,
      iddbQuerier: () => getWorkspaceDesktopAccessesIDDB(workspace.id),
      dependencies: [workspace.id, args],
      fetchPolicy,
    });

  return useMemo(
    () => ({
      accesses,
      loading,
      fetchAccesses,
    }),
    [accesses, loading, fetchAccesses],
  );
};
