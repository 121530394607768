import React, { FC } from 'react';
import { StyledLinkCheckboxWrapper } from './LinkCheckbox.styled';
import { Checkbox } from '../../../../../shared/components/Checkbox';
import { useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../../Desktop/i18n';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';

interface LinkCheckboxProps {
  checked?: boolean;
  onChecked: () => void;
}

export const LinkCheckbox: FC<LinkCheckboxProps> = ({ checked, onChecked }) => {
  const intl = useIntl();

  return (
    <StyledLinkCheckboxWrapper
      data-tooltip-id="global-tooltip"
      data-tooltip-content={intl.formatMessage({
        id: DesktopTranslation.selectLinkCheckbox,
      })}
      data-tooltip-place={TooltipPlace.top}
      data-event-off="click mouseleave">
      <Checkbox checked={checked} onChange={onChecked} />
    </StyledLinkCheckboxWrapper>
  );
};
