import React, { FC, useCallback, useMemo } from 'react';
import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldAttributes,
  Form,
  Formik,
} from 'formik';
import { ConferenceTranslation } from '../../i18n';
import { FormInput } from '../../../../shared/components/Input';
import { FormSelect } from '../../../../shared/components/Select';
import {
  StyledScheduleRepeatButtonsControls,
  StyledScheduleRepeatDay,
  StyledScheduleRepeatEveryRow,
  StyledScheduleRepeatOnRow,
  StyledScheduleRepeatRadio,
  StyledScheduleRepeatRow,
} from './ScheduleRepeatingConferenceForm.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { REPEAT_FREQ_OPTIONS } from '../../Conference.constants';
import { useRepeatPattern, useWeekCounts } from '../../Conference.hooks';
import {
  ScheduledRepeatedConferenceFormValues,
  ScheduleRepeatingConferenceFormProps,
  ScheduleRepeatingConferenceFormValues,
  ScheduleRepeatingRepeatFreqValues,
} from '../../Conference.types';
import { Calendar } from '../../../../shared/components/Calendar';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { GlobalTranslation } from '../../../Intl/i18n';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import * as Yup from 'yup';
import { Schema } from 'yup';

const repeatScheduleFormSchema: Schema<ScheduleRepeatingConferenceFormValues> =
  Yup.object().shape({
    intervalValue: Yup.number(),
    intervalRange: Yup.string(),
    repeatOnWeeks: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
        active: Yup.boolean().required(),
      }),
    ),
    repeatOnMonths: Yup.number(),
    endsOn: Yup.string(),
    endsOnDate: Yup.string(),
    occurrences: Yup.number().when('endsOn', {
      is: endsOn =>
        endsOn === ConferenceTranslation.scheduleRepeatingConferenceAfter,
      then: Yup.number().min(1),
    }),
  });

export const ScheduleRepeatingConferenceForm: FC<
  ScheduleRepeatingConferenceFormProps
> = ({ data, onSubmit, onClose, selectedDate }) => {
  const { formatMessage } = useIntl();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const weekCountsOptions = useWeekCounts(selectedDate, data);
  const { generateRepeatPattern, generateFormInitialValues } =
    useRepeatPattern(selectedDate);

  const initialValues: ScheduledRepeatedConferenceFormValues = useMemo(
    () => generateFormInitialValues(data),
    [generateFormInitialValues, data],
  );

  const intervalRangeOptions = useMemo(
    () =>
      REPEAT_FREQ_OPTIONS.map(option => (
        <option value={option.value} key={option.value}>
          {formatMessage({
            id: ConferenceTranslation[option.label],
          })}
        </option>
      )),
    [formatMessage],
  );

  const repeatOnMonthsOptions = useMemo(
    () =>
      weekCountsOptions.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      )),
    [weekCountsOptions],
  );

  const handleKeyPressRepeatOnWeeks = useCallback(
    (
      event: React.KeyboardEvent,
      arrayHelpers: ArrayHelpers,
      day: any,
      index: number,
    ) => {
      if (event.key === 'Enter') {
        arrayHelpers.replace(index, {
          ...day,
          active: !day.active,
        });
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    (values: ScheduledRepeatedConferenceFormValues) => {
      const res = generateRepeatPattern(values);
      if (res) {
        onSubmit(res);
      }
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          scheduleRepeatingConferenceOpened: undefined,
        }),
      });
    },
    [generateRepeatPattern, navigate, onSubmit, queryParams],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={repeatScheduleFormSchema}
      validateOnMount
      onSubmit={handleSubmit}>
      {({ setFieldValue, values }) => (
        <Form>
          <StyledScheduleRepeatEveryRow>
            <FormattedMessage
              id={ConferenceTranslation.scheduleRepeatingConferenceRepeatEvery}
            />
            <Field
              autoFocus
              min={1}
              name="intervalValue"
              type="number"
              component={FormInput}
            />
            <Field name="intervalRange" component={FormSelect}>
              {intervalRangeOptions}
            </Field>
          </StyledScheduleRepeatEveryRow>

          {values.intervalRange === ScheduleRepeatingRepeatFreqValues.WEEK && (
            <StyledScheduleRepeatRow>
              <FormattedMessage
                id={ConferenceTranslation.scheduleRepeatingConferenceRepeatOn}
              />
              <div>
                <FieldArray
                  name="repeatOnWeeks"
                  render={arrayHelpers =>
                    values.repeatOnWeeks.map((day, index) => (
                      <StyledScheduleRepeatDay
                        key={index}
                        tabIndex={0}
                        active={day.active}
                        onKeyDown={e =>
                          handleKeyPressRepeatOnWeeks(
                            e,
                            arrayHelpers,
                            day,
                            index,
                          )
                        }
                        onClick={() => {
                          arrayHelpers.replace(index, {
                            ...day,
                            active: !day.active,
                          });
                        }}>
                        {day.label}
                      </StyledScheduleRepeatDay>
                    ))
                  }
                />
              </div>
            </StyledScheduleRepeatRow>
          )}

          {values.intervalRange === ScheduleRepeatingRepeatFreqValues.MONTH && (
            <StyledScheduleRepeatRow>
              <Field name="repeatOnMonths" component={FormSelect}>
                {repeatOnMonthsOptions}
              </Field>
            </StyledScheduleRepeatRow>
          )}
          <StyledScheduleRepeatOnRow>
            <FormattedMessage
              id={ConferenceTranslation.scheduleRepeatingConferenceEnds}
            />
            <StyledScheduleRepeatRadio>
              <label>
                <Field
                  name="endsOn"
                  type="radio"
                  value={formatMessage({
                    id: ConferenceTranslation.scheduleRepeatingConferenceNever,
                  })}
                />
                <FormattedMessage
                  id={ConferenceTranslation.scheduleRepeatingConferenceNever}
                />
              </label>
            </StyledScheduleRepeatRadio>
            <StyledScheduleRepeatRadio>
              <label>
                <Field
                  name="endsOn"
                  type="radio"
                  value={formatMessage({
                    id: ConferenceTranslation.scheduleRepeatingConferenceOn,
                  })}
                />
                <FormattedMessage
                  id={ConferenceTranslation.scheduleRepeatingConferenceOn}
                />
              </label>
              <div className="schedule-repeating-calendar">
                <Field
                  name="endsOnDate"
                  onChange={(value: string) =>
                    setFieldValue('endsOnDate', value)
                  }
                  disabled={
                    values.endsOn !==
                    formatMessage({
                      id: ConferenceTranslation.scheduleRepeatingConferenceOn,
                    })
                  }
                  component={(args: FieldAttributes<any>) => (
                    <Calendar {...args} selectedDate={selectedDate} />
                  )}
                />
              </div>
            </StyledScheduleRepeatRadio>
            <StyledScheduleRepeatRadio>
              <label>
                <Field
                  name="endsOn"
                  type="radio"
                  value={formatMessage({
                    id: ConferenceTranslation.scheduleRepeatingConferenceAfter,
                  })}
                />
                <FormattedMessage
                  id={ConferenceTranslation.scheduleRepeatingConferenceAfter}
                />
              </label>
              <div className="schedule-repeating-occurrences">
                <Field
                  name="occurrences"
                  type="number"
                  disabled={
                    values.endsOn !==
                    formatMessage({
                      id: ConferenceTranslation.scheduleRepeatingConferenceAfter,
                    })
                  }
                  min={1}
                  max={365}
                  component={FormInput}
                />
                <FormattedMessage
                  id={
                    ConferenceTranslation.scheduleRepeatingConferenceOccurrences
                  }
                />
              </div>
            </StyledScheduleRepeatRadio>
          </StyledScheduleRepeatOnRow>
          <StyledScheduleRepeatButtonsControls>
            <Button
              type="button"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              onClick={onClose}>
              <FormattedMessage id={GlobalTranslation.cancel} />
            </Button>
            <Button
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}>
              <FormattedMessage
                id={ConferenceTranslation.scheduleConferenceFormSave}
              />
            </Button>
          </StyledScheduleRepeatButtonsControls>
        </Form>
      )}
    </Formik>
  );
};
