import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';

type ReencryptVaultAccessesControlsProps = {
  onAccept: () => Promise<unknown>;
  onDecline: () => Promise<unknown>;
};

export const ReencryptVaultAccessesControls: FC<
  ReencryptVaultAccessesControlsProps
> = ({ onAccept, onDecline }) => {
  const [disabled, setDisabled] = useState(false);

  const handleAcceptClick = useCallback(() => {
    setDisabled(true);
    onAccept().then(() => setDisabled(true));
  }, [onAccept]);

  const handleDeclineClick = useCallback(() => {
    setDisabled(true);
    onDecline().then(() => setDisabled(true));
  }, [onDecline]);

  return (
    <>
      <Button
        fullWidth
        disabled={disabled}
        mode={ButtonMode.secondary}
        size={ButtonSize.md}
        onClick={handleDeclineClick}
        data-testid="recovery-request-decline-button">
        <FormattedMessage id={EncryptionTranslation.reencryptAccessesDecline} />
      </Button>
      <Button
        fullWidth
        disabled={disabled}
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        onClick={handleAcceptClick}
        data-testid="recovery-request-accept-button">
        <FormattedMessage id={EncryptionTranslation.reencryptAccessesAccept} />
      </Button>
    </>
  );
};
