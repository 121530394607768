import type {
  AppTrackingAction,
  DesktopTrackingAction,
} from './DesktopTracking.types';
import { EventCategoryType, sendTrackingEvent } from '../../Tracking';

export const sendDesktopTrackingEvent = (action: DesktopTrackingAction) => {
  sendTrackingEvent({
    category: EventCategoryType.DESKTOP,
    action,
  });
};

export const sendAppTrackingEvent = (action: AppTrackingAction) => {
  sendTrackingEvent({
    category: EventCategoryType.APP,
    action,
  });
};
