import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, PopoverProps } from '../../../shared/components/Popover';
import {
  ShareDesktopModalBody,
  StyledShareDesktopPopover,
} from './ShareDesktopPopover.styled';
import { InviteMember } from './InviteMember';
import { MembersList } from './MembersList';
import { getTopLeftAnchor } from '../../../shared/components/Popover/Popover.utils';
import { useMobile } from '../../../shared/hooks';
import { Modal, ModalHeader } from '../../../shared/components/Modal';
import { DesktopTranslation } from '../i18n';
import { DesktopApiType } from '../data/Desktop/types/Desktop.types';
import { useAccessesRepository } from '../../Access/data/Access.repositories';
import { useInvitationsRepository } from '../../Invitation/data/Invitation/Invitation.repositories';

interface ShareDesktopPopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRef'> {
  desktop: DesktopApiType;
}

export const ShareDesktopPopover: FC<ShareDesktopPopoverProps> = ({
  desktop,
  ...props
}) => {
  const isMobile = useMobile();

  const { accesses, loading: loadingAccesses } = useAccessesRepository({
    desktopId: desktop.id,
  });

  const currentTeamIds = useMemo(() => {
    return accesses
      .filter(access => !!access.workspaceTeam?.id)
      .map(access => access.workspaceTeam!.id);
  }, [accesses]);

  const currentAccountIds = useMemo(() => {
    return accesses
      .filter(access => !!access.account?.id)
      .map(access => access.account!.id);
  }, [accesses]);

  const { invitations, loading: loadingInvitations } = useInvitationsRepository(
    {
      variables: {
        desktopId: desktop.id,
      },
    },
  );

  const filteredInvitations = useMemo(
    () =>
      invitations.filter(
        ({ recipients, accountsCreated }) =>
          recipients.length && !accountsCreated,
      ),
    [invitations],
  );

  const currentInviteEmails = useMemo(
    () =>
      filteredInvitations
        .map(invite => invite.recipients[0]?.email)
        .filter(Boolean),
    [filteredInvitations],
  );

  const loading = loadingAccesses || loadingInvitations;

  const content = (
    <>
      <InviteMember
        desktop={desktop}
        currentTeamIds={currentTeamIds}
        currentAccountIds={currentAccountIds}
        currentInviteEmails={currentInviteEmails}
      />
      <MembersList
        desktop={desktop}
        accesses={accesses}
        invites={filteredInvitations}
        loading={loading}
      />
    </>
  );

  if (isMobile) {
    return (
      <Modal visible fullScreen onRequestClose={props.onRequestClose}>
        <ModalHeader onRequestClose={props.onRequestClose}>
          <FormattedMessage id={DesktopTranslation.shareModalTitle} />
        </ModalHeader>
        <ShareDesktopModalBody>{content}</ShareDesktopModalBody>
      </Modal>
    );
  }

  return (
    <Popover anchorGetter={getTopLeftAnchor} verticalMargin={12} {...props}>
      <StyledShareDesktopPopover>{content}</StyledShareDesktopPopover>
    </Popover>
  );
};
