import React, { FC, useCallback, useState } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { isVideoMimeType } from '../../domains/Asset/Asset.utils';
import { useMobile } from '../../shared/hooks';
import { PreviewPlayIcon } from '../../shared/icons';
import { VideoPlayerTranslation } from './i18n';
import { VideoPlayer } from './VideoPlayer';
import {
  StyledPlayButton,
  StyledPlayerWrapperImage,
  StyledPlayWrapper,
  StyledVideoIsBeingProcessed,
  StyledVideoPreview,
} from './VideoPlayer.styled';

interface VideoPlayerPreviewProp {
  mimeType: string;
  url: string;
  previewImageSrc?: string;
  title?: string;
  withPadding?: boolean;
}

export const VideoPlayerPreview: FC<VideoPlayerPreviewProp> = ({
  mimeType,
  url,
  previewImageSrc,
  title,
  withPadding = true,
}) => {
  const [renderPreview, setRenderPreview] = useState(false);
  const isMobile = useMobile();
  const isVideo = isVideoMimeType(mimeType);

  const intl = useIntl();

  const handleClick = useCallback(() => {
    setRenderPreview(true);
  }, []);

  return isVideo ? (
    <StyledVideoPreview
      data-testid="video-preview-container"
      isMobile={isMobile}>
      {!renderPreview && (
        <StyledPlayWrapper>
          {previewImageSrc ? (
            <StyledPlayerWrapperImage
              withPadding={withPadding}
              src={previewImageSrc}
              alt={title}
              onClick={handleClick}
              data-testid="video-preview-player-image"
              loading="lazy"
            />
          ) : (
            <StyledVideoIsBeingProcessed>
              <FormattedHTMLMessage
                id={VideoPlayerTranslation.videoIsBeingProcessed}
              />
            </StyledVideoIsBeingProcessed>
          )}
          <StyledPlayButton
            aria-label={intl.formatMessage({
              id: VideoPlayerTranslation.playerPlayButton,
            })}
            icon={PreviewPlayIcon}
            data-testid="video-preview-play-button"
            onClick={handleClick}
          />
        </StyledPlayWrapper>
      )}

      {renderPreview && (
        <VideoPlayer
          source={url}
          autoPlay={true}
          controls={true}
          withCustomControls={false}
        />
      )}
    </StyledVideoPreview>
  ) : null;
};
