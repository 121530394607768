import styled, { css } from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const CustomAppFormBody = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: flex;
  `}
`;

export const CustomAppFormFieldsPart = styled.div`
  flex-basis: 100%;
`;

export const CustomAppFormUploadPart = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    flex: 0 0 146px;
    margin-left: 40px;
    margin-top: 0;
  `}
`;

export const CustomAppFormImage = styled.img<{ invalid?: boolean }>`
  width: 120px;
  height: 120px;
  margin-bottom: 0.25rem;
  border-radius: 3px;
  object-fit: cover;
  cursor: pointer;

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${p => p.theme.colors.CriticalLight};
    `}
`;

export const ImageUploadLabel = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
`;

export const ImageUploadError = styled.div`
  color: ${p => p.theme.colors.CriticalLight};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: 0.25rem;
`;

export const ImageUploadButton = styled(Button)`
  margin-top: 0.25rem;
`;

export const CustomAppField = styled.div`
  & ~ & {
    margin-top: 16px;
  }
`;

export const CustomAppFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  button ~ button {
    margin-left: 6px;
  }
`;

export const AppPreviewImageBox = styled.div`
  max-width: 120px;
`;

export const AppPreviewImageOuter = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
`;

export const AppPreviewImage = styled.img<{ invalid?: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 3px;

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${p => p.theme.colors.CriticalLight};
    `}
`;

export const AppPreviewImageDescription = styled.div`
  margin-top: 5px;
  text-align: center;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const CheckBoxLabel = styled.label`
  display: inline-block;

  span {
    float: left;
    margin-right: 1rem;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;
