import React, { FC, useCallback, useEffect, useState } from 'react';
import { Calendar as ReactCalendar } from 'react-date-range';
import { Modal } from '../Modal';
import { InputWithIcon } from '../InputWithIcon/InputWithIcon';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FieldProps } from 'formik';
import { getCurrentTheme } from '../../../domains/Theme';

const StyledInputWithIcon = styled(InputWithIcon)`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 1rem;
`;

const StyledReactCalendar = styled(ReactCalendar)`
  &.rdrCalendarWrapper {
    background: ${p => p.theme.colors.Surface};

    .rdrPprevButton {
      i {
        border-color: transparent ${p => p.theme.colors.IconLight} transparent
          transparent;
      }
    }

    .rdrNextButton {
      i {
        border-color: transparent transparent transparent
          ${p => p.theme.colors.IconLight};
      }
    }
  }

  .rdrMonthAndYearWrapper {
    button {
      background: ${p => p.theme.colors.Primary};

      &:focus-visible {
        outline: none;
        background-color: ${p => p.theme.colors.Primary};
        border: 2px solid ${p => p.theme.colors.PrimaryLight};
      }
    }
  }

  .rdrDayDisabled {
    background: ${p => p.theme.colors.SurfaceSubdued};

    span {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis}!important;
    }
  }

  .rdrDay {
    span {
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }

  .rdrDayPassive {
    span {
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    }
  }

  .rdrSelected ~ .rdrDayNumber span {
    color: ${p => p.theme.colors.PrimaryTextLight}!important;
  }

  .rdrDayNumber {
    span::after {
      color: ${p => p.theme.colors.Primary};
    }
  }

  .rdrMonthPicker {
    select {
      color: ${p => p.theme.colors.PrimaryTextLight};
      background: ${p => p.theme.colors.Primary};
      padding: 0.625rem 1.25rem;
      line-height: 1;
    }
  }

  .rdrYearPicker {
    select {
      color: ${p => p.theme.colors.PrimaryTextLight};
      background: ${p => p.theme.colors.Primary};
      padding: 0.625rem 1.25rem;
      line-height: 1;
    }
  }

  .rdrWeekDay {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  .rdrMonthAndYearWrapper button,
  .rdrMonthPicker select,
  .rdrYearPicker select {
    &:hover {
      background-color: ${p => p.theme.colors.PrimaryDark};
    }

    &:focus:not(:focus-visible),
    &:active {
      outline: none;
      background-color: ${p => p.theme.colors.PrimaryDarker};
    }
  }
`;

export const Calendar: FC<
  FieldProps & {
    label?: string;
    onChange: (value: Date) => void;
    disabled: boolean;
    selectedDate: Date;
  }
> = props => {
  const { field, onChange, label, disabled, selectedDate } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (inputValue.length === 0) {
      setInputValue(format(field.value, 'dd/MM/yyyy'));
    }
  }, [field.value, inputValue, selectedDate]);

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  const handleSelect = useCallback(
    (selectedDate: Date) => {
      setInputValue(format(selectedDate, 'dd/MM/yyyy'));
      onChange(selectedDate);
      handleClose();
    },
    [handleClose, onChange],
  );

  return (
    <>
      <StyledInputWithIcon
        onClick={() => setIsOpened(true)}
        value={inputValue}
        label={label}
        disabled={disabled}
        reverseIcon
      />
      <Modal
        onRequestClose={handleClose}
        visible={isOpened}
        containerZIndex={27}
        width={335}>
        <StyledReactCalendar
          {...field}
          // @ts-ignore
          color={getCurrentTheme().colors.Primary}
          date={selectedDate || field.value}
          minDate={new Date() as any}
          onChange={handleSelect}
        />
      </Modal>
    </>
  );
};
