import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ChangeUserPasswordFormValues } from '../../User.types';
import { FormInput } from '../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { UserTranslation } from '../../i18n';
import {
  PASSWORD_CHARACTER_REGEX,
  PASSWORD_NUMBER_REGEX,
} from '../../User.constants';
import {
  ChangeUserPasswordFormInner,
  HiddenInput,
  StyledControls,
} from './ChangeUserPassword.form.styled';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { appEnv } from '../../../../appEnv';
import { GlobalTranslation } from '../../../Intl/i18n';

const ChangeUserPasswordFormSchema: Yup.Schema<ChangeUserPasswordFormValues> =
  Yup.object().shape(
    {
      currentPassword: Yup.string().when('newPassword', {
        is: newPassword => newPassword?.length > 0,
        then: Yup.string().required(
          UserTranslation.profileFormOldPasswordRequired,
        ),
      }),
      newPassword: Yup.string().when('currentPassword', {
        is: currentPassword => currentPassword?.length > 0,
        then: Yup.string()
          .required(UserTranslation.profileFormNewPasswordRequired)
          .min(8, UserTranslation.profileFormPasswordMinimum)
          .matches(
            PASSWORD_CHARACTER_REGEX,
            UserTranslation.profileFormPasswordContainLetter,
          )
          .matches(
            PASSWORD_NUMBER_REGEX,
            UserTranslation.profileFormPasswordContainNumber,
          )
          .notOneOf(
            [Yup.ref('currentPassword'), null],
            UserTranslation.profileFormPasswordDidNotChange,
          ),
      }),
      repeatPassword: Yup.string()
        .when('newPassword', {
          is: newPassword => newPassword?.length > 0,
          then: Yup.string().required(
            UserTranslation.profileFormConfirmPasswordRequired,
          ),
        })
        .oneOf(
          [Yup.ref('newPassword'), null],
          UserTranslation.profileFormPasswordMatch,
        ),
    },
    [['currentPassword', 'newPassword']],
  );

export interface ChangeUserPasswordFormValuesProps {
  onSubmit: (formValues: ChangeUserPasswordFormValues) => void;
  onCancel?: () => void;
}

export const ChangeUserPasswordForm: FC<ChangeUserPasswordFormValuesProps> = ({
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();

  const initialValues: ChangeUserPasswordFormValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeUserPasswordFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <ChangeUserPasswordFormInner>
            <div className="fields">
              <ImportantMessage
                data-testid="warning-message"
                type={ImportantMessageType.WARNING}>
                <FormattedHTMLMessage
                  id={UserTranslation.profilePasswordImportantMessage}
                  values={{
                    learnMoreLink: appEnv.SUPPORT_PASSWORD_MANAGER_URL,
                  }}
                />
              </ImportantMessage>

              <ImportantMessage
                data-testid="info-message"
                type={ImportantMessageType.INFO}>
                <FormattedHTMLMessage
                  id={GlobalTranslation.checkEmailMessage}
                />
              </ImportantMessage>

              {/*Chrome password autocomplete disabling workaround*/}
              <HiddenInput type="password" tabIndex={-1} />

              <Field
                className="fieldBox"
                data-testid="current-password"
                name="currentPassword"
                type="password"
                label={intl.formatMessage({
                  id: UserTranslation.profileOldPasswordLabel,
                })}
                component={FormInput}
              />
              <Field
                className="fieldBox"
                data-testid="new-password"
                name="newPassword"
                type="password"
                label={intl.formatMessage({
                  id: UserTranslation.profileNewPasswordLabel,
                })}
                component={FormInput}
              />
              <Field
                className="fieldBox"
                data-testid="confirm-password"
                name="repeatPassword"
                type="password"
                label={intl.formatMessage({
                  id: UserTranslation.profileConfirmPasswordLabel,
                })}
                component={FormInput}
              />

              <StyledControls>
                <Button
                  data-testid="user-form-cancel-button"
                  type="button"
                  mode={ButtonMode.secondary}
                  size={ButtonSize.md}
                  onClick={onCancel}>
                  <FormattedMessage
                    id={UserTranslation.adminUserFormCancelButton}
                  />
                </Button>
                <Button
                  disabled={isSubmitting || !dirty}
                  mode={ButtonMode.primary}
                  data-testid="submit-button"
                  size={ButtonSize.md}
                  type="submit">
                  <FormattedMessage
                    id={UserTranslation.adminUserFormSubmitButton}
                  />
                </Button>
              </StyledControls>
            </div>
          </ChangeUserPasswordFormInner>
        </Form>
      )}
    </Formik>
  );
};
