import React, { FC, useMemo } from 'react';
import { useAppIntegrationsMarketplaceCategories } from '../../AppIntegrations.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  StyledMarketplaceCategoriesWrapper,
  StyledMarketplaceCategoriesList,
} from './WorkspaceIntegrations.styled';
import { MarketplaceCategory } from '../Marketplace/MarketplaceCategory';
import { AppIntegrationsTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import { AppIntegrationsCategories } from '../../AppIntegrations.types';
import { extractMarketplaceCategories } from '../../AppIntegrations.utils';

export const WorkspaceIntegrations: FC = () => {
  const { data, loading } = useAppIntegrationsMarketplaceCategories({
    variables: {
      personal: false,
    },
    fetchPolicy: 'cache-and-network',
  });

  const marketplaceCategories = useMemo(
    () => extractMarketplaceCategories(data),
    [data],
  );

  return (
    <StyledMarketplaceCategoriesWrapper>
      <div className="title">
        <FormattedMessage
          id={AppIntegrationsTranslation.appIntegrationWorkspaceTitle}
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <StyledMarketplaceCategoriesList>
          {marketplaceCategories?.map(marketplaceCategory => (
            <MarketplaceCategory
              marketplaceCategory={marketplaceCategory}
              integrationCategory={AppIntegrationsCategories.workspace}
              key={marketplaceCategory.id}
            />
          ))}
        </StyledMarketplaceCategoriesList>
      )}
    </StyledMarketplaceCategoriesWrapper>
  );
};
