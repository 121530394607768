import React, { FC, useContext, useEffect, useMemo } from 'react';
import { StyledPasswordsPage } from './PasswordsPage.styled';
import { useMobile, useQueryParams, useTablet } from '../../../../shared/hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  DESKTOP_ROOT_PATHNAME,
  PASSWORDS_ROOT_PATHNAME,
  PASSWORDS_VAULT_PATHNAME,
  PASSWORDS_VAULT_SLUG,
} from '../../../Desktop/Desktop.constants';
import { PasswordView } from '../PasswordView/PasswordView';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import {
  useCurrentWorkspaceAccountPrivateKey,
  useCurrentWorkspaceVaultsList,
  useWorkspaceVaultById,
} from '../../Encryption.hooks';
import {
  EDIT_APP_MODAL_Z_INDEX,
  MANAGE_APP_PASSWORDS_MODAL_Z_INDEX,
} from '../../../AppStore/AppStore.constants';
import { getShortId } from '../../../../shared/utils/id';
import { EditVaultItemModal } from '../../VaultItemForm';
import { getVaultIri } from '../../Encryption.utils';
import { DeleteVaultModal } from '../../DeleteVault/DeleteVaultModal';
import { EmptyState } from '../EmptyState';
import { CreateVaultItemModal } from '../../VaultItemForm/CreateVaultItem/CreateVaultItemModal';
import { Route, Routes } from '../../../ErrorInterceptor/router.injectors';
import {
  SegmentEmpty,
  SegmentType,
  useSetLastUsedSegment,
  useSetLastUsedSegmentPath,
} from '../../../Segment';
import { PasswordsSegmentTitleBar } from '../PasswordsSegmentTitleBar/PasswordsSegmentTitleBar';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import { SelectVaultContext } from '../PasswordsSwitcher/SelectVault.context';
import { DeleteMultipleVaultsModal } from '../../DeleteMultipleVaults/DeleteMultipleVaultsModal';
import { usePreviewSegment } from '../../../PreviewSegment/PreviewSegment.hooks';
import { UnshareVaultModal } from '../../UnshareVault/UnshareVaultModal';
import { EditAppModal } from '../../../AppStore/Modals/EditCustomAppModal';

export const PasswordsPage: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { pathname } = useLocation();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;
  const [passwordsPageRef] = useHideScrollbarWhenIdle();
  const isMobile = useMobile();
  const isTablet = useTablet();

  const { selectedVaults, unselectAll } = useContext(SelectVaultContext);
  const selectedVaultIds: string[] = useMemo(() => {
    return Object.keys(selectedVaults);
  }, [selectedVaults]);

  const currentVaultId = useMemo(() => {
    const match = matchPath(PASSWORDS_VAULT_PATHNAME, pathname);
    if (!match) {
      return undefined;
    }

    const { vaultId } = match.params;
    return vaultId ? getVaultIri(vaultId) : null;
  }, [pathname]);

  const setLastSegment = useSetLastUsedSegment();
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.PASSWORDS);
  useEffect(() => {
    setLastSegment(SegmentType.PASSWORDS);
    setLastSegmentPath(pathname);
  }, [currentVaultId, pathname, setLastSegment, setLastSegmentPath]);

  const {
    permissions: { canUnlockCredentials },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  useEffect(() => {
    if (!canUnlockCredentials && permissionsLoaded) {
      navigate(
        generatePath(DESKTOP_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
      );
    }
  }, [canUnlockCredentials, permissionsLoaded, workspace, navigate]);

  const { vaults, workspaceVaultsLoading } = useCurrentWorkspaceVaultsList();
  const { vault } = useWorkspaceVaultById(currentVaultId as string);

  const onPasswordDelete = (deletedVaultIds: string[]) => {
    if (queryParams.deleteSelectedVaults) {
      unselectAll();
    }

    //TODO Use here sorted vaults as in vaults list to have correct selection
    const currentVaultIsDeleted =
      currentVaultId && deletedVaultIds.includes(currentVaultId);
    const vaultForRedirect =
      currentVaultIsDeleted &&
      vaults.find(vault => !deletedVaultIds.includes(vault.id));

    navigate({
      ...(currentVaultIsDeleted
        ? {
            pathname: generatePath(PASSWORDS_ROOT_PATHNAME, {
              workspaceId: getShortId(workspaceId),
              ...(vaultForRedirect
                ? { vaultId: getShortId(vaultForRedirect.id) }
                : {}),
            }),
          }
        : {}),
      search: getQueryParamsFrom({
        ...queryParams,
        deleteSelectedVaults: undefined,
        deleteVaultId: undefined,
        deleteCurrentVault: undefined,
        unshareCurrentVault: undefined,
      }),
    });
  };

  const { isPreviewMode } = usePreviewSegment();

  const showUnlockPasswordsScreen = !workspaceVaultsLoading && !privateKey;

  return (
    <>
      {!isPreviewMode &&
        (workspaceVaultsLoading ? (
          <SegmentEmpty withTitlebar={!isMobile}>
            <Spinner />
          </SegmentEmpty>
        ) : (
          <>
            {!isMobile && !isTablet && (
              <PasswordsSegmentTitleBar vault={vault} />
            )}
            <StyledPasswordsPage
              ref={passwordsPageRef}
              showContentOnFullPage={showUnlockPasswordsScreen}
              isPasswordVisible={!!currentVaultId}
              data-testid="passwords-page">
              {!workspaceVaultsLoading && !vaults?.length ? (
                <EmptyState />
              ) : (
                <Routes>
                  <Route
                    path={PASSWORDS_VAULT_SLUG}
                    element={<PasswordView />}
                  />
                </Routes>
              )}
            </StyledPasswordsPage>
          </>
        ))}

      {queryParams.isCreatePasswordModalOpened && (
        <CreateVaultItemModal
          allowManualPasswordCreation
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                isCreatePasswordModalOpened: undefined,
              }),
            })
          }
        />
      )}
      {currentVaultId && queryParams.editAppId && (
        <EditAppModal containerZIndex={EDIT_APP_MODAL_Z_INDEX} />
      )}
      {privateKey &&
        (queryParams.editVaultId ||
          (currentVaultId && queryParams.editCurrentVault)) && (
          <EditVaultItemModal
            vaultId={
              queryParams.editVaultId
                ? getVaultIri(queryParams.editVaultId as string)
                : currentVaultId!
            }
            containerZIndex={MANAGE_APP_PASSWORDS_MODAL_Z_INDEX}
            onRequestClose={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  editCurrentVault: undefined,
                  editVaultId: undefined,
                }),
              });
            }}
            onDone={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  editCurrentVault: undefined,
                  editVaultId: undefined,
                }),
              });
            }}
          />
        )}
      {privateKey &&
        (queryParams.deleteCurrentVault || queryParams.deleteVaultId) && (
          <DeleteVaultModal
            vaultId={
              queryParams.deleteVaultId
                ? getVaultIri(queryParams.deleteVaultId as string)
                : (currentVaultId as string)
            }
            onDeleted={onPasswordDelete}
            onRequestClose={() => {
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  deleteCurrentVault: undefined,
                  deleteVaultId: undefined,
                }),
              });
            }}
          />
        )}
      {privateKey && queryParams.unshareCurrentVault && (
        <UnshareVaultModal
          vaultId={currentVaultId as string}
          onUnshared={onPasswordDelete}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                unshareCurrentVault: undefined,
              }),
            });
          }}
        />
      )}
      {privateKey && queryParams.deleteSelectedVaults && (
        <DeleteMultipleVaultsModal
          vaultIds={selectedVaultIds}
          onDeleted={onPasswordDelete}
          onRequestClose={() => {
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                deleteSelectedVaults: undefined,
              }),
            });
          }}
        />
      )}
    </>
  );
};
