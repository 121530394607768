import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledTabList, TabsType } from '../../../../../shared/components/Tabs';
import { AdminHeaderSeparator, AdminHeaderTitle } from '../../../../Admin';
import {
  StyledCustomIntegrationViewWrapper,
  StyledIntegrationTab,
  StyledTabPanel,
  StyledTabs,
} from './CustomIntegrationView.styled';
import { CustomIntegrationProperties } from './CustomIntegrationProperties';
import { CustomIntegrationBot } from './CustomIntegrationBot';
import { CustomIntegrationHooks } from './CustomIntegrationHooks';
import { useGetIntegrationClient } from '../../../AppIntegrations.hooks';
import { AppIntegrationsTranslation } from '../../../i18n';
import { useParams } from 'react-router-dom';

export const CustomIntegrationView: FC = () => {
  const { integrationId } = useParams<{ integrationId: string }>();

  const { data } = useGetIntegrationClient({
    skip: !integrationId,
    variables: {
      id: '/integration-clients/' + integrationId,
    },
  });

  if (!data) {
    return null;
  }

  const integrationClient = data.integrationClient;

  return (
    <StyledCustomIntegrationViewWrapper>
      <AdminHeaderTitle data-testid="title" className="header">
        <FormattedMessage id={AppIntegrationsTranslation.appIntegrationTitle} />{' '}
        <AdminHeaderSeparator /> {integrationClient.name}
      </AdminHeaderTitle>
      <StyledTabs>
        <StyledTabList type={TabsType.tabs}>
          <StyledIntegrationTab>
            <FormattedMessage
              id={AppIntegrationsTranslation.appCustomIntegrationPropertiesTab}
            />
          </StyledIntegrationTab>
          <StyledIntegrationTab>
            <FormattedMessage
              id={AppIntegrationsTranslation.appCustomIntegrationBotTab}
            />
          </StyledIntegrationTab>
          <StyledIntegrationTab>
            <FormattedMessage
              id={AppIntegrationsTranslation.appCustomIntegrationHooksTab}
            />
          </StyledIntegrationTab>
        </StyledTabList>
        <StyledTabPanel>
          <CustomIntegrationProperties integrationClient={integrationClient} />
        </StyledTabPanel>
        <StyledTabPanel>
          <CustomIntegrationBot integrationClient={integrationClient} />
        </StyledTabPanel>
        <StyledTabPanel>
          <CustomIntegrationHooks integrationClient={integrationClient} />
        </StyledTabPanel>
      </StyledTabs>
    </StyledCustomIntegrationViewWrapper>
  );
};
