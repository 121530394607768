import React, { FC, useCallback, useRef } from 'react';
import {
  NotificationApiType,
  NotificationStatus,
} from '../Notifications.types';
import {
  NotificationAvatar,
  NotificationAvatarAssets,
  NotificationAvatarPlaceholder,
  NotificationContent,
  NotificationCreatedAt,
  NotificationSubstanceImage,
  StyledNotificationStatus,
} from './Notification.styled';
import { NotificationContainer } from './NotificationContainer';
import { NotificationMessage } from './NotificationMessage';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { DateLocales } from '../../Intl/i18n/i18n.dates';
import { useIntl } from 'react-intl';
import { NotificationTranslation } from '../i18n';
import {
  AvatarMode,
  StyledAvatar,
  StyledImg,
} from '../../User/UserAvatar/Avatar.styled';
import { getGlideImageSrcSet } from '../../../shared/utils/url.utils';
import { UserIcon } from '../../../shared/icons';
import { useMarkAsReadNotificationMutation } from '../Notifications.hooks';
import { NotificationObserver } from './NotificationObserver';

const AVATAR_IMAGE_SIZE = 32;
const SUBSTANCE_IMAGE_SIZE = 24;

interface NotificationProps {
  item: NotificationApiType;
}

export const Notification: FC<NotificationProps> = React.memo(({ item }) => {
  const { message, status } = item;
  const { locale, formatMessage } = useIntl();
  const dateFnsLocale = DateLocales[locale];

  const [markAsReadNotificationMutation] = useMarkAsReadNotificationMutation();

  const readRequestSent = useRef(false);

  const markAsRead = useCallback(() => {
    if (readRequestSent.current) {
      return;
    }
    readRequestSent.current = true;

    markAsReadNotificationMutation({
      variables: {
        input: {
          id: item.id,
          markAsRead: true,
        },
      },
    }).catch(() => {
      readRequestSent.current = false;
    });
  }, [markAsReadNotificationMutation, item.id]);

  return (
    <div>
      {status === NotificationStatus.NEW && (
        <NotificationObserver onMarkAsRead={markAsRead} />
      )}
      <NotificationContainer message={message}>
        <NotificationAvatar>
          <NotificationAvatarAssets>
            <StyledAvatar size={AVATAR_IMAGE_SIZE} mode={AvatarMode.circle}>
              {message.fields.notification_image ? (
                <StyledImg
                  src={message.fields.notification_image}
                  srcSet={getGlideImageSrcSet(
                    message.fields.notification_image,
                    {
                      w: AVATAR_IMAGE_SIZE,
                      h: AVATAR_IMAGE_SIZE,
                      fit: 'crop-center',
                    },
                  )}
                  size={AVATAR_IMAGE_SIZE}
                  initials={''}
                  data-testid="notification-image"
                />
              ) : (
                <NotificationAvatarPlaceholder data-testid="person-icon">
                  <UserIcon />
                </NotificationAvatarPlaceholder>
              )}
            </StyledAvatar>
            {message.fields.context_image && (
              <NotificationSubstanceImage>
                <StyledAvatar
                  size={SUBSTANCE_IMAGE_SIZE}
                  mode={AvatarMode.circle}>
                  <StyledImg
                    src={message.fields.context_image}
                    srcSet={getGlideImageSrcSet(message.fields.context_image, {
                      w: SUBSTANCE_IMAGE_SIZE,
                      h: SUBSTANCE_IMAGE_SIZE,
                      fit: 'crop-center',
                    })}
                    size={SUBSTANCE_IMAGE_SIZE}
                    initials={''}
                    data-testid="context-image"
                  />
                </StyledAvatar>
              </NotificationSubstanceImage>
            )}
          </NotificationAvatarAssets>
        </NotificationAvatar>
        <NotificationContent data-testid="content">
          <div className="notification-item-row">
            <strong data-testid="desktop-name">
              {'desktop_name' in message.fields
                ? message.fields.desktop_name
                : ''}
            </strong>
            <NotificationCreatedAt
              title={format(parseISO(item?.createdAt), 'PPpp', {
                locale: dateFnsLocale,
              })}
              data-testid="created-at">
              {formatDistanceToNow(parseISO(item?.createdAt))}
            </NotificationCreatedAt>
          </div>
          <div className="notification-item-row">
            <NotificationMessage message={message} data-testid="message" />
            {item.status === NotificationStatus.NEW && (
              <StyledNotificationStatus
                title={formatMessage({ id: NotificationTranslation.unread })}
                data-testid="unread"
              />
            )}
          </div>
        </NotificationContent>
      </NotificationContainer>
    </div>
  );
});
