import React, { FC } from 'react';
import { IconProps } from '../../../shared/icons/icon.types';

export const PlayIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8742 10.0769C11.4895 9.86337 11 10.1197 11 10.5349V21.4653C11 21.8803 11.4895 22.1366 11.8742 21.923L21.7198 16.4579C22.0934 16.2505 22.0934 15.7495 21.7198 15.5421L11.8742 10.0769Z"
        fill="currentColor"
      />
    </svg>
  );
});
