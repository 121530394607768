import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { DesktopTranslation } from '../i18n';
import { FormTextarea } from '../../../shared/components/Textarea';
import {
  DesktopField,
  DesktopFormControls,
  FormBody,
  ImportantMessageBody,
  StyledSubTitle,
  StyledSwitch,
} from './DesktopForm.styled';
import { useCurrentWorkspacePermissions } from '../../Workspace/Workspace.hooks';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../shared/components/ImportantMessage';
import { appEnv } from '../../../appEnv';
import { useMobile } from '../../../shared/hooks';
import type {
  DesktopApiType,
  DesktopFormValues,
} from '../data/Desktop/types/Desktop.types';
import { FormikHelpers } from 'formik/dist/types';

const DesktopFormSchema: Schema<DesktopFormValues> = Yup.object().shape({
  name: Yup.string().required(DesktopTranslation.formNameRequiredError),
  description: Yup.string(),
  shareable: Yup.boolean(),
});

interface DesktopFormProps {
  onlyInternalDesktopCreation?: boolean;
  desktop?: DesktopApiType;
  onSubmit: (
    values: DesktopFormValues,
    formikHelpers: FormikHelpers<DesktopFormValues>,
  ) => void;
  onCancel: () => void;
}

export const DesktopForm: FC<DesktopFormProps> = ({
  onlyInternalDesktopCreation = false,
  desktop,
  onSubmit,
  onCancel,
}) => {
  const {
    permissions: { canCreateCompanyDesktop },
  } = useCurrentWorkspacePermissions();

  const isMobile = useMobile();
  const intl = useIntl();
  const shareableDesktop = onlyInternalDesktopCreation
    ? false
    : desktop?.shareable ?? true;

  const initialValues: DesktopFormValues = {
    name: desktop?.name ?? '',
    description: desktop?.description ?? '',
    shareable: shareableDesktop,
  };

  if (!desktop) {
    initialValues.addAccount = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DesktopFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form data-testid="desktop-form">
          <FormBody>
            <DesktopField>
              <Field
                autoFocus
                name="name"
                type="text"
                data-testid="desktop-form-name-input"
                placeholder={intl.formatMessage({
                  id: DesktopTranslation.formNamePlaceholder,
                })}
                label={intl.formatMessage({
                  id: DesktopTranslation.formNameLabel,
                })}
                component={FormInput}
              />
            </DesktopField>

            <DesktopField>
              <Field
                name="description"
                data-testid="desktop-form-description-input"
                placeholder={intl.formatMessage({
                  id: DesktopTranslation.formDescriptionPlaceholder,
                })}
                label={intl.formatMessage({
                  id: DesktopTranslation.formDescriptionLabel,
                })}
                component={FormTextarea}
              />
            </DesktopField>

            {!desktop &&
              canCreateCompanyDesktop &&
              !onlyInternalDesktopCreation && (
                <ImportantMessage
                  type={ImportantMessageType.INFO}
                  withIcon
                  circleIcon>
                  <ImportantMessageBody>
                    <div className="content">
                      <div className="title">
                        <FormattedMessage
                          id={
                            DesktopTranslation.formInternalDesktopMessageTitle
                          }
                        />
                        {!isMobile && (
                          <StyledSubTitle>
                            <FormattedMessage
                              id={
                                DesktopTranslation.formInternalDesktopSubMessageTitle
                              }
                            />
                          </StyledSubTitle>
                        )}
                      </div>
                      <p>
                        <FormattedHTMLMessage
                          id={DesktopTranslation.formInternalDesktopMessageText}
                          values={{
                            learnMoreUrl: appEnv.SUPPORT_DESKTOP_TYPES_URL,
                          }}
                        />
                      </p>
                    </div>
                    <div className="action">
                      <StyledSwitch
                        color="primary"
                        name="shareable"
                        value={!values.shareable}
                        onChange={(e: any) => {
                          setFieldValue('shareable', !e.target.checked);
                        }}
                      />
                    </div>
                  </ImportantMessageBody>
                </ImportantMessage>
              )}

            <DesktopFormControls>
              {desktop ? (
                <Button
                  data-testid="desktop-form-submit-button"
                  type="submit"
                  mode={ButtonMode.primary}
                  size={ButtonSize.md}
                  disabled={isSubmitting}>
                  <FormattedMessage id={DesktopTranslation.editSubmitButton} />
                </Button>
              ) : (
                <>
                  <Button
                    data-testid="desktop-form-cancel-button"
                    type="button"
                    mode={ButtonMode.secondary}
                    size={ButtonSize.md}
                    onClick={onCancel}>
                    <FormattedMessage
                      id={DesktopTranslation.createCancelButton}
                    />
                  </Button>
                  <Button
                    data-testid="desktop-form-submit-button"
                    type="submit"
                    mode={ButtonMode.primary}
                    size={ButtonSize.md}
                    disabled={isSubmitting}>
                    <FormattedMessage
                      id={DesktopTranslation.createSubmitButton}
                    />
                  </Button>
                </>
              )}
            </DesktopFormControls>
          </FormBody>
        </Form>
      )}
    </Formik>
  );
};
