import styled from 'styled-components';

export const StyledOtherWorkspaceNotifications = styled.div`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xxs};
  margin-left: ${({ theme }) => theme.spacing.m};

  &:before {
    content: ' ';
    width: 1px;
    height: 24px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.OnNavbarDivider};
    top: 4px;
    left: -${({ theme }) => theme.spacing.m};
  }
`;

export const OtherWorkspaceContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AdditionalNotificationsCounter = styled.div`
  ${({ theme }) => theme.fontTemplates.headerList};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.OnNavbarMediumEmphasis};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.OnNavbarMediumEmphasis};
`;

export const AdditionalNotificationsIndicator = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${p => p.theme.colors.CountBadgeBackground};
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 3px;
  border: 1px solid ${({ theme }) => theme.colors.IdentityBar};
`;
