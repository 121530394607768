import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ExternalServiceAuthButton,
  ExternalServiceAuthDescriptionStyled,
  ExternalServiceAuthButtonIcon,
} from '..';
import { ImageApiType } from '../../../shared/api/api.types';
import { getImageSrcSet } from '../../../shared/utils/url.utils';
import { ExternalServiceAuthTranslation } from './i18n';

interface ExternalServiceSignInWithGoogleProps {
  signIn: () => void;
  icon?: ImageApiType;
}
export const ExternalServiceSignInWithGoogle: FC<ExternalServiceSignInWithGoogleProps> = ({
  signIn,
  icon,
}) => {
  return (
    <div>
      <ExternalServiceAuthDescriptionStyled>
        <FormattedMessage
          id={ExternalServiceAuthTranslation.signInWithGoogleDescription}
        />
      </ExternalServiceAuthDescriptionStyled>
      <ExternalServiceAuthButton
        onClick={signIn}
        data-testid="external-service-auth-provider-google">
        {icon && (
          <ExternalServiceAuthButtonIcon
            src={icon.contentUrl}
            srcSet={getImageSrcSet(icon.contentUrl, 15)}
            data-testid="external-service-auth-icon"
          />
        )}
        <FormattedMessage
          id={ExternalServiceAuthTranslation.signInWithGoogle}
        />
      </ExternalServiceAuthButton>
    </div>
  );
};
