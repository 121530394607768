import { SectionHeader } from '../SingleView/Section.styled';
import styled from 'styled-components';

export const SectionHeaderWithSubTitle = styled(SectionHeader)`
  flex-direction: column;
  align-items: baseline;

  & h3 {
    margin-bottom: 1.188rem;
  }
`;

export const StyledNotificationsIntegrations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  img {
    width: 2.188rem;
    height: 2.188rem;
    margin-right: 0.75rem;
  }
`;

export const StyledNotificationsIntegrationItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2.188rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledNotificationsIntegrationsName = styled.div`
  ${({ theme }) => theme.fonts.Regular};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: auto;
  align-items: center;
  display: flex;
`;

export const StyledWorkspaceNotificationsTitle = styled.div`
  margin-top: 2.188rem;
`;

export const StyledNotificationsIntegrationListIsEmpty = styled.span`
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;
