import React from 'react';
import { FC } from 'react';
import { IconProps } from './icon.types';

export const HoverPreviewContentArrowIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M-3.78552e-07 8.99999L11 0.339736L11 17.6602L-3.78552e-07 8.99999Z"
        fill="white"
      />
    </svg>
  );
});
