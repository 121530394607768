import React, { FC } from 'react';
import { IconProps } from './icon.types';

export const ClockCircleArrowIcon: FC<IconProps> = React.memo(props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73242 2.98778C6.44488 2.66544 7.21801 2.49911 8 2.49994C9.34399 2.50016 10.6414 2.99239 11.6473 3.8837C12.6532 4.77502 13.298 6.00375 13.46 7.33794C13.4611 7.3471 13.4624 7.35622 13.4638 7.3653C13.4877 7.57353 13.5 7.7853 13.5 7.99994C13.5 11.0375 11.0376 13.4999 8 13.4999C5.21617 13.4999 2.91541 11.4317 2.55045 8.74802C2.49463 8.33758 2.16421 7.99994 1.75 7.99994C1.33579 7.99994 0.995779 8.33687 1.03958 8.74876C1.41327 12.2627 4.38697 14.9999 8 14.9999C11.866 14.9999 15 11.8659 15 7.99994C15 4.38691 12.2627 1.41321 8.74882 1.03952C8.73021 1.03754 8.71176 1.03635 8.69348 1.03591C7.85117 0.952049 6.99654 1.02124 6.17167 1.24439C4.87553 1.59502 3.70756 2.31099 2.807 3.30694L1.427 1.92694C1.39204 1.89188 1.34745 1.868 1.2989 1.85832C1.25034 1.84863 1.20001 1.85359 1.15427 1.87254C1.10853 1.8915 1.06946 1.92362 1.04199 1.96481C1.01453 2.006 0.999912 2.05443 1 2.10394V5.74994C1 5.81624 1.02634 5.87983 1.07322 5.92671C1.12011 5.9736 1.1837 5.99994 1.25 5.99994H4.896C4.94551 6.00003 4.99393 5.98541 5.03513 5.95795C5.07632 5.93048 5.10843 5.89141 5.12739 5.84567C5.14635 5.79993 5.1513 5.7496 5.14162 5.70104C5.13194 5.65249 5.10805 5.6079 5.073 5.57294L3.869 4.36894C4.38463 3.78103 5.01996 3.31012 5.73242 2.98778Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
      <path
        d="M8.28033 4.21967C8.42098 4.36032 8.5 4.55109 8.5 4.75V7.742L10.528 8.554C10.7067 8.63191 10.8481 8.77634 10.9221 8.95669C10.9961 9.13705 10.997 9.33914 10.9246 9.52014C10.8522 9.70115 10.7121 9.84684 10.5341 9.92633C10.3561 10.0058 10.1541 10.0129 9.971 9.946L7.471 8.946C7.33197 8.89028 7.21281 8.79421 7.12886 8.67017C7.04492 8.54612 7.00003 8.39978 7 8.25V4.75C7 4.55109 7.07902 4.36032 7.21967 4.21967C7.36032 4.07902 7.55109 4 7.75 4C7.94891 4 8.13968 4.07902 8.28033 4.21967Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </svg>
  );
});
