import styled from 'styled-components';

export const StyledWorkspaceSwitcher = styled.div<{
  lockDownMode: boolean;
  compensateToolbarHeight: boolean;
}>`
  --layout-topbar-height: 40px;
  min-height: 100%;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  user-select: none;
  display: flex;
  transition: 0.5s;
  background-color: ${p => p.theme.colors.Surface};
  ${({ theme: { breakpoints }, compensateToolbarHeight }) => breakpoints.md`
      width: initial;
      overflow: initial;
      height: 100%;
   `}
`;

export const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-column-end: span 3;
`;

export const NoAccessScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-column-end: span 3;
  background-color: ${p => p.theme.colors.Surface};

  .logoutButton {
    margin-top: 1rem;
  }
`;
