import React, { FC, useCallback } from 'react';
import { ListItem } from '../ListItem';
import { TeamAvatar } from './TeamItem.styled';
import { TeamIcon } from '../../../../../shared/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { DesktopTranslation } from '../../../i18n';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { ListItemMenu } from '../ListItemMenu';
import { useConfirm } from '../../../../../shared/components/Modal';
import type {
  DesktopAccessType,
  DesktopApiType,
} from '../../../data/Desktop/types/Desktop.types';
import { AccessApiType } from '../../../../Access/data/types/Access.types';
import { useAccessActions } from '../../../../Access/data/Access.actions';

interface TeamItemProps {
  access: AccessApiType;
  desktop: DesktopApiType;
}

export const TeamItem: FC<TeamItemProps> = ({ access, desktop }) => {
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();
  const { askConfirmation } = useConfirm();

  const { updateAccess, removeAccessById } = useAccessActions();

  const deleteAccess = useCallback(() => {
    removeAccessById(access.id, desktop.id);
  }, [removeAccessById, desktop.id, access.id]);

  const editAccess = useCallback(
    (accessType: DesktopAccessType) => {
      updateAccess(desktop.id, access.id, accessType);
    },
    [updateAccess, desktop.id, access.id],
  );

  const handleDelete = useCallback(async () => {
    if (
      await askConfirmation(
        formatMessage({
          id: DesktopTranslation.accessRemoveTeamAccessConfirmation,
        }),
      )
    ) {
      deleteAccess();
    }
  }, [askConfirmation, deleteAccess, formatMessage]);

  return (
    <ListItem
      title={
        <FormattedMessage
          id={DesktopTranslation.accessTeamTitle}
          values={{
            team: access.workspaceTeam!.name,
            workspace: workspace.name,
          }}
        />
      }
      subtitle={
        <FormattedMessage
          id={DesktopTranslation.accessTeamMembers}
          values={{
            count: access.workspaceTeam!.membersCount,
          }}
        />
      }
      prepend={
        <TeamAvatar>
          <TeamIcon />
        </TeamAvatar>
      }
      append={
        <ListItemMenu
          onDelete={handleDelete}
          onEdit={editAccess}
          accessType={access.accessType}
        />
      }
    />
  );
};
