import { database } from '../../Database';
import { editCustomAppByIdGraphQLApi } from './Operations/AppStoreGraphQLApi.operations';
import type { EditCustomAppVariables } from '../AppStore.mutations';
import type { DesktopAppApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';

export const useAppStoreActions = () => {
  const updateAppIDDB = async (newApp: DesktopAppApiType) => {
    const cachedApp = await database.desktopApps.get({
      appId: newApp.id,
    });

    if (cachedApp) {
      database.desktopApps.update(cachedApp.id, {
        ...cachedApp,
        sourceData: {
          ...cachedApp,
          app: {
            ...cachedApp.sourceData.app,
            ...newApp,
          },
        },
      });
    }
  };

  const editCustomApp = async (variables: EditCustomAppVariables) => {
    try {
      const editedApp = await editCustomAppByIdGraphQLApi(variables);

      if (editedApp) {
        await updateAppIDDB(editedApp.editCustomApp.app);
      }
    } catch (error: any) {
      showToastGraphQLErrors(error.graphQLErrors);
      console.error(error);
    }
  };

  return { updateAppIDDB, editCustomApp };
};
