export const vaultItemDomain = (vaultItemUrl?: string): string | null => {
  if (!vaultItemUrl) {
    return null;
  }

  try {
    return new URL(vaultItemUrl).hostname;
  } catch {
    return null;
  }
};
