export enum DocumentType {
  PDF = 'pdf',
  TEXT = 'text',
  MS_WORD = 'word',
  MS_EXCEL = 'excel',
  MS_POWERPOINT = 'powerpoint',
  GOOGLE_COMMON = 'docs',
  GOOGLE_DRIVE = 'gdrive',
  GOOGLE_DRIVE_FOLDER = 'gdrive-folder',
  GOOGLE_DOCUMENT = 'document',
  GOOGLE_SPREADSHEETS = 'spreadsheets',
  GOOGLE_PRESENTATION = 'presentation',
  COMMON = 'COMMON',
  GOOGLE_FOLDER = 'gdrive-folder',
  JSON = 'json',
  XML = 'xml',
  RTF = 'rtf',
  JIRA = 'jira_ticket',
}
