import React from 'react';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { FormattedMessage } from 'react-intl';
import {
  StyledLinkAccountMFAWarning,
  LinkAccountMFAWarningLink,
  LinkAccountMFAWarningContainer,
} from './LinkAccountMFAWarning.styled';
import { InfoQuestionIcon } from '../../../../shared/icons';
import { appEnv } from '../../../../appEnv';
import { UserTranslation } from '../../i18n';

export const LinkAccountMFAWarning = () => {
  return (
    <LinkAccountMFAWarningContainer>
      <ImportantMessage type={ImportantMessageType.WARNING}>
        <StyledLinkAccountMFAWarning>
          <FormattedMessage
            id={UserTranslation.deleteAccountLinkedAccountsMFAWarning}
          />
          <LinkAccountMFAWarningLink href={appEnv.SUPPORT_LINKING_ACCOUNTS_URL}>
            <InfoQuestionIcon />
          </LinkAccountMFAWarningLink>
        </StyledLinkAccountMFAWarning>
      </ImportantMessage>
    </LinkAccountMFAWarningContainer>
  );
};
