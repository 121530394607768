import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditTeamForm } from '../../../TeamForm/EditTeamForm';
import { TeamTranslation } from '../../../i18n';

export const EditTeamModal: FC<ModalProps> = ({ onRequestClose, ...props }) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={TeamTranslation.editModalHeader} />
      </ModalHeader>
      <EditTeamForm onDone={onRequestClose} />
    </Modal>
  );
};
