import styled, { css } from 'styled-components';
import { SegmentPage } from '../../Segment/Segment.styled';

export const StyledConferencesPage = styled(SegmentPage)<{
  isMobile: boolean;
  isConferenceVisible: boolean;
}>`
  background-color: ${p => p.theme.colors.Surface};
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &.loading {
    display: flex;
    flex-direction: column;
  }

  ${({ isConferenceVisible }) =>
    isConferenceVisible &&
    css`
      .conference-list {
        display: none;
      }
    `}
`;
