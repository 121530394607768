export enum EventCategoryType {
  ONBOARDING = 'ONBOARDING',
  BILLING = 'BILLING',
  DESKTOP = 'DESKTOP',
  DESKTOP_VIEW = 'DESKTOP_VIEW',
  STATUS = 'STATUS',
  SEGMENT = 'SEGMENT',
  LINK = 'LINK',
  APP = 'APP',
}

export interface TrackingEvent {
  category: string;
  action: string;
  label?: string;
  triggeredByCode?: boolean;
}
