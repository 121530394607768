import React, { FC } from 'react';
import { ConferenceIcon, ImageWrapper } from './ConferenceAvatar.styled';
import { VideoCallIcon } from '../../Chat/icons';

interface ConferenceAvatarEmptyProps {
  avatarSize: string;
}

export const ConferenceAvatarEmpty: FC<ConferenceAvatarEmptyProps> = ({
  avatarSize,
}) => {
  return (
    <ImageWrapper avatarSize={avatarSize}>
      <ConferenceIcon>
        <VideoCallIcon width={20} height={20} />
      </ConferenceIcon>
    </ImageWrapper>
  );
};
