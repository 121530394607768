import styled from 'styled-components';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';

export const StyledCarousel = styled.div`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  *:focus-visible {
    outline: none;
  }

  width: 100%;
`;

export const StyledCarouselContainer = styled.div`
  max-height: 70vh;
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCarouselItem = styled.div<{ isMobile: boolean }>`
  max-height: 75vh;
  align-items: center;
  justify-content: center;
  display: flex;

  & img {
    max-width: ${p => (p.isMobile ? '100%' : '85%')};
    max-height: 70vh;
  }
`;

export const StyledVideoPlayerPreview = styled.div<{
  isMobile: boolean;
}>`
  max-width: ${p => (p.isMobile ? '100%' : '90%')};
  width: 100%;
  padding-top: ${p => (p.isMobile ? '62%' : '52.25%')};
  position: relative;
  margin: 0 auto;
  display: flex !important;
  position: relative;
`;

export const StyledCarouselControl = styled(ButtonWithIcon)<{
  isMobile: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(0);
  left: ${p => (p.isMobile ? '0.3125rem' : 0)};
  width: ${p => (p.isMobile ? '2rem' : '2.5rem')};
  height: ${p => (p.isMobile ? '2rem' : '2.5rem')};
  display: flex !important;
  z-index: 2;
  background: ${p =>
    p.isMobile
      ? p.theme.colors.AssetsModalBackground
      : p.theme.colors.LinkHoverButtonBackground};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.IconLight};

  &.slick-next {
    left: unset;
    right: ${p => (p.isMobile ? '0.3125rem' : 0)};
    transform: translate(0, -50%) rotate(180deg);
  }

  &.slick-disabled {
    opacity: 0.3;
  }
`;
