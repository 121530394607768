import styled from 'styled-components';

export const StyledEncryptedState = styled.div<{ isMobile: boolean }>`
  margin: ${p => (p.isMobile ? 0 : 'auto')};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  height: fit-content;
  max-width: 440px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  line-height: 1.7;

  .burgerButton {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`;

export const StyledCreatePasswordTitle = styled.span`
  margin-top: 1.5rem;
  font-size: 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;

export const StyledCreatePasswordSubTitle = styled.span`
  margin: 0.25rem 0 1.625rem 0;
  font-size: 1rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const LockIconWrapper = styled.div`
  margin: 0 auto;
  width: 5rem;
  height: 5rem;
  background-color: ${p => p.theme.colors.OnSurfaceHover};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    width: 2rem;
    height: 2.375rem;
  }
`;

export const AdditionalInfoWrapper = styled.div`
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem;
  background-color: ${p => p.theme.colors.OnSurfaceHover};
`;

export const SetPasswordInfoRow = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 1rem;
  line-height: 1.5;

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    color: ${p => p.theme.colors.Primary};
  }
`;
