import React, { FC, useCallback } from 'react';
import { ConferencesListItem } from './ConferencesListItem';
import { StyledConferencesList } from './ConferencesList.styled';
import { ConferenceApiType } from '../../../Conference.types';
import { ConferencesListEmptyState } from './ConferencesListEmptyState';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';
import { useUpdateSeenChatConferenceMutation } from '../../../Conference.hooks';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import { useAccountsContext } from '../../../../Account';
import { Spinner } from '../../../../../shared/components/Spinner';

interface ConferencesListProps {
  conferences: ConferenceApiType[];
  listFooter: FC;
}

export const ConferencesList: FC<ConferencesListProps> = ({
  conferences,
  listFooter: ListFooter,
}) => {
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const [updateSeenChatConferenceMutation] =
    useUpdateSeenChatConferenceMutation();

  const markLastConferenceAsSeen = useCallback(
    (id: string) => {
      updateSeenChatConferenceMutation({
        variables: {
          input: {
            id: id,
          },
        },
      });
    },
    [updateSeenChatConferenceMutation],
  );

  const [conferencesListRef] = useHideScrollbarWhenIdle();

  const { loading: accountsAvailabilityLoading } = useAccountsContext();

  return (
    <StyledConferencesList ref={conferencesListRef}>
      {accountsAvailabilityLoading ? (
        <Spinner />
      ) : conferences.length ? (
        <ul>
          {conferences.map(conference => (
            <li key={conference.id}>
              <ConferencesListItem
                conference={conference}
                onSeen={
                  !conference.seenBy.includes(currentAccount.id)
                    ? markLastConferenceAsSeen
                    : undefined
                }
              />
            </li>
          ))}
          <ListFooter />
        </ul>
      ) : (
        <ConferencesListEmptyState />
      )}
    </StyledConferencesList>
  );
};
