import { format } from 'date-fns';
import React, { memo, useContext, useEffect, useRef } from 'react';
import { useIsInViewport } from '../../../../../shared/hooks/dom.hooks';
import { useAccountPopoverControls } from '../../../AccountPopover';
import type {
  ChatItemType,
  ChatMessageInternalType,
} from '../../../Chat.types';
import { ChatMessage } from '../ChatMessage/ChatMessage';
import { TODAY_DATE_FORMAT } from '../ConversationView.constants';
import { useChatMessageMenu } from '../ConversationView.hooks';
import { useMessageListDataManagerContext } from '../MessagesList/MessageList.hooks';
import {
  StyledChatMessageThreadItem,
  ThreadSpacer,
} from './ChatMessageThread.styled';
import { ChatMessageThreadDateDelimiter } from './ChatMessageThreadDateDelimeter';
import { usePageVisibility } from 'react-page-visibility';
import { ChatMessageContext } from '../ChatMessage/ChatMessage.context';
import { makeChatMessageThreadDomId } from './ChatMessageThread.utils';

interface ChatMessageThreadItemProps {
  chatItem: ChatItemType;
  showNone: boolean;
  isMobile: boolean;
  replyFormVisible: boolean;
  withReaction: boolean;
}

export const ChatMessageThreadItem = memo<ChatMessageThreadItemProps>(
  ({ chatItem, showNone, isMobile, replyFormVisible, withReaction }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const isRendered = useRef(false);
    const currentDate = format(new Date(), TODAY_DATE_FORMAT);
    const { showAccountPopover } = useAccountPopoverControls();
    const threadIsInView = useIsInViewport(elementRef);
    const { addUnreadMessage } = useMessageListDataManagerContext();
    const pageIsVisible = usePageVisibility();

    const isMessageUnread =
      pageIsVisible &&
      elementRef.current &&
      'id' in chatItem &&
      !chatItem.isSeen &&
      threadIsInView;

    useEffect(() => {
      if (isMessageUnread) {
        addUnreadMessage(chatItem);
      }
    }, [addUnreadMessage, chatItem, isMessageUnread]);

    const {
      showChatMessageMenu,
      cancelMessageEditing,
      updateChatMessageHook,
      showChatMessageAssetMenu,
    } = useChatMessageMenu((chatItem as ChatMessageInternalType).id);

    const { messageIsEditing } = useContext(ChatMessageContext);

    useEffect(() => {
      if (elementRef.current) {
        isRendered.current = true;
      }
    }, [isRendered]);

    return (
      <StyledChatMessageThreadItem
        ref={elementRef}
        showNone={showNone}
        isMobile={isMobile}
        replyFormVisible={replyFormVisible}
        withReaction={withReaction && !('dateDelimiter' in chatItem)}>
        {'dateDelimiter' in chatItem ? (
          <ChatMessageThreadDateDelimiter
            dateDelimiter={chatItem.dateDelimiter}
            currentDate={currentDate}
          />
        ) : (
          <ChatMessage
            chatMessage={chatItem}
            threadMessageDomId={makeChatMessageThreadDomId(chatItem.id)}
            secondary={chatItem.secondary}
            onAvatarClick={showAccountPopover}
            onMenu={showChatMessageMenu}
            onAssetMenu={showChatMessageAssetMenu}
            {...(messageIsEditing(makeChatMessageThreadDomId(chatItem.id))
              ? {
                  isEditing: true,
                  onEditSave: updateChatMessageHook,
                  onEditCancel: cancelMessageEditing,
                }
              : null)}
            className={`thread-chat-message${
              chatItem.secondary ? ' is-secondary' : ''
            }`}
            allowReply={false}
            showTread={false}
          />
        )}
        <ThreadSpacer />
      </StyledChatMessageThreadItem>
    );
  },
);
