import styled, { css } from 'styled-components';
import { SeparatorMode } from './Separator';

export const StyledSeparator = styled.div<{
  mode: SeparatorMode;
  size?: number;
  noWrap?: boolean;
  uppercase?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${p => (p.size ? p.size + 'px' : '100%')};
  flex: 1 1 100%;

  ${({ mode, size }) =>
    mode === SeparatorMode.vertical &&
    css`
      width: initial;
      height: ${size ? size + 'px' : '100%'};
      flex: 1 1 auto;
      flex-direction: column;
    `}

  .line {
    background-color: ${p => p.theme.colors.Divider};

    flex: 1 1 100%;
    height: 1px;

    ${p =>
      p.mode === SeparatorMode.vertical &&
      css`
        width: 1px;
        height: initial;
      `}
  }

  .message {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.825rem;
    line-height: 1.3;
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

    flex: 1 1 auto;
    margin: 0 10px;

    ${p =>
      p.noWrap &&
      css`
        white-space: nowrap;
      `}

    ${p =>
      p.uppercase &&
      css`
        text-transform: uppercase;
      `}
    
    ${p =>
      p.mode === SeparatorMode.vertical &&
      css`
        margin: 10px 0;
      `}
  }
`;
