import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DangerZone,
  DangerZoneButton,
  DangerZoneButtonMode,
  DangerZoneHeaderTitle,
  DangerZoneWarning,
  Section,
  SectionBody,
  SectionHeader,
} from '../../../../../Admin';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { UserTranslation } from '../../../../i18n';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { useRevokeInvitationMutation } from '../../../../User.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import { Spinner } from '../../../../../../shared/components/Spinner';
import { AccountInvitationApiType } from '../../../../../Invitation/data/Invitation/types/Invitation.types';
import { deleteInvitationFromIDDB } from '../../../../../Invitation/data/Invitation/operations/InvitationIDDB.operations';

interface DangerZoneProps {
  invitation: AccountInvitationApiType;
}

export const DangerZoneSection: FC<DangerZoneProps> = ({ invitation }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { askConfirmation } = useConfirm();
  const [revokeInvitationMutation] = useRevokeInvitationMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleRevokeClick = useCallback(() => {
    askConfirmation(
      formatMessage({
        id: UserTranslation.adminDangerZoneRevokeConfirm,
      }),
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      setLoading(true);
      revokeInvitationMutation({
        variables: {
          input: {
            id: invitation.id,
          },
        },
      })
        .then(() => deleteInvitationFromIDDB(invitation.id))
        .then(() => {
          showToastSuccessMessage(UserTranslation.adminDangerZoneRevokeSuccess);
          const usersPath = location.pathname.split('/').slice(0, 5).join('/');
          navigate(usersPath);
        })
        .catch(err => {
          setLoading(false);
          showToastGraphQLErrors(err.graphQLErrors);
        });
    });
  }, [
    askConfirmation,
    formatMessage,
    revokeInvitationMutation,
    invitation.id,
    location.pathname,
    navigate,
  ]);

  return (
    <Section data-testid="danger-zone">
      <SectionHeader>
        <DangerZoneHeaderTitle data-testid="title">
          <FormattedMessage id={UserTranslation.adminDangerZone} />
        </DangerZoneHeaderTitle>
      </SectionHeader>

      <SectionBody noPadding={true}>
        <DangerZone>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <DangerZoneButton
                data-testid="revoke-button"
                size={ButtonSize.sm}
                mode={ButtonMode.secondary}
                dangerMode={DangerZoneButtonMode.critical}
                onClick={handleRevokeClick}>
                <FormattedMessage
                  id={UserTranslation.adminDangerZoneRevokeButton}
                />
              </DangerZoneButton>
              <DangerZoneWarning data-testid="suspend-warning">
                <FormattedMessage
                  id={UserTranslation.adminDangerZoneRevokeWarning}
                />
              </DangerZoneWarning>
            </>
          )}
        </DangerZone>
      </SectionBody>
    </Section>
  );
};
