import { ApiConnection } from '../../../shared/api/api.types';
import { VaultApiType, VaultItemApiType } from '../Encryption.types';

export const updateVaultItem = (
  vaults: VaultApiType[] = [],
  vaultId: string,
  updatedVaultProperties: Partial<VaultItemApiType>,
): VaultApiType[] => {
  return vaults.map(vault => {
    return vault.id !== vaultId
      ? vault
      : {
          ...vault,
          vaultItems: {
            ...vault.vaultItems,
            edges: [
              {
                ...vault.vaultItems?.edges[0],
                node: {
                  ...vault.vaultItems?.edges[0].node,
                  ...updatedVaultProperties,
                },
              },
            ],
          } as ApiConnection<VaultItemApiType>,
        };
  });
};
