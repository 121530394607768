import { ApolloClient } from '@apollo/client';
import {
  NewScheduledRepeatingChatConferenceEvent,
  RemoveScheduledRepeatingChatConferenceEvent,
} from '../ChatMercure.types';
import { DEFAULT_PAGE_SIZE } from '../../../Conference/ConferencesPage';
import {
  addScheduledRepeatingConferenceToListCache,
  removeScheduledRepeatingConferenceToListCache,
} from '../../../Conference/cache/ScheduledRepeatingConference.cache';
import { FilterPeriod } from '../../../Conference/Conference.types';

export const handleNewRepeatingScheduledChatConferencesInCache = (
  event: NewScheduledRepeatingChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  if (event.scheduleChatConferences[0].chatConference?.workspace?.id) {
    addScheduledRepeatingConferenceToListCache(
      apolloClient,
      {
        first: DEFAULT_PAGE_SIZE,
        filterPeriod: FilterPeriod.future,
        workspace:
          '/workspaces/' +
          event.scheduleChatConferences[0].chatConference.workspace.id,
      },
      event.scheduleChatConferences,
      event['@iri'],
    );
  }
};

export const handleRemoveRepeatingScheduledChatConferencesInCache = (
  event: RemoveScheduledRepeatingChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  if (event.workspaceId) {
    removeScheduledRepeatingConferenceToListCache(
      apolloClient,
      {
        first: DEFAULT_PAGE_SIZE,
        workspace: '/workspaces/' + event.workspaceId,
        filterPeriod: FilterPeriod.future,
      },
      event.scheduleChatConferenceIds,
    );
    removeScheduledRepeatingConferenceToListCache(
      apolloClient,
      {
        first: DEFAULT_PAGE_SIZE,
        workspace: '/workspaces/' + event.workspaceId,
        filterPeriod: FilterPeriod.past,
      },
      event.scheduleChatConferenceIds,
    );
  }
};
