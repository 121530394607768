import { captureException } from '@sentry/react';
import type { SignUpFlow } from '../../../Onboarding/Onboarding.types';
import type { AccountApiType } from '../../../User/User.types';
import { createLinkedAccountWorkspaceApi } from '../DataSource/WorkspaceApiSource';

export const useWorkspaceApiRepository = () => {
  const createLinkedAccountWorkspace = async (
    flowType: SignUpFlow,
  ): Promise<AccountApiType | null> => {
    try {
      const account = await createLinkedAccountWorkspaceApi(flowType);

      return account;
    } catch (err) {
      captureException(err);
      return null;
    }
  };

  return {
    createLinkedAccountWorkspace,
  };
};
