import React, { useCallback, useState } from 'react';
import { StyledUpgradeButton } from './UpgradeButton.styled';
import { DesktopTranslation } from '../../../Desktop/i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowRightIcon } from '../../../../shared/icons';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useUpgradeWorkspaceMutation,
} from '../../../Workspace/Workspace.hooks';
import { PaymentPlan } from '../../../Billing/Billing.types';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { useConfirm } from '../../../../shared/components/Modal';
import { useGetRepeatedPaymentCustomerByWorkspaceQuery } from '../../../Billing/Billing.hooks';
import { BillingTranslation } from '../../../Billing/i18n';
import { UpgradeConfirmationControls } from '../../../Billing/UpgradeProAccount/UpgradeProAccount.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { useNativeWrapper } from '../../../NativeWrapper';
import { sendBillingTrackingEvent } from '../../../Billing/tracking/BillingTracking.utils';

export const UpgradeButton = () => {
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { account } = useCurrentAccount();
  const [upgradeWorkspaceMutation] = useUpgradeWorkspaceMutation();
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const { isNativeWrapperAvailable } = useNativeWrapper();

  const {
    data,
    loading: paymentCustomerLoading,
    refetch: refetchPaymentCustomer,
  } = useGetRepeatedPaymentCustomerByWorkspaceQuery({
    variables: {
      id: workspace.id,
    },
    fetchPolicy: 'no-cache',
  });

  const paymentCustomer = data?.workspace?.paymentCustomer;

  const askUpgradeConfirmation = useCallback(
    () =>
      askConfirmation(
        intl.formatMessage({
          id: BillingTranslation.upgradeConfirmation,
        }),
        intl.formatMessage({
          id: BillingTranslation.upgradeConfirmationTitle,
        }),
        {
          width: 360,
          customControls: (onConfirm, onCancel) => (
            <UpgradeConfirmationControls>
              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                onClick={onCancel}
                data-testid="enter-new-details">
                <FormattedMessage
                  id={BillingTranslation.upgradeEnterNewDetailsButton}
                />
              </Button>
              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={onConfirm}
                data-testid="continue-upgrade">
                <FormattedMessage
                  id={BillingTranslation.upgradeContinueButton}
                />
              </Button>
            </UpgradeConfirmationControls>
          ),
        },
      ),
    [askConfirmation, intl],
  );

  const handleCtaClick = useCallback(() => {
    const upgradeToPro = (hasPaymentCustomer: boolean) => {
      setIsUpgrading(true);
      return upgradeWorkspaceMutation({
        variables: {
          input: {
            id: workspace.id,
            newType: PaymentPlan.PRO,
            reuseOldBilling: hasPaymentCustomer,
          },
        },
      })
        .then(res => {
          setIsUpgrading(false);
          if (!res.data) {
            return;
          }
          if (hasPaymentCustomer) {
            sendBillingTrackingEvent('upgrade_with_existing_payment_customer');
            showToastSuccessMessage(BillingTranslation.upgradeAccountSuccess);
          }
          refetchWorkspaces({
            id: account!.identityId,
          });
          refetchPermissions();
          refetchPaymentCustomer();
        })
        .catch(e => {
          setIsUpgrading(false);
          showToastGraphQLErrors(e.graphQLErrors);
        });
    };

    if (paymentCustomer) {
      askUpgradeConfirmation().then(confirm => {
        if (confirm === null) return;
        upgradeToPro(confirm);
      });
    } else {
      upgradeToPro(!!paymentCustomer);
    }
  }, [
    paymentCustomer,
    upgradeWorkspaceMutation,
    workspace.id,
    refetchWorkspaces,
    account,
    refetchPermissions,
    refetchPaymentCustomer,
    askUpgradeConfirmation,
  ]);

  if (workspace.type === PaymentPlan.FREE && !isNativeWrapperAvailable) {
    return (
      <div>
        <StyledUpgradeButton
          data-testid="upgrade-button"
          className="verify-trigger"
          disabled={paymentCustomerLoading}
          onClick={handleCtaClick}>
          <FormattedMessage id={DesktopTranslation.upgradeBannerProCtaText} />
          <ArrowRightIcon width={10} height={8} />
        </StyledUpgradeButton>
        {isUpgrading && <SpinnerFullscreen />}
      </div>
    );
  }

  return null;
};
