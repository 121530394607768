import React, { FC } from 'react';
import { CheckmarkIcon, LockIcon } from '../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import {
  StyledCreatePasswordSubTitle,
  StyledCreatePasswordTitle,
  SetPasswordInfoRow,
  LockIconWrapper,
  AdditionalInfoWrapper,
} from './EncryptedState.styled';
import { EncryptionTranslation } from '../../i18n';
import { SetPassword } from '../../../Password/SetPassword';
import { PasswordTranslation } from '../../../Password/i18n';

export const SetPasswordView: FC = () => {
  return (
    <>
      <LockIconWrapper>
        <LockIcon />
      </LockIconWrapper>
      <FormattedMessage
        tagName={StyledCreatePasswordTitle}
        id={EncryptionTranslation.masterPasswordCreatePasswordTitle}
      />
      <FormattedMessage
        tagName={StyledCreatePasswordSubTitle}
        id={EncryptionTranslation.masterPasswordCreatePasswordSubTitle}
      />
      <SetPassword
        showWarningMessage={false}
        submitButtonMessageId={
          PasswordTranslation.setPasswordFormSubmitButtonTitle
        }
      />
      <AdditionalInfoWrapper>
        <SetPasswordInfoRow>
          <CheckmarkIcon />
          <FormattedMessage
            id={EncryptionTranslation.masterPasswordUnlockMessageFirstRow}
          />
        </SetPasswordInfoRow>
        <SetPasswordInfoRow>
          <CheckmarkIcon />
          <FormattedMessage
            id={EncryptionTranslation.masterPasswordUnlockMessageSecondRow}
          />
        </SetPasswordInfoRow>
        <SetPasswordInfoRow>
          <CheckmarkIcon />
          <FormattedMessage
            id={EncryptionTranslation.masterPasswordUnlockMessageThirdRow}
          />
        </SetPasswordInfoRow>
      </AdditionalInfoWrapper>
    </>
  );
};
