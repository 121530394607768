import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { AccountApiType } from '../../User/User.types';
import { AvatarImage } from '../../Chat/ChatAvatar/AvatarImage';
import { AvatarPlaceholder } from '../../Chat/ChatAvatar/AvatarPlaceholder';

const ImageGrid = styled.div<{ itemsCount: number; avatarSize: string }>`
  flex-shrink: 0;
  width: ${p => p.avatarSize};
  height: ${p => p.avatarSize};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  color: ${p => p.theme.colors.PrimaryTextLight};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${p => p.theme.colors.BackgroundLight};
  font-size: ${p => p.avatarSize};

  ${p =>
    p.itemsCount === 2 &&
    css`
      > *:nth-child(1) {
        grid-area: 1 / 1 / span 2 / 1;
      }

      > *:nth-child(2) {
        grid-area: 1 / 2 / span 2 / 2;
      }
    `}

  ${p =>
    p.itemsCount === 3 &&
    css`
      > *:nth-child(1) {
        grid-area: 1 / 1 / span 2 / 1;
      }
    `}

  .extraItems {
    background-color: ${p => p.theme.colors.BackgroundLight};
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    font-size: 0.25em;
    padding-top: 0.125rem;
    padding-right: 0.125rem;
    text-align: center;
  }
`;

const MAX_VISIBLE_PARTICIPANTS = 4;

export const ConferenceAvatarGroup = React.forwardRef<
  HTMLDivElement,
  { participants: AccountApiType[]; avatarSize?: string; onClick?: () => void }
>(({ participants, avatarSize = '2.625rem', onClick }, ref) => {
  const hasInvisibleParticipants =
    participants.length > MAX_VISIBLE_PARTICIPANTS;

  const visibleParticipants = useMemo(
    () =>
      hasInvisibleParticipants
        ? participants.slice(0, MAX_VISIBLE_PARTICIPANTS - 1)
        : participants,
    [hasInvisibleParticipants, participants],
  );
  const invisibleParticipantsCount =
    participants.length - visibleParticipants.length;

  return (
    <ImageGrid
      ref={ref}
      itemsCount={participants.length}
      avatarSize={avatarSize}
      onClick={onClick}>
      {visibleParticipants.map(participant =>
        participant.image ? (
          <AvatarImage key={participant.id} account={participant} />
        ) : (
          <AvatarPlaceholder key={participant.id} account={participant} />
        ),
      )}
      {invisibleParticipantsCount > 0 && (
        <div className="extraItems">+{invisibleParticipantsCount}</div>
      )}
    </ImageGrid>
  );
});
