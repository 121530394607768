import React, { useCallback, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { useAuthService, useCurrentAccount } from '../../../Auth/Auth.hooks';
import { ExternalRegistrationForm } from './ExternalRegistrationForm';
import { Navigate } from 'react-router-dom';
import {
  ExternalRegistrationNoAccountMessage,
  ExternalRegistrationNoAccountMessageEmail,
  ExternalRegistrationPageTitle,
} from './ExternalRegistrationPage.styled';
import { OnboardingTranslation } from '../../i18n';
import { FormattedMessage } from 'react-intl';
import { useQueryParams } from '../../../../shared/hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import { OnboardingStep } from '../OnboardingPage.styled';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import {
  useConnectionFromUrl,
  useSelectPlanForAccountMutation,
} from '../../Onboarding.hooks';
import {
  ExternalRegistrationFormValues,
  SignUpFlow,
} from '../../Onboarding.types';
import { useCookieStorage } from '../../../../shared/utils/storage';
import { OPEN_QUICK_START_GUIDE_STORAGE_KEY } from '../../Onboarding.constants';
import {
  openDeepLinkInNativeWrapper,
  useNativePlatformFromUrl,
} from '../../../NativeWrapper';
import { useGetForRegistrationAccountInvitation } from '../../../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const ExternalRegistrationPage = () => {
  const { flow, inviteId, linkRequestId } = useQueryParams();

  const nativePlatform = useNativePlatformFromUrl();
  const connection = useConnectionFromUrl();

  const { data: invitationData, loading: invitationLoading } =
    useGetForRegistrationAccountInvitation({
      skip: !inviteId,
      variables: {
        id: `/account-invitations/${inviteId}`,
      },
    });

  const invitation = invitationData?.getForRegistrationAccountInvitation;
  const { account, refetchAccountData } = useCurrentAccount();
  const [selectPlanForAccountMutation] = useSelectPlanForAccountMutation();
  const [invitationEmailError, setInvitationEmailError] = useState(false);

  const [, setOpenQuickStartGuide] = useCookieStorage(
    OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  );

  const authService = useAuthService();

  const selectPlanForAccount = useCallback(
    ({ email, acceptTerms, ...values }: ExternalRegistrationFormValues) => {
      return selectPlanForAccountMutation({
        variables: {
          input: {
            ...values,
            ...(invitationData
              ? {
                  accountInvitation:
                    invitationData.getForRegistrationAccountInvitation.id,
                }
              : {}),
            ...(linkRequestId ? { accountLinkRequest: linkRequestId } : {}),
          },
        },
      })
        .then(async response => {
          if (!response) {
            return;
          }

          if (response.errors) {
            const message = response.errors[0].message;
            if (message === 'Email provided does not match invitation email') {
              setInvitationEmailError(true);
            }
          }

          if (nativePlatform && connection) {
            openDeepLinkInNativeWrapper(
              nativePlatform,
              `login/external?connection=${connection}`,
            );
            return;
          }
          sessionStorage.setItem('isSocialSignUp', 'true');
          setOpenQuickStartGuide('true');
          await authService.getToken({ ignoreCache: true });
          refetchAccountData!();
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [
      selectPlanForAccountMutation,
      invitationData,
      linkRequestId,
      nativePlatform,
      connection,
      setOpenQuickStartGuide,
      refetchAccountData,
      authService,
    ],
  );

  if (invitationLoading) {
    return <Spinner size={32} containerHeight={280} />;
  }

  if (account!.currentFlow !== SignUpFlow.EXTERNAL_SELECT_PLAN) {
    return <Navigate to={'/'} />;
  }

  return (
    <OnboardingPage>
      <OnboardingStep>
        <ExternalRegistrationPageTitle>
          <FormattedMessage
            id={OnboardingTranslation.onboardingExternalRegistrationTitle}
          />
        </ExternalRegistrationPageTitle>
        <ExternalRegistrationNoAccountMessage>
          <FormattedMessage
            id={
              OnboardingTranslation.onboardingExternalRegistrationNoAccountMessage
            }
            values={{
              email: (
                <ExternalRegistrationNoAccountMessageEmail>
                  {account?.email}
                </ExternalRegistrationNoAccountMessageEmail>
              ),
            }}
          />
        </ExternalRegistrationNoAccountMessage>
        <ExternalRegistrationForm
          account={account!}
          flow={flow as SignUpFlow}
          invitation={invitation}
          onSubmit={selectPlanForAccount}
          invitationEmailError={invitationEmailError}
        />
      </OnboardingStep>
    </OnboardingPage>
  );
};
