import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../../Workspace/Workspace.hooks';
import { FormattedMessage } from 'react-intl';
import { useQueryParams } from '../../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { NavigationItem, StyledNavigation } from './Navigation.styled';
import { PaymentPlan } from '../../../../../Billing/Billing.types';
import { VerifyFeatureAccess } from '../../../../../Billing/VerifyFeatureAccess';
import { NavigationTrigger } from '../../../../../Workspace/WorkspaceAdmin/WorkspaceAdmin.styled';
import { BillingTranslation } from '../../../../../Billing/i18n';
import { AppStoreTranslation } from '../../../../i18n';
import { TabId } from '../../../../AppStore.constants';

export const Navigation: FC = () => {
  const queryParams = useQueryParams();
  const { tabId } = queryParams;
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: {
      canMenuSsoPage,
      canViewSsoPage,
      canMenuCustomAppsPage,
      canViewCustomAppsPage,
      canSeeMyPasswords,
    },
  } = useCurrentWorkspacePermissions();

  const setActiveTab = useCallback(
    (tabId: TabId) =>
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          tabId: tabId,
          categoryId: undefined,
          ssoGroupId: undefined,
        }),
      }),
    [navigate, queryParams],
  );

  return (
    <StyledNavigation data-testid="navigation">
      <NavigationItem
        data-tourid="app-store-app-store-link"
        className={!tabId || tabId === TabId.appStore ? 'active' : ''}>
        <NavigationTrigger onClick={() => setActiveTab(TabId.appStore)}>
          <FormattedMessage id={AppStoreTranslation.appStoreTabFilter} />
        </NavigationTrigger>
      </NavigationItem>

      <NavigationItem
        data-tourid="app-store-my-store-link"
        className={tabId === TabId.personal ? 'active' : ''}>
        <NavigationTrigger onClick={() => setActiveTab(TabId.personal)}>
          <FormattedMessage id={AppStoreTranslation.personalTabFilter} />
        </NavigationTrigger>
      </NavigationItem>

      {/*PASSWORDS*/}
      {canSeeMyPasswords && (
        <NavigationItem
          data-tourid="password-apps-link"
          className={tabId === TabId.passwords ? 'active' : ''}>
          <NavigationTrigger onClick={() => setActiveTab(TabId.passwords)}>
            <FormattedMessage id={AppStoreTranslation.myPasswordsTabFilter} />
          </NavigationTrigger>
        </NavigationItem>
      )}

      {/* // TODO: Add UpgradeRequired? */}
      {workspace.type !== PaymentPlan.FREE && (
        <NavigationItem
          data-tourid="app-store-company-link"
          className={tabId === TabId.company ? 'active' : ''}>
          <NavigationTrigger onClick={() => setActiveTab(TabId.company)}>
            <FormattedMessage
              id={AppStoreTranslation.companyTabFilter}
              values={{ workspaceName: workspace.name }}
            />
          </NavigationTrigger>
        </NavigationItem>
      )}

      {/* CUSTOM APPS */}
      <VerifyFeatureAccess
        canDisplay={canMenuCustomAppsPage}
        canAccess={canViewCustomAppsPage}
        restrictionMessage={BillingTranslation.generalUpgradeMessage}>
        <NavigationItem
          data-tourid="app-store-custom-apps-link"
          className={tabId === TabId.customApps ? 'active' : ''}>
          <NavigationTrigger onClick={() => setActiveTab(TabId.customApps)}>
            <FormattedMessage id={AppStoreTranslation.customAppsTabFilter} />
          </NavigationTrigger>
        </NavigationItem>
      </VerifyFeatureAccess>

      {/* SSO */}
      <VerifyFeatureAccess
        canDisplay={canMenuSsoPage}
        canAccess={canViewSsoPage}
        restrictionMessage={BillingTranslation.generalUpgradeMessage}>
        <NavigationItem
          data-tourid="app-store-sso-apps-link"
          className={tabId === TabId.sso ? 'active' : ''}>
          <NavigationTrigger onClick={() => setActiveTab(TabId.sso)}>
            <FormattedMessage id={AppStoreTranslation.ssoAppTabName} />
          </NavigationTrigger>
        </NavigationItem>
      </VerifyFeatureAccess>
    </StyledNavigation>
  );
};
