import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { ImageApiType } from '../../../shared/api/api.types';
import { StyledImageUpload, StyledInput } from './ImageUpload.styled';
import { useUploadImageMutation } from '../Image.hooks';
import {
  showToastGraphQLErrors,
  showToastInfoMessage,
} from '../../../shared/components/Toast';
import { GlobalTranslation } from '../../Intl/i18n';
import { isFileSizeWithinLimit } from './ImageUpload.utils';

const DEFAULT_IMAGE_INPUT_ID = 'image-upload-input';

export interface ImageUploadProps {
  id?: string;
  onUpload: (file: ImageApiType) => void;
  disabled?: boolean;
}

export const ImageUpload: FC<PropsWithChildren<ImageUploadProps>> = ({
  children,
  onUpload,
  id,
  disabled,
}) => {
  const [uploadImageMutation] = useUploadImageMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = useCallback(
    ({
      target: {
        validity,
        files: [file],
      },
    }: {
      target: {
        validity: { valid: boolean };
        files: any;
      };
    }) => {
      const isFileSizeAvailableToUpload = isFileSizeWithinLimit(file);

      if (isFileSizeAvailableToUpload) {
        validity.valid &&
          file &&
          uploadImageMutation({ variables: { file } })
            .then(({ data }) => {
              if (data) {
                onUpload(data.createImageAsset.imageAsset);
              }
            })
            .catch(e => showToastGraphQLErrors(e.graphQLErrors));
      } else {
        if (fileInputRef?.current?.value) {
          fileInputRef.current.value = '';
        }
        showToastInfoMessage(GlobalTranslation.uploadFileSizeMessage);
      }
    },
    [onUpload, uploadImageMutation],
  );

  return (
    <StyledImageUpload data-testid="image-upload" className="imageUpload">
      <StyledInput
        disabled={disabled}
        onChange={handleUpload}
        type="file"
        title=""
        data-testid="file-input"
        id={id || DEFAULT_IMAGE_INPUT_ID}
        ref={fileInputRef}
      />
      <label htmlFor={id || DEFAULT_IMAGE_INPUT_ID} data-testid="upload-label">
        {children}
      </label>
    </StyledImageUpload>
  );
};
