import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useBrowserSupportCheck } from './BrowserSupport.hooks';
import { showBrowserNotFullySupportedToast } from '../../shared/components/Toast';
import { useSessionStorage } from 'react-use';
import { BROWSER_NOT_FULLY_SUPPORTED_TOAST_SHOWN } from './BrowserSupport.constants';

export const BrowserSupportProvider: FC<PropsWithChildren> = ({ children }) => {
  const { supported } = useBrowserSupportCheck();
  const [
    browserNotFullySupportedToastShown,
    setBrowserNotFullySupportedToastShown,
  ] = useSessionStorage<boolean>(
    BROWSER_NOT_FULLY_SUPPORTED_TOAST_SHOWN,
    false,
  );

  useEffect(() => {
    if (!supported && !browserNotFullySupportedToastShown) {
      showBrowserNotFullySupportedToast();
      setBrowserNotFullySupportedToastShown(true);
    }
  }, []); // eslint-disable-line

  return <>{children}</>;
};
