import type { ChatMessageInternalType } from '../../../Chat.types';
import {
  bulkGetByParentId,
  bulkPut,
  bulkUpdate,
  clearDB,
  deleteOne,
  getOne,
  putOne,
  updateOne,
} from '../../DataSource/ChatMessage/ChatMessagesIndexedDBSource';
import { ChatMessagesTableType } from '../../../../Database/ChatMessagesTable/ChatMessagesTable';

export const getIDDBChatMessage = async (
  chatMessageId: string,
): Promise<ChatMessagesTableType | undefined> => await getOne(chatMessageId);

export const createIDDBChatMessage = async (
  chatMessage: ChatMessageInternalType,
) => await putOne(chatMessage);

export const deleteIDDBChatMessage = async (id: string) => await deleteOne(id);

export const updateIDDBChatMessage = async (
  chatMessageId: string,
  chatMessage: ChatMessageInternalType,
) => await updateOne(chatMessageId, chatMessage);

export const replaceOptimisticIDDBChatMessage = async (
  optimisticChatMessageId: string,
  chatMessageFromAPI: ChatMessageInternalType,
) => {
  try {
    const optimisticChatMessageRow = await getIDDBChatMessage(
      optimisticChatMessageId,
    );
    const cachedChatMessageFromApiRow = await getIDDBChatMessage(
      chatMessageFromAPI.id,
    );

    if (!cachedChatMessageFromApiRow) {
      await updateIDDBChatMessage(optimisticChatMessageId, chatMessageFromAPI);
    } else {
      await deleteIDDBChatMessage(optimisticChatMessageId);
    }
  } catch {
    deleteIDDBChatMessage(optimisticChatMessageId);
  }
};

export const createMultipleIDDBChatMessage = async (
  chatMessages: ChatMessageInternalType[],
) => await bulkPut(chatMessages);

export const clearIDDBChatMessagesList = async (conversationId: string) =>
  await clearDB(conversationId);

export const getBulkIDDBChatMessagesByParentId = async (parentId: string) =>
  await bulkGetByParentId(parentId);

export const bulkUpdateIDDBChatMessage = async (
  chatMessageIds: string[],
  updater: (
    value: ChatMessagesTableType,
    ref: { value: ChatMessagesTableType },
  ) => void,
) => {
  return await bulkUpdate(chatMessageIds, updater);
};
