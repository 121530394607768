import React, { FC } from 'react';
import {
  StyledConferenceView,
  ConferenceViewSection,
} from './ConferenceView.styled';
import { ConferenceHeader } from './ConferenceHeader';
import { ConferenceTitleControls } from './ConferenceTitleControls';
import { ConferenceDetails } from './ConferenceDetails';
import { useCurrentConference } from '../../Conference.hooks';
import { ConferenceParticipants } from './ConferenceParticipants';
import {
  SegmentPageTitleBar,
  TitleBarAvatar,
  TitleBarControls,
  TitleBarTitle,
  TitleBarTitleContainer,
} from '../../../Segment/SegmentPageTitleBar';
import { ConferenceHeaderUsersTitle } from './ConferenceHeader/ConferenceHeaderUsersTitle';
import { ConferenceAvatar } from '../../ConferenceAvatar';
import { StyledConferencesPage } from '../ConferencesPage.styled';
import { useMobile } from '../../../../shared/hooks';
import { useHideScrollbarWhenIdle } from '../../../../shared/hooks/element.hooks';

type ConferenceViewProps = {
  currentConferenceId?: string;
};

export const ConferenceView: FC<ConferenceViewProps> = ({
  currentConferenceId,
}) => {
  const { conference } = useCurrentConference();
  const isMobile = useMobile();
  const [ConferencePageRef] = useHideScrollbarWhenIdle();

  return (
    <>
      <SegmentPageTitleBar>
        {conference && (
          <>
            <TitleBarTitleContainer>
              <TitleBarAvatar>
                <ConferenceAvatar
                  conference={conference}
                  avatarSize="24px"
                  showOnlineStatus={false}
                />
              </TitleBarAvatar>
              <TitleBarTitle>
                {conference?.desktop?.name || <ConferenceHeaderUsersTitle />}
              </TitleBarTitle>
            </TitleBarTitleContainer>
            <TitleBarControls>
              <ConferenceTitleControls />
            </TitleBarControls>
          </>
        )}
      </SegmentPageTitleBar>

      <StyledConferencesPage
        ref={ConferencePageRef}
        isMobile={isMobile}
        isConferenceVisible={!!currentConferenceId}>
        {conference && (
          <StyledConferenceView>
            <ConferenceViewSection>
              <ConferenceHeader />
            </ConferenceViewSection>
            <ConferenceViewSection>
              <ConferenceDetails />
            </ConferenceViewSection>
            <ConferenceViewSection>
              <ConferenceParticipants />
            </ConferenceViewSection>
          </StyledConferenceView>
        )}
      </StyledConferencesPage>
    </>
  );
};
