import React, { FC, useCallback } from 'react';
import { TeamForm } from '../TeamForm';
import { StyledCreateTeamForm } from './CreateTeamForm.styled';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import type { TeamFormValues } from '../../data/Team/types/Team.types';
import { useTeamsActions } from '../../data/Team/Team.actions';

interface CreateTeamFormProps {
  onDone: () => void;
}

export const CreateTeamForm: FC<CreateTeamFormProps> = ({ onDone }) => {
  const { createTeam } = useTeamsActions();

  const handleSubmit = useCallback(
    (formValues: TeamFormValues) =>
      createTeam(formValues)
        .then(onDone)
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        }),
    [createTeam, onDone],
  );

  return (
    <StyledCreateTeamForm>
      <TeamForm onSubmit={handleSubmit} onCancel={onDone} />
    </StyledCreateTeamForm>
  );
};
