import React, { FC, useCallback, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { useLocation } from 'react-router';
import { LinkTranslation } from '../i18n';
import { LinkApiType, LinkFormValues } from '../Link.types';
import { FormInput } from '../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { TagIcon } from '../../../shared/icons';
import {
  AvatarBoxWrapper,
  FlexSeparator,
  FormControls,
  FormFieldsWrapper,
  LinkField,
  LinkFieldsGroup,
  StyledAvatarBox,
  TagButton,
} from './LinkForm.styled';
import { ImageUpload } from '../../Image';
import {
  ImageUploadButton,
  ImageUploadLabel,
  StyledAvatar,
} from '../../User/UserForms/EditUserProfileForm/EditUserProfile.form.styled';
import {
  getGlideImageSrcSet,
  getQueryParamsFrom,
} from '../../../shared/utils/url.utils';
import { UserTranslation } from '../../User/i18n';
import UploadImagePlaceholder from '../../../shared/assets/images/image-upload.svg';
import { ManageTagModal } from '../LinksView/Modals/ManageTagModal';
import { useNavigate } from 'react-router-dom';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { useCurrentDesktopPermissions } from '../../Desktop/Desktop.hooks';
import { getShortId } from '../../../shared/utils/id';
import { PermissionContext } from '../../Desktop/data/Desktop/types/Desktop.types';

interface LinkFormProps {
  link?: LinkApiType | null;
  onSubmit: (values: LinkFormValues) => Promise<unknown>;
  onCancel: () => void;
}

export const LinkForm: FC<LinkFormProps> = ({ link, onSubmit, onCancel }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const [isTagModalOpened, setTagModalOpened] = useState(false);
  const { canEditLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(link?.desktopId || ''),
  );

  const LinkFormSchema: Schema<LinkFormValues> = useMemo(() => {
    return Yup.object().shape({
      customImage: Yup.string(),
      name: link
        ? Yup.string().required(LinkTranslation.formNameRequired)
        : Yup.string(),
      url: Yup.string().required(LinkTranslation.formUrlRequired),
      tags: Yup.array(),
    });
  }, [link]);

  const { state = '' } = useLocation();

  const initialValues: LinkFormValues = {
    customImage: link?.customImage?.id || '',
    name: link?.customTitle || link?.data?.title || '',
    url: link?.data?.url || state,
    tags: [],
  };

  const [imageUrl, setImageUrl] = useState<string>(
    link?.customImage?.contentUrl || '',
  );

  const handleEditTagsClick = useCallback(() => {
    setTagModalOpened(true);
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        linkId: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const handleCloseTagModal = useCallback(() => {
    setTagModalOpened(false);
  }, []);

  const LINK_IMAGE_SIZE = 120;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={LinkFormSchema}
        onSubmit={(values, actions) => {
          onSubmit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form data-testid="link-form">
            <div>
              <FormFieldsWrapper>
                <AvatarBoxWrapper>
                  <ImageUpload
                    onUpload={({ id, contentUrl }) => {
                      setFieldValue('customImage', id);
                      setImageUrl(contentUrl);
                    }}>
                    <ImageUploadLabel>Picture</ImageUploadLabel>
                    <StyledAvatarBox>
                      <StyledAvatar
                        src={imageUrl || UploadImagePlaceholder}
                        srcSet={getGlideImageSrcSet(imageUrl, {
                          w: LINK_IMAGE_SIZE,
                          h: LINK_IMAGE_SIZE,
                          fit: 'crop-center',
                        })}
                      />
                      <ImageUploadButton
                        size={ButtonSize.sm}
                        mode={ButtonMode.secondary}
                        disabled={!canEditLink}>
                        <FormattedMessage
                          id={UserTranslation.profileImageUploadButton}
                        />
                      </ImageUploadButton>
                    </StyledAvatarBox>
                  </ImageUpload>
                </AvatarBoxWrapper>

                <LinkFieldsGroup>
                  <LinkField>
                    <Field
                      name="url"
                      type="text"
                      data-testid="url-input"
                      placeholder={intl.formatMessage({
                        id: LinkTranslation.formUrlPlaceholder,
                      })}
                      label={intl.formatMessage({
                        id: LinkTranslation.formUrlLabel,
                      })}
                      autoFocus={!link && !state}
                      maxLength={null}
                      component={FormInput}
                      disabled={isSubmitting || !canEditLink}
                    />
                  </LinkField>

                  <LinkField>
                    <Field
                      name="name"
                      type="text"
                      data-testid="name-input"
                      placeholder={intl.formatMessage({
                        id: LinkTranslation.formNamePlaceholder,
                      })}
                      label={intl.formatMessage({
                        id: LinkTranslation.formNameLabel,
                      })}
                      autoFocus={!link && state}
                      component={FormInput}
                      disabled={isSubmitting || !canEditLink}
                    />
                  </LinkField>
                </LinkFieldsGroup>
              </FormFieldsWrapper>

              <FormControls>
                {!link && canEditLink && (
                  <TagButton
                    type="button"
                    fullWidth
                    mode={ButtonMode.secondary}
                    size={ButtonSize[isMobile ? 'sm' : 'md']}
                    data-testid="tags-button"
                    onClick={handleEditTagsClick}
                    icon={TagIcon}>
                    <FormattedMessage id={LinkTranslation.linksViewTagsLink} />
                  </TagButton>
                )}
                <FlexSeparator />
                <Button
                  data-testid="cancel-button"
                  type="button"
                  mode={ButtonMode.secondary}
                  size={ButtonSize[isMobile ? 'sm' : 'md']}
                  onClick={onCancel}>
                  <FormattedMessage id={LinkTranslation.formCancelButton} />
                </Button>
                <Button
                  data-testid="submit-button"
                  type="submit"
                  mode={ButtonMode.primary}
                  size={ButtonSize[isMobile ? 'sm' : 'md']}
                  disabled={isSubmitting || !canEditLink}>
                  <FormattedMessage id={LinkTranslation.formSubmitButton} />
                </Button>
              </FormControls>
            </div>
            {isTagModalOpened && (
              <ManageTagModal
                containerZIndex={999}
                selectedTags={values?.tags}
                onRequestClose={handleCloseTagModal}
                onCreateTags={tags => {
                  setFieldValue('tags', tags);
                  handleCloseTagModal();
                }}
                isOpen={isTagModalOpened}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
