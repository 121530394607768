import { getShortId } from '../../../../shared/utils/id';
import { getRestApiClient } from '../../../Api/Api.utils';
import { RestApiClientResponse } from '../../../Api/RestApiClient/RestApiClient.types';
import type { AccessApiType } from '../types/Access.types';

export const getAccessesRestApi = async (
  desktopId: string,
): Promise<RestApiClientResponse<AccessApiType[]>> => {
  const restApiClient = getRestApiClient();
  const shortDesktopId = getShortId(desktopId);

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient.fetch(`/desktop/${shortDesktopId}/accesses`);
};

export const getWorkspaceAccessesRestApi = async (
  workspaceId: string,
): Promise<RestApiClientResponse<AccessApiType[]>> => {
  const restApiClient = getRestApiClient();
  const shortWorkspaceId = getShortId(workspaceId);

  if (!restApiClient) {
    throw new Error('Rest client was not provided');
  }

  return restApiClient.fetch(
    `/workspace/${shortWorkspaceId}/internal/desktops/accesses`,
  );
};
