import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthService } from '../Auth.hooks';
import { AppShellSkeleton } from '../../AppRoot/AppShellSkeleton';
import { sanitizeUrl } from '../../../shared/utils/url.utils';
import { setCookie } from '../../../shared/utils/storage';
import { appEnv } from '../../../appEnv';
import { SESSION_INSTANCE_ID } from '../Auth.constants';
import { shouldLogAuthToSentry } from '../../../shared/utils/logging.utils';
import { captureMessage } from '../../ErrorInterceptor';
import { captureException } from '@sentry/react';
import { generateRandomId } from '../../../shared/utils/id';

export const HandleLoginPage: FC = () => {
  const authService = useAuthService();

  const [sessionEstablished, setSessionEstablished] = useState(false);

  useEffect(() => {
    if (authService) {
      if (shouldLogAuthToSentry()) {
        captureMessage(
          `[HandleLoginPage] call authService.handleLogin() from useEffect`,
        );
      }
      authService
        .handleLogin()
        .then(({ appState }) => {
          if (shouldLogAuthToSentry()) {
            captureMessage(
              `[HandleLoginPage] appState returned from the authService.handleLogin() call`,
            );
          }
          if (appState) {
            window.location.href = sanitizeUrl(
              appState,
              appEnv.WORKSPACE_DOMAIN,
            );
          } else {
            setSessionEstablished(true);
            setCookie(SESSION_INSTANCE_ID, generateRandomId());
          }
        })
        .catch(e => {
          captureException(e);
          alert(e.message);
        });
    }
  }, [authService]);

  if (!sessionEstablished) {
    return <AppShellSkeleton />;
  }

  return <Navigate to="/" />;
};
