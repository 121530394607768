import {
  NewScheduledChatConferenceEvent,
  RemoveScheduledChatConferenceEvent,
  UpdateScheduledChatConferenceEvent,
} from '../ChatMercure.types';
import { ApolloClient } from '@apollo/client';
import { makeScheduledChatConference } from './scheduledChatConference.utils';
import { DEFAULT_PAGE_SIZE } from '../../../Conference/ConferencesPage';
import {
  addScheduledConferenceToListCache,
  removeScheduledConferenceFromListCache,
  updateScheduledConferenceInCache,
} from '../../../Conference/cache/ScheduledConference.cache';
import { FilterPeriod } from '../../../Conference/Conference.types';

export const handleNewScheduledChatConference = (
  event: NewScheduledChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  const scheduledChatConference = makeScheduledChatConference(event);
  const filterPeriod =
    event.startAt > new Date().toISOString()
      ? FilterPeriod.future
      : FilterPeriod.past;

  if (event.chatConference?.workspace?.id) {
    addScheduledConferenceToListCache(
      apolloClient,
      {
        first: DEFAULT_PAGE_SIZE,
        workspace: event.chatConference.workspace.id,
        filterPeriod: filterPeriod,
      },
      scheduledChatConference,
    );
  }
};

export const handleUpdateScheduledConferenceInCache = (
  event: UpdateScheduledChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  const scheduledChatConference = makeScheduledChatConference(event);
  const workspaceId = event.chatConference.workspace?.id;
  if (!workspaceId) {
    return;
  }

  updateScheduledConferenceInCache(
    apolloClient,
    workspaceId,
    scheduledChatConference,
  );
};

export const handleRemoveScheduledConferenceInCache = (
  event: RemoveScheduledChatConferenceEvent,
  apolloClient: ApolloClient<any>,
) => {
  const scheduledChatConferenceId = '/schedule-chat-conferences/' + event.id;

  if (event.workspaceId) {
    removeScheduledConferenceFromListCache(
      apolloClient,
      {
        first: DEFAULT_PAGE_SIZE,
        workspace: '/workspaces/' + event.workspaceId,
        filterPeriod: FilterPeriod.future,
      },
      scheduledChatConferenceId,
    );
  }
};
