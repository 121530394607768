import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  ChatConversationInternalType,
  ConversationListSections,
  ConversationsSortedByGroups,
  ExpandedConversationsType,
} from '../../../Chat.types';
import { useDebouncedCallback } from 'use-debounce';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../Workspace/Workspace.hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../../Workspace/Workspace.constants';
import { useAccountsContext } from '../../../../Account';
import { useChatConversationUnreadCounterGetter } from '../../../../Counters';
import { Virtuoso } from 'react-virtuoso';
import ConversationListPartsRenderer from './ConversationListPartsRenderer';
import {
  useVirtualisedConversations,
  VirtualisedConversationsProps,
} from './ConversationList.hooks';
import { useHideScrollbarWhenIdle } from '../../../../../shared/hooks/element.hooks';
import { getShortId } from '../../../../../shared/utils/id';

interface ConversationListProps {
  conversations: ConversationsSortedByGroups;
  onSearch: (value: string) => void;
  isLoading: boolean;
}

export const ConversationList: FC<ConversationListProps> = ({
  conversations,
  onSearch,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const {
    permissions: {
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
    },
  } = useCurrentWorkspacePermissions();
  const showAppsIntegrationsSection =
    canViewWorkspaceAppIntegrations || canViewPersonalAppIntegrations;
  const [sectionCollapsed, setSectionCollapsed] = useState<
    ConversationListSections[]
  >([]);
  const virtuosoScrollRef = useRef<HTMLElement | Window | null>(null);
  const [listHideScrollRef] = useHideScrollbarWhenIdle();

  const {
    virtuosoConversations,
    collapsedSections,
  }: VirtualisedConversationsProps = useVirtualisedConversations(
    conversations,
    sectionCollapsed,
  );

  const debouncedCallback = useDebouncedCallback(event => {
    onSearch(event?.target?.value ?? '');
  }, 500);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValue(event.target.value);
      event.persist();
      debouncedCallback(event);
    },
    [debouncedCallback],
  );

  const [filterValue, setFilterValue] = useState('');

  const handleClearFilterValue = useCallback(() => {
    setFilterValue('');
    debouncedCallback('');
  }, [debouncedCallback]);

  const getChatConversationUnreadCount =
    useChatConversationUnreadCounterGetter();

  const unreadMessagesCounter = useCallback(
    (conversations: ChatConversationInternalType[]): number =>
      conversations.reduce(
        (acc, item) => acc + getChatConversationUnreadCount(item.id),
        0,
      ),
    [getChatConversationUnreadCount],
  );
  const handleIntegrationsSettingsClick = useCallback(() => {
    if (!showAppsIntegrationsSection) {
      return null;
    }

    navigate(
      generatePath(APPS_INTEGRATIONS_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      }),
    );
  }, [navigate, showAppsIntegrationsSection, workspace.id]);

  const appIntegrationsUnreadMessagesCounter = useMemo(
    () => unreadMessagesCounter(conversations.appIntegrations),
    [conversations.appIntegrations, unreadMessagesCounter],
  );

  const { loading: accountsAvailabilityLoading } = useAccountsContext();

  const isLoadingWithAvailabilityAccounts = useMemo(
    () => isLoading || accountsAvailabilityLoading,
    [isLoading, accountsAvailabilityLoading],
  );

  const handleToggle = (name: ConversationListSections) => {
    setSectionCollapsed(prevSections =>
      prevSections.includes(name)
        ? prevSections.filter(el => el !== name)
        : [...prevSections, name],
    );
  };

  const itemContent = useCallback(
    (index: number, item: ExpandedConversationsType) => (
      <ConversationListPartsRenderer
        item={item}
        filterValue={filterValue}
        conversations={conversations}
        collapsedSections={collapsedSections}
        isLoadingWithAvailabilityAccounts={isLoadingWithAvailabilityAccounts}
        appIntegrationsUnreadMessagesCounter={
          appIntegrationsUnreadMessagesCounter
        }
        handleClearFilterValue={handleClearFilterValue}
        handleChange={handleChange}
        unreadMessagesCounter={unreadMessagesCounter}
        handleIntegrationsSettingsClick={handleIntegrationsSettingsClick}
        handleToggle={handleToggle}
      />
    ),
    [
      appIntegrationsUnreadMessagesCounter,
      collapsedSections,
      conversations,
      filterValue,
      handleChange,
      handleIntegrationsSettingsClick,
      handleClearFilterValue,
      isLoadingWithAvailabilityAccounts,
      unreadMessagesCounter,
    ],
  );

  return (
    <>
      <Virtuoso
        scrollerRef={(scrollerRef: HTMLElement | Window | null) => {
          listHideScrollRef(scrollerRef as HTMLElement | null);
          virtuosoScrollRef.current = scrollerRef;
        }}
        data={virtuosoConversations}
        itemContent={itemContent}
      />
    </>
  );
};
