import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../shared/components/Spinner';
import { ListItemContent } from '../../../../shared/components/List';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { KeyboardArrowRightIcon } from '../../../../shared/icons';
import { CreateTeamModal } from './CreateTeamModal';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  AdminHeader,
  AdminHeaderControls,
  AdminHeaderTitle,
  AdminList,
  AdminListEmptyMessage,
  AdminListItem,
  AdminListItemLink,
} from '../../../Admin';
import { TeamTranslation } from '../../i18n';
import { useMobile, useQueryParams } from '../../../../shared/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Search } from '../../../../shared/components/Search';
import { InputSize } from '../../../../shared/components/Input';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentRouteUrl } from '../../../../shared/hooks/router.hooks';
import { useTeamsRepository } from '../../data/Team/Team.repositories';
import { getLongId, getShortId } from '../../../../shared/utils/id';
import type { TeamApiType } from '../../data/Team/types/Team.types';

const FILTER_QUERY_PARAM_NAME = 'filter';
const CREATE_TEAM_MODAL_QUERY_PARAM_NAME = 'createGroup';

export const TeamsList: FC = () => {
  const url = useCurrentRouteUrl();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const searchValue = queryParams[FILTER_QUERY_PARAM_NAME] as string;

  const { teams, loading } = useTeamsRepository({
    workspaceId: getLongId('workspaces', workspaceId as string),
  });

  const filteredTeams: TeamApiType[] | null = useMemo(() => {
    if (searchValue) {
      return teams.filter(
        team =>
          team.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
      );
    }

    return null;
  }, [searchValue, teams]);

  const sortedTeams = useMemo(
    () => teams.sort((a, b) => a.name.localeCompare(b.name)),
    [teams],
  );

  const teamsList = filteredTeams || sortedTeams || [];

  return (
    <>
      <AdminHeader data-testid="header">
        {!isMobile && (
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage id={TeamTranslation.adminListHeaderTitle} />
          </AdminHeaderTitle>
        )}
        <AdminHeaderControls data-testid="controls">
          <Search
            queryParamName={FILTER_QUERY_PARAM_NAME}
            size={InputSize.sm}
          />
          <Button
            size={ButtonSize.sm}
            mode={ButtonMode.primary}
            disabled={loading}
            onClick={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  [CREATE_TEAM_MODAL_QUERY_PARAM_NAME]: true,
                }),
              })
            }
            data-testid="new-team-button"
            data-tourid="admin-create-team-button">
            <FormattedMessage id={TeamTranslation.adminListAddButton} />
          </Button>
        </AdminHeaderControls>
      </AdminHeader>

      {loading && !teams.length ? (
        <Spinner />
      ) : teamsList.length ? (
        <AdminList data-testid="team-list">
          {teamsList.map(team => (
            <AdminListItem
              key={team.id}
              data-testid="team-item"
              data-entityid={team.id}>
              <AdminListItemLink
                to={`${url}/${getShortId(team.id)}`}
                data-testid="team-link">
                <ListItemContent title={team.name} subtitle={team.description}>
                  <ButtonWithIcon
                    icon={KeyboardArrowRightIcon}
                    data-testid="arrow-button"
                  />
                </ListItemContent>
              </AdminListItemLink>
            </AdminListItem>
          ))}
        </AdminList>
      ) : (
        <AdminListEmptyMessage>
          <FormattedMessage id={TeamTranslation.adminListEmptyMessage} />
        </AdminListEmptyMessage>
      )}

      {queryParams[CREATE_TEAM_MODAL_QUERY_PARAM_NAME] && (
        <CreateTeamModal
          visible={true}
          onDone={() => navigate({})}
          onRequestClose={() =>
            navigate({
              search: getQueryParamsFrom({
                ...queryParams,
                [CREATE_TEAM_MODAL_QUERY_PARAM_NAME]: undefined,
              }),
            })
          }
        />
      )}
    </>
  );
};
