import React, { FC, useCallback, useMemo } from 'react';
import { useConfirm } from '../../../../../shared/components/Modal';
import { useModalControls } from '../../../../../shared/components/Modal/Modal.hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RelatedUsersList,
  Section,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../Admin';
import { DesktopTranslation } from '../../../i18n';
import { Spinner } from '../../../../../shared/components/Spinner';
import { ManageRelatedUsersModal } from './ManageRelatedUsersModal';
import { getListChanges } from '../../../../../shared/utils/list.utils';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../../../User/User.types';
import { isAccountAdmin } from '../../../../User/User.utils';
import {
  DesktopAccessType,
  DesktopApiType,
} from '../../../data/Desktop/types/Desktop.types';
import { useCurrentWorkspaceDesktopAccessesRepository } from '../../../../Access/data/Access.repositories';
import { createAccessMap } from '../../../../Access/data/utils/Access.utils';
import { getLongId } from '../../../../../shared/utils/id';
import { useAccessActions } from '../../../../Access/data/Access.actions';
import { useAccountsContext } from '../../../../Account';

export const ManageRelatedUsers: FC<{ desktop: DesktopApiType }> = ({
  desktop,
}) => {
  const intl = useIntl();
  const { askConfirmation } = useConfirm();
  const relatedUsersModal = useModalControls();
  const { accountsWithAvailability } = useAccountsContext();

  const { accesses, loading } = useCurrentWorkspaceDesktopAccessesRepository(
    {},
  );
  const desktopAccessesMap = useMemo(
    () => createAccessMap(accesses),
    [accesses],
  );
  const desktopAccesses = useMemo(() => {
    return (
      desktopAccessesMap?.[getLongId('desktops', desktop!.id as string)] || []
    );
  }, [desktopAccessesMap, desktop]);

  const relatedUsers = useMemo(() => {
    return (
      desktopAccesses
        .filter(access => access.account?.id && !access.workspaceTeam?.id)
        // It's important to get full user object from accountsWithAvailability here
        // since we rely on workspaceCache further in this component
        .map(access => accountsWithAvailability[access.account!.id])
        .filter(Boolean) as AccountApiType[]
    );
  }, [desktopAccesses, accountsWithAvailability]);

  const { createAccess, updateAccess, removeAccessById } = useAccessActions();

  const editUserAccess = useCallback(
    (accessId: string, newAccessType: DesktopAccessType) => {
      updateAccess(desktop.id, accessId, newAccessType);
    },
    [desktop, updateAccess],
  );

  const handleManageMembersSubmit = useCallback(
    (selectedUsers: AccountApiType[]) => {
      const { added, removed } = getListChanges(relatedUsers, selectedUsers);

      return Promise.all([
        ...added.map((account: AccountWithCountsApiType) => {
          const isAdmin = isAccountAdmin(account);

          return createAccess(desktop.id, {
            account: account.id,
            accessType: isAdmin
              ? DesktopAccessType.Manager
              : DesktopAccessType.EditLink,
          });
        }),
        ...removed.map((account: AccountApiType) => {
          const desktopAccess = desktopAccessesMap[desktop.id]?.find(
            access => access.account?.id === account.id,
          );
          if (desktopAccess) {
            return removeAccessById(desktopAccess.id, desktop.id);
          }
          return Promise.resolve();
        }),
      ] as any).then(() => {
        relatedUsersModal.close();
      });
    },
    [
      relatedUsers,
      desktop.id,
      relatedUsersModal,
      createAccess,
      removeAccessById,
      desktopAccessesMap,
    ],
  );

  const handleDeleteRelatedUserClick = useCallback(
    (account: AccountApiType) => {
      askConfirmation(
        intl.formatMessage({
          id: DesktopTranslation.adminDesktopUsersRemoveConfirmation,
        }),
      ).then(confirm => {
        if (!confirm) {
          return;
        }

        const desktopAccess = desktopAccessesMap[desktop.id]?.find(
          access => access.account?.id === account.id,
        );
        if (desktopAccess) {
          removeAccessById(desktopAccess.id, desktop.id);
        }
      });
    },
    [askConfirmation, intl, desktop.id, removeAccessById, desktopAccessesMap],
  );

  return (
    <Section data-testid="related-members-section">
      <SectionHeader data-testid="header">
        <SectionHeaderTitle data-testid="title">
          <FormattedMessage id={DesktopTranslation.adminDesktopUsers} />
        </SectionHeaderTitle>
        <SectionHeaderButton
          onClick={relatedUsersModal.open}
          data-testid="manage-button">
          <FormattedMessage
            id={DesktopTranslation.adminDesktopUsersManageButton}
          />
        </SectionHeaderButton>
      </SectionHeader>

      {loading && !relatedUsers ? (
        <Spinner />
      ) : (
        <>
          <RelatedUsersList
            users={relatedUsers}
            desktopAccesses={desktopAccessesMap[desktop.id]}
            emptyMessage={intl.formatMessage({
              id: DesktopTranslation.adminDesktopUsersEmptyMessage,
            })}
            desktop={desktop}
            onEditAccess={editUserAccess}
            onRemove={handleDeleteRelatedUserClick}
          />

          <ManageRelatedUsersModal
            subtitle={desktop.name}
            relatedUsers={relatedUsers}
            onSubmit={handleManageMembersSubmit}
            visible={relatedUsersModal.visible}
            onRequestClose={relatedUsersModal.close}
          />
        </>
      )}
    </Section>
  );
};
