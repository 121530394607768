export const LOGO_SIZE = 40;

export const APP_Z_INDEX = 1;
export const APP_WITH_OPENED_MENU_Z_INDEX = 250;

export enum GoogleAppOpenType {
  Domain = 'domain',
  AuthUser = 'authuser',
  None = 'none',
}

export const GOOGLE_APPS_OPEN_TYPES: Record<string, GoogleAppOpenType> = {
  'https://mail.google.com/': GoogleAppOpenType.Domain,
  'https://drive.google.com/': GoogleAppOpenType.Domain,
  'https://calendar.google.com/calendar/': GoogleAppOpenType.Domain,
  'https://pay.google.com/': GoogleAppOpenType.AuthUser,
  'https://photos.google.com/': GoogleAppOpenType.AuthUser,
  'https://hangouts.google.com/': GoogleAppOpenType.AuthUser,
  'https://docs.google.com/document/': GoogleAppOpenType.AuthUser,
  'https://docs.google.com/forms/': GoogleAppOpenType.AuthUser,
  'https://ads.google.com/home/': GoogleAppOpenType.None,
  'https://tagmanager.google.com/': GoogleAppOpenType.AuthUser,
  'https://analytics.google.com/': GoogleAppOpenType.None,
  'https://tasks.google.com/embed/list/~default': GoogleAppOpenType.None,
  'https://docs.google.com/spreadsheets/': GoogleAppOpenType.AuthUser,
  'https://admin.google.com/': GoogleAppOpenType.AuthUser,
  'https://artsandculture.google.com/': GoogleAppOpenType.AuthUser,
  'https://chat.google.com/': GoogleAppOpenType.AuthUser,
  'https://classroom.google.com/': GoogleAppOpenType.Domain,
  'https://console.cloud.google.com/': GoogleAppOpenType.AuthUser,
  'https://contacts.google.com/': GoogleAppOpenType.AuthUser,
  'https://earth.google.com/': GoogleAppOpenType.None,
  'https://firebase.google.com/': GoogleAppOpenType.None,
  'https://console.firebase.google.com/': GoogleAppOpenType.AuthUser,
  'https://jamboard.google.com/': GoogleAppOpenType.AuthUser,
  'https://keep.google.com/': GoogleAppOpenType.Domain,
  'https://www.google.com/maps/': GoogleAppOpenType.None,
  'https://meet.google.com/': GoogleAppOpenType.AuthUser,
  'https://news.google.com/': GoogleAppOpenType.AuthUser,
  'https://play.google.com/console/': GoogleAppOpenType.AuthUser,
  'https://podcasts.google.com/': GoogleAppOpenType.AuthUser,
  'https://safety.google/': GoogleAppOpenType.None,
  'https://search.google.com/search-console/about': GoogleAppOpenType.None,
  'https://docs.google.com/presentation/': GoogleAppOpenType.AuthUser,
  'https://stadia.google.com/': GoogleAppOpenType.None,
  'https://translate.google.com/': GoogleAppOpenType.AuthUser,
  'https://www.google.com/travel/': GoogleAppOpenType.AuthUser,
  'https://voice.google.com/': GoogleAppOpenType.None,
  'https://workspace.google.com/dashboard': GoogleAppOpenType.AuthUser,
};
