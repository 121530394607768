import { GoogleAuthStatusInBrowserApiType } from './BrowserGoogleAuthStatus.types';
import { gql } from '@apollo/client';

export interface GetGoogleAuthLinkResponse {
  googleAuthUrl: GoogleAuthStatusInBrowserApiType;
}

export interface GetGoogleAuthLinkVariables {
  workspace: string;
  scopeType: string;
}

export const GET_GOOGLE_AUTH_LINK = gql`
  query getGoogleAuthUrl($workspace: ID!, $scopeType: String!) {
    googleAuthUrl(workspace: $workspace, scopeType: $scopeType) {
      authUrl
    }
  }
`;
