import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, LinkMode, LinkSize } from '../../../../shared/components/Link';
import {
  Loading,
  StyledEmbedPreview,
  StyledEmbedPreviewContainer,
  StyledEmbedPreviewError,
  StyledEmbedPreviewErrorInfo,
} from './EmbedPreview.styled';
import { DocumentPreviewTranslation } from '../i18n';
import { Spinner } from '../../../../shared/components/Spinner';

const ERROR_TIMEOUT = 30000;
interface EmbedPreviewProps {
  url: string;
  title: string;
  height?: string | number;
  width?: string | number;
  style?: object;
  isLink?: boolean;
}

export const EmbedPreview: FC<EmbedPreviewProps> = ({
  url,
  title,
  width = '100%',
  height = '100%',
  isLink,
  style,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const previewTimeout = useRef<ReturnType<typeof setTimeout>>();

  const onEmbed = useCallback(() => {
    setLoading(false);

    if (previewTimeout.current) {
      clearTimeout(previewTimeout.current);
    }
  }, []);

  useEffect(() => {
    previewTimeout.current = setTimeout(() => {
      setError(true);
    }, ERROR_TIMEOUT);

    return () => {
      if (previewTimeout.current) {
        clearTimeout(previewTimeout.current);
      }
    };
  }, []);

  return (
    <StyledEmbedPreviewContainer isLink={isLink}>
      {/* //TODO: CODE BELOW NEED TO BE TESTED AND WILL BE STYLED AND REFACTORED AFTER GETTING FEEDBACK FROM @MARTIN */}
      {error ? (
        <StyledEmbedPreviewError>
          <StyledEmbedPreviewErrorInfo>
            <FormattedMessage
              id={DocumentPreviewTranslation.documentPreviewSomethingWentWrong}
            />
            <br />
            <FormattedMessage
              id={DocumentPreviewTranslation.documentPreviewOpenDocumetDirectly}
            />
          </StyledEmbedPreviewErrorInfo>

          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            mode={LinkMode.primary}
            size={LinkSize.md}>
            <FormattedMessage
              id={
                DocumentPreviewTranslation.documentPreviewOpenDocumetDirectlyButton
              }
            />
          </Link>
        </StyledEmbedPreviewError>
      ) : (
        <>
          <StyledEmbedPreview
            src={url}
            title={title}
            width={width}
            height={height}
            style={style}
            onLoad={onEmbed}
          />
          {loading && (
            <Loading>
              <Spinner
                description={DocumentPreviewTranslation.loadingPreviewTitle}
              />
            </Loading>
          )}
        </>
      )}
    </StyledEmbedPreviewContainer>
  );
};
