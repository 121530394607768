import React, { FC, useMemo } from 'react';
import {
  LinkApiType,
  LinkDocumentMetaData,
  LinkVideoMetaData,
} from '../Link/Link.types';
import { ChatMessageAssetApiType } from '../Chat/Chat.types';
import {
  DocumentPreview,
  getLinkMetaType,
  linkHasAuthUrl,
  linkHasMetaPreviewUrl,
  useDocumentIsSupportedPreviewType,
} from './DocumentPreview';
import { ReaderPreview } from './ReaderPreview';
import { PREVIEW_IMAGE_MAX_WIDTH } from './Preview.constants';
import { useVideoProviderPreviewReady } from '../VideoPlayer/VideoPlayer.hooks';
import { VideoPlayerExternal } from '../VideoPlayer/VideoPlayer.external';
import { getImageSrcSet } from '../../shared/utils/url.utils';
import { ExternalServiceAuth, useIsGoogle } from '../ExternalServiceAuth';
import { useBrowserGoogleAuthStatusContext } from '../BrowserGoogleAuthStatus';
import { GoogleAuthStatusTypes } from '../BrowserGoogleAuthStatus/BrowserGoogleAuthStatus.types';

type PreviewProps = {
  link?: LinkApiType;
  messageAsset?: ChatMessageAssetApiType;
  inModal?: boolean;
};

export const Preview: FC<PreviewProps> = ({ link, messageAsset, ...props }) => {
  const linkHasMetaType = useMemo(() => getLinkMetaType(link), [link]);
  const linkHasPreviewUrl = useMemo(() => linkHasMetaPreviewUrl(link), [link]);
  const canPreviewDocument = useDocumentIsSupportedPreviewType(messageAsset);
  const canPreviewVideoProvider: boolean | null = useVideoProviderPreviewReady(
    (link?.data.metadata as LinkVideoMetaData)?.type ?? null,
  );
  const linkWithAuthUrl = linkHasAuthUrl(link);

  const isGoogleLink = useIsGoogle(link?.data.metadata?.type);
  const {
    browserGoogleAuthorizationStatus,
  } = useBrowserGoogleAuthStatusContext();

  const needExternalServiceAuth =
    isGoogleLink &&
    browserGoogleAuthorizationStatus === GoogleAuthStatusTypes.unauthorized;

  /**
   * Preview handling for a link
   */
  if (link) {
    return linkWithAuthUrl || needExternalServiceAuth ? (
      <ExternalServiceAuth
        linkMetaData={link.data.metadata as LinkDocumentMetaData}
        linkUrl={link.data.url}
        icon={link.data.icon}
      />
    ) : linkHasMetaType && linkHasPreviewUrl ? (
      <DocumentPreview link={link} {...props} />
    ) : canPreviewVideoProvider ? (
      <VideoPlayerExternal link={link} />
    ) : (
      <ReaderPreview link={link} {...props} />
    );
  }

  /**
   * Preview handling for messageAsset
   */
  if (messageAsset) {
    if (canPreviewDocument && messageAsset.metadata) {
      return <DocumentPreview messageAsset={messageAsset} />;
    }

    return (
      <img
        src={messageAsset.url}
        srcSet={getImageSrcSet(messageAsset.url, PREVIEW_IMAGE_MAX_WIDTH)}
        alt={messageAsset.filename}
        data-testid="preview-upload-img"
      />
    );
  }

  return (
    <div style={{ background: 'red', width: '40px', height: '40px' }}></div>
  );
};
