import React, { FC } from 'react';
import { createIntl, RawIntlProvider } from 'react-intl';
import { AppRoot } from './domains/AppRoot';
import { LOCALE_NAME_ENGLISH } from './domains/Intl/i18n/i18n.constants';
import { enLocale } from './domains/Intl/i18n';
import { AuthProvider, HandleLoginPage } from './domains/Auth';
import { ConfirmProvider } from './shared/components/Modal';
import { DragAndDropProvider } from './domains/Drag';
import { MaintenanceProvider } from './domains/Maintenance/Maintenance.provider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { OfflineProvider } from './domains/Offline';
import { HANDLE_LOGIN_PATHNAME } from './domains/Auth/Auth.constants';
import { HeadTitle } from './shared/components/HeadTitle';
import { WakeUpProvider } from './domains/WakeUp/WakeUpProvider';
import { ThemeProvider } from './domains/Theme';
import { PersistedStorageProvider } from './domains/PersistedStorage';
import { NativeWrapperProvider } from './domains/NativeWrapper';
import { ErrorInterceptor, withProfiler } from './domains/ErrorInterceptor';
import {
  Route,
  Router,
  Routes,
} from './domains/ErrorInterceptor/router.injectors';
import { ToastContainer } from './shared/components/Toast/ToastContainer';
import { InstallAppProvider } from './domains/InstallApp/InstallApp.provider';
import { BroadcastChannelProvider } from './domains/BroadcastChannel/BroadcastChannel.provider';
import { LoggedOutScreenProvider } from './domains/Auth/LoggedOutScreen/LoggedOutScreen.provider';
import { WindowMessageProvider } from './domains/WindowMessage/WindowMessage.provider';
import { ScheduledDowntimeProvider } from './domains/ScheduledDowntime/ScheduledDowntime.provider';
import { TooltipComponent } from './shared/components/Tooltip';
import { PwaDomainCheckProvider } from './domains/PWA/providers/PwaDomainCheckProvider';

const intl = createIntl({ locale: LOCALE_NAME_ENGLISH, messages: enLocale });

const App: FC = () => {
  return (
    <Router>
      <InstallAppProvider>
        <BroadcastChannelProvider>
          <WindowMessageProvider>
            <RawIntlProvider value={intl}>
              <HeadTitle />
              <ThemeProvider>
                <TooltipComponent />
                <ErrorInterceptor>
                  <PersistedStorageProvider>
                    <NativeWrapperProvider>
                      <OfflineProvider>
                        <AuthProvider>
                          <ScheduledDowntimeProvider>
                            <MaintenanceProvider>
                              <LoggedOutScreenProvider>
                                <ConfirmProvider>
                                  <DragAndDropProvider>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}>
                                      <WakeUpProvider>
                                        <PwaDomainCheckProvider>
                                          <Routes>
                                            <Route
                                              path={HANDLE_LOGIN_PATHNAME}
                                              element={<HandleLoginPage />}
                                            />
                                            <Route
                                              path={'*'}
                                              element={<AppRoot />}
                                            />
                                          </Routes>
                                        </PwaDomainCheckProvider>
                                      </WakeUpProvider>
                                    </MuiPickersUtilsProvider>
                                    <ToastContainer />
                                  </DragAndDropProvider>
                                </ConfirmProvider>
                              </LoggedOutScreenProvider>
                            </MaintenanceProvider>
                          </ScheduledDowntimeProvider>
                        </AuthProvider>
                      </OfflineProvider>
                    </NativeWrapperProvider>
                  </PersistedStorageProvider>
                </ErrorInterceptor>
              </ThemeProvider>
            </RawIntlProvider>
          </WindowMessageProvider>
        </BroadcastChannelProvider>
      </InstallAppProvider>
    </Router>
  );
};

export default withProfiler(App);
