import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../../shared/hooks';
import { AddIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { PasswordTranslation } from '../../../../Password/i18n';
import { PREVIEW_SEGMENT_URL_CHANGE_DELAY } from '../../../../PreviewSegment/PreviewSegment.constants';
import { PreviewSegmentContext } from '../../../../PreviewSegment/PreviewSegment.context';
import { SegmentType } from '../../../../Segment';
import { NavigatorActionButton } from '../../../../Segment/SegmentNavigator/SegmentNavigator.styled';
import { useCurrentWorkspaceAccountPrivateKey } from '../../../Encryption.hooks';

export const PasswordsNavigatorTitleActions = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { isPreviewMode, navigateToSegment } = useContext(
    PreviewSegmentContext,
  );

  const handleCreatePasswordButtonClick = () => {
    if (isPreviewMode) {
      navigateToSegment(SegmentType.PASSWORDS, true);
    }

    setTimeout(
      () => {
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            isCreatePasswordModalOpened: true,
          }),
        });
      },
      isPreviewMode ? PREVIEW_SEGMENT_URL_CHANGE_DELAY : 0,
    );
  };

  return (
    <>
      <NavigatorActionButton
        data-tooltip-content={formatMessage({
          id: PasswordTranslation.tooltipCreatePassword,
        })}
        data-tooltip-place={TooltipPlace.bottom}
        icon={AddIcon}
        iconSize={14}
        onClick={handleCreatePasswordButtonClick}
        disabled={!privateKey}
        data-testid="create-password-button"
      />
    </>
  );
};
