import React, { FC, useCallback, useContext, useRef } from 'react';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../../../shared/components/Menu';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { DeleteMenuItem } from './ScheduledConferencesListItem.styled';
import { DotsIcon } from '../../../../../../shared/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConferenceTranslation } from '../../../../i18n';
import {
  useDeclineScheduleChatConference,
  useDeleteScheduledConference,
} from '../../../../Conference.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../../shared/components/Toast';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../../shared/hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../../../Workspace/Workspace.hooks';
import { getConferencePageLink } from '../../../../Conference.utils';
import { useConfirm } from '../../../../../../shared/components/Modal';
import { ScheduledChatConferenceApiType } from '../../../../Conference.types';
import {
  CONFERENCES_CONFERENCE_PATHNAME,
  CONFERENCES_ROOT_PATHNAME,
} from '../../../../../Desktop/Desktop.constants';
import { PreviewSegmentContext } from '../../../../../PreviewSegment/PreviewSegment.context';
import { getShortId } from '../../../../../../shared/utils/id';

interface ScheduledConferencesMenuProps {
  isEditDisabled: boolean;
  conference: ScheduledChatConferenceApiType;
}

export const ScheduledConferencesMenu: FC<ScheduledConferencesMenuProps> = ({
  isEditDisabled,
  conference,
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { workspace } = useCurrentWorkspace();
  const { handleDeleteConference } = useDeleteScheduledConference(conference);
  const [declineScheduleChatConference] = useDeclineScheduleChatConference();
  const isConferenceCreatedByCurrentUser =
    currentAccount?.id === conference?.createdBy?.id;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { askConfirmation } = useConfirm();
  const intl = useIntl();
  const { isPreviewMode } = useContext(PreviewSegmentContext);
  const handleEditConference = useCallback(() => {
    navigate({
      pathname: generatePath(CONFERENCES_CONFERENCE_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        conferenceId: getShortId(conference.id),
      }),
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleConferenceOpened: true,
      }),
    });
  }, [conference, navigate, queryParams, workspace]);

  const handleCopyLink = useCallback(() => {
    const conferenceLink =
      window.location.origin +
      getConferencePageLink(conference.chatConference?.id || '');

    navigator.clipboard.writeText(conferenceLink).then(() => {
      showToastSuccessMessage(
        ConferenceTranslation.scheduledUpcomingConferenceCopyLinkToastText,
      );
    });
  }, [conference]);

  const handleDecline = useCallback(() => {
    if (!conference) {
      return null;
    }

    if (conference.repeatingScheduleChatConference) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          scheduleConferenceDeclineConfirmationModalOpen: true,
        }),
      });
    } else {
      askConfirmation(
        intl.formatMessage({
          id: ConferenceTranslation.scheduledConferenceDeclineConfirmationMessageSingle,
        }),
        intl.formatMessage({
          id: ConferenceTranslation.scheduledConferenceDeclineConfirmationTitle,
        }),
        {
          width: 450,
        },
      ).then(confirm => {
        if (!confirm) {
          return;
        }
        declineScheduleChatConference({
          variables: {
            input: {
              id: conference.id,
              _id: conference._id,
              workspace: workspace.id,
            },
          },
        })
          .then(() => {
            navigate({
              pathname: generatePath(CONFERENCES_ROOT_PATHNAME, {
                workspaceId: getShortId(workspace.id),
              }),
              search: getQueryParamsFrom({
                ...queryParams,
              }),
            });
          })
          .catch(e => {
            showToastGraphQLErrors(e.graphQLErrors);
          });
      });
    }
  }, [
    askConfirmation,
    conference,
    declineScheduleChatConference,
    navigate,
    intl,
    queryParams,
    workspace,
  ]);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent) => {
      if (isPreviewMode) {
        event.preventDefault();
      }
    },
    [isPreviewMode],
  );

  return (
    <>
      <ButtonWithIcon
        ref={buttonRef}
        icon={DotsIcon}
        onClick={handleButtonClick}
      />
      <Menu
        trigger={buttonRef}
        width={180}
        viaPortal={!isPreviewMode}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.LEFT}>
        {isConferenceCreatedByCurrentUser && !isEditDisabled && (
          <MenuItem onClick={handleEditConference}>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferenceEditMeeting}
            />
          </MenuItem>
        )}
        <MenuItem onClick={handleCopyLink}>
          <FormattedMessage
            id={
              ConferenceTranslation.scheduledUpcomingConferenceCopyMeetingsLink
            }
          />
        </MenuItem>
        {!isConferenceCreatedByCurrentUser && (
          <MenuItem onClick={handleDecline}>
            <FormattedMessage
              id={ConferenceTranslation.scheduledConferenceDeclineMeeting}
            />
          </MenuItem>
        )}
        {isConferenceCreatedByCurrentUser && (
          <MenuItem onClick={handleDeleteConference}>
            <DeleteMenuItem>
              <FormattedMessage
                id={
                  ConferenceTranslation.scheduledUpcomingConferenceDeleteMeeting
                }
              />
            </DeleteMenuItem>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
