import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { AboutYouFormValues } from '../../Onboarding.types';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../i18n';
import { FormInput } from '../../../../shared/components/Input';
import { FormSelect } from '../../../../shared/components/Select';
import { AboutYouFormField, AboutYouNextButton } from './AboutYouPage.styled';
import { extractNodes } from '../../../../shared/api/api.utils';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { useGetIndustryCategories } from '../../Onboarding.hooks';

const AboutYouFormSchema: Schema<AboutYouFormValues> = Yup.object().shape({
  jobTitle: Yup.string().required(
    OnboardingTranslation.onboardingAboutYouJobRequired,
  ),
  industry: Yup.string().required(
    OnboardingTranslation.onboardingAboutYouIndustryRequired,
  ),
});

export interface AboutYouFormProps {
  onSubmit: (values: AboutYouFormValues) => void;
}

export const initialValues: AboutYouFormValues = {
  jobTitle: '',
  industry: '',
};

export const AboutYouForm: FC<AboutYouFormProps> = ({ onSubmit }) => {
  const intl = useIntl();

  const { data: industriesData } = useGetIndustryCategories();

  const industries = useMemo(() => extractNodes(industriesData?.industries), [
    industriesData,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AboutYouFormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => {
        return (
          <Form data-testid="about-you-form">
            <AboutYouFormField>
              <Field
                type="text"
                name="jobTitle"
                label={intl.formatMessage({
                  id: OnboardingTranslation.onboardingAboutYouJobLabel,
                })}
                placeholder={intl.formatMessage({
                  id: OnboardingTranslation.onboardingAboutYouJobPlaceholder,
                })}
                component={FormInput}
                data-testid="job-title"
              />
            </AboutYouFormField>

            <AboutYouFormField>
              <Field
                name="industry"
                type="text"
                data-testid="industry-select"
                label={intl.formatMessage({
                  id: OnboardingTranslation.onboardingAboutYouSelectLabel,
                })}
                placeholder={intl.formatMessage({
                  id: OnboardingTranslation.onboardingAboutYouSelectPlaceholder,
                })}
                component={FormSelect}>
                <option value="" />
                {industries.length &&
                  industries.map(industry => (
                    <option value={industry.id} key={industry.id}>
                      {industry.label}
                    </option>
                  ))}
              </Field>
            </AboutYouFormField>

            <AboutYouNextButton
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              fullWidth
              type="submit"
              disabled={isSubmitting || !isValid}
              data-testid="next-button">
              <FormattedMessage
                id={OnboardingTranslation.onboardingAboutYouNextButton}
              />
            </AboutYouNextButton>
          </Form>
        );
      }}
    </Formik>
  );
};
