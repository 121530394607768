import styled, { css } from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { OnboardingStepHeader } from '../OnboardingPage.styled';

export const ChooseFeatureStepHeader = styled(OnboardingStepHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const ChooseFeatureTile = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  min-height: 48px;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem 0.75rem 1.125rem;
  border-radius: 8px;
  border: solid 1px ${p => p.theme.colors.SurfaceBorder};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: solid 1px ${p => p.theme.colors.Primary};
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${p => p.theme.colors.BackgroundInputAutofill};
      border: solid 1px ${p => p.theme.colors.Primary};
    `};
`;

export const ChooseFeatureInner = styled.div`
  display: flex;
`;

export const ChooseFeatureTileWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 44px);
  padding: 0 0.25rem 0 0.625rem;
  user-select: none;
`;

export const ChooseFeatureButtonTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
`;

export const ChooseFeatureButtonSubtitle = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const ChooseFeatureNextButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xxl};
`;

export const ChooseFeatureFormFields = styled.div`
  &:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  svg {
    flex-shrink: 0;
  }
`;
