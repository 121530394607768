import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { appEnv } from '../../../appEnv';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { RoundedCheckmarkIcon } from '../../../shared/icons';
import {
  checkExtensionInstalled,
  extensionSupported,
} from '../../../shared/utils/extension.utils';
import { ChromeExtensionTranslation } from '../i18n';
import {
  InstallPluginContent,
  InstallPluginLink,
  StyledInstallPlugin,
} from './InstallPlugin.styled';
import { showToastSuccessMessage } from '../../../shared/components/Toast';

export interface InstallPluginProps {
  onInstall: () => void;
  onSkip: () => void;
}

const CHECK_EXTENSION_INTERVAL = 2000;

export const InstallPlugin: FC<InstallPluginProps> = ({
  onInstall,
  onSkip,
}) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);

  const checkInterval = useRef<any>(null);

  useEffect(() => {
    if (!extensionSupported) {
      onSkip();
      return;
    }

    checkExtensionInstalled()
      .then(installed => {
        setExtensionInstalled(installed);

        if (!installed) {
          checkInterval.current = setInterval(() => {
            checkExtensionInstalled().then(installed => {
              setExtensionInstalled(installed);
            });
          }, CHECK_EXTENSION_INTERVAL);
        }
      })
      .catch(() => {});
    return () => {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (extensionInstalled) {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
      showToastSuccessMessage(
        ChromeExtensionTranslation.installPluginSuccessMessage,
      );
    }
  }, [extensionInstalled]); // eslint-disable-line

  return (
    <StyledInstallPlugin>
      <InstallPluginContent data-testid="content">
        <FormattedHTMLMessage
          id={ChromeExtensionTranslation.installPluginFeaturesTitle}
        />
        <ul>
          <li>
            <RoundedCheckmarkIcon />
            <FormattedHTMLMessage
              id={
                ChromeExtensionTranslation.installPluginPasswordManagerFeature
              }
            />
          </li>
          <li>
            <RoundedCheckmarkIcon />
            <FormattedHTMLMessage
              id={ChromeExtensionTranslation.installPluginImportFeature}
            />
          </li>
          <li>
            <RoundedCheckmarkIcon />
            <FormattedHTMLMessage
              id={ChromeExtensionTranslation.installPluginQuickAddFeature}
            />
          </li>
        </ul>
        <FormattedHTMLMessage
          id={ChromeExtensionTranslation.installPluginLearnMore}
          values={{ learnMoreLink: appEnv.SUPPORT_IMPORT_BOOKMARKS_URL }}
        />
      </InstallPluginContent>
      {!extensionInstalled && (
        <InstallPluginLink onClick={onSkip} data-testid="install-plugin-button">
          <FormattedMessage
            id={ChromeExtensionTranslation.installPluginButton}
          />
        </InstallPluginLink>
      )}

      <Button
        onClick={onSkip}
        mode={ButtonMode.secondary}
        size={ButtonSize.md}
        fullWidth
        data-testid="next-button">
        <FormattedMessage
          id={
            extensionInstalled
              ? ChromeExtensionTranslation.installPluginNextButton
              : ChromeExtensionTranslation.installPluginSkipButton
          }
        />
      </Button>
    </StyledInstallPlugin>
  );
};
