import React, { FC } from 'react';
import {
  NotificationMessageApiType,
  NotificationMessageDesktopAppFields,
  NotificationMessageDesktopChatEnabledFields,
  NotificationMessageDesktopLinkAbusedFields,
  NotificationMessageDesktopLinkFields,
  NotificationMessageDesktopUserAddedFields,
  NotificationMessagePasswordRecoveryRequestCreated,
  NotificationMessageScheduledConferenceBeforeStart,
  NotificationMessageScheduledConferenceCanceled,
  NotificationMessageScheduledConferenceInvitedMember,
  NotificationMessageScheduledConferenceUpdated,
  NotificationMessageTeamDesktopAdded,
  NotificationMessageTeamDesktopRemoved,
  NotificationMessageUserAddedToDesktop,
  NotificationMessageUserAddedToTeam,
  NotificationMessageUserBookmarksImportFinished,
  NotificationMessageUserChangeRoleFields,
  NotificationMessageUserRemovedFromDesktop,
  NotificationMessageUserRemovedFromTeam,
  NotificationMessageVaultAccessSharedRemovedFromYouFields,
  NotificationMessageVaultAccessSharedWithYouFields,
  NotificationMessageWorkspaceCustomAppRemoved,
  NotificationType,
} from '../../Notifications.types';
import { NotificationTranslation } from '../../i18n';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { combineFirstAndLastNames } from '../../Notifications.utils';
import { format, parseISO } from 'date-fns';
import { SCHEDULED_CONFERENCE_START_AT_FORMAT } from '../../../Conference/Conference.constants';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import styled from 'styled-components';

interface NotificationMessageProps {
  message: NotificationMessageApiType;
}

const StyledNotification = styled.div`
  div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const NotificationMessage: FC<NotificationMessageProps> = ({
  message,
}) => {
  const { fields } = message;
  const { formatMessage } = useIntl();

  switch (message.type) {
    /**
     * Desktop related notification types
     */

    case NotificationType.DESKTOP_LINK_ADDED: {
      const firstName = (fields as NotificationMessageDesktopLinkFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageDesktopLinkFields)
        .account_last_name;
      const linkName = (fields as NotificationMessageDesktopLinkFields)
        .link_name;
      const linkUrl = (fields as NotificationMessageDesktopLinkFields).link_url;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            linkName || (!linkName && !linkUrl)
              ? NotificationTranslation.NotificationMessageDesktopLinkAdded
              : NotificationTranslation.NotificationMessageDesktopLinkToFileAdded
          }
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopLinkFields)
              .desktop_name,
            linkName: linkName
              ? linkName
              : linkUrl
              ? linkUrl
              : formatMessage({ id: NotificationTranslation.missingTitle }),
          }}
        />
      );
    }

    case NotificationType.DESKTOP_LINK_ABUSED: {
      const firstName = (fields as NotificationMessageDesktopLinkAbusedFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageDesktopLinkAbusedFields)
        .account_last_name;
      const linkName = (fields as NotificationMessageDesktopLinkAbusedFields)
        .link_name;
      const folderName = (fields as NotificationMessageDesktopLinkAbusedFields)
        .folder_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopLinkAbused}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopLinkFields)
              .desktop_name,
            linkName: linkName
              ? linkName
              : formatMessage({ id: NotificationTranslation.missingTitle }),
            folderName: folderName ? ` (${folderName})` : '',
            abuseCategories: (
              fields as NotificationMessageDesktopLinkAbusedFields
            ).abuse_description,
          }}
        />
      );
    }

    case NotificationType.DESKTOP_LINK_REMOVED: {
      const firstName = (fields as NotificationMessageDesktopLinkFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;
      const linkUrl = (fields as NotificationMessageDesktopLinkAbusedFields)
        .link_url;
      const linkName = (fields as NotificationMessageDesktopLinkAbusedFields)
        .link_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopLinkRemoved}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopLinkFields)
              .desktop_name,
            linkName: linkName ? linkName : linkUrl,
          }}
        />
      );
    }

    case NotificationType.DESKTOP_APP_ADDED: {
      const firstName = (fields as NotificationMessageDesktopAppFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopAppAdded}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopAppFields)
              .desktop_name,
            appName: (fields as NotificationMessageDesktopAppFields).app_name,
          }}
        />
      );
    }

    case NotificationType.DESKTOP_APP_REMOVED: {
      const firstName = (fields as NotificationMessageDesktopAppFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopAppRemoved}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopAppFields)
              .desktop_name,
            appName: (fields as NotificationMessageDesktopAppFields).app_name,
          }}
        />
      );
    }

    case NotificationType.DESKTOP_USER_ADDED: {
      const firstName = (fields as NotificationMessageDesktopUserAddedFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopUserAdded}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopUserAddedFields)
              .desktop_name,
          }}
        />
      );
    }

    case NotificationType.DESKTOP_CHAT_ENABLED: {
      const firstName = (fields as NotificationMessageDesktopChatEnabledFields)
        .account_first_name;
      const lastName = (fields as NotificationMessageDesktopChatEnabledFields)
        .account_last_name;
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageDesktopChatEnabled}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageDesktopChatEnabledFields)
              .desktop_name,
          }}
        />
      );
    }
    /**
     * User related notification types
     */

    case NotificationType.USER_WORKSPACE_CHANGED_ROLE:
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageUserRoleChange}
          values={{
            groupLabel: (fields as NotificationMessageUserChangeRoleFields)
              .group_label,
            workspaceName: (fields as NotificationMessageUserChangeRoleFields)
              .workspace_name,
          }}
        />
      );

    case NotificationType.USER_DESKTOP_ADDED: {
      const firstName = (fields as NotificationMessageUserAddedToDesktop)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageUserAddedToDesktop}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            desktopName: (fields as NotificationMessageUserAddedToDesktop)
              .desktop_name,
          }}
        />
      );
    }
    case NotificationType.USER_REMOVED_FROM_DESKTOP:
      const firstName = (fields as NotificationMessageUserAddedToDesktop)
        .by_account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .by_account_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageUserRemovedFromDesktop}
          values={{
            desktopName: (fields as NotificationMessageUserRemovedFromDesktop)
              .desktop_name,
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
          }}
        />
      );

    case NotificationType.USER_TEAM_ADDED: {
      const firstName = (fields as NotificationMessageUserAddedToTeam)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageUserTeamAdded}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            teamName: (fields as NotificationMessageUserAddedToTeam).team_name,
          }}
        />
      );
    }
    case NotificationType.USER_TEAM_REMOVED: {
      const firstName = (fields as NotificationMessageUserRemovedFromTeam)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageUserTeamRemoved}
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            teamName: (fields as NotificationMessageUserRemovedFromTeam)
              .team_name,
          }}
        />
      );
    }

    case NotificationType.USER_BOOKMARK_IMPORT_FINISHED: {
      const desktopName = (
        fields as NotificationMessageUserBookmarksImportFinished
      ).desktop_name;
      const importedBookmarks = (
        fields as NotificationMessageUserBookmarksImportFinished
      ).imported_count;
      const totalBookmarks = (
        fields as NotificationMessageUserBookmarksImportFinished
      ).bookmark_count;
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessageUserBookmarksImportFinished
          }
          values={{
            desktopName,
            importedBookmarks,
            totalBookmarks,
          }}
        />
      );
    }

    /**
     * Billing related notification types
     */

    case NotificationType.PAYMENT_NEW_INVOICE:
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessagePaymentNewInvoice}
        />
      );

    case NotificationType.PAYMENT_FAILED:
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessagePaymentFailed}
        />
      );

    case NotificationType.PAYMENT_INVALID_VAT:
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessagePaymentVatInvalid}
        />
      );

    /**
     * Workspace related notification types
     */

    case NotificationType.WORKSPACE_CUSTOM_APP_REMOVED: {
      const firstName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_first_name;
      const lastName = (fields as NotificationMessageWorkspaceCustomAppRemoved)
        .account_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessageWorkspaceCustomAppRemoved
          }
          values={{
            firstName: combineFirstAndLastNames(firstName || lastName, null),
            fullName: combineFirstAndLastNames(firstName, lastName),
            workspaceName: (
              fields as NotificationMessageWorkspaceCustomAppRemoved
            ).workspace_name,
            appName: (fields as NotificationMessageWorkspaceCustomAppRemoved)
              .app_name,
          }}
        />
      );
    }

    /**
     * PASSWORD MANAGER RELATED NOTIFICATIONS
     */

    case NotificationType.VAULT_ITEM_SHARED_WITH_YOU: {
      const firstName = (
        fields as NotificationMessageVaultAccessSharedWithYouFields
      ).account_first_name;
      const lastName = (
        fields as NotificationMessageVaultAccessSharedWithYouFields
      ).account_last_name;
      const appName = (
        fields as NotificationMessageVaultAccessSharedWithYouFields
      ).app_name;
      const itemUrl = (
        fields as NotificationMessageVaultAccessSharedWithYouFields
      ).vault_item_url;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessageVaultAccessSharedWithYou
          }
          values={{
            fullName: combineFirstAndLastNames(firstName, lastName),
            appName: appName || itemUrl,
          }}
        />
      );
    }

    case NotificationType.VAULT_ITEM_SHARED_REMOVED_FROM_YOU: {
      const firstName = (
        fields as NotificationMessageVaultAccessSharedRemovedFromYouFields
      ).account_first_name;
      const lastName = (
        fields as NotificationMessageVaultAccessSharedRemovedFromYouFields
      ).account_last_name;
      const appName = (
        fields as NotificationMessageVaultAccessSharedRemovedFromYouFields
      ).app_name;
      const itemUrl = (
        fields as NotificationMessageVaultAccessSharedWithYouFields
      ).vault_item_url;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessageVaultAccessSharedRemovedFromYou
          }
          values={{
            fullName: combineFirstAndLastNames(firstName, lastName),
            appName: appName || itemUrl,
          }}
        />
      );
    }

    case NotificationType.PASSWORD_RECOVERY_REQUEST_CREATED: {
      const firstName = (
        fields as NotificationMessagePasswordRecoveryRequestCreated
      ).requested_by_first_name;
      const lastName = (
        fields as NotificationMessagePasswordRecoveryRequestCreated
      ).requested_by_last_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessagePasswordRecoveryRequestCreated
          }
          values={{
            fullName: combineFirstAndLastNames(firstName, lastName),
          }}
        />
      );
    }

    case NotificationType.PASSWORD_RECOVERY_REQUEST_ACCEPTED: {
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessagePasswordRecoveryRequestAccepted
          }
        />
      );
    }

    case NotificationType.PASSWORD_RECOVERY_REQUEST_DECLINED: {
      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessagePasswordRecoveryRequestDeclined
          }
        />
      );
    }

    /**
     * CONFERENCE RELATED NOTIFICATIONS
     */

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_BEFORE_START: {
      const title = (
        fields as NotificationMessageScheduledConferenceBeforeStart
      ).title;
      const minutes = (
        fields as NotificationMessageScheduledConferenceBeforeStart
      ).how_many_minutes_before_start;
      return (
        <>
          <StyledNotification
            data-tooltip-content={title}
            data-tooltip-place={TooltipPlace.left}>
            <FormattedHTMLMessage
              tagName="div"
              id={
                NotificationTranslation.NotificationMessageScheduledConferenceBeforeStart
              }
              values={{
                minutes,
                title: title || '',
              }}
            />
          </StyledNotification>
        </>
      );
    }

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_INVITED_MEMBER: {
      const title = (
        fields as NotificationMessageScheduledConferenceInvitedMember
      ).title;
      const fullName = (
        fields as NotificationMessageScheduledConferenceInvitedMember
      ).created_by_fullname;
      const startAt = (
        fields as NotificationMessageScheduledConferenceInvitedMember
      ).startAt;
      const CONFERENCE_TITLE_MAX_LENGTH = 30;

      return (
        <>
          <StyledNotification
            data-tooltip-content={title}
            data-tooltip-place={TooltipPlace.left}>
            <FormattedHTMLMessage
              tagName="div"
              id={
                NotificationTranslation.NotificationMessageScheduledConferenceInvitedMember
              }
              values={{
                fullName,
                title: title?.substring(0, CONFERENCE_TITLE_MAX_LENGTH) || '',
                startAt: format(
                  parseISO(startAt),
                  SCHEDULED_CONFERENCE_START_AT_FORMAT,
                ),
              }}
            />
          </StyledNotification>
        </>
      );
    }

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_UPDATED: {
      const title = (fields as NotificationMessageScheduledConferenceUpdated)
        .title;
      const fullName = (fields as NotificationMessageScheduledConferenceUpdated)
        .created_by_fullname;

      const CONFERENCE_TITLE_MAX_LENGTH = 30;

      return (
        <>
          <StyledNotification
            data-tooltip-content={title}
            data-tooltip-place={TooltipPlace.left}>
            <FormattedHTMLMessage
              tagName="div"
              id={
                NotificationTranslation.NotificationMessageScheduledConferenceUpdated
              }
              values={{
                fullName,
                title: title?.substring(0, CONFERENCE_TITLE_MAX_LENGTH) || '',
              }}
            />
          </StyledNotification>
        </>
      );
    }

    case NotificationType.SCHEDULE_CHAT_CONFERENCE_NOTIFICATION_CANCELED: {
      const title = (fields as NotificationMessageScheduledConferenceCanceled)
        .title;
      const fullName = (
        fields as NotificationMessageScheduledConferenceCanceled
      ).canceled_by;

      const CONFERENCE_TITLE_MAX_LENGTH = 30;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={
            NotificationTranslation.NotificationMessageScheduledConferenceCanceled
          }
          values={{
            fullName,
            title: title?.substring(0, CONFERENCE_TITLE_MAX_LENGTH) || '',
          }}
        />
      );
    }

    case NotificationType.TEAM_DESKTOP_ADDED: {
      const teamName = (fields as NotificationMessageTeamDesktopAdded)
        .team_name;
      const desktopName = (fields as NotificationMessageTeamDesktopAdded)
        .desktop_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageTeamDesktopAdded}
          values={{
            desktopName,
            teamName,
          }}
        />
      );
    }

    case NotificationType.TEAM_DESKTOP_REMOVED: {
      const teamName = (fields as NotificationMessageTeamDesktopRemoved)
        .team_name;
      const desktopName = (fields as NotificationMessageTeamDesktopRemoved)
        .desktop_name;

      return (
        <FormattedHTMLMessage
          tagName="div"
          id={NotificationTranslation.NotificationMessageTeamDesktopRemoved}
          values={{
            desktopName,
            teamName,
          }}
        />
      );
    }

    default:
      return <>{message.type}</>;
  }
};
