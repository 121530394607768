import {
  ScheduleRepeatingRepeatFreqLabels,
  ScheduleRepeatingRepeatFreqValues,
} from './Conference.types';
import { appEnv } from '../../appEnv';
import { WORKSPACE_PATHNAME } from '../Workspace/Workspace.constants';

export const MAX_CONFERENCE_PARTICIPANTS_FREE_WORKSPACE = 1;
export const MAX_CONFERENCE_PARTICIPANTS_PRO_WORKSPACE = 9999;
export const TIME_OUT_TO_REFETCH_REPEATING_MEETINGS = 3500;
export const REFETCH_REPEATING_MEETINGS_TRIGGER_VALUE = 30;
export const GRAPHQL_TYPENAME_CHAT_CONFERENCE = 'ChatConference';
export const GRAPHQL_TYPENAME_CHAT_CONFERENCE_EDGE = 'ChatConferenceEdge';
export const GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE =
  'ScheduleChatConference';
export const GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBERS =
  'ScheduleChatConferenceMemberConnection';
export const GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_EDGE =
  'ScheduleChatConferenceEdge';
export const GRAPHQL_TYPENAME_REPEATING_SCHEDULED_CHAT_CONFERENCE =
  'RepeatingScheduleChatConference';
export const GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_MEMBER_EDGE =
  'ScheduleChatConferenceMemberEdge';
export const SCHEDULE_CHAT_CONFERENCE_MEMBER_IRI =
  '/schedule-chat-conference-members/';

export const CONFERENCE_CREATE_PATHNAME = `/meet`;
export const CONFERENCE_ROOT_SLUG = `calls`;
export const CONFERENCE_ROOT_ROUTE_PATH = `${CONFERENCE_ROOT_SLUG}/*`;
export const CONFERENCE_ROOT_PATHNAME = `${WORKSPACE_PATHNAME}/${CONFERENCE_ROOT_SLUG}`;

export const SCHEDULED_CONFERENCE_START_AT_FORMAT =
  'EEE, MMM dd, ' + appEnv.TIME_FORMAT;
export const SCHEDULED_CONFERENCE_END_AT_FORMAT = appEnv.TIME_FORMAT;

export const REPEAT_FREQ_OPTIONS: {
  value: ScheduleRepeatingRepeatFreqValues;
  label: ScheduleRepeatingRepeatFreqLabels;
}[] = [
  {
    value: ScheduleRepeatingRepeatFreqValues.DAY,
    label: ScheduleRepeatingRepeatFreqLabels.DAY,
  },
  {
    value: ScheduleRepeatingRepeatFreqValues.WEEK,
    label: ScheduleRepeatingRepeatFreqLabels.WEEK,
  },
  {
    value: ScheduleRepeatingRepeatFreqValues.MONTH,
    label: ScheduleRepeatingRepeatFreqLabels.MONTH,
  },
];

export const REPEAT_ON_WEEKS_OPTIONS = (currentDays: string[]) => {
  const options: {
    label: string;
    value: string;
    active: boolean;
  }[] = [
    {
      label: 'M',
      value: 'Mon',
      active: currentDays.includes('Mon'),
    },
    {
      label: 'T',
      value: 'Tue',
      active: currentDays.includes('Tue'),
    },
    {
      label: 'W',
      value: 'Wed',
      active: currentDays.includes('Wed'),
    },
    {
      label: 'T',
      value: 'Thu',
      active: currentDays.includes('Thu'),
    },
    {
      label: 'F',
      value: 'Fri',
      active: currentDays.includes('Fri'),
    },
    {
      label: 'S',
      value: 'Sat',
      active: currentDays.includes('Sat'),
    },
    {
      label: 'S',
      value: 'Sun',
      active: currentDays.includes('Sun'),
    },
  ];

  return options;
};
