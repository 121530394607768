import { showToastError } from '../../../../shared/components/Toast';
import { DesktopAppSortType } from '../../data/Desktop/types/Desktop.types';
import { updateDesktopAppSort } from '../DataSource/DesktopAppApiDataSource';

export const updateDesktopAppSortRepository = async (
  desktopApps: DesktopAppSortType[],
) => {
  const response = await updateDesktopAppSort(desktopApps);
  const { errors } = response;

  if (errors) {
    const message = errors[0]?.message;
    if (message) {
      showToastError(message);
    }
  }

  return response;
};
