import React, { FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { Modal, ModalHeaderColor } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import {
  ButtonControls,
  StyledScheduleRepeatingConferenceModal,
} from './ScheduleRepeatingConferenceModal.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { ScheduledConferenceUpdateConfirmationModalProps } from '../../Conference.types';
import { useCurrentConference } from '../../Conference.hooks';
import isEqual from 'lodash/isEqual';

export const ScheduleRepeatingConfirmationModal: FC<
  ScheduledConferenceUpdateConfirmationModalProps
> = ({ values, onSubmit, reapeatingPattern }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const modalBackgroundColor = theme.colors.Surface;
  const modalTextColor = theme.colors.OnSurfaceHighEmphasis;
  const { scheduledConference } = useCurrentConference();

  const shouldShowUpdateAllButton = useMemo(() => {
    if (
      !scheduledConference ||
      !reapeatingPattern ||
      !scheduledConference.repeatingScheduleChatConference
    ) {
      return false;
    }

    const prevStartAtDate = new Date(values.selectedDay).getDate();
    const prevEndAtDate = new Date(values.selectedEndDay).getDate();
    const newStartAtDate = new Date(values.startAt).getDate();
    const newEndAtDate = new Date(values.endAt).getDate();

    if (
      prevStartAtDate !== newStartAtDate ||
      prevEndAtDate !== newEndAtDate ||
      values.timezone.value !== scheduledConference.timezone ||
      !isEqual(
        reapeatingPattern,
        scheduledConference.repeatingScheduleChatConference.repeatPattern,
      )
    ) {
      return false;
    }

    return true;
  }, [
    scheduledConference,
    reapeatingPattern,
    values.selectedDay,
    values.selectedEndDay,
    values.startAt,
    values.endAt,
    values.timezone.value,
  ]);

  const onClose = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleRepeatingConfirmationOpened: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const handleUpdateSingleConference = useCallback(() => {
    onSubmit(values, false, false);
    onClose();
  }, [onClose, onSubmit, values]);

  const handleUpdateThisAndFollowingConferences = useCallback(() => {
    onSubmit(values, true, false);
    onClose();
  }, [onClose, onSubmit, values]);

  const handleUpdateAllConferences = useCallback(() => {
    onSubmit(values, true, true);
    onClose();
  }, [onClose, onSubmit, values]);

  return (
    <Modal onRequestClose={onClose} visible width={440} containerZIndex={26}>
      <StyledScheduleRepeatingConferenceModal>
        <ModalHeaderColor
          onRequestClose={onClose}
          backgroundColor={modalBackgroundColor}
          textColor={modalTextColor}>
          <FormattedMessage
            id={
              ConferenceTranslation.scheduledRepeatingConferenceConfirmationModalTitle
            }
          />
        </ModalHeaderColor>
        <ButtonControls>
          <Button
            type="button"
            mode={ButtonMode.secondary}
            size={ButtonSize.md}
            onClick={handleUpdateSingleConference}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateOne
              }
            />
          </Button>
          <Button
            type="button"
            mode={
              !shouldShowUpdateAllButton
                ? ButtonMode.primary
                : ButtonMode.secondary
            }
            size={ButtonSize.md}
            onClick={handleUpdateThisAndFollowingConferences}>
            <FormattedMessage
              id={
                ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateThisAndFollowing
              }
            />
          </Button>
          {shouldShowUpdateAllButton ? (
            <Button
              type="button"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              onClick={handleUpdateAllConferences}>
              <FormattedMessage
                id={
                  ConferenceTranslation.scheduledConferenceUpdateConfirmationModalUpdateAll
                }
              />
            </Button>
          ) : null}
        </ButtonControls>
      </StyledScheduleRepeatingConferenceModal>
    </Modal>
  );
};
