import styled from 'styled-components';
import { Button } from '../../../../../shared/components/Button/Button';

export const LinkAccountOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${p => p.theme.breakpoints.md`
    flex-direction: row;
  `}
`;

export const LinkAccountOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  flex: 1 1 50%;
  margin: 24px;
  padding: 16px;
  border-radius: 8px;
  background: ${p => p.theme.colors.Background};
  text-align: center;
`;

export const LinkAccountOptionHeading = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.825rem;
  line-height: 1.3;
`;

export const LinkAccountHeadingIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.BackgroundLight};
  border-radius: 50%;
  margin-right: 10px;
`;

export const LinkAccountOptionDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.825rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  margin-top: 24px;
`;

export const LinkAccountOptionLearnMore = styled.div`
  text-align: center;
`;

export const LinkAccountOptionLearnMoreLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${p => p.theme.colors.Primary};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.825rem;
  line-height: 1.3;
`;

export const LinkAccountOptionButton = styled(Button)`
  margin-top: 24px;
`;
