import { createContext, Dispatch } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { DesktopType } from '../../Desktop/Desktop.constants';

interface WorkspaceDndContextValue {
  setAppDroppedToDesktopHandler: Dispatch<{
    desktopId: string;
    handler: (e: DragEndEvent) => void;
  }>;
  setDesktopSortedHandler: Dispatch<{
    id: DesktopType;
    handler: (e: DragEndEvent) => void;
  }>;
  setAppSortedHandler: Dispatch<(e: DragEndEvent) => void>;
}

export const WorkspaceDndContext = createContext<WorkspaceDndContextValue>({
  setAppDroppedToDesktopHandler: () => {},
  setDesktopSortedHandler: () => {},
  setAppSortedHandler: () => {},
});
