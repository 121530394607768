import styled from 'styled-components';

export const StyledWorkspacePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: none;
  border-radius: 4px;
  padding: 0.25rem;
  cursor: pointer;
  transition: border-color 0.2s linear;
  color: ${p => p.theme.colors.PrimaryTextLight};
  background-color: ${({ theme }) => theme.colors.Surface};
  padding-left: 0.75rem;

  .drag-icon {
    color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
    margin: 0 ${({ theme }) => theme.spacing.xs};
  }
`;
