import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams, useTablet } from '../../../../shared/hooks';
import {
  sortByCreationDate,
  sortByMoveDate,
} from '../../../../shared/utils/list.utils';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { convertGraphQLApiChatConversationToInternal } from '../../../Chat/Chat.utils';
import { getFolderByFolderId } from '../../../Folder/Folder.utils';
import { useChatMercureListener } from '../../../Mercure/Chat/ChatMercure.hooks';
import {
  ChatEventRequests,
  ChatEventTypes,
  NewChatConversationEvent,
} from '../../../Mercure/Chat/ChatMercure.types';
import { SegmentType, useSetLastUsedSegmentPath } from '../../../Segment';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
  useWorkspaceLastOpenedDesktop,
} from '../../../Workspace/Workspace.hooks';
import { DesktopLayout, DesktopTab } from '../../Desktop.constants';
import { CurrentDesktopContentContextType } from '../../Desktop.context';
import { useGetIsChatHidden } from '../../Desktop.hooks';
import {
  getDesktopLayoutStorage,
  setDesktopLayoutStorage,
} from '../../Desktop.utils';
import { useCaseGetDesktopLinks } from '../../UseCase/getDesktopLinks';
import { useLiveQuery } from 'dexie-react-hooks';
import { database } from '../../../Database';
import { useCaseGetDesktopFolders } from '../../UseCase/getDesktopFolders';
import { useCaseGetDesktopApps } from '../../UseCase/getDesktopApps';
import { useCaseGetDesktopChat } from '../../UseCase/getDesktopChat';
import { ChatConversationApiType } from '../../../Chat/Chat.types';
import { getLongId, getShortId } from '../../../../shared/utils/id';
import { useDesktopsRepository } from '../../data/Desktop/Desktop.repositories';
import type { DesktopApiType } from '../../data/Desktop/types/Desktop.types';
import { useAccessesRepository } from '../../../Access/data/Access.repositories';
import { AccessApiType } from '../../../Access/data/types/Access.types';

export const StandardDesktopViewModel = () => {
  const navigate = useNavigate();
  const isTablet = useTablet();
  const queryParams = useQueryParams();
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const isChatHidden = useGetIsChatHidden();

  const {
    permissions: { canViewDesktopChatSection },
  } = useCurrentWorkspacePermissions();
  const { desktopId } = useParams<{ desktopId?: string; tab?: DesktopTab }>();

  const {
    createLink,
    createFolder,
    editAppId,
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
    folderId: folderIdParam,
  } = queryParams;

  const folderId = getLongId('folders', folderIdParam as string);

  const { desktops, loading: desktopsLoading } = useDesktopsRepository({
    variables: {
      filterOnlyAccessible: true,
    },
  });

  const desktop: DesktopApiType | undefined = useMemo(
    () =>
      desktops.find(
        ({ id }) => id === getLongId('desktops', desktopId as string),
      ),
    [desktops, desktopId],
  );

  const { accesses } = useAccessesRepository({
    desktopId: desktop?.id,
  });

  const liveApps = useLiveQuery(
    () => {
      return database.desktopApps
        .where('desktopId')
        .equals(getLongId('desktops', desktopId as string) ?? '')
        .sortBy('createdAt');
    },
    [desktopId],
    [],
  );

  const desktopApps = useMemo(() => {
    if (liveApps?.length) {
      return liveApps?.map(app => ({
        ...app.sourceData,
        desktop: {
          id: app.desktopId,
        },
      }));
    }
    return [];
  }, [liveApps]);

  const liveLinks = useLiveQuery(
    () => {
      return database.desktopLinks
        .where('desktopId')
        .equals(getLongId('desktops', desktopId as string) ?? '')
        .sortBy('createdAt')
        .then(links =>
          links.filter(link =>
            folderId ? link.folder === folderId : !link.folder,
          ),
        );
    },
    [desktopId, folderId],
    [],
  );

  const desktopLinks = useMemo(() => {
    if (liveLinks?.length) {
      return liveLinks?.map(link => link.sourceData).sort(sortByMoveDate);
    }
    return [];
  }, [liveLinks]);

  const liveFolders = useLiveQuery(
    () => {
      return database.desktopFolders
        .where('desktopId')
        .equals(getLongId('desktops', desktopId as string) ?? '')
        .sortBy('createdAt');
    },
    [desktopId],
    [],
  );

  const desktopFolders = useMemo(() => {
    if (!desktopId) {
      return [];
    }

    if (liveFolders?.length) {
      return liveFolders
        ?.map(folder => folder.sourceData)
        .filter(folder => getShortId(folder.desktop.id) === desktopId)
        .sort(sortByCreationDate);
    }
    return [];
  }, [desktopId, liveFolders]);

  const hasApps = !!desktopApps.length;
  const hasLinks = !!desktopLinks.length;
  const hasFolders = !!desktopFolders.length;
  const hasChat = desktop
    ? desktop.chatEnabled &&
      !!desktop.chatConversationIri &&
      !isChatHidden &&
      canViewDesktopChatSection
    : false;

  const { setLastOpenedDesktop: setWorkspaceLastOpenedDesktop } =
    useWorkspaceLastOpenedDesktop();

  const { pathname } = useLocation();
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.DESKTOPS);

  const [conversationEventData, setConversationEventData] =
    useState<NewChatConversationEvent>();

  const [movingLinkToFolder, setMovingLinkToFolder] = useState<
    string | undefined
  >(undefined);

  const { addListener, removeListener } = useChatMercureListener();

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = async e => {
      if (
        e['@type'] === ChatEventTypes.ChatConversation &&
        e['@request'] === ChatEventRequests.New
      ) {
        setConversationEventData(e);
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, removeListener]);

  /**
   * Desktop links
   */
  const { getDesktopLinks, desktopLinksLoading } = useCaseGetDesktopLinks();

  useEffect(() => {
    if (
      !desktop?.id ||
      !workspace.id ||
      (!!movingLinkToFolder && movingLinkToFolder === (folderId as string))
    ) {
      return;
    }
    getDesktopLinks(
      desktop.id,
      workspace.id,
      (folderIdParam as string) ?? undefined,
    );
  }, [
    getDesktopLinks,
    movingLinkToFolder,
    folderIdParam,
    desktop?.id,
    workspace.id,
    folderId,
  ]);

  /**
   * Desktop folders
   */

  const { getDesktopFolders, desktopFoldersLoading } =
    useCaseGetDesktopFolders();

  useEffect(() => {
    if (!desktop?.id || !workspace.id) {
      return;
    }
    getDesktopFolders(desktop.id, workspace.id);
  }, [getDesktopFolders, workspace.id, desktop?.id]); // eslint-disable-line

  /**
   * Desktop apps
   */

  const { getDesktopApps, desktopAppsLoading } = useCaseGetDesktopApps();

  useEffect(() => {
    if (!desktop?.id || !workspace.id) {
      return;
    }
    getDesktopApps(desktop.id, workspace.id);
  }, [getDesktopApps, workspace.id, desktop?.id]);

  /**
   * Desktop chat
   */

  const { getDesktopChat } = useCaseGetDesktopChat();
  const [conversation, setConversation] = useState<ChatConversationApiType>();
  useEffect(() => {
    if (!hasChat || !desktop?.id || !workspace.id) {
      return;
    }
    getDesktopChat(desktop.id, workspace.id).then((res: any) => {
      if (res?.data) {
        setConversation(res.data.desktopChat.chatConversation);
      }
    });
  }, [getDesktopChat, workspace.id, hasChat, desktop?.id]);

  const desktopConversation = useMemo(() => {
    if (
      !desktop?.chatEnabled ||
      (!conversation && !conversationEventData) ||
      !conversation
    ) {
      return undefined;
    }

    return convertGraphQLApiChatConversationToInternal(
      conversation || conversationEventData,
      { workspaceId: workspace.id, accountId: account.id },
    );
  }, [
    account.id,
    conversation,
    conversationEventData,
    desktop?.chatEnabled,
    workspace.id,
  ]);

  useEffect(() => {
    if (desktopId) {
      setLastSegmentPath(pathname);
      if (desktop) {
        setWorkspaceLastOpenedDesktop(desktop);
      }
    }
  }, [
    desktop,
    desktopId,
    pathname,
    setLastSegmentPath,
    setWorkspaceLastOpenedDesktop,
  ]);

  /**
   * Contexts
   */

  const desktopAccessForCurrentAccount = useMemo(
    () =>
      accesses?.find(
        (access: AccessApiType) => access?.account?.id === account.id,
      ),
    [account.id, accesses],
  );

  const [desktopLayout, setDesktopLayout] = useState<DesktopLayout>(
    getDesktopLayoutStorage(desktop?.id),
  );

  const updateDesktopLayout = useCallback(
    (layout: DesktopLayout) => {
      setDesktopLayout(layout);
      if (desktop?.id) {
        setDesktopLayoutStorage(desktop?.id, layout);
      }
    },
    [desktop?.id],
  );

  // change when tablet state changes
  // do not store this, just update the soft state
  useEffect(() => {
    const storedLayout = getDesktopLayoutStorage(desktop?.id);

    if (isTablet && storedLayout === DesktopLayout.CONSOLIDATED) {
      setDesktopLayout(DesktopLayout.TABBED);
    } else if (!isTablet && storedLayout === DesktopLayout.CONSOLIDATED) {
      setDesktopLayout(DesktopLayout.CONSOLIDATED);
    }
  }, [desktop?.id, desktopLayout, isTablet]);

  // active change / desktop change
  // store layout
  useEffect(() => {
    if (desktop?.id) {
      const storedLayout = isTablet
        ? DesktopLayout.TABBED
        : getDesktopLayoutStorage(desktop?.id);

      setDesktopLayout(storedLayout);
    }
  }, [desktop?.id, isTablet]);

  const initialChatData = useMemo(() => {
    if (!conversation) {
      return undefined;
    }

    // TODO: fix initialChatData here..
    return [];
  }, [conversation]);

  const appStoreModalCloseHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        appStoreModal: undefined,
        editAppId: undefined,
        tabId: undefined,
        createCustomAppModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const createLinkModalCloseHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        createLink: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const chooseVaultForDesktopModalCloseHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        selectVaultForAppId: undefined,
        selectVaultForGroupAppId: undefined,
        selectVaultForDesktopIdOverride: undefined,
      }),
    });
  }, [navigate, queryParams]);

  const desktopIsLoading =
    desktopAppsLoading ||
    desktopLinksLoading ||
    desktopFoldersLoading ||
    desktopsLoading;

  const desktopHasContent = hasApps || hasLinks || hasFolders || hasChat;

  const desktopisEmpty =
    !desktopIsLoading && !hasApps && !hasLinks && !hasFolders && !hasChat;

  const desktopName = desktop?.name || '';

  const createFolderParentName =
    folderId && desktopFolders.length
      ? getFolderByFolderId(desktopFolders, folderId as string)?.name || ''
      : desktopName;

  const currentDesktopContentContextValue: CurrentDesktopContentContextType =
    useMemo(() => {
      return {
        desktopApps,
        desktopAppsLoading,
        desktopFolders,
        desktopLinks,
        desktopLinksLoading,
        desktopLinksTotal: desktopLinks.length,
        hasChat,
        desktopAccessForCurrentAccount,
        setDesktopLayout: updateDesktopLayout,
        desktopLayout,
        setMovingLinkToFolder,
        movingLinkToFolder,
      };
    }, [
      desktopApps,
      desktopAppsLoading,
      desktopFolders,
      desktopLinks,
      desktopLinksLoading,
      hasChat,
      desktopAccessForCurrentAccount,
      updateDesktopLayout,
      desktopLayout,
      movingLinkToFolder,
    ]);

  return {
    currentDesktopContentContextValue,
    initialChatData,
    desktopId,
    desktopIsLoading,
    desktopHasContent,
    desktopIsEmpty: desktopisEmpty,
    desktopConversation,
    desktopLayout,
    createFolderParentName,
    createLink,
    createFolder,
    editAppId,
    desktopName,
    selectVaultForAppId,
    selectVaultForGroupAppId,
    selectVaultForDesktopIdOverride,
    appStoreModalCloseHandler,
    createLinkModalCloseHandler,
    chooseVaultForDesktopModalCloseHandler,
  };
};
