import { VaultApiType } from '../../Encryption.types';
import {
  getVaultApp,
  getVaultItem,
  isVaultManuallyCreated,
} from '../../Encryption.utils';

export const otherPasswordsSort = (a: VaultApiType, b: VaultApiType) => {
  const vaultItemA = getVaultItem(a);
  const vaultItemB = getVaultItem(b);
  return vaultItemA.url?.localeCompare(vaultItemB?.url || '') || -1;
};

export const appPasswordsSort = (a: VaultApiType, b: VaultApiType) => {
  const vaultAppA = getVaultApp(a);
  const vaultAppB = getVaultApp(b);
  return vaultAppA?.name?.localeCompare(vaultAppB?.name || '') || -1;
};

export const appPasswordsFilter = (vault: VaultApiType) => {
  const vaultApp = getVaultApp(vault);
  return vaultApp && !vaultApp?.hidden;
};

export const groupPasswordsFilter = (vault: VaultApiType) => {
  const vaultApp = getVaultApp(vault);
  return vaultApp?.hidden;
};

export const otherPasswordsFilter = (vault: VaultApiType) => {
  const vaultApp = getVaultApp(vault);
  return isVaultManuallyCreated(vault) && !vaultApp;
};
