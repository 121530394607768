import styled, { css } from 'styled-components';

export const DocViewerContainer = styled.div<{ inModal: boolean }>`
  display: flex;
  width: 100%;

  ${({ theme, inModal }) =>
    inModal
      ? css`
          max-height: 80vh;
          height: 100vh;
        `
      : css`
          height: 100%;
        `};
`;
