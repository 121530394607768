import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { useMobile } from '../../../../../../../shared/hooks';

const StyledTab = styled.button<{
  isMobile: boolean;
}>`
  appearance: none;
  border: 0;
  background: transparent;
  margin-right: 16px;
  cursor: pointer;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 98px;
      height: 44px;
      margin: 0 8px;
    `}
  &:hover {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }
`;

const Title = styled.span<{
  isActve: boolean;
  isMobile: boolean;
}>`
  font-size: ${({ isMobile }) => (isMobile ? 1 : 0.875)}rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  line-height: 1.43;
  color: ${({ isActve, theme }) =>
    isActve
      ? theme.colors.OnSurfaceHighEmphasis
      : theme.colors.OnSurfaceLightEmphasis};

  .item-count {
    color: ${({ theme }) => theme.colors.OnSurfaceLightEmphasis};
    margin-left: 4px;
  }
`;

export const Tab: FC<{
  path: string;
  title: string;
  itemCount?: number;
  isActive: boolean;
}> = ({ path, title, itemCount = 0, isActive }) => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const onClickHandler = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <StyledTab onClick={onClickHandler} isMobile={isMobile}>
      <Title isActve={isActive} isMobile={isMobile}>
        {title}
        {itemCount > 0 && <span className="item-count">{itemCount}</span>}
      </Title>
    </StyledTab>
  );
};
