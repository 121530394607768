import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { useQueryParams } from '../../../../shared/hooks';
import { ImportStepTypes } from '../../ChromeExtension.types';
import { ImportAppsView } from './ImportApps';
import { ImportLinksView } from './ImportLinks';
import { ImportModalContent } from './importModal.styled';
import { ImportSuccess } from './ImportSuccess';

interface ImportModalProps extends ModalProps {
  visible: boolean;
  onRequestClose: () => void;
}

export const ImportModal: FC<ImportModalProps> = ({
  visible,
  onRequestClose,
}) => {
  const { importStep } = useQueryParams();

  return (
    <Modal
      visible={visible}
      width={960}
      minHeight={730}
      onRequestClose={onRequestClose}>
      <ModalHeader underline={false} onRequestClose={onRequestClose} />
      <ImportModalContent>
        {/*<StepIndicator />*/}
        {importStep === ImportStepTypes.selectApps && <ImportAppsView />}
        {importStep === ImportStepTypes.selectLinks && <ImportLinksView />}
        <ImportSuccess />
      </ImportModalContent>
    </Modal>
  );
};
