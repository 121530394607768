import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { useImportBookmarkMutation } from '../../ChromeExtension.hooks';
import {
  convertBookmarksToLinksImport,
  getAllBookmarkIdsList,
  importLinksFromHistory,
  prepareTreeNodes,
} from '../../ChromeExtension.utils';
import { ChromeExtensionTranslation } from '../../i18n';
import { ImportLinksWarning } from '../../ImportAppsView/ImportLinksList/ImportLinksList.styled';
import {
  ButtonGroup,
  ImportControls,
  ImportModalTitle,
  TreeContainer,
} from './importModal.styled';
import { ImportStepTypes } from '../../ChromeExtension.types';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { InstallExtensionBanner } from './InstallExtensionBanner';

export const ImportLinksView: FC<{}> = () => {
  const currentDesktop = useCurrentDesktop();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [importedBookmarks, setImportedBookmarks] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [extensionNotInstalled, setExtensionNotInstalled] = useState(false);
  const [checked, setChecked] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<any[]>([]);

  useEffect(() => {
    importLinksFromHistory()
      .then((data: any) => {
        if (data) {
          setImportedBookmarks(data);
        }
      })
      .catch(() => {
        setExtensionNotInstalled(true);
      });
  }, []);

  const bookmarksTree = useMemo(() => {
    return prepareTreeNodes(
      importedBookmarks && importedBookmarks[0]?.children,
    );
  }, [importedBookmarks]);

  const allBookmarksIds = useMemo(() => {
    return getAllBookmarkIdsList(importedBookmarks);
  }, [importedBookmarks]);

  const checkedMap = useMemo(() => {
    return checked.reduce((acc, curr) => {
      return { ...acc, [curr]: true };
    }, {});
  }, [checked]);

  const [importBookmark] = useImportBookmarkMutation();

  const importSelectedLinks = useCallback(() => {
    setLoading(false);
    importBookmark({
      variables: {
        input: {
          desktop: currentDesktop!.id,
          bookmarks: convertBookmarksToLinksImport(
            importedBookmarks,
            checkedMap,
          ),
        },
      },
    })
      .then(() => {
        setLoading(false);
        navigate({
          search: getQueryParamsFrom({
            ...queryParams,
            importStep: ImportStepTypes.importLinksSuccess,
          }),
        });
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    importBookmark,
    currentDesktop,
    navigate,
    queryParams,
    checkedMap,
    importedBookmarks,
  ]);

  const selectAllLinks = useCallback(() => {
    setChecked(allBookmarksIds);
  }, [allBookmarksIds]);

  const clearAllLinks = useCallback(() => {
    setChecked([]);
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerFullscreen size={60} darkIcon>
          <FormattedMessage
            id={ChromeExtensionTranslation.importLinksSpinnerMessage}
          />
        </SpinnerFullscreen>
      ) : (
        <>
          <ImportModalTitle>
            <FormattedMessage
              id={ChromeExtensionTranslation.importLinksTitle}
            />
          </ImportModalTitle>

          {extensionNotInstalled ? (
            <InstallExtensionBanner />
          ) : (
            <>
              <ImportLinksWarning>
                <ImportantMessage type={ImportantMessageType.WARNING} withIcon>
                  <FormattedMessage
                    id={ChromeExtensionTranslation.importLinksImportantMessage}
                  />
                </ImportantMessage>
              </ImportLinksWarning>

              {bookmarksTree && (
                <TreeContainer>
                  <CheckboxTree
                    nodes={bookmarksTree}
                    checked={checked}
                    expanded={expanded}
                    onCheck={setChecked}
                    onExpand={setExpanded}
                  />
                </TreeContainer>
              )}
            </>
          )}

          <ImportControls>
            <ButtonGroup>
              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                type="button"
                onClick={selectAllLinks}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSelectAllButton}
                />
              </Button>

              <Button
                mode={ButtonMode.secondary}
                size={ButtonSize.md}
                type="button"
                onClick={clearAllLinks}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importClearAllButton}
                />
              </Button>
            </ButtonGroup>

            <ButtonGroup>
              <Button
                mode={ButtonMode.tertiary}
                size={ButtonSize.md}
                type="button"
                onClick={() => {
                  navigate({
                    search: getQueryParamsFrom({
                      ...queryParams,
                      importModal: undefined,
                      importStep: undefined,
                    }),
                  });
                }}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSkipButton}
                />
              </Button>

              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                type="button"
                disabled={!checked?.length}
                onClick={importSelectedLinks}>
                <FormattedMessage
                  id={ChromeExtensionTranslation.importSelectedLinksButton}
                />
              </Button>
            </ButtonGroup>
          </ImportControls>
        </>
      )}
    </>
  );
};
