import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { BaseEmoji } from 'emoji-mart';
import { StyledEmojiListItem } from './EmojiListItem.styled';

interface EmojiListItemProps {
  emoji: BaseEmoji;
  onSelect: (emoji: BaseEmoji) => void;
  active: boolean;
  index: number;
  onIndexChange: (index: number) => void;
}

export const EmojiListItem: FC<EmojiListItemProps> = React.memo(
  ({ emoji, active, onSelect, index, onIndexChange }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      if (active) {
        buttonRef.current?.scrollIntoView({ block: 'nearest' });
      }
    }, [active]);

    const handleMouseDown: MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        e.preventDefault();
        onSelect(emoji);
      },
      [emoji, onSelect],
    );

    const handleMouseEnter = useCallback(() => {
      onIndexChange(index);
    }, [index, onIndexChange]);

    return (
      <StyledEmojiListItem
        ref={buttonRef}
        active={active}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        data-testid="emoji-list-item">
        <span data-testid="emoji-icon">{emoji.native}</span>
        {emoji.colons}
      </StyledEmojiListItem>
    );
  },
);
