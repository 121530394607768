import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import {
  AccountOrigin,
  AccountWithCountsApiType,
} from '../../../../User/User.types';
import {
  OptionChatAvatar,
  OptionInner,
  OptionLabel,
  StyledUserRole,
} from './CustomOption.styled';
import { NewOption } from '../ConversationSelect.types';
import { IntegrationBadge } from '../../../ChatAvatar/IntegrationBadge';
import { useGetUserRoleTitle } from '../../../../User/User.utils';

export const CustomOption: FC<OptionProps<AccountWithCountsApiType, false>> = ({
  label,
  isFocused,
  data,
  innerProps,
}) => {
  const option: AccountWithCountsApiType | NewOption = data;
  const isExternalUser =
    'origin' in option && option.origin !== AccountOrigin.webtopcom;
  const getUserRole = useGetUserRoleTitle();
  const { id, onClick, onMouseMove, onMouseOver } = innerProps;

  return (
    <OptionInner
      isFocused={isFocused}
      data-testid="add-user-option"
      data-entityid={option.id}
      id={id}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseOver={onMouseOver}>
      {'id' in option && (
        <OptionChatAvatar
          account={option}
          className="avatar"
          avatarSize="1.5rem"
          onlineStatusSize="0.6rem"
        />
      )}
      <OptionLabel data-testid="user-name">{label}</OptionLabel>
      {isExternalUser && (
        <IntegrationBadge origin={AccountOrigin.slack} badgeSize={20} />
      )}
      <StyledUserRole data-testid="user-role">
        {getUserRole(data)}
      </StyledUserRole>
    </OptionInner>
  );
};
