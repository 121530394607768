import { useMemo } from 'react';

export const usePrepareMessageTextSpaces = (value: string) => {
  return useMemo(() => {
    const splitedValue = value.split(' ');

    const splitedArray = splitedValue.map((word, i) => {
      if (
        word === '' &&
        splitedValue[i + 1] === '' &&
        splitedValue[i - 1] !== ' '
      ) {
        return '\u00A0';
      }

      return ' ' + word;
    });

    return (
      splitedArray.slice(0, splitedArray.length - 1).join(' ') +
      splitedArray.slice(-1)
    );
  }, [value]);
};
