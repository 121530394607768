import React from 'react';
import { ChatConversationsTableType } from '../../../../../Database/ChatConversationsTable/ChatConversationsTable';
import type { ChatMessagesTableType } from '../../../../../Database/ChatMessagesTable/ChatMessagesTable';

interface ChatMessageThreadSidebarContextType {
  threadMessages: ChatMessagesTableType[];
  threadSidebarIsOpen: boolean;
  closeThreadSideBar: () => void;
  conversation: ChatConversationsTableType | undefined;
  showMessageInConversation: (
    conversationId?: string,
    messageId?: string,
    isThread?: boolean,
  ) => void;
  chatMessageId: string | null;
  loading: boolean;
  scrollToMessage: string | null;
  disableLastCommentAt: boolean;
  setDisableLastCommentAt: (state: boolean) => void;
  setCurrentConversationWidth: (width: number) => void;
  shouldCloseSidebar: boolean;
  removeHightlight: () => void;
}

export const ChatMessageThreadSidebarContext =
  React.createContext<ChatMessageThreadSidebarContextType>({
    threadMessages: [],
    threadSidebarIsOpen: false,
    closeThreadSideBar: () => {},
    conversation: undefined,
    showMessageInConversation: () => {},
    chatMessageId: '',
    loading: false,
    scrollToMessage: '',
    disableLastCommentAt: false,
    setDisableLastCommentAt: () => {},
    setCurrentConversationWidth: () => {},
    shouldCloseSidebar: false,
    removeHightlight: () => {},
  });
