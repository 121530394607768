import styled, { css } from 'styled-components';

export const StyledEmojiListItem = styled.button<{ active: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.25rem 0.5rem;
  display: block;
  text-align: left;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  align-items: center;
  display: flex;

  & + & {
    margin-top: 0.25rem;
  }

  ${p =>
    p.active &&
    css`
      background-color: ${p => p.theme.colors.Background};
    `}

  > span {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
`;
