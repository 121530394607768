import React, { FC, useMemo, useRef } from 'react';
import { useCurrentWorkspace, useWorkspacesQuery } from '../../Workspace.hooks';
import {
  CurrentSelectedWorkspace,
  CurrentSelectedWorkspaceName,
  StyledWorkspaceSelect,
  SwitchToHeading,
  SwitchToWorkspaceItem,
  SwitchToWorkspaceList,
  SwitchToWorkspaceName,
  WorkspaceSelectButton,
} from './WorkspaceSelect.styled';
import { KeyboardArrowDownIcon } from '../../../../shared/icons';
import { extractNodes } from '../../../../shared/api/api.utils';
import { Menu, MenuItem } from '../../../../shared/components/Menu';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { WorkspaceIcon } from '../../WorkspaceIcon';
import { useTheme } from 'styled-components';
import { WorkspaceTranslation } from '../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkspaceApiType } from '../../Workspace.types';
import { TooltipPlace } from '../../../../shared/components/Tooltip';

interface WorkspaceSelectProps {
  onSelect: (workspace: WorkspaceApiType) => void;
}

export const WorkspaceSelect: FC<WorkspaceSelectProps> = ({ onSelect }) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const accountSelectButtonRef = useRef(null);
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { account: authenticatedAccount } = useCurrentAccount();
  const { data } = useWorkspacesQuery({
    variables: {
      id: authenticatedAccount!.identityId,
    },
  });
  const identityWorkspaces = useMemo(
    () =>
      data
        ? extractNodes(data.accountIdentity.myAccounts)
            .map(account => extractNodes(account.workspaces))
            .flat()
        : [],
    [data],
  );
  const workspaces = useMemo(
    () => identityWorkspaces.map(({ workspace }) => workspace),
    [identityWorkspaces],
  );

  if (!workspaces?.length) {
    return null;
  }

  return (
    <StyledWorkspaceSelect>
      <WorkspaceSelectButton ref={accountSelectButtonRef}>
        <CurrentSelectedWorkspace>
          <WorkspaceIcon
            workspace={currentWorkspace}
            size={24}
            rounded={true}
            color={theme.colors.OnSurfaceMediumEmphasis}
            backgroundColor={theme.colors.Surface}
          />
          <CurrentSelectedWorkspaceName>
            {currentWorkspace.name}
          </CurrentSelectedWorkspaceName>
        </CurrentSelectedWorkspace>

        <KeyboardArrowDownIcon
          data-tooltip-place={TooltipPlace.right}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: WorkspaceTranslation.tooltipAdminNavSelectWorkspace,
          })}
        />
      </WorkspaceSelectButton>
      <Menu trigger={accountSelectButtonRef} width={200}>
        <SwitchToHeading>
          <FormattedMessage
            id={WorkspaceTranslation.adminSwitchToWorkspaceHeading}
          />
        </SwitchToHeading>
        <SwitchToWorkspaceList>
          {workspaces.map(workspace => (
            <MenuItem
              key={workspace.id}
              onClick={() => onSelect(workspace)}
              displayCheckMark={workspace.id === currentWorkspace.id}>
              <SwitchToWorkspaceItem>
                <WorkspaceIcon
                  workspace={workspace}
                  size={24}
                  rounded={true}
                  color={theme.colors.OnSurfaceMediumEmphasis}
                  backgroundColor={theme.colors.Surface}
                />
                <SwitchToWorkspaceName>{workspace.name}</SwitchToWorkspaceName>
              </SwitchToWorkspaceItem>
            </MenuItem>
          ))}
        </SwitchToWorkspaceList>
      </Menu>
    </StyledWorkspaceSelect>
  );
};
