import styled from 'styled-components';
import { OnlineStatusCircle } from '../../Account/components/OnlineStatus/OnlineStatusCircle';

export const StyledConferenceAvatar = styled.div<{
  avatarSize: string;
  onlineStatusBorderColor: string;
}>`
  position: relative;
  flex-shrink: 0;
  width: ${p => p.avatarSize};
  height: ${p => p.avatarSize};
  flex: 0 0 ${p => p.avatarSize};
  font-size: ${p => p.avatarSize};
  color: ${p => p.theme.colors.PrimaryTextLight};

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .initials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    text-transform: uppercase;
    background: ${p => p.theme.colors.SurfaceSubdued};
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: 0.375em;
  }

  ${OnlineStatusCircle} {
    position: absolute;
    bottom: -0.125rem;
    right: -0.125rem;
    border: 0.125rem solid ${p => p.onlineStatusBorderColor};
  }
`;

export const ImageWrapper = styled.div<{ avatarSize: string }>`
  position: relative;
  width: ${p => p.avatarSize};
  height: ${p => p.avatarSize};
  flex: 0 0 ${p => p.avatarSize};
`;

export const ConferenceIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  color: ${p => p.theme.colors.IconLight};
  cursor: pointer;
`;

export const DesktopIcon = styled.div<{ sharedDesktop?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  cursor: pointer;

  svg {
    color: ${p =>
      p.sharedDesktop ? p.theme.colors.OnNavbar : p.theme.colors.Primary};
    width: 50%;
    height: 50%;
  }
`;
