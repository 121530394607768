import { getShortId } from '../../../../../shared/utils/id';
import { getApolloClient, getRestApiClient } from '../../../../Api/Api.utils';
import { RestApiClientResponse } from '../../../../Api/RestApiClient/RestApiClient.types';
import { ChatConversationApiToChatConversationsTableRow } from '../../../../Database/ChatConversationsTable/ChatConversationsTable.utils';
import {
  ADD_MEMBERS_TO_CHAT_CONVERSATION,
  AddMembersToChatConversationResponse,
  AddMembersToChatConversationVariables,
  CREATE_CHAT_CONVERSATION,
  CREATE_PENDING_CHAT_CONVERSATION,
  CreateChatConversationResponse,
  CreateChatConversationVariables,
  CreatePendingChatConversationResponse,
  CreatePendingChatConversationVariables,
  EDIT_CHAT_CONVERSATION,
  EditChatConversationResponse,
  EditChatConversationVariables,
  INVITE_GUEST_TO_CHAT_CONVERSATION,
  InviteGuestToChatConversationResponse,
  InviteGuestToChatConversationVariables,
  LEAVE_FROM_CHAT_CONVERSATION,
  LeaveFromChatConversationResponse,
  LeaveFromChatConversationVariables,
  PIN_CHAT_CONVERSATION,
  PinChatConversationResponse,
  PinChatConversationVariables,
  REMOVE_CHAT_CONVERSATION,
  REMOVE_MEMBERS_FROM_CHAT_CONVERSATION,
  RemoveChatConversationResponse,
  RemoveChatConversationVariables,
  RemoveMembersFromChatConversationResponse,
  RemoveMembersFromChatConversationVariables,
  UNPIN_CHAT_CONVERSATION,
  UnpinChatConversationResponse,
  UnpinChatConversationVariables,
} from '../../../Chat.mutations';
import {
  GET_CHAT_CONVERSATION,
  GET_CHAT_CONVERSATION_INVITATIONS,
  GetChatConversationResponse,
  GetChatConversationVariables,
  HIDE_CHAT_CONVERSATION,
  HideChatConversationResponse,
  HideChatConversationVariables,
} from '../../../Chat.queries';
import type {
  ChatConversationApiType,
  ChatConversationUserContext,
} from '../../../Chat.types';

export const getConversationsApi = (
  userContext: ChatConversationUserContext,
  authToken: string | null | undefined,
): Promise<
  RestApiClientResponse<Array<{ chatConversation: ChatConversationApiType }>>
> => {
  const restApiClient = getRestApiClient();
  return restApiClient!.fetch(
    `/workspace/${getShortId(userContext.workspaceId)}/chat`,
  );
};

export const createConversationApi = (
  userIds: string[],
  workspaceId: string,
  title?: string,
) => {
  const apolloClient = getApolloClient();

  //!TODO create a separate restClient class maintaining api url and headers inside
  return apolloClient!
    .mutate<CreateChatConversationResponse, CreateChatConversationVariables>({
      mutation: CREATE_CHAT_CONVERSATION,
      variables: {
        input: {
          workspace: workspaceId,
          users: userIds,
          title,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data?.createChatConversation.chatConversation);
};

export const createPendingConversationApi = (
  userIds: string[],
  emails: string[],
  workspaceId: string,
  title?: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<
      CreatePendingChatConversationResponse,
      CreatePendingChatConversationVariables
    >({
      mutation: CREATE_PENDING_CHAT_CONVERSATION,
      variables: {
        input: {
          workspace: workspaceId,
          accounts: userIds,
          inviteeEmails: emails,
          title,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(
      response => response.data?.createPendingChatConversation.chatConversation,
    );
};

export const renameConversationApi = (
  id: string,
  title: string,
  userContext: ChatConversationUserContext,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<EditChatConversationResponse, EditChatConversationVariables>({
      mutation: EDIT_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          title,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => {
      const conversationResp =
        response.data?.editChatConversation.chatConversation;
      if (!conversationResp) {
        return null;
      }

      return ChatConversationApiToChatConversationsTableRow(
        conversationResp,
        userContext,
      );
    });
};

export const inviteConversationGuestApi = (id: string, email: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<
      InviteGuestToChatConversationResponse,
      InviteGuestToChatConversationVariables
    >({
      mutation: INVITE_GUEST_TO_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          email,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CHAT_CONVERSATION_INVITATIONS,
          variables: {
            chatConversationIri: id,
          },
        },
      ],
      fetchPolicy: 'no-cache',
    })
    .then(
      response => response.data?.inviteGuestToChatConversation.chatConversation,
    );
};

export const addConversationParticipantApi = (id: string, userId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<
      AddMembersToChatConversationResponse,
      AddMembersToChatConversationVariables
    >({
      mutation: ADD_MEMBERS_TO_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          users: [userId],
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(
      response => response.data?.addMembersToChatConversation.chatConversation,
    );
};

export const removeConversationParticipantApi = (
  id: string,
  userId: string,
) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<
      RemoveMembersFromChatConversationResponse,
      RemoveMembersFromChatConversationVariables
    >({
      mutation: REMOVE_MEMBERS_FROM_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          users: [userId],
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(
      response =>
        response.data?.removeMembersFromChatConversation.chatConversation,
    );
};

export const pinConversationApi = (id: string, accountId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<PinChatConversationResponse, PinChatConversationVariables>({
      mutation: PIN_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          accountIri: accountId,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(response => response.data?.pinChatConversation.chatConversation);
};

export const unpinConversationApi = (id: string, accountId: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<UnpinChatConversationResponse, UnpinChatConversationVariables>({
      mutation: UNPIN_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
          accountIri: accountId,
        },
      },
    })
    .then(response => response.data?.unpinChatConversation.chatConversation);
};

export const leaveConversationApi = (id: string) => {
  const apolloClient = getApolloClient();

  return apolloClient!
    .mutate<
      LeaveFromChatConversationResponse,
      LeaveFromChatConversationVariables
    >({
      mutation: LEAVE_FROM_CHAT_CONVERSATION,
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: 'no-cache',
    })
    .then(
      response => response.data?.leaveFromChatConversation.chatConversation.id,
    );
};

export const removeConversationApi = (id: string) => {
  const apolloClient = getApolloClient();
  return apolloClient!.mutate<
    RemoveChatConversationResponse,
    RemoveChatConversationVariables
  >({
    mutation: REMOVE_CHAT_CONVERSATION,
    variables: {
      input: {
        id,
      },
    },
  });
};

export const getConversationByIdApi = (id: string) => {
  const apolloClient = getApolloClient();
  return apolloClient!
    .mutate<GetChatConversationResponse, GetChatConversationVariables>({
      mutation: GET_CHAT_CONVERSATION,
      variables: {
        id,
      },
    })
    .then(response => response.data?.chatConversation);
};

export const hideConversationApi = (
  accountId: string,
  conversationId: string,
) => {
  const apolloClient = getApolloClient();
  return apolloClient!
    .mutate<HideChatConversationResponse, HideChatConversationVariables>({
      mutation: HIDE_CHAT_CONVERSATION,
      variables: {
        input: {
          id: conversationId,
          accountIri: accountId,
        },
      },
    })
    .then(
      response => response.data?.hideChatConversation.chatConversation || null,
    );
};
