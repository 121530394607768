import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledConferenceStatus } from './ConferenceStatus.styled';

interface ConferenceStatusProps {
  status: string;
  isActive?: boolean;
}

export const ConferenceStatus: FC<ConferenceStatusProps> = ({
  status,
  isActive,
}) => {
  return (
    <StyledConferenceStatus isActive={isActive}>
      {status && <FormattedMessage id={status} />}
    </StyledConferenceStatus>
  );
};
