import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppleTouchDevice } from '../../../../shared/hooks';
import { AppLink } from '../../../App/Views/AppLink/AppLink';
import { ContentLayout } from '../../../ContentLayout';
import {
  useCurrentDesktop,
  useCurrentDesktopPermissions,
} from '../../Desktop.hooks';
import {
  StyledAppsLayoutGrid,
  StyledAppsLayoutGridInner,
} from './AppsLayoutGrid.styled';
import { DragEndEvent, DragOverlay, useDndMonitor } from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { useWorkspaceDndContext } from '../../../Workspace/WorkspaceDndProvider/WorkspaceDnd.hooks';
import { useCaseEditDesktopApp } from '../../UseCase/editDesktopApp';
import { sortByAccountSort } from '../../../../shared/utils/list.utils';
import { useCaseGetDesktopApps } from '../../UseCase/getDesktopApps';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useCurrentTypeOfPage } from '../../Desktop.utils';
import type { DesktopAppEdgeApiType } from '../../data/Desktop/types/Desktop.types';

interface LayoutProps {
  apps: DesktopAppEdgeApiType[];
}

export const APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID =
  'APP_LAYOUT_GRID_SORTABLE_CONTAINER';

export const AppsLayoutGrid: FC<LayoutProps> = ({ apps }) => {
  const { canRemoveApp } = useCurrentDesktopPermissions();
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const isAppleTouchDevice = useAppleTouchDevice();
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();

  const appsByPosition = useMemo(() => {
    return apps.sort(sortByAccountSort);
  }, [apps]);

  const [appsWithManualSort, setAppsWithManualSort] = useState<
    DesktopAppEdgeApiType[]
  >([]);

  useEffect(() => {
    setAppsWithManualSort(() => appsByPosition);
  }, [appsByPosition]);

  const [currentDraggableAppId, setCurrentDraggableAppId] = useState('');
  const currentDraggableApp = useMemo(() => {
    return apps.find(appNode => appNode.id === currentDraggableAppId);
  }, [apps, currentDraggableAppId]);

  useDndMonitor({
    onDragStart(event) {
      if ((event.active?.id as string)?.startsWith('/desktop-apps/')) {
        setCurrentDraggableAppId(event.active?.id as string);
      }
    },
    onDragEnd() {
      setCurrentDraggableAppId('');
    },
  });

  const { updateDesktopAppSort } = useCaseEditDesktopApp();
  const { getDesktopApps } = useCaseGetDesktopApps();

  const handleAppSorted = useCallback(
    (e: DragEndEvent) => {
      const oldIndex = e.active?.data?.current?.sortable?.index;
      const newIndex = e.over?.data?.current?.sortable?.index;
      const newAppsWithSort = arrayMove(appsWithManualSort, oldIndex, newIndex);
      setAppsWithManualSort(newAppsWithSort);
      updateDesktopAppSort(
        newAppsWithSort.map((app, index) => ({ id: app.id, position: index })),
      ).then(() => {
        if (desktop) {
          getDesktopApps(desktop?.id, workspace.id);
        }
      });
    },
    [
      appsWithManualSort,
      updateDesktopAppSort,
      desktop,
      workspace,
      getDesktopApps,
    ],
  );

  const { setAppSortedHandler } = useWorkspaceDndContext();
  useEffect(() => {
    setAppSortedHandler(() => handleAppSorted);
  }, [handleAppSorted]); // eslint-disable-line

  return (
    <StyledAppsLayoutGrid>
      <SortableContext
        id={APP_LAYOUT_GRID_SORTABLE_CONTAINER_ID}
        disabled={isFavoritesDesktop}
        items={appsWithManualSort.map(appNode => appNode.id)}
        strategy={rectSortingStrategy}>
        {appsWithManualSort.map(appNode => (
          <StyledAppsLayoutGridInner
            key={appNode.app.id}
            processHover={!isAppleTouchDevice}>
            <AppLink
              key={appNode.id}
              app={appNode}
              dragEnabled={canRemoveApp}
              isDragging={appNode.id === currentDraggableApp?.id}
              appsLayout={ContentLayout.GRID}
              processHover={!isAppleTouchDevice}
              heartIconOnlyOnFavorited={isAppleTouchDevice}
            />
          </StyledAppsLayoutGridInner>
        ))}
      </SortableContext>
      {currentDraggableApp && (
        <DragOverlay>
          <AppLink
            app={currentDraggableApp}
            dragEnabled={canRemoveApp}
            appsLayout={ContentLayout.GRID}
            processHover={false}
            heartIconOnlyOnFavorited={false}
          />
        </DragOverlay>
      )}
    </StyledAppsLayoutGrid>
  );
};
