import React, { FC } from 'react';
import { ConferenceApiType } from '../../../../../Conference.types';
import {
  useConferenceParticipants,
  useConferenceParticipantsTitle,
} from '../../../../../Conference.hooks';
import {
  StyledConferenceTitle,
  StyledConferenceTitleWrapper,
} from './ConferenceTitle.styled';
import { useIntl } from 'react-intl';
import { ConferenceTranslation } from '../../../../../i18n';
import { AccountOrigin } from '../../../../../../User/User.types';
import SlackIcon from '../../../../../../../shared/assets/images/slack.svg';

interface ConferenceUsersTitleProps {
  conference: ConferenceApiType;
  isActive?: boolean;
}

export const ConferenceTitleUser: FC<ConferenceUsersTitleProps> = ({
  conference,
  isActive,
}) => {
  const intl = useIntl();
  const title =
    useConferenceParticipantsTitle(conference) ||
    intl.formatMessage({
      id: ConferenceTranslation.conferencesListEmptyConferenceTitle,
    });
  const conferenceParticipant = useConferenceParticipants(conference, true);
  const isSlackUser =
    conferenceParticipant.length === 1 &&
    conferenceParticipant[0].origin === AccountOrigin.slack;

  return (
    <StyledConferenceTitleWrapper>
      <StyledConferenceTitle status={conference.status} isActive={isActive}>
        {title}
      </StyledConferenceTitle>
      {isSlackUser && <img src={SlackIcon} alt="" />}
    </StyledConferenceTitleWrapper>
  );
};
