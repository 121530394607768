import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { ContentLayout } from '../../../ContentLayout';
import { LOGO_SIZE } from '../../App.constants';

export const StyledAppWrapper = styled.div<{
  appsLayout: ContentLayout;
  isDragging: boolean;
  isDragEnabled: boolean;
  processHover: boolean;
  showIosOptions: boolean;
  zIndex: number;
}>`
  position: relative;
  flex: 1;
  z-index: ${p => p.zIndex};
  display: flex;
  align-items: center;
  justify-content: center;

  & > a.with-focus-visible:focus:not(:focus-visible) {
    outline: none;
  }

  .menu.opened ~ .appLink {
    ${({ appsLayout, theme }) =>
      appsLayout === ContentLayout.GRID &&
      css`
        background-color: ${theme.colors.BackgroundLight};
      `}

    ${({ appsLayout, theme }) =>
      appsLayout === ContentLayout.FLUID &&
      css`
        background-color: ${theme.colors.Background};
      `}
  }

  .menu.opened ~ button {
    ${({ appsLayout, theme }) =>
      appsLayout === ContentLayout.GRID &&
      css`
        background-color: ${theme.colors.Background};
      `}
    ${({ appsLayout, theme }) =>
      appsLayout === ContentLayout.FLUID &&
      css`
        background-color: ${theme.colors.BackgroundLight};
      `}
    svg {
      transform: translateY(0);
    }
  }

  ${p =>
    p.processHover &&
    css`
      &:hover {
        button {
          opacity: 1;
          visibility: visible;

          &:hover {
            svg {
              transform: translateY(0);
            }
          }
        }

        .favorite-icon {
          opacity: 1;
          visibility: visible;
        }
      }
    `}

  ${p =>
    p.showIosOptions &&
    css`
      button {
        opacity: 1;
        visibility: visible;

        &:hover {
          svg {
            transform: translateY(0);
          }
        }
      }

      .make-favorite {
        opacity: 1;
      }
    `}


  ${p =>
    p.isDragging &&
    css`
      pointer-events: none;
      display: none;
    `}

  ${p =>
    p.isDragEnabled &&
    css`
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    `}
`;

export const StyledAppLink = styled.a`
  height: 100%;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.2s ease;
`;

export const AppImageWrap = styled.div<{ isSVGImage?: boolean }>`
  display: flex;
  justify-content: center;
  height: ${LOGO_SIZE}px;
  margin-bottom: 0.625rem;
`;

export const AppImage = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  flex: 0 0 ${p => p.size}px;
  user-select: none;
  object-fit: contain;
  overflow: hidden;

  svg {
    transform: translateY(0);
  }
`;

export const AppName = styled.div`
  ${({ theme }) => theme.fontTemplates.smallSemibold};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  max-width: 80px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const AppLogin = styled.div`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-family: ${p => p.theme.fonts.Regular};
  max-width: 80px;
  width: 100%;
  margin: 0 auto;
  font-size: 0.625rem;
  line-height: 1.4;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const MenuButton = styled(ButtonWithIcon)<{
  appsLayout: ContentLayout;
}>`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  width: 28px;
  height: 28px;
  background-color: ${({ theme }) => theme.colors.LinkHoverBackground};
  color: ${({ theme }) => theme.colors.PrimaryTextLight};
  transition: all 0.15s ease;

  ${({ appsLayout }) =>
    appsLayout === ContentLayout.FLUID &&
    css`
      bottom: 0;
      right: 0;
    `}
`;

export const FavoriteIcon = styled.div<{
  processHover: boolean;
  showIosOptions: boolean;
  appsLayout: ContentLayout;
  isFavorite: boolean;
}>`
  opacity: ${({ isFavorite, showIosOptions }) =>
    isFavorite || showIosOptions ? 1 : 0};
  visibility: ${({ isFavorite, showIosOptions }) =>
    isFavorite || showIosOptions ? 'visible' : 'hidden'};
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  color: ${p => p.theme.colors.FavoriteIcon};
  cursor: pointer;

  ${({ appsLayout }) =>
    appsLayout === ContentLayout.FLUID &&
    css`
      top: ${({ theme }) => theme.spacing.xxs};
      right: ${({ theme }) => theme.spacing.xxs};
    `}

  ${p =>
    p.processHover &&
    css`
      &:hover svg {
        color: ${p => p.theme.colors.FavoriteIconHover};
      }
    `}

  ${p =>
    !p.processHover &&
    css`
      color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    `}
`;
