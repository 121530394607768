import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  ACTIVATE_ACCOUNT_PATHNAME,
  EXTERNAL_REGISTRATION_PATHNAME,
  LINK_ACCOUNT_PATHNAME,
} from '../../Onboarding/Onboarding.constants';
import { useCurrentAccount } from '../Auth.hooks';
import { NotificationsContext } from '../../Notifications/Notifications.context';
import {
  LOGOUT_PATHNAME,
  MFA_SESSION_EXPIRED_TIME,
  MFA_SESSION_EXPIRED_TIME_WITH_OFFSET,
  MFA_SESSION_STARTED_STORAGE_KEY,
} from '../Auth.constants';
import { SignUpFlow, SignUpStep } from '../../Onboarding/Onboarding.types';
import { InboxContext } from '../../Inbox/Inbox.context';
import { shouldLogAuthToSentry } from '../../../shared/utils/logging.utils';
import { captureMessage } from '../../ErrorInterceptor';
import { AppShellSkeleton } from '../../AppRoot/AppShellSkeleton';
import { getCookie } from '../../../shared/utils/storage';
import { addMilliseconds, isFuture, isPast, parseISO } from 'date-fns';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';

export const PrivateArea: FC<PropsWithChildren> = ({ children }) => {
  const { account } = useCurrentAccount();
  const { pathname, search } = useLocation();

  // Notifications setup
  const [open, setOpen] = useState<boolean>(false);
  const [keepOpen, setKeepOpen] = useState<boolean>(false);
  const [isInboxOpened, setInboxOpened] = useState<boolean>(false);
  const [keepInboxOpen, setKeepInboxOpen] = useState<boolean>(false);

  const toggleNotifications = useCallback(() => {
    setOpen(opened => !opened);
  }, []);

  const closeNotifications = useCallback(() => {
    setOpen(false);
  }, []);

  const keepNotificationsOpen = useCallback((isOpen: boolean) => {
    setKeepOpen(isOpen);
  }, []);

  const toggleInbox = useCallback(() => {
    setInboxOpened(opened => !opened);
  }, []);

  const closeInbox = useCallback(() => {
    setInboxOpened(false);
  }, []);

  const handleKeepInboxOpen = useCallback((isOpen: boolean) => {
    setKeepInboxOpen(isOpen);
  }, []);

  const InboxControls = useMemo(
    () => ({
      toggleInbox,
      closeInbox,
      keepInboxOpen: handleKeepInboxOpen,
      isOpen: isInboxOpened,
      keepOpen: keepInboxOpen,
    }),
    [
      closeInbox,
      handleKeepInboxOpen,
      isInboxOpened,
      keepInboxOpen,
      toggleInbox,
    ],
  );

  const NotificationsControls = useMemo(
    () => ({
      toggleNotifications,
      closeNotifications,
      keepNotificationsOpen,
      isOpen: open,
      keepOpen: keepOpen,
    }),
    [
      closeNotifications,
      keepNotificationsOpen,
      keepOpen,
      open,
      toggleNotifications,
    ],
  );

  if (account === null) {
    if (shouldLogAuthToSentry()) {
      captureMessage('[PrivateArea] redirect to LogoutPage (no account)');
    }
    const mfaSessionStartedCookieValue = getCookie(
      MFA_SESSION_STARTED_STORAGE_KEY,
    );
    const mfaSessionStarted =
      mfaSessionStartedCookieValue && parseISO(mfaSessionStartedCookieValue);
    const displayMfaSessionTimeoutMessage =
      mfaSessionStarted &&
      isPast(addMilliseconds(mfaSessionStarted, MFA_SESSION_EXPIRED_TIME)) &&
      isFuture(
        addMilliseconds(
          mfaSessionStarted,
          MFA_SESSION_EXPIRED_TIME_WITH_OFFSET,
        ),
      );

    return (
      <Navigate
        to={{
          pathname: LOGOUT_PATHNAME,
          ...(displayMfaSessionTimeoutMessage
            ? {
                search: getQueryParamsFrom({
                  displayMfaSessionTimeoutMessage: true,
                  sendLogoutMessageToOtherTabs: true,
                }),
              }
            : {}),
        }}
      />
    );
  }

  if (!account) {
    return <AppShellSkeleton data-skeletonId="PrivateArea_account_null" />;
  }

  // We have an account, but email not verified
  if (
    account &&
    !account.verifiedEmail &&
    pathname !== ACTIVATE_ACCOUNT_PATHNAME
  ) {
    return <Navigate to={ACTIVATE_ACCOUNT_PATHNAME} />;
  }

  // We have an account created with external login provider
  if (
    account &&
    account.currentFlow === SignUpFlow.EXTERNAL_SELECT_PLAN &&
    pathname !== EXTERNAL_REGISTRATION_PATHNAME
  ) {
    return (
      <Navigate
        to={{
          pathname: EXTERNAL_REGISTRATION_PATHNAME,
          search,
        }}
      />
    );
  }

  // Redirect to LinkAccountPage if pendingLinkRequestId present in account
  if (
    account &&
    account.pendingLinkRequestId &&
    account.currentStep === SignUpStep.completed &&
    pathname !== LINK_ACCOUNT_PATHNAME
  ) {
    return (
      <Navigate
        to={
          LINK_ACCOUNT_PATHNAME +
          `?linkRequestId=${account.pendingLinkRequestId}`
        }
      />
    );
  }

  // We have valid account and can continue
  return (
    <NotificationsContext.Provider value={NotificationsControls}>
      <InboxContext.Provider value={InboxControls}>
        {children}
      </InboxContext.Provider>
    </NotificationsContext.Provider>
  );
};
