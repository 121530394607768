import React, { useState, useEffect } from 'react';

type RenderDelayProps = {
  children: React.ReactElement;
  delay?: number;
};

export const RenderDelay = ({ children, delay = 500 }: RenderDelayProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return isShown ? children : null;
};
