import React, { forwardRef } from 'react';
import {
  StyledLinkDetails,
  StyledLinkHover,
  StyledLinkHoverContent,
  StyledOpenLink,
  StyledOpenLinkInner,
} from './LinkHover.styled';
import { LinkTranslation } from '../../../i18n';
import { FormattedMessage } from 'react-intl';
import {
  EyeIcon,
  InformationIcon,
  OpenLinkIcon,
} from '../../../../../shared/icons';

type LinkHoverControlsProps = {
  visible: boolean;
  handleOpenLinkDetails: () => void;
  handleOpenLink: (e: React.SyntheticEvent) => void;
  linkOpenTarget?: string;
  isChatLink: boolean;
  displayPreviewLink: boolean;
};

export const LinkHoverControls = forwardRef<
  HTMLDivElement,
  LinkHoverControlsProps
>(
  (
    {
      visible,
      handleOpenLinkDetails,
      handleOpenLink,
      linkOpenTarget,
      isChatLink,
      displayPreviewLink = true,
    },
    ref,
  ) =>
    visible ? (
      <StyledLinkHover visible={visible} ref={ref}>
        <StyledLinkHoverContent>
          <StyledOpenLink
            target={linkOpenTarget}
            rel="noopener noreferrer"
            onClick={handleOpenLink}
            data-testid="open-link-button">
            <StyledOpenLinkInner>
              <OpenLinkIcon />

              <FormattedMessage id={LinkTranslation.linkHoverOpenLink} />
            </StyledOpenLinkInner>
          </StyledOpenLink>

          {displayPreviewLink && (
            <StyledLinkDetails
              iconFirst={true}
              onClick={handleOpenLinkDetails}
              icon={isChatLink ? EyeIcon : InformationIcon}
              data-testid="link-details-button">
              <FormattedMessage
                id={
                  isChatLink
                    ? LinkTranslation.linkMenuItemLinkPreview
                    : LinkTranslation.linkHoverLinkDetails
                }
              />
            </StyledLinkDetails>
          )}
        </StyledLinkHoverContent>
      </StyledLinkHover>
    ) : null,
);
