import React, { FC } from 'react';
import { StyledConferencesListEmptyState } from './ConferencesListEmptyState.styled';
import { ConferenceTranslation } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyConferencesListSvg from './empty-conferences-list.svg';

export const ConferencesListEmptyState: FC = () => {
  const int = useIntl();
  return (
    <StyledConferencesListEmptyState>
      <img
        className="image"
        src={EmptyConferencesListSvg}
        alt={int.formatMessage({
          id: ConferenceTranslation.conferencesListEmptyStateTitle,
        })}
      />
      <div className="heading">
        <FormattedMessage
          id={ConferenceTranslation.conferencesListEmptyStateTitle}
        />
      </div>
      <div className="subheading">
        <FormattedMessage
          id={ConferenceTranslation.conferencesListEmptyStateSubtitle}
        />
      </div>
    </StyledConferencesListEmptyState>
  );
};
