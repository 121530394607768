import styled from 'styled-components';

export const StyledEmptyState = styled.div<{}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 36.25rem;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: center;
`;

export const EmptyImage = styled.img`
  margin-bottom: 3.5rem;
`;

export const EmptyHeader = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  ${({ theme: { breakpoints, fonts } }) => breakpoints.md`
    font-size: 1.5rem;
    font-family: ${fonts.Regular};
  `}
`;

export const EmptyStateTitle = styled.div`
  font-size: 1.5rem;
`;

export const EmptyHeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 36.25rem;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: center;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const StyledDivider = styled.div`
  height: 10rem;
  background: ${p => p.theme.colors.Divider};
  padding-left: 1px;
  margin: 0 3.5rem 3rem 3.5rem;
`;

export const StyledEmptyStateDesktopVersion = styled.div`
  height: 18.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 2rem auto 0 auto;
`;

export const StyledEmptyStateHelpText = styled.div`
  margin-top: 2rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${p => p.theme.fonts.Medium};
  .empty-state-onboarding {
    color: ${p => p.theme.colors.Primary};
    cursor: pointer;
  }
  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const StyledMessageDesktopVersion = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
  margin: 1.625rem 0;
  text-align: center;
`;

export const StyledMessage = styled.div<{}>`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 1.125rem;
  margin-bottom: 2.5rem;
  text-align: center;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 13.75rem;
  height: 15rem;
`;

export const StyledPlusIconWrapper = styled.div`
  padding: 1.063rem 1.188rem;
  border-radius: 0.5rem;
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  margin-bottom: 2.7rem;
`;

export const StyledMessageInstructions = styled.div<{}>`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
  margin-top: 1rem;
`;

export const ImportWarning = styled.div`
  max-width: 21.25rem;
  margin-top: 4.375rem;
  text-align: left;
  align-items: center;
`;
