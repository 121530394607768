import React, { FC } from 'react';
import { Modal, ModalHeader } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { FolderTranslation } from '../../i18n';
import { EditFolder } from '../../FolderForm';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';

const closeModalDelay = 30;

export const EditFolderModal: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const onRequestClose = () => {
    setTimeout(() => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          editFolderId: undefined,
        }),
      });
    }, closeModalDelay);
  };

  return (
    <Modal visible={true} width={588} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={FolderTranslation.editFolderModalHeader} />
      </ModalHeader>
      <EditFolder onDone={onRequestClose} />
    </Modal>
  );
};
