import { Ref, RefObject } from 'react';
import { RefCallback } from 'use-callback-ref/dist/es5/types';

// source: https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
export const mergeRefs = (
  ...refs: Array<RefObject<any> | RefCallback<any> | Ref<any>>
) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: RefObject<any> | RefCallback<any> | Ref<any> | null) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        // @ts-ignore
        ref.current = inst;
      }
    }
  };
};
