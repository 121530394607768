import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ActivateWorkspaceInputType } from '../../../Workspace.types';
import { WorkspaceTranslation } from '../../../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormInput } from '../../../../../shared/components/Input';
import { StyledIdentifierContainer } from './RegisterFreeProWorkspaceForm.styled';
import { RegisterWorkspaceFormSubmitHandler } from '../ActivateWorkspaceForm.types';
import { appEnv } from '../../../../../appEnv';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { FormInputWithText } from '../../../../../shared/components/InputWithText';
import { SignUpFlow } from '../../../../Onboarding/Onboarding.types';
import { OnboardingTranslation } from '../../../../Onboarding/i18n';
import { OnboardingStepText } from '../../../../Onboarding/OnboardingPage/OnboardingPage.styled';
import { InformationIcon } from '../../../../../shared/icons';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';

interface RegisterFreeProWorkspaceFormProps {
  onSubmit: RegisterWorkspaceFormSubmitHandler;
  handleChangeWorkspaceName: (name: string) => void;
}

const FormFields = styled.div`
  & > div ~ div {
    margin-top: 1.625rem;
  }
`;

const FormControls = styled.div`
  margin-top: 2rem;
`;

export const RegisterFreeProWorkspaceForm: FC<
  RegisterFreeProWorkspaceFormProps
> = ({ onSubmit, handleChangeWorkspaceName }) => {
  const intl = useIntl();
  const { account } = useCurrentAccount();
  const workspaceDomain = `.${appEnv.WORKSPACE_DOMAIN}`;
  const isFree = account?.currentFlow === SignUpFlow.FREE;

  const RegisterFreeProWorkspaceFormSchema: Yup.Schema<ActivateWorkspaceInputType> =
    Yup.object().shape({
      name: Yup.string().required(WorkspaceTranslation.formNameRequiredError),
      ...(!isFree
        ? {
            identifier: Yup.string().required(
              WorkspaceTranslation.formIdentifierRequiredError,
            ),
          }
        : {}),
    });

  const initialValues: ActivateWorkspaceInputType = {
    name: '',
    ...(!isFree ? { identifier: '' } : {}),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterFreeProWorkspaceFormSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({ isSubmitting, isValid, values, dirty }) => {
        handleChangeWorkspaceName(values.name);

        return (
          <Form data-testid="workspace-form">
            <FormFields>
              <Field
                name="name"
                type="text"
                data-testid="workspace-form-name-input"
                label={intl.formatHTMLMessage({
                  id: WorkspaceTranslation.formNameLabel,
                })}
                labelIcon={() => (
                  <>
                    <InformationIcon
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={intl.formatMessage({
                        id: WorkspaceTranslation.tooltipFormNameLabel,
                      })}
                      data-tooltip-place={TooltipPlace.top}
                      width={16}
                      height={16}
                    />
                  </>
                )}
                component={FormInput}
                autoFocus
              />

              {!isFree && (
                <StyledIdentifierContainer>
                  <Field
                    name="identifier"
                    type="text"
                    data-testid="workspace-form-identifier-input"
                    label={
                      <FormattedHTMLMessage
                        id={WorkspaceTranslation.formIdentifierLabel}
                      />
                    }
                    text={workspaceDomain}
                    labelIcon={() => (
                      <>
                        <InformationIcon
                          data-tooltip-content={intl.formatMessage({
                            id: WorkspaceTranslation.tooltipFormIdentifierLabel,
                          })}
                          data-tooltip-place={TooltipPlace.top}
                          width={16}
                          height={16}
                        />
                      </>
                    )}
                    component={FormInputWithText}
                  />
                </StyledIdentifierContainer>
              )}
            </FormFields>

            <OnboardingStepText>
              <FormattedHTMLMessage
                id={OnboardingTranslation.onboardingCreateWorkspaceDescription}
              />
            </OnboardingStepText>

            <OnboardingStepText>
              <FormattedHTMLMessage
                id={OnboardingTranslation.onboardingCreateWorkspaceUrl}
              />
            </OnboardingStepText>

            <FormControls>
              <Button
                data-testid="workspace-form-submit-button"
                type="submit"
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                disabled={!isValid || isSubmitting || !dirty}
                fullWidth>
                <FormattedMessage
                  id={WorkspaceTranslation.formContinueButton}
                />
              </Button>
            </FormControls>
          </Form>
        );
      }}
    </Formik>
  );
};
