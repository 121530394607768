import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import {
  TimelineEventScope,
  TimelineEventAction,
} from '../../Timeline.constants';
import { TimelineEventApiType } from '../../Timeline.types';

interface TimelineEventUndoTitleProps {
  event: TimelineEventApiType;
}

export const TimelineEventUndoTitle: FC<TimelineEventUndoTitleProps> = ({
  event,
}) => {
  const { context } = event;

  const renderTimelineEventUndoTitle = () => {
    switch (event.scopeType) {
      case TimelineEventScope.desktops:
        switch (event.action) {
          case TimelineEventAction.remove_app_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventAppSectionRestoreTitle}
                values={{
                  appsNumber: context.removed_items_count,
                }}
              />
            );
          case TimelineEventAction.remove_link_section_from_desktop:
            return (
              <FormattedMessage
                id={TimelineTranslation.eventLinkSectionRestoreTitle}
                values={{
                  linksNumber: context.removed_items_count,
                }}
              />
            );
        }
        break;
      case TimelineEventScope.apps:
        switch (event.action) {
          case TimelineEventAction.remove_custom_from_desktop:
            return event.context.app_name;
        }
        break;
      case TimelineEventScope.links:
        switch (event.action) {
          case TimelineEventAction.remove:
            return event.context.link_name || event.context.link_url;
        }
        break;

      default:
        return null;
    }
  };

  return <>{renderTimelineEventUndoTitle()}</>;
};
