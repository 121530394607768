import styled from 'styled-components';

export const StyledIncomingCallToast = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TextBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  margin-left: 0.75rem;

  b {
    font-family: ${p => p.theme.fonts.Semibold};
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const CallButton = styled.button<{
  backgroundColor: string;
  rotateIcon?: boolean;
}>`
  appearance: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${p => p.backgroundColor};
  color: ${p => p.theme.colors.PrimaryTextLight};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: opacity 0.2s ease;

  svg {
    transform: ${p => (p.rotateIcon ? 'rotate(135deg)' : 'none')};
  }

  & + & {
    margin-left: 0.75rem;
  }

  &:hover,
  &:disabled {
    opacity: 0.4;
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    opacity: 0.12;
  }
`;
