import React, { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import { useQueryParams } from '../../hooks';
import { getQueryParamsFrom } from '../../utils/url.utils';
import {
  InputWithIcon,
  InputWithIconProps,
} from '../InputWithIcon/InputWithIcon';
import { CloseIcon, SearchIcon } from '../../icons';
import { GlobalTranslation } from '../../../domains/Intl/i18n';
import { ClearSearchFieldButton, StyledSearch } from './Search.styled';
import { ButtonSize } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

export const SEARCH_DEFAULT_DEBOUNCE_TIME = 500;

interface SearchProps extends Omit<InputWithIconProps, 'icon'> {
  queryParamName?: string;
  debounceTime?: number;
  displayClearButtonWhenHasInput?: boolean;
}

export const Search: FC<SearchProps> = ({
  queryParamName,
  debounceTime = SEARCH_DEFAULT_DEBOUNCE_TIME,
  displayClearButtonWhenHasInput = true,
  ...props
}) => {
  const queryParams = useQueryParams();
  const queryParam = queryParamName && queryParams[queryParamName];
  const navigate = useNavigate();
  const intl = useIntl();

  const [searchValue, setSearchValue] = useState<string>(
    (queryParam as string) || '',
  );
  const [debouncedSearchValue] = useDebounce(searchValue, debounceTime);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!queryParamName) {
      return;
    }

    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        [queryParamName]: debouncedSearchValue || undefined,
      }),
    });
  }, [debouncedSearchValue, queryParamName]); // eslint-disable-line

  useEffect(() => {
    if (!queryParamName) {
      return;
    }

    setSearchValue((queryParam as string) || '');
  }, [queryParam, queryParamName]);

  const clearSearchInput = () => {
    if (!queryParamName) {
      return;
    }
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        [queryParamName]: undefined,
      }),
    });
  };

  return (
    <StyledSearch>
      <InputWithIcon
        data-testid="search"
        icon={() => <SearchIcon width={12} height={12} />}
        value={searchValue}
        placeholder={intl.formatMessage({
          id: GlobalTranslation.searchInputPlaceholder,
        })}
        aria-label={intl.formatMessage({
          id: GlobalTranslation.searchInputLabel,
        })}
        onChange={e => setSearchValue(e.target?.value)}
        {...props}
      />
      {displayClearButtonWhenHasInput && searchValue?.length > 1 && (
        <ClearSearchFieldButton
          data-testid="clear-search-button"
          icon={CloseIcon}
          aria-label={intl.formatMessage({
            id: GlobalTranslation.searchInputClearButtonAriaLabel,
          })}
          size={ButtonSize.sm}
          onClick={clearSearchInput}
        />
      )}
    </StyledSearch>
  );
};
