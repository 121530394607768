import { getDesktopType } from '../../Desktop.utils';
import { DesktopType } from '../../Desktop.constants';
import type { DesktopApiType } from '../../data/Desktop/types/Desktop.types';

export const splitDesktopsIntoLists = (allDesktops: DesktopApiType[]) =>
  allDesktops.reduce<{
    internalDesktops: DesktopApiType[];
    sharedDesktops: DesktopApiType[];
    privateDesktops: DesktopApiType[];
  }>(
    (acc, item) => {
      const desktopType = getDesktopType(item);
      if (desktopType === DesktopType.INTERNAL) {
        acc.internalDesktops.push(item);
      } else if (desktopType === DesktopType.SHARED) {
        acc.sharedDesktops.push(item);
      } else {
        acc.privateDesktops.push(item);
      }
      return acc;
    },
    { internalDesktops: [], sharedDesktops: [], privateDesktops: [] },
  );
