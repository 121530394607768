import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { ErrorMessage } from '../../../shared/components/Input';
import { BillingCvcIcon } from '../../../shared/icons/BillingCvc';
import { BillingVisaIcon } from '../../../shared/icons/BillingVisa';
import { BillingMasterCardIcon } from '../../../shared/icons/BillingMasterCard';
import { BillingAmexIcon } from '../../../shared/icons/BillingAmex';
import { stripeErrorCodeToMessage } from '../Billing.utils';

import {
  StyledCardCvcContainer,
  StyledCardDetails,
  StyledCardExpiryContainer,
  StyledCardFieldIcons,
  StyledCardNumberContainer,
} from './CreditCardDetailsInput.styled';
import {
  sharedCardClasses,
  sharedCardStyles,
} from './CreditCardDetailsInput.constants';
import { getCurrentTheme, LightTheme } from '../../Theme';
import { FieldClusterTitle } from '../../../shared/components/FieldCluster';

interface CreditCardDetailsInputProps {
  label?: string | null;
  disabled?: boolean;
  withCurrentTheme?: boolean;
}

export const CreditCardDetailsInput: FC<CreditCardDetailsInputProps> = ({
  label = null,
  disabled = false,
  withCurrentTheme = true,
}) => {
  const [numberError, setNumberError] = useState<string | null>(null);
  const [expiryError, setExpiryError] = useState<string | null>(null);
  const [cvcError, setCvcError] = useState<string | null>(null);
  const currentTheme = getCurrentTheme();
  const currentCardStyles = sharedCardStyles(
    withCurrentTheme ? currentTheme : LightTheme,
  );

  const CARD_NUMBER_OPTIONS = {
    disabled: disabled,
    classes: { base: 'CardNumber', ...sharedCardClasses },
    style: currentCardStyles,
  };

  const CARD_EXPIRY_OPTIONS = {
    disabled: disabled,
    classes: { base: 'CardExpiry', ...sharedCardClasses },
    style: currentCardStyles,
  };

  const CARD_CVC_OPTIONS = {
    disabled: disabled,
    classes: { base: 'CardCvc', ...sharedCardClasses },
    style: currentCardStyles,
  };

  const didChangeNumber = (e: StripeCardNumberElementChangeEvent) => {
    setNumberError(e.error ? stripeErrorCodeToMessage(e.error.code) : null);
  };

  const didChangeExpiry = (e: StripeCardExpiryElementChangeEvent) => {
    setExpiryError(e.error ? stripeErrorCodeToMessage(e.error.code) : null);
  };

  const didChangeCvc = (e: StripeCardCvcElementChangeEvent) => {
    setCvcError(e.error ? stripeErrorCodeToMessage(e.error.code) : null);
  };

  return (
    <div data-testid="credit-card-details-panel">
      {label && (
        <FieldClusterTitle data-testid="label">
          <FormattedMessage id={label} />
        </FieldClusterTitle>
      )}
      <StyledCardDetails>
        <StyledCardNumberContainer data-testid="card-number-container">
          <StyledCardFieldIcons>
            <BillingVisaIcon />
            <BillingMasterCardIcon />
            <BillingAmexIcon />
          </StyledCardFieldIcons>
          <CardNumberElement
            options={CARD_NUMBER_OPTIONS}
            onChange={didChangeNumber}
          />
        </StyledCardNumberContainer>
        <StyledCardExpiryContainer data-testid="card-expiry-container">
          <CardExpiryElement
            options={CARD_EXPIRY_OPTIONS}
            onChange={didChangeExpiry}
          />
        </StyledCardExpiryContainer>
        <StyledCardCvcContainer data-testid="card-cvc-container">
          <StyledCardFieldIcons>
            <BillingCvcIcon />
          </StyledCardFieldIcons>
          <CardCvcElement options={CARD_CVC_OPTIONS} onChange={didChangeCvc} />
        </StyledCardCvcContainer>
      </StyledCardDetails>
      {numberError && (
        <ErrorMessage data-testid="card-error-number">
          <FormattedMessage id={numberError} />
        </ErrorMessage>
      )}
      {expiryError && (
        <ErrorMessage data-testid="card-error-expiry">
          <FormattedMessage id={expiryError} />
        </ErrorMessage>
      )}
      {cvcError && (
        <ErrorMessage data-testid="card-error-cvc">
          <FormattedMessage id={cvcError} />
        </ErrorMessage>
      )}
    </div>
  );
};
