import React, { FC, useMemo } from 'react';
import { StyledVideoIframe } from './VideoPlayer.styled';

interface IframePlayerProps {
  width?: string;
  height?: string;
  source: string;
  title?: string;
  autoPlay?: boolean;
  fullScreen?: boolean;
}

const IframePlayer: FC<IframePlayerProps> = ({
  width = '100%',
  height = '100%',
  title,
  source,
  autoPlay,
}) => {
  const preparedSource = useMemo(() => {
    const videoUrl = new URL(`${source}`);

    if (!videoUrl.searchParams.get('autoplay') && autoPlay) {
      return `${videoUrl.href}?autoplay=1`;
    }

    return videoUrl.href;
  }, [autoPlay, source]);

  return (
    <StyledVideoIframe
      allowFullScreen={true}
      width={width}
      height={height}
      src={preparedSource}
      title={title}
      allow="autoplay"
    />
  );
};

export default IframePlayer;
