import styled from 'styled-components';

export const StyledOnboardingCountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  div {
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 0.1875rem 0.25rem 0 0.25rem;
    color: ${p => p.theme.colors.Primary};
    background-color: ${p => p.theme.colors.PrimaryLightest};
  }
`;
