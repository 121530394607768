import React, { FC, useCallback, useMemo, useState } from 'react';
import { isProbablyReaderable, Readability } from '@mozilla/readability';
import { PreviewFrame } from './ReaderPreview.styled';
import { LinkApiType } from '../../Link/Link.types';
import { useAuthService } from '../../Auth/Auth.hooks';
import { UnreadableFallback } from './UnreadableFallback/UnreadableFallback';
import { tidyDocument } from './ReaderPreview.utils';
import DOMPurify from 'dompurify';
import { getShortId } from '../../../shared/utils/id';

type ReaderPreviewProps = {
  link: LinkApiType;
};

export const ReaderPreview: FC<ReaderPreviewProps> = ({ link }) => {
  const [readableContent, setReadableContent] = useState<string | undefined>();
  const [didGetToken, setDidGetToken] = useState(false);
  const [isUnreadable, setIsUnreadable] = useState(false);

  /**
   * Get the link document
   */
  const fetchLinkDocument = useCallback(
    async (token: string) => {
      try {
        const previewUrl = `${process.env.READER_URL}?link_id=${getShortId(
          link.id,
        )}`;
        const response = await fetch(previewUrl, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        });

        const sanitizedDom = DOMPurify.sanitize(await response.text(), {
          USE_PROFILES: { html: true },
        });

        const fetchedDocument = document.implementation.createHTMLDocument();
        fetchedDocument.write(sanitizedDom);

        if (isProbablyReaderable(fetchedDocument)) {
          const tidiedDocument = tidyDocument(fetchedDocument);
          const readabilityArticle = new Readability(tidiedDocument).parse();

          if (!readabilityArticle?.content) {
            setIsUnreadable(true);
          } else {
            setReadableContent(readabilityArticle.content);
          }
        } else {
          setIsUnreadable(true);
        }
      } catch (error) {
        setIsUnreadable(true);
        console.error(`Link reader error: ${error}`);
      }
    },
    [link.id],
  );

  /**
   * Get token for reader url
   */
  const authService = useAuthService();
  useMemo(() => {
    if (!didGetToken) {
      authService?.getToken().then(token => {
        if (!token) {
          setIsUnreadable(true);
          return null;
        }
        setDidGetToken(true);
        fetchLinkDocument(token);
      });
    }
  }, [authService, didGetToken, fetchLinkDocument]);

  if (isUnreadable) {
    return <UnreadableFallback link={link} />;
  }

  if (!isUnreadable && readableContent) {
    return (
      <PreviewFrame dangerouslySetInnerHTML={{ __html: readableContent }} />
    );
  }

  return null;
};
