import React, { FC, memo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { IconProps } from '../../icons/icon.types';
import { FormInputLabel, Input, InputProps } from '../Input';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';

export interface InputWithIconProps extends InputProps {
  icon?: FC<IconProps>;
  label?: string;
  reverseIcon?: boolean;
  iconOnClick?: () => void;
}

export const StyledInputWithIcon = styled.div<{ reverseIcon?: boolean }>`
  position: relative;

  > svg,
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    ${p =>
      p.reverseIcon
        ? css`
            right: 10px;
          `
        : css`
            left: 10px;
          `}
  }

  svg + input {
    ${p =>
      p.reverseIcon
        ? css`
            padding-right: 35px;
          `
        : css`
            padding-left: 35px;
          `}
    &:focus {
      ${p =>
        p.reverseIcon
          ? css`
              padding-right: 29px;
            `
          : css`
              padding-left: 29px;
            `}
    }
  }
  button + input {
    ${p =>
      p.reverseIcon
        ? css`
            padding-right: 45px;
          `
        : css`
            padding-left: 45px;
          `}
    &:focus {
      ${p =>
        p.reverseIcon
          ? css`
              padding-right: 44px;
            `
          : css`
              padding-left: 44px;
            `}
    }
  }
`;

export const InputWithIcon = memo(
  React.forwardRef<HTMLInputElement, InputWithIconProps>(
    (
      {
        icon: Icon,
        size,
        label,
        reverseIcon,
        children,
        iconOnClick = null,
        ...inputProps
      },
      ref,
    ) => {
      const handleOnClickIcon = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          iconOnClick && iconOnClick();
        },
        [iconOnClick],
      );

      return (
        <div className="input-container">
          {label && (
            <FormInputLabel htmlFor={inputProps.name}>{label}</FormInputLabel>
          )}
          <StyledInputWithIcon reverseIcon={reverseIcon}>
            {Icon && iconOnClick ? (
              <ButtonWithIcon onClick={handleOnClickIcon} icon={Icon} />
            ) : (
              Icon && <Icon />
            )}
            <Input size={size} ref={ref} {...inputProps} />
            {children}
          </StyledInputWithIcon>
        </div>
      );
    },
  ),
);
