import { database } from '../../../../Database';

export const getAccountsByRelatedTeamIdIDDB = (teamId: string) => {
  try {
    return database.accounts.where('relatedTeams').equals(teamId).toArray();
  } catch (e) {
    console.error(e);
    return Promise.resolve([]);
  }
};
