import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { WorkspaceDomainApiType } from '../../Domain.types';
import { EditDomainForm } from '../../DomainForms/EditDomainForm';
import { DomainTranslation } from '../../i18n';

interface EditDomainModalProps extends ModalProps {
  currentDomain: WorkspaceDomainApiType;
  onDone: () => void;
}

export const EditDomainModal: FC<EditDomainModalProps> = ({
  currentDomain,
  onRequestClose,
  onDone,
  ...props
}) => {
  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={DomainTranslation.adminListEditModalHeader} />
      </ModalHeader>
      <EditDomainForm
        currentDomain={currentDomain}
        onDone={onDone}
        onRequestClose={onRequestClose}
      />
    </Modal>
  );
};
