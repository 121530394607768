import React, { FC, memo, useContext, useMemo } from 'react';
import { AvatarImage } from '../../../ChatAvatar/AvatarImage';
import { MAX_AUTHORS_TO_VIEW_COUNT } from './ChatMessageThread.constants';
import { ChatMessageThreadContext } from './ChatMessageThread.context';
import {
  ChatMessageThreadViewAuthorAvatarPlaceholderStyled,
  ChatMessageThreadViewAuthorsStyled,
  ChatMessageThreadViewAuthorStyled,
} from './ChatMessageThread.styled';
import { ChatMessageViewThreadViewAuthorsCounter } from './ChatMessageThreadViewAuthorsCounter';

export const ChatMessageThreadViewAuthors: FC = memo(() => {
  const { threadMessageAuthors } = useContext(ChatMessageThreadContext);

  const showThreadViewAuthorsCounter =
    threadMessageAuthors.length - MAX_AUTHORS_TO_VIEW_COUNT > 0;

  const authorsToRender = useMemo(
    () => threadMessageAuthors.slice(0, MAX_AUTHORS_TO_VIEW_COUNT),
    [threadMessageAuthors],
  );

  return (
    <ChatMessageThreadViewAuthorsStyled data-testid="chat_message_thread_view_author">
      {authorsToRender.map(author => (
        <ChatMessageThreadViewAuthorStyled key={`thread_author_${author.id}`}>
          {author.image?.contentUrl ? (
            <AvatarImage account={author} />
          ) : (
            <ChatMessageThreadViewAuthorAvatarPlaceholderStyled
              account={author}
            />
          )}
        </ChatMessageThreadViewAuthorStyled>
      ))}

      {showThreadViewAuthorsCounter ? (
        <ChatMessageViewThreadViewAuthorsCounter
          count={threadMessageAuthors.length - MAX_AUTHORS_TO_VIEW_COUNT}
        />
      ) : null}
    </ChatMessageThreadViewAuthorsStyled>
  );
});
