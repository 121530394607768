import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ArrowRightIcon } from '../../../shared/icons';
import { AppRootTranslation } from '../../AppRoot/i18n';
import {
  CloseButton,
  DEFAULT_POSITION,
} from '../../../shared/components/Toast';
import InstallApp from '../../../shared/assets/images/install-app.svg';
import { IS_PWA_TOAST_SHOWN } from '../../AppRoot/AppRoot.constants';
import { useInstallAppEvent } from '../InstallApp.hooks';

const INSTALL_APP_TOAST_ID = 'install-app-toast-id';

const StyledInstallAppToast = styled.div`
  display: flex;
  font-size: 0.875rem;
  align-items: flex-start;
  p {
    margin-bottom: 0.125rem;
    line-height: 1.25;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
    &:first-child {
      font-family: ${({ theme }) => theme.fonts.Semibold};
      color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    }
  }

  button {
    margin-top: 0.375rem;
    font-size: 0.75rem;
    color: ${p => p.theme.colors.Primary};
    font-family: ${p => p.theme.fonts.Semibold};
    &:hover svg {
      margin-left: 0.625rem;
    }
    span {
      flex-shrink: 0;
    }
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
`;

const InstallAppToastLabel = styled.span`
  display: flex;
  margin-top: 0.375rem;
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 0.75rem;
  color: ${p => p.theme.colors.Primary};
  svg {
    margin-left: 0.375rem;
    transition: all 0.3s ease;
  }
`;

export const InstallAppToast: FC<{ active: boolean }> = ({ active }) => {
  const { installEvent, clearInstallEvent } = useInstallAppEvent();

  const installPWA = useCallback(() => {
    if (!installEvent) {
      return;
    }

    installEvent
      .prompt()
      .catch(() => {})
      .finally(clearInstallEvent);
  }, [installEvent, clearInstallEvent]);

  if (!active || !!sessionStorage.getItem(IS_PWA_TOAST_SHOWN)) {
    return null;
  }

  if (installEvent) {
    toast(
      <StyledInstallAppToast onClick={installPWA}>
        <img src={InstallApp} alt="" />
        <div>
          <p>
            <FormattedMessage id={AppRootTranslation.installAppToastTitle} />
          </p>
          <p>
            <FormattedMessage
              id={AppRootTranslation.installAppToastDescription}
            />
          </p>
          <InstallAppToastLabel>
            <FormattedMessage
              id={AppRootTranslation.installAppToastLinkLabel}
            />
            <ArrowRightIcon width={12} height={12} />
          </InstallAppToastLabel>
        </div>
      </StyledInstallAppToast>,
      {
        hideProgressBar: true,
        position: DEFAULT_POSITION,
        closeButton: <CloseButton />,
        autoClose: false,
        toastId: INSTALL_APP_TOAST_ID,
        onClose: () => sessionStorage.setItem(IS_PWA_TOAST_SHOWN, 'true'),
      },
    );
  }

  return null;
};
