import { gql } from '@apollo/client';
import { AuthConfig } from './Auth.types';
import { AccountApiType } from '../User/User.types';

export const AUTH_CONFIG = `
  mutation AuthConfig($input: authConfigInput!) {
    authConfig(input: $input) {
      config {
        domain
        clientId
        audience
      }
    }
  }
`;

export interface AuthConfigResponse {
  authConfig: {
    config: AuthConfig;
  };
}

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($input: activateAccountInput!) {
    activateAccount(input: $input) {
      account {
        id
        _id
        identityId
        email
        firstName
        lastName
        verifiedEmail
        createdWorkspace
        currentFlow
      }
    }
  }
`;

export interface ActivateAccountVariables {
  input: {
    id: string;
    activationHash: string;
  };
}

export interface ActivateAccountResponse {
  activateAccount: {
    account: AccountApiType;
  };
}
