import React, { FC, useCallback, useMemo } from 'react';
import { GeneralSettingsForm } from './GeneralSettingsForm';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { GlobalTranslation } from '../../../Intl/i18n';
import { GeneralSettingsFormValues } from '../../GeneralSettings.types';
import { useUpdateGeneralSettingsMutation } from '../../GeneralSettings.hooks';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { ThemeType, useTheme } from '../../../Theme';
import { FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { useConfirm } from '../../../../shared/components/Modal';
import { GeneralSettingsTranslation } from '../../i18n';
import { EditGeneralSettingsConfirmationControls } from './EditGeneralSettings.styled';

export const EditGeneralSettings: FC = () => {
  const { account, refetchAccountData, updateAccountTheme } =
    useCurrentAccount();
  const [updateGeneralSettingsMutation] = useUpdateGeneralSettingsMutation();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const { setTheme } = useTheme();
  const intl = useIntl();
  const { askConfirmation } = useConfirm();

  const settings = useMemo(() => account && account.identity, [account]);

  const editGeneralSettings = useCallback(
    (
      formValues: GeneralSettingsFormValues,
      formikHelpers: FormikHelpers<{}>,
    ) => {
      const { openApp, openLink, theme, mfaRequired } = formValues;
      const lastSelectedTheme = account?.identity.theme;
      if (theme && theme !== lastSelectedTheme) {
        updateAccountTheme(theme);
      }

      return updateGeneralSettingsMutation({
        variables: {
          input: {
            ...formValues,
            openApp,
            openLink,
            theme,
            mfaRequired,
            id: account!.identityId,
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            const currentTheme =
              data.updateGlobalSettingsAccountIdentity.accountIdentity.theme;
            setTheme(currentTheme);
          }
          showToastSuccessMessage(GlobalTranslation.toastSaveMessage);
        })
        .then(() => {
          if (refetchAccountData) {
            refetchAccountData();
          }
          refetchPermissions();
        })
        .then(() => {
          formikHelpers.setSubmitting(false);
          formikHelpers.setTouched({});
        })
        .catch(e => {
          if (lastSelectedTheme) {
            updateAccountTheme(lastSelectedTheme);
          }
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [
      account,
      refetchAccountData,
      refetchPermissions,
      setTheme,
      updateAccountTheme,
      updateGeneralSettingsMutation,
    ],
  );

  const handleSubmit = useCallback(
    (
      formValues: GeneralSettingsFormValues,
      formikHelpers: FormikHelpers<{}>,
    ) => {
      const { setSubmitting, resetForm } = formikHelpers;
      const { mfaRequired } = formValues;

      if (settings && !settings?.mfaRequired && mfaRequired) {
        askConfirmation(
          <FormattedMessage
            id={GeneralSettingsTranslation.mfaActivateConfirmationMessage}
          />,
          intl.formatMessage({
            id: GeneralSettingsTranslation.mfaActivateConfirmationTitle,
          }),
          {
            width: 384,
            customControls: (onConfirm, onCancel) => (
              <EditGeneralSettingsConfirmationControls>
                <Button
                  mode={ButtonMode.secondary}
                  size={ButtonSize.md}
                  onClick={onCancel}
                  data-testid="cancel-button">
                  <FormattedMessage id={GlobalTranslation.cancel} />
                </Button>
                <Button
                  mode={ButtonMode.primary}
                  size={ButtonSize.md}
                  onClick={onConfirm}
                  data-testid="confirm-button">
                  <FormattedMessage id={GlobalTranslation.confirm} />
                </Button>
              </EditGeneralSettingsConfirmationControls>
            ),
          },
        ).then(confirm => {
          if (!confirm) {
            setSubmitting(false);
            resetForm({
              values: {
                openApp: settings.openApp,
                openLink: settings.openLink,
                mfaRequired: settings.mfaRequired,
                theme: settings.theme || ThemeType.light,
                serviceUpdatesEmailsEnabled:
                  settings.serviceUpdatesEmailsEnabled,
                productInformationEmailsEnabled:
                  settings.productInformationEmailsEnabled,
              },
            });
            return;
          }

          editGeneralSettings(formValues, formikHelpers);
        });
      } else {
        editGeneralSettings(formValues, formikHelpers);
      }
    },
    [askConfirmation, editGeneralSettings, intl, settings],
  );

  return (
    <div>
      <GeneralSettingsForm settings={settings} onSubmit={handleSubmit} />
    </div>
  );
};
