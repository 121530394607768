import React from 'react';

interface InstallAppContextType {
  installEvent?: WindowEventMap['beforeinstallprompt'];
  clearInstallEvent: () => void;
}

export const InstallAppContext = React.createContext<InstallAppContextType>({
  clearInstallEvent: () => {},
});
