import React, { FC, memo } from 'react';
import {
  DesktopSidebarIdentifier,
  useDesktopSidebar,
} from '../../Desktop/DesktopSidebar';
import { useMobile } from '../../../shared/hooks';
import { MembersModal } from '../MembersModal';
import { MembersSidebar } from '../MembersSidebar';
import { StyledMembersSidebarProps } from '../MembersSidebar/MembersSidebar.styled';

export const MembersUniversal: FC<StyledMembersSidebarProps> = memo(props => {
  const { identifier: sidebarItem } = useDesktopSidebar();
  const isMobile = useMobile();

  if (sidebarItem !== DesktopSidebarIdentifier.MEMBERS) {
    return null;
  }

  if (isMobile) {
    return <MembersModal />;
  }

  return <MembersSidebar {...props} />;
});
