import React, { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { UserTranslation } from '../../../i18n';

import {
  StyledAvatar,
  StyledDetails,
  StyledEmail,
  StyledExpires,
  StyledGroup,
  StyledInformation,
  StyledItemControls,
  StyledName,
  StyledPendingUserListItem,
} from './PendingUserListItem.styled';
import { ClockIcon } from '../../../../../shared/icons/Clock.icon';

import { GroupTag } from '../../../UserGroupTag';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../User.constants';
import { useDateFormatters } from '../../../../../shared/hooks/date.hooks';
import { isPast, parseISO } from 'date-fns';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

interface ListItemProps {
  invite: AccountInvitationApiType;
}

export const PendingUserListItem: FC<PropsWithChildren<ListItemProps>> = ({
  invite,
  children,
}) => {
  const { formatMessage } = useIntl();

  const { format, formatDistance } = useDateFormatters();

  const name = invite.recipients[0].name;
  const email = invite.recipients[0].email;
  const group = invite.groups.workspace_group;
  const expires = invite.expiresAt;

  const now = new Date();

  return (
    <StyledPendingUserListItem>
      <StyledAvatar title="Pending">
        <ClockIcon />
      </StyledAvatar>
      <StyledInformation>
        <StyledDetails>
          {name && <StyledName data-testid="name">{name}</StyledName>}
          <StyledEmail data-testid="email">{email}</StyledEmail>
        </StyledDetails>
        <StyledGroup>
          {group && (
            <>
              <GroupTag
                groupIdentifier={GroupIdentity.PENDING}
                data-testid="status">
                {formatMessage({
                  id: UserTranslation[GroupIdentity.PENDING],
                })}
              </GroupTag>
              <GroupTag groupIdentifier={group} data-testid="group">
                {formatMessage({
                  id: UserTranslation[group as GroupIdentity],
                })}
              </GroupTag>
            </>
          )}
        </StyledGroup>
        <StyledExpires
          title={format(parseISO(expires!), 'PPp')}
          data-testid="expires">
          {formatMessage(
            {
              id: isPast(parseISO(expires!))
                ? UserTranslation.adminListPendingUserExpired
                : UserTranslation.adminListPendingUserExpires,
            },
            {
              time: formatDistance(parseISO(expires!), now, {
                addSuffix: true,
              }),
            },
          )}
        </StyledExpires>
      </StyledInformation>
      <StyledItemControls>{children}</StyledItemControls>
    </StyledPendingUserListItem>
  );
};
