import React, { FC, useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { OnboardingStepHeader } from '../../OnboardingPage.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { OnboardingTranslation } from '../../../i18n';
import {
  InvitationActionPreview,
  InvitationActionsDescription,
  WorkspacePreview,
  WorkspacePreviewAvatar,
  WorkspacePreviewAvatarImage,
  WorkspacePreviewData,
  WorkspacePreviewName,
} from './InvitationAction.styled';
import { getAccountName } from '../../../../User/User.utils';
import { useWorkspacesQuery } from '../../../../Workspace/Workspace.hooks';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { Spinner } from '../../../../../shared/components/Spinner';
import { getInvitationMatchingWorkspaceAccount } from '../../../Onboarding.utils';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import { LinkAccountPreview as AccountPreview } from '../../LinkAccount/LinkAccountPreview';
import { ArrowDownIcon } from '../../../../../shared/icons/ArrowDown.icon';
import { StyledWorkspaceIcon } from '../../../../Workspace/WorkspaceIcon/WorkspaceIcon.styled';
import { StyledInitials } from '../../../../User/UserAvatar/Avatar.styled';
import { AccountInvitationApiType } from '../../../../Invitation/data/Invitation/types/Invitation.types';

interface InvitationActionsProps {
  invitation: AccountInvitationApiType;
  onAcceptWithCurrentAccount: () => void;
  onLoginWithAnotherAccount: () => void;
  onRegisterNewAccount: () => void;
}

const DEFAULT_ICON_SIZE = 48;

export const InvitationActions: FC<InvitationActionsProps> = ({
  invitation,
  onAcceptWithCurrentAccount,
  onLoginWithAnotherAccount,
  onRegisterNewAccount,
}) => {
  const { account: authenticatedAccount } = useCurrentAccount();

  // TODO: replace query here with context value after context structure change
  const { data, loading } = useWorkspacesQuery({
    variables: {
      id: authenticatedAccount!.identityId,
    },
  });
  const workspaceAccounts = useMemo(
    () => extractNodes(data?.accountIdentity.myAccounts),
    [data],
  );

  const matchingWorkspaceAccount = useMemo(() => {
    return getInvitationMatchingWorkspaceAccount(workspaceAccounts, invitation);
  }, [workspaceAccounts, invitation]);

  if (loading) {
    return <Spinner containerHeight={200} />;
  }

  return (
    <div data-testid="invitation-actions">
      <OnboardingStepHeader>
        <FormattedMessage
          id={OnboardingTranslation.onboardingInvitationPageTitle}
        />
      </OnboardingStepHeader>

      {matchingWorkspaceAccount ? (
        <InvitationActionsDescription>
          <FormattedHTMLMessage
            id={OnboardingTranslation.onboardingInvitationPageInvitationValid}
            values={{
              accountName:
                (matchingWorkspaceAccount &&
                  getAccountName(matchingWorkspaceAccount)) ||
                '',
              workspaceName: invitation?.workspaceName,
            }}
          />
        </InvitationActionsDescription>
      ) : (
        <InvitationActionsDescription>
          <ImportantMessage type={ImportantMessageType.WARNING} withIcon>
            <FormattedMessage
              id={
                OnboardingTranslation.onboardingInvitationPageInvitationNotValid
              }
            />
          </ImportantMessage>
        </InvitationActionsDescription>
      )}

      {matchingWorkspaceAccount && (
        <InvitationActionPreview>
          <AccountPreview account={matchingWorkspaceAccount} />
          <ArrowDownIcon width={12} height={16} />
          <WorkspacePreview>
            <WorkspacePreviewAvatar>
              {invitation?.workspaceImageUrl ? (
                <WorkspacePreviewAvatarImage
                  src={invitation.workspaceImageUrl}
                />
              ) : (
                <StyledWorkspaceIcon rounded={true} size={DEFAULT_ICON_SIZE}>
                  <StyledInitials size={DEFAULT_ICON_SIZE}>
                    {invitation?.workspaceName?.charAt(0)}
                  </StyledInitials>
                </StyledWorkspaceIcon>
              )}
            </WorkspacePreviewAvatar>
            <WorkspacePreviewData>
              <WorkspacePreviewName>
                {invitation?.workspaceName}
              </WorkspacePreviewName>
            </WorkspacePreviewData>
          </WorkspacePreview>
        </InvitationActionPreview>
      )}

      {matchingWorkspaceAccount ? (
        <Button
          data-testid="accept-with-current-account"
          mode={ButtonMode.primary}
          size={ButtonSize.md}
          fullWidth
          onClick={onAcceptWithCurrentAccount}>
          <FormattedMessage
            id={
              OnboardingTranslation.onboardingInvitationPageAcceptWithCurrentAccount
            }
          />
        </Button>
      ) : (
        <>
          {invitation?.accountExists ? (
            <Button
              data-testid="accept-with-another-account"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              fullWidth
              onClick={onLoginWithAnotherAccount}>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingInvitationPageAcceptWithAnotherExistingAccount
                }
              />
            </Button>
          ) : (
            <Button
              data-testid="register-new-account"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              fullWidth
              onClick={onRegisterNewAccount}>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingInvitationPageAcceptWithNewAccount
                }
              />
            </Button>
          )}
        </>
      )}
    </div>
  );
};
