import React, { FC, useMemo, useRef } from 'react';
import {
  FolderControlsButton,
  StyledFolderControls,
  StyledFolderControlsFooter,
  StyledFolderCounter,
  StyledFolderCounters,
} from './Folder.styled';
import { DotsIcon, HeartFullIcon, HeartIcon } from '../../../shared/icons';
import { FolderApiType, FolderWithLinksApiType } from '../Folder.types';
import { FoldersMenu } from './Menu';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { FolderTranslation } from '../i18n';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { DesktopTranslation } from '../../Desktop/i18n';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';

type FolderControlsTypes = {
  visible: boolean;
  onClick?: () => void;
  toggleFavoriteFolder: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  folder: FolderApiType | FolderWithLinksApiType;
  handleDeleteFolder: () => void;
  currentDesktop: DesktopApiType | string | null;
  handleOpenMenu: (isOpened: boolean) => void;
};

export const FolderControls: FC<FolderControlsTypes> = ({
  visible,
  onClick,
  folder,
  toggleFavoriteFolder,
  handleDeleteFolder,
  currentDesktop,
  handleOpenMenu,
}) => {
  const buttonRef = useRef(null);
  const intl = useIntl();

  const getFavoriteUiText = useMemo(
    (): string =>
      intl.formatMessage({
        id: folder.accountFavorite
          ? DesktopTranslation.removeItemFromFavorites
          : DesktopTranslation.addItemToFavorites,
      }),
    [folder.accountFavorite, intl],
  );

  const renderFolderCounters = useMemo(() => {
    const subFolderCount = folder.subFolderIds?.length || 0;
    const linksCount = folder.linksCount;

    if (subFolderCount === 0 && linksCount === 0) {
      return null;
    }

    return (
      <StyledFolderCounters>
        {subFolderCount > 0 ? (
          <StyledFolderCounter>
            <FormattedHTMLMessage
              id={FolderTranslation.subfolderCount}
              values={{ subfolderCount: subFolderCount }}
            />
          </StyledFolderCounter>
        ) : null}

        {linksCount > 0 ? (
          <StyledFolderCounter>
            <FormattedHTMLMessage
              id={FolderTranslation.subfolderLinksCount}
              values={{ subfolderLinksCount: linksCount }}
            />
          </StyledFolderCounter>
        ) : null}
      </StyledFolderCounters>
    );
  }, [folder.linksCount, folder.subFolderIds]);

  return (
    <StyledFolderControls visible={visible} onClick={onClick}>
      <FolderControlsButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={getFavoriteUiText}
        data-tooltip-place={TooltipPlace.top}
        data-event-off="click mouseleave"
        data-testid="favorite-icon"
        aria-label={getFavoriteUiText}
        onClick={e => {
          e.stopPropagation();
          toggleFavoriteFolder();
        }}
        icon={folder.accountFavorite ? HeartFullIcon : HeartIcon}
      />

      <StyledFolderControlsFooter>
        {renderFolderCounters}
        <FolderControlsButton
          data-tooltip-id="global-tooltip"
          data-tooltip-content={intl.formatMessage({
            id: FolderTranslation.tooltipFolderDotsButton,
          })}
          data-tooltip-place={TooltipPlace.top}
          onClick={e => {
            e.stopPropagation();
          }}
          ref={buttonRef}
          icon={DotsIcon}
          data-testid="folder-three-dots"
        />
      </StyledFolderControlsFooter>
      {currentDesktop && (
        <FoldersMenu
          handleOpenMenu={handleOpenMenu}
          trigger={buttonRef}
          handleRemoveFolder={handleDeleteFolder}
          toggleFavoriteFolder={toggleFavoriteFolder}
          folder={folder}
        />
      )}
    </StyledFolderControls>
  );
};
