import React, { FC, useCallback } from 'react';
import { useLinkCacheUpdate } from '../../Link.hooks';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { LinkFormValues } from '../../Link.types';
import { StyledCreateLink } from './CreateLink.styles';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
} from '../../../../shared/components/Toast';
import { LinkForm } from '../LinkForm';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { useQueryParams } from '../../../../shared/hooks';
import { displayInstallPluginToast } from '../../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { LINKS_PER_QUERY } from '../../LinksView/SectionLinks/SectionLinks.constants';
import { useCaseCreateLink } from '../../UseCase/createLink';
import { LinkTranslation } from '../../i18n';
import { getLongId, getShortId } from '../../../../shared/utils/id';

interface CreateLinkProps {
  onDone: () => void;
}

export const CreateLink: FC<CreateLinkProps> = ({ onDone }) => {
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();
  const queryParams = useQueryParams();
  const { folderId } = queryParams;

  const { createLinkUseCase } = useCaseCreateLink();

  const { addLinkToLinksCache } = useLinkCacheUpdate();
  const createLink = useCallback(
    (values: LinkFormValues) => {
      const { customImage, ...formValues } = values;
      return createLinkUseCase(getShortId(workspace.id), {
        desktop: desktop?.id as string,
        ...formValues,
        ...(folderId
          ? { folder: getLongId('folders', folderId as string) }
          : {}),
        ...(customImage ? { customImage } : {}),
      })
        .then(({ data, errors }) => {
          // TODO: check the errors
          if (errors) {
            const message = errors[0].message;
            if (message.split('"')[2] === ' already exists in this desktop') {
              showToastErrorMessage(LinkTranslation.linkAlreadyExistInDesktop, {
                url: formValues.url,
              });
            } else if (
              message ===
              'Unable to add link to folder. Limit of max links per folder is reached.'
            ) {
              showToastErrorMessage(LinkTranslation.linkLimitFolderReached, {
                url: formValues.url,
              });
            } else {
              showToastGraphQLErrors([...errors]);
            }
          }

          if (!data) {
            return;
          }

          // TODO: @bqk not needed with dexie?
          addLinkToLinksCache(
            {
              hasFolder: !!(queryParams.folderId as string),
              desktop: desktop?.id as string,
              desktop_workspace: workspace.id,
              filterOnlyAccessible: true,
              folderId: queryParams.folderId as string,
              last: LINKS_PER_QUERY,
            },
            data.createLink.link,
          );
        })
        .finally(() => {
          onDone();
          displayInstallPluginToast();
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    },
    [
      addLinkToLinksCache,
      createLinkUseCase,
      desktop?.id,
      folderId,
      onDone,
      queryParams.folderId,
      workspace.id,
    ],
  );

  return (
    <StyledCreateLink>
      <LinkForm onSubmit={createLink} onCancel={onDone} />
    </StyledCreateLink>
  );
};
