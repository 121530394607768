import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  useConferenceParticipantsTitle,
  useCurrentConference,
} from '../../../../Conference.hooks';
import { ConferenceTranslation } from '../../../../i18n';

export const ConferenceHeaderUsersTitle: FC = () => {
  const intl = useIntl();
  const { conference } = useCurrentConference();
  const title =
    useConferenceParticipantsTitle(conference) ||
    intl.formatMessage({
      id: ConferenceTranslation.conferencesViewEmptyConferenceTitle,
    });
  return <>{title}</>;
};
