import React from 'react';
import { StyledDesktopViewSkeleton } from './DesktopViewSkeleton.styled';
import { SkeletonBox } from '../../../../shared/components/SkeletonBox';
import { useTheme } from 'styled-components';
import { AppsViewSkeleton } from '../AppsView/AppsViewSkeleton';
import { useMobile } from '../../../../shared/hooks';

export const DesktopViewSkeleton = () => {
  const { colors } = useTheme();
  const isMobile = useMobile();

  return (
    <StyledDesktopViewSkeleton isMobile={isMobile}>
      <div className="header">
        <SkeletonBox
          className="hide-on-mobile"
          width={6.5}
          height={1.5}
          color={colors.AppsViewSkeleton}
        />
        {isMobile && (
          <div className="header-navigation">
            <div>
              <SkeletonBox
                width={1.5}
                height={1.5}
                color={colors.DesktopViewSkeleton}
                margin={[0, 0.5, 0, 0]}
              />
              <SkeletonBox
                width={4}
                height={1.5}
                color={colors.DesktopViewSkeleton}
                margin={[0, 0, 0, 0]}
              />
            </div>
            <div>
              <SkeletonBox
                width={1.5}
                height={1.5}
                color={colors.Primary}
                margin={[0, 0.5, 0, 0]}
              />
              <SkeletonBox
                width={1.5}
                height={1.5}
                color={colors.Primary}
                margin={[0, 0.5, 0, 0]}
              />
              <SkeletonBox
                width={1.5}
                height={1.5}
                color={colors.Primary}
                margin={[0, 0.5, 0, 0]}
              />
            </div>
          </div>
        )}
        <div className="spacer" />
        <SkeletonBox
          className="hide-on-mobile"
          width={5}
          height={1.75}
          borderRadius={0.25}
          margin={[0, 0, 0, 0.5]}
          color={colors.DesktopViewSkeleton}
        />
        <SkeletonBox
          className="hide-on-mobile"
          width={5}
          height={1.75}
          borderRadius={0.25}
          margin={[0, 0, 0, 0.5]}
          color={colors.DesktopViewSkeleton}
        />
        <SkeletonBox
          className="hide-on-mobile"
          width={4}
          height={1.75}
          borderRadius={0.25}
          margin={[0, 0, 0, 0.5]}
          color={colors.Primary}
        />
        <SkeletonBox
          className="hide-on-mobile"
          width={1.75}
          height={1.75}
          margin={[0, 0, 0, 0.625]}
          color={colors.DesktopViewSkeleton}
        />
        <SkeletonBox
          className="hide-on-mobile"
          width={1.75}
          height={1.75}
          margin={[0, 0, 0, 0.625]}
          color={colors.DesktopViewSkeleton}
        />
      </div>

      <AppsViewSkeleton />
    </StyledDesktopViewSkeleton>
  );
};
