import styled from 'styled-components';
import { Breakpoints } from '../../../Theme';

interface LinkProps {
  fullWidthLink?: boolean;
}

export const StyledOnboardingModal = styled.div<{
  titleAlignment?: string;
}>`
  padding: 0 1.75rem 1.5rem 1.75rem;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.Semibold};

  & > div:first-child {
    padding: 1.375rem 0;
    justify-content: ${({ titleAlignment }) => titleAlignment ?? 'center'};
    position: relative;

    button {
      position: absolute;
      right: 0;

      @media (max-width: ${Breakpoints.lg - 1}px) {
        right: -14px;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: ${p => p.theme.colors.Divider};
    margin: 0 -1.75rem;
  }
`;

export const StyledVideo = styled.iframe.attrs({
  allowfullscreen: 'allowfullscreen',
  allow: 'fullscreen',
})`
  height: 16.25rem;
  width: 100%;
`;

export const ButtonControls = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 0.875rem;
  }
`;

export const StyledCardsWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;
  flex-flow: wrap;
  justify-content: space-between;
`;

export const StyledCards = styled.a<LinkProps>`
  width: ${({ fullWidthLink }) => (fullWidthLink ? '100%' : '48%')};
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  border: solid 1px ${p => p.theme.colors.BorderDefault};
  cursor: pointer;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  padding: 0.5rem;
  justify-content: ${({ fullWidthLink }) => (fullWidthLink ? 'center' : '')};
  margin-bottom: ${({ fullWidthLink }) => (fullWidthLink ? '' : '1rem')};
`;

export const StyledIcon = styled.div<{ bgColor: string }>`
  border-radius: 50%;
  background-color: ${props => props.bgColor};
  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
`;

export const StyledCardTitle = styled.span`
  font-size: 0.75rem;
`;

export const QuickGuideSubHeaderContent = styled.div`
  font-size: 1.5rem;
  padding: 0 1.5rem 0.313rem;
  line-height: 2rem;
  letter-spacing: -0.14px;
  font-weight: 600;
`;

export const QuickGuideHeader = styled.div`
  margin-bottom: 0.5rem;

  button {
    color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};

    @media (max-width: ${Breakpoints.lg}px) {
      padding: 0.875rem;
      top: 0.5rem;
    }
  }
`;

export const QuickGuideDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.Regular};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  padding: 0 20px;
`;
