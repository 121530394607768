import styled from 'styled-components';

export const StyledSharedWithWrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 0;
  }
`;

export const StyledSharedWithList = styled.div`
  margin-top: 1rem;
`;

export const StyledSharedByTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  button {
    margin-left: 1rem;
  }
`;
