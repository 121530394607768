import React, { Dispatch, SetStateAction } from 'react';
import type { ChatMessagesTableType } from '../../../Database/ChatMessagesTable/ChatMessagesTable';
import type { SearchMessageType } from '../../Chat.context';
import type { ScrollDirection } from './ConversationView.types';

export interface ConversationViewContextProsp {
  messages: ChatMessagesTableType[];
}

interface ConverstionViewContextType {
  setMessages: (
    conversationId: string,
    messages: ChatMessagesTableType[],
  ) => void;
  messages: ChatMessagesTableType[];
  searchMessage?: SearchMessageType | undefined;
  setSearchMessage: Dispatch<SetStateAction<SearchMessageType | undefined>>;
  firstItemIndex: number | undefined;
  loadMoreMessages: (scrollDirection: ScrollDirection) => void;
  listLoading: boolean;
  loadingBefore: boolean;
  loadingAfter: boolean;
  setLoadingListState: () => void;
}

export const ConversationViewContext =
  React.createContext<ConverstionViewContextType>({
    setMessages: () => undefined,
    messages: [],
    searchMessage: undefined,
    setSearchMessage: () => {},
    firstItemIndex: 0,
    loadMoreMessages: () => {},
    listLoading: true,
    loadingBefore: false,
    loadingAfter: false,
    setLoadingListState: () => {},
  });
