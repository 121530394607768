import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LinkApiType } from '../Link.types';
import { Link } from '../LinksView/Link';
import {
  UntrustedLinkConfirmationLink,
  UntrustedLinkConfirmationReason,
  UntrustedLinkConfirmationReasonMessage,
  UntrustedLinkConfirmationText,
} from './LinkOpenConfirmation.styled';
import { LinkTranslation } from '../i18n';

interface LinkOpenConfirmationProps {
  abuseChecked: boolean;
  abuseDescription?: string;
  link?: LinkApiType;
}

export const LinkOpenConfirmation: FC<LinkOpenConfirmationProps> = ({
  abuseChecked,
  abuseDescription,
  link,
}) => {
  return (
    //TODO Remove ts-ignore
    //@ts-ignore
    <DndProvider backend={HTML5Backend}>
      {link && <Link link={link} />}
      <UntrustedLinkConfirmationText>
        <FormattedMessage
          id={
            abuseChecked
              ? LinkTranslation.untrustedLinkDescription
              : LinkTranslation.uncheckedLinkDescription
          }
        />
      </UntrustedLinkConfirmationText>
      {abuseDescription && (
        <UntrustedLinkConfirmationReason>
          <FormattedMessage
            id={LinkTranslation.untrustedLinkReason}
            values={{
              reason: (
                <UntrustedLinkConfirmationReasonMessage>
                  {abuseDescription}
                </UntrustedLinkConfirmationReasonMessage>
              ),
            }}
          />
        </UntrustedLinkConfirmationReason>
      )}
      <UntrustedLinkConfirmationLink
        href={process.env.DETECTED_ABUSE_URL}
        target="_blank">
        <FormattedMessage
          id={
            abuseChecked
              ? LinkTranslation.untrustedLinkInformationLink
              : LinkTranslation.uncheckedLinkInformationLink
          }
        />
      </UntrustedLinkConfirmationLink>
    </DndProvider>
  );
};
