import React from 'react';
import { useTheme } from 'styled-components';
import { SkeletonBox } from '../../../../shared/components/SkeletonBox';
import { StyledWorkspaceSwitcherSkeleton } from './WorkspaceSwitcherSkeleton.styled';

export const WorkspaceSwitcherSkeleton = () => {
  const { colors } = useTheme();
  return (
    <StyledWorkspaceSwitcherSkeleton>
      <div className="body">
        <SkeletonBox
          width={2.5}
          height={2.5}
          borderRadius={0.25}
          color={colors.WorkspaceSwitcherSkeleton}
        />
        <SkeletonBox
          width={2.5}
          height={2.5}
          borderRadius={0.25}
          margin={[0.5, 0, 0, 0]}
          color={colors.WorkspaceSwitcherSkeleton}
        />
        <SkeletonBox
          width={2.5}
          height={2.5}
          borderRadius={0.25}
          margin={[0.5, 0, 0, 0]}
          color={colors.WorkspaceSwitcherSkeleton}
        />
        <SkeletonBox
          width={2.5}
          height={2.5}
          borderRadius={0.25}
          margin={[0.5, 0, 0, 0]}
          color={colors.WorkspaceSwitcherSkeleton}
        />
      </div>
      <div className="footer">
        <SkeletonBox
          width={1.375}
          height={1.375}
          borderRadius={0.25}
          color={colors.WorkspaceSwitcherSkeleton}
        />
        <SkeletonBox
          width={1.375}
          height={1.375}
          borderRadius={0.25}
          margin={[1.375, 0, 0, 0]}
          color={colors.WorkspaceSwitcherSkeleton}
        />
      </div>
    </StyledWorkspaceSwitcherSkeleton>
  );
};
