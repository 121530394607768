import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyledAppIntegrationNavigation,
  StyledAppsIntegrationWrapper,
  StyledAppsSection,
  StyledBackButton,
  StyledDesktopIcon,
  StyledFilter,
  StyledManageIntegrations,
  StyledResultItem,
  StyledSearchResult,
  StyledSearchResultList,
  StyledSpinner,
  StyledTabName,
  StyledTabPanel,
  StyledTabs,
  StyledTabsControls,
} from './SearchAppsIntegration.styled';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { GlobalSearchTab } from '../ExpandedSearch';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { GlobalSearchTranslation } from '../../i18n';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  ArrowLeftIcon,
  CogIcon,
  CsvFileIcon,
  GoogleDocsIcon,
  GoogleFormsIcon,
  GooglePdfIcon,
  GoogleSheetIcon,
  GoogleSlidesIcon,
  KeyboardArrowRightIcon,
  LongArrowLeftIcon,
} from '../../../../shared/icons';
import { EnterIcon } from '../../../../shared/icons/EnterIcon';
import { AppIntegrationApiType } from '../../../AppStore/AppStore.types';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  StyledTab,
  StyledTabList,
  TabsType,
} from '../../../../shared/components/Tabs';
import { TooltipPlace } from '../../../../shared/components/Tooltip';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { TABS_DIRECTION } from '../ExpandedSearch.constants';
import { useSearchAppIntegrations } from '../../../AppIntegrations/AppIntegrations.hooks';
import { PageInfoApiType } from '../../../../shared/api/api.types';
import {
  MarketplaceItemType,
  SearchResult,
} from '../../../AppIntegrations/AppIntegrations.types';
import { SearchAppIntegrationsResponse } from '../../../AppIntegrations/AppIntegrations.queries';
import { extractNodes } from '../../../../shared/api/api.utils';
import { mergeWithArray } from '../../../../shared/utils/list.utils';
import { GraphQLError } from 'graphql';
import { RETRY_LOAD_MORE_TIMEOUT } from '../../GlobalSearch.constants';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { LoadMoreObserver } from '../../../../shared/components/LoadMore';
import { APPS_INTEGRATIONS_PATHNAME } from '../../../Workspace/Workspace.constants';
import { getShortId } from '../../../../shared/utils/id';

interface SearchAppsIntegrationProps {
  appsList: AppIntegrationApiType[];
  searchSectionBlur: (redirectTo?: string) => void;
}

enum Tabs {
  allContent = 0,
}

const SELECT_ALL_TAB = {
  id: 'ALL_APPS',
};

const ICONS_FOR_FILES: { [key: string]: ReactNode } = {
  'application/vnd.google-apps.presentation': <GoogleSlidesIcon />,
  'application/vnd.google-apps.spreadsheet': <GoogleSheetIcon />,
  'application/vnd.google-apps.document': <GoogleDocsIcon />,
  'application/vnd.google-apps.form': <GoogleFormsIcon />,
  'application/pdf': <GooglePdfIcon />,
  'text/csv': <CsvFileIcon />,
};

type TabsStateType = {
  marketplaceItem: MarketplaceItemType;
  appIntegration: string | null;
  searchResult: SearchResult[];
  prevSearch: string | null;
  loading: boolean;
  totalCount: number;
  fetchMore: any;
  pageInfo: PageInfoApiType | null;
};

interface TabsStateInterface {
  [key: string]: TabsStateType;
}

const LIMIT_CHARS_FOR_SEARCH = 3;

const tabsStateInitialValues = {
  marketplaceItem: {},
  appIntegration: null,
  searchResult: [],
  prevSearch: null,
  loading: false,
  fetchMore: () => {},
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
};

export const SearchAppsIntegration: FC<SearchAppsIntegrationProps> = ({
  appsList,
  searchSectionBlur,
}) => {
  const { workspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();
  const [tabsState, setTabsState] = useState<TabsStateInterface>({});

  const { globalSearch } = queryParams;
  const globalSearchRef = useRef(globalSearch);
  const appsListRef = useRef(null);
  const [tabsList, setTabsList] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState(Tabs.allContent);
  const [loadingMoreSearchResults, setLoadingMoreSearchResults] =
    useState(false);

  const handleLoadMoreSearchResults = useCallback(() => {
    setLoadingMoreSearchResults(true);
    tabsState[tabsList[selectedTab]]
      .fetchMore({
        variables: {
          appIntegration: tabsState[tabsList[selectedTab]].appIntegration || '',
          query: (globalSearch as string) || '',
          first: 20,
          cursor: tabsState[tabsList[selectedTab]]?.pageInfo?.endCursor,
        },
        updateQuery: (
          prev: SearchAppIntegrationsResponse,
          {
            fetchMoreResult,
          }: { fetchMoreResult: SearchAppIntegrationsResponse },
        ) => {
          const { pageInfo, totalCount } =
            fetchMoreResult.listAppIntegrationSearches;
          const data = extractNodes(
            fetchMoreResult?.listAppIntegrationSearches,
          );
          const selectedTabId = tabsList[selectedTab];
          setTabsState((prevState: TabsStateInterface) => ({
            ...prevState,
            [selectedTabId]: {
              ...prevState[selectedTabId],
              searchResult: [...prevState[selectedTabId].searchResult, ...data],
              pageInfo: pageInfo,
              totalCount: totalCount,
              loading: false,
              prevSearch: globalSearch as string,
            },
          }));

          return mergeWithArray(
            prev || tabsState[selectedTab],
            fetchMoreResult || {},
          ) as SearchAppIntegrationsResponse;
        },
      })
      .then(() => setLoadingMoreSearchResults(false))
      .catch((e: { graphQLErrors: GraphQLError[] }) => {
        setTimeout(
          () => setLoadingMoreSearchResults(false),
          RETRY_LOAD_MORE_TIMEOUT,
        );
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [globalSearch, selectedTab, tabsList, tabsState]);

  useEffect(() => {
    if (appsList.length) {
      setTabsList(
        Object.assign(
          {},
          [SELECT_ALL_TAB, ...appsList].map((elem: any) => elem.id),
        ),
      );
      setTabsState({
        ...appsList.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: {
              ...tabsStateInitialValues,
              marketplaceItem: cur.marketplaceItem,
            },
          }),
          {},
        ),
      });
    }
  }, [appsList]);

  const handleDesktopSearchClick = () => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        globalSearchTab: GlobalSearchTab.apps,
      }),
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'Enter') {
      handleDesktopSearchClick();
    }
  };

  const handleTabChange = (value: number) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    const element = document.getElementById(
      'apps-integration-tab-label-' + selectedTab,
    );
    element?.scrollIntoView({
      block: 'end',
    });
  }, [selectedTab]);

  const handleTabChangeFromButton = useCallback(
    (event: MouseEvent) => {
      const { value } = event.target as HTMLButtonElement;

      if (selectedTab > 0 && value === TABS_DIRECTION.left) {
        setSelectedTab(prevState => prevState - 1);
      } else if (
        selectedTab < appsList.length &&
        value === TABS_DIRECTION.right
      ) {
        setSelectedTab(prevState => prevState + 1);
      }
    },
    [appsList, selectedTab],
  );

  const AppIntegrationTab: FC<{
    appData: AppIntegrationApiType;
  }> = ({ appData }) => {
    const { marketplaceItem, id } = appData;

    const shouldSkipQuery =
      !globalSearch ||
      globalSearch.length < LIMIT_CHARS_FOR_SEARCH ||
      tabsState[id].prevSearch === globalSearch;
    const { fetchMore } = useSearchAppIntegrations({
      variables: {
        appIntegration: id,
        query: (globalSearch as string) || '',
        first: 20,
      },
      fetchPolicy: 'network-only',
      skip: shouldSkipQuery,
      onCompleted: ({
        listAppIntegrationSearches,
      }: SearchAppIntegrationsResponse) => {
        globalSearchRef.current = globalSearch;

        if (listAppIntegrationSearches) {
          const tabResult = {
            ...tabsState[id],
            appIntegration: id,
            loading: false,
            fetchMore: fetchMore,
            prevSearch: globalSearch as string,
            pageInfo: listAppIntegrationSearches.pageInfo,
            searchResult: extractNodes(listAppIntegrationSearches),
          };

          setTabsState({
            ...tabsState,
            [id]: tabResult,
          });
        }
      },
    });

    if (
      tabsState[id].prevSearch !== globalSearchRef.current &&
      !tabsState[id].loading &&
      globalSearch &&
      globalSearch?.length > LIMIT_CHARS_FOR_SEARCH
    ) {
      setTabsState({
        ...tabsState,
        [id]: {
          ...tabsState[id],
          fetchMore: fetchMore,
          loading: true,
          searchResult: [],
        },
      });
    }

    return (
      <>
        <img
          src={marketplaceItem?.app.logo.contentUrl}
          alt={marketplaceItem?.app.name}
        />
        <StyledTabName id={`apps-integration-tab-label-${id}`}>
          {marketplaceItem?.appName}
        </StyledTabName>
        <div>
          {tabsState[id].loading ? (
            <Spinner size={16} />
          ) : (
            tabsState[id].searchResult.length
          )}
        </div>
      </>
    );
  };

  const allContentSearchResult: (SearchResult & {
    marketplaceItemLogo?: string;
  })[] = useMemo(
    () =>
      Object.values(tabsState).reduce(
        (acc: SearchResult[], curr: TabsStateType) => {
          const marketplaceItemLogo = curr.marketplaceItem.app.logo.contentUrl;

          return [
            ...acc,
            ...curr.searchResult.map((elem: SearchResult) => ({
              ...elem,
              marketplaceItemLogo: marketplaceItemLogo,
            })),
          ];
        },
        [],
      ),
    [tabsState],
  );
  const isAllContentTabLoading = useMemo(
    () => Object.values(tabsState).some(tabState => tabState.loading),
    [tabsState],
  );

  const searchResult: (SearchResult & {
    marketplaceItemLogo?: string;
  })[] = useMemo(() => {
    return selectedTab === Tabs.allContent
      ? allContentSearchResult
      : tabsState[tabsList[selectedTab]].searchResult ?? [];
  }, [allContentSearchResult, selectedTab, tabsList, tabsState]);

  if (appsList.length + 1 !== Object.keys(tabsList).length) {
    return <Spinner />;
  }

  const handleManageIntegrationsClick = (
    e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();

    navigate(
      generatePath(APPS_INTEGRATIONS_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        search: getQueryParamsFrom({
          ...queryParams,
          globalSearch: undefined,
          globalSearchTab: undefined,
        }),
      }),
    );
  };

  return (
    <StyledAppsIntegrationWrapper>
      <StyledAppIntegrationNavigation
        onKeyDown={handleKeyDown}
        onClick={handleDesktopSearchClick}
        tabIndex={0}>
        <StyledBackButton>
          <div
            data-tooltip-place={TooltipPlace.top}
            data-tooltip-content={formatMessage({
              id: GlobalSearchTranslation.tooltipAppIntegrationsGoBack,
            })}>
            <StyledDesktopIcon>
              <LongArrowLeftIcon />
            </StyledDesktopIcon>
            <FormattedMessage
              id={GlobalSearchTranslation.searchInDesktopButton}
            />
          </div>
          <div className="enter-icon">
            <EnterIcon />
          </div>
        </StyledBackButton>
        <StyledManageIntegrations
          data-tooltip-content={formatMessage({
            id: GlobalSearchTranslation.tooltipManageButton,
          })}
          data-tooltip-place={TooltipPlace.top}
          onClick={handleManageIntegrationsClick}>
          <CogIcon height={16} width={16} />
          <FormattedMessage
            id={GlobalSearchTranslation.searchManageIntegrations}
          />
        </StyledManageIntegrations>
      </StyledAppIntegrationNavigation>
      <StyledFilter>
        <StyledAppsSection ref={appsListRef}>
          <StyledTabs
            type={TabsType.tabs}
            selectedIndex={selectedTab}
            onSelect={index => setSelectedTab(index)}>
            {tabsList && (
              <>
                <StyledTabList type={TabsType.tabs} tabwidth={130}>
                  <StyledTab onClick={() => setSelectedTab(Tabs.allContent)}>
                    <div
                      data-tooltip-place={TooltipPlace.top}
                      data-tooltip-content={formatMessage({
                        id: GlobalSearchTranslation.tooltipAllAppsTab,
                      })}>
                      <StyledTabName id={`apps-integration-tab-label-0`}>
                        <FormattedMessage
                          id={
                            GlobalSearchTranslation.searchFilterAllAppsTabName
                          }
                        />
                      </StyledTabName>
                      <StyledSpinner>
                        <div>
                          {isAllContentTabLoading ? (
                            <Spinner size={16} />
                          ) : (
                            allContentSearchResult.length
                          )}
                        </div>
                      </StyledSpinner>
                    </div>
                  </StyledTab>
                  {appsList.map((elem, idx) => (
                    <StyledTab
                      // +1 since first tab is ALL_CONTENT and it not present in appsList
                      onClick={() => handleTabChange(idx + 1)}
                      key={elem.id + '-tab'}>
                      <AppIntegrationTab appData={elem} />
                    </StyledTab>
                  ))}
                </StyledTabList>
                {Object.keys(tabsList).map((elem, idx) => (
                  <StyledTabPanel key={'apps-integrations-tab-panel-' + idx} />
                ))}
              </>
            )}
          </StyledTabs>
        </StyledAppsSection>
        {!!appsList.length && (
          <StyledTabsControls>
            <ButtonWithIcon
              icon={ArrowLeftIcon}
              value={TABS_DIRECTION.left}
              onClick={handleTabChangeFromButton}
            />
            <ButtonWithIcon
              icon={KeyboardArrowRightIcon}
              value={TABS_DIRECTION.right}
              onClick={handleTabChangeFromButton}
            />
          </StyledTabsControls>
        )}
      </StyledFilter>

      <StyledSearchResult>
        <StyledSearchResultList>
          {!!searchResult.length ? (
            searchResult.map((data, idx) => (
              <StyledResultItem
                key={data.contextId + '-result-item-' + idx}
                href={data.externalURI}
                rel="noopener"
                target="_blank">
                <div>
                  {!!ICONS_FOR_FILES[data.summary] ? (
                    ICONS_FOR_FILES[data.summary]
                  ) : (
                    <img
                      src={
                        selectedTab === Tabs.allContent
                          ? data.marketplaceItemLogo
                          : tabsState[tabsList[selectedTab]].marketplaceItem.app
                              ?.logo?.contentUrl
                      }
                      alt=""
                    />
                  )}
                  <span>{data.title}</span>
                </div>
              </StyledResultItem>
            ))
          ) : selectedTab === Tabs.allContent ? (
            isAllContentTabLoading
          ) : tabsState[tabsList[selectedTab]].loading ? (
            <Spinner />
          ) : (
            <FormattedHTMLMessage
              id={GlobalSearchTranslation.searchResultNoFound}
            />
          )}
          {tabsState[tabsList[selectedTab]]?.pageInfo?.hasNextPage && (
            <LoadMoreObserver
              loading={loadingMoreSearchResults}
              onLoadMore={handleLoadMoreSearchResults}
            />
          )}
        </StyledSearchResultList>
      </StyledSearchResult>
    </StyledAppsIntegrationWrapper>
  );
};
