import React, { FC } from 'react';
import { AccountApiType } from '../../../../User/User.types';
import { ChatAvatar } from '../../../../Chat/ChatAvatar';
import {
  LinkAccountPreviewData,
  LinkAccountPreviewEmail,
  LinkAccountPreviewName,
  StyledLinkAccountPreview,
} from './LinkAccountPreview.styled';
import { getAccountName } from '../../../../User/User.utils';

interface LinkAccountPreviewProps {
  account: AccountApiType;
}

export const LinkAccountPreview: FC<LinkAccountPreviewProps> = ({
  account,
}) => {
  return (
    <StyledLinkAccountPreview>
      <ChatAvatar
        account={account}
        avatarSize="3rem"
        showOnlineStatus={false}
      />
      <LinkAccountPreviewData>
        <LinkAccountPreviewName>
          {getAccountName(account)}
        </LinkAccountPreviewName>
        <LinkAccountPreviewEmail>{account.email}</LinkAccountPreviewEmail>
      </LinkAccountPreviewData>
    </StyledLinkAccountPreview>
  );
};
