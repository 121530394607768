import React, { FC } from 'react';
import { LinkOpenConfirmation } from '../../../../Link/LinkOpenConfirmation';

export const TimelineLinkOpenConfirmation: FC<{
  abuseChecked: boolean;
  abuseDescription: string;
}> = ({ abuseChecked, abuseDescription }) => {
  return (
    <LinkOpenConfirmation
      abuseChecked={abuseChecked}
      abuseDescription={abuseDescription}
    />
  );
};
