import { AccountApiType } from '../../../User/User.types';
import { getAccountName } from '../../../User/User.utils';

export const shouldShowOption = (account: AccountApiType, rawInput: string) => {
  const accountEmail = account.email;
  const searchTerm = rawInput.toLowerCase();
  if (accountEmail.includes(searchTerm)) {
    return true;
  }
  const accountName = (getAccountName(account) || '').toLowerCase();
  return accountName.includes(searchTerm);
};
