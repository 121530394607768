import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../shared/components/ButtonWithIcon/ButtonWithIcon';

const MEMBER_LIST_HEADER_HEIGHT = 90;

export const StyledMembersList = styled.div`
  padding: 0.75rem;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: flex;
    flex-direction: column;
    height: calc(100% - ${MEMBER_LIST_HEADER_HEIGHT}px);
    overflow-y: auto;
  `}
`;

export const ListWrapper = styled.div`
  & + & {
    margin-top: 1rem;
  }

  h1 {
    ${({ theme }) => theme.fontTemplates.headerList}
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  .member-name {
    max-width: calc(100% - 2.25rem);

    span {
      max-width: 90%;
    }
  }
`;

export const StyledExpand = styled(ButtonWithIcon)<{
  expanded?: boolean;
}>`
  width: 100%;
  display: flex;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-weight: 600;
  letter-spacing: 0.48px;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: ${p => p.theme.fonts.Semibold};
  padding: 0 0.5rem;

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    margin-right: 0.563rem;
    transition: transform 0.2s;
  }

  ${p =>
    !p.expanded &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}
`;
