import styled from 'styled-components';

export const SubHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: ${p => p.theme.colors.Primary};
  color: ${p => p.theme.colors.PrimaryTextLight};
  padding: 1.5rem 3.75rem;
  border-radius: 8px;
  margin-bottom: 2.5rem;
`;

export const SubHeaderText = styled.div``;

export const SubHeaderPrice = styled.div`
  margin-top: 0.75rem;
  font-size: 1.5rem;
`;
