import styled from 'styled-components';
import { TabPanel, Tabs } from 'react-tabs';
import { StyledTab } from '../../../shared/components/Tabs';

export const StyledTabs = styled(Tabs)`
  height: 100%;
  width: 100%;

  img {
    width: 1rem;
    height: 1rem;
  }
`;

export const StyledAppsIntegrationsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};

  .header {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

styled(StyledTab)`
  width: auto !important;
  padding: 0 ${({ theme }) => theme.spacing.m};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

styled(TabPanel)`
  overflow: auto;

  &.react-tabs__tab-panel--selected {
    height: 100%;
    width: 100%;
  }
`;
