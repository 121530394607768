import styled, { css } from 'styled-components';

const BORDER_WIDTH = 4;
const BORDER_RADIUS = 8;
const APP_HEIGHT = 116 + BORDER_WIDTH;

export const StyledAppsLayoutFluid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, 106px);
  grid-auto-rows: min-content;
  grid-row-gap: 2.75rem;
  grid-column-gap: 0.75rem;
  padding-bottom: 4rem;
  overflow: hidden;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    grid-template-columns: repeat(auto-fill, 110px);
    grid-column-gap: 1rem;
  `}
`;

interface StyledProps {
  isFirst: boolean;
  isLast: boolean;
  processHover: boolean;
}

const BorderCap = css`
  content: '';
  width: ${BORDER_WIDTH * 4}px;
  height: ${APP_HEIGHT}px;
  position: absolute;
  top: -${BORDER_WIDTH}px;
  border-top: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
  border-bottom: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
`;

const BorderCapLeft = css`
  &:before {
    ${BorderCap}
    left: 0;
    border-top-left-radius: ${BORDER_RADIUS}px;
    border-bottom-left-radius: ${BORDER_RADIUS}px;
    border-left: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
  }
`;

const BorderCapRight = css`
  &:after {
    ${BorderCap}
    right: 0;
    border-top-right-radius: ${BORDER_RADIUS}px;
    border-bottom-right-radius: ${BORDER_RADIUS}px;
    border-right: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
  }
`;

const BorderContinue = css`
  content: '';
  background-color: ${p => p.theme.colors.Surface};
  width: ${BORDER_WIDTH * 2}px;
  height: ${APP_HEIGHT}px;
  position: absolute;
  top: -${BORDER_WIDTH}px;
  border-top: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
  border-bottom: ${BORDER_WIDTH}px solid ${p => p.theme.colors.BorderLight};
`;

const BorderContinueRight = css`
  &:after {
    ${BorderContinue};
    right: -${BORDER_WIDTH * 2}px;
  }
`;

const BorderContinueLeft = css`
  &:before {
    ${BorderContinue};
    left: -${BORDER_WIDTH * 2}px;
  }
`;

export const StyledCategoryApp = styled.div<StyledProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.Surface};

  border-top: ${BORDER_WIDTH}px solid ${({ theme }) => theme.colors.BorderLight};
  border-bottom: ${BORDER_WIDTH}px solid
    ${({ theme }) => theme.colors.BorderLight};
  padding: 6px;
  width: 110px;
  height: ${APP_HEIGHT}px;
  display: flex;

  &.category-end {
    .app-category-name {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  ${({ theme, processHover }) =>
    processHover &&
    css`
      a:hover {
        background-color: ${theme.colors.OnSurfaceHover};
      }
    `}

  /* The first, but not last app */
  ${({ isFirst, isLast }) =>
    isFirst &&
    !isLast &&
    css`
      border-top-left-radius: ${BORDER_RADIUS}px;
      border-bottom-left-radius: ${BORDER_RADIUS}px;

      ${BorderCapLeft};
      ${BorderContinueRight};
    `}

  /* The last, but not first app */
  ${({ isFirst, isLast }) =>
    isLast &&
    !isFirst &&
    css`
      border-top-right-radius: ${BORDER_RADIUS}px;
      border-bottom-right-radius: ${BORDER_RADIUS}px;

      ${BorderCapRight};
      ${BorderContinueLeft};
    `}

  /* The middle app */
  ${({ isFirst, isLast }) =>
    !isFirst &&
    !isLast &&
    css`
      ${BorderContinueLeft}
      ${BorderContinueRight}
    `}

  /* The first and last app */
  ${({ isFirst, isLast }) =>
    isFirst &&
    isLast &&
    css`
      border-radius: ${BORDER_RADIUS}px;
      ${BorderCapLeft}
      ${BorderCapRight}
    `}
`;

export const StyledCategoryLabel = styled.div`
  color: ${({ theme }) => theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.875rem;
  border-radius: 4px;
  white-space: nowrap;
  position: absolute;
  bottom: -24px;
  left: ${BORDER_WIDTH}px;

  span {
    pointer-events: none;
  }
`;
