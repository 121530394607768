import { useCallback, useState } from 'react';
import { getLongId, getShortId } from '../../../shared/utils/id';
import { getDesktopLinksRepository } from '../deprecatedData/Repository/DesktopRepository';

export const useCaseGetDesktopLinks = () => {
  const [desktopLinksLoading, setDesktopLinksLoading] = useState(false);

  const getDesktopLinks = useCallback(
    async (desktopId: string, workspaceId: string, folderId?: string) => {
      setDesktopLinksLoading(true);
      const response = await getDesktopLinksRepository(
        getShortId(desktopId),
        getLongId('workspaces', workspaceId),
        folderId ? getShortId(folderId) : undefined,
      );

      if (response) {
        setDesktopLinksLoading(false);
        return response;
      }
    },
    [],
  );

  return {
    getDesktopLinks,
    desktopLinksLoading,
  };
};
