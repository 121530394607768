import { gql } from '@apollo/client';
import { DesktopAppFragmentFields } from '../../../../Desktop/Desktop.fragments';
import type { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

export const DELETE_APP_DESKTOP = gql`
  mutation DeleteAppDesktop($input: deleteAppDesktopInput!) {
    __typename
    deleteAppDesktop(input: $input) {
      desktop {
        id
        apps {
          edges {
            node {
              ${DesktopAppFragmentFields}
            }
          }
        }
      }
    }
  }
`;

export interface DeleteAppFromDesktopVariables {
  input: {
    id: string;
    app: string;
  };
}

export interface DeleteAppFromDesktopResponse {
  deleteAppDesktop: {
    desktop: DesktopApiType;
  };
}
