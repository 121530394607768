import styled from 'styled-components';

export const StyledImageUpload = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  button {
    background-color: ${p => p.theme.colors.Surface};
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;

  &::file-selector-button {
    cursor: pointer;
  }

  &:not([disabled])&:hover {
    & + label button {
      background-color: ${p => p.theme.colors.OnSurfaceHover};
      border: 1px solid ${p => p.theme.colors.OnSurfaceHover};
    }
  }

  &[disabled] {
    cursor: auto;
  }
`;
