import React, { FC } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';

export const JoinChatErrorPage: FC = () => {
  return (
    <OnboardingPage>
      <OnboardingStep data-testid="join-chat-error-step">
        <FormattedMessage
          tagName={OnboardingStepHeader}
          id={OnboardingTranslation.onboardingJoinChatErrorHeader}
        />
        <FormattedMessage
          tagName={OnboardingStepText}
          id={OnboardingTranslation.onboardingJoinChatErrorDescription}
        />
      </OnboardingStep>
    </OnboardingPage>
  );
};
