import styled from 'styled-components';
import { appEnv } from '../../../appEnv';
import { Link, LinkMode, LinkSize } from '../../../shared/components/Link';

export const StyledInstallPlugin = styled.div`
  ul {
    margin-bottom: 2rem;
    li {
      display: flex;
      align-items: center;
      margin-top: 2rem;
    }
  }
  button {
    margin-top: 0.75rem;
  }
  svg {
    color: ${p => p.theme.colors.Primary};
    margin-right: 0.75rem;
  }
`;

export const InstallPluginTitle = styled.h1`
  font-size: 1.5rem;
  font-family: ${p => p.theme.fonts.Medium};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const InstallPluginContent = styled.div`
  font-family: ${p => p.theme.fonts.Medium};
  margin-bottom: 1.5rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const InstallPluginLink = styled(Link).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: appEnv.IMPORT_EXTENSION_LINK,
  mode: LinkMode.primary,
  size: LinkSize.md,
  fullWidth: true,
})`
  margin-top: 1rem;
`;
