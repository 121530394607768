import styled from 'styled-components';

export const StyledClaimsMapper = styled.div`
  .claim-wrapper {
    margin: 1rem 0;
    display: flex;
    align-items: flex-end;

    .claim-name {
      margin-right: 0.5rem;
      flex: 1;
    }

    .claim-value {
      flex: 1;
    }

    .claim-value-container {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }

    .remove-claim {
      appearance: none;
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.625rem;
      height: 2.625rem;
      margin-left: 0.25rem;
      color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
      cursor: pointer;

      &:not(:disabled):hover {
        color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
      }

      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }

    select {
      font-family: ${p => p.theme.fonts.Regular};
    }
  }

  .mt-1 {
    margin-top: 1rem;
  }
`;
