import React, { FC } from 'react';
import { UserTranslation } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  GeneralInfoContent,
  GeneralInfoGroupTag,
  GeneralInfoMeta,
  GeneralInfoSubtitle,
  Section,
  SectionBody,
} from '../../../../../Admin';
import { GroupTag } from '../../../../UserGroupTag';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../../User.constants';
import { isPast, parseISO } from 'date-fns';
import { useDateFormatters } from '../../../../../../shared/hooks/date.hooks';
import { AccountInvitationApiType } from '../../../../../Invitation/data/Invitation/types/Invitation.types';

interface GeneralInformationProps {
  invitation: AccountInvitationApiType;
}

export const GeneralInformationSection: FC<GeneralInformationProps> = ({
  invitation,
}) => {
  const { formatMessage } = useIntl();

  const { format, formatRelative, formatDistance } = useDateFormatters();
  const now = new Date();

  return (
    <>
      <Section data-testid="invitation-information">
        <SectionBody data-testid="section">
          <GeneralInfoContent>
            <GeneralInfoMeta>
              <GeneralInfoSubtitle>
                <span
                  data-testid="created"
                  title={format(parseISO(invitation.createdAt!), 'PPp')}>
                  {formatMessage(
                    {
                      id: UserTranslation.adminAccountInvitationCreatedAt,
                    },
                    {
                      when: formatRelative(
                        parseISO(invitation.createdAt!),
                        now,
                      ),
                    },
                  )}
                </span>
                <b>{`  |  `}</b>
                <span
                  data-testid="expires"
                  title={format(parseISO(invitation.expiresAt!), 'PPp')}>
                  {formatMessage(
                    {
                      id: isPast(parseISO(invitation.expiresAt!))
                        ? UserTranslation.adminAccountInvitationExpiredAt
                        : UserTranslation.adminAccountInvitationExpiresAt,
                    },
                    {
                      when: formatRelative(
                        parseISO(invitation.expiresAt!),
                        now,
                      ),
                      timeleft: formatDistance(
                        parseISO(invitation.expiresAt!),
                        now,
                        { addSuffix: true },
                      ),
                    },
                  )}
                </span>
              </GeneralInfoSubtitle>
              <GeneralInfoSubtitle data-testid="limit">
                {formatMessage(
                  {
                    id: UserTranslation.adminAccountInvitationSpots,
                  },
                  {
                    taken: invitation.accountsCreated,
                    available: invitation.accountLimit,
                  },
                )}
              </GeneralInfoSubtitle>
              <GeneralInfoGroupTag data-testid="group-tag-label">
                <FormattedMessage
                  id={UserTranslation.adminAccountInvitationAssignedRole}
                />
                <GroupTag
                  groupIdentifier={invitation.groups.workspace_group}
                  data-testid="group-tag-value">
                  {formatMessage({
                    id: UserTranslation[
                      invitation.groups.workspace_group as GroupIdentity
                    ],
                  })}
                </GroupTag>
              </GeneralInfoGroupTag>
            </GeneralInfoMeta>
          </GeneralInfoContent>
        </SectionBody>
      </Section>
    </>
  );
};
