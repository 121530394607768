import { useMutation } from '@apollo/client';

import {
  CREATE_IMAGE_ASSET,
  createImageAssetResponse,
  createImageAssetVariables,
} from './Image.mutations';

export const useUploadImageMutation = () =>
  useMutation<createImageAssetResponse, createImageAssetVariables>(
    CREATE_IMAGE_ASSET,
  );
