import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { EditDesktopForm } from '../../../DesktopForm/EditDesktopForm';
import { DesktopTranslation } from '../../../i18n';
import { useDesktopTrackingEventOnInit } from '../../../tracking/DesktopTracking.hooks';

export const EditDesktopModal: FC<Omit<ModalProps, 'visible'>> = ({
  onRequestClose,
  ...props
}) => {
  useDesktopTrackingEventOnInit('open_edit_desktop');
  return (
    <Modal visible={true} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={DesktopTranslation.editModalHeader} />
      </ModalHeader>
      <EditDesktopForm onDone={onRequestClose} />
    </Modal>
  );
};
