import { gql } from '@apollo/client';
import { AdminNotificationsSettingsApiType } from './NotificationsSettingsAdmin.types';

export const NOTIFICATIONS_SETTINGS_FRAGMENT_FIELDS = `
  id
  messagesNotifications
  generalBellNotificationsEnable
  billingNotificationsEnable
  upcomingMeetingNotificationsEnable
  soundsEnable
`;

export const UPDATE_NOTIFICATIONS_SETTINGS = gql`
  mutation UpdateAccountIdentitySetting(
    $input: updateAccountIdentitySettingInput!
  ) {
    updateAccountIdentitySetting(input: $input) {
      accountIdentitySetting {
        ${NOTIFICATIONS_SETTINGS_FRAGMENT_FIELDS}
      }
    }
  }
`;

export interface UpdateNotificationSettingsVariables {
  input: AdminNotificationsSettingsApiType;
}

export interface UpdateNotificationsSettingsResponse {
  updateAccountIdentitySetting: {
    accountIdentitySetting: AdminNotificationsSettingsApiType;
  };
}
