import React, { FC, useCallback } from 'react';
import {
  ConferenceApiType,
  ConferencesTabsValues,
  FilterPeriod,
  ScheduledChatConferenceApiType,
} from '../../Conference.types';
import {
  ConferencesStyledTab,
  StyledConferencesNavigator,
  StyledTabPanel,
  StyledTabs,
} from './ConferencesNavigator.styled';
import { ConferencesList } from './ConferencesList';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConferenceTranslation } from '../../i18n';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { ScheduledConferencesList } from './ScheduledConferencesList';
import { StyledTabList, TabsType } from '../../../../shared/components/Tabs';
import { TooltipPlace } from '../../../../shared/components/Tooltip';

interface ConferencesNavigatorProps {
  conferences: ConferenceApiType[];
  listFooter: FC;
  className?: string;
  scheduleConferenceProps: {
    scheduledConferencesPast: ScheduledChatConferenceApiType[];
    scheduledConferencesFuture: ScheduledChatConferenceApiType[];
    loadingConferencesFuture: boolean;
    loadingConferencesPast: boolean;
    fetchMoreScheduledConferences: (filterPeriod: FilterPeriod) => void;
    hideLoadMoreConferencesFuture?: boolean;
    hideLoadMoreConferencesPast?: boolean;
  };
}

export const ConferencesNavigator: FC<ConferencesNavigatorProps> = ({
  conferences,
  listFooter,
  className,
  scheduleConferenceProps,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { meetingsActiveTab } = queryParams;
  const { formatMessage } = useIntl();

  const handleTabsClick = useCallback(
    (tabIdx: ConferencesTabsValues) => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          meetingsActiveTab: tabIdx,
        }),
      });
    },
    [navigate, queryParams],
  );

  return (
    <StyledConferencesNavigator className={className}>
      <StyledTabs
        selectedIndex={Number(meetingsActiveTab) || 0}
        onSelect={index => handleTabsClick(index)}>
        <StyledTabList type={TabsType.tabs}>
          <ConferencesStyledTab
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: ConferenceTranslation.tooltipTabPastCalls,
            })}
            data-tooltip-place={TooltipPlace.top}>
            <FormattedMessage
              id={ConferenceTranslation.conferenceViewTabNameCalls}
            />
          </ConferencesStyledTab>
          <ConferencesStyledTab
            data-tooltip-id="global-tooltip"
            data-tooltip-content={formatMessage({
              id: ConferenceTranslation.tooltipTabScheduleMeeting,
            })}
            data-tooltip-place={TooltipPlace.top}>
            <FormattedMessage
              id={ConferenceTranslation.scheduledUpcomingConferenceScheduled}
            />
          </ConferencesStyledTab>
        </StyledTabList>

        <StyledTabPanel>
          <ConferencesList conferences={conferences} listFooter={listFooter} />
        </StyledTabPanel>
        <StyledTabPanel>
          <ScheduledConferencesList {...scheduleConferenceProps} />
        </StyledTabPanel>
      </StyledTabs>
    </StyledConferencesNavigator>
  );
};
