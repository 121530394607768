import React, { FC, useMemo } from 'react';
import { StyledItem, StyledItemBody } from '../SearchItems/SearchItems.styled';
import { TimeIcon } from '../../../../../shared/icons';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import { StyledRecentTitle } from './RecentViews.styled';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { ResentSearchesType } from '../../../GlobalSearch.types';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';

export const RecentSearches: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();

  const recentSearches = useMemo(() => {
    if (!account?.recentSearches?.[workspace.id]) {
      return null;
    }

    const workspaceRecentSearches = (
      account.recentSearches as ResentSearchesType
    )[workspace.id];

    return workspaceRecentSearches
      ? [...workspaceRecentSearches].reverse()
      : [];
  }, [account?.recentSearches, workspace.id]);

  if (!account?.recentSearches?.[workspace.id]) {
    return null;
  }

  const handleRecentSearchesClick = (value: string) => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        globalSearch: value,
      }),
    });
  };

  return (
    <>
      <StyledRecentTitle>
        <FormattedMessage
          id={GlobalSearchTranslation.expandedSearchRecentSearches}
        />
      </StyledRecentTitle>
      {recentSearches?.map((item, idx) => (
        <StyledItem
          onClick={() => handleRecentSearchesClick(item)}
          key={item + idx}>
          <StyledItemBody>
            <TimeIcon />
            <span>{item}</span>
          </StyledItemBody>
        </StyledItem>
      ))}
    </>
  );
};
