import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../shared/components/Modal';
import { CreateLink } from '../../../LinkForm';
import { FormattedMessage } from 'react-intl';
import { LinkTranslation } from '../../../i18n';

interface CreateLinkModalProps extends Omit<ModalProps, 'visible'> {
  desktopName: string;
}

export const CreateLinkModal: FC<CreateLinkModalProps> = ({
  desktopName,
  onRequestClose,
}) => {
  return (
    <Modal visible={true} width={588} onRequestClose={onRequestClose}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={LinkTranslation.createLinkModalHeader} />{' '}
        {desktopName}
      </ModalHeader>
      <CreateLink onDone={onRequestClose} />
    </Modal>
  );
};
