import React, { FC, useCallback, useState } from 'react';
import { AccountApiType } from '../../../User/User.types';
import { getAccountName } from '../../../User/User.utils';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { ChatAvatar } from '../../../Chat/ChatAvatar';
import {
  AccountEmail,
  AccountInfo,
  AccountName,
  AccountTitle,
  StyledSharedWIthAccountsListItem,
} from './SharedWIthAccountsListItem.styled';
import { FormattedMessage } from 'react-intl';
import { EncryptionTranslation } from '../../i18n';

interface SharedWIthAccountsListItemProps {
  account: AccountApiType;
  onDeleteButtonClick: (account: AccountApiType) => Promise<unknown>;
}

export const SharedWIthAccountsListItem: FC<SharedWIthAccountsListItemProps> = ({
  account,
  onDeleteButtonClick,
}) => {
  const [isDeleting, setDeleting] = useState(false);

  const handleDeleteButtonClick = useCallback(() => {
    setDeleting(true);
    onDeleteButtonClick(account).finally(() => {
      setDeleting(false);
    });
  }, [account, onDeleteButtonClick]);

  return (
    <StyledSharedWIthAccountsListItem
      key={account.id}
      data-testid="account-list-item"
      data-entityid={account.id}>
      <AccountInfo>
        <ChatAvatar account={account} />
        <AccountTitle data-testid="account-title">
          <AccountName data-testid="account-name">
            {getAccountName(account)}
          </AccountName>
          <AccountEmail data-testid="account-email">
            {account.email}
          </AccountEmail>
        </AccountTitle>
      </AccountInfo>
      <Button
        size={ButtonSize.sm}
        mode={ButtonMode.secondary}
        disabled={isDeleting}
        onClick={handleDeleteButtonClick}
        data-testid="delete-button">
        <FormattedMessage
          id={EncryptionTranslation.sharePasswordsAccountsListDeleteButton}
        />
      </Button>
    </StyledSharedWIthAccountsListItem>
  );
};
