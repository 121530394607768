import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledDrawerSettingsMenu = styled.nav`
  background-color: ${p => p.theme.colors.Background};
  overflow-y: scroll;
`;

export const DrawerSettingsMenuList = styled.ul``;

export const DrawerSettingsMenuItem = styled.li`
  border-top: 1px solid ${p => p.theme.colors.BorderLight};
  border-bottom: 1px solid ${p => p.theme.colors.BorderLight};

  & + & {
    border-top: none;
  }
`;

export const DrawerSettingsMenuNavLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  padding: 18px 22px 18px 34px;
  background-color: ${p => p.theme.colors.Surface};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-size: 1.125rem;
`;

export const DrawerSettingsMenuLinkText = styled.span`
  flex: 1 1 100%;
  margin-left: 0.75rem;
`;

export const DrawerSettingsSeparator = styled.div`
  height: 0;
  margin-bottom: 2rem;
`;

export const WorkspaceSelectContainer = styled.div`
  & > * {
    padding: 0.5rem 2rem;
  }
`;
