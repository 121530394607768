import styled, { css } from 'styled-components';

export const StyledLayoutTabbed = styled.div<{
  isMobile: boolean;
}>`
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      overflow: hidden;
      flex: 1;
    `}

  ${({ isMobile }) =>
    !isMobile &&
    css`
      height: 100%;
    `}
`;
