import { gql } from '@apollo/client';
import { AccountApiType } from '../User/User.types';
import {
  AccountLinkRequestApiType,
  ExternalRegistrationFormValues,
  ResetPasswordInput,
  SignUpStep,
  TeamSizeType,
  UseCaseType,
} from './Onboarding.types';
import { AccountFields } from '../User/User.fragments';

export const CHANGE_ONBOARDING_STEP_ACCOUNT = gql`
  mutation ChangeOnboardingStepAccount(
    $input: changeOnboardingStepAccountInput!
  ) {
    changeOnboardingStepAccount(input: $input) {
      account {
        ${AccountFields}
      }
    }
  }
`;

export interface ChangeOnboardingStepAccountVariables {
  input: {
    onboardingStep: SignUpStep;
    force?: boolean;
  };
}

export interface ChangeOnboardingStepAccountResponse {
  changeOnboardingStepAccount: {
    account: AccountApiType;
  };
}

export const RESEND_ACTIVATE_ACCOUNT = gql`
  mutation ResendActivateAccount($input: resendActivateAccountInput!) {
    resendActivateAccount(input: $input) {
      account {
        id
        _id
        email
      }
    }
  }
`;

export interface ResendActivateAccountVariables {
  input: {
    id: string;
    email: string;
  };
}

export interface ResendActivateAccountResponse {
  resendActivateAccount: {
    account: AccountApiType;
  };
}

export const REQUEST_RESET_PASSWORD = gql`
  mutation ForgotPassword($input: forgotPasswordInput!) {
    forgotPassword(input: $input) {
      clientMutationId
    }
  }
`;

export interface RequestResetPasswordVariables {
  input: {
    email: string;
  };
}

export interface RequestResetPasswordResponse {
  forgotPassword: {
    clientMutationId: string;
  };
}

export const RESET_PASSWORD = gql`
  mutation ResetPasswordAccount($input: resetPasswordAccountInput!) {
    resetPasswordAccount(input: $input) {
      clientMutationId
    }
  }
`;

export interface ResetPasswordVariables {
  input: ResetPasswordInput;
}

export interface ResetPasswordResponse {
  resetPasswordAccount: {
    clientMutationId: string;
  };
}

export const SELECT_PLAN_FOR_ACCOUNT = gql`
  mutation SelectPlanForAccount($input: selectPlanForAccountInput!) {
    selectPlanForAccount(input: $input) {
      clientMutationId
    }
  }
`;

export interface SelectPlanForVariables {
  input: Omit<ExternalRegistrationFormValues, 'email'>;
}

export interface SelectPlanForResponse {
  selectPlanForAccount: {
    clientMutationId: string;
  };
}

export const CREATE_ACCOUNT_LINK_REQUEST = gql`
  mutation CreateAccountLinkRequest($input: createAccountLinkRequestInput!) {
    createAccountLinkRequest(input: $input) {
      accountLinkRequest {
        id
        _id
        validUntil
        linked
      }
    }
  }
`;

export interface CreateAccountLinkRequestVariables {
  input: {};
}

export interface CreateAccountLinkRequestResponse {
  createAccountLinkRequest: {
    accountLinkRequest: AccountLinkRequestApiType;
  };
}

export const COMPLETE_ACCOUNT_LINK_REQUEST = gql`
  mutation CompleteAccountLinkRequest(
    $input: completeAccountLinkRequestInput!
  ) {
    completeAccountLinkRequest(input: $input) {
      accountLinkRequest {
        id
        _id
        validUntil
        linked
        createdAt
        modifiedAt
      }
    }
  }
`;

export interface CompleteAccountLinkRequestVariables {
  input: {};
}

export interface CompleteAccountLinkRequestResponse {
  completeAccountLinkRequest: {
    clientMutationId: string;
  };
}

export const CANCEL_LINK_REQUEST_FOR_ACCOUNT = gql`
  mutation CancelAccountLinkRequest {
    cancelLinkRequestForAccount(input: {}) {
      clientMutationId
    }
  }
`;

export interface CancelLinkRequestForAccountVariables {}

export interface CancelLinkRequestForAccountResponse {
  cancelLinkRequestForAccount: {
    clientMutationId: string;
  };
}

export const VERIFY_EMAIL_CHANGE_ACCOUNT = gql`
  mutation VerifyEmailChangeAccount($input: verifyEmailChangeAccountInput!) {
    verifyEmailChangeAccount(input: $input) {
      account {
        id
        _id
        identityId
        email
        firstName
        lastName
        verifiedEmail
        createdWorkspace
        currentFlow
        pendingEmail
      }
    }
  }
`;

export interface VerifyEmailChangeAccountVariables {
  input: {
    id: string;
    activationHash: string;
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    pubKey: string;
    privKeyEncrypted: string;
  };
}

export interface VerifyEmailChangeAccountResponse {
  verifyEmailChangeAccount: {
    account: AccountApiType;
  };
}

export const CHANGE_USE_CASE_FOR_ACCOUNT = gql`
  mutation ChangeUseCaseForAccount($input: changeUseCaseForAccountInput!) {
    changeUseCaseForAccount(input: $input) {
      clientMutationId
    }
  }
`;

export interface ChangeUseCaseForAccountVariables {
  input: {
    id: string;
    useCase: string;
  };
}

export interface ChangeUseCaseForAccountResponse {
  changeUseCaseForAccount: {
    clientMutationId: string;
  };
}

export const CHANGE_ABOUT_YOU_FOR_ACCOUNT = gql`
  mutation ChangeAboutYouForAccount($input: changeAboutYouForAccountInput!) {
    changeAboutYouForAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export interface ChangeAboutYouForAccountVariables {
  input: { id: string; jobTitle: string; industry: string };
}

export interface ChangeAboutYouForAccountResponse {
  changeAboutYouForAccount: {
    useCase: UseCaseType;
  };
}

export const CHANGE_TEAM_SIZE_FOR_ACCOUNT = gql`
  mutation ChangeTeamSizeForAccount($input: changeTeamSizeForAccountInput!) {
    changeTeamSizeForAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export interface ChangeTeamSizeForAccountVariables {
  input: {
    id: string;
    teamSize: TeamSizeType;
  };
}

export interface ChangeTeamSizeForAccountResponse {
  changeTeamSizeForAccount: {
    teamSize: TeamSizeType;
  };
}

export const CHOOSE_FEATURE_FOR_WORKSPACE = gql`
  mutation ChooseFeatureForWorkspace($input: chooseFeatureForWorkspaceInput!) {
    chooseFeatureForWorkspace(input: $input) {
      workspace {
        id
        organizeAndSearch
        communicate
      }
    }
  }
`;

export interface ChooseFeatureForWorkspaceVariables {
  input: {
    id: string;
    organizeAndSearch: boolean;
    communicate: boolean;
  };
}

export interface ChooseFeatureForWorkspaceResponse {
  chooseFeatureForWorkspace: {
    workspace: {
      id: string;
      organizeAndSearch: boolean;
      communicate: boolean;
    };
  };
}
