import { FieldProps } from 'formik';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { InputWithIcon } from '../../../../../../shared/components/InputWithIcon/InputWithIcon';
import { EyeClosedIcon, EyeIcon } from '../../../../../../shared/icons';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { AppIntegrationsTranslation } from '../../../../i18n';

enum FieldType {
  text = 'text',
  password = 'password',
}

export const SecretInputFieldWrapper = styled.div`
  input {
    padding: 0.625rem 2.875rem 0.625rem 1rem;

    &:focus {
      padding: 0.563rem 2.813rem 0.563rem 0.938rem;
    }
  }
`;

export const SecretInputFieldInner = styled.div`
  .input-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    height: 2rem;
    width: 2rem;

    &:first-child {
      right: 0.5rem;
      left: auto;
    }

    &:nth-child(2) {
      right: 2.5rem;
      left: auto;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
    }
  }
`;

export const SecretInputFieldWithIcon: FC<FieldProps> = ({
  form,
  field,
  ...props
}) => {
  const [showSecret, setShowSecret] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const handleOnChange = useCallback(() => {
    setShowSecret(value => !value);
  }, []);

  return (
    <SecretInputFieldWrapper>
      <InputWithIcon
        ref={inputRef}
        data-testid="secret-input"
        disabled
        label={formatMessage({
          id: AppIntegrationsTranslation.appCustomIntegrationSecretLabel,
        })}
        {...field}
        type={showSecret ? FieldType.text : FieldType.password}>
        <SecretInputFieldInner>
          <ButtonWithIcon
            className="input-button"
            icon={!showSecret ? EyeIcon : EyeClosedIcon}
            type="button"
            onClick={handleOnChange}
            data-tooltip-content={formatMessage({
              id: !showSecret
                ? AppIntegrationsTranslation.appCustomIntegrationShowSecret
                : AppIntegrationsTranslation.appCustomIntegrationHideSecret,
            })}
            data-tooltip-place={TooltipPlace.bottom}
          />
        </SecretInputFieldInner>
      </InputWithIcon>
    </SecretInputFieldWrapper>
  );
};
