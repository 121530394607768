import styled from 'styled-components';
import { Link, LinkMode, LinkSize } from '../../../../shared/components/Link';
import { appEnv } from '../../../../appEnv';

export const ExtensionWarning = styled.div`
  max-width: 532px;
  padding: 1rem 2.5rem 1.875rem;
  margin: 0 auto;
`;

export const ExtensionModalContent = styled.div`
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
  padding: 2.5rem 2.5rem 4.625rem;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  button {
    margin-top: 2.375rem;
  }
`;

export const ExtensionIconsContainer = styled.div`
  padding: 0 2.5rem;
  text-align: center;

  img {
    max-width: 100%;
  }
`;

export const InstallExtensionLink = styled(Link).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: appEnv.IMPORT_EXTENSION_LINK,
  mode: LinkMode.secondary,
  size: LinkSize.md,
})`
  margin-top: 2rem;
`;
