import React, { FC, useCallback, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { RequestResetPasswordDescription } from './RequestResetPasswordPage.styled';
import { RequestResetPasswordForm } from './RequestResetPasswordForm';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { Navigate } from 'react-router-dom';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import { useRequestResetPasswordMutation } from '../../Onboarding.hooks';
import { RequestResetPasswordFormValues } from '../../Onboarding.types';
import { GlobalTranslation } from '../../../Intl/i18n';

export const RequestResetPasswordPage: FC = () => {
  const [requestResetPasswordMutation] = useRequestResetPasswordMutation();
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false);
  const { account } = useCurrentAccount();

  const requestResetPassword = useCallback(
    (values: RequestResetPasswordFormValues) => {
      return requestResetPasswordMutation({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(() => {
        setResetPasswordRequested(true);
      });
    },
    [requestResetPasswordMutation],
  );

  if (account) {
    return <Navigate to="/" />;
  }

  return (
    <OnboardingPage>
      <OnboardingStep data-testid="reset-password-step">
        <OnboardingStepHeader data-testid="header">
          <FormattedMessage
            id={OnboardingTranslation.onboardingRequestResetPasswordTitle}
          />
        </OnboardingStepHeader>

        {resetPasswordRequested ? (
          <RequestResetPasswordDescription data-testid="info-description">
            <FormattedMessage
              id={
                OnboardingTranslation.onboardingRequestResetPasswordSuccessMessage
              }
            />
          </RequestResetPasswordDescription>
        ) : (
          <>
            <RequestResetPasswordDescription data-testid="description">
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingRequestResetPasswordDescription
                }
              />
            </RequestResetPasswordDescription>
            <ImportantMessage
              data-testid="warning-message"
              type={ImportantMessageType.WARNING}>
              <FormattedHTMLMessage
                id={
                  OnboardingTranslation.onboardingRequestResetPasswordWarningMessage
                }
              />
            </ImportantMessage>
            <ImportantMessage
              data-testid="info-message"
              type={ImportantMessageType.INFO}>
              <FormattedHTMLMessage id={GlobalTranslation.checkEmailMessage} />
            </ImportantMessage>
            <RequestResetPasswordForm onSubmit={requestResetPassword} />
          </>
        )}
      </OnboardingStep>
    </OnboardingPage>
  );
};
