import styled from 'styled-components';

export const PlanUspItems = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1rem;
  margin-top: 26px;

  @media (max-width: 568px) {
    grid-template-columns: auto;
  }
`;

export const PlanUspItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PlanUspItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #e3f6ff;
  color: ${p => p.theme.colors.Primary};
`;
