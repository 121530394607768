import styled from 'styled-components';
import { SegmentPage } from '../../../Segment/Segment.styled';

export const ScheduledConferenceViewWrapper = styled(SegmentPage)`
  display: flex;
  background-color: ${p => p.theme.colors.Surface};
  overflow-y: auto;
  flex-direction: column;

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
    padding: ${spacing.xl} 2.5rem;
  `}

  ${({ theme: { breakpoints, spacing } }) => breakpoints.lg`
    padding: ${spacing.xxl} 6.3rem;
  `}
`;

export const ScheduledConferenceViewSection = styled.div`
  max-width: 29rem;
  width: 100%;
  margin-top: 1rem;
  padding: 0 1.5rem 1rem 1.5rem;
`;
