import styled from 'styled-components';

export const DomainField = styled.div`
  & ~ & {
    margin-top: 1rem;
  }
`;

export const DomainFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.625rem;

  button ~ button {
    margin-left: 0.375rem;
  }
`;
