import styled from 'styled-components';

export const StyledWorkspaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const WorkspaceTitle = styled.div<{ lightBackground?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.fontTemplates.regularSemibold};
  line-height: 1.43;
  color: ${({ lightBackground, theme }) =>
    lightBackground
      ? theme.colors.OnSurfaceHighEmphasis
      : theme.colors.OnNavbar};
`;
