import styled from 'styled-components';

export const StyledBillingPageBannerBanner = styled.div`
  width: 100%;
  padding: 2rem;
  margin-top: 2rem;

  ${({ theme: { breakpoints } }) => breakpoints.md`
     max-width: 384px;
  `}
`;

export const BillingPageBannerItem = styled.div`
  position: relative;

  & ~ & {
    margin-top: 2rem;
  }
`;

export const BillingPageBannerItemTitle = styled.h3`
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 1.25rem;
  line-height: 1.2;
`;

export const BillingPageBannerItemDescription = styled.p`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  white-space: pre-line;
`;

export const BillingPageBannerIcon = styled.img`
  position: absolute;
  top: 0;
  left: -2rem;
  width: 1.25rem;
  height: 1.25rem;
`;
