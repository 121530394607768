import styled from 'styled-components';

export const StyledMarketplaceCategoriesWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl}
    ${({ theme }) => theme.spacing.xs} 0;

  .title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: ${p => p.theme.colors.OnSurface};
  }
`;

export const StyledMarketplaceCategoriesList = styled.div`
  margin-top: 1.125rem;
  display: flex;
  flex-direction: column;
`;
