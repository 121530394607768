import React, { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import { BellIcon } from '../../shared/icons';
import {
  Badge,
  BadgeWrapper,
  StyledNotifications,
} from './Notifications.styled';
import { NotificationsList } from './NotificationsList';
import {
  useGetNotificationsQuery,
  useNotifications,
} from './Notifications.hooks';
import { useCurrentAccount } from '../Auth/Auth.hooks';
import { useLocation } from 'react-router-dom';
import { useCurrentWorkspace } from '../Workspace/Workspace.hooks';
import { NOTIFICATIONS_INITIAL_LOAD_COUNT } from './Notifications.constants';
import { CountBadge } from '../../shared/components/CountBadge';
import { getCurrentWorkspaceInfo } from '../Workspace/Workspace.utils';
import { CounterType, useUnreadCounter } from '../Counters';
import { TooltipPlace } from '../../shared/components/Tooltip';
import { DesktopTranslation } from '../Desktop/i18n';
import { useIntl } from 'react-intl';
import { getShortId } from '../../shared/utils/id';

export const Notifications = () => {
  const location = useLocation();
  const { account } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const notificationsNode: Ref<HTMLDivElement> = useRef(null);
  const notifications = useNotifications();
  const { formatMessage } = useIntl();

  const toggleNotificationsHandler = useCallback(() => {
    notifications.toggleNotifications();
  }, [notifications]);

  const currentWorkspaceInfo = useMemo(
    () =>
      account && workspace.id
        ? getCurrentWorkspaceInfo(account, getShortId(workspace.id))
        : null,
    [account, workspace.id],
  );

  /**
   * Clicks outside should close the list
   */
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        !notifications.keepOpen &&
        notifications.isOpen &&
        notificationsNode.current &&
        notificationsNode.current.contains(e.target as HTMLElement)
      ) {
        return;
      }
      if (!notifications.keepOpen) {
        notifications.closeNotifications();
      }
    },
    [notifications],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  /**
   * Make sure notifications are closed when changing routes
   */
  let previousLocationPathname = useRef('');
  useEffect(() => {
    if (location.pathname !== previousLocationPathname.current) {
      previousLocationPathname.current = location.pathname;
      notifications.closeNotifications();
    }
  }, [location, notifications, previousLocationPathname]);

  const { data, loading, fetchMore } = useGetNotificationsQuery({
    skip: !currentWorkspaceInfo?.account,
    variables: {
      accountId: currentWorkspaceInfo?.account || '',
      workspaceId: workspace!.id,
      first: NOTIFICATIONS_INITIAL_LOAD_COUNT,
    },
  });

  const unreadCount = useUnreadCounter(workspace.id, CounterType.Notifications);

  return (
    <StyledNotifications ref={notificationsNode}>
      <BadgeWrapper>
        <Badge
          data-tooltip-id="global-tooltip"
          data-tooltip-content={formatMessage({
            id: DesktopTranslation.tooltipNotificationsButton,
          })}
          data-tooltip-place={TooltipPlace.bottom}
          icon={() => <BellIcon height={20} />}
          hasUnread={!!unreadCount}
          onClick={toggleNotificationsHandler}
          open={notifications.isOpen}
          contentFill={true}
          data-tourid="notifications-bell"
          data-testid="notifications-bell"
        />
        <CountBadge
          offsetRight={-3}
          offsetTop={-2}
          count={unreadCount}
          limitCount={true}
        />
      </BadgeWrapper>
      {notifications.isOpen && data && (
        <NotificationsList
          notifications={data}
          loading={loading}
          fetchMore={fetchMore}
          workspaceInfo={currentWorkspaceInfo}
        />
      )}
    </StyledNotifications>
  );
};
