import React, { FC, useCallback, useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../../Workspace/Workspace.hooks';
import {
  CHATS_CONVERSATION_PATHNAME,
  CHATS_ROOT_PATHNAME,
} from '../../../Desktop/Desktop.constants';
import { ConversationType } from '../../Chat.types';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { StyledAccountChatFinder } from './AccountChatFinder.styled';
import { getShortId } from '../../../../shared/utils/id';
import { useChatConversationRepository } from '../../Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { database } from '../../../Database';
import { useLiveQuery } from 'dexie-react-hooks';
import { filterOutNonChatConversations } from '../../ChatSegment/ChatSegment.utils';
import type { ChatConversationsTableType } from '../../../Database/ChatConversationsTable/ChatConversationsTable';

const CREATE_CONVERSATION_REDIRECT_TIME_OUT = 400;

export const AccountChatFinder: FC = () => {
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { accountId } = useParams<{ accountId?: string }>();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { createConversation } = useChatConversationRepository();
  const processing = useRef(false);

  useLiveQuery(
    () =>
      database.chatConversations
        .where('workspaceId')
        .equals(workspace.id)
        .sortBy('lastMessageAt')
        .then(response =>
          findOrCreateChat(filterOutNonChatConversations(response)),
        )
        .catch(() => []),
    [workspace.id],
    [],
  );

  const findOrCreateChat = (conversations: ChatConversationsTableType[]) => {
    if (!accountId) {
      navigate(
        generatePath(CHATS_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
        { replace: true },
      );
      return;
    }

    if (processing.current) {
      return;
    }

    processing.current = true;

    const fullAccountId = `/accounts/${accountId}`;

    const accountConversation = conversations.find(
      ({ userIds, type }) =>
        type === ConversationType.private && userIds.includes(fullAccountId),
    );

    if (accountConversation) {
      const path = generatePath(CHATS_CONVERSATION_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        conversationId: getShortId(accountConversation.id),
      });

      navigate(path, { replace: true });
      return;
    }

    createChatConversation();
  };

  const createChatConversation = useCallback(async () => {
    const fullAccountId = `/accounts/${accountId}`;

    createConversation([fullAccountId, currentAccount.id])
      .then(conversationId => {
        if (conversationId) {
          //!@NIKA_BERIDZE ---> Refactor is needed here, main issue is with useRedirectChats
          setTimeout(() => {
            navigate(
              generatePath(CHATS_CONVERSATION_PATHNAME, {
                workspaceId: getShortId(workspace.id),
                conversationId: getShortId(conversationId),
              }),
              { replace: true },
            );
          }, CREATE_CONVERSATION_REDIRECT_TIME_OUT);
        }
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      })
      .finally(() => (processing.current = false));
  }, [
    accountId,
    createConversation,
    currentAccount.id,
    navigate,
    workspace.id,
  ]);

  return (
    <StyledAccountChatFinder>
      <Spinner />
    </StyledAccountChatFinder>
  );
};
