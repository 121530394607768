import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../../../../shared/components/Button/Button';

export const StyledNotificationContainer = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  padding: 0.625rem;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }
`;

export const NotificationLink = styled(Link)`
  width: 100%;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
`;

export const NotificationButton = styled(Button)`
  width: 100%;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
`;

export const NotificationModal = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
`;
