import { TeamsTableType } from '../../../../Database/WorkspaceTeams/WorkspaceTeamsTable';

export const addAccountToTeamRelatedAccounts = (
  relatedAccountIds: string[] = [],
  newAccountId: string,
) => {
  return Array.from(new Set([...relatedAccountIds, newAccountId]));
};

export const createTeamRowsMap = (teamRows: TeamsTableType[]) => {
  return (
    teamRows?.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr };
    }, {} as Record<string, TeamsTableType>) || {}
  );
};
