import React, { FC, memo, MutableRefObject } from 'react';
import { GlobalSearchResult } from '../../GlobalSearch.queries';
import { SearchResultTypes } from '../ExpandedSearch.constants';
import {
  AppItem,
  ChatItem,
  FolderItem,
  LinkItem,
  MeetingItem,
} from './SearchItems';
import { LinkCommentItem } from './SearchItems/LinkCommentItem';

interface SearchResultItemProps extends GlobalSearchResult {
  onBlur: (redirectTo?: string) => void;
  skipBlurRef: MutableRefObject<boolean>;
  searchQuery: string;
  searchOnlyByConversation: boolean;
}

export const SearchResultItem: FC<SearchResultItemProps> = memo(
  ({
    skipBlurRef,
    searchQuery,
    hitType,
    folderHit,
    desktopAppHit,
    linkCommentHit,
    linkHit,
    chatConversationHit,
    scheduleChatConferenceHit,
    repeatingScheduleChatConferenceHit,
    chatMessageHit,
    searchOnlyByConversation,
    onBlur,
  }) => {
    switch (hitType) {
      case SearchResultTypes.app:
        return desktopAppHit ? (
          <AppItem
            {...desktopAppHit}
            onBlur={onBlur}
            skipBlurRef={skipBlurRef}
          />
        ) : null;
      case SearchResultTypes.link:
        return linkHit ? (
          <LinkItem {...linkHit} skipBlurRef={skipBlurRef} />
        ) : null;
      case SearchResultTypes.folder:
        return folderHit ? <FolderItem {...folderHit} onBlur={onBlur} /> : null;
      case SearchResultTypes.linkComment:
        return linkCommentHit ? (
          <LinkCommentItem
            {...linkCommentHit}
            desktopIri={chatConversationHit?.desktopIri}
            linkIri={chatConversationHit?.linkIri}
          />
        ) : null;
      case SearchResultTypes.meeting:
        return <MeetingItem {...scheduleChatConferenceHit} onBlur={onBlur} />;
      case SearchResultTypes.repeatableMeeting:
        return (
          <MeetingItem
            {...repeatingScheduleChatConferenceHit}
            scheduleChatConferenceId={scheduleChatConferenceHit?.id}
            onBlur={onBlur}
          />
        );
      case SearchResultTypes.chatMessage:
      case SearchResultTypes.chatConversation:
        return chatMessageHit || chatConversationHit ? (
          <ChatItem
            {...chatMessageHit}
            conversationIri={
              chatMessageHit?.conversationIri || chatConversationHit!.id
            }
            accountIri={
              chatMessageHit?.accountIri ?? chatConversationHit?.users[0]
            }
            type={chatConversationHit!.type}
            onBlur={onBlur}
            searchQuery={searchQuery}
            users={chatConversationHit!.users}
            customConversationTitle={chatConversationHit?.conversationTitle}
            searchOnlyByConversation={searchOnlyByConversation}
          />
        ) : null;

      default:
        return null;
    }
  },
);
