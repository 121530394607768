import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import { CopyIcon } from '../../../../shared/icons';
import {
  DESKTOP_HEADER_PLUS_FOOTER_HEIGHT,
  MOBILE_HEADER_PLUS_FOOTER_HEIGHT,
} from '../../../Theme';

export const StyledPasswordViewWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - ${MOBILE_HEADER_PLUS_FOOTER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 1rem;

  ${({ theme: { breakpoints } }) => breakpoints.xlg`
    padding: 5.375rem 7.5rem 6.625rem 7.5rem;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.md`
     max-height: calc(100vh - ${DESKTOP_HEADER_PLUS_FOOTER_HEIGHT}px);
  `}
`;

export const StyledPasswordViewTitle = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 1rem;
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  margin: 2rem 0;
  background-color: ${p => p.theme.colors.Divider};
`;

export const StyledPasswordViewBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
`;

export const StyledPasswordBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  font-weight: 500;

  &:nth-child(3) {
    margin-top: 0.25rem;
  }

  span {
    max-width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledPasswordWithCopyButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  & > div {
    display: -webkit-box;
    max-width: calc(100% - 2rem);
    overflow: hidden;
  }
`;

export const StyledBodyRowTitle = styled.div`
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  width: 6.5rem;
`;

export const StyledPasswordStrength = styled.div<{ color?: string }>`
  background-color: ${p => p.color};
  width: 12.5rem;
  height: 0.5rem;
  border-radius: 1rem;
`;

export const StyledControlsWrapper = styled.div<{
  isMobile?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * ~ * {
    margin-left: 1rem;
  }

  ${p =>
    p.isMobile &&
    css`
      button {
        padding: 0.25rem 0.5rem;
      }
    `}
`;

export const StyledSharePasswordButtonWrapper = styled.div`
  width: 100%;
`;

export const StyledEditPasswordButton = styled(ButtonWithIcon)`
  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledDeletePasswordButton = styled(ButtonWithIcon)`
  color: ${p => p.theme.colors.CriticalDark};
`;

export const StyledPasswordCopyButton = styled(ButtonWithIcon).attrs({
  icon: CopyIcon,
})`
  padding: 0.25rem;

  svg {
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:active {
    outline: none;
    background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.PrimaryLight};
  }
`;
