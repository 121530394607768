import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../i18n';
import {
  CommunicateIcon,
  OrganizeAndSearchIcon,
} from '../../../../shared/icons';
import {
  ChooseFeatureFormValues,
  ChooseFeatureType,
} from '../../Onboarding.types';
import {
  ChooseFeatureButtonSubtitle,
  ChooseFeatureButtonTitle,
  ChooseFeatureFormFields,
  ChooseFeatureNextButton,
  ChooseFeatureTile,
  ChooseFeatureTileWrapper,
} from './ChooseFeaturePage.styled';
import {
  Checkbox,
  CheckboxShapeType,
} from '../../../../shared/components/Checkbox';
import { showToastInfoMessage } from '../../../../shared/components/Toast';

export interface ChooseFeatureFormProps {
  onSubmit: (values: ChooseFeatureFormValues) => void;
}

export const initialValues: ChooseFeatureFormValues = {
  organizeAndSearch: true,
  communicate: false,
};

export const ChooseFeatureForm: FC<ChooseFeatureFormProps> = ({ onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => {
        const isFeatureSelected =
          !values.communicate && !values.organizeAndSearch;
        const customToastId = 'choose-feature-toast-id';

        if (isFeatureSelected) {
          showToastInfoMessage(
            OnboardingTranslation.onboardingChooseFeatureInfoToast,
            {},
            {
              toastId: customToastId,
            },
          );
        }
        return (
          <Form data-testid="choose-feature-form">
            <ChooseFeatureFormFields>
              <Field
                name="organizeAndSearch"
                type="checkbox"
                value={ChooseFeatureType.organize_and_search}
                component={() => (
                  <label
                    htmlFor={ChooseFeatureType.organize_and_search}
                    data-testid="organize-and-search-panel">
                    <ChooseFeatureTile checked={values.organizeAndSearch}>
                      <OrganizeAndSearchIcon />
                      <ChooseFeatureTileWrapper>
                        <ChooseFeatureButtonTitle data-testid="title">
                          <FormattedMessage
                            id={
                              OnboardingTranslation.onboardingOrganizeAndSearchFeatureTitle
                            }
                          />
                        </ChooseFeatureButtonTitle>
                        <ChooseFeatureButtonSubtitle data-testid="subtitle">
                          <FormattedMessage
                            id={
                              OnboardingTranslation.onboardingOrganizeAndSearchFeatureSubtitle
                            }
                          />
                        </ChooseFeatureButtonSubtitle>
                      </ChooseFeatureTileWrapper>

                      <Checkbox
                        id={ChooseFeatureType.organize_and_search}
                        checked={values.organizeAndSearch ? true : false}
                        onChange={() =>
                          setFieldValue(
                            'organizeAndSearch',
                            !values.organizeAndSearch,
                          )
                        }
                        shape={CheckboxShapeType.circle}
                        hidden={values.organizeAndSearch ? false : true}
                        data-testid="checkbox"
                      />
                    </ChooseFeatureTile>
                  </label>
                )}
              />

              <Field
                name="communicate"
                type="checkbox"
                value={ChooseFeatureType.communicate}
                component={() => (
                  <label
                    htmlFor={ChooseFeatureType.communicate}
                    data-testid="communicate-panel">
                    <ChooseFeatureTile checked={values.communicate}>
                      <CommunicateIcon />
                      <ChooseFeatureTileWrapper>
                        <ChooseFeatureButtonTitle data-testid="title">
                          <FormattedMessage
                            id={
                              OnboardingTranslation.onboardingCommunicateTitle
                            }
                          />
                        </ChooseFeatureButtonTitle>
                        <ChooseFeatureButtonSubtitle data-testid="subtitle">
                          <FormattedMessage
                            id={
                              OnboardingTranslation.onboardingCommunicateSubtitle
                            }
                          />
                        </ChooseFeatureButtonSubtitle>
                      </ChooseFeatureTileWrapper>

                      <Checkbox
                        id={ChooseFeatureType.communicate}
                        checked={values.communicate ? true : false}
                        onChange={() =>
                          setFieldValue('communicate', !values.communicate)
                        }
                        shape={CheckboxShapeType.circle}
                        hidden={values.communicate ? false : true}
                        data-testid="checkbox"
                      />
                    </ChooseFeatureTile>
                  </label>
                )}
              />
            </ChooseFeatureFormFields>

            <ChooseFeatureNextButton
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              fullWidth
              type="submit"
              disabled={isSubmitting || isFeatureSelected}
              data-testid="next-button">
              <FormattedMessage
                id={OnboardingTranslation.onboardingUseCaseNextButton}
              />
            </ChooseFeatureNextButton>
          </Form>
        );
      }}
    </Formik>
  );
};
