export const loadScript = (src: string) => {
  return new Promise<void>(function(resolve, reject) {
    const scriptElement = document.createElement('script') as HTMLScriptElement;
    let ready = false;
    scriptElement.type = 'text/javascript';
    scriptElement.src = src;
    scriptElement.async = true;
    scriptElement.onerror = function(err) {
      reject(err);
    };
    scriptElement.onload = function() {
      // @ts-ignore
      if (!ready && (!this.readyState || this.readyState === 'complete')) {
        ready = true;
        resolve();
      }
    };
    const scriptTag = document.getElementsByTagName(
      'script',
    )[0] as HTMLScriptElement;
    scriptTag.parentElement?.insertBefore(scriptElement, scriptTag);
  });
};
