import styled, { css } from 'styled-components';
import { ChatAvatar } from '../../../../../Chat/ChatAvatar';

export const OptionInner = styled.div<{ isFocused: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.635rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  ${p =>
    p.isFocused &&
    css`
      background-color: ${p => p.theme.colors.Background};
    `}
`;

export const OptionLabel = styled.span`
  margin-right: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const OptionChatAvatar = styled(ChatAvatar)`
  margin-right: 0.625rem;
`;

export const OptionTeamIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-right: 0.625rem;
`;

export const StyledUserRole = styled.span`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  font-size: 0.75rem;
`;
