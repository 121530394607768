import React, { FC, useCallback, useRef } from 'react';
import { TimelineEventApiType } from '../../Timeline.types';
import {
  StyledTimelineEvent,
  TimelineCreatedBy,
  TimelineEventDate,
  TimelineEventImage,
  TimelineEventImageContainer,
  TimelineEventInfo,
  TimeLineLabelInner,
  TimelineMenuButton,
} from './TimelineEvent.styled';

import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import { format } from 'date-fns';
import { useCurrentWorkspaceAccount } from '../../../Workspace/Workspace.hooks';
import { appEnv } from '../../../../appEnv';
import { DotsIcon } from '../../../../shared/icons';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../shared/components/Menu';
import { ClockCircleArrowIcon } from '../../../../shared/icons/ClockCircleArrow';
import { useConfirm } from '../../../../shared/components/Modal/ConfirmModal';
import { useUndoTimelineEventMutation } from '../../Timeline.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import { TimelineEventUndoTitle } from './TimelineEventUndoTitle';
import { TimelineEventUndoType } from './TimelineEventUndoType';
import { TimelineEventTitle } from './TimelineEventTitle';

export interface TimelineEventProps {
  event: TimelineEventApiType;
}

export const TimelineEvent: FC<TimelineEventProps> = ({ event }) => {
  const { image, context, id: eventId, canUndo } = event;
  const intl = useIntl();
  const { account } = useCurrentWorkspaceAccount();
  const eventPersonalCreated = context.created_by === account!.id;
  const iconRef = useRef(null);
  const { askConfirmation } = useConfirm();

  const renderEventTime = () => {
    return (
      <FormattedMessage
        id={TimelineTranslation.eventTime}
        values={{
          time: format(new Date(event.occurredAt), appEnv.TIME_FORMAT),
        }}
      />
    );
  };

  const buttonRef = useRef(null);

  const [undoTimelineEventMutation] = useUndoTimelineEventMutation();

  const unDoEventHandler = useCallback(() => {
    askConfirmation(
      <FormattedMessage
        id={TimelineTranslation.eventUnDoItemConfirmationDescription}
        values={{
          itemType: <TimelineEventUndoType event={event} />,
        }}
      />,
      <FormattedMessage
        id={TimelineTranslation.eventUnDoItemConfirmationTitle}
        values={{
          itemName: <TimelineEventUndoTitle event={event} />,
        }}
      />,
      {
        width: 384,
      },
    ).then(confirm => {
      if (!confirm) {
        return;
      }
      return undoTimelineEventMutation({
        variables: { input: { id: eventId } },
      })
        .then(() => {
          showToastSuccessMessage(TimelineTranslation.eventRestoreItemMessage, {
            itemType: <TimelineEventUndoType event={event} />,
          });
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
        });
    });
  }, [askConfirmation, event, eventId, undoTimelineEventMutation]);

  return (
    <StyledTimelineEvent>
      <TimelineEventImageContainer>
        {image?.contentUrl && (
          <TimelineEventImage src={image.contentUrl} ref={iconRef} />
        )}
      </TimelineEventImageContainer>
      <TimeLineLabelInner>
        <TimelineEventInfo>
          <TimelineEventTitle event={event} />
          {context.created_by_name && (
            <TimelineCreatedBy>
              <FormattedHTMLMessage
                id={TimelineTranslation.eventCreatedBy}
                values={{
                  memberName: eventPersonalCreated
                    ? intl.formatMessage({
                        id: TimelineTranslation.eventCreatedByMeLabel,
                      })
                    : context.created_by_name,
                }}
              />
            </TimelineCreatedBy>
          )}
          <TimelineEventDate>{renderEventTime()}</TimelineEventDate>
        </TimelineEventInfo>

        {canUndo && (
          <TimelineMenuButton
            ref={buttonRef}
            icon={DotsIcon}
            onClick={e => e.preventDefault()}
          />
        )}
      </TimeLineLabelInner>
      <Menu
        trigger={buttonRef}
        width={100}
        vAlign={VerticalAlignment.BOTTOM}
        hAlign={HorizontalAlignment.RIGHT}
        data-testid="timeline-menu">
        {canUndo && (
          <MenuItem
            onClick={unDoEventHandler}
            icon={() => <ClockCircleArrowIcon width={20} height={20} />}
            data-testid="undo-timeline-event">
            <FormattedMessage id={TimelineTranslation.eventUndoMenuItem} />
          </MenuItem>
        )}
      </Menu>
    </StyledTimelineEvent>
  );
};
