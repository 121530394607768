import React, { FC, useCallback } from 'react';
import { InitialBillingForm } from '../../../BillingForms/InitialBillingForm';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { OnboardBillingContactFormValues } from '../../../Billing.types';
import { FormikHelpers } from 'formik';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../../Workspace/Workspace.hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
} from '../../../../../shared/components/Toast';
import { stripeErrorCodeToMessage } from '../../../Billing.utils';
import { useCreatePaymentCustomerMutation } from '../../../Billing.hooks';
import { BillingTranslation } from '../../../i18n';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { useIntl } from 'react-intl';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../shared/hooks';
import { PaymentMethod } from '@stripe/stripe-js';

interface AddPaymentMethodProps {
  ctaText?: string;
  withCurrentTheme?: boolean;
}

export const AddPaymentMethod: FC<AddPaymentMethodProps> = ({
  ctaText,
  withCurrentTheme,
}) => {
  const { refetchAccountData } = useCurrentAccount();
  const { refetchWorkspaces } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const intl = useIntl();
  const queryParams = useQueryParams();

  const { workspaceId } = useParams<{ workspaceId: string }>();

  const handleSubmit = (
    values: OnboardBillingContactFormValues,
    formikHelpers: FormikHelpers<OnboardBillingContactFormValues>,
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const { setSubmitting } = formikHelpers;

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) || {
          token: 'TODO: fix this type',
        },
        billing_details: {
          email: values.email,
          name: values.name,
          address: {
            city: values.city,
            country: values.country,
            line1: values.line1,
            line2: values.line2,
            postal_code: values.postalCode,
            state: values.state,
          },
        },
      })
      .then(result => {
        const { error } = result;
        if (error) {
          setSubmitting(false);
          showToastErrorMessage(stripeErrorCodeToMessage(error.code));
        } else {
          createPaymentCustomer(result, values, setSubmitting);
        }
      });
  };

  const [createPaymentCustomerMutation] = useCreatePaymentCustomerMutation();

  const createPaymentCustomer = useCallback(
    (
      { paymentMethod }: { paymentMethod: PaymentMethod },
      {
        billingName,
        coupon,
        vatType,
        vatNumber,
      }: OnboardBillingContactFormValues,
      setSubmitting: (value: boolean) => void,
    ) => {
      createPaymentCustomerMutation({
        variables: {
          input: {
            paymentMethodId: paymentMethod.id,
            billingEmail: paymentMethod.billing_details.email,
            workspace: `/workspaces/${workspaceId}`,
            postalCode:
              paymentMethod.billing_details.address?.postal_code ?? null,
            country: paymentMethod.billing_details.address?.country ?? null,
            city: paymentMethod.billing_details.address?.city ?? null,
            line1: paymentMethod.billing_details.address?.line1 ?? null,
            line2: paymentMethod.billing_details.address?.line2 ?? null,
            state: paymentMethod.billing_details.address?.state ?? null,
            coupon: coupon || null,
            billingName,
            vatType: vatType || null,
            vatNumber: vatNumber || null,
          },
        },
      })
        .then(async ({ errors }) => {
          if (errors) {
            showToastGraphQLErrors([...errors]);
            setSubmitting(false);
          }

          if (refetchAccountData) {
            await refetchAccountData();
          }
          if (refetchWorkspaces) {
            await refetchWorkspaces();
          }
          if (refetchPermissions) {
            await refetchPermissions();
          }

          navigate({
            search: getQueryParamsFrom({
              ...queryParams,
              upgradeSuccess: true,
            }),
          });
        })
        .catch(err => {
          showToastGraphQLErrors(err.graphQLErrors);
          setSubmitting(false);
        });
    },
    [
      createPaymentCustomerMutation,
      workspaceId,
      refetchAccountData,
      refetchWorkspaces,
      refetchPermissions,
      navigate,
      queryParams,
    ],
  );

  return (
    <InitialBillingForm
      handleSubmit={handleSubmit}
      stripe={stripe}
      ctaText={
        ctaText ||
        intl.formatMessage({
          id: BillingTranslation.upgradeFreeModalTitle,
        })
      }
      withCurrentTheme={withCurrentTheme}
    />
  );
};
