import React, {
  FC,
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ChatDate,
  ChatMessageMenuButton,
  IntegrationBadgeContainer,
  MessageBody,
  MessageBubble,
  MessageContainer,
  MessageItemContainer,
  MessageModifiedLabel,
  StyledAccountName,
  StyledChatMessage,
  StyledMessageDate,
  StyledMessageOwnerName,
  SystemChatMessage,
} from './ChatMessage.styled';
import type {
  ChatMessageAssetApiType,
  ChatMessageInternalType,
  ChatTextMessageInternalType,
} from '../../../Chat.types';
import { ConversationType, MessageType } from '../../../Chat.types';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';
import {
  AccountApiType,
  AccountOrigin,
  AvailabilityStatusType,
} from '../../../../User/User.types';
import { ChatAvatar } from '../../../ChatAvatar';
import { getAccountName } from '../../../../User/User.utils';
import { ChatTranslation } from '../../../i18n';
import { ConferenceMessage } from './ConferenceMessage';
import { LinkMessage } from './LinkMessage';
import { CallFilledIcon, ChatReactionIcon } from '../../../../../shared/icons';
import { RichMessage } from './RichMessage';
import { EditMessageForm, EditMessageFormProps } from '../EditMessageForm';
import { MessageReactions } from './MessageReactions';
import { useEmojiPickerControls } from '../../../EmojiPickerPopover';
import { useMessageReactionControls } from './MessageReactions/MessageReactions.hooks';
import { useMobile } from '../../../../../shared/hooks';
import {
  isSystemMessage,
  makeChatMessageDomId,
  messageTooltipFullDateTime,
} from './ChatMessage.utils';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useCurrentConversation } from '../../../Chat.hooks';
import { IntegrationBadge } from '../../../ChatAvatar/IntegrationBadge';
import { MessageAssetContainer } from './MessageAsset/MessageAssetContainer';
import { useMessageAuthor, useMessageDate } from './ChatMessage.hooks';
import { ChatMessageAction } from './ChatMessageActions';
import { ChatMessageUnreadBadge } from './ChatMessageUnreadBadge';
import { useMessageListDataManagerContext } from '../MessagesList/MessageList.hooks';
import { useIsInViewport } from '../../../../../shared/hooks/dom.hooks';
import { ChatMessageThreadViewWrapper } from '../ChatMessageThread/ChatMessageThreadViewWrapper';
import { useAccountsContext } from '../../../../Account';
import { SkeletonBox } from '../../../../../shared/components/SkeletonBox';

export const REMOVE_UNSEEN_BADGE_DELAY = 1500;

interface ChatMessageProps {
  chatMessage: ChatMessageInternalType;
  onAvatarClick?: (
    account: AccountApiType,
    ref: RefObject<HTMLElement>,
  ) => void;
  secondary?: boolean;
  onMenu: (
    chatMessage: ChatTextMessageInternalType,
    domRef: RefObject<HTMLElement>,
  ) => void;
  onAssetMenu: (
    chatMessage: ChatTextMessageInternalType,
    asset: ChatMessageAssetApiType,
    domRef: RefObject<HTMLElement>,
  ) => void;
  isEditing?: boolean;
  onEditSave?: EditMessageFormProps['onSave'];
  onEditCancel?: EditMessageFormProps['onCancel'];
  className?: string;
  allowReply?: boolean;
  isScrolling?: boolean;
  showTread?: boolean;
  threadMessageDomId?: string;
}

export const ChatMessage: FC<ChatMessageProps> = React.memo(
  ({
    chatMessage,
    onAvatarClick,
    secondary = false,
    onMenu,
    onAssetMenu,
    isEditing = false,
    onEditSave,
    onEditCancel,
    className,
    allowReply = true,
    isScrolling = false,
    showTread = true,
    threadMessageDomId,
  }) => {
    const { formatMessage } = useIntl();
    const { showEmojiPicker } = useEmojiPickerControls();
    const { account } = useCurrentWorkspaceAccount();
    const { addUnreadMessage } = useMessageListDataManagerContext();
    const isMobile = useMobile();
    const currentAccountId = account?.id;
    const elementRef = useRef<HTMLDivElement>(null);
    const messageIsInView = useIsInViewport(elementRef);
    const avatarRef = useRef<HTMLImageElement>(null);
    const messageReactionButtonRef = useRef<HTMLButtonElement | null>(null);
    const messageDate = useMessageDate(chatMessage.createdAt);
    const { loading: accountsAvailabilityLoading } = useAccountsContext();
    const messageAuthor = useMessageAuthor(chatMessage.authorId);
    const systemMessage = isSystemMessage(chatMessage.authorId, messageAuthor);

    const hasReactions = !!chatMessage.reactions.length;
    const hasThreadMessages = chatMessage.threadMessagesCount > 0;

    const { addReactionToChatMessage } =
      useMessageReactionControls(chatMessage);

    const [messageIsSeen, setMessageIsSeen] = useState(chatMessage.isSeen);

    useEffect(() => {
      setMessageIsSeen(chatMessage.isSeen);
    }, [chatMessage.isSeen]);

    const showInCallBadge =
      chatMessage.type !== MessageType.System &&
      messageAuthor?.onCall &&
      messageAuthor?.onlineStatus !== AvailabilityStatusType.Offline;

    const { conversation: currentConversation } = useCurrentConversation();

    const isBotChat =
      currentConversation?.type === ConversationType.appIntegration;

    const singleChatLinkIri =
      'links' in chatMessage.context &&
      chatMessage.context.links &&
      chatMessage.context.links.length === 1 &&
      chatMessage.context.links[0];

    const ownMessage = chatMessage.authorId
      ? currentAccountId === chatMessage.authorId
      : false;

    const accountOrigin =
      messageAuthor?.origin || account.origin || AccountOrigin.webtopcom;

    const isSlackUser = accountOrigin === AccountOrigin.slack;

    const withReactions =
      (!hasThreadMessages && allowReply && !systemMessage) ||
      ownMessage ||
      (!hasReactions && !systemMessage);

    const withAssets =
      (chatMessage.type === MessageType.Message ||
        chatMessage.type === MessageType.ThreadMessage) &&
      chatMessage.context.assets;

    const markMessageAsSeen = useCallback(() => {
      if (messageIsInView && !messageIsSeen) {
        addUnreadMessage(chatMessage);

        setMessageIsSeen(true);
      }
    }, [addUnreadMessage, chatMessage, messageIsInView, messageIsSeen]);

    useEffect(() => {
      markMessageAsSeen();
    }, [markMessageAsSeen]);

    const addMessageReaction: MouseEventHandler<HTMLButtonElement> =
      useCallback(
        e => {
          if (!(e.currentTarget instanceof HTMLElement)) {
            return;
          }

          messageReactionButtonRef.current = e.currentTarget;

          showEmojiPicker(messageReactionButtonRef)
            .then(emoji => {
              if (!('native' in emoji)) {
                return;
              }

              addReactionToChatMessage(emoji.native);
            })
            .catch(() => {});
        },
        [addReactionToChatMessage, showEmojiPicker],
      );

    const makeChatDomId = useMemo(
      () => makeChatMessageDomId(chatMessage.id),
      [chatMessage.id],
    );

    return (
      <StyledChatMessage
        ref={elementRef}
        id={threadMessageDomId || makeChatDomId}
        secondary={secondary}
        data-testid="chat-message"
        data-entityid={chatMessage.id}
        withReaction={withReactions}
        isMobile={isMobile}
        messageType={chatMessage.type}
        className={className}>
        <MessageContainer secondary={secondary}>
          {!secondary && (
            <ChatAvatar
              className="avatar"
              ref={avatarRef}
              isLoading={accountsAvailabilityLoading}
              onClick={() => {
                if (!messageAuthor || systemMessage) {
                  return;
                }
                onAvatarClick?.(messageAuthor, avatarRef);
              }}
              account={messageAuthor ?? MessageType.System}
              showOnlineStatus={false}
            />
          )}
          <MessageBody data-testid="message-body">
            {!secondary && (
              <ChatDate data-testid="chat-message-date" isMobile={isMobile}>
                <StyledMessageOwnerName>
                  {ownMessage ? (
                    <FormattedMessage
                      id={ChatTranslation.labelsYou}
                      tagName="span"
                    />
                  ) : chatMessage.type === MessageType.System ? (
                    <FormattedMessage
                      id={ChatTranslation.labelsSystem}
                      tagName="span"
                    />
                  ) : accountsAvailabilityLoading ? (
                    <SkeletonBox borderRadius={0.25} width={6} height={1.25} />
                  ) : messageAuthor ? (
                    <StyledAccountName>
                      {getAccountName(messageAuthor)}
                    </StyledAccountName>
                  ) : null}
                </StyledMessageOwnerName>

                {showInCallBadge && (
                  <CallFilledIcon
                    width={20}
                    height={20}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-place={TooltipPlace.bottom}
                    data-tooltip-content={formatMessage({
                      id: ChatTranslation.peoplePanelInACallBadge,
                    })}
                  />
                )}

                <StyledMessageDate
                  data-tooltip-id="global-tooltip"
                  data-tooltip-place={TooltipPlace.top}
                  data-tooltip-content={messageTooltipFullDateTime(
                    chatMessage.createdAt,
                  )}>
                  {messageDate}
                </StyledMessageDate>

                <ChatMessageUnreadBadge show={!messageIsSeen} />

                {isSlackUser && (
                  <IntegrationBadgeContainer>
                    <IntegrationBadge
                      origin={AccountOrigin.slack}
                      badgeSize={20}
                    />
                  </IntegrationBadgeContainer>
                )}
              </ChatDate>
            )}

            {chatMessage.type === MessageType.Conference && (
              <ConferenceMessage chatMessage={chatMessage} />
            )}

            {isEditing &&
              onEditSave &&
              onEditCancel &&
              (chatMessage.type === MessageType.Message ||
                chatMessage.type === MessageType.ThreadMessage) && (
                <EditMessageForm
                  chatMessage={chatMessage}
                  onSave={onEditSave}
                  onCancel={onEditCancel}
                />
              )}
            <>
              {chatMessage.message && !isEditing && (
                <MessageItemContainer
                  data-testid="message-item-container"
                  isSystem={systemMessage}
                  isMobile={isMobile}>
                  <MessageBubble
                    data-testid="message-bubble"
                    ownMessage={ownMessage}>
                    {systemMessage ? (
                      <SystemChatMessage data-testid="system-message-content">
                        <RichMessage isSystemMessage={systemMessage}>
                          {chatMessage.message}
                        </RichMessage>
                      </SystemChatMessage>
                    ) : (
                      <span data-testid="message-content">
                        <RichMessage>{chatMessage.message}</RichMessage>
                      </span>
                    )}

                    {chatMessage.isModified && (
                      <>
                        {' '}
                        <MessageModifiedLabel data-testid="message-modified">
                          <FormattedMessage
                            id={ChatTranslation.messageModifiedLabel}
                          />
                        </MessageModifiedLabel>
                      </>
                    )}
                  </MessageBubble>
                </MessageItemContainer>
              )}

              {withAssets && (
                <MessageAssetContainer
                  isEditing={isEditing}
                  message={chatMessage}
                  ownMessage={ownMessage}
                  hasReactions={hasReactions}
                  isMobile={isMobile}
                  allowReply={allowReply}
                  onAssetMenu={onAssetMenu}
                  addMessageReaction={addMessageReaction}
                />
              )}

              {!isBotChat &&
                (chatMessage.type === MessageType.Message ||
                  chatMessage.type === MessageType.ThreadMessage) &&
                singleChatLinkIri && (
                  <MessageItemContainer
                    key={singleChatLinkIri}
                    isMobile={isMobile}>
                    <LinkMessage
                      ownMessage={ownMessage}
                      chatLinkIri={singleChatLinkIri}
                      chatMessage={chatMessage}
                    />
                  </MessageItemContainer>
                )}

              {hasReactions && (
                <MessageItemContainer isMobile={isMobile}>
                  <MessageReactions chatMessage={chatMessage}>
                    <ChatMessageMenuButton
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={formatMessage({
                        id: ChatTranslation.tooltipAddReactionButton,
                      })}
                      data-tooltip-place={TooltipPlace.left}
                      type="button"
                      ref={messageReactionButtonRef}
                      icon={ChatReactionIcon}
                      onClick={addMessageReaction}
                      data-testid="add-reaction-btn"
                    />
                  </MessageReactions>
                </MessageItemContainer>
              )}
            </>

            {showTread && (
              <ChatMessageThreadViewWrapper chatMessage={chatMessage} />
            )}
          </MessageBody>
        </MessageContainer>

        {(!isScrolling || isMobile) && (
          <ChatMessageAction
            message={chatMessage}
            hasThreadMessages={hasThreadMessages}
            allowReply={allowReply}
            systemMessage={systemMessage}
            ownMessage={ownMessage}
            messageType={chatMessage.type}
            hasReactions={hasReactions}
            addMessageReaction={addMessageReaction}
            threadMessageDomId={threadMessageDomId}
            onMenu={onMenu}
          />
        )}
      </StyledChatMessage>
    );
  },
);
