import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../i18n';
import { FormInput } from '../../../../shared/components/Input';
import {
  FieldSet,
  FieldSetControls,
} from '../../../../shared/components/FieldSet';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { CheckBoxLabel } from './AddPaymentMethodForm.styled';
import { CreditCardDetailsInput } from '../../CreditCardDetailsInput';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import * as Yup from 'yup';
import { Schema } from 'yup';

export interface AddPaymentMethodFormValues {
  default: boolean;
}

const AddPaymentMethodFormSchema: Schema<AddPaymentMethodFormValues> = Yup.object().shape(
  {
    default: Yup.boolean(),
  },
);

interface AddPaymentMethodFormProps {
  onSubmit: (
    values: AddPaymentMethodFormValues,
    formikHelpers: FormikHelpers<AddPaymentMethodFormValues>,
  ) => void;
}

export const AddPaymentMethodForm: FC<AddPaymentMethodFormProps> = ({
  onSubmit,
}) => {
  const initialValues: AddPaymentMethodFormValues = {
    default: true,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={AddPaymentMethodFormSchema}>
      {({ isSubmitting }) => (
        <Form>
          <FieldSet disabled={isSubmitting}>
            <CreditCardDetailsInput
              disabled={isSubmitting}
              label={BillingTranslation.formNameCardDetailsLabel}
            />
            <div>
              <CheckBoxLabel htmlFor="default">
                <Field name="default" type="checkbox" component={FormInput} />
                <FormattedMessage
                  id={BillingTranslation.adminPaymentMethodSetAsDefault}
                />
              </CheckBoxLabel>
            </div>
          </FieldSet>
          <FieldSetControls disabled={isSubmitting}>
            <Button
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.sm}
              fullWidth
              data-testid="submit">
              <FormattedMessage
                id={BillingTranslation.adminPaymentMethodsAddButton}
              />
            </Button>
          </FieldSetControls>
          {isSubmitting && <SpinnerFullscreen />}
        </Form>
      )}
    </Formik>
  );
};
