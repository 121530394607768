import React, { FC } from 'react';
import { useAccountsContext } from '../../../Account';
import { ChatAvatar } from '../../../Chat/ChatAvatar';
import { IntegrationBadge } from '../../../Chat/ChatAvatar/IntegrationBadge';
import {
  MemberName,
  MemberTitle,
  StyledMemberItem,
} from '../../../Chat/MembersList/MemberItem/MemberItem.styled';
import { AccountApiType, AccountOrigin } from '../../../User/User.types';
import { getAccountName } from '../../../User/User.utils';
import { IntegrationBadgeContainer } from './CallAllMembersList.styled';

interface CallAllMemberListItemProps {
  account: AccountApiType;
}

export const CallAllMemberListItem: FC<CallAllMemberListItemProps> = ({
  account,
}) => {
  const { accountsWithAvailability } = useAccountsContext();
  const accountWithAvailability =
    accountsWithAvailability[account.id] || account;
  const accountOrigin =
    accountWithAvailability?.origin ||
    account.origin ||
    AccountOrigin.webtopcom;
  const isSlackUser = accountOrigin === AccountOrigin.slack;
  return (
    <StyledMemberItem>
      <ChatAvatar
        account={account}
        avatarSize="1.5rem"
        onlineStatusSize="0.625rem"
      />
      <div>
        <MemberTitle>
          <MemberName>{getAccountName(account)}</MemberName>
          {isSlackUser && (
            <IntegrationBadgeContainer>
              <IntegrationBadge origin={AccountOrigin.slack} badgeSize={20} />
            </IntegrationBadgeContainer>
          )}
        </MemberTitle>
      </div>
    </StyledMemberItem>
  );
};
