import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Modal,
  ModalHeader,
  ModalHeaderAction,
} from '../../../../shared/components/Modal';
import {
  useLaptop,
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../shared/hooks';
import { isValidGuid } from '../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';

import {
  LINK_DETAILS_MODAL_WIDTH_DESKTOP,
  LINK_DETAILS_MODAL_WIDTH_LAPTOP,
  LINK_DETAILS_MODAL_WIDTH_MOBILE,
  LINK_DETAILS_MODAL_WIDTH_TABLET,
  LinkDetailsTab,
} from './LinkDetailsModal.constants';
import { ChatIcon, EyeIcon, InformationIcon } from '../../../../shared/icons';
import { useChatLinkQuery } from '../../../Chat/Chat.hooks';
import { LinkDetailsContentContainer } from './LinkDetailsContentContainer';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { LinkDetailsTitle } from './LinkDetailsTitle/LinkDetailsTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkTranslation } from '../../i18n';
import { useIsGoogle } from '../../../ExternalServiceAuth';
import { useBrowserGoogleAuthStatusContext } from '../../../BrowserGoogleAuthStatus';
import { useCaseGetLink } from '../../UseCase/getLink';
import { LinkApiType } from '../../Link.types';
import { LinkDetailsLoadingError } from './LinkDetailsModal.styled';

export const LinkDetailsModal = () => {
  const queryParams = useQueryParams();
  //TODO Find other way to update link data after tags were updated "refetchLink"
  const { linkDetails, linkDetailsTab, chatLink, refetchLink } = queryParams;
  const navigate = useNavigate();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isLaptop = useLaptop();
  const isChatLink = Boolean(chatLink);
  const { formatMessage } = useIntl();
  const [linkId, setLinkId] = useState<string | undefined>(undefined);

  const [linkData, setLinkData] = useState<LinkApiType | null>();
  const { getLinkUseCase, linkLoading } = useCaseGetLink();

  const { data: chatLinkData, loading: chatLinkLoading } = useChatLinkQuery({
    skip: !linkId || !isChatLink,
    variables: {
      id: '/chat-links/' + linkId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const link = useMemo(
    () => linkData || chatLinkData?.chatLink,
    [chatLinkData, linkData],
  );

  const loading = useMemo(
    () => linkLoading || chatLinkLoading,
    [chatLinkLoading, linkLoading],
  );

  useEffect(() => {
    if (!linkId || isChatLink) {
      return;
    }
    getLinkUseCase(linkId as string).then(response => {
      if (response?.data) {
        setLinkData(response.data.link);
      }
    });
  }, [isChatLink, linkId]); // eslint-disable-line

  useEffect(() => {
    if (refetchLink) {
      getLinkUseCase(linkId as string)
        .then(response => {
          if (response?.data) {
            setLinkData(response.data.link);
          }
        })
        .finally(() => {
          navigate({
            search: getQueryParamsFrom({
              ...queryParams,
              refetchLink: undefined,
            }),
          });
        });
    }
  }, [navigate, linkId, queryParams, refetchLink]); // eslint-disable-line

  const isGoogleLink = useIsGoogle(link?.data.metadata?.type);

  const { clearGoogleAuthStatusInBrowser } =
    useBrowserGoogleAuthStatusContext();

  useEffect(() => {
    if (!isGoogleLink) {
      return;
    }

    clearGoogleAuthStatusInBrowser();
    return () => {
      clearGoogleAuthStatusInBrowser();
    };
  }, [clearGoogleAuthStatusInBrowser, isGoogleLink]);

  const handleCloseLinkDetails = useCallback(() => {
    setTimeout(() => {
      setLinkId(undefined);

      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          linkDetails: undefined,
          linkDetailsTab: undefined,
          chatLink: undefined,
          thread: undefined,
          conversation: undefined,
        }),
      });
    }, 0);
  }, [navigate, queryParams]);

  useEffect(() => {
    if (linkDetails !== linkId) {
      if (!isValidGuid(linkDetails as string)) {
        handleCloseLinkDetails();
      } else {
        setLinkId(linkDetails as string);
      }
    }
  }, [handleCloseLinkDetails, linkId, chatLink, linkDetails, isChatLink]);

  const modalWidth = useMemo(() => {
    if (isMobile) {
      return LINK_DETAILS_MODAL_WIDTH_MOBILE;
    } else if (isTablet) {
      return LINK_DETAILS_MODAL_WIDTH_TABLET;
    } else if (isLaptop) {
      return LINK_DETAILS_MODAL_WIDTH_LAPTOP;
    }

    return LINK_DETAILS_MODAL_WIDTH_DESKTOP;
  }, [isLaptop, isMobile, isTablet]);

  const handleTabClick = useCallback(
    (clickedTab?: LinkDetailsTab) => {
      let setTab = clickedTab === linkDetailsTab ? undefined : clickedTab;
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          linkDetailsTab: setTab,
        }),
      });
    },
    [navigate, queryParams, linkDetailsTab],
  );

  const modalHeaderActions: ModalHeaderAction[] = useMemo(() => {
    if (isChatLink) {
      return [
        {
          icon: EyeIcon,
          isActive: linkDetailsTab === undefined,
          hidden: !isMobile,
          menuLabel: formatMessage({
            id: LinkTranslation.linkMenuItemLinkPreview,
          }),
          onClick: () => {
            handleTabClick(undefined);
          },
        },
        {
          icon: InformationIcon,
          isActive: linkDetailsTab === LinkDetailsTab.DETAILS,
          menuLabel: formatMessage({
            id: LinkTranslation.linkMenuItemLinkDetails,
          }),
          onClick: () => {
            handleTabClick(LinkDetailsTab.DETAILS);
          },
          tooltipId: 'details-menu-item-id',
        },
      ];
    }

    return [
      {
        icon: EyeIcon,
        isActive: linkDetailsTab === undefined,
        hidden: !isMobile,
        menuLabel: formatMessage({
          id: LinkTranslation.linkMenuItemLinkPreview,
        }),
        onClick: () => {
          handleTabClick(undefined);
        },
      },
      {
        icon: ChatIcon,
        isActive: linkDetailsTab === LinkDetailsTab.COMMENTS,
        menuLabel: formatMessage({
          id: LinkTranslation.linkMenuItemLinkComment,
        }),
        onClick: () => {
          handleTabClick(LinkDetailsTab.COMMENTS);
        },
        toolTip: formatMessage({
          id: LinkTranslation.linkMenuItemLinkComments,
        }),
        'data-testid': 'comments-icon',
      },
      {
        icon: InformationIcon,
        isActive: linkDetailsTab === LinkDetailsTab.DETAILS,
        menuLabel: formatMessage({
          id: LinkTranslation.linkMenuItemLinkDetails,
        }),
        onClick: () => {
          handleTabClick(LinkDetailsTab.DETAILS);
        },
        toolTip: formatMessage({ id: LinkTranslation.linkMenuItemLinkDetails }),
        'data-testid': 'link-details-icon',
      },
    ];
  }, [formatMessage, handleTabClick, isChatLink, isMobile, linkDetailsTab]);

  return (
    <Modal
      visible={!!linkId}
      width={modalWidth}
      fullScreen={isMobile}
      fullHeight={true}
      onRequestClose={handleCloseLinkDetails}>
      <ModalHeader
        onRequestClose={handleCloseLinkDetails}
        actionButtons={modalHeaderActions}>
        {link && (
          <LinkDetailsTitle
            link={link}
            disableTrackTimelineEvent={isChatLink}
          />
        )}
      </ModalHeader>
      {link ? (
        <LinkDetailsContentContainer
          data={link}
          linkDetailsTab={linkDetailsTab as string}
        />
      ) : loading ? (
        <SpinnerFullscreen />
      ) : (
        <LinkDetailsLoadingError>
          <FormattedMessage
            id={LinkTranslation.linkDetailsLoadingErrorMessage}
          />
        </LinkDetailsLoadingError>
      )}
    </Modal>
  );
};
