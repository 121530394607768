import { ApolloClient } from '@apollo/client';
import {
  GET_SCHEDULED_CHAT_CONFERENCES,
  GetScheduledChatConferencesResponse,
  GetScheduledChatConferencesVariables,
} from '../Conference.queries';
import { ScheduledChatConferenceApiType } from '../Conference.types';
import {
  GRAPHQL_TYPENAME_REPEATING_SCHEDULED_CHAT_CONFERENCE,
  GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_EDGE,
} from '../Conference.constants';
import { sortScheduledConferencesByStartAt } from '../Conference.utils';
import { existsInNestedArray } from '../../../shared/utils/list.utils';

export const addScheduledRepeatingConferenceToListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetScheduledChatConferencesVariables,
  scheduledConferences: ScheduledChatConferenceApiType[],
  repeatingIri: string,
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache?.scheduleChatConferences) {
      return;
    }

    const newConferences = sortScheduledConferencesByStartAt(
      [
        ...conferencesListCache.scheduleChatConferences.edges.map(
          conference => conference.node,
        ),
        ...scheduledConferences.filter(
          conference =>
            !existsInNestedArray(
              conferencesListCache,
              'scheduleChatConferences.edges',
              { node: { _id: conference._id } },
            ),
        ),
      ].map(conference => ({
        __typename: GRAPHQL_TYPENAME_SCHEDULED_CHAT_CONFERENCE_EDGE,
        node: {
          repeatingScheduleChatConference: {
            id: repeatingIri,
            __typename: GRAPHQL_TYPENAME_REPEATING_SCHEDULED_CHAT_CONFERENCE,
          },
          ...conference,
          id: '/schedule-chat-conferences/' + conference._id,
        },
      })),
    );

    proxy.writeQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        scheduleChatConferences: {
          ...conferencesListCache.scheduleChatConferences,
          edges: newConferences,
          ...(conferencesListCache.scheduleChatConferences.pageInfo
            ? {
                pageInfo: {
                  ...conferencesListCache.scheduleChatConferences.pageInfo,
                  endCursor: window.btoa(
                    (
                      parseInt(
                        window.atob(
                          conferencesListCache.scheduleChatConferences.pageInfo
                            .endCursor,
                        ),
                        10,
                      ) + scheduledConferences.length
                    ).toString(),
                  ),
                },
              }
            : null),
        },
      },
    });
  } catch (e) {}
};

export const removeScheduledRepeatingConferenceToListCache = (
  proxy: {
    readQuery: ApolloClient<any>['readQuery'];
    writeQuery: ApolloClient<any>['writeQuery'];
  },
  variables: GetScheduledChatConferencesVariables,
  scheduledConferencesIds: string[],
) => {
  try {
    const conferencesListCache = proxy.readQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
    });

    if (!conferencesListCache?.scheduleChatConferences) {
      return;
    }

    proxy.writeQuery<
      GetScheduledChatConferencesResponse,
      GetScheduledChatConferencesVariables
    >({
      query: GET_SCHEDULED_CHAT_CONFERENCES,
      variables,
      data: {
        ...conferencesListCache,
        scheduleChatConferences: {
          ...conferencesListCache.scheduleChatConferences,
          edges: [
            ...conferencesListCache.scheduleChatConferences.edges.filter(
              scheduledConference =>
                !scheduledConferencesIds.includes(scheduledConference.node._id),
            ),
          ],
        },
      },
    });
  } catch (e) {}
};
