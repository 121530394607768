import { useEffect, useState } from 'react';
import { useQueryParams } from '../../../../shared/hooks';
import { usePreviewSegment } from '../../../PreviewSegment/PreviewSegment.hooks';
import { useCurrentTypeOfPage } from '../../Desktop.utils';

export enum DesktopContentType {
  NONE = 'none',
  FAVORITES = 'favorites',
  STANDARD = 'standard',
}

export const DesktopContentViewModel = () => {
  // TODO: Set up usecases and get the actual links, apps and chat here.

  // hooks
  const { isFavoritesDesktop } = useCurrentTypeOfPage();
  const { isPreviewMode } = usePreviewSegment();
  const { appStoreModal: showAppStore } = useQueryParams();

  // states
  const [desktopContentType, setDesktopContentType] = useState(
    DesktopContentType.NONE,
  );

  useEffect(() => {
    if (!isPreviewMode && isFavoritesDesktop) {
      setDesktopContentType(DesktopContentType.FAVORITES);
    } else if (!isPreviewMode && !isFavoritesDesktop) {
      setDesktopContentType(DesktopContentType.STANDARD);
    } else {
      setDesktopContentType(DesktopContentType.NONE);
    }
  }, [isFavoritesDesktop, isPreviewMode]);

  return {
    desktopContentType,
    showAppStore,
  };
};
