import React, { FC, useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../../shared/components/Input';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnboardingTranslation } from '../../../i18n';
import { showToastGraphQLErrors } from '../../../../../shared/components/Toast';
import {
  HiddenInput,
  ResetPasswordControls,
  ResetPasswordField,
} from './ResetPasswordForm.styled';
import {
  PASSWORD_CHARACTER_REGEX,
  PASSWORD_NUMBER_REGEX,
} from '../../../Onboarding.constants';
import { ResetPasswordFormValues } from '../../../Onboarding.types';

const ResetPasswordFormSchema: Schema<ResetPasswordFormValues> = Yup.object().shape(
  {
    newPassword: Yup.string()
      .required(
        OnboardingTranslation.onboardingResetPasswordFormPasswordRequired,
      )
      .min(8, OnboardingTranslation.onboardingResetPasswordFormPasswordMinimum)
      .matches(
        PASSWORD_CHARACTER_REGEX,
        OnboardingTranslation.onboardingResetPasswordFormPasswordContainLetter,
      )
      .matches(
        PASSWORD_NUMBER_REGEX,
        OnboardingTranslation.onboardingResetPasswordFormPasswordContainNumber,
      ),
    repeatPassword: Yup.string()
      .required(
        OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordRequired,
      )
      .min(
        8,
        OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordMinimum,
      )
      .matches(
        PASSWORD_CHARACTER_REGEX,
        OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordContainLetter,
      )
      .matches(
        PASSWORD_NUMBER_REGEX,
        OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordContainNumber,
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordMatch,
      ),
  },
);

interface RequestResetPasswordFormProps {
  onSubmit: (values: ResetPasswordFormValues) => Promise<unknown>;
}

export const ResetPasswordForm: FC<RequestResetPasswordFormProps> = ({
  onSubmit,
}) => {
  const intl = useIntl();

  const initialValues = {
    newPassword: '',
    repeatPassword: '',
  };

  const handleSubmit = useCallback(
    (values: ResetPasswordFormValues) => {
      return onSubmit(values).catch(err => {
        showToastGraphQLErrors(err.graphQLErrors);
      });
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordFormSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="reset-password-form">
          {/*Chrome password autocomplete disabling workaround*/}
          <HiddenInput type="password" tabIndex={-1} />

          <ResetPasswordField>
            <Field
              name="newPassword"
              type="password"
              data-testid="new-password-input"
              label={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingResetPasswordFormPasswordLabel,
              })}
              placeholder={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingResetPasswordFormPasswordPlaceholder,
              })}
              component={FormInput}
            />
          </ResetPasswordField>

          <ResetPasswordField>
            <Field
              name="repeatPassword"
              type="password"
              data-testid="repeat-password-input"
              label={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordLabel,
              })}
              placeholder={intl.formatMessage({
                id:
                  OnboardingTranslation.onboardingResetPasswordFormRepeatPasswordPlaceholder,
              })}
              component={FormInput}
            />
          </ResetPasswordField>

          <ResetPasswordControls>
            <Button
              type="submit"
              data-testid="submit-button"
              disabled={isSubmitting}
              size={ButtonSize.md}
              mode={ButtonMode.primary}>
              <FormattedMessage
                id={
                  OnboardingTranslation.onboardingResetPasswordFormSubmitButton
                }
              />
            </Button>
          </ResetPasswordControls>
        </Form>
      )}
    </Formik>
  );
};
