import styled from 'styled-components';

export const DesktopsList = styled.ul`
  margin-bottom: 60px;
  ${({ theme: { breakpoints } }) => breakpoints.md`
    overflow-y: auto;
    max-height: 55vh;
  `}

  li ~ li {
    margin-top: 20px;
  }
`;
