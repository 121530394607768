import { getShortId } from '../../../../shared/utils/id';
import { getRestApiClient } from '../../../Api/Api.utils';
import {
  RequestMethod,
  RestApiClientResponse,
} from '../../../Api/RestApiClient/RestApiClient.types';
import type { DesktopAccessType } from '../../../Desktop/data/Desktop/types/Desktop.types';
import type { AccessApiType, CreateAccessArgs } from '../types/Access.types';

export const createAccessRestApi = (
  desktopId: string,
  args: CreateAccessArgs,
): Promise<RestApiClientResponse<AccessApiType>> | undefined => {
  const restClient = getRestApiClient();
  const shortDesktopId = getShortId(desktopId);

  return restClient!.fetch(
    `/desktop/${shortDesktopId}/access`,
    RequestMethod.POST,
    {
      ...args,
    },
  );
};

export const deleteAccessByIdRestApi = (
  desktopId: string,
  accessId: string,
) => {
  const restClient = getRestApiClient();
  const shortDesktopId = getShortId(desktopId);
  const shortAccessId = getShortId(accessId);

  return restClient!.fetch(
    `/desktop/${shortDesktopId}/access/${shortAccessId}`,
    RequestMethod.DELETE,
  );
};

export const updateAccessByIdRestApi = (
  desktopId: string,
  desktopAccessId: string,
  accessType: DesktopAccessType,
): Promise<RestApiClientResponse<AccessApiType>> | undefined => {
  const restClient = getRestApiClient();
  const shortDesktopId = getShortId(desktopId);
  const shortAccessId = getShortId(desktopAccessId);

  return restClient!.fetch(
    `/desktop/${shortDesktopId}/access/${shortAccessId}`,
    RequestMethod.PUT,
    { accessType },
  );
};
