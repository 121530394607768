import React, { FC, PropsWithChildren } from 'react';
import { MenuPopoverProvider } from '../../../shared/components/Menu/MenuPopover';
import { WorkerQueueProvider } from '../../Mercure/WorkerQueue';
import { GeneralMercureProvider } from '../../Mercure/General';
import { WebPushProvider } from '../../WebPush/WebPush.provider';
import {
  AccountOnlineStatusProvider,
  AccountsProvider,
  IdleDetectionProvider,
} from '../../Account';
import { ChatMercureProvider } from '../../Mercure/Chat/ChatMercure.provider';
import { CountersProvider } from '../../Counters';
import { DesktopSidebarProvider } from '../../Desktop/DesktopSidebar/DesktopSidebar.provider';
import { AccountPopoverProvider } from '../../Chat/AccountPopover';
import { EncryptionProvider } from '../../Encryption/Encryption.provider';
import { BrowserSupportProvider } from '../../BrowserSupport/BrowserSupport.provider';
import { ExternalServiceAuthProvider } from '../../ExternalServiceAuth';
import { BrowserGoogleAuthStatusProvider } from '../../BrowserGoogleAuthStatus';
import { AnnouncementProvider } from '../../Announcement';
import { DesktopItemMultiselectProvider } from '../../Desktop/DesktopItemMultiselect';
import { PingMercureProvider } from '../../Mercure/Ping/PingMercure.provider';
import { WorkspaceDndProvider } from '../WorkspaceDndProvider';

export const WorkspaceProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <WorkerQueueProvider>
      <AnnouncementProvider>
        <AccountsProvider>
          <IdleDetectionProvider>
            <PingMercureProvider>
              <AccountOnlineStatusProvider>
                <GeneralMercureProvider>
                  <EncryptionProvider>
                    <WebPushProvider>
                      <BrowserSupportProvider>
                        <ChatMercureProvider>
                          <CountersProvider>
                            <DesktopSidebarProvider>
                              <AccountPopoverProvider>
                                <MenuPopoverProvider>
                                  <ExternalServiceAuthProvider>
                                    <BrowserGoogleAuthStatusProvider>
                                      <DesktopItemMultiselectProvider>
                                        <WorkspaceDndProvider>
                                          {children}
                                        </WorkspaceDndProvider>
                                      </DesktopItemMultiselectProvider>
                                    </BrowserGoogleAuthStatusProvider>
                                  </ExternalServiceAuthProvider>
                                </MenuPopoverProvider>
                              </AccountPopoverProvider>
                            </DesktopSidebarProvider>
                          </CountersProvider>
                        </ChatMercureProvider>
                      </BrowserSupportProvider>
                    </WebPushProvider>
                  </EncryptionProvider>
                </GeneralMercureProvider>
              </AccountOnlineStatusProvider>
            </PingMercureProvider>
          </IdleDetectionProvider>
        </AccountsProvider>
      </AnnouncementProvider>
    </WorkerQueueProvider>
  );
};
