import React from 'react';
import { ChatEvent } from './ChatMercure.types';

export interface ChatMercureContextType {
  addListener: (listener: (e: ChatEvent) => void) => void;
  removeListener: (listener: (e: ChatEvent) => void) => void;
}

export const ChatMercureContext = React.createContext<ChatMercureContextType>({
  addListener: () => {},
  removeListener: () => {},
});
