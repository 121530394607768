import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useCurrentAccount } from '../../Auth/Auth.hooks';
import { PingMercureContext } from './PingMercure.context';
import { PingEvent } from './PingMercure.types';
import { useUserCacheUpdate } from '../../User/User.hooks';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { usePingMercureUrl } from './PingMercure.hooks';
import { useAccountsContext } from '../../Account';
import { PingMercureClient } from './PingMercureClient';
import { MercureClientType, MercureStatus } from '../Mercure.types';
import { useMercureClient } from '../Mercure.hooks';
import { useIsOnline } from '../../Offline';

export const PingMercureProvider: FC<PropsWithChildren> = ({ children }) => {
  const { account: currentAccount } = useCurrentAccount();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { pingMercureClient, setPingMercureClient } = useMercureClient();

  const currentWorkspaceId = currentWorkspace?.id;

  const currentAccountRef = useRef(currentAccount);
  currentAccountRef.current = currentAccount;

  const listenersRef = useRef<Array<(e: PingEvent) => void>>([]);

  const { updateAccountOnlineStatusInCache } = useUserCacheUpdate();
  const { accountsWithAvailability, loading: accountsWithAvailabilityLoading } =
    useAccountsContext();
  const { updateAccount } = useCurrentAccount();

  const pingMercureUrl = usePingMercureUrl();

  const pingMercureInitalToken =
    currentAccountRef.current?.mercureInfo?.ping?.authorization;

  const isOnline = useIsOnline();

  const mercureClientArgs = useMemo(() => {
    if (
      accountsWithAvailabilityLoading ||
      !pingMercureUrl ||
      !pingMercureInitalToken
    ) {
      return null;
    }

    return {
      mercureClientType: MercureClientType.ping,
      mercureUrl: pingMercureUrl,
      initialAuthToken: pingMercureInitalToken,
      updateAccount,
      currentWorkspaceId,
      updateAccountOnlineStatusInCache,
      accountsWithAvailability,
      listenersRef,
      isOnline,
    };
  }, [
    accountsWithAvailability,
    accountsWithAvailabilityLoading,
    currentWorkspaceId,
    pingMercureInitalToken,
    pingMercureUrl,
    updateAccount,
    updateAccountOnlineStatusInCache,
    isOnline,
  ]);

  useEffect(() => {
    if (pingMercureClient && mercureClientArgs) {
      pingMercureClient.updateVariables(mercureClientArgs);
    }
  }, [mercureClientArgs, pingMercureClient]);

  useEffect(() => {
    if (!mercureClientArgs || pingMercureClient) {
      return;
    }

    setPingMercureClient(new PingMercureClient({ ...mercureClientArgs }));
  }, [pingMercureClient, mercureClientArgs, setPingMercureClient]);

  useEffect(() => {
    if (
      !isOnline &&
      pingMercureClient?.mercureStatus === MercureStatus.connected
    ) {
      pingMercureClient.disconnectMercureClient();
    }
  }, [isOnline, pingMercureClient]);

  useEffect(() => {
    if (
      isOnline &&
      pingMercureClient?.mercureStatus === MercureStatus.disconnected
    ) {
      pingMercureClient.reconnect();
    }
  }, [isOnline, pingMercureClient]);

  return (
    <PingMercureContext.Provider
      value={{
        addListener: listener => {
          listenersRef.current = [...listenersRef.current, listener];
        },
        removeListener: listener => {
          listenersRef.current = listenersRef.current.filter(
            l => l !== listener,
          );
        },
      }}>
      {children}
    </PingMercureContext.Provider>
  );
};
