import styled from 'styled-components';
import { OnlineStatusCircle } from '../../Account/components/OnlineStatus/OnlineStatusCircle';
import { StyledAvatarImage } from './AvatarImage/AvatarImage.styled';
import { StyledAvatarPlaceholder } from './AvatarPlaceholder/AvatarPlaceholder.styled';

export const StyledChatAvatar = styled.div<{
  avatarSize: string;
  isLoading?: boolean;
}>`
  position: relative;
  flex-shrink: 0;
  width: ${p => p.avatarSize};
  height: ${p => p.avatarSize};
  font-size: ${p => p.avatarSize};
  color: ${p => p.theme.colors.PrimaryTextLight};

  ${StyledAvatarImage}, ${StyledAvatarPlaceholder} {
    border-radius: 50%;
  }

  ${OnlineStatusCircle} {
    position: absolute;
    bottom: 0;
    right: 0;
    border-width: 0.125rem;
    border-style: solid;
  }
`;
