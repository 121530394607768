import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import { TimelineEventAction } from '../../Timeline.constants';
import { TeamIcon } from '../../../../shared/icons';
import styled, { css } from 'styled-components';
import { isShared } from '../../../Desktop/Desktop.utils';
import { getShortId } from '../../../../shared/utils/id';

export const TeamIconContainer = styled.span<{ highlighted: boolean }>`
  position: relative;
  margin-left: 5px;
  top: 4px;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      color: ${theme.colors.Primary};
    `};
`;

interface TimelineDesktopInfoProps {
  desktopId?: string;
  desktopName?: string;
  eventAction?: TimelineEventAction;
  shareable?: boolean;
  memberCount?: number;
}

export const TimelineDesktopInfo: FC<TimelineDesktopInfoProps> = ({
  desktopId,
  desktopName,
  eventAction,
  shareable = true,
  memberCount = 0,
}) => {
  const { workspace } = useCurrentWorkspace();
  const currentDesktop = useCurrentDesktop();

  const renderFormattedMessage = () => {
    switch (eventAction) {
      case TimelineEventAction.add:
      case TimelineEventAction.create:
      case TimelineEventAction.add_custom_to_desktop:
      case TimelineEventAction.add_team:
      case TimelineEventAction.add_receiver:
      case TimelineEventAction.add_sender:
        return (
          <>
            <FormattedMessage id={TimelineTranslation.eventToLabel} />{' '}
          </>
        );
      case TimelineEventAction.open:
      case TimelineEventAction.remove:
      case TimelineEventAction.update:
      case TimelineEventAction.remove_custom_from_desktop:
      case TimelineEventAction.remove_team:
      case TimelineEventAction.remove_receiver:
      case TimelineEventAction.remove_sender:
        return (
          <>
            <FormattedMessage id={TimelineTranslation.eventFromLabel} />{' '}
          </>
        );
      default:
        return '';
    }
  };

  const isSharedDesktop = currentDesktop ? isShared(currentDesktop) : null;

  if (currentDesktop?.id || !desktopId) return null;
  return (
    <>
      {renderFormattedMessage()}
      <>
        {desktopId && desktopName && (
          <Link
            className="highlighted"
            to={`/workspace/${getShortId(workspace.id)}/desktop/${getShortId(
              desktopId,
            )}/apps`}>
            {desktopName}
          </Link>
        )}

        {isSharedDesktop && (
          <TeamIconContainer highlighted={!shareable}>
            <TeamIcon />
          </TeamIconContainer>
        )}
      </>
    </>
  );
};
