import { getShortId } from '../../../../../shared/utils/id';
import { getRestApiClient } from '../../../../Api/Api.utils';
import {
  RequestMethod,
  RestApiClientResponse,
} from '../../../../Api/RestApiClient/RestApiClient.types';
import type { TeamApiType, TeamCreateInputType } from '../types/Team.types';

export const createWorkspaceTeamRestApi = (variables: TeamCreateInputType) => {
  const restApiClient = getRestApiClient();

  if (!restApiClient) {
    return null;
  }

  return restApiClient
    .fetch(`/workspace-team`, RequestMethod.POST, variables)
    .then(({ data }: RestApiClientResponse<TeamApiType>) => data);
};

export const deleteTeamRestApi = async (workspaceTeamId: string) => {
  try {
    const shortWorkspaceTeamId = getShortId(workspaceTeamId);
    const restApiClient = getRestApiClient();

    if (!restApiClient) {
      return null;
    }

    return restApiClient
      .fetch(`/workspace-team/${shortWorkspaceTeamId}`, RequestMethod.DELETE)
      .then(({ data }: RestApiClientResponse<TeamApiType>) => data);
  } catch (e) {
    console.error(e);

    return null;
  }
};

export const editTeamRestApi = async (
  workspaceTeamId: string,
  workspaceTeamName: string,
  workspaceTeamDescription: string,
) => {
  try {
    const shortWorkspaceTeamId = getShortId(workspaceTeamId);
    const restApiClient = getRestApiClient();

    if (!restApiClient) {
      return null;
    }

    return restApiClient.fetch(
      `/workspace-team/${shortWorkspaceTeamId}`,
      RequestMethod.PUT,
      {
        description: workspaceTeamDescription,
        name: workspaceTeamName,
      },
    );
  } catch (e) {
    console.error(e);

    return null;
  }
};
