import styled from 'styled-components';
import { ChatAvatar } from '../../../Chat/ChatAvatar';

export const OptionChatAvatar = styled(ChatAvatar)`
  margin-right: 0.625rem;
`;

export const OptionInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
  }
`;

export const StyledCustomOptionLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledListInfo = styled.ul`
  padding: 1rem 1rem 0 1rem;
  list-style-type: disc;
`;

export const StyledListInfoWrapper = styled.div`
  padding-top: 1rem;
`;
