import React, { FC } from 'react';
import { EmojiData, Picker } from 'emoji-mart';
import { Popover, PopoverProps } from '../../../shared/components/Popover';

import 'emoji-mart/css/emoji-mart.css';
import { useMobile } from '../../../shared/hooks';
import { getBottomCenterPage } from '../../../shared/components/Popover/Popover.utils';

export interface EmojiPickerPopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRef'> {
  onSelect: (emoji: EmojiData) => void;
}

export const EmojiPickerPopover: FC<EmojiPickerPopoverProps> = ({
  onSelect,
  ...popoverProps
}) => {
  const isMobile = useMobile();

  return (
    <Popover
      horizontalMargin={isMobile ? 8 : undefined}
      anchorGetter={isMobile ? getBottomCenterPage : undefined}
      {...popoverProps}>
      <Picker
        onSelect={onSelect}
        showPreview={false}
        showSkinTones={false}
        native
      />
    </Popover>
  );
};
