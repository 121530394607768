import styled, { css } from 'styled-components';
import { SegmentPage } from '../../Segment/Segment.styled';

export const StyledChatsPage = styled(SegmentPage)<{
  isMobile: boolean;
  isChatVisible: boolean;
}>`
  overflow: hidden;

  ${({ isMobile, isChatVisible }) =>
    isMobile &&
    css`
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      transition: transform 0.5s ease;
      transform: translateX(0px);
      display: block;

      ${isChatVisible &&
      css`
        .chat-list {
          display: none;
        }
      `}
    `}
`;
