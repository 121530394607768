import React, { FC, useCallback, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../shared/components/Table';
import { Cell, CellProps, Column, useTable } from 'react-table';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../shared/components/Input';
import { FieldSet } from '../../../../shared/components/FieldSet';
import { PaymentMethodAccount } from '../../PaymentMethodAccount';
import { useIntl } from 'react-intl';
import { BillingTranslation } from '../../i18n';
import { PaymentMethodApiType } from '../../Billing.types';
import { getDefaultPaymentMethodId } from '../../Billing.utils';
import { RemovePaymentMethodButton } from './RemovePaymentMethodButton';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';

interface PaymentMethodsTableProps {
  tableData: PaymentMethodApiType[];
  onUpdate: (
    values: PaymentMethodsFormValues,
    FormikHelpers: FormikHelpers<PaymentMethodsFormValues>,
  ) => void;
  isUpdating: boolean;
  handleDeletePaymentMethod: (paymentMethodId: string) => void;
}

interface PaymentMethodsFormValues {
  paymentMethodId: string;
}

interface PaymentMethodsFormColumns {
  paymentMethodId: string;
  account: string;
  expires: string;
  remove: PaymentMethodApiType;
}

const PaymentMethodsSchema: Schema<PaymentMethodsFormValues> =
  Yup.object().shape({
    paymentMethodId: Yup.string().required(),
  });

export const PaymentMethodsTable: FC<PaymentMethodsTableProps> = ({
  tableData,
  onUpdate,
  isUpdating,
  handleDeletePaymentMethod,
}) => {
  const {
    permissions: { canEditBillingPage },
  } = useCurrentWorkspacePermissions();
  const { formatMessage } = useIntl();

  const data: PaymentMethodsFormColumns[] = useMemo(
    () =>
      tableData.map((paymentMethod: PaymentMethodApiType) => ({
        paymentMethodId: paymentMethod.paymentMethodId,
        account: paymentMethod.lastFour,
        expires: `${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`,
        remove: paymentMethod,
      })),
    [tableData],
  );
  const defaultPaymentMethodId = useMemo(
    () => getDefaultPaymentMethodId(tableData),
    [tableData],
  );

  const onDeleteClick = useCallback(
    (id: string) => {
      handleDeletePaymentMethod(id);
    },
    [handleDeletePaymentMethod],
  );

  // TODO: Add sorting option for some columns
  // TODO: Remove ts-ignore
  //@ts-ignore
  const columns: Array<Column<PaymentMethodsFormColumns>> = useMemo(
    () => [
      {
        Header: formatMessage({
          id: BillingTranslation.adminDefaultMetodTableHeader,
        }),
        accessor: 'paymentMethodId',
        Cell: (props: CellProps<Cell>) => {
          if (!canEditBillingPage && props.value !== defaultPaymentMethodId) {
            return null;
          }
          return (
            <Field
              name="paymentMethodId"
              type="radio"
              value={props.value}
              component={FormInput}
            />
          );
        },
      },
      {
        Header: formatMessage({
          id: BillingTranslation.adminPaymentAccountTableHeader,
        }),
        accessor: 'account',
        Cell: (props: CellProps<Cell>) => {
          return <PaymentMethodAccount digits={props.value} />;
        },
      },
      {
        Header: formatMessage({
          id: BillingTranslation.adminExpiryDateTableHeader,
        }),
        accessor: 'expires',
      },
      {
        Header: '',
        accessor: 'remove',
        Cell: (props: CellProps<Cell>) => {
          if (
            defaultPaymentMethodId === props.value.paymentMethodId ||
            !canEditBillingPage
          )
            return null;

          return (
            <RemovePaymentMethodButton
              paymentMethod={props.value}
              onDeleteClick={onDeleteClick}
              isUpdating={isUpdating}
            />
          );
        },
      },
    ],
    [
      formatMessage,
      canEditBillingPage,
      defaultPaymentMethodId,
      onDeleteClick,
      isUpdating,
    ],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const initialValues: PaymentMethodsFormValues = useMemo(
    () => ({
      paymentMethodId: defaultPaymentMethodId,
    }),
    [defaultPaymentMethodId],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onUpdate}
      validationSchema={PaymentMethodsSchema}>
      {({ isSubmitting, submitForm }) => (
        <Form onChange={submitForm}>
          <FieldSet disabled={isSubmitting}>
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <TableHeader {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </TableHeader>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </FieldSet>
        </Form>
      )}
    </Formik>
  );
};
