import React, { FC, useContext, useEffect } from 'react';
import {
  AdminHeader,
  AdminHeaderTitle,
  AdminPage,
  DrawerHeader,
} from '../../Admin';
import { EditGeneralSettings } from './EditGeneralSettings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useMobile } from '../../../shared/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { GeneralSettingsTranslation } from '../i18n';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const GeneralSettingsAdmin: FC = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  return (
    <AdminPage data-testid="settings-admin">
      {isMobile ? (
        <DrawerHeader
          onRequestClose={() =>
            navigate(`/workspace/${getShortId(workspace.id)}`)
          }>
          <FormattedHTMLMessage id={GeneralSettingsTranslation.modalTitle} />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage id={GeneralSettingsTranslation.modalTitle} />
          </AdminHeaderTitle>
        </AdminHeader>
      )}

      <EditGeneralSettings />
    </AdminPage>
  );
};
