import styled from 'styled-components';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from '../Link.constants';

export const StyledLinkData = styled.div<{ lowHeight?: boolean }>`
  width: 100%;
  min-height: ${({ lowHeight }) => (lowHeight ? 42 : IMAGE_HEIGHT)}px;
  max-width: calc(100% - ${IMAGE_WIDTH}px - ${({ theme }) => theme.spacing.xs});
  padding: ${({ theme }) => theme.spacing.xxs};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
    padding: ${spacing.xxs};
    padding-right: unset;
  `}
`;

export const Title = styled.h4<{ oneLiner?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${({ oneLiner }) => (oneLiner ? 1 : 2)};
  -webkit-box-orient: vertical;
`;

export const Hostname = styled.div`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.75rem;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  column-gap: ${({ theme }) => theme.spacing.xs};

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Favicon = styled.img`
  height: 15px;
  max-width: 15px;
  border-radius: 0.125rem;
  vertical-align: middle;
  pointer-events: none;
  user-select: none;
`;

export const LinkWarning = styled.span`
  background-color: ${p => p.theme.colors.Warning};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  padding: 3px 6px;
  font-size: 0.75rem;
  line-height: 1.2;
  border-radius: 4px;
  margin-left: ${({ theme }) => theme.spacing.xxs};
  word-break: keep-all;
`;

export const HostnameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkRequireAccess = styled.div`
  font-family: ${p => p.theme.fonts.Regular};
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  line-height: 1.2;
  word-break: keep-all;
  display: flex;
  align-items: center;

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
`;
