import { ApiConnection, ImageApiType } from '../../shared/api/api.types';

export interface MarketplaceCategoryType {
  id: string;
  name: string;
  image: {
    contentUrl: string;
  };
  marketplaceItems: MarketplaceItemType[];
}

export interface MarketplaceAppType {
  id: string;
  name: string;
  logo: {
    contentUrl: string;
  };
}

export interface AppIntegrationsAccountType {
  id: string;
  active: boolean;
}

export interface MarketplaceItemType {
  id: string;
  authenticated: boolean;
  accountAppIntegration?: AppIntegrationsAccountType;
  name: string;
  app: {
    name: string;
    logo: {
      contentUrl: string;
    };
  };
}

export interface SearchResult {
  title: string;
  context: string;
  contextId: string;
  externalURI: string;
  summary: string;
  date: string;
}

export interface IntegrationClientApiType {
  id: string;
  name: string;
  bot: IntegrationBotApiType;
  secret: string;
}

export interface IntegrationBotApiType {
  firstName: string;
  image: ImageApiType;
}

export interface IntegrationHookApiType {
  id: string;
  secret: string;
  desktop: {
    id: string;
    name: string;
  };
}

export interface IntegrationClientFullApiType {
  id: string;
  name: string;
  bot: IntegrationBotApiType;
  secret: string;
  hooks: ApiConnection<IntegrationHookApiType>;
}

export interface CreateIntegrationFormValues {
  integrationName: string;
  botName: string;
  botImage: string;
}

export interface CustomIntegrationHookFormValues {
  desktop: string;
  hookId: string;
  secret: string;
}

export interface CustomIntegrationPropertiesFormValues {
  integrationName: string;
  clientId: string;
  secret: string;
}

export interface CustomIntegrationBotFormValues {
  botName: string;
  botImage: string;
}

export enum AppIntegrationsCategories {
  personal = 'personal',
  workspace = 'workspace',
  custom = 'custom',
}

export enum APP_INTEGRATIONS_DRIVERS_MAP {
  google = 'google',
  github = 'github',
  trello = 'trello',
  dropbox = 'dropbox',
  mailchimp = 'mailchimp',
  notion = 'notion',
  salesforce = 'salesforce',
  asana = 'asana',
  hubspot = 'hubspot',
  microsoft = 'microsoft',
}
