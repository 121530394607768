import React, { FC, useCallback } from 'react';
import countries from 'i18n-iso-countries';
import { FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../../i18n';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderTitle,
} from '../../../../Admin';
import {
  StyledAddressLine,
  StyledAddressName,
} from './BillingInformation.styled';
import { useModalControls } from '../../../../../shared/components/Modal/Modal.hooks';
import { EditBillingInformationModal } from '../../../BillingModals/EditBillingInformationModal';
import {
  BillingContactFormValues,
  PaymentCustomerApiType,
} from '../../../Billing.types';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { useUpdatePaymentCustomerMutation } from '../../../Billing.hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { useCurrentWorkspacePermissions } from '../../../../Workspace/Workspace.hooks';

interface BillingInformationProps {
  paymentCustomer: PaymentCustomerApiType | undefined;
  refetch: () => void;
}

export const BillingInformation: FC<BillingInformationProps> = ({
  paymentCustomer,
  refetch,
}) => {
  const {
    permissions: { canEditBillingPage },
  } = useCurrentWorkspacePermissions();
  const editBillingInformationModal = useModalControls();
  const [updatePaymentCustomerMutation] = useUpdatePaymentCustomerMutation();

  const handleEditSubmit = useCallback(
    (values: BillingContactFormValues) => {
      const { vatType, vatNumber, ...formValues } = values;
      return updatePaymentCustomerMutation({
        variables: {
          input: {
            ...formValues,
            ...(vatType ? { vatType } : {}),
            ...(vatType ? { vatNumber } : {}),
          },
        },
      })
        .then(() => {
          refetch();
          editBillingInformationModal.close();
          showToastSuccessMessage(
            BillingTranslation.adminBillingInformationUpdatedMessage,
          );
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
        });
    },
    [editBillingInformationModal, refetch, updatePaymentCustomerMutation],
  );

  return (
    <>
      <Section data-testid="section-billing-information">
        <SectionHeader data-testid="header">
          <SectionHeaderTitle data-testid="title">
            <FormattedMessage
              id={BillingTranslation.adminBillingInformationTitle}
            />
          </SectionHeaderTitle>
          {canEditBillingPage && (
            <SectionHeaderButton
              onClick={editBillingInformationModal.open}
              data-testid="edit">
              <FormattedMessage
                id={BillingTranslation.adminBillingInformationEditButton}
              />
            </SectionHeaderButton>
          )}
        </SectionHeader>
        <SectionBody data-testid="body">
          {paymentCustomer ? (
            <>
              <StyledAddressName data-testid="billing-name">
                {paymentCustomer.billingName}
              </StyledAddressName>
              <StyledAddressLine data-testid="line1">
                {paymentCustomer.line1}
              </StyledAddressLine>
              <StyledAddressLine data-testid="line2">
                {paymentCustomer.line2}
              </StyledAddressLine>
              <StyledAddressLine data-testid="zip-city">
                {`${paymentCustomer.postalCode}, ${paymentCustomer.city}`}
              </StyledAddressLine>
              <StyledAddressLine data-testid="state-country">
                {/* TODO: Use current language instead of static "en" */}
                {paymentCustomer.state ? `${paymentCustomer.state}, ` : null}
                {countries.getName(paymentCustomer.country, 'en')}
              </StyledAddressLine>
            </>
          ) : (
            <Spinner />
          )}
        </SectionBody>
      </Section>
      {paymentCustomer && (
        <EditBillingInformationModal
          visible={editBillingInformationModal.visible}
          onRequestClose={editBillingInformationModal.close}
          onSubmit={handleEditSubmit}
          paymentCustomer={paymentCustomer}
        />
      )}
    </>
  );
};
