import {
  NewChatConversationEvent,
  RemoveChatConversationEvent,
  UpdateChatConversationEvent,
} from '../ChatMercure.types';
import { makeChatConversation } from './chatConversation.utils';
import { convertGraphQLApiChatConversationToInternal } from '../../../Chat/Chat.utils';
import { ChatConversationUserContext } from '../../../Chat/Chat.types';
import {
  createConversationIDDB,
  deleteConversationIDDB,
  updateConversationIDDB,
} from '../../../Chat/Data/DataSource/ChatConversation/ChatConversationsIndexedDBSource';
import { ChatConversationApiToChatConversationsTableRow } from '../../../Database/ChatConversationsTable/ChatConversationsTable.utils';

export const handleNewChatConversation = (
  event: NewChatConversationEvent,
  context: ChatConversationUserContext,
) => {
  const conversationTableType = ChatConversationApiToChatConversationsTableRow(
    makeChatConversation(event),
    context,
  );
  createConversationIDDB(conversationTableType);
};

export const handleUpdateChatConversation = async (
  event: UpdateChatConversationEvent,
  context: ChatConversationUserContext,
) => {
  const chatConversation = convertGraphQLApiChatConversationToInternal(
    makeChatConversation(event),
    context,
  );

  await updateConversationIDDB(chatConversation);
};

export const handleRemoveChatConversation = (
  event: RemoveChatConversationEvent,
) => {
  const { '@iri': conversationId } = event;
  deleteConversationIDDB(conversationId);
};
