import styled from 'styled-components';

export const StyledFolderPreview = styled.div<{
  clientOffset: { x: number; y: number };
  sourceOffset: { x: number; y: number };
}>`
  position: relative;
  top: ${p => p.clientOffset.y - p.sourceOffset.y}px;
  left: ${p => p.clientOffset.x - p.sourceOffset.x}px;
  max-width: 184px;

  .folder-container {
    background-color: ${p => p.theme.colors.Primary};
    border-color: ${p => p.theme.colors.Primary};
  }

  .folder-title {
    color: ${p => p.theme.colors.PrimaryTextLight};
  }
`;
