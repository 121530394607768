import React, { FC, useMemo } from 'react';
import { FieldProps } from 'formik';
import _get from 'lodash/get';
import {
  FormInputLabel,
  StyledFormInput,
} from '../../../../../../../../shared/components/Input';
import { Checkbox } from '../../../../../../../../shared/components/Checkbox';

interface FormInputProps {
  label?: string;
  className?: string;
  id?: string;
  description?: string;
}

export const SsoFormCheckbox: FC<FieldProps & FormInputProps> = ({
  field,
  form,
  label,
  className,
  description,
  ...props
}) => {
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );
  return (
    <StyledFormInput className={className} invalid={!!errorIdToDisplay}>
      {label && (
        <FormInputLabel htmlFor={props.id || field.name}>
          {label}
        </FormInputLabel>
      )}
      <Checkbox {...field} {...props}>
        {description}
      </Checkbox>
    </StyledFormInput>
  );
};
