import styled from 'styled-components';

export const StyledLinkAccountSuccessScreen = styled.div`
  text-align: center;
`;

export const LinkAccountSuccessMessage = styled.div`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5;
`;

export const LinkAccountSuccessIcon = styled.div`
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.SuccessDark};
  border-radius: 50%;
  margin: 36px auto;
`;
