import { gql } from '@apollo/client';

export const REMOVE_FOLDER = gql`
  mutation RemoveFolder($input: removeFolderInput!) {
    removeFolder(input: $input) {
      folder {
        id
      }
    }
  }
`;

export interface RemoveFolderVariables {
  input: {
    id: string;
  };
}

export interface RemoveFolderResponse {
  removeFolder: {
    folder: {
      id: string;
    };
  };
}
