import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useState,
} from 'react';
import { ShareDesktopPopoverContext } from './ShareDesktopPopover.context';
import { ShareDesktopPopover } from './ShareDesktopPopover';
import type { DesktopApiType } from '../data/Desktop/types/Desktop.types';

export const ShareDesktopPopoverProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [popoverData, setPopoverData] = useState<{
    desktop: DesktopApiType;
    domRef: RefObject<HTMLElement>;
  }>();

  const showShareDesktopPopover = useCallback(
    (desktop: DesktopApiType, domRef: RefObject<HTMLElement>) => {
      setPopoverData({
        desktop,
        domRef,
      });
    },
    [],
  );

  const hideShareDesktopPopover = useCallback(() => {
    setPopoverData(undefined);
  }, []);

  return (
    <ShareDesktopPopoverContext.Provider
      value={{ showShareDesktopPopover, hideShareDesktopPopover }}>
      {children}

      {popoverData && (
        <ShareDesktopPopover
          triggerRef={popoverData.domRef}
          onRequestClose={hideShareDesktopPopover}
          desktop={popoverData.desktop}
        />
      )}
    </ShareDesktopPopoverContext.Provider>
  );
};
