import { GraphQLError } from 'graphql/error/GraphQLError';
import { getShortId } from '../../../../shared/utils/id';
import { getApolloClient } from '../../../Api/Api.utils';
import {
  GetWorkspaceFavoritesRestResponse,
  GetWorkspaceFavoritesRestVariables,
  GET_WORKSPACE_FAVORITES_REST,
} from '../Operations/Queries/GetWorkspaceFavorites.query';

/**
 * Get workspace favorites from API
 * @param workspaceId
 * @returns Favorites data for workspace
 */
export const getWorkspaceFavoritesRest = async (
  workspaceId: string,
): Promise<{
  errors?: readonly GraphQLError[];
  error?: any;
  data?: GetWorkspaceFavoritesRestResponse;
}> => {
  const apolloClient = getApolloClient();

  try {
    const { errors, data } = await apolloClient!.query<
      GetWorkspaceFavoritesRestResponse,
      GetWorkspaceFavoritesRestVariables
    >({
      query: GET_WORKSPACE_FAVORITES_REST,
      variables: {
        workspaceId: getShortId(workspaceId),
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });
    return Promise.resolve({ errors, data });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
