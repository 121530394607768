import React, { FC, useCallback, useState } from 'react';
import { useCreateWorkspaceAccountInvitation } from '../../../User/User.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { WorkspaceAccountGroupIdentity as GroupIdentity } from '../../../User/User.constants';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { DesktopSelect, DesktopSelectProps } from './DesktopSelect';
import { DesktopTranslation } from '../../i18n';
import { Guidance } from './InviteMember.styled';
import { ImportantMessageType } from '../../../../shared/components/ImportantMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { isAccountAdmin, isAccountGuest } from '../../../User/User.utils';
import {
  DesktopAccessType,
  DesktopApiType,
} from '../../data/Desktop/types/Desktop.types';
import { useTeamsActions } from '../../../Team/data/Team/Team.actions';
import { useAccountDesktopsActions } from '../../data/AccountDesktop/AccountDesktops.actions';
import { useInvitationsRepository } from '../../../Invitation/data/Invitation/Invitation.repositories';
import { getShortId } from '../../../../shared/utils/id';

interface InviteMemberProps
  extends Pick<
    DesktopSelectProps,
    'currentTeamIds' | 'currentAccountIds' | 'currentInviteEmails'
  > {
  desktop: DesktopApiType;
}

export const InviteMember: FC<InviteMemberProps> = ({
  desktop: currentDesktop,
  ...props
}) => {
  const intl = useIntl();

  const { addDesktopToTeamTeam } = useTeamsActions();
  const { addAccountToDesktop } = useAccountDesktopsActions();
  const [createWorkspaceAccountInvitation] =
    useCreateWorkspaceAccountInvitation();
  const { fetchInvitations } = useInvitationsRepository({
    variables: {
      desktopId: currentDesktop.id,
    },
    fetchPolicy: 'cache-only',
  });

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [processing, setProcessing] = useState(false);

  const handleSelect: DesktopSelectProps['onSelect'] = useCallback(
    item => {
      setProcessing(true);
      if (typeof item === 'string') {
        return createWorkspaceAccountInvitation({
          variables: {
            input: {
              workspace: currentWorkspace.id,
              email: item,
              group: GroupIdentity.GUEST,
              desktop: currentDesktop.id,
              desktopAccess: DesktopAccessType.CanComment,
            },
          },
        })
          .then(() => fetchInvitations())
          .catch(e => {
            showToastGraphQLErrors(e.graphQLErrors);
          })
          .finally(() => {
            setProcessing(false);
          });
      } else if ('name' in item) {
        return addDesktopToTeamTeam(item.id, currentDesktop.id).finally(() => {
          setProcessing(false);
        });
      } else {
        const isAdmin = isAccountAdmin(item);
        const isGuest = isAccountGuest(item);

        return addAccountToDesktop(
          item.id,
          currentDesktop.id,
          isAdmin
            ? DesktopAccessType.Manager
            : isGuest
            ? DesktopAccessType.CanComment
            : DesktopAccessType.EditLink,
        ).then(() => setProcessing(false));
      }
    },
    [
      createWorkspaceAccountInvitation,
      currentWorkspace.id,
      currentDesktop.id,
      fetchInvitations,
      addDesktopToTeamTeam,
      addAccountToDesktop,
    ],
  );

  const isPrivateDesktop = currentDesktop.shareable;

  return (
    <>
      <DesktopSelect
        onSelect={handleSelect}
        allowTeams={!isPrivateDesktop}
        allowGuests={isPrivateDesktop}
        placeholder={intl.formatMessage({
          id: isPrivateDesktop
            ? DesktopTranslation.accessInviteAccountToPrivateDesktopPlaceholder
            : DesktopTranslation.accessInviteAccountToInternalDesktopPlaceholder,
        })}
        isDisabled={processing}
        {...props}
      />
      <Guidance type={ImportantMessageType.INFO}>
        {!isPrivateDesktop && (
          <p>
            <FormattedMessage
              id={
                DesktopTranslation.accessInternalDesktopsInviteAccountHelpText
              }
            />{' '}
            <Link
              to={
                generatePath(`${WORKSPACE_PATHNAME}/admin/users`, {
                  workspaceId: getShortId(currentWorkspace.id),
                }) + `?inviteUser=true`
              }>
              <FormattedMessage
                id={DesktopTranslation.accessInviteAccountLinkText}
              />
            </Link>
          </p>
        )}
        {isPrivateDesktop && (
          <FormattedMessage
            tagName="p"
            id={DesktopTranslation.accessPrivateDesktopsInviteAccountHelpText}
          />
        )}
      </Guidance>
    </>
  );
};
