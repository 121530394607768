import React, { FC, useMemo, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { useQueryParams } from '../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import {
  showToastErrorMessage,
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../shared/components/Toast';
import {
  OnboardingStep,
  OnboardingStepHeader,
  OnboardingStepText,
} from '../OnboardingPage.styled';
import { VerifyPasswordForm } from './VerifyPasswordForm';
import { getAccountKeyset } from '../../../Encryption/Encryption.utils';
import {
  hashAuthPassword,
  hashMasterPassword,
} from '../../../../shared/utils/hash.utils';
import { OnboardingTranslation } from '../../i18n';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../shared/components/ImportantMessage';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { getAccountId } from '../../../Auth/Auth.utils';
import {
  useGetAccount,
  useVerifyEmailChangeAccountMutation,
} from '../../Onboarding.hooks';
import { LOGIN_PATHNAME, LOGOUT_PATHNAME } from '../../../Auth/Auth.constants';
import { VerifyEmailChangeFormValues } from '../../Onboarding.types';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import {
  encryptPrivateKey,
  exportPublicKey,
  generateRsaKeys,
  reencryptPrivateKey,
} from '../../../Encryption/Encryption.crypto.utils';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { useEffectOnce } from 'react-use';
import { validatePendingEmailHashApi } from './VerifyEmailChangeApiRepository';
import { useApolloClient } from '@apollo/client';

const VERIFY_EMAIL_LOGOUT_TIMEOUT = 1500;

export const VerifyEmailChangePage: FC = () => {
  const { id, hash } = useQueryParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const apolloClient = useApolloClient();

  const {
    data: accountById,
    refetch: refetchAccount,
    loading: accountIsLoading,
  } = useGetAccount({
    variables: {
      id: getAccountId(id as string),
    },
    fetchPolicy: 'no-cache',
  });

  const validatePendingEmailHash = async () => {
    if (!id || !hash) {
      return;
    }

    const validationResp = await validatePendingEmailHashApi(
      id as string,
      hash as string,
    );

    if (validationResp.status === 403) {
      navigate({
        search: getQueryParamsFrom({
          id: undefined,
          hash: undefined,
        }),
      });
    }

    setLoading(false);
  };

  useEffectOnce(() => {
    validatePendingEmailHash();
  });

  const keyset = useMemo(() => {
    if (!accountById) {
      return null;
    }
    return getAccountKeyset(accountById.account);
  }, [accountById]);

  const reencryptKeyset = (currentPassword: string) => {
    if (!accountById || !accountById?.account.pendingEmail) {
      return Promise.resolve([]);
    }

    if (keyset) {
      const { privKeyEncrypted } = keyset;
      return Promise.all([
        hashMasterPassword(currentPassword, accountById?.account.email),
        hashMasterPassword(currentPassword, accountById?.account?.pendingEmail),
      ])
        .then(([currentPasswordHash, newPasswordHash]) => {
          return reencryptPrivateKey(
            privKeyEncrypted,
            currentPasswordHash,
            newPasswordHash,
            accountById.account.id,
          );
        })
        .then(reencryptedPrivateKey => [keyset.pubKey, reencryptedPrivateKey]);
    }
    return Promise.all([
      hashMasterPassword(currentPassword, accountById?.account.pendingEmail),
      generateRsaKeys(),
    ]).then(([newPasswordHash, { privateKey, publicKey }]) => {
      return Promise.all([
        exportPublicKey(publicKey),
        encryptPrivateKey(privateKey, newPasswordHash, accountById.account.id),
      ]);
    });
  };

  const [verifyEmailChangeAccountMutation] =
    useVerifyEmailChangeAccountMutation();

  const verifyEmailChangeAccount = ({
    currentPassword,
  }: VerifyEmailChangeFormValues) => {
    if (!accountById || !accountById?.account.pendingEmail) {
      return;
    }
    return Promise.all([
      hashAuthPassword(currentPassword, accountById?.account.email),
      hashAuthPassword(currentPassword, accountById.account?.pendingEmail),
      reencryptKeyset(currentPassword),
    ])
      .catch(e => {
        showToastErrorMessage(
          OnboardingTranslation.verifyEmailChangePasswordIncorrectErrorMessage,
        );
        return null;
      })
      .then(encryptedData => {
        if (!encryptedData) {
          showToastErrorMessage(
            OnboardingTranslation.verifyEmailChangePasswordIncorrectErrorMessage,
          );
          return null;
        }
        const [
          currentPasswordHash,
          newPasswordHash,
          [pubKey, privKeyEncrypted],
        ] = encryptedData;
        return verifyEmailChangeAccountMutation({
          variables: {
            input: {
              id: getAccountId(id as string),
              activationHash: hash as string,
              currentPassword: currentPasswordHash,
              newPassword: newPasswordHash,
              repeatPassword: newPasswordHash,
              pubKey,
              privKeyEncrypted,
            },
          },
        });
      })
      .then(res => {
        if (!res) {
          return;
        }
        if (res.errors?.length) {
          return Promise.reject(res.errors);
        }
        if (!res?.data?.verifyEmailChangeAccount?.account?.pendingEmail) {
          showToastSuccessMessage(
            OnboardingTranslation.verifyEmailChangeSuccessMessage,
          );
          setTimeout(() => {
            navigate(LOGOUT_PATHNAME);
          }, VERIFY_EMAIL_LOGOUT_TIMEOUT);
        }
      })
      .catch(err => {
        showToastGraphQLErrors(err.graphQLErrors || err);
      });
  };

  return loading || accountIsLoading ? (
    <SpinnerFullscreen size={48} />
  ) : (
    <>
      <HeadTitle translationId={OnboardingTranslation.verifyEmailChangeTitle} />
      <OnboardingPage>
        <OnboardingStep>
          <FormattedMessage
            id={OnboardingTranslation.verifyEmailChangeHeader}
            tagName={OnboardingStepHeader}
          />
          {accountById ? (
            <>
              {id && hash && accountById.account?.pendingEmail ? (
                <>
                  <FormattedHTMLMessage
                    id={OnboardingTranslation.verifyEmailChangeDescription}
                    values={{
                      email: accountById.account?.email,
                      pendingEmail: accountById.account?.pendingEmail,
                    }}
                    tagName={OnboardingStepText}
                  />
                  <ImportantMessage type={ImportantMessageType.WARNING}>
                    <FormattedMessage
                      id={OnboardingTranslation.verifyEmailChangeWarning}
                    />
                  </ImportantMessage>
                  <VerifyPasswordForm onSubmit={verifyEmailChangeAccount} />
                </>
              ) : (
                <ImportantMessage type={ImportantMessageType.WARNING}>
                  <FormattedMessage
                    id={
                      OnboardingTranslation.verifyEmailChangeIncorrectRequestErrorMessage
                    }
                  />
                </ImportantMessage>
              )}
            </>
          ) : (
            <>
              <ImportantMessage type={ImportantMessageType.WARNING}>
                <FormattedMessage
                  id={
                    OnboardingTranslation.verifyEmailChangeUnauthorisedErrorMessage
                  }
                />
              </ImportantMessage>
              <Button
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                onClick={() =>
                  navigate({
                    pathname: LOGIN_PATHNAME,
                    search: getQueryParamsFrom({
                      returnTo: encodeURI(window.location.href),
                    }),
                  })
                }
                fullWidth>
                <FormattedMessage
                  id={
                    OnboardingTranslation.verifyEmailChangeUnauthorisedLoginButton
                  }
                />
              </Button>
            </>
          )}
        </OnboardingStep>
      </OnboardingPage>
    </>
  );
};
