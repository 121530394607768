import styled, { css } from 'styled-components';
import {
  DROPZONE_BACKGROUND_COLOR_ACTIVE,
  DROPZONE_BACKGROUND_COLOR_DEFAULT,
  DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME,
} from '../../../Drag/ExternalUrlDropZone/ExternalUrlDropZone.constants';
import { THREADS_SIDEBAR_WIDTH_PERCENT } from './ChatMessageThread/ChatMessageThread.constants';
import { CONVERSATION_MIN_WIDTH } from './ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.constants';

export const StyledConversationView = styled.div`
  background-color: ${p => p.theme.colors.Surface};
  user-select: text;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 1rem;
  position: relative;
  height: 100%;
`;

export const StyledDropZone = styled.div<{
  isDraggingOver: boolean;
  multiple: boolean;
  width: number;
  height: number;
}>`
  position: absolute;
  height: ${p => p.height + 'px'};
  width: ${p => p.width + 'px'};
  background-color: ${DROPZONE_BACKGROUND_COLOR_DEFAULT};
  border: 1px solid ${p => p.theme.colors.Primary};
  border-radius: ${p => (p.multiple ? '20px' : '8px')};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  ${p =>
    css`
      .${DROPZONE_DRAGGING_OVER_WRAPPER_CLASSNAME} & {
        opacity: 1;
        z-index: 1;
        pointer-events: auto;
        background-color: ${p.isDraggingOver
          ? DROPZONE_BACKGROUND_COLOR_ACTIVE
          : DROPZONE_BACKGROUND_COLOR_DEFAULT};

        ${p.isDraggingOver &&
        css`
          background-color: ${DROPZONE_BACKGROUND_COLOR_ACTIVE};
        `}
      }
    `}
`;

export const MessagesLoading = styled.div<{ placement: 'top' | 'bottom' }>`
  position: absolute;
  ${({ placement }) => (placement === 'top' ? 'top: 0;' : 'bottom: 0;')}
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.Surface};
  padding: ${({ theme }) => theme.spacing.xxs} 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.9;
`;

export const MessageLoader = styled.div`
  display: flex;
  margin-bottom: 0 !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Footer = styled.div`
  padding: 0.2rem 1rem 1.2rem 1rem;
`;

export const HrefMenuItem = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  padding: 0.25rem 2rem 0.25rem 1rem;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.125rem;
  position: relative;
  text-align: left;
  color: inherit;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }
`;

export const ScrollToBottomButton = styled.button<{
  visible: boolean;
}>`
  position: absolute;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  bottom: 0.375rem;
  right: 0.625rem;
  width: 2rem;
  height: 2rem;
  background: ${({ theme }) => theme.colors.Surface};
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05),
    0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  border-radius: 3.75rem;
  cursor: pointer;
  border: none;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  margin-left: auto;
  z-index: 2;

  svg {
    position: absolute;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledConversatinInnerContainer = styled.div<{
  threadSidebarIsOpen: boolean;
  currentConversationWidth: number;
}>`
  width: ${({ threadSidebarIsOpen, currentConversationWidth }) =>
    threadSidebarIsOpen && currentConversationWidth > CONVERSATION_MIN_WIDTH
      ? `calc(100% - ${THREADS_SIDEBAR_WIDTH_PERCENT}%)`
      : '100%'};
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`;
