import React, { FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { LaunchIcon } from '../../../../../shared/icons';
import { StyledLink } from './CustomIntegrationsDocumentationLink.styled';
import { appEnv } from '../../../../../appEnv';
import { AppIntegrationsTranslation } from '../../../i18n';

export const CustomIntegrationDocumentationLink: FC = () => {
  return (
    <StyledLink href={appEnv.CUSTOM_INTEGRATIONS_DOCUMENTATION_LINK}>
      <FormattedHTMLMessage
        id={AppIntegrationsTranslation.appCustomIntegrationDocumentationLink}
      />
      <LaunchIcon />
    </StyledLink>
  );
};
