import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { VaultItemApiType, VaultItemFormValues } from '../Encryption.types';
import { Field, Formik } from 'formik';
import { FormInput } from '../../../shared/components/Input';
import {
  FormBody,
  FormControls,
  FormFields,
  FormFieldWrapper,
  ImageUploadWrapper,
  OwnerNoSharingWarning,
  StyledForm,
} from './VaultItemForm.styled';
import { PasswordGenerator } from './PasswordGenerator';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { EncryptionTranslation } from '../i18n';
import { PasswordInputFieldWithIcons } from './PasswordInputFieldWithIcons';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../shared/components/ImportantMessage';
import { appEnv } from '../../../appEnv';
import { HiddenInput } from '../../Onboarding/OnboardingPage/RegisterationPage/RegistrationForm/RegistrationForm.styled';
import { ImageUpload, ImageUploadBody } from '../../Image';
import {
  CustomAppFormImage,
  ImageUploadButton,
  ImageUploadError,
} from '../../AppStore/Forms/CustomAppForm/CustomAppForm.styled';
import UploadImagePlaceholder from '../../../shared/assets/images/image-upload.svg';
import { AppStoreTranslation } from '../../AppStore/i18n';

interface EditVaultItemFormProps {
  decryptedVaultItem?: VaultItemApiType;
  displayOwnerNoSharingWarning?: boolean;
  displayUrlField?: boolean;
  displayImageField?: boolean;
  disabled?: boolean;
  onSubmit: (vaultItem: VaultItemFormValues) => void;
  processing?: boolean;
  displayGroupSelect?: boolean;
  groupAppId?: string;
  appId?: string;
}

export const VaultItemForm: FC<EditVaultItemFormProps> = ({
  decryptedVaultItem,
  displayOwnerNoSharingWarning,
  displayUrlField,
  displayImageField,
  disabled,
  onSubmit,
  processing,
  displayGroupSelect,
  groupAppId,
  appId,
}) => {
  const intl = useIntl();
  const [imageUrl, setImageUrl] = useState(
    decryptedVaultItem?.image?.contentUrl,
  );

  const EditVaultItemFormSchema: Schema<VaultItemFormValues> =
    Yup.object().shape(
      decryptedVaultItem
        ? {
            login: Yup.string(),
            password: Yup.string(),
            ...(displayUrlField
              ? {
                  url: Yup.string().required(
                    EncryptionTranslation.formUrlRequired,
                  ),
                }
              : {}),
          }
        : {
            login: Yup.string().when('password', {
              is: password => !password,
              then: Yup.string().required(),
            }),
            password: Yup.string().when('login', {
              is: login => !login,
              then: Yup.string().required(),
            }),
            ...(displayImageField ? { image: Yup.string() } : {}),
            ...(displayUrlField
              ? {
                  url: Yup.string().required(
                    EncryptionTranslation.formUrlRequired,
                  ),
                }
              : {}),
          },
      decryptedVaultItem
        ? undefined
        : [
            ['login', 'password'],
            ['password', 'login'],
          ],
    );

  const initialValues: VaultItemFormValues = {
    login: decryptedVaultItem?.login || '',
    password: decryptedVaultItem?.password || '',
    ...(displayImageField
      ? { image: decryptedVaultItem?.image?.id || '' }
      : {}),
    ...(displayUrlField ? { url: decryptedVaultItem?.url || '' } : {}),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditVaultItemFormSchema}
      validateOnMount
      onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, errors, touched, values }) => (
        <StyledForm data-testid="vault-form">
          <FormBody>
            <FormFields displayAsLeftColumn={displayImageField}>
              <Field
                name="login"
                type="text"
                label={intl.formatMessage({
                  id: EncryptionTranslation.editPasswordLoginInputLabel,
                })}
                disabled={disabled}
                displayErrors={false}
                component={FormInput}
                data-testid="login-input"
              />
              <FormFieldWrapper>
                {/*Chrome email autocomplete disabling workaround*/}
                <HiddenInput type="password" tabIndex={-1} />

                <Field
                  name="password"
                  displayErrors={false}
                  disabled={disabled}
                  component={PasswordInputFieldWithIcons}
                />
              </FormFieldWrapper>
              {displayUrlField && (
                <FormFieldWrapper>
                  <Field
                    name="url"
                    placeholder="https://"
                    label={intl.formatMessage({
                      id: EncryptionTranslation.editPasswordUrlInputLabel,
                    })}
                    displayErrors={true}
                    disabled={disabled}
                    component={FormInput}
                    maxLength={null}
                    data-testid="url-input"
                  />
                </FormFieldWrapper>
              )}
            </FormFields>

            {displayImageField && (
              <ImageUploadWrapper
                className="image-upload-wrapper"
                data-testid="image-upload-wrapper">
                <ImageUpload
                  id="vault-item-image-upload"
                  onUpload={({ id, contentUrl }) => {
                    setFieldValue('image', id);
                    setImageUrl(contentUrl);
                  }}>
                  <ImageUploadBody>
                    <CustomAppFormImage
                      src={imageUrl || UploadImagePlaceholder}
                      data-testid="vault-item-logo"
                    />
                    {errors.image && touched.image && (
                      <ImageUploadError>
                        <FormattedMessage
                          id={AppStoreTranslation.customAppFormLogoRequired}
                        />
                      </ImageUploadError>
                    )}
                    <ImageUploadButton
                      size={ButtonSize.sm}
                      mode={ButtonMode.secondary}
                      data-testid="image-upload-button">
                      <FormattedMessage
                        id={AppStoreTranslation.customAppFormLogoButton}
                      />
                    </ImageUploadButton>
                  </ImageUploadBody>
                </ImageUpload>
              </ImageUploadWrapper>
            )}
          </FormBody>
          {displayOwnerNoSharingWarning && !disabled && (
            <OwnerNoSharingWarning data-testid="owner-no-sharing-warning">
              <ImportantMessage type={ImportantMessageType.WARNING}>
                <FormattedHTMLMessage
                  id={
                    EncryptionTranslation.editVaultFormOwnerNoSharingWarningMessage
                  }
                  values={{
                    learnMoreLink: appEnv.SUPPORT_PASSWORD_MANAGER_URL,
                  }}
                />
              </ImportantMessage>
            </OwnerNoSharingWarning>
          )}
          {!disabled && (
            <PasswordGenerator
              onPasswordGenerate={(value: string) =>
                setFieldValue('password', value)
              }
            />
          )}
          {!disabled && (
            <FormControls>
              <Button
                type="submit"
                disabled={
                  processing ||
                  isSubmitting ||
                  (appId &&
                    (!values.password.length || !values.login.length)) ||
                  (!values.password.length && !values.login.length) ||
                  (displayGroupSelect && !groupAppId)
                }
                mode={ButtonMode.primary}
                size={ButtonSize.md}
                data-testid="submit-button">
                {decryptedVaultItem ? (
                  <FormattedMessage
                    id={EncryptionTranslation.editVaultFormUpdateButton}
                  />
                ) : (
                  <FormattedMessage
                    id={EncryptionTranslation.createVaultFormUpdateButton}
                  />
                )}
              </Button>
            </FormControls>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};
