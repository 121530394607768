import takeRight from 'lodash/takeRight';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useLaptop,
  useMediumScreen,
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../shared/hooks';
import { getShortId } from '../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { database } from '../../../Database';
import { useCurrentDesktop } from '../../../Desktop/Desktop.hooks';
import { useDesktopSidebar } from '../../../Desktop/DesktopSidebar';
import { useCaseGetDesktopFolders } from '../../../Desktop/UseCase/getDesktopFolders';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { FolderCrumbsLocationContext } from '../../Folder.constants';
import { FolderApiType } from '../../Folder.types';
import { injectedDataType } from './FolderCrumbs';
import { getFolderCrumbs } from './FolderCrumbs.utils';

export const FolderCrumbsViewModel = (
  locationContext: FolderCrumbsLocationContext,
  injectedData: injectedDataType,
) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const isMobile = useMobile();
  const isTablet = useTablet();
  const isLaptop = useLaptop();
  const isMediumScreen = useMediumScreen();
  const { identifier: hasSidebarItem } = useDesktopSidebar();
  const [outOfMenuFolderCount, setOutOfMenuFolderCount] = useState(1);
  const foldersLengthInDatabaseRef = useRef(0);
  const desktop = useCurrentDesktop();
  const { workspace } = useCurrentWorkspace();

  const [desktopFolders, setDesktopFolders] = useState<FolderApiType[]>([]);
  database.desktopFolders.count().then((res: number) => {
    foldersLengthInDatabaseRef.current = res;
  });

  useEffect(
    () => {
      if (injectedData?.desktop?.id) {
        (async () => {
          await database.desktopFolders
            .where('desktopId')
            .equals(injectedData.desktop.id)
            .toArray()
            .then(res => {
              setDesktopFolders(res.map(folder => folder.sourceData));
            });
        })();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedData, foldersLengthInDatabaseRef.current],
  );

  const hasInjectedData = injectedData.desktop && injectedData.folderId;

  const folders = useMemo(
    () =>
      getFolderCrumbs(
        desktopFolders,
        injectedData.folderId || (queryParams.folderId as string),
      ),
    [desktopFolders, injectedData, queryParams.folderId],
  );

  /**
   * Folders that will always be visible with name (backwards from current)
   */
  const alwaysShownFolders = useMemo(
    () => takeRight(folders, outOfMenuFolderCount),
    [folders, outOfMenuFolderCount],
  );

  /**
   * Folders that will be added to the folders menu
   */
  const foldersInMenu = useMemo(
    () => folders.slice(0, -outOfMenuFolderCount),
    [folders, outOfMenuFolderCount],
  );

  /**
   * Logic for determining reliable number of folders to present
   * outside the folder-menu. This will be tweaked and adjusted
   * based on feedback, and more complex conditions will likely be
   * added (i.e. its consolidated, but chat is not enabled).
   * Folder names (except last folder) will also truncate - so there is
   * some room for folder crumbs that are longer than calculated.
   */
  useEffect(() => {
    switch (locationContext) {
      case FolderCrumbsLocationContext.LINKS_CONSOLIDATED: {
        if (isLaptop) {
          setOutOfMenuFolderCount(4);
        } else if (isMediumScreen && hasSidebarItem) {
          setOutOfMenuFolderCount(3);
        } else if (isMediumScreen) {
          setOutOfMenuFolderCount(7);
        } else {
          setOutOfMenuFolderCount(8);
        }
        break;
      }
      case FolderCrumbsLocationContext.LINKS_TAB:
        if (isMobile) {
          setOutOfMenuFolderCount(1);
        } else if (isTablet) {
          setOutOfMenuFolderCount(2);
        } else if (isLaptop) {
          setOutOfMenuFolderCount(4);
        } else if (isMediumScreen && hasSidebarItem) {
          setOutOfMenuFolderCount(6);
        } else if (isMediumScreen) {
          setOutOfMenuFolderCount(7);
        } else {
          setOutOfMenuFolderCount(8);
        }
        break;
      case FolderCrumbsLocationContext.LINKDETAILS:
        setOutOfMenuFolderCount(2);
        break;
    }
  }, [
    hasSidebarItem,
    isLaptop,
    isMediumScreen,
    isMobile,
    isTablet,
    locationContext,
  ]);

  const { getDesktopFolders } = useCaseGetDesktopFolders();

  const handleRootClick = useCallback(() => {
    if (desktop) {
      getDesktopFolders(desktop.id, workspace.id);
    }
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        folderId: undefined,
        linkDetails: undefined,
      }),
    });
  }, [desktop, getDesktopFolders, navigate, queryParams, workspace.id]);

  const handleFolderClick = useCallback(
    (folder: FolderApiType) => {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          folderId: getShortId(folder.id),
          linkDetails: undefined,
        }),
      });
    },
    [navigate, queryParams],
  );

  return {
    handleRootClick,
    handleFolderClick,
    alwaysShownFolders,
    foldersInMenu,
    folders,
    hasInjectedData,
    queryParams,
  };
};
