import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '../../../shared/components/Menu';
import { FluidIcon } from '../../../shared/icons';
import { ContentLayout } from '../ContentLayout.constants';
import { DesktopTranslation } from '../../Desktop/i18n';

interface FluidLayoutOptionProps {
  setLayout: (newLayout: ContentLayout) => void;
  currentLayout: ContentLayout;
}

export const FluidLayoutOption: FC<FluidLayoutOptionProps> = ({
  setLayout,
  currentLayout,
}) => {
  return (
    <MenuItem
      icon={() => <FluidIcon width={14} height={14} />}
      displayCheckMark={currentLayout === ContentLayout.FLUID}
      onClick={() => {
        setLayout(ContentLayout.FLUID);
      }}>
      <FormattedMessage id={DesktopTranslation.appsLayoutFluid} />
    </MenuItem>
  );
};
