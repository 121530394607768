import React, { FC, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AdminHeader,
  AdminHeaderTitle,
  AdminPage,
  DrawerHeader,
  Section,
} from '../../Admin';
import { BillingTranslation } from '../i18n';
import {
  BillingContact,
  BillingInformation,
  Invoices,
  PaymentMethods,
  PlanInformation,
} from './Sections';
import { useGetPaymentCustomerByWorkspaceQuery } from '../Billing.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../shared/components/ImportantMessage';
import { billingIsOk } from '../Billing.utils';
import { useMobile } from '../../../shared/hooks';
import { StripeElementsProvider } from '../StripeElements';
import { WorkspaceContext } from '../../Workspace/Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const BillingAdmin: FC<{}> = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { workspace, refetchWorkspaces } = useCurrentWorkspace();
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  useEffect(() => {
    refetchWorkspaces();
  }, []); // eslint-disable-line

  const { data, refetch, loading } = useGetPaymentCustomerByWorkspaceQuery({
    variables: {
      id: workspace.id,
    },
    fetchPolicy: 'no-cache',
  });

  /**
   * Set last admin path
   */
  useEffect(() => {
    if (setLastOpenedAdminPath) {
      setLastOpenedAdminPath(pathname);
    }
  }, [pathname, setLastOpenedAdminPath]);

  const {
    permissions: { canViewBillingPage },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();

  if (permissionsLoaded && !canViewBillingPage) {
    navigate(`/workspace/${getShortId(workspace.id)}/admin`);
  } else if (!permissionsLoaded) {
    return null;
  }

  const paymentCustomer = data?.workspace?.paymentCustomer;
  return (
    <AdminPage data-testid="billing-admin-page">
      {isMobile ? (
        <DrawerHeader
          onRequestClose={() =>
            navigate(`/workspace/${getShortId(workspace.id)}`)
          }>
          <FormattedMessage id={BillingTranslation.adminHeaderTitle} />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage id={BillingTranslation.adminHeaderTitle} />
          </AdminHeaderTitle>
        </AdminHeader>
      )}

      {!billingIsOk(workspace) && (
        <Section>
          <ImportantMessage
            type={ImportantMessageType.CRITICAL}
            data-testid="important-msg">
            <FormattedMessage
              id={
                workspace.canceledAt
                  ? BillingTranslation.adminBillingCancelled
                  : BillingTranslation.adminBillingNotOk
              }
            />
          </ImportantMessage>
        </Section>
      )}

      <PlanInformation paymentCustomer={paymentCustomer} />
      {/* TODO: Remove refetch here too */}
      <BillingInformation paymentCustomer={paymentCustomer} refetch={refetch} />
      <BillingContact paymentCustomer={paymentCustomer} />
      <StripeElementsProvider>
        <PaymentMethods id={paymentCustomer ? paymentCustomer.id : null} />
      </StripeElementsProvider>
      <Invoices paymentCustomer={paymentCustomer} loading={loading} />
    </AdminPage>
  );
};
