import React, { FC, PropsWithChildren } from 'react';
import { StyledTimelineDay, TimelineDayTitle } from './TimelineDay.styled';
import { useIntl } from 'react-intl';
import { formatDistance, isToday, isYesterday } from 'date-fns';
import { TimelineTranslation } from '../../i18n';

interface TimelineDayProps {
  date: string;
}

export const TimelineDay: FC<PropsWithChildren<TimelineDayProps>> = ({
  children,
  date,
}) => {
  const intl = useIntl();
  const formatDayTitle = () => {
    if (isToday(new Date(date))) {
      return intl.formatMessage({ id: TimelineTranslation.dayDateToday });
    }
    if (isYesterday(new Date(date))) {
      return intl.formatMessage({ id: TimelineTranslation.dayDateYesterday });
    }
    return formatDistance(new Date(date), new Date(), { addSuffix: true });
  };

  return (
    <StyledTimelineDay>
      <TimelineDayTitle data-testid="timeline-day-date">
        {formatDayTitle()}
      </TimelineDayTitle>
      {children}
    </StyledTimelineDay>
  );
};
