import { FC, ReactNode, useCallback, useRef } from 'react';
import {
  MessageFormButton,
  SendButton,
  StyledEditButtons,
  StyledMessageFormFooter,
} from './MessageForm.styled';
import React from 'react';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import {
  MentionIcon,
  ChatReactionIcon,
  AttachmentIcon,
} from '../../../../../shared/icons';
import { ENTER_KEY } from '../../../../Keyboard/Keyboard.constants';
import { ChatTranslation } from '../../../i18n';
import { SendIcon } from '../../../icons';
import { MessageFormType } from './MessageForm.types';
import { useEmojiPickerControls } from '../../../EmojiPickerPopover';
import {
  EditorControls,
  MentionData,
} from '../../../../../shared/components/Editor';
import { useIntl } from 'react-intl';

interface MessageFormFooterProps {
  editorControls: EditorControls;
  resetEditor: () => void;
  droppedAttachments: File[] | undefined;
  onFilesDropped: (value: React.SetStateAction<File[] | undefined>) => void;
  mentionData: MentionData[];
  inputFileNodeId: string;
  canSubmit: boolean;
  submitMessage: () => Promise<void>;
  formType: MessageFormType;
  onCancel?: () => void;
  allowAttachments?: boolean;
  buttonsLables?: {
    cancelLabel: ReactNode;
    submitLabel: ReactNode;
  };
}

export const MessageFormFooter: FC<MessageFormFooterProps> = ({
  editorControls,
  resetEditor,
  droppedAttachments,
  onFilesDropped,
  mentionData,
  inputFileNodeId,
  canSubmit,
  submitMessage,
  formType,
  onCancel,
  allowAttachments,
  buttonsLables,
}) => {
  const intl = useIntl();
  const pickEmojiButtonRef = useRef<HTMLButtonElement>(null);

  const handleTriggerMention = () => {
    editorControls.insertMentionTrigger();
  };

  const { showEmojiPicker } = useEmojiPickerControls();

  const pickEmoji = useCallback(() => {
    showEmojiPicker(pickEmojiButtonRef)
      .then(emoji => {
        if (!('native' in emoji)) {
          return;
        }

        editorControls.insertText(emoji.native, true);
      })
      .catch(() => {});
  }, [editorControls, showEmojiPicker]);

  const handleCancel = useCallback(() => {
    resetEditor();

    if (droppedAttachments && droppedAttachments?.length > 0) {
      onFilesDropped(undefined);
    }

    onCancel?.();
  }, [droppedAttachments, onCancel, onFilesDropped, resetEditor]);

  return (
    <StyledMessageFormFooter>
      {mentionData.length ? (
        <MessageFormButton
          role="button"
          icon={MentionIcon}
          onClick={handleTriggerMention}
          data-tooltip-id="global-tooltip"
          data-tooltip-content={intl.formatMessage({
            id: ChatTranslation.tooltipMentionIcon,
          })}
          data-tooltip-place={TooltipPlace.top}
          data-testid="mention-btn"
          aria-label={intl.formatMessage({
            id: ChatTranslation.newMessageFormInsertMentionButton,
          })}
        />
      ) : null}

      <MessageFormButton
        ref={pickEmojiButtonRef}
        type="button"
        icon={ChatReactionIcon}
        onClick={pickEmoji}
        data-tooltip-id="global-tooltip"
        data-tooltip-content={intl.formatMessage({
          id: ChatTranslation.tooltipEmojiIcon,
        })}
        data-tooltip-place={TooltipPlace.top}
        data-testid="pick-emoji-btn"
        aria-label={intl.formatMessage({
          id: ChatTranslation.newMessageFormPickEmojiButton,
        })}
      />

      {allowAttachments && (
        <MessageFormButton
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === ENTER_KEY) {
              const targetElement = e.target as HTMLDivElement;
              targetElement.click();
            }
          }}
          as="label"
          htmlFor={inputFileNodeId}
          role="button"
          data-tooltip-id="global-tooltip"
          data-tooltip-content={
            allowAttachments
              ? intl.formatMessage({
                  id: ChatTranslation.tooltipAttachFileIcon,
                })
              : ''
          }
          data-tooltip-place={TooltipPlace.top}
          data-testid="attachment-upload-btn"
          aria-label={intl.formatMessage({
            id: ChatTranslation.attachmentUploadButtonLabel,
          })}>
          <AttachmentIcon />
        </MessageFormButton>
      )}
      {buttonsLables && (
        <StyledEditButtons data-testid="footer">
          <Button
            mode={ButtonMode.secondary}
            size={ButtonSize.sm}
            onClick={handleCancel}
            data-testid="cancel-btn">
            {buttonsLables.cancelLabel}
          </Button>

          <Button
            mode={ButtonMode.primary}
            size={ButtonSize.sm}
            disabled={!canSubmit}
            onClick={submitMessage}
            data-testid="submit-btn">
            {buttonsLables.submitLabel}
          </Button>
        </StyledEditButtons>
      )}

      {formType === MessageFormType.NewMessage && (
        <SendButton
          type="button"
          data-tooltip-id="global-tooltip"
          data-tooltip-content={
            canSubmit
              ? intl.formatMessage({
                  id: ChatTranslation.tooltipSendMessageIcon,
                })
              : ''
          }
          data-tooltip-place={TooltipPlace.top}
          data-testid="send-message-btn"
          aria-label={intl.formatMessage({
            id: ChatTranslation.newMessageFormSendButton,
          })}
          disabled={!canSubmit}
          onClick={submitMessage}>
          <SendIcon />
        </SendButton>
      )}
    </StyledMessageFormFooter>
  );
};
