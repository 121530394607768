import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { OnboardingTranslation } from '../../i18n';
import {
  EndScreenDescription,
  EndScreenTitle,
  StyledEndScreen,
} from './EndPage.styled';
import { OnboardingPage } from '../OnboardingPage';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { SignUpStep } from '../../Onboarding.types';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { useOnboardingTrackingEventOnInit } from '../../tracking/OnboardingTracking.hooks';
import { getShortId } from '../../../../shared/utils/id';

export const EndPage: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = useMemo(() => {
    return getShortId(workspace.id);
  }, [workspace.id]);
  const { account } = useCurrentAccount();
  const currentStep = account?.currentStep;

  const redirectToWorkspace = useCallback(() => {
    navigate(generatePath(WORKSPACE_PATHNAME, { workspaceId }));
  }, [navigate, workspaceId]);

  useEffect(() => {
    if (currentStep !== SignUpStep.completed) {
      redirectToWorkspace();
    }
  }, [currentStep, redirectToWorkspace]);

  useOnboardingTrackingEventOnInit('complete_onboarding');

  return (
    <>
      <HeadTitle
        translationId={OnboardingTranslation.onboardingEndScreenTitle}
      />
      <OnboardingPage>
        <StyledEndScreen>
          <EndScreenTitle data-testid="title">
            <FormattedMessage
              id={OnboardingTranslation.onboardingEndScreenHeading}
            />
          </EndScreenTitle>
          <EndScreenDescription data-testid="description">
            <FormattedMessage
              id={OnboardingTranslation.onboardingEndScreenDescription}
            />
          </EndScreenDescription>
          <Button
            type="button"
            mode={ButtonMode.primary}
            size={ButtonSize.md}
            onClick={redirectToWorkspace}
            fullWidth
            data-testid="go-to-workspace-btn">
            <FormattedMessage
              id={OnboardingTranslation.onboardingEndScreenButtonLabel}
            />
          </Button>
        </StyledEndScreen>
      </OnboardingPage>
    </>
  );
};
