import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { useQueryParams } from '../../../shared/hooks';
import { LongArrowRightIcon } from '../../../shared/icons';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { GlobalSearchTranslation } from '../i18n';
import {
  ExpandedSearchTabs,
  SearchAppIntegrationSection,
  StyledAppIcon,
  StyledAppIntegrationIcon,
  StyledExpandedSearch,
} from './ExpandedSearch.styled';
import { SearchAppsIntegration } from './SearchAppsIntegration';
import { EnterIcon } from '../../../shared/icons/EnterIcon';
import {
  useAppIntegrationList,
  useGetTabsPermissionsState,
} from '../GlobalSearch.hooks';
import { extractNodes } from '../../../shared/api/api.utils';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useCurrentWorkspacePermissions,
} from '../../Workspace/Workspace.hooks';
import { isCurrentAccountGuest } from '../../User/User.utils';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { TABS } from './ExpandedSearch.constants';
import { GlobalSearchResult } from '../GlobalSearch.queries';
import { PageInfoApiType } from '../../../shared/api/api.types';
import { SearchGlobal } from './SearchGlobal';
import { showToastGraphQLErrors } from '../../../shared/components/Toast';
import { APPS_INTEGRATIONS_PATHNAME } from '../../Workspace/Workspace.constants';
import { getShortId } from '../../../shared/utils/id';

export enum GlobalSearchTab {
  apps = 'apps',
  links = 'links',
  chats = 'chats',
  appsIntegrations = 'appsIntegrations',
}

export interface TabsStateInitialValuesType {
  data: GlobalSearchResult[];
  prevSearch: string | null;
  pageInfo: PageInfoApiType;
  totalCount: number;
  prevConversationId: string;
}

export interface TabsStateType {
  [key: string]: TabsStateInitialValuesType;
}

export const ExpandedSearch: FC<{
  searchSectionBlur: () => void;
  skipBlurRef: MutableRefObject<boolean>;
}> = ({ searchSectionBlur, skipBlurRef }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { globalSearchTab, globalSearch } = queryParams;
  const { workspace } = useCurrentWorkspace();
  const { formatMessage } = useIntl();
  const {
    permissions: { canViewGlobalSearchIntegrations },
  } = useCurrentWorkspacePermissions();

  const tabsStateInitialValues = useMemo(
    () => ({
      data: [],
      prevSearch: null,
      totalCount: 0,
      prevConversationId: '',
      pageInfo: {
        startCursor: '',
        endCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
      },
    }),
    [],
  );

  const allowedTabs = useGetTabsPermissionsState();
  const initialTabsState = useMemo(() => {
    const allowedTabsMap = Object.keys(allowedTabs).reduce((acc, cur) => {
      if (allowedTabs[cur]) {
        acc[cur] = tabsStateInitialValues;
      }
      return acc;
    }, {} as { [key: string]: TabsStateInitialValuesType });
    return {
      [TABS.ALL_CONTENT]: tabsStateInitialValues,
      ...allowedTabsMap,
    };
  }, [allowedTabs, tabsStateInitialValues]);
  const [tabsState, setTabsState] = useState<TabsStateType>(initialTabsState);

  const {
    permissions: {
      canViewWorkspaceAppIntegrations,
      canViewPersonalAppIntegrations,
    },
  } = useCurrentWorkspacePermissions();
  const {
    data,
    refetch: refetchIntegrationList,
    loading: appListLoading,
  } = useAppIntegrationList({
    variables: {
      workspace: workspace.id,
      filterOnlyAccessible: true,
      active: true,
    },
    fetchPolicy: 'network-only',
  });

  const appsList = useMemo(
    () =>
      extractNodes(data?.appIntegrations).filter(
        app => app?.marketplaceItem && app.marketplaceItem?.searchable,
      ),
    [data],
  );

  const isGuestAccount = useMemo(() => {
    return isCurrentAccountGuest(currentAccount, getShortId(workspace.id));
  }, [currentAccount, workspace.id]);
  const appIntegrationsDisabled = !(
    canViewWorkspaceAppIntegrations || canViewPersonalAppIntegrations
  );

  const handleAppIntegrationClick = () => {
    if (appIntegrationsDisabled || appListLoading) {
      return null;
    }

    if (!!appsList.length) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          globalSearchTab: GlobalSearchTab.appsIntegrations,
        }),
      });
    } else {
      navigate(
        generatePath(APPS_INTEGRATIONS_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          search: getQueryParamsFrom({
            ...queryParams,
            globalSearch: undefined,
            globalSearchTab: undefined,
          }),
        }),
      );
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'Enter') {
      handleAppIntegrationClick();
    }
  };

  useEffect(() => {
    setTabsState(initialTabsState);
  }, [globalSearch, initialTabsState]);

  useEffect(() => {
    if (globalSearchTab === GlobalSearchTab.appsIntegrations) {
      refetchIntegrationList().catch(err =>
        showToastGraphQLErrors(err.graphQLErrors),
      );
    }
  }, [globalSearchTab, refetchIntegrationList]);

  return (
    <StyledExpandedSearch data-testid="extended-search">
      {globalSearchTab !== GlobalSearchTab.appsIntegrations ? (
        <>
          <ExpandedSearchTabs>
            {!isGuestAccount && canViewGlobalSearchIntegrations && (
              <SearchAppIntegrationSection
                disabled={appIntegrationsDisabled || appListLoading}
                onKeyDown={handleKeyDown}
                onClick={handleAppIntegrationClick}
                tabIndex={0}>
                <div
                  data-tooltip-place={TooltipPlace.top}
                  data-tooltip-content={
                    !!appsList.length && !appIntegrationsDisabled
                      ? formatMessage({
                          id: GlobalSearchTranslation.tooltipAppIntegrations,
                        })
                      : appIntegrationsDisabled
                      ? formatMessage({
                          id: GlobalSearchTranslation.appIntegrationsNoAccess,
                        })
                      : ''
                  }>
                  <StyledAppIntegrationIcon>
                    <LongArrowRightIcon />
                  </StyledAppIntegrationIcon>
                  <FormattedMessage
                    id={
                      !!appsList.length && !appIntegrationsDisabled
                        ? GlobalSearchTranslation.searchInAppIntegrationButton
                        : GlobalSearchTranslation.searchInAppIntegrationEmptyButton
                    }
                  />
                  {!appIntegrationsDisabled &&
                    appsList?.map(
                      ({ marketplaceItem }, idx) =>
                        marketplaceItem && (
                          <StyledAppIcon
                            key={marketplaceItem.app.logo.contentUrl + idx}
                            src={marketplaceItem.app.logo.contentUrl}
                            alt={marketplaceItem.app.name}
                          />
                        ),
                    )}
                </div>
                <div className="enter-icon">
                  <EnterIcon />
                </div>
              </SearchAppIntegrationSection>
            )}
          </ExpandedSearchTabs>
          <SearchGlobal
            tabsState={tabsState}
            searchQuery={globalSearch as string}
            handleTabSearchState={setTabsState}
            setTabsState={setTabsState}
            searchSectionBlur={searchSectionBlur}
            initialTabsState={initialTabsState}
            skipBlurRef={skipBlurRef}
          />
        </>
      ) : (
        <SearchAppsIntegration
          searchSectionBlur={searchSectionBlur}
          appsList={appsList}
        />
      )}
    </StyledExpandedSearch>
  );
};
