import styled from 'styled-components';

export const SubLabel = styled.span`
  margin-top: 0.25rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 1.2;
  word-break: break-all;
`;
