import styled, { css } from 'styled-components';
import { Input } from '../../../../shared/components/Input';

export const UserList = styled.div`
  padding-top: 1rem;
`;

export const TitleInput = styled(Input)<{ withError?: boolean }>`
  margin-bottom: 1rem;

  ${({ withError, theme }) =>
    withError &&
    css`
      border: 1px solid ${theme.colors.CriticalDark};

      &:focus {
        border: 1px solid ${theme.colors.CriticalDark};
        padding: 0.625rem 1rem;
      }
    `}
`;

export const FormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  padding-left: 0.5rem;

  button ~ button {
    margin-left: 0.75rem;
  }
`;

export const ImportantMessageContainer = styled.div`
  margin-top: 1rem;
`;

export const NewConversationTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TitleMaximumSymbolsError = styled.div`
  position: absolute;
  bottom: 0;
  color: ${p => p.theme.colors.CriticalDark};
  font-family: ${p => p.theme.fonts.Medium};
  font-size: 0.75rem;
`;
