import React, { FC, useEffect } from 'react';
import { Spinner } from '../../../shared/components/Spinner';
import { useQueryParams } from '../../../shared/hooks';
import { MembersUniversal } from '../../Chat/MembersUniversal';
import { ScheduledConferenceDeleteConfirmationModal } from './ScheduledConferenceView/ScheduledConferenceDeleteConfirmationModal/ScheduledConferenceDeleteConfirmationModal';
import {
  ConferenceApiType,
  ScheduledChatConferenceApiType,
} from '../Conference.types';
import { ScheduleConferenceModal } from '../ConferenceSchedule/ScheduleConferenceModal';
import { ScheduleRepeatingDeclineConfirmationModal } from '../ConferenceSchedule/ScheduleRepeatingConferenceModal/ScheduleRepeatingDeclineConfirmationModal';
import { useLocation } from 'react-router-dom';
import {
  SegmentEmpty,
  SegmentType,
  useSetLastUsedSegmentPath,
} from '../../Segment';
import { ConferencesPageContent } from './ConferencesPageContent';

export const DEFAULT_PAGE_SIZE = 5;

type ConferencesPageProps = {
  loading: boolean;
  currentConferenceId?: string;
  currentScheduledConference?: ScheduledChatConferenceApiType;
  currentConference?: ConferenceApiType;
  handleDeleteConference: (
    deleteAllConferences?: boolean,
    deleteThisAndFollowing?: boolean,
  ) => void;
};

export const ConferencesPage: FC<ConferencesPageProps> = ({
  loading,
  currentConferenceId,
  currentScheduledConference,
  currentConference,
  handleDeleteConference,
}) => {
  const queryParams = useQueryParams();

  const { pathname } = useLocation();
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.MEETINGS);

  useEffect(() => {
    const { meetingsActiveTab } = queryParams;
    setLastSegmentPath(
      `${pathname}${
        meetingsActiveTab ? `?meetingsActiveTab=${meetingsActiveTab}` : ''
      }`,
    );
  }, [
    pathname,
    queryParams,
    queryParams.meetingsActiveTab,
    setLastSegmentPath,
  ]);

  return (
    <>
      {loading ? (
        <SegmentEmpty>
          <Spinner />
        </SegmentEmpty>
      ) : (
        <>
          <ConferencesPageContent
            currentConferenceId={currentConferenceId}
            currentScheduledConference={currentScheduledConference}
            currentConference={currentConference}
          />
          <MembersUniversal />
        </>
      )}
      {queryParams?.scheduleConferenceOpened && <ScheduleConferenceModal />}
      {queryParams?.scheduleConferenceDeleteConfirmationModalOpen && (
        <ScheduledConferenceDeleteConfirmationModal
          onSubmit={handleDeleteConference}
        />
      )}
      {queryParams?.scheduleConferenceDeclineConfirmationModalOpen && (
        <ScheduleRepeatingDeclineConfirmationModal />
      )}
    </>
  );
};
