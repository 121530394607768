import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInput } from '../../../../shared/components/Input';
import { BillingTranslation } from '../../i18n';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { StyledBillingContactForm } from './EditBillingContactForm.styled';
import { Spinner } from '../../../../shared/components/Spinner';
import { useCurrentWorkspacePermissions } from '../../../Workspace/Workspace.hooks';
import { PaymentCustomerApiType } from '../../Billing.types';

export interface BillingContactFormValues {
  billingEmail: string;
}

const BillingContactSchema: Schema<BillingContactFormValues> =
  Yup.object().shape({
    billingEmail: Yup.string()
      .email(BillingTranslation.formEmailInvalid)
      .required(BillingTranslation.formEmailRequired),
  });

interface BillingContactFormProps {
  onSubmit: (
    data: BillingContactFormValues,
    helpers: FormikHelpers<BillingContactFormValues>,
  ) => Promise<void>;
  paymentCustomer: PaymentCustomerApiType;
}

export const BillingContactForm: FC<BillingContactFormProps> = ({
  onSubmit,
  paymentCustomer,
}) => {
  const {
    permissions: { canEditBillingPage },
  } = useCurrentWorkspacePermissions();
  const { formatMessage } = useIntl();

  const initialValues: BillingContactFormValues = {
    billingEmail: paymentCustomer.billingEmail,
  };

  const handleSubmit = (
    data: BillingContactFormValues,
    helpers: FormikHelpers<BillingContactFormValues>,
  ) => {
    onSubmit(data, helpers).then(() => {
      helpers.resetForm({
        values: {
          billingEmail: data.billingEmail,
        },
      });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={BillingContactSchema}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <StyledBillingContactForm>
            {/*TODO: Test field validation*/}
            <Field
              name="billingEmail"
              label={formatMessage({
                id: BillingTranslation.adminBillingContactLabel,
              })}
              component={FormInput}
              disabled={isSubmitting || !canEditBillingPage}
            />
            {/*TODO: Test button state based on field state*/}
            {canEditBillingPage && (
              <Button
                type="submit"
                mode={ButtonMode.secondary}
                size={ButtonSize.sm}
                disabled={isSubmitting || !dirty}
                data-testid="submit">
                {isSubmitting ? (
                  <Spinner size={14} />
                ) : (
                  <FormattedMessage
                    id={BillingTranslation.adminBillingContactSubmitButton}
                  />
                )}
              </Button>
            )}
          </StyledBillingContactForm>
        </Form>
      )}
    </Formik>
  );
};
