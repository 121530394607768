import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useCreateDesktopMutation } from '../../Desktop.hooks';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
} from '../../../Workspace/Workspace.hooks';
import { StyledCreateDesktopForImport } from './CreateDesktopForImport.styled';
import { CreateDesktopForImportForm } from './CreateDesktopForImport.form';
import { ImportStepTypes } from '../../../ChromeExtension/ChromeExtension.types';
import type { DesktopForImportFormValues } from '../../data/Desktop/types/Desktop.types';
import { useDesktopsActions } from '../../data/Desktop/Desktop.actions';
import { getShortId } from '../../../../shared/utils/id';

export const CreateDesktopForImport: FC<{}> = () => {
  const { workspace } = useCurrentWorkspace();
  const { refetchPermissions } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [createDesktopMutation] = useCreateDesktopMutation();
  const { createDesktop } = useDesktopsActions();

  const handleSubmit = useCallback(
    (formValues: DesktopForImportFormValues) =>
      createDesktop({
        ...formValues,
        workspace: workspace.id,
        description: '',
        shareable: true,
      }).then(newDesktop => {
        if (!newDesktop) {
          return;
        }

        const shortWorkspaceId = getShortId(workspace.id);
        const shortDesktopId = getShortId(newDesktop.id);
        requestAnimationFrame(() => {
          navigate({
            pathname: `/workspace/${shortWorkspaceId}/desktop/${shortDesktopId}/apps`,
            search: getQueryParamsFrom({
              ...queryParams,
              createDesktopForImportModal: undefined,
              importModal: true,
              importStep: ImportStepTypes.selectApps,
            }),
          });
        });
      }),
    [createDesktop, workspace.id, navigate, queryParams],
  );

  return (
    <StyledCreateDesktopForImport>
      <CreateDesktopForImportForm onSubmit={handleSubmit} />
    </StyledCreateDesktopForImport>
  );
};
