const HSL_PALETTE_DEGREES = 360;

const getStringHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

/**
 * @see https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 * @param str
 * @param saturation
 * @param lightness
 * @return string
 */
export const getPastelColorByString = (
  str: string,
  saturation: number,
  lightness: number,
) => {
  const hue = getStringHash(str) % HSL_PALETTE_DEGREES;
  return `hsl(${hue},${saturation}%,${lightness}%)`;
};
