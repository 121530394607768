import styled from 'styled-components';

export const StyledSpinnerFullscreen = styled.div<{
  dimmedBackground: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, dimmedBackground }) =>
    dimmedBackground ? theme.colors.SurfaceSubdued : theme.colors.Surface};
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerLabel = styled.div`
  max-width: 210px;
  margin-top: 12px;
  text-align: center;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;
