import { useMemo } from 'react';
import youtubeIcon from '../../shared/assets/images/youtube-icon.png';
import vimeoIcon from '../../shared/assets/images/vimeo-icon.png';

export enum VideoProvider {
  vimeo = 'vimeo',
  youtube = 'youtube',
}

export const useVideoProviderPreviewReady = (type: string | null): boolean => {
  return useMemo(() => {
    if (!type) {
      return false;
    }

    return Object.values(VideoProvider).some((provider: string) => {
      return provider.includes(type);
    });
  }, [type]);
};

export const useVideoProviderIconUrl = (
  externalType: VideoProvider,
  fallbackUrl = '',
) => {
  if (externalType === VideoProvider.youtube) {
    return youtubeIcon;
  }
  if (externalType === VideoProvider.vimeo) {
    return vimeoIcon;
  }
  return fallbackUrl;
};
