import React, { FC, useCallback } from 'react';
import { EditIcon } from '../../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import { ConferenceTranslation } from '../../../i18n';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';

import { useCurrentConference } from '../../../Conference.hooks';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useCurrentWorkspaceAccount } from '../../../../Workspace/Workspace.hooks';
import { openConferenceWindow } from '../../../Conference.utils';

export const ScheduledConferenceTitleControls: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { account: currentAccount } = useCurrentWorkspaceAccount();
  const { scheduledConference } = useCurrentConference();

  const handleEditClick = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        scheduleConferenceOpened: true,
      }),
    });
  }, [navigate, queryParams]);

  const isScheduledConferenceOwner =
    currentAccount?.id === scheduledConference?.createdBy?.id;

  const handleJoinClick = useCallback(() => {
    if (scheduledConference?.chatConference) {
      openConferenceWindow(scheduledConference.chatConference.id);
    }
  }, [scheduledConference]);

  return (
    <>
      {isScheduledConferenceOwner && (
        <ButtonWithIcon
          data-testid="edit-meeting-button"
          icon={EditIcon}
          iconFirst={true}
          mode={ButtonMode.secondary}
          size={ButtonSize.sm}
          onClick={handleEditClick}>
          <FormattedMessage
            id={ConferenceTranslation.scheduledUpcomingConferenceEditMeeting}
          />
        </ButtonWithIcon>
      )}

      <Button
        data-testid="join-meeting-button"
        mode={ButtonMode.primary}
        size={ButtonSize.sm}
        type="button"
        onClick={handleJoinClick}>
        <FormattedMessage
          id={ConferenceTranslation.scheduledUpcomingConferenceJoin}
        />
      </Button>
    </>
  );
};
