import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { StyledRange } from '../../../../shared/components/Range';

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex: 0 1 40%;
  margin-bottom: 0.75rem;
  justify-content: space-between;
  line-height: 1.43;
`;

export const RangeOutput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  width: 2.5rem;
  height: 1.75rem;
  margin-left: 0.6875rem;
  border-radius: 4px;
  border: solid 1px ${p => p.theme.colors.BorderDefault};
  background-color: ${p => p.theme.colors.Surface};
`;

export const PasswordLength = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.75rem;

  & span {
    margin-right: 1rem;
    white-space: nowrap;
  }

  & ${StyledRange} {
    @media (max-width: 550px) {
      min-width: 35%;
    }
  }
`;

export const GeneratePasswordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2.25rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.Background};
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const GeneratePasswordButton = styled(Button)`
  font-size: 0.75rem;
`;
