import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useState,
} from 'react';
import { AccountPopoverContext } from './AccountPopover.context';
import { AccountPopover } from './AccountPopover';
import {
  AccountApiType,
  AccountWithCountsApiType,
} from '../../User/User.types';
import { useAccountsContext } from '../../Account';

export const AccountPopoverProvider: FC<PropsWithChildren> = ({ children }) => {
  const { accountsWithAvailability } = useAccountsContext();
  const [popoverData, setPopoverData] = useState<{
    account: AccountWithCountsApiType;
    domRef: RefObject<HTMLElement>;
  }>();

  const showAccountPopover = useCallback(
    (account: AccountApiType, domRef: RefObject<HTMLElement>) => {
      setPopoverData({
        account: {
          ...account,
          workspaceCache: accountsWithAvailability[account.id].workspaceCache,
        },
        domRef,
      });
    },
    [accountsWithAvailability],
  );

  const hideAccountPopover = useCallback(() => {
    setPopoverData(undefined);
  }, []);

  return (
    <AccountPopoverContext.Provider
      value={{ showAccountPopover, hideAccountPopover }}>
      {children}
      {popoverData && (
        <AccountPopover
          triggerRef={popoverData.domRef}
          onRequestClose={hideAccountPopover}
          account={popoverData.account as AccountWithCountsApiType}
        />
      )}
    </AccountPopoverContext.Provider>
  );
};
