import React, { useCallback, useRef } from 'react';
import {
  Menu,
  MenuItem,
  MenuLevel,
  MenuSeparator,
  useMenuLevelControls,
} from '../../../../../shared/components/Menu';
import {
  ArrowRightIcon,
  ChatIcon,
  CheckIcon,
  CopyIcon,
  EditIcon,
  EyeIcon,
  HeartFullIcon,
  HeartIcon,
  InformationIcon,
  LockIcon,
  TagIcon,
  TrashIcon,
} from '../../../../../shared/icons';
import { FormattedMessage } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import { FoldersList } from './FoldersList';
import { useCurrentDesktopPermissions } from '../../../../Desktop/Desktop.hooks';
import {
  HorizontalAlignment,
  MenuControlsProps,
  MenuProps,
  VerticalAlignment,
} from '../../../../../shared/components/Menu/Menu.types';
import { LinkApiType } from '../../../Link.types';
import { useMobile, useQueryParams } from '../../../../../shared/hooks';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useNavigate } from 'react-router-dom';
import { ChatTranslation } from '../../../../Chat/i18n';
import { DesktopTranslation } from '../../../../Desktop/i18n';
import { LinkDetailsTab } from '../../../Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { useCurrentTypeOfPage } from '../../../../Desktop/Desktop.utils';
import { getShortId } from '../../../../../shared/utils/id';
import { PermissionContext } from '../../../../Desktop/data/Desktop/types/Desktop.types';

interface LinksMenuProps extends MenuProps {
  handleEditLink: () => void;
  handleDeleteLink?: () => void;
  handleOpenMenu: (isOpened: boolean) => void;
  handleCopyLink?: () => void;
  link: LinkApiType;
  chatLinksMenu?: boolean;
  toggleFavoriteLink: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isUpdatingFavorite: boolean;
  displayPreviewOption?: boolean;
  displayUnselectOption?: boolean;
  displayUnselectAllOption?: boolean;
  displaySingleLinkOptions?: boolean;
  isDocumentLink: boolean | null;
  googleAuthUrl?: string;
  openGoogleAuthWindow?: () => void;
  handleSelectClick?: () => void;
  handleUnselectClick?: () => void;
  handleSelectAllClick?: () => void;
  handleUnselectAllClick?: () => void;
}

export const LinksMenu = (props: LinksMenuProps) => {
  const foldersMenuLevelControls = useMenuLevelControls();
  const tagsMenuLevelControls = useMenuLevelControls();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const {
    handleDeleteLink,
    handleEditLink,
    handleOpenMenu,
    handleCopyLink,
    link,
    chatLinksMenu = false,
    toggleFavoriteLink,
    isUpdatingFavorite,
    isDocumentLink,
    googleAuthUrl,
    openGoogleAuthWindow,
    displayPreviewOption = true,
    displayUnselectOption,
    displayUnselectAllOption,
    displaySingleLinkOptions = true,
    handleSelectClick,
    handleUnselectClick,
    handleSelectAllClick,
    handleUnselectAllClick,
  } = props;
  const { canEditLink, canRemoveLink } = useCurrentDesktopPermissions(
    PermissionContext.user,
    getShortId(link.desktopId || ''),
  );
  const isMobile = useMobile();
  const foldersMenuItemRef = useRef<HTMLDivElement>(null);
  const linksMenuRef = useRef<MenuControlsProps>(null);
  const subMenuItemRef = useRef<any>(null);

  const forceCloseLinksMenu = useCallback(() => {
    if (linksMenuRef.current?.forceClose) {
      linksMenuRef.current?.forceClose();
    }
  }, []);

  const { isFavoritesDesktop: favoriteLinksMenu } = useCurrentTypeOfPage();

  return (
    <Menu
      ref={linksMenuRef}
      submenuRef={subMenuItemRef}
      width={226}
      maxHeight={280}
      vAlign={VerticalAlignment.BOTTOM}
      hAlign={HorizontalAlignment.RIGHT}
      firstLevelHidden={
        tagsMenuLevelControls.visible || foldersMenuLevelControls.visible
      }
      onOpen={() => handleOpenMenu(true)}
      onClose={() => {
        tagsMenuLevelControls.close();
        foldersMenuLevelControls.close();
        handleOpenMenu(false);
      }}
      data-testid="desktop-link-context-menu"
      {...props}>
      {displaySingleLinkOptions && (
        <>
          {/*TODO: Remove this permission check when API will turn on tags fetching for READ_ONLY users*/}
          {canEditLink && !chatLinksMenu && (
            <MenuItem
              closeOnClick
              icon={TagIcon}
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    manageTag: true,
                    linkId: getShortId(link.id),
                  }),
                })
              }
              data-testid="tags">
              <FormattedMessage id={LinkTranslation.linksViewTagsLink} />
            </MenuItem>
          )}
          {displayPreviewOption && (chatLinksMenu || isMobile) && (
            <MenuItem
              closeOnClick
              icon={EyeIcon}
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    linkDetails: getShortId(link.id),
                    chatLink: chatLinksMenu ? chatLinksMenu : undefined,
                  }),
                })
              }
              data-testid="linkdetails-preview">
              <FormattedMessage id={LinkTranslation.linkMenuItemLinkPreview} />
            </MenuItem>
          )}

          {googleAuthUrl && (
            <MenuItem
              closeOnClick
              icon={LockIcon}
              onClick={() => openGoogleAuthWindow && openGoogleAuthWindow()}
              data-testid="linkdetails-sign-in-with-google">
              <FormattedMessage
                id={LinkTranslation.linkMenuItemSignInWithGoogle}
              />
            </MenuItem>
          )}

          {!chatLinksMenu && (
            <MenuItem
              closeOnClick
              icon={link.accountFavorite ? HeartFullIcon : HeartIcon}
              onClick={!isUpdatingFavorite ? toggleFavoriteLink : () => {}}
              data-testid="manage-favorite">
              <FormattedMessage
                id={
                  link.accountFavorite
                    ? DesktopTranslation.removeItemFromFavorites
                    : DesktopTranslation.addItemToFavorites
                }
              />
            </MenuItem>
          )}
          {!chatLinksMenu && (
            <MenuItem
              closeOnClick
              icon={InformationIcon}
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    linkDetails: getShortId(link.id),
                    linkDetailsTab: LinkDetailsTab.DETAILS,
                  }),
                })
              }
              data-testid="linkdetails-details">
              <FormattedMessage id={LinkTranslation.linkMenuItemLinkDetails} />
            </MenuItem>
          )}
          {canEditLink && !chatLinksMenu && (
            <MenuItem
              icon={EditIcon}
              onClick={handleEditLink}
              data-testid="edit-link">
              <FormattedMessage id={LinkTranslation.linksViewEditLink} />
            </MenuItem>
          )}

          {!isDocumentLink && !chatLinksMenu && (
            <MenuItem
              closeOnClick
              icon={ChatIcon}
              onClick={() =>
                navigate({
                  search: getQueryParamsFrom({
                    ...queryParams,
                    linkDetails: getShortId(link.id),
                    linkDetailsTab: LinkDetailsTab.COMMENTS,
                    conversation: undefined,
                    thread: undefined,
                  }),
                })
              }
              data-testid="linkdetails-comment">
              <FormattedMessage id={LinkTranslation.linkMenuItemLinkComment} />
            </MenuItem>
          )}
          <MenuSeparator />
        </>
      )}

      {link.desktopId && !favoriteLinksMenu && (
        <>
          {displayUnselectOption ? (
            <>
              {handleUnselectClick && (
                <MenuItem
                  icon={CheckIcon}
                  onClick={handleUnselectClick}
                  data-testid="link-unselect">
                  <FormattedMessage
                    id={LinkTranslation.linkMenuItemLinkUnselect}
                  />
                </MenuItem>
              )}
            </>
          ) : (
            <>
              {handleSelectClick && (
                <MenuItem
                  icon={CheckIcon}
                  onClick={handleSelectClick}
                  data-testid="link-select">
                  <FormattedMessage
                    id={LinkTranslation.linkMenuItemLinkSelect}
                  />
                </MenuItem>
              )}
            </>
          )}

          {handleSelectAllClick && (
            <MenuItem
              icon={CheckIcon}
              onClick={handleSelectAllClick}
              data-testid="link-select-all">
              <FormattedMessage
                id={LinkTranslation.linkMenuItemLinkSelectAll}
              />
            </MenuItem>
          )}

          {displayUnselectAllOption && handleUnselectAllClick && (
            <MenuItem
              icon={CheckIcon}
              onClick={handleUnselectAllClick}
              data-testid="link-unselect-all">
              <FormattedMessage
                id={LinkTranslation.linkMenuItemLinkUnselectAll}
              />
            </MenuItem>
          )}

          <MenuSeparator />
        </>
      )}

      {handleCopyLink && (
        <MenuItem
          icon={CopyIcon}
          onClick={handleCopyLink}
          data-testid="copy-link">
          <FormattedMessage id={LinkTranslation.linksViewCopyLink} />
        </MenuItem>
      )}

      {!chatLinksMenu && !favoriteLinksMenu && canEditLink && (
        <span>
          <span ref={foldersMenuItemRef}>
            <MenuItem
              icon={ArrowRightIcon}
              onClick={isMobile ? foldersMenuLevelControls.open : () => {}}
              closeOnClick={false}
              displayNextLevelArrow={true}
              data-testid="move-to">
              <FormattedMessage id={LinkTranslation.linksViewMoveTo} />
            </MenuItem>
          </span>
          {!isMobile && (
            <Menu
              ref={subMenuItemRef}
              trigger={foldersMenuItemRef}
              width={180}
              maxHeight={300}
              vAlign={VerticalAlignment.INSIDE_TOP}
              hAlign={HorizontalAlignment.OUTSIDE_RIGHT}
              data-testid="link-folders-menu">
              <FoldersList link={link} closeLinksMenu={forceCloseLinksMenu} />
            </Menu>
          )}
        </span>
      )}

      {(canRemoveLink || chatLinksMenu) && handleDeleteLink && (
        <>
          <MenuSeparator />
          <MenuItem
            highlightRed
            icon={TrashIcon}
            onClick={handleDeleteLink}
            data-testid="remove-link">
            <FormattedMessage
              id={
                chatLinksMenu
                  ? ChatTranslation.linksMenuRemovePreview
                  : LinkTranslation.linksViewDeleteLink
              }
            />
          </MenuItem>
        </>
      )}
      {foldersMenuLevelControls.visible && (
        <MenuLevel
          visible={true}
          onRequestClose={foldersMenuLevelControls.close}>
          <FoldersList link={link} closeLinksMenu={forceCloseLinksMenu} />
        </MenuLevel>
      )}
    </Menu>
  );
};
