import { gql } from '@apollo/client';
import {
  NotificationsIntegrationsApiType,
  WorkspaceNotificationsApiType,
  WorkspaceNotificationsFormValues,
} from '.';
import { NOTIFICATIONS_SETTINGS_FRAGMENT_FIELDS } from '../../GeneralSettings/NotificationsSettingsAdmin';

export const UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS = gql`
  mutation UpdateAccountWorkspaceSetting(
    $input: updateAccountWorkspaceSettingInput!
  ) {
    updateAccountWorkspaceSetting(input: $input) {
      accountWorkspaceSetting {
        ${NOTIFICATIONS_SETTINGS_FRAGMENT_FIELDS}
        useGlobalSettings
      }
    }
  }
`;

export interface UpdateAccountWorkspaceSettingVariables {
  input: WorkspaceNotificationsFormValues;
}

export interface UpdateAccountWorkspaceSettingResponse {
  updateAccountWorkspaceSetting: {
    accountWorkspaceSetting: WorkspaceNotificationsApiType;
  };
}

export const UPDATE_INTEGRATIONS_ACCOUNT_WORKSPACE_SETTING = gql`
  mutation UpdateIntegrationsAccountWorkspaceSetting(
    $input: updateIntegrationsAccountWorkspaceSettingInput!
  ) {
    updateIntegrationsAccountWorkspaceSetting(input: $input) {
      accountWorkspaceSetting {
        ${NOTIFICATIONS_SETTINGS_FRAGMENT_FIELDS}
        useGlobalSettings
        integrationsNotificationsSettings
      }
    }
  }
`;

export interface UpdateAccountWorkspaceIntegrationsVariables {
  input: NotificationsIntegrationsApiType;
}
