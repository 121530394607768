import { useMemo } from 'react';
import { InviteUsersFormFields } from '../../User.types';

export const useSomeEmailIsEmpty = (users: InviteUsersFormFields[]) => {
  const isEmpty = useMemo(() => {
    return !users.some(user => user.email.length > 0);
  }, [users]);

  return isEmpty;
};
