import { useMutation } from '@apollo/client';
import { RESET_MFA, RESET_MFA_ACCOUNT, UPDATE_MFA } from './Mfa.mutations';
import {
  ResetMfaAccountResponse,
  ResetMfaAccountVariables,
  ResetMfaResponse,
  ResetMfaVariables,
  UpdateMfaResponse,
  UpdateMfaVariables,
} from './Mfa.types';

export const useUpdateMfa = () =>
  useMutation<UpdateMfaResponse, UpdateMfaVariables>(UPDATE_MFA);

export const useResetMfa = () =>
  useMutation<ResetMfaResponse, ResetMfaVariables>(RESET_MFA);

export const useResetMfaAccount = () =>
  useMutation<ResetMfaAccountResponse, ResetMfaAccountVariables>(
    RESET_MFA_ACCOUNT,
  );
