import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { MembersList } from '../MembersList';
import {
  StyledMembersSidebar,
  StyledMembersSidebarProps,
} from './MembersSidebar.styled';
import { ChatTranslation } from '../i18n';
import { DesktopSidebarHeader } from '../../Desktop/DesktopSidebar';

export const MembersSidebar: FC<StyledMembersSidebarProps> = props => {
  const membersSidebarRef = useRef<HTMLDivElement>(null);

  return (
    <StyledMembersSidebar
      {...props}
      ref={membersSidebarRef}
      data-testid="members-sidebar">
      <DesktopSidebarHeader>
        <FormattedMessage id={ChatTranslation.sidebarTitle} />
      </DesktopSidebarHeader>
      <MembersList membersSidebarRef={membersSidebarRef} />
    </StyledMembersSidebar>
  );
};
