import { gql } from '@apollo/client';
import { AccountFields } from './User.fragments';
import { VaultAccessInputType } from '../Encryption/Encryption.types';
import { GRAPHQL_TYPENAME_MUTATION } from '../../shared/api/api.constants';
import type { DesktopAccessType } from '../Desktop/data/Desktop/types/Desktop.types';
import type {
  AccountApiType,
  ChangeUserPasswordInput,
  UserProfileInput,
} from './User.types';
import {
  AccountInvitationApiType,
  BatchAccountInvitationApiType,
} from '../Invitation/data/Invitation/types/Invitation.types';
import { AccountInvitationFields } from '../Invitation/data/Invitation/dataSources/InvitationGraphQl.dataSources';

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: createAccountInput!) {
    createAccount(input: $input) {
      account {
        id
        _id
        email
        firstName
        lastName
      }
    }
  }
`;

export interface CreateAccountVariables {
  input: {
    accountInvitation: string | null;
    flow: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    repeatPassword: string;
  };
}

export interface CreateAccountResponse {
  createAccount: {
    account: AccountApiType;
  };
}

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($input: deleteSelfAccountInput!) {
    deleteSelfAccount(input: $input) {
      account {
        id
        _id
        email
        firstName
        lastName
      }
    }
  }
`;

export interface DeleteAccountVariables {
  input: {
    id: string;
  };
}

export interface DeleteAccountResponse {
  deleteSelfAccount: {
    account: AccountApiType;
  };
}

export const SUSPEND_WORKSPACE_USER = gql`
  mutation DeactivateAccountInWorkspace(
    $input: deactivateAccountInWorkspaceInput!
  ) {
    deactivateAccountInWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface SuspendWorkspaceUserVariables {
  input: {
    id: string;
    account: string;
  };
}

export interface SuspendWorkspaceUserResponse {
  clientMutationId: string;
}

export const ACTIVATE_WORKSPACE_USER = gql`
  mutation ActivateAccountInWorkspace(
    $input: activateAccountInWorkspaceInput!
  ) {
    activateAccountInWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface ActivateWorkspaceUserVariables {
  input: {
    id: string;
    account: string;
  };
}

export interface ActivateWorkspaceUserResponse {
  clientMutationId: string;
}

export const REMOVE_USER_FROM_WORKSPACE = gql`
  mutation RemoveAccountFromWorkspace(
    $input: removeAccountFromWorkspaceInput!
  ) {
    removeAccountFromWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface RemoveUserWorkspaceVariables {
  input: {
    id: string;
    account: string;
  };
}

export interface RemoveUserWorkspaceResponse {
  clientMutationId: string;
}

export const CHANGE_WORKSPACE_ACCOUNT_GROUP = gql`
  mutation ChangeWorkspaceGroupForAccount(
    $input: changeWorkspaceGroupForAccountInput!
  ) {
    changeWorkspaceGroupForAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export interface ChangeWorkspaceGroupForAccountVariables {
  input: {
    id: string;
    workspace: string;
    groupIdentifier: string;
  };
}

export interface ChangeWorkspaceGroupForAccountResponse {
  account: AccountApiType;
}

export const CREATE_WORKSPACE_BATCH_ACCOUNT_INVITATION = gql`
  mutation CreateWorkspaceBatchAccountInvitation(
    $input: createWorkspaceBatchAccountInvitationInput!
  ) {
    createWorkspaceBatchAccountInvitation(input: $input) {
      clientMutationId
    }
  }
`;

export const CREATE_WORKSPACE_ACCOUNT_INVITATION = gql`
  mutation CreateWorkspaceAccountInvitation(
    $input: createWorkspaceAccountInvitationInput!
  ) {
    createWorkspaceAccountInvitation(input: $input) {
      clientMutationId
    }
  }
`;

export interface CreateWorkspaceAccountInvitationVariables {
  input: {
    workspace: string;
    email: string;
    group: string;
    desktop?: string;
    desktopAccess?: DesktopAccessType;
  };
}

export interface CreateWorkspaceAccountInvitationResponse {
  clientMutationId: string;
}

export interface CreateWorkspaceBatchAccountInvitationVariables {
  input: {
    workspace: string;
    members: BatchAccountInvitationApiType[];
  };
}

export interface CreateWorkspaceBatchAccountInvitationResponse {
  clientMutationId: string;
}

export const RESEND_INVITATION = gql`
  mutation ResendWorkspaceAccountInvitation(
    $input: resendWorkspaceAccountInvitationInput!
  ) {
    resendWorkspaceAccountInvitation(input: $input) {
      accountInvitation {
        ${AccountInvitationFields}
      }
    }
  }
`;

export interface ResendInvitationVariables {
  input: {
    id: string;
    workspace: string;
  };
}

export interface ResendInvitationResponse {
  resendWorkspaceAccountInvitation: {
    accountInvitation: AccountInvitationApiType;
  };
}

export const REVOKE_INVITATION = gql`
  mutation RevokeWorkspaceAccountInvitation(
    $input: deleteAccountInvitationInput!
  ) {
    deleteAccountInvitation(input: $input) {
      accountInvitation {
        id
      }
    }
  }
`;

export interface RevokeInvitationVariables {
  input: {
    id: string;
  };
}

export interface RevokeInvitationResponse {
  __typename: typeof GRAPHQL_TYPENAME_MUTATION;
  deleteAccountInvitation: {
    __typename: 'deleteAccountInvitationPayload';
    accountInvitation: {
      __typename: 'deleteAccountInvitationPayloadData';
      id: string;
    };
  };
}

export const ACCEPT_ACCOUNT_INVITATION = gql`
  mutation AcceptAccountInvitation($input: acceptAccountInvitationInput!) {
    acceptAccountInvitation(input: $input) {
      accountInvitation {
        id
        type
        referenceId
        desktopId
      }
    }
  }
`;

export interface AcceptAccountInvitationVariables {
  input: {
    id: string;
  };
}

export interface AcceptAccountInvitationResponse {
  acceptAccountInvitation: {
    accountInvitation: {
      id: string;
      type: string;
      referenceId: string;
      desktopId: string | null;
    };
  };
}

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: updateAccountInput!) {
    updateAccount(input: $input) {
      account {
        ${AccountFields}
      }
    }
  }
`;

export interface UpdateAccountVariables {
  input: UserProfileInput;
}

export interface UpdateAccountResponse {
  account: AccountApiType;
}

export const REVOKE_EMAIL_CHANGE = gql`
  mutation revokeEmailChangeForAccount(
    $input: revokeEmailChangeForAccountInput!
  ) {
    revokeEmailChangeForAccount(input: $input) {
      account {
        ${AccountFields}
      }
    }
  }
`;

export interface RevokeEmailChangeVariables {
  input: {
    id: string;
  };
}

export interface RevokeEmailChangeResponse {
  account: AccountApiType;
}

export const CHANGE_PASSWORD_ACCOUNT = gql`
  mutation ChangePasswordAccount($input: changePasswordAccountInput!) {
    changePasswordAccount(input: $input) {
      clientMutationId
    }
  }
`;

export interface ChangePasswordAccountVariables {
  input: Omit<ChangeUserPasswordInput, 'currentPassword'> & {
    currentPassword: string | null;
  };
}

export interface ChangePasswordAccountResponse {
  clientMutationId: string;
}

export const CHANGE_OWNER_WORKSPACE = gql`
  mutation ChangeOwnerWorkspace($input: changeOwnerWorkspaceInput!) {
    changeOwnerWorkspace(input: $input) {
      clientMutationId
    }
  }
`;

export interface ChangeOwnerWorkspaceVaribles {
  input: {
    id: string;
    newOwner: string;
    vaultAccesses: VaultAccessInputType[];
  };
}

export interface ChangeOwnerWorkspaceResponse {
  clientMutationId: string;
}
