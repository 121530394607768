import styled from 'styled-components';

export const StyledAppStoreView = styled.div`
  height: 100%;
  margin: 24px 0;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    height: 80vh;
  `}
`;

export const AppStoreContent = styled.div`
  padding: 0 20px;
  height: 100%;

  @media all and (min-width: 580px) {
    display: flex;
    justify-content: center;
  }
`;

export const AppStoreSidebar = styled.div`
  flex: 0 0 180px;
  display: flex;
  flex-direction: column;
`;

export const AppsSection = styled.div`
  flex: 1 1 100%;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    margin-left: 12px;
    overflow-y: auto;
    max-height: 100%;
  `}

  ${({ theme: { breakpoints } }) => breakpoints.lg`
    margin-left: 24px;
  `}
`;

export const NoAppsAvailable = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 40px;
  font-size: 1rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};

  p {
    text-align: center;
    margin-bottom: 1rem;
    max-width: 430px;
  }
`;

export const AppStoreSpacer = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const AppsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 68px);
  grid-gap: 16px 24px;
  justify-content: space-between;
  padding: 16px;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    grid-template-columns: 1fr 1fr;
  `}
`;
