import React, { FC, memo } from 'react';
import {
  CloseIcon,
  CogIcon,
  CornerBottomIcon,
  SearchThinIcon,
} from '../../../../../shared/icons';
import {
  ChatConversationInternalType,
  ConversationListSections,
  ConversationsSortedByGroups,
  ExpandedConversationsType,
} from '../../../Chat.types';
import {
  StyledAppIntegrationSection,
  StyledConversationExpand,
  StyledConversationSearch,
  StyledConversationSearchCloseButton,
  StyledConversationsFilters,
  StyledExpandContainer,
} from './ConversationList.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChatTranslation } from '../../../i18n';
import { Expand } from '../../../../../shared/components/Expand';
import { ConversationListItem } from './ConversationListItem';
import { CollapsedSectionsProps } from './ConversationList.hooks';
import { CONVERSATION_LIST_SEARCH_BAR } from './ConversationList.constants';
import { ConversationListItemSkeleton } from './ConversationListItem/ConversationListItemSkeleton/ConversationListItemSkeleton';

const ICON_SIZE = 12;

interface ConversationListPartsRendererProps {
  item: ExpandedConversationsType;
  filterValue: string;
  conversations: ConversationsSortedByGroups;
  isLoadingWithAvailabilityAccounts: boolean;
  appIntegrationsUnreadMessagesCounter: number;
  handleClearFilterValue: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  unreadMessagesCounter: (
    conversations: ChatConversationInternalType[],
  ) => number;
  handleIntegrationsSettingsClick: () => void;
  handleToggle: (name: ConversationListSections) => void;
  collapsedSections: CollapsedSectionsProps;
}

const ConversationListPartsRenderer: FC<ConversationListPartsRendererProps> = ({
  item,
  filterValue,
  conversations,
  isLoadingWithAvailabilityAccounts,
  appIntegrationsUnreadMessagesCounter,
  handleClearFilterValue,
  handleChange,
  unreadMessagesCounter,
  handleIntegrationsSettingsClick,
  handleToggle,
  collapsedSections,
}) => {
  const intl = useIntl();

  const handleExpand = (type: ConversationListSections, toggle: () => void) => {
    handleToggle(type);
    toggle();
  };

  const searchField = (
    <StyledConversationsFilters data-testid="filters-panel">
      <SearchThinIcon width={ICON_SIZE} height={ICON_SIZE} />
      <StyledConversationSearch
        value={filterValue}
        onChange={handleChange}
        placeholder={intl.formatMessage({
          id: ChatTranslation.conversationFilterPlaceholder,
        })}
        data-testid="conversation-search"
      />

      {filterValue.length ? (
        <StyledConversationSearchCloseButton
          icon={CloseIcon}
          onClick={handleClearFilterValue}
        />
      ) : null}
    </StyledConversationsFilters>
  );

  const pinnedExpand = (
    <StyledExpandContainer>
      <Expand
        expandedByDefault={!collapsedSections.pinned}
        dataTestId="pinned-expand"
        renderTrigger={toggle => (
          <StyledConversationExpand
            iconFirst
            icon={CornerBottomIcon}
            expanded={!collapsedSections.pinned}
            onClick={() =>
              handleExpand(ConversationListSections.pinned, toggle)
            }>
            <FormattedMessage id={ChatTranslation.conversationListPinned} />
          </StyledConversationExpand>
        )}
        badgeCount={unreadMessagesCounter(conversations.pinned)}
      />
    </StyledExpandContainer>
  );

  const integrationExpand = (
    <StyledExpandContainer>
      <Expand
        expandedByDefault={!collapsedSections.appIntegrations}
        dataTestId="integration-expand"
        renderTrigger={toggle => (
          <StyledAppIntegrationSection
            expanded={!collapsedSections.appIntegrations}
            hasMissedMessages={!!appIntegrationsUnreadMessagesCounter}>
            <StyledConversationExpand
              iconFirst
              icon={CornerBottomIcon}
              onClick={() =>
                handleExpand(ConversationListSections.appIntegrations, toggle)
              }
              expanded={!collapsedSections.appIntegrations}>
              <FormattedMessage
                id={ChatTranslation.conversationListIntegrations}
              />
            </StyledConversationExpand>
            <CogIcon
              onClick={handleIntegrationsSettingsClick}
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          </StyledAppIntegrationSection>
        )}
        badgeCount={appIntegrationsUnreadMessagesCounter}
      />
    </StyledExpandContainer>
  );

  const desktopExpand = (
    <StyledExpandContainer>
      <Expand
        expandedByDefault={!collapsedSections.desktop}
        dataTestId="desktop-expand"
        renderTrigger={toggle => (
          <StyledConversationExpand
            iconFirst
            icon={CornerBottomIcon}
            onClick={() =>
              handleExpand(ConversationListSections.desktop, toggle)
            }
            expanded={!collapsedSections.desktop}>
            <FormattedMessage id={ChatTranslation.conversationListChannels} />
          </StyledConversationExpand>
        )}
        badgeCount={unreadMessagesCounter(conversations.desktop)}
      />
    </StyledExpandContainer>
  );

  const privateExpand = (
    <StyledExpandContainer>
      <Expand
        expandedByDefault={!collapsedSections.private}
        dataTestId="private-expand"
        renderTrigger={toggle => (
          <StyledConversationExpand
            iconFirst
            icon={CornerBottomIcon}
            onClick={() =>
              handleExpand(ConversationListSections.private, toggle)
            }
            expanded={!collapsedSections.private}>
            <FormattedMessage
              id={ChatTranslation.conversationListDirectMessages}
            />
          </StyledConversationExpand>
        )}
        badgeCount={unreadMessagesCounter(conversations.private)}
      />
    </StyledExpandContainer>
  );

  const expandRenderer = () => {
    const groupName = item as ConversationListSections;

    switch (groupName) {
      case ConversationListSections.pinned:
        return pinnedExpand;
      case ConversationListSections.appIntegrations:
        return integrationExpand;
      case ConversationListSections.desktop:
        return desktopExpand;
      case ConversationListSections.private:
        return privateExpand;
    }
  };

  if (item === CONVERSATION_LIST_SEARCH_BAR) {
    return searchField;
  }

  if (typeof item === 'string') {
    return expandRenderer();
  }

  if (isLoadingWithAvailabilityAccounts) {
    return <ConversationListItemSkeleton />;
  }

  return <ConversationListItem key={item.id} conversation={item} />;
};

export default memo(ConversationListPartsRenderer);
