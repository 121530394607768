import styled, { css } from 'styled-components';

export const ImageWrapper = styled.div<{ defaultMargin: boolean }>`
  position: relative;
  margin-right: ${({ defaultMargin, theme }) =>
    defaultMargin ? theme.spacing.xs : 0};
  display: flex;
`;

export const ImageWrapperGrid = styled(ImageWrapper)<{ itemsCount: number }>`
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  color: ${p => p.theme.colors.PrimaryTextLight};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${p => p.theme.colors.BackgroundLight};

  ${p =>
    p.itemsCount === 2 &&
    css`
      > *:nth-child(1) {
        grid-area: 1 / 1 / span 2 / 1;
      }

      > *:nth-child(2) {
        grid-area: 1 / 2 / span 2 / 2;
      }
    `}

  ${p =>
    p.itemsCount === 3 &&
    css`
      > *:nth-child(1) {
        grid-area: 1 / 1 / span 2 / 1;
      }
    `}
`;

export const ImageWrapperGridExtraItems = styled.div`
  background-color: ${p => p.theme.colors.BackgroundLight};
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  font-size: 0.625rem;
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  text-align: center;
`;

export const DesktopIcon = styled.div<{ sharedDesktop?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  cursor: pointer;

  svg {
    color: ${p =>
      p.sharedDesktop ? p.theme.colors.OnNavbar : p.theme.colors.Primary};
  }
`;

export const DirectMessageGroup = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.OnSurface};
`;

export const DirectMessageGroupCount = styled.div`
  position: absolute;
  bottom: -3px;
  right: -3px;
  min-width: 14px;
  height: 14px;
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.75rem;
  line-height: 12px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.Surface};
  color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.SurfaceSubdued};
    color: ${({ theme }) => theme.colors.OnSurfaceHighEmphasis};
    padding: 2px 2px 0 2px;
  }
`;

export const DeletedUserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.SurfaceSubdued};
  cursor: pointer;
  svg {
    color: ${p => p.theme.colors.IconLight};
  }
`;
