import React, { FC, useCallback } from 'react';
import { DesktopForm } from '../DesktopForm';
import { StyledEditDesktopForm } from './EditDesktopForm.styled';
import { useCurrentDesktop } from '../../Desktop.hooks';
import {
  showToastError,
  showToastGraphQLErrors,
} from '../../../../shared/components/Toast';
import { useDesktopsActions } from '../../data/Desktop/Desktop.actions';
import type {
  DesktopApiType,
  DesktopFormValues,
} from '../../data/Desktop/types/Desktop.types';
import { FormikHelpers } from 'formik';

interface EditDesktopFormProps {
  onDone: () => void;
}

export const EditDesktopForm: FC<EditDesktopFormProps> = ({ onDone }) => {
  const currentDesktop = useCurrentDesktop() as DesktopApiType;
  const { updateDesktopById, updateDesktopInIDDB } = useDesktopsActions();

  const handleSubmit = useCallback(
    (
      { shareable, ...formValues }: DesktopFormValues,
      { setSubmitting }: FormikHelpers<DesktopFormValues>,
    ) => {
      if (!currentDesktop.id) {
        return;
      }

      try {
        updateDesktopInIDDB(currentDesktop.id, {
          ...currentDesktop,
          name: formValues.name,
          description: formValues.description,
        });

        updateDesktopById({
          ...formValues,
          id: currentDesktop.id,
        })
          .then(() => onDone())
          .catch(e => {
            setSubmitting(false);
            showToastGraphQLErrors(e.graphQLErrors);
          });
      } catch (e) {
        showToastError(`Desktop ${currentDesktop.name} update failed`);
      }
    },
    [currentDesktop, updateDesktopInIDDB, onDone, updateDesktopById],
  );

  return (
    <StyledEditDesktopForm>
      <DesktopForm
        desktop={currentDesktop}
        onSubmit={handleSubmit}
        onCancel={onDone}
      />
    </StyledEditDesktopForm>
  );
};
