import styled from 'styled-components';

export const StyledBillingContactForm = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1rem;

  button {
    margin-left: 1rem;
  }
`;

export const FormFields = styled.div`
  & > div ~ div {
    margin-top: 26px;
  }
`;

export const FormControls = styled.div`
  margin-top: 26px;
`;
