import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { TooltipPlace } from '../../../../../shared/components/Tooltip';
import { useQueryParams } from '../../../../../shared/hooks';
import { ChatIcon } from '../../../../../shared/icons';
import { getShortId } from '../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { LinkTranslation } from '../../../i18n';
import { LinkApiType } from '../../../Link.types';
import { LinkDetailsTab } from '../../../Modals/LinkDetailsModal/LinkDetailsModal.constants';
import { LinkControlsButton } from '../LinkControls/LinkControls.styled';

type LinkCommentsCounterProps = {
  link: LinkApiType;
};

export const LinkCommentsCounter: FC<LinkCommentsCounterProps> = ({ link }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { formatMessage } = useIntl();

  return (
    <>
      <LinkControlsButton
        data-tooltip-id="global-tooltip"
        data-tooltip-content={formatMessage(
          { id: LinkTranslation.tooltipLinkCommentsCounter },
          { count: link.messagesCount },
        )}
        data-tooltip-place={TooltipPlace.top}
        data-testid="comments-counter-icon"
        onClick={() => {
          navigate({
            search: getQueryParamsFrom({
              ...queryParams,
              linkDetails: getShortId(link.id),
              linkDetailsTab: LinkDetailsTab.COMMENTS,
              conversation: undefined,
              thread: undefined,
            }),
          });
        }}
        iconFirst
        icon={ChatIcon}>
        <span className="counter">{link.messagesCount}</span>
      </LinkControlsButton>
    </>
  );
};
