import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { matchPath, Navigate, Routes, useLocation } from 'react-router-dom';
import { WorkspaceSwitcher } from '../Workspace';
import {
  ACTIVATE_ACCOUNT_PATHNAME,
  CONFERENCE_PAGE_PATHNAME,
  DROPBOX_ERROR_PAGE,
  EXTERNAL_REGISTRATION_PATHNAME,
  INVITATION_PATHNAME,
  INVITE_USERS_PATHNAME,
  JOIN_CHAT_ERROR_PATHNAME,
  JOIN_CHAT_PATHNAME,
  JOIN_CHAT_SUCCESS_PATHNAME,
  LINK_ACCOUNT_PATHNAME,
  ONBOARDING_END_PATHNAME,
  PLAN_PATHNAME,
  REGISTRATION_FLOW_PATHNAME,
  REGISTRATION_PATHNAME,
  REQUEST_RESET_PASSWORD_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  VERIFY_EMAIL_ACCOUNT_PATHNAME,
} from '../Onboarding/Onboarding.constants';
import { LoginPage, LogoutPage, PrivateArea } from '../Auth';
import {
  CompleteLinkAccountPage,
  ExternalRegistrationPage,
  InvitationPage,
  JoinChatErrorPage,
  JoinChatPage,
  JoinChatSuccessPage,
  PlanPage,
  RegistrationPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  VerifyEmailChangePage,
  VerifyEmailPage,
} from '../Onboarding/OnboardingPage';
import {
  AccountApiType,
  UnreadNotificationsCountApiType,
} from '../User/User.types';
import { useAuthService } from '../Auth/Auth.hooks';
import { CurrentAccountContext } from '../Auth/Auth.context';
import {
  FAILED_SERVICE_AUTH,
  SUCCESS_SERVICE_AUTH,
  WORKSPACE_PATHNAME,
  WORKSPACE_ROOT_PATHNAME,
} from '../Workspace/Workspace.constants';
import { AppShellSkeleton } from './AppShellSkeleton';
import { useIPad } from '../../shared/hooks';
import { KeyboardProvider } from '../Keyboard';
import { ConferencePage } from '../Conference/ConferencePage';
import { CreateConferencePage } from '../Conference/CreateConferencePage';
import { CONFERENCE_CREATE_PATHNAME } from '../Conference/Conference.constants';
import { CurrentAccountContextType } from '../Auth/Auth.types';
import {
  LOGIN_PATHNAME,
  LOGOUT_PATHNAME,
  RESET_MFA_PATHNAME,
} from '../Auth/Auth.constants';
import { InstallAppToast } from '../InstallApp/InstallAppToast';
import { StyledCustomScrollbars, StyledRootStyles } from './AppRoot.styled';
import { SignUpStep } from '../Onboarding/Onboarding.types';
import {
  PwaDarkThemeColor,
  SELECTED_THEME,
  ThemeType,
  useTheme,
} from '../Theme';
import { useGoogleAnalytics } from '../Tracking';
import { setCookie } from '../../shared/utils/storage';
import { useIsOnline } from '../Offline';
import { ResetMfaPage } from '../MFA/ResetMfaPage';
import {
  FailedServiceAuthPage,
  SuccessServiceAuth,
} from '../ExternalServiceAuth';
import { UnsubscribePage } from '../Subscription';
import { UNSUBSCRIBE_PRODUCT_INFORMATION_EMAILS_PATHNAME } from '../Subscription/Subscription.constans';
import { Helmet } from 'react-helmet';
import { Route } from '../ErrorInterceptor/router.injectors';
import { setUser } from '../ErrorInterceptor';
import { GlobalSearchProvider } from '../GlobalSearch/GlobalSearch.provider';
import { DropboxErrorPage } from '../../shared/components/DropboxErrorPage/DropboxErrorPage';
import { useEffectOnce } from 'react-use';
import { MercureProvider } from '../Mercure/Mercure.provider';
import { useCurrentAccountRepository } from '../Account/data/CurrentAccount/CurrentAccount.repositories';
import { useCurrentAccountActions } from '../Account/data/CurrentAccount/CurrentAccount.actions';

export const AppRoot = () => {
  const {
    account: authenticatedAccount,
    fetchAccountData,
    setAccountAsNull,
  } = useCurrentAccountRepository({
    fetchPolicy: 'cache-only',
  });
  const { updateAccount } = useCurrentAccountActions();

  useGoogleAnalytics();
  const authService = useAuthService();

  const location = useLocation();
  const { pathname } = location;
  const { setTheme, themeType: selectedTheme } = useTheme();

  const updateAccountUnreadNotifications = useCallback(
    (updatedUnreadNotifications: UnreadNotificationsCountApiType[]) => {
      updateAccount(
        (prevAccount: AccountApiType | null) =>
          ({
            ...prevAccount,
            unreadNotifications: updatedUnreadNotifications,
          } as AccountApiType),
      );
    },
    [updateAccount],
  );

  const updateAccountTheme = useCallback(
    (theme: string) => {
      updateAccount(
        (prevAccount: AccountApiType | null) =>
          ({
            ...prevAccount,
            identity: {
              ...prevAccount?.identity,
              theme: theme,
            },
          } as AccountApiType),
      );
    },
    [updateAccount],
  );

  const updateCurrentAccount: CurrentAccountContextType['updateAccount'] =
    useCallback(
      updater => {
        updateAccount((prevAccount: AccountApiType | null) => {
          if (!prevAccount) {
            return prevAccount;
          }

          return updater(prevAccount);
        });
      },
      [updateAccount],
    );

  const accountContextValue = useMemo(() => {
    return {
      account: authenticatedAccount as AccountApiType,
      refetchAccountData: fetchAccountData,
      updateAccountUnreadNotifications,
      updateAccount: updateCurrentAccount,
      updateAccountTheme,
    };
  }, [
    authenticatedAccount,
    fetchAccountData,
    updateAccountUnreadNotifications,
    updateCurrentAccount,
    updateAccountTheme,
  ]);

  const isIPad = useIPad();
  useEffect(() => {
    if (isIPad) {
      const iPadViewportParams =
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no';
      document
        ?.querySelector('meta[name="viewport"]')
        ?.setAttribute('content', iPadViewportParams);
    }
  }, [isIPad]);

  const isOnline = useIsOnline();
  const isOnlineRef = useRef(isOnline);

  useEffectOnce(() => {
    authService.getToken().then(token => {
      if (isOnlineRef.current) {
        if (token) {
          fetchAccountData();
        } else {
          setAccountAsNull();
        }
      }
    });
  });

  useEffect(() => {
    if (authenticatedAccount) {
      // First set theme from account identity
      const { theme } = authenticatedAccount.identity;
      const currentTheme = theme ? theme : ThemeType.light;

      setCookie(SELECTED_THEME, currentTheme);

      if (selectedTheme !== currentTheme) {
        setTheme(currentTheme);
      }
    }
  }, [authenticatedAccount, selectedTheme, setTheme]);

  useEffect(() => {
    if (authenticatedAccount) {
      setUser({
        id: authenticatedAccount.id,
      });
    }
  }, [authenticatedAccount]);

  const isInstallAppToastActive = useMemo(
    () =>
      authenticatedAccount?.currentStep === SignUpStep.completed &&
      !matchPath(INVITE_USERS_PATHNAME, pathname) &&
      !matchPath(ONBOARDING_END_PATHNAME, pathname) &&
      !matchPath(CONFERENCE_CREATE_PATHNAME, pathname) &&
      !matchPath(CONFERENCE_PAGE_PATHNAME, pathname),
    [authenticatedAccount?.currentStep, pathname],
  );

  // Undefined means that things are still processing
  if (authenticatedAccount === undefined) {
    return <AppShellSkeleton />;
  }

  return (
    <CurrentAccountContext.Provider value={accountContextValue}>
      <Helmet>
        <meta name="theme-color" content={PwaDarkThemeColor} />
      </Helmet>
      <StyledRootStyles />
      <KeyboardProvider>
        <GlobalSearchProvider>
          <InstallAppToast active={isInstallAppToastActive} />
          <Routes>
            <Route
              path={UNSUBSCRIBE_PRODUCT_INFORMATION_EMAILS_PATHNAME}
              element={<UnsubscribePage />}
            />
            <Route path={RESET_MFA_PATHNAME} element={<ResetMfaPage />} />
            <Route path={LOGIN_PATHNAME} element={<LoginPage />} />
            <Route path={LOGOUT_PATHNAME} element={<LogoutPage />} />
            <Route path={PLAN_PATHNAME} element={<PlanPage />} />
            <Route
              path={REGISTRATION_FLOW_PATHNAME}
              element={<RegistrationPage />}
            />
            <Route
              path={REGISTRATION_PATHNAME}
              element={<RegistrationPage />}
            />
            <Route
              path={ACTIVATE_ACCOUNT_PATHNAME}
              element={<VerifyEmailPage />}
            />
            <Route
              path={VERIFY_EMAIL_ACCOUNT_PATHNAME}
              element={<VerifyEmailChangePage />}
            />
            <Route path={INVITATION_PATHNAME} element={<InvitationPage />} />
            <Route path={JOIN_CHAT_PATHNAME} element={<JoinChatPage />} />
            <Route
              path={JOIN_CHAT_SUCCESS_PATHNAME}
              element={<JoinChatSuccessPage />}
            />
            <Route
              path={JOIN_CHAT_ERROR_PATHNAME}
              element={<JoinChatErrorPage />}
            />
            <Route
              path={RESET_PASSWORD_PATHNAME}
              element={<ResetPasswordPage />}
            />
            <Route
              path={REQUEST_RESET_PASSWORD_PATHNAME}
              element={<RequestResetPasswordPage />}
            />
            <Route
              path={CONFERENCE_PAGE_PATHNAME}
              element={<ConferencePage />}
            />
            <Route
              path={'*'}
              element={
                <PrivateArea>
                  <MercureProvider>
                    <StyledCustomScrollbars />
                    <Routes>
                      <Route
                        path={CONFERENCE_CREATE_PATHNAME}
                        element={<CreateConferencePage />}
                      />
                      <Route
                        path={EXTERNAL_REGISTRATION_PATHNAME}
                        element={<ExternalRegistrationPage />}
                      />
                      <Route
                        path={LINK_ACCOUNT_PATHNAME}
                        element={<CompleteLinkAccountPage />}
                      />

                      <Route
                        path={WORKSPACE_ROOT_PATHNAME}
                        element={<WorkspaceSwitcher />}
                      />
                      <Route
                        path={WORKSPACE_PATHNAME + '/*'}
                        element={<WorkspaceSwitcher />}
                      />

                      {/* ROUTE WILL BE NEW AFTER RELEASE. WILL BE LIKE - /service-auth/:success or fail. AND ACCORDINGLY WILL HAVE 2 COMPONENTS */}
                      <Route
                        path={SUCCESS_SERVICE_AUTH}
                        element={<SuccessServiceAuth />}
                      />

                      <Route
                        path={FAILED_SERVICE_AUTH}
                        element={<FailedServiceAuthPage />}
                      />

                      <Route
                        path={DROPBOX_ERROR_PAGE}
                        element={<DropboxErrorPage />}
                      />

                      <Route index element={<Navigate to="/workspace" />} />
                    </Routes>
                  </MercureProvider>
                </PrivateArea>
              }
            />
          </Routes>
        </GlobalSearchProvider>
      </KeyboardProvider>
    </CurrentAccountContext.Provider>
  );
};
