import React, { FC, useMemo } from 'react';
import { Link } from '../../../Link/LinksView/Link';
import { DragLinkItemType } from '../../Drag.types';
import { StyledLinkPreview } from './LinkPreview.styled';

export const LinkPreview: FC<{ item: DragLinkItemType }> = ({ item }) => {
  const deckView = useMemo(() => {
    return (
      item.currentDesktopSelectedLinksMap &&
      item.currentDesktopSelectedLinksMap[item.link.id] &&
      Object.keys(item.currentDesktopSelectedLinksMap).length > 1
    );
  }, [item]);

  return (
    <StyledLinkPreview
      clientOffset={item.clientOffset!}
      sourceOffset={item.sourceOffset!}>
      <Link link={item.link} deckView={deckView} />
    </StyledLinkPreview>
  );
};
