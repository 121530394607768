import React, {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  SearchResultTypes,
  TABS,
  TABS_SEARCH_RESULT,
} from '../ExpandedSearch.constants';
import { GlobalSearchResponse } from '../../GlobalSearch.queries';
import { Spinner } from '../../../../shared/components/Spinner';
import { StyledSearchResultWrapper } from '../ExpandedSearch.styled';
import { RecentApps, RecentSearches } from './RecentViews';
import { RecentLinks } from './RecentViews/RecentLinks';
import { FormattedHTMLMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../i18n';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { GraphQLError } from 'graphql';
import { TabsStateType } from '../ExpandedSearch';
import { LoadMoreObserver } from '../../../../shared/components/LoadMore';
import { mergeWithArray } from '../../../../shared/utils/list.utils';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  RETRY_LOAD_MORE_TIMEOUT,
  SEARCH_CHAR_LIMIT,
} from '../../GlobalSearch.constants';
import {
  GlobalSearchByAllTabsResponseType,
  useGlobalSearchContext,
} from '../../GlobalSearch.hooks';
import { SearchResultItem } from './SearchResultItem';

interface SearchResultViewProps {
  tabsState: TabsStateType;
  searchQuery?: string;
  searchSectionBlur: (redirectTo?: string) => void;
  handleTabSearchState: (prevState: TabsStateType | {}) => void;
  globalSearchQueryData: GlobalSearchByAllTabsResponseType;
  skipBlurRef: MutableRefObject<boolean>;
  searchOnlyByConversation: boolean;
}

export const SearchResultView: FC<SearchResultViewProps> = ({
  tabsState,
  searchQuery,
  searchSectionBlur,
  handleTabSearchState,
  globalSearchQueryData,
  skipBlurRef,
  searchOnlyByConversation,
}) => {
  const [loadingMoreSearchResults, setLoadingMoreSearchResults] =
    useState(false);

  const { selectedTabCategoryKey } = useGlobalSearchContext();

  const isAllContentTabContentLoading = useMemo(
    () =>
      [TABS.APPS, TABS.CHATS, TABS.MEETINGS, TABS.LINKS].some(
        tab => globalSearchQueryData[tab].loading,
      ),
    [globalSearchQueryData],
  );

  const handleLoadMoreSearchResults = useCallback(() => {
    setLoadingMoreSearchResults(true);
    globalSearchQueryData[selectedTabCategoryKey]
      .fetchMore({
        variables: {
          cursor: tabsState[selectedTabCategoryKey].pageInfo.endCursor,
        },
        updateQuery: (
          prev: GlobalSearchResponse,
          { fetchMoreResult }: { fetchMoreResult: GlobalSearchResponse },
        ) => {
          const { pageInfo, totalCount } = fetchMoreResult?.listGlobalSearches;
          const data = extractNodes(fetchMoreResult?.listGlobalSearches);

          handleTabSearchState((prevState: TabsStateType) => ({
            ...prevState,
            [selectedTabCategoryKey]: {
              data: [...prevState[selectedTabCategoryKey].data, ...data],
              pageInfo: pageInfo,
              totalCount: totalCount,
              prevSearch: searchQuery,
              prevConversationId:
                prevState[selectedTabCategoryKey].prevConversationId ?? '',
            },
            [TABS.ALL_CONTENT]: {
              data: [...prevState[TABS.ALL_CONTENT].data, ...data],
              totalCount: prevState[TABS.ALL_CONTENT].totalCount,
              prevSearch: searchQuery,
              pageInfo:
                selectedTabCategoryKey === TABS.ALL_CONTENT ? pageInfo : null,
            },
          }));

          return mergeWithArray(
            prev || tabsState[selectedTabCategoryKey],
            fetchMoreResult || {},
          ) as GlobalSearchResponse;
        },
      })
      .then(() => setLoadingMoreSearchResults(false))
      .catch((e: { graphQLErrors: GraphQLError[] }) => {
        setTimeout(
          () => setLoadingMoreSearchResults(false),
          RETRY_LOAD_MORE_TIMEOUT,
        );
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [
    globalSearchQueryData,
    handleTabSearchState,
    searchQuery,
    selectedTabCategoryKey,
    tabsState,
  ]);

  if (!searchQuery || searchQuery.length < SEARCH_CHAR_LIMIT) {
    switch (selectedTabCategoryKey) {
      case TABS.ALL_CONTENT:
        return <RecentSearches />;
      case TABS.APPS:
        return (
          <RecentApps onBlur={searchSectionBlur} skipBlurRef={skipBlurRef} />
        );
      case TABS.LINKS:
        return <RecentLinks skipBlurRef={skipBlurRef} />;
      case TABS.CHATS:
        return <RecentSearches />;
      case TABS.MEETINGS:
        return <RecentSearches />;
    }
  }

  if (globalSearchQueryData[selectedTabCategoryKey].loading) {
    if (selectedTabCategoryKey === TABS.ALL_CONTENT) {
      if (isAllContentTabContentLoading) {
        return <Spinner size={32} />;
      }
    } else {
      return <Spinner size={32} />;
    }
  }

  if (!tabsState[selectedTabCategoryKey].data) {
    return null;
  }

  return (
    <StyledSearchResultWrapper>
      {tabsState[selectedTabCategoryKey].data.length ? (
        tabsState[selectedTabCategoryKey].data.map(
          item =>
            TABS_SEARCH_RESULT[selectedTabCategoryKey].includes(
              item.hitType as SearchResultTypes,
            ) && (
              <SearchResultItem
                key={item.id}
                {...item}
                onBlur={searchSectionBlur}
                skipBlurRef={skipBlurRef}
                searchQuery={searchQuery}
                searchOnlyByConversation={searchOnlyByConversation}
              />
            ),
        )
      ) : (
        <FormattedHTMLMessage
          id={GlobalSearchTranslation.searchResultNoFound}
        />
      )}
      {tabsState[selectedTabCategoryKey]?.pageInfo?.hasNextPage && (
        <LoadMoreObserver
          loading={loadingMoreSearchResults}
          onLoadMore={handleLoadMoreSearchResults}
        />
      )}
    </StyledSearchResultWrapper>
  );
};
