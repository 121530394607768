import styled from 'styled-components';

export const CheckBoxLabel = styled.label`
  display: inline-block;

  > div {
    float: left;
    margin-right: 1rem;
  }
`;
