import React, { FC, useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../../i18n';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderTitle,
} from '../../../../Admin';
import {
  BillingContactForm,
  BillingContactFormValues,
} from '../../../BillingForms/EditBillingContactForm';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { useUpdatePaymentCustomerMutation } from '../../../Billing.hooks';
import { FormikHelpers } from 'formik';
import { PaymentCustomerApiType } from '../../../Billing.types';
import { Spinner } from '../../../../../shared/components/Spinner';
import {
  ImportantMessage,
  ImportantMessageType,
} from '../../../../../shared/components/ImportantMessage';
import { GlobalTranslation } from '../../../../Intl/i18n';
import styled from 'styled-components';

interface BillingContactProps {
  paymentCustomer: PaymentCustomerApiType | undefined;
}

const StyledImportantMessage = styled(ImportantMessage)`
  margin: ${({ theme }) => theme.spacing.m} 0 0;
`;

export const BillingContact: FC<BillingContactProps> = ({
  paymentCustomer,
}) => {
  const [updatePaymentCustomerMutation] = useUpdatePaymentCustomerMutation();
  const handleSubmit = useCallback(
    (
      values: BillingContactFormValues,
      formikHelpers: FormikHelpers<BillingContactFormValues>,
    ) => {
      const updatedData = {
        id: paymentCustomer?.id,
        billingName: paymentCustomer?.billingName,
        country: paymentCustomer?.country,
        city: paymentCustomer?.city,
        line1: paymentCustomer?.line1,
        line2: paymentCustomer?.line2,
        postalCode: paymentCustomer?.postalCode,
        state: paymentCustomer?.state,
        vatType: paymentCustomer?.vatType,
        vatNumber: paymentCustomer?.vatNumber,
        ...values,
      };
      const { setSubmitting } = formikHelpers;

      return updatePaymentCustomerMutation({
        variables: {
          input: updatedData as PaymentCustomerApiType,
        },
      })
        .then(() => {
          showToastSuccessMessage(
            BillingTranslation.adminBillingContactUpdatedMessage,
          );
          setSubmitting(false);
        })
        .catch(e => {
          showToastGraphQLErrors(e.graphQLErrors);
          setSubmitting(false);
        });
    },
    [paymentCustomer, updatePaymentCustomerMutation],
  );

  return (
    <Section data-testid="section-billing-contact">
      <SectionHeader data-testid="header">
        <SectionHeaderTitle data-testid="title">
          <FormattedMessage id={BillingTranslation.adminBillingContactTitle} />
        </SectionHeaderTitle>
      </SectionHeader>
      <SectionBody data-testid="body">
        {paymentCustomer ? (
          <>
            <div data-testid="description">
              <FormattedMessage
                id={BillingTranslation.adminBillingContactBody}
              />
            </div>
            <StyledImportantMessage type={ImportantMessageType.INFO}>
              <FormattedHTMLMessage id={GlobalTranslation.checkEmailMessage} />
            </StyledImportantMessage>
            <BillingContactForm
              onSubmit={handleSubmit}
              paymentCustomer={paymentCustomer}
              data-testid="form"
            />
          </>
        ) : (
          <Spinner />
        )}
      </SectionBody>
    </Section>
  );
};
