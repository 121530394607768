import { useMemo } from 'react';
import { TIME_ZONES } from './TimeDate.constants';
import { getTZOffsetFromTZName } from './TimeDate.utils';

export const useTimeZoneOptions = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return useMemo(() => {
    const options = TIME_ZONES.map(tz => {
      return { value: tz, label: `${tz} ${getTZOffsetFromTZName(tz)}` };
    });
    if (!TIME_ZONES.includes(userTimeZone)) {
      options.unshift({
        value: userTimeZone,
        label: `${userTimeZone} ${getTZOffsetFromTZName(userTimeZone)}`,
      });
    }

    return options;
  }, [userTimeZone]);
};
