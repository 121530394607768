import React, { FC } from 'react';
import { StyledWorkspaceInfo, WorkspaceTitle } from './WorkspaceInfo.styled';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
} from '../../Workspace.hooks';
import { getAccountName } from '../../../User/User.utils';

export const WorkspaceInfo: FC<{ lightBackground?: boolean }> = ({
  lightBackground,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();

  return (
    <StyledWorkspaceInfo>
      <WorkspaceTitle
        lightBackground={lightBackground}
        data-testid="current-workspace-name"
        data-tourid="current-workspace-name">
        {workspace.name || getAccountName(account!)}
      </WorkspaceTitle>
    </StyledWorkspaceInfo>
  );
};
