import { database } from '../../../../Database';

export const getTeamsByAccountIdFromIDDB = async (accountId?: string) => {
  if (!accountId) {
    return [];
  }

  try {
    return database.teams.where('relatedAccounts').equals(accountId).toArray();
  } catch (e) {
    console.error(e);
    return [];
  }
};
