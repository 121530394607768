export const IntegrationFields = `
id
name
bot {
  id
  firstName
  image {
    id
    contentUrl
  }
}
secret
`;
