import styled, { css } from 'styled-components';
import { LINK_CONTAINER_MIN_HEIGHT } from '../../../VideoPlayer/VideoPlayer.constants';

export const StyledLinkInnerContainer = styled.div<{
  isDragging?: boolean;
  isDragEnabled?: boolean;
  deckView?: boolean;
}>`
  position: relative;
  opacity: ${p => (p.isDragging ? 0.3 : 1)};
  flex: 1;
  max-width: 100%;

  ${p =>
    p.isDragging &&
    css`
      touch-action: none;
      pointer-events: none;
    `}

  ${p =>
    p.deckView &&
    css`
      &:before,
      &:after {
        content: '';
        background-color: ${p => p.theme.colors.Surface};
        border: 1px solid ${p => p.theme.colors.BorderDefault};
        border-radius: 0.5rem;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translateX(6px) translateY(6px);
      }

      &:after {
        z-index: 1;
        transform: translateX(12px) translateY(12px);
      }
    `}

  ${p =>
    p.isDragEnabled &&
    css`
      user-select: none;
      -webkit-touch-callout: none;
    `}
`;

export const StyledLinkControls = styled.div<{
  hasOpenMenu?: boolean;
  selected?: boolean;
}>`
  min-height: ${LINK_CONTAINER_MIN_HEIGHT}px;
  background-color: ${p => p.theme.colors.Surface};
  border: 1px solid ${p => p.theme.colors.BorderDefault};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing.xs};
  transition: background-color 0.1s;
  position: relative;
  z-index: 3;

  ${p =>
    p.selected &&
    css`
      background-color: ${p.theme.colors.SelectedItemLight};
    `}

  ${({ hasOpenMenu, theme }) =>
    hasOpenMenu &&
    `
    background-color: ${theme.colors.Background};
  `}
`;

export const StyledVideoPreviewOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.Surface};
  padding: 0;
  transition: background-color 0.1s;
  border-radius: 0.4rem;
`;

export const StyledLink = styled.a`
  display: block;
  cursor: pointer;
`;

export const StyledLinkInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  width: 100%;
`;
