import React, { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { UserTranslation } from '../../../../../../i18n';
import { MakeOwner } from '../MakeOwner';
import { AccountApiType } from '../../../../../../User.types';
import { MakeOwnerModalContent } from '../MakeOwner.styled';

interface MakeOwnerModalProps extends Omit<ModalProps, 'visible'> {
  userAccount: AccountApiType;
  onDone: () => void;
}

export const MakeOwnerModal: FC<MakeOwnerModalProps> = ({
  onDone,
  onRequestClose,
  userAccount,
  ...props
}) => {
  return (
    <Modal visible={true} onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage
          id={UserTranslation.adminDangerZoneMakeOwnerModalHeader}
        />
      </ModalHeader>
      <MakeOwnerModalContent>
        <MakeOwner userAccount={userAccount} onDone={onDone} />
      </MakeOwnerModalContent>
    </Modal>
  );
};
