import styled from 'styled-components';

export const StyledSegmentSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.Background};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .body {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .list {
    margin-bottom: 1.5rem;

    &-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
