import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const EditGeneralSettingsConfirmationControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  ${Button} ~ ${Button} {
    margin-left: 0.625rem;
  }
`;
