import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../shared/components/ButtonWithIcon/ButtonWithIcon';
import {
  VIDEO_CONTAINER_HEIGHT,
  VIDEO_CONTAINER_MOBILE_HEIGHT,
} from './VideoPlayer.constants';

export const VideoPlayerContainerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover .custom-player-controls {
    opacity: 0.9;
  }
`;

export const VideoPlayerInnerContainerStyled = styled.div`
  width: 100%;
  height: 100%;
`;

export const VideoPlayerClickableLayerStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
`;

export const VideoPlayerStyled = styled.video<{
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
`;

// TODO: FOR FUTURE
// ::-internal-media-controlslist {
//   display: none !important;
// }

export const VideoPlayerErrorStyled = styled.div`
  display: flex;
  align-items: center;
  height: 17.5rem;
`;

export const StyledVideoPreview = styled.div<{
  isMobile: boolean;
}>`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  height: ${p =>
    p.isMobile
      ? `${VIDEO_CONTAINER_MOBILE_HEIGHT}px`
      : `${VIDEO_CONTAINER_HEIGHT}px`};
`;

export const StyledPlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledPlayButton = styled(ButtonWithIcon)`
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
`;

export const StyledProviderContent = styled.div`
  padding: 0.75rem 1.063rem 0;
  display: inline-grid;
`;

export const StyledProviderType = styled.div`
  ${({ theme }) => theme.fontTemplates.regular}
  display: flex;
  align-items: center;

  img {
    margin-right: 0.375rem;
    max-width: 2rem;
    height: 0.875rem;
    object-fit: contain;
    margin-bottom: 0.125rem;
  }
`;

export const StyledProviderTitle = styled.div`
  ${({ theme }) => theme.fontTemplates.regularSemibold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const StyledProviderDesctiprion = styled.div`
  ${({ theme }) => theme.fontTemplates.regular}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.75rem;
`;

export const StyledProviderImage = styled.div``;

export const StyledPlayerWrapperImage = styled.img<{
  withPadding: boolean;
}>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  ${({ withPadding }) =>
    withPadding &&
    css`
      padding: ${p => p.theme.spacing.m};
    `};
  object-fit: cover;
`;

export const StyledVideoIframe = styled.iframe`
  border: 0;
  padding: ${p =>
    `0 ${p.theme.spacing.xs} ${p.theme.spacing.xs} ${p.theme.spacing.xs}`};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledVideoPreviewInner = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
`;

export const StyledVideoIsBeingProcessed = styled.div`
  margin-top: 1.5rem;
`;
