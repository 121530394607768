import styled from 'styled-components';

export const UntrustedLinkConfirmationText = styled.p`
  margin: 20px 0 12px;
  line-height: 1.2;
`;

export const UntrustedLinkConfirmationReason = styled.p`
  margin: 20px 0 12px;
  line-height: 1.2;
`;
export const UntrustedLinkConfirmationReasonMessage = styled.span`
  font-family: ${({ theme }) => theme.fonts.Semibold};
`;

export const UntrustedLinkConfirmationLink = styled.a`
  color: ${p => p.theme.colors.Primary};
`;
