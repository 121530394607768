import type { OnboardingTrackingAction } from './OnboardingTracking.types';
import { EventCategoryType, sendTrackingEvent } from '../../Tracking';

export const sendOnboardingTrackingEvent = (
  action: OnboardingTrackingAction,
) => {
  sendTrackingEvent({
    category: EventCategoryType.ONBOARDING,
    action,
  });
};
