import React, { FC, useEffect, useRef } from 'react';
import { Spinner } from '../Spinner';
import { StyledLoadMoreObserver } from './LoadMore.styled';

interface LoadMoreObserverProps {
  loading: boolean;
  containerHeight?: number;
  onLoadMore: () => void;
}

export const LoadMoreObserver: FC<LoadMoreObserverProps> = ({
  loading,
  containerHeight = 100,
  onLoadMore,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting) && !loading) {
        onLoadMore();
      }
    });
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [onLoadMore, loading]);

  return (
    <StyledLoadMoreObserver ref={elementRef}>
      {loading && <Spinner containerHeight={containerHeight} />}
    </StyledLoadMoreObserver>
  );
};
