import React, { FC, memo } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { appEnv } from '../../../../appEnv';
import { SegmentEmpty } from '../../../Segment';
import { WorkspaceTranslation } from '../../i18n';
import { ContentContainer } from './EmptyWorkspacesPage.styled';
import { EmptyWorkspacesPageActions } from './EmptyWorkspacesPageActions';

export const EmptyWorkspacesPage: FC = memo(() => {
  return (
    <SegmentEmpty withTitlebar={false}>
      <ContentContainer>
        <FormattedHTMLMessage
          id={WorkspaceTranslation.noWorkspaces}
          values={{ supportUrl: appEnv.SUPPORT_URL }}
        />

        <EmptyWorkspacesPageActions />
      </ContentContainer>
    </SegmentEmpty>
  );
});
