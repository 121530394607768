import React, { ComponentPropsWithRef, memo } from 'react';
import { StyledSeparator } from './Separator.styled';

interface SeparatorProps extends Omit<ComponentPropsWithRef<'div'>, 'size'> {
  message: string;
  mode?: SeparatorMode;
  size?: number;
  noWrap?: boolean;
  uppercase?: boolean;
}

export enum SeparatorMode {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export const Separator = memo(
  React.forwardRef<HTMLDivElement, SeparatorProps>(
    ({ message, mode = SeparatorMode.horizontal, ...props }, ref) => {
      return (
        <StyledSeparator ref={ref} mode={mode} {...props}>
          <div className="line"></div>
          <div className="message">{message}</div>
          <div className="line"></div>
        </StyledSeparator>
      );
    },
  ),
);
