import styled from 'styled-components';

export const StyledLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;

  svg {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`;
