import styled from 'styled-components';

export const StyledPlanTrialInfo = styled.div`
  margin-top: 1rem;
  line-height: 1.2;

  span {
    padding: 0 5px;
    color: ${p => p.theme.colors.WarningDark};
    background-color: ${p => p.theme.colors.WarningLight};
  }
`;
