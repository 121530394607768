import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { TABS, TABS_MAP } from './ExpandedSearch/ExpandedSearch.constants';
import { GlobalSearchContext } from './GlobalSearch.context';
import { useGetTabsPermissionsState } from './GlobalSearch.hooks';

export const GlobalSearchProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.ALL_CONTENT);
  const [searchOnlyByConversation, setSearchOnlyByConversation] =
    useState(false);

  const tabsPermissions = useGetTabsPermissionsState();

  const tabsCategoryMap = useMemo(() => {
    let currentTabPosition = 0;
    return Object.keys(TABS_MAP).reduce((acc, cur: string) => {
      if (tabsPermissions[cur] || Number(cur) === TABS.ALL_CONTENT) {
        acc[cur] = currentTabPosition;
        currentTabPosition++;
        return acc;
      }
      return acc;
    }, {} as { [key: string]: number });
  }, [tabsPermissions]);

  const selectedTabCategoryKey: TABS = useMemo(() => {
    return Number(
      Object.keys(tabsCategoryMap).find(
        key => tabsCategoryMap[key] === selectedTab,
      ) ?? TABS.ALL_CONTENT,
    );
  }, [selectedTab, tabsCategoryMap]);

  const handleTabChange = useCallback(
    (value: number) => {
      setSelectedTab(tabsCategoryMap[value]);
    },
    [tabsCategoryMap],
  );

  const globalSearchContextData = useMemo(
    () => ({
      isFocused,
      setIsFocused,
      selectedTab,
      setSelectedTab: handleTabChange,
      searchOnlyByConversation,
      setSearchOnlyByConversation,
      selectedTabCategoryKey,
    }),
    [
      isFocused,
      selectedTab,
      handleTabChange,
      searchOnlyByConversation,
      selectedTabCategoryKey,
    ],
  );

  return (
    <GlobalSearchContext.Provider value={globalSearchContextData}>
      {children}
    </GlobalSearchContext.Provider>
  );
};
