import React, { FC, useCallback, useContext, useEffect } from 'react';
import {
  generatePath,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  useCurrentWorkspace,
  useCurrentWorkspacePermissions,
  useWorkspaceLastOpenedDesktop,
} from '../Workspace.hooks';
import {
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  WorkspaceInfoContent,
  WorkspaceInfoData,
  WorkspaceInfoDescription,
  WorkspaceInfoEditButton,
  WorkspaceInfoImage,
  WorkspaceInfoItem,
  WorkspaceInfoItemTitle,
  WorkspaceInfoMeta,
  WorkspaceInfoTitle,
} from './WorkspaceSettingsAdmin.styled';
import { WorkspaceTranslation } from '../i18n';
import {
  AdminHeader,
  AdminHeaderTitle,
  AdminPage,
  DrawerHeader,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderTitle,
} from '../../Admin';
import { getWorkspaceAdminCloseLink } from '../Workspace.utils';
import { useMobile, useQueryParams } from '../../../shared/hooks';
import { TooltipPlace } from '../../../shared/components/Tooltip';
import { WorkspaceIcon } from '../WorkspaceIcon';
import { appEnv } from '../../../appEnv';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { EditWorkspaceSettingsModal } from './EditWorkspaceSettingsModal';
import { EditWorkspaceMfaSettings } from './EditWorkspaceMfaSettings';
import { WORKSPACE_ADMIN_PATHNAME } from '../Workspace.constants';
import { WorkspaceFeatures } from './WorkspaceFeatures';
import { PaymentPlan } from '../../Billing/Billing.types';
import { UpgradeProAccount } from '../../Billing/UpgradeProAccount';
import { useNativeWrapper } from '../../NativeWrapper';
import { WorkspaceContext } from '../Workspace.context';
import { getShortId } from '../../../shared/utils/id';

export const WorkspaceSettingsAdmin: FC = () => {
  const { workspace } = useCurrentWorkspace();
  const { lastOpenedDesktop } = useWorkspaceLastOpenedDesktop();
  const {
    permissions: { canViewProfilePage, canEditWorkspace },
    permissionsLoaded,
  } = useCurrentWorkspacePermissions();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const queryParams = useQueryParams();
  const { editWorkspaceSettingsModal } = queryParams;
  const { pathname } = useLocation();
  const { setLastOpenedAdminPath } = useContext(WorkspaceContext);

  /**
   * Set last admin path
   */
  useEffect(() => {
    setLastOpenedAdminPath(pathname);
  }, [pathname, setLastOpenedAdminPath]);

  const {
    permissions: { canUpgrade },
  } = useCurrentWorkspacePermissions();
  const { isNativeWrapperAvailable } = useNativeWrapper();

  const workspaceDomain = `.${appEnv.WORKSPACE_DOMAIN}`;

  const openEditWorkspaceSettingsModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editWorkspaceSettingsModal: true,
      }),
    });
  }, [navigate, queryParams]);

  const closeEditWorkspaceSettingsModal = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editWorkspaceSettingsModal: undefined,
      }),
    });
  }, [navigate, queryParams]);

  if (permissionsLoaded && !canViewProfilePage) {
    const workspaceAdminPath = generatePath(WORKSPACE_ADMIN_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspaceAdminPath} />;
  } else if (!permissionsLoaded) {
    return null;
  }

  return (
    <AdminPage data-testid="workspace-settings-admin">
      {isMobile ? (
        <DrawerHeader
          data-testid="heading"
          onRequestClose={() =>
            navigate(getWorkspaceAdminCloseLink(workspace, lastOpenedDesktop))
          }>
          <FormattedMessage
            id={WorkspaceTranslation.workspaceSettingsPageTitle}
          />
        </DrawerHeader>
      ) : (
        <AdminHeader data-testid="header">
          <AdminHeaderTitle data-testid="title">
            <FormattedMessage
              id={WorkspaceTranslation.workspaceSettingsPageTitle}
            />
          </AdminHeaderTitle>
        </AdminHeader>
      )}

      {workspace && (
        <>
          {workspace.type === PaymentPlan.FREE &&
            canUpgrade &&
            !isNativeWrapperAvailable && <UpgradeProAccount />}

          <Section data-testid="workspace-info-section">
            <SectionHeader data-testid="header">
              <SectionHeaderTitle data-testid="title">
                <FormattedHTMLMessage
                  id={WorkspaceTranslation.workspaceSettingsInfoTitle}
                />
              </SectionHeaderTitle>
            </SectionHeader>
            <SectionBody data-testid="section-body">
              <WorkspaceInfoContent>
                <WorkspaceInfoImage data-testid="image">
                  <WorkspaceIcon workspace={workspace} size={110} />
                </WorkspaceInfoImage>
                <WorkspaceInfoMeta isMobile={isMobile}>
                  <WorkspaceInfoTitle data-testid="title">
                    {workspace.name}
                  </WorkspaceInfoTitle>
                  <WorkspaceInfoDescription data-testid="description">
                    {workspace.description ? (
                      workspace.description
                    ) : (
                      <FormattedMessage
                        id={WorkspaceTranslation.workspaceSettingsNoDescription}
                      />
                    )}
                  </WorkspaceInfoDescription>
                  <WorkspaceInfoItem data-testid="workspace-info-item">
                    <WorkspaceInfoItemTitle data-testid="item-title">
                      <FormattedMessage
                        id={WorkspaceTranslation.formEmailLabel}
                      />
                    </WorkspaceInfoItemTitle>
                    <WorkspaceInfoData data-testid="item-data">
                      {workspace.contactEmail}
                    </WorkspaceInfoData>
                  </WorkspaceInfoItem>
                  <WorkspaceInfoItem data-testid="workspace-info-item">
                    <WorkspaceInfoItemTitle data-testid="item-title">
                      <FormattedMessage
                        id={WorkspaceTranslation.formUrlLabel}
                      />
                    </WorkspaceInfoItemTitle>
                    <WorkspaceInfoData data-testid="item-data">
                      {workspace.homepageUrl}
                    </WorkspaceInfoData>
                  </WorkspaceInfoItem>
                  <WorkspaceInfoItem data-testid="workspace-info-item">
                    <WorkspaceInfoItemTitle data-testid="item-title">
                      <FormattedMessage
                        id={WorkspaceTranslation.formSubdomainLabel}
                      />
                    </WorkspaceInfoItemTitle>
                    <WorkspaceInfoData data-testid="item-data">
                      {workspace.identifier}
                      {workspaceDomain}
                    </WorkspaceInfoData>
                  </WorkspaceInfoItem>
                  {canEditWorkspace && (
                    <WorkspaceInfoEditButton
                      data-tooltip-place={TooltipPlace.top}
                      data-testid="edit-profile-button"
                      onClick={openEditWorkspaceSettingsModal}
                      type="button"
                      mode={ButtonMode.secondary}
                      size={ButtonSize.md}>
                      <FormattedHTMLMessage
                        id={WorkspaceTranslation.workspaceSettingsInfoButton}
                      />
                    </WorkspaceInfoEditButton>
                  )}
                </WorkspaceInfoMeta>
              </WorkspaceInfoContent>
            </SectionBody>
          </Section>
          <Section data-testid="mfa-section">
            <SectionHeader data-testid="header">
              <SectionHeaderTitle data-testid="title">
                <FormattedHTMLMessage id={WorkspaceTranslation.formMfaLabel} />
              </SectionHeaderTitle>
            </SectionHeader>
            <SectionBody data-testid="section-body">
              <EditWorkspaceMfaSettings />
            </SectionBody>
          </Section>
          <Section data-testid="features-section">
            <SectionHeader data-testid="header">
              <SectionHeaderTitle data-testid="title">
                <FormattedHTMLMessage
                  id={WorkspaceTranslation.formFeaturesLabel}
                />
              </SectionHeaderTitle>
            </SectionHeader>
            <SectionBody data-testid="section-body">
              <WorkspaceFeatures />
            </SectionBody>
          </Section>
        </>
      )}

      <EditWorkspaceSettingsModal
        visible={Boolean(editWorkspaceSettingsModal)}
        onRequestClose={closeEditWorkspaceSettingsModal}
      />
    </AdminPage>
  );
};
