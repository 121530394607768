import React, { FC, useCallback } from 'react';
import {
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { useCurrentDesktop } from '../../../../Desktop/Desktop.hooks';
import { useNavigate } from 'react-router-dom';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { FormattedMessage } from 'react-intl';
import { AppStoreTranslation } from '../../../i18n';
import {
  CustomAppPreviewBox,
  CustomAppPreviewButton,
  CustomAppPreviewDescription,
  CustomAppPreviewHeader,
  CustomAppPreviewIcon,
  CustomAppPreviewName,
  StyledCustomAppPreview,
} from './CustomAppPreview.styled';
import { getImageSrcSet } from '../../../../../shared/utils/url.utils';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { setDefaultImage } from '../../../../../shared/utils/image.utils';
import UploadImagePlaceholder from '../../../../../shared/assets/images/image-upload.svg';
import { useCaseAddAppToDesktop } from '../../../UseCase/addAppToDesktop';
import type { DesktopAppApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import { getShortId } from '../../../../../shared/utils/id';

interface CustomAppPreviewProps {
  app: DesktopAppApiType;
  onRequestClose: () => void;
}

const PREVIEW_ICON_SIZE = 74;

export const CustomAppPreview: FC<CustomAppPreviewProps> = ({
  app,
  onRequestClose,
}) => {
  const { workspace } = useCurrentWorkspace();
  const desktop = useCurrentDesktop();
  const navigate = useNavigate();

  const { addAppToDesktopUseCase } = useCaseAddAppToDesktop();

  const addAppHandleClick = useCallback(() => {
    return addAppToDesktopUseCase(desktop!.id, app.id)
      .then(() => {
        showToastSuccessMessage(AppStoreTranslation.customAppAddedToDesktop);
        navigate(
          `/workspace/${getShortId(workspace.id)}/desktop/${getShortId(
            desktop?.id || '',
          )}/apps`,
        );
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
      });
  }, [addAppToDesktopUseCase, workspace.id, desktop, app.id, navigate]);

  return (
    <StyledCustomAppPreview>
      <CustomAppPreviewHeader>
        <FormattedMessage id={AppStoreTranslation.customAppPreviewHeader} />
      </CustomAppPreviewHeader>
      <CustomAppPreviewDescription>
        <FormattedMessage
          id={AppStoreTranslation.customAppPreviewSuccessMessage}
          values={{ appName: app.name, workspaceName: workspace.name }}
        />
      </CustomAppPreviewDescription>
      <CustomAppPreviewBox>
        <CustomAppPreviewIcon
          src={app.logo.contentUrl}
          srcSet={getImageSrcSet(app.logo.contentUrl, PREVIEW_ICON_SIZE)}
          alt={app.name}
          onError={e =>
            setDefaultImage(e.target, UploadImagePlaceholder, PREVIEW_ICON_SIZE)
          }
        />
        <CustomAppPreviewName>{app.name}</CustomAppPreviewName>
      </CustomAppPreviewBox>

      <CustomAppPreviewButton
        mode={ButtonMode.primary}
        size={ButtonSize.md}
        fullWidth
        onClick={addAppHandleClick}>
        <FormattedMessage
          id={AppStoreTranslation.customAppPreviewDesktopButton}
        />
      </CustomAppPreviewButton>
      <CustomAppPreviewButton
        mode={ButtonMode.secondary}
        size={ButtonSize.md}
        fullWidth
        onClick={onRequestClose}>
        <FormattedMessage
          id={AppStoreTranslation.customAppPreviewContinueButton}
        />
      </CustomAppPreviewButton>
    </StyledCustomAppPreview>
  );
};
