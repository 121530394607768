import styled, { css } from 'styled-components';

export const FeaturesFormField = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${p =>
    p.isMobile &&
    css`
      flex-direction: column;
    `}

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const FeaturesFormFieldTitleWrapper = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};

  ${p =>
    p.isMobile &&
    css`
      margin-bottom: 1rem;
    `}

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const FeaturesFormFieldTitle = styled.h3`
  font-family: ${p => p.theme.fonts.Semibold};
  font-size: 1rem;
  line-height: 1.5;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
`;
