import React from 'react';
import { AppStoreModal } from '../../../AppStore/Modals/AppStoreModal';
import { FavoritesDesktop } from '../FavoritesDesktop/FavoritesDesktop';
import { StandardDesktop } from '../StandardDesktop/StandardDesktop';
import { DesktopContentType, DesktopContentViewModel } from './ViewModel';

export const DesktopContent = () => {
  const { desktopContentType, showAppStore } = DesktopContentViewModel();

  switch (desktopContentType) {
    case DesktopContentType.STANDARD:
      return (
        <>
          <StandardDesktop />
          {showAppStore && <AppStoreModal />}
        </>
      );
    case DesktopContentType.FAVORITES:
      return <FavoritesDesktop />;
    default:
      return null;
  }
};
