import type { SegmentType } from '../Segment';

export interface PreviewSegmentContextType {
  isPreviewMode: boolean;
  navigateToSegment: (segment: SegmentType, isPreviewMode?: boolean) => void;
  handleHoverPreview: (e: MouseEvent) => void;
  handleClickPreview: (e: MouseEvent) => void;
  closePreview: () => void;
}

export enum PreviewSegmentEventTypes {
  Mouseleave = 'mouseleave',
  MouseEnter = 'mouseenter',
  MouseClick = 'click',
}
