import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChatConversationInternalType,
  ConversationType,
  SearchChatConversationApiType,
} from '../../../../Chat.types';
import {
  ControlsGroup,
  ConversationControls,
  ConversationInner,
  ConversationName,
  ConversationWrapper,
  IntegrationBadgeContainer,
  OptionsButton,
  StyledConversationListItem,
  StyledConversationNavLink,
} from './ConversationListItem.styled';
import {
  CountBadge,
  PositionType,
} from '../../../../../../shared/components/CountBadge';
import { ConversationAvatar } from './ConversationAvatar';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceAccount,
  useMobileNavigationSidebar,
} from '../../../../../Workspace/Workspace.hooks';
import {
  useChatConversationTitle,
  useCurrentConversation,
  useSearchMessageData,
} from '../../../../Chat.hooks';
import { useAccountsContext } from '../../../../../Account';
import {
  AccountOrigin,
  AvailabilityStatusType,
} from '../../../../../User/User.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChatTranslation } from '../../../../i18n';

import { CallFilledIcon, KebabIcon } from '../../../../../../shared/icons';
import { TooltipPlace } from '../../../../../../shared/components/Tooltip';
import { useConversationStateControls } from '../../../../ChatView/ConversationState/ConversationState.hooks';
import {
  useAppleTouchDevice,
  useMobile,
  useQueryParams,
  useTablet,
} from '../../../../../../shared/hooks';
import { GlobalSearchTab } from '../../../../../GlobalSearch/ExpandedSearch';
import { getQueryParamsFrom } from '../../../../../../shared/utils/url.utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { IntegrationBadge } from '../../../../ChatAvatar/IntegrationBadge';
import { ConversationListItemPreview } from './ConversationListItemPreview';
import { format, parseISO } from 'date-fns';
import { appEnv } from '../../../../../../appEnv';
import {
  useChatConversationHasUnreadCount,
  useChatConversationUnreadCounterGetter,
} from '../../../../../Counters';
import {
  CHATS_CONVERSATION_PATHNAME,
  CHATS_ROOT_PATHNAME,
} from '../../../../../Desktop/Desktop.constants';
import {
  HorizontalAlignment,
  Menu,
  MenuItem,
  VerticalAlignment,
} from '../../../../../../shared/components/Menu';
import { getShortId } from '../../../../../../shared/utils/id';
import { usePreviewSegment } from '../../../../../PreviewSegment/PreviewSegment.hooks';
import { useChatMessageRepository } from '../../../../Data/Repository/ChatMessage/ChatMessageApiRepository';
import { useChatConversationRepository } from '../../../../Data/Repository/ChatConversation/ChatConversationsApiRepository';
import { MOBILE_NAVIGATION_SIDEBAR_CLOSE_TIMEOUT } from '../ConversationList.constants';
import { ChatMessageThreadSidebarContext } from '../../../../ChatView/ConversationView/ChatMessageThread/ChatMessageThreadSidebar/ChatMessageThreadSidebar.context';
import { SegmentType, useSetLastUsedSegmentPath } from '../../../../../Segment';
import { useLocation } from 'react-router';

interface ConversationListItemProps {
  conversation: ChatConversationInternalType;
  hitMessage?: SearchChatConversationApiType['hitMessage'];
  searchResult?: boolean;
}

export const ConversationListItem: FC<ConversationListItemProps> = ({
  conversation,
  hitMessage,
  searchResult = false,
}) => {
  const { workspace } = useCurrentWorkspace();
  const { account } = useCurrentWorkspaceAccount();
  const { conversation: currentConversation } = useCurrentConversation();
  const { setSearchMessage } = useSearchMessageData();
  const [menuOpen, setMenuOpen] = useState(false);
  const currentConversationId = currentConversation?.id;
  const queryParams = useQueryParams();
  const { globalSearchTab } = queryParams;
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isAppleTouchDevice = useAppleTouchDevice();
  const { formatMessage } = useIntl();
  const { isPreviewMode } = usePreviewSegment();
  const { markAllChatMessagesAsSeen } = useChatMessageRepository();
  const { pinConversation, unpinConversation, hideConversation } =
    useChatConversationRepository();
  const { shouldCloseSidebar } = useContext(ChatMessageThreadSidebarContext);
  const setLastSegmentPath = useSetLastUsedSegmentPath(SegmentType.CHATS);

  const privateChatAccountId = useMemo(
    () =>
      conversation.type === ConversationType.private ||
      conversation.type === ConversationType.appIntegration
        ? conversation.userIds.find(id => account.id !== id)
        : undefined,
    [conversation.type, conversation.userIds, account.id],
  );

  const conversationTitle = useChatConversationTitle(conversation);

  const { accountsWithAvailability } = useAccountsContext();

  const privateChatAccount = privateChatAccountId
    ? accountsWithAvailability[privateChatAccountId]
    : undefined;

  const itemRef = useRef<any>(null);

  const isActive = conversation.id === currentConversationId;

  useEffect(() => {
    if (isActive) {
      if (itemRef.current) {
        (itemRef.current as HTMLAnchorElement).scrollIntoView({
          block: 'nearest',
        });
      }
    }
  }, [isActive, isPreviewMode]);

  const showInCallBadge = useMemo(() => {
    return (
      conversation.type === ConversationType.private &&
      privateChatAccount?.onCall &&
      privateChatAccount?.onlineStatus !== AvailabilityStatusType.Offline &&
      conversation.userIds.includes(account.id)
    );
  }, [conversation, account, privateChatAccount]);

  const pinned = useMemo(() => {
    return !!conversation.pinnedAt;
  }, [conversation.pinnedAt]);

  const handlePinChatConversation = useCallback(() => {
    if (!pinned) {
      return pinConversation(conversation.id);
    } else {
      return unpinConversation(conversation.id);
    }
  }, [pinned, pinConversation, conversation.id, unpinConversation]);

  const { setState } = useConversationStateControls();

  const setConversationState = useCallback(() => {
    if (!hitMessage || !hitMessage.parentChatMessageIri) {
      return;
    }

    if (globalSearchTab === GlobalSearchTab.chats) {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          globalSearchTab: undefined,
        }),
      });
    }

    setState({
      scrollToMessageId: hitMessage.id,
      openThreadOfMessageId: hitMessage.parentChatMessageIri,
    });
  }, [globalSearchTab, navigate, hitMessage, queryParams, setState]);

  const accountOrigin = privateChatAccount?.origin || AccountOrigin.webtopcom;
  const isSlackUser = accountOrigin === AccountOrigin.slack;

  const formattedDate = useMemo(() => {
    const date = hitMessage
      ? hitMessage.createdAt
      : conversation.lastMessageAt
      ? conversation.lastMessageAt
      : conversation.createdAt;

    return date ? format(parseISO(date), `MMM. d, ${appEnv.TIME_FORMAT}`) : '';
  }, [conversation.createdAt, conversation.lastMessageAt, hitMessage]);

  const getChatConversationUnreadCount =
    useChatConversationUnreadCounterGetter();
  const chatConversationHasUnreadCount = useChatConversationHasUnreadCount(
    workspace.id,
    conversation.id,
  );

  const { mobileNavigationSidebarClose } = useMobileNavigationSidebar();

  const handleConversationClick = useCallback(
    (e: React.SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate({
        pathname: generatePath(CHATS_CONVERSATION_PATHNAME, {
          workspaceId: getShortId(workspace.id),
          conversationId: getShortId(conversation.id),
        }),
        search: getQueryParamsFrom({
          ...queryParams,
          ...{
            ...(shouldCloseSidebar && {
              thread: undefined,
              conversation: undefined,
              highlight: undefined,
            }),
          },
        }),
      });

      if (!hitMessage) {
        return;
      }

      setSearchMessage({
        id: hitMessage?.id,
        createdAt: hitMessage?.createdAt,
        isThreadMessage: hitMessage
          ? hitMessage.parentChatMessageIri !== null
          : false,
      });

      setTimeout(() => {
        if (!menuOpen) {
          mobileNavigationSidebarClose();
        }
      }, MOBILE_NAVIGATION_SIDEBAR_CLOSE_TIMEOUT);
    },
    [
      conversation.id,
      navigate,
      hitMessage,
      menuOpen,
      mobileNavigationSidebarClose,
      queryParams,
      setSearchMessage,
      shouldCloseSidebar,
      workspace.id,
    ],
  );

  const handleMarkAllAsRead = useCallback(() => {
    markAllChatMessagesAsSeen(conversation.id);
  }, [conversation.id, markAllChatMessagesAsSeen]);

  const threadOptionsRef = useRef(null);

  const linkPath = useMemo(
    () =>
      generatePath(CHATS_CONVERSATION_PATHNAME, {
        workspaceId: getShortId(workspace.id),
        conversationId: getShortId(conversation.id),
      }),
    [conversation.id, workspace.id],
  );

  const chatConversationUnreadCount = getChatConversationUnreadCount(
    conversation.id,
  );

  const handleHideConversation = useCallback(async () => {
    await hideConversation(conversation.id);

    if (location.pathname.includes(getShortId(conversation.id))) {
      setLastSegmentPath(null);

      navigate({
        pathname: generatePath(CHATS_ROOT_PATHNAME, {
          workspaceId: getShortId(workspace.id),
        }),
      });
    }
  }, [
    conversation.id,
    hideConversation,
    navigate,
    location,
    setLastSegmentPath,
    workspace.id,
  ]);

  const hideConversationButtonRenderer = useMemo(() => {
    if (pinned) {
      return null;
    }

    return !conversation.isHidden &&
      !conversation.isHiddenAt &&
      chatConversationUnreadCount === 0 ? (
      <MenuItem
        onClick={handleHideConversation}
        data-testid="hide-chat-conversation">
        <FormattedMessage id={ChatTranslation.hideConversation} />
      </MenuItem>
    ) : null;
  }, [
    chatConversationUnreadCount,
    conversation.isHidden,
    conversation.isHiddenAt,
    handleHideConversation,
    pinned,
  ]);

  return (
    <StyledConversationNavLink
      ref={itemRef}
      onClickCapture={setConversationState}
      onClick={handleConversationClick}
      to={linkPath}
      data-testid="conversation-nav-link"
      data-tooltip-id="global-tooltip"
      data-tooltip-content={conversationTitle ? conversationTitle : ''}
      data-tooltip-place={TooltipPlace.top}
      data-chat-type={conversation.type}
      data-entityid={conversation.id}>
      <StyledConversationListItem
        hasHover={isMobile}
        className={`conversation-list-item ${isActive ? ' active' : undefined}`}
        highlighted={chatConversationHasUnreadCount}
        data-testid="conversation-list-item">
        <ConversationAvatar
          defaultMargin={false}
          account={privateChatAccount}
          conversation={conversation}
        />

        <div className="wrapper">
          <ConversationInner>
            <ConversationWrapper className="wrapper">
              <ConversationName
                className="conversation-name"
                data-testid="conversation-name">
                {conversationTitle ? (
                  <div>
                    <span>{conversationTitle}</span>
                    {searchResult && <span>{formattedDate}</span>}
                  </div>
                ) : privateChatAccount?.origin === AccountOrigin.bot &&
                  privateChatAccount.firstName ? (
                  privateChatAccount.firstName
                ) : (
                  account.firstName
                )}
              </ConversationName>

              {isSlackUser && (
                <IntegrationBadgeContainer>
                  <IntegrationBadge
                    origin={AccountOrigin.slack}
                    badgeSize={20}
                  />
                </IntegrationBadgeContainer>
              )}

              {searchResult && (
                <ConversationListItemPreview
                  conversation={conversation}
                  hitMessage={hitMessage}
                />
              )}
            </ConversationWrapper>

            <ConversationControls
              className="conversation-controls"
              data-testid="conversation-controls">
              <ControlsGroup>
                {showInCallBadge && (
                  <CallFilledIcon
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: ChatTranslation.peoplePanelInACallBadge,
                    })}
                    data-tooltip-place={TooltipPlace.bottom}
                  />
                )}
                <>
                  <OptionsButton
                    ref={threadOptionsRef}
                    visible={
                      isMobile || isTablet || isAppleTouchDevice || menuOpen
                    }
                    isActive={menuOpen}
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={formatMessage({
                      id: ChatTranslation.switcherListItemOptionsTooltip,
                    })}
                    data-tooltip-place={TooltipPlace.bottom}
                    data-testid="options-button"
                    aria-label={formatMessage({
                      id: ChatTranslation.switcherListItemOptionsTooltip,
                    })}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}>
                    <KebabIcon width={16} height={16} />
                  </OptionsButton>

                  <Menu
                    onOpen={() => {
                      setMenuOpen(true);
                    }}
                    onClose={() => {
                      setMenuOpen(false);
                    }}
                    viaPortal={!isPreviewMode}
                    trigger={threadOptionsRef}
                    vAlign={VerticalAlignment.BOTTOM}
                    hAlign={HorizontalAlignment.RIGHT}
                    data-testid="chat-conversation-list-item-menu">
                    <MenuItem
                      onClick={handlePinChatConversation}
                      data-testid="pin-chat-option">
                      <FormattedMessage
                        id={
                          pinned
                            ? ChatTranslation.tooltipUnpinChatButton
                            : ChatTranslation.tooltipPinChatButton
                        }
                      />
                    </MenuItem>

                    {hideConversationButtonRenderer}

                    <MenuItem
                      onClick={handleMarkAllAsRead}
                      isDisabled={!chatConversationHasUnreadCount}
                      data-testid="mark-all-read">
                      <FormattedMessage
                        id={ChatTranslation.switcherListItemMarkAllRead}
                      />
                    </MenuItem>
                  </Menu>
                </>

                {chatConversationUnreadCount > 0 && !searchResult ? (
                  <div className="countBadgeWrapper">
                    <CountBadge
                      count={chatConversationUnreadCount}
                      positionMode={PositionType.relative}
                    />
                  </div>
                ) : null}
              </ControlsGroup>
            </ConversationControls>
          </ConversationInner>
        </div>
      </StyledConversationListItem>
    </StyledConversationNavLink>
  );
};
