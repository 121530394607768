import { gql } from '@apollo/client';
import {
  GeneralSettingsApiType,
  GeneralSettingsFormValues,
} from './GeneralSettings.types';

export const UPDATE_GENERAL_SETTINGS = gql`
  mutation UpdateGeneralSettings(
    $input: updateGlobalSettingsAccountIdentityInput!
  ) {
    updateGlobalSettingsAccountIdentity(input: $input) {
      accountIdentity {
        openApp
        openLink
        theme
        mfaRequired
        productInformationEmailsEnabled
        serviceUpdatesEmailsEnabled
      }
    }
  }
`;

export interface UpdateGeneralSettingsVariables {
  input: GeneralSettingsFormValues & { id: string };
}

export interface UpdateGeneralSettingsResponse {
  updateGlobalSettingsAccountIdentity: {
    accountIdentity: GeneralSettingsApiType;
  };
}
