import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { FormattedMessage } from 'react-intl';
import { CalendarIcon } from '../../icons';
import { FieldProps } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GlobalTranslation } from '../../../domains/Intl/i18n/i18n.types';
import { KeyboardTimePickerWrapper, StyledButton } from './TimePicker.styled';
import { ButtonMode } from '../Button/Button';
import _get from 'lodash/get';
import { materialTheme } from './TimePicker.MuiTheme';
import { appEnv } from '../../../appEnv';
import { getCurrentThemeType } from '../../../domains/Theme';

interface TimePickerProps {
  onChange: (date: MaterialUiPickersDate) => void;
  value: Date;
  format: string;
  showErrorMessageAbove?: boolean;
  keyboardIcon: ReactNode;
}

// Docks here https://material-ui-pickers.dev/api/KeyboardTimePicker
export const TimePicker: FC<FieldProps & TimePickerProps> = props => {
  const { form, field, keyboardIcon } = props;
  const errorIdToDisplay = useMemo(
    () => _get(form.touched, field.name) && _get(form.errors, field.name),
    [form.touched, form.errors, field.name],
  );

  const handleOnChange = useCallback(
    (date: MaterialUiPickersDate) => {
      props.onChange
        ? props.onChange(date)
        : form.setFieldValue(field.name, date);
    },
    [field.name, form, props],
  );
  const isAMPMFormat = appEnv.TIME_FORMAT === 'hh:mm aa';

  const currentThemeType = getCurrentThemeType();
  const currentTheme = useMemo(() => materialTheme(), [currentThemeType]); // eslint-disable-line

  return (
    <ThemeProvider theme={currentTheme}>
      <KeyboardTimePickerWrapper>
        <KeyboardTimePicker
          {...props}
          {...props.field}
          format={appEnv.TIME_FORMAT}
          onChange={handleOnChange}
          placeholder={isAMPMFormat ? '08:00 AM' : '13:00'}
          mask={isAMPMFormat ? '__:__ _M' : '__:__'}
          maskChar="_"
          ampm={isAMPMFormat}
          error={Boolean(errorIdToDisplay)}
          helperText={
            errorIdToDisplay && (
              <FormattedMessage id={errorIdToDisplay as GlobalTranslation} />
            )
          }
          autoOk={false}
          inputVariant="outlined"
          initialFocusedDate={props.field.value}
          keyboardIcon={
            keyboardIcon === undefined ? (
              <CalendarIcon width={18} height={18} />
            ) : (
              keyboardIcon
            )
          }
          invalidDateMessage={
            <FormattedMessage id={GlobalTranslation.invalidDateFormat} />
          }
          cancelLabel={
            <StyledButton mode={ButtonMode.secondary}>
              <FormattedMessage id={GlobalTranslation.cancel} />
            </StyledButton>
          }
          okLabel={
            <StyledButton mode={ButtonMode.primary}>
              <FormattedMessage id={GlobalTranslation.save} />
            </StyledButton>
          }
          value={props.field.value}
        />
      </KeyboardTimePickerWrapper>
    </ThemeProvider>
  );
};
