import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { appEnv } from '../../../../appEnv';
import AnnouncementImage from '../../../../shared/assets/images/announcement4-cover.png';
import { AnnouncementTranslation } from '../../i18n';
import {
  AnnouncementCover,
  FeaturesContainer,
  FeaturesItem,
  FeaturesList,
  FeaturesTitle,
  StyledAnnouncement,
} from '../Announcement.styled';

export const Announcement5Rebranding = () => {
  return (
    <StyledAnnouncement>
      <AnnouncementCover
        src={AnnouncementImage}
        srcSet={AnnouncementImage}
        alt="announcement-cover"
      />
      <FeaturesContainer>
        <FeaturesTitle>
          <FormattedHTMLMessage
            id={AnnouncementTranslation.announcement5FeaturesTitle}
          />
        </FeaturesTitle>
        <FeaturesList>
          <FeaturesItem>
            <b>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5FirstParagraphTitle}
              />
            </b>
            <p>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5FirstParagraphContent}
              />
            </p>
          </FeaturesItem>
          <FeaturesItem>
            <b>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5SecondParagraphTitle}
              />
            </b>
            <p>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5SecondParagraphContent}
                values={{
                  supportLink: appEnv.APPS_INTEGRATIONS_READ_MORE_LINK,
                }}
              />
            </p>
          </FeaturesItem>
          <FeaturesItem>
            <b>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5ThirdParagraphTitle}
              />
            </b>
            <p>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5ThirdParagraphContent}
                values={{
                  supportLink: appEnv.SUPPORT_DIFFERENCE_BETWEEN_PACKAGES_URL,
                }}
              />
            </p>
          </FeaturesItem>
          <FeaturesItem>
            <p>
              <FormattedHTMLMessage
                id={AnnouncementTranslation.announcement5FourthParagraphContent}
              />
            </p>
          </FeaturesItem>
        </FeaturesList>
      </FeaturesContainer>
    </StyledAnnouncement>
  );
};
