import styled, { css } from 'styled-components';
import { RemoveButton } from './RemoveButton';

export const StyledUploadAttachment = styled.div<{
  processing: boolean;
  isImage: boolean;
}>`
  position: relative;
  border: 1px solid ${p => p.theme.colors.BorderLight};
  border-radius: 0.5rem;
  background-color: ${p => p.theme.colors.Surface};

  ${p =>
    p.isImage
      ? css`
          width: 4rem;
          height: 4rem;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        `
      : css`
          display: flex;
          align-items: center;
          max-width: 13rem;
        `}

  ${p =>
    p.processing &&
    css`
      opacity: 0.4;
    `}

  &:not(:hover) {
    ${RemoveButton} {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5rem;
`;

export const IconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const AttachmentDetails = styled.div`
  padding-right: 0.5rem;
  word-break: break-all;
  font-family: ${({ theme }) => theme.fonts.Semibold};
  font-size: 0.875rem;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;
