import { appEnv } from '../../appEnv';
import {
  BookmarkType,
  ImportFolderType,
  TreeType,
} from './ChromeExtension.types';
import { extensionSupported } from '../../shared/utils/extension.utils';

export const importAppsFromHistory = () => {
  return new Promise((resolve, reject) => {
    if (!extensionSupported) {
      reject(new Error('Chromium-based browser is not used'));
    }

    chrome.runtime?.sendMessage(
      appEnv.IMPORT_EXTENSION_ID,
      { history: true },
      (response: string[]) => {
        if (chrome.runtime.lastError) {
          reject(new Error(chrome.runtime.lastError.message));
        } else {
          resolve(response);
        }
      },
    );
  });
};

export const prepareTreeNodes = (data: BookmarkType[]) => {
  if (!data) {
    return [];
  }
  const result: TreeType[] = [];

  const getTreeChildren = (item: BookmarkType): TreeType[] => {
    return item.children
      ?.map(subItem => {
        if (subItem.children) {
          if (!subItem.children.length) {
            return {};
          }
          return {
            value: subItem.id,
            label: subItem.title,
            children: getTreeChildren(subItem),
          };
        }

        return {
          value: subItem.id,
          label: subItem.title,
        };
      })
      .filter(item => item.value) as TreeType[];
  };

  data?.forEach((item: any) => {
    if (item.children) {
      if (!item.children.length) {
        return;
      }
      result.push({
        value: item.id,
        label: item.title,
        children: getTreeChildren(item),
      });
    } else {
      result.push({
        value: item.id,
        label: item.title,
      });
    }
  });

  return result;
};

const flattenNestedBookmarks = (
  links: BookmarkType[],
  selectedLinksMap?: { [key: string]: boolean },
): BookmarkType[] => {
  const result: BookmarkType[] = [];
  const flattenLevel = (level: BookmarkType) => {
    if (level.children) {
      level.children.forEach(child => flattenLevel(child));
    } else if (!selectedLinksMap || selectedLinksMap[level.id]) {
      result.push(level);
    }
  };
  links?.forEach(child => flattenLevel(child));
  return result;
};

export const convertBookmarksToLinksImport = (
  bookmarks: BookmarkType[],
  selectedLinksMap: { [key: string]: boolean },
) => {
  if (!bookmarks || !selectedLinksMap) {
    return [];
  }

  const linksWithFolder: ImportFolderType[] = [];
  const linksWithoutFolder: ImportFolderType = {
    folderName: null,
    links: [],
  };

  const groupLinksByFolder = (bookmark: BookmarkType) => {
    if (bookmark.children) {
      linksWithFolder.push({
        folderName: bookmark.title,
        links: flattenNestedBookmarks(bookmark.children, selectedLinksMap).map(
          bookmark => ({
            title: bookmark.title,
            url: bookmark.url!,
          }),
        ),
      });
    } else if (selectedLinksMap[bookmark.id]) {
      linksWithoutFolder.links.push({
        title: bookmark.title,
        url: bookmark.url!,
      });
    }
  };

  bookmarks[0].children?.forEach(rootFolder => {
    rootFolder.children?.forEach(item => {
      groupLinksByFolder(item);
    });
  });

  return [...linksWithFolder, linksWithoutFolder].filter(
    folder => folder.links.length,
  );
};

export const importLinksFromHistory = () => {
  return new Promise((resolve, reject) => {
    if (!extensionSupported) {
      reject(new Error('Chromium-based browser is not used'));
    }
    chrome.runtime?.sendMessage(
      appEnv.IMPORT_EXTENSION_ID,
      { bookmarks: true },
      (response: any) => {
        if (chrome.runtime.lastError) {
          reject(new Error(chrome.runtime.lastError.message));
        } else {
          resolve(response);
        }
      },
    );
  });
};

export const getAllBookmarkIdsList = (bookmarks: BookmarkType[]): string[] => {
  return flattenNestedBookmarks(bookmarks).map(bookmark => bookmark.id);
};
