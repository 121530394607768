import React, { FC, useMemo } from 'react';
import { isVideoMimeType } from '../../../../../Asset';
import { FileAssetApiType } from '../../../../../Asset/Asset.types';
import { Attachment } from '../../../../../Asset/Attachment/Attachment.types';
import { StyledAttachmentsContainer } from './AttachmentsContainer.styled';
import { UploadAttachment } from './UploadAttachment';

interface AttachmentsContainerProps {
  attachments: Attachment[];
  onRemove: (id: string) => void;
}

export const AttachmentsContainer: FC<AttachmentsContainerProps> = ({
  attachments,
  onRemove,
}) => {
  const filteredAttachments = useMemo(
    () => attachments.filter(attachment => attachment.id),
    [attachments],
  );

  if (!filteredAttachments.length) {
    return null;
  }

  // We don't want to display compressed video asset when editing message
  const hideAttachment = (attachment: Attachment) => {
    if (
      isVideoMimeType(attachment.asset?.mimeType) &&
      attachment.asset?.mimeType
    ) {
      const asset = attachment.asset as FileAssetApiType;

      return (
        typeof asset.metadata === 'object' &&
        !Array.isArray(asset.metadata) &&
        asset.metadata &&
        !('original_asset_id' in asset.metadata)
      );
    }
  };

  return (
    <StyledAttachmentsContainer data-testid="attachments">
      {filteredAttachments.map(attachment =>
        hideAttachment(attachment) ? null : (
          <UploadAttachment
            key={attachment.id}
            attachment={attachment}
            onRemove={onRemove}
          />
        ),
      )}
    </StyledAttachmentsContainer>
  );
};
