import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { StyledMarketplaceItemWrapper } from './MarketplaceItem.styled';
import { AppIntegrationMarketplaceItemType } from '../../../AppIntegrations.queries';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { AppIntegrationsTranslation } from '../../../i18n';
import { AdminHeaderSeparator } from '../../../../Admin';
import {
  useGetAppIntegrationsAuthUrl,
  useInstallMarketplaceItemMutation,
} from '../../../AppIntegrations.hooks';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../../../../../shared/hooks';
import { MARKETPLACE_CATEGORY_ITEMS_PATH } from '../../../AppIntegrations.constants';
import { getShortId } from '../../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import {
  SteamEventLoginAppIntegrationsRequest,
  StreamEvent,
  StreamEventType,
} from '../../../../Mercure/General';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../../../shared/components/Toast';
import { useMercureListener } from '../../../../Mercure/General/GeneralMercure.hooks';

export const MarketplaceItem: FC<{
  marketplaceItem: AppIntegrationMarketplaceItemType;
  workspaceId: string;
  refetchMarketplaceItems: () => void;
}> = ({ marketplaceItem, workspaceId, refetchMarketplaceItems }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { marketplaceCategoryId } = useParams<{
    marketplaceCategoryId: string;
  }>();
  const { addListener, removeListener } = useMercureListener();

  const response = useGetAppIntegrationsAuthUrl({
    variables: {
      workspaceId: 'workspaces/' + workspaceId,
      connectorConfiguration: marketplaceItem.connectorConfiguration,
    },
    fetchPolicy: 'network-only',
  });

  const [installMarketplaceItem] = useInstallMarketplaceItemMutation();
  const data = response[marketplaceItem.connectorConfiguration.driver];

  const isAuthorized = Boolean(marketplaceItem.authenticated);
  const isAppInstalled = Boolean(marketplaceItem.accountAppIntegration);

  const moveToAppConfiguration = useCallback(() => {
    navigate({
      pathname: generatePath(MARKETPLACE_CATEGORY_ITEMS_PATH, {
        workspaceId: workspaceId,
        marketplaceCategoryId: marketplaceCategoryId,
        marketplaceCategoryItemId: getShortId(marketplaceItem.id),
        appId: getShortId(marketplaceItem.app.id),
      }),
      search: getQueryParamsFrom({ ...queryParams }),
    });
  }, [
    navigate,
    marketplaceCategoryId,
    marketplaceItem,
    queryParams,
    workspaceId,
  ]);
  const handleLoginAppIntegration = useCallback(() => {
    if (marketplaceItem.connectorConfiguration.type === 'credentials') {
      navigate({
        search: getQueryParamsFrom({
          ...queryParams,
          createAppIntegrationCredentialMarketplaceItemId: marketplaceItem.id,
        }),
      });
    } else {
      const popupWidth = 500;
      const popupHeight = 650;

      const xPosition = window.screen.width / 2 - popupWidth / 2;
      const yPosition = window.screen.height / 2 - popupHeight / 2;

      if (data?.authUrl) {
        window.open(
          data!.authUrl,
          `${marketplaceItem.connectorConfiguration.driver} Authorization`,
          `popup, height=${popupHeight}, width=${popupWidth}, left=${xPosition}, top=${yPosition}`,
        );
      }
    }
  }, [data, navigate, marketplaceItem, queryParams]);

  const handleInstallMarketplaceItem = useCallback(() => {
    if (Boolean(marketplaceItem.accountAppIntegration?.active)) {
      refetchMarketplaceItems();
      return null;
    }

    installMarketplaceItem({
      variables: {
        input: {
          workspace: '/workspaces/' + workspaceId,
          appIntegrationMarketplaceItem: marketplaceItem.id,
        },
      },
    })
      .then(res => {
        if (res) {
          showToastSuccessMessage(
            AppIntegrationsTranslation.createAppIntegrationSuccessInstalledMessage,
          );
          refetchMarketplaceItems();
          moveToAppConfiguration();
        }
      })
      .catch(e => showToastGraphQLErrors(e.graphQLErrors));
  }, [
    moveToAppConfiguration,
    marketplaceItem,
    installMarketplaceItem,
    workspaceId,
    refetchMarketplaceItems,
  ]);

  const handleConfigurationClick = useCallback(() => {
    if (!isAuthorized) {
      handleLoginAppIntegration();
    } else if (!isAppInstalled) {
      handleInstallMarketplaceItem();
    } else {
      moveToAppConfiguration();
    }
  }, [
    handleInstallMarketplaceItem,
    handleLoginAppIntegration,
    isAppInstalled,
    isAuthorized,
    moveToAppConfiguration,
  ]);

  const cardControlsText = useMemo(() => {
    if (!isAuthorized) {
      return AppIntegrationsTranslation.backToAppIntegrationsLoginButton;
    }

    if (!isAppInstalled) {
      return AppIntegrationsTranslation.backToAppIntegrationsInstallButton;
    }

    return AppIntegrationsTranslation.appIntegrationConfiguration;
  }, [isAppInstalled, isAuthorized]);

  useEffect(() => {
    const listener: Parameters<typeof addListener>[0] = (
      event: StreamEvent,
    ) => {
      if (
        event['@type'] === StreamEventType.APPS_INTEGRATIONS_ACTION &&
        Object.values(SteamEventLoginAppIntegrationsRequest).includes(
          // TODO refactor types for mercure
          event['@request'] as SteamEventLoginAppIntegrationsRequest,
        )
      ) {
        handleInstallMarketplaceItem();
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [
    addListener,
    handleInstallMarketplaceItem,
    marketplaceItem,
    refetchMarketplaceItems,
    removeListener,
  ]);

  return (
    <StyledMarketplaceItemWrapper onClick={handleConfigurationClick}>
      <img
        className="logo"
        src={marketplaceItem.app.logo.contentUrl}
        alt={marketplaceItem.name}
      />
      <div className="body">
        <span className="name">{marketplaceItem.name}</span>
        <span className="description">{marketplaceItem.description}</span>
      </div>
      <div className="controls">
        <Button mode={ButtonMode.tertiary} size={ButtonSize.sm}>
          <FormattedMessage id={cardControlsText} />
          <AdminHeaderSeparator />
        </Button>
      </div>
    </StyledMarketplaceItemWrapper>
  );
};
