import React, { FC, useMemo } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { FormInput } from '../../../../shared/components/Input';
import { FormSelect } from '../../../../shared/components/Select/FormSelect';
import {
  FieldSet,
  FieldSetControls,
} from '../../../../shared/components/FieldSet';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../shared/components/Button/Button';
import { BillingTranslation } from '../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { COUNTRIES, countryVatOptions } from '../../Billing.constants';
import { CreditCardDetailsInput } from '../../CreditCardDetailsInput';
import { SpinnerFullscreen } from '../../../../shared/components/SpinnerFullscreen';
import { OnboardBillingContactFormValues } from '../../Billing.types';
import {
  VatLearnMore,
  VatLearnMoreLink,
} from '../EditBillingInformationForm/EditBillingInformationForm.styled';
import { appEnv } from '../../../../appEnv';
import {
  FieldClusterContainer,
  FieldClusterRow,
  FieldClusterTitle,
} from '../../../../shared/components/FieldCluster';

const initialValues: OnboardBillingContactFormValues = {
  name: '',
  email: '',
  billingName: '',
  country: 'US',
  city: '',
  line1: '',
  line2: '',
  postalCode: '',
  state: '',
  coupon: '',
  vatType: '',
  vatNumber: '',
};
// TODO: use translation from billing
const BillingFormSchema: Schema<OnboardBillingContactFormValues> = Yup.object().shape(
  {
    name: Yup.string().required(
      BillingTranslation.formNameOfCardHolderRequired,
    ),
    email: Yup.string().email().required(BillingTranslation.formEmailRequired),
    billingName: Yup.string().required(
      BillingTranslation.formBillingNameRequired,
    ),
    country: Yup.string(),
    city: Yup.string().required(BillingTranslation.formCityRequired),
    line1: Yup.string().required(BillingTranslation.formLine1Required),
    line2: Yup.string(),
    postalCode: Yup.string().required(BillingTranslation.formPostCodeRequired),
    state: Yup.string(),
    coupon: Yup.string(),
    vatType: Yup.string().when('vatNumber', {
      is: vatNumber => vatNumber?.length > 0,
      then: Yup.string().required(BillingTranslation.formVatTypeRequired),
    }),
    vatNumber: Yup.string().when('vatType', {
      is: vatType => vatType?.length > 0,
      then: Yup.string().required(BillingTranslation.formVatNumberRequired),
    }),
  },
  [['vatType', 'vatNumber']],
);

interface BillingPageFormProps {
  stripe: Stripe | null;
  handleSubmit: (
    values: OnboardBillingContactFormValues,
    FormikHelpers: FormikHelpers<OnboardBillingContactFormValues>,
  ) => void;
  ctaText?: string | null;
  withCurrentTheme?: boolean;
}

export const InitialBillingForm: FC<BillingPageFormProps> = ({
  stripe,
  handleSubmit,
  ctaText = null,
  withCurrentTheme,
}) => {
  const { formatMessage } = useIntl();
  const countriesOptions = useMemo(
    () =>
      Object.keys(COUNTRIES).map(key => (
        <option key={key} value={key}>
          {COUNTRIES[key]}
        </option>
      )),
    [],
  );

  const countryTaxIdOptions = useMemo(() => {
    return countryVatOptions.map(({ title, value }, i) => (
      <option key={value + i} value={value}>
        {title}
      </option>
    ));
  }, []);

  return (
    <Formik
      validationSchema={BillingFormSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid="registration-form">
          <FieldSet disabled={isSubmitting}>
            <CreditCardDetailsInput
              disabled={isSubmitting}
              label={BillingTranslation.formNameCardDetailsLabel}
              withCurrentTheme={withCurrentTheme}
            />

            <FieldClusterContainer>
              <Field
                name="name"
                type="text"
                data-testid="name-input"
                placeholder={formatMessage({
                  id: BillingTranslation.formNameOfCardHolderLabel,
                })}
                aria-label={formatMessage({
                  id: BillingTranslation.formNameOfCardHolderLabel,
                })}
                inlineError
                component={FormInput}
              />
            </FieldClusterContainer>

            <FieldClusterContainer>
              <FieldClusterTitle>
                <FormattedMessage
                  id={BillingTranslation.formClusterTitleBillingContact}
                />
              </FieldClusterTitle>
              <FieldClusterRow inputCount={1} firstRow={true} lastRow={false}>
                <Field
                  name="billingName"
                  type="text"
                  data-testid="billing-name-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formBillingNameLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formBillingNameLabel,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
              <FieldClusterRow inputCount={1} firstRow={false} lastRow={true}>
                <Field
                  name="email"
                  type="email"
                  data-testid="email-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formEmailLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formEmailLabel,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
            </FieldClusterContainer>

            <FieldClusterContainer>
              <FieldClusterTitle>
                <FormattedMessage
                  id={BillingTranslation.formClusterTitleAddress}
                />
              </FieldClusterTitle>
              <FieldClusterRow inputCount={1} firstRow={true} lastRow={false}>
                <Field
                  name="country"
                  placeholder={formatMessage({
                    id: BillingTranslation.formCountryLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formCountryLabel,
                  })}
                  component={FormSelect}>
                  {countriesOptions}
                </Field>
              </FieldClusterRow>
              <FieldClusterRow inputCount={1} firstRow={false} lastRow={false}>
                <Field
                  name="line1"
                  type="text"
                  data-testid="line1-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formLine1Label,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formLine1Label,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
              <FieldClusterRow inputCount={1} firstRow={false} lastRow={false}>
                <Field
                  name="line2"
                  type="text"
                  data-testid="line2-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formLine2Label,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formLine2Label,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
              <FieldClusterRow inputCount={2} firstRow={false} lastRow={false}>
                <Field
                  name="city"
                  type="text"
                  data-testid="city-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formCityLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formCityLabel,
                  })}
                  inlineError
                  component={FormInput}
                />

                <Field
                  name="postalCode"
                  type="text"
                  data-testid="postalCode-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formPostCodeLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formPostCodeLabel,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
              <FieldClusterRow inputCount={1} firstRow={false} lastRow={true}>
                <Field
                  name="state"
                  type="text"
                  data-testid="state-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formStateLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formStateLabel,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
            </FieldClusterContainer>

            <FieldClusterContainer>
              <FieldClusterRow inputCount={1} firstRow={true} lastRow={true}>
                <Field
                  name="coupon"
                  type="text"
                  data-testid="coupon-input"
                  placeholder={formatMessage({
                    id: BillingTranslation.formCouponLabel,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formCouponLabel,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
            </FieldClusterContainer>

            <FieldClusterContainer>
              <FieldClusterTitle>
                <FormattedMessage id={BillingTranslation.formClusterTitleVat} />
              </FieldClusterTitle>
              <FieldClusterRow inputCount={1} firstRow={true} lastRow={false}>
                <Field
                  className="taxIdType"
                  name="vatType"
                  placeholder={formatMessage({
                    id: BillingTranslation.formVatType,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formVatType,
                  })}
                  inlineError
                  component={FormSelect}>
                  <option value="" />
                  {countryTaxIdOptions}
                </Field>
              </FieldClusterRow>
              <FieldClusterRow inputCount={1} firstRow={false} lastRow={true}>
                <Field
                  name="vatNumber"
                  type="text"
                  placeholder={formatMessage({
                    id: BillingTranslation.formVatNumber,
                  })}
                  aria-label={formatMessage({
                    id: BillingTranslation.formVatNumber,
                  })}
                  inlineError
                  component={FormInput}
                />
              </FieldClusterRow>
            </FieldClusterContainer>

            {appEnv.VAT_LEARN_MORE_URL && (
              <VatLearnMore data-testid="vat-learn-more">
                <FormattedMessage
                  id={BillingTranslation.formVatLearnMoreLabelLink}
                />
                <VatLearnMoreLink
                  href={appEnv.VAT_LEARN_MORE_URL}
                  data-testid="vat-learn-more-link">
                  <FormattedMessage
                    id={BillingTranslation.formVatLearnMoreLink}
                  />
                </VatLearnMoreLink>
              </VatLearnMore>
            )}
          </FieldSet>
          <FieldSetControls disabled={!stripe || isSubmitting}>
            <Button
              type="submit"
              data-testid="submit-button"
              size={ButtonSize.md}
              mode={ButtonMode.primary}
              fullWidth>
              {ctaText ? (
                ctaText
              ) : (
                <FormattedMessage
                  id={BillingTranslation.onboardingAddPaymentMethodButton}
                />
              )}
            </Button>
          </FieldSetControls>
          {isSubmitting && <SpinnerFullscreen />}
        </Form>
      )}
    </Formik>
  );
};
