import { persistedLogStorage } from './PersistedLogStorage';
import { PersistedLogCategory } from './PersistedLog.types';

export const createPersistedLog = (
  message: string,
  category?: PersistedLogCategory,
) => {
  try {
    persistedLogStorage.logs.put({
      date: new Date(),
      category: category || 'common',
      message,
    });
  } catch (e) {
    console.error(e);
  }
};
