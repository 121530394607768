import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../Theme';

export const TemplateCheckboxWrapper = styled.label<{
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0 16px;
  font-family: ${p => p.theme.fonts.Semibold};
  border-radius: 8px;
  border: solid 1px ${p => p.theme.colors.BorderDefault};
  cursor: pointer;
  transition: background-color 0.3s ease;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${p => p.theme.colors.BackgroundInputAutofill};
    `}

  &:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }

  ${({ theme: { breakpoints, spacing } }) => breakpoints.md`
    width: 50%;
    &:first-child {
      margin: 0 ${spacing.xl} 0 0;
    }
  `};

  &:hover {
    background-color: ${p => p.theme.colors.BackgroundInputAutofill};
  }
`;

export const TemplateCheckboxMessage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const TemplateName = styled.span`
  margin-left: 0.25rem;
`;

export const TemplateLogo = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 0.25rem;
`;

export const TemplateIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 0.75rem;
  }

  @media (max-width: ${Breakpoints.xs}px) {
    display: none;
  }
`;

export const TemplateIcon = styled.img`
  width: 22px;
  height: 22px;
`;
