import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import {
  TimelineEventAction,
  TimelineEventScope,
} from '../../Timeline.constants';
import { openUrl } from '../../../../shared/utils/url.utils';
import { useTimelineEventTracker } from '../../Timeline.hooks';
import { OpenType } from '../../../GeneralSettings/GeneralSettingsAdmin/EditGeneralSettings/GeneralSettingsForm/GeneralSettingsForm.constants';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';

interface TimelineAppInfoProps {
  appName?: string;
  appUrl?: string;
  appId: string;
  iconRef?: RefObject<HTMLImageElement>;
  isApp?: boolean;
  isLinkInactive?: boolean;
}

const StyledLink = styled.a`
  cursor: pointer;

  &.inactive {
    cursor: auto;
  }
`;

export const TimelineAppInfo: FC<TimelineAppInfoProps> = ({
  appName = '',
  appUrl = '',
  appId,
  iconRef,
  isApp,
  isLinkInactive,
}) => {
  const { account } = useCurrentAccount();
  const openType = account?.identity.openApp || OpenType.newTab;
  const trackTimelineEvent = useTimelineEventTracker();

  const openLink = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      trackTimelineEvent(
        TimelineEventScope.apps,
        appId,
        TimelineEventAction.open,
      );
      openUrl(openType, appUrl, appName, event);
    },
    [appId, appUrl, appName, trackTimelineEvent, openType],
  );

  useEffect(() => {
    iconRef?.current?.addEventListener('click', openLink);
    return () => {
      /* eslint-disable */
      iconRef?.current?.removeEventListener('click', openLink);
    };
  }, [openLink, iconRef]);

  return (
    //TODO remove ts-ignore
    //@ts-ignore
    <StyledLink className={`highlighted${isLinkInactive ? ' inactive' : ''}`} onClick={!isLinkInactive ? openLink : undefined}>
      {isApp && <FormattedMessage id={TimelineTranslation.eventTitleTheApp} />}
      {appName}
    </StyledLink>
  );
};
