import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingTranslation } from '../../../i18n';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderTitle,
} from '../../../../Admin';
import { InvoicesTable } from '../../../BillingTables';
import { extractNodes } from '../../../../../shared/api/api.utils';
import { Spinner } from '../../../../../shared/components/Spinner';
import { format, parseISO } from 'date-fns';
import {
  InvoiceTableDataType,
  PaymentCustomerApiType,
} from '../../../Billing.types';
import Dinero from 'dinero.js';
import { PaymentMethodAccount } from '../../../PaymentMethodAccount';

interface InvoicesProps {
  paymentCustomer: PaymentCustomerApiType | undefined;
  loading: boolean;
}

export const Invoices: FC<InvoicesProps> = ({ paymentCustomer, loading }) => {
  const invoices = useMemo(() => paymentCustomer?.invoices, [paymentCustomer]);

  const data = useMemo(() => {
    const dataFromApi = extractNodes(invoices);
    return dataFromApi.map(
      (node): InvoiceTableDataType => {
        return {
          date: format(parseISO(node.periodStart), 'PP'),
          paymentmethod: <PaymentMethodAccount digits={node.paymentMethod} />,
          amount: `${Dinero({ amount: node.amount }).toFormat(
            '0,0.00',
          )} ${node.currency.toUpperCase()}`,
          pdf: node.pdf,
        };
      },
    );
  }, [invoices]);

  return (
    <Section data-testid="section-invoices">
      <SectionHeader data-testid="header">
        <SectionHeaderTitle data-testid="title">
          <FormattedMessage id={BillingTranslation.adminInvoicesTitle} />
        </SectionHeaderTitle>
      </SectionHeader>
      <SectionBody data-testid="body">
        {data && !loading ? <InvoicesTable data={data} /> : <Spinner />}
      </SectionBody>
    </Section>
  );
};
