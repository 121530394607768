import React, { FC, useMemo } from 'react';
import { extractNodes } from '../../../../shared/api/api.utils';
import {
  Checkbox,
  CheckboxShapeType,
} from '../../../../shared/components/Checkbox';
import { Spinner } from '../../../../shared/components/Spinner';
import {
  useAppStoreApps,
  useAppStoreCategories,
} from '../../../AppStore/AppStore.hooks';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { getAppsWithCategories } from '../../Template.utils';
import { TemplateListLabel } from '../ApplyTemplates.styled';
import {
  AppsContentGrid,
  AppTile,
  AppTileIcon,
  AppTileName,
  AppTileWrapper,
} from './SelectAppsForm.styled';
import type { DesktopAppApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

const COLUMN_APPS_LIMIT = 3;
const COLUMNS_LIMIT = 16;

export interface SelectAppsFormProps {
  selectedApps: Set<string>;
  onSelectApp: (appId: string) => void;
}

export const SelectAppsForm: FC<SelectAppsFormProps> = ({
  selectedApps,
  onSelectApp,
}) => {
  const { workspace } = useCurrentWorkspace();

  const { data: appsData, loading: loadingApps } = useAppStoreApps({
    skip: !workspace.id,
    variables: {
      filterUsedByMe: false,
      category: null,
      workspace: null,
      filterFreeTextSearch: null,
      custom: null,
      filterExistVaultItemsByWorkspace: null,
      onboardingEnabled: true,
      presentInAppStore: true,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: categoriesData, loading: loadingCategories } =
    useAppStoreCategories({
      variables: {
        presentInAppStore: true,
      },
    });

  const apps = useMemo(() => extractNodes(appsData?.apps), [appsData]);
  const categories = useMemo(
    () => extractNodes(categoriesData?.categories),
    [categoriesData],
  );

  const sortedAppsWithCategories = useMemo(
    () => getAppsWithCategories(categories, apps, COLUMN_APPS_LIMIT),
    [apps, categories],
  );

  const renderAppsList = () => {
    const appsList = [];
    let columnsCount = 0;
    for (let category in sortedAppsWithCategories) {
      if (columnsCount >= COLUMNS_LIMIT) {
        break;
      }

      if (!category || !sortedAppsWithCategories[category].length) {
        continue;
      }

      const appsListData = sortedAppsWithCategories[category].map(
        (app: DesktopAppApiType, index: number) => {
          if (index >= COLUMN_APPS_LIMIT) {
            return false;
          }

          const checked = selectedApps.has(app.id);

          return (
            <label htmlFor={app.id} key={app.id}>
              <AppTile checked={checked}>
                <AppTileWrapper>
                  <AppTileIcon src={app.logo.contentUrl} alt={app.name} />
                  <AppTileName>{app.name}</AppTileName>
                </AppTileWrapper>

                <Checkbox
                  id={app.id}
                  value={checked ? 'on' : 'off'}
                  onChange={() => onSelectApp(app.id)}
                  shape={CheckboxShapeType.circle}
                  hidden={checked ? false : true}
                />
              </AppTile>
            </label>
          );
        },
      );

      appsList.push(
        <div>
          <TemplateListLabel>{category}</TemplateListLabel>
          {appsListData}
        </div>,
      );

      columnsCount++;
    }
    return appsList;
  };

  if (loadingApps || loadingCategories) {
    return <Spinner containerHeight={180} />;
  }

  return (
    <>
      <AppsContentGrid data-testid="add-content-apps-grid">
        {renderAppsList()}
      </AppsContentGrid>
    </>
  );
};
