import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import { Translation } from '../../Intl/i18n/i18n.types';
import { StyledUpgrade } from './VerifyFeatureAccess.styled';
import { useCurrentWorkspacePermissions } from '../../Workspace/Workspace.hooks';
import { BillingTranslation } from '../i18n';
import { showToastInfoMessage } from '../../../shared/components/Toast';
import { RequestUpgradeModal } from './RequestUpgradeModal';
import { useNativeWrapper } from '../../NativeWrapper/NativeWrapper.hooks';

interface VerifyFeatureAccessProps {
  restrictionMessage?: Translation;
  restrictionMessageValues?: { [key: string]: any };
  canDisplay: boolean;
  canAccess: boolean;
  unaffectedStyles?: boolean;
}

export const VerifyFeatureAccess: FC<
  PropsWithChildren<VerifyFeatureAccessProps>
> = ({
  children,
  restrictionMessage,
  restrictionMessageValues = {},
  canDisplay = false,
  canAccess = false,
  unaffectedStyles = false,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    permissions: { canUpgrade },
  } = useCurrentWorkspacePermissions();

  const { isNativeWrapperAvailable } = useNativeWrapper();

  const openRequestModalHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (!canUpgrade) {
        showToastInfoMessage(BillingTranslation.noAccessToFeature);
        return;
      }

      setShowModal(true);
    },
    [canUpgrade],
  );

  return canDisplay && !canAccess ? (
    <>
      <StyledUpgrade
        unaffectedStyles={unaffectedStyles}
        onClick={
          !isNativeWrapperAvailable ? openRequestModalHandler : () => {}
        }>
        {children}
      </StyledUpgrade>
      <RequestUpgradeModal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </>
  ) : (
    <>{children}</>
  );
};
