import React, { FC, PropsWithChildren } from 'react';
import { SegmentPageTitleBar } from '../SegmentPageTitleBar';
import { EmptyContent, StyledSegmentEmpty } from './SegmentEmpty.styled';

type SegmentEmptyProps = {
  withTitlebar?: boolean;
};

export const SegmentEmpty: FC<PropsWithChildren<SegmentEmptyProps>> = ({
  children,
  withTitlebar = true,
}) => {
  return (
    <StyledSegmentEmpty data-testid="segment-empty">
      {withTitlebar && <SegmentPageTitleBar />}
      <EmptyContent data-testid="empty-content">{children}</EmptyContent>
    </StyledSegmentEmpty>
  );
};
