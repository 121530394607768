import React, { FC } from 'react';
import { Popover, PopoverProps } from '../../../../shared/components/Popover';
import {
  TimelineDesktopModalBody,
  StyledTimelineDesktopPopover,
} from './TimelineDesktopPopover.styled';
import { getTopLeftAnchor } from '../../../../shared/components/Popover/Popover.utils';
import { useMobile } from '../../../../shared/hooks';
import { Modal, ModalHeader } from '../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { TimelineTranslation } from '../../i18n';
import { TimelineView } from '../TimelineView';
import type { DesktopApiType } from '../../../Desktop/data/Desktop/types/Desktop.types';

interface ShareDesktopPopoverProps
  extends Pick<PopoverProps, 'onRequestClose' | 'triggerRef'> {
  desktop: DesktopApiType;
}

export const TimelineDesktopPopover: FC<ShareDesktopPopoverProps> = ({
  desktop,
  ...props
}) => {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Modal visible fullScreen onRequestClose={props.onRequestClose}>
        <ModalHeader onRequestClose={props.onRequestClose}>
          <FormattedMessage id={TimelineTranslation.headerTitle} />
        </ModalHeader>
        <TimelineDesktopModalBody>
          <TimelineView />
        </TimelineDesktopModalBody>
      </Modal>
    );
  }

  return (
    <Popover anchorGetter={getTopLeftAnchor} verticalMargin={12} {...props}>
      <StyledTimelineDesktopPopover id="timeline-desktop-popover">
        <TimelineView />
      </StyledTimelineDesktopPopover>
    </Popover>
  );
};
