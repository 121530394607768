import React, { FC, PropsWithChildren } from 'react';
import {
  BrowserRouter,
  Route as RouterRoute,
  Routes as RouterRoutes,
} from 'react-router-dom';

export const Route = RouterRoute;

export const Routes = RouterRoutes;

export const Router: FC<PropsWithChildren<any>> = ({ children }) => (
  <BrowserRouter>{children}</BrowserRouter>
);
