import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { OnboardingStepHeader } from '../OnboardingPage.styled';

export const AboutTeamStepHeader = styled(OnboardingStepHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`;

export const AboutTeamNextButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xxl};
`;

export const AboutTeamFormField = styled.div`
  &:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;
