import React, { FC } from 'react';
import { ConferenceApiType } from '../../../../../Conference.types';
import { StyledConferenceTitle } from './ConferenceTitle.styled';

interface ConferenceDesktopTitleProps {
  conference: ConferenceApiType;
  isActive?: boolean;
}

export const ConferenceTitleDesktop: FC<ConferenceDesktopTitleProps> = ({
  conference,
  isActive,
}) => {
  return (
    <StyledConferenceTitle isActive={isActive} status={conference.status}>
      {conference.desktop?.name}
    </StyledConferenceTitle>
  );
};
