import React, { FC, memo, PropsWithChildren } from 'react';
import { Spinner, SpinnerProps } from '../Spinner/Spinner';
import {
  SpinnerLabel,
  StyledSpinnerFullscreen,
} from './SpinnerFullscreen.styled';

interface SpinnerFullscreenProps extends SpinnerProps {
  dimmedBackground?: boolean;
}

export const SpinnerFullscreen: FC<PropsWithChildren<SpinnerFullscreenProps>> =
  memo(({ children, dimmedBackground = false, ...props }) => {
    return (
      <StyledSpinnerFullscreen dimmedBackground={dimmedBackground}>
        {children ? (
          <div>
            <Spinner {...props} />
            <SpinnerLabel>{children || ''}</SpinnerLabel>
          </div>
        ) : (
          <Spinner {...props} />
        )}
      </StyledSpinnerFullscreen>
    );
  });
