import styled, { css } from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';

export const StyledReactions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 30px;

  button {
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
`;

export const Reaction = styled(Button)<{
  hasOwnReaction: boolean;
}>`
  font-size: 0.875rem;
  font-family: ${({ theme, hasOwnReaction }) =>
    hasOwnReaction ? theme.fonts.Semibold : theme.fonts.Regular};
  padding: 0.125rem 0.375rem 0;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  border-radius: 0.75rem;
  background-color: ${p =>
    p.hasOwnReaction
      ? p.theme.colors.OwnMessageBubble
      : p.theme.colors.BackgroundLight};
  border: 1px solid
    ${p =>
      p.hasOwnReaction ? p.theme.colors.Primary : p.theme.colors.BorderLight};
  color: ${p =>
    p.hasOwnReaction
      ? p.theme.colors.Primary
      : p.theme.colors.OnSurfaceHighEmphasis};

  ${p =>
    !p.hasOwnReaction &&
    css`
      &:hover {
        background-color: ${p.theme.colors.OnSurfaceHover};
        border-color: ${p.theme.colors.BorderDefault};
      }
    `}

  span {
    margin-left: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
`;
