import { gql } from '@apollo/client';
import { DesktopAppFragmentFields } from '../../../../Desktop/Desktop.fragments';
import type { DesktopApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import { DesktopAppEdgeApiType } from '../../../../Desktop/data/Desktop/types/Desktop.types';
import { ApiConnection } from '../../../../../shared/api/api.types';
import {
  GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
  GRAPHQL_TYPENAME_DESKTOP_APP_EDGE,
} from '../../../../Desktop/Desktop.constants';

export const ADD_APP_DESKTOP = gql`
  mutation AddAppDesktop($input: addAppDesktopInput!) {
    __typename
    addAppDesktop(input: $input) {
      desktop {
        id
        apps {
          edges {
            node {
              ${DesktopAppFragmentFields}
            }
          }
        }
      }
    }
  }
`;

export interface AddAppToDesktopVariables {
  input: {
    id: string;
    app: string;
  };
}

export interface AddAppToDesktopResponse {
  addAppDesktop: {
    desktop: DesktopApiType & {
      apps?: ApiConnection<
        DesktopAppEdgeApiType,
        typeof GRAPHQL_TYPENAME_DESKTOP_APP_CONNECTION,
        typeof GRAPHQL_TYPENAME_DESKTOP_APP_EDGE
      >;
    };
  };
}
