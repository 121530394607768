import { WorkspaceAccountGroupIdentity } from '../User.constants';
import type { AvailabilityAccountsType } from '../../Account/providers/AccountsProvider/AccountsProvider.types';
import type { AccountApiType, AccountWithCountsApiType } from '../User.types';

export const relatedUsersWithWorkspaceCache = (
  accounts: AccountApiType[],
  accountsWithAvailability: AvailabilityAccountsType,
): AccountWithCountsApiType[] =>
  accounts
    .filter(
      account =>
        account.workspaceCache?.[account.id]?.groupIdentifier !==
        WorkspaceAccountGroupIdentity.GUEST,
    )
    .map(account => accountsWithAvailability[account.id]);
