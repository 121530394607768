import React, { FC, memo } from 'react';
import { ChatMessageInternalType } from '../../../Chat.types';
import { ChatMessageThreadProvider } from './ChatMessageThread.provider';
import { ChatMessageThreadView } from './ChatMessageThreadView';

interface ChatMessageThreadViewProps {
  chatMessage: ChatMessageInternalType;
}

export const ChatMessageThreadViewWrapper: FC<ChatMessageThreadViewProps> =
  memo(({ chatMessage }) => {
    return (
      <ChatMessageThreadProvider chatMessage={chatMessage}>
        <ChatMessageThreadView />
      </ChatMessageThreadProvider>
    );
  });
