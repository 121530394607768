import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalProps,
} from '../../../../shared/components/Modal';
import { CreateDesktopForm } from '../../DesktopForm';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import { DesktopTranslation } from '../../i18n';
import { DESKTOP_ID_PATHNAME } from '../../Desktop.constants';
import { getShortId } from '../../../../shared/utils/id';
import { getQueryParamsFrom } from '../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../shared/hooks';
import type { DesktopApiType } from '../../data/Desktop/types/Desktop.types';
import { useDesktopTrackingEventOnInit } from '../../tracking/DesktopTracking.hooks';

const NAVIGATE_TO_DESKTOP_AFTER_CREATE_DELAY = 300;

interface CreateDesktopModalProps extends ModalProps {
  onlyInternalDesktopCreation?: boolean;
}

export const CreateDesktopModal: FC<CreateDesktopModalProps> = ({
  onRequestClose,
  onlyInternalDesktopCreation,
  ...props
}) => {
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  useDesktopTrackingEventOnInit('open_create_desktop');

  const onDone = useCallback(
    (createdDesktop?: DesktopApiType) => {
      if (createdDesktop) {
        const desktopPath = generatePath(DESKTOP_ID_PATHNAME, {
          workspaceId: getShortId(currentWorkspace.id),
          desktopId: getShortId(createdDesktop.id),
        });
        setTimeout(() => {
          navigate({
            pathname: desktopPath,
            search: getQueryParamsFrom({
              ...queryParams,
              createDesktopModal: undefined,
            }),
          });
        }, NAVIGATE_TO_DESKTOP_AFTER_CREATE_DELAY);
      }
    },
    [navigate, queryParams, currentWorkspace],
  );

  return (
    <Modal onRequestClose={onRequestClose} {...props}>
      <ModalHeader onRequestClose={onRequestClose}>
        <FormattedMessage id={DesktopTranslation.createModalHeader} />
      </ModalHeader>
      <CreateDesktopForm
        onDone={onDone}
        onRequestClose={onRequestClose}
        onlyInternalDesktopCreation={onlyInternalDesktopCreation}
      />
    </Modal>
  );
};
