import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  ActivateWorkspaceInputType,
  RoleInCompany,
} from '../../../Workspace.types';
import { WorkspaceTranslation } from '../../../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../shared/components/Button/Button';
import { FormInput } from '../../../../../shared/components/Input';
import { StyledIdentifierContainer } from './RegisterEnterpriseWorkspaceForm.styled';
import { FormSelect } from '../../../../../shared/components/Select/FormSelect';
import { GlobalTranslation } from '../../../../Intl/i18n/i18n.types';
import { RegisterWorkspaceFormSubmitHandler } from '../ActivateWorkspaceForm.types';
import { appEnv } from '../../../../../appEnv';
import { FormInputWithText } from '../../../../../shared/components/InputWithText';

const COMPANY_SIZES = ['1-10', '10-50', '50-300', '300+'];
const COMPANY_ROLES = [
  RoleInCompany.Director,
  RoleInCompany.SalesAndMarketing,
  RoleInCompany.Technology,
  RoleInCompany.HumanResources,
  RoleInCompany.Other,
];

const RegisterEnterpriseWorkspaceFormSchema: Yup.Schema<ActivateWorkspaceInputType> = Yup.object().shape(
  {
    name: Yup.string().required(WorkspaceTranslation.formNameRequiredError),
    identifier: Yup.string().required(
      WorkspaceTranslation.formIdentifierRequiredError,
    ),
    companySize: Yup.string(),
    role: Yup.mixed().oneOf(['', ...COMPANY_ROLES]),
  },
);

interface RegisterEnterpriseWorkspaceFormProps {
  onSubmit: RegisterWorkspaceFormSubmitHandler;
}

const FormFields = styled.div`
  & > div ~ div {
    margin-top: 1.625rem;
  }
`;

const FormControls = styled.div`
  margin-top: 3.125rem;
`;

const INITIAL_VALUES: ActivateWorkspaceInputType = {
  name: '',
  identifier: '',
  companySize: '',
  role: '',
};

export const RegisterEnterpriseWorkspaceForm: FC<RegisterEnterpriseWorkspaceFormProps> = ({
  onSubmit,
}) => {
  const intl = useIntl();
  const workspaceDomain = `.${appEnv.WORKSPACE_DOMAIN}`;

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={RegisterEnterpriseWorkspaceFormSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form data-testid="workspace-form">
          <FormFields>
            <Field
              name="name"
              type="text"
              data-testid="workspace-form-name-input"
              label={intl.formatMessage({
                id: WorkspaceTranslation.formNameLabel,
              })}
              component={FormInput}
              autoFocus
            />
            <StyledIdentifierContainer>
              <Field
                name="identifier"
                type="text"
                data-testid="workspace-form-identifier-input"
                label={
                  <FormattedHTMLMessage
                    id={WorkspaceTranslation.formIdentifierLabel}
                    values={{
                      tellMoreLink: appEnv.SUPPORT_WORKSPACE_URL_INFO_URL,
                    }}
                  />
                }
                text={workspaceDomain}
                component={FormInputWithText}
              />
            </StyledIdentifierContainer>
            <Field
              name="companySize"
              type="text"
              data-testid="workspace-form-company-size-input"
              label={intl.formatMessage({
                id: WorkspaceTranslation.formCompanySizeLabel,
              })}
              component={FormSelect}>
              <option value="" disabled>
                {intl.formatMessage({
                  id: GlobalTranslation.labelSelect,
                })}
              </option>
              {COMPANY_SIZES.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Field>
            <Field
              name="role"
              type="text"
              data-testid="workspace-form-role-input"
              label={intl.formatMessage({
                id: WorkspaceTranslation.formRoleLabel,
              })}
              component={FormSelect}>
              <option value="" disabled>
                {intl.formatMessage({
                  id: GlobalTranslation.labelSelect,
                })}
              </option>
              {COMPANY_ROLES.map(role => (
                <option key={role} value={role}>
                  {intl.formatMessage({
                    id:
                      WorkspaceTranslation[
                        `companyRole${role}` as keyof typeof WorkspaceTranslation
                      ],
                  })}
                </option>
              ))}
            </Field>
          </FormFields>
          <FormControls>
            <Button
              data-testid="workspace-form-submit-button"
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={!isValid || isSubmitting || !dirty}>
              <FormattedMessage id={WorkspaceTranslation.formContinueButton} />
            </Button>
          </FormControls>
        </Form>
      )}
    </Formik>
  );
};
