import { database } from '../../../Database';
import { DesktopsTableType } from '../../../Database/DesktopTable/DesktopsTable';
import { DesktopAccessType } from '../Desktop/types/Desktop.types';
import { useAccessActions } from '../../../Access/data/Access.actions';
import { addRelatedDesktopsToAccountsIDDB } from '../../../Account/data/DesktopAccounts/operations/DesktopAccountIDDB.operations';
import { addRelatedAccountsToDesktopsIDDB } from './operations/AccountDesktop.operations';

export const useAccountDesktopsActions = () => {
  const { createAccess } = useAccessActions();

  const bulkPutAccountDesktopsToIDDB = async (
    desktops: DesktopsTableType[],
  ) => {
    try {
      database.desktops.bulkPut(desktops);
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountToDesktop = async (
    accountId: string,
    desktopId: string,
    accessType?: DesktopAccessType,
  ) => {
    try {
      await createAccess(desktopId, {
        accessType: accessType || DesktopAccessType.EditLink,
        account: accountId,
      });

      return Promise.allSettled([
        addRelatedDesktopsToAccountsIDDB([accountId], [desktopId]),
        addRelatedAccountsToDesktopsIDDB([desktopId], [accountId]),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  return { addAccountToDesktop, bulkPutAccountDesktopsToIDDB };
};
