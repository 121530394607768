import React, {
  FC,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  matchPath,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useAccountPermissionsForWorkspaceQuery,
  useEvictAccountWorkspace,
  useRedirectWorkspace,
  useWorkspacesQuery,
} from '../Workspace.hooks';
import { extractNodes } from '../../../shared/api/api.utils';
import { useAuthService, useCurrentAccount } from '../../Auth/Auth.hooks';
import {
  WorkspaceContext,
  WorkspaceStateContext,
  WorkspaceStateContextType,
} from '../Workspace.context';
import {
  WorkspaceApiType,
  WorkspacePermissionsApiType,
} from '../Workspace.types';
import {
  BILLING_PATHNAME,
  BILLING_SLUG,
} from '../../Billing/Billing.constants';
import { useIPad, useMobile, useQueryParams } from '../../../shared/hooks';
import { getQueryParamsFrom } from '../../../shared/utils/url.utils';
import { MfaModal } from '../../MFA/MfaModal';
import {
  ABOUT_TEAM_PATHNAME,
  ABOUT_TEAM_SLUG,
  ABOUT_YOU_PATHNAME,
  ABOUT_YOU_SLUG,
  ACTIVATE_WORKSPACE_PATHNAME,
  ACTIVATE_WORKSPACE_SLUG,
  ADD_CONTENT_PATHNAME,
  ADD_CONTENT_SLUG,
  CHOOSE_FEATURE_PATHNAME,
  CHOOSE_FEATURE_SLUG,
  INSTALL_PLUGIN_PATHNAME,
  INSTALL_PLUGIN_SLUG,
  INVITE_USERS_PATHNAME,
  INVITE_USERS_SLUG,
  ONBOARDING_END_PATHNAME,
  ONBOARDING_END_SLUG,
  OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  USE_CASE_PATHNAME,
  USE_CASE_SLUG,
} from '../../Onboarding/Onboarding.constants';
import { AppShellSkeleton } from '../../AppRoot/AppShellSkeleton';
import {
  clearTriggerMfaVerification,
  isMfaRequiredBasedOnAPIErrors,
  rememberMfaIsRequired,
} from '../../Auth/Auth.utils';
import { useCookieStorage } from '../../../shared/utils/storage';
import { useExternalUrlDropWrapperProps } from '../../Drag/ExternalUrlDropZone';
import { AccountApiType } from '../../User/User.types';
import { useOnClickOutside } from '../../../shared/hooks/user-actions';
import { SignUpFlow, SignUpStep } from '../../Onboarding/Onboarding.types';
import { useDebounce } from 'use-debounce';
import { EmptyWorkspacesPage } from './EmptyWorkspacesPage/EmptyWorkspacesPage';
import { displayInstallPluginToast } from '../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { WorkspaceProviders } from '../WorkspaceProviders';
import { GlobalSearchProvider } from '../../GlobalSearch/GlobalSearch.provider';
import { Route, Routes } from '../../ErrorInterceptor/router.injectors';
import { ActivateWorkspacePage } from '../../Onboarding/OnboardingPage';
import { StripeElementsProvider } from '../../Billing/StripeElements';
import { BillingPage } from '../../Onboarding/OnboardingPage/BillingPage';
import { UseCasePage } from '../../Onboarding/OnboardingPage/UseCasePage';
import { AboutYouPage } from '../../Onboarding/OnboardingPage/AboutYouPage';
import { AboutTeamPage } from '../../Onboarding/OnboardingPage/AboutTeamPage';
import { WorkspaceAdmin } from '../WorkspaceAdmin';
import { ChooseFeaturePage } from '../../Onboarding/OnboardingPage/ChooseFeaturePage';
import { AddContentPage } from '../../Onboarding/OnboardingPage/AddContentPage';
import { InviteUsersPage } from '../../Onboarding/OnboardingPage/InviteUsersPage';
import { InstallPluginPage } from '../../Onboarding/OnboardingPage/InstallPluginPage';
import { EndPage } from '../../Onboarding/OnboardingPage/EndPage';
import { UpgradeFreeModal } from '../../Billing/BillingModals/UpgradeFreeModal';
import { SuccessUpgradeModal } from '../../Billing/BillingModals/SuccessUpgradeModal';
import { ScheduleConferenceModal } from '../../Conference/ConferenceSchedule/ScheduleConferenceModal';
import { DraggedPreview } from '../../Drag/DraggedPreview';
import { InviteUserModal } from '../../User/UserModals/InviteUserModal';
import { OnboardingQuickTourModal } from './OnboardingQuickTourModal';
import { StyledWorkspaceSwitcher } from './WorkspaceSwitcher.styled';
import { billingIsOk } from '../../Billing/Billing.utils';
import { WorkspaceAdminMenuDrawer } from '../WorkspaceAdminMenuDrawer';
import { Segment } from '../../Segment/Segment';
import {
  WORKSPACE_PATHNAME,
  WORKSPACE_ROOT_PATHNAME,
} from '../Workspace.constants';
import { DESKTOP_ROOT_PATHNAME } from '../../Desktop/Desktop.constants';
import type { DesktopApiType } from '../../Desktop/data/Desktop/types/Desktop.types';
import { getShortId } from '../../../shared/utils/id';
import { WorkspaceAccountGroupIdentity } from '../../User/User.constants';

export const MOBILE_NAVIGATION_STATE_DEBOUNCE_TIME = 60;

export const WorkspaceSwitcher: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { settingsModal } = queryParams;

  const authService = useAuthService();

  const { account } = useCurrentAccount();
  const location = useLocation();

  const { data, loading, refetch } = useWorkspacesQuery({
    skip: !account,
    variables: {
      id: account!.identityId,
    },
  });

  const workspaceSubdomainRedirectRef = useRef(false);

  const identityWorkspaces = useMemo(
    () =>
      data
        ? extractNodes(data.accountIdentity.myAccounts)
            .map(account => extractNodes(account.workspaces))
            .flat()
        : [],
    [data],
  );

  const { workspaceId } = useParams<{ workspaceId?: string }>();

  const accountWorkspace = useMemo(
    () =>
      identityWorkspaces.find(
        ({ workspace }) => getShortId(workspace.id) === workspaceId,
      ),
    [workspaceId, identityWorkspaces],
  );

  const workspace = accountWorkspace?.workspace;

  const workspaceInfo = useMemo(() => {
    return (
      (workspace?.id &&
        account?.workspaceInfo?.find(
          info => info.id === getShortId(workspace?.id),
        )) ||
      null
    );
  }, [workspace?.id, account?.workspaceInfo]);

  const isOwnerOfCurrentWorkspace =
    workspaceInfo?.role === WorkspaceAccountGroupIdentity.OWNER;

  const workspaceAccountMap: { [key: string]: AccountApiType } = useMemo(
    () =>
      data
        ? extractNodes(data.accountIdentity.myAccounts).reduce((acc, curr) => {
            const { workspaces, ...workspaceAccount } = curr;

            return {
              ...acc,
              ...(extractNodes(workspaces)?.reduce(
                (workspacesMap, { workspace }) => {
                  return { ...workspacesMap, [workspace.id]: workspaceAccount };
                },
                {},
              ) || {}),
            };
          }, {})
        : {},
    [data],
  );

  const mainAccount = useMemo(() => {
    return data
      ? extractNodes(data.accountIdentity.myAccounts).find(
          account => account.isMainAccount,
        )
      : null;
  }, [data]);

  useRedirectWorkspace(identityWorkspaces, workspace);

  const lastOpenedDesktopRef = useRef<DesktopApiType | null>(null);
  const setLastOpenedDesktop = useCallback((value: DesktopApiType) => {
    lastOpenedDesktopRef.current = value;
  }, []);

  const lastOpenedPathRef = useRef<string | null>(null);
  const setLastOpenedPath = useCallback((value: string) => {
    lastOpenedPathRef.current = value;
  }, []);

  const lastOpenedAdminPathRef = useRef(new Map());
  const setLastOpenedAdminPath = useCallback(
    (value: string) => {
      lastOpenedAdminPathRef.current.set(
        getShortId(workspace?.id || ''),
        value,
      );
    },
    [workspace?.id],
  );

  const blockedByMfa =
    accountWorkspace &&
    accountWorkspace.isMfaRequired &&
    !accountWorkspace.isMfaValid;
  const accountWorkspaceId = accountWorkspace?.id;
  const mfaRequiredBasedOnAPIErrors = isMfaRequiredBasedOnAPIErrors();
  const evictAccountWorkspace = useEvictAccountWorkspace();

  const [isMfaTokenInvalid, setMfaTokenInvalid] = useState(false);

  const waitingForMfa = !identityWorkspaces.length || blockedByMfa;
  const { data: permissions, refetch: refetchPermissions } =
    useAccountPermissionsForWorkspaceQuery({
      skip: !workspaceId || waitingForMfa,
      variables: {
        workspace: '/workspaces/' + workspaceId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const canSeeInstallPluginToast =
    permissions?.workspacePermission?.canSeeInstallPluginToast;
  useEffect(() => {
    if (workspace && !loading && canSeeInstallPluginToast) {
      displayInstallPluginToast();
    }
  }, [canSeeInstallPluginToast, loading, workspace]);

  useEffect(() => {
    if ((blockedByMfa || mfaRequiredBasedOnAPIErrors) && accountWorkspaceId) {
      authService.tryMfaSilently().then(success => {
        clearTriggerMfaVerification();

        if (success) {
          rememberMfaIsRequired();
          evictAccountWorkspace(accountWorkspaceId);
        } else {
          refetch();
          setMfaTokenInvalid(true);
        }
      });
    }
  }, [
    accountWorkspaceId,
    authService,
    blockedByMfa,
    evictAccountWorkspace,
    mfaRequiredBasedOnAPIErrors,
    refetch,
  ]);

  const [privateDesktopsOpen, setPrivateDesktopsOpen] = useState(true);
  const [internalDesktopsOpen, setInternalDesktopsOpen] = useState(true);
  const [sharedDesktopsOpen, setSharedDesktopsOpen] = useState(true);
  const [navExpanded, toggleNav] = useState(true);
  const [navExpandedOnHover, toggleNavOnHover] = useState(false);

  const [mobileNavigationSidebarIsOpen, setMobileNavigationSidebarIsOpen] =
    useState(false);
  const [debouncedMobileNavigationSidebarIsOpen] = useDebounce(
    mobileNavigationSidebarIsOpen,
    MOBILE_NAVIGATION_STATE_DEBOUNCE_TIME,
  );

  const workspaceState: WorkspaceStateContextType = useMemo(
    () => ({
      privateDesktopsOpen,
      togglePrivateDesktops: () => {
        setPrivateDesktopsOpen(prevState => !prevState);
      },
      internalDesktopsOpen,
      toggleInternalDesktops: () => {
        setInternalDesktopsOpen(prevState => !prevState);
      },
      sharedDesktopsOpen,
      toggleSharedDesktops: () => {
        setSharedDesktopsOpen(prevState => !prevState);
      },
      navExpanded,
      toggleNav,
      navExpandedOnHover,
      toggleNavOnHover,
      canBeDisplayedInNav: () => {
        if (navExpandedOnHover && !navExpanded) {
          return true;
        }

        return navExpanded;
      },
      showTextLabel: navExpandedOnHover || navExpanded,
    }),
    [
      internalDesktopsOpen,
      navExpandedOnHover,
      navExpanded,
      privateDesktopsOpen,
      sharedDesktopsOpen,
    ],
  );

  const segmentSwitcherRef: Ref<any> = useRef();
  const AppTopBarRef: Ref<any> = useRef();
  const workspaceNavigatorRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();
  const isIpad = useIPad();
  const isMobileOrIpad = useMemo(() => isMobile || isIpad, [isIpad, isMobile]);
  useOnClickOutside({
    refs: [segmentSwitcherRef, AppTopBarRef, workspaceNavigatorRef],
    action: () => {
      setMobileNavigationSidebarIsOpen(false);
    },
    ignoreWhen: !isMobileOrIpad,
  });

  const dragWrapperProps = useExternalUrlDropWrapperProps();

  const [openQuickStartGuide] = useCookieStorage(
    OPEN_QUICK_START_GUIDE_STORAGE_KEY,
  );

  const wasQuickStartGuideOpened = useRef(false);

  useEffect(() => {
    const canOpenQuickStartGuide =
      openQuickStartGuide === 'true' || !openQuickStartGuide;

    const isWorkspaceRootUrl = matchPath(
      WORKSPACE_ROOT_PATHNAME,
      location.pathname,
    );
    const isWorkspaceUrl = matchPath(WORKSPACE_PATHNAME, location.pathname);
    const isDesktopRootUrl = matchPath(
      DESKTOP_ROOT_PATHNAME,
      location.pathname,
    );

    const isCompletePageUrl = matchPath(
      ONBOARDING_END_PATHNAME,
      location.pathname,
    );

    const isInstallPagePluginUrl = matchPath(
      INSTALL_PLUGIN_PATHNAME,
      location.pathname,
    );

    if (
      !isCompletePageUrl &&
      !isWorkspaceRootUrl &&
      !isWorkspaceUrl &&
      !isDesktopRootUrl &&
      !isInstallPagePluginUrl &&
      account?.currentStep === SignUpStep.completed &&
      canOpenQuickStartGuide &&
      !queryParams?.openOnboardingQuickTour &&
      !wasQuickStartGuideOpened.current
    ) {
      wasQuickStartGuideOpened.current = true;

      navigate(
        {
          search: getQueryParamsFrom({
            ...queryParams,
            openOnboardingQuickTour: true,
          }),
        },
        { replace: true },
      );
    }
  }, [
    account?.currentStep,
    navigate,
    location.pathname,
    openQuickStartGuide,
    queryParams,
  ]);

  if (
    !identityWorkspaces.length &&
    !loading &&
    account?.currentFlow !== SignUpFlow.FREE
  ) {
    return <EmptyWorkspacesPage />;
  }

  if (
    workspace &&
    account?.currentStep === SignUpStep.billing &&
    isOwnerOfCurrentWorkspace &&
    permissions?.workspacePermission?.canViewBillingPage
  ) {
    const billingMatch = matchPath(BILLING_PATHNAME, location.pathname);
    if (!billingMatch) {
      const billingPath = generatePath(BILLING_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={billingPath} />;
    }
  }

  if (
    workspace &&
    account?.currentStep === SignUpStep.use_case &&
    isOwnerOfCurrentWorkspace
  ) {
    const useCaseMatch = matchPath(USE_CASE_PATHNAME, location.pathname);
    if (!useCaseMatch) {
      const useCasePath = generatePath(USE_CASE_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={useCasePath} />;
    }
  }

  if (
    workspace &&
    account?.currentStep === SignUpStep.about_you &&
    isOwnerOfCurrentWorkspace
  ) {
    const aboutYouMatch = matchPath(ABOUT_YOU_PATHNAME, location.pathname);
    if (!aboutYouMatch) {
      const aboutYouPath = generatePath(ABOUT_YOU_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={aboutYouPath} />;
    }
  }

  if (
    workspace &&
    account?.currentStep === SignUpStep.about_team &&
    isOwnerOfCurrentWorkspace
  ) {
    const aboutTeamMatch = matchPath(ABOUT_TEAM_PATHNAME, location.pathname);
    if (!aboutTeamMatch) {
      const aboutTeamPath = generatePath(ABOUT_TEAM_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={aboutTeamPath} />;
    }
  }

  if (workspace && account?.currentStep === SignUpStep.activate_workspace) {
    const activateWorkspaceMatch = matchPath(
      ACTIVATE_WORKSPACE_PATHNAME,
      location.pathname,
    );
    if (!activateWorkspaceMatch) {
      const activateWorkspacePath = generatePath(ACTIVATE_WORKSPACE_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={activateWorkspacePath} />;
    }
  }

  if (
    workspace &&
    account?.currentStep === SignUpStep.choose_feature &&
    isOwnerOfCurrentWorkspace
  ) {
    const chooseFeatureMatch = matchPath(
      CHOOSE_FEATURE_PATHNAME,
      location.pathname,
    );
    if (!chooseFeatureMatch) {
      const chooseFeaturePath = generatePath(CHOOSE_FEATURE_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={chooseFeaturePath} />;
    }
  }

  if (workspace && account?.currentStep === SignUpStep.add_content) {
    const addContentMatch = matchPath(ADD_CONTENT_PATHNAME, location.pathname);
    if (!addContentMatch) {
      const addContentPath = generatePath(ADD_CONTENT_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={addContentPath} />;
    }
  }

  if (workspace && account?.currentStep === SignUpStep.invite_users) {
    const inviteUsersMatch = matchPath(
      INVITE_USERS_PATHNAME,
      location.pathname,
    );
    if (!inviteUsersMatch) {
      const inviteUsersPath = generatePath(INVITE_USERS_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={inviteUsersPath} />;
    }
  }

  if (workspace && account?.currentStep === SignUpStep.install_extension) {
    const installPluginMatch = matchPath(
      INSTALL_PLUGIN_PATHNAME,
      location.pathname,
    );
    if (!installPluginMatch) {
      const installPluginPath = generatePath(INSTALL_PLUGIN_PATHNAME, {
        workspaceId: getShortId(workspace.id),
      });
      return <Navigate to={installPluginPath} />;
    }
  }

  if (workspace && !workspaceSubdomainRedirectRef.current) {
    if (workspace.uri === window.location.host) {
      window.location.href = `${window.location.protocol}//${
        process.env.DEFAULT_APP_HOST
      }/workspace/${getShortId(workspace.id)}`;
    } else if (process.env.DEFAULT_APP_HOST !== window.location.host) {
      window.location.href = `${window.location.protocol}//${process.env.DEFAULT_APP_HOST}${window.location.pathname}`;
    }
    workspaceSubdomainRedirectRef.current = true;
  }

  if (blockedByMfa && !isMfaTokenInvalid) {
    return <AppShellSkeleton />;
  }

  if (
    identityWorkspaces &&
    accountWorkspace &&
    mainAccount &&
    blockedByMfa &&
    isMfaTokenInvalid
  ) {
    return (
      <MfaModal
        accountWorkspaces={identityWorkspaces}
        accountWorkspace={accountWorkspace}
        mainAccount={mainAccount}
      />
    );
  }

  if ((!workspace && loading) || !permissions || (!workspace && workspaceId)) {
    return <AppShellSkeleton />;
  }

  return (
    <WorkspaceContext.Provider
      value={{
        workspace: workspace as WorkspaceApiType,
        workspaceInfo,
        refetchWorkspaces: refetch,
        permissions:
          (permissions?.workspacePermission as WorkspacePermissionsApiType) ||
          {},
        permissionsLoaded: !!permissions,
        refetchPermissions,
        lastOpenedDesktop: lastOpenedDesktopRef.current,
        setLastOpenedDesktop: setLastOpenedDesktop as (
          desktop: DesktopApiType | null,
        ) => void,
        lastOpenedPath: lastOpenedPathRef.current,
        setLastOpenedPath: setLastOpenedPath as (path: string | null) => void,

        lastOpenedAdminPath: lastOpenedAdminPathRef.current,
        setLastOpenedAdminPath: setLastOpenedAdminPath as (
          path: string,
        ) => void,

        workspaceAccountMap: workspaceAccountMap,
        mobileNavigationSidebarIsOpen: debouncedMobileNavigationSidebarIsOpen,
        mobileNavigationSidebarToggle: () => {
          setMobileNavigationSidebarIsOpen(!mobileNavigationSidebarIsOpen);
        },
        mobileNavigationSidebarClose: () => {
          if (mobileNavigationSidebarIsOpen) {
            setTimeout(() => {
              setMobileNavigationSidebarIsOpen(false);
            }, 0);
          }
        },
        identityWorkspaces,
      }}>
      <WorkspaceStateContext.Provider value={workspaceState}>
        <WorkspaceProviders>
          <GlobalSearchProvider>
            <Routes>
              {workspace && (
                <Route
                  path={ACTIVATE_WORKSPACE_SLUG}
                  element={<ActivateWorkspacePage />}
                />
              )}
              {workspace && (
                <Route
                  path={BILLING_SLUG}
                  element={
                    <StripeElementsProvider>
                      <BillingPage />
                    </StripeElementsProvider>
                  }
                />
              )}
              {workspace && (
                <Route path={USE_CASE_SLUG} element={<UseCasePage />} />
              )}
              {workspace && (
                <Route path={ABOUT_YOU_SLUG} element={<AboutYouPage />} />
              )}
              {workspace && (
                <Route path={ABOUT_TEAM_SLUG} element={<AboutTeamPage />} />
              )}
              {workspace && (
                <Route path={`/admin/*`} element={<WorkspaceAdmin />} />
              )}
              {workspace && (
                <Route
                  path={CHOOSE_FEATURE_SLUG}
                  element={<ChooseFeaturePage />}
                />
              )}
              {workspace && (
                <Route path={ADD_CONTENT_SLUG} element={<AddContentPage />} />
              )}
              {workspace && (
                <Route path={INVITE_USERS_SLUG} element={<InviteUsersPage />} />
              )}
              {workspace && (
                <Route
                  path={INSTALL_PLUGIN_SLUG}
                  element={<InstallPluginPage />}
                />
              )}
              {workspace && (
                <Route path={ONBOARDING_END_SLUG} element={<EndPage />} />
              )}
              <Route
                path={'*'}
                element={
                  <StyledWorkspaceSwitcher
                    compensateToolbarHeight={!workspace && !loading}
                    {...dragWrapperProps}
                    data-testid="workspaces-bar"
                    lockDownMode={
                      !billingIsOk(workspace) && !workspace?.isUpgrading
                    }>
                    <Segment
                      workspacesLoading={loading}
                      data-testid="segment"
                    />
                    {workspace && (
                      <>
                        {settingsModal && (
                          <WorkspaceAdminMenuDrawer
                            onRequestClose={() => {
                              navigate({
                                search: getQueryParamsFrom({
                                  ...queryParams,
                                  settingsModal: undefined,
                                }),
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </StyledWorkspaceSwitcher>
                }
              />
            </Routes>
          </GlobalSearchProvider>
        </WorkspaceProviders>
        {workspace?.isUpgrading &&
          (permissions?.workspacePermission?.canUpgrade ||
            permissions?.workspacePermission?.canDowngrade) && (
            <UpgradeFreeModal />
          )}
        {queryParams.upgradeSuccess && <SuccessUpgradeModal />}
        {queryParams?.openOnboardingQuickTour && <OnboardingQuickTourModal />}
        {queryParams?.createScheduleConferenceOpened && (
          <ScheduleConferenceModal />
        )}
        <DraggedPreview />
        {queryParams?.inviteUsersFromPeoplePane && (
          <InviteUserModal
            visible={true}
            onRequestClose={() =>
              navigate({
                search: getQueryParamsFrom({
                  ...queryParams,
                  inviteUsersFromPeoplePane: undefined,
                }),
              })
            }
          />
        )}
      </WorkspaceStateContext.Provider>
    </WorkspaceContext.Provider>
  );
};
