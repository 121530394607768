import React, { FC, useCallback } from 'react';
import { CustomAppForm } from '../CustomAppForm';
import {
  useCustomAppStoreApp,
  useEditCustomAppMutation,
} from '../../../AppStore.hooks';
import { AppStoreAppFormValues } from '../../../AppStore.types';
import { showToastSuccessMessage } from '../../../../../shared/components/Toast';
import { AppStoreTranslation } from '../../../i18n';
import { useQueryParams } from '../../../../../shared/hooks';
import { Spinner } from '../../../../../shared/components/Spinner';
import { useNavigate } from 'react-router-dom';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import { useAppStoreActions } from '../../../Data/AppStore.actions';

interface EditCustomAppProps {
  onDone: () => void;
}

export const EditCustomApp: FC<EditCustomAppProps> = ({ onDone }) => {
  const [editCustomAppMutation] = useEditCustomAppMutation();
  const queryParams = useQueryParams();
  const { editAppId } = queryParams;
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace?.id;
  const { editCustomApp } = useAppStoreActions();

  const { data, loading, refetch } = useCustomAppStoreApp({
    skip: !editAppId || !workspaceId,
    variables: { id: '/apps/' + editAppId, workspace: workspaceId },
    fetchPolicy: 'cache-and-network',
  });

  const handleSubmit = useCallback(
    (values: AppStoreAppFormValues) => {
      const { logo } = values;
      return editCustomApp({
        input: {
          id: '/apps/' + editAppId,
          ...values,
          logo: logo ? logo : null,
        },
      }).then(() => {
        showToastSuccessMessage(
          AppStoreTranslation.editCustomAppSuccessMessage,
        );
        refetch();
        onDone();
      });
    },
    [editCustomApp, editAppId, refetch, onDone],
  );

  if (!editAppId || (!loading && !data?.workspaceApp)) {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editAppId: undefined,
      }),
    });
    return null;
  }

  if (loading) {
    return <Spinner containerHeight={200} />;
  }

  return (
    <>
      <CustomAppForm app={data?.workspaceApp} onSubmit={handleSubmit} />
    </>
  );
};
