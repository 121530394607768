/**
 * This class provides functions that will modify the database table.
 * Any functionality that somehow modifies the table shall be found here.
 */

import type { ChatMessageInternalType } from '../../../Chat.types';
import type { IndexableType } from 'dexie';
import { database } from '../../../../Database/Database';
import {
  ChatMessagesTableType,
  ChatMessageToChatMessageTableRow,
} from '../../../../Database/ChatMessagesTable/ChatMessagesTable';
import { captureException } from '@sentry/react';

/**
 * Get a chat message by it's ID
 */
export const getOne = async (chatMessageId: string) => {
  try {
    return await database.chatMessages.get(chatMessageId);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Add or update existing chat message to the database
 * When adding multiple chat messages use "bulkPut" instead.
 */
export const putOne = async (chatMessage: ChatMessageInternalType) => {
  try {
    const tableTypeMessage = ChatMessageToChatMessageTableRow(chatMessage);
    await database.chatMessages.put(tableTypeMessage);

    return tableTypeMessage;
  } catch (error) {
    captureException(error);
  }
};

/**
 * Add or update existing chat messages array to the database.
 * When adding single chat message use "putOne" instead.
 */
export const bulkPut = async (chatMessages: ChatMessageInternalType[]) => {
  try {
    const messageRowsArray = chatMessages.map(chatMessage =>
      ChatMessageToChatMessageTableRow(chatMessage),
    );

    await database.chatMessages.bulkPut(messageRowsArray);
  } catch (error) {
    captureException(error);
  }
};

export const bulkUpdate = async (
  chatMessageIds: string[],
  updater: (
    value: ChatMessagesTableType,
    ref: { value: ChatMessagesTableType },
  ) => void,
) => {
  try {
    await database.chatMessages
      .where('id')
      .anyOf(chatMessageIds)
      .modify(updater);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Update a chat message in the database
 */
export const updateOne = async (
  chatMessageId: string,
  chatMessage: ChatMessageInternalType,
) => {
  try {
    const updatedMessage = await database.chatMessages.update(
      chatMessageId,
      ChatMessageToChatMessageTableRow(chatMessage),
    );
    return updatedMessage || null;
  } catch (error) {
    captureException(error);
  }
};

/**
 * Delete a chat message from the database
 */
export const deleteOne = async (chatMessageId: string) => {
  try {
    await database.chatMessages.delete(chatMessageId);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Get bulk chat messages array.
 */
export const bulkGet = async (chatMessages: IndexableType[]) => {
  try {
    await database.chatMessages.bulkGet(chatMessages);
  } catch (error) {
    captureException(error);
  }
};

/**
 * Clear chat messages table by conversation id.
 */
export const clearDB = async (conversationId: string) => {
  try {
    await database.chatMessages
      .where('conversationId')
      .equals(conversationId)
      .delete();
  } catch (error) {
    captureException(error);
  }
};

/**
 * Get bulk chat messages array by parrent.
 */
export const bulkGetByParentId = async (chatMessagId: string) => {
  try {
    return await database.chatMessages
      .where('parentId')
      .equals(chatMessagId)
      .toArray();
  } catch (error) {
    captureException(error);
  }
};

export const bulkDeleteChatMessagesFromIDDB = async (keys: string[]) => {
  return await database.chatMessages.bulkDelete(keys);
};

export const getMessageByConversationId = async (
  conversationId: string,
  limit = Infinity,
  excludeThreadMessages = false,
) => {
  return await database.chatMessages
    .where('conversationId')
    .equals(conversationId)
    .limit(limit)
    .filter(msg => !excludeThreadMessages || !msg.parentId)
    .sortBy('createdAt');
};
