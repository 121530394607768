import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import {
  CustomIntegrationField,
  CustomIntegrationFormBody,
  CustomIntegrationFormControls,
} from '../CustomIntegrationView.styled';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../../../../shared/components/Button/Button';
import { CreateIntegrationFormValues } from '../../../../AppIntegrations.types';
import { FormInput } from '../../../../../../shared/components/Input';
import { CustomIntegrationBotFields } from '../CustomIntegrationBot/CustomIntegrationBotFields';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { AppIntegrationsTranslation } from '../../../../i18n';

interface CreateIntegrationFormProps {
  onSubmit: (values: CreateIntegrationFormValues) => void;
  onCancel: () => void;
}

export const CreateIntegrationForm: FC<CreateIntegrationFormProps> = ({
  onCancel,
  onSubmit,
}) => {
  const intl = useIntl();

  const CreateIntegrationFormSchema: Schema<CreateIntegrationFormValues> = Yup.object().shape(
    {
      integrationName: Yup.string().required(
        AppIntegrationsTranslation.appCustomIntegrationNameRequired,
      ),
      botName: Yup.string().required(
        AppIntegrationsTranslation.appCustomIntegrationBotNameRequired,
      ),
      botImage: Yup.string(),
    },
  );

  const initialValues: CreateIntegrationFormValues = {
    integrationName: '',
    botName: '',
    botImage: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CreateIntegrationFormSchema}>
      {({ isSubmitting, setFieldValue, dirty, errors, touched }) => (
        <Form data-testid="new-integration-form">
          <CustomIntegrationFormBody>
            <CustomIntegrationField>
              <Field
                name="integrationName"
                type="text"
                data-testid="integration-name-input"
                placeholder={intl.formatMessage({
                  id:
                    AppIntegrationsTranslation.appCustomIntegrationNamePlaceholder,
                })}
                label={intl.formatMessage({
                  id: AppIntegrationsTranslation.appCustomIntegrationNameLabel,
                })}
                component={FormInput}
              />
            </CustomIntegrationField>
            <CustomIntegrationBotFields
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
          </CustomIntegrationFormBody>

          <CustomIntegrationFormControls>
            <Button
              type="button"
              mode={ButtonMode.secondary}
              size={ButtonSize.md}
              className="cancel-button"
              onClick={onCancel}
              data-testid="cancel-button">
              <FormattedMessage
                id={AppIntegrationsTranslation.appCustomIntegrationCancelButton}
              />
            </Button>
            <Button
              type="submit"
              mode={ButtonMode.primary}
              size={ButtonSize.md}
              disabled={!dirty || isSubmitting}
              data-testid="submit-button">
              <FormattedMessage
                id={AppIntegrationsTranslation.appCustomIntegrationSaveButton}
              />
            </Button>
          </CustomIntegrationFormControls>
        </Form>
      )}
    </Formik>
  );
};
