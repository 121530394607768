import React, { useCallback } from 'react';
import { Modal, ModalHeader } from '../../../../../shared/components/Modal';
import { FormattedMessage } from 'react-intl';
import { LinkTranslation } from '../../../i18n';
import { EditLink } from '../../../LinkForm';
import { EDIT_LINK_MODAL_Z_INDEX } from '../../../../Desktop/Desktop.constants';
import { useNavigate } from 'react-router';
import { getQueryParamsFrom } from '../../../../../shared/utils/url.utils';
import { useQueryParams } from '../../../../../shared/hooks';

export const EditLinkModal = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const onRequestCloseHandler = useCallback(() => {
    navigate({
      search: getQueryParamsFrom({
        ...queryParams,
        editLink: undefined,
      }),
    });
  }, [navigate, queryParams]);

  return (
    <Modal
      visible={true}
      width={588}
      containerZIndex={EDIT_LINK_MODAL_Z_INDEX}
      onRequestClose={onRequestCloseHandler}>
      <ModalHeader onRequestClose={onRequestCloseHandler}>
        <FormattedMessage id={LinkTranslation.editLinkModalHeader} />
      </ModalHeader>
      <EditLink onDone={onRequestCloseHandler} />
    </Modal>
  );
};
