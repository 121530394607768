import styled from 'styled-components';

export const CreateAppIntegrationCredentialFormControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  button ~ button {
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  .cancel-button {
    background-color: ${p => p.theme.colors.Surface};
  }
`;

export const CreateAppIntegrationCredentialFormWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.m};
`;
