import styled, { css } from 'styled-components';
import { LINK_COLUMN_GAP, LINK_ROW_GAP } from '../../../LinksView.styled';

export const StyledLinksList = styled.div`
  position: relative;
  width: calc(100% - ${LINK_COLUMN_GAP} / 2);
`;

export const ListContainer = styled.div`
  display: grid;
  column-gap: 0;
  row-gap: ${LINK_ROW_GAP};
  grid-template-columns: repeat(auto-fill, 1fr);
`;

export const ListRowItem = styled.div<{
  type: 'folders' | 'links';
  columns: number;
}>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  padding-bottom: ${LINK_ROW_GAP};

  ${({ type }) =>
    type === 'links' &&
    css`
      column-gap: ${LINK_COLUMN_GAP};
    `}
`;
