import React, { FC, useCallback, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { ActivateWorkspace } from '../../../Workspace/WorkspaceForm';
import { OnboardingStep, OnboardingStepHeader } from '../OnboardingPage.styled';
import { generatePath, Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { OnboardingTranslation } from '../../i18n';
import { BILLING_PATHNAME } from '../../../Billing/Billing.constants';
import { WorkspaceApiType } from '../../../Workspace/Workspace.types';
import { IllustrationSections, StepsIllustration } from '../StepsIllustration';
import { useMobile } from '../../../../shared/hooks';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const ActivateWorkspacePage: FC = () => {
  const isMobile = useMobile();
  const [workspaceActivated, setWorkspaceActivated] =
    useState<WorkspaceApiType | null>(null);
  const { account, refetchAccountData } = useCurrentAccount();

  const [illustrationWorkspaceName, setIllustrationWorkspaceName] =
    useState('');

  const handleActivate = useCallback(
    (workspace: WorkspaceApiType) => {
      if (refetchAccountData) {
        refetchAccountData().then(() => {
          setWorkspaceActivated(workspace);
        });
      }
    },
    [refetchAccountData],
  );

  if (account?.createdWorkspace && workspaceActivated) {
    const path = generatePath(BILLING_PATHNAME, {
      workspaceId: getShortId(workspaceActivated.id),
    });
    return <Navigate to={path} />;
  }

  return (
    <>
      <HeadTitle
        translationId={OnboardingTranslation.onboardingCreateWorkspaceTitle}
      />
      <OnboardingPage
        horizontallyCentered
        after={
          isMobile
            ? null
            : () => (
                <StepsIllustration
                  companyName={illustrationWorkspaceName}
                  displayDesktopList={true}
                  displayIllustrationSections={[
                    IllustrationSections.apps,
                    IllustrationSections.links,
                    IllustrationSections.chat,
                  ]}
                />
              )
        }>
        <OnboardingStep data-testid="company-details-step">
          <OnboardingStepsCounter currentStep={account?.currentStep} />
          <OnboardingStepHeader data-testid="header">
            <FormattedMessage
              id={OnboardingTranslation.onboardingCreateWorkspaceHeading}
            />
          </OnboardingStepHeader>

          <ActivateWorkspace
            handleChangeWorkspaceName={setIllustrationWorkspaceName}
            onActivate={handleActivate}
          />
        </OnboardingStep>
      </OnboardingPage>
    </>
  );
};
