import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { UnshareVaultControls } from './UnshareVault.styled';
import { EncryptionTranslation } from '../i18n';
import {
  Button,
  ButtonMode,
  ButtonSize,
} from '../../../shared/components/Button/Button';
import {
  useCurrentAccountKeyset,
  useRemoveVaultAccessMutation,
  useVaultsListUpdate,
  useWorkspaceVaultById,
} from '../Encryption.hooks';
import {
  showToastGraphQLErrors,
  showToastSuccessMessage,
} from '../../../shared/components/Toast';
import { getVaultAccess, getVaultApp, getVaultItem } from '../Encryption.utils';
import { useCurrentWorkspace } from '../../Workspace/Workspace.hooks';
import { getHostname } from '../../Chat/ChatView/ConversationView/ChatMessage/RichMessage/RichMessage.utils';

interface UnshareVaultProps {
  vaultId: string;
  onUnshared?: (ids: string[]) => void;
  onCancelled?: () => void;
}

export const UnshareVault: FC<UnshareVaultProps> = ({
  vaultId,
  onUnshared,
  onCancelled,
}) => {
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;
  const { vault } = useWorkspaceVaultById(vaultId);
  const vaultItem = useMemo(() => {
    return vault && getVaultItem(vault);
  }, [vault]);
  const vaultApp = useMemo(() => {
    return getVaultApp(vault);
  }, [vault]);
  const vaultItemDomain = useMemo(() => {
    return vaultItem?.url && getHostname(vaultItem.url);
  }, [vaultItem]);

  const vaultAccess = vault && getVaultAccess(vault, keysetId);
  const vaultAccessShared = vaultAccess?.shared;
  const vaultAccessId = vaultAccess?.id;

  const [isUnsharing, setUnsharing] = useState(false);
  const { removeVault } = useVaultsListUpdate();

  const { workspace } = useCurrentWorkspace();
  const workspaceId = workspace.id;

  useEffect(() => {
    if ((!vaultAccessId || !vaultAccessShared) && onCancelled) {
      onCancelled();
    }
  }, [vaultAccessId, vaultAccessShared, onCancelled]);

  const [removeVaultAccessMutation] = useRemoveVaultAccessMutation();
  const deleteVaultAccess = () => {
    setUnsharing(true);
    return removeVaultAccessMutation({
      variables: {
        input: {
          id: vaultAccessId!,
          workspace: workspaceId,
        },
      },
    })
      .then(
        res => {
          if (res?.data) {
            showToastSuccessMessage(
              EncryptionTranslation.editVaultFormSuccessMessage,
            );
            removeVault(vault!.id);
            if (onUnshared) {
              onUnshared([vault!.id]);
            }
          }
        },
        e => {
          showToastGraphQLErrors(e.graphQLErrors);
        },
      )
      .catch(e => showToastGraphQLErrors(e))
      .finally(() => setUnsharing(false));
  };

  return (
    <>
      <FormattedHTMLMessage
        id={EncryptionTranslation.unshareVaultDescription}
        values={{ name: vaultApp?.name || vaultItemDomain }}
      />
      <UnshareVaultControls>
        <Button
          mode={ButtonMode.secondary}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={isUnsharing}
          onClick={onCancelled}
          data-testid="cancel-button">
          <FormattedMessage
            id={EncryptionTranslation.unshareVaultCancelButton}
          />
        </Button>
        <Button
          mode={ButtonMode.danger}
          size={ButtonSize.md}
          type="button"
          fullWidth
          disabled={isUnsharing}
          onClick={deleteVaultAccess}
          data-testid="delete-button">
          <FormattedMessage
            id={EncryptionTranslation.unshareVaultDeleteButton}
          />
        </Button>
      </UnshareVaultControls>
    </>
  );
};
