import styled from 'styled-components';
import {
  ModalContent,
  ModalHeaderTitle,
} from '../../../../shared/components/Modal';

export const ImportModalTitle = styled(ModalHeaderTitle)`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
`;

export const ImportModalContent = styled.div`
  padding: 0.625rem 1.5rem 0;
  min-height: 300px;
`;

export const ImportModalDescription = styled(ModalContent)`
  text-align: center;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 40px 75px;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const ImportControls = styled.div`
  padding: 1rem 0;

  ${({ theme: { breakpoints } }) => breakpoints.md`
    display: flex;
    justify-content: space-between;
  `}
`;

export const ButtonGroup = styled.div`
  button {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    &:first-child {
      margin-right: 1rem;
    }

    ${({ theme: { breakpoints } }) => breakpoints.md`
      width: auto;
      margin-bottom: 0;
    `}
  }

  ${({ theme: { breakpoints } }) => breakpoints.sm`
    display: flex;
    align-items: center;
  `}
`;

export const TreeContainer = styled.div`
  height: 465px;
  padding: 20px;
  overflow: auto;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  background-color: ${p => p.theme.colors.Background};
  font-size: 0.875rem;
  border-radius: 4px;

  .rct-title {
    margin-left: 10px;
  }

  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    border: 1px solid ${p => p.theme.colors.BorderDefault};
  }

  ul,
  ol {
    margin-top: 16px;
    list-style: none;
  }

  li {
    margin-bottom: 10px;
  }
`;
