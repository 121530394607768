import styled, { css } from 'styled-components';
import { ButtonWithIcon } from '../../../../../shared/components/ButtonWithIcon/ButtonWithIcon';

export const StyledLinkControls = styled.div<{
  visible: boolean;
  withLinkHover: boolean;
}>`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xxs};
  right: ${({ theme }) => theme.spacing.xxs};
  background-color: ${({ theme }) => theme.colors.ModalBackdrop};
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.colors.Surface};
  display: grid;
  grid-auto-flow: column;
  gap: ${({ theme }) => theme.spacing.xxs};
  padding: 1px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${({ theme }) =>
    theme.animationTemplates.opacityOnHoverTransition};

  ${({ theme: { breakpoints } }) => breakpoints.md`
    background-color: unset;
  `}

  ${p =>
    p.withLinkHover &&
    css`
      color: ${({ theme }) => theme.colors.LinkHoverIcon};
    `}
`;

export const LinkControlsButton = styled(ButtonWithIcon)`
  padding: 0;
  width: 28px;
  height: 28px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.OnSurfaceSelected};
  }

  svg {
    width: 16px;
    height: 16px;
  }

  span ~ svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }

  .counter {
    font-family: ${({ theme }) => theme.fonts.Semibold};
    font-size: 0.75rem;
    line-height: 1;
  }
`;

export const LinkControlsContainer = styled.div<{
  isMobile?: boolean;
}>`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xxs};
  right: ${({ theme }) => theme.spacing.xxs};
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.colors.LinkHoverIcon};
  display: grid;
  grid-auto-flow: column;
  gap: 0.25rem;
  padding: 1px;
  opacity: 1;
  transition: 0.2s opacity ease-out;

  ${({ isMobile }) =>
    isMobile &&
    css`
      background-color: ${({ theme }) => theme.colors.ModalBackdrop};
    `}
`;
