import styled from 'styled-components';
import { OnboardingStep } from '../OnboardingPage.styled';

export const AddContentOnboardingtStep = styled(OnboardingStep)`
  max-width: 960px;
`;

export const AddContentHeaderWrapper = styled.div`
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
`;

export const AddContentDescription = styled.div`
  margin-bottom: 2rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const DescriptionSeparator = styled.div`
  margin: 1rem 0;
  border-top: 1px solid ${p => p.theme.colors.BorderLight};
`;
