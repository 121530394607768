import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button } from '../../../../../shared/components/Button/Button';
import { MenuItem } from '../../../../../shared/components/Menu';
import Highlighter from 'react-highlight-words';

export const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  .global-search-icon-hover {
    opacity: 0;
    margin-left: 0.625rem;
  }
  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    .global-search-icon-hover {
      opacity: 1;
      color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;

  .global-search-icon-hover {
    opacity: 0;
    margin-left: 0.625rem;
  }
  &:hover {
    background-color: ${p => p.theme.colors.OnSurfaceHover};
    .global-search-icon-hover {
      opacity: 1;
      color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
    }
  }

  svg {
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }
`;

export const StyledItemBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  -webkit-line-clamp: 1;

  span {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  img {
    margin-right: 0.5rem;
    height: auto;
    width: 1.25rem;
  }

  svg {
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }

  button {
    margin-left: 0.375rem;
    padding: ${({ theme }) => theme.spacing.xxs};

    svg {
      margin: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.OnSurfaceHover};
    }

    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.OnSurfacePressed};
    }
  }

  ul {
    overflow-x: hidden;
    button svg {
      margin-right: 0.5rem;
    }
  }
`;

export const StyledChatItemBody = styled.div`
  margin-left: 0.5rem;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .chat-message {
    max-width: calc(100% - 15px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: inline-block;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  span {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  img {
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }

  svg {
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const StyledItemType = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceLightEmphasis};
`;

export const StyledItemHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  span {
    font-size: 0.75rem;
    color: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
  }
`;

export const StyledMenuButton = styled(Button)`
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledMenuItem = styled(MenuItem)`
  button {
    width: 100%;
    display: flex;
    align-items: center;
    color: ${p => p.theme.colors.OnSurfaceHighEmphasis};
  }

  svg {
    height: 0.875rem !important;
    width: 0.875rem !important;
  }
  span {
    margin: 0 0 0 0.5rem;
  }
`;

export const StyledDesktopName = styled.span`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis} !important;
`;

export const StyledLogin = styled.span`
  color: ${p => p.theme.colors.OnSurfaceMediumEmphasis} !important;
`;

export const StyledDivider = styled.div`
  margin: 0 0.5rem;
  border-radius: 50%;
  height: 2px;
  width: 2px;
  background: ${p => p.theme.colors.OnSurfaceMediumEmphasis};
`;

export const StyledChatMessage = styled.div`
  max-width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${p => p.theme.colors.OnSurfaceHighEmphasis};

  a {
    color: ${p => p.theme.colors.Primary};
  }
`;

export const StyledHighlighter = styled(Highlighter)`
  .textHighlighter {
    ${({ theme }) => theme.fontTemplates.regularSemibold};
    background: ${p => p.theme.colors.TextHighlighterBackgroundColor};
    padding: 0.1875rem 0;
  }
`;
