import React, { FC, useMemo, useState } from 'react';
import { OnboardingPage } from '../OnboardingPage';
import { ApplyTemplates } from '../../../Template/ApplyTemplates';
import { OnboardingStepHeader } from '../OnboardingPage.styled';
import { FormattedMessage } from 'react-intl';
import { OnboardingTranslation } from '../../i18n';
import { HeadTitle } from '../../../../shared/components/HeadTitle';
import { TemplateApiType } from '../../../Template/Template.types';
import { TemplateAppSet } from '../../../Template/Template.constants';
import { useCurrentWorkspace } from '../../../Workspace/Workspace.hooks';
import {
  AddContentDescription,
  AddContentHeaderWrapper,
  AddContentOnboardingtStep,
} from './AddContentPage.styled';
import { useChangeOnboardingStepAccountMutation } from '../../Onboarding.hooks';
import { useCurrentAccount } from '../../../Auth/Auth.hooks';
import { generatePath, Navigate, useNavigate } from 'react-router-dom';
import { SignUpStep } from '../../Onboarding.types';
import {
  INSTALL_PLUGIN_PATHNAME,
  ONBOARDING_TEMPLATES_APPLIED_COOKIE_KEY,
} from '../../Onboarding.constants';
import { setCookie } from '../../../../shared/utils/storage';
import { showToastGraphQLErrors } from '../../../../shared/components/Toast';
import { WORKSPACE_PATHNAME } from '../../../Workspace/Workspace.constants';
import { OnboardingStepsCounter } from '../OnboardingStepsCounter';
import { getShortId } from '../../../../shared/utils/id';

export const AddContentPage: FC = () => {
  const navigate = useNavigate();
  const { account, refetchAccountData } = useCurrentAccount();
  const { workspace } = useCurrentWorkspace();
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateApiType[]>(
    [],
  );

  const selectedTemplateAppSets = useMemo(() => {
    return selectedTemplates
      .map(template => template.appSet)
      .filter(Boolean) as TemplateAppSet[];
  }, [selectedTemplates]);

  const [changeOnboardingStepAccountMutation] =
    useChangeOnboardingStepAccountMutation();

  const changeOnboardingStep = (setTemplatesCookie?: boolean) => {
    return changeOnboardingStepAccountMutation({
      variables: {
        input: {
          onboardingStep: SignUpStep.invite_users,
        },
      },
    })
      .then(() => {
        if (setTemplatesCookie) {
          setCookie(
            ONBOARDING_TEMPLATES_APPLIED_COOKIE_KEY,
            JSON.stringify(selectedTemplateAppSets),
          );
        }
        return refetchAccountData?.();
      })
      .catch(e => {
        showToastGraphQLErrors(e.graphQLErrors);
        navigate('/');
      });
  };

  if (account?.currentStep === SignUpStep.invite_users) {
    return (
      <Navigate
        to={{
          pathname: generatePath(INSTALL_PLUGIN_PATHNAME, {
            workspaceId: getShortId(workspace.id),
          }),
        }}
      />
    );
  }

  if (account?.currentStep !== SignUpStep.add_content) {
    const workspacePath = generatePath(WORKSPACE_PATHNAME, {
      workspaceId: getShortId(workspace.id),
    });
    return <Navigate to={workspacePath} />;
  }

  return (
    <>
      <HeadTitle translationId={OnboardingTranslation.addContentPageTitle} />
      <OnboardingPage horizontallyCentered wrapperWidth={960}>
        <AddContentOnboardingtStep data-testid="desktop-templates-step">
          <AddContentHeaderWrapper>
            <OnboardingStepsCounter currentStep={account?.currentStep} />
            <OnboardingStepHeader data-testid="header">
              <FormattedMessage
                id={OnboardingTranslation.addContentPageHeading}
              />
            </OnboardingStepHeader>

            <AddContentDescription data-testid="description">
              <FormattedMessage
                id={OnboardingTranslation.addContentPageDescription}
              />
            </AddContentDescription>
          </AddContentHeaderWrapper>

          <ApplyTemplates
            applyButtonText={
              OnboardingTranslation.addContentApplyTemplatesButton
            }
            onTemplateSelect={setSelectedTemplates}
            onTemplatesApply={() => changeOnboardingStep()}
          />
        </AddContentOnboardingtStep>
      </OnboardingPage>
    </>
  );
};
