import React, { FC, MutableRefObject, useCallback, useRef } from 'react';
import {
  StyledDesktopName,
  StyledDivider,
  StyledItem,
  StyledItemBody,
  StyledItemType,
  StyledMenuButton,
  StyledMenuItem,
} from './SearchItems.styled';
import { GlobalSearchResultAppHit } from '../../../GlobalSearch.queries';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchTranslation } from '../../../i18n';
import {
  HorizontalAlignment,
  Menu,
  VerticalAlignment,
} from '../../../../../shared/components/Menu';
import { ArrowRightIcon } from '../../../../../shared/icons';
import { generatePath } from 'react-router-dom';
import {
  DESKTOP_TAB_PATHNAME,
  DesktopTab,
} from '../../../../Desktop/Desktop.constants';
import { getShortId } from '../../../../../shared/utils/id';
import { useCurrentWorkspace } from '../../../../Workspace/Workspace.hooks';
import {
  useCurrentAccountKeyset,
  useCurrentWorkspaceAccountPrivateKey,
  useDesktopAppVault,
  useDesktopAppVaultLogin,
} from '../../../../Encryption/Encryption.hooks';
import { displayInstallPluginToast } from '../../../../ChromeExtension/InstallPlugin/InstallPluginToast';
import { getGoogleMultipleAccountsOpenUri } from '../../../../AppStore/AppStore.utils';
import { openUrl } from '../../../../../shared/utils/url.utils';
import { useCurrentAccount } from '../../../../Auth/Auth.hooks';
import { getDecryptedVaultPassword } from '../../../../Encryption/Encryption.crypto.utils';
import { sendCredentialsToInsert } from '../../../../Desktop/Desktop.utils';
import { DesktopAppGroupType } from '../../../../Desktop/data/Desktop/types/Desktop.types';

export const AppItem: FC<
  GlobalSearchResultAppHit & {
    onBlur: (redirectTo?: string) => void;
    skipBlurRef: MutableRefObject<boolean>;
  }
> = ({ app, desktop, onBlur, skipBlurRef }) => {
  const { workspace } = useCurrentWorkspace();
  const appMenuRef = useRef<HTMLButtonElement>(null);
  const vault = useDesktopAppVault(app.id, app.groupAppId, desktop?.id);
  const { login } = useDesktopAppVaultLogin(app.id, desktop?.id);
  const { account } = useCurrentAccount();
  const { privateKey } = useCurrentWorkspaceAccountPrivateKey();
  const { keyset } = useCurrentAccountKeyset();
  const keysetId = keyset?.id;

  const stopEventPropagation = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const fillInCredentials = useCallback(() => {
    if (vault && keysetId && privateKey) {
      getDecryptedVaultPassword(vault, keysetId, privateKey).then(password => {
        if (login || password) {
          sendCredentialsToInsert(
            app.uri,
            login || '',
            password || '',
            app.loginUriPattern,
          );
        }
      });
    }
  }, [login, vault, keysetId, privateKey, app]);

  const handleAppClick = useCallback(
    (event: React.MouseEvent) => {
      stopEventPropagation(event);
      displayInstallPluginToast();

      const supportMultipleAccounts =
        app.groupAppId === DesktopAppGroupType.google;
      const multipleAccountsAppOpenUri = supportMultipleAccounts
        ? getGoogleMultipleAccountsOpenUri(app.uri, login)
        : null;
      const appLoginUri = multipleAccountsAppOpenUri ?? app.uri;

      openUrl(account!.identity.openApp, appLoginUri, app.name, event);
      fillInCredentials();
      onBlur();
    },
    [onBlur, stopEventPropagation, app, fillInCredentials, login, account],
  );

  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      stopEventPropagation(event);
      appMenuRef.current?.click();
    },
    [stopEventPropagation],
  );

  const handleOpenAppLocationClick = useCallback(() => {
    const pathName = generatePath(DESKTOP_TAB_PATHNAME, {
      workspaceId: getShortId(workspace.id),
      desktopId: getShortId(desktop.id),
      tab: DesktopTab.apps,
    });
    onBlur(pathName);
  }, [onBlur, workspace, desktop]);

  return (
    <StyledItem onClick={handleAppClick} onContextMenu={onContextMenu}>
      <StyledItemBody>
        {app.logo.contentUrl && <img src={app.logo.contentUrl} alt="" />}
        <span>{app.name}</span>
        {desktop?.name && (
          <>
            <StyledDivider />
            <StyledDesktopName>{desktop.name}</StyledDesktopName>
            <StyledMenuButton ref={appMenuRef} onClick={stopEventPropagation} />
            <Menu
              width={200}
              trigger={appMenuRef}
              vAlign={VerticalAlignment.CENTER}
              hAlign={HorizontalAlignment.LEFT}
              onOpen={() => (skipBlurRef.current = true)}
              onClose={() => (skipBlurRef.current = false)}>
              <StyledMenuItem onClick={handleOpenAppLocationClick}>
                <ArrowRightIcon />
                <FormattedMessage
                  id={GlobalSearchTranslation.expandedSearchOpenAppLocation}
                />
              </StyledMenuItem>
            </Menu>
          </>
        )}
        {login && (
          <>
            <StyledDivider />
            <StyledDesktopName>{login}</StyledDesktopName>
          </>
        )}
      </StyledItemBody>
      <StyledItemType>
        <FormattedMessage id={GlobalSearchTranslation.expandedSearchAppType} />
      </StyledItemType>
    </StyledItem>
  );
};
